import React, { useMemo } from "react";
import useCustomFieldDropdownOptions from "src/hooks/useCustomFieldDropdownOptions";
import { FieldWrapper, MultiFieldSelect, TitleLabel } from "./shared";
import { OptionItem } from "src/types";

interface MultiDropdownFieldType {
  cf_key: string;
  disabled?: boolean;
  fieldName: string;
  id: string;
  setFieldValue: any;
  value?: (OptionItem | string)[];
  label?: string;
  index: number;
  options?: OptionItem[];
}

export const MultiDropdownField: React.FC<MultiDropdownFieldType> = ({
  cf_key,
  disabled,
  fieldName,
  id,
  setFieldValue,
  value,
  label,
  index,
  options,
}) => {
  const { options: customOptions } = useCustomFieldDropdownOptions(cf_key);

  return (
    <FieldWrapper key={id}>
      <TitleLabel>{label ?? cf_key}</TitleLabel>
      <MultiFieldSelect
        name={`${fieldName}[${index}].key`}
        options={customOptions?.length ? customOptions : options}
        isMulti={true}
        value={
          value?.map((item) =>
            typeof item === "string" ? { label: item, value: item } : { label: item.label, value: item.value },
          ) ?? []
        }
        isDisabled={disabled}
        onChange={(newValue: any) => {
          const selected_options = newValue?.map((item: any) => item?.value ?? item);
          setFieldValue(`${fieldName}[${index}].list_value`, selected_options);
        }}
      />
    </FieldWrapper>
  );
};
