import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { GridFilterContext } from "../../../context";

export default function TableStickyHeader({ item, dataTip, dataFor, backgroundColor, canSort = false }: any) {
  const headerRef: any = useRef(null);
  const [headerWidth, setHeaderWidth] = useState(80);
  const { sortOption, setSortOption, sharedViewId } = useContext(GridFilterContext);

  useEffect(() => {
    if (!headerRef) return;
    setHeaderWidth(headerRef.current.clientWidth);
  }, [headerRef?.current]);

  return (
    <GridTH
      ref={headerRef}
      data-for={dataFor}
      data-tip={dataTip}
      onClick={() => {
        !!canSort && setSortOption(item.metric);
      }}
      selected={sortOption === item.metric}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "inherit",
        color: backgroundColor ? theme.WHITE_COLOR : "inherit",
        borderBottom: sortOption === item.metric ? `2px solid ${theme.PRIMARY500}` : `inherit`,
      }}
    >
      <THText>
        <MarginDiv width={headerWidth}>{item?.metric_label}</MarginDiv>
      </THText>
    </GridTH>
  );
}
interface SelectedProp {
  selected?: boolean;
}

const GridTH = styled.th<SelectedProp>`
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  /* padding: 8px; */
  box-sizing: border-box;
  min-width: 80px;
  max-width: 217px;
  height: 40px;
  font-size: 9px;
  font-weight: 600;
  background-color: inherit;
  word-break: "break";
  cursor: pointer;
  &:first-child {
    width: 217px;
    background-color: ${theme.WHITE_COLOR};
    border-top: none;
  }
`;

const THText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  height: 100%;
`;

const MarginDiv = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-around;
  align-items: center;

  /* top: -50%; */
  // width of header - padding
  width: calc(${(props) => props.width}px - 16px);
  height: 100%;
  font-weight: 600;
  /* display: inline-block; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* word-break: break-all; */
  /* overflow: hidden; */
`;
