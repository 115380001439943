import styled from "styled-components";
import * as React from "react";
import { useContext, useEffect } from "react";
import { AppText, AppSidebarCard } from "../UI";
import { theme } from "../../utils/theme";

import { ModalContext } from "../../context";

import { NewAppButton } from "../UI/NewAppButton";

import { Modal } from "./Modal";
import { EditEmailModalV2 } from "./";

import { useFlags } from "launchdarkly-react-client-sdk";

import { TemplateEditor } from "../UI";

const FullEmailModal: React.FC = () => {
  const {
    emailModal,
    setEmailModal,
    selectedEmail,
    setSelectedEmail,
    showEmailEditorModal,
    openEmailEditorModal,
  } = useContext(ModalContext);

  useEffect(() => {
    return () => {
      setSelectedEmail({
        from: "",
        date: "",
        time: "",
        subject: "",
        html: "",
        lead_id: "",
        lead_has_opted_out_of_email: false,
        name: "",
        email_thread_id: "",
        to: [] as string[],
        channel: "",
      });
    };
  }, []);

  const {
    from,
    to,
    channel,
    date,
    time,
    subject,
    html,
    lead_id,
    name,
    lead_has_opted_out_of_email,
    email_thread_id,
  } = selectedEmail;

  return (
    <>
      {!!showEmailEditorModal ? (
        <>{<EditEmailModalV2 />}</>
      ) : (
        <Modal open={emailModal} onClose={() => setEmailModal(false)} closeButtonSize={16}>
          <AppSidebarCard
            titleHeight={48}
            title={!!subject ? subject : "Email Thread"}
            width={536}
            id="EmailCard"
            unbordered
          >
            <EmailContainer>
              <EmailInfoDiv>
                <ImageBorder>
                  <PictureDiv>{from?.slice()[0]?.toUpperCase()}</PictureDiv>
                </ImageBorder>
                <FlexDiv>
                  <AppText style={{ fontWeight: 600, fontSize: 14 }}>{!!name ? name : from}</AppText>
                  <AppText>{`${from}`}</AppText>

                  <AppText>
                    {date} {time}
                  </AppText>
                </FlexDiv>
              </EmailInfoDiv>
              <TemplateEditor ableToEdit={false} initialContent={html} />
              <Divider />
            </EmailContainer>
            <ButtonDiv>
              <NewAppButton
                size="sm"
                variant="secondary"
                onClick={() => {
                  // alert(JSON.stringify({ selectedEmailTo: selectedEmail.to, from: from, channel, to: to }));
                  openEmailEditorModal({
                    lead_id: lead_id,
                    full_name: name,
                    email: channel === "Outbound" ? to?.[0] : from,
                    email_thread_id: email_thread_id,
                    subject: subject,
                    intent: undefined,
                    isManualEmailSequenceStep: false,
                    sequenceStepId: "",
                  });
                }}
                disabled={lead_has_opted_out_of_email}
              >
                Reply
              </NewAppButton>
            </ButtonDiv>
          </AppSidebarCard>
        </Modal>
      )}
    </>
  );
};

const EmailContainer = styled.div`
  min-height: 400px;
  padding: 0px 24px;
`;

const EmailInfoDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 24px;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 16px;
`;

const PictureDiv = styled.div`
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50%;
  color: ${theme.NEUTRAL400};
  background-color: ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
`;

const ImageBorder = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;

export { FullEmailModal };
