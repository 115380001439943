import styled from "styled-components";
import { client, restAPI } from "../../apollo";
import * as React from "react";
import { useState, useEffect } from "react";
import { NewAppButton, AppText, Wrapper } from "../UI";
import { theme } from "../../utils/theme";
import { isLoggedInVar, loggedInUser } from "../../apollo/cache";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  StepAdmins,
  StepUsersV2,
  StepFollowup,
  StepProducts,
  StepRevenue,
  StepCustomFields,
  StepIntegrationV2,
  StepRestingRules,
  StepLeadsV2,
  StepTimesV2,
} from "../Segments/";
import iconPhoneCallback from "../../images/ic_phone_callback.png";
import * as Sentry from "@sentry/react";
import ReactTooltip from "react-tooltip";
import { useFlags } from "launchdarkly-react-client-sdk";

const stepDescription = [
  {
    title: "Scheduling Details",
    description:
      "Here you can configure the opening and closing times for your Organization. These times will set the availability for reps to schedule demos and other Events",
  },
  {
    title: "Create Enablement Users",
    description:
      "Here you can manage who has administrator access to Sellfire. Enablement Users will be able to view data from a company-wide level, edit the company-wide settings in System Configuration, see how the algorithm is performing in System View, and veto Lead updates.",
  },
  {
    title: "Build Your Sales Team",
    description:
      "Here you will set up your organization's sales teams, and set assignments for reps and their sales managers. You may either upload your teams via bulk CSV upload or create each new user individually. Once uploaded, the use the module below to click and drag reps for re-assignment. The metric averages are intended to help you balance the teams. For reference on how to properly upload users via CSV, please download the CSV template.",
  },
  // {
  //   title: "Configure Algo",
  //   description: "Here you have visibility into your preseeded data for your system's algorithmic Lead Distribution",
  // },
  {
    title: "Field Manager",
    description:
      "Create as many custom fields as you need, determine if reps can view and/or edit those fields in Lead Cards, and manage your organization's Lead Source, Industry, Sub-Industry, Default Revenue Forecast, and Default Close Date values.",
  },
  {
    title: "Import Leads",
    description: `Here you can import leads in bulk via CSV or individually. If importing via CSV, the column (field) names must match the column (field) names in Sellfire exactly. To ensure you are using the correct names, download the CSV template.
      There are three required fields: primary_phone_number, business_name, and state. If including industry and sub_industry, those must match industries and sub industries that are already in Sellfire under "System Configuration > Field Manager > Industry."
      To see any error reasons, scroll to the far right column of the error report.`,
  },
  {
    title: "Automated Campaign Set Up",
    description:
      "Here you can set up automated texts and emails to send to Leads around a scheduled event, such as a Demos, Decision Calls and Call Backs.",
  },
  // { title: "Configure Product Pricing", description: "Here you can add the products that your reps will be selling, set the price points and determine rep's commission earned" },
  { title: "Revenue Planning", description: "Here you can set your organization's monthly revenue goals." },
  {
    title: "Rules of Engagement",
    description: "You can configure rules for how long your leads stay in the resting pool here",
  },
  {
    title: "Third Party Integration",
    description: "Here you can migrate your Salesforce and HubSpot accounts to Sellfire",
  },
  { title: "Loading...", description: "Please wait for the data to load" },
];

const Onboarding: React.FC = () => {
  const [blinds, setBlinds] = useState(false);
  const history = useHistory();
  const { leadIntegrationRelatedRecords } = useFlags();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const raw_step: any = useQuery().get("step") || 1;
  const step = typeof raw_step === "string" ? parseInt(raw_step) : 1;
  console.log("step: ", step);
  // const [step, setStep]: [any, any] = useQueryParam("step");
  // console.log("step: ", step === "1");

  const onComplete = () => {
    // Set the reactive variable to onboardComplete: true
    // This will allow the user to access the dashboard
    console.log("loggedInUser before: ", loggedInUser());
    loggedInUser({
      ...loggedInUser(),
      organization: { id: loggedInUser().organization?.id || "", onboardComplete: true },
    });
    console.log("loggedInUser after: ", loggedInUser());
    history.push(`/dash`);
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
      <StyledTooltip
        multiline
        place="bottom"
        effect="solid"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      <OnboardingColorWrapper>
        <ThisWrapper>
          <Main>
            <OnboardingWrapper>
              <DisappearingDiv circle={10} step={step}>
                <PopupContainerDiv blinds={true}>
                  <SuccessScreen
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    <SuccessScreenText marginBottom={46}>
                      Congratulations! You have completed registration!
                    </SuccessScreenText>
                    <SuccessScreenText fontSize={49} fontWeight={600} marginBottom={60}>
                      Welcome to Sellfire
                    </SuccessScreenText>
                    <img src={iconPhoneCallback} alt="Phone Icon" height="111px" />
                    <SuccessScreenText marginTop={81}>
                      Login credentials have been sent out to your team
                    </SuccessScreenText>
                    <SuccessScreenText marginBottom={72}>
                      You may login to your dashboard now or sign out of the program
                    </SuccessScreenText>
                    <CenterDiv>
                      <NewAppButton
                        style={{ margin: "0px 16.5px" }}
                        height={37}
                        width={185}
                        variant="secondary"
                        hoverVariant="secondary"
                        onClick={() => {
                          localStorage.clear();
                          isLoggedInVar(false);
                          loggedInUser(JSON.parse("{}"));
                        }}
                      >
                        Logout
                      </NewAppButton>
                      <NewAppButton
                        style={{ margin: "0px 16.5px" }}
                        onClick={onComplete}
                        variant="confirm"
                        hoverVariant="confirm"
                        height={37}
                        width={185}
                      >
                        Proceed to Sellfire
                      </NewAppButton>
                    </CenterDiv>
                  </SuccessScreen>
                </PopupContainerDiv>
              </DisappearingDiv>
              <SimpleFlexDiv>
                <StepLocationText>
                  {step === 9 ? "" : `Step ${step}:`} {stepDescription[step - 1].title}
                </StepLocationText>
                <QuestionMarkMoreInfo data-tip={stepDescription[step - 1].description}>?</QuestionMarkMoreInfo>
              </SimpleFlexDiv>
              <ProgressDiv>
                {step > 1 && (
                  <Link to={`onboarding?step=${step - 1}`}>
                    <BackArrow>
                      <AiOutlineLeft
                        style={{
                          margin: "5px 5px",
                        }}
                        size={30}
                        color={theme.WHITE_COLOR}
                      />
                    </BackArrow>
                  </Link>
                )}
                <ProgressTrail>
                  <ProgressCircle circle={1} step={step} />
                  <ProgressCircle circle={2} step={step} />
                  <ProgressCircle circle={3} step={step} />
                  <ProgressCircle circle={4} step={step} />
                  <ProgressCircle circle={5} step={step} />
                  <ProgressCircle circle={6} step={step} />
                  <ProgressCircle circle={7} step={step} />
                  <ProgressCircle circle={8} step={step} />
                  <ProgressCircle circle={9} step={step} />
                  {/* <ProgressCircle circle={10} step={step} /> */}
                  {/* <ProgressCircle circle={11} step={step} /> */}
                </ProgressTrail>
              </ProgressDiv>
              <ContentContainer>
                {step === 1 && <StepTimesV2 isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 2 && <StepAdmins isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 3 && <StepUsersV2 isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 4 && <StepCustomFields isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 5 && <StepLeadsV2 isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 6 && <StepFollowup isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {/* {step === 8 && <StepProducts isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />} */}
                {step === 7 && <StepRevenue isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 8 && <StepRestingRules isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
                {step === 9 && <StepIntegrationV2 isOnboarding step={step} blinds={blinds} setBlinds={setBlinds} />}
              </ContentContainer>
            </OnboardingWrapper>
          </Main>
        </ThisWrapper>
      </OnboardingColorWrapper>
    </Sentry.ErrorBoundary>
  );
};

const StyledTooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 400px;
`;

const OnboardingColorWrapper = styled.div`
  background-color: ${theme.NEUTRAL100};
  min-width: 1100px;
  min-height: inherit;
  padding-left: calc((100vw - 1100px) / 2);
  width: 100vw;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

const OnboardingWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
`;

const ThisWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  margin-right: 0;
  margin-left: 0;
`;

const StepLocationText = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
`;

const QuestionMarkMoreInfo = styled(AppText)`
  display: inline-block;
  line-height: 20px;
  margin: auto;
  font-size: 14px;
  padding-left: 7px;
  margin-left: 14px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${theme.NEUTRAL100};
  color: ${theme.PRIMARY500};
  cursor: help;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

interface IFontSize {
  fontSize?: number;
  fontWeight?: number;
  marginBottom?: number;
  marginTop?: number;
}

const SuccessScreenText = styled(AppText)<IFontSize>`
  color: ${theme.WHITE_COLOR};
  font-size: ${(props) => props.fontSize || 16}px;
  font-weight: ${(props) => props.fontWeight || 500};
  margin-bottom: ${(props) => props.marginBottom || 25}px;
  margin-top: ${(props) => props.marginTop || 0}px;
`;

const SuccessScreen = styled.div`
  width: 100%;
  height: 100%;
  z-index: 3;
  background: ${theme.PRIMARY500};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ProgressProps {
  step: number;
  circle: number;
  width?: number;
}

const DisappearingDiv = styled.div<ProgressProps>`
  display: ${(props) => (props.step === props.circle ? "block" : "none")};
  width: ${(props) => `${props.width}px` || "100%"};
  height: 100%;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin-top: 50px;
  width: auto;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: inherit;
  width: 100%;
`;

const ProgressCircle = styled.div<ProgressProps>`
  position: absolute;
  top: -9px;
  left: ${(props) => (props.circle - 1) * 100}px;
  height: 18px;
  width: 18px;
  border: 2px solid
    ${(props) =>
      props.step === props.circle ? theme.PRIMARY500 : props.step > props.circle ? theme.PRIMARY500 : theme.NEUTRAL100};
  border-radius: 50%;
  background: ${(props) =>
    props.step === props.circle ? theme.WHITE_COLOR : props.step > props.circle ? theme.PRIMARY500 : theme.NEUTRAL100};
`;

const ProgressTrail = styled.div`
  position: relative;
  min-width: 810px;
  width: 810px;
  height: 1px;
  background: ${theme.NEUTRAL100};
  border: 2px solid ${theme.NEUTRAL100};
  margin-left: 60px;
  /* margin-right: 50px; */
`;

const BackArrow = styled.div`
  position: absolute;
  left: 0;
  top: -20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  cursor: pointer;
`;

const ProgressDiv = styled.div`
  margin-top: 50px;
  position: relative;
  display: flex;
`;

const Main = styled.div<ModalOnProps>`
  position: ${(props) => (props.blinds ? "fixed" : "relative")};
  /* min-width: calc(100vw - 72px);
  min-height: 100vh; */
  height: auto;
  /* width: max-content;
  max-height: max-content; */
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: inherit;
  background-color: ${theme.surface.neutral.tertiary};
  width: 100%;
  min-height: inherit;
`;

export { Onboarding };
