import React from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FormDatePicker } from "../Field";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { AppSidebarCard } from "../UI";
import { NewAppButton } from "../UI";
import { theme } from "../../utils/theme";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { appToast } from "../../utils/toast";

const SUBMIT_TIMEOFF_REQUEST = gql`
  mutation submitTimeoffRequest($id: String!, $start_time: DateTime!, $end_time: DateTime!) {
    editTimeOffRequest(schedule_item_id: $id, start_time: $start_time, end_time: $end_time) {
      id
      schedule_item {
        id
        start_time
        end_time
      }
    }
  }
`;

const SUBMIT_NEW_TIMEOFF_REQUEST = gql`
  mutation submitNewTimeoffRequest($start_time: DateTime!, $end_time: DateTime!) {
    logTimeOffRequest(start_time: $start_time, end_time: $end_time) {
      id
      schedule_item {
        id
        start_time
        end_time
      }
    }
  }
`;

interface RequestTimeOffProps {
  id: string;
  full_name?: string;
  start: Date;
  end: Date;
  onClinkingClose: () => void;
  newEvent?: boolean;
  refetchData: () => void;
}

interface MyFormikProps {}

const validationSchema = Yup.object({
  start: Yup.date().required(), // Must after current date, and before end.
  end: Yup.date().required().min(Yup.ref("start"), "End date must be after start date."), // Must come after start.
});

const RequestTimeOff: React.FC<RequestTimeOffProps> = ({
  id,
  full_name,
  start,
  end,
  newEvent,
  onClinkingClose,
  refetchData,
}) => {
  const [submitTimeoffRequest] = useMutation(SUBMIT_TIMEOFF_REQUEST, {
    onCompleted() {
      appToast("Timeoff request submitted successfully!");
      onClinkingClose();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `submitTimeoffRequest GraphQL Error: ${message}`,
      });
      console.log("Error in timeOffRequest ", message);
    },
  });
  const [submitNewTimeoffRequest] = useMutation(SUBMIT_NEW_TIMEOFF_REQUEST, {
    onCompleted() {
      appToast("Timeoff request submitted successfully!");
      onClinkingClose();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `submitNewTimeOffRequest GraphQL Error: ${message}`,
      });
      console.log("Error in timeOffRequest ", message);
    },
  });
  return (
    <Container>
      <Formik
        initialValues={{
          full_name: full_name,
          start: start,
          end: end,
          id: id,
        }}
        onSubmit={async (values) => {
          console.log("Timeoff values: ", values);
          if (newEvent) {
            await submitNewTimeoffRequest({
              variables: {
                start_time: values.start,
                end_time: values.end,
              },
            });
          } else {
            await submitTimeoffRequest({
              variables: {
                id: values.id,
                start_time: values.start,
                end_time: values.end,
              },
            });
          }
          refetchData();
        }}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting, values }: FormikProps<MyFormikProps>) => {
          return (
            <AppSidebarCard
              color={theme.NEUTRAL100}
              textcolor={theme.BLACK_COLOR}
              title={`Timeoff Request ${full_name ? `for ${full_name}` : ``}`}
              width={270}
            >
              <FieldContainer>
                <FormDatePicker name="start" title="Start Time" />
                <FormDatePicker name="end" title="End Time" />
              </FieldContainer>
              <NewAppButton style={{ fontSize: "17px" }} variant="confirm" hoverVariant="confirm" onClick={submitForm}>
                SAVE
              </NewAppButton>
            </AppSidebarCard>
          );
        }}
      </Formik>
      <CloseButton onClick={() => onClinkingClose()}>
        <IoMdClose size={22} color={theme.PRIMARY500} />
      </CloseButton>
    </Container>
  );
};

const CloseButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  right: -14px;
  top: -3px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 5;
`;

const Container = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FieldContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { RequestTimeOff };
