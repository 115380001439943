import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { AppText, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { useContext, useState } from "react";
import { newCloseModal, showMoreArrow } from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import {
  GridFilterContext,
  ModalContext,
  LeadFilterContext,
  FavoritedLeadFilterContext,
  CustomQueueFilterContext,
} from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useQuery } from "@apollo/client";
import { countryCodes } from "../../static/countries";

// Queries
const GET_LEAD_SOURCE_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const GET_STATE_OPTIONS = gql`
  query getStateOptions {
    getStateOptions
  }
`;

const GET_CITY_OPTIONS = gql`
  query getCityOptions {
    getCityOptions
  }
`;

const GET_TIMEZONE_OPTIONS = gql`
  query getTZOptions {
    getTZOptions
  }
`;

const GET_INDUSTRY_OPTIONS = gql`
  query getUniqueIndustries {
    getUniqueIndustries
  }
`;

const LeadCustomQueueFilterComponent: React.FC = () => {
  // States for whether each filer is expanded
  const [expanded, setExpanded] = useState([] as string[]);

  // Context for filters
  const { leadSources, setLeadSources } = useContext(GridFilterContext);
  const { showCustomQueueFiltersModal, setShowCustomQueueFiltersModal } = useContext(ModalContext);
  const { leadFilter, setLeadFilter, setSelected, selected, resetLeadQueueFilters } = useContext(LeadFilterContext);
  const [tempLeadFilter, setTempLeadFilter] = useState(leadFilter);
  const [tempSelected, setTempSelected] = useState(selected);

  //Queries
  const { data: leadSourceOptions, error: leadSourceError, loading: leadSourceLoading } = useQuery(
    GET_LEAD_SOURCE_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const { data: industryOptions, error: industryError, loading: industryLoading } = useQuery(GET_INDUSTRY_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  //const { data: cityOptions, error: cityErroy, loading: cityLoading } = useQuery(GET_CITY_OPTIONS);
  const { data: stateOptions, error: stateError, loading: stateLoading } = useQuery(GET_STATE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: tzOptions, error: tzError, loading: tzLoading } = useQuery(GET_TIMEZONE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  //TODO: Add city loading back in.
  const loading = leadSourceLoading || industryLoading || stateLoading || tzLoading;
  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  // Custom Data

  const leadSourceOptionsArray = leadSourceOptions.getUniqueLeadSources?.map((item: any) => item.label);
  const industryOptionsArray = industryOptions.getUniqueIndustries?.map((item: any) => item.label);
  const countryOptionsArray = countryCodes?.map((item: any) => item.iso2);

  // Custom Functions
  const mapOptionsToKey = (key: string) => {
    switch (key) {
      case "types":
        return ["ColdCall", "CallBack"];
      case "lead_sources":
        return leadSourceOptionsArray;
      case "channels":
        return ["Inbound", "Outbound"];
      case "industries":
        return industryOptionsArray;
      case "states":
        return stateOptions.getStateOptions;
      case "countries":
        return countryOptionsArray;
      case "timezones":
        return tzOptions.getTZOptions;
      case "PDV":
        return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
    }
  };

  const mapLabelToKey = (key: string) => {
    switch (key) {
      case "types":
        return "Types";
      case "lead_sources":
        return "Lead Sources";
      case "channels":
        return "Channel (Source Type)";
      case "industries":
        return "Industry";
      // case "cities":
      //   return cityOptions.getCityOptions;
      //   break;
      case "states":
        return "State";
      case "countries":
        return "Country";
      case "timezones":
        return "Timezone";
      // case "PDV":
      //   return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
      //   break;
    }
  };

  const formatFilterName = (key: string, name: string) => {
    if (key !== "types") return name;
    const array = name.match(/[A-Z][a-z]+/g);
    return array?.join(" ") ?? name;
  };

  const handleCheckboxClick = (key: string, item: string | number) => {
    if (tempLeadFilter[key]?.includes(item)) {
      const filterKeyArray = tempLeadFilter[key]?.filter((selected: string) => {
        return selected !== item;
      });
      const newSelected = tempSelected.filter((filter: any) => filter.item !== item);
      setTempSelected(newSelected);
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempSelected([...tempSelected, { key: key, item: item }]);
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: [...(tempLeadFilter[key] ?? []), item],
      });
    }
  };

  // Custom Data
  const filterOptionsArray = Object.keys(tempLeadFilter);

  return (
    <EditCardDiv>
      <TitleDiv>
        <TitleText>Filters</TitleText>
        <CloseButton>
          {/* <NewAppButton small borderless onClick={() => setShowCustomQueueFiltersModal(!showCustomQueueFiltersModal)}> */}
          <NewAppButton onClick={() => setShowCustomQueueFiltersModal(!showCustomQueueFiltersModal)}>
            <img src={newCloseModal} alt="Close" />
          </NewAppButton>
        </CloseButton>
      </TitleDiv>
      <ScrollDiv>
        {filterOptionsArray?.map((key: string) => {
          const optionsArray = mapOptionsToKey(key);
          return (
            <SectionContainerDiv key={key}>
              <SectionRow>
                <SectionTitle>
                  <AppText style={{ borderBottom: "none" }}>
                    {mapLabelToKey(key)} &nbsp;
                    {!!leadSources && !!leadSources.length && (
                      <span style={{ fontWeight: 600 }}>({leadSources?.length})</span>
                    )}
                  </AppText>
                </SectionTitle>
                <div>
                  <NewAppButton
                    // small
                    // borderless
                    onClick={() =>
                      expanded?.includes(key)
                        ? setExpanded(expanded.filter((item) => item !== key))
                        : setExpanded([...expanded, key])
                    }
                  >
                    {expanded?.includes(key) ? (
                      <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                    ) : (
                      <img src={showMoreArrow} alt="Expand" />
                    )}
                  </NewAppButton>
                </div>
              </SectionRow>

              <PaddingAndScrollDiv>
                {expanded?.includes(key) &&
                  optionsArray?.map((item: string) => {
                    return (
                      <FilterOptionsContainer>
                        <AppCheckbox
                          title={formatFilterName(key, item)}
                          checked={tempLeadFilter[key]?.includes(item)}
                          onClick={() => {
                            handleCheckboxClick(key, item);
                          }}
                        />
                      </FilterOptionsContainer>
                    );
                  })}
              </PaddingAndScrollDiv>
            </SectionContainerDiv>
          );
        })}
      </ScrollDiv>

      <SubmitDiv>
        <NewAppButton
          onClick={() => {
            resetLeadQueueFilters();
            // setTempLeadFilter(leadFilter);
            // setSelected(selected);
            setShowCustomQueueFiltersModal(!showCustomQueueFiltersModal);
          }}
        >
          Reset Filters
        </NewAppButton>
        <NewAppButton
          variant={"primary"}
          onClick={() => {
            setLeadFilter(tempLeadFilter);
            setSelected(tempSelected);
            setShowCustomQueueFiltersModal(!showCustomQueueFiltersModal);
          }}
          disabled={JSON.stringify(tempLeadFilter) === JSON.stringify(leadFilter)}
        >
          Apply Filters {!!tempSelected.length && `(${tempSelected.length})`}
        </NewAppButton>
      </SubmitDiv>
    </EditCardDiv>
  );
};

const ScrollDiv = styled.div`
  max-height: calc(100vh - 156px);
  height: calc(100vh - 156px);
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 20px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

export { LeadCustomQueueFilterComponent };
