import React, { useState } from "react";
import Switch from "react-switch";
import { theme } from "../../../../../utils/theme";
import { useQuery, useMutation } from "@apollo/client";
import { useDebounce } from "../../../../../utils/hooks";
import { appToast } from "../../../../../utils/toast";
import { gql } from "@apollo/client";
import { IIntegrations } from "src/types";
import { SkeletonBlock } from "src/Components/UI";
import { AppText } from "src/Components/UI";
import styled from "styled-components";

interface fetchHubSpotSyncRuleExpectedResponse {
  fetchHubSpotSyncRule: {
    hubspot_inbound_creation_sync: boolean;
    hubspot_inbound_update_sync: boolean;
    hubspot_outbound_activity_sync: boolean;
    hubspot_outbound_creation_sync: boolean;
    hubspot_outbound_update_sync: boolean;
    hubspot_deal_create_on_sale: boolean;
  };
}

const FETCH_HUBSPOT_SETTINGS = gql`
  query FetchHubSpotSyncRule {
    fetchHubSpotSyncRule {
      hubspot_inbound_creation_sync
      hubspot_inbound_update_sync
      hubspot_outbound_activity_sync
      hubspot_outbound_creation_sync
      hubspot_outbound_update_sync
      hubspot_deal_create_on_sale
    }
  }
`;
// we are going to have the settings in a state var and set with the fetch. a debounce listener will update the settings for all of the toggles

interface ISettings {
  leadCreationOutbound?: boolean;
  leadCreationInbound?: boolean;
  leadUpdateOutbound?: boolean;
  leadUpdateInbound?: boolean;
  leadActivityOutbound?: boolean;
  dealCreationOnSale?: boolean;
}

const HubspotSettings = ({}) => {
  const [initialSettings, setInitialSettings] = useState<ISettings | undefined>(undefined);
  const [currentSettings, setCurrentSettings] = useState<ISettings | undefined>(undefined);

  // fetch for HS

  const {
    data: hubspotData,
    loading: hubspotLoading,
    error: hubspotError,
  } = useQuery<fetchHubSpotSyncRuleExpectedResponse>(FETCH_HUBSPOT_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        const {
          fetchHubSpotSyncRule: {
            hubspot_inbound_creation_sync,
            hubspot_inbound_update_sync,
            hubspot_outbound_activity_sync,
            hubspot_outbound_creation_sync,
            hubspot_outbound_update_sync,
            hubspot_deal_create_on_sale,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });

        setCurrentSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });
      }
    },
  });

  // update for HS
  const UPDATE_HUBSPOT_SYNC_RULE = gql`
    mutation UpdateHubSpotSyncRule($syncRuleInput: OrgHubSpotSyncInput!) {
      updateHubSpotSyncRule(sync_rule_input: $syncRuleInput) {
        organization_id
        hubspot_inbound_creation_sync
        hubspot_inbound_update_sync
        hubspot_outbound_activity_sync
        hubspot_outbound_creation_sync
        hubspot_outbound_update_sync
        hubspot_deal_create_on_sale
      }
    }
  `;

  // mutation
  const [updateHubSpotSyncRule] = useMutation(UPDATE_HUBSPOT_SYNC_RULE, {
    onCompleted: (data) => {
      // update the intial and current settings
      appToast("Updated HubSpot settings");

      if (data) {
        const {
          updateHubSpotSyncRule: {
            hubspot_inbound_creation_sync,
            hubspot_inbound_update_sync,
            hubspot_outbound_activity_sync,
            hubspot_outbound_creation_sync,
            hubspot_outbound_update_sync,
            hubspot_deal_create_on_sale,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });

        setCurrentSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });
      }
    },
  });

  // listen for changes to the settings and debounce them

  useDebounce(
    () => {
      const notInitalized = !currentSettings || !initialSettings;

      if (notInitalized) {
        return;
      }

      const settingsHaveNotChanged =
        currentSettings.leadCreationOutbound === initialSettings.leadCreationOutbound &&
        currentSettings.leadCreationInbound === initialSettings.leadCreationInbound &&
        currentSettings.leadUpdateOutbound === initialSettings.leadUpdateOutbound &&
        currentSettings.leadUpdateInbound === initialSettings.leadUpdateInbound &&
        currentSettings.leadActivityOutbound === initialSettings.leadActivityOutbound &&
        currentSettings.dealCreationOnSale === initialSettings.dealCreationOnSale;

      if (settingsHaveNotChanged) {
        return;
      }

      updateHubSpotSyncRule({
        variables: {
          syncRuleInput: {
            hubspot_inbound_creation_sync: currentSettings.leadCreationInbound,
            hubspot_inbound_update_sync: currentSettings.leadUpdateInbound,
            hubspot_outbound_activity_sync: currentSettings.leadActivityOutbound,
            hubspot_outbound_creation_sync: currentSettings.leadCreationOutbound,
            hubspot_outbound_update_sync: currentSettings.leadUpdateOutbound,
            hubspot_deal_create_on_sale: currentSettings.dealCreationOnSale,
          },
        },
      });
    },
    [currentSettings],
    1000,
  );

  const error = hubspotError;

  const loading = hubspotLoading;

  const canToggle = !loading && !error;

  const Loader = () => {
    return (
      <Page>
        <SkeletonBlock height={"90vh"} width={"100%"} borderRadius={6} />
      </Page>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SettingsContainer>
        <SettingsContainerTitle>Lead Creation</SettingsContainerTitle>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadCreationOutbound: checked ? true : false,
                });
              }
            }}
            checked={!!currentSettings?.leadCreationOutbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Outbound Salesforce Lead Creation Sync`}</ToggleText>
        </ToggleRow>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadCreationInbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadCreationInbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Inbound Salesforce Lead Creation Sync`}</ToggleText>
        </ToggleRow>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsContainerTitle>Lead Update</SettingsContainerTitle>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadUpdateOutbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadUpdateOutbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Outbound Salesforce Lead Update Sync`}</ToggleText>
        </ToggleRow>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadUpdateInbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadUpdateInbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Inbound Salesforce Lead Update Sync`}</ToggleText>
        </ToggleRow>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsContainerTitle>Lead Activity</SettingsContainerTitle>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadActivityOutbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadActivityOutbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Outbound Salesforce Lead Activity Sync`}</ToggleText>
        </ToggleRow>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsContainerTitle>Deal</SettingsContainerTitle>

        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  dealCreationOnSale: checked,
                });
              }
            }}
            checked={!!currentSettings?.dealCreationOnSale}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>Create Deal On Sale</ToggleText>
        </ToggleRow>
      </SettingsContainer>
    </>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px;
`;

const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const ToggleText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
`;

const SettingsContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export default HubspotSettings;
