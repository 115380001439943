import React, { useContext } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";

interface DeleteFieldModalProps {
  id: string;
  onDelete?: (id: string) => void;
}

export const DeleteFieldModal: React.FC<DeleteFieldModalProps> = ({ id, onDelete }) => {
  const { showDeleteFieldModal, setShowDeleteFieldModal } = useContext(ModalContext);

  return (
    <Modal
      open={showDeleteFieldModal}
      onClose={() => {
        setShowDeleteFieldModal(false);
      }}
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Delete Field
          </AppText>

          <FlexDiv direction="column" align="center">
            <AppText fontSize={14} fontWeight={400}>
              Are you sure you want to delete this field?
            </AppText>
            <AppText fontSize={14} fontWeight={400}>
              You cannot undo this action.
            </AppText>
          </FlexDiv>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowDeleteFieldModal(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              onDelete?.(id);
              setShowDeleteFieldModal(false);
            }}
          >
            Yes, Delete It
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 98px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
