import styled, { keyframes } from "styled-components";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { theme } from "../../utils/theme";
import { SimpleGrid } from "../Segments";
import { ManagerGrid } from "../Segments";
import { GridInfoTableV2 } from "../Segments";
import { GridFiltersComponent } from "../Segments/";
import { FilterContext, GridFilterContext, ModalContext } from "../../context";
import { isUserRep, loggedInUser } from "../../apollo/cache";
import { AiOutlineLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { AppText } from "../UI";
import { SaveToFolderModal, RepFiltersComponent } from "../modal";
import { iconExpandDash } from "../../images";
import { MixpanelActions } from "./../../services/mixpanel";
import { PhoenixExpandButton } from "../UI/Phoenix/PhoenixNavigation/PhoenixExpandButton";

const ManagerGridPage: React.FC = () => {
  const { saveCallToLibraryModal, setSaveCallToLibraryModal, currentConferenceID, showRepFiltersModal } = useContext(
    ModalContext,
  );

  useEffect(() => {
    if (loggedInUser()) {
      MixpanelActions.track("Performance Dashboard", {
        type: "Page Load",
        org: loggedInUser().organization?.id,
        team: loggedInUser().team_id,
        user: loggedInUser().id,
        role: loggedInUser().role,
        // rank: ,
      });
    }
  }, []);

  const { filterSidebarExpanded } = useContext(GridFilterContext);

  return (
    <DashboardContainer>
      {saveCallToLibraryModal && (
        <SaveToFolderModal
          blinds={saveCallToLibraryModal}
          setBlinds={setSaveCallToLibraryModal}
          conferenceID={currentConferenceID}
        />
      )}
      {showRepFiltersModal && (
        <DarkDiv2>
          <SlideInDiv>
            <RepFiltersComponent />
          </SlideInDiv>
        </DarkDiv2>
      )}
      <MainArea>
        {filterSidebarExpanded && <GridFiltersComponent />}

        <MainContainer>
          <GridInfoTableV2 disableGroupBy={isUserRep()} only_show_rep={isUserRep()} expand_by_default={isUserRep()} />
        </MainContainer>
      </MainArea>
    </DashboardContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
  height: 100%;
  background: ${theme.surface.neutral.tertiary};
  /* width: 100vw; */
`;

// const SidebarContainer = styled.div<ExpandedProps>`
//   /* margin-left: 72px; */
//   /* padding: 50px 20px; */
//   max-height: calc(100vh);
//   width: 416px;
//   transform: ${(props) => (props.expanded ? "translateX(0px)" : "translateX(-416px)")};
//   transition: transform 0.2s ease-in-out;
//   overflow: visible;
//   border-right: 1px solid ${theme.NEUTRAL100};
//   position: fixed;
//   z-index: 10;
//   background-color: ${theme.WHITE_COLOR};
// `;

const DashboardContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
`;

const MainArea = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  min-height: 100%;
`;

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 552px; }
`;

const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SlideInDiv = styled.div`
  /* position: absolute;
  left: 72px; */
  width: 478px;
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  z-index: 8;
  background-color: ${theme.WHITE_COLOR};
`;
export { ManagerGridPage };
