import React from "react";
import {
  merge_primary,
  addNewUser,
  associated_white,
  arrowsLeftAndRight,
  arrowsUpAndDown,
  phone_primary,
  chat,
  mail_blue,
  user_white,
  contact_info_add,
  mail,
} from "../../../images/NewDesign";

import { StatCard } from "../../UI/StatCard";

const RenderAssociateTitle = ({
  primary_lead_id,
  associate_lead_ids,
  contact_id,
  isAssociatedLead,
  lead_activity_data,
  iconSize,
  padding,
}: {
  primary_lead_id: string;
  associate_lead_ids: string[];
  contact_id: string;
  isAssociatedLead: boolean;
  lead_activity_data?: any;
  iconSize?: number;
  padding?: string;
}) => {
  if (isAssociatedLead) {
    if (
      !!lead_activity_data &&
      !!lead_activity_data?.associate_child &&
      !associate_lead_ids?.includes(lead_activity_data?.associate_child?.id)
    ) {
      return <StatCard icon={arrowsLeftAndRight} variant={"tertiary"} iconSize={iconSize} containerPadding={padding} />;
    }
    return <StatCard icon={associated_white} variant={"secondary"} iconSize={iconSize} containerPadding={padding} />;
  }

  if (contact_id === primary_lead_id) {
    return <StatCard icon={user_white} variant={"primary"} iconSize={iconSize} containerPadding={padding} />;
  }

  if (associate_lead_ids?.includes(contact_id)) {
    return <StatCard icon={associated_white} variant={"secondary"} iconSize={iconSize} containerPadding={padding} />;
  }

  return <StatCard icon={arrowsLeftAndRight} variant={"tertiary"} iconSize={iconSize} containerPadding={padding} />;
};

export default RenderAssociateTitle;
