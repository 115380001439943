import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, AppTitle2, FlexDiv, NewAppButton } from "../../../UI";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { copyTextToClipboard } from "../../../../utils/misc";
import { appToast, errorToast, successToast } from "../../../../utils/toast";
import { RulesTable } from "../RulesTable";

import { Loading } from "../../../UI";

import { info } from "../../../../images/NewDesign";
import { useDebounce } from "../../../../utils/hooks";
import { PhoenixStyledTooltip } from "../../../Dumb/PhoenixStyledTooltip";
import { PhoenixIcon, PhoenixMultiSelect } from "../../../UI/Phoenix";
import Widget from "./Widget";
import ReactTooltip from "react-tooltip";
import { OptionItem } from "../../../../types";
import { generateIBCEmbeddableHTML } from "../../../../utils/format";

const GET_INBOUND_CONCIERGE_WIDGET_SETTINGS = gql`
  query getInboundConciergeWidgetSettings {
    getInboundConciergeWidgetSettings {
      id
      created_at
      updated_at
      show_leads_custom_time_options
      representative_response_time
      brand_name
      header_background_color
      header_text_color
      button_color
      button_text_color
      font
      session_token
      existing_lead_response_time
      overflow_reps_on_existing_lead
      custom_existing_lead_response_time
      action
      actionOptions
    }
  }
`;

const SET_INBOUND_CONCIERGE_WIDGET_SETTINGS = gql`
  mutation setInboundConciergeWidgetSettings(
    $show_leads_custom_time_options: Boolean
    $representative_response_time: Int
    $brand_name: String
    $header_background_color: String
    $header_text_color: String
    $button_color: String
    $button_text_color: String
    $font: String
    $existing_lead_response_time: Int
    $custom_existing_lead_response_time: Boolean
    $overflow_reps_on_existing_lead: Boolean
    $scheduleItemType: ScheduleItemType
  ) {
    setInboundConciergeWidgetSettings(
      show_leads_custom_time_options: $show_leads_custom_time_options
      representative_response_time: $representative_response_time
      brand_name: $brand_name
      header_background_color: $header_background_color
      header_text_color: $header_text_color
      button_color: $button_color
      button_text_color: $button_text_color
      font: $font
      existing_lead_response_time: $existing_lead_response_time
      custom_existing_lead_response_time: $custom_existing_lead_response_time
      overflow_reps_on_existing_lead: $overflow_reps_on_existing_lead
      scheduleItemType: $scheduleItemType
    )
  }
`;

interface BookingWidgetProps {
  rule_id: string | undefined;
}

const BookingWidget = ({ rule_id }: BookingWidgetProps) => {
  const history = useHistory();

  const [loadedPage, setLoadedPage] = useState(false);

  interface inboundConciergeWidgetSettings {
    // how long rep has to accept call me now request
    repResponseTime?: number;
    // how long rep has to accept call me now request from existing lead
    existingLeadRepResponseTime?: number;
    // whether or not to use the existingLeadRepResponseTime
    enableExistingLeadRepResponseTime?: boolean;
    // whether or not to overflow reps on existing lead to next available rep
    overflowRepsOnExistingLead?: boolean;
    // what event type to use when a prospect schedules an event through the inbound concierge widget
    scheduleItemType?: string;
  }

  // state var starts as undefined, then is set to the server settings on load
  const [inboundConciergeWidgetLocalSettings, setInboundConciergeWidgetLocalSettings] = useState<
    inboundConciergeWidgetSettings | undefined
  >(undefined);

  const settings_update_debounce_time = 1000;
  // debounce update the settings on the server when the local settings change
  useDebounce(
    () => {
      // to prevent edge cases on initial load
      const notInitialized = !inboundConciergeWidgetLocalSettings;

      // prevent unnecessary updates
      const noChanges =
        inboundConciergeWidgetLocalSettings?.repResponseTime ===
          customizationOptions?.getInboundConciergeWidgetSettings?.representative_response_time &&
        inboundConciergeWidgetLocalSettings?.existingLeadRepResponseTime ===
          customizationOptions?.getInboundConciergeWidgetSettings?.existing_lead_response_time &&
        inboundConciergeWidgetLocalSettings?.enableExistingLeadRepResponseTime ===
          customizationOptions?.getInboundConciergeWidgetSettings?.custom_existing_lead_response_time &&
        inboundConciergeWidgetLocalSettings?.overflowRepsOnExistingLead ===
          customizationOptions?.getInboundConciergeWidgetSettings?.overflow_reps_on_existing_lead &&
        inboundConciergeWidgetLocalSettings?.scheduleItemType ===
          customizationOptions?.getInboundConciergeWidgetSettings?.action;

      if (notInitialized || noChanges) {
        return;
      }

      // we need to validate the rep response time and existing lead rep response time before updating the settings on the server

      // if it is not valid, do not update the settings (throws error toast for user to fix)

      // validate the rep response time if it is not valid, do not update the settings (throws error toast)
      const validRepResponseTime = validateRepResponseTime({
        value: inboundConciergeWidgetLocalSettings?.repResponseTime,
      });

      if (!validRepResponseTime) {
        return;
      }

      // validate the existing lead rep response time if it is not valid, do not update the settings (throws error toast)
      const validExistingLeadRepResponseTime = validateRepResponseTime({
        value: inboundConciergeWidgetLocalSettings?.existingLeadRepResponseTime,
      });

      if (!validExistingLeadRepResponseTime) {
        return;
      }

      // settings are valid, so we can update the settings on the server

      setInboundConciergeWidgetSettings({
        variables: {
          representative_response_time: inboundConciergeWidgetLocalSettings?.repResponseTime,
          existing_lead_response_time: inboundConciergeWidgetLocalSettings?.existingLeadRepResponseTime,
          custom_existing_lead_response_time: inboundConciergeWidgetLocalSettings?.enableExistingLeadRepResponseTime,
          overflow_reps_on_existing_lead: inboundConciergeWidgetLocalSettings?.overflowRepsOnExistingLead,
          scheduleItemType: inboundConciergeWidgetLocalSettings?.scheduleItemType,
        },
      });
    },

    [inboundConciergeWidgetLocalSettings],
    settings_update_debounce_time,
  );

  const { data: customizationOptions, loading, error, refetch: refetchCustomizationOptions } = useQuery(
    GET_INBOUND_CONCIERGE_WIDGET_SETTINGS,
    {
      fetchPolicy: "cache-and-network",

      onCompleted: (data) => {
        setInboundConciergeWidgetLocalSettings({
          repResponseTime: data.getInboundConciergeWidgetSettings.representative_response_time,
          existingLeadRepResponseTime: data.getInboundConciergeWidgetSettings.existing_lead_response_time,
          enableExistingLeadRepResponseTime: data.getInboundConciergeWidgetSettings.custom_existing_lead_response_time,
          overflowRepsOnExistingLead: data.getInboundConciergeWidgetSettings.overflow_reps_on_existing_lead,
          scheduleItemType: data.getInboundConciergeWidgetSettings.action,
        });

        const htmlCode = generateIBCEmbeddableHTML(data.getInboundConciergeWidgetSettings.session_token);
        setHTMLCode(htmlCode);
      },
      onError: (error) => {
        console.log(error);
        appToast("Error fetching customization options");
      },
    },
  );

  const [setInboundConciergeWidgetSettings, { data, loading: mutationLoading, error: mutationError }] = useMutation(
    SET_INBOUND_CONCIERGE_WIDGET_SETTINGS,
    {
      onCompleted: () => {
        refetchCustomizationOptions();
        successToast("Settings updated");
      },
      onError: (error) => {
        console.log(error);
        errorToast("Error updating settings");
      },
    },
  );

  interface validateRepResponseTimeProps {
    value: number | undefined | null;
  }

  const validateRepResponseTime = ({ value }: validateRepResponseTimeProps) => {
    if (!value || typeof value !== "number") {
      errorToast("Please enter a valid number");
      return false;
    }

    const seconds = value;

    if (seconds < 15) {
      errorToast("Minimum response time is 15 seconds");
      return false;
    }

    if (seconds > 120) {
      errorToast("Maximum response time is 120 seconds");
      return false;
    }

    // if it is a valid number, update the state
    return true;
  };

  // ------------------  INBOUND CONCIERGE ------------------ //

  const [HTMLCode, setHTMLCode] = useState("");

  // refresh tooltip
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [inboundConciergeWidgetLocalSettings]);

  if (loading && !inboundConciergeWidgetLocalSettings) return <Loading />;

  return (
    <>
      <PhoenixStyledTooltip multiline place="right" effect="solid" id="booking-widget-tooltip" lineHeight={16} />
      {!rule_id && (
        <>
          <AppTitle2 style={{ marginBottom: 28 }}>Widget Embedding</AppTitle2>
          <>
            <NoMarginSection>
              <EmbeddingGrid>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Widget
                    isMini={true}
                    customizationOptions={{
                      brand_name: customizationOptions?.getInboundConciergeWidgetSettings?.brand_name,
                      header_background_color:
                        customizationOptions?.getInboundConciergeWidgetSettings?.header_background_color,
                      header_text_color: customizationOptions?.getInboundConciergeWidgetSettings?.header_text_color,
                      button_color: customizationOptions?.getInboundConciergeWidgetSettings?.button_color,
                      button_text_color: customizationOptions?.getInboundConciergeWidgetSettings?.button_text_color,
                      font: customizationOptions?.getInboundConciergeWidgetSettings?.font,
                      is_demo: true,
                    }}
                  />
                </div>
                <FlexDiv direction="column" gap={24}>
                  <AppText>
                    Paste the code below onto your website in order to integrate the Inbound Concierge Widget, allowing
                    prospective clients to schedule events or connect directly with representatives at time of form
                    completion.
                  </AppText>
                  <Code>{HTMLCode}</Code>
                  <FlexDiv align="center" gap={20}>
                    <NewAppButton
                      variant="secondary"
                      width={188}
                      height={48}
                      onClick={() => history.push(`/system-config/inbound-concierge/customize-widget`)}
                    >
                      Customize Branding
                    </NewAppButton>
                    <NewAppButton
                      variant="primary"
                      width={188}
                      height={48}
                      onClick={() => {
                        copyTextToClipboard(HTMLCode);
                      }}
                      style={{ letterSpacing: 1 }}
                    >
                      COPY HTML
                    </NewAppButton>
                  </FlexDiv>
                </FlexDiv>
              </EmbeddingGrid>
            </NoMarginSection>

            <Section>
              <SectionTitleDiv>
                <AppTitle2>
                  Rep <em>Call Me</em> Now Response Time
                </AppTitle2>
                <PhoenixIcon
                  data-for="booking-widget-tooltip"
                  data-tip="This determines how long reps have to accept Call Me Now requests made by prospects through the
                    widget. Once this time has elapsed, the Call Me Now request will be transferred to the next available
                    rep per the associated Routing Rules."
                  svg={info}
                  size={14}
                />
              </SectionTitleDiv>

              <FlexDiv align="center" gap={20}>
                <NumberInput
                  type="number"
                  max={120}
                  min={15}
                  value={inboundConciergeWidgetLocalSettings?.repResponseTime}
                  onChange={(e: any) => {
                    setInboundConciergeWidgetLocalSettings({
                      ...inboundConciergeWidgetLocalSettings,
                      repResponseTime: parseInt(e.target.value),
                    });
                  }}
                />

                <AppText>seconds (minimum is 15 seconds and maximum is 120)</AppText>
              </FlexDiv>
            </Section>
            <Section>
              <SectionTitleDiv>
                <AppTitle2>
                  Owned Lead <em>Call Me</em> Now Overflow
                </AppTitle2>
                <PhoenixIcon
                  data-for="booking-widget-tooltip"
                  data-tip="Toggle on if you would like a Call Me Now request from a currently-owned lead to first try the owning
                    rep, and if they do not accept the request in the timeframe configured in the applicable Call Me Now  
                    Response Time (above or below), transfer to the next available rep per the associated Routing Rules."
                  svg={info}
                  size={14}
                />
              </SectionTitleDiv>
              <FlexDiv
                align="center"
                gap={20}
                style={{
                  height: 40,
                }}
              >
                <Switch
                  name="overflow-reps-on-existing-lead"
                  onChange={(checked) => {
                    setInboundConciergeWidgetLocalSettings({
                      ...inboundConciergeWidgetLocalSettings,
                      overflowRepsOnExistingLead: checked,
                    });
                  }}
                  checked={!!inboundConciergeWidgetLocalSettings?.overflowRepsOnExistingLead}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={24}
                  width={40}
                  handleDiameter={18}
                />
              </FlexDiv>
            </Section>
            <Section>
              <SectionTitleDiv>
                <AppTitle2>Event Type</AppTitle2>
                <PhoenixIcon
                  data-for="booking-widget-tooltip"
                  data-tip="The event type to be used when a prospect schedules an event through the Inbound Concierge widget."
                  svg={info}
                  size={14}
                />
              </SectionTitleDiv>
              <FlexDiv align="center" gap={20}>
                <PhoenixMultiSelect
                  options={customizationOptions?.getInboundConciergeWidgetSettings?.actionOptions?.map(
                    (option: OptionItem) => ({
                      value: option?.value,
                      label: option?.label || option?.value,
                    }),
                  )}
                  width={200}
                  name="event_type"
                  isMulti={false}
                  isClearable={false}
                  isDisabled={false}
                  placeholder="Select Event Type"
                  onChange={(selectedOption: OptionItem) => {
                    setInboundConciergeWidgetLocalSettings({
                      ...inboundConciergeWidgetLocalSettings,
                      scheduleItemType: selectedOption?.value as string,
                    });
                  }}
                  value={{
                    value: inboundConciergeWidgetLocalSettings?.scheduleItemType,
                    label:
                      customizationOptions?.getInboundConciergeWidgetSettings?.actionOptions?.find(
                        (option: OptionItem) => option?.value === inboundConciergeWidgetLocalSettings?.scheduleItemType,
                      )?.label || inboundConciergeWidgetLocalSettings?.scheduleItemType,
                  }}
                />
              </FlexDiv>
            </Section>
            <Section>
              <SectionTitleDiv>
                <AppTitle2>
                  Owning Rep <em>Call Me</em> Now Response Time
                </AppTitle2>
                <PhoenixIcon
                  data-for="booking-widget-tooltip"
                  data-tip="Toggle on if you would like Call Me Now requests from owned leads to attempt the owning rep for a
                     different amount of time than what you have configured for the Rep Call Me Now Response Time above. If
                     toggled off, the Rep Call Me Now Response Time configured above will apply."
                  svg={info}
                  size={14}
                />
              </SectionTitleDiv>

              <FlexDiv align="center" gap={20} height={40}>
                <Switch
                  name="enable-existing-lead-rep-response-time"
                  onChange={(checked) => {
                    setInboundConciergeWidgetLocalSettings({
                      ...inboundConciergeWidgetLocalSettings,
                      enableExistingLeadRepResponseTime: checked,
                    });
                  }}
                  onColor={theme.PRIMARY500}
                  checked={!!inboundConciergeWidgetLocalSettings?.enableExistingLeadRepResponseTime}
                  height={24}
                  width={40}
                  handleDiameter={18}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
                {inboundConciergeWidgetLocalSettings?.enableExistingLeadRepResponseTime && (
                  <>
                    <NumberInput
                      type="number"
                      max={120}
                      min={15}
                      value={inboundConciergeWidgetLocalSettings?.existingLeadRepResponseTime}
                      onChange={(e: any) => {
                        if (!e.target.value) return;

                        setInboundConciergeWidgetLocalSettings({
                          ...inboundConciergeWidgetLocalSettings,
                          existingLeadRepResponseTime: parseInt(e.target.value),
                        });
                      }}
                    />
                    <AppText>seconds (minimum is 15 seconds and maximum is 120)</AppText>
                  </>
                )}
              </FlexDiv>
            </Section>
          </>
        </>
      )}
      <Section>
        <div
          style={{
            width: "800px",
          }}
        >
          <RulesTable
            rule={"widget"}
            rule_subtitle={"Disable Widget Rules"}
            rule_title={"Widget Is Not Shown If The Following Criteria Are Met"}
            rule_id={rule_id}
          />
        </div>
      </Section>
    </>
  );
};

const EmbeddingGrid = styled.div`
  display: grid;
  grid-template-columns: 192.12px minmax(auto, 630px);
  grid-gap: 40px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

const NoMarginSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Code = styled.code`
  padding: 16px;
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.NEUTRAL300};
  height: 100px;
  overflow: auto;
`;

const SectionTitleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NumberInput = styled.input`
  text-align: center;
  width: 40px;
  height: 40px;

  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;

export default BookingWidget;
