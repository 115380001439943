import * as React from "react";
import { toast, ToastOptions } from "react-toastify";
import { StyledToast } from "../Components/Dumb/StyledToast";
import { TOAST_CLOSE_DURATION } from "./variables";
import { MixpanelActions } from "src/services/mixpanel";
import * as Sentry from "@sentry/react";
import { wifi_alert, flame } from "src/images/NewDesign";
import { theme } from "./theme";

export const TOAST_DEFAULT_OPTIONS: ToastOptions = {
  hideProgressBar: true,
  autoClose: TOAST_CLOSE_DURATION,
};
// export const appToast = (message: string, options?: ToastOptions) => {
//   toast(message, { ...TOAST_DEFAULT_OPTIONS, ...options });
// };

export const appToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"primary"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const successToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"success"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const errorToast = (
  message: string,
  options?: ToastOptions,
  subtext?: string,
  logPrefix?: string,
  logSentry?: boolean,
  logMixpanel?: boolean,
) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"error"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });

  if (logSentry) {
    Sentry.captureEvent({
      message: logPrefix ? `${logPrefix}: ${message}` : message,
    });
  }
  if (logMixpanel) {
    MixpanelActions.track(logPrefix ?? "Error Toast", { message });
  }
};

export const warningToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"warning"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const hotToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(
    <StyledToast
      message={message}
      subtext={subtext}
      variant={"hot"}
      icon={flame}
      iconColor={theme.icon.dataviz4.hover}
    />,
    {
      ...TOAST_DEFAULT_OPTIONS,
      ...options,
    },
  );
};

export const connectionToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(
    <StyledToast
      message={message}
      subtext={subtext}
      variant={"warning"}
      icon={wifi_alert}
      iconColor={theme.WARNING500}
    />,
    {
      ...TOAST_DEFAULT_OPTIONS,
      ...options,
      position: "bottom-left",
    },
  );
};

export const notificationToast = ({
  message,
  subtext,
  variant,
  options,
  id,
  lead_id,
  redirect,
}: {
  message: string;
  subtext?: string;
  variant: "primary" | "success" | "error" | "warning" | "hot";
  options?: ToastOptions;
  id?: string;
  route?: string;
  lead_id?: string;
  redirect?: string;
}) => {
  toast(<StyledToast message={message} subtext={subtext} variant={variant} lead_id={lead_id} redirect={redirect} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
    toastId: id,
  });
};

export const customToast = (component: React.ReactNode, options?: ToastOptions) => {
  toast(component, { ...TOAST_DEFAULT_OPTIONS, ...options });
};
