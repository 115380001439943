import React, { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { AppInput, AppInputProps } from "../UI";
import { NewAppTimePicker } from "../UI";
import moment from "moment";

interface TimeFieldProps extends AppInputProps {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
  onChange?: (e: any) => void;
  value?: any;
  /**
   * start - for start hours
   * end - for end hours
   * undefined - for generic hours
   */
  timeType?: "start" | "end";
  // timeType?: "start" | "end" | "moment";
  // format?: any;

  onChangeSideEffect?: () => void;
}

/**
 * Used as a time picker in formik elements
 */
const NewTimeField = ({ style, timeType, onChangeSideEffect, ...props }: TimeFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props.name);

  const onChange = (time: moment.Moment) => {
    onChangeSideEffect && onChangeSideEffect();
    const minute = time ? time.minute() : null;
    const hour = time ? time.hour() : null;
    switch (timeType) {
      case "start":
        setFieldValue(`${field.name}`, { ...field.value, start_hour: hour, start_minute: minute });
        return;
      case "end":
        setFieldValue(`${field.name}`, { ...field.value, end_hour: hour, end_minute: minute });
        return;
      // case "moment":
      //   setFieldValue(`${field.name}`, time.format());
      //   return;
      default:
        setFieldValue(`${field.name}`, { ...field.value, hour, minute });
        return;
    }
  };

  const getValue = () => {
    const { start_hour, start_minute, end_hour, end_minute, hour, minute } = field.value;
    switch (timeType) {
      case "start":
        if (start_hour === undefined || start_minute === undefined) {
          return undefined;
        }
        if (start_hour === null || start_minute === null) {
          return undefined;
        }
        return moment().hour(field.value.start_hour).minute(field.value.start_minute);
      case "end":
        if (end_hour === undefined || end_minute === undefined) {
          return undefined;
        }
        if (end_hour === null || end_minute === null) {
          return undefined;
        }
        return moment().hour(field.value.end_hour).minute(field.value.end_minute);
      default:
        if (hour === undefined || minute === undefined) {
          return undefined;
        }
        if (hour === null || minute === null) {
          return undefined;
        }
        return moment().hour(field.value.hour).minute(field.value.minute);
    }
  };

  const getError = () => {
    switch (timeType) {
      case "start":
        return meta.error ? (meta.error as any).start_hour : "";
      case "end":
        return meta.error ? (meta.error as any).end_hour : "";
      default:
        return meta.error ? (meta.error as any).hour : "";
    }
  };

  return <NewAppTimePicker {...field} {...props} value={getValue()} error={getError()} onChange={onChange} />;
};

export { NewTimeField };
