import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";

interface CustomQueueFilterContextState {
  leadFilter: any;
  setLeadFilter: Dispatch<SetStateAction<any>>;
  selected: any;
  setSelected: Dispatch<SetStateAction<any>>;
  leadSystemFilter: any;
  setLeadSystemFilter: Dispatch<SetStateAction<any>>;
  selectedSystem: any;
  setSelectedSystem: Dispatch<SetStateAction<any>>;
  resetLeadSystemFilters: () => void;
  resetLeadCustomQueueFilters: () => void;
  expandCustomQueue: boolean;
  setExpandCustomQueue: Dispatch<SetStateAction<boolean>>;
  filterNum: boolean;
}

export const CustomQueueFilterContext = createContext<CustomQueueFilterContextState>(
  {} as CustomQueueFilterContextState,
);

const DEFAULT_STATE = {
  types: [] as string[],
  lead_sources: [] as string[],
  channels: [] as string[],
  industries: [] as string[],
  //cities: [] as string[],
  states: [] as string[],
  countries: [] as string[],
  timezones: [] as string[],
};

export const CustomQueueFilterProvider: FunctionComponent = ({ children }) => {
  const [expandCustomQueue, setExpandCustomQueue] = useState(true);

  const [leadFilter, setLeadFilter] = useState(
    JSON.parse(
      localStorage?.getItem("customQueueLeadFilter") ??
        `{
   "types": [],
   "lead_sources": [],
   "channels": [],
   "industries": [],
   "states": [],
   "countries": [],
   "timezones": []
}`,
    ) || DEFAULT_STATE,
  );

  const [leadSystemFilter, setLeadSystemFilter] = useState(
    JSON.parse(
      localStorage?.getItem("customQueueLeadSystemFilter") ??
        `{
   "reps": [],
   "status": [],
   "industries": [],
   "sub_industry": [],
   "lead_sources": [],
   "states": [],
   "timezones": [],
   "history_filter": {
    "metric": "",
    "operator": "",
    "amount": "",
    "amount_2": "",
    "lowerbound_date": "",
    "upperbound_date": ""
   },
   "call_result_filter": {
    "call_result_type": "",
    "call_result_option": ""
   },
   "rep_activity_filter": {
     "user_ids": [],
     "rep_lowerbound_date": "",
    "rep_upperbound_date": ""
   }
}`,
    ) || {
      reps: [] as string[],
      status: [] as string[],
      industries: [] as string[],
      sub_industry: [] as string[],
      lead_sources: [] as string[],
      states: [] as string[],
      // countries: [] as string[],
      timezones: [] as string[],
      history_filter: {
        metric: undefined,
        operator: undefined,
        amount: undefined,
        amount_2: undefined,
        lowerbound_date: undefined,
        upperbound_date: undefined,
      },
      call_result_filter: {
        call_result_type: "",
        call_result_option: "",
      },
      rep_activity_filter: {
        user_ids: [],
        rep_lowerbound_date: "",
        rep_upperbound_date: "",
      },
    },
  );

  useEffect(() => {
    localStorage.setItem("customQueueLeadFilter", JSON.stringify(leadFilter) || "");
  }, [leadFilter]);

  const [selected, setSelected] = useState(
    JSON.parse(localStorage.getItem("custom_queue_selected_filters") || "[]") || [],
  );

  useEffect(() => {
    localStorage.setItem("custom_queue_selected_filters", JSON.stringify(selected) || "[]");
  }, [selected]);

  // system view filters
  useEffect(() => {
    localStorage.setItem("customQueueLeadSystemFilter", JSON.stringify(leadSystemFilter) || "");
  }, [leadSystemFilter]);

  const [selectedSystem, setSelectedSystem] = useState(
    JSON.parse(localStorage.getItem("custom_queue_selected_system_filters") || "[]") || [],
  );

  useEffect(() => {
    localStorage.setItem("custom_queue_selected_system_filters", JSON.stringify(selectedSystem) || "[]");
  }, [selectedSystem]);

  const resetLeadCustomQueueFilters = () => {
    setSelected([]);
    setLeadFilter(DEFAULT_STATE);
  };

  const resetLeadSystemFilters = () => {
    setLeadSystemFilter({
      reps: [] as string[],
      status: [] as string[],
      industries: [] as string[],
      sub_industry: [] as string[],
      lead_sources: [] as string[],
      states: [] as string[],
      // countries: [] as string[],
      timezones: [] as string[],
      history_filter: {
        metric: undefined,
        operator: undefined,
        amount: undefined,
        amount_2: undefined,
        lowerbound_date: undefined,
        upperbound_date: undefined,
      },
      call_result_filter: {
        call_result_type: "",
        call_result_option: "",
      },
      rep_activity_filter: {
        user_ids: [],
        rep_lowerbound_date: "",
        rep_upperbound_date: "",
      },
    });
    setSelectedSystem([]);
  };

  /**
   * The number of filters applied
   */
  const filterNum =
    leadFilter.types.length +
    leadFilter.lead_sources.length +
    leadFilter.channels.length +
    leadFilter.industries.length +
    leadFilter.states.length +
    leadFilter.countries.length +
    leadFilter.timezones.length;

  const memoizedValue = useMemo(
    () => ({
      leadFilter,
      setLeadFilter,
      selected,
      setSelected,
      leadSystemFilter,
      setLeadSystemFilter,
      selectedSystem,
      setSelectedSystem,
      resetLeadSystemFilters,
      expandCustomQueue,
      setExpandCustomQueue,
      resetLeadCustomQueueFilters,
      filterNum,
    }),
    [
      leadFilter,
      setLeadFilter,
      selected,
      setSelected,
      leadSystemFilter,
      setLeadSystemFilter,
      selectedSystem,
      setSelectedSystem,
      resetLeadSystemFilters,
      expandCustomQueue,
      setExpandCustomQueue,
      resetLeadCustomQueueFilters,
      filterNum,
    ],
  );

  return <CustomQueueFilterContext.Provider value={memoizedValue}>{children}</CustomQueueFilterContext.Provider>;
};
