import { Form, Formik } from "formik";
import React, { useContext } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../../context";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv } from "../../UI";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { Modal } from "../Modal";

export const DeleteFormModal = () => {
  const { showDeleteFormModal, setShowDeleteFormModal, deleteFormModalData, setDeleteFormModalData } = useContext(
    ModalContext,
  );

  const validationSchema = Yup.object({
    id: Yup.string().required("Form ID is required"),
  });

  return (
    <Modal
      open={showDeleteFormModal}
      onClose={() => {
        setShowDeleteFormModal(false);
      }}
      popupAnimation
    >
      <Formik
        initialValues={{
          id: deleteFormModalData?.id || undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({ isValid, dirty, errors, values }) => {
          console.log(errors);
          return (
            <Form>
              <Main>
                <ModalBody>
                  <AppText fontSize={16} fontWeight={600}>
                    Delete Form
                  </AppText>

                  <FlexDiv direction="column" gap={8} height={138}>
                    <AppText fontSize={14} fontWeight={400} lineHeight={20} textAlign="center">
                      Are you sure you want to delete this template? This action cannot be undone.
                    </AppText>
                  </FlexDiv>
                </ModalBody>

                <ModalButtonContainer>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={() => setShowDeleteFormModal(false)}
                  >
                    No, Cancel
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    type="submit"
                    buttonType="secondary"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    disabled={!isValid}
                    onClick={() => {
                      deleteFormModalData?.deleteOpsiqForm && deleteFormModalData.deleteOpsiqForm();
                    }}
                  >
                    Yes, Continue
                  </PhoenixAppButton>
                </ModalButtonContainer>
              </Main>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 0px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
