import styled from "styled-components";

type Direction = "row" | "column" | "row-reverse" | "column-reverse";
type Align = "normal" | "stretch" | "center" | "flex-start" | "flex-end" | "baseline";
type Justify =
  | "left"
  | "right"
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-evenly"
  | "space-around"
  | "space-between";

interface FlexProps {
  direction?: Direction;
  align?: Align;
  justify?: Justify;
  gap?: number;
  wrap?: "wrap" | "wrap-reverse" | "nowrap";
  grow?: number;
  width?: number | string;
  height?: number | string;
  padding?: string;
}

export const FlexDiv = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  align-items: ${(props) => (props.align ? props.align : "normal")};
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  gap: ${(props) => (props.gap ? props.gap : 0)}px;
  flex-wrap: ${(props) => props.wrap ?? "nowrap"};
  flex-grow: ${(props) => props.grow ?? 0};
  width: ${({ width }) => (!!width ? (typeof width === "number" ? `${width}px` : width) : "initial")};
  height: ${({ height }) => (!!height ? (typeof height === "number" ? `${height}px` : height) : "initial")};
  padding: ${({ padding }) => padding ?? "0"};

  /* For Value */
  .value {
    flex-grow: 1; /* Take up remaining space */
    max-width: 100%; /* Avoid expanding too much */
    height: auto;
  }

  .fields,
  .operator {
    flex-basis: 75%;
    max-width: 200px;
  }

  .cadence {
    flex-grow: 1; /* Take up remaining space */
    max-width: 100%; /* Avoid expanding too much */
    height: 66px;
  }

  .valueEqualNotEqual {
    height: 70px;
  }
`;
