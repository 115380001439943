import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppErrorText, Loading, AppSidebarCard } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import { NewAppButton } from "../../UI/NewAppButton";
import { cloudinary, openUploadWidget } from "../../../services/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { FocusOn } from "@cloudinary/url-gen/qualifiers/focusOn";
import { appToast } from "../../../utils/toast";

interface ProfileImageProps {
  profileImage?: string | null;
  diameter?: number;
  borderDiameter?: number;
  borderWidth?: number;
  borderColor?: string;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  profileImage,
  diameter = 100,
  borderDiameter = 108,
  borderWidth = 10,
  borderColor = theme.PRIMARY500,
}) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    const default_image = "hfvs3jniatz9yadvcb7r";
    if (profileImage) {
      setImage(profileImage);
    } else {
      setImage(default_image);
    }
  }, [profileImage]);

  // after image is set;
  const myImage = cloudinary.image(image);
  myImage.quality("auto:low");

  // myImage.resize(thumbnail().width(100).height(100).gravity(focusOn(FocusOn.face())));

  return (
    <AccountSettingsBorder borderDiameter={borderDiameter} borderWidth={borderWidth} borderColor={borderColor}>
      <AdvancedImageStyle cldImg={myImage} diameter={diameter} />
    </AccountSettingsBorder>
  );
};

interface BorderStyleProps {
  borderDiameter: number;
  borderWidth: number;
  borderColor: string;
}

const AccountSettingsBorder = styled.div<BorderStyleProps>`
  height: ${(props) => `${props.borderDiameter}px`};
  width: ${(props) => `${props.borderDiameter}px`};
  border-radius: 50%;
  background-color: transparent;
  border: ${(props) => `${props.borderWidth}px solid ${props.borderColor}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface AdvancedImageStyleProps {
  diameter: number;
}

const AdvancedImageStyle = styled(AdvancedImage)<AdvancedImageStyleProps>`
  border-radius: ${(props) => `${props.diameter}px`};
  width: ${(props) => `${props.diameter}px`};
  height: ${(props) => `${props.diameter}px`};
  border: 1px solid rgba(255, 255, 255, 0.5);
`;

export { ProfileImage };
