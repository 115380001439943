import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useState, useContext } from "react";
import { AppButton, AppText, Loading, AppSidebarCard, AppErrorText } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { AiFillPlusCircle } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { DeleteField, UpdateCustomField, CreateLeadSourceModal, UpdateIndustry } from "../../modal/";
import { toast } from "react-toastify";
import iconBurger from "../../../images/ic-draggable-bars.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { defaultFieldResolver } from "graphql";
import { appToast } from "../../../utils/toast";
import { iconNewInfo, iconNewMoveRow, showMoreArrow } from "../../../images";
import { ModalContext } from "../../../context/ModalContext";
import { loggedInUser } from "../../../apollo/cache";
import { PipelineCloseDateModal } from "../../modal/PipelineFunnelValuesModal";
import { PipelineMetricDefaultValueModal } from "../../modal/PipelineMetricDefaultValueModal";
import { useFlags } from "launchdarkly-react-client-sdk";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const FETCH_CUSTOM_FIELDS = gql`
  query FetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const FETCH_INDUSTRY_OPTIONS = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const FETCH_LEAD_SOURCE_OPTIONS = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const DELETE_CUSTOM_FIELD = gql`
  mutation DeleteCustomField($id: String!) {
    deleteOneCustomField(where: { id: $id }) {
      type
      key
      value
      id
      options
      allow_reps_to_edit
    }
  }
`;

const DELETE_CUSTOM_FIELD_V2 = gql`
  mutation DeleteCustomField($id: String!) {
    deleteCustomField(id: $id) {
      type
      key
      value
      id
      options
      allow_reps_to_edit
    }
  }
`;
const DELETE_SYSTEM_FIELD = gql`
  mutation removeSystemField($systemFieldType: SystemFieldType!, $label: String!) {
    removeSystemField(systemFieldType: $systemFieldType, label: $label)
  }
`;

const CHANGE_VISIBILITY = gql`
  mutation UpdateOneCustomField(
    $customFieldId: String!
    $key: String
    $type: CustomFieldType
    $allowRepsToEdit: Boolean
    $visible: Boolean
    $options: [String]
  ) {
    updateOneCustomField(
      custom_field_id: $customFieldId
      key: $key
      type: $type
      allow_reps_to_edit: $allowRepsToEdit
      visible: $visible
      options: $options
    ) {
      id
      key
      type
      visible
      allow_reps_to_edit
    }
  }
`;

export const FETCH_PIPELINE_VALUES = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      mrr
      mrr_label
      day_to_close
      mrr_required_on_sale
    }
  }
`;

const StepCustomFields: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const defaultField = {
    id: "",
    keyName: "",
    type: "",
    value: "",
    options: [],
    allow_reps_to_edit: "false",
    visible: true,
  };

  const {
    leadSourceModalData,
    setLeadSourceModalData,
    industryModalData,
    setIndustryModalData,
    pipelineCloseDateModal,
    setPipelineCloseDateModal,
    pipelineMetricDefaultValueModal,
    setPipelineMetricDefaultValueModal,
  } = useContext(ModalContext);

  const [modalField, setModalField] = useState(defaultField);

  const [sourceExpanded, setSourceExpanded] = useState(false);
  const [industryExpanded, setIndustryExpanded] = useState(false);
  const [pipelineMetricExpanded, setPipelineMetricExpanded] = useState(false);
  const [closeDateExpanded, setCloseDateExpanded] = useState(false);

  const [deleteModalData, setDeleteModalData] = useState({ id: "", fieldType: "", fieldName: "" });

  const history = useHistory();

  const [deleteOneCustomField, { loading: loadingDelete }] = useMutation(DELETE_CUSTOM_FIELD_V2, {
    onCompleted({ deleteOneCustomField }) {
      console.log("Delete custom field: ", deleteOneCustomField);
      if (!deleteOneCustomField) {
        return;
      }
      appToast("Custom field deleted");
      refetch();
    },
    onError({ message }) {
      console.log("Error in deleteOneCustomField: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneCustomField GraphQL Error: ${message}`,
      });
    },
  });

  const [deleteSystemField, { loading: loadingSystemField }] = useMutation(DELETE_SYSTEM_FIELD, {
    onCompleted({ deleteSystemField }) {
      console.log("Deleted system field: ", deleteSystemField);
      appToast("Deleted system field");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `deleteSystemField GraphQL Error: ${message}`,
      });
      console.log("Error in deleteSystemField: ", message);
    },
    refetchQueries: ["leadSourceOptions", "industryOptions"],
  });

  const [changeVisibility] = useMutation(CHANGE_VISIBILITY, {
    onCompleted({ changeVisibility }) {
      appToast("Custom field visibility changed");
      refetch();
    },
    onError({ message }) {
      console.log("Error in changeVisibility: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `GraphQL Error: ${message}`,
      });
    },
  });

  function onDragEnd(result: any) {
    console.log("result: ", result);
    const { source, destination, draggableId: field_id } = result;
    console.log("source: ", source);
    console.log("destination: ", destination);
    console.log("destination: ", destination);
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    // If source and destination droppable ids are same, don't change anything
    if (sInd === dInd) {
      return;
    }

    const is_visible = dInd === "false" ? false : true;
    // Can get optimistic response here as well
    console.log("all_users: ", data.fetchCustomFields);
    const user_list = data.fetchCustomFields.filter((u: any) => u.id === field_id);
    console.log("user_list: ", user_list);
    // const optimistic_user = user_list.length ? { ...user_list[0], team_id } : { id: user_id, team_id };

    // if (data.fetchCustomFields.filter((item: any) => item.visible === true).length >= 10 && dInd === "true") {
    //   return;
    // }
    changeVisibility({
      variables: {
        customFieldId: field_id,
        visible: is_visible,
      },
    });
    // if destination is unassigned, called unassignUser mutation
    // If destination is truthy id, call the assignTeam mutation
  }

  const { data, loading, error, refetch } = useQuery(FETCH_CUSTOM_FIELDS, { fetchPolicy: "network-only" });

  const {
    data: dataIndustries,
    loading: loadingIndustries,
    error: errorIndustries,
    refetch: refetchIndustries,
  } = useQuery(FETCH_INDUSTRY_OPTIONS, {
    fetchPolicy: "network-only",
    variables: { org_id: loggedInUser().organization_id },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: dataLeadSources,
    loading: loadingLeadSources,
    error: errorLeadSources,
    refetch: refetchLeadSources,
  } = useQuery(FETCH_LEAD_SOURCE_OPTIONS, {
    fetchPolicy: "network-only",
    variables: { org_id: loggedInUser().organization_id },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataPipelineValues, loading: loadingPipelineValues, error: errorPipelineValues } = useQuery(
    FETCH_PIPELINE_VALUES,
    {
      fetchPolicy: "network-only",
      variables: { org_id: loggedInUser().organization_id },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  console.log("data: ", data);

  if (error) {
    return <p>Error loading admins</p>;
  }
  console.log("data: ", data);

  if (
    data &&
    data.fetchCustomFields &&
    data.fetchCustomFields.reduce((acc: number, cur: any) => (cur.visible === true ? ++acc : acc), 0) > 7
  ) {
    defaultField.visible = false;
  }

  return (
    <>
      <FlexEndDiv>
        <AddCustomFieldButton
          variant={"primary"}
          onClick={() => {
            setModalField({ ...defaultField });
            setBlinds(true);
          }}
        >
          Add Custom Field
        </AddCustomFieldButton>
      </FlexEndDiv>
      <DisappearingDiv>
        {blinds && !!deleteModalData.id ? (
          <DeleteField
            customFields
            blinds={!!deleteModalData.id}
            close={() => setDeleteModalData({ id: "", fieldType: "", fieldName: "" })}
            id={deleteModalData.id}
            onDeleteFunction={async (item_id: string) => {
              await deleteOneCustomField({
                variables: {
                  id: item_id,
                },
              });
              setBlinds(false);
            }}
            loading={loadingDelete}
            fieldType={deleteModalData.fieldType}
            fieldName={deleteModalData.fieldName}
          />
        ) : (
          blinds && (
            <UpdateCustomField
              blinds={blinds}
              close={() => {
                setModalField(defaultField);
                refetch();
                setBlinds(false);
              }}
              setDeleteModalData={setDeleteModalData}
              {...modalField}
            />
          )
        )}

        {/* Lead Source Modals */}
        {leadSourceModalData.delete_modal_on ? (
          <DeleteField
            customFields
            blinds={leadSourceModalData.delete_modal_on}
            close={() =>
              setLeadSourceModalData({
                name_modal_on: false,
                delete_modal_on: false,
                id: "",
                label: "",
              })
            }
            id={leadSourceModalData.id}
            onDeleteFunction={async (item_id: string) => {
              await deleteSystemField({
                variables: {
                  systemFieldType: "LeadSource",
                  label: item_id,
                },
              });
              setBlinds(false);
            }}
            loading={loadingSystemField}
            fieldType={"Lead Source"}
            fieldName={leadSourceModalData.label}
          />
        ) : (
          leadSourceModalData.name_modal_on && (
            <CreateLeadSourceModal
              blinds={leadSourceModalData.name_modal_on}
              close={() => {
                setLeadSourceModalData({
                  name_modal_on: false,
                  delete_modal_on: false,
                  id: "",
                  label: "",
                });
              }}
            />
          )
        )}

        {/* Industry Modals */}
        {industryModalData.delete_modal_on ? (
          <DeleteField
            customFields
            blinds={industryModalData.delete_modal_on}
            close={() =>
              setIndustryModalData({
                name_modal_on: false,
                delete_modal_on: false,
                id: "",
                label: "",
                sub_fields: [],
              })
            }
            id={industryModalData.id}
            onDeleteFunction={async (item_id: string) => {
              await deleteSystemField({
                variables: {
                  systemFieldType: "Industry",
                  label: item_id,
                },
              });
              setBlinds(false);
            }}
            loading={loadingSystemField}
            fieldType={"Industry"}
            fieldName={industryModalData.label}
          />
        ) : (
          industryModalData.name_modal_on && (
            <UpdateIndustry
              blinds={industryModalData.name_modal_on}
              close={() => {
                setIndustryModalData({
                  name_modal_on: false,
                  delete_modal_on: false,
                  id: "",
                  label: "",
                  sub_fields: [],
                });
              }}
              setDeleteModalData={() =>
                setIndustryModalData({
                  ...industryModalData,
                  delete_modal_on: true,
                })
              }
              {...industryModalData}
            />
          )
        )}

        {pipelineCloseDateModal && <PipelineCloseDateModal />}
        {pipelineMetricDefaultValueModal && <PipelineMetricDefaultValueModal />}

        {loading ? (
          <Loading />
        ) : !data.fetchCustomFields.length ? (
          <EmptyStateDiv>
            <EmptyStateText>Add A Custom Field</EmptyStateText>
            <CenterDiv>
              <AiFillPlusCircle
                style={{ cursor: "pointer" }}
                size={48}
                color={theme.SUCCESS500}
                onClick={() => {
                  setModalField({ ...defaultField });
                  setBlinds(true);
                }}
              />
            </CenterDiv>
          </EmptyStateDiv>
        ) : (
          <div>
            <TopAlignDiv>
              <AppSidebarCard title="Custom Fields">
                <DragDropContext onDragEnd={onDragEnd}>
                  <CampaignTable>
                    <TableHeadingText>Custom Field Name</TableHeadingText>
                    <TableHeadingText>Allow Reps to Edit</TableHeadingText>
                    <TableHeadingText>Custom Field Type</TableHeadingText>
                    <TableHeadingText>Actions</TableHeadingText>
                  </CampaignTable>

                  <Droppable key={"true"} droppableId={"true"}>
                    {(provided, snapshot) => (
                      <TableDataDiv ref={provided.innerRef} {...provided.droppableProps}>
                        {data.fetchCustomFields
                          .slice()
                          .sort((a: any, b: any) => (a?.key > b?.key ? -1 : 1))
                          ?.map((item: any, index: number) => {
                            console.log("item: ", item);
                            if (item.visible === true) {
                              return (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided, snapshot) => (
                                    <CampaignDiv
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <CampaignTable>
                                        <TableText translucent={!item.visible}>
                                          <img
                                            style={{ marginRight: "12px", cursor: "pointer" }}
                                            src={iconNewMoveRow}
                                            height="10px"
                                          />

                                          {item.key}
                                        </TableText>
                                        <TableText translucent={!item.visible}>
                                          {item.allow_reps_to_edit === true ? "Yes" : "No"}
                                        </TableText>
                                        <TableText translucent={!item.visible}>{item.type}</TableText>
                                        <ClickableText
                                          onClick={() => {
                                            setModalField({ ...item, keyName: item.key });
                                            setBlinds(true);
                                          }}
                                        >
                                          Manage Field
                                        </ClickableText>
                                      </CampaignTable>
                                    </CampaignDiv>
                                  )}
                                </Draggable>
                              );
                            }
                          })}
                      </TableDataDiv>
                    )}
                  </Droppable>
                  <TableSubheadingText>Non Visible Fields</TableSubheadingText>
                  <TableDataDiv>
                    {/* <hr /> */}

                    <Droppable key={"false"} droppableId={"false"}>
                      {(provided, snapshot) => (
                        <TableDataDiv ref={provided.innerRef} {...provided.droppableProps}>
                          {data.fetchCustomFields
                            .slice()
                            .sort((a: any, b: any) => (a?.key > b?.key ? -1 : 1))
                            ?.map((item: any, index: number) => {
                              console.log("item: ", item);
                              if (item.visible === false) {
                                return (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <CampaignDiv
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <CampaignTable>
                                          <TableText translucent={!item.visible}>
                                            <img
                                              style={{ marginRight: "12px", cursor: "pointer" }}
                                              src={iconNewMoveRow}
                                              height="10px"
                                            />

                                            {item.key}
                                          </TableText>
                                          <TableText translucent={!item.visible}>
                                            {item.allow_reps_to_edit === true ? "Yes" : "No"}
                                          </TableText>
                                          <TableText translucent={!item.visible}>{item.type}</TableText>
                                          <ClickableText
                                            onClick={() => {
                                              setModalField({ ...item, keyName: item.key });
                                              setBlinds(true);
                                            }}
                                          >
                                            Manage Field
                                          </ClickableText>
                                        </CampaignTable>
                                      </CampaignDiv>
                                    )}
                                  </Draggable>
                                );
                              }
                            })}
                        </TableDataDiv>
                      )}
                    </Droppable>
                  </TableDataDiv>
                </DragDropContext>
                <InlineFlexEndDiv></InlineFlexEndDiv>
              </AppSidebarCard>
            </TopAlignDiv>
          </div>
        )}

        <TopAlignDiv>
          <AppSidebarCard title="Sellfire System Fields">
            <SectionRow style={{ borderTop: "none" }}>
              <SectionTitle>
                <AppText style={{ borderBottom: "none" }}>Lead Source</AppText>
              </SectionTitle>
              {sourceExpanded && (
                <InlineFlexEndDiv>
                  <AddCustomFieldButton
                    variant={"primary"}
                    onClick={() => {
                      setLeadSourceModalData({
                        id: "",
                        delete_modal_on: false,
                        name_modal_on: true,
                        label: "",
                      });
                    }}
                  >
                    Add Lead Source
                  </AddCustomFieldButton>
                </InlineFlexEndDiv>
              )}
              <div>
                {/* <NewAppButton small borderless onClick={() => setSourceExpanded(!sourceExpanded)}> */}
                <NewAppButton onClick={() => setSourceExpanded(!sourceExpanded)}>
                  {sourceExpanded ? (
                    <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                  ) : (
                    <img src={showMoreArrow} alt="Expand" />
                  )}
                </NewAppButton>
              </div>
            </SectionRow>
            {sourceExpanded && (
              <>
                <SystemTable>
                  <TableHeadingText>Lead Source</TableHeadingText>
                  <TableHeadingText>Actions</TableHeadingText>
                </SystemTable>
                {loadingLeadSources ? (
                  <Loading />
                ) : errorLeadSources ? (
                  <AppErrorText>Error loading lead sources.</AppErrorText>
                ) : dataLeadSources?.fetchLeadSourceOptions?.length === 0 ? (
                  <AppText>No lead sources pressent. Click "Add Lead Source" below to add one.</AppText>
                ) : (
                  dataLeadSources?.fetchLeadSourceOptions
                    ?.slice()
                    ?.sort((a: any, b: any) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
                    ?.map((item: any) => (
                      <SystemTable>
                        <TableText>{item.label}</TableText>
                        <ClickableText
                          onClick={() => {
                            setLeadSourceModalData({
                              id: item.id,
                              delete_modal_on: true,
                              name_modal_on: false,
                              label: item.label,
                            });
                          }}
                        >
                          Delete Field
                        </ClickableText>
                      </SystemTable>
                    ))
                )}
              </>
            )}
            <SectionRow
              style={{
                borderTop: sourceExpanded ? `1px solid ${theme.NEUTRAL200}` : "none",
                borderBottom: industryExpanded ? `1px solid ${theme.NEUTRAL200}` : "none",
              }}
            >
              <SectionTitle>
                <AppText style={{ borderBottom: "none" }}>Industry</AppText>
              </SectionTitle>
              {industryExpanded && (
                <InlineFlexEndDiv>
                  <AddCustomFieldButton
                    variant={"primary"}
                    onClick={() => {
                      // setModalField({ ...defaultField });
                      setIndustryModalData({
                        id: "",
                        delete_modal_on: false,
                        name_modal_on: true,
                        label: "",
                        sub_fields: [],
                      });
                    }}
                  >
                    Add Industry
                  </AddCustomFieldButton>
                </InlineFlexEndDiv>
              )}
              <div>
                {/* <NewAppButton small borderless onClick={() => setIndustryExpanded(!industryExpanded)}> */}
                <NewAppButton onClick={() => setIndustryExpanded(!industryExpanded)}>
                  {industryExpanded ? (
                    <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                  ) : (
                    <img src={showMoreArrow} alt="Expand" />
                  )}
                </NewAppButton>
              </div>
            </SectionRow>
            {industryExpanded && (
              <>
                <IndustryTable>
                  <TableHeadingText>Primary Industry</TableHeadingText>
                  <TableHeadingText>Sub-Industry</TableHeadingText>
                  <TableHeadingText>Actions</TableHeadingText>
                </IndustryTable>

                {/* <TableDataDiv> */}

                {loadingIndustries ? (
                  <Loading />
                ) : errorIndustries ? (
                  <AppErrorText>Error loading industries.</AppErrorText>
                ) : !!dataIndustries &&
                  !!dataIndustries?.fetchIndustryOptions &&
                  dataIndustries.fetchIndustryOptions?.length === 0 ? (
                  <AppText>No industries pressent. Click "Add Industry" below to add one.</AppText>
                ) : (
                  !!dataIndustries &&
                  !!dataIndustries?.fetchIndustryOptions &&
                  dataIndustries.fetchIndustryOptions
                    ?.slice()
                    ?.sort((a: any, b: any) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
                    ?.map((item: any) => (
                      <IndustryTable>
                        <TableText>{item.label}</TableText>
                        <TableText style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                          {item.sub_industries
                            .slice()
                            .sort((a: any, b: any) => (a < b ? -1 : 1))
                            ?.map(
                              (subindustry: string, index: number) =>
                                `${subindustry}${
                                  index + 2 < item.sub_industries.length
                                    ? `, `
                                    : index + 2 === item.sub_industries.length
                                    ? ` & `
                                    : `.`
                                }`,
                            )}
                        </TableText>
                        <ClickableText
                          onClick={() => {
                            setIndustryModalData({
                              id: item.id,
                              delete_modal_on: false,
                              name_modal_on: true,
                              label: item.label,
                              sub_fields: item.sub_industries,
                            });
                          }}
                        >
                          Manage Field
                        </ClickableText>
                      </IndustryTable>
                    ))
                )}
              </>
            )}
            {
              <>
                <SectionRow style={{ borderBottom: "none" }}>
                  <SectionTitle>
                    <AppText>Default Revenue Forecast Value</AppText>
                  </SectionTitle>
                  <div>
                    {/* <NewAppButton small borderless onClick={() => setSourceExpanded(!sourceExpanded)}> */}
                    <NewAppButton onClick={() => setPipelineMetricExpanded(!pipelineMetricExpanded)}>
                      {pipelineMetricExpanded ? (
                        <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                      ) : (
                        <img src={showMoreArrow} alt="Expand" />
                      )}
                    </NewAppButton>
                  </div>
                </SectionRow>

                {pipelineMetricExpanded && (
                  <>
                    <IndustryTable>
                      <TableHeadingText>Default Revenue Forecast Value</TableHeadingText>
                      <TableHeadingText>Value ($)</TableHeadingText>
                      <TableHeadingText></TableHeadingText>
                    </IndustryTable>
                    <IndustryTable style={{ borderBottom: "none" }}>
                      <TableText>{dataPipelineValues?.fetchOrganization?.mrr_label}</TableText>
                      <TableText style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {dataPipelineValues?.fetchOrganization?.mrr}
                      </TableText>
                      <ClickableText
                        onClick={() => {
                          setPipelineMetricDefaultValueModal(true);
                        }}
                      >
                        Edit
                      </ClickableText>
                    </IndustryTable>
                  </>
                )}

                <SectionRow style={{ borderBottom: "none" }}>
                  <SectionTitle>
                    <AppText style={{ borderBottom: "none" }}>Default Close Date</AppText>
                  </SectionTitle>
                  <div>
                    {/* <NewAppButton small borderless onClick={() => setSourceExpanded(!sourceExpanded)}> */}
                    <NewAppButton onClick={() => setCloseDateExpanded(!closeDateExpanded)}>
                      {closeDateExpanded ? (
                        <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                      ) : (
                        <img src={showMoreArrow} alt="Expand" />
                      )}
                    </NewAppButton>
                  </div>
                </SectionRow>

                {closeDateExpanded && (
                  <>
                    <IndustryTable>
                      <TableHeadingText>Default Close Date</TableHeadingText>
                      <TableHeadingText>Value</TableHeadingText>
                      <TableHeadingText></TableHeadingText>
                    </IndustryTable>
                    <IndustryTable style={{ borderBottom: "none" }}>
                      <TableText>Default Days to Close</TableText>
                      <TableText style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {dataPipelineValues?.fetchOrganization?.day_to_close}
                      </TableText>
                      <ClickableText
                        onClick={() => {
                          setPipelineCloseDateModal(true);
                        }}
                      >
                        Edit
                      </ClickableText>
                    </IndustryTable>
                  </>
                )}
              </>
            }
          </AppSidebarCard>
        </TopAlignDiv>
        {isOnboarding === true && (
          <CenterDiv>
            <SaveAndContinueButton type="submit" onClick={() => history.push(`/onboarding?step=${step + 1}`)}>
              Continue
            </SaveAndContinueButton>
          </CenterDiv>
        )}
      </DisappearingDiv>
    </>
  );
};

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  padding-left: 16px;
  padding-right: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const ClickableText = styled(AppText)`
  font-weight: 600;
  color: ${theme.PRIMARY500};
  padding-left: 16px;
  padding-top: 11px;
  border-bottom: none;
  cursor: pointer;
`;

const FlexEndDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0px 12px 12px 0px;
`;

const InlineFlexEndDiv = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  padding: 12px 94px 12px 0px;
`;

const AddCustomFieldButton = styled(NewAppButton)`
  /* margin-top: 0px; */
  /* margin-bottom: 20px; */
  width: 150px;
  text-transform: capitalize;
  /* font-size: 11px;
  width: 144px;
  height: 33px;
  border: solid 1px ${theme.NEUTRAL200}; */
`;

const MaximumReachedTextDiv = styled.div`
  background: ${theme.PRIMARY300};
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-top: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  height: 40px;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const EmptyStateText = styled(AppText)`
  font-size: 14px;
  margin-bottom: 23px;
`;

const EmptyStateDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

interface IOpacity {
  translucent?: boolean;
}

const TableText = styled(AppText)<IOpacity>`
  font-size: 13px;
  opacity: ${(props) => (props.translucent ? 0.5 : 1)};
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
`;

const TableSubheadingText = styled(AppText)<IOpacity>`
  font-weight: 600;
  width: 100%;
  height: 40px;
  padding-top: 11px;
  padding-left: 16px;
  opacity: ${(props) => (props.translucent ? 0.5 : 1)};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ButtonsRow = styled.div`
  display: flex;
`;

const EditButton = styled(AppButton)`
  box-sizing: border-box;
  height: 28px;
  width: 124px;
  border-radius: 2px;
  border: solid 2px ${theme.NEUTRAL100};
  font-size: 12px;
  font-weight: 500;
  margin-left: 10.5px;
  margin-right: 18px;
`;

const InvisibleButton = styled.button`
  height: 30px;
  width: 25px;
  border: none;
  vertical-align: bottom;
  background: transparent;
  :focus {
    border: none;
    outline: 0;
  }
`;

const TableHeadingText = styled(AppText)`
  font-weight: 600;
  padding: 11px 16px;
  width: 100%;
  height: 100%;
  vertical-align: center;
  background-color: ${theme.NEUTRAL200};
  border-right: 1px solid ${theme.NEUTRAL200};
  :last-child {
    border-right: none;
  }
`;

const OrderNumberText = styled(TableHeadingText)`
  margin-bottom: 0;
`;

const CampaignTable = styled.div`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  grid-template-columns: 1fr 1fr 1fr 120px;
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const SystemTable = styled(CampaignTable)`
  grid-template-columns: 1fr 120px;
`;

const IndustryTable = styled(CampaignTable)`
  grid-template-columns: 320px 1fr 120px;
`;

const CampaignDiv = styled.div`
  /* display: grid;
  grid-template-columns: 966px 130px;
  grid-template-rows: 40px;
  align-items: center; */
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-width: 1150px;
`;

const TableDataDiv = styled.div`
  max-height: 451px;
  min-height: 101px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

export { StepCustomFields };
