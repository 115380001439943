import styled from "styled-components";
import * as React from "react";

import { AppButton, AppErrorText, Loading } from "../../UI";
import { theme } from "../../../utils/theme";

import { Link, useLocation, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, gql } from "@apollo/client";

import { NotificationType, PERFORM_NOTIFICATION_ACTION } from "./HeaderNotifications";

import { iconAccept, iconDecline } from "../../../images/";
import { appToast } from "../../../utils/toast";
import { sanitizeURL } from "../../../utils/misc";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";
import { ModalContext } from "src/context";
import { useContext } from "react";

interface CreateButtonProps {
  item: NotificationType;
  refetch: any;
}

const CreateActionButtons: React.FC<CreateButtonProps> = ({ item, refetch }) => {
  const [performNotificationAction, { loading, error }] = useMutation(PERFORM_NOTIFICATION_ACTION, {
    async onCompleted({ performNotificationAction }) {
      console.log("performNotificationAction: ", performNotificationAction);
      await refetch();
      if (!performNotificationAction) {
        appToast("Error performing action. Something went wrong.");
        return;
      }
      appToast("Action performed created!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `performNotificationAction GraphQL Error: ${message}`,
      });
      console.log("Error in performNotificationAction: ", message);
    },
  });

  const history = useHistory();

  if (loading) return <Loading />;
  if (error) return <AppErrorText></AppErrorText>;

  switch (item?.type) {
    case "CallReportReviewed":
      return (
        <GoToCallButton
          color={theme.ATTENTION700}
          onClick={async () => {
            await performNotificationAction({
              variables: {
                notification_id: item?.id,
                relevant_id: item?.relevant_id,
                type: item?.type,
              },
            });

            const santizedURL = sanitizeURL(`/call-report/${item?.relevant_id}`);
            history.push(santizedURL);
          }}
        >
          Go to Call
        </GoToCallButton>
      );
    case "AddContactRequest":
    case "EditContactRequest":
    case "DeleteContactRequest":
      return (
        <GoToCallButton
          color={theme.ATTENTION700}
          onClick={async () => {
            await performNotificationAction({
              variables: {
                notification_id: item?.id,
                relevant_id: item?.relevant_id,
                type: item?.type,
              },
            });
          }}
        >
          Undo
        </GoToCallButton>
      );
    case "TimeOffRequest":
      return (
        <>
          <img
            src={iconAccept}
            height="17px"
            style={{ marginRight: "17px", verticalAlign: "bottom", cursor: "pointer" }}
            onClick={async () => {
              await performNotificationAction({
                variables: {
                  notification_id: item?.id,
                  relevant_id: item?.relevant_id,
                  type: item?.type,
                  accept: true,
                },
              });
            }}
          />
          <img
            src={iconDecline}
            height="20px"
            style={{ marginRight: "17px", verticalAlign: "bottom", cursor: "pointer" }}
            onClick={async () => {
              await performNotificationAction({
                variables: {
                  notification_id: item?.id,
                  relevant_id: item?.relevant_id,
                  type: item?.type,
                  accept: false,
                },
              });
            }}
          />
        </>
      );
    default:
      return <></>;
  }
};

const GoToCallButton = styled(AppButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 82px;
  height: 24px;

  background-color: ${theme.surface.neutral.primary};
  color: ${theme.text.brand.primary};

  border: 1px solid ${theme.border.brand.primary};
  border-radius: 4px;

  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
`;

export { CreateActionButtons };
