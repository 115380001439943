import React from "react";
import { AppText, FlexDiv } from "../../UI";
import { PhoenixIcon } from "../../UI/Phoenix/PhoenixIcon";
import { eye, eye_off } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";

interface EyeRendererProps {
  visible: boolean;
  onChange: (visible: boolean) => void;
  showText?: boolean;
  visibleText?: string;
  notVisibleText?: string;
  iconSize?: number;
  iconColor?: string;
  textSize?: number;
  textWeight?: number;
  textColor?: string;
  disabled?: boolean;
  height?: number;
  width?: number;
}

const PhoenixEyeToggle: React.FC<EyeRendererProps> = ({
  visible,
  onChange,
  showText = true,
  visibleText = "On",
  notVisibleText = "Off",
  iconSize = 16,
  iconColor = theme.PRIMARY600,
  textSize = 12,
  textWeight = 400,
  textColor = theme.BLACK_COLOR,
  disabled = false,
  height,
  width,
}) => {
  const svg = visible ? eye : eye_off;

  return (
    <FlexDiv gap={8} align="center" height={height} width={width}>
      <PhoenixIcon
        svg={svg}
        size={iconSize}
        color={!disabled ? iconColor : theme.NEUTRAL200}
        pointer={!disabled}
        disabled={disabled}
        onClick={() => {
          onChange(!visible);
        }}
      />
      {showText && (
        <AppText fontSize={textSize} fontWeight={textWeight} color={disabled ? theme.NEUTRAL200 : textColor}>
          {visible ? visibleText : notVisibleText}
        </AppText>
      )}
    </FlexDiv>
  );
};

export { PhoenixEyeToggle };
