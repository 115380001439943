import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { appToast } from "src/utils/toast";
import { CustomField } from "src/utils/misc";

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      type
      key
      value
      allow_reps_to_edit
      visible
    }
  }
`;

interface FetchCustomFieldsExpectedResponse {
  fetchCustomFields?: CustomField[];
}

const useCustomFields = () => {
  const { data: customFieldsData, loading } = useQuery<FetchCustomFieldsExpectedResponse>(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {},
    onError: (error) => {
      console.log("error fetching custom fields", error);
      appToast("Something went wrong.");
    },
  });

  return {
    data: customFieldsData?.fetchCustomFields ?? [],
    loading,
  };
};

export default useCustomFields;
