import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";

interface FavoritedLeadFilterContextState {
  leadFilter: FavoriteLeadFilterObject;
  setLeadFilter: Dispatch<SetStateAction<any>>;
  selected: any;
  setSelected: Dispatch<SetStateAction<any>>;
  resetLeadFavoriteFilters: () => void;

  expandFavorites: boolean;
  setExpandFavorites: Dispatch<SetStateAction<boolean>>;
  filterNum: boolean;
}

type FavoriteLeadFilterObject = any;

export const FavoritedLeadFilterContext = createContext<FavoritedLeadFilterContextState>(
  {} as FavoritedLeadFilterContextState,
);

const DEFAULT_STATE = {
  types: [] as string[],
  lead_sources: [] as string[],
  channels: [] as string[],
  industries: [] as string[],
  //cities: [] as string[],
  states: [] as string[],
  countries: [] as string[],
  timezones: [] as string[],
};

export const FavoritedLeadFilterProvider: FunctionComponent = ({ children }) => {
  const [expandFavorites, setExpandFavorites] = useState(true);

  const [leadFilter, setLeadFilter] = useState(
    JSON.parse(
      localStorage?.getItem("favoritedLeadFilter") ??
        `{
   "types": [],
   "lead_sources": [],
   "channels": [],
   "industries": [],
   "states": [],
   "countries": [],
   "timezones": []
}`,
    ) || DEFAULT_STATE,
  );

  const [selected, setSelected] = useState(
    JSON.parse(localStorage.getItem("favorited_selected_filters") || "[]") || [],
  );

  useEffect(() => {
    localStorage.setItem(
      "favoritedLeadFilter",
      JSON.stringify(leadFilter) ||
        `{
      "types": [],
      "lead_sources": [],
      "channels": [],
      "industries": [],
      "states": [],
      "countries": [],
      "timezones": []
  }`,
    );
  }, [leadFilter]);

  useEffect(() => {
    localStorage.setItem("favorited_selected_filters", JSON.stringify(selected) || "[]");
  }, [selected]);

  const resetLeadFavoriteFilters = () => {
    setSelected([]);
    setLeadFilter({
      types: [] as string[],
      lead_sources: [] as string[],
      channels: [] as string[],
      industries: [] as string[],
      //cities: [] as string[],
      states: [] as string[],
      countries: [] as string[],
      timezones: [] as string[],
    });
  };

  const filterNum =
    (leadFilter?.types?.length || 0) +
    (leadFilter?.lead_sources?.length || 0) +
    (leadFilter?.channels?.length || 0) +
    (leadFilter?.industries?.length || 0) +
    (leadFilter?.states?.length || 0) +
    (leadFilter?.countries?.length || 0) +
    (leadFilter?.timezones?.length || 0);

  const memoizedValue = useMemo(
    () => ({
      leadFilter,
      setLeadFilter,
      selected,
      setSelected,
      expandFavorites,
      setExpandFavorites,
      filterNum,
      resetLeadFavoriteFilters,
    }),
    [
      leadFilter,
      setLeadFilter,
      selected,
      setSelected,
      expandFavorites,
      setExpandFavorites,
      filterNum,
      resetLeadFavoriteFilters,
    ],
  );

  return <FavoritedLeadFilterContext.Provider value={memoizedValue}>{children}</FavoritedLeadFilterContext.Provider>;
};
