import React from "react";
import { useField } from "formik";
import { AppMultiSelect, AppMultiSelectProps } from "../UI";

const FormMultiSelectField = ({
  style,
  ...props
}: {
  name: string;
  style?: React.CSSProperties;
  onChange?: (e: any, values?: any) => void;
  value?: any;
  options?: any[];
  defaultValue?: string;
  isMulti?: boolean;
} & AppMultiSelectProps) => {
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <AppMultiSelect {...field} {...props} error={props.error || meta.error} />
    </div>
  );
};

export { FormMultiSelectField };
