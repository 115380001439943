import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import { AppText } from "./AppText";
import { theme } from "../../utils/theme";
import { useChannel } from "@ably-labs/react-hooks";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN, LOGGED_IN_USER } from "../../apollo/query";
import { loggedInUser } from "../../apollo/cache";
import { USER_STATUS } from "../../utils/variables";
import { getLocalStorage } from "../../utils/misc";
import { UserStatusContext } from "../../context";

// shows the reps status in the infoHeader(left nav bar)
const RepStatusMenuIcon = () => {
  const { userUIStatus, setUserUIStatus } = useContext(UserStatusContext);

  const { data: ablyLoggedInUser } = useQuery(LOGGED_IN_USER);

  const { data: ablyIsLoggedIn } = useQuery(IS_LOGGED_IN);

  useChannel(`${ablyLoggedInUser?.loggedInUser?.id}:liveUserStatus`, (payload) => {
    console.log("inbound from Ably: ", payload);

    const malformedUserId = !ablyLoggedInUser?.loggedInUser?.id;
    const notLoggedIn = !ablyIsLoggedIn?.isLoggedIn;

    if (malformedUserId || notLoggedIn) {
      console.log(
        "something went wrong with user status update, malformed user id or not logged in",
        malformedUserId,
        notLoggedIn,
      );
      return;
    }

    const currentStatus = payload?.data?.status;

    localStorage.setItem("userStatusLocal", JSON.stringify(currentStatus));

    if (currentStatus) {
      setUserUIStatus(currentStatus);
      return;
    }

    console.log("something went wrong with the rep status listener", payload?.data);
  });

  const renderStatus = (be_status: USER_STATUS) => {
    switch (be_status) {
      case "IDLE":
        return "Available";
      case "CALL":
        return "On a Call";
      case "DEMO":
        return "On a Call";
      case "OFFLINE":
        return "Offline";
      default:
        return "Available";
    }
  };

  useEffect(() => {
    const isOnCall = userUIStatus === "CALL" || userUIStatus === "DEMO";
    if (isOnCall) {
      localStorage.setItem("userIsOnCallLocal", JSON.stringify(isOnCall));
      window.dispatchEvent(new Event("storage"));
    }
  }, [userUIStatus]);

  return (
    <IconDiv status={userUIStatus}>
      <Icon>
        <IconText status={userUIStatus}>{renderStatus(userUIStatus)}</IconText>
      </Icon>
    </IconDiv>
  );
};

const IconDiv = styled.div<{ status: USER_STATUS }>`
  margin-top: 8px;
  padding: 4px 8px;
  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case "IDLE":
          return theme.SUCCESS400;
        case "CALL":
          return theme.WARNING500;
        case "DEMO":
          return theme.WARNING500;
        case "OFFLINE":
          return theme.NEUTRAL300;
        default:
          return theme.SUCCESS400;
      }
    }};

  background-color: ${({ status }) => {
    // Figma uses these custom opacity colors
    switch (status) {
      case "IDLE":
        return "rgba(68, 191, 139, 0.20)";
      case "CALL":
        return "rgba(255, 179, 56, 0.20)";
      case "DEMO":
        return "rgba(255, 179, 56, 0.20)";
      case "OFFLINE":
        return "rgba(166, 170, 188, 0.20)";
      default:
        return "rgba(68, 191, 139, 0.20)";
    }
  }};
  border-radius: 20px;
`;

const Icon = styled.div`
  min-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconText = styled(AppText)<{ status: USER_STATUS }>`
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  color: ${({ status }) => {
    switch (status) {
      case "IDLE":
        return theme.SUCCESS400;
      case "CALL":
        return theme.WARNING500;
      case "DEMO":
        return theme.WARNING500;
      case "OFFLINE":
        return theme.NEUTRAL300;
      default:
        return theme.SUCCESS400;
    }
  }};
`;

export { RepStatusMenuIcon };
