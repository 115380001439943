import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { CallContext } from "src/context/CallContext";
import { theme } from "src/utils/theme";
import styled from "styled-components";
import { call_quality_good, call_quality_fair, call_quality_poor } from "src/images/NewDesign";

const callColors = {
  good: theme.SUCCESS500,
  poor: theme.WARNING500,
  bad: theme.PILL_DARK_RED,
};

const networkWarningColors = {
  "high-rtt": callColors.poor,
  "low-mos": callColors.poor,
  "high-jitter": callColors.poor,
  "high-packet-loss": callColors.poor,
  "high-packets-lost-fraction": callColors.bad,
  "low-bytes-received": callColors.poor,
  "low-bytes-sent": callColors.poor,
  "ice-connectivity-lost": callColors.bad,
  "": callColors.good,
};

const networkWarningTooltips = {
  "high-rtt": "High Round Trip Time (RTT) detected. This may cause noticeable audio delays.",
  "low-mos": "Overall network conditions appear to be poor. This can affect call quality.",
  "high-jitter":
    "High jitter detected. This can result in audio quality problems on the call, such as crackling and choppy audio.",
  "high-packet-loss": "High packet loss detected. This can result in choppy audio or a dropped call.",
  "high-packets-lost-fraction": "Significant packet loss detected! This can result in choppy audio or a dropped call.",
  "low-bytes-received": "No data received for 3 seconds. This can affect call quality.",
  "low-bytes-sent": "No data sent for 3 seconds. This can affect call quality.",
  "ice-connectivity-lost": "Connection was lost.",
  "": "Connection appears stable.",
};

const returnIcon = (currentTwilioWarning: string) => {
  switch (currentTwilioWarning) {
    case "high-packets-lost-fraction":
    case "ice-connectivity-lost":
      return call_quality_poor;
    case "high-rtt":
    case "low-mos":
    case "high-jitter":
    case "high-packet-loss":
    case "low-bytes-received":
    case "low-bytes-sent":
      return call_quality_fair;
    default:
      return call_quality_good;
  }
};

export const TwilioWarnings = () => {
  const { currentTwilioWarning, setCurrentTwilioWarning } = useContext(CallContext);
  // const { setCurrentTwilioWarning } = useContext(CallContext);
  // const currentTwilioWarning = "";

  useEffect(() => {
    return () => {
      setCurrentTwilioWarning("");
    };
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentTwilioWarning]);

  return (
    <Container>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        id="twilio-warnings"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {dataTip}
          </span>
        )}
      />
      <Indicator
        data-for={"twilio-warnings"}
        data-tip={networkWarningTooltips[currentTwilioWarning as keyof typeof networkWarningTooltips]}
      >
        <img
          src={returnIcon(currentTwilioWarning)}
          alt="Call network quality indicator"
          style={{ width: 14, height: 14, cursor: "info" }}
        />
      </Indicator>
    </Container>
  );
};

const Indicator = styled.div`
  width: 16px;
  height: 16px;
`;

const Container = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// export default TwilioWarnings;
