import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { LeadFilterContext } from "src/context";
import { theme } from "src/utils/theme";
import { PhoenixIcon, PhoenixInput } from "src/Components/UI/Phoenix";
import { AppText, FlexDiv } from "src/Components/UI";
import { chevron_down, chevron_up, search, star_filled } from "src/images/NewDesign";
import { OptionItem } from "src/types";

import DynamicDropdown from "./DynamicDropdown";

type SavedViewDropdownProps = {
  savedViews: any[];
};

interface GroupedSavedView {
  label: string;
  options: OptionItem[];
}

const formatViewToOption = (view: any) => ({
  is_default: view.is_default,
  is_favorite: view.is_favorite,
  label: view.name,
  value: view.id,
});

const style = {
  backgroundColor: theme.NEUTRAL100,
  border: `1px solid ${theme.NEUTRAL100}`,
  width: "128px",
};

const GroupOption = ({
  group,
  isExpandable,
  isFavorite,
  onChange,
}: {
  group: GroupedSavedView;
  isExpandable?: boolean;
  isFavorite?: boolean;
  onChange: (value: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    if (!isExpandable) return;
    setIsOpen(!isOpen);
  };

  return (
    <GroupHeader key={group.label}>
      <FlexDiv
        gap={8}
        align="center"
        justify="space-between"
        onClick={toggleOpen}
        style={{ paddingBottom: isOpen ? "0" : "10px" }}
      >
        <FlexDiv align="center" gap={8}>
          {isFavorite ? (
            <PhoenixIcon color={theme.text.brand.primary} fillIcon={true} size={16} svg={star_filled} variant="brand" />
          ) : null}
          <AppText fontSize={12} color={theme.text.brand.primary}>
            {group.label}
          </AppText>
        </FlexDiv>

        {isExpandable ? (
          <PhoenixIcon svg={isOpen ? chevron_up : chevron_down} size={16} color={theme.icon.brand.default} />
        ) : null}
      </FlexDiv>

      {isOpen && (
        <FlexDiv direction="column">
          {group.options.map((option) => (
            <OptionWrapper
              align="center"
              gap={8}
              width="100%"
              onClick={() => onChange(option.value.toString())}
              key={option.value}
            >
              <TruncatedText fontSize={12} fontWeight={400}>
                {option?.label}
              </TruncatedText>
            </OptionWrapper>
          ))}
        </FlexDiv>
      )}
    </GroupHeader>
  );
};

const SavedViewDropdown = ({ savedViews }: SavedViewDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { savedViewID, setSavedViewID } = useContext(LeadFilterContext);

  const options = useMemo(
    () =>
      savedViews.map(formatViewToOption).filter((view) => view.label.toLowerCase().includes(searchQuery.toLowerCase())),
    [savedViews, searchQuery],
  );

  const groupedOptions = useMemo((): GroupedSavedView[] => {
    return [
      {
        label: "Default View",
        options: options.filter((view: any) => view.is_default) ?? [],
      },
      {
        label: "Favorited Views",
        options: options.filter((view: any) => view.is_favorite && !view.is_default) ?? [],
      },

      {
        label: "Saved Views",
        options: options.filter((view: any) => !view.is_default && !view.is_favorite) ?? [],
      },
    ];
  }, [options]);

  const selectedOption = options.find((o) => o.value === savedViewID) ?? null;

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const onChange = useCallback((value: string) => {
    setSavedViewID(value);
    setIsOpen(false);
  }, []);

  const handleIsOpenChange = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setSearchQuery("");
    }
    setIsOpen(isOpen);
  }, []);

  return (
    <DynamicDropdown
      headerStyle={style}
      isOpen={isOpen}
      label={selectedOption?.label ?? "Select a View"}
      menuWidth="300px"
      onClear={() => setSavedViewID(null)}
      position="right"
      renderFooter={() => null}
      renderHeader={null}
      setIsOpen={handleIsOpenChange}
    >
      <PhoenixInput
        placeholder="Search"
        insideLeftElementOverride={
          <PhoenixIcon svg={search} size={16} color={theme.icon.brand.default} hoverColor={theme.icon.brand.default} />
        }
        onChange={handleSearchChange}
        value={searchQuery}
        displayNoContextText
      />
      <FlexDiv gap={0} direction="column">
        {groupedOptions.map((group) => {
          if (!group.options.length) return null;
          const isFavorite = group.label === "Favorited Views";
          return <GroupOption group={group} isExpandable={isFavorite} onChange={onChange} isFavorite={isFavorite} />;
        })}
      </FlexDiv>
    </DynamicDropdown>
  );
};

export default SavedViewDropdown;

const GroupHeader = styled(FlexDiv)`
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 0 0;
`;

const TruncatedText = styled(AppText)`
  display: block;
  overflow: hidden;
  padding: 10px 0;
  text-overflow: ellipsis;
  transition: color 0.2s ease-in-out;
  white-space: nowrap;
  width: 100%;
`;

const OptionWrapper = styled(FlexDiv)`
  cursor: pointer;

  &:hover {
    ${TruncatedText} {
      color: ${theme.text.brand.primary};
    }
  }
`;
