import styled from "styled-components";
import * as React from "react";
import { AppErrorText, AppInput, AppText, Loading, AppModalPopup } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import { useContext, useState } from "react";
import {
  iconButtonArrowGray,
  iconNewMoveRow,
  newCloseModal,
  searchIcon,
  showMoreArrow,
  tempIconUpDown,
} from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import { GridFilterContext, ModalContext, LeadFilterContext } from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useMutation, useQuery } from "@apollo/client";
import { countryCodes } from "../../static/countries";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { OutsideEvent } from "../Dumb";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField } from "../Field";

// Queries
const SAVE_CALL_REPORT_SELECTED_COLUMNS = gql`
  mutation saveCallReportEventSelectedColumns($selected_columns: [String!]!) {
    saveCallReportEventSelectedColumns(selected_columns: $selected_columns) {
      id
      visible_call_report_event_columns_computed
    }
  }
`;

const FETCH_USER_CALL_EVENT_REPORT_COLUMNS = gql`
  query fetchUserCallReportColumns {
    fetchUser {
      id
      visible_call_report_event_columns_computed
    }
  }
`;

interface MetricColumn {
  metric_label: string;
  metric: string;
  fixed: boolean;
}

interface ManageTableEventColumnsModalProps {
  dataAllColumns: MetricColumn[];
}

const searchboxSchema = Yup.object().shape({
  available_fields_search_event: Yup.string().notRequired(),
});

interface MyFormikProps {
  available_fields_search_event: string;
}

const ManageTableEventColumnsModal: React.FC<ManageTableEventColumnsModalProps> = ({ dataAllColumns }) => {
  // const [searchTerm, setSearchTerm] = useState("");

  const [selectedAvailableFields, setSelectedAvailableFields] = useState([] as string[]);
  const [selectedVisibleFields, setSelectedVisibleFields] = useState([] as string[]);
  const [tempSelected, setTempSelected] = useState([] as string[]);

  const { tableModal, setTableModal } = useContext(GridFilterContext);

  const { data: dataColumns, loading: loadingColumns, error: errorColumns } = useQuery(
    FETCH_USER_CALL_EVENT_REPORT_COLUMNS,
    {
      fetchPolicy: "network-only",
      onError({ message }) {
        console.log(`Error in fetchUser for Call Report Columns: `, message);
        appToast(message);
      },
      onCompleted() {
        setTempSelected(dataColumns?.fetchUser?.visible_call_report_event_columns_computed);
      },
    },
  );

  const [saveCallReportSelectedColumns, { loading: loadingSelectedColumns, error: errorSelectedColumns }] = useMutation(
    SAVE_CALL_REPORT_SELECTED_COLUMNS,
    {
      onCompleted({ saveCallReportEventSelectedColumns }) {
        if (!saveCallReportEventSelectedColumns) {
          appToast("Error saving column choices");
          return;
        }
        appToast("Visible columns changed successfully");
        setTableModal(!tableModal);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `saveCallReportSelectedColumns GraphQL Error: ${message}`,
        });
        console.log("Error in saveCallReportSelectedColumns: ", message);
      },
      refetchQueries: ["fetchUserCallReportEventColumns"],
    },
  );

  const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setTempSelected(result);
  };

  const onDragEndReps = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderReps(tempSelected, result.source.index, result.destination.index);
  };

  return (
    <AppModalPopup blinds={tableModal} setBlinds={setTableModal} unbordered width={786} height={490}>
      <Formik
        initialValues={{
          available_fields_search_event: "",
        }}
        validationSchema={searchboxSchema}
        onSubmit={async () => {
          console.log("selected columns: ", tempSelected);
          await saveCallReportSelectedColumns({
            variables: {
              selected_columns: tempSelected,
            },
          });
        }}
      >
        {({ values, submitForm, isSubmitting }: FormikProps<MyFormikProps>) => {
          return (
            <EditCardDiv>
              <TitleDiv>
                <TitleText>Manage Columns</TitleText>
                <CloseButton>
                  <NewAppButton
                    onClick={() => {
                      setTableModal(!tableModal);
                    }}
                  >
                    <img src={newCloseModal} alt="Close" />
                  </NewAppButton>
                </CloseButton>
              </TitleDiv>
              <ScrollDiv>
                <RepListsGrid>
                  <SelectRepsContainer>
                    <AppText>Available fields</AppText>
                    <RepsListContainer>
                      <SearchRepsInput name="available_fields_search_event" placeholder="Search Fields" />
                      <RepsScrollDiv>
                        {!!loadingColumns ? (
                          <Loading />
                        ) : errorColumns ? (
                          <AppErrorText>Error loading reps</AppErrorText>
                        ) : (
                          !!dataColumns &&
                          dataAllColumns
                            .slice()
                            .filter(
                              (item: any) =>
                                !values.available_fields_search_event ||
                                `${item.metric_label}`
                                  .toLowerCase()
                                  .includes(values.available_fields_search_event.toLowerCase()),
                            )
                            .filter((item) => !tempSelected.includes(item.metric))
                            ?.map((item: any) => (
                              <AppCheckbox
                                title={item.metric_label}
                                checked={selectedAvailableFields.includes(item.metric)}
                                onClick={() => {
                                  selectedAvailableFields.includes(item.metric)
                                    ? setSelectedAvailableFields(
                                        selectedAvailableFields.slice().filter((li: string) => li !== item.metric),
                                      )
                                    : setSelectedAvailableFields([...selectedAvailableFields, item.metric]);
                                }}
                              />
                            ))
                        )}
                      </RepsScrollDiv>
                    </RepsListContainer>
                  </SelectRepsContainer>
                  <FlexColDiv>
                    <IconButton
                      style={{ marginBottom: "8px" }}
                      onClick={() => {
                        setTempSelected([...tempSelected, ...selectedAvailableFields]);
                        setSelectedAvailableFields([]);
                      }}
                    >
                      <img src={iconButtonArrowGray} alt="Add selected reps as assigned" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setTempSelected(tempSelected.slice().filter((item) => !selectedVisibleFields.includes(item)));
                        setSelectedVisibleFields([]);
                      }}
                    >
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={iconButtonArrowGray}
                        alt="Remove selected reps from assigned"
                      />
                    </IconButton>
                  </FlexColDiv>

                  <SelectRepsContainer>
                    <SpaceBetweenDiv>
                      <AppText>Visible fields</AppText>
                    </SpaceBetweenDiv>
                    <RepsListContainer>
                      <DragDropContext onDragEnd={onDragEndReps}>
                        <Droppable droppableId="droppablecolfields">
                          {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <DragElements>
                                {loadingColumns ? (
                                  <Loading />
                                ) : errorColumns ? (
                                  <AppErrorText>Error loading reps</AppErrorText>
                                ) : (
                                  !!dataColumns && (
                                    <>
                                      {tempSelected?.map((metric, index) => {
                                        const item = dataAllColumns.find((col) => col.metric === metric);
                                        return item ? (
                                          <Draggable key={item.metric} draggableId={item.metric} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <RepDraggableDiv>
                                                  <img
                                                    style={{ marginRight: "8px", marginTop: "6px" }}
                                                    src={iconNewMoveRow}
                                                    height="12px"
                                                    alt="Drag me"
                                                  />
                                                  <AppCheckbox
                                                    disabled={item.fixed}
                                                    title={item.metric_label}
                                                    checked={selectedVisibleFields.includes(item.metric)}
                                                    onClick={() =>
                                                      selectedVisibleFields.includes(item.metric)
                                                        ? setSelectedVisibleFields(
                                                            selectedVisibleFields
                                                              .slice()
                                                              .filter((li: string) => li !== item.metric),
                                                          )
                                                        : setSelectedVisibleFields([
                                                            ...selectedVisibleFields,
                                                            item.metric,
                                                          ])
                                                    }
                                                  />
                                                </RepDraggableDiv>
                                              </div>
                                            )}
                                          </Draggable>
                                        ) : null;
                                      })}
                                    </>
                                  )
                                )}
                              </DragElements>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </RepsListContainer>
                  </SelectRepsContainer>
                </RepListsGrid>
              </ScrollDiv>

              <SubmitDiv>
                <ManageButtons
                  onClick={() => {
                    setTableModal(!tableModal);
                  }}
                >
                  Cancel
                </ManageButtons>
                <ManageButtons
                  variant={"primary"}
                  onClick={submitForm}
                  disabled={
                    isSubmitting ||
                    loadingSelectedColumns ||
                    JSON.stringify(dataColumns?.fetchUser?.visible_call_report_event_columns_computed) ===
                      JSON.stringify(tempSelected)
                  }
                >
                  Save
                </ManageButtons>
              </SubmitDiv>
            </EditCardDiv>
          );
        }}
      </Formik>
    </AppModalPopup>
  );
};

const DisabledDiv = styled.div`
  padding-left: 20px;
  /* margin-bottom: 12px; */
  display: flex;
  align-items: center;
  height: 24px;
`;

const DisabledOptionText = styled(AppText)`
  opacity: 0.6;
`;

const MaxError = styled(AppText)`
  color: ${theme.ATTENTION700};
  margin-top: 8px;
  font-size: 10px;
`;

const ContainerDivSort = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RepDraggableDiv = styled.div`
  display: flex;
`;

const DragElements = styled.div`
  max-height: 208px;
  min-height: 208px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const IconButton = styled(NewAppButton)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-gap: 16px;
  margin: 24px 30px;
`;

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RepsScrollDiv = styled.div`
  max-height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchRepsInput = styled(InputField)`
  background-color: ${theme.NEUTRAL100};
  margin: 0px;
  /* margin-top: 16px; */
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

const RepsListContainer = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  height: 240px;
  overflow: hidden;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 12px;
  border-radius: 2px;
`;

const SelectRepsContainer = styled.div`
  width: 330px;
`;

const ManageButtons = styled(NewAppButton)`
  width: 186px;
  height: 48px;
`;

const ScrollDiv = styled.div`
  max-height: 434px;
  height: 434px;
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 0px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 24px 32px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

export { ManageTableEventColumnsModal };
