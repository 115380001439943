import styled from "styled-components";
import * as React from "react";
import { NewAppButton, AppErrorText, AppSidebarCard } from "../UI";

interface EventItemProps {
  title: string;
  refetch: () => void;
  error: string;
}

const LeadCardError: React.FC<EventItemProps> = ({ title, refetch, error }) => {
  return (
    <AppSidebarCard showExpand title={title}>
      <PaddingDiv>
        <AppErrorText>{error}</AppErrorText>
        <NewAppButton variant={"primary"} onClick={refetch}>
          Reload
        </NewAppButton>
      </PaddingDiv>
    </AppSidebarCard>
  );
};

const PaddingDiv = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export { LeadCardError };
