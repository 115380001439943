import styled, { css } from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "./AppText";
import * as React from "react";
import { FiPhoneCall, FiMail } from "react-icons/fi";

interface AppModalPopupProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  priorityPopup?: boolean;
  setPriorityPopup?: (blinds: boolean) => void;
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number;
  /**
   * height of button. Default value is 50.
   */
  height?: number;
  secondary?: boolean;
  cancel?: boolean;
  textcolor?: string;
  unbordered?: boolean;
  stickBottom?: boolean;
  top?: number;

  animate?: boolean;
}

export const AppModalPopup = ({
  blinds,
  setBlinds,
  priorityPopup,
  setPriorityPopup,
  top,
  title,
  width,
  height,
  color,
  unbordered,
  textcolor,
  children,
  stickBottom,
  animate,
  ...props
}: AppModalPopupProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <>
      {blinds && !priorityPopup && (
        <Main
          onClick={() => {
            setBlinds(false);
          }}
        >
          <ModalContainer>
            <PopupModal
              unbordered={unbordered}
              width={width}
              height={height}
              top={top}
              stickBottom={stickBottom}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              animate={animate}
            >
              {children}
            </PopupModal>
          </ModalContainer>
        </Main>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: relative;
  display: block;
  min-width: 100%;
  min-height: 100%;
`;

const Main = styled.div`
  display: block;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(22, 21, 26, 0.5);
  z-index: 12;
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
  height?: number;
  top?: number;
  unbordered?: boolean;
  animate?: boolean;
}

const PopupModal = styled.div<ModalProps>`
  position: absolute;
  bottom: ${(props) => (props.stickBottom ? 0 : "none")};
  left: ${(props) => (!!props.width ? `calc((100vw - ${props.width}px) / 2)` : "calc((100vw - 453px) / 2)")};
  top: ${(props) =>
    !!props.top ? `${props.top}px` : !!props.height ? `calc((100vh - ${props.height}px) / 2)` : "104px"};
  width: ${(props) => (!!props.width ? `${props.width}px` : "453px")};
  border-radius: 8px;
  height: auto;
  background: ${theme.WHITE_COLOR};
  border: ${(props) => (props.unbordered ? "none" : `4px solid ${theme.NEUTRAL100}`)};
  z-index: 3;

  ${(props) =>
    props.animate &&
    css`
      animation: ${theme.lightPopup} 0.2s ease-in-out;
    `}
`;

AppModalPopup.defaultProps = {
  secondary: false,
  unbordered: false,
  stickBottom: false,
};
