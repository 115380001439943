import React from "react";
import { useField } from "formik";
import { AppSelect, AppSelectProps } from "../UI";

const FormSelectField = ({
  style,
  ...props
}: {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  currency?: boolean;
  width?: number;
  fontSize?: number;
  // added later:
  onChange?: (e: any) => void;
  value?: string | number;
  options?: any;
  customArrowLocation?: number;
  defaultValue?: string;
  id?: string;
} & AppSelectProps) => {
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <AppSelect {...field} {...props} error={meta.touched && meta.error} />
    </div>
  );
};

export { FormSelectField };
