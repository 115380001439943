import React, { useContext, useMemo, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Switch from "react-switch";
import { v4 as uuidv4 } from "uuid";
import { components } from "react-select";
import { info, plus } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";
import { FONT_OPTIONS } from "src/utils/variables";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { PhoenixInputField } from "src/Components/Field/Phoenix";
import { ColorField } from "src/Components/Segments/Onboarding/widget/CustomizeWidget";
import { AppErrorText, AppText, FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import styled, { keyframes } from "styled-components";
import { CustomField, FormField, formatFormFields } from "src/utils/misc";
import { FormExpectedResponse, OPSIQFormsFormikProps } from "./OPSIQFormDetail";

import useAvailableFields from "src/hooks/useAvailableFields";
import { Field } from "src/Components/Segments/FormsFieldList/Field";
import { ToggleIBCModal } from "src/Components/modal/OPSIQForms/ToggleIBCModal";
import { ModalContext } from "src/context";
import { appToast } from "src/utils/toast";

const FETCH_ALL_LEAD_SOURCES = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const FETCH_All_EMAIL_TEMPLATES = gql`
  query fetchTemplates($TemplateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $TemplateFilter) {
      id
      name
    }
  }
`;

const TOGGLE_IBC = gql`
  mutation CreateOrUpdateOPSIQForm($opsiqFormInput: OpsiqFormInput!) {
    createOrUpdateOPSIQForm(OpsiqFormInput: $opsiqFormInput) {
      id
    }
  }
`;

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      admins_managers {
        id
        full_name
        email
      }
      Reps {
        id
        full_name
        email
      }
    }
  }
`;

const MAX_FONT_SIZE = 24;
const MIN_FONT_SIZE = 10;

const buttonAlignmentOptions = [
  { label: "Left", value: "Left" },
  { label: "Center", value: "Center" },
  { label: "Right", value: "Right" },
];

interface IOPSIQFormSettings {
  allCustomFields: CustomField[];
  allSystemFields: string[];
  errors?: Record<string, any>;
  formID?: string;
  formName?: string;
  loading: boolean;
  setFieldValue: (field: keyof OPSIQFormsFormikProps, value: any, shouldValidate?: boolean) => void;
  setSelectedFieldID: (id: string, type: "normal" | "hidden" | "referrer") => void;
  values: OPSIQFormsFormikProps;
}

export const OPSIQFormSettings = ({
  formID,
  errors,
  formName,
  values,
  setFieldValue,
  loading,
  allCustomFields,
  allSystemFields,
  setSelectedFieldID,
}: IOPSIQFormSettings) => {
  const { setShowToggleIBCModal } = useContext(ModalContext);
  const leadSettingsRef = useRef<HTMLDivElement>(null);
  // Lead source options
  const { data: leadsourceData, loading: leadsourceLoading, error: leadsourceError } = useQuery(
    FETCH_ALL_LEAD_SOURCES,
    {
      fetchPolicy: "cache-and-network",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { availableCustomFields, availableSystemFields } = useAvailableFields({
    customFields: allCustomFields,
    systemFields: allSystemFields,
    values,
  });

  const customFieldOptions = [...availableCustomFields, ...(values.referrer_fields?.map((f) => f.custom_field) ?? [])]
    .filter((x) => Boolean(x?.key))
    .sort((a, b) => a?.key?.localeCompare(b?.key || "") || 0)
    ?.map((field) => ({
      label: field?.key,
      value: field?.id,
    }));

  // email template options
  const { data: emailTemplateData, loading: emailTemplateLoading, error: emailTemplateError } = useQuery(
    FETCH_All_EMAIL_TEMPLATES,
    {
      variables: { TemplateFilter: { type: "EMAIL" } },
      fetchPolicy: "cache-and-network",
    },
  );

  const { data: orgData } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const ADMIN_EMAILS =
    orgData?.fetchOrganization?.admins_managers?.map((item: any) => ({
      label: `${item.email} (${item.full_name})`,
      value: item.email,
    })) ?? [];

  const REPS_EMAILS =
    orgData?.fetchOrganization?.Reps?.map((item: any) => ({
      label: `${item.email} (${item.full_name})`,
      value: item.email,
    })) ?? [];

  const EMAIL_LIST_OPTIONS = [...ADMIN_EMAILS, ...REPS_EMAILS].sort((a, b) => a.label.localeCompare(b.label));

  const [toggleIBC] = useMutation(TOGGLE_IBC, {
    onError: (error) => {
      appToast("Something went wrong");
      console.log("createOrUpdateOPSIQForm error", error);
    },
    refetchQueries: ["FetchOPSIQForm"],
  });

  const EMAIL_TEMPLATE_OPTIONS = emailTemplateData?.fetchTemplates?.map((t: any) => ({
    label: t.name,
    value: t.id,
  }));

  const leadSourceOptions =
    leadsourceData?.fetchLeadSourceOptions?.map((ls: any) => ({
      label: ls.label,
      value: ls.label,
    })) ?? [];

  const scrollToEndOf = (ref: React.RefObject<HTMLDivElement>) => {
    setTimeout(() => {
      ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: "smooth" });
    }, 100);
  };

  const settingsErrror = leadsourceError || emailTemplateError;

  const settingsLoading = loading || leadsourceLoading || emailTemplateLoading;

  const onToggleIBC = async () => {
    setFieldValue("is_IBC", !values.is_IBC);

    await toggleIBC({
      variables: {
        opsiqFormInput: {
          opsiq_form_id: formID,
          form_name: formName,
          is_IBC: !values.is_IBC,
        },
      },
    });
  };

  return (
    <Main>
      {settingsErrror ? (
        <AppErrorText>Something went wrong.</AppErrorText>
      ) : settingsLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <PhoenixStyledTooltip id="form-settings-tooltip" />
          <FormSettingSection>
            <SectionTitle title="Use with Inbound Concierge" />
            <FlexDiv gap={2} direction="column">
              <Switch
                onColor={theme.PRIMARY500}
                height={16}
                width={32}
                checkedIcon={false}
                uncheckedIcon={false}
                handleDiameter={12}
                checked={!!values.is_IBC}
                onChange={() => setShowToggleIBCModal(true)}
              />
            </FlexDiv>
          </FormSettingSection>
          <FormSettingSection maxHeight={476} ref={leadSettingsRef}>
            <SectionTitle
              title="Hidden Fields"
              tooltip="Fields are not visible to prospects, but will be recorded in Sellfire upon form submision."
            />
            <FlexDiv gap={8} direction="column">
              <PhoenixMultiSelect
                name="lead-source"
                required
                isMulti={false}
                isClearable={false}
                titleText="Select Lead Source"
                menuPosition="fixed"
                menuShouldBlockScroll
                options={leadSourceOptions}
                value={
                  leadSourceOptions?.find((t: OptionItem) => t.value === values.form_lead_source?.hidden_value) ?? ""
                }
                onChange={(selected: OptionItem) =>
                  setFieldValue("form_lead_source", {
                    ...values.form_lead_source,
                    custom_field_id: null,
                    is_referrer: false,
                    hidden_field: true,
                    hidden_value: selected.value,
                    system_field: "LEAD_SOURCE",
                  })
                }
                error={errors?.form_lead_source}
                marginBottom={false}
              />
              <AppText fontSize={10} fontWeight={500} color={theme.text.neutral.secondary}>
                The selected lead source will be tied to all leads who complete this form
              </AppText>
            </FlexDiv>
            {/* BE not implemented yet update once implemented */}
            <LeadSettingsFieldDiv
              allCustomFields={allCustomFields}
              allSystemFields={allSystemFields}
              availableCustomFields={availableCustomFields}
              availableSystemFields={availableSystemFields}
              scrollToEnd={() => scrollToEndOf(leadSettingsRef)}
              setFieldValue={setFieldValue}
              setSelectedFieldID={(v) => setSelectedFieldID(v, "hidden")}
              values={values}
            />
          </FormSettingSection>
          <FormSettingSection maxHeight={300}>
            <SectionTitle title="Form Referral Settings" />
            <AppText fontSize={12} fontWeight={400}>
              If you would like to save what site referred the lead to this form, choose a custom field to assign it to.
            </AppText>
            <FlexDiv direction="column" gap={8}>
              <FieldText>Select Field</FieldText>
              <FlexDiv align="center" gap={8} height={"100%"}>
                <PhoenixMultiSelect
                  name="field-referral"
                  isMulti={true}
                  isClearable={false}
                  isDisabled={false}
                  options={customFieldOptions}
                  value={values?.referrer_fields?.map(
                    (field) => customFieldOptions?.find((f) => f.value === field.custom_field_id) ?? "",
                  )}
                  onChange={(selected: OptionItem[]) => {
                    const removedFields = values.referrer_fields?.filter(
                      (field) => !selected?.map((f) => f.value).includes(field.custom_field_id ?? ""),
                    );

                    if (removedFields?.length) {
                      removedFields?.forEach((field) => {
                        if (field.id) {
                          setSelectedFieldID(field.id, "referrer");
                        }
                      });
                    } else {
                      const updatedFields = selected?.map((f: OptionItem) => {
                        const existingField = values.referrer_fields?.find(
                          (field) => field.custom_field_id === f.value,
                        ) as (FormField & { custom_id: boolean }) | undefined;
                        const customField = allCustomFields?.find((cf) => cf.id === f.value);
                        return {
                          id: existingField?.id ?? uuidv4(),
                          custom_field: customField,
                          custom_field_id: f.value,
                          custom_id: existingField?.id ? existingField?.custom_id : true,
                          is_referrer: true,
                          hidden_field: true,
                          hidden_value: null,
                          system_field: null,
                        };
                      });
                      setFieldValue("referrer_fields", updatedFields);
                    }
                  }}
                  marginBottom={false}
                  width={352}
                  menuPortal
                  maxMenuHeight={250}
                  menuPosition="fixed"
                  menuShouldBlockScroll
                />
                <PhoenixIcon
                  svg={info}
                  size={16}
                  variant="brand"
                  data-tip="Only visible fields will be displayed."
                  data-for="form-settings-tooltip"
                />
              </FlexDiv>
            </FlexDiv>
          </FormSettingSection>
          <FormSettingSection>
            <SectionTitle
              title="Email Verification"
              tooltip="Rep Tokens are not currently supported for email verification"
            />
            <AppText fontSize={12} fontWeight={400}>
              Send a confirmation email to the lead when the form is completed
            </AppText>
            <FlexDiv gap={4} align="center">
              <PhoenixCheckbox
                checked={!!values.send_email_verification}
                onChange={() => {
                  const newValue = !values.send_email_verification;
                  setFieldValue("send_email_verification", newValue);
                  if (!newValue) {
                    setFieldValue("template_id", undefined, false);
                  }
                }}
              />
              <AppText fontSize={12} fontWeight={400}>
                Send Email Verification
              </AppText>
            </FlexDiv>
            <PhoenixMultiSelect
              name="email-verification-template"
              required
              isMulti={false}
              titleText="Email Template"
              isDisabled={!values.send_email_verification}
              marginBottom={false}
              menuPortal
              options={EMAIL_TEMPLATE_OPTIONS}
              value={EMAIL_TEMPLATE_OPTIONS?.find((t: OptionItem) => t.value === values.template_id || "") ?? ""}
              onChange={(selected: OptionItem) => {
                setFieldValue("template_id", selected?.value ?? "");
              }}
              menuPosition="fixed"
              error={errors?.template_id}
              menuShouldBlockScroll
            />
          </FormSettingSection>
          <FormSettingSection>
            <SectionTitle title="Submission Email" />
            <AppText fontSize={12} fontWeight={400}>
              Send submission email to the selected email address when the form is submitted
            </AppText>
            <FlexDiv gap={4} align="center">
              <PhoenixCheckbox
                checked={!!values.send_form_submission_email}
                onChange={() => {
                  const newValue = !values.send_form_submission_email;
                  setFieldValue("send_form_submission_email", newValue);
                  if (!newValue) {
                    setFieldValue("form_submission_email_list", [], false);
                  }
                }}
              />
              <AppText fontSize={12} fontWeight={400}>
                Send form submission email
              </AppText>
            </FlexDiv>
            <PhoenixMultiSelect
              name="form-submission-email-list"
              required
              creatableOptions
              isMulti={true}
              titleText="Email List"
              isDisabled={!values.send_form_submission_email}
              marginBottom={false}
              menuPortal
              options={EMAIL_LIST_OPTIONS}
              value={
                values.form_submission_email_list?.map(
                  (e) => EMAIL_LIST_OPTIONS?.find((o: any) => o.value === e) ?? { label: e, value: e },
                ) ?? []
              }
              onChange={(selected: OptionItem[]) => {
                setFieldValue("form_submission_email_list", selected?.map((s) => s.value) ?? []);
              }}
              menuPosition="fixed"
              error={errors?.form_submission_email_list}
              menuShouldBlockScroll
            />
          </FormSettingSection>
          <FormSettingSection>
            <SectionTitle title="Form Fields" />
            <FontDropdown
              name="label-font"
              marginBottom={false}
              titleText="Label Font"
              value={values.form_field_label_font}
              onChange={(selected: OptionItem) => setFieldValue("form_field_label_font", selected.value)}
            />
            <FlexDiv gap={40} align="flex-start" style={{ marginBottom: -12 }}>
              <PhoenixInputField
                name="form_field_label_text_size"
                type="number"
                titleText="Label Text Size"
                showNumberArrows
                label="Label Text Size"
                inputValueType="number"
                handleNumberArrowDownClick={() =>
                  setFieldValue(
                    "form_field_label_text_size",
                    Math.max(values.form_field_label_text_size - 1, MIN_FONT_SIZE),
                  )
                }
                handleNumberArrowUpClick={() =>
                  setFieldValue(
                    "form_field_label_text_size",
                    Math.min(values.form_field_label_text_size + 1, MAX_FONT_SIZE),
                  )
                }
                width={100}
              />
              <FlexDiv gap={8} align="flex-end">
                <PhoenixInputField
                  name="form_field_label_color"
                  titleText="Label Color"
                  displayNoContextText
                  width={120}
                />
                <ColorField name="form_field_label_color" type="color" width={40} height={40} />
              </FlexDiv>
            </FlexDiv>
            <FontDropdown
              name="formEntryFont"
              titleText="Form Entry Font"
              marginBottom={false}
              value={values.form_field_text_font}
              onChange={(selected: OptionItem) => setFieldValue("form_field_text_font", selected.value)}
            />
            <FlexDiv gap={40} align="flex-start" style={{ marginBottom: -12 }}>
              <PhoenixInputField
                name="form_field_text_size"
                type="number"
                titleText="Form Text Size"
                showNumberArrows
                inputValueType="number"
                handleNumberArrowDownClick={() =>
                  setFieldValue("form_field_text_size", Math.max(values.form_field_text_size - 1, MIN_FONT_SIZE))
                }
                handleNumberArrowUpClick={() =>
                  setFieldValue("form_field_text_size", Math.min(values.form_field_text_size + 1, MAX_FONT_SIZE))
                }
              />
              <FlexDiv gap={8} align="flex-end">
                <PhoenixInputField
                  name="form_field_text_color"
                  titleText="Text Color"
                  displayNoContextText
                  width={120}
                />
                <ColorField name="form_field_text_color" type="color" displayNoContextText width={40} height={40} />
              </FlexDiv>
            </FlexDiv>
          </FormSettingSection>
          <FormSettingSection>
            <SectionTitle title="Buttons" />
            <FlexDiv gap={2} align="flex-start" style={{ marginBottom: -14 }}>
              <PhoenixInputField titleText="Button Content" name="button_content" />
            </FlexDiv>
            <FontDropdown
              name="formButtonFont"
              titleText="Button Text Font"
              value={values.button_font}
              onChange={(selected: OptionItem) => setFieldValue("button_font", selected.value)}
              marginBottom={false}
            />

            <PhoenixMultiSelect
              name="button-alignment"
              isMulti={false}
              titleText="Button alignment"
              marginBottom={false}
              menuPortal
              options={buttonAlignmentOptions}
              value={buttonAlignmentOptions?.find((t: OptionItem) => t.value === values.button_alignment || "")}
              onChange={(selected: OptionItem) => {
                setFieldValue("button_alignment", selected?.value ?? "");
              }}
              menuPosition="fixed"
              error={errors?.button_alignment}
              menuShouldBlockScroll
              isClearable={false}
            />

            <FlexDiv gap={40} align="flex-start" style={{ marginBottom: -12 }}>
              <PhoenixInputField
                name="button_text_size"
                type="number"
                titleText="Button Text Size"
                showNumberArrows
                inputValueType="number"
                handleNumberArrowDownClick={() =>
                  setFieldValue("button_text_size", Math.max(values.button_text_size - 1, MIN_FONT_SIZE))
                }
                handleNumberArrowUpClick={() =>
                  setFieldValue("button_text_size", Math.min(values.button_text_size + 1, MAX_FONT_SIZE))
                }
                width={100}
              />
              <FlexDiv gap={8} align="flex-end">
                <PhoenixInputField
                  name="button_text_color"
                  titleText="Button Text Color"
                  displayNoContextText
                  width={120}
                />
                <ColorField name="button_text_color" type="color" width={40} height={40} />
              </FlexDiv>
            </FlexDiv>
            <FlexDiv gap={8} align="flex-end">
              <PhoenixInputField
                name="button_fill_color"
                titleText="Button Fill Color"
                displayNoContextText
                width={120}
              />
              <ColorField name="button_fill_color" type="color" width={40} height={40} />
            </FlexDiv>
            <FlexDiv gap={8}>
              <PhoenixCheckbox
                checked={values.show_button_border}
                onChange={() => setFieldValue("show_button_border", !values.show_button_border)}
              />
              <AppText fontSize={12} fontWeight={500}>
                Show Button Border
              </AppText>
            </FlexDiv>
            {values.show_button_border && (
              <FlexDiv gap={8} align="flex-end">
                <PhoenixInputField
                  name="button_border_color"
                  titleText="Button Border Color"
                  displayNoContextText
                  width={120}
                />
                <ColorField name="button_border_color" type="color" width={40} height={40} />
              </FlexDiv>
            )}
            <PhoenixInputField
              width={120}
              name="button_corner_radius"
              showNumberArrows
              inputValueType="number"
              handleNumberArrowDownClick={() =>
                setFieldValue("button_corner_radius", Math.max(values.button_corner_radius - 1, 0))
              }
              handleNumberArrowUpClick={() =>
                setFieldValue("button_corner_radius", Math.min(values.button_corner_radius + 1, MAX_FONT_SIZE))
              }
              titleText="Button Corner Radius"
            />
          </FormSettingSection>
          <FormSettingSection>
            <SectionTitle title="Form Container" />
            <FlexDiv gap={8} align="flex-end">
              <PhoenixInputField
                name="form_background_color"
                titleText="Form Background Color"
                displayNoContextText
                width={120}
              />
              <ColorField name="form_background_color" type="color" width={40} height={40} />
            </FlexDiv>
            <FlexDiv gap={8}>
              <PhoenixCheckbox
                checked={values.show_form_border}
                onChange={() => setFieldValue("show_form_border", !values.show_form_border)}
              />
              <AppText fontSize={12} fontWeight={500}>
                Show Form Border
              </AppText>
            </FlexDiv>
            {values.show_form_border && (
              <FlexDiv gap={8} align="flex-end">
                <PhoenixInputField
                  name="form_border_color"
                  titleText="Button Border Color"
                  displayNoContextText
                  width={120}
                />
                <ColorField name="form_border_color" type="color" width={40} height={40} />
              </FlexDiv>
            )}
            <PhoenixInputField
              name="form_corner_radius"
              width={120}
              titleText="Form Corner Radius"
              showNumberArrows
              inputValueType="number"
              handleNumberArrowDownClick={() =>
                setFieldValue("form_corner_radius", Math.max(values.form_corner_radius - 1, 0))
              }
              handleNumberArrowUpClick={() =>
                setFieldValue("form_corner_radius", Math.min(values.form_corner_radius + 1, MAX_FONT_SIZE))
              }
            />
          </FormSettingSection>
          <FormSettingSection>
            <SectionTitle title="Thank You Message" />

            {!values.is_IBC ? (
              <FlexDiv gap={8}>
                <PhoenixCheckbox
                  checked={values.is_redirection}
                  onChange={() => setFieldValue("is_redirection", !values.is_redirection)}
                />
                <AppText fontSize={12} fontWeight={500}>
                  Enable Form Redirection
                </AppText>
              </FlexDiv>
            ) : null}

            {!values.is_IBC && values.is_redirection ? (
              <FlexDiv gap={8}>
                <PhoenixInputField name="redirection_url" titleText="Redirection URL" />
              </FlexDiv>
            ) : (
              <>
                <FontDropdown
                  name="thank_you_message_font"
                  marginBottom={false}
                  titleText="Thank You Message Font"
                  value={values.thank_you_message_font}
                  onChange={(selected: OptionItem) => setFieldValue("thank_you_message_font", selected.value)}
                />
                <FlexDiv gap={40} align="flex-start" style={{ marginBottom: -12 }}>
                  <PhoenixInputField
                    titleText="Text Size"
                    name="thank_you_message_text_size"
                    showNumberArrows
                    inputValueType="number"
                    handleNumberArrowDownClick={() => {
                      setFieldValue(
                        "thank_you_message_text_size",
                        Math.max(values.thank_you_message_text_size - 1, MIN_FONT_SIZE),
                      );
                    }}
                    handleNumberArrowUpClick={() => {
                      setFieldValue(
                        "thank_you_message_text_size",
                        Math.min(values.thank_you_message_text_size + 1, MAX_FONT_SIZE),
                      );
                    }}
                    width={100}
                  />
                  <FlexDiv gap={8} align="flex-end">
                    <PhoenixInputField
                      titleText="Text Color"
                      name="thank_you_message_text_color"
                      type="color"
                      displayNoContextText
                      width={120}
                    />
                    <ColorField
                      name="thank_you_message_text_color"
                      type="color"
                      width={40}
                      height={40}
                      displayNoContextText
                    />
                  </FlexDiv>
                </FlexDiv>
                <PhoenixInputField titleText="Message Content" name="thank_you_message_content" />
              </>
            )}
          </FormSettingSection>
          <ToggleIBCModal onToggle={onToggleIBC} />
        </>
      )}
    </Main>
  );
};

const LeadSettingsFieldDiv = ({
  allCustomFields,
  allSystemFields,
  availableCustomFields,
  availableSystemFields,
  scrollToEnd,
  setFieldValue,
  setSelectedFieldID,
  values,
}: {
  allCustomFields: CustomField[];
  allSystemFields: string[];
  availableCustomFields: CustomField[];
  availableSystemFields: string[];
  scrollToEnd: () => void;
  setFieldValue: (field: keyof OPSIQFormsFormikProps, value: any) => void;
  setSelectedFieldID: (id: string) => void;
  values: FormExpectedResponse;
}) => {
  const fieldData: FormField[] = values?.hidden_fields || [];

  const hiddenFields = useMemo(() => {
    return formatFormFields(fieldData, allSystemFields, allCustomFields);
  }, [fieldData, allSystemFields, allCustomFields]);

  const addNewHiddenField = () => {
    setFieldValue("hidden_fields", [
      ...hiddenFields,
      {
        custom_field_id: undefined,
        custom_field: undefined,
        custom_id: true,
        hidden_field: true,
        id: uuidv4(),
        order: hiddenFields.length,
        required: false,
        system_field: undefined,
        type: "SYSTEM_FIELD",
      },
    ]);

    scrollToEnd();
  };

  const onFieldChange = (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => {
    const customField = allCustomFields.find((cf) => cf.id === newOption.value);

    const updatedFields = hiddenFields?.map((field) =>
      field.id === id
        ? {
            ...field,
            custom_field_id: radio === "custom" ? newOption.value : undefined,
            custom_field: customField,
            system_field: radio === "system" ? newOption.value : undefined,
          }
        : field,
    );
    setFieldValue("hidden_fields", updatedFields);
  };

  const onFieldRadioChange = (id: string, newType: "system" | "custom") => {
    const updatedFields = hiddenFields?.map((field) =>
      field.id === id
        ? {
            ...field,
            custom_field_id: null,
            custom_field: null,
            system_field: null,
            type: newType === "system" ? "SYSTEM_FIELD" : "CUSTOM_FIELD",
            value: "",
          }
        : field,
    );
    setFieldValue("hidden_fields", updatedFields);
  };

  const onFieldRequiredChange = (id: string, newRequired: boolean) => {
    const updatedFields = hiddenFields?.map((field) => (field.id === id ? { ...field, required: newRequired } : field));
    setFieldValue("hidden_fields", updatedFields);
  };

  const onDeleteField = (id: string) => {
    setSelectedFieldID(id);
  };

  return (
    <FlexDiv direction="column" style={{ borderTop: `1px solid ${theme.border.neutral.primary}` }}>
      {hiddenFields?.map((field, index) => (
        <HiddenFieldWrapper key={field.id}>
          <Field
            availableCustomFields={availableCustomFields}
            availableSystemFields={availableSystemFields}
            customFieldData={allCustomFields}
            data={field}
            fieldName={`hidden_fields`}
            index={index}
            isHidden
            onDeleteField={() => onDeleteField(field.id)}
            onFieldChange={onFieldChange}
            onFieldRadioChange={onFieldRadioChange}
            onFieldRequiredChange={onFieldRequiredChange}
            setFieldValue={setFieldValue}
            values={values}
          />
        </HiddenFieldWrapper>
      ))}
      <PhoenixAppButton
        style={{ marginTop: "24px" }}
        buttonType="ghost-small"
        variant="brand"
        onClick={() => {
          addNewHiddenField();
        }}
      >
        <PhoenixIcon svg={plus} size={16} variant="brand" />
        Add Field
      </PhoenixAppButton>
    </FlexDiv>
  );
};

const FontDropdown = ({
  value,
  onChange,
  name,
  titleText,
  marginBottom,
}: {
  value?: string;
  onChange: (selected: OptionItem) => void;
  name: string;
  titleText: string;
  marginBottom: boolean;
}) => {
  const { Option, SingleValue } = components;
  const FontOption = (props: any) => (
    <Option {...props}>
      <FlexDiv style={{ fontFamily: props.data.value }}>{props?.data?.label}</FlexDiv>
    </Option>
  );
  const FontSingleValue = (props: any) => (
    <SingleValue {...props}>
      <FlexDiv align="center" style={{ fontFamily: props.data.value }}>
        {props.children}
      </FlexDiv>
    </SingleValue>
  );

  return (
    <PhoenixMultiSelect
      name={name}
      isMulti={false}
      isClearable={false}
      marginBottom={marginBottom}
      titleText={titleText}
      options={FONT_OPTIONS}
      value={FONT_OPTIONS.find((f) => f.value === value)}
      onChange={onChange}
      components={{ Option: FontOption, SingleValue: FontSingleValue }}
      menuPosition="fixed"
      menuShouldBlockScroll
    />
  );
};

const SectionTitle = ({ title, tooltip }: { title: string; tooltip?: string }) => (
  <FlexDiv gap={8} align="center">
    <AppText fontSize={12} fontWeight={600} color={theme.text.brand.primary} uppercase>
      {title}
    </AppText>
    {tooltip && (
      <PhoenixIcon svg={info} size={16} variant="brand" pointer data-for="form-settings-tooltip" data-tip={tooltip} />
    )}
  </FlexDiv>
);

const LoadingComponent = () => {
  return (
    <SkeletonContainer align="flex-start" direction="column" gap={24}>
      <FormSettingSkeleton height={40} />
      <FormSettingSkeleton height={80} />
      <FormSettingSkeleton height={120} />
      <FormSettingSkeleton height={160} />
      <FormSettingSkeleton height={200} />
    </SkeletonContainer>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  overflow-y: auto;
`;

const FieldText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const FormSettingSection = styled.div<{ maxHeight?: number }>`
  width: 424px;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid ${theme.border.neutral.primary};
  background: ${theme.fill.neutral.primary};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "auto")};
  overflow-y: auto;
`;

const pulse = keyframes`
  0% {
    background-color: ${theme.PRIMARY100};
  }
  50% {
    background-color: ${theme.PRIMARY300};
  }
  100% {
    background-color: ${theme.PRIMARY100};
  }
`;

const HiddenFieldWrapper = styled.div`
  border-bottom: 1px solid ${theme.border.neutral.primary};
  display: flex;
  flex-direction: column;
  padding: 24px 0;
`;

const FormSettingSkeleton = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: ${theme.PRIMARY100};
  animation: ${pulse} 1s ease infinite;
  border-radius: 8px;
`;

const SkeletonContainer = styled(FlexDiv)`
  padding: 24px;
  width: 100%;

  ${FormSettingSkeleton}:nth-child(1) {
    opacity: 1;
    animation-delay: 0.1s;
  }
  ${FormSettingSkeleton}:nth-child(2) {
    opacity: 0.84;
    animation-delay: 0.2s;
  }
  ${FormSettingSkeleton}:nth-child(3) {
    opacity: 0.68;
    animation-delay: 0.3s;
  }
  ${FormSettingSkeleton}:nth-child(4) {
    opacity: 0.52;
    animation-delay: 0.4s;
  }
  ${FormSettingSkeleton}:nth-child(5) {
    opacity: 0.36;
    animation-delay: 0.5s;
  }
`;
