import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { iconDesktop } from "../../../images";
import { theme } from "../../../utils/theme";
import { AppButton, AppText, Loading } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { appToast } from "../../../utils/toast";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { CallContext } from "../../../context";

const CREATE_JOINME_AD_HOC_LINK = gql`
  mutation startAdHocJoinMeMeeting {
    startAdHocJoinMeMeeting
  }
`;

const CREATE_JOINME_SCHEDULED_LINK = gql`
  mutation startJoinMeMeeting($scheduled_item_id: String!) {
    startJoinMeMeeting(scheduled_item_id: $scheduled_item_id)
  }
`;

const FETCH_SCHEDULED_ITEM = gql`
  query fetchCurrentLead {
    fetchCurrentLead {
      schedule_item {
        join_me_item {
          schedule_item_id
        }
      }
    }
  }
`;

interface JoinMeLinkProps {
  leadID: string;
}

const JoinMeLink: React.FC<JoinMeLinkProps> = ({ leadID }) => {
  const { data, error, loading } = useQuery(FETCH_SCHEDULED_ITEM, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [startAdHocJoinMeMeeting, { data: adHocData, loading: adHocLoading }] = useMutation(CREATE_JOINME_AD_HOC_LINK, {
    onCompleted(joinMeData) {
      setJoinMeItem(joinMeData.startAdHocJoinMeMeeting);
      setJoinMeLeadID(leadID);
    },
    onError({ message }) {
      console.error(message);
      appToast("Error creating JoinMe link.");
      Sentry.captureEvent({
        message: `startAdHocJoinMeMeeting GraphQL Error: ${message}`,
      });
    },
  });
  const [startJoinMeMeeting, { data: scheduledData, loading: scheduledLoading }] = useMutation(
    CREATE_JOINME_SCHEDULED_LINK,
    {
      onCompleted(scheduledData) {
        setJoinMeItem(scheduledData.startJoinMeMeeting);
        setJoinMeLeadID(leadID);
      },
      onError({ message }) {
        console.error(message);
        appToast("Error creating JoinMe link.");
        Sentry.captureEvent({
          message: `startJoinMeMeeting GraphQL Error: ${message}`,
        });
      },
    },
  );

  const { joinMeItem, setJoinMeItem, joinMeLeadID, setJoinMeLeadID } = useContext(CallContext);

  const pageLoading = adHocLoading || scheduledLoading || loading;

  // checks for joinMe scheduled event, calls again if loading changes for accuracy.
  useEffect(() => {
    if (data?.fetchCurrentLead?.schedule_item?.join_me_item?.schedule_item_id ?? false) {
      startJoinMeMeeting({
        variables: { scheduled_item_id: data.fetchCurrentLead.schedule_item.join_me_item.schedule_item_id },
      });
    }
  }, [loading]);

  // Check for anything loading.

  if (pageLoading)
    return (
      <Container>
        <JoinMeButton
          width={257}
          // fontsize={13}
          disabled={pageLoading}
        >
          <Loading color={"white"} />
        </JoinMeButton>
      </Container>
    );

  return (
    <>
      {!!joinMeItem.viewerLink && joinMeLeadID === leadID ? (
        <Container>
          <LinkGridContainer>
            <LinkDiv>
              <LinkHeader>Viewer Link</LinkHeader>
              <Link>{joinMeItem.viewerLink ?? "No link available."}</Link>
            </LinkDiv>
            <RightText
              onClick={() => {
                navigator?.clipboard?.writeText(joinMeItem.viewerLink);
              }}
            >
              Copy
            </RightText>
            <LinkDiv>
              <LinkHeader>Presenter Link</LinkHeader>
              <Link>{joinMeItem?.presenterLink ?? "No link available."}</Link>
            </LinkDiv>
            <RightText>
              <a href={joinMeItem?.presenterLink} target="_blank">
                Go To Link
              </a>
            </RightText>
          </LinkGridContainer>
        </Container>
      ) : (
        <InitialStateDiv>
          <JoinMeLabelText>JoinMe</JoinMeLabelText>
          <SimpleLinkText onClick={() => startAdHocJoinMeMeeting()}>Create Link</SimpleLinkText>
        </InitialStateDiv>
      )}
    </>
  );
};

const InitialStateDiv = styled.div`
  margin-left: 24px;
`;

const JoinMeLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
`;

const Container = styled.div`
  margin-top: 8px;
  /* background-color: ${theme.NEUTRAL100}; */
  width: 100%;
  /* max-width: 353px; */
  /* height: 108px; */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JoinMeButton = styled(NewAppButton)`
  /* display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px; */
`;

const LinkGridContainer = styled.div`
  display: grid;
  padding: 8px 24px;
  grid-gap: 16px;
  width: 100%;
  grid-template-columns: 1fr 0.4fr;
  grid-template-rows: 1fr 1fr;
`;

const LinkDiv = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  justify-content: flex-end;
  min-width: 0;
`;

const Link = styled.div`
  font-weight: 400;
  min-width: 0;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LinkHeader = styled.p`
  font-weight: 500;
  font-size: 10px;
  color: "5E5E5E";
`;

const RightText = styled(AppText)`
  justify-self: end;
  align-self: end;
  color: ${theme.PRIMARY500};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  opacity: 70%;
  text-decoration: none;
  border-bottom: none;
  :hover {
    opacity: 100%;
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
  a {
    text-decoration: none;
    color: ${theme.PRIMARY500};
  }
`;

const SimpleLinkText = styled(RightText)`
  text-align: left;
  justify-self: start;
  align-self: start;
`;

export { JoinMeLink };
