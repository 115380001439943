import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { mobileSize } from "src/utils/breakpoints";

const lerpSpeed = 0.05;
const DEFAULT_X = 100;
const DEFAULT_GRADIENT_END = 100;
const GRADIENT_END_HOVER = 200;
const MAX_DELTA = 5;

const lerp = (start: number, end: number, t: number) => start * (1 - t) + end * t;
const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

type GradientButtonProps = {
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function GradientButton({ children, ...props }: GradientButtonProps) {
  const [mouseX, setMouseX] = useState(DEFAULT_X);
  const [isHovering, setIsHovering] = useState(false);
  const [gradientEnd, setGradientEnd] = useState(DEFAULT_GRADIENT_END);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const targetMouseX = useRef(DEFAULT_X);
  const prevMouseX = useRef(DEFAULT_X);

  useEffect(() => {
    const animate = () => {
      setMouseX((prev) => {
        const target = targetMouseX.current;
        const delta = target - prevMouseX.current;
        const clampedDelta = clamp(delta, -MAX_DELTA, MAX_DELTA);
        const newValue = lerp(prev, prevMouseX.current + clampedDelta, lerpSpeed);
        prevMouseX.current = newValue;
        return newValue;
      });
      setGradientEnd((prev) => lerp(prev, isHovering ? GRADIENT_END_HOVER : DEFAULT_GRADIENT_END, lerpSpeed));

      requestAnimationFrame(animate);
    };

    const animationFrame = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [isHovering]);

  const handleMouseMove = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      targetMouseX.current = ((event.clientX - rect.left) / rect.width) * 100;
    }
  }, []);

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <StyledButton
      ref={buttonRef}
      {...props}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <StyledButtonGradient
        style={{ background: `radial-gradient(circle at ${mouseX}% 50%, #0282f9 0%, #204fc7 ${gradientEnd}%)` }}
      />
      <ButtonContent>{children}</ButtonContent>
    </StyledButton>
  );
}

const StyledButton = styled.button`
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px 56px;
  position: relative;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media ${mobileSize} {
    padding: 14px 16px;
  }
`;

const StyledButtonGradient = styled.div`
  inset: 0;
  position: absolute;
`;

const ButtonContent = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  text-align: center;

  @media ${mobileSize} {
    font-size: 16px;
  }
`;
