import * as React from "react";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { CallContext, LeadCardContext, LeadFilterContext, ModalContext } from "../../../context";
import { check, filter, refresh } from "../../../images/NewDesign";
import { MixpanelActions } from "../../../services/mixpanel";
import { QUEUE_SORT_OPTIONS } from "../../../utils/misc";
import { theme } from "../../../utils/theme";
import { LeadCardV2 } from "../../Segments/DashboardSideBarSegments";
import { LeadCardError } from "../../Smart";
import { AppSidebarCard, AppTabDiv, AppText, FlexDiv, Loading } from "../../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixMultiSelect } from "../../UI/Phoenix";
import { ClearCustomQueueModal } from "../../modal";
import { gql, useMutation, useQuery } from "@apollo/client";
import { appToast, errorToast } from "../../../utils/toast";
import { brandClock, brandUserAdd, brandFilter } from "../../../images";

interface CurrentLeadProps {
  closeOtherModals?: any;
  leadIdToFilter: string;
}

const GET_TIMEZONE_OPTIONS = gql`
  query getTZOptions {
    getTZOptions
  }
`;

const REQUEST_MORE_LEADS = gql`
  mutation requestMoreLeads {
    requestMoreLeads
  }
`;

const EmptyState: React.FC = () => {
  const [sendEnablementNotification, setSendEnablementNotification] = useState(false);
  const { leadFilter, setLeadFilter, tempLeadFilter, setTempLeadFilter, selected, setSelected } = useContext(
    LeadFilterContext,
  );

  // const { handleResetQueueToDefaultSettings } = useContext(LeadCardContext);
  const { resetLeadQueueFilters } = useContext(LeadFilterContext);

  const { data: tzOptions, error: tzError, loading: tzLoading } = useQuery(GET_TIMEZONE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [requestMoreLeads, { loading: requestMoreLeadsLoading }] = useMutation(REQUEST_MORE_LEADS, {
    onCompleted: (data) => {
      if (data?.requestMoreLeads) {
        appToast("A notification has been sent to your Admin requesting more Leads.");
      } else {
        errorToast("Error: You've reached the limit of 1 request per hour. Please try again later.");
      }
    },
    onError: (error) => {
      console.log("Request more leads error", error);
      errorToast("Error: You've reached the limit of 1 request per hour. Please try again later.");
    },
  });

  // if (tzLoading) return <></>;

  const timezones = tzOptions?.getTZOptions;

  return (
    <FlexDiv direction="column" align="center" justify="center" height="100%" padding="20px">
      <AppText fontSize={14} fontWeight={600} style={{ margin: "20px auto", textAlign: "center" }}>
        Empty Queue? Let's get you dialing again!
      </AppText>
      {/* {JSON.stringify(leadFilter)} */}

      <EmptyQueueDiv done={selected?.length === 0}>
        <CTAIconDiv>
          <PhoenixIcon svg={brandFilter} size={24} variant="brand" />
          {selected?.length === 0 && (
            <CTADoneDiv>
              <PhoenixIcon svg={check} size={12} color="white" />
            </CTADoneDiv>
          )}
        </CTAIconDiv>
        <EmptyQueueCTADiv>
          <AppText color={theme.NEUTRAL400} fontSize={10} fontWeight={400} lineHeight={14}>
            Remove any filters which might limit what Leads you can see.
          </AppText>
          {/* <FlexDiv
              direction="row"
              align="center"
              gap={4}
              onClick={() => {
                resetLeadQueueFilters();
                // if (selected?.length <= leadFilter?.timezones?.length) return;
                // const currentTimezones = leadFilter?.timezones?.map((item: any) => item);
                // handleResetQueueToDefaultSettings();
                // if (currentTimezones?.length) {
                // setLeadFilter((leadFilter: any) => ({ ...leadFilter }));
                // setTempLeadFilter((tempLeadFilter: any) => ({ ...tempLeadFilter, timezones: currentTimezones }));
                // setSelected([
                //   // ...selected.filter((item: any) => item.key === "timezones"),
                //   ...currentTimezones?.map((item: any) => ({ key: "timezones", item: item })),
                // ]);
                // }
              }}
            > */}
          <PhoenixAppButton
            variant="brand"
            buttonType="ghost-small"
            uppercase
            height={24}
            style={{ width: "auto" }}
            borderRadius={4}
            disabled={selected?.length === 0}
            onClick={() => {
              resetLeadQueueFilters();
            }}
          >
            Clear filters
          </PhoenixAppButton>
          {/* </FlexDiv> */}
        </EmptyQueueCTADiv>
      </EmptyQueueDiv>

      {!tzLoading && (
        <EmptyQueueDiv done={timezones?.length <= leadFilter?.timezones?.length}>
          <CTAIconDiv>
            <PhoenixIcon svg={brandClock} size={24} variant="brand" />
            {timezones?.length <= leadFilter?.timezones?.length && (
              <CTADoneDiv>
                <PhoenixIcon svg={check} size={12} color="white" />
              </CTADoneDiv>
            )}
          </CTAIconDiv>
          <EmptyQueueCTADiv>
            <AppText color={theme.NEUTRAL400} fontSize={10} fontWeight={400} lineHeight={14}>
              Leads are hidden when it's before 8am or after 5pm in their local timezone.
            </AppText>
            <PhoenixAppButton
              onClick={() => {
                setLeadFilter({ ...leadFilter, timezones });
                setTempLeadFilter({ ...tempLeadFilter, timezones });
                setSelected([
                  ...selected.filter((item: any) => item.key !== "timezones"),
                  ...timezones?.map((item: any) => ({ key: "timezones", item: item })),
                ]);
              }}
              disabled={timezones?.length <= leadFilter?.timezones?.length}
              variant="brand"
              buttonType="ghost-small"
              uppercase
              height={24}
              style={{ width: "auto" }}
              borderRadius={4}
            >
              Ignore timezone limits
            </PhoenixAppButton>
          </EmptyQueueCTADiv>
        </EmptyQueueDiv>
      )}
      <EmptyQueueDiv done={sendEnablementNotification}>
        <CTAIconDiv>
          <PhoenixIcon svg={brandUserAdd} size={24} variant="brand" />
          {sendEnablementNotification && (
            <CTADoneDiv>
              <PhoenixIcon svg={check} size={12} color="white" />
            </CTADoneDiv>
          )}
        </CTAIconDiv>
        <EmptyQueueCTADiv>
          <AppText color={theme.NEUTRAL400} fontSize={10} fontWeight={400} lineHeight={14}>
            None of the above worked? Notify an Admin you need more Leads.
          </AppText>
          <PhoenixAppButton
            style={{ width: "auto" }}
            onClick={async () => {
              await requestMoreLeads();
              setSendEnablementNotification(true);
            }}
            disabled={requestMoreLeadsLoading || sendEnablementNotification}
            variant="brand"
            buttonType="ghost-small"
            uppercase
            height={24}
            borderRadius={4}
          >
            Request more leads
          </PhoenixAppButton>
        </EmptyQueueCTADiv>
      </EmptyQueueDiv>
      {/* <AppText>
        Refer to the{" "}
        <a
          href="https://support.opsiq.ai/hc/en-us/articles/8668030085901--Rep-Lead-Queues-Management#:~:text=number%20of%20criteria.-,Active%20Queue,-In%20the%20Active"
          rel="noopener noreferrer"
          target="_blank"
        >
          Help Article
        </a> for more.
      </AppText> */}
    </FlexDiv>
  );
};

const UpcomingDialsComponent: React.FC<CurrentLeadProps> = ({ closeOtherModals, leadIdToFilter = "" }) => {
  //Local State

  const [isClicked, setIsClicked] = useState(false);

  //Context
  const { setShowFiltersModal, showFiltersModal, setShowClearCustomQueueModal } = useContext(ModalContext);
  const { leadFilter, filterNum } = useContext(LeadFilterContext);
  const {
    selectedTabDialQueue,
    setSelectedTabDialQueue,
    sortOptionDialQueue,
    setSortOptionDialQueue,
    upcomingDials,
    setUpcomingDials,
    customDials,
    setCustomDials,
    handleResetQueueToDefaultSettings,
    setSkip,
    setCustomSkip,
    skip,
    customSkip,
    hasMore,
    hasMoreCustom,
    handleShowMore,
    handleShowMoreCustom,
    refreshActiveAndCustomQueue,
    errorCustom,
    clearAndRefetchCustomQueue,
    errorRecentsV2,
    fetchCustomLeadsInQueue,
    fetchFilteredLeadsInQueueV2,
    loadingRecentsV2,
  } = useContext(LeadCardContext);

  const { resetNextDial, nextActionOverride } = useContext(CallContext);

  // this is the full reset and refetch of the queue intents from a skip of 0
  // this is not a reset of the sorts/filters
  useEffect(() => {
    refreshActiveAndCustomQueue();
  }, [selectedTabDialQueue, leadFilter, sortOptionDialQueue]);

  if (errorRecentsV2 || errorCustom) {
    return (
      <LeadCardError
        error="Error loading dial queue"
        title="Queue"
        refetch={() => {
          refreshActiveAndCustomQueue();
        }}
      />
    );
  }

  return (
    <>
      <ClearCustomQueueModal clearAndRefetchCustomQueue={clearAndRefetchCustomQueue} />
      <AppSidebarCard
        width={432}
        showExpand
        title={"Queue"}
        render={
          <Horizontal>
            <FlexDiv direction="row" align="center" gap={8}>
              <FlexDiv
                direction="row"
                align="center"
                gap={4}
                onClick={() => {
                  handleResetQueueToDefaultSettings();
                }}
                style={{ cursor: "pointer", margin: "0px 4px" }}
              >
                <PhoenixAppButton buttonType="ghost-small" variant="brand">
                  <PhoenixIcon
                    svg={refresh}
                    hoverColor={theme.buttontext.brand_outline.default}
                    color={theme.buttontext.brand_outline.default}
                    size={12}
                    pointer
                  />
                  Reset to Default
                </PhoenixAppButton>
              </FlexDiv>
              <PhoenixMultiSelect
                isMulti={false}
                name="sort"
                options={QUEUE_SORT_OPTIONS}
                placeholder="Sort"
                onChange={(e: any) => {
                  setSortOptionDialQueue(e.value);
                }}
                value={{
                  label: "Sort",
                  value: sortOptionDialQueue,
                }}
                isClearable={false}
                marginBottom={false}
                error={false}
                ghostSelect
                ghostSelectAppThemeOverride={"Dark"}
                width={50}
                optionsContainerWidth={186}
                style={{ overflow: "hidden" }}
                minHeight={22}
                lineHeight="10px"
                maxHeight={22}
                // menuPortal={true}
                pointer
                menuInset="20px 0px 0px -130px"
                menuBorder
                removeMenuPadding
              />
            </FlexDiv>

            <div style={{ position: "relative" }}>
              {!!filterNum && (
                <NotificationsNumber
                  onClick={() => {
                    MixpanelActions.track("Queue Event", {
                      type: "filter",
                      tab: `${selectedTabDialQueue}`,
                      view: "Dashboard",
                    });
                    setShowFiltersModal(!showFiltersModal);
                  }}
                />
              )}
              <PhoenixIcon
                svg={filter}
                color={theme.icon.brand.default}
                hoverColor={theme.icon.brand.hover}
                size={16}
                onClick={() => {
                  MixpanelActions.track("Queue Event", {
                    type: "filter",
                    tab: `${selectedTabDialQueue}`,
                    view: "Dashboard",
                  });
                  setShowFiltersModal(!showFiltersModal);
                }}
                pointer
              />
            </div>
            {isClicked && (
              <OptionsDiv>
                <Option
                  onClick={() => {
                    setSortOptionDialQueue("HighestPDV");
                    setIsClicked(false);
                  }}
                >
                  Predicted Dial Value
                </Option>
                <Option
                  onClick={() => {
                    setSortOptionDialQueue("MostRecentDial");
                    setIsClicked(false);
                  }}
                >
                  Most Recently Dialed
                </Option>
                <Option
                  onClick={() => {
                    setSortOptionDialQueue("LastSinceDialed");
                    setIsClicked(false);
                  }}
                >
                  Longest Dial Since
                </Option>
                <Option
                  onClick={() => {
                    setSortOptionDialQueue("MostRecentlyAdded");
                    setIsClicked(false);
                  }}
                >
                  Most Recently Added
                </Option>
              </OptionsDiv>
            )}
          </Horizontal>
        }
      >
        <TabDiv>
          <AppTabDiv
            selected={selectedTabDialQueue === "active"}
            onClick={() => {
              MixpanelActions.track("Queue Event", { type: "sort", tab: `${selectedTabDialQueue}`, view: "Dashboard" });
              setSelectedTabDialQueue("active");

              const currentNextActionLeadIsManualOverride = nextActionOverride?.intent_object?.lead?.id;

              // refresh to get the new selected next action lead from active queue fetch if there is not an override from event or manual override
              if (!currentNextActionLeadIsManualOverride) resetNextDial();
            }}
          >
            Active
          </AppTabDiv>
          <AppTabDiv
            onClick={() => {
              MixpanelActions.track("Queue Event", { type: "sort", tab: `${selectedTabDialQueue}`, view: "Dashboard" });
              setSelectedTabDialQueue("custom");

              const currentNextActionLeadIsManualOverride = nextActionOverride?.intent_object?.lead?.id;

              // refresh to get the new selected next action lead from custom queue fetch if there is not an override from event or manual override
              if (!currentNextActionLeadIsManualOverride) resetNextDial();
            }}
            selected={selectedTabDialQueue === "custom"}
          >
            Custom
          </AppTabDiv>
        </TabDiv>
        {selectedTabDialQueue === "custom" && customDials?.length > 0 && (
          <ClearCustomQueueDiv>
            <PhoenixAppButton
              variant="brand-outline"
              buttonType="secondary"
              width={70}
              height={15}
              buttonTextFontSize={7}
              buttonTextFontWeight={500}
              centered
              padding="1px"
              uppercase
              onClick={() => {
                // show confirmation modal
                setShowClearCustomQueueModal(true);
              }}
            >
              Clear Queue
            </PhoenixAppButton>
          </ClearCustomQueueDiv>
        )}
        {selectedTabDialQueue === "active" ? (
          <ScrollDiv id="upcoming-scroll-div">
            <InfiniteScroll
              dataLength={upcomingDials.length}
              next={handleShowMore}
              hasMore={hasMore}
              loader={<Loading />}
              scrollableTarget="upcoming-scroll-div"
            >
              {upcomingDials.length > 0
                ? upcomingDials?.map(
                    (item: any, index: number) =>
                      leadIdToFilter !== item.lead_id && (
                        <BorderDiv borderTop={index > 1} key={`upcoming-${item.id}`}>
                          <LeadCardV2
                            showEventTypeCard
                            parentType={`activeQueueDials`}
                            leadData={item.lead}
                            intent={item}
                            closeOtherModals={closeOtherModals}
                          />
                        </BorderDiv>
                      ),
                  )
                : !loadingRecentsV2 && <EmptyState />}
            </InfiniteScroll>
          </ScrollDiv>
        ) : (
          <ScrollDiv id="custom-scroll-div">
            <InfiniteScroll
              dataLength={customDials.length}
              next={handleShowMoreCustom}
              hasMore={hasMoreCustom}
              loader={<Loading />}
              scrollableTarget="custom-scroll-div"
            >
              {customDials.length > 0 ? (
                customDials?.map(
                  (item: any, index: number) =>
                    leadIdToFilter !== item.lead_id && (
                      <BorderDiv borderTop={index > 1} key={`custom-${index}-${item?.lead?.id}`}>
                        <LeadCardV2
                          showEventTypeCard
                          parentType={`customQueueDials`}
                          leadData={item.lead}
                          intent={item?.lead?.lead_intent?.length && item?.lead?.lead_intent[0]}
                          closeOtherModals={closeOtherModals}
                        />
                      </BorderDiv>
                    ),
                )
              ) : (
                <AppText style={{ margin: "150px auto", textAlign: "center" }}>
                  You have no upcoming leads in your custom queue. <br />
                </AppText>
              )}
            </InfiniteScroll>
          </ScrollDiv>
        )}
      </AppSidebarCard>
    </>
  );
};

interface BorderProps {
  borderTop?: boolean;
}

const BorderDiv = styled.div<BorderProps>`
  border-bottom: 1px solid ${theme.NEUTRAL200};
  padding: 0px;
  margin: 0px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  height: 400px;
`;

const OptionsDiv = styled.div`
  position: absolute;
  left: -60px;
  top: 30px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 6;
`;

const Option = styled.div`
  display: flex;
  justify-content: canter;
  align-items: center;
  height: 39px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  color: ${theme.BLACK_COLOR};
  text-transform: none;
  letter-spacing: 0.2px;
  cursor: pointer;
  :first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  :last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const Horizontal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 8px;
`;

const TabDiv = styled(Horizontal)`
  justify-content: space-between;
  height: 46px;
`;

const ClearCustomQueueDiv = styled.div`
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  width: 100%;
  margin-top: 2px;
`;

const NotificationsNumber = styled.div`
  position: absolute;
  top: -5px;
  right: -2px;
  height: 10px;
  width: 10px;
  /* padding-top: 2px; */
  background: ${theme.ATTENTION700};
  border-radius: 50%;
  justify-items: center;
  /* border: 1px solid ${theme.BLACK_COLOR}; */
  z-index: 2;
  cursor: pointer;
`;

const CTADoneDiv = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  background: ${theme.SUCCESS500};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CTAIconDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 56px;
  width: 56px;
  height: 56px;
  background-color: ${theme.LIGHT_BLUE};
`;

const EmptyQueueDiv = styled.div<{ done?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px;
  gap: 24px;
  border: 1px solid ${(props) => (props.done ? theme.SUCCESS500 : theme.NEUTRAL200)};

  border-radius: 8px;
  margin: 0px 20px;
  margin-bottom: 16px;
`;

const EmptyQueueCTADiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  padding: 2px;
  gap: 8px;
`;

export { UpcomingDialsComponent };
