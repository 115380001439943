import { theme } from "../../utils/theme";
import styled, { css } from "styled-components";
import * as React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

interface WrapperProps {
  children: React.ReactNode;
  fixedPosition?: boolean;
  height?: string;
}

export const ReactDatesWrapper: React.FC<WrapperProps> = ({ children, height = "42px", fixedPosition = false }) => {
  return (
    <StyledDatePickerWrapper height={height} fixedPosition={fixedPosition}>
      {children}
    </StyledDatePickerWrapper>
  );
};

const StyledDatePickerWrapper = styled.div<{ fixedPosition: boolean; height: string }>`
  overflow: visible;
  height: ${(props) => props.height};

  ${(props) =>
    props.fixedPosition &&
    css`
      position: relative;
      .DateRangePicker_picker {
        position: fixed;
        left: 550px !important;
        top: 20vh !important;
        height: fit-content;
        z-index: 1000;
        background: transparent;
      }
    `}

  .SingleDatePicker__SingleDatePicker_1 {
    width: 100%;
  }
  
  .DateRangePicker_picker__portal{
    & .DayPicker_portal__horizontal {
      left: 745px;
      top: 160px;
    }
  }
  .DateRangePickerInput_clearDates {
    position: absolute;
    top: 20px !important;
    height: 26px;
    width: 26px;
    /* padding: 0px !important; */
    /* margin-bottom: 10px !important; */
  }
  .DateRangePickerInput_clearDates_svg {
    left: 6px;
    top: 7px;
    position: absolute;
  }

  & .DateRangePicker,
  .DateRangePickerInput {
    width: max-content;
    .DateInput {
      .DateInput_input {
        font-size: 14px;
        border-bottom: 0;
        padding: 12px 16px 14px;
        height: 40px;
      }
    }

    .DateRangePickerInput__withBorder {
      border-radius: 4px;
      overflow: hidden;

      :hover,
      .DateInput_input__focused {
        border: 1px solid ${theme.PRIMARY500};
      }

      .CalendarDay__selected {
        background: ${theme.PRIMARY600};
        border: 1px double ${theme.PRIMARY600};
        opacity: 0.9;
      }
      .CalendarDay__selected_span {
        background: ${theme.PRIMARY200};
        border: 1px double ${theme.PRIMARY300};
        :hover {
          background: ${theme.PRIMARY200}, 1);
          border: 1px double ${theme.PRIMARY300};
        }
      }
    }

    .DateRangePicker_picker.DateRangePicker_picker {
      top: 43px;
      left: 2px;
    }
  }
`;
