import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useState } from "react";
import { check, search_blue } from "../../../images/NewDesign";
import { check_blue, trash, plus, minus } from "../../../images/NewDesign";
import { PhoenixIcon } from "./PhoenixIcon";

export interface PhoenixInputProps {
  // used in rendering things next to the input and setting the input type
  inputValueType?: "string" | "number" | "currency" | "percentage" | "minutes" | undefined;

  // search type

  searchInput?: boolean;

  // delete type

  deleteInput?: boolean;

  // left element override

  insideLeftElementOverride?: React.ReactNode;
  outsideLeftElementOverride?: React.ReactNode;

  // right element override

  insiderightElementOverride?: React.ReactNode;
  outsideRightElementOverride?: React.ReactNode;

  // left element onClick
  insideLeftElementOnClick?: () => void;
  outsideLeftElementOnClick?: () => void;

  //right element onClick
  insideRightElementOnClick?: () => void;
  outsideRightElementOnClick?: () => void;

  showNumberArrows?: boolean;
  handleNumberArrowUpClick?: () => void;
  handleNumberArrowDownClick?: () => void;

  // text below the input that gives context
  contextText?: string | false;

  contextTextFontSize?: number;

  // text above the input that gives context
  titleText?: string | false;
  titleTextLocation?: "top" | "left";
  // basic styling
  width?: number;
  height?: number;
  fontSize?: number;
  fontWeight?: number;
  textAlign?: string;

  checked?: boolean;
  checkedColor?: string;
  inputOnlyWidth?: number | string;

  titleTextSpacing?: number;
  titleTextFontSize?: number | string;
  titleTextFontWeight?: number | string;

  /**
   * When there is no title, leave out a top margin
   */
  noTopMargin?: boolean;
  /**
   * If true, will not display an error message even if there is an error message
   */
  displayNoContextText?: boolean;
  disabled?: boolean;
  variant?: "primary" | "error" | "success" | "warning";
  requiredStar?: boolean;
  labelWeight?: number;

  // not used as far as I can tell. can be removed once we are sure that we don't need theme for the field component
  primary?: boolean;
  margin?: string;
}

// this is the input field itself
export const Input = styled.input<PhoenixInputProps>`
  font-family: ${theme.PRIMARY_FONT};
  width: ${(props) =>
    props.inputOnlyWidth
      ? typeof props.inputOnlyWidth === "number"
        ? `${props.inputOnlyWidth}px`
        : props.inputOnlyWidth
      : props.width
      ? `${props.width}px`
      : `100%`};

  height: ${(props) => (props.height ? `${props.height}px` : `40px`)};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `12px`)};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : `400`)};
  padding-left: ${(props) =>
    props.inputValueType === "currency" ? 35 : props.searchInput ? 35 : props.insideLeftElementOverride ? 35 : 15}px;

  padding-right: ${(props) =>
    props.inputValueType === "percentage" ? 35 : props.inputValueType === "minutes" ? 35 : 15}px;

  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL300};
  overflow-y: auto;

  // customize the scroll bar if needed
  &::-webkit-scrollbar {
    height: 40px;
    width: 3.3px;
  }

  ${(props) => {
    if (props.margin) {
      return `
        margin: ${props.margin};
      `;
    }
  }}
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 1.5;
  box-sizing: border-box;
  color: ${(props) => (!props.disabled ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  break-inside: avoid;

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    opacity: 0.4;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  }
  :focus {
    ${(props) => {
      switch (props.variant) {
        case "primary":
          return `
        outline: 1px solid ${theme.PRIMARY500};
        outline-offset: 1px;
        

      `;
        case "error":
          return `
        outline: 1px solid ${theme.ATTENTION500};
        border: none;
      `;
        case "success":
          return `
        outline: 1px solid ${theme.SUCCESS500};
        border: none;
   
      `;
        default:
          return `
        outline: 1px solid ${theme.PRIMARY500};
        outline-offset: 1px;
        

    `;
      }
    }};
  }
  background-color: ${(props) => (props.disabled ? theme.NEUTRAL100 : theme.WHITE_COLOR)};
  :hover:not(:focus) {
    border: ${(props) => (!props.disabled ? `1px solid ${theme.NEUTRAL500}` : `1px solid ${theme.NEUTRAL300}`)};
  }
`;

// this is the entire component with input and other additional elements
const PhoenixInput = ({
  displayNoContextText = false,
  titleText,
  contextText,
  titleTextLocation = "top",
  variant,
  insideLeftElementOverride,
  searchInput = false,
  showNumberArrows = false,
  handleNumberArrowUpClick,
  handleNumberArrowDownClick,
  inputOnlyWidth,
  checkedColor,
  ...props
}: PhoenixInputProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  return (
    <EntireComponentDiv width={props.width} height={props.height}>
      {!!titleText && titleTextLocation === "top" && (
        <InputTitle
          textAlign={props.textAlign}
          labelWeight={props.labelWeight}
          titleTextSpacing={props.titleTextSpacing}
          titleTextFontSize={props.titleTextFontSize}
          titleTextFontWeight={props.titleTextFontWeight}
        >
          {titleText}
          {props.requiredStar && <span style={{ color: theme.DANGER600 }}>*</span>}
        </InputTitle>
      )}
      <EntireInputRow>
        <OutsideLeftElements
          onClick={() => {
            props.outsideLeftElementOnClick && props.outsideLeftElementOnClick();
          }}
        >
          {!!titleText && titleTextLocation === "left" && (
            <InputTitle style={{ marginRight: 10 }} textAlign={props.textAlign}>
              {titleText}
            </InputTitle>
          )}
        </OutsideLeftElements>
        <Wrapper>
          <InsideLeftElements
            onClick={() => {
              props.insideLeftElementOnClick && props.insideLeftElementOnClick();
            }}
          >
            {insideLeftElementOverride ? (
              insideLeftElementOverride
            ) : searchInput ? (
              <img src={search_blue} style={{ width: 14, height: 14 }} />
            ) : (
              props?.inputValueType === "currency" && (
                <LeftText contextText={!!contextText} focused={isFocused}>
                  $
                </LeftText>
              )
            )}
          </InsideLeftElements>

          <Input
            {...(props as any)}
            onFocus={(e) => {
              toggleFocus();
              !!props.onFocus && props.onFocus(e);
            }}
            onBlur={(e: any) => {
              toggleFocus();
              !!props.onBlur && props.onBlur(e);
            }}
            titleText={titleText}
            contextText={!displayNoContextText && !!contextText}
            variant={variant}
            insideLeftElementOverride={insideLeftElementOverride}
            searchInput={searchInput}
            type={
              props.inputValueType === "number" ||
              props.inputValueType === "currency" ||
              props.inputValueType === "percentage" ||
              props.inputValueType === "minutes"
                ? "number"
                : "string"
            }
            showNumberArrows={showNumberArrows}

            // todo get the elements to focus on the input field when clicked
          />

          <InsideRightElements
            onClick={() => {
              props.insideRightElementOnClick && props.insideRightElementOnClick();
            }}
          >
            {props?.insiderightElementOverride ? (
              props.insiderightElementOverride
            ) : props?.inputValueType === "percentage" ? (
              <PercentText contextText={!!contextText} focused={isFocused}>
                %
              </PercentText>
            ) : props?.inputValueType === "minutes" ? (
              <RightText contextText={!!contextText} focused={isFocused}>
                Minutes
              </RightText>
            ) : props.checked ? (
              <PhoenixIcon svg={check} size={16} color={checkedColor ?? theme.PRIMARY500} alt="checked icon" />
            ) : null}
          </InsideRightElements>

          {showNumberArrows && (
            <NumberArrowsContainer>
              <UpArrow
                onClick={() => {
                  handleNumberArrowUpClick && handleNumberArrowUpClick();
                }}
              >
                <PhoenixIcon
                  svg={plus}
                  size={12}
                  color={theme.WHITE_COLOR}
                  hoverColor={theme.WHITE_COLOR}
                  alt="up"
                  pointer
                />
              </UpArrow>

              <DownArrow
                onClick={() => {
                  handleNumberArrowDownClick && handleNumberArrowDownClick();
                }}
              >
                <PhoenixIcon
                  svg={minus}
                  size={12}
                  color={theme.WHITE_COLOR}
                  hoverColor={theme.WHITE_COLOR}
                  alt="down"
                  pointer
                />
              </DownArrow>
            </NumberArrowsContainer>
          )}
        </Wrapper>

        <OutsideRightElements>
          {props.deleteInput && (
            <div
              onClick={() => {
                props.outsideRightElementOnClick && props.outsideRightElementOnClick();
              }}
              style={{
                marginLeft: 10,
              }}
            >
              <PhoenixIcon svg={trash} size={16} variant="brand" alt="trashcan delete button" />
            </div>
          )}
        </OutsideRightElements>
      </EntireInputRow>
      {!displayNoContextText && (
        <BottomElements>
          {!!contextText ? (
            <InputContextText variant={variant} contextTextFontSize={props.contextTextFontSize}>
              {contextText}
            </InputContextText>
          ) : (
            <InputContextText variant={variant} contextTextFontSize={props.contextTextFontSize}>
              &nbsp;
            </InputContextText>
          )}
        </BottomElements>
      )}
    </EntireComponentDiv>
  );
};

interface EntireComponentDivProps {
  width?: number;
  height?: number;
}

interface VariantProps {
  variant?: string;
  contextTextFontSize?: number;
}

const EntireComponentDiv = styled.div<EntireComponentDivProps>`
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => (props.height ? `${props.height}px` : `100%`)};
  display: flex;
  flex-direction: column;
`;

const EntireInputRow = styled.div`
  display: flex;
`;

interface TextFocus {
  focused?: boolean;
  contextText?: boolean;
  minutes?: boolean;
}

const LeftText = styled.span<TextFocus>`
  font-size: 13px;
  line-height: 1;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  margin: 0;
`;

const RightText = styled.span<TextFocus>`
  font-size: 13px;
  line-height: 1;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.minutes ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  margin: 0;
`;

const PercentText = styled(RightText)`
  font-size: 12px;
`;

RightText.defaultProps = {
  focused: false,
  contextText: false,
  minutes: false,
};

LeftText.defaultProps = {
  focused: false,
  contextText: false,
};

const InputTitle = styled.p<PhoenixInputProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${(props) =>
    props.titleTextFontSize
      ? typeof props.titleTextFontSize === "number"
        ? `${props.titleTextFontSize}px`
        : `${props.titleTextFontSize}`
      : `12px`};

  font-weight: ${(props) =>
    props.titleTextFontWeight
      ? typeof props.titleTextFontWeight === "number"
        ? `${props.titleTextFontWeight}`
        : `${props.titleTextFontWeight}`
      : `500`};

  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: ${(props) =>
    props.titleTextSpacing
      ? typeof props.titleTextSpacing === "number"
        ? `${props.titleTextSpacing}px`
        : `${props.titleTextSpacing}`
      : `4px`};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

const NumberArrowsContainer = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: flex-start;

  border-style: solid;
  border-color: ${theme.NEUTRAL300};
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 4px 4px 0px;
  overflow: hidden;

  background-color: ${theme.PRIMARY500};
`;

const UpArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 50%;
  background-color: ${theme.PRIMARY500};

  cursor: pointer;
  border-bottom: 1px solid ${theme.PRIMARY600};
  transition: background-color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY600};
  }
`;

const DownArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 50%;

  background-color: ${theme.PRIMARY500};
  cursor: pointer;
  transition: background-color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY600};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  :hover ${Input} {
    :not(:focus) {
      border: 1px solid ${theme.NEUTRAL500};
    }
  }
  :hover:not(:focus-within) ${NumberArrowsContainer} {
    border-color: ${theme.NEUTRAL500};
  }
`;

const OutsideLeftElements = styled.div`
  display: flex;
  align-items: center;
`;
const InsideLeftElements = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  left: 12px;
`;
const OutsideRightElements = styled.div<{
  isFocused?: boolean;
}>`
  cursor: pointer;

  display: flex;
  align-items: center;
  height: 100%;
`;
const InsideRightElements = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 12px;
`;
const BottomElements = styled.div``;

const InputContextText = styled.p<VariantProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${(props) => (props.contextTextFontSize ? `${props.contextTextFontSize}px` : "12px")};
  margin: 0;
  margin-top: 4px;
  text-align: left;
  font-weight: ${(props) => (props.variant === "error" ? "500" : "normal")};
  line-height: 18px;
  letter-spacing: 0.2px;
  text-shadow: 1px 1px 2px ${theme.WHITE_COLOR};

  ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
        color: ${theme.NEUTRAL400}
      `;
      case "error":
        return `
        color: ${theme.ATTENTION700};
      `;
      case "success":
        return `
        color: ${theme.SUCCESS500};
      `;
      default:
        return `
        color: ${theme.NEUTRAL400}
    `;
    }
  }};
`;

export { PhoenixInput };
