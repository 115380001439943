import { createContext } from "react";

export type LeadsTableContextType = {
  getHeaderLabel: (header: string) => string;
  getTitle: (header: string, mrrLabel: string) => string;
  handleAllSelected: () => void;
  isAllSelected: boolean;
  leadSort: string;
  leadSortAscending: boolean;
  loadingTableAndHeaders: boolean;
  mrrLabel: string;
  nonSortableColumns: string[];
  setLeadSort: (leadSort: string) => void;
  setLeadSortAscending: (leadSortAscending: boolean) => void;
};

export const LeadsTableContext = createContext<LeadsTableContextType>({} as LeadsTableContextType);
