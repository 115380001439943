import styled from "styled-components";
import * as React from "react";
import { AppErrorText, AppSelect, AppText, Loading } from "../UI";
import { EventItem } from "../Smart/EventItem";
import { theme } from "../../utils/theme";
import { useState } from "react";
import { ApolloError } from "@apollo/client";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

interface EventListProps {
  data: any;
  handleEditClick: (id: string, time: string, lead_id: string) => void;
}

const EventList: React.FC<EventListProps> = (props) => {
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState("time");
  const setSortState = (column: string) => {
    if (column === sortColumn) {
      setSortOrder(!sortOrder);
    } else {
      setSortColumn(column);
      setSortOrder(true);
    }
  };

  return (
    <ListContainer>
      <HeaderDiv>
        <>
          <HeaderText onClick={() => setSortState("time")}>
            Time of Event {sortColumn === "time" ? sortOrder ? <FaCaretDown /> : <FaCaretUp /> : ""}
          </HeaderText>
          <HeaderText onClick={() => setSortState("salesperson")}>
            Sales Rep{sortColumn === "salesperson" ? sortOrder ? <FaCaretDown /> : <FaCaretUp /> : ""}
          </HeaderText>
          <HeaderText onClick={() => setSortState("contact")}>
            Contact Name{sortColumn === "contact" ? sortOrder ? <FaCaretDown /> : <FaCaretUp /> : ""}
          </HeaderText>
          <HeaderText onClick={() => setSortState("event")}>
            Event{sortColumn === "event" ? sortOrder ? <FaCaretDown /> : <FaCaretUp /> : ""}
          </HeaderText>
        </>
      </HeaderDiv>

      {props.data
        .sort((a: any, b: any) => {
          if (a[sortColumn] < b[sortColumn]) {
            return sortOrder ? -1 : 1;
          }
          if (a[sortColumn] > b[sortColumn]) {
            return sortOrder ? 1 : -1;
          }
          return 0;
        })
        ?.map((item: any) => {
          return (
            <EventItem
              key={item.id}
              id={item.id}
              role={item.role}
              time={item.time}
              contact={item.contact}
              event={item.event}
              event_label={item.event_label}
              salesperson={item.salesperson}
              lead_id={item.lead_id}
              handleEditClick={props.handleEditClick}
            />
          );
        })}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  grid-template-columns: 1fr;
  padding: 17px 16px 0 23px;
  width: 1150px;
  height: 600px;
  background-color: ${theme.WHITE_COLOR};
  overflow: auto;
  border-radius: 7px;
  border: solid 4px ${theme.NEUTRAL100};
`;

const HeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 232px 277px 361px 110px 1fr;
  align-items: center;
  height: 40px;
`;

const HeaderText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  border-bottom: none;
`;

export { EventList };
