import React from "react";
import { useField } from "formik";
import { AppTextArea } from "../UI";

const FormAreaInputField = ({
  style,
  type = "text",
  ...props
}: {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  currency?: boolean;
  noBorder?: boolean;
  fixedSize?: boolean;
  borderColor?: string;
  fontSize?: number;
  width?: number;
  id?: string;
}) => {
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <AppTextArea {...props} {...field} error={meta.touched && meta.error} type={type} />
    </div>
  );
};

export { FormAreaInputField };
