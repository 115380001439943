import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading, AppErrorText, AppSidebarCard } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery, MutationFunctionOptions, FetchResult } from "@apollo/client";
import { SetStateAction, Dispatch } from "react";

interface CallChangeButtonsProps {
  showActionButtons: boolean;
  switchToColdCall: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  setNonScheduledCallBack: Dispatch<SetStateAction<boolean>>;
}

const CallChangeButtons: React.FC<CallChangeButtonsProps> = ({
  showActionButtons,
  setNonScheduledCallBack,
  switchToColdCall,
}) => {
  const setNonScheduledCallBackOnClick = () => {
    setNonScheduledCallBack(true);
  };

  const switchToColdCAllOnClick = async () => {
    await switchToColdCall({
      refetchQueries: [`fetchCurrentLead`],
    });
  };

  if (!showActionButtons) {
    return null;
  }

  return (
    <CallChangeButtonsDiv>
      <Spread>
        <StyledButton onClick={() => switchToColdCAllOnClick()}>MAKE ANOTHER COLD CALL</StyledButton>
        <StyledButton onClick={() => setNonScheduledCallBackOnClick()}>MAKE A NON SCHEDULED CALL BACK</StyledButton>
      </Spread>
    </CallChangeButtonsDiv>
  );
};

const CallChangeButtonsDiv = styled.div`
  width: 378px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px 0px;
`;

const Spread = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const StyledButton = styled(AppButton)`
  width: 186px;
  height: 30px;
  background: transparent;
  font-size: 9px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  border: 1px solid ${theme.TERTIARY500};
  border-radius: 2.3px;
`;
export { CallChangeButtons };
