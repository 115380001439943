import React, { useMemo } from "react";
import { DropResult, Droppable } from "react-beautiful-dnd";
import { DraggableFieldType } from "src/utils/variables";
import { CustomField, FormField } from "src/utils/misc";
import { DraggableFieldBase } from "./DraggableFieldBase";
import { Field } from "./Field";
import { FieldItem as FieldItemType } from "src/utils/misc";
import { RequiredField } from "./RequiredField";

type FieldItem = FieldItemType<FormField>;

const FormsFieldList: React.FC<{
  droppableId: string;
  handleFieldDragEnd?: (result: DropResult) => void;
  onChangeHandlers: {
    onFieldChange: (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => void;
    onFieldRequiredChange: (id: string, newRequired: boolean) => void;
    onFieldRadioChange: (id: string, newType: "system" | "custom") => void;
  };
  fields: FieldItem[] | undefined;
  customFieldData: CustomField[];
  availableSystemFields: string[];
  availableCustomFields: CustomField[];
  setSelectedFieldID: (id: string) => void;
  rowIndex?: number;
}> = ({
  availableCustomFields,
  availableSystemFields,
  customFieldData,
  droppableId,
  fields,
  onChangeHandlers: { onFieldChange, onFieldRequiredChange, onFieldRadioChange },
  setSelectedFieldID,
}) => {
  return (
    <Droppable droppableId={droppableId} type="FIELD">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: "50px", width: "100%" }}>
          {fields?.map((f: FieldItem, i: number) => {
            return (
              <DraggableFieldBase
                key={`drag-field-${f.id}`}
                type={f.baseType}
                index={i}
                id={f.id}
                setSelectedFieldID={setSelectedFieldID}
              >
                {f.baseType === DraggableFieldType.REQUIRED_FIELD && <RequiredField value={f?.system_field || ""} />}
                {f.baseType === DraggableFieldType.FIELD && (
                  <Field
                    availableCustomFields={availableCustomFields}
                    availableSystemFields={availableSystemFields}
                    customFieldData={customFieldData}
                    data={f}
                    index={f.order ?? i}
                    fieldName="form_fields"
                    onFieldChange={onFieldChange}
                    onFieldRadioChange={onFieldRadioChange}
                    onFieldRequiredChange={onFieldRequiredChange}
                  />
                )}
              </DraggableFieldBase>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default FormsFieldList;
