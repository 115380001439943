import styled from "styled-components";
import * as React from "react";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../../UI";
import { useState, useContext, useEffect } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { GridFilterContext } from "../../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { iconGreenCheck } from "../../../images/";
import { formatBusinessName } from "../../../utils/format";

const FETCH_PIPELINE_LIST = gql`
  query fetchPipelineList(
    $user_id: String
    $lead_id: String
    $team_id: String
    $organization_id: String
    $metric: String
    $lowerbound_date: Date
    $upperbound_date: Date
    $skip: Int
    $take: Int
    $dashboard_filter: DashboardFilter
  ) {
    fetchPipelineList(
      user_id: $user_id
      lead_id: $lead_id
      team_id: $team_id
      organization_id: $organization_id
      metric: $metric
      lowerbound_date: $lowerbound_date
      upperbound_date: $upperbound_date
      dashboard_filter: $dashboard_filter
      skip: $skip
      take: $take
    ) {
      id
      business_name
      first_name
      last_name
      set
      held
      close
      rep {
        id
        first_name
        last_name
      }
      phase_label
      created_at
      updated_at
    }
  }
`;

interface CallReportProps {
  userID?: string;
  teamID?: string;
  leadID?: string;
  orgID?: string;
  metric?: string;
  gridFilter: boolean;
  callReportPage?: boolean;
}

const PipelineListTable: React.FC<CallReportProps> = (props) => {
  const [sortAscending, setSortAscending] = useState(false);
  const { dateStart, dateEnd, products, channel, gridFilter } = useContext(GridFilterContext);

  const [reportsList, setReportsList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [hasToBeRefetched, setHasToBeRefetched] = useState(false);

  const takeNumber = 7;

  const { data: listData, loading: listLoading, error: listError, refetch, called } = useQuery(FETCH_PIPELINE_LIST, {
    variables: {
      user_id: !!props.userID ? props.userID : undefined,
      team_id: !!props.teamID ? props.teamID : undefined,
      lead_id: !!props.leadID ? props.leadID : undefined,
      organization_id: !!props.orgID ? props.orgID : undefined,
      metric: !!props.metric ? props.metric : undefined,
      lowerbound_date: dateStart,
      upperbound_date: dateEnd,
      skip: skip,
      take: takeNumber,
      // order_by: !!props.metric && props.metric === "Pipeline" ? "EventTime" : "CallTime",
      // desc: !sortAscending,
      dashboard_filter: {
        Channels: gridFilter.channels,
        LeadSources: gridFilter.lead_sources,
        LeadCreationSources: gridFilter.lead_creation_sources,
        primary_industries: gridFilter.industries,
        sub_industries: gridFilter.sub_industries,
      },
    },
    fetchPolicy: "network-only",
    onCompleted() {
      const newList = [...reportsList, ...listData.fetchPipelineList].reduce(
        (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      // setReportsList(reportsList.concat(listData.fetchPipelineList));
      if (listData.fetchPipelineList.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
  });

  const handleShowMore = () => {
    setSkip(skip + takeNumber);
    // refetch();
  };

  useEffect(() => {
    if (!called) {
      return;
    }
    setReportsList([]);
    setSkip(0);
    setHasMore(true);
  }, [
    props.gridFilter,
    props.leadID,
    props.metric,
    props.orgID,
    props.teamID,
    props.userID,
    channel,
    products,
    dateStart,
    dateEnd,
    sortAscending,
  ]);

  if (listError)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <AppErrorText>Error Loading Items</AppErrorText>
        <AppErrorText>{listError.message}</AppErrorText>
      </ListContainer>
    );

  // If no call reports available.
  if (!listLoading && reportsList.length === 0 && listData.fetchPipelineList === 0)
    return (
      <ListContainer
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        callReportPage={props.callReportPage}
      >
        <AppText>No reports to display.</AppText>
      </ListContainer>
    );
  return (
    <>
      {props.callReportPage && <TitleText>Call History</TitleText>}
      <ListContainer callReportPage={props.callReportPage} id="call-report-list">
        <InfiniteScroll
          dataLength={reportsList.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="call-report-list"
        >
          <HeaderDiv>
            <HeaderText
            // style={{
            //   cursor:
            //     props.metric && !["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
            //       ? "pointer"
            //       : "default",
            // }}
            // onClick={() =>
            //   props.metric &&
            //   !["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric) &&
            //   setSortAscending(!sortAscending)
            // }
            >
              Last Updated
            </HeaderText>
            <HeaderText>Contact Name</HeaderText>
            <HeaderText>Business Name</HeaderText>
            <HeaderText>Sales Rep</HeaderText>
            <HeaderText>
              {/* {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
                ? "Associated Action"
                : "Call Result"} */}
              Current Phase
            </HeaderText>
            <HeaderTextCenter>Set</HeaderTextCenter>
            <HeaderTextCenter>Held</HeaderTextCenter>
            <HeaderTextCenter>Closed</HeaderTextCenter>
            {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric) && (
              <HeaderText onClick={() => setSortAscending(!sortAscending)}>
                Time of Scheduled Activity {sortAscending ? <FaCaretUp /> : <FaCaretDown />}
              </HeaderText>
            )}
            <HeaderText />
          </HeaderDiv>
          {!!reportsList.length &&
            reportsList.slice()?.map((item: any, index: number) => {
              console.log(item, "-------");
              return (
                <ListItemDiv>
                  <ListItemText>{item.updated_at && moment(item.updated_at).format("MM/DD/YYYY h:mm A")}</ListItemText>
                  <ListItemText>{`${item.first_name} ${item.last_name}`}</ListItemText>
                  <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item.id}`}>
                    <ListItemText>{formatBusinessName(item.business_name)}</ListItemText>
                  </WrapAnchor>
                  <ListItemText>
                    {!!item.rep?.id ? `${item.rep?.first_name} ${item.rep?.last_name}` : "Unassigned"}
                  </ListItemText>
                  <ListItemText>{item.phase_label}</ListItemText>
                  <ListItemIcon>{item.set ? <img src={iconGreenCheck} height="10px" /> : "-"}</ListItemIcon>
                  <ListItemIcon>{item.held ? <img src={iconGreenCheck} height="10px" /> : "-"}</ListItemIcon>
                  <ListItemIcon>{item.closed ? <img src={iconGreenCheck} height="10px" /> : "-"}</ListItemIcon>
                </ListItemDiv>
              );
            })}
          {/* {listLoading === true && <Loading />} */}
        </InfiniteScroll>
      </ListContainer>
    </>
  );
};

const WrapAnchor = styled.a`
  max-width: 100px;
  text-overflow: ellipsis;
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  padding-right: 15px;
`;

const ListItemIcon = styled.div`
  place-self: center;
  font-size: 10px;
`;

const ListItemDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 35px 35px 35px;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
`;

const TitleText = styled(AppText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.NEUTRAL500};
  margin-top: 14px;
  margin-bottom: 5px;
`;

const HeaderDiv = styled.div`
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr 35px 35px 35px;
  align-items: center;
  justify-items: start;
  margin-top: 14px;
  height: 30px;
  top: 0px;
  background-color: ${theme.WHITE_COLOR};
`;

const HeaderText = styled(AppText)`
  font-size: 8px;
  font-weight: 500;
  border-bottom: none;
`;

const HeaderTextCenter = styled(AppText)`
  justify-self: center;
  font-size: 8px;
  font-weight: 500;
`;

interface CallReportPageProps {
  callReportPage?: boolean;
}

const ListContainer = styled.div<CallReportPageProps>`
  width: ${(props) => (props.callReportPage ? "947px" : "100%")};
  height: 299px;
  border-radius: ${(props) => (props.callReportPage ? "3px" : "16px")};
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  margin-left: ${(props) => (props.callReportPage ? "0px" : "0px")};
  margin-bottom: ${(props) => (props.callReportPage ? "50px" : "20px")};
  overflow: auto;
  padding: 0px 25px 0px 25px;
`;

export { PipelineListTable };
