import styled from "styled-components";
import * as React from "react";
import { useState, useEffect } from "react";
import { AppInput, AppButton, AppText, AppSidebarCard } from "../../UI";
import { theme } from "../../../utils/theme";
import { mobileSize } from "../../../utils/breakpoints";
import { HiOutlineSearch } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { GrFormEdit } from "react-icons/gr";
import { BsTrashFill } from "react-icons/bs";
import iconIndustry from "../../images/ic-industry.png";
import iconSource from "../../images/ic-source.png";
import { Link } from "react-router-dom";
import moment from "moment";
import Switch from "react-switch";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { DashboardSidebar } from "../DashboardSidebar";
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryArea,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryGroup,
} from "victory";
import { formatCellData, formatUSD } from "../../../utils/format";

const dummyData = [
  { x: 1, y: 2 },
  { x: 2, y: 3 },
  { x: 3, y: 5 },
  { x: 4, y: 4 },
  { x: 5, y: 7 },
];

const teamAverage = 3;
const companyAverage = 5;

interface GraphProps {
  data: any;
  bestIndividual: number;
  companyAverage: number;
  metricType: string;
}

const ProgressGraph: React.FC<GraphProps> = ({ data, bestIndividual, companyAverage, metricType }) => {
  return (
    <ChartDiv>
      <VictoryChart
        domainPadding={30}
        height={220}
        width={1040}
        style={{ parent: { zIndex: 1 } }}
        containerComponent={<VictoryVoronoiContainer />}
        theme={VictoryTheme.material}
      >
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: {
              letterSpacing: "1.67px",
              fontSize: "9px",
              color: "rgb(26, 26, 26)",
            },
            axis: { stroke: theme.NEUTRAL200, strokeWidth: 1 },
            grid: { stroke: theme.NEUTRAL200, strokeWidth: 1, strokeDasharray: "10,0" },
            ticks: { stroke: "transparent" },
          }}
          // tickFormat={(y) => `${Math.round(y * 1000000) / 1000000}`}
          tickFormat={(y) => `${y}`}
          // label="Number"
        />
        <VictoryAxis
          style={{
            tickLabels: {
              fontSize: "9px",
              color: "rgb(26, 26, 26)",
            },
            axis: { stroke: theme.NEUTRAL200, strokeWidth: 1 },
            grid: { stroke: theme.NEUTRAL200, strokeWidth: 1, strokeDasharray: "10,0" },
            ticks: { stroke: "transparent" },
          }}
          tickFormat={(x) => (!!x.length ? `${x?.split(" ").join("\n")}` : "")}
          // label="Date"
        />
        <VictoryLine
          y={() => companyAverage}
          labels={({ datum }: any) =>
            `50th percentile in the Organization: ${formatCellData(companyAverage, metricType)}`
          }
          labelComponent={
            <VictoryTooltip
              cornerRadius={3}
              style={{ fontSize: "9px", fill: `${theme.WHITE_COLOR}` }}
              flyoutStyle={{
                stroke: "none",
                fill: "black",
              }}
              flyoutPadding={({ text }) => ({ top: 4, bottom: 4, left: 16, right: 16 })}
            />
          }
          style={{
            data: {
              stroke: `${theme.PRIMARY500}`,
              fillOpacity: 1,
              strokeWidth: 2,
              strokeDasharray: "8,3",
            },
            labels: {
              fill: "white",
            },
          }}
        />
        <VictoryLine
          y={() => bestIndividual}
          labels={({ datum }: any) =>
            `80th percentile in the Organization: ${formatCellData(bestIndividual, metricType)}`
          }
          labelComponent={
            <VictoryTooltip
              cornerRadius={3}
              style={{ fontSize: "9px", fill: `${theme.WHITE_COLOR}`, margin: `0px 4px 0px 4px` }}
              flyoutStyle={{
                stroke: "none",
                fill: "black",
              }}
              flyoutPadding={({ text }) => ({ top: 4, bottom: 4, left: 16, right: 16 })}
            />
          }
          style={{
            data: {
              stroke: `${theme.SUCCESS500}`,
              fillOpacity: 1,
              strokeWidth: 2,
              strokeDasharray: "8,3",
            },
            labels: {
              fill: "white",
            },
          }}
        />
        <VictoryLine
          data={[{ y: 0 }]}
          style={{
            data: { stroke: "transparent" },
            parent: { border: "1px solid transparent" },
          }}
        />
        <VictoryLine
          data={[{ y: 1 }]}
          style={{
            data: { stroke: "transparent" },
            parent: { border: "1px solid transparent" },
          }}
        />
        <VictoryGroup data={data} x="x" y="y">
          <VictoryLine
            x="x"
            y="y"
            labels={({ datum }: any) => datum.label}
            labelComponent={
              <VictoryTooltip
                cornerRadius={3}
                style={{ fontSize: "9px", fill: `${theme.WHITE_COLOR}` }}
                flyoutStyle={{
                  stroke: "none",
                  fill: "black",
                  // display: ({ datum }) => (datum.y === 0 ? "none" : "auto"),
                }}
                flyoutPadding={({ text }) => ({ top: 4, bottom: 4, left: 16, right: 16 })}
              />
            }
            style={{
              data: {
                stroke: ({ datum }) => (datum?.y === 0 ? `${theme.ATTENTION500}` : `${theme.TERTIARY500}`),
                fill: "transparent",
                strokeWidth: 3,
              },
              labels: { fill: "white" },
            }}
          />
          <VictoryScatter
            x="x"
            y="y"
            style={{
              data: {
                fill: theme.WHITE_COLOR,
                stroke: theme.TERTIARY500,
                fillOpacity: 1,
                strokeWidth: 2,
                // display: ({ datum }) => (datum.y === 0 ? "none" : "auto"),
              },
              labels: {
                display: "none",
                opacity: 0,
              },
            }}
            size={4}
          />
        </VictoryGroup>
      </VictoryChart>
    </ChartDiv>
  );
};

const ChartDiv = styled.div``;

export { ProgressGraph };
