import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext, useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context";
import { iconInviteAccepted } from "../../images";
import { hubSpotDefinition, salesforceDefinition } from "../../static/definitions";
import { downloadResourceAWS } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppErrorText, AppText, Loading } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  integration_mapping_id: string;
}

interface FieldsData {
  external_field: string;
  match: boolean;
  opsiq_field: string;
}

const FETCH_MAPPING_FIELD_SELECT_OPTION = gql`
  query fetchMappingFieldSelectOption($integration_mapping_id: String!) {
    fetchMappingFieldSelectOption(integration_mapping_id: $integration_mapping_id) {
      external_field
      match
      opsiq_field
    }
  }
`;

const SYNC_ALL_MAPPING_FIELD = gql`
  mutation syncAllMappingFieldSelectOption($integration_mapping_id: String!) {
    syncAllMappingFieldSelectOption(integration_mapping_id: $integration_mapping_id)
  }
`;

const SYNC_ONE_MAPPING_FIELD = gql`
  mutation syncMappingFieldSelectOption($integration_mapping_id: String!, $select_option_value: String!) {
    syncMappingFieldSelectOption(
      integration_mapping_id: $integration_mapping_id
      select_option_value: $select_option_value
    )
  }
`;

const EXPORT_INTEGRATION_PICKLIST_OPTIONS = gql`
  mutation exportIntegrationPickListOptions($integration_mapping_id: String!, $integration_type: INTEGRATION_TYPE!) {
    exportIntegrationPickListOptions(
      integration_mapping_id: $integration_mapping_id
      integration_type: $integration_type
    ) {
      url
    }
  }
`;
const IntegrationMapSyncPicklist: React.FC<DisappearingDivProps> = ({ integration_mapping_id }) => {
  const { integrationMapPicklistModal, setIntegrationMapPicklistModal, mapStateData, selectedIntegration } = useContext(
    ModalContext,
  );

  const [
    fetchMappingFieldSelectOption,
    { data: dataOptions, loading: loadingOptions, error: errorOptions },
  ] = useLazyQuery(FETCH_MAPPING_FIELD_SELECT_OPTION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [syncAllMappingField, { loading }] = useMutation(SYNC_ALL_MAPPING_FIELD, {
    onCompleted({ syncAllMappingFieldSelectOption }) {
      if (!syncAllMappingFieldSelectOption) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("Updated mappings");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `syncAllMappingFieldSelectOption GraphQL Error: ${message}`,
      });
      console.log("Error in syncAllMappingFieldSelectOption: ", message);
    },
    refetchQueries: [{ query: FETCH_MAPPING_FIELD_SELECT_OPTION, variables: { integration_mapping_id } }],
  });

  const [syncOneMappingField] = useMutation(SYNC_ONE_MAPPING_FIELD, {
    onCompleted({ syncMappingFieldSelectOption }) {
      if (!syncMappingFieldSelectOption) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("Updated mappings");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `syncMappingFieldSelectOption GraphQL Error: ${message}`,
      });
      console.log("Error in syncMappingFieldSelectOption: ", message);
    },
    refetchQueries: [{ query: FETCH_MAPPING_FIELD_SELECT_OPTION, variables: { integration_mapping_id } }],
  });

  const [exportIntegrationPicklistOptions] = useMutation(EXPORT_INTEGRATION_PICKLIST_OPTIONS, {
    async onCompleted({ exportIntegrationPickListOptions }) {
      if (!exportIntegrationPickListOptions) {
        appToast("Error exporting calls. Something went wrong.");
        return;
      }
      appToast("Success! Download should begin shortly");
      !!exportIntegrationPickListOptions.url && downloadResourceAWS(exportIntegrationPickListOptions.url);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `exportIntegrationPickListOptions GraphQL Error: ${message}`,
      });
    },
  });
  useEffect(() => {
    console.log("integration_mapping_id", integration_mapping_id);
    fetchMappingFieldSelectOption({ variables: { integration_mapping_id } });
  }, [integration_mapping_id]);

  const mapSchema = Yup.object().shape({
    integration_mapping_id: Yup.string(),
  });

  const matchedItem = (item: FieldsData, value: string) => {
    if (item.match) {
      return (
        <TableText>
          <img src={iconInviteAccepted} style={{ marginRight: "5px" }} /> {value}
        </TableText>
      );
    } else {
      return <TableText style={{ color: "#F9463F" }}>{value}</TableText>;
    }
  };

  const syncOneMapping = (item: FieldsData) => {
    syncOneMappingField({
      variables: {
        integration_mapping_id,
        select_option_value: item.external_field,
      },
    });
  };
  const individualSyncBox = (item: FieldsData) => {
    if (!item.match && !item.opsiq_field) {
      return (
        <SyncOneButton hoverVariant="secondary" onClick={() => syncOneMapping(item)}>
          SYNC TO SELLFIRE
        </SyncOneButton>
      );
    }
    return;
  };
  console.log("selectedIntegration", selectedIntegration);
  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Formik
        initialValues={{
          integration_mapping_id,
        }}
        onSubmit={async (values) => {
          await syncAllMappingField({
            variables: {
              integration_mapping_id,
            },
          });
        }}
        validationSchema={mapSchema}
      >
        {({
          submitForm,
          setFieldValue,
          isValid,
          isSubmitting,
          values,
        }: FormikProps<{ integration_mapping_id: string }>) => {
          return (
            <PopupContainerDiv>
              <Modal
                open={integrationMapPicklistModal}
                onClose={() => setIntegrationMapPicklistModal(false)}
                closeButtonSize={16}
              >
                <PaddingDiv>
                  <SectionTitle>{selectedIntegration} Field Mapping Discrepancies</SectionTitle>
                  <SectionSubTitle>
                    {selectedIntegration == "SalesForce" && salesforceDefinition}
                    {selectedIntegration == "HubSpot" && hubSpotDefinition}
                  </SectionSubTitle>
                  <CampaignTable selectedIntegration={selectedIntegration} index={0}>
                    <TableHeadingText>Sellfire Field/Dropdown Value</TableHeadingText>
                    <TableHeadingText>{selectedIntegration} Field/Dropdown Value</TableHeadingText>
                    <SyncAllButton
                      hoverVariant="primary"
                      variant="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      SYNC ALL TO SELLFIRE
                    </SyncAllButton>
                  </CampaignTable>
                  {loadingOptions || loading ? (
                    <Loading />
                  ) : errorOptions ? (
                    <AppErrorText>Error loading mapping data</AppErrorText>
                  ) : (
                    dataOptions &&
                    dataOptions.fetchMappingFieldSelectOption &&
                    dataOptions.fetchMappingFieldSelectOption?.map((item: any, index: number) => {
                      return (
                        <CampaignTable selectedIntegration={selectedIntegration} index={index + 1}>
                          {matchedItem(item, item.opsiq_field)}
                          {matchedItem(item, item.external_field)}
                          <TableText>{individualSyncBox(item)}</TableText>
                        </CampaignTable>
                      );
                    })
                  )}
                </PaddingDiv>
                <ButtonContainer>
                  <DownloadButton
                    hoverVariant="secondary"
                    onClick={() => {
                      exportIntegrationPicklistOptions({
                        variables: {
                          integration_mapping_id: values.integration_mapping_id,
                          integration_type: selectedIntegration,
                        },
                      });
                    }}
                  >
                    Download CSV
                  </DownloadButton>
                  <DoneButton
                    hoverVariant="primary"
                    onClick={() => {
                      setIntegrationMapPicklistModal(false);
                    }}
                  >
                    Done
                  </DoneButton>
                </ButtonContainer>
              </Modal>
            </PopupContainerDiv>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  width: 100%;
  text-align: center;
`;

const SectionSubTitle = styled.div`
  padding-top: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  min-width: 656px;
  max-width: 656px;
  text-align: center;
`;

const PaddingDiv = styled.div`
  padding: 24px;
  height: 572px;
  max-height: 60vh;
  overflow: auto;
`;

interface CampaignTableProps {
  selectedIntegration?: string;
  index: number;
}

const CampaignTable = styled.div<CampaignTableProps>`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;

  //string literals for props w/ grid template
  grid-template-columns: 1fr 1fr 134px;
  grid-template-rows: 40px;
  align-items: stretch;
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${(props) => (props.index % 2 === 0 ? theme.WHITE_COLOR : `rgba(237, 238, 242, 0.4)`)};
`;

const TableHeadingText = styled(AppText)`
  padding: 11px 16px;
  width: 100%;
  height: 100%;
  vertical-align: center;
  color: #949ab1;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;

const PopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 9;
`;

const TableText = styled(AppText)`
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 11px;
  width: 100%;
  letter-spacing: 0.2px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
  border: 1px solid ${theme.NEUTRAL100};
  padding: 2rem;
`;

const ErrorButton = styled(NewAppButton)`
  :active {
    background-color: ${theme.PRIMARY800};
  }
`;

const SyncOneButton = styled(NewAppButton)`
  height: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #3e65ff;
  border: 1px solid #3e65ff;
  border-radius: 4px;
`;

const SyncAllButton = styled(NewAppButton)`
  height: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 14px;
  display: block;
  align-self: center;
  letter-spacing: 0.05em;
  color: ${theme.WHITE_COLOR};
  border-radius: 4px;
  min-width: 102px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid ${theme.NEUTRAL200};
  padding: 20px;
`;

const DownloadButton = styled(NewAppButton)`
  justify-content: center;
  padding: 16px;
  gap: 8px;
  width: 188px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dcdde5;
  border-radius: 8px;
  color: #3e65ff;
`;

const DoneButton = styled(NewAppButton)`
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  width: 188px;
  height: 48px;
  background: #3e65ff;
  border-radius: 8px;
  color: #ffffff;
  margin-left: 16px;
`;

export { IntegrationMapSyncPicklist };
