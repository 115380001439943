import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { CustomObjectSummary } from "../../../__generated__/graphql";
import { AppText, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
// Styled component for the card container

interface RelatedRecordSummaryProp {
  customObjectSummary?: CustomObjectSummary[];
  lead_id: string;
  viewRecord: (id: string) => void;
}

const RelatedRecordsCard = ({ customObjectSummary, viewRecord }: RelatedRecordSummaryProp) => {
  return (
    <CardContainer>
      <CategoriesContainer>
        {customObjectSummary &&
          customObjectSummary?.map((el, index) => {
            return (
              <FlexDiv key={index} gap={4}>
                <AppText fontSize={14} fontWeight={500}>
                  {el.name}
                </AppText>

                <AppText
                  color={theme.text.brand.primary}
                  cursor="pointer"
                  fontSize={14}
                  fontWeight={500}
                  onClick={() => viewRecord(el?.id as string)}
                  showBorderOnClick={false}
                >
                  ({el.association_count})
                </AppText>
              </FlexDiv>
            );
          })}
      </CategoriesContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  border-radius: 0px 0px 8px 8px;
  padding: 16px;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
`;

export default RelatedRecordsCard;
