import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CustomObjectRecord, CustomObjectRow, CustomObjectSummary } from "../../../__generated__/graphql";
import { edit } from "../../../images/NewDesign";
import { handleCustomObjectRecordDataType } from "../../../utils/other-functions";
import { AppSidebarCard, AppText, Loading } from "../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { EditCustomObjectFieldsComponent } from "../../modal/EditCustomObject";
import { useDebounce } from "../../../utils/hooks";
import { debounce } from "lodash";
import { theme } from "../../../utils/theme";
import { isValidURL } from "../../../utils/misc";

const FETCH_ALL_CUSTOM_OBJECT_FIELDS = gql`
  query fetchAllCustomObjectFields($custom_object_id: String!) {
    fetchAllCustomObjectFields(custom_object_id: $custom_object_id) {
      id
      name
      type
      list_option
      allow_reps_to_edit
      visible
      show_on_lead_preview
      is_name_field
    }
  }
`;

// fetchCustomObjectRowsFromLead
export const FETCH_CUSTOM_OBJECT_ROWS_FROM_LEAD = gql`
  query fetchCustomObjectRowsFromLead($lead_id: String!, $custom_object_id: String!, $skip: Int!, $take: Int!) {
    fetchCustomObjectRowsFromLead(lead_id: $lead_id, custom_object_id: $custom_object_id, skip: $skip, take: $take) {
      id
      record {
        id
        num_value
        string_value
        list_value
        date_value
        boo_value
        object_field_id
        object_field {
          id
          name
          visible
          show_on_lead_preview
          type
          is_name_field
        }
      }
    }
  }
`;

interface RelatedRecordCardDataProps {
  lead_id: string;
  customObjectSummary?: CustomObjectSummary[];
}

const RelatedRecordCardData: React.FC<RelatedRecordCardDataProps> = ({ lead_id, customObjectSummary }) => {
  if (!customObjectSummary) return null;
  // limit to 3 entities
  const entities = customObjectSummary.slice(0, 3);

  return (
    <>
      {entities?.map((object) => (
        <React.Fragment key={`lead-details-${object.id}`}>
          <CustomObjectEntityDetail
            lead_id={lead_id}
            customObjectDetail={object}
            renderType="LeadDetail"
            key={`lead-details-${object.id}`}
          />
        </React.Fragment>
      ))}
    </>
  );
};

interface RelatedRecordCardDataDetailProps {
  lead_id: string;
  customObjectDetail?: CustomObjectSummary;
  renderType?: "LeadCard" | "LeadDetail";
  inLeadDetail?: boolean;
  isContained?: boolean;
}

export const CustomObjectEntityDetail: React.FC<RelatedRecordCardDataDetailProps> = React.memo(
  ({ lead_id, customObjectDetail, renderType = "LeadDetail", isContained }) => {
    const [showModal, setShowModal] = useState(false);
    const [rows, setRows] = useState<CustomObjectRow[]>([]);
    const [selectedRow, setSelectedRow] = useState<CustomObjectRow | null>(null);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const takeNumber = 20;

    const handleCreateNewEvent = () => {
      if (!showModal === true) {
        setSelectedRow(null);
      }
      setShowModal(!showModal);
    };

    const history = useHistory();

    const { data } = useQuery(FETCH_ALL_CUSTOM_OBJECT_FIELDS, {
      variables: { custom_object_id: customObjectDetail?.id || "" },
      fetchPolicy: "cache-and-network",
    });

    useQuery(FETCH_CUSTOM_OBJECT_ROWS_FROM_LEAD, {
      variables: { lead_id, custom_object_id: customObjectDetail?.id || "", skip, take: takeNumber },
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        const newList = Array.from(
          new Map([...rows, ...data.fetchCustomObjectRowsFromLead]?.map((item) => [item.id, item])).values(),
        );
        setRows(newList);
        if (data.fetchCustomObjectRowsFromLead.length < takeNumber) {
          setHasMore(false);
        }
      },
    });

    const handleShowMore = () => {
      if (!hasMore) return; // Prevent fetching more if no more data is available
      const newSkip = skip + takeNumber;
      setSkip(newSkip);
    };

    const renderModal = () => {
      if (showModal) {
        return (
          <EditCustomObjectFieldsComponent
            custom_object_id={customObjectDetail?.id || ""}
            name={customObjectDetail?.name || ""}
            closeCustomObjectFieldsModal={handleCreateNewEvent}
            showEditCustomObjectFields={showModal}
            lead_id={lead_id}
            custom_object_fields={data?.fetchAllCustomObjectFields}
            selectedRow={selectedRow}
            customHeight
          />
        );
      }
    };

    const LeadDetailStyle = () => {
      return (
        <AppSidebarCard
          title={customObjectDetail?.name || ""}
          count={+(customObjectDetail?.association_count ?? 0)}
          textcolor={theme.text.neutral.primary}
          fontSize={14}
          fontWeight={500}
          color={theme.fill.brand.secondary}
          viewAll={true}
          handleViewAllEvent={() => {
            history.push(`/lead-detail/${lead_id}/custom-object/${customObjectDetail?.id}`);
          }}
          createNew={true}
          handleCreateNewEvent={handleCreateNewEvent}
          marginBottom={"16px"}
        >
          <ScrollingDiv id={`${customObjectDetail?.id}-div`} smallHeight={true}>
            <div style={{ padding: "0px 8px" }}>
              <InfiniteScroll
                dataLength={rows.length}
                next={handleShowMore}
                hasMore={hasMore}
                loader={<Loading />}
                scrollableTarget={`${customObjectDetail?.id}-div`}
              >
                {rows?.map((row: CustomObjectRow, index: number) => {
                  const name_field =
                    row.record.find((record) => record.object_field.is_name_field)?.string_value || "No Name";
                  return (
                    <RecordContainer key={index}>
                      <CoreComponent name_field={name_field} row={row} />
                    </RecordContainer>
                  );
                })}
              </InfiniteScroll>
            </div>
          </ScrollingDiv>
        </AppSidebarCard>
      );
    };

    const LeadCardStyle = () => {
      return (
        <ScrollingDiv id={`${customObjectDetail?.id}-lead-card-div`} smallHeight={false}>
          <InfiniteScroll
            dataLength={rows.length}
            next={handleShowMore}
            hasMore={hasMore}
            loader={<Loading />}
            scrollableTarget={`${customObjectDetail?.id}-lead-card-div`}
          >
            {rows?.map((row: CustomObjectRow, index: number) => {
              const name_field =
                row.record.find((record) => record.object_field.is_name_field)?.string_value || "No Name";
              return (
                <LeadCardRecordContainer key={index}>{CoreComponent({ name_field, row })}</LeadCardRecordContainer>
              );
            })}
          </InfiniteScroll>
        </ScrollingDiv>
      );
    };

    // Memoizing the function to handle the event of viewing a record
    const handleViewRecord = useCallback(
      (rowId: string) => {
        history.push({
          pathname: `/record-detail/${rowId}/custom-object/${customObjectDetail?.id}`,
          state: { object_name: customObjectDetail?.name || "" },
        });
      },
      [history, customObjectDetail?.id, customObjectDetail?.name],
    );

    // Memoizing the function to toggle the modal and optionally clear the selected row
    const handleToggleModal = useCallback((row: CustomObjectRow) => {
      setShowModal((prevShowModal) => {
        if (prevShowModal) {
          setSelectedRow(null);
        } else {
          setSelectedRow(row);
        }
        return !prevShowModal;
      });
    }, []);

    const CoreComponent = ({ name_field, row }: { name_field: string; row: CustomObjectRow }) => {
      return (
        <Main isContained={isContained}>
          <HeaderDiv>
            <LeftHeader>
              <NameFieldWrapper
                onClick={() => {
                  handleViewRecord(row.id);
                }}
              >
                <NameFieldText>{name_field}</NameFieldText>
              </NameFieldWrapper>
            </LeftHeader>
          </HeaderDiv>
          <ScrollingDiv id={`${customObjectDetail?.id}-div-other`} smallHeight={true}>
            {row?.record?.map((record: CustomObjectRecord, index: number) => {
              // todo update this type (computer creashes when trying to update create graphQL file need to sync on how/why that was used)
              // @ts-ignore

              if (record.object_field.visible && record?.object_field?.show_on_lead_preview) {
                const value = handleCustomObjectRecordDataType(record, record.object_field.type);
                if (value === "N/A") return null;
                return (
                  <GridDiv>
                    <FieldName>{record.object_field.name}</FieldName>
                    <FieldValue>
                      {isValidURL(value) ? (
                        <a href={`${value?.startsWith("http") ? "" : "//"}${value}`} target="_blank" rel="noreferrer">
                          {value}
                        </a>
                      ) : (
                        value
                      )}
                    </FieldValue>
                  </GridDiv>
                );
              }
            })}
          </ScrollingDiv>
        </Main>
      );
    };

    const RenderComponent = useMemo(() => {
      if (renderType === "LeadDetail") {
        return <LeadDetailStyle />;
      } else {
        return <LeadCardStyle />;
      }
    }, [rows]);

    // RENDER COMPONENT
    return (
      <>
        {renderModal()}
        {RenderComponent}
      </>
    );
  },
);

const Main = styled.div<{ isContained?: boolean }>`
  ${({ isContained }) =>
    isContained &&
    `
    background: ${theme.WHITE_COLOR};
    border: 1px solid ${theme.NEUTRAL200};
    border-radius: 4px;
    padding: 4px 0;
    
  `}
`;

interface ScrollingDivProps {
  inLeadDetail?: boolean;
  smallHeight?: boolean;
}

const ScrollingDiv = styled.div<ScrollingDivProps>`
  max-height: ${(props) => (props?.smallHeight ? "300px" : "auto")};
  overflow-y: auto;
  ${(props) => props.inLeadDetail && "padding: 0px 8px"};
`;

const RecordContainer = styled.div`
  padding-bottom: 4px;
  border-bottom: 1px solid var(--border-neutral-secondary, #e1e2e7);
`;

const LeadCardRecordContainer = styled.div`
  margin-top: 4px;
`;

const HeaderDiv = styled.div`
  display: flex;
  padding: 8px 8px 4px 8px;
  align-items: center;
  justify-content: space-between;
`;

const GridDiv = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 0px 8px 4px 8px;
`;

const FieldName = styled(AppText)`
  color: ${theme.text.neutral.secondary};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FieldValue = styled(AppText)`
  color: ${theme.text.neutral.secondary};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameFieldText = styled(AppText)`
  color: ${theme.text.brand.primary};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
  }
`;

const LeftHeader = styled.div``;

const NameFieldWrapper = styled.div`
  cursor: pointer;
`;

const RightHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export default RelatedRecordCardData;
