import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { AdvancedImage } from "@cloudinary/react";
import * as Sentry from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import { restAPI } from "../../../apollo";
import { currentCallState } from "../../../apollo/cache";
import { CallContext } from "../../../context";
import transferPending from "../../../images/lotties/transferPending.json";
import { ABLY_OPTIONS } from "../../../services/ably";
import { cloudinary } from "../../../services/cloudinary";
import { formatTimeDifferenceByDelineation } from "../../../utils/format";
import { useDebouncedState } from "../../../utils/hooks";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { BACKEND_URL, DEFAULT_CLOUDINARY_IMAGE } from "../../../utils/variables";
import { AppText, Loading, NewAppButton } from "../../UI";
import { transferFailure } from "../../../images/lotties";
import { PhoenixAppButton, PhoenixInput } from "../../UI/Phoenix";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { PhoenixInputField } from "../../Field/Phoenix";
import { TransferAttemptStatus } from "../../../__generated__/graphql";

type ExternalTransferStepOneProps = {
  callSid: string;
  transferCall: (callSid: string, phoneNumber: string) => void;
};
const ExternalStepOne: React.FC<ExternalTransferStepOneProps> = ({ callSid, transferCall }) => {
  const phoneNumberSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Only numbers are allowed")
      .min(10, "Phone number must be at least 10 characters")
      .required("Phone number is required"),
    formattedNumber: Yup.string(),
  });

  return (
    <Formik
      initialValues={{ phoneNumber: "" }}
      validationSchema={phoneNumberSchema}
      onSubmit={(values) => {
        transferCall(callSid!, values.phoneNumber);
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <PhoneNumberContainer>
            <PhoneLabelDiv>
              <PhoneNumberLabel>Phone Number</PhoneNumberLabel>
            </PhoneLabelDiv>
            <PhoneNumberDiv>
              <ExtensionDiv>
                <ExtensionText>+1</ExtensionText>
              </ExtensionDiv>
              <PhoenixInputField
                id="phoneNumber"
                name="phoneNumber"
                placeholder="0000000000"
                onChange={(e) => {
                  setFieldValue("phoneNumber", e.target.value);
                }}
                checked={!errors.phoneNumber && values.phoneNumber.length === 10}
                checkedColor={theme.SUCCESS500}
              />
            </PhoneNumberDiv>

            <PhoenixAppButton
              buttonType="primary"
              type="submit"
              width={200}
              disabled={!errors.phoneNumber && values.phoneNumber.length === 10 ? false : true}
            >
              TRANSFER
            </PhoenixAppButton>
          </PhoneNumberContainer>
        </Form>
      )}
    </Formik>
  );
};

// Styled components
const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  margin-top: 150px;
`;

const PhoneLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-width: 400px;
`;

const PhoneNumberLabel = styled(AppText)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const PhoneNumberDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 400px;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 40px;
`;

const ExtensionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const ExtensionText = styled(AppText)`
  text-align: center;
  font-size: 12px;
  color: ${theme.NEUTRAL300};
`;

export { ExternalStepOne };
