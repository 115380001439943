import React, { useContext } from "react";
import styled from "styled-components";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { CallReportSegment } from "../CallReportSegments";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { FlexDiv } from "../../UI";
import { ModalContext } from "../../../context";

interface SoundbitesTabProps {
  refetch_call_report: () => void;
  deleteCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  is_call_live: boolean;
  start_time: string;
  salesperson: string;
  recording_url: string;
  peakData: any;
  conference_id: string;
  lead: any;
  formattedCoachingNotes: any;
  conferenceData: any;
  aiProcessing: boolean;
}

export const SoundbitesTab: React.FC<SoundbitesTabProps> = ({
  salesperson,
  recording_url,
  peakData,
  conference_id,
  lead,
  formattedCoachingNotes,
  refetch_call_report,
  deleteCoachingNote,
  addCoachingNote,
  editCoachingNote,
  is_call_live,
  start_time,
  aiProcessing,
  conferenceData,
}) => {
  const { setShowSoundbiteModal } = useContext(ModalContext);

  return (
    <FlexDiv direction="column" justify="space-between" height="100%">
      <SoundbitesContainer>
        <PhoenixAppButton buttonType="secondary" onClick={() => setShowSoundbiteModal(true)}>
          Create Soundbite
        </PhoenixAppButton>
      </SoundbitesContainer>
      <CallReportSegment
        salesperson={salesperson}
        url={recording_url}
        peakData={peakData}
        conference_id={conference_id}
        lead_data={lead}
        coaching_notes={formattedCoachingNotes}
        refetch_call_report={refetch_call_report}
        deleteCoachingNote={deleteCoachingNote}
        addCoachingNote={addCoachingNote}
        editCoachingNote={editCoachingNote}
        is_call_live={is_call_live}
        start_time={start_time}
        aiProcessing={aiProcessing}
        showTimeline={conferenceData?.duration >= 20}
        callDuration={conferenceData?.duration}
      />
    </FlexDiv>
  );
};

const SoundbitesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 24px;
`;
