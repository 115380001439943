import * as React from "react";
import { useEffect, useState } from "react";
import { connectionToast } from "src/utils/toast";

// Add this interface at the top of the file
interface NetworkInformation extends EventTarget {
  readonly downlink: number;
  readonly effectiveType: string;
  readonly rtt: number;
  readonly saveData: boolean;
  readonly type: string;
  onchange: EventListener;
}

// Instead of extending Navigator directly, use declaration merging
declare global {
  interface Navigator {
    connection?: NetworkInformation | null;
    mozConnection?: NetworkInformation | null;
    webkitConnection?: NetworkInformation | null;
  }
}

const ConnectionToast = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [networkQuality, setNetworkQuality] = useState("good");

  useEffect(() => {
    // Network monitoring
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => {
      setIsOnline(false);
      setNetworkQuality("offline");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Network Quality Monitoring
    const connection = navigator?.connection || navigator?.mozConnection || navigator?.webkitConnection;

    // const navigatorWithConnection = navigator as NavigatorWithConnection | undefined;

    // const connection = navigatorWithConnection
    //   ? nav.connection ||
    //     navigatorWithConnection.mozConnection ||
    //     navigatorWithConnection.webkitConnection
    //   : undefined;

    const updateNetworkQuality = () => {
      if (!connection) return;
      const { effectiveType, downlink, rtt } = connection;

      if (effectiveType === "4g" && downlink > 2 && rtt <= 500) {
        setNetworkQuality("good");
      } else if ((effectiveType === "4g" && (downlink <= 2 || rtt > 500)) || (effectiveType === "3g" && rtt <= 750)) {
        setNetworkQuality("poor");
        connectionToast(
          "Unstable internet connection",
          undefined,
          "Your internet connection is weak, which may affect your call quality. Try rebooting your Wi-Fi router or connecting to a different network.",
        );
      } else {
        setNetworkQuality("very poor");
        connectionToast(
          "Unstable internet connection",
          undefined,
          "Your internet connection is weak, which may affect your call quality. Try rebooting your Wi-Fi router or connecting to a different network.",
        );
      }
    };

    if (connection) {
      connection.addEventListener("change", updateNetworkQuality);
      updateNetworkQuality();
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (connection) connection.removeEventListener("change", updateNetworkQuality);
    };
  }, []);

  // useEffect(() => {
  //   if (!isOnline) {
  //     connectionToast("You are currently offline.", undefined, "Please check your internet connection.");
  //   }
  //   if (isOnline && (networkQuality === "poor" || networkQuality === "very poor")) {
  //     connectionToast(
  //       "Unstable internet connection",
  //       undefined,
  //       "Your internet connection is weak, which may affect your call quality. Try rebooting your Wi-Fi router or connecting to a different network.",
  //     );
  //   }
  // }, [networkQuality, isOnline]);

  return (
    <>
      {/* {!isOnline && (
        <div style={{ color: "white", backgroundColor: "red", padding: "10px" }}>
          ⚠️ You are currently offline. Some features may not be available.
        </div>
      )}
      {networkQuality === "poor" && (
        <div style={{ color: "white", backgroundColor: "orange", padding: "10px" }}>
          ⚠️ Network quality is poor. Some features may be slow or limited.
        </div>
      )}
      {networkQuality === "very poor" && (
        <div style={{ color: "white", backgroundColor: "red", padding: "10px" }}>
          ⚠️ Network quality is very poor. Features may be heavily impacted.
        </div>
      )} */}
    </>
  );
};

export default ConnectionToast;
