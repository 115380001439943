import React from "react";
import styled from "styled-components";
import { FieldWrapper, MultiFieldTextSelect, TitleLabel } from "./shared";

type MultiTextFieldType = {
  id: string;
  cf_key: string;
  setFieldValue: any;
  value?: string[];
  disabled?: boolean;
  fieldName: string;
  label?: string;
  index: number;
};

export const MultiTextField = ({
  id,
  cf_key,
  setFieldValue,
  value,
  disabled,
  fieldName,
  label,
  index,
}: MultiTextFieldType) => {
  const customComponents = {
    ClearIndicator,
    DropdownIndicator,
    IndicatorSeparator,
  };

  return (
    <FieldWrapper key={id}>
      <TitleLabel>{label ?? cf_key}</TitleLabel>
      <MultiFieldTextSelect
        isMulti={true}
        isDisabled={disabled}
        onChange={(newValue: any) => {
          setFieldValue(`${fieldName}[${index}].list_value`, newValue);
        }}
        placeholder="Enter Multi Text values"
        components={customComponents}
        value={value}
        hideSelectedOptions={true}
      />
    </FieldWrapper>
  );
};

const ClearIndicator = styled.div`
  display: none;
`;

const DropdownIndicator = styled.div`
  display: none;
`;

const IndicatorSeparator = styled.div`
  display: none;
`;
