import React, { useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText } from "..";

interface CallResultPillProps {
  result: string;
  colorOverride?: string;
  backgroundColorOverride?: string;
}

export const CallResultPill: React.FC<CallResultPillProps> = ({ result, colorOverride, backgroundColorOverride }) => {
  const isSale = result?.toLowerCase() === "sale";
  const isSpokeWith = ["dm", "ndm", "dmviandm", "nocontact"].includes(result?.toLowerCase());
  const color = isSale || isSpokeWith ? theme.text.neutral.inverse : theme.text.brand.primary;
  const backgroundColor = isSale || isSpokeWith ? theme.fill.brand.primary : theme.fill.brand.secondary;

  const formattedResult = useMemo(() => {
    switch (result?.toLowerCase()) {
      case "dmviandm":
        return "DM via NDM";
      case "nocontact":
        return "No Contact";
      default:
        return result;
    }
  }, [result]);

  return (
    <PillContainer backgroundColor={backgroundColorOverride || backgroundColor}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={colorOverride || color}>
        {formattedResult || "No Result"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;

  padding: 4px 8px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
`;
