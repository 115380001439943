import React, { useState, useContext, useEffect, useMemo } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import { theme } from "../../../utils/theme";
import { ModalContext } from "../../../context";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput, PhoenixRadio, PhoenixTextArea } from "../../UI/Phoenix";
import { PhoenixMultiSelectField } from "../../Field/Phoenix/PhoenixMultiSelectField";
import { AppText, DarkDiv, Loading, SkeletonBlock } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { chat, info, mail, phone_call, settings, xIcon } from "../../../images/NewDesign";
import { Formik, FormikProps } from "formik";
import { components } from "react-select";
import { errorToast } from "../../../utils/toast";
import { useDebounce } from "../../../utils/hooks";
import { AddTimeDelay } from "../../UI/Sequences/AddTimeDelay";
import { cloneDeep } from "lodash";
import { ScheduleEventOptions } from "../../UI/Sequences/ScheduleEventOptions";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FETCH_STEP_METRICS } from "../../../utils/sequence-reporting";
import { MixpanelActions } from "../../../services/mixpanel";
import { loggedInUser } from "src/apollo/cache";

const FETCH_TEMPLATES = gql`
  query fetchTemplates($templateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $templateFilter) {
      id
      name
      type
    }
  }
`;

const CREATE_OR_UPDATE_SEQUENCE_STEP = gql`
  mutation createOrUpdateSequenceStep($sequenceStepInput: SequenceStepInput!) {
    createOrUpdateSequenceStep(SequenceStepInput: $sequenceStepInput) {
      id
    }
  }
`;

interface SequenceStepModalProps {
  sequenceId: string;
  stepAction?: string;
  sequenceData: any;
  startingView?: "edit" | "metric";
}

interface StepFormProps {
  conditions: string[];
  tasks: any[];
  origin_id: string | null;
  sequence_id: string;
  step_id: string;
  is_branch: boolean;
}

export const SequenceStepModal: React.FC<SequenceStepModalProps> = ({ sequenceId, stepAction, sequenceData }) => {
  const { toggleSequenceReporting } = useFlags();

  const {
    setShowSequenceStepModal,
    sequenceStepData,
    setSequenceStepData,
    setShowTemplatePreviewModal,
    setTemplatePreviewModalData,
    setUpdateStepTrigger,
    sequenceStepModalView,
    setSequenceStepModalView,
  } = useContext(ModalContext);

  const { nodeData } = sequenceStepData;

  // hide first step delay for FieldPulse, FieldPulse sandbox, and our test org.
  // ^ product team request.
  const hideTimeDelay = useMemo(
    () =>
      nodeData?.origin_id === null &&
      [
        "91f1b9c2-009b-48f9-93e5-b044788c1aa5",
        "ece14be5-953f-4213-b102-9c409ccbba14",
        "35372a5d-8395-4f2e-974f-4ac1d0216a3e",
      ].includes(loggedInUser().organization_id as string),
    [nodeData],
  );

  const prevNodeTask = sequenceData?.active_steps?.find((ele: any) => ele.id === sequenceStepData?.originId)
    ?.actions?.[0]?.task;
  const nextNodeTask = sequenceData?.active_steps?.find((ele: any) => ele.origin_id === sequenceStepData?.nodeData?.id)
    ?.actions?.[0]?.task;

  const automatedSteps = ["automatedEmail", "automatedSms"];

  const [searchTemplates, setSearchTemplates] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<string>(stepAction || nodeData?.actions?.[0]?.task);
  const [customTaskNote, setCustomTaskNote] = useState<string>(
    nodeData?.actions?.[0]?.custom_task_note ? nodeData?.actions?.[0]?.custom_task_note : "",
  );

  useEffect(() => {
    nodeData?.actions?.[0]?.task && setSelectedAction(nodeData?.actions?.[0]?.task);
  }, [nodeData]);

  const { data: dataTemplates, loading: loadingTemplates, error: errorTemplates, refetch: refetchTemplates } = useQuery(
    FETCH_TEMPLATES,
    {
      fetchPolicy: "network-only",
      skip: !["automatedEmail", "automatedSms"].includes(selectedAction),
      variables: {
        templateFilter: {
          type: selectedAction === "automatedEmail" ? "EMAIL" : "SMS",
        },
      },
      onCompleted({ fetchSequence }) {
        console.log("fetchSequence:", fetchSequence);
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [createOrUpdateSequenceStep, { loading: loadingCreateOrUpdateSequenceStep }] = useMutation(
    CREATE_OR_UPDATE_SEQUENCE_STEP,
    {
      async onCompleted({ createOrUpdateSequenceStep }) {
        console.log("createOrUpdateSequenceStep:", createOrUpdateSequenceStep);
        setShowSequenceStepModal(false);
        setSelectedAction("");
        setSequenceStepData({});
        setShowTemplatePreviewModal(false);

        if (!nodeData) {
          // update step count when creating new step
          setUpdateStepTrigger(true);
        }

        MixpanelActions.track("Sequence Edits Saved", { type: "create/modify step" });
      },
      onError({ message }) {
        errorToast(`${message}`);
        Sentry.captureEvent({
          message: `createOrUpdateSequenceStep GraphQL Error: ${message}`,
        });
        console.log(`createOrUpdateSequenceStep GraphQL Error: ${message}`);
        setSelectedAction("");
        setSequenceStepData({});
        setShowTemplatePreviewModal(false);
      },
      refetchQueries: ["fetchSequence"],
    },
  );

  const { data: stepMetricData, loading: loadingStepMetrics } = useQuery(FETCH_STEP_METRICS, {
    fetchPolicy: "cache-and-network",
    skip: !nodeData?.id,
    variables: {
      stepId: nodeData?.id,
    },
    onCompleted({ fetchStepMetrics }) {
      console.log("fetchStepMetrics:", fetchStepMetrics);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const metrics = useMemo(() => {
    const actionData = nodeData?.actions?.[0];
    const metricData = stepMetricData?.fetchStepMetrics;
    return {
      delivered_perc: actionData?.delivered_perc || 0,
      opened_perc: actionData?.opened_perc || 0,
      replied_perc: actionData?.replied_perc || 0,
      clicked_perc: actionData?.clicked_perc || 0,
      unsubscribed_perc: actionData?.unsubscribed_perc || 0,

      // Math.round hack to limit decimals only if decimals are present. Unlike .toFixed which will always append decimals

      tasks_completion_rate: Math.round((metricData?.tasks_completion_rate || 0) * 100) / 100,

      avg_time_to_complete: Math.round(reduceMetricMinutes(metricData?.avg_time_to_complete || 0).reduced * 100) / 100,
      avg_time_to_complete_unit: reduceMetricMinutes(metricData?.avg_time_to_complete || 0).unit,

      snooze_and_skipped_count: metricData?.snooze_and_skipped_count || 0,

      answer_rate: Math.round((metricData?.answer_rate || 0) * 100) / 100,
      voicemail_or_no_contact_rate: Math.round((metricData?.voicemail_or_no_contact_rate || 0) * 100) / 100,
      talk_time_avg: Math.round((metricData?.talk_time_avg || 0) * 100) / 100,
      dials_count: metricData?.dials_count || 0,
      dm_connect_rate: Math.round((metricData?.dm_connect_rate || 0) * 100) / 100,
      bad_number_rate: Math.round((metricData?.bad_number_rate || 0) * 100) / 100,

      conversion_rate: Math.round((metricData?.conversion_rate || 0) * 100) / 100,
    };
  }, [nodeData, stepMetricData]);

  useDebounce(
    () => {
      refetchTemplates({
        templateFilter: {
          type: selectedAction === "automatedEmail" ? "EMAIL" : "SMS",
          template_name: searchTemplates,
        } as { type: string; template_name: string },
      });
    },
    [searchTemplates],
    500,
  );

  const stepActionOptions = [
    { value: "automatedEmail", label: "Automated Email", icon: mail },
    { value: "manualEmail", label: "Manual Email", icon: mail },
    { value: "automatedSms", label: "Automated SMS", icon: chat },
    { value: "manualSms", label: "Manual SMS", icon: chat },
    { value: "manualCall", label: "Phone Call", icon: phone_call },
    { value: "requestManagerFeedback", label: "Request Manager Feedback", icon: info },
    { value: "customTask", label: "Custom Task", icon: settings },
  ];

  const { Option, SingleValue } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <FlexDiv gap={8}>
        <PhoenixIcon
          svg={props?.data?.icon}
          size="small"
          color={props.isSelected ? theme.WHITE_COLOR : theme.PRIMARY500}
          hoverColor={props.isSelected ? theme.WHITE_COLOR : theme.PRIMARY500}
        />
        {props?.data?.label}
      </FlexDiv>
    </Option>
  );
  const IconSingleValue = (props: any) => (
    <SingleValue {...props}>
      <OptionWrapper align="center" gap={8}>
        <PhoenixIcon
          svg={props?.data?.icon}
          size="small"
          color={props.isSelected ? theme.WHITE_COLOR : theme.PRIMARY500}
          hoverColor={props.isSelected ? theme.WHITE_COLOR : theme.PRIMARY500}
        />
        {props.children}
      </OptionWrapper>
    </SingleValue>
  );

  const isDisabled = (values: any) => {
    return (
      loadingCreateOrUpdateSequenceStep ||
      loadingTemplates ||
      !values.tasks?.[0]?.task ||
      (selectedAction === "customTask" && customTaskNote.length === 0) ||
      (["automatedEmail", "automatedSms"].includes(selectedAction) && !values?.tasks?.[0]?.template_id)
    );
  };

  const handleOptionFilter = (prevNodeTask: string, curTask: string) => {
    const newOptions =
      prevNodeTask === "manualCall"
        ? stepActionOptions
        : stepActionOptions?.filter((ele: { label: string; value: string }) => ele.value !== "requestManagerFeedback");
    return !automatedSteps.includes(curTask)
      ? newOptions?.filter((ele: { value: string; label: string }) => !automatedSteps.includes(ele.value))
      : newOptions;
  };

  const handleSubmit = (e: any) => {
    const isAutomatedAction = ["automatedEmail", "automatedSms"].includes(selectedAction);

    // new step state
    if (
      isAutomatedAction &&
      !e?.tasks?.[0]?.delay_days &&
      !e?.tasks?.[0]?.delay_hours &&
      !e?.tasks?.[0]?.delay_minutes &&
      !(
        nodeData &&
        e?.tasks?.[0]?.delay_days === undefined &&
        e?.tasks?.[0]?.delay_hours === undefined &&
        e?.tasks?.[0]?.delay_minutes === undefined
      )
    ) {
      // force a 15 minute delay on automated tasks if no delay is inputed
      const dataCopy = cloneDeep(e);
      dataCopy.tasks[0].delay_days = 0;
      dataCopy.tasks[0].delay_hours = 0;
      dataCopy.tasks[0].delay_minutes = 2;
      return createOrUpdateSequenceStep({
        variables: {
          sequenceStepInput: dataCopy,
        },
      });
    }

    // edit step state
    if (
      nodeData &&
      e?.tasks?.[0]?.delay_days === undefined &&
      e?.tasks?.[0]?.delay_hours === undefined &&
      e?.tasks?.[0]?.delay_minutes === undefined
    ) {
      // in edit state and no new delay is inputed, send the old delay in minutes
      const dataCopy = cloneDeep(e);
      dataCopy.tasks[0].delay_days = 0;
      dataCopy.tasks[0].delay_hours = 0;
      dataCopy.tasks[0].delay_minutes = nodeData?.actions?.[0]?.delay_amount || (isAutomatedAction ? 15 : 0);

      const oldTask = nodeData?.actions?.[0]?.task;
      // remove 15 min automated delay on first step if changing to non-automated step
      if (!dataCopy?.origin_id && ["automatedEmail", "automatedSms"].includes(oldTask) && !isAutomatedAction) {
        dataCopy.tasks[0].delay_days = 0;
        dataCopy.tasks[0].delay_hours = 0;
        dataCopy.tasks[0].delay_minutes = 0;
      }

      return createOrUpdateSequenceStep({
        variables: {
          sequenceStepInput: dataCopy,
        },
      });
    }

    return createOrUpdateSequenceStep({
      variables: {
        sequenceStepInput: e,
      },
    });
  };

  return (
    <>
      <DarkDiv noFade />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column" editState={!!nodeData}>
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            color={theme.PRIMARY500}
            size={24}
            pointer
            onClick={() => {
              setShowSequenceStepModal(false);
              setSequenceStepData({});
              setSelectedAction("");
              setShowTemplatePreviewModal(false);
            }}
            style={{ margin: !!nodeData ? "8px 0px 0px 8px" : undefined }}
          />
          {!!nodeData ? (
            <EditStepHeader direction="column" gap={24}>
              <FlexDiv gap={16} align="center" style={{ paddingLeft: "32px" }}>
                <IconCircle>
                  <PhoenixIcon
                    svg={stepActionOptions.find((option: any) => option.value === nodeData?.actions?.[0]?.task)?.icon}
                    size="small"
                    variant="neutral"
                    hoverColor={theme.NEUTRAL300}
                  />
                </IconCircle>
                <AppText fontSize={16} fontWeight={400} lineHeight={22}>
                  {stepActionOptions.find((option: any) => option.value === nodeData?.actions?.[0]?.task)?.label}
                </AppText>
              </FlexDiv>

              <FlexDiv>
                <EditViewOption selected={false} width="24px" />
                <EditViewOption
                  selected={sequenceStepModalView === "edit"}
                  width="92px"
                  onClick={() => setSequenceStepModalView("edit")}
                >
                  Edit Step
                </EditViewOption>
                {!!toggleSequenceReporting && (
                  <EditViewOption
                    selected={sequenceStepModalView === "metric"}
                    width="120px"
                    onClick={() => setSequenceStepModalView("metric")}
                  >
                    Step Metrics
                  </EditViewOption>
                )}
                <EditViewOption selected={false} width="100%" />
              </FlexDiv>
            </EditStepHeader>
          ) : (
            <AppText
              fontSize={16}
              fontWeight={600}
              style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}
            >
              Add Step
            </AppText>
          )}
        </ModalHeader>

        <Formik
          initialValues={{
            conditions: [],
            tasks: [
              {
                task: !!nodeData?.actions?.length ? nodeData?.actions?.[0]?.task : stepAction,
                custom_task_note: customTaskNote,
                template_id: !!nodeData?.actions?.length ? nodeData?.actions?.[0]?.template_id : null,
                scheduled_event_behavior:
                  !!nodeData?.actions?.length && nodeData?.actions?.[0]?.scheduled_event_behavior
                    ? nodeData?.actions?.[0]?.scheduled_event_behavior
                    : "Neutral",
              },
            ],
            origin_id: !!nodeData?.origin_id
              ? nodeData?.origin_id
              : sequenceStepData.originId
              ? sequenceStepData.originId
              : null,
            sequence_id: sequenceId,
            step_id: !!nodeData?.id ? nodeData?.id : "",
            is_branch: false,
          }}
          onSubmit={(e: StepFormProps) => handleSubmit(e)}
        >
          {({ submitForm, values, setFieldValue }: FormikProps<StepFormProps>) => (
            <>
              <ModalBody direction="column" gap={32}>
                {!nodeData || sequenceStepModalView === "edit" ? (
                  <>
                    <CriteriaContainer>
                      <CriteriaTitle>Step Action</CriteriaTitle>
                      <div style={{ width: "100%" }}>
                        <PhoenixMultiSelectField
                          name="phase"
                          value={
                            !!values.tasks?.length
                              ? stepActionOptions.find((ele) => ele.value === values.tasks?.[0]?.task)
                              : stepActionOptions.find((ele) => ele.value === stepAction)
                          }
                          isMulti={false}
                          onChange={(e: any) => {
                            if (!["automatedEmail", "automatedSms"].includes(e?.value)) {
                              setFieldValue("tasks", [{ task: e?.value, custom_task_note: customTaskNote }]);
                            } else {
                              setFieldValue("tasks", [{ task: e?.value }]);
                            }
                            setSelectedAction(e?.value);
                            setSearchTemplates("");
                            setShowTemplatePreviewModal(false);
                          }}
                          options={
                            prevNodeTask === "manualCall"
                              ? stepActionOptions
                              : stepActionOptions?.filter(
                                  (ele: { label: string; value: string }) => ele.value !== "requestManagerFeedback",
                                )
                          }
                          marginBottom={false}
                          isClearable={false}
                          components={{ Option: IconOption, SingleValue: IconSingleValue }}
                          isDisabled={nodeData?.is_host_step || nextNodeTask === "requestManagerFeedback"}
                        />
                      </div>
                    </CriteriaContainer>

                    <CriteriaContainer>
                      <FlexDiv direction="column" gap={8}>
                        {["automatedEmail", "automatedSms"].includes(selectedAction) && (
                          <>
                            <CriteriaTitle>Templates</CriteriaTitle>
                            <CriteriaSubText>Select an existing template or create a new template.</CriteriaSubText>
                            <PhoenixInput
                              placeholder="Search Templates"
                              variant="primary"
                              type="text"
                              name="search"
                              id="search-templates"
                              searchInput
                              fontSize={10}
                              fontWeight={400}
                              displayNoContextText
                              width={376}
                              value={searchTemplates}
                              onChange={(e: any) => setSearchTemplates(e.target.value)}
                            />
                            <TemplateListContainer direction="column" gap={8}>
                              {loadingTemplates ? (
                                <Loading />
                              ) : (
                                dataTemplates?.fetchTemplates?.map((ele: any) => (
                                  <TemplateOption selected={values?.tasks?.[0]?.template_id === ele.id} key={ele.id}>
                                    <FlexDiv align="center" gap={8}>
                                      <PhoenixRadio
                                        selected={values?.tasks?.[0]?.template_id === ele.id}
                                        size={16}
                                        onClick={() =>
                                          setFieldValue("tasks", [{ ...values?.tasks?.[0], template_id: ele.id }])
                                        }
                                      />
                                      <AppText
                                        fontSize={12}
                                        fontWeight={500}
                                        noWrap
                                        style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}
                                      >
                                        {ele.name}
                                      </AppText>
                                    </FlexDiv>
                                    <AppText
                                      fontSize={12}
                                      fontWeight={500}
                                      color={theme.PRIMARY500}
                                      style={{
                                        cursor: "pointer",
                                        borderBottom: "none",
                                      }}
                                      onClick={() => {
                                        setShowTemplatePreviewModal(true);
                                        setTemplatePreviewModalData({
                                          templateId: ele?.id,
                                          templateType: ele?.type === "EMAIL" ? "email" : "sms",
                                          noDarkDiv: true,
                                        });
                                      }}
                                    >
                                      Preview
                                    </AppText>
                                  </TemplateOption>
                                ))
                              )}
                            </TemplateListContainer>
                          </>
                        )}
                        {!["automatedEmail", "automatedSms"].includes(selectedAction) && (
                          <>
                            <CriteriaTitle>Write a note</CriteriaTitle>
                            <CriteriaSubText>Write a note about what this task entails</CriteriaSubText>
                            <PhoenixTextArea
                              width={376}
                              height={120}
                              fixedSize
                              placeholder="Text goes here"
                              borderColor={theme.NEUTRAL300}
                              value={customTaskNote}
                              onChange={(e: any) => {
                                setCustomTaskNote(e?.target?.value);
                                setFieldValue("tasks", [{ ...values?.tasks?.[0], custom_task_note: e?.target?.value }]);
                              }}
                            />
                          </>
                        )}
                      </FlexDiv>
                    </CriteriaContainer>

                    <FlexDiv direction="column" gap={16}>
                      {!hideTimeDelay && (
                        <AddTimeDelay
                          setFieldValue={setFieldValue}
                          values={{ ...values, sequenceStepData: { ...sequenceStepData?.nodeData } }}
                          edit={!!sequenceStepData?.nodeData?.actions?.[0]?.delay_amount}
                          isAutomatedAction={["automatedEmail", "automatedSms"].includes(selectedAction)}
                        />
                      )}

                      <ScheduleEventOptions values={values} setFieldValue={setFieldValue} />
                    </FlexDiv>
                  </>
                ) : (
                  <MetricBody>
                    <AppText
                      color={theme.NEUTRAL300}
                      fontSize={10}
                      fontWeight={600}
                      uppercase
                      letterSpacing={1}
                      lineHeight={16}
                    >
                      General Metrics
                    </AppText>

                    <MetricGrid columnCount={2} style={{ marginBottom: "24px" }}>
                      {!["automatedEmail", "automatedSms"].includes(nodeData?.actions?.[0]?.task) && (
                        <MetricContainer width={182}>
                          {!loadingStepMetrics ? (
                            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
                              {metrics.avg_time_to_complete}
                            </FadeInText>
                          ) : (
                            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={0.5} />
                          )}
                          <FlexDiv align="center" gap={4}>
                            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                              Average Time to Complete
                            </AppText>
                            <PhoenixIcon
                              data-tip={`Average time to complete this task measured in ${metrics.avg_time_to_complete_unit}.`}
                              data-for="avg-time-to-complete"
                              variant="neutral"
                              svg={info}
                              size={14}
                              style={{ width: "fit-content" }}
                            />
                          </FlexDiv>
                        </MetricContainer>
                      )}
                      <MetricContainer width={182}>
                        <FlexDiv gap={4} align="flex-end">
                          {!loadingStepMetrics ? (
                            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
                              {nodeData?.lead_count || 0}
                            </FadeInText>
                          ) : (
                            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1} />
                          )}
                        </FlexDiv>
                        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                          Count of Leads
                        </AppText>
                      </MetricContainer>
                      {!["automatedEmail", "automatedSms", "manualEmail", "manualSms"].includes(
                        nodeData?.actions?.[0]?.task,
                      ) && (
                        <MetricContainer width={182}>
                          {!loadingStepMetrics ? (
                            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
                              {metrics.conversion_rate}%
                            </FadeInText>
                          ) : (
                            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1} />
                          )}
                          <FlexDiv align="center" gap={4}>
                            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                              Conversion Rate
                            </AppText>
                            <PhoenixIcon
                              data-tip="Rate leads advance to a subsequent phase from this step."
                              data-for="conversion-rate"
                              variant="neutral"
                              svg={info}
                              size={14}
                              style={{ width: "fit-content" }}
                            />
                          </FlexDiv>
                        </MetricContainer>
                      )}
                      <MetricContainer width={182}>
                        {!loadingStepMetrics ? (
                          <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
                            {metrics.tasks_completion_rate}%
                          </FadeInText>
                        ) : (
                          <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1.5} />
                        )}
                        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                          Completed Rate
                        </AppText>
                      </MetricContainer>
                    </MetricGrid>

                    {["manualEmail", "automatedEmail"].includes(nodeData?.actions?.[0]?.task) && (
                      <EmailMetrics metrics={metrics} />
                    )}
                    {["manualSms", "automatedSms"].includes(nodeData?.actions?.[0]?.task) && (
                      <SmsMetrics metrics={metrics} />
                    )}
                    {nodeData?.actions?.[0]?.task === "customTask" && (
                      <CustomTaskMetrics metrics={metrics} loading={loadingStepMetrics} />
                    )}
                    {nodeData?.actions?.[0]?.task === "manualCall" && (
                      <DialsMetrics metrics={metrics} loading={loadingStepMetrics} />
                    )}
                  </MetricBody>
                )}

                <PhoenixStyledTooltip id="conversion-rate" />
                <PhoenixStyledTooltip id="avg-time-to-complete" />
                <PhoenixStyledTooltip id="avg-duration-call" />
              </ModalBody>

              <ModalFooter justify="space-between">
                <PhoenixAppButton
                  buttonType="secondary"
                  variant="danger-outline"
                  buttonTextFontSize={10}
                  uppercase
                  onClick={() => {
                    setShowSequenceStepModal(false);
                    setSequenceStepData({});
                    setSelectedAction("");
                    setShowTemplatePreviewModal(false);
                  }}
                >
                  Cancel
                </PhoenixAppButton>

                <PhoenixAppButton
                  buttonType="secondary"
                  variant="brand"
                  buttonTextFontSize={10}
                  uppercase
                  disabled={isDisabled(values)}
                  onClick={
                    !nodeData || sequenceStepModalView === "edit"
                      ? submitForm
                      : () => {
                          setShowSequenceStepModal(false);
                          setSelectedAction("");
                          setSequenceStepData({});
                          setShowTemplatePreviewModal(false);
                        }
                  }
                >
                  {!nodeData || sequenceStepModalView === "edit" ? "Save Changes" : "Done"}
                </PhoenixAppButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContainer>
    </>
  );
};

interface MetricProps {
  delivered_perc: number;
  opened_perc: number;
  replied_perc: number;
  clicked_perc: number;
  unsubscribed_perc: number;

  tasks_completion_rate: number;

  avg_time_to_complete: number;
  avg_time_to_complete_unit: "minutes" | "hours" | "days";

  snooze_and_skipped_count: number;

  answer_rate: number;
  voicemail_or_no_contact_rate: number;
  talk_time_avg: number;
  dials_count: number;
  dm_connect_rate: number;
  bad_number_rate: number;
}

const EmailMetrics = ({ metrics }: { metrics: MetricProps }) => {
  const { delivered_perc, opened_perc, replied_perc, clicked_perc, unsubscribed_perc } = metrics;
  return (
    <>
      <AppText color={theme.NEUTRAL300} fontSize={10} fontWeight={600} uppercase letterSpacing={1} lineHeight={16}>
        Email Metrics
      </AppText>

      <MetricGrid columnCount={3}>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {delivered_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Delivery Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {opened_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Open Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {replied_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Reply Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {unsubscribed_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Unsubscribe Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {clicked_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Clickthrough Rate
          </AppText>
        </MetricContainer>
      </MetricGrid>
    </>
  );
};

const SmsMetrics = ({ metrics }: { metrics: MetricProps }) => {
  const { unsubscribed_perc, delivered_perc } = metrics;
  return (
    <>
      <AppText color={theme.NEUTRAL300} fontSize={10} fontWeight={600} uppercase letterSpacing={1} lineHeight={16}>
        SMS Metrics
      </AppText>

      <MetricGrid columnCount={3}>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {delivered_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Delivery Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          <AppText fontSize={22} fontWeight={500} lineHeight={26}>
            {unsubscribed_perc}%
          </AppText>
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Unsubscribe Rate
          </AppText>
        </MetricContainer>
      </MetricGrid>
    </>
  );
};

const CustomTaskMetrics = ({ metrics, loading }: { metrics: MetricProps; loading: boolean }) => {
  return (
    <>
      <AppText color={theme.NEUTRAL300} fontSize={10} fontWeight={600} uppercase letterSpacing={1} lineHeight={16}>
        Custom Task Metrics
      </AppText>

      <MetricGrid columnCount={3}>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.tasks_completion_rate}%
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1.5} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Task Completion Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.avg_time_to_complete}
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={2} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Task Speed
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.snooze_and_skipped_count}
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={2.5} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Snoozed/Skipped Tasks
          </AppText>
        </MetricContainer>
      </MetricGrid>
    </>
  );
};

const DialsMetrics = ({ metrics, loading }: { metrics: MetricProps; loading: boolean }) => {
  return (
    <>
      <AppText color={theme.NEUTRAL300} fontSize={10} fontWeight={600} uppercase letterSpacing={1} lineHeight={16}>
        Dial Metrics
      </AppText>

      <MetricGrid columnCount={3}>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.answer_rate}%
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Answer Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.voicemail_or_no_contact_rate}%
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1.5} />
          )}
          <FadeInText fontSize={12} fontWeight={400} lineHeight={18}>
            Voicemail Percentage Rate
          </FadeInText>
        </MetricContainer>
        <MetricContainer width={120} style={{ padding: "8px" }}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.talk_time_avg}
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={2} />
          )}
          <FlexDiv align="center" gap={4}>
            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
              Average Duration of Call
            </AppText>
            <PhoenixIcon
              data-tip="Average call duration measured in seconds."
              data-for="avg-duration-call"
              variant="neutral"
              svg={info}
              size={14}
              style={{ width: "fit-content" }}
            />
          </FlexDiv>
        </MetricContainer>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {~~metrics.dials_count}
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={1.5} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Number of Dials
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.dm_connect_rate}%
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={2} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            DM Connect Rate
          </AppText>
        </MetricContainer>
        <MetricContainer width={120}>
          {!loading ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={26}>
              {metrics.bad_number_rate}%
            </FadeInText>
          ) : (
            <SkeletonBlock width={45} height={26} borderRadius={4} delayNumber={2.5} />
          )}
          <AppText fontSize={12} fontWeight={400} lineHeight={18}>
            Bad Number Rate
          </AppText>
        </MetricContainer>
      </MetricGrid>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

interface ModalHeaderProps {
  editState: boolean;
}

const ModalHeader = styled(FlexDiv)<ModalHeaderProps>`
  padding: ${(props) => !props.editState && "8px"};
  border-bottom: ${(props) => !props.editState && `1px solid ${theme.NEUTRAL200}`};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 40px;

  height: 100%;
  margin-bottom: auto;

  overflow-y: overlay;
  overflow-x: hidden;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;

const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const CriteriaTitle = styled(AppText)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
`;
const CriteriaSubText = styled(AppText)`
  font-size: 12px;
  color: ${theme.NEUTRAL300};
  line-height: 14px;
  margin-bottom: 8px;
`;

const TemplateListContainer = styled(FlexDiv)`
  max-height: 40vh;
  min-height: 40vh;
  margin-top: 8px;
  overflow-y: auto;
  padding-right: 8px;
`;

interface TemplateOptionProps {
  selected: boolean;
}

const TemplateOption = styled.div<TemplateOptionProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 14px;

  background-color: ${theme.PRIMARY100};
  border-radius: 8px;
  border: ${(props) => (props?.selected ? `2px solid ${theme.PRIMARY500}` : `2px solid ${theme.PRIMARY100}`)};
`;

const OptionWrapper = styled(FlexDiv)`
  transition: color 0.15s ease-in-out;
`;

const EditStepHeader = styled(FlexDiv)`
  padding-top: 24px;
`;

interface EditViewOptionProps {
  selected: boolean;
  width: string;
}

const EditViewOption = styled.div<EditViewOptionProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: ${(props) => props.width};
  height: 32px;
  padding: 8px 16px;

  border-bottom: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL300)};

  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};

  cursor: pointer;
  user-select: none;

  transition: all 0.1s ease-in-out;
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 50%;
`;

const MetricBody = styled.div``;

interface MetricGridProps {
  columnCount: number;
}

const MetricGrid = styled.div<MetricGridProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
  grid-gap: 8px;

  padding-top: 8px;
`;

interface MetricContainerProps {
  width: number;
}

const MetricContainer = styled.div<MetricContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  min-width: ${(props) => props.width}px;
  min-height: 84px;

  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 8px;

  text-align: center;

  animation: ${theme.fadeIn} 0.15s ease-in-out forwards;
`;

const FadeInText = styled(AppText)`
  animation: ${theme.textFadeIn} 0.2s ease-in-out forwards;
`;

const reduceMetricMinutes = (minutes: number) => {
  // convert metric from minutes -> hours -> days if value is large enough

  let reduced = minutes;
  let unit: "minutes" | "hours" | "days" = "minutes";

  const hours = minutes / 60;
  const days = hours / 24;

  if (days >= 2) {
    reduced = reduced / 60 / 24;
    unit = "days";
  } else if (hours >= 16) {
    reduced = reduced / 60;
    unit = "hours";
  }

  return { reduced, unit };
};
