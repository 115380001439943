import styled from "styled-components";

interface DarkDivProps {
  noFade?: boolean;
}

export const DarkDiv = styled.div<DarkDivProps>`
  position: fixed;
  left: 0px;
  top: 0;
  z-index: 898;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${(props) => !props.noFade && "fadeIn 0.2s ease"};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
