import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { gql, useLazyQuery } from "@apollo/client";
import { CallContext } from "./CallContext";
import { LeadFilterContext } from "./LeadFilterContext";
import { NextIntent } from "src/types";

export interface ContextNextIntent extends NextIntent {
  [key: string]: any;
}

interface LeadCardContextState {
  customFieldsExpanded: boolean;
  setCustomFieldsExpanded: Dispatch<SetStateAction<boolean>>;
  historyExpanded: boolean;
  setHistoryExpanded: Dispatch<SetStateAction<boolean>>;
  callNotesExpanded: boolean;
  setCallNotesExpanded: Dispatch<SetStateAction<boolean>>;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  leadHistoryFilter: string;
  setLeadHistoryFilter: Dispatch<SetStateAction<string>>;
  selectedTabDialQueue: "active" | "custom";
  setSelectedTabDialQueue: Dispatch<SetStateAction<"active" | "custom">>;
  sortOptionDialQueue: string;
  setSortOptionDialQueue: Dispatch<SetStateAction<string>>;
  upcomingDials: any[];
  setUpcomingDials: Dispatch<SetStateAction<any[]>>;
  customDials: any[];
  setCustomDials: Dispatch<SetStateAction<any[]>>;
  favoriteDials: any[];
  setFavoriteDials: Dispatch<SetStateAction<any[]>>;
  leadCardHistoryReset: boolean;
  setLeadCardHistoryReset: Dispatch<SetStateAction<boolean>>;
  ableToOverideNextDial: boolean;
  setAbleToOverideNextDial: Dispatch<SetStateAction<boolean>>;
  leadInNextDial: string;
  setLeadInNextDial: Dispatch<SetStateAction<string>>;
  skip: number;
  setSkip: Dispatch<SetStateAction<number>>;
  customSkip: number;
  setCustomSkip: Dispatch<SetStateAction<number>>;
  handleResetQueueToDefaultSettings: () => void;
  hasMore: boolean;
  hasMoreCustom: boolean;
  setHasMoreCustom: Dispatch<SetStateAction<boolean>>;
  handleShowMore: () => void;
  handleShowMoreCustom: () => void;

  clearAndRefetchCustomQueue: () => void;
  refreshActiveAndCustomQueue: () => void;
  errorRecentsV2: any;
  errorCustom: any;
  loadingRecentsV2: boolean;

  leadIntentInNextDial: NextIntent | undefined;
  setLeadIntentInNextDial: Dispatch<SetStateAction<NextIntent | undefined>>;

  fetchFilteredLeadsInQueueV2: ({
    variables,
  }: {
    variables: { skip: number; take: number; lead_filter: any; sort_option: string };
  }) => void;
  fetchCustomLeadsInQueue: ({
    variables,
  }: {
    variables: { skip: number; take: number; lead_filter: any; sort_option: string };
  }) => void;

  dataRecommendedActionV2: any;
  loadingRecommendedActionV2: any;
  fetchSuggestedActionLeadV2: ({
    variables,
  }: {
    variables: { skip: number; take: number; lead_filter: any; sort_option: string };
  }) => void;
  resetRecentActivities: () => void;
  recentActivitiesRefreshNow: boolean;
  setRecentActivitiesRefreshNow: Dispatch<SetStateAction<boolean>>;
  activitiesCommunicationType: "Phone" | "SMS" | "Email" | "All";
  setActivitiesCommunicationType: Dispatch<SetStateAction<"Phone" | "SMS" | "Email" | "All">>;
}

export const LeadCardContext = createContext<LeadCardContextState>({} as LeadCardContextState);

export const FETCH_FILTERED_LEADS_IN_QUEUE_V2 = gql`
  query fetchFilteredLeadsInQueueV2($skip: Int, $take: Int, $lead_filter: LeadFilter, $sort_option: DialSortOption) {
    fetchFilteredLeadsInQueueV2(skip: $skip, take: $take, lead_filter: $lead_filter, sort_option: $sort_option) {
      id
      lead_id
      type
      lead {
        id
        organization {
          id
          name
          allow_remove_step_action
          allow_snooze_step_action
        }
        sequence_id
        sequence_step_id
        sequence_step {
          actions {
            id
            task
            custom_task_note
            scheduled_event_behavior
          }
        }
      }
      id
      lead {
        id
        favorited
        primary_phone_number
        current_lead_type
        city
        state
        country
        lead_value {
          value
        }
        timezone_by_state
        lead_source
        industry
        sub_industry
        business_name
        full_name
        first_name
        last_name
        lead_ownership_status
        rep_id
        computed_mrr
        current_close_date
      }
    }
  }
`;

// export const FETCH_FILTERED_CUSTOM_QUEUE = gql`
//   ${CORE_LEAD_CARD_DATA}
//   query fetchCustomQueueLeads($skip: Int, $take: Int, $lead_filter: LeadFilter, $sort_option: DialSortOption) {
//     fetchCustomQueueLeads(lead_filter: $lead_filter, skip: $skip, take: $take, sort_option: $sort_option) {
//       user_id
//       lead_id
//       lead {
//         id
//         ...CoreLeadCardData
//         timezone
//       }
//     }
//   }
// `;

export const FETCH_FILTERED_CUSTOM_QUEUE_V2 = gql`
  query fetchCustomQueueLeads($skip: Int, $take: Int, $lead_filter: LeadFilter, $sort_option: DialSortOption) {
    fetchCustomQueueLeads(lead_filter: $lead_filter, skip: $skip, take: $take, sort_option: $sort_option) {
      user_id
      lead_id
      lead {
        id
        favorited
        primary_phone_number
        primary_email
        current_lead_type
        city
        state
        country
        rep_id
        current_close_date
        computed_mrr
        lead_value {
          id
          value
        }
        timezone_by_state
        lead_source
        industry
        sub_industry
        business_name
        full_name
        first_name
        last_name
        lead_ownership_status
        next_lead_intent {
          id
          event_type_label
          dial_num_in_sales_cycle
          anytime_day
          current_sequence_step
          schedule_item {
            id
            start_time
            end_time
            deleted_at
          }
          replacement_lead_intent_id
        }
        next_intent_scheduled_or_unscheduled {
          id
          event_type_label
          dial_num_in_sales_cycle
          anytime_before #general time
          anytime_after
          anytime_day
          anytime_day_upperbound
          anytime_tz
          general_time_start_date
          general_time_end_date
          current_sequence_step
          schedule_item {
            #specific time
            id
            start_time
            end_time
            deleted_at
          }
          replacement_lead_intent_id
        }
        next_scheduled_event {
          id
          type
        }
        next_scheduled_event_time
        pipeline_state_label
        timezone
      }
    }
  }
`;

const FETCH_RECOMMENDED_ACTION_V2 = gql`
  query fetchFilteredLeadsInQueueV2($skip: Int, $take: Int, $lead_filter: LeadFilter, $sort_option: DialSortOption) {
    fetchFilteredLeadsInQueueV2(skip: $skip, take: $take, lead_filter: $lead_filter, sort_option: $sort_option) {
      id
      lead_id
      type
      lead {
        id
        sequence_id
        sequence_step_id
        sequence_step {
          actions {
            id
            task
            custom_task_note
            scheduled_event_behavior
          }
        }
        organization {
          id
          name
          allow_remove_step_action
          allow_snooze_step_action
        }
        favorited
        primary_phone_number
        primary_email
        current_lead_type
        city
        state
        country
        timezone_by_state
        lead_source
        industry
        sub_industry
        business_name
        full_name
        first_name
        last_name
        lead_ownership_status
        rep_id
        computed_mrr
        current_close_date
      }
    }
  }
`;

export const LeadCardProvider: FunctionComponent = ({ children }) => {
  const { nextActionOverride, resetNextDial } = useContext(CallContext);

  const { leadFilter, resetLeadQueueFilters } = useContext(LeadFilterContext);

  //State that holds active/ custom queue data so it can be removed from any lead card as well
  const [upcomingDials, setUpcomingDials] = useState<any[]>([]);
  const [customDials, setCustomDials] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreCustom, setHasMoreCustom] = useState(true);
  const [favoriteDials, setFavoriteDials] = useState<any[]>([]);
  const [leadIntentInNextDial, setLeadIntentInNextDial] = useState<NextIntent | undefined>(undefined);
  // State that holds whether Custom Fields / Additional Information is expanded
  const [customFieldsExpanded, setCustomFieldsExpanded] = useState(
    Boolean(localStorage.getItem("lead_card_custom_fields_expanded")) || false,
  );
  const [historyExpanded, setHistoryExpanded] = useState(
    Boolean(localStorage.getItem("lead_card_history_expanded")) || false,
  );
  const [callNotesExpanded, setCallNotesExpanded] = useState(
    Boolean(localStorage.getItem("lead_card_call_notes_expanded")) || false,
  );

  const [selectedTab, setSelectedTab] = useState(localStorage.getItem("selected_lead_card_tab") || "contact-info");

  const [leadHistoryFilter, setLeadHistoryFilter] = useState(localStorage.getItem("lead_history_filter") || "All");

  const [selectedTabDialQueue, setSelectedTabDialQueue] = useState<"active" | "custom">(
    (localStorage.getItem("selected_tab_dial_queue") as "active" | "custom") || "active",
  );

  const [sortOptionDialQueue, setSortOptionDialQueue] = useState(
    localStorage.getItem("sort_option_dial_queue") || "OrganizationOrder",
  );

  const [leadCardHistoryReset, setLeadCardHistoryReset] = useState(false);

  const { setSuggestedActionData } = useContext(CallContext);

  // this value lets other cards know if the current next dial lead is a scheduled event (blocks next dial)
  const [ableToOverideNextDial, setAbleToOverideNextDial] = useState<boolean>(
    Boolean(localStorage.getItem("ableToOverideNextDial") === "true"),
  );

  // this value lets other cards check if they are already in the next dial
  const [leadInNextDial, setLeadInNextDial] = useState(localStorage.getItem("leadInNextDial") || "");

  const [skip, setSkip] = useState(0);

  const [customSkip, setCustomSkip] = useState(0);

  // Recent Activities
  const [recentActivities, setRecentActivities] = useState<any[]>([]);
  const [recentActivitiesSkip, setRecentActivitiesSkip] = useState(0);
  const [recentActivitiesHasMore, setRecentActivitiesHasMore] = useState(true);
  const [activitiesCommunicationType, setActivitiesCommunicationType] = useState<"Phone" | "SMS" | "Email" | "All">(
    "All",
  );

  const [recentActivitiesRefreshNow, setRecentActivitiesRefreshNow] = useState(false);

  // suggested action card
  const [
    fetchSuggestedActionLeadV2,
    { data: dataRecommendedActionV2, loading: loadingRecommendedActionV2, error: errorRecommendedActionV2 },
  ] = useLazyQuery(FETCH_RECOMMENDED_ACTION_V2, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted() {
      const intentData = dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0];

      const leadData = intentData?.lead;

      setSuggestedActionData({
        intentData: intentData,
        leadData: leadData,
        expectedAction: leadData?.sequence_step?.actions?.[0]?.task,
      });
      console.log("suggestedActionData:", {
        intentData: intentData,
        leadData: leadData,
        expectedAction: leadData?.sequence_step?.actions?.[0]?.task,
      });
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  // active queue
  const [
    fetchFilteredLeadsInQueueV2,
    { data: dataRecentsV2, loading: loadingRecentsV2, error: errorRecentsV2 },
  ] = useLazyQuery(FETCH_FILTERED_LEADS_IN_QUEUE_V2, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      // remove duplicates
      const newList = [...upcomingDials, ...dataRecentsV2?.fetchFilteredLeadsInQueueV2].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id).includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );

      if (dataRecentsV2?.fetchFilteredLeadsInQueueV2?.length < 4) setHasMore(false);
      else setHasMore(true);

      setUpcomingDials(newList);
    },
  });

  const [fetchCustomLeadsInQueue, { data: dataCustom, loading: loadingCustom, error: errorCustom }] = useLazyQuery(
    FETCH_FILTERED_CUSTOM_QUEUE_V2,
    {
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        // remove duplicates
        const newList = [...customDials, ...dataCustom.fetchCustomQueueLeads].reduce(
          (acc, cv) => (acc?.map((a: any) => a?.lead_id).includes(cv?.lead_id) ? acc : [...acc, cv]),
          [],
        );

        // set hasMore before setting the list to prevent doubled requests
        if (data?.fetchCustomQueueLeads?.length < 4) setHasMoreCustom(false);
        else setHasMoreCustom(true);

        // set the list
        setCustomDials(newList);
      },
    },
  );

  const resetRecentActivities = () => {
    setRecentActivitiesRefreshNow(true);
  };

  const clearAndRefetchCustomQueue = () => {
    setCustomDials([]);
    fetchCustomLeadsInQueue({
      variables: {
        skip: 0,
        take: 4,
        lead_filter: leadFilter,
        sort_option: sortOptionDialQueue,
      },
    });
  };

  const handleShowMore = () => {
    fetchFilteredLeadsInQueueV2({
      variables: {
        skip: skip + 4,
        take: 4,
        lead_filter: leadFilter,
        sort_option: sortOptionDialQueue,
      },
    });

    // set skip for the next fetch
    setSkip(skip + 4);
  };

  //Custom Functions
  const handleShowMoreCustom = () => {
    fetchCustomLeadsInQueue({
      variables: {
        skip: customSkip + 4,
        take: 4,
        lead_filter: leadFilter,
        sort_option: sortOptionDialQueue,
      },
    });
    // set skip for the next fetch
    setCustomSkip(customSkip + 4);
  };

  const refreshActiveAndCustomQueue = () => {
    // fetch new data based on selected tab
    if (selectedTabDialQueue === "active") {
      setSkip(0);
      setUpcomingDials([]);

      fetchFilteredLeadsInQueueV2({
        variables: {
          skip: 0,
          take: 4,
          lead_filter: leadFilter,
          sort_option: sortOptionDialQueue,
        },
      });
    } else if (selectedTabDialQueue === "custom") {
      setCustomSkip(0);
      setCustomDials([]);
      fetchCustomLeadsInQueue({
        variables: {
          skip: 0,
          take: 4,
          lead_filter: leadFilter,
          sort_option: sortOptionDialQueue,
        },
      });
    }
  };

  const handleResetQueueToDefaultSettings = () => {
    // reset sort
    setSortOptionDialQueue("OrganizationOrder");

    // reset filters
    resetLeadQueueFilters();
  };

  useEffect(() => {
    localStorage.setItem("lead_card_custom_fields_expanded", customFieldsExpanded ? "true" : "");
  }, [customFieldsExpanded]);

  useEffect(() => {
    localStorage.setItem("lead_card_history_expanded", historyExpanded ? "true" : "");
  }, [historyExpanded]);

  useEffect(() => {
    localStorage.setItem("lead_card_call_notes_expanded", callNotesExpanded ? "true" : "");
  }, [callNotesExpanded]);

  useEffect(() => {
    localStorage.setItem("selected_lead_card_tab", !!selectedTab ? selectedTab : "contact-info");
  }, [selectedTab]);

  useEffect(() => {
    localStorage.setItem("lead_history_filter", !!leadHistoryFilter ? leadHistoryFilter : "All");
  }, [leadHistoryFilter]);

  useEffect(() => {
    localStorage.setItem("selected_tab_dial_queue", !!selectedTabDialQueue ? selectedTabDialQueue : "active");
  }, [selectedTabDialQueue]);

  useEffect(() => {
    localStorage.setItem("sort_option_dial_queue", !!sortOptionDialQueue ? sortOptionDialQueue : "HighestPDV");
  }, [sortOptionDialQueue]);

  useEffect(() => {
    localStorage.setItem("ableToOverideNextDial", !!ableToOverideNextDial ? "true" : "false");
  }, [ableToOverideNextDial]);

  useEffect(() => {
    localStorage.setItem("leadInNextDial", !!leadInNextDial ? leadInNextDial : "");
  }, [leadInNextDial]);

  const memoizedValue = useMemo(
    () => ({
      customFieldsExpanded,
      setCustomFieldsExpanded,
      historyExpanded,
      setHistoryExpanded,
      callNotesExpanded,
      setCallNotesExpanded,
      selectedTab,
      setSelectedTab,
      // this is not used on the leadCardV2 as it triggers severl cards to refetch leadHistory on change
      // can be removed once the newLeadCard is removed
      leadHistoryFilter,
      setLeadHistoryFilter,
      selectedTabDialQueue,
      setSelectedTabDialQueue,
      sortOptionDialQueue,
      setSortOptionDialQueue,
      upcomingDials,
      setUpcomingDials,
      customDials,
      setCustomDials,
      favoriteDials,
      setFavoriteDials,
      leadCardHistoryReset,
      setLeadCardHistoryReset,
      ableToOverideNextDial,
      setAbleToOverideNextDial,
      leadInNextDial,
      setLeadInNextDial,
      skip,
      setSkip,
      customSkip,
      setCustomSkip,
      handleResetQueueToDefaultSettings,
      hasMore,
      hasMoreCustom,
      setHasMoreCustom,
      handleShowMore,
      handleShowMoreCustom,

      refreshActiveAndCustomQueue,

      errorRecentsV2,
      errorCustom,
      clearAndRefetchCustomQueue,
      dataRecommendedActionV2,
      loadingRecommendedActionV2,
      fetchSuggestedActionLeadV2,

      fetchFilteredLeadsInQueueV2,
      fetchCustomLeadsInQueue,
      loadingRecentsV2,
      leadIntentInNextDial,
      setLeadIntentInNextDial,
      recentActivities,
      setRecentActivities,
      recentActivitiesSkip,
      setRecentActivitiesSkip,
      recentActivitiesHasMore,
      setRecentActivitiesHasMore,
      resetRecentActivities,
      recentActivitiesRefreshNow,
      setRecentActivitiesRefreshNow,
      activitiesCommunicationType,
      setActivitiesCommunicationType,
    }),
    [
      customFieldsExpanded,
      setCustomFieldsExpanded,
      historyExpanded,
      setHistoryExpanded,
      callNotesExpanded,
      setCallNotesExpanded,
      selectedTab,
      setSelectedTab,
      leadHistoryFilter,
      setLeadHistoryFilter,
      selectedTabDialQueue,
      setSelectedTabDialQueue,
      sortOptionDialQueue,
      setSortOptionDialQueue,
      upcomingDials,
      setUpcomingDials,
      customDials,
      setCustomDials,
      favoriteDials,
      setFavoriteDials,
      leadCardHistoryReset,
      setLeadCardHistoryReset,
      ableToOverideNextDial,
      setAbleToOverideNextDial,
      leadInNextDial,
      setLeadInNextDial,
      skip,
      setSkip,
      customSkip,
      setCustomSkip,
      handleResetQueueToDefaultSettings,
      hasMore,
      hasMoreCustom,
      setHasMoreCustom,
      handleShowMore,
      handleShowMoreCustom,

      refreshActiveAndCustomQueue,

      errorRecentsV2,
      errorCustom,
      clearAndRefetchCustomQueue,
      dataRecommendedActionV2,
      loadingRecommendedActionV2,
      fetchSuggestedActionLeadV2,

      fetchFilteredLeadsInQueueV2,
      fetchCustomLeadsInQueue,
      loadingRecentsV2,
      leadIntentInNextDial,
      setLeadIntentInNextDial,
      recentActivities,
      setRecentActivities,
      recentActivitiesSkip,
      setRecentActivitiesSkip,
      recentActivitiesHasMore,
      setRecentActivitiesHasMore,
      resetRecentActivities,
      recentActivitiesRefreshNow,
      setRecentActivitiesRefreshNow,
      activitiesCommunicationType,
      setActivitiesCommunicationType,
    ],
  );

  return <LeadCardContext.Provider value={memoizedValue}>{children}</LeadCardContext.Provider>;
};
