import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppButton, AppErrorText, AppText, Loading } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { gql, useQuery, useMutation } from "@apollo/client";
import { FormSelectField, InputField } from "../../Field";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { CallContext, CartContext } from "../../../context";
import { mapProducts } from "./ClosingScript";
import { appToast } from "../../../utils/toast";
import * as Sentry from "@sentry/react";
import { currentCallState } from "../../../apollo/cache";
import { FiArrowLeft } from "react-icons/fi";
import { useFlags } from "launchdarkly-react-client-sdk";

interface CreatePandaDocProps {
  setCallOptions: (callOption: string) => void;
}

interface MyFormikProps {
  template_id: string;
  fields: Array<{
    name: string;
    value: string;
  }>;
}

interface ProductDataType {
  contract_duration: string;
  payment_terms: string;
}

const pandaDocFieldsSchema = Yup.object().shape({
  template_id: Yup.string().required("Select the template to use"),
  fields: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      value: Yup.string().notRequired(),
    }),
  ),
});

export const FETCH_PANDADOC_TEMPLATE_REP = gql`
  query fetchAllPandaDocTemplateForRep {
    fetchAllPandaDocTemplateForRep {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

export const PREVIEW_PANDADOC_DATA = gql`
  query previewPandaDocData(
    $products: [ProductInputType!]!
    $lead_id: String!
    $sale_id: String
    $template_id: String!
    $product_data: ProductDataType!
  ) {
    previewPandaDocData(
      products: $products
      lead_id: $lead_id
      sale_id: $sale_id
      template_id: $template_id
      product_data: $product_data
    )
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      show_sale_flow_custom_fields
    }
  }
`;

export const CREATE_PANDADOC_DOCUMENT = gql`
  mutation createDocument($previewPandaDocData: PandaDocMappingInput!, $template_id: String!, $lead_id: String) {
    createDocument(previewPandaDocData: $previewPandaDocData, template_id: $template_id, lead_id: $lead_id)
  }
`;

const CreatePandaDocComponent: React.FC<CreatePandaDocProps> = ({ setCallOptions }) => {
  const [selectedTemplateID, setSelectedTemplateID] = useState("");
  const { callOptionStackPush } = useContext(CallContext);
  const {
    lead_id,
    products,
    pandadoc_sale_id,
    setPandaDocId,
    contract_duration,
    payment_terms,
    prepayment,
  } = useContext(CartContext);

  const { data: leadData, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: lead_id },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataVisibleTemplates, loading: loadingVisibleTemplates, error: errorVisibleTemplates } = useQuery(
    FETCH_PANDADOC_TEMPLATE_REP,
    {
      fetchPolicy: "network-only",
    },
  );

  const { data: dataPandaDocPreview, loading: loadingDocPreview, error: errorDocPreview } = useQuery(
    PREVIEW_PANDADOC_DATA,
    {
      variables: {
        products: mapProducts(products),
        lead_id: lead_id,
        // sale_id: cartSaleID,
        template_id: selectedTemplateID,
        product_data: {
          contract_duration,
          payment_terms,
          prepayment,
        },
      },
      fetchPolicy: "network-only",
      skip: !selectedTemplateID,
    },
  );

  const [createDocument, { loading: loadingCreateDocument }] = useMutation(CREATE_PANDADOC_DOCUMENT, {
    async onCompleted({ createDocument }) {
      console.log("createDocument: ", createDocument);
      if (!createDocument) {
        appToast("Error creating PandaDoc");
        return;
      }
      if (createDocument?.id) {
        setPandaDocId(createDocument?.id);
      }
      appToast("Document is being sent");
      if (leadData?.fetchLead?.show_sale_flow_custom_fields) {
        setCallOptions("confirm-details-custom");
        callOptionStackPush("confirm-details-custom");
      } else {
        setCallOptions("closing-script");
        callOptionStackPush("closing-script");
      }
      // clearCartContext();
      // if (!currentCallState().onCall) {
      //   revertCallState();
      // }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `createDocument GraphQL Error: ${message}`,
      });
      console.log("Error in createDocument: ", message);
    },
  });

  return loadingVisibleTemplates ? (
    <Loading />
  ) : errorVisibleTemplates ? (
    <AppErrorText>Error loading templates</AppErrorText>
  ) : (
    <Formik
      enableReinitialize
      initialValues={{
        template_id: selectedTemplateID,
        fields: dataPandaDocPreview?.previewPandaDocData?.tokens,
      }}
      onSubmit={async (values) => {
        const newDoc = {
          ...dataPandaDocPreview.previewPandaDocData,
          tokens: values.fields,
        };
        console.log(newDoc);
        await createDocument({
          variables: {
            template_id: values.template_id,
            previewPandaDocData: newDoc,
            lead_id: lead_id,
          },
        });
      }}
      validationSchema={pandaDocFieldsSchema}
    >
      {({ values, submitForm, setFieldValue, isValid, isSubmitting }: FormikProps<MyFormikProps>) => {
        return (
          <FormContainer>
            <HeaderContainer>
              <AppText fontSize={17}>PandaDoc</AppText>
            </HeaderContainer>

            {dataVisibleTemplates?.fetchAllPandaDocTemplateForRep && (
              <FormSelectField
                name="template_id"
                options={[
                  { label: "Select template...", value: "" },
                  ...dataVisibleTemplates?.fetchAllPandaDocTemplateForRep
                    .slice()
                    .sort((a: any, b: any) =>
                      `${a?.template_name} ${a?.template_id}`
                        .toLocaleLowerCase()
                        .localeCompare(`${b?.template_name} ${b?.template_id}`.toLocaleLowerCase()),
                    )
                    ?.map((item: any) => ({
                      label: item.template_name,
                      value: item.template_id,
                    })),
                ]}
                onChange={(e: any) => {
                  setSelectedTemplateID(e.target.value);
                  setFieldValue("template_id", e.target.value);
                }}
              />
            )}
            <ScrollDiv>
              <FieldArray
                name="fields"
                render={() => (
                  <div style={{ width: "100%" }}>
                    {loadingDocPreview ? (
                      <Loading />
                    ) : (
                      values?.fields &&
                      values.fields.length > 0 &&
                      values.fields?.map((option, index) => (
                        <div key={`${option.name} ${index}`}>
                          <TemplateFieldLabel>{values.fields[index].name}</TemplateFieldLabel>
                          <TemplateFieldValue name={`fields.${index}.value`} />
                        </div>
                      ))
                    )}
                  </div>
                )}
              />
            </ScrollDiv>
            <ButtonsDiv>
              <ConfirmSaleButton
                // fontsize={14}
                variant={"secondary"}
                onClick={() => {
                  console.log("show_sale_flow_custom_fields: ", leadData?.fetchLead?.show_sale_flow_custom_fields);
                  if (leadData?.fetchLead?.show_sale_flow_custom_fields) {
                    setCallOptions("confirm-details-custom");
                    callOptionStackPush("confirm-details-custom");
                  } else {
                    setCallOptions("closing-script");
                    callOptionStackPush("closing-script");
                  }
                }}
                disabled={loadingCreateDocument || loading}
              >
                Skip
              </ConfirmSaleButton>
              {/* <ConfirmSaleButton primary fontsize={14} onClick={submitForm} disabled={loadingCreateDocument}> */}
              <ConfirmSaleButton
                variant={"primary"}
                onClick={submitForm}
                disabled={loadingCreateDocument || loading || !values.template_id}
              >
                Send Document
              </ConfirmSaleButton>
            </ButtonsDiv>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

const ButtonsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

const ScrollDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 600px;
  overflow-y: scroll;
`;

const TemplateFieldValue = styled(InputField)`
  margin-top: 6px;
`;

const TemplateFieldLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
`;

const ConfirmSaleButton = styled(NewAppButton)`
  /* margin: auto auto 36px; */
  /* width: 262px; */
  text-transform: capitalize;
`;

const ClosingScriptWarning = styled.div`
  font-size: 13px;
  padding: 0px 40px;
  text-align: justify;
`;

const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 39px;
`;

const SpacerDiv = styled.div`
  width: 25px;
  height: 25px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

export { CreatePandaDocComponent };
