import { gql, useMutation, useQuery } from "@apollo/client";
import { FieldArray, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { InputField, FormMultiSelectField, FormSelectField } from "../../Field";
import { AppButton, AppErrorText, AppText, Loading, NewAppButton, NewAppSelect } from "../../UI";
import * as Yup from "yup";
import { CallContext } from "../../../context";
import { appToast, errorToast } from "../../../utils/toast";
import { convertDate } from "../../../utils/format";
import { CustomField } from "src/Components/Field/CustomField";
import { PhoenixInputField } from "src/Components/Field/Phoenix";
import { currentCallState } from "src/apollo/cache";
import { calendarInviteSent } from "src/images";

export const requiredDemoFields = [
  { order: 1, id: "67ae9e4d-a8ae-4b75-a132-577a0589baa4", key: "Amount" },
  { order: 2, id: "50eb0d18-6ea5-7adb-5f60-f0891f68c39b", key: "Current Competitor" },
  { order: 3, id: "cd22c847-ef7e-59d1-5cf6-dd71356667ca", key: "Number of Employees" },
];

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
      value
      visible
      boo_value
      num_value
      type
      lead_id
      options
      computed_value
      list_value
      allow_reps_to_edit
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
    }
  }
`;

const UPDATE_LEAD = gql`
  mutation updateLead($id: String!, $custom_fields: [CustomFieldInput!]) {
    updateLead(id: $id, custom_fields: $custom_fields) {
      id
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
    }
  }
`;
interface CustomField {
  id: string;
  value: string | number | boolean;
  num_value?: number;
  list_value?: string[];
  boo_value?: boolean;
  date_value?: string;
  type: string;
  key: string;
  options: string[];
}

interface MyFormikProps {
  demoFields: CustomField[];
}

interface ConfirmLeadDetailsCustomProps {
  // confirmComplete: () => void;
  leadID: string;
}

const ConfirmDemoRequiredFields: React.FC<ConfirmLeadDetailsCustomProps> = (props) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const { data, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: props.leadID },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: customFieldsData, loading: customFieldsLoading, error: customFieldsError } = useQuery(
    FETCH_CUSTOM_FIELDS,
    {
      fetchPolicy: "network-only",
    },
  );

  const [updateLead, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_LEAD, {
    async onCompleted({ updateLead }) {
      if (!updateLead) {
        appToast("Error confirming lead. Something went wrong.");
        return;
      }
      appToast("Updated Demo Required Fields");
      setIsEnteringRequiredFields(false);
      // currentCallState({
      //   ...currentCallState(),
      //   dispositionLogged: true,
      // });
      setIsCompleted(true);
      if (!currentCallState().onCall && currentCallState().dispositionLogged && !currentCallState().alternate_contact) {
        revertCallState();
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateLead GraphQL Error: ${message}`,
      });
    },
  });

  const { isEnteringRequiredFields, setIsEnteringRequiredFields, revertCallState, resetCallOptionStack } = useContext(
    CallContext,
  );

  const numberYupValidation = () => ({
    is: "Rate" || "Percentage" || "Number",
    then: Yup.number().typeError("Must be a number").nullable(),
  });

  const textYupValidation = () => ({
    is: "Text",
    then: Yup.string(),
  });

  const booleanYupValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const requiredValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const confirmDetailsSchema = () =>
    Yup.object().shape({
      demoFields: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          type: Yup.string().notRequired(),
          value: Yup.string().when("type", textYupValidation()).nullable(),
          boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
          num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
          date_value: Yup.string().typeError("Must be a string").when("type", textYupValidation()).nullable(),
        }),
      ),
    });

  useEffect(() => {
    setIsEnteringRequiredFields(true);
  }, []);

  useEffect(() => {
    return () => {
      if (isCompleted) {
        resetCallOptionStack();
        setIsCompleted(false);
        setIsEnteringRequiredFields(false);
      }
    };
  }, [isCompleted]);

  if (loading || customFieldsLoading) return <Loading />;
  if (error || customFieldsError) return <AppErrorText>Error fetching lead details.</AppErrorText>;

  const findValueFromLeadDate = (key: string) => {
    const leadData = data?.fetchLead?.visible_custom_fields?.find(
      (customField: CustomField) => customField.key === key,
    );
    return leadData?.value || leadData?.list_value || leadData?.computed_value;
  };

  const required_keys = requiredDemoFields.map((field) => field.key);

  const demoRequiredFields = customFieldsData?.fetchCustomFields
    ?.filter((customField: CustomField) => required_keys.includes(customField.key))
    .map((item: CustomField) => ({ ...item, value: findValueFromLeadDate(item.key) }))
    .sort((a: CustomField, b: CustomField) => {
      const orderA = requiredDemoFields.map((field) => field).filter((field) => field.key === a.key)?.[0]?.order;
      const orderB = requiredDemoFields.map((field) => field).filter((field) => field.key === b.key)?.[0]?.order;
      if (!orderA) return 1;
      if (!orderB) return -1;
      return orderA - orderB;
    });

  if (!isEnteringRequiredFields && isCompleted) {
    return (
      <>
        <HeaderContainer>
          <AppText fontSize={16} fontWeight={600}>
            Confirm Demo Required Fields
          </AppText>
        </HeaderContainer>
        <FormContainerDiv>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <img src={calendarInviteSent} height="168px" alt="Calendar invite sent" />
            <AppText style={{ marginBottom: "16px" }}>Successfully confirmed required demo fields!</AppText>
            <AppText>You can now end the call.</AppText>
          </div>
        </FormContainerDiv>
      </>
    );
  }

  return (
    <FormContainer>
      <HeaderContainer>
        <AppText fontSize={16} fontWeight={600}>
          Confirm Demo Required Fields
        </AppText>
        {/* <HelpfulLinks /> */}
      </HeaderContainer>
      <Formik
        initialValues={{
          demoFields: (demoRequiredFields || [])?.map((customField: CustomField) => {
            console.log("customField: ", customField);
            let valueToUse = {
              id: customField.id,
              value: "",
              list_value: [],
              type: customField.type,
              key: customField.key,
              options: [],
            } as any;
            let optionsToUse = [] as string[];
            switch (customField.type) {
              case "Dropdown":
                valueToUse.value = customField.value;
                valueToUse.options = customField.options;
                break;
              case "MultiDropdown":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                valueToUse.options = customField.options;
              case "MultiText":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                break;
              case "DateTime":
                valueToUse.date_value = customField.date_value ? convertDate(customField.date_value) : undefined;
                break;
              case "Date":
                valueToUse.value = customField.value;
                break;
              case "Number":
                valueToUse.num_value = customField.num_value;
                break;
              case "Text":
                valueToUse.value = customField.value;
                break;
              case "Boolean":
                valueToUse.boo_value = customField.boo_value;
                break;
              case "Rate":
                valueToUse.num_value = customField.num_value;
                break;
              case "Percentage":
                valueToUse.num_value = customField.num_value;
                break;
              default:
                valueToUse.value = "";
            }
            return valueToUse;
          }),
        }}
        validationSchema={confirmDetailsSchema()}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={async (values) => {
          // check validation
          // for (const k of values.demoFields) {
          //   switch (k.type) {
          //     case "Text":
          //     case "Dropdown":
          //       if (!k.value) {
          //         return errorToast(`${k.key} is a required field!`);
          //       }
          //       break;
          //   }
          // }
          const final_custom_fields = values.demoFields?.map((customField) => {
            switch (customField.type) {
              case "Dropdown":
                return {
                  id: customField.id,
                  key: customField.key,
                  value: customField.value,
                };
              case "MultiDropdown":
              case "MultiText":
                return {
                  id: customField.id,
                  // @ts-ignore
                  list_value: customField.list_value?.map((k) => k.value ?? k),
                };
              case "DateTime":
                return {
                  id: customField.id,
                  date_value: customField.date_value,
                };
              case "Date":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Number":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Text":
                return {
                  id: customField.id,
                  key: customField.key,
                  value: customField.value,
                };
              case "Boolean":
                return {
                  id: customField.id,
                  boo_value: customField.boo_value,
                };
              case "Rate":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Percentage":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              default:
                return {
                  id: customField.id,
                  key: customField.key,
                  value: customField.value,
                };
            }
          });
          console.log("final_custom_fields: ", final_custom_fields);
          // Check if any value in final_custom_fields is empty, null or undefined
          const hasEmptyValue = final_custom_fields.some((field) => {
            // const value = field.value || field.list_value || field.date_value || field.num_value || field.boo_value;
            const value = field.value;
            return (
              value === undefined || value === null || value === "" || (Array.isArray(value) && value.length === 0)
            );
          });

          if (hasEmptyValue) {
            return errorToast(`All fields are required to create a demo!`);
          }
          await updateLead({
            variables: {
              id: props.leadID,
              custom_fields: final_custom_fields ?? undefined,
            },
          });
        }}
      >
        {({ submitForm, values, setFieldValue, isSubmitting, isValid, errors }: FormikProps<MyFormikProps>) => {
          return (
            <>
              <FormContainerDiv>
                <FieldArray name="demoFields">
                  {() => (
                    <>
                      {values.demoFields?.map((customField: any, index: number) => {
                        return customField.type === "Text" ? (
                          <>
                            <TitleLabel>{customField.key}</TitleLabel>
                            <PhoenixInputField
                              requiredStar
                              name={`demoFields[${index}].key`}
                              value={values.demoFields[index].value}
                              placeholder={customField.key}
                              type="text"
                              onChange={(e) => {
                                setFieldValue(`demoFields[${index}].value`, e.target.value);
                              }}
                            />
                          </>
                        ) : customField.type === "Dropdown" ? (
                          <>
                            <TitleLabel>{customField.key}</TitleLabel>
                            <NewAppSelect
                              name={`demoFields[${index}].key`}
                              required={true}
                              value={values.demoFields[index].value}
                              options={
                                customField?.options?.map((item: string) => ({ label: item, value: item })) || []
                              }
                              onChange={(e: any) => {
                                setFieldValue(`demoFields[${index}].value`, e?.value);
                              }}
                            />
                          </>
                        ) : null;
                      })}
                    </>
                  )}
                </FieldArray>
              </FormContainerDiv>
              {isSubmitting ? (
                <Loading />
              ) : (
                <NewAppButton type="submit" size={"lg"} variant={"primary"} onClick={submitForm} disabled={!isValid}>
                  Confirm Details
                </NewAppButton>
              )}
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

const TitleLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: center;
  overflow-y: auto;
  padding: 24px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

export { ConfirmDemoRequiredFields };
