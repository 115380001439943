import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { CallContext, ModalContext } from "../../context";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppText, NewAppButton } from "../UI";
import { Modal } from "./Modal";
import { PhoenixAppButton } from "../UI/Phoenix";

const UPDATE_ACTIVE_TWILIO_RECORDING = gql`
  mutation updateActiveTwilioRecording($action: TwilioRecordingAction!) {
    updateActiveTwilioRecording(action: $action)
  }
`;

const RecordConsentModal: React.FC = () => {
  const { showConsentModal, setShowConsentModal } = useContext(ModalContext);

  const { toggleDidStartRecording } = useContext(CallContext);

  const [updateActiveTwilioRecording] = useMutation(UPDATE_ACTIVE_TWILIO_RECORDING, {
    onCompleted: ({ updateActiveTwilioRecording }) => {
      if (updateActiveTwilioRecording) {
        appToast("Sucessfully recording");
        toggleDidStartRecording(true);
      }
    },
    onError: ({ message }) => {
      Sentry.captureEvent({
        message: `updateActiveTwilioRecording graphql error: ${message}`,
      });
      appToast(`Error updating recording: ${message}`);
    },
  });

  const handleConsentClick = async () => {
    await updateActiveTwilioRecording({
      variables: {
        action: "Start",
      },
    });
    setShowConsentModal(false);
  };

  useEffect(() => {
    toggleDidStartRecording(false);
  }, []); // if this component was rendered then the recording has to be manually started after getting permission from the lead.

  return (
    <Modal open={showConsentModal} onClose={() => setShowConsentModal(false)} closeButtonSize={16}>
      <TextContainer>
        <AppText fontWeight={700} fontSize={20}>
          Record Call?
        </AppText>
        <AppText fontSize={14} textAlign="center">
          Based on the state you have indicated for this lead, this lead appears to be in an all-party consent state. In
          order to record this call, please confirm that you've asked for and received permission to record the call.
        </AppText>
      </TextContainer>
      <ButtonsContainer>
        <PhoenixAppButton
          width={175}
          height={50}
          variant="danger-outline"
          buttonType="secondary"
          onClick={() => setShowConsentModal(!showConsentModal)}
        >
          Cancel
        </PhoenixAppButton>
        <PhoenixAppButton width={175} height={50} variant="brand" buttonType="secondary" onClick={handleConsentClick}>
          Record Call
        </PhoenixAppButton>
      </ButtonsContainer>
    </Modal>
  );
};

const TextContainer = styled.div`
  margin: 3rem;
  width: 500px;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: ${theme.NEUTRAL100};
`;

export { RecordConsentModal };
