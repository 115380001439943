import React, { useState, useContext, useEffect } from "react";
import Switch from "react-switch";
import { theme } from "../../../../utils/theme";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { edit, trash } from "../../../../images/NewDesign";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";

import moment from "moment";

import { useQuery, gql, useMutation } from "@apollo/client";
import { appToast } from "../../../../utils/toast";

import { SkeletonBlock } from "../../../UI/SkeletonBlock";
import { AppErrorText } from "../../../UI";

// for federal dates

const FETCH_FEDERAL_NO_EVENT_DATES = gql`
  query FetchDefaultHolidays($year: Int!) {
    fetchDefaultHolidays(year: $year) {
      date
      dateString
      name
      observe
    }
  }
`;

const UPDATE_FEDERAL_NO_EVENT_DATE = gql`
  mutation AddOrUpdateFederalHoliday($data: FederalHolidayInput!) {
    addOrUpdateFederalHoliday(data: $data) {
      id
    }
  }
`;

const FederalDateTable = ({ selectedYear }: { selectedYear: number }) => {
  const [initialLoad, setInitialLoad] = useState(true);

  const [rowData, setRowData] = useState([] as any[]);

  //---------------------------------------------------------//
  // -----------------federal no event dates-----------------//
  //---------------------------------------------------------//

  const {
    data: federalNoEventData,
    loading: federalNoEventLoading,
    error: federalNoEventError,
    refetch: refetchFederalNoEventData,
  } = useQuery(FETCH_FEDERAL_NO_EVENT_DATES, {
    variables: {
      year: selectedYear,
    },
    fetchPolicy: "cache-and-network",
    skip: !selectedYear,
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast(message);
    },

    onCompleted({ fetchDefaultHolidays }) {
      interface FederalHoliday {
        date: number;
        dateString: string;
        name: string;
        observe: boolean;
      }

      interface RowData {
        title: string;
        date: string;
        observe: FederalHoliday;
        year: number;
      }

      if (initialLoad) setInitialLoad(false);
      setRowData([
        ...(fetchDefaultHolidays
          ?.map((event: FederalHoliday) => {
            console.log("event", event);
            return {
              title: event?.name ?? "NA",
              date: moment(event?.date)?.tz("utc")?.format("dddd, MMMM Do YYYY") ?? "NA",

              // we need to pass the whole event because the mutation needs all of the data
              observe: event,
              // only used for comparison
              year: moment(event?.date)?.year(),
            };
          })
          .sort((a: RowData, b: RowData) => {
            return moment(a?.observe?.date)?.isBefore(moment(b?.observe?.date)) ? -1 : 1;
          }) ?? []),
      ]);
    },
  });

  const [updateFederalNoEventDate] = useMutation(UPDATE_FEDERAL_NO_EVENT_DATE, {
    onCompleted: (data) => {
      appToast("Updated Successfully");
    },
    onError: (error) => {
      appToast(error.message);
    },
    refetchQueries: ["FetchDefaultHolidays"],
  });

  /*
  {
  data: {
    date: 1640908800000,
    dateString: '2021-12-31',
    name: "New Year's Day",
    observe: true,
  },
}
  */

  const ObserveFederalHolidayCellRenderer = (props: any) => {
    console.log("props", props);
    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
        <Switch
          onChange={(checked) => {
            updateFederalNoEventDate({
              variables: {
                data: {
                  date: props?.data?.observe?.date,
                  dateString: props?.data?.observe?.dateString,
                  name: props?.data?.observe?.name,
                  observe: checked,
                },
              },
            });
          }}
          onColor={theme.PRIMARY500}
          offColor={theme.NEUTRAL200}
          checked={!!props?.data?.observe?.observe}
          height={16}
          width={32}
          handleDiameter={12}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>
    );
  };

  const FEDERAL_COLUMN_DEFS = [
    {
      headerName: "No Event Date",
      field: "title",
      sortable: false,
      filter: false,
      resizable: true,
      flex: 1,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-no-event-table-header",
    },
    {
      headerName: "Day",
      field: "date",
      sortable: false,
      filter: false,
      resizable: true,
      flex: 1,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-no-event-table-header",
    },
    {
      headerName: "Observe",
      field: "observe",
      sortable: false,
      filter: false,
      resizable: true,
      width: 104,
      cellStyle: { textAlign: "left" },
      cellRendererFramework: ObserveFederalHolidayCellRenderer,
      headerClass: "ag-no-event-table-header",
    },
  ];

  const loading = federalNoEventLoading;
  const error = federalNoEventError;

  if (initialLoad && loading) {
    return <SkeletonBlock height={500} width={"100%"} borderRadius={4} />;
  }

  if (error) {
    return <AppErrorText>Something went wrong. Please try again later.</AppErrorText>;
  }

  return (
    <Wrapper>
      <div
        className={"ag-transfer-rules-table ag-theme-no-event-table"}
        style={{
          // minWidth: "600px",
          width: "100%",
          border: `1px solid ${theme.NEUTRAL300}`,
          height: "fit-content",
          borderRadius: "8px",
          maxHeight: "550px",
          overflow: "auto",
          padding: "4px",
        }}
      >
        <AgGridReact
          rowData={rowData}
          rowDragEntireRow={true}
          columnDefs={FEDERAL_COLUMN_DEFS}
          suppressMovableColumns={true}
          suppressDragLeaveHidesColumns={true}
          tooltipMouseTrack={true}
          animateRows={true}
          enableCellTextSelection={true}
          rowHeight={40}
          domLayout="autoHeight"
        />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .ag-row-drag {
    visibility: hidden;
    display: none;
  }

  .ag-row {
    border-bottom: none !important;
  }
  .ag-theme-no-event-table {
    justify-content: left;
  }

  .ag-theme-no-event-table .ag-row-even {
    background-color: #f7f9ff;
  }

  .ag-theme-no-event-table .ag-row-odd {
    background-color: #ffffff;
  }

  .ag-theme-no-event-table {
    --ag-odd-row-background-color: #f7f9ff;
    --ag-header-background-color: #f7f9ff;
    --ag-header-height: 40px;
    --ag-row-height: 40px;
    --ag-font-size: 12px;
    --ag-font-family: "Inter", sans-serif;
    --ag-borders: none;
    --ag-range-selection-border-color: #00000000;
  }

  .ag-row-drag {
    visibility: hidden;
    display: none;
  }

  .ag-no-event-table-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    font-family: "Inter";
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    /* Neutral/Neutral-Main */
    background-color: white;
    color: #000000;
  }

  .ag-no-event-table-header div {
    justify-content: left;
  }

  .ag-theme-no-event-table-list .ag-row-even {
    background-color: #f7f9ff;
  }

  .ag-theme-no-event-table-list .ag-row-odd {
    background-color: #ffffff;
  }
`;

export default FederalDateTable;
