import React from "react";
import { ModalContext } from "src/context";

const useModalContext = () => {
  const context = React.useContext(ModalContext);

  if (!context) {
    throw new Error("useModalContext must be used within a ModalContextProvider");
  }

  return context;
};

export default useModalContext;
