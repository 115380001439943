import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput, PhoenixMultiSelect } from "../UI/Phoenix";
import Switch from "react-switch";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { more_vertical } from "../../images/NewDesign";
import { useClickOutside } from "../../utils/hooks";
import { createPortal } from "react-dom";
import { AddOrEditProductModal } from "../modal";
import { ModalContext } from "../../context";
import { gql, useQuery, useMutation } from "@apollo/client";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import ReactTooltip from "react-tooltip";
import { appToast, errorToast, successToast } from "../../utils/toast";
import { ManageDeleteProductModal } from "../modal/ManageDeleteProductModal";
import { TERMS_OPTIONS, copyToClipboard } from "../../utils/misc";
import { OptionItem } from "../../types";

const FETCH_PRODUCTS = gql`
  query fetchProducts($includeInactive: Boolean) {
    fetchProducts(include_inactive: $includeInactive) {
      id
      active
      product_title
      updated_at
      last_updated_by
      prepayment_options
      pricing
      show_override_price_option
      pricing_floor

      available_concessions {
        id
        amount
      }

      fee_type

      salesforce_id
      pricebook_entry_id
    }
  }
`;

const ADD_OR_UPDATE_PRODUCTS = gql`
  mutation addOrUpdateProducts($products: [ProductsInputNew!]!) {
    addOrUpdateProducts(products: $products) {
      id
    }
  }
`;

const FETCH_PAYMENT_SETTINGS = gql`
  query fetchOrgPaymentSettings {
    fetchOrgPaymentSettings {
      id

      enable_contract_duration
      contract_duration_options
      default_contract_duration

      enable_payment_duration
      contract_term_options
      default_term_duration
    }
  }
`;

const UPDATE_PAYMENT_SETTINGS = gql`
  mutation updateOrgPaymentSettings($input: OrganizationPaymentSettingsInput!) {
    updateOrgPaymentSettings(input: $input) {
      id
    }
  }
`;

interface IPaymentSettings {
  enable_contract_duration: boolean;
  contract_duration_options: string[];
  default_contract_duration: string | null;

  enable_payment_duration: boolean;
  contract_term_options: string[];
  default_term_duration: string | null;
}

export type TableProductType = {
  id: string;
  product_title: string;
  fee_type: string;
  prepayment_options: string;
  pricing: string;
  available_concessions: string;
  pricing_floor: string;
  salesforce_id: string | null;
  pricebook_entry_id: string | null;
  show_override_price_option: boolean;
  active: boolean;
  last_updated_by: string | null;
};

export const ManageProducts: React.FC = () => {
  const {
    showAddOrEditProductModal,
    setShowAddOrEditProductModal,
    showManageDeleteProductModal,
    setShowManageDeleteProductModal,
  } = useContext(ModalContext);

  const [editProductModalState, setEditProductModalState] = useState<boolean>(false);

  const [orgPaymentSettings, setOrgPaymentSettings] = useState<IPaymentSettings>({
    enable_contract_duration: false,
    contract_duration_options: [],
    default_contract_duration: null,

    enable_payment_duration: false,
    contract_term_options: [],
    default_term_duration: null,
  });

  const [selectedProduct, setSelectedProduct] = useState<TableProductType | null>(null);

  const [search, setSearch] = useState<string>("");

  const tableWrapRef = useRef<HTMLDivElement>(null);
  const [tableWrapEle, setTableWrapEle] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    setTableWrapEle(tableWrapRef.current);
  }, [tableWrapRef]);

  const [rowData, setRowData] = useState<any[] | null>(null);

  const [updateProduct] = useMutation(ADD_OR_UPDATE_PRODUCTS, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      errorToast(`Error saving Products: ${message}`);
    },
  });

  const [updatePayment, { loading: loadingUpdatePayment }] = useMutation(UPDATE_PAYMENT_SETTINGS, {
    onCompleted: ({ updateOrgPaymentSettings }) => {
      successToast("Payment settings updated");
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      errorToast(`Error saving Payment Settings: ${message}`);
    },
  });

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        width: 40,
        cellStyle: { justifyContent: "center" },
        cellRendererFramework: RowMenu,
        cellRendererParams: {
          tableWrapEle,
          setShowAddOrEditProductModal,
          setEditProductModalState,
          setShowManageDeleteProductModal,
          setSelectedProduct,
        },
        pinned: "left",
      },
      {
        headerName: "Product Name",
        width: 160,
        field: "product_title",
        pinned: "left",
        cellClass: "ag-products-overflow",
        cellRendererFramework: OverflowTooltipRenderer,
      },
      {
        headerName: "Last Edited",
        field: "updated_at",
        width: 204,
        cellClass: "ag-products-overflow",
        cellRendererFramework: OverflowTooltipRenderer,
      },
      {
        headerName: "Fee Type",
        field: "fee_type",
        width: 121,
      },
      {
        headerName: "Prepayment Terms",
        field: "prepayment_options",
        headerClass: "ag-products-center-header",
        cellStyle: { justifyContent: "center" },
        width: 152,
      },
      {
        headerName: "Price",
        field: "pricing",
        headerClass: "ag-products-center-header",
        cellStyle: { justifyContent: "center" },
        width: 156,
      },
      {
        headerName: "Allow Price Override",
        field: "show_override_price_option",
        headerClass: "ag-products-center-header",
        cellStyle: { justifyContent: "center" },
        cellRendererFramework: CellToggle,
        cellRendererParams: {
          setRowData,
          updateProduct,
        },
        width: 156,
      },
      {
        headerName: "Minimum Threshold",
        field: "pricing_floor",
        headerClass: "ag-products-center-header",
        cellStyle: { justifyContent: "center" },
        width: 148,
      },
      {
        headerName: "Discount Options",
        field: "available_concessions",
        width: 152,
        cellClass: "ag-products-overflow",
        cellRendererFramework: OverflowTooltipRenderer,
      },
      {
        headerName: "Salesforce Product ID",
        field: "salesforce_id",
        width: 158,
        cellClass: "ag-products-overflow",
        cellRendererFramework: OverflowTooltipRenderer,
        onCellClicked: (e) => copyToClipboard(e?.value || ""),
      },
      {
        headerName: "Salesforce Pricebook Entry ID",
        field: "pricebook_entry_id",
        width: 205,
        cellClass: "ag-products-overflow",
        cellRendererFramework: OverflowTooltipRenderer,
      },
      {
        headerName: "Product Visibility",
        field: "active",
        headerClass: "ag-products-center-header",
        cellStyle: { justifyContent: "center" },
        cellRendererFramework: CellToggle,
        cellRendererParams: {
          setRowData,
          updateProduct,
        },
        width: 156,
      },
    ],
    [rowData],
  );

  useQuery(FETCH_PRODUCTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      includeInactive: true,
    },
    onCompleted: ({ fetchProducts }) => {
      const newRowData = fetchProducts?.map((product: any) => ({
        ...product,
        updated_at:
          moment(product.updated_at).format("MM/DD/YYYY") +
          (!!product.last_updated_by ? ` by ${product.last_updated_by}` : ""),
        prepayment_options: [...product.prepayment_options].sort((a: number, b: number) => a - b).join(", "),
        pricing: `$${product.pricing}`,
        pricing_floor: `$${product.pricing_floor || 0}`,
        available_concessions: [...product.available_concessions]
          .sort((a: any, b: any) => a.amount - b.amount)
          ?.map((concession: any) => `$${concession.amount}`)
          ?.join(", "),
        fee_type: product.fee_type === "RECURRING" ? "Recurring" : "One-Time",
      }));
      setRowData(newRowData);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { loading: loadingPaymentSettings } = useQuery(FETCH_PAYMENT_SETTINGS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ fetchOrgPaymentSettings }) => {
      setOrgPaymentSettings(fetchOrgPaymentSettings);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <PageWrap>
      <PhoenixStyledTooltip id="product-table-cell-overflow" lineHeight={16} />

      {showAddOrEditProductModal && (
        <AddOrEditProductModal editProductModalState={editProductModalState} selectedProduct={selectedProduct} />
      )}
      {showManageDeleteProductModal && <ManageDeleteProductModal selectedProductID={selectedProduct?.id || ""} />}

      <Main>
        <Header>
          <AppText fontSize={22} fontWeight={500} lineHeight={28} style={{ marginBottom: "16px" }}>
            Manage Products
          </AppText>

          <FlexDiv justify="space-between" align="center" style={{ paddingLeft: "8px" }}>
            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
              {rowData?.length} Products
            </AppText>

            <FlexDiv gap={8}>
              <PhoenixAppButton
                buttonType="secondary"
                variant="brand"
                width={188}
                height={40}
                onClick={() => {
                  setEditProductModalState(false);
                  setShowAddOrEditProductModal(true);
                  setSelectedProduct(null);
                }}
              >
                <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1} color={theme.WHITE_COLOR}>
                  Add Product
                </AppText>
              </PhoenixAppButton>
              <PhoenixInput
                placeholder="Search Products"
                searchInput
                displayNoContextText={true}
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </FlexDiv>
          </FlexDiv>
        </Header>

        <Body>
          <SettingsContainer>
            <SettingsHeader justify="space-between" align="center">
              <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.NEUTRAL300}>
                Organization Settings
              </AppText>

              <AppText
                fontSize={10}
                fontWeight={600}
                letterSpacing={1}
                uppercase
                color={theme.PRIMARY500}
                style={{ cursor: "pointer", borderBottom: "none" }}
                onClick={() => {
                  const {
                    enable_contract_duration,
                    contract_duration_options,
                    default_contract_duration,
                    enable_payment_duration,
                    contract_term_options,
                    default_term_duration,
                  } = orgPaymentSettings;
                  updatePayment({
                    variables: {
                      input: {
                        enable_contract_duration,
                        contract_duration_options,
                        default_contract_duration,
                        enable_payment_duration,
                        contract_term_options,
                        default_term_duration,
                      },
                    },
                  });
                }}
              >
                Apply Settings
              </AppText>
            </SettingsHeader>

            <div style={{ paddingBottom: "40px", borderBottom: `1px solid ${theme.NEUTRAL200}` }}>
              <FlexDiv gap={8} align="center" style={{ marginBottom: "24px" }}>
                <Switch
                  onChange={(checked) =>
                    setOrgPaymentSettings({ ...orgPaymentSettings, enable_contract_duration: checked })
                  }
                  onColor={theme.PRIMARY500}
                  checked={orgPaymentSettings.enable_contract_duration}
                  height={16}
                  width={32}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  handleDiameter={12}
                  disabled={loadingPaymentSettings || loadingUpdatePayment}
                />

                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Enable Contract Duration
                </AppText>
              </FlexDiv>

              <SettingsText>Contract Duration</SettingsText>
              <PhoenixMultiSelect
                isMulti={true}
                isClearable={false}
                name="contract-duration"
                options={TERMS_OPTIONS.filter(
                  (o: OptionItem) => !orgPaymentSettings?.contract_duration_options?.find((c: string) => c === o.label),
                )}
                value={
                  orgPaymentSettings?.contract_duration_options?.map((o: string) => ({ label: o, value: o })) || []
                }
                onChange={(e: OptionItem[]) => {
                  setOrgPaymentSettings({
                    ...orgPaymentSettings,
                    contract_duration_options: e?.map((o) => o.label as string),
                  });
                }}
                marginBottom={false}
                style={{ marginBottom: "16px" }}
                multiValueUppercase
                isDisabled={loadingPaymentSettings || loadingUpdatePayment}
                creatableOptions
                noOptionsText="Type a value and press enter to add a contract duration option."
              />

              <SettingsText>Contract Duration Default</SettingsText>
              <PhoenixMultiSelect
                isMulti={false}
                isClearable={false}
                name="contract-duration-default"
                options={
                  orgPaymentSettings?.contract_duration_options?.map((o: string) => ({ label: o, value: o })) || []
                }
                value={{
                  label: orgPaymentSettings?.default_contract_duration,
                  value: orgPaymentSettings?.default_contract_duration,
                }}
                onChange={(e: OptionItem) =>
                  setOrgPaymentSettings({ ...orgPaymentSettings, default_contract_duration: e.label as string })
                }
                marginBottom={false}
                isDisabled={loadingPaymentSettings || loadingUpdatePayment}
              />
            </div>

            <div style={{ paddingTop: "24px" }}>
              <FlexDiv gap={8} align="center" style={{ marginBottom: "24px" }}>
                <Switch
                  onChange={(checked) =>
                    setOrgPaymentSettings({ ...orgPaymentSettings, enable_payment_duration: checked })
                  }
                  onColor={theme.PRIMARY500}
                  checked={orgPaymentSettings.enable_payment_duration}
                  height={16}
                  width={32}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  handleDiameter={12}
                  disabled={loadingPaymentSettings || loadingUpdatePayment}
                />

                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Enable Payment Terms
                </AppText>
              </FlexDiv>

              <SettingsText>Payment Terms</SettingsText>
              <PhoenixMultiSelect
                isMulti={true}
                isClearable={false}
                name="payment-terms"
                options={TERMS_OPTIONS.filter(
                  (o: OptionItem) => !orgPaymentSettings?.contract_term_options?.find((c: string) => c === o.label),
                )}
                value={orgPaymentSettings?.contract_term_options?.map((o: string) => ({ label: o, value: o })) || []}
                onChange={(e: any) =>
                  setOrgPaymentSettings({
                    ...orgPaymentSettings,
                    contract_term_options: e?.map((o: OptionItem) => o.label as string),
                  })
                }
                marginBottom={false}
                style={{ marginBottom: "16px" }}
                multiValueUppercase
                isDisabled={loadingPaymentSettings || loadingUpdatePayment}
                creatableOptions
                noOptionsText="Type a value and press enter to add a payment term option."
              />

              <SettingsText>Payment Terms Default</SettingsText>
              <PhoenixMultiSelect
                isMulti={false}
                isClearable={false}
                name="payment-terms-default"
                options={orgPaymentSettings?.contract_term_options?.map((o: string) => ({ label: o, value: o })) || []}
                value={{
                  label: orgPaymentSettings?.default_term_duration,
                  value: orgPaymentSettings?.default_term_duration,
                }}
                onChange={(e: OptionItem) =>
                  setOrgPaymentSettings({ ...orgPaymentSettings, default_term_duration: e.label })
                }
                marginBottom={false}
                isDisabled={loadingPaymentSettings || loadingUpdatePayment}
              />
            </div>
          </SettingsContainer>

          <TableContainer ref={tableWrapRef}>
            <AgGridReact
              rowData={rowData?.filter((row) => row.product_title.toLowerCase().includes(search.toLowerCase()))}
              columnDefs={columnDefs}
              enableCellTextSelection={true}
              domLayout="autoHeight"
              rowHeight={40}
              suppressMovableColumns
              suppressColumnVirtualisation
            />
          </TableContainer>
        </Body>
      </Main>
    </PageWrap>
  );
};

interface IRowMenu extends ICellRendererParams {
  tableWrapEle: HTMLDivElement | null;
  setShowAddOrEditProductModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEditProductModalState: React.Dispatch<React.SetStateAction<boolean>>;
  setShowManageDeleteProductModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<TableProductType | null>>;
}

const RowMenu = (props: IRowMenu) => {
  const rowTop = useMemo<number>(() => props?.node?.rowTop || 0, [props]);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => {
    setShowMenu(false);
  });

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <PhoenixIcon svg={more_vertical} size={16} variant="neutral" pointer onClick={() => setShowMenu(true)} />
      {showMenu &&
        !!props.tableWrapEle &&
        createPortal(
          <PopupMenu style={{ top: rowTop + 100 }} ref={ref}>
            <PopupOption
              onClick={() => {
                props.setShowAddOrEditProductModal(true);
                props.setEditProductModalState(true);
                props.setSelectedProduct(props.data);
                setShowMenu(false);
              }}
            >
              Edit Product
            </PopupOption>
            <PopupOption
              onClick={() => {
                props.setShowManageDeleteProductModal(true);
                props.setSelectedProduct(props.data);
                setShowMenu(false);
              }}
            >
              Delete Product
            </PopupOption>
          </PopupMenu>,
          props.tableWrapEle,
        )}
    </div>
  );
};

const PopupMenu = styled.div`
  position: absolute;
  left: 32px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.15s ease-in-out;
`;
const PopupOption = styled.div`
  width: 152px;
  padding: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${theme.BLACK_COLOR};
  cursor: pointer;

  background-color: ${theme.WHITE_COLOR};
  :hover {
    background-color: ${theme.PRIMARY50};
  }

  transition: background-color 0.15s ease-in-out;
`;

interface ICellToggle extends ICellRendererParams {
  setRowData?: React.Dispatch<React.SetStateAction<any[] | null>>;
  updateProduct: (product: any) => void;
}

const CellToggle = (props: ICellToggle) => {
  const { data, column } = props;
  const colId = (column as any).colId;

  return (
    <Switch
      onChange={(checked) => {
        const newData = { ...data, [colId]: checked };
        props.setRowData && props.setRowData((prev) => prev?.map((row) => (row.id === data.id ? newData : row)) || []);

        const submitValue = {
          id: newData.id,
          show_override_price_option:
            colId === "show_override_price_option" ? checked : data.show_override_price_option,
          active: colId === "active" ? checked : data.active,
          product_title: newData.product_title,
          pricing_floor: parseInt(newData?.pricing_floor?.slice(1) || 0),
          prepayment_options: !!newData?.prepayment_options?.length
            ? newData?.prepayment_options?.split(", ")?.map((po: string) => parseInt(po)) || []
            : [],
          pricing: parseInt(newData?.pricing?.slice(1) || 0),
          available_concessions:
            (!!newData?.available_concessions?.length
              ? newData?.available_concessions
                  ?.split(", ")
                  ?.map((e: string) => ({ label: e, amount: parseInt(e.slice(1)) }))
              : []) || [],
          pricebook_entry_id: newData.pricebook_entry_id,
          salesforce_id: newData.salesforce_id,
          fee_type: newData.fee_type === "Recurring" ? "RECURRING" : "ONE_TIME",
        };

        props.updateProduct({ variables: { products: [submitValue] } });
      }}
      onColor={theme.PRIMARY500}
      checked={colId === "show_override_price_option" ? data.show_override_price_option : data.active}
      height={16}
      width={32}
      checkedIcon={false}
      uncheckedIcon={false}
      handleDiameter={12}
    />
  );
};

const OverflowTooltipRenderer = (props: ICellRendererParams) => {
  ReactTooltip.rebuild();
  return (
    <div
      style={{ width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
      data-for={props?.value?.length >= 15 ? "product-table-cell-overflow" : undefined}
      data-tip={props.value}
    >
      <span>{props.value}</span>
    </div>
  );
};

const PageWrap = styled.div`
  height: 100vh;
  padding: 24px;
`;

const Main = styled.div`
  height: 100%;

  background-color: ${theme.WHITE_COLOR};
  border-radius: 16px;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 40px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const Body = styled.div`
  display: flex;
  min-width: 1160px;
  height: 100%;
`;

const SettingsContainer = styled.div`
  width: 400px;
  height: 100%;
  padding: 24px;

  border-right: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;
`;

const SettingsHeader = styled(FlexDiv)`
  margin-bottom: 24px;
`;

const SettingsText = styled(AppText)`
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const TableContainer = styled.div`
  position: relative;

  width: 100%;
  height: 84%;
  padding: 24px;

  overflow: auto;

  .ag-root {
    min-height: 295px;
  }

  .ag-header,
  .ag-header-container,
  .ag-header-row {
    min-height: 40px !important;
    background-color: ${theme.PRIMARY100};
  }
  .ag-header-cell {
    padding: 0px 16px;
  }

  .ag-products-center-header {
    .ag-header-cell-label {
      justify-content: center;
    }
  }

  .ag-cell-value,
  .ag-header-cell-label {
    font-size: 12px;
    line-height: 18px;
    color: ${theme.BLACK_COLOR};
  }
  .ag-header-cell-label {
    font-weight: 600;
  }

  .ag-row-odd {
    background-color: ${theme.PRIMARY50};
  }

  .ag-cell {
    display: flex;
    align-items: center;
    padding: 0px 16px;

    border: none;
  }

  .ag-products-overflow {
    .ag-cell-wrapper {
      width: 100%;
    }
  }
`;
