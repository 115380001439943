import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { chevron_down, chevron_up } from "../../../../images/NewDesign";
import { IIntegrations } from "../../../../types";
import { theme } from "../../../../utils/theme";
import { appToast } from "../../../../utils/toast";
import { AppErrorText, AppText, SkeletonBlock } from "../../../UI";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { PhoenixStyledTooltip } from "../../../Dumb/PhoenixStyledTooltip";
import DOMPurify from "dompurify";
import { isValidURL } from "../../../../utils/misc";

interface ISalesforceRelatedRecordsProps {
  leadId: string;
}

// top level record

interface IRecord {
  [object_name: string]: ISubRecord[];
}

// sub records

interface ISubRecord {
  [object_field: string]: ISubRecordField;
}

// sub record fields (FE doesn't actully know the field names as they are fetched from SF by the BE)

interface BaseFieldPairs {
  ops_status: "Success" | "Failure";
  ops_status_boolean: boolean;
  error_message: string;
}
interface ISubRecordField extends BaseFieldPairs {
  string?: string | boolean | number | null;
}

const FETCH_RECORDS = gql`
  query FetchLeadIntegrationROFields($integration: INTEGRATION_TYPE!, $leadId: String!) {
    fetchLeadIntegrationROFields(integration: $integration, lead_id: $leadId) {
      data
      ops_status
      ops_status_boolean
    }
  }
`;

const SalesforceRelatedRecordsLeadCardTab = ({ leadId }: ISalesforceRelatedRecordsProps) => {
  const { data, loading: loadingRecords, error: errorRecords } = useQuery(FETCH_RECORDS, {
    fetchPolicy: "network-only",
    variables: {
      leadId,
      integration: IIntegrations.Salesforce,
    },
    onCompleted: (data) => {},
    onError: (error) => {
      appToast("Error loading Salesforce related records.");
    },
  });

  const [expandedRecordIds, setExpandedRecordIds] = useState<string[]>([]);

  const ExpandButton = ({
    onClick,
    expanded,
    disabled,
  }: {
    onClick: () => void;
    expanded: boolean;
    disabled: boolean;
  }) => {
    return (
      <PhoenixIcon
        disabled={disabled}
        svg={expanded ? chevron_up : chevron_down}
        size={24}
        color={theme.PRIMARY600}
        onClick={() => {
          onClick();
        }}
      />
    );
  };

  const loading = loadingRecords;

  const error = errorRecords;

  if (error) {
    return (
      <Main>
        <AppErrorText>Error loading Salesforce related records.</AppErrorText>;
      </Main>
    );
  }

  if (loading) {
    return (
      <Main>
        <SkeletonBlock height={100} width={"100%"} borderRadius={0} />
      </Main>
    );
  }

  const noRecords = data?.fetchLeadIntegrationROFields?.data?.length === 0;

  if (noRecords) {
    return (
      <Main
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100px",
        }}
      >
        <AppText
          style={{
            textAlign: "center",
            color: theme.NEUTRAL400,
          }}
        >
          No record mappings found.
        </AppText>
      </Main>
    );
  }

  // to do update data model to not have these in the same level as the fields
  const KEY_VALUES_TO_NEVER_SHOW = ["ops_status", "ops_status_boolean", "error_message", "id", "__typename"];

  const convertValuesToString = (value: any) => {
    let current_value = value;

    if (typeof value === "object" || Array.isArray(value)) {
      current_value = JSON.stringify(value);
    }

    if (value === null || value === "null") {
      current_value = "";
    }

    if (value === undefined || value === "undefined") {
      current_value = "";
    }

    if (typeof value === "boolean") {
      current_value = value ? "true" : "false";
    }

    if (typeof value === "number") {
      current_value = value.toString();
    }

    // string or html etc...
    return DOMPurify.sanitize(current_value);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    appToast("Copied to clipboard.");
  };
  return (
    <Main>
      {data?.fetchLeadIntegrationROFields?.data?.slice()?.map((record: IRecord) => (
        <>
          <PhoenixStyledTooltip
            id={`record-object-tooltip`}
            multiline
            place="top"
            effect="solid"
            className="sib-tooltip"
            css={{
              maxWidth: 600,
              lineHeight: 1.4,
              textAlign: "center",
              fontFamily: theme.PRIMARY_FONT,
            }}
            backgroundColor={theme.PRIMARY800}
            getContent={(dataTip) => (
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {}}
              >
                {dataTip}
              </span>
            )}
          />
          {Object.entries(record)?.map(([object_name, object_fields], index) => (
            <SubRecordContainer key={object_name ? `${object_name}-${index}` : `${index}`}>
              <SubRecordHeader>
                <SubRecordName
                  data-tip={object_name}
                  data-for={`record-object-tooltip`}
                  onClick={() => {
                    copyToClipboard(object_name);
                  }}
                  style={{
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  {object_name}
                </SubRecordName>
                {object_fields.some((subRecord: ISubRecord) => !subRecord.ops_status_boolean) ? (
                  <SalesforceErrorText>
                    {object_fields.find((subRecord: ISubRecord) => !subRecord.ops_status_boolean)?.error_message}
                  </SalesforceErrorText>
                ) : (
                  <ExpandButton
                    disabled={false}
                    onClick={() => {
                      if (expandedRecordIds.includes(object_name)) {
                        setExpandedRecordIds(expandedRecordIds.filter((id) => id !== object_name));
                      } else {
                        setExpandedRecordIds([...expandedRecordIds, object_name]);
                      }
                    }}
                    expanded={expandedRecordIds.includes(object_name)}
                  />
                )}
              </SubRecordHeader>
              {expandedRecordIds.includes(object_name) && (
                <SubRecordContainer>
                  {object_fields?.map((subRecord: ISubRecord, index) => {
                    return (
                      <>
                        <PhoenixStyledTooltip
                          id={`record-fields-tooltip`}
                          multiline
                          place="top"
                          effect="solid"
                          className="sib-tooltip"
                          css={{
                            maxWidth: 600,
                            lineHeight: 1.4,
                            textAlign: "center",
                            fontFamily: theme.PRIMARY_FONT,
                          }}
                          backgroundColor={theme.PRIMARY800}
                          getContent={(dataTip) => (
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "10px",
                                lineHeight: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() => {}}
                            >
                              {dataTip}
                            </span>
                          )}
                        />
                        <FieldContainer key={index}>
                          <Field>
                            <FieldTextColumn>
                              <FieldName header>Field</FieldName>
                            </FieldTextColumn>
                            <FieldTextColumn>
                              <FieldValue header>Value</FieldValue>
                            </FieldTextColumn>
                          </Field>
                          {Object.entries(subRecord)?.map(([field_name, field_value]) => {
                            // todo: the BE model could be updated so the FE dosen't have to do this

                            // filter out non Sellfire fields

                            if (KEY_VALUES_TO_NEVER_SHOW.includes(field_name)) {
                              return null;
                            }

                            const display_value = convertValuesToString(field_value);

                            return (
                              <Field key={field_name + field_value}>
                                <FieldTextColumn>
                                  <FieldName
                                    data-tip={field_name}
                                    data-for={`record-fields-tooltip`}
                                    onClick={() => {
                                      copyToClipboard(field_name);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                  >
                                    {field_name}
                                  </FieldName>
                                </FieldTextColumn>
                                <FieldTextColumn>
                                  <FieldValue
                                    data-tip={convertValuesToString(field_value)}
                                    data-for={`record-fields-tooltip`}
                                    onClick={() => {
                                      copyToClipboard(convertValuesToString(field_value));
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                  >
                                    {isValidURL(display_value) ? (
                                      <a
                                        href={`${display_value.startsWith("http") ? "" : "//"}${display_value}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {display_value}
                                      </a>
                                    ) : (
                                      display_value
                                    )}
                                  </FieldValue>
                                </FieldTextColumn>
                              </Field>
                            );
                          })}
                        </FieldContainer>
                      </>
                    );
                  })}
                </SubRecordContainer>
              )}
            </SubRecordContainer>
          ))}
        </>
      ))}
    </Main>
  );
};

const Main = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.PRIMARY100};
  display: flex;
  flex-direction: column;
`;

const FieldTextColumn = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubRecordHeader = styled.div`
  width: 100%;
  height: max-content;
  min-height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
`;

const SubRecordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.PRIMARY100};
  padding: 0px 16px;
  width: 100%;
  max-width: 100%;
  overflow: auto;
`;

const SubRecordName = styled(AppText)`
  color: ${theme.NEUTRAL400};
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  // elipsis if text overflows
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL200};
`;

const Field = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  gap: 8px;
`;

const FieldName = styled(AppText)<{ header?: boolean }>`
  font-size: 12px;
  font-weight: ${(props) => (props.header ? "600" : "400")};
  // elipsis no overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const FieldValue = styled(AppText)<{ header?: boolean }>`
  font-size: 12px;
  font-weight: ${(props) => (props.header ? "600" : "400")};

  // old
  // text should never wrap and should turn into scroll if it overflows
  /* white-space: nowrap; */

  // elipsis no overflow
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const SalesforceErrorText = styled(AppText)`
  font-size: 10px;
  overflow: auto;
  color: ${theme.NEUTRAL400};
  width: 100%;
`;

export { SalesforceRelatedRecordsLeadCardTab };
