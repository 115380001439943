import React from "react";
import { useField } from "formik";
import { TemplateEditor } from "../UI";
import { TemplateEditorProps } from "../UI";

interface TemplateEditorFieldProps extends TemplateEditorProps {
  name: string;
  style?: React.CSSProperties;
  maxKeyCount?: number;
}

const TemplateEditorField: React.FC<TemplateEditorFieldProps> = ({ style, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;

  const contextText = meta?.touched && meta?.error;

  const handleContentChange = (content: string) => {
    setValue(content);
    setTouched(true);
  };

  return <TemplateEditor {...field} {...props} contextText={contextText} onContentChange={handleContentChange} />;
};

export { TemplateEditorField };
