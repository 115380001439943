import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/react";
import { loggedInUser } from "../apollo/cache";
import { environment } from "../utils/variables";

export const initLogRocket = () => {
  const user = loggedInUser();
  if (environment === "Development") {
    return;
  }

  if (!user || !user.email) {
    return;
  }
  LogRocket.init("d4wwxx/opsiq", {});

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
  setupLogRocketReact(LogRocket);
  setLogRocket();
};

export const setLogRocket = () => {
  console.log("setup log rocket");
  const user = loggedInUser();
  console.log("user: ", user);

  if (!user || !user.email) {
    return;
  }
  LogRocket.identify(user.email, {
    name: `${user.first_name} ${user.last_name}`,
    email: `${user.email}`,
    id: `${user?.id}`,
    role: user.role || "",
    organization_id: user.organization_id || "",
    phone_number: user.phone_number || "",
    environment: environment || "",
  });
};
