import styled from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import {
  AppButton,
  AppText,
  AppModalPriorityPopup,
  Loading,
  AppErrorText,
  AppSidebarCard,
  NewAppSelect,
  FlexDiv,
} from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../Field";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import { Modal } from "./Modal";
import { ModalContext } from "../../context";
import Switch from "react-switch";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { FETCH_PIPELINE_VALUES } from "../Segments";
import { loggedInUser } from "../../apollo/cache";
import { DEFAULT_MRR_LABEL } from "../../utils/variables";
import { updateLocalOrg, updateLocalUser } from "../Smart";
import { PhoenixAppButton, PhoenixMultiSelect, PhoenixRadio } from "../UI/Phoenix";
import { PhoenixInputField } from "../Field/Phoenix";

interface DisappearingDivProps {}

const MRR_OPTIONS = [
  { label: "MRR", value: "MRR" },
  { label: "MRR + One-Time Fees", value: "MRR + One-Time Fees" },
  { label: "Revenue Value", value: "Revenue Value" },
];

interface MyFormikProps {
  mrr: number;
  mrrLabel: string;
  mrrRequiredOnSale: boolean;
}

const editEmailSchema = Yup.object().shape({
  mrr: Yup.number().required("MRR Default Value is required"),
  mrrLabel: Yup.string(),
  mrrRequiredOnSale: Yup.boolean(),
});

const UPDATE_ORG_MRR = gql`
  mutation updateOrgMRR($mrr: Int!, $mrrRequiredOnSale: Boolean!, $mrrLabel: String!) {
    updateOrgMRR(mrr: $mrr, mrr_required_on_sale: $mrrRequiredOnSale, mrr_label: $mrrLabel) {
      id
      mrr_label
      mrr
      mrr_required_on_sale
    }
  }
`;

const PipelineMetricDefaultValueModalV2: React.FC<DisappearingDivProps> = () => {
  const { pipelineMetricDefaultValueModal, setPipelineMetricDefaultValueModal } = useContext(ModalContext);

  const { data: dataPipelineValues, loading: loadingPipelineValues, error: errorPipelineValues } = useQuery(
    FETCH_PIPELINE_VALUES,
    {
      fetchPolicy: "network-only",
      variables: { org_id: loggedInUser().organization_id },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  console.log("pipeline called");

  const [updateOrgMRR, { loading: loadingOrgMRR }] = useMutation(UPDATE_ORG_MRR, {
    onCompleted({ updateOrgMRR }) {
      console.log("updateOrgMRR: ", updateOrgMRR);
      appToast("Updated Default MRR Values");
      setPipelineMetricDefaultValueModal(false);
      updateLocalOrg({ mrr_label: updateOrgMRR?.mrr_label });
      updateLocalUser(updateOrgMRR?.mrr_label);
    },
    onError({ message }) {
      console.log("Error in updateOrgMRR: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateOrgMRR GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchOrganization"],
  });

  if (loadingPipelineValues)
    return (
      <Modal open={pipelineMetricDefaultValueModal} onClose={() => setPipelineMetricDefaultValueModal(false)}>
        <ContentDiv>
          <Loading />
        </ContentDiv>
      </Modal>
    );
  if (errorPipelineValues)
    return (
      <Modal open={pipelineMetricDefaultValueModal} onClose={() => setPipelineMetricDefaultValueModal(false)}>
        <ContentDiv>
          <AppErrorText>Error loading default values</AppErrorText>
        </ContentDiv>
      </Modal>
    );

  return (
    <Modal open={pipelineMetricDefaultValueModal} onClose={() => setPipelineMetricDefaultValueModal(false)}>
      <ContentDiv>
        <Formik
          enableReinitialize={true}
          initialValues={{
            mrr: Number(dataPipelineValues?.fetchOrganization?.mrr),
            mrrLabel: dataPipelineValues?.fetchOrganization?.mrr_label,
            mrrRequiredOnSale: dataPipelineValues?.fetchOrganization?.mrr_required_on_sale,
          }}
          validationSchema={editEmailSchema}
          onSubmit={async (values) => {
            await updateOrgMRR({
              variables: {
                mrr: Number(values.mrr),
                mrrRequiredOnSale: values.mrrRequiredOnSale,
                mrrLabel: values.mrrLabel,
              },
            });
          }}
        >
          {({ submitForm, isSubmitting, values, errors, setFieldValue }: FormikProps<MyFormikProps>) => {
            return (
              <>
                <TitleDiv>
                  <PopupTitle>Edit Default Revenue Forecast</PopupTitle>
                </TitleDiv>
                <div style={{ width: "336px" }}>
                  <FlexDiv />
                  <PhoenixMultiSelect
                    isMulti={false}
                    isClearable={false}
                    options={MRR_OPTIONS}
                    name="mrrLabel"
                    titleText="Default Revenue Forecast Metric"
                    value={MRR_OPTIONS?.find((option) => option.value === values.mrrLabel)}
                    onChange={async (e: any) => {
                      setFieldValue(`mrrLabel`, e.value);
                    }}
                  />
                </div>
                <PhoenixInputField name="mrr" titleText="Default Revenue Forecast Value" type="number" width={336} />
                <VerticalDiv>
                  <SwitchLabel>Sale Phase Value</SwitchLabel>
                  <FlexDiv gap={8}>
                    <PhoenixRadio
                      selected={values.mrrRequiredOnSale === true}
                      onClick={() => setFieldValue("mrrRequiredOnSale", true)}
                    />
                    <AppText fontSize={12} fontWeight={400}>
                      Have Reps enter a value in the {values.mrrLabel || DEFAULT_MRR_LABEL} field in the Make Sale flow.
                    </AppText>
                  </FlexDiv>
                  <FlexDiv gap={8}>
                    <PhoenixRadio
                      selected={values.mrrRequiredOnSale === false}
                      onClick={() => setFieldValue("mrrRequiredOnSale", false)}
                    />
                    <AppText fontSize={12} fontWeight={400}>
                      Automatically populate the MRR + One-Time Fees Reps associate with product selections in the Make
                      Sale flow in the {values.mrrLabel || DEFAULT_MRR_LABEL} field.
                    </AppText>
                  </FlexDiv>
                </VerticalDiv>
                <ButtonsContainer>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    onClick={() => setPipelineMetricDefaultValueModal(false)}
                    uppercase={true}
                  >
                    Cancel
                  </PhoenixAppButton>

                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand"
                    onClick={submitForm}
                    uppercase={true}
                    disabled={isSubmitting}
                  >
                    Update
                  </PhoenixAppButton>
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </ContentDiv>
    </Modal>
  );
};

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4px;
  margin-bottom: 15px;
  min-height: 200px;
  gap: 16px;
  width: 336px;
`;

const SwitchLabel = styled(AppText)`
  /* font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-right: 16px; */
  font-size: 12px;
  margin-right: 16px;
  font-weight: 600;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  height: 72px;
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
`;

const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  width: 336px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ContentDiv = styled.div`
  width: 456px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 350px;
`;

interface SelectedProp {
  selected: boolean;
}

const RadioBorder = styled.div<SelectedProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 50%;
  margin-right: 8px;
  height: fit-content;
  width: fit-content;
`;

const RadioButton = styled.div<SelectedProp>`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
`;

const RadioText = styled(AppText)`
  font-size: 11px;
  line-height: 15px;
  opacity: 0.75;
`;

const TitleDiv = styled.div`
  height: 56px;
  width: 100%;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

export { PipelineMetricDefaultValueModalV2 };
