import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { AppButton, AppErrorText, Loading } from "../UI";
import { FormMultiSelectField } from "../Field";
import { ModalContext } from "../../context";
import { Dispatch, SetStateAction } from "react";
import { Formik, FormikProps } from "formik";
import { toTitleCase } from "../../utils/format";
import * as Yup from "yup";
import { appToast } from "../../utils/toast";
import { Modal } from "./Modal";
export const FETCH_USER_FOR_SHARING = gql`
  query fetchUserForSharing {
    fetchUserForSharing {
      id
      full_name
      role
    }
  }
`;

export const FETCH_SHARED_USERS_FROM_FOLDER = gql`
  query fetchSharedUsersFromFolder($folder_id: String!) {
    fetchSharedUsersFromFolder(folder_id: $folder_id) {
      id
      full_name
      role
    }
  }
`;

export const UPDATE_FOLDER_PERMISSION = gql`
  mutation updateFolderPermission($folder_id: String!, $user_id_list: [String!]!) {
    updateFolderPermission(folder_id: $folder_id, user_id_list: $user_id_list) {
      id
      name
      shared_user {
        id
        full_name
      }
    }
  }
`;

interface CallFeedbackProps {
  blinds: boolean;
  setBlinds: Dispatch<SetStateAction<boolean>>;
  folderID: string;
}

interface MyFormikProps {
  id_array: any[];
}

const callFeedbackSchema = Yup.object().shape({
  id_array: Yup.array().required("Select at least one person!"),
});

const ShareFolderModal: React.FC<CallFeedbackProps> = ({ blinds, setBlinds, folderID }) => {
  // Fetch all Managers and Admins
  const { data: dataUsers, loading: loadingUsers, error: errorUsers } = useQuery(FETCH_USER_FOR_SHARING, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataPermittedUsers, loading: loadingPermittedUsers, error: errorPermittedUsers } = useQuery(
    FETCH_SHARED_USERS_FROM_FOLDER,
    {
      variables: { folder_id: folderID },
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [updateFolderPermission] = useMutation(UPDATE_FOLDER_PERMISSION, {
    onCompleted({ updateFolderPermission }) {
      setBlinds(false);
      appToast("Updated permitted users successfully!");
      if (!updateFolderPermission) {
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateFolderPermission GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchSharedUsersFromFolder"],
  });

  const allUsersOptions = dataUsers?.fetchUserForSharing
    ?.slice()
    ?.sort((a: { full_name: string }, b: { full_name: string }) => {
      if (a.full_name?.[0] > b.full_name?.[0]) return 1;
      if (a.full_name?.[0] < b.full_name?.[0]) return -1;
    })
    ?.map((item: any) => {
      return {
        value: item?.id,
        label: toTitleCase(item.full_name),
      };
    });

  return (
    <Modal open={blinds} onClose={() => setBlinds(false)} closeButtonSize={16}>
      <FeedbackContainer>
        {loadingUsers || loadingPermittedUsers ? (
          <Loading />
        ) : errorUsers || errorPermittedUsers ? (
          <AppErrorText>Error loading users</AppErrorText>
        ) : (
          <>
            {/* <Header>Share this folder with other people in your organization</Header> */}
            <Header>Change who can view this folder</Header>
            {/* <InfoDiv>{role === "manager" ? managerObject.info : repObject.info}</InfoDiv> */}
            <Formik
              enableReinitialize={true}
              initialValues={{
                id_array: !!dataPermittedUsers?.fetchSharedUsersFromFolder?.length
                  ? dataPermittedUsers?.fetchSharedUsersFromFolder?.map((item: any) => ({
                      value: item?.id,
                      label: toTitleCase(item.full_name),
                    }))
                  : [],
              }}
              onSubmit={async (values) => {
                await updateFolderPermission({
                  variables: {
                    folder_id: folderID,
                    user_id_list: values?.id_array?.map((item: any) => {
                      return item.value;
                    }),
                  },
                });
                // role === "manager" ? managerObject.onSubmit(values) : repObject.onSubmit(values);
              }}
              validationSchema={callFeedbackSchema}
              isInitialValid={false}
            >
              {({ submitForm, setFieldValue, isValid, values, isSubmitting }: FormikProps<MyFormikProps>) => {
                return (
                  <>
                    <SelectDiv>
                      <FormMultiSelectField
                        name={"id_array"}
                        options={allUsersOptions}
                        onChange={(e: any) => {
                          setFieldValue("id_array", !!e ? e : []);
                        }}
                      />
                    </SelectDiv>
                    {isSubmitting ? <Loading /> : <SubmitButton onClick={submitForm}>Save</SubmitButton>}
                    <CancelButton
                      onClick={() => {
                        setBlinds(false);
                      }}
                    >
                      Cancel
                    </CancelButton>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </FeedbackContainer>
    </Modal>
  );
};

const FeedbackContainer = styled.div`
  border-radius: 4px;
  height: 576px;
  width: 580px;
  /* height: fit-content; */
  background-color: ${theme.WHITE_COLOR};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`;

const Header = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-top: 96px;
  margin-bottom: 40px;
  padding: 0px 64px 0px 65px;
  text-align: center;
`;

const InfoDiv = styled.div`
  font-size: 16px;
  padding: 0px 104px 0px 109px;
  font-weight: 400;
  margin-bottom: 32px;
  height: 42px;
  text-align: center;
`;

const SelectDiv = styled.div`
  max-width: 505px;
  min-width: 505px;
  /* min-height: 300px; */
`;

const SubmitButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 24px;
  border-radius: 2px;
`;

const CancelButton = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: black;
  width: auto;
  font-size: 12px;
  margin-bottom: 50px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export { ShareFolderModal };
