import { useQuery } from "@apollo/client";
import React, { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import { CallContext } from "../../context";
import { theme } from "../../utils/theme";
import { FETCH_LEAD } from "./Call";
import { CallResultV2, InactiveRightSidebar } from "./CallSegments";
import { google_meet_logo, zoom_logo } from "src/images/NewDesign";

export const ZoomMeetingIndicator = () => (
  <div style={{ position: "absolute", top: 8, right: 8, display: "flex", alignItems: "center", gap: 7 }}>
    <img src={zoom_logo} height={32} />
  </div>
);

export const GoogleMeetIndicator = () => (
  <div style={{ position: "absolute", top: 8, right: 8, display: "flex", alignItems: "center", gap: 7 }}>
    <img src={google_meet_logo} height={32} />
  </div>
);

const DashboardSidebarRight: React.FC = () => {
  const { callLeadId, callState, callOptionStackPeek, isNonCallActivity, isZoomCall, isGoogleMeetCall } = useContext(
    CallContext,
  );

  const { data } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: callLeadId },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <SidebarContainer callState={callState || isNonCallActivity} callOptionStackPeek={callOptionStackPeek()}>
      {callState || isNonCallActivity ? (
        <>
          {isZoomCall && <ZoomMeetingIndicator />}
          {isGoogleMeetCall && <GoogleMeetIndicator />}
          <CallResultV2
            // Displays a title for the non-call activity section
            logNonCallTitle={
              isNonCallActivity
                ? data?.fetchLead?.business_name
                  ? `${data?.fetchLead?.business_name}`
                  : data?.fetchLead?.first_name || data?.fetchLead?.last_name
                  ? `${data?.fetchLead?.first_name} ${data?.fetchLead?.last_name}`
                  : ""
                : ""
            }
            isInHeldPhase={data?.fetchLead?.phase === "HeldPhase"}
            lead_primary_email={data?.fetchLead?.primary_email ?? ""}
            lead_full_name={`${data?.fetchLead?.first_name} ${data?.fetchLead?.last_name}`}
            scheduled_event_behavior={data?.fetchLead?.sequence_step?.actions?.[0]?.scheduled_event_behavior}
          />
        </>
      ) : (
        <InactiveRightSidebar />
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div<{ callState: boolean | undefined; callOptionStackPeek: string }>`
  margin-left: auto;
  padding: ${(props) => (props.callOptionStackPeek === "custom make sale" ? "24px 0px" : props.callState ? "24px" : 0)};
  padding-top: 18px;
  border-radius: 8px;
  width: 480px;
  max-height: 100vh;
  background-color: ${(props) => (props.callState ? theme.WHITE_COLOR : "none")};
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export { DashboardSidebarRight };
