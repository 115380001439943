import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText } from "../AppText";
import { FlexDiv } from "../FlexDiv";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import { clamp } from "lodash";
import { SkeletonBlock } from "../SkeletonBlock";
import { filterSequenceDataGlobal } from "../../../utils/sequences";
import { primaryBarColors } from "../../../utils/sequence-reporting";
import { Dot } from "../../Pages";
import { AllSequenceFetchShape } from "../../../types/SequenceTypes";

export interface SequenceGraphProps {
  /** gql Query */
  dataFetch: DocumentNode;
  /** Determines if we refetch `dataFetch` on sequence_id or phase global filter change.
   * Some charts do not require a refetch as the metrics can easily be filtered by FE.
   *
   * If x-axis data is not sequence, this will likely be true. */
  refetchOnGlobalFilterChange: boolean;
  /** Variables for dataFetch query */
  fetchArgs?: {
    upperbound_date: string | Date | undefined;
    lowerbound_date: string | Date | undefined;
    sequence_ids: string[];
    team_ids: string[];
    user_ids: string[];
    site_ids: string[];
  };

  /** Key name that holds the chart value.
   *
   * @example
   * If the query returns [{id: 1, revenue_influenced: 100}, {id: 2, revenue_influenced: 210}, {id: 3, revenue_influenced: 321}]
   * Then dataKey should be 'revenue_influenced'
   */
  dataKey: string;
  /** Alternate dataKey [] */
  altDataKeys?: string[];

  /** ID of chart. Used to distinguish charts from each other. It is possible to have two charts with the same title and dataFetch so we use ID to differentiate.
   *
   * __Once ID is set for a chart it should not be changed__. ID for charts are saved in local storage to help determine chart display order. Changing ID of a chart after its been loaded on a users device can be problematic.
   */
  id?: string;

  /** Overrides altDataKeys with primary dataKey name. Used for non-grouped bar charts that have multiple datakeys */
  dataKeyOverride?: boolean;
  /** Groups altDataKey bars together */
  groupedBarChart?: boolean;

  title?: string;
  description?: string;

  /** Optional legend. string[] of legend labels. Legend dot colors are mapped to match chartTheme */
  legend?: string[];
  /** Position of legend. Default is top */
  legendPosition?: "top" | "right";

  /** Display a metric on the Y axis tick values
   * @param Revenue will display '$100'
   * @param Percent will display '100%'
   * @default undefined will display '100' */
  metric?: "Revenue" | "Percent";

  yAxisLabel?: string;
  xAxisLabel?: string;

  chartTheme?: string[];

  /** Minimum chart width in pixels. Default is 320 */
  minChartWidth?: number;

  /** All sequence data gathered from reporting page. Used for global filters. */
  allSequenceData?: AllSequenceFetchShape[];
  /** Sequence specific result filter */
  filterByPhases?: string[];
  /** Sequence specific result filter */
  filterBySequences?: string[];

  /** Renderer for custom tooltip. Use to display extra information in tooltip. Takes any valid TSX */
  tooltipLabelFormatter?: (payload: any) => void;

  /** Used to format data returned from chart fetch.  */
  dataFormatter?: (dataVal: any, dataKeys: string[]) => any;

  /** Used to determine if a chart has been loaded once on page initial load. Helps with onDragEnd refetch caching.
   *
   * Charts are unmounted and remounted on segment drag end so we must track this info in a higher order component and pass it as a prop.
   *
   * Used internally. Should not be manually passed when creating new charts. */
  finishedLoadingCharts?: DocumentNode[];
  setFinishedLoadingCharts?: React.Dispatch<React.SetStateAction<DocumentNode[]>>;
}

const chartHeight = 132;

export const SequenceGraph: React.FC<SequenceGraphProps> = ({
  dataFetch,
  refetchOnGlobalFilterChange,
  fetchArgs,
  dataKey,
  altDataKeys,
  dataKeyOverride = false,
  groupedBarChart = false,
  title,
  description,
  legend,
  legendPosition,
  metric,
  yAxisLabel,
  xAxisLabel,
  chartTheme,
  minChartWidth = 320,
  allSequenceData,
  filterByPhases = [],
  filterBySequences = [],
  tooltipLabelFormatter,
  dataFormatter,
  finishedLoadingCharts,
  setFinishedLoadingCharts,
}) => {
  const [chartData, setChartData] = useState<any>([]);

  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const didFirstLoad = useMemo(() => !!finishedLoadingCharts?.includes(dataFetch), []);

  const { data, loading, error } = useQuery(dataFetch, {
    fetchPolicy: finishedLoadingCharts?.includes(dataFetch) ? "cache-first" : "network-only",
    skip: !fetchArgs?.sequence_ids,
    variables: {
      sequenceDashboardInput: fetchArgs,
    },
    onCompleted() {
      let dataVal = Object.values(data)?.[0] as [];
      const dataKeys = [dataKey, ...(!!altDataKeys ? altDataKeys : [])];

      if (!!dataFormatter) {
        dataVal = dataFormatter(dataVal, dataKeys);
      }

      // filter dataVal to only include objects with keys that match dataKeys
      let filteredDataVal = dataVal
        ?.filter((item) => Object.keys(item).some((key) => dataKeys.includes(key)))
        ?.map((d: any, i: number) => ({ ...d, index: i }));

      const locallyFilteredData =
        !refetchOnGlobalFilterChange &&
        allSequenceData &&
        filterSequenceDataGlobal(dataVal, allSequenceData, filterBySequences, filterByPhases);

      // replace all altDataKeys with primary dataKey if dataKeyOverride is true
      if (dataKeyOverride) {
        filteredDataVal = filteredDataVal?.map((item: any) => {
          const keys = Object.keys(item);
          const matchingKey = keys.find((key) => dataKeys.includes(key));

          if (matchingKey) {
            const modifiedItem = { ...item };
            modifiedItem.value = item[matchingKey] || 0;
            delete modifiedItem[matchingKey];
            return modifiedItem;
          }

          return item;
        });
      }

      setChartData(!refetchOnGlobalFilterChange ? locallyFilteredData : filteredDataVal);
      if (setFinishedLoadingCharts && !finishedLoadingCharts?.includes(dataFetch)) {
        setFinishedLoadingCharts((oldArray) => [...oldArray, dataFetch]);
      }
    },
  });

  useEffect(() => {
    if (!data) return;
    let dataFetch = Object.values(data)?.[0] as [];
    const dataKeys = [dataKey, ...(!!altDataKeys ? altDataKeys : [])];

    if (!!dataFormatter) {
      dataFetch = dataFormatter(dataFetch, dataKeys);
    }

    if (!refetchOnGlobalFilterChange && allSequenceData) {
      const newChartData = filterSequenceDataGlobal(dataFetch, allSequenceData, filterBySequences, filterByPhases);
      setChartData(newChartData);
    }
  }, [filterBySequences, filterByPhases, allSequenceData]);

  const longestYAxisLabelLength = useMemo(
    // look at all dataKey and altDataKey values, remove decimal, and find the longest length
    () => {
      const dataKeys = [dataKey, ...(altDataKeys || [])];
      const labelLengths = chartData
        ?.map((c: any) =>
          dataKeys?.map(
            (key) => (~~c[key])?.toString() + (metric === "Percent" ? "%" : metric === "Revenue" ? "$" : ""),
          ),
        )
        .flat()
        .reduce((acc: any, cur: any) => (cur.length > acc ? cur.length : acc), 0);

      return labelLengths;
    },
    [chartData],
  );

  const calculatedChartWidth = useMemo(
    // Recharts `BarChart` component does not have dynamic width calculation. We need to do this ourselves.

    /* Either minChartWidth or length of chart data multiplied by 32. 32 is what I found to be best.
     If altDataKeys are present, add 10px per altDataKey to the width multiplier. */
    () =>
      Math.max(
        minChartWidth,
        chartData.length * (32 + (!!altDataKeys && groupedBarChart ? 10 * altDataKeys.length : 0)),
      ),
    [chartData],
  );

  const calculateYAxisWidth = useCallback(
    // Recharts doesnt have dynamic YAxis width calculation. We need to do this ourselves.
    () => (longestYAxisLabelLength === 4 ? longestYAxisLabelLength * 6 : longestYAxisLabelLength * 5.2),
    [longestYAxisLabelLength],
  );

  const barTheme = useMemo(() => chartTheme || primaryBarColors, [chartTheme]);

  return (
    <SequenceGraphContainer>
      {!loading ? (
        <SequenceContentContainer gap={16} direction="column" style={{ height: "100%" }} didFirstLoad={didFirstLoad}>
          <div>
            {!!title && (
              <AppText fontSize={12} fontWeight={600} lineHeight={18} color={theme.BLACK_COLOR}>
                {title}
              </AppText>
            )}
            {!!description && (
              <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.NEUTRAL300}>
                {description}
              </AppText>
            )}
            {!!legend && (!legendPosition || legendPosition === "top") && (
              <FlexDiv gap={16} align="center" wrap="wrap" style={{ paddingTop: "4px" }}>
                {legend?.map((label: string, i: number) => (
                  <FlexDiv gap={4} align="center" key={`bar-chart-legend-${label}-${i}`}>
                    <Dot color={barTheme[i % barTheme.length]} />
                    <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.BLACK_COLOR}>
                      {label}
                    </AppText>
                  </FlexDiv>
                ))}
              </FlexDiv>
            )}
          </div>

          <FlexDiv gap={8} style={{ position: "relative", height: "100%" }}>
            {!!yAxisLabel && (
              <YAxisText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={theme.NEUTRAL300}>
                {yAxisLabel}
              </YAxisText>
            )}

            <ChartContainer style={{ marginLeft: !!yAxisLabel ? "12px" : undefined }}>
              <div>
                <BarChart width={calculatedChartWidth} height={chartHeight} data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} style={{ transform: "translateX(9px)" }} />
                  {[dataKey, ...(!!altDataKeys && groupedBarChart ? altDataKeys : [])]?.map(
                    (dataKey: string, i: number) => (
                      <Bar
                        dataKey={dataKey}
                        barSize={!!altDataKeys && groupedBarChart ? 8 : 12}
                        shape={(props) => {
                          const { index } = props.payload;
                          const color = barTheme[(!!altDataKeys && groupedBarChart ? i : index) % barTheme.length];
                          return <rect {...props} y={props.y || 0} fill={color} rx={2} />;
                        }}
                        minPointSize={4}
                        isAnimationActive={false}
                        onMouseEnter={(e) => {
                          const tooltipWidth = tooltipRef?.current?.offsetWidth || 0;
                          const tooltipHeight = tooltipRef?.current?.offsetHeight || 0;
                          const minTooltipY = 6;

                          // Calculate the maximum allowed x pos for tooltip
                          const maxTooltipX = calculatedChartWidth - tooltipWidth;

                          // Calculate the desired x pos for tooltip and clamp it to the container bounds
                          const xPos = clamp(e.x - (tooltipWidth / 2 - 6), 0, maxTooltipX);
                          // Calculate the desired y pos for tooltip and clamp it to the container bounds
                          const yPos = clamp(
                            Math.max(e.height / 2 - tooltipHeight - minTooltipY, e.y - tooltipHeight - minTooltipY),
                            0,
                            chartHeight,
                          );

                          // Place tooltip on top of the bar and center it
                          setTooltipPosition({
                            x: xPos,
                            y: yPos,
                          });

                          setShowTooltip(true);
                        }}
                        onMouseLeave={() => setShowTooltip(false)}
                        key={`bar-chart-bar-${dataKey}-${i}`}
                      />
                    ),
                  )}
                  <YAxis
                    width={calculateYAxisWidth()}
                    style={YAxisStyle}
                    tickFormatter={(value: number) =>
                      metric === "Percent" ? `${value}%` : metric === "Revenue" ? `$${value}` : `${value}`
                    }
                    tickLine={false}
                    axisLine={false}
                    type={"number"}
                    tickCount={5}
                    allowDecimals={false}
                  />
                  <XAxis tick={false} height={1} stroke={theme.NEUTRAL300} />

                  <Tooltip
                    position={tooltipPosition}
                    content={
                      <CustomTooltip
                        ref={tooltipRef}
                        showTooltip={showTooltip}
                        metric={metric}
                        barTheme={barTheme}
                        labelFormatter={tooltipLabelFormatter}
                      />
                    }
                    animationDuration={0}
                  />
                </BarChart>

                {!!xAxisLabel && (
                  <AppText
                    fontSize={8}
                    fontWeight={600}
                    lineHeight={12}
                    uppercase
                    color={theme.NEUTRAL300}
                    style={{ paddingLeft: `${calculateYAxisWidth() + 4}px` }}
                  >
                    {xAxisLabel}
                  </AppText>
                )}
              </div>

              {!!legend && legendPosition === "right" && (
                <FlexDiv direction="column" gap={6}>
                  {legend?.map((label: string, i: number) => (
                    <FlexDiv gap={4} align="center" key={`bar-chart-legend-${label}-${i}`}>
                      <Dot color={barTheme[i % barTheme.length]} />
                      <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.BLACK_COLOR}>
                        {label}
                      </AppText>
                    </FlexDiv>
                  ))}
                </FlexDiv>
              )}
            </ChartContainer>
          </FlexDiv>
        </SequenceContentContainer>
      ) : (
        <FlexDiv gap={20} direction="column" style={{ height: "100%" }}>
          <FlexDiv gap={4} direction="column">
            <SkeletonBlock width={140} height={18} borderRadius={4} delayNumber={1} />
            <SkeletonBlock width={260} height={14} borderRadius={4} delayNumber={2} />
          </FlexDiv>
          <FlexDiv>
            <SkeletonBlock width={12} height={52} borderRadius={4} delayNumber={2} />
            <SkeletonBlock
              width={32}
              height={100}
              borderRadius={4}
              style={{ margin: "0px 12px 0px 14px" }}
              delayNumber={3}
            />
            <FlexDiv gap={12} align="flex-end" style={{ height: "132px", marginBottom: "auto" }}>
              <SkeletonBlock width={12} height={64} borderRadius={4} delayNumber={4} />
              <SkeletonBlock width={12} height={72} borderRadius={4} delayNumber={5} />
              <SkeletonBlock width={12} height={92} borderRadius={4} delayNumber={6} />
              <SkeletonBlock width={12} height={120} borderRadius={4} delayNumber={7} />
              <SkeletonBlock width={12} height={48} borderRadius={4} delayNumber={8} />
              <SkeletonBlock width={12} height={80} borderRadius={4} delayNumber={9} />
              <SkeletonBlock width={12} height={64} borderRadius={4} delayNumber={10} />
              <SkeletonBlock width={12} height={48} borderRadius={4} delayNumber={11} />
              <SkeletonBlock width={12} height={52} borderRadius={4} delayNumber={12} />
              <SkeletonBlock width={12} height={52} borderRadius={4} delayNumber={13} />
              <SkeletonBlock width={12} height={72} borderRadius={4} delayNumber={14} />
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      )}
    </SequenceGraphContainer>
  );
};

const SequenceGraphContainer = styled.div`
  position: relative;
  width: 360px;
  height: 240px;

  padding: 8px;

  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0);
  transition: box-shadow 0.1s ease-in-out;

  :hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }

  & .recharts-rectangle {
    visibility: hidden;
  }

  & .recharts-bar-rectangle > * {
    transition: height 0.2s ease-in-out, y 0.2s ease-in-out;
  }
`;

const YAxisText = styled(AppText)`
  position: absolute;
  transform-origin: center left;
  transform: rotate(-90deg) translate(-100%, 4px);
  white-space: nowrap;
`;

const ChartContainer = styled.div`
  display: flex;
  gap: 16px;

  width: 324px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const YAxisStyle = {
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "14px",
  transform: "translateX(10px)",
  fill: theme.BLACK_COLOR,
};

interface SequenceContentContainer {
  didFirstLoad: boolean;
}

const SequenceContentContainer = styled(FlexDiv)<SequenceContentContainer>`
  & * {
    animation: ${(props) =>
      !props.didFirstLoad &&
      css`
        ${theme.fadeIn} .25s ease-in-out
      `};
  }
`;

interface CustomTooltipProps {
  active?: any;
  payload?: any;
  showTooltip?: boolean;
  metric?: "Revenue" | "Percent";
  ref: any;
  barTheme: string[];
  labelFormatter?: (payload: any) => void;
}

const CustomTooltip: React.FC<CustomTooltipProps> = forwardRef<HTMLDivElement, CustomTooltipProps>((props, ref) => {
  const { active, payload, showTooltip, metric, barTheme, labelFormatter } = props;
  if (active && payload && payload.length) {
    const name = payload[0]?.payload?.name;
    return (
      <CustomTooltipContainer ref={ref} showTooltip={showTooltip}>
        <AppText fontSize={10} fontWeight={400} lineHeight={14} color={theme.WHITE_COLOR}>
          {name}
        </AppText>
        {!!labelFormatter && labelFormatter(payload)}
        {payload?.map((p: any, index: number) => (
          <ValueText
            fontSize={8}
            fontWeight={500}
            lineHeight={11}
            color={theme.WHITE_COLOR}
            backgroundColor={barTheme[index % barTheme.length]}
            key={`ValueText-${p?.dataKey}-${index}}`}
          >
            {metric === "Revenue" && "$"}
            {/* cut decimals off. 1.8901 -> 1.89.  Regex removes the decimal if its '00'.  Regex: 1.00 -> 1 */}
            {typeof p?.value === "number" ? p?.value.toFixed(2).replace(/\.00$/, "") : p?.value}
            {metric === "Percent" && "%"}
          </ValueText>
        ))}
      </CustomTooltipContainer>
    );
  }

  return null;
});

interface CustomTooltipContainerProps {
  showTooltip?: boolean;
}

const CustomTooltipContainer = styled.div<CustomTooltipContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  width: fit-content;
  height: fit-content;
  padding: 8px;

  background-color: #0f183ecc;
  border-radius: 4px;

  opacity: ${(props) => (!!props.showTooltip ? 1 : 0)};
  visibility: ${(props) => (!!props.showTooltip ? "unset" : "hidden")};
  transition: opacity 0.1s ease-in-out;
`;

interface ValueTextProps {
  backgroundColor?: string;
}

const ValueText = styled(AppText)<ValueTextProps>`
  padding: 2px 8px;
  border-radius: 20px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : theme.PRIMARY500)};
`;
