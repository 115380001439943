import styled from "styled-components";
import * as React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import { AppButton, AppText, AppInput, Loading, AppErrorText, AppSelect, ToolTipContainer } from "../../UI";
import { theme } from "../../../utils/theme";
import * as Sentry from "@sentry/react";
import { dummyDataTeams, dummyDataLeads } from "./dummyData";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { formatCellData, formatUSD } from "../../../utils/format";
import moment from "moment";
import { CallHistoryList, LeadDetailsTable, FETCH_LEAD_POOL_DETAILS } from "../../Smart/";
import ReactTooltip from "react-tooltip";
import { useMutation, useQuery, gql } from "@apollo/client";
import { iconArrow } from "../../../images/";
import { HiOutlineSearch } from "react-icons/hi";
import { BiFilterAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { LEAD_POOL_GRID_PERSIST_KEY, SEARCH_DEBOUNCE_INTERVAL } from "../../../utils/variables";
import { LeadPoolSearchList } from "../../Smart/LeadPoolSearchList";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { sanitizeURL } from "../../../utils/misc";
import { search, xIcon } from "../../../images/NewDesign";
import { PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";

interface DisappearingDivProps {
  step: number;
  isOnboarding?: boolean;
}

const stateOptions = [
  { value: "Industry", label: "Industry" },
  { value: "LeadCreationSource", label: "Lead Creation Source" },
  { value: "LeadSource", label: "Lead Source" },
  { value: "Team", label: "Team" },
];

export const FETCH_LEAD_POOLS_DATA = gql`
  query fetchLeadPools($view_filter: LeadPoolViewFilter, $show_teams: Boolean, $team_id: String) {
    fetchLeadPools(view_filter: $view_filter, show_teams: $show_teams, team_id: $team_id) {
      id
      view_filter
      metric
      row_label
      row_id
      organization_id
      team_id
      user_id
      value
      metric_label
      metric_tooltip
      metric_type
    }
  }
`;

const FETCH_USER_FILTERS = gql`
  query fetchUserFilters($user_id: String) {
    fetchUser(user_id: $user_id) {
      id
      lead_source_filter
      industry_filter
      lead_source_white_list_array
      industry_white_list_array
    }
  }
`;

const UserIcons = ({ user_id }: { user_id?: string }) => {
  const { data, loading, error } = useQuery(FETCH_USER_FILTERS, {
    fetchPolicy: "network-only",
    variables: { user_id },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data && data.fetchUser]);
  if (!user_id) {
    return null;
  }
  if (loading) {
    return (
      <div
        style={{
          marginTop: "5px",
          marginRight: "5px",
          maxHeight: "10px",
          maxWidth: "25px",
          transform: "scale(0.5)",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (error || !data) {
    return null;
  }
  console.log("data: ", data);
  if (!data?.fetchUser?.industry_filter && !data?.fetchUser?.lead_source_filter) {
    return null;
  }
  let industries = "";
  let lead_sources = "";
  if (data?.fetchUser.industry_filter) {
    industries = data?.fetchUser?.industry_white_list_array.join(", ");
  }
  if (data?.fetchUser.lead_source_filter) {
    lead_sources = data?.fetchUser?.lead_source_white_list_array.join(", ");
  }
  return (
    <UserIconsDiv>
      <BiFilterAlt data-for={`${user_id}-tooltip`} data-tip="Test" size={14} color={theme.PRIMARY500} />
      <StyledTooltip
        id={`${user_id}-tooltip`}
        multiline
        place="top"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      >
        <FilterTextContainer>
          <p>This user has the following filters applied to their lead pool:</p>
          <br />
          {data?.fetchUser?.industry_filter && <p>Industry: {industries}</p>}
          {data?.fetchUser?.lead_source_filter && <p>Lead Source: {lead_sources}</p>}
        </FilterTextContainer>
      </StyledTooltip>
    </UserIconsDiv>
  );
};

const gridDefaultState = {
  tableView: "LeadSource",
  sortTableAscending: false,
  showTeams: true,
  teamId: "",
  selectedCell: ["", ""],
  filterTableByRow: "",
  showLeadDetails: false,
  leadDetailsData: {} as any,
  drilledDownState: false,
  drilledDownTableData: {} as any,
  leadSourceValue: { name: "", metric: "" },
  leadSourceOrgId: "",
  filterUsersInTable: "",
  leadCreationSource: ["", ""],
  leadIndustry: ["", ""],
};

const LeadPoolsComponent: React.FC<DisappearingDivProps> = ({ step, isOnboarding }) => {
  const [tableView, setTableView] = useState(gridDefaultState.tableView);
  const [sortTableAscending, setSortTableAscending] = useState(gridDefaultState.sortTableAscending);
  const [showTeams, setShowTeams] = useState(gridDefaultState.showTeams);
  const [teamId, setTeamId] = useState(gridDefaultState.teamId);
  const [selectedCell, setSelectedCell] = useState(gridDefaultState.selectedCell);
  const [filterTableByRow, setFilterTableByRow] = useState(gridDefaultState.filterTableByRow);
  const [showLeadDetails, setShowLeadDetails] = useState(gridDefaultState.showLeadDetails);
  const [leadDetailsData, setLeadDetailsData] = useState(gridDefaultState.leadDetailsData);
  const [drilledDownState, setDrilledDownState] = useState(gridDefaultState.drilledDownState);
  // const [drilledDownTableData, setDrilledDownTableData] = useState(gridDefaultState.drilledDownTableData);
  const [leadSourceValue, setLeadSourceValue] = useState(gridDefaultState.leadSourceValue);
  const [leadSourceOrgId, setLeadSourceOrgId] = useState(gridDefaultState.leadSourceOrgId);
  const [filterUsersInTable, setFilterUsersInTable] = useState(gridDefaultState.filterUsersInTable);

  // search bar state
  const [searchState, setSearchState] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const searchInput = useRef<HTMLInputElement>(null);

  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  const [leadCreationSource, setLeadCreationSource] = useState(gridDefaultState.leadCreationSource);
  const [leadIndustry, setLeadIndustry] = useState(gridDefaultState.leadIndustry);

  //debounced search rather than query as this involves less breaking changes
  const debouncedChange = useMemo(
    () =>
      debounce((e) => {
        const { value } = e.target;
        setSearchValue(value);
      }, SEARCH_DEBOUNCE_INTERVAL),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  }, []);

  // set persist state
  useEffect(() => {
    const persistedStateRaw = localStorage.getItem(LEAD_POOL_GRID_PERSIST_KEY);
    if (!persistedStateRaw) {
      return;
    }
    const persistedState = JSON.parse(persistedStateRaw);
    if (!persistedState) {
      return;
    }
    setTableView(persistedState.tableView ?? gridDefaultState.tableView);
    setSortTableAscending(persistedState.sortTableAscending ?? gridDefaultState.sortTableAscending);
    setShowTeams(persistedState.showTeams ?? gridDefaultState.showTeams);
    setTeamId(persistedState.teamId ?? gridDefaultState.teamId);
    setSelectedCell(persistedState.selectedCell ?? gridDefaultState.selectedCell);
    setFilterTableByRow(persistedState.filterTableByRow ?? gridDefaultState.filterTableByRow);
    setShowLeadDetails(persistedState.showLeadDetails ?? gridDefaultState.showLeadDetails);
    setLeadDetailsData(persistedState.leadDetailsData ?? gridDefaultState.leadDetailsData);
    setDrilledDownState(persistedState.drilledDownState ?? gridDefaultState.drilledDownState);
    // setDrilledDownTableData(persistedState.drilledDownTableData ?? gridDefaultState.drilledDownTableData);
    setLeadSourceValue(persistedState.leadSourceValue ?? gridDefaultState.leadSourceValue);
    setLeadSourceOrgId(persistedState.leadSourceOrgId ?? gridDefaultState.leadSourceOrgId);
    setFilterUsersInTable(persistedState.filterUsersInTable ?? gridDefaultState.filterUsersInTable);
    setLeadCreationSource(persistedState.leadCreationSource ?? gridDefaultState.leadCreationSource);
    setLeadIndustry(persistedState.leadIndustry ?? gridDefaultState.leadIndustry);
  }, []);

  // persist settings when a change occurs
  useEffect(() => {
    const table_properties = {
      tableView,
      sortTableAscending,
      showTeams,
      teamId,
      selectedCell,
      filterTableByRow,
      showLeadDetails,
      leadDetailsData,
      drilledDownState,
      // drilledDownTableData,
      leadSourceValue,
      leadSourceOrgId,
      filterUsersInTable,
      leadCreationSource,
      leadIndustry,
    };
    localStorage.setItem(LEAD_POOL_GRID_PERSIST_KEY, JSON.stringify(table_properties));
  }, [
    tableView,
    sortTableAscending,
    showTeams,
    teamId,
    selectedCell,
    filterTableByRow,
    showLeadDetails,
    leadDetailsData,
    drilledDownState,
    // drilledDownTableData,
    leadSourceValue,
    leadSourceOrgId,
    filterUsersInTable,
    leadCreationSource,
    leadIndustry,
  ]);

  const history = useHistory();

  const { data: dataTable, loading: loadingTable, error: errorTable, refetch: refetchTable } = useQuery(
    FETCH_LEAD_POOLS_DATA,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      variables: {
        view_filter: tableView,
        show_teams: showTeams,
        team_id: teamId,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  // const dummyData = useMemo(() => {
  //   return tableView === "Team" ? dummyDataTeams : dummyDataLeads;
  // }, [tableView]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataTable && dataTable.fetchLeadPools]);

  // if (!!!dataTable) return <Loading />;
  if (errorTable) return <AppErrorText>Error loading table</AppErrorText>;

  const tableHeaders = dataTable
    ? dataTable.fetchLeadPools
        ?.map((item: any) => item.metric_label)
        ?.filter((v: string, i: number, a: any) => a.indexOf(v) === i)
    : [];

  const rowLabels = dataTable
    ? dataTable.fetchLeadPools
        ?.map((item: any) => item)
        ?.filter((item: any) => item.metric_label === "Assigned Leads")
        ?.sort((a: any, b: any) => (sortTableAscending ? a.value - b.value : b.value - a.value))
        ?.filter((item: any) => !!!filterUsersInTable || !!!item.user_id || filterUsersInTable === item.user_id)
        ?.map((item: any) => ({ row_id: item.row_id, row_label: item.row_label, user_id: item.user_id }))
        ?.map((item: any) => JSON.stringify(item))
        ?.filter((v: string, i: number, a: any) => a.indexOf(v) === i)
        ?.map((item: any) => JSON.parse(item))
    : [];

  const teamRows = dataTable
    ? dataTable.fetchLeadPools
        ?.map((item: any) => item)
        ?.filter((item: any) => !!item.team_id && !!!item.user_id)
        ?.map((item: any) => ({ row_id: item.row_id, row_label: item.row_label }))
        ?.map((item: any) => JSON.stringify(item))
        ?.filter((v: string, i: number, a: any) => a.indexOf(v) === i)
        ?.map((item: any) => JSON.parse(item))
    : [];

  const returnTooltipData = (item: string) => {
    const element = dataTable
      ? dataTable.fetchLeadPools?.map((item: any) => item)?.filter((row: any) => row.metric_label === item)
      : [];

    return !!element[0].metric_tooltip ? element[0].metric_tooltip : "No tooltip";
  };

  const handleCellClick = async (item: any) => {
    if (tableView === "LeadSource") {
      setLeadCreationSource(["", ""]);
      setLeadIndustry(["", ""]);
      if (item.row_label !== "MY COMPANY METRICS") {
        setFilterTableByRow(item.row_id);
        setLeadSourceValue({ name: item.row_label, metric: item.metric });
        setShowLeadDetails(true);
        setLeadSourceOrgId("");
        setFilterUsersInTable("");
      }
      if (item.row_label === "MY COMPANY METRICS") {
        setFilterTableByRow(item.row_id);
        // setLeadSourceValue({ name: item.row_label, metric: item.metric });
        setShowLeadDetails(true);
        setLeadSourceOrgId(item.organization_id);
        // setShowTeams(false);
        setLeadSourceValue({ name: "", metric: item.metric });
        setTeamId("");
        setFilterUsersInTable("");
      }
    }
    if (tableView === "Team") {
      setLeadCreationSource(["", ""]);
      if (!!item.team_id) {
        if (filterTableByRow === item.row_id) {
          setShowTeams(false);
          setTeamId(item.team_id);
          setLeadDetailsData(item);
          setShowLeadDetails(true);
          setFilterUsersInTable("");
        } else if (filterTableByRow !== item.row_id) {
          setFilterTableByRow(item.row_id);
          setTeamId(item.team_id);
          setLeadDetailsData(item);
          setShowLeadDetails(true);
          setFilterUsersInTable("");
        }
      }
      if (!!item.user_id) {
        setShowTeams(false);
        setTeamId(item.team_id);
        setLeadDetailsData(item);
        setShowLeadDetails(true);
        setFilterTableByRow("");
        setFilterUsersInTable(item.user_id);
      }
      if (!!item.organization_id) {
        setShowTeams(false);
        setLeadDetailsData(item);
        setShowLeadDetails(true);
        setFilterUsersInTable("");
        setTeamId("");
      }
    }
    if (tableView === "LeadCreationSource") {
      if (!!item.organization_id) {
        setShowLeadDetails(true);
        setLeadCreationSource([item.row_label, item.metric, item.organization_id]);
      } else {
        setShowLeadDetails(true);
        setLeadCreationSource([item.row_label, item.metric]);
      }
    }
    if (tableView === "Industry") {
      setFilterTableByRow(item.row_id);
      if (!!item.organization_id) {
        setShowLeadDetails(true);
        setLeadIndustry([item.row_label, item.metric, item.organization_id]);
      } else {
        setShowLeadDetails(true);
        setLeadIndustry([item.row_label, item.metric]);
      }
    }
  };

  const isRowLabelClickable = (row: string) => {
    if (tableView === "LeadSource") {
      return row === "MY COMPANY METRICS" && !!filterTableByRow;
    }
    if (tableView === "Industry") {
      return row === "MY COMPANY METRICS" && !!filterTableByRow;
    }
    if (tableView === "Team") {
      if (row === "MY COMPANY METRICS") {
        return true;
      } else if (teamRows?.map((item: any) => item.row_label)?.includes(row)) {
        return true;
      }
      return false;
    }
  };

  const handleRowLabelClick = (row: any) => {
    if (tableView === "LeadSource") {
      if (row === "MY COMPANY METRICS") {
        setFilterTableByRow("");
        setFilterUsersInTable("");
        setShowLeadDetails(false);
        setSelectedCell(["", ""]);
      }
    } else if (tableView === "Industry") {
      if (row === "MY COMPANY METRICS") {
        setFilterTableByRow("");
        setShowLeadDetails(false);
        setSelectedCell(["", ""]);
      }
    } else if (tableView === "Team") {
      if (row === "MY COMPANY METRICS") {
        setShowTeams(true);
        setFilterTableByRow("");
        setTeamId("");
        setFilterUsersInTable("");
        setShowLeadDetails(false);
        setSelectedCell(["", ""]);
      } else if (teamRows?.map((item: any) => item.row_label).includes(row)) {
        const team_id = dataTable.fetchLeadPools
          ?.map((item: any) => item)
          ?.filter((item: any) => row === item.row_label && !!item.team_id)
          ?.map((item: any) => item.team_id)[0];
        console.log("team_id: ", team_id);
        setFilterTableByRow("");
        setShowTeams(false);
        setTeamId(team_id);
        // setLeadDetailsData(item);
        setShowLeadDetails(false);
        setSelectedCell(["", ""]);
        setFilterUsersInTable("");
      } else {
        setTeamId("");
        setFilterTableByRow(row);
      }
    }
  };

  const drillDownFunction = (item: any) => {
    setDrilledDownState(true);

    const sanitizedURL = sanitizeURL(`/lead-detail/${item.id}`);
    history.push(sanitizedURL);
  };

  const getMetric = () => {
    if (tableView === "LeadCreationSource") {
      return leadCreationSource[1];
    } else if (tableView === "Industry") {
      return leadIndustry[1];
    } else if (tableView === "LeadSource") {
      return leadSourceValue.metric;
    } else {
      return leadDetailsData.metric;
    }
  };

  const getTeamId = () => {
    if (tableView === "LeadCreationSource") {
      return "";
    } else if (tableView === "Industry") {
      return "";
    } else {
      return leadDetailsData.team_id;
    }
  };

  const getUserId = () => {
    if (tableView === "LeadCreationSource") {
      return "";
    } else if (tableView === "Industry") {
      return "";
    } else {
      return leadDetailsData.user_id;
    }
  };

  const getOrganizationId = () => {
    if (tableView === "LeadCreationSource" && !!leadCreationSource[2]) {
      return leadCreationSource[2];
    } else if (tableView === "Industry" && !!leadIndustry[2]) {
      return leadIndustry[2];
    } else if (tableView === "LeadSource") {
      return leadSourceOrgId;
    } else {
      return leadDetailsData.organization_id;
    }
  };

  const getLeadSource = () => {
    if (tableView === "LeadCreationSource") {
      return "";
    } else if (tableView === "Industry") {
      return "";
    } else if (tableView === "LeadSource") {
      return leadSourceValue.name;
    } else {
      return undefined;
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured in System View"}>
      <SearchContainer>
        <PhoenixInput
          value={localSearchValue}
          placeholder="Search Leads"
          displayNoContextText
          width={248}
          height={32}
          onFocus={() => setSearchState(true)}
          onBlur={() => setSearchState(false)}
          onChange={(e: React.SyntheticEvent) => {
            e.persist();
            debouncedChange(e);
            setLocalSearchValue((e.target as HTMLInputElement).value);
          }}
          insiderightElementOverride={
            <PhoenixIcon
              svg={searchValue.length ? xIcon : search}
              size={13}
              pointer={!searchState}
              onClick={() => {
                if (searchValue.length) setSearchValue("");
                if (localSearchValue.length) setLocalSearchValue("");
                if (searchInput && searchInput.current) searchInput.current.focus();
              }}
            />
          }
        />
        {searchState && (
          <LeadPoolSearchList
            inputRef={searchInput}
            searchText={searchValue}
            drillDownFunction={drillDownFunction}
            setSearchState={setSearchState}
          />
        )}
      </SearchContainer>
      {!dataTable ? (
        <Loading />
      ) : (
        // : drilledDownState ? (
        //   <GridTable>
        //     <thead>
        //       <GridTH style={{ width: 210 }}>
        //         <BackButton onClick={() => setDrilledDownState(false)}> &lt; BACK TO LEADS</BackButton>
        //         <br />
        //         {!!drilledDownTableData.lead_source && `Lead Source: ${drilledDownTableData.lead_source}`}
        //       </GridTH>
        //       <GridTH>Industry</GridTH>
        //       <GridTH>Current State</GridTH>
        //       <GridTH>Current LV</GridTH>
        //       <GridTH>Most Recent Disposition</GridTH>
        //       <GridTH>MRD Agent</GridTH>
        //       <GridTH>Scheduled Next Dial Agent</GridTH>
        //       <GridTH>Scheduled Next Dial Date</GridTH>
        //     </thead>
        //     <tbody>
        //       <GridTR>
        //         <GridTD style={{ width: 210 }}>
        //           {!!drilledDownTableData?.full_name ? drilledDownTableData.full_name : "Not named"}
        //         </GridTD>
        //         <GridTD>{!!drilledDownTableData?.industry ? drilledDownTableData.industry : "Not specified"}</GridTD>
        //         <GridTD>
        //           {!!drilledDownTableData?.phase_label ? drilledDownTableData.phase_label : "No phase assigned"}
        //         </GridTD>

        //         <GridTD>
        //           {!!drilledDownTableData?.lead_value?.value
        //             ? formatUSD(drilledDownTableData.lead_value.value)
        //             : drilledDownTableData?.lead_value?.value ?? "No value assigned"}
        //         </GridTD>
        //         <GridTD>
        //           {!!drilledDownTableData?.most_recent_disposition?.related_disposition?.label
        //             ? drilledDownTableData.most_recent_disposition.related_disposition.label
        //             : "No disposition logged"}
        //         </GridTD>
        //         <GridTD>
        //           {!!drilledDownTableData?.most_recent_disposition?.user?.full_name
        //             ? drilledDownTableData.most_recent_disposition.user.full_name
        //             : "Never assigned"}
        //         </GridTD>
        //         <GridTD>
        //           {!!drilledDownTableData?.next_scheduled_agent?.full_name
        //             ? drilledDownTableData.next_scheduled_agent.full_name
        //             : "Not assigned"}
        //         </GridTD>
        //         <GridTD>
        //           {!!drilledDownTableData?.next_lead_intent?.schedule_item?.start_time
        //             ? moment(drilledDownTableData.next_lead_intent.schedule_item.start_time).format("MM/DD/YYYY h:mm A")
        //             : "Not scheduled"}
        //         </GridTD>
        //       </GridTR>
        //     </tbody>
        //   </GridTable>
        // )
        <>
          <ReactTooltip
            multiline
            place="top"
            effect="solid"
            css={{
              maxWidth: 300,
              lineHeight: 1.4,
              textAlign: "center",
              fontFamily: theme.PRIMARY_FONT,
            }}
            backgroundColor={theme.PRIMARY800}
            getContent={(dataTip) => (
              <span
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "10px",
                  lineHeight: "14px",
                }}
              >
                {dataTip}
              </span>
            )}
          />
          <GridTable>
            <thead>
              <GridTR>
                <GridTH style={{ padding: "0px" }}>
                  <DropdownContainer>
                    <StyledAppSelect
                      placeholder="Select View"
                      allowSelectPlaceholder={false}
                      options={stateOptions}
                      carrotColor={theme.BLACK_COLOR}
                      width={200}
                      title="View by"
                      textAlign="left"
                      value={tableView}
                      customArrowLocation={23}
                      onChange={(e: any) => {
                        setShowLeadDetails(false);
                        setShowTeams(true);
                        setTeamId("");
                        setSelectedCell(["", ""]);
                        setFilterTableByRow("");
                        setFilterUsersInTable("");
                        setTableView(e.target.value);
                      }}
                    />
                  </DropdownContainer>
                </GridTH>
                {tableHeaders?.map((item: string) =>
                  item === "Assigned Leads" ? (
                    <GridTH
                      style={{ cursor: "pointer" }}
                      key={`TH${item}`}
                      onClick={() => {
                        setSortTableAscending(!sortTableAscending);
                      }}
                    >
                      <GridTHText>{item}</GridTHText>
                      <QuestionMarkMoreInfo data-tip={returnTooltipData(item)}>?</QuestionMarkMoreInfo>
                      {sortTableAscending ? (
                        <AiFillCaretDown
                          size={14}
                          style={{ marginBottom: "-4px", marginLeft: "5px" }}
                          color={theme.BLACK_COLOR}
                        />
                      ) : (
                        <AiFillCaretUp
                          size={14}
                          style={{ marginBottom: "-4px", marginLeft: "5px" }}
                          color={theme.BLACK_COLOR}
                        />
                      )}
                    </GridTH>
                  ) : (
                    <GridTH key={`TH${item}`}>
                      <GridTHText>{item}</GridTHText>
                      <QuestionMarkMoreInfo data-tip={returnTooltipData(item)}>?</QuestionMarkMoreInfo>
                    </GridTH>
                  ),
                )}
              </GridTR>
            </thead>
            <tbody>
              {rowLabels
                ?.map((item: any) => item)
                ?.filter((item: any) =>
                  !!filterTableByRow
                    ? item.row_label === "MY COMPANY METRICS" || item.row_id === filterTableByRow
                    : true,
                )
                ?.map((row: any) => (
                  <GridTR key={row.row_id}>
                    <GridTD
                      clickable={isRowLabelClickable(row.row_label)}
                      onClick={() => {
                        if (!isRowLabelClickable(row.row_label)) {
                          return;
                        }
                        handleRowLabelClick(row.row_label);
                      }}
                    >
                      <RowTitle
                      // style={{ textDecoration: !!item.team_id && tableState === "Team" ? "uppercase" : "normal" }}
                      >
                        <div style={{ display: "flex" }}>
                          {loadingTable && (
                            <div
                              style={{
                                marginTop: "5px",
                                marginRight: "5px",
                                maxHeight: "10px",
                                maxWidth: "25px",
                                transform: "scale(0.5)",
                              }}
                            >
                              <Loading />
                            </div>
                          )}
                          {isRowLabelClickable(row.row_label) && (!!teamId || row.row_label === "MY COMPANY METRICS") && (
                            <ArrowImage
                              style={{
                                transform: !!filterTableByRow
                                  ? "rotate(0deg)"
                                  : teamId === row.team_id
                                  ? "rotate(90deg)"
                                  : !!teamId
                                  ? "rotate(90deg)"
                                  : "rotate(90deg)",
                              }}
                              src={iconArrow}
                              alt="Expand/Collapse"
                            />
                          )}
                          {row.row_label}
                          <UserIcons user_id={row?.user_id} />
                        </div>
                      </RowTitle>
                    </GridTD>
                    {tableHeaders?.map((header: any) =>
                      dataTable.fetchLeadPools
                        ?.map((item: any) => item)
                        ?.filter((item: any) => row.row_id === item.row_id && header === item.metric_label)
                        ?.filter((_: any, index: number) => index === 0)
                        ?.map((item: any) => (
                          <>
                            <GridTD
                              key={`TR${item.id}`}
                              clickable={
                                !(
                                  header.includes("Sale") ||
                                  header.includes("Value") ||
                                  header.includes("CC Days Remaining")
                                )
                              }
                              selected={selectedCell[0] === item.row_id && selectedCell[1] === item.metric_label}
                              onClick={() => {
                                if (
                                  !(
                                    header.includes("Sale") ||
                                    header.includes("Value") ||
                                    header.includes("CC Days Remaining")
                                  )
                                ) {
                                  setSelectedCell([item.row_id, item.metric_label, item.row_label, item.value]);
                                  handleCellClick(item);
                                }
                              }}
                            >
                              {formatCellData(item?.value, item?.metric_type)}
                            </GridTD>
                          </>
                        )),
                    )}
                  </GridTR>
                ))}
            </tbody>
          </GridTable>
        </>
      )}
      {showLeadDetails && !drilledDownState && (
        <BottomTableDiv>
          <LeadDetailsTable
            metric={getMetric()}
            team_id={getTeamId()}
            user_id={getUserId()}
            organization_id={getOrganizationId()}
            click_function={drillDownFunction}
            lead_source={getLeadSource()}
            table_title={{ header: selectedCell[1], subheader: selectedCell[2] }}
            total_records={selectedCell[3]}
            lead_creation_source={
              tableView === "LeadCreationSource" && !!!leadCreationSource[2] ? leadCreationSource[0] : ""
            }
            industry={tableView === "Industry" && !!!leadIndustry[2] ? leadIndustry[0] : ""}
            refetchTable={async () =>
              await refetchTable({
                view_filter: tableView,
                show_teams: showTeams,
                team_id: teamId,
              })
            }
          />
        </BottomTableDiv>
      )}
      {/* {drilledDownState && (
        <BottomTableDiv>
          <CallHistoryList leadID={drilledDownTableData.id} />
        </BottomTableDiv>
      )} */}
    </Sentry.ErrorBoundary>
  );
};
const BackButton = styled(AppButton)`
  margin-bottom: 5px;
  height: 30px;
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  background-color: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL100};
`;

const IconButton = styled(AppButton)`
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomTableDiv = styled.div`
  margin-top: 58px;
  width: 100%;
`;

const ArrowImage = styled.img`
  margin-right: 12px;
  transform: rotate(90deg);
`;

interface QuestionSize {
  big?: boolean;
}

const QuestionMarkMoreInfo = styled(AppText)<QuestionSize>`
  display: inline-block;
  line-height: ${(props) => (props.big ? "15px" : "10px")};
  margin: ${(props) => (props.big ? "auto" : "5px")};
  font-size: ${(props) => (props.big ? "10px" : "7px")};
  padding-left: ${(props) => (props.big ? "5px" : "0px")};
  margin-left: 5px;
  height: ${(props) => (props.big ? "15px" : "10px")};
  width: ${(props) => (props.big ? "15px" : "10px")};
  border-radius: 50%;
  background-color: ${theme.NEUTRAL100};
  color: ${theme.PRIMARY500};
  cursor: pointer;
  padding-right: 0px;
  margin-right: 0px;
`;

const StyledAppSelect = styled(AppSelect)`
  /* margin: 0px;
  font-size: 11px;
  height: 30px; */

  font-size: 12px;
`;

const DropdownContainer = styled.div`
  text-align: left;
`;

interface SelectedProp {
  selected?: boolean;
  clickable?: boolean;
}

const GridTR = styled.tr`
  /* td:nth-child(19),
  th:nth-child(19) {
    border-right: solid 1.5px #16151a;
  } */
`;

const GridTH = styled.th`
  border: 1px solid ${theme.NEUTRAL200};
  padding: 5px;
  max-width: 100px;
  /* height: 160px; */
  /* transform: translate(2px, 55px) rotate(270deg);
  min-width: 36px;
  max-width: 36px;
  font-size: 10px; */
  :first-child {
    transform: translate(0px, 0px) rotate(0deg);
    width: 200px;
    border: 0px solid ${theme.NEUTRAL200};
  }
`;

const GridTHText = styled(AppText)`
  font-size: 9px;
  text-align: center;
  white-space: normal;
  font-weight: 500;
`;

const GridTD = styled.td<SelectedProp>`
  white-space: nowrap;
  border: 1px solid ${theme.NEUTRAL200};
  line-height: 30px;
  font-size: 9px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  /* pointer-events: ${(props) => (props.clickable ? "auto" : "none")}; */
  box-shadow: ${(props) => (!!props.selected ? `inset 0px 0px 2px 2px ${theme.PRIMARY500}` : "none")};
  text-align: center;
  background: ${(props) => (props.clickable ? theme.WHITE_COLOR : "transparent")};
`;

const RowTitle = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const GridTable = styled.table`
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  padding: 5px;
`;

const SearchContainer = styled.div`
  align-self: flex-end;
  margin-bottom: 16px;
  padding-right: 14px;
`;

const UserIconsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const StyledTooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 600px;
`;

const FilterTextContainer = styled.div`
  white-space: pre-wrap;
  overflow: hidden;
`;

export { LeadPoolsComponent };
