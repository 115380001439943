import { useChannel } from "@ably-labs/react-hooks";
import { AdvancedImage } from "@cloudinary/react";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import { TransferAttemptStatus } from "../../../__generated__/graphql";
import { CallContext } from "../../../context";
import { transferFailure } from "../../../images/lotties";
import transferPending from "../../../images/lotties/transferPending.json";
import { cloudinary } from "../../../services/cloudinary";
import { DEFAULT_CLOUDINARY_IMAGE } from "../../../utils/variables";
import { AppText, Loading, NewAppButton } from "../../UI";
import { formatPhoneNumber } from "../../../utils/format";
import { currentCallState } from "src/apollo/cache";

type ExternalStepTwoProps = {
  externalPhoneNumber: string;
  // kickExternalTransferNumber: () => void;
  setStep: (step: number) => void;
};

const ExternalStepTwo: React.FC<ExternalStepTwoProps> = ({
  externalPhoneNumber,
  setStep,
}) => {
  const {
    setTransferError,
    transferState,
    setTransferState,
    callOptionStackPush,
    callOptionStackPop,
    setAssociatedAction,
    updateCallHoldStatus,
    transfer_attempt_id,
    cancelTransferGlobal,
    hangup,
    revertCallState,
    externalRepCallSid,
    kickExternalTransferNumber,
  } = useContext(CallContext);

  // useEffect(() => {
  //   return () => {
  //     setTransferState("Success");
  //     setTransferError("");
  //   };
  // }, []);

  const formattedPhoneNumber = formatPhoneNumber(`+1${externalPhoneNumber}`);
  const [mergedLead, setMergedLead] = useState(false);
  const profileImage = cloudinary.image(DEFAULT_CLOUDINARY_IMAGE);

  const external_transfer_attempt_channel = transfer_attempt_id ? `transferAttempt:${transfer_attempt_id}` : "";

  useChannel(external_transfer_attempt_channel, (payload) => {
    console.log("inbound transfer from Ably: ", payload, external_transfer_attempt_channel);
    if (!payload?.data?.status) {
      Sentry.captureEvent({
        message: `No transfer status update in payload from Ably in TransferComponentV3 `,
        extra: {
          payload,
        },
      });
      return;
    }

    // If state changes in real-time, update local state
    if (payload?.data?.status !== transferState) {
      setTransferState(payload?.data?.status);
    }
  });

  const lottieOptions = (loop: boolean, animationData: any) => {
    return {
      loop: loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  };

  const RejectedComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Top>
          <Lottie style={{ marginTop: 48 }} options={lottieOptions(false, transferFailure)} height={144} width={144} />
          <TopTextContainer>
            <TopTextFocus>Unable to Transfer Call</TopTextFocus>
          </TopTextContainer>
          <NewAppButton
            variant={"secondary"}
            size={"lg"}
            onClick={() => {
              if (!!externalRepCallSid) {
                kickExternalTransferNumber();
                cancelTransferGlobal(externalRepCallSid);
              }
              setTransferError("");
              setTransferState("Idle");
              setStep(1);
            }}
          >
            Try a Different Number
          </NewAppButton>
        </Top>
        <Bottom style={{ gap: 40 }}>
          <BottomTextContainer style={{ gap: 16 }}>
            <BottomTextDescription>
              If no one at this number is available, provide the phone number to the other person on the line.
            </BottomTextDescription>
            <BottomTextFocus style={{ fontSize: 16 }}>{formattedPhoneNumber}</BottomTextFocus>
          </BottomTextContainer>
          <BottomButtonContainers>
            <NewAppButton
              variant={"primary"}
              size={"lg"}
              onClick={() => {
                callOptionStackPush("schedule-event");
              }}
            >
              Schedule Next Event
            </NewAppButton>
            {!currentCallState().dispositionLogged && (
              <NewAppButton
                variant={"secondary"}
                size={"lg"}
                onClick={() => {
                  setAssociatedAction("");
                  callOptionStackPush("log-other");
                }}
              >
                Log Other Call Result
              </NewAppButton>
            )}
          </BottomButtonContainers>
        </Bottom>
      </div>
    );
  };
  const InProgressComponent = () => {
    return (
      <Top>
        <Lottie options={lottieOptions(true, transferPending)} height={144} width={144} />
        <TopTextFocus>Call Transfer in Progress</TopTextFocus>
        <NewAppButton
          size={"lg"}
          variant={"attention"}
          onClick={() => {
            setStep(1);
            kickExternalTransferNumber();
            cancelTransferGlobal(externalRepCallSid);
          }}
        >
          Cancel Transfer
        </NewAppButton>
      </Top>
    );
  };

  const SuccessComponent = () => {
    if (mergedLead === false) {
      return (
        <SuccessDiv>
          <Top>
            <ImageContainer>
              <AdvancedImageStyle style={{ opacity: 1 }} cldImg={profileImage} />
            </ImageContainer>
            <TopTextContainer>
              <TopTextDescription>{`You are now connected with`}</TopTextDescription>
              <TopPhoneFocus>{formattedPhoneNumber}</TopPhoneFocus>
            </TopTextContainer>
          </Top>

          <Bottom>
            <BottomTextDescription>{`Merge your lead into the call when ready and then exit`}</BottomTextDescription>
            <NewAppButton
              variant={"primary"}
              size={"lg"}
              onClick={() => {
                updateCallHoldStatus(false);
                setMergedLead(true);
              }}
            >
              Merge In Lead
            </NewAppButton>
          </Bottom>
        </SuccessDiv>
      );
    }
    if (mergedLead === true) {
      return (
        <SuccessDiv>
          <Top>
            <ImageContainer>
              <AdvancedImageStyle style={{ opacity: 1 }} cldImg={profileImage} />
            </ImageContainer>
            <TopTextContainer>
              <TopTextDescription>{`You are now connected with`}</TopTextDescription>
              <TopPhoneFocus>{formattedPhoneNumber}</TopPhoneFocus>
            </TopTextContainer>
          </Top>
          <Bottom>
            <BottomTextContainer>
              <BottomTextFocus>Your lead has successfully been merged into the call.</BottomTextFocus>
              <BottomTextDescription>Leave the call whenever you are ready.</BottomTextDescription>
            </BottomTextContainer>

            {!currentCallState().dispositionLogged && (
              <NewAppButton
                variant={"primary"}
                size={"lg"}
                onClick={() => {
                  updateCallHoldStatus(false);
                  setAssociatedAction("");
                  callOptionStackPush("log-other");
                }}
              >
                Next
              </NewAppButton>
            )}
          </Bottom>
        </SuccessDiv>
      );
    }
    return <Loading />;
  };

  const DefaultComponent = () => {
    return (
      <Top>
        <Lottie options={lottieOptions(true, transferPending)} height={144} width={144} />
      </Top>
    );
  };

  const renderComponent = () => {
    switch (transferState) {
      case TransferAttemptStatus.Rejected:
      case TransferAttemptStatus.Cancelled:
      case TransferAttemptStatus.Fail:
        return RejectedComponent();

      case TransferAttemptStatus.InProgress:
        return InProgressComponent();
      case TransferAttemptStatus.Success:
        return SuccessComponent();
      case TransferAttemptStatus.Idle:
      default:
        return DefaultComponent();
    }
  };
  return <Container>{renderComponent()}</Container>;
};

const Container = styled.div`
  margin-top: 150px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  gap: 40px;
  width: 100%;
`;

const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const TopTextFocus = styled(AppText)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
`;

const TopPhoneFocus = styled(AppText)`
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
`;

const TopTextDescription = styled(AppText)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const Bottom = styled.div`
  display: flex;
  padding: var(--Widget-Count, 0px) 40px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  width: 100%;
`;

const BottomButtonContainers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const BottomTextFocus = styled(AppText)`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`;

const BottomTextDescription = styled(AppText)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 124px;
  border: 3px solid #3e65ff;
  margin-bottom: 8px;
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 110px;
  width: 110px;
  height: 110px;
`;

const SuccessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48;
  justifycontent: space-between;
  height: 100%;
`;

export { ExternalStepTwo };
