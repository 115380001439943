import styled from "styled-components";

import { theme } from "../../../utils/theme";
import * as React from "react";

// a standardized tab component to be used for all tab navigation in the app
// example usage: lead card history, more info, etc. tab section
// important css note : hugs content with a 16px padding on the left and right

interface PhoenixTabProps {
  tabName: string;
  selected: boolean;
  onClick: () => void;
}

const PhoenixTab = ({ tabName, selected, onClick }: PhoenixTabProps) => {
  return (
    <Tab selected={selected} onClick={onClick}>
      {tabName}
    </Tab>
  );
};

const Tab = styled.div<AppTabProps>`
  width: max-content;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  padding: 8px 16px;
  font-weight: 600;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : `${theme.NEUTRAL300}`)};

  border-bottom: ${(props) => (props.selected ? `1px solid ${theme.PRIMARY500}` : `none`)};
  // if selected move the tab up 1px to make it look like it's not overlapping the border
  transform: ${(props) => (props.selected ? `translateY(1px)` : `none`)};
`;

interface AppTabProps {
  selected: boolean;
  height?: number;
}

export { PhoenixTab };
