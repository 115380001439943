import styled from "styled-components";
import * as React from "react";
import { useMemo, useState, useEffect, useContext } from "react";
import { AppText, Loading, NewAppButton, AppIconButton, AppInput, AppErrorText } from "../../UI";
import { currentCallState } from "../../../apollo/cache";
import { theme } from "../../../utils/theme";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { CallContext } from "../../../context";
import { restAPI } from "../../../apollo";
import {
  ABLY_API_KEY,
  BACKEND_URL,
  DEFAULT_CLOUDINARY_IMAGE,
  FETCH_USER_TRANSFER_LIST_INTERVAL,
} from "../../../utils/variables";
import moment from "moment";
import { appToast } from "../../../utils/toast";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { formatCellData } from "../../../utils/format";
import { iconArrowDown } from "../../../images";
import transferPending from "../../../images/lotties/transferPending.json";
import transferSuccess from "../../../images/lotties/transferSuccess.json";
import transferFailure from "../../../images/lotties/transferFailure.json";
import Lottie from "react-lottie";
import { cloudinary } from "../../../services/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { ABLY_OPTIONS } from "../../../services/ably";
import { ProfileImageWithText } from "../SettingSegments/ProfileImageWithText";
import { PhoenixIcon } from "../../UI/Phoenix";
import { bar_chart_down } from "../../../images/NewDesign";
import { formatTimeDifferenceByDelineation } from "../../../utils/format";
import { loggedInUser } from "../../../apollo/cache";
import * as Sentry from "@sentry/react";
import { SkeletonBlock } from "../../UI/SkeletonBlock";
import { useDebounce, useDebouncedState } from "../../../utils/hooks";

const FETCH_MANUAL_TRANSFER_REP_LISTV2 = gql`
  query fetchManualTransferRepListV2($lead_id: String!, $sort_option: TransferSortOption!, $ascending: Boolean!) {
    fetchManualTransferRepListV2(lead_id: $lead_id, sort_option: $sort_option, ascending: $ascending) {
      user_id
      first_name
      last_name
      team_name
      profile_image
      site_id
      site_name
      role
      updated_at
      status
      status_label
    }
  }
`;

const FETCH_AUTO_TRANSFER = gql`
  query autoTransferCall($lead_id: String!, $multiplier: Int!) {
    autoTransferCall(lead_id: $lead_id, multiplier: $multiplier)
  }
`;

const FETCH_ORG_LIVE_TRANSFER_SETTINGS = gql`
  query FetchOrgLiveTransferSetting {
    fetchOrgLiveTransferSetting {
      id
      transfer_type
      max_cycle
    }
  }
`;

interface TransferProps {
  chooseRep: (rep_id: string) => void;
  transferSessionsCount: number;
}

const TransferComponentV3: React.FC<TransferProps> = ({ chooseRep, transferSessionsCount }) => {
  const {
    data: dataOrgLiveTransferSettings,
    error: errorOrgLiveTransferSettings,
    loading: loadingOrgLiveTransferSettings,
    refetch: refetchOrgLiveTransferSettings,
  } = useQuery(FETCH_ORG_LIVE_TRANSFER_SETTINGS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching live transfer settings");
    },
  });

  const {
    callLeadId,
    setTransferError,
    transferState,
    setTransferState,
    intentId,
    callOptionStackPeek,
    callOptionStackPush,
    callOptionStackPop,
    setAssociatedAction,
    hangup,
    updateCallHoldStatus,
    transfer_attempt_id,
    setTransferAttemptId,
    cancelTransferGlobal,
    callSid,
  } = useContext(CallContext);

  // component cleanup
  useEffect(() => {
    return () => {
      setTransferError("");
      setTransferState("Idle");
    };
  }, []);

  // if the call is ended before transfering
  useEffect(() => {
    const callEnded = !currentCallState().onCall;
    // current functionaly allows reps to continue call after lead has left
    if (callEnded && transferState !== "Success") {
      console.log("call ended before transfer, cancelling transfer and popping call option");
      // cancel any pending transfer
      cancelTransferGlobal();
      // pop call option off the stack
      callOptionStackPop();
    }
  }, [currentCallState().onCall]);

  /**
   * Returns the current user's live call transfer type (Manual or Auto)
   * If the user's organization has rep_level_call_transfer setting set to true,
   * Then it will return the user's transfer type on the user object
   * Otherwise, it will return the organization's global transfer type
   */
  const repTransferType = useMemo(() => {
    if (!loadingOrgLiveTransferSettings) {
      return loggedInUser().organization?.rep_level_call_transfer
        ? loggedInUser().transfer_type
        : dataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.transfer_type;
    }
    return "Manual";
  }, [dataOrgLiveTransferSettings, loadingOrgLiveTransferSettings]);

  // transfer state logic
  useEffect(() => {
    if (transferState === "Cancelled") {
      updateCallHoldStatus(false);
      if (callOptionStackPeek() === "transfer demo") {
        setTransferAttemptId("");
      }
    } else if (transferState === "Rejected" || transferState === "Fail") {
      if (callOptionStackPeek() === "transfer demo") {
        setTransferAttemptId("");
        if (repTransferType === `Auto`) {
          setAutoTransferOn(true);
          fetchAutoTransfer();
        }
      }
    }
  }, [transferState]);

  const [autoTransferOn, setAutoTransferOn] = useState(true);
  const [destinationUser, setDestinationUser] = useState({ user_id: "", user_name: "", user_profile_image: "" });
  const [transferCallSelection, setTransferCallSelection] = useState("");
  const [sortOption, setSortOption] = useState("CloseRate");
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [expandSortButton, setExpandSortButton] = useState(false);
  const [searchValue, setSearchValue] = useDebouncedState("", 1000);
  const lottieOptions = (loop: boolean, animationData: any) => {
    return {
      loop: loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  };

  const cancelAndGoBackToActionList = () => {
    //take off hold
    updateCallHoldStatus(false);

    // cancel transfer
    setTransferState("Cancelled");

    appToast(
      "No reps availiable per the live transfer rules set by the organization. Please schedule a meeting instead.",
    );

    // go back to call options
    callOptionStackPop();
  };

  const [
    fetchManualTransferRepList,
    { data: manualRepList, loading: loadingManualRepList, error: errorManualRepList },
  ] = useLazyQuery(FETCH_MANUAL_TRANSFER_REP_LISTV2, {
    fetchPolicy: "network-only",
    variables: {
      ascending: ascendingOrder,
      sort_option: sortOption,
      lead_id: callLeadId,
    },

    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted: ({ fetchManualTransferRepListV2 }) => {
      const backupRuleIsScheduleMeeting =
        dataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.backup_rule === "ScheduleMeeting";
      const noReps = fetchManualTransferRepListV2?.length === 0;
      if (noReps && backupRuleIsScheduleMeeting) {
        cancelAndGoBackToActionList();
      }
    },
  });

  const [fetchAutoTransfer] = useLazyQuery(FETCH_AUTO_TRANSFER, {
    fetchPolicy: "network-only",
    variables: {
      lead_id: callLeadId,
      multiplier: transferSessionsCount || 1,
    },
    onCompleted: ({ autoTransferCall }) => {
      if (callOptionStackPeek() === "transfer demo") {
        if (!autoTransferCall?.user_id) {
          appToast("No Reps available to receive the transfer. Taking the lead off of hold...");
          updateCallHoldStatus(false);
          setTransferState("Cancelled");
          setTransferAttemptId("");
          setAutoTransferOn(false);
          return;
        }

        // try new rep
        setDestinationUser({ user_id: "", user_name: "", user_profile_image: "" });
        transferCall(autoTransferCall?.user_id, callSid!, autoTransferCall?.auto_transfer_selection_id);
      } else {
        chooseRep(autoTransferCall?.user_id);
        callOptionStackPush("log-result");
      }
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      setAutoTransferOn(false);
      appToast("No Reps available to receive the transfer. Taking the lead off of hold...");
      if (callOptionStackPeek() === "transfer demo") {
        updateCallHoldStatus(false);
        setTransferState("Cancelled");
        setTransferAttemptId("");
      }
    },
  });

  // main logic
  useEffect(() => {
    if (loadingOrgLiveTransferSettings) return;

    if (callOptionStackPeek() === "transfer demo" && repTransferType === `Auto`) {
      setAutoTransferOn(true);
      fetchAutoTransfer();
    } else if (callOptionStackPeek() === "transfer demo" && repTransferType === `Manual`) {
      fetchManualTransferRepList();
    }
  }, [dataOrgLiveTransferSettings]);

  configureAbly(ABLY_OPTIONS);

  const ably_transfer_attempt_key = transfer_attempt_id ? `transferAttempt:${transfer_attempt_id}` : ``;

  /**
   * This ably channel receives about updates to the transferAttempt.
   *
   * Possible States
   * ---------------
   * InProgress
   * Success
   * Fail
   * Rejected
   * Cancelled
   * Idle
   *
   */
  const [_, __] = useChannel(ably_transfer_attempt_key, (payload) => {
    console.log("inbound transfer from Ably: ", ably_transfer_attempt_key, payload);
    if (!payload?.data?.status) {
      Sentry.captureEvent({
        message: `No transfer status update in payload from Ably in TransferComponentV3 `,
        extra: {
          payload,
        },
      });
      return;
    }

    // If state changes in real-time, update local state
    if (payload?.data?.status !== transferState) {
      setTransferState(payload?.data?.status);
    }
  });

  const transferCall = async (user_id_param: string, call_sid: string, auto_transfer_selection_id?: string) => {
    setTransferState("InProgress");
    updateCallHoldStatus(true);

    const response = await restAPI.post(`${BACKEND_URL}/twilio/transferCall`, {
      intent_id: intentId,
      lead_id: callLeadId,
      user_id: user_id_param,
      auto_transfer_selection_id,
      call_sid,
    });

    console.log("response from transferCall: ", response);
    if (response.data.error) {
      setTransferError(response.data.error);
      setDestinationUser({ user_id: "", user_name: "", user_profile_image: "" });
      return appToast(response.data.error);
    }
    const { transfer_attempt_id, user_id, user_name, user_profile_image } = response.data;

    setTransferAttemptId(transfer_attempt_id);
    setDestinationUser({ user_id, user_name, user_profile_image });
  };

  const formatSortOptionName = (name: string) => {
    switch (name) {
      case "CloseRate":
        return "Close Rate";
      case "SiteName":
        return "Site Name";
      default:
        return "";
    }
  };

  /* current time for rep last active calculation

updating current time on re-render leads to weird UX

if you want the current time to update add the state variable that will change to the dependency array

*/

  const now = useMemo(() => moment(), [manualRepList]);

  const MemoizedTimeSinceLastActive = React.memo(
    ({
      item,
      transferCallSelection,
      theme,
      now,
    }: {
      item: any;
      transferCallSelection: string;
      theme: any;
      now: moment.Moment;
    }) => {
      return (
        <div>
          <AppText
            key={item?.user_id}
            style={{
              color: `${item?.user_id === transferCallSelection ? theme.WHITE_COLOR : theme.BLACK_COLOR}`,
              textAlign: "left",
            }}
          >
            {`Last updated ${formatTimeDifferenceByDelineation({
              startingTimeMoment: moment(item?.updated_at),
              endingTimeMoment: now,
              showEndingText: true,
              roundNumber: true,
            })}`}
          </AppText>
        </div>
      );
    },
  );

  const error = errorManualRepList || errorOrgLiveTransferSettings;

  const manualLiveTransferSetting = repTransferType === `Manual`;

  const showRepList = transferState === "Idle" && manualLiveTransferSetting;

  const loading = loadingOrgLiveTransferSettings;

  // prevent showing lotte before knowing whether to show rep list or auto transfer
  if (loading) {
    return <SkeletonBlock height={"100%"} width={"100%"} borderRadius={50} />;
  }

  if (error) {
    return (
      <div>
        <AppErrorText>Error: {error.message}</AppErrorText>
      </div>
    );
  }

  if (showRepList) {
    return loadingManualRepList ? (
      <TransferDiv>
        <SkeletonBlock height={"100%"} width={"100%"} borderRadius={4} />
      </TransferDiv>
    ) : (
      <TransferDiv>
        <AppText style={{ fontSize: "16px", margin: "0px auto 0px auto" }}>Choose Rep</AppText>
        <UserStatusTitleDiv>
          <UserTitle>Reps</UserTitle>

          <div style={{ display: "flex", alignItems: "center" }}>
            <AppIconButton onClick={() => setAscendingOrder(!ascendingOrder)}>
              <img
                style={{
                  transform: !!ascendingOrder ? "rotate(180deg" : "rotate(0deg)",
                }}
                src={iconArrowDown}
              />
            </AppIconButton>
            <UserTitle>Sort by {formatSortOptionName(sortOption)}</UserTitle>
            <AppIconButton onClick={() => setExpandSortButton(!expandSortButton)}>
              <PhoenixIcon svg={bar_chart_down} size={20} variant="brand" />
            </AppIconButton>
          </div>
          {!!expandSortButton && (
            <OptionsDiv>
              <Option
                onClick={() => {
                  setExpandSortButton(!expandSortButton);
                  setSortOption("CloseRate");
                }}
              >
                Close Rate
              </Option>
              <Option
                onClick={() => {
                  setExpandSortButton(!expandSortButton);
                  setSortOption("SiteName");
                }}
              >
                Site Name
              </Option>
            </OptionsDiv>
          )}
        </UserStatusTitleDiv>
        <AppInput placeholder="Search for Rep..." onChange={(e: any) => setSearchValue(e.target.value)} />
        <ButtonsDiv>
          {manualRepList?.fetchManualTransferRepListV2
            ?.filter((item: any) => {
              const lowerCaseSearch = searchValue.toLowerCase();
              const fullName = (item?.first_name + " " + item?.last_name).toLowerCase();
              return fullName.includes(lowerCaseSearch);
            })
            ?.filter((item: any) => item?.user_id !== loggedInUser().id)
            ?.map((item: any) => (
              <NewAppButton
                disabled={!currentCallState().onCall}
                key={item.user_id}
                variant={"secondary"}
                height={88}
                selected={item?.user_id === transferCallSelection}
                onClick={() => setTransferCallSelection(item?.user_id)}
                className={!currentCallState().onCall ? "disabled-rep-row" : ""}
              >
                <div style={{ display: "flex", flexDirection: "row", height: 88 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "16px",
                      justifyContent: "center",
                    }}
                  >
                    <ProfileImageWithText
                      profile_image={item?.profile_image}
                      first_name={item?.first_name}
                      last_name={item?.last_name}
                    />
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "column",
                      alignSelf: "center",
                      flexGrow: 2,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div>
                      <AppText>{`${item?.first_name} ${item?.last_name} (${item?.role})`}</AppText>
                    </div>
                    <MemoizedTimeSinceLastActive
                      item={item}
                      transferCallSelection={transferCallSelection}
                      theme={theme}
                      now={now}
                    />
                    <div
                      style={{
                        display: "inline-flex",
                        flexDirection: "row",
                        gap: 8,
                        color: `${item?.user_id === transferCallSelection ? theme.WHITE_COLOR : theme.BLACK_COLOR}`,
                      }}
                    >
                      <AppText>{`${item?.team_name}`}</AppText>
                      <AppText>{`${formatCellData(item?.close_rate, "percentage")}% CR`}</AppText>
                      <AppText>{`${item?.site_name}`}</AppText>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center", display: "inline-flex", justifyContent: "flex-end", flexGrow: 1 }}>
                    <AppText>{item?.status_label?.toUpperCase()}</AppText>
                  </div>
                </div>
              </NewAppButton>
            ))}
        </ButtonsDiv>
        <NewAppButton
          style={{ marginTop: 20 }}
          size={"lg"}
          variant={"primary"}
          onClick={() => {
            if (callOptionStackPeek() === "transfer demo") {
              transferCall(transferCallSelection, callSid!);
            } else {
              chooseRep(transferCallSelection);
              callOptionStackPush("log-result");
            }
          }}
          disabled={!transferCallSelection || !currentCallState().onCall}
        >
          Choose Rep
        </NewAppButton>
      </TransferDiv>
    );
  }

  // Dialing State
  if (transferState === "InProgress") {
    const profileImage = cloudinary.image(
      destinationUser.user_profile_image ? destinationUser.user_profile_image : DEFAULT_CLOUDINARY_IMAGE,
    );
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 48,
          justifyContent: "space-between",
          flexGrow: 2,
          gap: 32,
        }}
      >
        <Lottie options={lottieOptions(true, transferPending)} height={144} width={144} />
        <TransferText>
          {destinationUser?.user_name
            ? `Attempting to connect to ${destinationUser.user_name}...`
            : `Call Transfer in Progress...`}
        </TransferText>
        <ImageContainer>
          <AdvancedImageStyle style={{ opacity: 1 }} cldImg={profileImage} />
        </ImageContainer>
        <NewAppButton size={"lg"} variant={"attention"} onClick={() => cancelTransferGlobal()}>
          Cancel Transfer
        </NewAppButton>
      </div>
    );
  }

  // If the destination rep never picked up
  if (
    (!autoTransferOn || callOptionStackPeek() === "choose user" || repTransferType === "Manual") &&
    (transferState === "Rejected" || transferState === "Fail" || transferState === "Cancelled")
  ) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Lottie style={{ marginTop: 48 }} options={lottieOptions(false, transferFailure)} height={144} width={144} />
        <div style={{ textAlign: "center", marginTop: 25 }}>
          <TransferText
            style={{ marginTop: 25, fontWeight: 500 }}
          >{`Unable to successfully transfer call.`}</TransferText>
          <NewAppButton
            style={{ marginTop: 25, marginBottom: 50 }}
            variant={"secondary"}
            size={"lg"}
            onClick={() => {
              setTransferError("");
              setTransferState("Idle");
              if (callOptionStackPeek() === "transfer demo") {
                // resetTransfer();
                callOptionStackPop();
              }
            }}
          >
            {repTransferType === `Auto` ? `Go Back` : `Return to AE List`}
          </NewAppButton>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 16, width: "100%" }}>
          <TransferTextDescription
            style={{ marginTop: 25 }}
          >{`If there are no Account Executives Available, keep the prospect on the line and select from these options.`}</TransferTextDescription>
          <NewAppButton
            variant={"primary"}
            size={"lg"}
            onClick={() => {
              callOptionStackPush("schedule-event");
            }}
          >
            Schedule Event
          </NewAppButton>
          <NewAppButton
            variant={"secondary"}
            size={"lg"}
            onClick={() => {
              setAssociatedAction("");

              callOptionStackPush("log-other");
            }}
          >
            Log a different call result
          </NewAppButton>
        </div>
      </div>
    );
  }

  // Connected State
  if (transferState === "Success") {
    const profileImage = cloudinary.image(
      destinationUser.user_profile_image ? destinationUser.user_profile_image : DEFAULT_CLOUDINARY_IMAGE,
    );
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 48,
          justifyContent: "space-between",
          flexGrow: 2,
          // height: "600px",
        }}
      >
        {destinationUser.user_name && (
          <>
            <ImageContainer>
              <AdvancedImageStyle style={{ opacity: 1 }} cldImg={profileImage} />
            </ImageContainer>
            <TextDiv>
              <TransferTextDescription>{`You are now connected to`}</TransferTextDescription>
              <TransferTextFocus>{destinationUser.user_name}</TransferTextFocus>
            </TextDiv>
          </>
        )}
        <TransferTextDescription
          style={{ marginTop: 150 }}
        >{`Merge your lead into the call when ready and then exit`}</TransferTextDescription>
        <NewAppButton
          variant={"primary"}
          size={"lg"}
          onClick={() => {
            updateCallHoldStatus(false);
            callOptionStackPush("log-other");
          }}
        >
          Merge In Lead
        </NewAppButton>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 16, width: "100%" }}>
          {!!currentCallState().onCall ? (
            <NewAppButton
              variant={"secondary"}
              size={"lg"}
              onClick={() => {
                hangup();
              }}
            >
              End Call
            </NewAppButton>
          ) : (
            <NewAppButton
              variant={"secondary"}
              size={"lg"}
              onClick={() => {
                callOptionStackPush("log-other");
              }}
            >
              Next
            </NewAppButton>
          )}
        </div>
      </div>
    );
  }

  // Default State (could not connect)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 48,
        justifyContent: "space-between",
        flexGrow: 2,
        // height: "600px",
      }}
    >
      <Lottie options={lottieOptions(true, transferPending)} height={144} width={144} />
    </div>
  );
};

const TransferDiv = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
  text-align: center;
`;

const TransferText = styled(AppText)`
  font-size: 16px;
  text-align: center;
`;

const TransferTextDescription = styled(AppText)`
  font-size: 14px;
  text-align: center;
  width: 275px;
`;

const TransferTextFocus = styled(AppText)`
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
`;

const UserTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

const UserStatusTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 2;

  .disabled-rep-row {
    opacity: 0.5;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 124px;
  border: 3px solid #3e65ff;
  margin-bottom: 8px;
`;

const TextDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 112px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 40px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 110px;
  width: 110px;
  height: 110px;
`;

export { TransferComponentV3 };
