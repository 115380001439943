import React, { useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText } from "..";

interface PersonSpokeToPillProps {
  person: string;
  colorOverride?: string;
  backgroundColorOverride?: string;
}

export const PersonSpokeToPill: React.FC<PersonSpokeToPillProps> = ({
  person,
  colorOverride,
  backgroundColorOverride,
}) => {
  const lowercasePerson = person.toLowerCase();

  const backgroundColor = lowercasePerson === "no contact" ? theme.fill.neutral.quaternary : theme.fill.brand.primary;
  const color = theme.WHITE_COLOR;

  return (
    <PillContainer backgroundColor={backgroundColorOverride || backgroundColor}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={colorOverride || color}>
        {person || "N/A"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 4px 8px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  width: max-content;
  max-width: 100%;
`;
