import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { CalendarToolbar } from "../Segments/CalendarToolbar";
import * as Sentry from "@sentry/react";
import { theme } from "../../utils/theme";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { AppErrorText, Loading } from "../UI";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { GeneralTimeCalendarPopup } from "../modal";
import { RequestTimeOff } from "../modal/RequestTimeoff";
import "../../utils/schedule-manager.css";
import { EventDetails } from "../Smart";
import { useHistory, useParams } from "react-router-dom";
import { appToast } from "../../utils/toast";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sanitizeURL } from "../../utils/misc";
import { OptionItem } from "../../types";
// Data for both views.
const FETCH_EVENTS = gql`
  query {
    fetchCalendarSchedule {
      id
      start_time
      end_time
      user {
        id
        full_name
        role
      }
      timeoff_request {
        id
      }
      join_me_item {
        id
        schedule_item_id
      }
      deleted_at
      lead_intent {
        id
        label
        type
        associated_action
        lead {
          id
          full_name
          business_name
          primary_email
          primary_phone_number
        }
      }
    }
  }
`;

const FETCH_SCHEDULE_ITEM = gql`
  query scheduleItem($id: String!) {
    scheduleItem(where: { id: $id }) {
      id
      start_time
      deleted_at
      join_me_item {
        id
        schedule_item_id
      }
      lead_intent {
        id
        lead {
          id
          full_name
        }
      }
    }
  }
`;

const FETCH_HISTORICAL_ITEM = gql`
  query fetchHistoricalEvent($id: String!) {
    fetchHistoricalEvent(id: $id) {
      id
      schedule_item {
        id
        start_time
        end_time
        deleted_at
        join_me_item {
          id
          schedule_item_id
        }
        lead_intent {
          id
        }
      }
      lead {
        id
        full_name
      }
    }
  }
`;

interface ParamTypes {
  event_id: string;
}

export const FETCH_GENERAL_CALLBACKS = gql`
  query fetchGeneralCallBacks($team_filter: Boolean, $org_filter: Boolean, $month: MONTH) {
    fetchGeneralCallBacks(team_filter: $team_filter, org_filter: $org_filter, month: $month)
  }
`;

const ScheduleManager: React.FC = () => {
  const { event_id } = useParams<ParamTypes>();
  const history = useHistory();

  const [eventEditData, setEventEditData] = useState({ id: "", time: "", lead_name: "", join_me: false, lead_id: "" });
  const [eventDetails, setEventDetails] = useState({} as any);
  const [timeOffData, setTimeOffData] = useState({
    id: "",
    startTime: new Date(0),
    endTime: new Date(0),
    userFullName: "",
    newEvent: true,
  });
  const [generalTimeData, setGeneralTimeData] = useState([]);
  const [popupDate, setPopupDate] = useState("");
  const [activeView, setActiveView] = useState("Calendar");
  const [editTimeOffOn, setEditTimeoffOn] = useState(false);
  const [generalTimeOn, setGeneralTimeOn] = useState(false);
  const [blinds, setBlinds] = useState(false);

  const [eventFilter, setEventFilter] = useState<OptionItem[]>([]);

  const localizer = momentLocalizer(moment);

  const { data: eventData, loading: eventLoading, error: eventError, refetch: refetchEventData } = useQuery(
    FETCH_EVENTS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
        appToast(message);
      },
    },
  );

  const { data: generalCallBackData, loading: generalCallbackLoading, error: generalCallbackError } = useQuery(
    FETCH_GENERAL_CALLBACKS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataEvent, loading: loadingEvent, error: errorEvent, refetch: refetchEvent } = useQuery(
    FETCH_SCHEDULE_ITEM,
    {
      fetchPolicy: "network-only",
      skip: !event_id,
      variables: { id: event_id },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
        appToast(message);
      },
    },
  );

  const [
    fetchHistoricalEvent,
    {
      data: dataHistoricalEvent,
      loading: loadingHistoricalEvent,
      error: errorHistoricalEvent,
      refetch: refetchHistoricalEvent,
    },
  ] = useLazyQuery(FETCH_HISTORICAL_ITEM, {
    fetchPolicy: "network-only",
    variables: { id: event_id },
    onCompleted(data) {
      setEventEditData({
        id: data?.fetchHistoricalEvent?.schedule_item?.id,
        time: data?.fetchHistoricalEvent?.schedule_item?.start_time,
        lead_name: data?.fetchHistoricalEvent?.schedule_item?.lead_intent?.lead?.full_name,
        join_me: data?.fetchHistoricalEvent?.schedule_item?.join_me_item ? true : false,
        lead_id: data?.fetchHistoricalEvent?.lead?.id,
      });
      setEditTimeoffOn(false);
      setActiveView("Event");
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
      appToast(message);
    },
  });

  useEffect(() => {
    if (!!dataEvent?.scheduleItem?.id) {
      if (dataEvent?.scheduleItem?.deleted_at) {
        fetchHistoricalEvent({
          variables: { id: event_id },
        });
      } else {
        setEventEditData({
          id: dataEvent?.scheduleItem?.id,
          time: dataEvent?.scheduleItem?.start_time,
          lead_name: dataEvent?.scheduleItem?.lead_intent?.lead?.full_name,
          join_me: dataEvent?.scheduleItem?.join_me_item ? true : false,
          lead_id: dataEvent?.scheduleItem?.lead_intent?.lead?.id,
        });
        setEditTimeoffOn(false);
        setActiveView("Event");
      }
    }
  }, [event_id, dataEvent]);

  const handleEditClick = (id: string) => {
    const santizedURL = sanitizeURL(`/manage-schedule/${id}`);
    history.push(santizedURL);
  };

  const handleTimeoffClick = (id: string, startTime: Date, endTime: Date, userFullName?: string) => {
    setTimeOffData({
      id: id,
      startTime: startTime,
      endTime: endTime,
      userFullName: userFullName ? userFullName : "",
      newEvent: false,
    });

    setEditTimeoffOn(true);
    setBlinds(true);
  };

  const handleNewTimeoffClick = (startTime: Date, endTime: Date) => {
    setTimeOffData({ id: "", startTime: startTime, endTime: endTime, userFullName: "", newEvent: true });
    setEditTimeoffOn(true);
    setBlinds(true);
  };

  const handleOutsideModalClick = () => {
    setEditTimeoffOn(false);
    setGeneralTimeOn(false);
    setBlinds(false);
  };

  const handleGeneralTimesClick = (data: any, date: string) => {
    setGeneralTimeOn(true);

    setEditTimeoffOn(false);
    setBlinds(true);
    setPopupDate(date);
    setGeneralTimeData(data);
  };

  const returnEventLabel = (item: any) => {
    if (item.timeoff_request) return "Time-Off";
    if (item.lead_intent && item.lead_intent.label) {
      if (item.lead_intent?.lead?.business_name)
        return `${item.lead_intent.lead.business_name} - ${item.lead_intent.label}`;
      return item.lead_intent.label;
    }
    if (item.generalCallBack) return item.title;
    return "Event";
  };

  const returnEventColor = (item: any) => {
    if (item.timeoff_request) return theme.TERTIARY500;
    if (!!item.deleted_at) return theme.WARNING500;
    else if (item.generalCallBack) return theme.PRIMARY500;
    else if (item.lead_intent?.associated_action === "Follow Up Demo") {
      return "#4EA1E0";
    } else if (item.lead_intent?.associated_action === "Decision Call") {
      return theme.SUCCESS700;
    } else if (item.lead_intent?.type === "Demo") {
      return theme.PRIMARY800;
    } else if (item.lead_intent?.type === "ScheduledCallBack") {
      return "#3D0E72";
    } else return theme.PRIMARY500;
  };

  const generalCallBackEventItems = generalCallBackData
    ? Object.keys(generalCallBackData?.fetchGeneralCallBacks)?.map((item: any) => {
        return {
          start_time: item?.replaceAll("_", "-"),
          end_time: item?.replaceAll("_", "-"),
          title: `${generalCallBackData?.fetchGeneralCallBacks?.[item].length} General Time Events`,
          generalCallBack: true,
          timeoff_request: false,
          index: item,
        };
      })
    : [];

  const calendarData = useMemo(() => {
    if (!eventData || !generalCallBackEventItems) return [];
    let events = eventData.fetchCalendarSchedule.concat(generalCallBackEventItems)?.map((item: any) => {
      return {
        title: returnEventLabel(item),
        start: moment(item?.start_time).toDate(),
        end: moment(item?.end_time).toDate(),
        color: !!item.deleted_at ? theme.WARNING500 : returnEventColor(item),
        timeoff_request: item?.timeoff_request,
        id: item?.id,
        full_name: item.user?.full_name ?? "",
        generalCallBack: item?.generalCallBack ?? false,
        index: item.index ?? "",
        lead_name: item?.lead_intent?.lead?.full_name,
        join_me: item?.join_me_item ? true : false ?? false,
        lead_id: item.lead_intent?.lead?.id ?? "",
        intent: item?.lead_intent,
      };
    });

    const eventFilterValues = eventFilter?.map((item) => item.value);

    events = events.filter((item: any) => {
      if (!eventFilterValues.length) return true;
      if (eventFilterValues.includes("Decision Call") && item.intent?.associated_action === "Decision Call") {
        return true;
      }
      if (eventFilterValues.includes("Follow Up Demo") && item.intent?.associated_action === "Follow Up Demo") {
        return true;
      }
      if (eventFilterValues.includes("Demo") && item.intent?.type === "Demo") {
        return true;
      }
      if (eventFilterValues.includes("ScheduledCallBack") && item.intent?.type === "ScheduledCallBack") {
        return true;
      }
      return false;
    });

    return events;
  }, [eventData, eventFilter]);

  if (eventLoading || generalCallbackLoading || loadingEvent) {
    return <Loading />;
  }

  if (eventError || generalCallbackError) {
    return <AppErrorText>Error loading events.</AppErrorText>;
  }

  // This is where you style the calendar events.
  function eventStyleGetter(event: any) {
    const style = {
      backgroundColor: event.color,
    };
    return {
      style: style,
    };
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Schedule Manager page."}>
      <Container>
        {blinds && <DarkDiv onClick={() => handleOutsideModalClick()} />}
        {activeView === "Calendar" && (
          <CalendarContainer>
            <Calendar
              components={{
                toolbar: (props) => (
                  <CalendarToolbar
                    eventFilter={eventFilter}
                    handleEventFilterChange={(e: OptionItem[]) => setEventFilter(e)}
                    {...props}
                  />
                ),
              }}
              popup={true}
              selectable={true}
              localizer={localizer}
              events={calendarData}
              eventPropGetter={eventStyleGetter}
              views={["day", "week", "month"]}
              // onSelectSlot={(e) => handleNewTimeoffClick(moment(e.start).toDate(), moment(e.end).toDate())}
              onSelectEvent={(e) => {
                if (e.timeoff_request) {
                  handleTimeoffClick(e.id, e.start, e.end, e.full_name);
                } else if (e.generalCallBack) {
                  handleGeneralTimesClick(generalCallBackData?.fetchGeneralCallBacks?.[e.index], e.start);
                } else if (!e.timeoff_request) {
                  handleEditClick(e.id);
                }
              }}
            />
          </CalendarContainer>
        )}

        {activeView === "Event" && <EventDetails eventEditData={eventEditData} />}

        {editTimeOffOn && (
          <RequestTimeOff
            id={timeOffData.id}
            full_name={timeOffData.userFullName ? timeOffData.userFullName : ""}
            start={timeOffData.startTime}
            end={timeOffData.endTime}
            newEvent={timeOffData.newEvent}
            onClinkingClose={() => {
              setEditTimeoffOn(false);
              setBlinds(false);
            }}
            refetchData={refetchEventData}
          />
        )}

        {generalTimeOn && (
          <GeneralTimeCalendarPopup onClickingClose={handleOutsideModalClick} data={generalTimeData} date={popupDate} />
        )}
      </Container>
    </Sentry.ErrorBoundary>
  );
};

const Container = styled.div`
  /* min-width: 1250px; */
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  /* width: calc(100vw - 72px); */
  min-height: calc(100vh);
  height: 100vh;
  background: ${theme.surface.neutral.tertiary};
  overflow: hidden;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 94vh;
  background: ${theme.WHITE_COLOR};
  border-radius: 3px;

  .rbc-calendar {
    background-color: ${theme.NEUTRAL100};
  }

  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    background: ${theme.PRIMARY50};
    z-index: 1;

    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .rbc-month-view,
  .rbc-time-view {
    border: 1px solid ${theme.NEUTRAL200};
    border-radius: 8px;
    overflow: hidden;
  }

  .rbc-time-slot,
  .rbc-day-bg {
    background-color: ${theme.WHITE_COLOR};
  }
`;

const CenterPopupDiv = styled.div`
  position: absolute;
  top: 10%;
  left: 30%;
`;

const DarkDiv = styled.div`
  background-color: rgba(22, 21, 26, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
`;

export { ScheduleManager };
