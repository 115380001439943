import React, { useState } from "react";
import moment from "moment";
import { DateRangePicker as ReactDatesPicker } from "react-dates";
import { AppText, FlexDiv, ReactDatesWrapper } from "src/Components/UI";

const DateRangePicker = ({
  endDateId,
  lowerboundKey = "lowerbound_date",
  onChange,
  startDateId,
  upperboundKey = "upperbound_date",
  value,
}: {
  endDateId: string;
  lowerboundKey?: string;
  onChange?: (value: any) => void;
  startDateId: string;
  upperboundKey?: string;
  value?: any;
}) => {
  const [focusedInput, setFocusedInput] = useState(null as any);

  return (
    <FlexDiv direction="column" gap={8}>
      <AppText>Between</AppText>
      <FlexDiv align="flex-start" justify="flex-end" direction="column">
        <ReactDatesWrapper fixedPosition>
          <ReactDatesPicker
            minimumNights={0}
            showClearDates
            isOutsideRange={() => false}
            startDate={value?.[lowerboundKey] ? moment(value?.[lowerboundKey]) : null}
            startDateId={startDateId}
            endDate={value?.[upperboundKey] ? moment(value?.[upperboundKey]) : null}
            endDateId={endDateId}
            onDatesChange={({ startDate, endDate }) => {
              onChange?.({
                [lowerboundKey]: startDate,
                [upperboundKey]: endDate,
              });
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          />
        </ReactDatesWrapper>
      </FlexDiv>
    </FlexDiv>
  );
};

export default DateRangePicker;
