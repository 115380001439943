import React, { useContext, useMemo, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { ModalContext } from "../../context";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { DarkDiv, AppText } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { theme } from "../../utils/theme";
import { PhoenixIcon, PhoenixAppButton, PhoenixRadio } from "../UI/Phoenix";
import { PhoenixInputField, PhoenixMultiSelectField } from "../Field/Phoenix";
import { xIcon } from "../../images/NewDesign";
import Switch from "react-switch";
import { OptionItem } from "../../types";
import { SYSTEM_FIELD_CHAR_LIMIT } from "../../utils/format";
import { gql, useMutation } from "@apollo/client";
import { errorToast, successToast } from "../../utils/toast";
import { cloneDeep } from "lodash";
import { TableProductType } from "../Pages/ManageProducts";
import { MixpanelActions } from "src/services/mixpanel";

const productSchema = Yup.object().shape({
  product_title: Yup.string()
    .required()
    .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  prepayment_options: Yup.array().required(),
  pricing: Yup.number().required(),
});

const ADD_OR_UPDATE_PRODUCTS = gql`
  mutation addOrUpdateProducts($products: [ProductsInputNew!]!) {
    addOrUpdateProducts(products: $products) {
      id
    }
  }
`;

interface AddOrEditProductModalProps {
  editProductModalState: boolean;
  selectedProduct: TableProductType | null;
}

export const AddOrEditProductModal: React.FC<AddOrEditProductModalProps> = ({
  editProductModalState,
  selectedProduct,
}) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const { setShowAddOrEditProductModal } = useContext(ModalContext);

  const discountOptions: { label: string; value: number }[] = useMemo(
    () =>
      (!!selectedProduct?.available_concessions?.length
        ? selectedProduct?.available_concessions
            ?.split(", ")
            ?.map((e: string) => ({ label: e, value: parseInt(e.slice(1)) }))
        : []) || [],
    [],
  );

  const [addOrUpdateProducts, { loading: loadingAddOrUpdateProducts }] = useMutation(ADD_OR_UPDATE_PRODUCTS, {
    onCompleted() {
      successToast(!!selectedProduct?.id ? "Product updated" : "Product created");
      if (!selectedProduct?.id) {
        const values = formikRef.current?.values;
        MixpanelActions.track("Product Added", {
          fee_type: values.fee_type,
          price_overrides_allowed: values.show_override_price_option,
          mapped_to_sfdc_id: Boolean(values.salesforce_id),
        });
      } else {
        MixpanelActions.track("Product Edited");
      }

      setShowAddOrEditProductModal(false);
    },
    onError({ message }) {
      errorToast(message);
      console.log("Error in addOrUpdateProducts: ", message);
    },
    refetchQueries: ["fetchProducts"],
  });

  return (
    <>
      <DarkDiv />
      <ModalContainer direction="column">
        <ModalHeader>
          <PhoenixIcon
            svg={xIcon}
            size={24}
            variant="brand"
            pointer
            onClick={() => setShowAddOrEditProductModal(false)}
          />
          <FlexDiv justify="center" style={{ width: "100%" }}>
            <AppText fontSize={18} fontWeight={500} lineHeight={24}>
              {editProductModalState ? "Edit" : "Add"} Product
            </AppText>
          </FlexDiv>
        </ModalHeader>

        <Formik
          innerRef={formikRef}
          initialValues={{
            id: selectedProduct?.id,
            product_title: selectedProduct?.product_title,
            fee_type: selectedProduct?.fee_type || "Recurring",
            prepayment_options:
              (!!selectedProduct?.prepayment_options?.length
                ? selectedProduct?.prepayment_options
                    ?.split(", ")
                    ?.map((e: string) => ({ label: e, value: parseInt(e) }))
                : []) || [],
            pricing: parseInt(selectedProduct?.pricing?.slice(1) || "0"),
            available_concessions: discountOptions,
            pricing_floor: parseInt(selectedProduct?.pricing_floor?.slice(1) || "0"),
            salesforce_id: selectedProduct?.salesforce_id,
            pricebook_entry_id: selectedProduct?.pricebook_entry_id,
            show_override_price_option: selectedProduct?.show_override_price_option || false,
            active: selectedProduct?.active || true,
          }}
          onSubmit={async (values) => {
            const submitValue: any = cloneDeep(values);
            submitValue.available_concessions = values.available_concessions?.map((ac: OptionItem) => ({
              label: ac.label,
              amount: ac.value,
            }));
            submitValue.fee_type = values.fee_type === "Recurring" ? "RECURRING" : "ONE_TIME";
            submitValue.prepayment_options = values.prepayment_options?.map((po: OptionItem) => po.value);

            await addOrUpdateProducts({ variables: { products: [submitValue] } });
          }}
          validationSchema={productSchema}
          validateOnMount
        >
          {({ values, submitForm, setFieldValue, isValid, isSubmitting }) => {
            return (
              <>
                <ModalBody>
                  <FlexDiv
                    direction="column"
                    gap={24}
                    style={{ paddingBottom: "40px", borderBottom: `1px solid ${theme.NEUTRAL200}` }}
                  >
                    <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.PRIMARY500}>
                      General Information
                    </AppText>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Product Name
                        <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                      </AppText>
                      <PhoenixInputField
                        name="product_title"
                        value={values.product_title}
                        onChange={(e) => setFieldValue("product_title", e.target.value)}
                        placeholder="Enter a product name"
                        displayNoContextText
                      />
                    </FlexDiv>

                    <FlexDiv gap={24} align="center">
                      <FlexDiv gap={8} align="center">
                        <PhoenixRadio
                          selected={values.fee_type === "Recurring"}
                          onClick={(e) => setFieldValue("fee_type", "Recurring")}
                        />
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          Recurring Fee
                        </AppText>
                      </FlexDiv>
                      <FlexDiv gap={8} align="center">
                        <PhoenixRadio
                          selected={values.fee_type === "One-Time"}
                          onClick={(e) => setFieldValue("fee_type", "One-Time")}
                        />
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          One-Time Fee
                        </AppText>
                      </FlexDiv>
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Prepayment Terms
                        <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                      </AppText>
                      <PhoenixMultiSelectField
                        isMulti={true}
                        isClearable={false}
                        name="prepayment_options"
                        value={values.prepayment_options}
                        options={Array.apply(null, Array(12))?.map((_, i) => ({ label: i + 1, value: i + 1 }))}
                        onChange={(e: OptionItem[]) => setFieldValue("prepayment_options", e)}
                        marginBottom={false}
                        error={false}
                        hideErrorMessage
                      />
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Price
                        <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                      </AppText>
                      <PhoenixInputField
                        name="pricing"
                        inputValueType="number"
                        value={values.pricing}
                        placeholder="$0"
                        displayNoContextText
                      />
                    </FlexDiv>
                  </FlexDiv>

                  <FlexDiv
                    direction="column"
                    gap={24}
                    style={{ paddingBottom: "40px", borderBottom: `1px solid ${theme.NEUTRAL200}` }}
                  >
                    <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.PRIMARY500}>
                      Overrides and Discounts
                    </AppText>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Discount Options
                      </AppText>
                      <PhoenixMultiSelectField
                        isMulti={true}
                        isClearable={false}
                        name="available_concessions"
                        value={values.available_concessions}
                        options={discountOptions}
                        onChange={(e: { label: string; value: string | number; __isNew__?: boolean }[]) => {
                          const newOptions = e?.map((option) =>
                            option.__isNew__
                              ? { label: `$${option.value}`, value: parseInt(option.value as string) }
                              : option,
                          );
                          setFieldValue("available_concessions", newOptions);
                        }}
                        marginBottom={false}
                        creatableOptions={true}
                        isValidNewOption={(e) => /^\d+$/.test(e)} // only allow numbers
                        hideErrorMessage
                        noOptionsText="Type a number and press enter to add a new discount option."
                      />
                    </FlexDiv>

                    <FlexDiv gap={8} align="center">
                      <Switch
                        onChange={(checked: boolean) => setFieldValue("show_override_price_option", checked)}
                        checked={values.show_override_price_option}
                        onColor={theme.PRIMARY500}
                        offColor={theme.NEUTRAL200}
                        height={16}
                        width={32}
                        handleDiameter={12}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        activeBoxShadow="0 0 3px #000"
                      />
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Allow Price Overrides
                      </AppText>
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Minimum Threshold
                      </AppText>
                      <div style={{ width: "176px" }}>
                        <PhoenixInputField
                          name="pricing_floor"
                          inputValueType="number"
                          value={values.pricing_floor}
                          showNumberArrows
                          displayNoContextText
                          onChange={(e: any) => setFieldValue("pricing_floor", parseInt(e.target.value) || 0)}
                          handleNumberArrowUpClick={() => setFieldValue("pricing_floor", values.pricing_floor + 1)}
                          handleNumberArrowDownClick={() =>
                            setFieldValue("pricing_floor", Math.max(0, values.pricing_floor - 1))
                          }
                        />
                      </div>
                    </FlexDiv>
                  </FlexDiv>

                  <FlexDiv direction="column" gap={24}>
                    <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.PRIMARY500}>
                      Salesforce Mapping
                    </AppText>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Salesforce Product ID
                      </AppText>
                      <PhoenixInputField
                        name="salesforce_id"
                        value={values.salesforce_id}
                        onChange={(e) => setFieldValue("salesforce_id", e.target.value)}
                        placeholder="Enter Salesforce Product ID"
                        displayNoContextText
                      />
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                        Salesforce Pricebook Entry ID
                      </AppText>
                      <PhoenixInputField
                        name="pricebook_entry_id"
                        value={values.pricebook_entry_id}
                        onChange={(e) => setFieldValue("pricebook_entry_id", e.target.value)}
                        placeholder="Enter Salesforce Pricebook Entry ID"
                        displayNoContextText
                      />
                    </FlexDiv>
                  </FlexDiv>
                </ModalBody>

                <ModalFooter>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={() => {
                      setShowAddOrEditProductModal(false);
                    }}
                  >
                    Cancel
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    buttonType="secondary"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={submitForm}
                    disabled={!isValid && !isSubmitting && !loadingAddOrUpdateProducts}
                  >
                    Continue
                  </PhoenixAppButton>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  } 
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  height: 72px;

  padding: 8px 8px 16px 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding: 24px 40px;

  overflow-y: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 72px;
  margin-top: auto;
  padding: 16px 40px;

  background-color: ${theme.WHITE_COLOR};
`;
