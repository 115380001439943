import React, { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../../utils/hooks";
import styled from "styled-components";
import { AppErrorText } from "..";
import { theme } from "../../../utils/theme";

/*
 * PhoenixPopupOptions

  * This component is a popup menu with an optional search bar.

  * unlike a select, this is only a menu with an onclick

  * use case :

1. click on an icon and see a menu next to it with options
2. when you want to show a list of options that do something onClick vs a select which has state and an onChange


*/

interface Option {
  // the value is what is passed into the onClick function
  value: string;
  label: string;
  onOptionClick: () => void;
  tabOption?: boolean;
  disabled?: boolean;
}

export interface PopupOptionsProps {
  loading: boolean;
  error: boolean;
  show: boolean;
  setShow: (show: boolean) => void;
  options: Option[];

  // optional
  backupLabel?: string;
  showSearch?: boolean;

  // menu container css overrides
  height?: number | string;
  containerTop?: number | string;
  containerLeft?: number | string;
  containerPosition?: string;
  containerWidth?: number | string;
  containerMinHeight?: number | string;
  containerMinWidth?: number | string;
  containerMaxHeight?: number | string;
  containerOverflow?: string;
  containerBackgroundColor?: string;
  containerBoxShadow?: string;
  containerBorder?: string;
  containerBorderRadius?: number | string;
  containerZIndex?: number;

  // menu item css overrides
  menuItemWidth?: number | string;
  menuItemHeight?: number | string;
  menuItemBackgroundColor?: string;
  menuItemHoverBackgroundColor?: string;
  menuItemColor?: string;
  menuItemHoverColor?: string;
  menuItemFontWeight?: number | string;
  menuItemFontSize?: number | string;
  menuItemLineHeight?: number | string;
  menuItemPadding?: number | string;
}

const PhoenixPopupOptions: React.FC<PopupOptionsProps> = ({
  loading,
  error,
  show = true,
  setShow,
  options,

  // optional
  backupLabel = "N/A",
  showSearch = false,
  height = 40,
  // css overrides
  containerTop = height,
  containerLeft,
  containerPosition = "absolute",
  containerWidth = "100%",
  containerMinHeight = "250px",
  containerMinWidth = "initial",
  containerMaxHeight = "300px",
  containerOverflow = "auto",
  containerBackgroundColor = theme.WHITE_COLOR,
  containerBoxShadow = "0px 0px 6px 0px rgba(0, 0, 0, 0.15)",
  containerBorder = `1px solid ${theme.NEUTRAL300}`,
  containerBorderRadius = "4px",
  containerZIndex = 999,
  menuItemWidth = "100%",
  menuItemHeight = "40px",
  menuItemBackgroundColor = theme.WHITE_COLOR,
  menuItemHoverBackgroundColor = theme.PRIMARY100,
  menuItemColor = theme.BLACK_COLOR,
  menuItemHoverColor = theme.BLACK_COLOR,
  menuItemFontWeight = 400,
  menuItemFontSize = "12px",
  menuItemLineHeight = "18px",
  menuItemPadding = "0 8px",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");

  useClickOutside(containerRef, () => {
    setShow(false);
  });

  if (!show) return null;

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <>
      <PopupContainer
        ref={containerRef}
        containerTop={containerTop}
        containerLeft={containerLeft}
        containerPosition={containerPosition}
        containerWidth={containerWidth}
        containerMinHeight={containerMinHeight}
        containerMinWidth={containerMinWidth}
        containerMaxHeight={containerMaxHeight}
        containerOverflow={containerOverflow}
        containerBackgroundColor={containerBackgroundColor}
        containerBoxShadow={containerBoxShadow}
        containerBorder={containerBorder}
        containerBorderRadius={containerBorderRadius}
        containerZIndex={containerZIndex}
      >
        {showSearch && (
          <SearchInput
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        )}
        {loading ? (
          <MenuItem
            menuItemBackgroundColor={menuItemBackgroundColor}
            menuItemColor={menuItemColor}
            menuItemFontWeight={menuItemFontWeight}
            menuItemFontSize={menuItemFontSize}
            menuItemLineHeight={menuItemLineHeight}
            menuItemPadding={menuItemPadding}
            menuItemWidth={menuItemWidth}
            menuItemHeight={menuItemHeight}
            menuItemHoverBackgroundColor={menuItemHoverBackgroundColor}
            menuItemHoverColor={menuItemHoverColor}
          >
            Loading...
          </MenuItem>
        ) : error ? (
          <MenuItem
            menuItemBackgroundColor={menuItemBackgroundColor}
            menuItemColor={menuItemColor}
            menuItemFontWeight={menuItemFontWeight}
            menuItemFontSize={menuItemFontSize}
            menuItemLineHeight={menuItemLineHeight}
            menuItemPadding={menuItemPadding}
            menuItemWidth={menuItemWidth}
            menuItemHeight={menuItemHeight}
            menuItemHoverBackgroundColor={menuItemHoverBackgroundColor}
            menuItemHoverColor={menuItemHoverColor}
          >
            <AppErrorText>Error loading options.</AppErrorText>
          </MenuItem>
        ) : (
          filteredOptions?.map((option: Option) => (
            <MenuItem
              menuItemBackgroundColor={menuItemBackgroundColor}
              menuItemColor={option.tabOption ? theme.PRIMARY500 : menuItemColor}
              menuItemFontWeight={menuItemFontWeight}
              menuItemFontSize={menuItemFontSize}
              menuItemLineHeight={menuItemLineHeight}
              menuItemPadding={menuItemPadding}
              menuItemWidth={menuItemWidth}
              menuItemHeight={menuItemHeight}
              menuItemHoverBackgroundColor={menuItemHoverBackgroundColor}
              menuItemHoverColor={menuItemHoverColor}
              key={option.value}
              onClick={() => {
                if (option.disabled) return;
                if (option.tabOption) return;
                if (option.onOptionClick) option.onOptionClick();
              }}
              tabOption={option.tabOption}
              disabled={option.disabled}
            >
              {option.label ?? backupLabel}
            </MenuItem>
          ))
        )}
      </PopupContainer>
    </>
  );
};

const PopupContainer = styled.div<ContainerProps>`
  top: ${(props) => (typeof props.containerTop === "number" ? `${props.containerTop}px` : props.containerTop)};
  left: ${(props) => (typeof props.containerLeft === "number" ? `${props.containerLeft}px` : props.containerLeft)};
  position: ${(props) => props.containerPosition};
  width: ${(props) => (typeof props.containerWidth === "number" ? `${props.containerWidth}px` : props.containerWidth)};
  min-width: ${(props) =>
    typeof props.containerMinWidth === "number" ? `${props.containerMinWidth}px` : props.containerMinWidth};
  max-height: ${(props) =>
    typeof props.containerMaxHeight === "number" ? `${props.containerMaxHeight}px` : props.containerMaxHeight};
  min-height: ${(props) =>
    typeof props.containerMinHeight === "number" ? `${props.containerMinHeight}px` : props.containerMinHeight};
  overflow: ${(props) => props.containerOverflow};
  background-color: ${(props) => props.containerBackgroundColor};
  box-shadow: ${(props) => props.containerBoxShadow};
  border: ${(props) => props.containerBorder};
  border-radius: ${(props) =>
    typeof props.containerBorderRadius === "number" ? `${props.containerBorderRadius}px` : props.containerBorderRadius};
  z-index: ${(props) =>
    typeof props.containerZIndex === "number" ? `${props.containerZIndex}` : props.containerZIndex};

  animation: ${theme.popup} 0.3s ease;
`;

interface ContainerProps {
  containerTop?: number | string;
  containerLeft?: number | string;
  containerPosition?: string;
  containerWidth?: number | string;
  containerMinHeight?: number | string;
  containerMinWidth?: number | string;
  containerMaxHeight?: number | string;
  containerOverflow?: string;
  containerBackgroundColor?: string;
  containerBoxShadow?: string;
  containerBorder?: string;
  containerBorderRadius?: number | string;
  containerZIndex?: number | string;
}

interface MenuItemProps {
  menuItemWidth?: number | string;
  menuItemHeight?: number | string;
  menuItemBackgroundColor?: string;
  menuItemHoverBackgroundColor?: string;
  menuItemColor?: string;
  menuItemHoverColor?: string;
  menuItemFontWeight?: number | string;
  menuItemFontSize?: number | string;
  menuItemLineHeight?: number | string;
  menuItemPadding?: number | string;

  // special case for tab options
  tabOption?: boolean;

  disabled?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  align-items: center;
  width: ${(props) => (typeof props.menuItemWidth === "number" ? `${props.menuItemWidth}px` : props.menuItemWidth)};
  min-width: ${(props) => (typeof props.menuItemWidth === "number" ? `${props.menuItemWidth}px` : props.menuItemWidth)};
  height: ${(props) => (typeof props.menuItemHeight === "number" ? `${props.menuItemHeight}px` : props.menuItemHeight)};
  background-color: ${(props) => props.menuItemBackgroundColor};
  padding: ${(props) => props.menuItemPadding};
  font-size: ${(props) =>
    typeof props.menuItemFontSize === "number" ? `${props.menuItemFontSize}px` : props.menuItemFontSize};
  line-height: ${(props) =>
    typeof props.menuItemLineHeight === "number" ? `${props.menuItemLineHeight}px` : props.menuItemLineHeight};
  cursor: ${(props) => (props.tabOption ? "default" : "pointer")};
  color: ${(props) => props.menuItemColor};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${(props) => !props.tabOption && props.menuItemHoverBackgroundColor};
    color: ${(props) =>
      !props.tabOption && (props.menuItemHoverColor ? props.menuItemHoverColor : props.menuItemColor)};
  }

  border-top: ${(props) => (props.tabOption ? `1px solid ${theme.NEUTRAL300}` : "none")};

  opacity: ${(props) => props.disabled && 0.5};
`;

const SearchInput = styled.input.attrs({
  autoFocus: true,
  type: "text",
  placeholder: "Search...",
})`
  width: 100%;
  padding: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  font-size: 14px;
  outline: none;

  ::placeholder {
    color: ${theme.NEUTRAL400};
  }
`;

export default PhoenixPopupOptions;
