import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { MixpanelActions } from "../../../../services/mixpanel";
import { theme } from "../../../../utils/theme";
import { appToast } from "../../../../utils/toast";
import { CurrentLeadType } from "../../../../types";

const FavoritedStar = ({
  leadId,
  parentType,
  dataFavoritedStatus,
}: {
  // lead id to favorite or unfavorite
  leadId: string;

  // parent type of the lead (where the lead was brought in from EX: ActiveQueue)
  parentType: string;

  // computed lead data
  dataFavoritedStatus?: boolean;
}) => {
  // local toggle state initialized from fetched data
  const [favoriteToggled, setFavoriteToggled] = useState(dataFavoritedStatus);

  // anytime we get fresh data update the local toggle state
  useEffect(() => {
    if (dataFavoritedStatus === undefined) return;
    if (dataFavoritedStatus === favoriteToggled) return;

    setFavoriteToggled(dataFavoritedStatus);
  }, [dataFavoritedStatus]);

  // favorite lead mutation
  const FAVORITE_LEAD = gql`
    mutation favoriteLead($lead_id: String!) {
      favoriteLead(lead_id: $lead_id) {
        id
        favorited
      }
    }
  `;

  const [favoriteLead, { loading: favoriteLoading, error: favoriteError }] = useMutation(FAVORITE_LEAD, {
    variables: {
      lead_id: leadId,
    },
    async onCompleted({ favoriteLead }) {
      if (!favoriteLead) {
        appToast("Error favoriting lead. Something went wrong.");
        return;
      }
      appToast("Lead favorited!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      favoriteLead: {
        id: leadId,
        favorited: true,
        __typename: "Lead",
      },
    },
    refetchQueries: ["fetchFavoritedLeads"],
  });

  // unfavorite lead mutation

  const UNFAVORITE_LEAD = gql`
    mutation unfavoriteLead($lead_id: String!) {
      unfavoriteLead(lead_id: $lead_id) {
        id
        favorited
      }
    }
  `;

  const [unfavoriteLead, { loading: unfavoriteLoading, error: unfavoriteError }] = useMutation(UNFAVORITE_LEAD, {
    variables: {
      lead_id: leadId,
    },
    async onCompleted({ unfavoriteLead }) {
      if (!unfavoriteLead) {
        appToast("Error unfavoriting lead. Something went wrong.");
        return;
      }

      appToast("Lead unfavorited!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      unfavoriteLead: {
        id: leadId,
        favorited: false,
        __typename: "Lead",
      },
    },
    refetchQueries: ["fetchFavoritedLeads"],
  });

  const LoadingState = () => {
    return <AiFillStar color={theme.NEUTRAL200} size={14} />;
  };

  if (favoriteLoading || unfavoriteLoading) return <LoadingState />;

  return (
    <>
      {favoriteToggled ? (
        <AiFillStar
          color={theme.icon.brand.default}
          size={18}
          cursor="pointer"
          onClick={() => {
            unfavoriteLead();
            MixpanelActions.track("Lead Card", {
              type: "favorite toggled",
              id: leadId,
              source: parentType,
            });
            setFavoriteToggled(false);
          }}
        />
      ) : (
        <AiOutlineStar
          color={theme.icon.brand.default}
          size={18}
          cursor="pointer"
          onClick={() => {
            favoriteLead();
            MixpanelActions.track("Lead Card", {
              type: "favorite toggled",
              id: leadId,
              source: parentType,
            });
            setFavoriteToggled(true);
          }}
        />
      )}
    </>
  );
};

export { FavoritedStar };
