import React, { useContext, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../../utils/theme";
import { ModalContext } from "../../../context";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon } from "../../UI/Phoenix";
import { AppText, DarkDiv, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { eye_off, reorder, xIcon } from "../../../images/NewDesign";
import { Segment } from "../../../types/SequenceTypes";
import { defaultSegments } from "../../../utils/sequence-reporting";
import { cloneDeep, set } from "lodash";
import { SEQUENCE_REPORTING_CHARTS } from "../../../utils/sequence-reporting";

interface EditSectionModalProps {
  selectedSegment: Segment | null;
  segments: Segment[];
  setSegments: React.Dispatch<React.SetStateAction<Segment[]>>;
}

export const EditSectionModal: React.FC<EditSectionModalProps> = ({ selectedSegment, segments, setSegments }) => {
  const { setShowEditSectionModal } = useContext(ModalContext);

  const [localSegments, setLocalSegments] = useState(segments);
  const localSelectedSegment: Segment | undefined = useMemo(
    () => localSegments?.find((segment) => segment.type === selectedSegment?.type),
    [localSegments],
  );

  const missingCharts = useMemo(() => {
    // used to check if a new chart is available that isnt in the user's local storage
    const defaultSelectedSegment = defaultSegments.find((segment) => segment.type === selectedSegment?.type);
    return defaultSelectedSegment?.charts?.filter((chart) => !localSelectedSegment?.charts?.includes(chart)) || [];
  }, [localSelectedSegment]);

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || !source) return;

    const localSegmentsCopy = cloneDeep(localSegments);
    const localSelectedSegmentCopy = localSegmentsCopy?.find((segment) => segment.type === selectedSegment?.type);
    if (!localSelectedSegmentCopy?.charts) return;
    localSelectedSegmentCopy.charts = [...localSelectedSegmentCopy.charts, ...missingCharts];

    const [splicedChart] = localSelectedSegmentCopy.charts.splice(source.index, 1);
    localSelectedSegmentCopy.charts.splice(destination.index, 0, splicedChart);

    setLocalSegments(localSegmentsCopy);
  };

  const handleCheck = (chart: string) => {
    const localSegmentsCopy = cloneDeep(localSegments);
    const localSelectedSegmentCopy = localSegmentsCopy?.find((segment) => segment.type === selectedSegment?.type);
    if (localSelectedSegmentCopy?.selected_charts?.includes(chart)) {
      localSelectedSegmentCopy.selected_charts = localSelectedSegmentCopy?.selected_charts?.filter(
        (c: string) => c !== chart,
      );
    } else {
      localSelectedSegmentCopy?.selected_charts?.push(chart);

      // true if a new chart has been created that isnt in the user's local storage
      if (!localSelectedSegmentCopy?.charts?.includes(chart)) {
        localSelectedSegmentCopy?.charts?.push(chart);
      }
    }
    setLocalSegments(localSegmentsCopy);
  };

  if (!selectedSegment) return null;

  return (
    <>
      <DarkDiv noFade />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            color={theme.PRIMARY500}
            size={24}
            pointer
            onClick={() => {
              setShowEditSectionModal(false);
            }}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            Edit {selectedSegment?.type} Section
          </AppText>
        </ModalHeader>

        <ModalBody direction="column" gap={24}>
          <FlexDiv
            gap={8}
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSegments(segments.filter((segment) => segment.type !== selectedSegment?.type));
              setShowEditSectionModal(false);
            }}
          >
            <PhoenixIcon svg={eye_off} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} size={12} pointer />
            <AppText fontSize={10} fontWeight={500} lineHeight={14} color={theme.PRIMARY500}>
              Hide This Section
            </AppText>
          </FlexDiv>

          <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
            Choose which metrics you'd like to include. You can also reorder them to suit your organization's needs.
          </AppText>

          <DragDropContainer>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="dnd-segment-edit-container" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {localSelectedSegment?.charts &&
                      [...localSelectedSegment.charts, ...missingCharts]?.map((chartId: string, index: number) => (
                        <Draggable
                          draggableId={`segment-chart-${chartId}`}
                          index={index}
                          key={`segment-charts-${chartId}`}
                        >
                          {(provided, snapshot) => (
                            <DragDropItem gap={16} ref={provided.innerRef} {...provided.draggableProps}>
                              <PhoenixIcon svg={reorder} size={16} variant="neutral" {...provided.dragHandleProps} />
                              <FlexDiv>
                                <PhoenixCheckbox
                                  checked={localSelectedSegment?.selected_charts?.includes(chartId)}
                                  onChange={() => handleCheck(chartId)}
                                />
                                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                  {
                                    (SEQUENCE_REPORTING_CHARTS as any)[localSelectedSegment?.type]?.find(
                                      (chart: any) => chart.id === chartId,
                                    )?.title
                                  }
                                </AppText>
                              </FlexDiv>
                            </DragDropItem>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DragDropContainer>
        </ModalBody>

        <ModalFooter justify="space-between">
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            buttonTextFontSize={10}
            uppercase
            onClick={() => {
              setShowEditSectionModal(false);
            }}
          >
            Cancel
          </PhoenixAppButton>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            buttonTextFontSize={10}
            uppercase
            disabled={!localSelectedSegment?.selected_charts?.length}
            onClick={() => {
              setShowEditSectionModal(false);
              !!localSegments && setSegments(localSegments);
            }}
          >
            Update
          </PhoenixAppButton>
        </ModalFooter>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 24px 40px;

  max-height: 80vh;
  min-height: 70vh;
  margin-bottom: auto;

  overflow-y: overlay;
  overflow-x: hidden;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;

const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const DragDropItem = styled(FlexDiv)`
  margin-bottom: 24px;
`;
