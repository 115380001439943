import * as Sentry from "@sentry/react";
import { loggedInUser } from "../apollo/cache";

/**
 * @function setSentry
 * Sets Sentry user and context properties
 */
export const setSentry = () => {
  const user = loggedInUser();
  if (!user || !user.email) {
    return;
  }
  Sentry.setUser({
    id: user?.id,
    email: user.email,
    username: user.email,
  });
  Sentry.setContext("profile", {
    ...user,
  });
};
