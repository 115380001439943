import styled from "styled-components";
import * as React from "react";
import { Loading, AppSidebarCard, AppErrorText } from "../../UI";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { LeadCardV2 } from "../DashboardSideBarSegments";
import { CORE_LEAD_CARD_DATA } from "../../../apollo/fragments";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFlags } from "launchdarkly-react-client-sdk";

const FETCH_RECENT_CALLS = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchRecentCalls($skip: Int, $take: Int) {
    fetchRecentCalls(skip: $skip, take: $take) {
      id
      lead_id
      type
      lead {
        id
        ...CoreLeadCardData
      }
    }
  }
`;
interface CurrentLeadProps {
  closeOtherModals?: any;
}

const RecentDialsComponent: React.FC<CurrentLeadProps> = ({ closeOtherModals }) => {
  const { limitUserInfiniteScroll } = useFlags();
  //Local State
  const [skip, setSkip] = useState(0);
  const [recentDials, setRecentDials] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  //Queries
  const { data: dataRecents, loading: loadingRecents, error: errorRecents, fetchMore: fetchMoreRecents } = useQuery(
    FETCH_RECENT_CALLS,
    {
      fetchPolicy: "network-only",
      variables: {
        skip: skip,
        // If you change the take value, you must change the length check below to match as well as setSkip in handleShowMore.
        take: 4,
      },
      onCompleted() {
        const newList = [...recentDials, ...dataRecents.fetchRecentCalls].reduce(
          (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
          [],
        );
        if (
          dataRecents.fetchRecentCalls.length < 4 ||
          // temp fix for performance issue for a specific user
          // should be fixed using virtualized list
          (limitUserInfiniteScroll && recentDials?.length >= 26)
        ) {
          setHasMore(false);
        }
        setRecentDials(newList);
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  //Custom Functions
  const handleShowMore = () => {
    setSkip(skip + 4);
    fetchMoreRecents({
      variables: {
        skip: skip,
      },
    });
  };

  if (loadingRecents && !!!recentDials.length) return <Loading />;
  if (errorRecents) return <AppErrorText>Error loading recent calls</AppErrorText>;
  if (recentDials.length <= 0) return <></>;

  return (
    <AppSidebarCard showExpand trackMixpanel title={"Recent Dials"} width={430}>
      <ScrollDiv id="recent-scroll-div">
        <InfiniteScroll
          dataLength={recentDials.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="recent-scroll-div"
        >
          {recentDials?.map((item: any, index: number) => (
            <BorderDiv borderTop={index > 0} key={`recent-${index}-${item.lead_id}`}>
              <LeadCardV2
                leadData={item.lead}
                parentType={"recentDials"}
                intent={item.lead.next_lead_intent}
                closeOtherModals={closeOtherModals}
              />
            </BorderDiv>
          ))}
        </InfiniteScroll>
      </ScrollDiv>
    </AppSidebarCard>
  );
};

interface BorderProps {
  borderTop?: boolean;
}

const BorderDiv = styled.div<BorderProps>`
  border-top: 1px solid ${(props) => (props.borderTop ? theme.NEUTRAL200 : "transparent")};
  padding: 0px;
  margin: 0px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  height: 400px;
`;

export { RecentDialsComponent };
