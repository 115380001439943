import { InMemoryCache, Reference } from "@apollo/client";
import { LoggedInUserType } from "../types";
import { OptionItem } from "../types/genericTypes";
import {
  DEFAULT_MRR_LABEL,
  PERSIST_VERSION,
  TOKEN_PERSIST_KEY,
  TZ_OVERRIDE_PERSIST_KEY,
  USER_PERSIST_KEY,
} from "../utils/variables";

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar();
        },
        tzOverride() {
          return tzOverride();
        },

        isOnCall() {
          return currentCallState().onCall;
        },
        loggedInUser() {
          return loggedInUser();
        },
        isUserRep() {
          return loggedInUser().role === "AE" || loggedInUser().role === "SDR" || loggedInUser().role === "PS";
        },
        getMrrLabel() {
          return loggedInUser()?.organization?.mrr_label || DEFAULT_MRR_LABEL;
        },
        showLeadSourceToUser() {
          return loggedInUser()?.organization?.showLeadSourceToUser ?? true;
        },
        showNoContactButtonShortcut() {
          return loggedInUser()?.organization?.show_nocontact_button ?? false;
        },

        showCalendarOptions() {
          return (
            // OPS-6945 override the org setting for showing calendar integrations on FE to a specific user
            (loggedInUser()?.always_show_calendar_options ||
              // main check for showing calendar integrations (by org)
              loggedInUser()?.organization?.show_calendar_options) ??
            true
          );
        },
        showAutoDialer() {
          return loggedInUser()?.organization?.auto_dial ?? false;
        },
        canDialCustomer() {
          return loggedInUser()?.organization?.enable_rep_to_connect_customers ?? false;
        },
        canTransferExternal() {
          return (
            (loggedInUser()?.organization?.allow_external_transfer && loggedInUser().allow_external_transfer) ?? false
          );
        },
        launches: {
          keyArgs: false,
          merge(existing, incoming) {
            let launches: Reference[] = [];
            if (existing && existing.launches) {
              launches = launches.concat(existing.launches);
            }
            if (incoming && incoming.launches) {
              launches = launches.concat(incoming.launches);
            }
            return {
              ...incoming,
              launches,
            };
          },
        },
      },
    },
  },
});

export const isLoggedInVar = cache.makeVar<boolean>(!!localStorage.getItem(TOKEN_PERSIST_KEY));
export const loggedInUser = cache.makeVar<LoggedInUserType>(JSON.parse(localStorage.getItem(USER_PERSIST_KEY) || "{}"));

export const isUserRep = () => ["AE", "SDR"].includes(loggedInUser().role || "");

interface CurrentCallState {
  onCall: boolean;
  dispositionLogged: boolean;
  alternate_contact?: boolean;
  release_lead?: boolean;
}

/**
 * Used to determine if user should be in active dialer state
 *
 * alternate_contact - if alternate_contact checkbox is selected in dialer
 *
 * onCall - if rep is on a call
 *
 * dispositionLogged - if a disposition has been logged
 *
 * release_lead - if the lead should be released after disposition
 */
export const currentCallState = cache.makeVar<CurrentCallState>({
  onCall: false,
  dispositionLogged: false,
  alternate_contact: false,
  release_lead: false,
});
/**
 * List of input devices for Twilio
 */
export const inputDevices = cache.makeVar<OptionItem[]>([]);
/**
 * List of output devices for Twilio
 */
export const outputDevices = cache.makeVar<OptionItem[]>([]);
/**
 * determines if Twilio is muted
 */
export const twilioMuted = cache.makeVar<boolean>(false);
/**
 * overrides timezone contraints
 */
export const tzOverride = cache.makeVar<boolean>(!!localStorage.getItem(TZ_OVERRIDE_PERSIST_KEY));
