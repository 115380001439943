import { AppText, Loading, NewAppSelect } from "../../UI";
import { theme } from "../../../utils/theme";
import * as React from "react";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { CallContext } from "../../../context";
import { gql, useQuery } from "@apollo/client";
import { OutsideEvent } from "../../Dumb";
import { formatPhoneNumber } from "../../../utils/format";
import { IoIosArrowDown } from "react-icons/io";
import { sortedCountryCodesData } from "../../../static/countries";
import { AiFillCaretDown } from "react-icons/ai";
import { PhoenixMultiSelect } from "../../UI/Phoenix";

const FETCH_DIAL_NUMBERS = gql`
  query fetchDialNumbers($lead_phone_number: String!) {
    fetchDialNumbers(lead_phone_number: $lead_phone_number)
  }
`;

interface WillDialAsProps {
  leadPhoneNumber: string;
  leadId: string;
}

export const AlternateContactDialAs: React.FC<WillDialAsProps> = ({ leadPhoneNumber, leadId }) => {
  const { dialAsNumber, setDialAsNumber } = useContext(CallContext);

  const { data: dataOptions, loading: loadingOptions, error: errorOptions, refetch: refetchOptions } = useQuery(
    FETCH_DIAL_NUMBERS,
    {
      fetchPolicy: "no-cache",
      variables: {
        lead_phone_number: leadPhoneNumber,
      },
    },
  );

  useEffect(() => {
    //sets primary number to Context API
    if (!!dataOptions?.fetchDialNumbers?.length) {
      setDialAsNumber(dataOptions?.fetchDialNumbers[0] ?? "");
    }
  }, [dataOptions]);

  useEffect(() => {
    refetchOptions({ lead_phone_number: leadPhoneNumber });
  }, [leadPhoneNumber, leadId]);

  // if (loadingOptions) return <Loading />;

  return (
    <ContainerDiv>
      <AppText style={{ fontWeight: 600, marginTop: "40px", marginBottom: "8px", width: "400px" }}>
        Will Dial As
      </AppText>
      <PhoenixMultiSelect
        name="alternate_contact_dial_as"
        isMulti={false}
        isClearable={false}
        options={dataOptions?.fetchDialNumbers?.map((num: string) => ({
          label: formatPhoneNumber(num),
          value: num,
        }))}
        onChange={(e: any) => {
          setDialAsNumber(e.value);
        }}
        value={{ label: formatPhoneNumber(dialAsNumber), value: dialAsNumber }}
        width={400}
        minHeight={42}
        marginBottom={false}
      />
    </ContainerDiv>
  );
};

const NumberSelect = styled.div`
  height: 40px;
  width: 400px;
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL300};
  padding-right: 8px;
  padding-left: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  letter-spacing: 0.2px;
`;

const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 12px;
  line-height: 18px;
  padding-left: 10px;
  font-weight: 400;
  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.PRIMARY300};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }
  :hover {
    background-color: rgba(62, 101, 255, 0.1);
  }
`;

const OptionsDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 44px;
  width: 400px;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 3;
`;

const ContainerDiv = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;
