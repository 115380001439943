import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import { cloneDeep } from "lodash";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { SingleDatePicker } from "react-dates";
import styled from "styled-components";
import * as Yup from "yup";
import { MRR_LABEL, SHOW_LEAD_SOURCE_TO_USER, SHOW_NEXT_ACTION } from "../../apollo/query";
import { findCountryWithCode, getExtraCountryCodes, sortedCountryCodesData } from "../../static/countries";
import { isValidEmail, validateZipCode } from "../../utils/format";
import { getLocalStorage, recursiveObjectTrim } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FormSelectField } from "../Field";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import { PhoenixMultiSelectField } from "../Field/Phoenix/PhoenixMultiSelectField";
import { VALIDATE_PHONE_NUMBER } from "../Segments/CallSegments/ConfirmLeadDetails";
import { StateDropdown } from "../Segments/LeadCard/StateDropdown";
import { AppErrorText, AppText, Loading } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { ReactDatesWrapper } from "../UI/ReactDatesWrapper";
import { SYSTEM_FIELD_CHAR_LIMIT, SYSTEM_FIELD_EMAIL_CHAR_LIMIT } from "./../../utils/format";
import { useFlags } from "launchdarkly-react-client-sdk";
import { loggedInUser } from "src/apollo/cache";

interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  lead_id: string;
  customHeight?: boolean;
}

interface MyFormikProps {
  first_name?: string;
  last_name?: string;
  business_name: string;
  // primary_phone_number: string;
  primary_email: string;
  lead_source: string;
  industry: string;
  sub_industry: string;
  channel: string;
  city: string;
  // state: string;
  state: string;
  address: string;
  zip: string;
  country_code: { label: string; value: { iso2: string; code: string } };
  mrr?: number;
  current_close_date?: string;
}

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: String!
    $first_name: String
    $last_name: String
    $business_name: String
    # $primary_phone_number: String
    $primary_email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $address: String
    $zip: String
    $country_code: String
    $mrr: Float
    $current_close_date: DateTime
  ) {
    updateLead(
      id: $id
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      # primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      address: $address
      zip: $zip
      country_code: $country_code
      mrr: $mrr
      current_close_date: $current_close_date
    ) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
    }
  }
`;

const optionsLoading = [
  {
    label: "Loading...",
    value: "",
  },
];

const channel_options = [
  {
    label: "Inbound",
    value: "Inbound",
  },
  {
    label: "Outbound",
    value: "Outbound",
  },
];

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      full_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      business_name
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
      computed_mrr
      current_close_date
    }
  }
`;

const UpdateLeadDataComponent: React.FC<DisappearingDivProps> = ({ close, lead_id, customHeight = false }) => {
  const { data: orgData } = useQuery(SHOW_NEXT_ACTION);

  const showNextAction = orgData?.showNextAction;

  //react dates
  const [focused, setFocused] = useState(null as any);

  const [selectedCountryISO2, setSelectedCountryISO2] = useState("US");

  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  const [updateLead, { loading }] = useMutation(UPDATE_LEAD, {
    async onCompleted({ updateLead }) {
      console.log("updateLead: ", updateLead);
      if (!updateLead) {
        appToast("Error updating lead. Something went wrong.");
        return;
      }
      appToast("Information updated!");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateLead GraphQL Error: ${message}`,
      });
      console.log("Error updating lead: ", message);
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact", "fetchFilteredLeadsInQueueV2"],
  });

  const { data: mrrLabel, loading: mrrLoading, error: mrrError } = useQuery(MRR_LABEL);

  const { data: leadsourceData, loading: leadsourceLoading, error: leadsourceError } = useQuery(
    GET_POSSIBLE_LEAD_SOURCES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const { data: industriesData, loading: industriesLoading, error: industriesError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataLead, loading: loadingLead, error: errorLead } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: lead_id },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },

    onCompleted({ fetchLead }) {
      setSelectedCountryISO2(fetchLead?.country);
    },
  });

  const [validatePhoneNumber, { loading: phoneLoading, data: phoneData, error: phoneError }] = useMutation(
    VALIDATE_PHONE_NUMBER,
  );

  if (leadsourceError) return <p>Error loading Lead Sources</p>;
  if (industriesError) return <p>Error loading Lead Sources</p>;

  if (loadingLead || !!!dataLead?.fetchLead) return <Loading />;
  if (errorLead) return <AppErrorText>Error loading Lead</AppErrorText>;

  const returnSubIndustryOptions = (industry: string) => {
    if (industriesLoading) {
      return optionsLoading;
    }
    if (!!industry) {
      const found = industriesData.fetchIndustryOptions.slice().filter((item: any) => item.label === industry);
      if (!!found.length) {
        return found[0].sub_industries?.map((ls: string) => ({ label: ls, value: ls }));
      } else {
        return [{ label: "Please select a valid primary industry", value: "" }];
      }
    } else {
      return [{ label: "Select Primary Industry", value: "" }];
    }
  };

  const newLeadSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .notRequired()
      .nullable()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    last_name: Yup.string()
      .trim()
      .notRequired()
      .nullable()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    business_name: Yup.string()
      .required("Business Name is required")
      .trim()
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    primary_email: Yup.string()
      .notRequired()
      .nullable()
      .max(SYSTEM_FIELD_EMAIL_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_EMAIL_CHAR_LIMIT} characters or less`)
      .test("email", "Must be a valid email", (value) => {
        if (!!value) {
          return isValidEmail(value) ?? true;
        }
        return true;
      }),
    lead_source: Yup.string().trim().notRequired().nullable(),
    industry: Yup.string().trim().notRequired().nullable(),
    sub_industry: Yup.string().trim().notRequired().nullable(),
    channel: Yup.string().trim().notRequired().nullable(),
    city: Yup.string().trim().notRequired().nullable(),
    state: Yup.string().required().trim(),
    mrr: Yup.string().trim().notRequired().nullable(),
    current_close_date: Yup.string().trim().notRequired().nullable(),
    address: Yup.string().trim().notRequired().nullable(),
    zip: Yup.string()
      .trim()
      .notRequired()
      .nullable()
      .test("valid zip", "Invalid zip code", (value) => {
        if (!value) return true;
        return validateZipCode(selectedCountryISO2).test(value) ?? true;
      }),

    country_code: Yup.object()
      .required()
      .shape({
        label: Yup.string(),
        value: Yup.object().shape({ iso2: Yup.string(), code: Yup.string() }),
      }),
  });
  
  const orgCountryCodeOptions = getLocalStorage("country_code_options", []);

  const defaultPlusExtraCountryOptions = [
    ...sortedCountryCodesData,
    ...getExtraCountryCodes(orgCountryCodeOptions),
  ].sort((a, b) => {
    if (a?.value?.iso2 === "US") return -1;
    if (b?.value?.iso2 === "US") return 1;
    return a?.label?.localeCompare(b?.label);
  });

  return (
    <Formik
      initialValues={{
        business_name: dataLead?.fetchLead?.business_name,
        primary_email: dataLead?.fetchLead?.primary_email,
        first_name: dataLead?.fetchLead?.first_name,
        last_name: dataLead?.fetchLead?.last_name,
        lead_source: dataLead?.fetchLead?.lead_source || "0",
        channel: dataLead?.fetchLead?.channel || "0",
        industry: dataLead?.fetchLead?.industry || "",
        sub_industry: dataLead?.fetchLead?.sub_industry || "",
        city: dataLead?.fetchLead?.city,
        address: dataLead?.fetchLead?.address,
        zip: dataLead?.fetchLead?.zip,
        mrr: dataLead?.fetchLead?.computed_mrr ? dataLead?.fetchLead?.computed_mrr : "",
        current_close_date: dataLead?.fetchLead?.current_close_date ? dataLead?.fetchLead?.current_close_date : "",
        country_code: {
          label: `${findCountryWithCode(dataLead?.fetchLead?.country) ?? "N/A"} (${
            dataLead?.fetchLead?.primary_phone_number_country_code ?? "1"
          })`,
          value: {
            iso2: dataLead?.fetchLead?.country ?? "US",
            code: dataLead?.fetchLead?.primary_phone_number_country_code ?? "1",
          },
        },
        state: dataLead?.fetchLead?.state,
      }}
      validationSchema={newLeadSchema}
      onSubmit={async (values) => {
        const val = cloneDeep({ ...values });
        recursiveObjectTrim(val);

        await updateLead({
          variables: {
            id: lead_id,
            business_name: val.business_name,
            primary_email: val.primary_email,
            first_name: val.first_name,
            last_name: val.last_name,
            city: val.city,
            state: val.state ?? undefined,
            country: val.country_code?.value?.iso2 ?? undefined,
            country_code: val.country_code?.value?.code ?? undefined,
            industry: val.industry === "" ? null : val.industry,
            sub_industry: val.sub_industry === "" ? null : val.sub_industry,
            lead_source: val.lead_source === "0" ? null : val.lead_source,
            channel: val.channel === "0" ? null : val.channel,
            address: val.address,
            zip: val.zip,
            mrr: val.mrr ? parseFloat(Number.isInteger(val.mrr) ? val.mrr + ".0" : val.mrr.toString()) : 0,
            current_close_date: val.current_close_date ? val.current_close_date : undefined,
          },
        });
      }}
    >
      {({ submitForm, values, setFieldValue, isValid, dirty, errors }: FormikProps<MyFormikProps>) => {
        return (
          <>
            <TitleDiv>
              <PopupTitle>Edit Contact Info</PopupTitle>
            </TitleDiv>
            <ScrollingDiv customHeight={customHeight}>
              <FieldDiv>
                <PopupInputLabel>
                  Primary Business Name <span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <PhoenixInputField name="business_name" />

                <PopupInputLabel>Primary Email</PopupInputLabel>
                <PhoenixInputField name="primary_email" />

                <FirstLastDiv>
                  <div style={{ width: "50%" }}>
                    <PopupInputLabel>First Name</PopupInputLabel>
                    <PhoenixInputField name="first_name" />
                  </div>
                  <div style={{ width: "50%" }}>
                    <PopupInputLabel>Last Name</PopupInputLabel>
                    <PhoenixInputField name="last_name" />
                  </div>
                </FirstLastDiv>

                <FirstLastDiv>
                  <div style={{ width: "50%" }}>
                    <PopupInputLabel>Address</PopupInputLabel>
                    <PhoenixInputField name="address" />
                  </div>
                  <div style={{ width: "50%" }}>
                    <PopupInputLabel>Zip Code</PopupInputLabel>
                    <PhoenixInputField name="zip" />
                  </div>
                </FirstLastDiv>

                <PopupInputLabel>City</PopupInputLabel>
                <PhoenixInputField name="city" />

                <PopupInputLabel>
                  Country <span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <PhoenixMultiSelectField
                  isMulti={false}
                  name="country_code"
                  options={defaultPlusExtraCountryOptions}
                  onChange={(e: any) => {
                    setFieldValue("country_code", !!e?.value ? { label: e?.label, value: e?.value } : {});
                    setSelectedCountryISO2(e?.value?.iso2);
                  }}
                />
                <PopupInputLabel>
                  State/Province <span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <StateDropdown
                  callback={setFieldValue}
                  country_code={values.country_code?.value?.iso2}
                  current_value={values.state}
                />

                <PopupInputLabel>Industry</PopupInputLabel>
                <PhoenixMultiSelectField
                  isMulti={false}
                  name="industry"
                  value={!!values.industry ? { label: values.industry, value: values.industry } : {}}
                  options={
                    industriesLoading
                      ? optionsLoading
                      : industriesData.fetchIndustryOptions?.map((ls: any) => ({ label: ls.label, value: ls.label }))
                  }
                  onChange={(e: any) => {
                    setFieldValue(`industry`, !!e?.value ? e?.value : "");
                    setFieldValue(`sub_industry`, "");
                  }}
                />

                <PopupInputLabel>Sub-Industry</PopupInputLabel>
                <PhoenixMultiSelectField
                  isMulti={false}
                  name="sub_industry"
                  value={!!values.sub_industry ? { label: values.sub_industry, value: values.sub_industry } : {}}
                  isDisabled={!values.industry}
                  options={returnSubIndustryOptions(values.industry)}
                  onChange={(e: any) => {
                    setFieldValue(`sub_industry`, !!e?.value ? e?.value : "");
                  }}
                />
                {showLeadSourceToUser?.showLeadSourceToUser && (
                  <>
                    <PopupInputLabel>Lead Source</PopupInputLabel>
                    <LeadSelect
                      name="lead_source"
                      textAlign="left"
                      allowSelectPlaceholder
                      options={
                        leadsourceLoading
                          ? optionsLoading
                          : leadsourceData.fetchLeadSourceOptions?.map((ls: any) => ({
                              label: ls.label,
                              value: ls.label,
                            }))
                      }
                      placeholder="Select"
                    />
                  </>
                )}
                <PopupInputLabel>Channel (Source Type)</PopupInputLabel>
                <LeadSelect
                  name="channel"
                  textAlign="left"
                  allowSelectPlaceholder
                  options={channel_options}
                  placeholder="Select"
                />

                <>
                  <PopupInputLabel>{mrrLabel?.getMrrLabel}</PopupInputLabel>
                  <PhoenixInputField name="mrr" />
                  <PopupInputLabel>Current Close Date</PopupInputLabel>
                  <ReactDatesWrapper>
                    <SingleDatePicker
                      openDirection={"up"}
                      id="edit-details-current-close-date"
                      required
                      date={moment(values?.current_close_date || undefined)}
                      onDateChange={(date) => setFieldValue("current_close_date", date)}
                      focused={focused}
                      onFocusChange={({ focused }) => setFocused(focused)}
                      numberOfMonths={1}
                      showDefaultInputIcon
                      inputIconPosition={"after"}
                    />
                  </ReactDatesWrapper>
                </>
              </FieldDiv>
            </ScrollingDiv>
            <SubmitDiv customHeight={customHeight}>
              <NewAppButton variant={"secondary"} onClick={close}>
                CANCEL
              </NewAppButton>
              <NewAppButton
                variant={"primary"}
                onClick={() => {
                  dirty ? submitForm() : close();
                }}
                disabled={loading}
              >
                {loading ? <Loading color={theme.WHITE_COLOR} /> : "SAVE"}
              </NewAppButton>
            </SubmitDiv>
          </>
        );
      }}
    </Formik>
  );
};

interface CustomHeightProps {
  customHeight: boolean;
}

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;
  /* bottom: ${(props) => (props.customHeight ? "0px" : "20px")}; */
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  /* margin-bottom: 32px; */
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FieldDiv = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 24px;
`;

const FirstLastDiv = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

const LeadSelect = styled(FormSelectField)`
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const PopupInputLabel = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 90px;
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
`;

export { UpdateLeadDataComponent };
