import styled from "styled-components";
import * as React from "react";
import { theme } from "../../utils/theme";
import { useMutation, gql, useQuery, MutationFunctionOptions, FetchResult, QueryLazyOptions } from "@apollo/client";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { SelectedContactType, CurrentLeadType } from "../../types";
import { LeadCardV2 } from "../Segments/DashboardSideBarSegments";
import { OutsideEvent } from "../Dumb";
import { ModalContext } from "../../context";
import { useFlags } from "launchdarkly-react-client-sdk";

interface SearchItemProps {
  setSelectedContact: (selectedContact: SelectedContactType) => void;
  data: CurrentLeadType;

  disableOutsideEvent: boolean;
  getLead?: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
  isSearchDashboard: boolean;
}

const SearchItem: React.FC<SearchItemProps> = ({
  setSelectedContact,
  data,

  disableOutsideEvent,
  getLead,
  isSearchDashboard,
}) => {
  const { openCustomFieldsModal } = useContext(ModalContext);
  const [showCurrentLead, setShowCurrentLead] = useState(false);

  const changeShowCurrentLead = () => {
    setShowCurrentLead(!showCurrentLead);
  };

  const closeLead = () => {
    setShowCurrentLead(false);
  };

  return (
    <SearchItemContainer isSearchDashboard={!!isSearchDashboard}>
      <OutsideEvent onOutsideClick={closeLead} disabled={disableOutsideEvent}>
        <div style={{ width: "441px" }}>
          <CallbackCard>
            <LeadCardV2
              hideActionButtons
              leadData={data}
              showLogNonCallActivity
              closeOtherModals={() => {
                closeLead();
              }}
              showEventTypeCard
            />
          </CallbackCard>
        </div>
      </OutsideEvent>
    </SearchItemContainer>
  );
};

const CallbackCard = styled.div`
  width: 400px;
  height: auto;
  border-radius: 4px;
  margin: 0px;
  /* line-height: 1; */
  padding: 0px;
  background-color: ${theme.WHITE_COLOR};
  /* display: grid;
  grid-template-areas:
    "name phone"
    "company email";
  grid-template-rows: 21px 14px;
  grid-template-columns: 180px 148px; */
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL200};
  :hover {
    cursor: pointer;
  }
`;

interface Dashboard {
  isSearchDashboard: boolean;
}

const SearchItemContainer = styled.div<Dashboard>`
  z-index: ${(props) => (props.isSearchDashboard ? 4 : 0)};
`;

export { SearchItem };
