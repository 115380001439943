import { gql, useLazyQuery } from "@apollo/client";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { formatPhoneNumber } from "../../../utils/format";
import { theme } from "../../../utils/theme";
import { AppText } from "../../UI";
import { PhoenixMultiSelect } from "../../UI/Phoenix";

const FETCH_DIAL_NUMBERS = gql`
  query fetchDialNumbers($lead_phone_number: String, $lead_id: String) {
    fetchDialNumbers(lead_phone_number: $lead_phone_number, lead_id: $lead_id)
  }
`;

interface PhoneNumberProp {
  leadPhoneNumber: string;
  leadId: string;
  dialAsNumber: string | undefined;
  setDialAsNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const WillDialAsV2: React.FC<PhoneNumberProp> = ({ leadPhoneNumber, leadId, dialAsNumber, setDialAsNumber }) => {
  const [
    fetchDialNums,
    { data: dataOptions, loading: loadingOptions, error: errorOptions, refetch: refetchOptions },
  ] = useLazyQuery(FETCH_DIAL_NUMBERS, {
    fetchPolicy: "no-cache",
    onCompleted() {
      console.log("fetched: ", dataOptions);
      if (!!dataOptions?.fetchDialNumbers?.length) {
        setDialAsNumber(dataOptions?.fetchDialNumbers[0] ?? "");
      } else {
        setDialAsNumber("");
      }
    },
  });

  useEffect(() => {
    fetchDialNums({
      variables: {
        lead_phone_number: leadPhoneNumber || "",
        lead_id: leadId,
      },
    });
  }, [leadId, leadPhoneNumber]);

  return (
    <Container>
      <div style={{ width: "100%" }}>
        <PhoenixMultiSelect
          isMulti={false}
          name="dialAsNumber"
          options={dataOptions?.fetchDialNumbers?.map((item: any) => ({ label: formatPhoneNumber(item), value: item }))}
          placeholder={formatPhoneNumber(dialAsNumber)}
          onChange={(e: any) => setDialAsNumber(e.value)}
          value={{ label: `Dialing As: ${formatPhoneNumber(dialAsNumber)}`, value: dialAsNumber }}
          isClearable={false}
          marginBottom={false}
          error={false}
          minHeight={24}
          maxHeight={24}
          lineHeight="10px"
          ghostSelect
          ghostSelectAppThemeOverride={"Dark"}
          style={{ overflow: "hidden" }}
          width={190}
          height={22}
          removeMenuPadding
          pointer
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8;
`;

export { WillDialAsV2 };
