import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useContext, useEffect, useMemo } from "react";
import { LeadFilterContext } from "src/context";
import { getTitle } from "src/utils/format";
import { appToast } from "src/utils/toast";

export const FETCH_FILTER_LEADS = gql`
  query filterLeads(
    $search_text: String
    $lead_filter: LeadFilter
    $skip: Int!
    $take: Int!
    $order_by: String
    $ascending: Boolean!
    $my_leads: Boolean
    $lead_system_operators: LeadSystemOperators
  ) {
    filterLeads(
      search_text: $search_text
      lead_filter: $lead_filter
      skip: $skip
      take: $take
      order_by: $order_by
      ascending: $ascending
      my_leads: $my_leads
      lead_system_operators: $lead_system_operators
    )
  }
`;

export const EXPORT_FILTERED_LEAD = gql`
  mutation exportFilteredLead($lead_filter: LeadFilter, $search_text: String, $my_leads: Boolean) {
    exportFilteredLead(lead_filter: $lead_filter, search_text: $search_text, my_leads: $my_leads)
  }
`;

const FETCH_FILTERED_LEAD_COUNT = gql`
  query fetchFilteredLeadCount(
    $lead_filter: LeadFilter
    $my_leads: Boolean
    $search_text: String
    $lead_system_operators: LeadSystemOperators
  ) {
    fetchFilteredLeadCount(
      lead_filter: $lead_filter
      my_leads: $my_leads
      search_text: $search_text
      lead_system_operators: $lead_system_operators
    )
  }
`;

const useFetchFilterLeads = ({
  ascending,
  lead_filter,
  lead_system_operators,
  leadSort,
  mrrLabel,
  my_leads,
  onCompleted,
  search_text,
  skip,
  take,
}: {
  ascending: boolean;
  lead_filter: Record<string, any>;
  lead_system_operators: Record<string, any>;
  leadSort: string;
  mrrLabel: any;
  my_leads: boolean;
  onCompleted?: () => void;
  search_text: string;
  skip: number;
  take: number;
}) => {
  const { setLeadsFilterVariables } = useContext(LeadFilterContext);

  const filterVariables = useMemo(
    () => ({
      ascending,
      lead_filter,
      lead_system_operators,
      my_leads,
      order_by: leadSort,
      search_text,
      skip,
      take,
    }),
    [ascending, lead_filter, lead_system_operators, my_leads, leadSort, search_text, skip, take],
  );

  useEffect(() => {
    setLeadsFilterVariables(filterVariables);
  }, [filterVariables]);

  const { data: dataTable, loading: loadingTable, error: errorTable, refetch: refetchTable } = useQuery(
    FETCH_FILTER_LEADS,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      variables: {
        search_text,
        lead_filter,
        lead_system_operators,
        skip: skip * take,
        take: take,
        order_by: getTitle(leadSort, mrrLabel) ?? "Status",
        ascending,
        my_leads,
      },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({ filterLeads, ...args }) {
        onCompleted?.();
      },
    },
  );

  const { data: dataCount, loading: loadingCount, error: errorCount } = useQuery(FETCH_FILTERED_LEAD_COUNT, {
    variables: {
      search_text,
      lead_filter,
      my_leads,
      lead_system_operators,
    },
    fetchPolicy: "no-cache",
    partialRefetch: false,
    nextFetchPolicy: "no-cache",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [exportFilteredLead, { loading: loadingExport, error: errorExport }] = useMutation(EXPORT_FILTERED_LEAD, {
    variables: {
      lead_filter,
      search_text,
    },
    async onCompleted({ exportFilteredLead }) {
      console.log("exportFilteredLead: ", exportFilteredLead);
      if (!exportFilteredLead) {
        appToast("Error exporting leads");
        return;
      }
      appToast("Success! Leads been have emailed.");
    },
    onError({ message }) {
      appToast(`Error exporting: ${message}`);
      Sentry.captureEvent({
        message: `exportFilteredLead GraphQL Error: ${message}`,
      });
      console.log("Error in exportFilteredLead: ", message);
    },
  });

  return {
    dataCount,
    dataTable,
    errorCount,
    errorExport,
    errorTable,
    exportFilteredLead,
    loadingCount,
    loadingExport,
    loadingTable,
    refetchTable,
  };
};

export default useFetchFilterLeads;
