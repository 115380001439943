import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, Loading, AppErrorText } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../../Field";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { sortByMonthAndYear } from "../../../utils/sorts";
import { useHistory } from "react-router-dom";
import { appToast, successToast } from "../../../utils/toast";
import { iconNewCaretDown } from "../../../images";
import { loggedInUser } from "../../../apollo/cache";
import { values } from "lodash";
import ReactTooltip from "react-tooltip";
import { formatTime } from "../../../utils/format";
import { PhoenixIcon } from "../../UI/Phoenix";
import { info } from "../../../images/NewDesign";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

interface MyFormikProps {
  auto_dial: boolean;
  auto_call_interval: number;
}

const stepRestingRules = Yup.object().shape({
  auto_dial: Yup.boolean().notRequired(),
  auto_call_interval: Yup.number().required(),
});

const ORGANIZATION_DATA = gql`
  {
    fetchOrganization {
      id
      auto_dial
      auto_call_interval
    }
  }
`;

const UPDATE_ORGANIZATION_REST_RULE = gql`
  mutation updateOrganizationSettings($auto_call: Boolean!, $auto_call_interval: Int!) {
    updateOrganizationSettings(auto_call: $auto_call, auto_call_interval: $auto_call_interval) {
      id
      auto_dial
      auto_call_interval
    }
  }
`;

const StepCallPacing: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const history = useHistory();

  const short_resting_min = 0;
  const short_resting_max = 2 ** 32 - 1;
  const resting_min = 0;
  const resting_max = 2 ** 32 - 1;
  const skipped_cold_call_rest_min = 0;
  const skipped_cold_call_rest_max = 2 ** 32 - 1;

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    variables: {
      org_id: loggedInUser().organization_id,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateOrganizationSettings, { loading: loadingRules, error: errorRules }] = useMutation(
    UPDATE_ORGANIZATION_REST_RULE,
    {
      async onCompleted({ updateOrganizationSettings }) {
        if (!updateOrganizationSettings) {
          appToast("Error updating Rules of Engagement. Something went wrong.");
          return;
        }
        successToast("Updated Call Pacing");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateOrganizationSettings GraphQL Error: ${message}`,
        });
        console.log("Error in updateOrganizationSettings: ", message);
      },
    },
  );

  if (loadingOrg) return <Loading />;
  if (errorOrg) return <AppErrorText>Error loading data</AppErrorText>;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        auto_dial: dataOrg.fetchOrganization.auto_dial,
        auto_call_interval: dataOrg.fetchOrganization.auto_call_interval,
      }}
      validationSchema={stepRestingRules}
      onSubmit={async (values) => {
        await updateOrganizationSettings({
          variables: {
            auto_call: values.auto_dial,
            auto_call_interval: Number(values.auto_call_interval),
          },
        });
        if (isOnboarding === true) {
          history.push(`/onboarding?step=${step + 1}`);
        }
      }}
    >
      {({ dirty, isValid, submitForm, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        return (
          <DisappearingDiv>
            <LocalWrapper isOnboarding={isOnboarding}>
              <div style={{ maxWidth: 250 }}>
                <ReactTooltip
                  multiline
                  place="top"
                  effect="solid"
                  className="sib-tooltip"
                  css={{
                    lineHeight: 1.4,
                    textAlign: "center",
                    fontFamily: theme.PRIMARY_FONT,
                  }}
                  id="call-pacing"
                  backgroundColor={theme.PRIMARY800}
                  getContent={(dataTip) => (
                    <span
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "10px",
                        lineHeight: "14px",
                      }}
                    >
                      {dataTip}
                    </span>
                  )}
                />
              </div>
              {/* <TopAlignDiv> */}
              <TopContentDiv>
                {/* <SegmentTitle>Resting Rules</SegmentTitle> */}
                <SwitchDiv>
                  <Switch
                    onChange={(checked) => setFieldValue("auto_dial", checked)}
                    checked={values.auto_dial}
                    onColor={theme.PRIMARY500}
                    offColor={theme.NEUTRAL200}
                    height={32}
                    width={54}
                    handleDiameter={24}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                  <AppText style={{ fontSize: "14px", marginLeft: "16px" }}>Enable Call Pacing</AppText>
                </SwitchDiv>
                <LabelText>
                  Call Pacing Interval<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in seconds)</span>
                  <PhoenixIcon
                    svg={info}
                    variant="brand"
                    size={12}
                    data-tip="This populates a timer at the top of reps' Sellfire windows (that they can pause) that counts down starting 
                   from when they confirm a call result for their previous call. 
                   When the count down hits zero, Sellfire automatically dials the primary number on the lead in their Next Dial."
                    data-for="call-pacing"
                  />
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="auto_call_interval"
                    type="number"
                    max={skipped_cold_call_rest_max}
                    min={skipped_cold_call_rest_min}
                    value={values.auto_call_interval}
                    disabled={!values.auto_dial}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= skipped_cold_call_rest_max && value >= skipped_cold_call_rest_min) {
                        setFieldValue("auto_call_interval", value);
                      }
                    }}
                  />

                  <AbsDivTop
                    disabled={values.auto_call_interval >= skipped_cold_call_rest_max}
                    onClick={() =>
                      values.auto_call_interval < skipped_cold_call_rest_max &&
                      setFieldValue("auto_call_interval", Number(values.auto_call_interval) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.auto_call_interval <= skipped_cold_call_rest_min}
                    onClick={() =>
                      values.auto_call_interval > skipped_cold_call_rest_min &&
                      setFieldValue("auto_call_interval", Number(values.auto_call_interval) - 1)
                    }
                  />
                  <AbsDivTime>
                    <AppText style={{ lineHeight: "40px", opacity: 0.5, textAlign: "end" }}>
                      ({formatTime(values.auto_call_interval)})
                    </AppText>
                  </AbsDivTime>
                </RelDiv>
              </TopContentDiv>
              {/* {JSON.stringify(values)} */}
              {isOnboarding === true ? (
                <CenterContentDiv>
                  <OldSaveAndContinueButton disabled={!(dirty && isValid)} type="submit" onClick={submitForm}>
                    Save & Continue
                  </OldSaveAndContinueButton>
                </CenterContentDiv>
              ) : (
                <CenterDiv>
                  <SaveAndContinueButton
                    disabled={!(dirty && isValid)}
                    type="submit"
                    onClick={submitForm}
                    variant={"primary"}
                  >
                    Save Changes
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </LocalWrapper>
          </DisappearingDiv>
        );
      }}
    </Formik>
  );
};

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const OldSaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const CenterContentDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

interface CaretProps {
  disabled: boolean;
  bottom?: boolean;
}

const AbsDivTop = styled.div<CaretProps>`
  display: flex;
  background: url(${iconNewCaretDown}) no-repeat;
  background-position: 5px 13px;
  position: absolute;
  right: 10px;
  top: ${(props) => (props.bottom ? "20px" : "0px")};
  transform: ${(props) => (props.bottom ? "rotate(180deg)" : "rotate(0deg)")};
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
const AbsDivTime = styled.div`
  display: flex;
  position: absolute;
  right: 36px;
  top: 0px;
  width: fit-content;
  height: 20px;
`;

const LabelText = styled(AppText)`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NumberInput = styled.input`
  height: 40px;
  padding-left: 12px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 2px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;
interface IsOnboardingProp {
  isOnboarding?: boolean;
}

const LocalWrapper = styled.div<IsOnboardingProp>`
  width: 364px;
  margin: ${(props) => (props.isOnboarding ? "20px auto" : "0px")};
`;

const TopContentDiv = styled.div`
  width: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  /* margin: 22px 0px; */
  margin-bottom: 16px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SaveAndContinueButton = styled(NewAppButton)`
  /* margin: 20px auto; */
  /* border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500; */
  width: 155px;
  height: 48px;
`;

export { StepCallPacing };
