import { FieldArray, Formik, FormikProps } from "formik";
import React, { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText, Loading } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import * as Yup from "yup";
import { InputField } from "../../Field";
import { formatUSD, formatUSDRaw } from "../../../utils/format";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { GridFilterContext, ModalContext } from "../../../context/";
import { AppErrorText } from "../../UI/AppText";
import iconRestore from "../../../images/icon-refresh.svg";
import moment from "moment";
import { isUserRep, loggedInUser } from "../../../apollo/cache";
import { info } from "../../../images/NewDesign";
import { PhoenixIcon } from "../../UI/Phoenix";
interface RevenueForecastProps {
  // organizationLevel: any;
  setBottomFunnelRevenue: (bottomFunnelRevenue: number) => void;
  setTopFunnelRevenue: (topFunnelRevenue: number) => void;
}

interface MyFormikProps {
  probability_array: number[];
}

const callFeedbackSchema = Yup.object().shape({
  probability_array: Yup.array().of(Yup.number().max(100).min(0)),
});

const getMRRTooltip = (mrr_label: string) => {
  switch (mrr_label) {
    case "MRR + Setup Fee":
      return `The sum of all MRR (monthly recurring revenue) + Setup Fees (one-time, non-recurring upfront payments) attached to each lead in the Pipeline. If a user has not entered a specific MRR + Setup Fee for a lead, it will default to the value entered in "System Configuration > Field Manager > Default Revenue Forecast Value"`;
    case "Revenue Value":
      return `The sum of all Revenue Value (as defined by your organization) attached to each lead in the Pipeline. If a user has not entered a specific Revenue Value for a lead, it will default to the value entered in "System Configuration > Field Manager > Default Revenue Forecast Value"`;
    case "Total MRR":
    case "Monthly Revenue Value (MRR)":
    default:
      return `The sum of all MRR (monthly recurring revenue) attached to each lead in the Pipeline. If a user has not entered a specific MRR for a lead, it will default to the value entered in "System Configuration > Field Manager > Default Revenue Forecast Value."`;
  }
};

const FETCH_REVENUE_FORECAST = gql`
  query fetchRevenueForcast($pipelineManagementArg: PipelineManagementArg!, $organization_id: String) {
    fetchRevenueForcast(pipeline_management_arg: $pipelineManagementArg, organization_id: $organization_id) {
      forcasted_revenue
      pipeline_metric
      probability
      total_mrr
    }
  }
`;

const FETCH_ORGANIZATION = gql`
  query fetchRevenueForcast {
    fetchOrganization {
      id
      mrr_label
    }
  }
`;

const RevenueForecastTable: React.FC<RevenueForecastProps> = ({ setBottomFunnelRevenue, setTopFunnelRevenue }) => {
  const {
    gridFilter,
    repFilter,
    dateStart,
    dateEnd,
    dateLabel,
    currentSavedView,
    pipelineIgnoreCloseDatesComputed: pipelineIgnoreCloseDates,
    sharedViewOrgId,
  } = useContext(GridFilterContext);

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
  });

  const { data: dataForecast, loading: loadingForecast, error: errorForecast, refetch: refetchForecast } = useQuery(
    FETCH_REVENUE_FORECAST,
    {
      fetchPolicy: "network-only",
      // skip: userLevel.length <= 0,
      variables: {
        pipelineManagementArg: {
          lead_filter: {
            Channels: gridFilter.channels,
            LeadSources: gridFilter.lead_sources,
            LeadCreationSources: gridFilter.lead_creation_sources,
            primary_industries: gridFilter.industries,
            sub_industries: gridFilter.sub_industries,
            NextScheduledEventDays: gridFilter.next_scheduled_event.next_scheduled_event_days
              ? parseInt(gridFilter.next_scheduled_event.next_scheduled_event_days)
              : 0,
            NextScheduledEventTypes: gridFilter.next_scheduled_event.next_scheduled_event_types || [],
            sequences: gridFilter.sequences,
            custom_fields: gridFilter.custom_fields,
          },
          rep_filter: {
            team_ids: repFilter.team_ids,
            site_ids: repFilter.site_ids,
            roles: repFilter.roles,
            user_ids: isUserRep() ? [loggedInUser().id] : repFilter.user_ids,
          },
          date_filter: {
            date_range: dateLabel,
            lowerbound_date: !!pipelineIgnoreCloseDates
              ? undefined
              : !!dateStart
              ? `${moment(dateStart).toDate()}`
              : undefined,
            upperbound_date: !!pipelineIgnoreCloseDates
              ? undefined
              : !!dateEnd
              ? `${moment(dateEnd).toDate()}`
              : undefined,
          },
        },
        organization_id: sharedViewOrgId,
      },
      onCompleted: (dataForecast) => {
        const revenueForecast = dataForecast.fetchRevenueForcast
          ?.map((item: any) => item.probability)
          ?.map((value: number, index: number) => (value * totalMMR[index]) / 100);

        setTopFunnelRevenue(revenueForecast.slice(0, 4).reduce((partialSum: number, a: number) => partialSum + a, 0));
        setBottomFunnelRevenue(revenueForecast.slice(4).reduce((partialSum: number, a: number) => partialSum + a, 0));
      },
    },
  );

  useEffect(() => {
    if (!currentSavedView?.id) {
      refetchForecast();
    }
  }, [currentSavedView?.id]);

  if (loadingForecast) return <Loading />;
  if (errorForecast) return <AppErrorText>Error loading forecast</AppErrorText>;

  const totalMMR = dataForecast.fetchRevenueForcast?.map((item: any) => item.total_mrr);

  const recalculateFunnelRevenue = (values: any) => {
    const revenueForecast = values.probability_array?.map(
      (value: number, index: number) => (value * totalMMR[index]) / 100,
    );
    localStorage.setItem("revenue_forecast", JSON.stringify(values.probability_array));

    setTopFunnelRevenue(revenueForecast.slice(0, 4).reduce((partialSum: number, a: number) => partialSum + a, 0));
    setBottomFunnelRevenue(revenueForecast.slice(4).reduce((partialSum: number, a: number) => partialSum + a, 0));
  };

  const formatPipelineLabels = (metric: string) => {
    switch (metric) {
      case "NDMContact":
        return "NDM Contact";
      case "NDMContactInterested":
        return "NDM Contact Interested";
      case "DMContact":
        return "DM Contact";
      case "DMContactInterested":
        return "DM Contact Interested";
      case "DemoSet":
        return "Demo Set";
      case "DemoSetFlaked":
        return "Demo Set Flaked";
      case "DemoHeld":
        return "Demo Held";
      case "FollowUpDemoHeld":
        return "Follow Up Demo Held";
      case "DecisionCallHeld":
        return "Decision Call Held";
      case "Sale":
        return "Sale";
      default:
        return metric;
    }
  };

  return (
    <RevenueForecastContainer>
      <ReactTooltip
        multiline
        // place="top"
        effect="solid"
        className="sib-tooltip"
        // id="callbar"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />

      <Formik
        enableReinitialize
        initialValues={{
          probability_array: !!localStorage.getItem("revenue_forecast")
            ? JSON.parse(localStorage.getItem("revenue_forecast") || "[]")
            : dataForecast.fetchRevenueForcast?.map((item: any) => item.probability),
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values));
        }}
        validationSchema={callFeedbackSchema}
      >
        {({ values, submitForm, setFieldValue, isValid, isSubmitting }: FormikProps<MyFormikProps>) => {
          const restoreValues = () => {
            const defaultValues = dataForecast.fetchRevenueForcast?.map((item: any) => item.probability);

            setFieldValue("probability_array", defaultValues);
            localStorage.setItem("revenue_forecast", JSON.stringify(defaultValues.slice()));
            const revenueForecast = defaultValues?.map(
              (value: number, index: number) => (value * totalMMR[index]) / 100,
            );

            setTopFunnelRevenue(
              revenueForecast.slice(0, 4).reduce((partialSum: number, a: number) => partialSum + a, 0),
            );
            setBottomFunnelRevenue(
              revenueForecast.slice(4).reduce((partialSum: number, a: number) => partialSum + a, 0),
            );
          };
          return (
            <>
              <SpaceBetweenDiv style={{ marginBottom: "16px" }}>
                <AppText fontSize={16} fontWeight={500}>
                  Revenue Forecast
                </AppText>
                <RefreshButton type="reset" variant="secondary" onClick={restoreValues}>
                  <img src={iconRestore} alt="Restore default values" /> &nbsp; Restore Default Values
                </RefreshButton>
              </SpaceBetweenDiv>
              <TableGrid
                cellCount={dataForecast.fetchRevenueForcast.length}
                style={{ marginBottom: "16px", border: "none" }}
              >
                <TableLabelCell style={{ pointerEvents: "none" }} />
                {dataForecast.fetchRevenueForcast?.map((item: any) => (
                  <GridBodyRowLabel style={{ fontSize: 8, paddingLeft: 8 }}>
                    {formatPipelineLabels(item?.pipeline_metric)}
                  </GridBodyRowLabel>
                ))}
              </TableGrid>
              <FieldArray name="probability_array">
                {() => (
                  <>
                    <TableGrid cellCount={dataForecast.fetchRevenueForcast.length}>
                      <TableLabelCell>
                        <GridBodyRowLabel>{dataOrg?.fetchOrganization?.mrr_label || `Total MRR`}</GridBodyRowLabel>
                        &nbsp;
                        <PhoenixIcon
                          data-tip={getMRRTooltip(dataOrg?.fetchOrganization?.mrr_label)}
                          size={14}
                          variant="neutral"
                          svg={info}
                        />
                      </TableLabelCell>
                      {totalMMR?.map((item: number, index: number) => (
                        <TableCell>
                          <CellDataText>{`$${item?.toLocaleString()}`}</CellDataText>
                        </TableCell>
                      ))}
                    </TableGrid>
                    <TableGrid cellCount={dataForecast.fetchRevenueForcast.length}>
                      <TableLabelCell>
                        <GridBodyRowLabel>Probability to Close</GridBodyRowLabel>
                        &nbsp;
                        <PhoenixIcon
                          data-tip={
                            "Likelihood that each lead in their current sales phase will eventually convert into a sale."
                          }
                          size={14}
                          variant="neutral"
                          svg={info}
                        />
                      </TableLabelCell>
                      {values.probability_array?.map((customField, index) => {
                        return (
                          <TableCell style={{ paddingRight: "24px", paddingTop: "3px" }}>
                            <ProbablilityInput
                              percentage
                              name={`probability_array[${index}]`}
                              type="number"
                              displayNoContext
                              onBlur={() => recalculateFunnelRevenue(values)}
                            />
                          </TableCell>
                        );
                      })}
                    </TableGrid>
                    <TableGrid cellCount={dataForecast.fetchRevenueForcast.length}>
                      <TableLabelCell>
                        <GridBodyRowLabel>Revenue Forecast</GridBodyRowLabel>
                        &nbsp;
                        <PhoenixIcon
                          data-tip={"Chosen Default Revenue Forecast Value multiplied by Probability to Close."}
                          size={14}
                          variant="neutral"
                          svg={info}
                        />
                      </TableLabelCell>
                      {values.probability_array?.map((value: number, index: number) => (
                        <TableCell>
                          <CellDataText>{`$${((value * totalMMR[index]) / 100).toLocaleString()}`}</CellDataText>
                        </TableCell>
                      ))}
                    </TableGrid>
                    <TotalRevenueDiv>
                      <AppText>Total Revenue Forecast </AppText>
                      <PhoenixIcon
                        data-tip={"Revenue Forecast ($)"}
                        size={14}
                        style={{ marginBottom: "2px", marginLeft: "4px", marginRight: "24px" }}
                        variant="neutral"
                        svg={info}
                      />

                      <TotalRevenueText
                        cellCount={
                          dataForecast.fetchRevenueForcast?.length > 0 ? dataForecast.fetchRevenueForcast?.length : 10
                        }
                      >
                        {formatUSDRaw(
                          values.probability_array
                            ?.map((value: number, index: number) => (value * totalMMR[index]) / 100)
                            .reduce((partialSum, a) => partialSum + a, 0),
                        )}
                      </TotalRevenueText>
                    </TotalRevenueDiv>
                  </>
                )}
              </FieldArray>
            </>
          );
        }}
      </Formik>
    </RevenueForecastContainer>
  );
};

const RefreshButton = styled(NewAppButton)`
  width: 138px;
  height: 28px;
  border: 0.575px solid ${theme.NEUTRAL200};
  border-radius: 4.6px;
  color: ${theme.PRIMARY500};
  font-size: 8px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

interface TableProps {
  cellCount: number;
}

const TotalRevenueText = styled(AppText)<TableProps>`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  min-width: calc((100% - 154px) / ${(props) => props.cellCount});
  /* min-width: 176px; */
  width: auto;
  padding-left: 8px;
`;

const TotalRevenueDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 38px;
  width: 100%;
`;

const ProbablilityInput = styled(InputField)`
  height: 24px;
  width: 52px;
  /* margin: 0px; */
  padding-left: 8px;
  padding-right: 4px;
  font-size: 10px;
  margin-top: -3px;
  margin-right: 4px;

  :focus {
    border-style: solid;
    border-width: 1px;
  }
`;

const GridBodyRowLabel = styled(AppText)`
  font-size: 10px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: 600;
  text-align: left;
  max-width: 118px;
`;

const CellDataText = styled(AppText)`
  font-size: 10px;
  font-weight: 400;
  width: 100%;
  /* padding-left: 8px; */
`;

const TableLabelCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
  width: 176px;
`;

const TableCell = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 8px;
`;

const TableGrid = styled.div<TableProps>`
  display: grid;
  grid-template-columns: 176px repeat(${(props) => props.cellCount}, 1fr);
  grid-gap: 2px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const RevenueForecastContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  /* padding: 24px; */
  /* border: 1px solid ${theme.NEUTRAL200}; */
  margin-top: 16px;
  /* padding-bottom: 62px; */
  overflow: hidden;
`;

export { RevenueForecastTable };
