import styled from "styled-components";
import React, { useCallback } from "react";
import { theme } from "../../utils/theme";
import ReactTooltip from "react-tooltip";
import { TooltipProps } from "react-tooltip";

// please make sure to add the data-tip and data-for attributes to the element you want to show the tooltip on
// you also need to add the id of the tooltip this should be the same as the data-for attribute

interface ITooltip extends TooltipProps {
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  fontWeight?: number;
  fontSize?: string | number;
  lineHeight?: string | number;
  uppercase?: boolean;
  textColor?: string;
  padding?: string | number;
  textAlign?: string;
  textTransform?: string;
  opacity?: number;
  textWrap?: string;

  // Note: If you're just rendering an img, data-tip prop is still required. You can set as an empty string.
  imgSrc?: string;
  imgAlt?: string;
  imgSize?: number;
}
const PhoenixStyledTooltip: React.FC<ITooltip> = ({ getContent, imgSrc, imgAlt, imgSize, ...props }) => {
  const customGetContent = useCallback(
    (dataTip: string) => (
      <>
        {typeof getContent === "function" ? getContent(dataTip) : dataTip}
        {imgSrc && <img src={imgSrc} alt={imgAlt || "Tooltip"} width={imgSize} />}
      </>
    ),
    [getContent, imgSrc, imgAlt, imgSize],
  );

  return (
    <CustomTooltip
      // main params from ReactTooltip.
      // these can be overridden by adding them to the component
      backgroundColor={theme.fill.brand.inverse}
      textColor={theme.text.neutral.inverse}
      effect="solid"
      getContent={customGetContent}
      // covers the rest of the custom override props that will be passed to the styled component
      {...props}
    >
      {props.children}
    </CustomTooltip>
  );
};

const CustomTooltip = styled(ReactTooltip)`
  text-transform: ${(props: any) =>
    props.uppercase ? "uppercase" : props.textTransform ? props.textTransform : "none"};
  width: ${(props: any) =>
    props.width ? (typeof props.width === "number" ? `${props.width}px` : props.width) : "max-content"};
  height: ${(props: any) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "max-content"};
  max-height: ${(props: any) =>
    props.maxHeight ? (typeof props.maxHeight === "number" ? `${props.maxHeight}px` : props.maxHeight) : "400px"};
  max-width: ${(props: any) =>
    props.maxWidth ? (typeof props.maxWidth === "number" ? `${props.maxWidth}px` : props.maxWidth) : "600px"};
  font-weight: ${(props: any) => props.fontWeight || 600};
  font-size: ${(props: any) =>
    props.fontSize ? (typeof props.fontSize === "number" ? `${props.fontSize}px` : props.fontSize) : "12px"} !important;
  line-height: ${(props: any) =>
    props.lineHeight ? (typeof props.lineHeight === "number" ? `${props.lineHeight}px` : props.lineHeight) : "12px"};
  padding: ${(props: any) =>
    props.padding ? (typeof props.padding === "number" ? `${props.padding}px` : props.padding) : "8px"} !important;
  text-align: ${(props: any) => props.textAlign || "center"};
  opacity: ${(props: any) => props.opacity || "inherit"};

  white-space: ${(props: any) => (props.textWrap ? props.textWrap : "wrap")};
`;

export { PhoenixStyledTooltip };
