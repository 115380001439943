import React from "react";
import styled from "styled-components";
import { chevron_left } from "../../../../../images/NewDesign";
import { IIntegrations } from "../../../../../types";
import { theme } from "../../../../../utils/theme";
import { AppErrorText, AppText } from "../../../../UI";
import { PhoenixIcon } from "../../../../UI/Phoenix";
import HubspotSettings from "./HubspotSettings";
import SalesforceSettings from "./SalesforceSettings";

const IntegrationSettingsPage = ({
  integration,
  integrationLabel,
  integrationLogo,
  goBack,
}: {
  integration: IIntegrations;
  integrationLogo: string;
  integrationLabel: string;
  goBack: () => void;
}) => {
  const supportedIntegrations = [IIntegrations.Salesforce, IIntegrations.HubSpot, IIntegrations.GoogleMeet];

  const BackButton = () => {
    return (
      <div
        style={{ display: "flex", gap: "4px", cursor: "pointer", padding: "4px", alignItems: "center" }}
        onClick={() => {
          goBack();
        }}
      >
        <PhoenixIcon svg={chevron_left} size={16} color={theme.PRIMARY500} />
        <AppText
          fontSize={10}
          fontWeight={600}
          color={theme.PRIMARY600}
          style={{
            color: theme.PRIMARY600,
          }}
          uppercase
        >
          Back
        </AppText>
      </div>
    );
  };

  if (!supportedIntegrations.includes(integration)) {
    return <AppErrorText>{`Integration ${integration} is not supported yet.`}</AppErrorText>;
  }

  return (
    <Page>
      <PageHeader>
        <BackButton />
        <AppText fontSize={22} fontWeight={500}>
          Integrations
        </AppText>
      </PageHeader>

      <IntegrationHeader>
        <IntegrationLogoImg src={integrationLogo} alt={integrationLabel} />
        <IntegrationName>{integrationLabel}</IntegrationName>
      </IntegrationHeader>

      <Spacer height={40} />

      {supportedIntegrations.includes(integration) && (
        <>
          {integration === IIntegrations.Salesforce && <SalesforceSettings />}

          {integration === IIntegrations.HubSpot && <HubspotSettings />}
        </>
      )}
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const PageHeader = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const IntegrationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IntegrationLogoImg = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
`;

const IntegrationName = styled(AppText)`
  font-size: 20px;
  font-weight: 600;
`;

export default IntegrationSettingsPage;
