import { gql, useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import { loggedInUser } from "../../apollo/cache";
import { IS_ON_CALL, LOGGED_IN_USER } from "../../apollo/query";
import { configureAbly } from "@ably-labs/react-hooks";
import { ABLY_OPTIONS } from "../../services/ably";
import { TOKEN_PERSIST_KEY } from "../../utils/variables";
import { useMutation } from "@apollo/client";
import { useTabFocus } from "../../utils/hooks";
import { getLocalStorage, tabSync } from "../../utils/misc";
import { setEnvDocTitle } from "../../utils/misc";
import { usePendo } from "src/utils/usePendo";

const FETCH_USER = gql`
  query fetchUser {
    fetchUser {
      id
      email
      first_name
      last_name
      role
      status
      organization_id
      team_id
      team_name
      manager_id
      manager {
        id
        first_name
        last_name
      }
      phone_number
      timezone
      profile_image
      organization {
        id
        name
        onboardComplete
        international
        mrr_label
        edge_servers
        showLeadSourceToUser
        show_nocontact_button
        show_calendar_options
        auto_dial
        specific_time_callbacks_slot_behaviour
      }
      always_show_calendar_options
      joinMe_connected
      created_at
      timekit_id
      timekit_type
      panda_doc_access
      dashboard_2_access
      default_audio_input
      default_audio_output
      visible_all_leads_sm
      visible_my_leads_columns_computed
      checkUserCalendarStatus
    }
  }
`;

const UPDATE_USER_STATUS_V2 = gql`
  mutation updateUserStatus($status: STATUS!, $do_not_update_if_on_call: Boolean) {
    updateUserStatus(status: $status, do_not_update_if_on_call: $do_not_update_if_on_call) {
      id
      email
      status
    }
  }
`;

const UPDATE_USER_TAB = gql`
  mutation updateRepTab($tab_id: String!) {
    updateRepTab(tab_id: $tab_id)
  }
`;

interface LocalUserType {
  [key: string]: any;
  organization: {
    [key: string]: any;
  };
}
export const updateLocalOrg = ({
  mrr_label,
  edge_servers,
  showLeadSourceToUser,
  show_calendar_options,
  show_nocontact_button,
  auto_dial,
  next_action,
}: {
  mrr_label?: string;
  edge_servers?: string[];
  showLeadSourceToUser?: boolean;
  show_calendar_options?: boolean;
  show_nocontact_button?: boolean;
  auto_dial?: boolean;
  next_action?: boolean;
}) => {
  loggedInUser({
    ...loggedInUser(),
    organization: {
      id: loggedInUser()?.organization?.id || "",
      ...loggedInUser().organization,
      mrr_label: mrr_label,
      edge_servers,
      showLeadSourceToUser,
      show_calendar_options,
      show_nocontact_button,
      auto_dial,
    },
  });
};
export const updateLocalUser = (user: LocalUserType) => {
  loggedInUser({
    ...loggedInUser(),
    ...user,
    organization: {
      id: loggedInUser()?.organization?.id || "",
      ...loggedInUser().organization,
      ...user?.organization,
    },
  });
};

export const DataWrapper: React.FC = ({ children }) => {
  // Calendar date picker for my schedule

  configureAbly(ABLY_OPTIONS);

  // visiblity change update to idle if user is logged in

  // temp fix until multiple tabs are supported

  const [updateUserStatusV2] = useMutation(UPDATE_USER_STATUS_V2);

  useTabFocus(() => {
    // must be straight check to localStorage
    const loggedIn = localStorage?.getItem(TOKEN_PERSIST_KEY) ?? false;

    if (loggedIn) {
      updateUserStatusV2({ variables: { status: "IDLE", do_not_update_if_on_call: true } });
    }
  });

  useTabFocus(
    () => {
      const isOnCall = getLocalStorage("userIsOnCallLocal");
      if (!isOnCall) {
        tabSync.handleTabSwitch();
      }
    },
    { waitTime: 2000 },
  );

  const [updateRepTab] = useMutation(UPDATE_USER_TAB);

  const [activeTabID, setActiveTabID] = useState<null | string>(null);

  useEffect(() => {
    const handleStorageChange = () => {
      const savedDevices = getLocalStorage("saved_devices");
      if (!!savedDevices && !!savedDevices.length) {
        const lastEle = savedDevices[savedDevices.length - 1];
        activeTabID !== lastEle && setActiveTabID(lastEle);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const localTabID = sessionStorage.getItem("tab_id");
    if (!localTabID) return;
    if (activeTabID === localTabID) {
      updateRepTab({ variables: { tab_id: activeTabID } });
    }
  }, [activeTabID]);

  useEffect(() => {
    const localTabID = sessionStorage.getItem("tab_id");
    if (!localTabID) return;
    updateRepTab({ variables: { tab_id: localTabID } });
  }, []);

  // (FOR DEV ONLY)

  useEffect(() => {
    setEnvDocTitle();
  }, []);

  const { data: loggedData } = useQuery(LOGGED_IN_USER);

  const [fetchUser, _] = useLazyQuery(FETCH_USER, {
    fetchPolicy: "network-only",
    onCompleted: ({ fetchUser }) => {
      updateLocalOrg({
        mrr_label: fetchUser?.organization?.mrr_label,
        edge_servers: fetchUser?.organization?.edge_servers,
        showLeadSourceToUser: fetchUser?.organization?.showLeadSourceToUser,
        show_nocontact_button: fetchUser?.organization?.show_nocontact_button,
        show_calendar_options: fetchUser?.organization?.show_calendar_options,
        next_action: fetchUser?.organization?.next_action,
      });
      updateLocalUser(fetchUser);
    },
  });

  const pendo: any = usePendo();

  useEffect(() => {
    if (!!loggedData?.loggedInUser?.id) {
      fetchUser();
      const user = loggedData?.loggedInUser;

      if (!pendo) return;
      pendo?.initialize({
        visitor: {
          id: user.id,
          email: user.email,
          role: user.role,
          first_name: user.first_name,
          last_name: user.last_name,
        },
        account: {
          id: user.id,
        },
      });
    }
  }, [loggedData?.loggedInUser?.id, pendo]);

  return <>{children}</>;
};
