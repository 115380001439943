import mixpanel from "mixpanel-browser";
import { loggedInUser, isLoggedInVar } from "../apollo/cache";
import { MIXPANEL_TOKEN } from "../utils/variables";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../apollo/query";

let env_check = process.env.NODE_ENV === "production";
// let env_check = true;

// export const MixpanelActions = {
//   identify: (id: any) => {
//     if (env_check) mixpanel.identify(id);
//   },
//   alias: (id: any) => {
//     if (env_check) mixpanel.alias(id);
//   },
//   track: (name: any, props?: any) => {
//     if (env_check) mixpanel.track(name, props);
//   },
//   people: {
//     set: (props: any) => {
//       if (env_check) mixpanel.people.set(props);
//     },
//   },
// };

export const MixpanelActions = {
  identify: (id: any) => {
    if (!!loggedInUser()?.id) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (!!loggedInUser()?.id) mixpanel.alias(id);
  },
  track: (name: any, props?: any) => {
    if (!!loggedInUser()?.id) {
      const user = loggedInUser();
      mixpanel.track(name, {
        ...props,
        $company: user?.organization?.name,
        $user_type: user?.role,
      });
    }
  },
  people: {
    set: (props: any) => {
      if (!!loggedInUser()?.id) mixpanel.people.set(props);
    },
  },
  reset: () => {
    console.log("Mixpanel Reset");
    mixpanel.reset();
  },
};

if (MIXPANEL_TOKEN) {
  if (env_check) {
    mixpanel.init(MIXPANEL_TOKEN, { debug: false });
  } else {
    // mixpanel.init("TEST", { debug: true });
    mixpanel.init(MIXPANEL_TOKEN, { debug: true });
  }

  //store properities we want with all track events
  mixpanel.register({ $environment: process.env.NODE_ENV });
}
export const initMixpanel = () => {
  const user = loggedInUser();

  if (!!user?.id) {
    MixpanelActions.identify(user.id);

    MixpanelActions.people.set({
      $user_id: user.id,
      $first_name: user?.first_name,
      $last_name: user?.last_name,
      $email: user?.email,
      $environment: process.env.NODE_ENV,
      $created_at: user?.created_at,
      $timezone: user?.timezone,
      $OpsIQ_phone_number: user?.phone_number,
      $manager_id: user?.manager_id,
      $manager: `${user?.manager?.first_name} ${user.manager?.last_name}`,
      $organization_id: user?.organization_id,
      $organization: user?.organization?.name,
      $team_id: user?.team_id,
      $team: user?.team_name,
      $GoogleCalendar_Connected: user?.timekit_type === "Google",
      $Outlook_Connected: user?.timekit_type === "Microsoft",
      $JoinMe_Connected: user?.joinMe_connected,
      //$Most_Recent_Microphone = changeInputDevices() in CallContext
      //$Most_Recent_Output = changeOutputDevices() in CallContext
      $role_type: user?.role,
      $external_id: { timekit_id: user?.timekit_id },

      //maybes?
      // Lifetime_self-sourced_leads
      // Lifetime_claimed_leads
      // Num_Leads_Assigned
      // Num_Leads_Owned
      // Num_Favorited_Leads
      // Num_CallReviews_Requested
    });
    // mixpanel.identify()
  } else {
    console.log("user not found could not init Mixpanel");
  }
};
