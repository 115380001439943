import styled, { keyframes } from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext, SetStateAction, Dispatch, useCallback } from "react";
import * as Sentry from "@sentry/react";
import {
  AppButton,
  AppText,
  AppSidebarCard,
  AppTextArea,
  AppSelect,
  Loading,
  AppErrorText,
  FlexDiv,
  StatCard,
  SkeletonBlock,
} from "../UI";
import { AppTabDiv } from "../UI/AppTabDiv";
import { NewAppButton } from "../UI/NewAppButton";
import { theme } from "../../utils/theme";
import { BsMic, BsVolumeDownFill } from "react-icons/bs";
import { dialer } from "../../images/NewDesign";
import {
  iconHangUp,
  iconTimeOnCall,
  iconCallSettings,
  iconCallParticipants,
  iconNumpadNew,
  iconMicNew,
  iconMicMutedNew,
  newCloseModal,
  iconStartRecording,
  iconStopRecording,
  iconTimeOnCallGreen,
  iconCallJoinDetails,
} from "../../images/";
import { FavoriteDialsComponent } from "./CallSegments";
import { currentCallState, inputDevices, outputDevices, twilioMuted } from "../../apollo/cache";
import moment from "moment-timezone";
import { useQuery, useMutation, gql, useLazyQuery } from "@apollo/client";
import { formatPhoneNumber, formatTime, formatUSD } from "../../utils/format";
import { FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL } from "../../utils/variables";
import { IS_ON_CALL } from "../../apollo/query";
import { CallContext, ModalContext, FavoritedLeadFilterContext } from "../../context";
import {
  Modal,
  CallFeedbackModal,
  JoinDetailsModal,
  CallParticipantsModal,
  RecordConsentModal,
  TwilioFeedbackModal,
} from "../modal";
import { loggedInUser } from "../../apollo/cache";
import ReactTooltip from "react-tooltip";
import { MyScheduleCal } from "../Smart/MyScheduleCal";
import { ScheduleContext } from "../../context/ScheduleContext";
import { appToast } from "../../utils/toast";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { renderLeadIntentEventTypeValues } from "../../utils/misc";
import _ from "lodash";
import { MixpanelActions } from "./../../services/mixpanel";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PhoenixIcon } from "../UI/Phoenix";
import { user_white, clock, settings, info, record, users } from "../../images/NewDesign";
import { MyMissedCal } from "../Smart/MyMissedCal";
import { useDebounce } from "../../utils/hooks";
import { TwilioWarnings } from "../Dumb/TwilioWarnings";

interface CallComponentProps {
  // callLeadId?: string;
  callStartTime?: Date;
  phoneNumber?: string;
  callState?: boolean;
  setBlinds: Dispatch<SetStateAction<boolean>>;
  closeOtherModals?: () => void;

  popup: boolean;
  setPopup: (popup: boolean) => void;
  currentTask: string;
  setCurrentTask: (currentTask: string) => void;
}

export const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_email
      phase
      can_record_without_permission
      sequence_step {
        actions {
          id
          scheduled_event_behavior
        }
      }
    }
  }
`;

const FETCH_CONFERENCE_PARTICIPANTS = gql`
  query fetchConferenceParticipants {
    participants: fetchConferenceParticipants
  }
`;

const UPDATE_ACTIVE_TWILIO_RECORDING = gql`
  mutation updateActiveTwilioRecording($action: TwilioRecordingAction!) {
    updateActiveTwilioRecording(action: $action)
  }
`;

const FETCH_SCHDEULE_ITEMS_LIST = gql`
  query fetchScheduleItemsList($user_id: String!, $skip: Int!, $take: Int!) {
    fetchScheduleItemsList(user_id: $user_id, skip: $skip, take: $take) {
      id
      start_time
      end_time
      timezone
      deleted_at
      lead_intent {
        id
        event_type_label
        current_sequence_step
        lead {
          id
          full_name
          business_name
          lead_value {
            id
            value
          }
        }
      }
    }
  }
`;

const FETCH_SCHEDULE_ITEMS_LIST_WITH_HISTORICAL_EVENTS = gql`
  query fetchScheduleItemsListWithHistoricalEvents($user_id: String!, $skip: Int!, $take: Int!) {
    fetchScheduleItemsListWithHistoricalEvents(user_id: $user_id, skip: $skip, take: $take) {
      schedule_item {
        id
        start_time
        end_time
        timezone
        computed_event_type
        deleted_at
      }
      lead {
        id
        full_name
        business_name
        lead_value {
          id
          value
        }
      }
    }
  }
`;

const UPDATE_DEFAULT_AUDIO = gql`
  mutation updateDefaultAudio($default_audio_input: String, $default_audio_output: String) {
    updateDefaultAudio(default_audio_input: $default_audio_input, default_audio_output: $default_audio_output) {
      id
      default_audio_input
      default_audio_output
    }
  }
`;

const FETCH_CALL_NOTES_TEMPLATE_PREVIEW = gql`
  query fetchCallNotesTemplatePreview($lead_id: String!) {
    fetchCallNotesTemplatePreview(lead_id: $lead_id) {
      body
    }
  }
`;

const Call: React.FC<CallComponentProps> = ({
  callStartTime,
  // callLeadId,
  phoneNumber,
  callState,
  setBlinds,
  closeOtherModals,
  setPopup,
  setCurrentTask,
}) => {
  const [callSettingsModal, setCallSettingsModal] = useState(false);

  const {
    showDialer,
    openDialer,
    showCallFeedbackModal,
    setShowCallFeedbackModal,
    showCallParticipantsModal,
    setShowCallParticipantsModal,
    showJoinDetailsModal,
    setShowJoinDetailsModal,
    setShowConsentModal,
    setShowTwilioFeedbackModal,
  } = useContext(ModalContext);

  const {
    showDatePicker,
    setShowDatePicker,
    myScheduleDate,
    setMyScheduleDate,
    expandSchedule,
    setExpandSchedule,
  } = useContext(ScheduleContext);

  const {
    callNotes,
    updateCallNotes,
    recording,
    toggleDidStartRecording,
    startedRecording,
    toggleRecording,
    recordingButtonDisabled,
    persistCallNotes,
    callNotesLastUpdated,
    toggleMute,
    hangup,
    getOutputDevice,
    device,
    changeInputDevice,
    changeOutputDevice,
    nextActionOverride,
    setNextActionOverride,
    callLeadId,
    defaultInput,
    setDefaultInput,
    defaultOutput,
    setDefaultOutput,
    timeOnCall,
    setTimeOnCall,
    isNonCallActivity,
    deviceResetCheck,
    setDeviceResetCheck,
    setIsConnectingToTwilio,
    isConnectingToTwilio,
  } = useContext(CallContext);

  const { connectionWarnings } = useFlags();

  const [selectedAudio, setSelectedAudio] = useState<"input" | "output" | undefined>(undefined);

  const { data, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: callLeadId },
    onCompleted: (data) => {
      if (currentCallState().onCall) {
        setIsConnectingToTwilio(false);
        if (data.fetchLead?.can_record_without_permission) {
          appToast(
            "The lead lives in a one-party state",
            {},
            "Recording of the lead and the rep will begin automatically",
          );
          return;
        }
        appToast(
          "This call is not being recorded by default",
          {},
          "This prospect resides in an all-party consent state",
        );
      }
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: isOnCall, loading: isOnCallLoading } = useQuery(IS_ON_CALL, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: participants,
    loading: participantsLoading,
    error: participantsError,
    refetch: refetchParticipants,
    startPolling,
    stopPolling,
  } = useQuery(FETCH_CONFERENCE_PARTICIPANTS, {
    // pollInterval: FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useQuery(FETCH_CALL_NOTES_TEMPLATE_PREVIEW, {
    fetchPolicy: "network-only",
    variables: { lead_id: callLeadId },
    onCompleted: ({ fetchCallNotesTemplatePreview }) => {
      updateCallNotes(fetchCallNotesTemplatePreview?.body || "", callLeadId || "");
    },
    skip: !callLeadId,
  });
  const [scheduleListItems, setScheduleList] = useState<any[]>([]);
  const scheduleListTake = 4; //amount of takes must be higher than the height of elementsit can display, infinite scroll works by refetching when it has to scroll
  const [scheduleSkip, setScheduleSkip] = useState(0);

  const showHistoricalEvents = loggedInUser().organization?.show_historical_events ?? false;

  const [
    fetchScheduleList,
    { data: scheduleList, loading: scheduleListLoading, error: scheduleListError, refetch: refetchScheduleList },
  ] = useLazyQuery(FETCH_SCHDEULE_ITEMS_LIST, {
    fetchPolicy: "no-cache",
    variables: { user_id: loggedInUser().id, skip: scheduleSkip, take: scheduleListTake },
    onCompleted: (scheduleList) => {
      const newList = [...scheduleListItems, ...scheduleList.fetchScheduleItemsList].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id).includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );
      if (scheduleList.fetchScheduleItemsList.length < scheduleListTake) {
        setHasMoreSchedule(false);
      } else setHasMoreSchedule(true);
      setScheduleList(newList);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [
    fetchScheduleListWithHistoricalEvents,
    { data: dataHistorical, loading: loadingHistorical, error: errorHistorical, refetch: refetchHistorical },
  ] = useLazyQuery(FETCH_SCHEDULE_ITEMS_LIST_WITH_HISTORICAL_EVENTS, {
    fetchPolicy: "no-cache",
    variables: { user_id: loggedInUser().id, skip: scheduleSkip, take: scheduleListTake },
    onCompleted: (dataHistorical) => {
      const newList = [...scheduleListItems, ...dataHistorical.fetchScheduleItemsListWithHistoricalEvents].reduce(
        // (acc, cv) => (acc.map((a: any) => a?.schedule_item?.id).includes(cv?.schedule_item?.id) ? acc : [...acc, cv]),
        // Use schedule_item.id to avoid duplicates
        (acc, cv) => (acc.map((a: any) => a?.schedule_item?.id).includes(cv?.schedule_item?.id) ? acc : [...acc, cv]),
        [],
      );
      if (dataHistorical.fetchScheduleItemsListWithHistoricalEvents.length < scheduleListTake) {
        setHasMoreSchedule(false);
      } else setHasMoreSchedule(true);
      setScheduleList(newList);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [favoritedListItems, setFavoritedList] = useState<any[]>([]);
  const favoritedListTake = 4; //amount of takes must be higher than the height of elementsit can display, infinite scroll works by refetching when it has to scroll
  const [favoritedSkip, setFavoritedSkip] = useState(0);

  const [updateActiveTwilioRecording] = useMutation(UPDATE_ACTIVE_TWILIO_RECORDING, {
    onCompleted: ({ updateActiveTwilioRecording }) => {
      if (!updateActiveTwilioRecording) {
        appToast("Recording turned off");
        toggleRecording(false);
      }
      if (updateActiveTwilioRecording) {
        appToast("Sucessfully recording");
        toggleRecording(true);
        toggleDidStartRecording(true);
      }
    },
    onError: ({ message }) => {
      Sentry.captureEvent({
        message: `updateActiveTwilioRecording graphql error: ${message}`,
      });
      appToast(`Error updating recording: ${message}`);
    },
  });

  const [updateDefaultAudio, { loading: loadingDefaultAudio }] = useMutation(UPDATE_DEFAULT_AUDIO, {
    onCompleted: ({ updateDefaultAudio }) => {
      appToast("Successfully updated default audio");
      console.log(updateDefaultAudio);
      selectedAudio === "input" && setDefaultInput(updateDefaultAudio.default_audio_input);
      selectedAudio === "output" && setDefaultOutput(updateDefaultAudio.default_audio_output);
      setSelectedAudio(undefined);
    },
    onError: ({ message }) => {
      console.log("Error in updateDefaultAudio: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateDefaultAudio GraphQL Error: ${message}`,
      });
    },
  });

  /**
   * time on the call in seconds
   */
  // const [timeOnCall, setTimeOnCall] = useState(0);
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (callState) {
      startPolling(FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL);
      console.log("fetch conference participants start polling");
    } else {
      stopPolling();
      console.log("fetch conference participants stop polling");
    }
    return () => {
      stopPolling();
      console.log("fetch conference participants stop polling");
    };
  }, [callState]);

  /**
   * Increments the number of seconds on a call while the call is still going on
   */
  useEffect(() => {
    // If user is still on call
    if (currentCallState().onCall) {
      const timeDifference = Math.abs(currentTime.diff(moment(callStartTime), "seconds"));
      setTimeOnCall(timeDifference);

      // deviceResetCheck should be false at this point but this is a failsafe
      if (deviceResetCheck) {
        setDeviceResetCheck(false);
      }
    }
  }, [currentTime]);

  const handleRecordingClick = () => {
    if (startedRecording) {
      // a recording was already started
      updateActiveTwilioRecording({
        variables: {
          action: recording ? "Pause" : "Resume", // pause or resume the recording
        },
      });
    } else {
      // start new recording
      if (!data?.fetchLead?.can_record_without_permission) setShowConsentModal(true);
      // show consent modal if we cannot record without their permission
      else
        updateActiveTwilioRecording({
          // start new recording
          variables: {
            action: "Start",
          },
        });
    }
  };

  const [scheduleTab, setScheduleTab] = useState("calendar"); //probably change this to save in context?
  const [hasMoreSchedule, setHasMoreSchedule] = useState(true);

  useEffect(() => {
    if (scheduleTab === "list") {
      if (showHistoricalEvents) {
        fetchScheduleListWithHistoricalEvents({
          variables: {
            user_id: loggedInUser().id,
            skip: scheduleSkip,
            take: scheduleListTake,
          },
        });
      } else {
        fetchScheduleList({
          variables: {
            user_id: loggedInUser().id,
            skip: scheduleSkip,
            take: scheduleListTake,
          },
        });
      }
    }
  }, [scheduleTab, scheduleSkip]);

  const handleShowMore = () => {
    setScheduleSkip(scheduleSkip + scheduleListTake);
  };

  const debouncedSaveNotes = useCallback(() => {
    if (!isNonCallActivity) {
      persistCallNotes();
    }
  }, [callNotes]);

  useDebounce(debouncedSaveNotes, [callNotes], 1000); // 1 second debounce

  const renderScheduleTab = () => (
    <AppSidebarCard height={40} title="My Schedule" width={432} showExpand style={{ overflow: "hidden" }}>
      <div
        style={{
          maxHeight: expandSchedule ? "fit-content" : "100px",
          overflow: expandSchedule ? "visible" : "hidden",
        }}
      >
        <ButtonContainer>
          <AppTab
            selected={scheduleTab === "calendar"}
            onClick={() => {
              MixpanelActions.track("Schedule Event", { type: "click", tab: `${scheduleTab}`, view: "Dashboard" });
              setScheduleTab("calendar");
            }}
          >
            Schedule
          </AppTab>
          <AppTab
            selected={scheduleTab === "list"}
            onClick={() => {
              MixpanelActions.track("Schedule Event", { type: "click", tab: `${scheduleTab}`, view: "Dashboard" });
              setScheduleTab("list");
            }}
          >
            List
          </AppTab>
          <AppTab
            selected={scheduleTab === "missed"}
            onClick={() => {
              MixpanelActions.track("Missed Event", { type: "click", tab: `${scheduleTab}`, view: "Dashboard" });
              setScheduleTab("missed");
            }}
          >
            Missed Demos
          </AppTab>
          <TabFiller />
        </ButtonContainer>
        {scheduleTab === "calendar" ? (
          <MyScheduleCal />
        ) : scheduleTab === "missed" ? (
          <MyMissedCal />
        ) : (
          <ScheduleScrollDiv id="schedule-list-div">
            <InfiniteScroll
              dataLength={scheduleListItems.length}
              next={handleShowMore}
              hasMore={hasMoreSchedule}
              loader={<Loading />}
              scrollableTarget="schedule-list-div"
            >
              {scheduleListItems.length > 0 &&
                (showHistoricalEvents
                  ? scheduleListItems.map((item: any, index: number) => {
                      const prevStartDate =
                        !!scheduleListItems[index - 1] &&
                        moment(scheduleListItems[index - 1].schedule_item.start_time).format("MMMM Do");
                      const currentStartDate = moment(item.schedule_item.start_time).format("MMMM Do");
                      return (
                        <ScheduleListDiv red={!!item?.schedule_item?.deleted_at}>
                          {prevStartDate !== currentStartDate && (
                            <DayDivider>
                              <AppText>{currentStartDate}</AppText>
                            </DayDivider>
                          )}

                          <ScheduleListItem key={item.schedule_item.id}>
                            <FlexDiv justify="flex-end" style={{ marginRight: "16px" }}>
                              <AppText
                                fontSize={12}
                                variant="primary"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                                onClick={() =>
                                  window.open(`/manage-schedule/${item.schedule_item?.id}`, "_blank", "noreferrer")
                                }
                              >
                                View Details
                              </AppText>
                            </FlexDiv>
                            <ScheduleListContent>
                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {moment(item.schedule_item.start_time).format("h:mm A")} -{" "}
                                  {moment(item.schedule_item.end_time).format("h:mm A")}
                                </AppText>
                                <FlexDiv gap={4}>
                                  <AppText fontSize={12}>{item.schedule_item?.computed_event_type}</AppText>
                                  <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
                                    |
                                  </AppText>
                                  <AppText fontSize={12} variant="primary">
                                    {formatUSD(_.round(item?.lead?.lead_value?.value, 2))}
                                  </AppText>
                                </FlexDiv>
                              </FlexDiv>

                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {item?.lead?.business_name || "No Name"}
                                </AppText>
                                <FlexDiv gap={8}>
                                  <StatCard icon={user_white} />
                                  <AppText fontSize={12}>{item?.lead?.full_name}</AppText>
                                </FlexDiv>
                              </FlexDiv>
                            </ScheduleListContent>
                          </ScheduleListItem>
                        </ScheduleListDiv>
                      );
                    })
                  : scheduleListItems.map((item: any, index: number) => {
                      const prevStartDate =
                        !!scheduleListItems[index - 1] &&
                        moment(scheduleListItems[index - 1].start_time).format("MMMM Do");
                      const currentStartDate = moment(item.start_time).format("MMMM Do");
                      return (
                        <ScheduleListDiv>
                          {prevStartDate !== currentStartDate && (
                            <DayDivider>
                              <AppText>{currentStartDate}</AppText>
                            </DayDivider>
                          )}

                          <ScheduleListItem key={item.id}>
                            <FlexDiv justify="flex-end" style={{ marginRight: "16px" }}>
                              <AppText
                                fontSize={12}
                                variant="primary"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                                onClick={() => window.open(`/manage-schedule/${item.id}`, "_blank", "noreferrer")}
                              >
                                View Details
                              </AppText>
                            </FlexDiv>
                            <ScheduleListContent>
                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {moment(item.start_time).format("h:mm A")} - {moment(item.end_time).format("h:mm A")}
                                </AppText>
                                <FlexDiv gap={4}>
                                  <AppText fontSize={12}>{item.lead_intent?.event_type_label}</AppText>
                                  <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
                                    |
                                  </AppText>
                                  <AppText fontSize={12} variant="primary">
                                    {formatUSD(_.round(item.lead_intent?.lead?.lead_value?.value, 2))}
                                  </AppText>
                                </FlexDiv>
                              </FlexDiv>

                              <FlexDiv direction="column" gap={8}>
                                <AppText fontSize={12} fontWeight={600}>
                                  {item.lead_intent?.lead?.business_name || "No Name"}
                                </AppText>
                                <FlexDiv gap={8}>
                                  <StatCard icon={user_white} />
                                  <AppText fontSize={12}>{item.lead_intent?.lead?.full_name}</AppText>
                                </FlexDiv>
                              </FlexDiv>
                            </ScheduleListContent>
                          </ScheduleListItem>
                        </ScheduleListDiv>
                      );
                    }))}
            </InfiniteScroll>
          </ScheduleScrollDiv>
        )}
      </div>
    </AppSidebarCard>
  );

  if ((loading && !isNonCallActivity) || isConnectingToTwilio)
    return (
      <CallComponentContainer>
        <MainContainer>
          <SkeletonBlock height={"95%"} width={"95%"} borderRadius={6} />
        </MainContainer>
        <CallSideBar />
      </CallComponentContainer>
    );

  if (!!error && !!callLeadId)
    return (
      <CallComponentContainer>
        <MainContainer>
          <AppErrorText>Failed to fetch lead</AppErrorText>
          <NewAppButton
            size={"lg"}
            variant={"secondary"}
            onClick={() => {
              refetch({ id: callLeadId });
            }}
          >
            retry
          </NewAppButton>
        </MainContainer>
        <CallSideBar />
      </CallComponentContainer>
    );

  return (
    <CallComponentContainer>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        id="callbar"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      {callSettingsModal && (
        <Modal open={callSettingsModal} onClose={() => setCallSettingsModal(false)} closeButtonSize={16}>
          <SettingsModalContainer>
            <TitleDiv>
              <TitleText>Settings</TitleText>
            </TitleDiv>
            <PaddingAndCenterDiv>
              <AppSelect
                name="inputSelect"
                options={inputDevices()}
                appSelectIcon={<BsMic size={15} color={theme.BLACK_COLOR} />}
                value={device?.audio?.inputDevice?.deviceId || "default"}
                //@ts-ignore
                onChange={(e: any) => {
                  setSelectedAudio("input");
                  updateDefaultAudio({
                    variables: {
                      default_audio_input: e.target.value,
                      default_audio_output: defaultOutput,
                    },
                  });
                  changeInputDevice(e.target.value);
                }}
              />
              <AppSelect
                name="outputSelect"
                options={outputDevices()}
                value={getOutputDevice()}
                //@ts-ignore
                onChange={(e: any) => {
                  setSelectedAudio("output");
                  updateDefaultAudio({
                    variables: {
                      default_audio_input: defaultInput,
                      default_audio_output: e.target.value,
                    },
                  });
                  changeOutputDevice(e.target.value);
                }}
                appSelectIcon={<BsVolumeDownFill size={20} color={theme.BLACK_COLOR} />}
              />
            </PaddingAndCenterDiv>
          </SettingsModalContainer>
        </Modal>
      )}
      <MainContainer>
        {showCallFeedbackModal && (
          <CallFeedbackModal blinds={showCallFeedbackModal} setBlinds={setShowCallFeedbackModal} role="rep" />
        )}
        <TwilioFeedbackModal />

        <CallStateContainer callState={callState}>
          {renderScheduleTab()}

          {callState && (
            <AppSidebarCard
              // unbordered
              style={{ border: "0px", marginTop: "24px" }}
              title="My Current Call Notes"
              width={432}
              render={
                <NotesInfoDiv>
                  <CallNoteLastUpdatedText
                    fontWeight={500}
                    fontSize={9}
                    style={{ color: theme.WHITE_COLOR }}
                    key={callNotesLastUpdated}
                  >
                    {!!callNotesLastUpdated && `(Updated: ${moment(callNotesLastUpdated).format("MM/DD/YYYY h:mm A")})`}
                  </CallNoteLastUpdatedText>
                  <NotesButton variant={"secondary"} onClick={() => persistCallNotes()}>
                    Save Notes
                  </NotesButton>
                </NotesInfoDiv>
              }
            >
              <div>
                <CallNotesDiv>
                  <NotesTextArea
                    noBorder
                    fixedSize
                    height={280}
                    width={380}
                    placeholder="Type here..."
                    value={callNotes}
                    // @ts-ignore
                    onChange={(event: any) => {
                      updateCallNotes(event.target.value, callLeadId || "");
                    }}
                  />
                </CallNotesDiv>
              </div>
            </AppSidebarCard>
          )}

          {!callState && <FavoriteDialsComponent closeOtherModals={closeOtherModals} />}
        </CallStateContainer>

        {callState && (
          <StickToBottomContainer>
            {currentCallState().onCall ? (
              <CallControlDiv>
                {!data.fetchLead?.can_record_without_permission && <RecordConsentModal />}
                <CallParticipantsModal />
                <JoinDetailsModal />
                <div>
                  <VerticalAlignDiv>
                    <img src={iconTimeOnCallGreen} alt="Time on call" />
                    <NewTimeOnCallText style={{ minWidth: 40 }} green>
                      {formatTime(timeOnCall)}
                    </NewTimeOnCallText>
                    {currentCallState().onCall && connectionWarnings && <TwilioWarnings />}
                  </VerticalAlignDiv>
                  <NewPhoneNumbertext>{!!phoneNumber ? formatPhoneNumber(phoneNumber) : ""}</NewPhoneNumbertext>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <DialerButton onClick={openDialer}>
                    <img src={iconNumpadNew} alt="Open numpad" />
                  </DialerButton>
                  <DialerButton
                    onClick={toggleMute}
                    // muted={twilioMuted()}
                  >
                    {twilioMuted() ? (
                      <img src={iconMicMutedNew} alt="Unmute mic" />
                    ) : (
                      <img src={iconMicNew} alt="Mute mic" />
                    )}
                  </DialerButton>
                  <DialerButton
                    style={{ backgroundColor: theme.ATTENTION700 }}
                    onClick={hangup}
                    disabled={!(!isOnCallLoading && isOnCall.isOnCall)}
                    id="hangup-button"
                  >
                    <img src={iconHangUp} alt="Hang up" />
                  </DialerButton>
                </div>
                <LastButtonsDiv>
                  <DialerButton
                    style={{ border: "none" }}
                    data-tip={recording ? "Stop Recording" : "Start Recording"}
                    data-for="callbar"
                    onClick={() => handleRecordingClick()}
                    height={12}
                    width={12}
                  >
                    <img src={recording ? iconStopRecording : iconStartRecording} alt="Toggle recording" />
                  </DialerButton>
                  <DialerButton
                    style={{ border: "none" }}
                    data-tip="Participants"
                    data-for="callbar"
                    onClick={() => {
                      refetchParticipants();
                      setShowCallParticipantsModal(!showCallParticipantsModal);
                    }}
                    height={12}
                    width={12}
                  >
                    <PhoenixIcon svg={users} alt="Participant details" variant="brand" size={14} />
                    <ParticipantNumber>
                      {participants?.participants.length > 0 ? participants.participants.length : ""}
                    </ParticipantNumber>
                  </DialerButton>
                  <DialerButton
                    style={{ border: "none" }}
                    data-tip="Join Details"
                    data-for="callbar"
                    onClick={() => setShowJoinDetailsModal(!showJoinDetailsModal)}
                    height={12}
                    width={12}
                  >
                    <PhoenixIcon svg={info} alt="Join details" variant="brand" size={14} />
                  </DialerButton>
                  <DialerButton
                    style={{ border: "none", position: "relative" }}
                    data-tip="Settings"
                    data-for="callbar"
                    onClick={() => setCallSettingsModal(!callSettingsModal)}
                    height={12}
                    width={12}
                  >
                    <PhoenixIcon svg={settings} variant="brand" size={14} />
                  </DialerButton>
                </LastButtonsDiv>
              </CallControlDiv>
            ) : (
              <div>
                <VerticalAlignDiv>
                  <TimeOnCallContainer>
                    <img src={iconTimeOnCall} alt="Time on call" />
                    <NewTimeOnCallText>Time on Call: {formatTime(timeOnCall || 0)}</NewTimeOnCallText>
                  </TimeOnCallContainer>
                  <PhoenixIcon svg={dialer} variant="neutral" style={{ marginLeft: 16, marginRight: 2 }} size={14} />
                  <NewPhoneNumbertext style={{ color: theme.NEUTRAL400, margin: 0 }}>
                    {!!phoneNumber ? formatPhoneNumber(phoneNumber) : ""}
                  </NewPhoneNumbertext>
                </VerticalAlignDiv>
                <NewPhoneNumbertext>Call has ended. Please submit a call result to continue.</NewPhoneNumbertext>
              </div>
            )}
          </StickToBottomContainer>
        )}
      </MainContainer>
    </CallComponentContainer>
  );
};

interface CallStateProps {
  callState: boolean | undefined;
}

const CallStateContainer = styled.div<CallStateProps>`
  max-height: ${(props) => (props.callState ? "calc(100% - 102px)" : "calc(100%)")};
  /* overflow-y: auto; */
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ExpandButton = styled.div`
  background-color: ${theme.PRIMARY800};
  color: ${theme.NEUTRAL200};
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-left: 6px;
  padding-bottom: 8px;
`;

interface ShouldBeGreen {
  green?: boolean;
}

const LastButtonsDiv = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  button {
    margin: 0;
  }
`;

const PaddingAndCenterDiv = styled.div`
  padding: 24px;
  padding-bottom: 14px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AppTab = styled(AppTabDiv)`
  width: fit-content;
  padding: 8px 16px 7px 16px;
  border-bottom: ${(props) => (props.selected ? `2px solid ${theme.PRIMARY500}` : `1px solid ${theme.NEUTRAL200}`)};
`;

const TabFiller = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

interface RedTextProps {
  red?: boolean;
}

const ScheduleListDiv = styled.div<RedTextProps>`
  background-color: ${(props) => (props.red ? theme.DANGER50 : "inherit")};
`;

const SettingsModalContainer = styled.div`
  /* height: max-content; */
  height: 226px;
  width: 432px;
  background-color: ${theme.WHITE_COLOR};
`;

const VerticalAlignDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const NewTimeOnCallText = styled(AppText)<ShouldBeGreen>`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => (props.green ? theme.SUCCESS500 : theme.NEUTRAL400)};
  margin-left: 6px;
`;

const NewPhoneNumbertext = styled(AppText)`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 5px;
`;

const TimeOnCallContainer = styled.div`
  display: flex;
  align-items: center;
  width: 172px;
`;

const CallControlDiv = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 33% 33% 33%;
  justify-content: space-between;
  align-items: flex-end;
`;

const StickToBottomContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};

  position: absolute;

  bottom: 0px;
  z-index: 5;
  height: 72px;
  width: 432px;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
`;

const NotesTextArea = styled(AppTextArea)`
  padding-left: 0px;
  padding-top: 20px;
  overflow: auto;
`;

const NotesInfoDiv = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CallComponentContainer = styled.div`
  height: 100vh;
  background: ${theme.surface.neutral.tertiary};
`;

const CallSideBar = styled.div`
  width: 100%;
  min-height: 800px;
  height: 100%;
  padding: 20px 18px 20px 18px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 413px;
`;

const MainContainer = styled.div`
  position: relative;
  padding: 24px 36px;
  padding: 0px;
  padding-top: 18px;
  margin: 0 auto;
  height: 100%;
  max-width: 480px;

  background: ${theme.surface.neutral.tertiary};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  min-height: 100vh;
`;

const DialerButton = styled(AppButton)`
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 5px;
  padding: 0 10px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL200};
  transition: 200ms all ease-in-out;
  :hover {
    border: 1px solid ${theme.NEUTRAL400};
  }
`;

const ParticipantNumber = styled(AppText)`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 10px;
  color: ${theme.NEUTRAL400};
`;

const NotesButton = styled(NewAppButton)`
  width: 80px;
  height: 12px;
  font-size: 8px;
`;

const CallNotesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  cursor: text;
`;

const DayDivider = styled.div`
  padding-top: 5px;
  padding-left: 12px;
  height: 27px;
  background-color: ${theme.NEUTRAL100};
`;

const ScheduleListItem = styled.div`
  padding-top: 16px;
  border: 1px solid ${theme.NEUTRAL100};
`;

const ScheduleListContent = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 34px;
  margin: 16px;
`;

const ScheduleScrollDiv = styled.div`
  overflow: auto;
  height: 275px;
`;

const flash = keyframes`
  from {
    color: ${theme.text.brand.primary};
  }
  to {
    color: ${theme.text.neutral.inverse};
  }
`;

const CallNoteLastUpdatedText = styled(AppText)`
  animation: ${flash} 0.5s ease;
`;

export { Call };
