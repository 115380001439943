import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";

interface PhoenixColorPickerMinimalProps {
  swatchSize?: "small" | "normal";

  // state variable that represent the current color selection
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;

  colorList: string[];
}

export const PhoenixColorPickerMinimal: React.FC<PhoenixColorPickerMinimalProps> = ({ ...props }) => {
  const colorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // event listener closes color menu when clicking outside of it
    const closeOpenMenu = (e: any) =>
      colorRef.current &&
      e.target !== colorRef.current &&
      ![...colorRef?.current?.children].includes(e.target) &&
      setOpen(false);

    document.addEventListener("mousedown", closeOpenMenu);

    return () => document.removeEventListener("mousedown", closeOpenMenu);
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {open && (
        <MenuWrapper>
          <Menu ref={colorRef} swatchSize={props.swatchSize}>
            {props.colorList?.map((color: string) => (
              <Palette
                color={color}
                onClick={() => {
                  props.setColor(color);
                  setOpen(false);
                }}
                key={`${colorRef?.current?.class}-${color}`}
              />
            ))}
            <TraingleWrap>
              <Triangle />
            </TraingleWrap>
          </Menu>
        </MenuWrapper>
      )}
      <Swatch {...props} onClick={() => setOpen(true)} />
    </div>
  );
};

const MenuWrapper = styled.div`
  position: absolute;
  width: 330px;

  animation: 0.1s linear popUp forwards;

  @keyframes popUp {
    0% {
      opacity: 0;
      scale: 0.9;
      bottom: 45px;
      left: -12px;
    }
    100% {
      opacity: 1;
      scale: 1;
      bottom: 65px;
      left: 0;
    }
  }
`;

interface MenuProps {
  swatchSize?: string;
}

const Menu = styled.div<MenuProps>`
  position: relative;
  z-index: 10;

  display: flex;
  gap: 8px 11px;
  flex-wrap: wrap;

  width: fit-content;
  max-width: 330px;
  height: fit-content;
  padding: 16px;

  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  // center over swatch
  transform: ${(props) =>
    props.swatchSize === "small" ? "translateX(calc(-50% + 12px));" : "translateX(calc(-50% + 20px))"};
`;

interface PaletteProps {
  color: string;
  onClick: () => void;
}

const Palette = styled.div<PaletteProps>`
  width: 40px;
  height: 40px;

  // theme.NEUTRAL300 -> rgba conversion to access border opacity.
  border: 1px solid rgba(166, 170, 188, 0.5);
  border-radius: 4px;

  background-color: ${(props) => props.color};
  cursor: pointer;
`;

const Swatch = styled.div<PhoenixColorPickerMinimalProps>`
  width: ${(props) => (props.swatchSize === "small" ? "24px" : "40px")};
  height: ${(props) => (props.swatchSize === "small" ? "24px" : "40px")};

  // theme.NEUTRAL300 -> rgba conversion to access border opacity.
  border: 1px solid rgba(166, 170, 188, 0.5);
  border-radius: 4px;

  background-color: ${(props) => props.color};
  cursor: pointer;
  transition: background-color 0.2s ease;
`;

const TraingleWrap = styled.div`
  position: absolute;
  left: calc(50% - 16px);
  bottom: -30px;

  overflow: hidden;
  width: 34px;
  height: 30px;
`;

const Triangle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL300};

  transform: rotate(45deg) translateX(-6px) translateY(-12px);
`;
