import styled from "styled-components";
import { theme } from "../../utils/theme";

interface AppButtonProps {
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number;
  /**
   * height of button. Default value is 50.
   */
  height?: number;
  /**
   * Makes button green
   */
  primary?: boolean;
  /**
   * Makes button black
   */
  cancel?: boolean;
  /**
   * Makes button white with a border
   */
  white?: boolean;
  /**
   * Makes button red
   */
  red?: boolean;
  /**
   * Gives button a border
   */
  bordered?: boolean;
  /**
   * Makes button text capitalized and bold
   */
  uppercase?: boolean;
  /**
   * Makes translucent and unclickable
   */
  disabled?: boolean;
  /**
   * Custom font size inside button
   */
  fontsize?: number;
  /**
   * Custom button color
   */
  customcolor?: string;
  /**
   * Custom text color. Takes presedence over other color properties
   */
  customtextcolor?: string;
}

export const AppButton = styled.button<AppButtonProps>`
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  width: ${(props) => (!!props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => props.height}px;
  background-color: ${(props) =>
    props.customcolor
      ? `${props.customcolor}`
      : props.cancel
      ? theme.BLACK_COLOR
      : props.primary
      ? theme.SUCCESS500
      : props.white
      ? theme.WHITE_COLOR
      : props.red
      ? theme.ATTENTION700
      : theme.PRIMARY500};
  border-radius: 4px;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  color: ${(props) =>
    props.customtextcolor ? `${props.customtextcolor}` : props.white ? theme.BLACK_COLOR : theme.WHITE_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${(props) => (!!props.fontsize ? `${props.fontsize}` : 18)}px;
  font-weight: ${(props) => (props.uppercase ? 600 : "normal")};
  border: solid ${(props) => (props.bordered ? 1 : 0)}px ${theme.TERTIARY500};
  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    /* Not in original design */
    /* box-shadow: ${(props) => (props.disabled ? "none" : "4px 4px 4px #333333")}; */
  }
`;

AppButton.defaultProps = {
  height: 50,
  primary: false,
  disabled: false,
};
