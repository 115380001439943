import React, { Dispatch, SetStateAction, useContext, useRef } from "react";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI/AppText";
import { ModalContext } from "src/context";
import { useClickOutside } from "src/utils/hooks";

interface ShowMoreMenuProps {
  setShowMoreMenu: Dispatch<SetStateAction<boolean>>;
}

export const ShowMoreMenu = ({ setShowMoreMenu }: ShowMoreMenuProps) => {
  const { setShowChoosePageModalState, setShowChoosePageModal } = useContext(ModalContext);

  const ref = useRef(null);
  useClickOutside(ref, () => setShowMoreMenu(false));

  return (
    <ShowMoreMenuContainer ref={ref}>
      <ShowMoreMenuOption
        onClick={() => {
          setShowChoosePageModal(true);
          setShowChoosePageModalState("new");
        }}
      >
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          Move to New Page
        </AppText>
      </ShowMoreMenuOption>
      <ShowMoreMenuOption
        onClick={() => {
          setShowChoosePageModal(true);
          setShowChoosePageModalState("existing");
        }}
      >
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          Move to Existing Page
        </AppText>
      </ShowMoreMenuOption>
    </ShowMoreMenuContainer>
  );
};

const ShowMoreMenuContainer = styled.div`
  position: absolute;
  z-index: 1;
  overflow: hidden;
  top: 28px;
  right: 8px;

  display: flex;
  flex-direction: column;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  animation: ${theme.lightPopup} 0.15s ease-in-out;
`;

const ShowMoreMenuOption = styled.div`
  padding: 8px;
  background-color: ${theme.WHITE_COLOR};
  cursor: pointer;

  &:hover {
    & > ${AppText} {
      color: ${theme.PRIMARY500};
    }
    background-color: ${theme.PRIMARY100};
  }

  transition: background-color 0.15s ease-in-out;
  & > ${AppText} {
    transition: color 0.15s ease-in-out;
    user-select: none;
  }
`;
