import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CustomObjectSummary } from "../../../../__generated__/graphql";
import { chevron_left, chevron_right } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { CustomObjectEntityDetail } from "../../../Pages/LeadDetail/CustomObjectRelatedRecordData";
import { FETCH_CUSTOM_OBJECT_SUMMARY } from "../../../Pages/LeadDetailV3";
import { AppText, FlexDiv, NewAppButton } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";

interface IRelatedRecordsProps {
  leadId: string;
  customObjectSummary?: CustomObjectSummary[];
}

// top level record

interface IRecord {
  [object_name: string]: ISubRecord[];
}

// sub records

interface ISubRecord {
  [object_field: string]: ISubRecordField;
}

// sub record fields (FE doesn't actully know the field names as they are fetched from SF by the BE)

interface BaseFieldPairs {
  ops_status: "Success" | "Failure";
  ops_status_boolean: boolean;
  error_message: string;
}
interface ISubRecordField extends BaseFieldPairs {
  string?: string | boolean | number | null;
}

const RelatedRecordsLeadCardTab = ({ leadId, customObjectSummary }: IRelatedRecordsProps) => {
  const history = useHistory();
  const [objectSummary, setObjectSummary] = useState<CustomObjectSummary[]>(customObjectSummary || []);
  const [expandedStates, setExpandedStates] = useState<{ [key: string]: boolean }>({});

  const { data, loading } = useQuery(FETCH_CUSTOM_OBJECT_SUMMARY, {
    fetchPolicy: "network-only",
    variables: {
      lead_id: leadId,
    },
    skip: customObjectSummary !== undefined,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    if (data?.fetchAssociatedCustomObjects) {
      console.log("data?.fetchAssociatedCustomObjects", data?.fetchAssociatedCustomObjects);
      setObjectSummary(data?.fetchAssociatedCustomObjects);
      const newState = resetExpandedStates(data?.fetchAssociatedCustomObjects);
      setExpandedStates(newState);
    }
    if (customObjectSummary) {
      setObjectSummary(customObjectSummary);
    }
  }, [data, customObjectSummary]);

  const resetExpandedStates = (data: CustomObjectSummary[]) => {
    return data?.reduce((acc: { [key: string]: boolean }, curr: CustomObjectSummary) => {
      if (curr.id) {
        acc[curr.id] = false;
      }
      return acc;
    }, {} as { [key: string]: boolean });
  };

  const noRecords = objectSummary?.length === 0;

  if (noRecords) {
    return (
      <Main
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100px",
        }}
      >
        <AppText
          style={{
            textAlign: "center",
            color: theme.NEUTRAL400,
          }}
        >
          No record mappings found.
        </AppText>
      </Main>
    );
  }

  const customBackButton = () => {
    return (
      <PhoenixAppButton
        buttonType="ghost-large"
        variant="brand"
        onClick={() => {
          const newState = resetExpandedStates(objectSummary);
          setExpandedStates(newState);
        }}
      >
        <PhoenixIcon svg={chevron_left} size={16} color={theme.PRIMARY600} pointer />
        Back
      </PhoenixAppButton>
    );
  };

  const renderRecordDetail = () => {
    const object_id = Object.keys(expandedStates).find((custom_object_id) => {
      return expandedStates[custom_object_id] === true;
    });

    const object = objectSummary?.find((obj) => obj.id === object_id);

    if (object_id && expandedStates[object_id]) {
      return (
        <>
          {customBackButton()}
          <CustomObjectEntityDetail
            lead_id={leadId}
            isContained
            customObjectDetail={object}
            renderType="LeadCard"
            key={`lead-card-${object_id}`}
          />
        </>
      );
    }
  };

  const isExpanded = () => {
    return Object.values(expandedStates).some((val) => val === true);
  };

  const ExpandButton = ({ onClick, disabled }: { onClick: () => void; disabled: boolean }) => {
    return (
      <PhoenixIcon
        disabled={disabled}
        svg={chevron_right}
        size={24}
        pointer
        color={theme.PRIMARY600}
        onClick={() => {
          onClick();
        }}
      />
    );
  };

  const viewRecord = (id: string) => {
    history.push(`/lead-detail/${leadId}/custom-object/${id}`);
  };

  return (
    <Main>
      {renderRecordDetail()}
      {!isExpanded() &&
        objectSummary?.map((object) => {
          return (
            <ObjectRow key={`lead-card-${object.id}`}>
              <FlexDiv gap={8}>
                <AppText fontSize={12} fontWeight={500}>
                  {object.name}
                </AppText>
                <AppText
                  color={theme.text.brand.primary}
                  cursor="pointer"
                  fontSize={12}
                  fontWeight={500}
                  onClick={() => viewRecord(object?.id as string)}
                  showBorderOnClick={false}
                >
                  ({object.association_count})
                </AppText>
              </FlexDiv>
              <ExpandButton
                onClick={() => {
                  setExpandedStates((prev) => {
                    return {
                      ...prev,
                      [object.id || ""]: !prev[object.id || ""],
                    };
                  });
                }}
                disabled={false}
              />
            </ObjectRow>
          );
        })}
    </Main>
  );
};

const Main = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.PRIMARY100};
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const ObjectRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 8px 0px 16px;
  height: 40px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;

  width: 100%;
  align-self: flex-start;
  background: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL200};
  margin-bottom: 8px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: max-content;
  padding: 0;
  margin-bottom: 12px;
  cursor: pointer;
  p,
  div {
    transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }

  :hover {
    p {
      color: ${theme.PRIMARY500};
    }
    div {
      border-color: ${theme.PRIMARY500};
    }
  }
`;

export { RelatedRecordsLeadCardTab };
