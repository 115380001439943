import React from "react";
import styled from "styled-components";
import { formatSequenceAction } from "../../../../utils/format";
import { theme } from "../../../../utils/theme";
import { AppText, FlexDiv } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import { user_white, user_remove, resting, xIcon } from "../../../../images/NewDesign";

// same functionality as skip lead Client renamed to snooze to match sequence action snooze
const SnoozeLeadButton = ({ handleSnoozeLead }: { handleSnoozeLead: () => void }) => {
  return (
    <>
      <PhoenixAppButton variant="brand" buttonType="ghost-small" onClick={handleSnoozeLead}>
        <PhoenixIcon svg={resting} color={theme.PRIMARY500} size={12} pointer />
        Snooze
      </PhoenixAppButton>
    </>
  );
};

const ReleaseLeadButton = ({ handleReleaseLead }: { handleReleaseLead: () => void }) => {
  return (
    <PhoenixAppButton variant="brand" buttonType="ghost-small" onClick={handleReleaseLead}>
      <PhoenixIcon svg={user_remove} color={theme.PRIMARY500} size={12} pointer />
      Release Lead
    </PhoenixAppButton>
  );
};

// conditional remove from custom queue based on parentType
const RemoveFromCustomQueueButton = ({ handleRemoveFromCustomQueue }: { handleRemoveFromCustomQueue?: () => void }) => {
  return (
    <PhoenixAppButton variant="brand" buttonType="ghost-small" onClick={handleRemoveFromCustomQueue}>
      <PhoenixIcon svg={user_white} color={theme.PRIMARY500} size={12} pointer />
      Remove from Custom Queue
    </PhoenixAppButton>
  );
};

const DefaultSequenceActionHeader = ({
  handleSnoozeLead,
  showDefaultSnoozeButton,
  showDefaultReleaseButton,
  showDefaultRemoveFromCustomQueueButton,
  handleReleaseLead,
  handleRemoveFromCustomQueue,
}: {
  handleSnoozeLead: () => void;
  showDefaultSnoozeButton: boolean;
  showDefaultReleaseButton: boolean;
  showDefaultRemoveFromCustomQueueButton: boolean;
  showDefaultRemoveFromNextDialButton: boolean;
  handleReleaseLead: () => void;
  handleRemoveFromCustomQueue?: () => void;
  handleRemoveFromNextDial?: () => void;
}) => {
  // Default sequence action is always manual call
  const expectedAction = "manualCall";

  return (
    <Main>
      <FlexDiv align="center" gap={8}>
        <PhoenixIcon
          svg={formatSequenceAction(expectedAction)?.icon}
          hoverColor={theme.PRIMARY500}
          size="small"
          pointer
        />
        <AppText fontSize={12} fontWeight={600}>
          {formatSequenceAction(expectedAction)?.text}
        </AppText>
      </FlexDiv>
      <FlexDiv align="center" gap={16}>
        {showDefaultReleaseButton && <ReleaseLeadButton handleReleaseLead={handleReleaseLead} />}
        {showDefaultSnoozeButton && <SnoozeLeadButton handleSnoozeLead={handleSnoozeLead} />}
        {showDefaultRemoveFromCustomQueueButton && (
          <RemoveFromCustomQueueButton handleRemoveFromCustomQueue={handleRemoveFromCustomQueue} />
        )}
      </FlexDiv>
    </Main>
  );
};

const Main = styled.div`
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.fill.brand.secondary};
  border-bottom: 1px solid ${theme.border.brand.tertiary};
`;
export { DefaultSequenceActionHeader };
