import React from "react";
import { NewAppMultiSelectProps, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";

type FilterSelectProps = Omit<NewAppMultiSelectProps, "onChange"> & {
  computedValue?: any;
  name: string;
  onChange?: (selected: OptionItem) => void;
  value?: string | null;
  width?: number;
};

const style = {
  backgroundColor: theme.NEUTRAL100,
  border: `1px solid ${theme.NEUTRAL100}`,
};

const FilterSelect = ({
  computedValue,
  name,
  onChange,
  options = [],
  placeholder,
  value,
  width,
  ...props
}: FilterSelectProps) => {
  return (
    <PhoenixMultiSelect
      isClearable={false}
      isMulti={false}
      marginBottom={false}
      menuPosition="fixed"
      menuShouldBlockScroll
      minHeight={32}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={computedValue ?? options.find((f) => f.value === value) ?? null}
      width={width}
      style={style}
      {...props}
    />
  );
};

export default FilterSelect;
