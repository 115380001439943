import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useState, useContext, useEffect, useMemo } from "react";
import { NewAppSelect, AppText } from "../../UI";
import { theme } from "../../../utils/theme";
import { gql, useLazyQuery } from "@apollo/client";
import { formatPhoneNumber } from "../../../utils/format";
import { PhoenixMultiSelect } from "../../UI/Phoenix";

const FETCH_DIAL_NUMBERS = gql`
  query fetchDialNumbers($lead_phone_number: String, $lead_id: String) {
    fetchDialNumbers(lead_phone_number: $lead_phone_number, lead_id: $lead_id)
  }
`;

interface PhoneNumberProp {
  leadPhoneNumber: string;
  leadId: string;
  dialAsNumber: string | undefined;
  setDialAsNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const WillDialAs: React.FC<PhoneNumberProp> = ({ leadPhoneNumber, leadId, dialAsNumber, setDialAsNumber }) => {
  const [
    fetchDialNums,
    { data: dataOptions, loading: loadingOptions, error: errorOptions, refetch: refetchOptions },
  ] = useLazyQuery(FETCH_DIAL_NUMBERS, {
    fetchPolicy: "no-cache",
    onCompleted() {
      console.log("fetched: ", dataOptions);
      if (!!dataOptions?.fetchDialNumbers?.length) {
        setDialAsNumber(dataOptions?.fetchDialNumbers[0] ?? "");
      } else {
        setDialAsNumber("");
      }
    },
  });

  useEffect(() => {
    console.log("WillDialas leadId: ", leadId);
    fetchDialNums({
      variables: {
        lead_phone_number: leadPhoneNumber || "",
        lead_id: leadId,
      },
    });
  }, [leadId]);

  return (
    <Container>
      <AppText style={{ whiteSpace: "nowrap", color: theme.WHITE_COLOR, paddingRight: 10 }}>Will Dial As</AppText>
      <div style={{ width: "100%" }}>
        <PhoenixMultiSelect
          isMulti={false}
          name="dialAsNumber"
          options={dataOptions?.fetchDialNumbers?.map((item: any) => ({ label: formatPhoneNumber(item), value: item }))}
          placeholder={formatPhoneNumber(dialAsNumber)}
          onChange={(e: any) => setDialAsNumber(e.value)}
          value={{ label: formatPhoneNumber(dialAsNumber), value: dialAsNumber }}
          isClearable={false}
          marginBottom={false}
          error={false}
          minHeight={24}
          maxHeight={24}
          lineHeight="10px"
          style={{ overflow: "hidden" }}
          dropdownArrowStyle={{ padding: "2px 8px 8px 8px" }}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8;
`;

export { WillDialAs };
