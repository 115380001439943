import React, { useState, useCallback, useRef, Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { caret_down, chevron_down, chevron_up, folder, more_vertical } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { AppText } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixIcon } from "../../UI/Phoenix";
import { ProfileImage } from "../SettingSegments/ProfileImage";
import { useClickOutside } from "../../../utils/hooks";
import { ModalContext } from "../../../context";
import { loggedInUser } from "src/apollo/cache";

interface FolderProps {
  data: Folder;
  handleRenameClick: (item: Folder) => void;
  handleDeleteClick: (item: Folder, folder: boolean) => void;
  handleShareClick: (id: string) => void;
  setMoveCall: Dispatch<SetStateAction<boolean>>;
  setCurrentConferenceID: Dispatch<SetStateAction<string>>;
  setSelectedFolder: Dispatch<SetStateAction<any>>;
}

interface Folder {
  id: string;
  name: string;
  size: number;
  conferences: any[];
  owner: Record<string, any>;
}

export const Folder: React.FC<FolderProps> = ({
  data,
  handleRenameClick,
  handleDeleteClick,
  handleShareClick,
  setMoveCall,
  setCurrentConferenceID,
  setSelectedFolder,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [folderSort, setFolderSort] = useState<"date" | "rep" | "contact" | "call_result" | "duration">("date");
  const [sortOrder, setSortOrder] = useState<"asc" | "des">("asc");

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [callMenu, setCallMenu] = useState<undefined | number>(undefined);

  //TODO: add type
  const [selectedCall, setSelectedCall] = useState<any>(null);

  const handleColumnClick = useCallback(
    (sortOption: "date" | "rep" | "contact" | "call_result" | "duration") => {
      setFolderSort(sortOption);
      if (folderSort === sortOption) {
        setSortOrder(sortOrder === "asc" ? "des" : "asc");
      } else {
        setSortOrder("asc");
      }
    },
    [folderSort, sortOrder],
  );

  const sortFunctions = {
    date: (a: any, b: any) => moment(a?.created_at).diff(moment(b?.created_at)),
    rep: (a: any, b: any) => a?.salesperson?.toLowerCase()?.localeCompare(b.salesperson.toLowerCase()),
    contact: (a: any, b: any) => a?.lead?.full_name?.toLowerCase()?.localeCompare(b.lead.full_name.toLowerCase()),
    call_result: (a: any, b: any) =>
      a?.latest_call_result?.label?.toLowerCase()?.localeCompare(b.latest_call_result.label.toLowerCase()),
    duration: (a: any, b: any) => a.duration - b.duration,
  };
  const sortedConferences = data.conferences.slice().sort((a: any, b: any) => {
    const sortFunction = sortFunctions[folderSort];
    return sortOrder === "asc" ? sortFunction(a, b) : sortFunction(b, a);
  });

  return (
    <>
      {showMenu && (
        <FolderMenu
          data={data}
          setShowMenu={setShowMenu}
          handleRenameClick={handleRenameClick}
          handleDeleteClick={handleDeleteClick}
          handleShareClick={handleShareClick}
        />
      )}
      <FolderContainer expanded={expanded} callCount={data.conferences.length}>
        <FolderHeader>
          <FlexDiv align="center" gap={16}>
            <PhoenixIcon
              svg={folder}
              alt="folder"
              size={24}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
            />
            <AppText fontSize={16} fontWeight={500} lineHeight={22}>
              {data.name}
              <CallCountSpan>({data.size})</CallCountSpan>
            </AppText>
          </FlexDiv>

          <FlexDiv align="center" gap={16}>
            <PhoenixIcon
              svg={more_vertical}
              alt="more"
              size={16}
              variant="brand"
              pointer
              onClick={() => setShowMenu(true)}
            />
            <PhoenixIcon
              svg={expanded ? chevron_up : chevron_down}
              alt="expand"
              size={24}
              variant="brand"
              pointer
              onClick={() => setExpanded(!expanded)}
            />
          </FlexDiv>
        </FolderHeader>

        <FolderBody expanded={expanded} callCount={data.conferences.length}>
          {!!data.conferences.length ? (
            <CallItemContainer>
              <CallItemCell width="162px" pointer onClick={() => handleColumnClick("date")}>
                <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                  Date/Time
                </AppText>
                {folderSort === "date" && (
                  <PhoenixIcon
                    svg={caret_down}
                    variant="brand"
                    hoverColor={theme.PRIMARY500}
                    size={16}
                    fillIcon
                    pointer
                    style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
                  />
                )}
              </CallItemCell>
              <CallItemCell width="186px" pointer onClick={() => handleColumnClick("rep")}>
                <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                  Rep
                </AppText>
                {folderSort === "rep" && (
                  <PhoenixIcon
                    svg={caret_down}
                    variant="brand"
                    hoverColor={theme.PRIMARY500}
                    size={16}
                    fillIcon
                    pointer
                    style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
                  />
                )}
              </CallItemCell>
              <CallItemCell width="186px" pointer onClick={() => handleColumnClick("contact")}>
                <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                  Contact Name
                </AppText>
                {folderSort === "contact" && (
                  <PhoenixIcon
                    svg={caret_down}
                    variant="brand"
                    hoverColor={theme.PRIMARY500}
                    size={16}
                    fillIcon
                    pointer
                    style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
                  />
                )}
              </CallItemCell>
              <CallItemCell width="186px" pointer onClick={() => handleColumnClick("call_result")}>
                <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                  Call Result
                </AppText>
                {folderSort === "call_result" && (
                  <PhoenixIcon
                    svg={caret_down}
                    variant="brand"
                    hoverColor={theme.PRIMARY500}
                    size={16}
                    fillIcon
                    pointer
                    style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
                  />
                )}
              </CallItemCell>
              <CallItemCell width="112px" pointer onClick={() => handleColumnClick("duration")}>
                <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                  Duration
                </AppText>
                {folderSort === "duration" && (
                  <PhoenixIcon
                    svg={caret_down}
                    variant="brand"
                    hoverColor={theme.PRIMARY500}
                    size={16}
                    fillIcon
                    pointer
                    style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
                  />
                )}
              </CallItemCell>
              <CallItemCell width="112px" />
              <CallItemCell width="46px" />
            </CallItemContainer>
          ) : (
            <EmptyFolder />
          )}
          {sortedConferences?.map((conference: any, index: number) => (
            <CallItem
              key={conference.id}
              data={conference}
              folderData={data}
              index={index}
              setCallMenu={setCallMenu}
              setSelectedCall={setSelectedCall}
              setSelectedFolder={setSelectedFolder}
            />
          ))}

          {callMenu !== undefined && (
            <CallMenu
              setCallMenu={setCallMenu}
              pos={callMenu}
              listLen={sortedConferences.length}
              setMoveCall={setMoveCall}
              setCurrentConferenceID={setCurrentConferenceID}
              selectedCall={selectedCall}
              handleDeleteClick={handleDeleteClick}
            />
          )}
        </FolderBody>
      </FolderContainer>
    </>
  );
};

interface IFolderBody {
  expanded: boolean;
  callCount: number;
}

interface FolderContainerProps {
  expanded: boolean;
  callCount: number;
}

const FolderContainer = styled.div<FolderContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background-color: ${theme.fill.neutral.primary};

  transition: min-height 0.3s ease-in-out;
`;

const CallCountSpan = styled.span`
  padding-left: 4px;

  color: ${theme.text.neutral.secondary};
  font-size: 14px;
`;

const FolderHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-start;

  width: 100%;
  height: 72px;
  padding: 24px 16px;
`;

const CallItemCell = styled.div<{ width?: string; pointer?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;

  min-width: ${(props) => props.width ?? "fit-content"};
  max-width: ${(props) => props.width ?? "fit-content"};

  padding: 0px 16px;

  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
`;

const FolderBody = styled.div<IFolderBody>`
  display: flex;
  flex-direction: column;

  position: relative;

  overflow-y: ${(props) => (!props.callCount ? "hidden" : "auto")};

  width: 100%;
  max-height: 300px;
  height: ${(props) =>
    props.expanded && !!props.callCount
      ? `calc(46px * ${props.callCount + 1})`
      : props.expanded && !props.callCount
      ? "120px"
      : "0px"};

  border-top: ${(props) => (props.expanded ? "1px" : "0px")} solid ${theme.border.neutral.secondary};

  transition: height 0.3s ease-in-out, border 0.3s ease-in-out;

  & > :nth-child(even) {
    background-color: ${theme.surface.brand.secondary};
    & > ${CallItemCell} {
      background-color: ${theme.surface.brand.secondary};
    }
  }
`;

const EmptyFolder: React.FC = () => {
  return (
    <EmptyFolderContainer align="center" justify="center">
      <AppText fontSize={16} fontWeight={400} lineHeight={22} color={theme.text.neutral.secondary}>
        There are no call recordings in this folder.
      </AppText>
    </EmptyFolderContainer>
  );
};

const EmptyFolderContainer = styled(FlexDiv)`
  width: 100%;
  min-height: 120px;
  max-height: 120px;
  background-color: ${theme.fill.brand.secondary};
`;

interface CallItemProps {
  data: any;
  folderData: any;
  index: number;
  setCallMenu: Dispatch<SetStateAction<number | undefined>>;
  // TODO: add type
  setSelectedCall: Dispatch<SetStateAction<any>>;
  setSelectedFolder: Dispatch<SetStateAction<any>>;
}

const CallItem: React.FC<CallItemProps> = ({
  data,
  folderData,
  index,
  setCallMenu,
  setSelectedCall,
  setSelectedFolder,
}) => {
  return (
    <CallItemContainer>
      <CallItemCell width="162px">
        <AppText fontSize={12} fontWeight={400} lineHeight={18} noWrap>
          {moment(data?.created_at).format("MM/DD/YYYY hh:mm A")}
        </AppText>
      </CallItemCell>
      <CallItemCell width="186px">
        <ProfileImage
          profileImage={data?.latest_activity?.user?.profile_image}
          diameter={24}
          borderDiameter={24}
          borderWidth={0}
        />
        <AppText
          fontSize={12}
          fontWeight={400}
          lineHeight={18}
          noWrap
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {data?.salesperson}
        </AppText>
      </CallItemCell>
      <CallItemCell width="186px">
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          {data?.lead?.full_name}
        </AppText>
      </CallItemCell>
      <CallItemCell width="186px">
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          <CallResultPill result={data?.latest_call_result?.label} />
        </AppText>
      </CallItemCell>
      <CallItemCell width="112px">
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          {moment.utc((data?.duration || 0) * 1000).format("m:ss")}
        </AppText>
      </CallItemCell>
      <CallItemCell width="112px">
        <AppText
          fontSize={12}
          lineHeight={18}
          fontWeight={600}
          color={theme.text.brand.primary}
          noWrap
          style={{ cursor: "pointer", borderBottom: "none" }}
          onClick={() => window.open(`/call-report/${data?.id}`, "_blank", "noreferrer")}
        >
          Call Report
        </AppText>
      </CallItemCell>
      <CallItemCell width="46px">
        <PhoenixIcon
          svg={more_vertical}
          alt="more"
          size={16}
          variant="brand"
          pointer
          onClick={() => {
            setCallMenu(index);
            setSelectedCall(data);
            setSelectedFolder({
              id: folderData?.id,
              name: folderData?.name,
              conferences: folderData?.conferences,
              owner: folderData?.owner,
            });
          }}
        />
      </CallItemCell>
    </CallItemContainer>
  );
};

const CallItemContainer = styled.div`
  display: flex;

  width: 100%;
  min-height: 40px;
`;

interface CallResultPillProps {
  result: string;
}

const CallResultPill: React.FC<CallResultPillProps> = ({ result }) => {
  const isSale = result?.toLowerCase() === "sale";
  const color = isSale ? theme.text.neutral.inverse : theme.text.brand.primary;
  const backgroundColor = isSale ? theme.fill.brand.primary : theme.fill.brand.secondary;
  return (
    <PillContainer backgroundColor={backgroundColor}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color}>
        {result || "No Result"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;

  padding: 4px 8px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
`;

interface FolderMenuProps {
  data: Folder;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  handleRenameClick: (item: Folder) => void;
  handleDeleteClick: (item: Folder, folder: boolean) => void;
  handleShareClick: (folder_id: string) => void;
}

const FolderMenu: React.FC<FolderMenuProps> = ({
  setShowMenu,
  handleRenameClick,
  handleDeleteClick,
  handleShareClick,
  data,
}) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => {
    setShowMenu(false);
  });

  return (
    <PopupContainer ref={containerRef}>
      <MenuItem onClick={() => handleRenameClick(data)}>Rename Folder</MenuItem>
      {(loggedInUser()?.id === data?.owner?.id || loggedInUser()?.role === "ADMIN") && (
        <MenuItem onClick={() => handleDeleteClick(data, true)}>Delete Folder</MenuItem>
      )}
      <MenuItem onClick={() => handleShareClick(data?.id)}>Share Folder</MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div<{ topPos?: number; listLen?: number }>`
  position: absolute;
  top: ${({ topPos, listLen }) =>
    topPos !== undefined && listLen ? `${Math.min(40 * topPos + 46, listLen * 40 - 46)}px` : "40px"};
  left: ${({ topPos, listLen }) => (topPos !== undefined && listLen ? "800px" : "auto")};
  right: ${({ topPos, listLen }) => (topPos !== undefined && listLen ? "auto" : "70px")};
  z-index: 10;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;

  width: 152px;
  height: 40px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;

interface CallMenuProps {
  setCallMenu: Dispatch<SetStateAction<number | undefined>>;
  listLen: number;
  pos: number;
  setMoveCall: Dispatch<SetStateAction<boolean>>;
  setCurrentConferenceID: Dispatch<SetStateAction<string>>;
  selectedCall: any;
  handleDeleteClick: (item: any, folder: boolean) => void;
}

const CallMenu: React.FC<CallMenuProps> = ({
  setCallMenu,
  pos,
  listLen,
  setMoveCall,
  setCurrentConferenceID,
  selectedCall,
  handleDeleteClick,
}) => {
  const containerRef = useRef(null);

  const { setMoveToAnotherFolderModal } = useContext(ModalContext);

  useClickOutside(containerRef, () => {
    setCallMenu(undefined);
  });

  return (
    <PopupContainer ref={containerRef} topPos={pos} listLen={listLen}>
      <MenuItem
        onClick={() => {
          setMoveCall(true);
          setMoveToAnotherFolderModal(true);
          setCurrentConferenceID(selectedCall?.id);
        }}
      >
        Move Recording
      </MenuItem>
      <MenuItem onClick={() => handleDeleteClick(selectedCall, false)}>Delete Recording</MenuItem>
    </PopupContainer>
  );
};
