export const optionsWhen = [
  {
    label: "Before",
    value: "before",
  },
  {
    label: "After",
    value: "after",
  },
];

export const optionsBoolean = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const optionsRole = [
  {
    label: "Sales Manager",
    value: "SM",
  },
  {
    label: "Sales Development Representative",
    value: "SDR",
  },
  {
    label: "Account Executive",
    value: "AE",
  },
];

export const getRoleLabel = (optionRole: string) => {
  const results = optionsRole.filter((r) => r.value === optionRole);
  if (!results || !results.length) {
    return "";
  }
  return results[0].label;
};

export const fallback_default_times = [
  { day: "Monday", start_hour: 9, start_minute: 0, end_hour: 5, end_minute: 0 },
  { day: "Tuesday", start_hour: 9, start_minute: 0, end_hour: 5, end_minute: 0 },
  { day: "Wednesday", start_hour: 9, start_minute: 0, end_hour: 5, end_minute: 0 },
  { day: "Thursday", start_hour: 9, start_minute: 0, end_hour: 5, end_minute: 0 },
  { day: "Friday", start_hour: 9, start_minute: 0, end_hour: 5, end_minute: 0 },
  { day: "Saturday", start_hour: undefined, start_minute: undefined, end_hour: undefined, end_minute: undefined },
  { day: "Sunday", start_hour: undefined, start_minute: undefined, end_hour: undefined, end_minute: undefined },
];
