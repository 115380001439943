import styled from "styled-components";
import { theme } from "../../../../../utils/theme";
interface AppTextProps {
  fontSize?: number;
  fontWeight?: number;
  variant?: "primary" | "error" | "success" | "warning" | "white";
  align?: "left" | "center" | "right" | string;
  fontFamily?: string;
  lineHeight?: number;
}

export const WidgetText = styled.p<AppTextProps>`
  font-family: ${(props) => props.fontFamily || theme.PRIMARY_FONT};
  font-size: ${(props) => props.fontSize || 12}px;
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  letter-spacing: 0.2px;
  width: fit-content;
  margin: 0;
  font-stretch: normal;
  font-style: "normal";
  line-height: ${(props) => props.lineHeight || 20}px;
  border-bottom: ${(props) => (!!props.onClick ? 4 : 0)}px solid ${theme.PRIMARY500};
  font-style: normal;
  // text align
  text-align: ${(props) => props.align || "left"};
  /* :hover {
    cursor: ${(props) => (!!props.onClick ? "pointer" : "text")};
  } */
  color: ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
        color: ${theme.PRIMARY500};
    `;
      case "error":
        return `
          color:${theme.ATTENTION700};

      `;
      case "success":
        return `
          color:${theme.SUCCESS500};

      `;
      case "warning":
        return `
          color:${theme.TERTIARY500};
      `;
      case "white":
        return `
          color:${theme.WHITE_COLOR};
      `;
      default:
        return `
          color:${theme.BLACK_COLOR};
    `;
    }
  }};
`;

export const AppErrorText = styled.p`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.ATTENTION700};
  margin: 0;
`;

export const AppTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
`;

export const AppFormTitle = styled.p`
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.BLACK_COLOR};
`;

export const AppPanelTitle = styled.h2`
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
`;

export const AppH3 = styled.h3``;
export const AppH4 = styled.h4``;
