import { gql, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../../context";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { PhoenixInputField } from "../../Field/Phoenix";
import { AppErrorText, AppText, FlexDiv, SkeletonBlock } from "../../UI";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { Modal } from "../Modal";

const CREATE_OR_UPDATE_FORM = gql`
  mutation CreateOrUpdateOPSIQForm($opsiqFormInput: OpsiqFormInput!) {
    createOrUpdateOPSIQForm(OpsiqFormInput: $opsiqFormInput) {
      id
    }
  }
`;

const validationSchema = Yup.object({
  name: Yup.string().required("Form name is required"),
  id: Yup.string().notRequired(),
});

export const UpdateFormModal = () => {
  const { showUpdateFormModal, setShowUpdateFormModal, updateFormModalData } = useContext(ModalContext);

  const [
    createOrUpdateOPSIQForm,
    { data: createOrUpdateOPSIQFormData, error: createOrUpdateOPSIQFormError, loading: createOrUpdateOPSIQFormLoading },
  ] = useMutation(CREATE_OR_UPDATE_FORM, {
    onCompleted: (data) => {
      console.log("createOrUpdateOPSIQForm", data);
      const newForm = !updateFormModalData.id;

      appToast(`${newForm ? "Created" : "Updated"} form successfully`);

      setShowUpdateFormModal(false);
    },
    onError: (error) => {
      console.log("createOrUpdateOPSIQForm error", error);
    },
    refetchQueries: ["FetchOPSIQForm", "FetchOPSIQForms"],
  });
  return (
    <Modal
      open={showUpdateFormModal}
      onClose={() => {
        setShowUpdateFormModal(false);
      }}
      popupAnimation
    >
      <Formik
        initialValues={{
          id: updateFormModalData?.id || undefined,
          name: updateFormModalData?.name || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          createOrUpdateOPSIQForm({
            variables: {
              opsiqFormInput: {
                opsiq_form_id: values.id,
                form_name: values.name,
              },
            },
          });
        }}
      >
        {({ isValid, dirty, errors, values, submitForm }) => {
          console.log(errors);
          return (
            <Form>
              <Main>
                <ModalBody>
                  <AppText fontSize={16} fontWeight={600}>
                    {updateFormModalData?.id ? "Rename Form" : "Create New Form"}
                  </AppText>
                  {createOrUpdateOPSIQFormError ? (
                    <AppErrorText>Something went wrong</AppErrorText>
                  ) : createOrUpdateOPSIQFormLoading ? (
                    <SkeletonBlock width={396} height={40} borderRadius={4} />
                  ) : (
                    <>
                      <FlexDiv direction="column" gap={8}>
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          Name
                        </AppText>
                        <PhoenixInputField name="name" placeholder="A New Form" width={396} displayNoContextText />
                      </FlexDiv>
                    </>
                  )}
                </ModalBody>
                <ModalButtonContainer>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={() => setShowUpdateFormModal(false)}
                  >
                    Cancel
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    buttonType="secondary"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    disabled={!isValid || !dirty || createOrUpdateOPSIQFormLoading}
                    onClick={submitForm}
                  >
                    Continue
                  </PhoenixAppButton>
                </ModalButtonContainer>
              </Main>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
  height: 312px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 40px;
  padding: 40px 40px 74px 40px;
  height: 100%;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 40px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;
