import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { AdvancedImage } from "@cloudinary/react";
import * as Sentry from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import { restAPI } from "../../../apollo";
import { currentCallState } from "../../../apollo/cache";
import { CallContext } from "../../../context";
import transferPending from "../../../images/lotties/transferPending.json";
import { ABLY_OPTIONS } from "../../../services/ably";
import { cloudinary } from "../../../services/cloudinary";
import { formatTimeDifferenceByDelineation } from "../../../utils/format";
import { useDebouncedState } from "../../../utils/hooks";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { BACKEND_URL, DEFAULT_CLOUDINARY_IMAGE } from "../../../utils/variables";
import { AppText, Loading, NewAppButton } from "../../UI";
import { transferFailure } from "../../../images/lotties";
import { PhoenixAppButton, PhoenixInput } from "../../UI/Phoenix";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { PhoenixInputField } from "../../Field/Phoenix";
import { TransferAttemptStatus } from "../../../__generated__/graphql";
import { ExternalStepOne } from "./ExternalTransferStepOne";
import { ExternalStepTwo } from "./ExternalTransferStepTwo";

const ExternalTransferStateComponent: React.FC = () => {
  const {
    callLeadId,
    setTransferError,
    transferState,
    setTransferState,
    intentId,
    callOptionStackPeek,
    callOptionStackPop,
    updateCallHoldStatus,
    transfer_attempt_id,
    setTransferAttemptId,
    cancelTransferGlobal,
    callSid,
    externalRepCallSid,
    setExternalRepCallSid,
  } = useContext(CallContext);

  const [externalPhoneNumber, setExternalPhoneNumber] = useState("");
  let [step, setStep] = useState(1);

  useEffect(() => {
    return () => {
      setTransferState("Idle");
      setTransferError("");
    };
  }, []);

  // if the call is ended before transfering
  useEffect(() => {
    const callEnded = !currentCallState().onCall;
    // current functionaly allows reps to continue call after lead has left
    if (externalRepCallSid && callEnded && transferState !== "Success") {
      cancelTransferGlobal(externalRepCallSid);
      callOptionStackPop();
    }
  }, [currentCallState().onCall]);

  // transfer state logic
  useEffect(() => {
    if (transferState === "Cancelled") {
      updateCallHoldStatus(false);
      if (callOptionStackPeek() === "external-transfer") {
        setTransferAttemptId("");
      }
    } else if (transferState === "Rejected" || transferState === "Fail") {
      if (callOptionStackPeek() === "external-transfer") {
        setTransferAttemptId("");
      }
    }
  }, [transferState]);

  configureAbly(ABLY_OPTIONS);

  const external_transfer_attempt_channel = transfer_attempt_id ? `transferAttempt:${transfer_attempt_id}` : "";

  useChannel(external_transfer_attempt_channel, (payload) => {
    console.log("inbound transfer from Ably: ", payload, external_transfer_attempt_channel);
    if (!payload?.data?.status) {
      Sentry.captureEvent({
        message: `No transfer status update in payload from Ably in TransferComponentV3 `,
        extra: {
          payload,
        },
      });
      return;
    }

    // If state changes in real-time, update local state
    if (payload?.data?.status !== transferState) {
      setTransferState(payload?.data?.status);
    }
  });

  const transferCall = async (call_sid: string, phone_number: string) => {
    setTransferState("InProgress");
    updateCallHoldStatus(true);

    if (phone_number) {
      const response = await restAPI.post(`${BACKEND_URL}/twilio/transferCallToExternalRep`, {
        intent_id: intentId,
        lead_id: callLeadId,
        call_sid,
        external_rep_number: phone_number,
        is_external_transfer: true,
      });

      if (response.data.error) {
        setTransferError(response.data.error);
        return appToast(response.data.error);
      }
      const { transfer_attempt_id, call_sid: external_rep_call_sid } = response.data;

      if (external_rep_call_sid) {
        setExternalRepCallSid(external_rep_call_sid);
      }
      setTransferAttemptId(transfer_attempt_id);
      setExternalPhoneNumber(phone_number);
      setStep(2);
    }
  };
  // const kickExternalTransferNumber = async () => {
  //   const response = await restAPI.post(`${BACKEND_URL}/twilio/kickExternalTransferParticipant`, {
  //     call_sid: callSid,
  //     external_sid: externalRepCallSid,
  //   });

  //   if (response.data.error) {
  //     setTransferError(response.data.error);
  //     return appToast(response.data.error);
  //   } else {
  //     setExternalRepCallSid("");
  //   }
  // };

  if (step === 1) {
    return <ExternalStepOne callSid={callSid!} transferCall={transferCall} />;
  }
  return (
    <ExternalStepTwo
      externalPhoneNumber={externalPhoneNumber}
      // kickExternalTransferNumber={kickExternalTransferNumber}
      setStep={setStep}
    />
  );
};

export { ExternalTransferStateComponent };
