export const contractOptions = [
  {
    label: "3 Months",
    value: "3 Months",
  },
  {
    label: "6 Months",
    value: "6 Months",
  },
  {
    label: "12 Months",
    value: "12 Months",
  },
  {
    label: "24 Months",
    value: "24 Months",
  },
  {
    label: "Month to Month",
    value: "Month to Month",
  },
];
