import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import * as Yup from "yup";
import { MRR_LABEL } from "../../apollo/query";
import { LeadFilterContext } from "../../context";
import { iconButtonArrowGray, iconNewMoveRow, searchIcon } from "../../images";
import { getTitle } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { InputField } from "../Field";
import { AppErrorText, AppText, FlexDiv, Loading } from "../UI";
import { AppCheckbox } from "../UI/AppCheckbox";
import { NewAppButton } from "../UI/NewAppButton";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { info } from "src/images/NewDesign";
const GET_COLUMNS = gql`
  query fetchColumns {
    fetchUser {
      id
      visible_my_leads_columns_computed
    }
    fetchMyLeadsColumnOptions
  }
`;

const UPDATE_COLUMNS = gql`
  mutation saveRepSelectedColumns($selected_columns: [String!]!) {
    saveRepSelectedColumns(selected_columns: $selected_columns) {
      id
      visible_my_leads_columns_computed
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  setIsInitialPageLoad: (value: boolean) => void;
  refetchTable: () => void;
}

const searchboxSchema = Yup.object().shape({
  available_fields_search: Yup.string().notRequired(),
});

interface MyFormikProps {
  available_fields_search: string;
}

const MyLeadsManageColumnsModal: React.FC<Props> = ({ onClose, open, setIsInitialPageLoad, refetchTable }) => {
  const { leadSort, setLeadSort, setLeadSortAscending, refetchUserSavedHeaders } = useContext(LeadFilterContext);

  const [dataColumns, setDataColumns] = useState<string[]>([]);
  const [selectedAvailableFields, setSelectedAvailableFields] = useState<string[]>([]);
  const [selectedVisibleFields, setSelectedVisibleFields] = useState<string[]>([]);
  const [visibleFields, setVisibleFields] = useState<string[]>([]);

  const { data, loading, error } = useQuery(GET_COLUMNS, {
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: mrrLabel } = useQuery(MRR_LABEL);

  const [updateColumns, { data: dataUpdate, loading: loadingUpdate, error: errorUpdate }] = useMutation(
    UPDATE_COLUMNS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const updatedColumns = data.saveRepSelectedColumns.visible_my_leads_columns_computed;
        appToast("Columns updated");

        // if the new columns don't include the current sort column, reset the sort column to the first column
        if (!updatedColumns.includes(leadSort)) {
          setLeadSort(updatedColumns[0]);
          setLeadSortAscending(true);
        }
        // refetch the table's columns
        refetchUserSavedHeaders();

        // refetch the leads
        refetchTable();

        setIsInitialPageLoad(false);
      },
      onError: ({ message }) => {
        appToast(message);
      },
      // refetchQueries: ["filterLeads"],
    },
  );

  useEffect(() => {
    if (!data || loading || error) {
      return;
    }
    setDataColumns(data.fetchMyLeadsColumnOptions);
    setVisibleFields(data.fetchUser.visible_my_leads_columns_computed);
  }, [data]);

  const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setVisibleFields(result);
  };

  const onDragEndReps = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderReps(visibleFields, result.source.index, result.destination.index);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ transform: "none", left: "calc(50% - 396px)", top: "calc(50% - 245px)" }}
    >
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Manage Columns</TitleText>
        </TitleDiv>
        <Formik
          initialValues={{
            available_fields_search: "",
          }}
          validationSchema={searchboxSchema}
          onSubmit={() => {}}
        >
          {({ values }: FormikProps<MyFormikProps>) => {
            return (
              <ScrollDiv>
                <RepListsGrid>
                  <SelectRepsContainer>
                    <AppText>Available fields</AppText>
                    <RepsListContainer>
                      <SearchRepsInput
                        // value={searchTerm}
                        // onChange={(e: any) => setSearchTerm(e.target.value)}
                        name="available_fields_search"
                        placeholder="Search Fields"
                      />
                      <RepsScrollDiv>
                        {!!loading ? (
                          <Loading />
                        ) : error ? (
                          <AppErrorText>Error loading reps</AppErrorText>
                        ) : (
                          !!dataColumns &&
                          dataColumns
                            .slice()
                            .filter(
                              (item: any) =>
                                !values.available_fields_search ||
                                `${item}`.toLowerCase().includes(values.available_fields_search.toLowerCase()),
                            )
                            .filter((item) => !visibleFields.includes(item))
                            ?.map((item: any) => {
                              const key = getTitle(item, mrrLabel);
                              return (
                                <FlexDiv gap={8}>
                                  <AppCheckbox
                                    title={key}
                                    checked={selectedAvailableFields.includes(item)}
                                    onClick={() => {
                                      selectedAvailableFields.includes(item)
                                        ? setSelectedAvailableFields(
                                            selectedAvailableFields.slice().filter((li: string) => li !== item),
                                          )
                                        : setSelectedAvailableFields([...selectedAvailableFields, item]);
                                    }}
                                  />

                                  {key === "Notes" ? (
                                    <>
                                      <PhoenixStyledTooltip id="notes-helper" />
                                      <PhoenixIcon
                                        style={{ marginTop: "4px" }}
                                        svg={info}
                                        size={16}
                                        variant="brand"
                                        data-for="notes-helper"
                                        data-tip="You must include notes filter to make this column visible"
                                      />
                                    </>
                                  ) : null}
                                </FlexDiv>
                              );
                            })
                        )}
                      </RepsScrollDiv>
                    </RepsListContainer>
                  </SelectRepsContainer>

                  <FlexColDiv>
                    <IconButton
                      style={{ marginBottom: "8px" }}
                      onClick={() => {
                        setVisibleFields([...visibleFields, ...selectedAvailableFields]);
                        setSelectedAvailableFields([]);
                      }}
                    >
                      <img src={iconButtonArrowGray} alt="Add selected reps as assigned" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setVisibleFields(visibleFields.slice().filter((item) => !selectedVisibleFields.includes(item)));
                        setSelectedVisibleFields([]);
                      }}
                    >
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={iconButtonArrowGray}
                        alt="Remove selected reps from assigned"
                      />
                    </IconButton>
                  </FlexColDiv>

                  <SelectRepsContainer>
                    <SpaceBetweenDiv>
                      <AppText>Visible fields</AppText>
                    </SpaceBetweenDiv>
                    <RepsListContainer>
                      <DragDropContext onDragEnd={onDragEndReps}>
                        <Droppable droppableId="droppablecolfields">
                          {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <DragElements>
                                {loading ? (
                                  <Loading />
                                ) : error ? (
                                  <AppErrorText>Error loading reps</AppErrorText>
                                ) : (
                                  !!visibleFields && (
                                    <>
                                      {visibleFields
                                        .slice()
                                        .sort((a, b) => visibleFields.indexOf(a) - visibleFields.indexOf(b))
                                        ?.map((item: any, index: number) => (
                                          <Draggable key={item} draggableId={item} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <RepDraggableDiv>
                                                  <img
                                                    style={{ marginRight: "8px", marginTop: "6px" }}
                                                    src={iconNewMoveRow}
                                                    height="12px"
                                                    alt="Drag me"
                                                  />
                                                  <AppCheckbox
                                                    title={getTitle(item, mrrLabel)}
                                                    checked={selectedVisibleFields.includes(item)}
                                                    onClick={() =>
                                                      selectedVisibleFields.includes(item)
                                                        ? setSelectedVisibleFields(
                                                            selectedVisibleFields
                                                              .slice()
                                                              .filter((li: string) => li !== item),
                                                          )
                                                        : setSelectedVisibleFields([...selectedVisibleFields, item])
                                                    }
                                                  />
                                                </RepDraggableDiv>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                      {/* {dataColumns.slice()?.map((item: any, index: number) => (
                                  <RepDraggableDiv>
                                    <img
                                      style={{ marginRight: "8px", marginTop: "6px" }}
                                      src={iconNewMoveRow}
                                      height="12px"
                                      alt="Drag me"
                                    />
                                    <AppCheckbox
                                      title={item}
                                      checked={selectedVisibleFields.includes(item)}
                                      onClick={() => {
                                        if (!item) {
                                          selectedVisibleFields.includes(item)
                                            ? setSelectedVisibleFields(
                                                selectedVisibleFields.slice().filter((li: string) => li !== item),
                                              )
                                            : setSelectedVisibleFields([...selectedVisibleFields, item]);
                                        }
                                      }}
                                    />
                                  </RepDraggableDiv>
                                ))} */}
                                    </>
                                  )
                                )}
                              </DragElements>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </RepsListContainer>
                  </SelectRepsContainer>
                </RepListsGrid>
              </ScrollDiv>
            );
          }}
        </Formik>
        <SubmitDiv>
          <PhoenixAppButton variant={"danger-outline"} width={186} height={40} buttonType={"primary"} onClick={onClose}>
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            variant={"brand"}
            width={186}
            height={40}
            buttonType={"primary"}
            disabled={loadingUpdate}
            onClick={async () => {
              if (!visibleFields.length) {
                appToast("You must select at least once visible field");
                return;
              }

              await updateColumns({ variables: { selected_columns: visibleFields } });
              onClose();
            }}
          >
            Save
          </PhoenixAppButton>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const DisabledDiv = styled.div`
  padding-left: 20px;
  /* margin-bottom: 12px; */
  display: flex;
  align-items: center;
  height: 24px;
`;

const DisabledOptionText = styled(AppText)`
  opacity: 0.6;
`;

const MaxError = styled(AppText)`
  color: ${theme.ATTENTION700};
  margin-top: 8px;
  font-size: 10px;
`;

const ContainerDivSort = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RepDraggableDiv = styled.div`
  display: flex;
`;

const DragElements = styled.div`
  max-height: 208px;
  min-height: 208px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const IconButton = styled(NewAppButton)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-gap: 16px;
  margin: 24px 30px;
`;

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RepsScrollDiv = styled.div`
  max-height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchRepsInput = styled(InputField)`
  background-color: ${theme.NEUTRAL100};
  margin: 0px;
  /* margin-top: 16px; */
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

const RepsListContainer = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  height: 240px;
  overflow: hidden;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 12px;
  border-radius: 2px;
`;

const SelectRepsContainer = styled.div`
  width: 330px;
`;

const ManageButtons = styled(NewAppButton)`
  width: 186px;
  height: 48px;
`;

const ScrollDiv = styled.div`
  max-height: 434px;
  height: 434px;
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 0px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 24px 32px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

export { MyLeadsManageColumnsModal };
