import styled from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { AppButton, AppText, AppModalPriorityPopup, Loading, AppErrorText, AppSidebarCard, NewAppSelect } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../Field";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import { Modal } from "./Modal";
import { ModalContext } from "../../context";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { FETCH_PIPELINE_VALUES } from "../Segments";
import { loggedInUser } from "../../apollo/cache";
import { PhoenixAppButton, PhoenixMultiSelect } from "../UI/Phoenix";

interface DisappearingDivProps {}

interface MyFormikProps {
  close_date_default_value: number;
}

const UPDATE_ORG_DAY_TO_CLOSE = gql`
  mutation updateOrgDayToClose($dayToClose: Int!) {
    updateOrgDayToClose(day_to_close: $dayToClose) {
      id
      day_to_close
    }
  }
`;

const editEmailSchema = Yup.object().shape({
  close_date_default_value: Yup.number().required("Close Date Default Value is required"),
});

const PipelineCloseDateModalV2: React.FC<DisappearingDivProps> = () => {
  const { pipelineCloseDateModal, setPipelineCloseDateModal } = useContext(ModalContext);

  const { data: dataPipelineValues, loading: loadingPipelineValues, error: errorPipelineValues } = useQuery(
    FETCH_PIPELINE_VALUES,
    {
      fetchPolicy: "network-only",
      variables: { org_id: loggedInUser().organization_id },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [updateOrgDayToClose, { loading: loadingOrgDayToClose }] = useMutation(UPDATE_ORG_DAY_TO_CLOSE, {
    onCompleted({ updateOrgDayToClose }) {
      appToast("Updated Close Date Default Value");
      setPipelineCloseDateModal(false);
    },
    onError({ message }) {
      console.log("Error in updateOrgDayToClose: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateOrgDayToClose GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchOrganization"],
  });

  const optionsDays = new Array(120)
    .fill(0)
    ?.map((_, i: number) => ({ value: i + 1, label: `${i + 1} day${i + 1 <= 1 ? "" : "s"}` }));

  return (
    <Modal open={pipelineCloseDateModal} onClose={() => setPipelineCloseDateModal(false)} closeButtonSize={16}>
      <ContentDiv>
        {/* <SummaryText>{JSON.stringify(optionsDays)}</SummaryText> */}
        <Formik
          enableReinitialize={true}
          initialValues={{
            close_date_default_value: dataPipelineValues?.fetchOrganization?.day_to_close,
          }}
          validationSchema={editEmailSchema}
          onSubmit={async (values) => {
            await updateOrgDayToClose({
              variables: {
                dayToClose: values.close_date_default_value,
              },
            });
          }}
        >
          {({ submitForm, isSubmitting, values, errors, setFieldValue }: FormikProps<MyFormikProps>) => {
            return (
              <>
                <TitleDiv>
                  <PopupTitle>Edit Default Close Date</PopupTitle>
                </TitleDiv>

                <ContentBody>
                  <PhoenixMultiSelect
                    isMulti={false}
                    isClearable={false}
                    options={optionsDays}
                    name="close_date_default_value"
                    titleText="Close Date (Days After First Contact)"
                    value={optionsDays?.find((option) => option.value === values.close_date_default_value)}
                    onChange={async (e: any) => {
                      setFieldValue(`close_date_default_value`, e.value);
                    }}
                  />
                </ContentBody>
                <ButtonsContainer>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    onClick={() => setPipelineCloseDateModal(false)}
                    uppercase={true}
                  >
                    Cancel
                  </PhoenixAppButton>

                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand"
                    onClick={submitForm}
                    uppercase={true}
                    disabled={isSubmitting}
                  >
                    Update
                  </PhoenixAppButton>
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </ContentDiv>
    </Modal>
  );
};

const ButtonsContainer = styled.div`
  width: 100%;
  height: 72px;
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
`;

const ContentDiv = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleDiv = styled.div`
  height: 56px;
  width: 100%;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  /* margin-bottom: 32px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ContentBody = styled.div`
  // margin: 40px;
  width: 376px;
  padding: 40px;
  gap: 8px;
`;

export { PipelineCloseDateModalV2 };
