import styled from "styled-components";
import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { AppButton, AppText } from "../UI";
import { theme } from "../../utils/theme";
import { ModalContext, CallContext } from "../../context";
import { appToast } from "../../utils/toast";
import { useDraggable } from "src/utils/hooks";

interface DialerProps {}

const DialerModal: React.FC<DialerProps> = ({}) => {
  const { draggableRef, handleDragStart, dragState } = useDraggable();

  const { closeDialer } = useContext(ModalContext);
  const { sendDigit } = useContext(CallContext);
  const [dialedNumbers, setDialedNumbers] = useState("");

  const addToDialedNumbers = (number: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    sendDigit(number);
    if (dialedNumbers.length >= 21) {
      setDialedNumbers(dialedNumbers.substring(1).concat(number));
    } else {
      setDialedNumbers(dialedNumbers.concat(number));
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    const { clipboardData } = e;
    //regex used since parseInt removes leading 0s
    const pastedText = clipboardData ? clipboardData.getData("text").replace(/\s+/g, "") : "";
    const numberValid = new RegExp("^[0-9]+$");
    if (!numberValid.test(pastedText)) {
      appToast(`${pastedText} is not a valid number`);
    } else {
      addToDialedNumbers(pastedText);
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);

    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <ModalContainer ref={draggableRef} onMouseDown={handleDragStart}>
      <NumberDisplayDiv>
        <NumberDisplayNumber length={dialedNumbers.length}>{dialedNumbers}</NumberDisplayNumber>
      </NumberDisplayDiv>
      <NumberContainerDiv>
        <NumberButton
          style={{
            gridColumn: "1 / span 1",
            gridRow: "1 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("1", e)}
        >
          1 <NumberLetters style={{ visibility: "hidden" }}>...</NumberLetters>{" "}
          {/* Keeps all numbers aligned the same. */}
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "2 / span 1",
            gridRow: "1 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("2", e)}
        >
          2 <NumberLetters>ABC</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "3 / span 1",
            gridRow: "1 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("3", e)}
        >
          3 <NumberLetters>DEF</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "1 / span 1",
            gridRow: "2 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("4", e)}
        >
          4 <NumberLetters>GHI</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "2 / span 1",
            gridRow: "2 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("5", e)}
        >
          5 <NumberLetters>JKL</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "3 / span 1",
            gridRow: "2 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("6", e)}
        >
          6 <NumberLetters>MNO</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "1 / span 1",
            gridRow: "3 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("7", e)}
        >
          7 <NumberLetters>PQRS</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "2 / span 1",
            gridRow: "3 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("8", e)}
        >
          8 <NumberLetters>TUV</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "3 / span 1",
            gridRow: "3 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("9", e)}
        >
          9 <NumberLetters>WXYZ</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "1 / span 1",
            gridRow: "4 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("*", e)}
        >
          *
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "2 / span 1",
            gridRow: "4 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("0", e)}
        >
          0 <NumberLetters>+</NumberLetters>
        </NumberButton>
        <NumberButton
          style={{
            gridColumn: "3 / span 1",
            gridRow: "4 / span 1",
          }}
          onMouseDown={(e) => addToDialedNumbers("#", e)}
        >
          #
        </NumberButton>
      </NumberContainerDiv>
      <CloseButtonContainer>
        <CloseButton onClick={closeDialer}>Close</CloseButton>
      </CloseButtonContainer>
      <PasteTip>extension/meeting IDs are pasteable</PasteTip>
    </ModalContainer>
  );
};

export { DialerModal };

const ModalContainer = styled.div`
  position: fixed;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 256px;
  height: 490px;
  border-radius: 4px;
  border: solid 2px #bebebe;
  background-color: #3a3a3a;
  overflow: hidden;
  padding: 5px 40px;
`;

const NumberDisplayDiv = styled.div`
  width: 100%;
  height: 28px;
  margin: 24px 0px;
  overflow: hidden;
`;

interface InputProps {
  length: number;
}

const NumberDisplayNumber = styled(AppText)<InputProps>`
  width: 100%;
  font-size: ${(props) => {
    if (props.length >= 8) {
      return "14px";
    }
    if (props.length < 8) {
      return "20px";
    }
    if (props.length < 4) {
      return "23px";
    }
  }};
  font-weight: 300;
  color: #ffffff;
  /* caret-color: transparent;
  background: transparent;
  border: none; */
`;

const NumberContainerDiv = styled.div`
  display: grid;
  grid-template-columns: 50px 50px 50px;
  grid-template-rows: 50px 50px 50px;
  column-gap: 10px;
  row-gap: 20px;
`;

const NumberLetters = styled.span`
  font-size: 8px;
`;

const NumberButton = styled(AppButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 23px;
  font-weight: 300;
  border-radius: 100%;
  color: ${theme.WHITE_COLOR};
  background: #5e5e5e;
  :active {
    top: 1px;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(AppButton)`
  width: 148px;
  height: 40px;
  border-radius: 4px;
  background-color: ${theme.ATTENTION700};
  margin-top: 24px;
`;

const SendButton = styled(AppButton)`
  width: 148px;
  height: 40px;
  border-radius: 4px;
  background-color: ${theme.SUCCESS500};
  margin-top: 24px;
`;

const PasteTip = styled(AppText)`
  color: ${theme.WHITE_COLOR};
  font-size: 8px;
  margin-top: 20px;
`;
