import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useState } from "react";
import { AppText } from "../AppText";

interface PhoenixTextAreaProps {
  currency?: boolean;
  error?: string | false;

  fontSize?: number;
  noBorder?: boolean;
  fixedSize?: boolean;

  borderColor?: string;
  variant?: "primary" | "error" | "success" | "warning" | undefined;
  onChange?: (e: any) => void;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  lineHeight?: number;

  hideError?: boolean;
  ableToEdit?: boolean;
  ableToResize?: boolean;
  placeholder?: string;

  cursorColor?: string;
  backgroundColor?: string;
}

export const TextArea = styled.textarea<PhoenixTextAreaProps>`
  width: ${(props) => (!!props.width ? (typeof props.width === "number" ? `${props.width}px` : props.width) : "100%")};
  height: ${(props) =>
    !!props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "100%"};
  padding: ${(props) => (!!props.borderColor ? "8px 16px" : "5px 10px")};
  max-width: ${(props) =>
    !!props.maxWidth ? (typeof props.maxWidth === "number" ? `${props.maxWidth}px` : props.maxWidth) : "100%"};
  max-height: ${(props) =>
    !!props.maxHeight ? (typeof props.maxHeight === "number" ? `${props.maxHeight}px` : props.maxHeight) : "100%"};
  min-width: ${(props) =>
    !!props.minWidth ? (typeof props.minWidth === "number" ? `${props.minWidth}px` : props.minWidth) : "inherit"};
  min-height: ${(props) =>
    !!props.minHeight ? (typeof props.minHeight === "number" ? `${props.minHeight}px` : props.minHeight) : "inherit"};

  border-width: 0.5;
  border-radius: 2.9px;
  border-color: ${(props) =>
    props.noBorder ? "transparent" : !!props.borderColor ? props.borderColor : theme.NEUTRAL400};
  resize: ${(props) => (props.fixedSize ? "none" : "both")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 1.6)};
  font-family: ${theme.PRIMARY_FONT};
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${theme.BLACK_COLOR};
  caret-color: ${(props) => props.cursorColor || theme.PRIMARY500};
  ${(props) => !props.ableToEdit && "cursor: default;"}
  ${(props) => !props.ableToResize && "resize: none;"}

  :focus {
    ${(props) => {
      switch (props.variant) {
        case "primary":
          return `
        outline: 2px double ${theme.PRIMARY500};
      `;
        case "error":
          return `
        outline: 1px solid ${theme.ATTENTION500};
      `;
        case "success":
          return `
        outline: 1px solid ${theme.SUCCESS500};
      `;
        default:
          return `
        outline: 2px double ${theme.PRIMARY500};
    `;
      }
    }}
  }
  ::placeholder {
    opacity: 0.7;
  }
`;

export const PhoenixTextArea = ({
  currency = false,
  title,
  error,
  hideError = false,
  ableToEdit = true,
  ableToResize = true,
  lineHeight,
  placeholder,
  ...props
}: PhoenixTextAreaProps & React.HTMLProps<HTMLTextAreaElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);

  return (
    <Div>
      {!!title && <InputTitle>{title}</InputTitle>}
      {currency && (
        <LeftText // error={!!errors[field.name] && !!touched[field.name]}
          error={!!error}
          focused={isFocused}
        >
          $
        </LeftText>
      )}
      {currency && (
        <RightText // error={!!errors[field.name] && !!touched[field.name]}
          error={!!error}
          focused={isFocused}
        >
          M
        </RightText>
      )}
      <TextArea
        onFocus={toggleFocus}
        onBlur={(e) => {
          toggleFocus();
          !!props.onBlur && props.onBlur(e);
        }}
        currency={currency}
        error={error}
        onChange={(e) => {
          !!props.onChange && !!ableToEdit && props.onChange(e);
        }}
        ableToEdit={ableToEdit}
        ableToResize={ableToResize}
        lineHeight={lineHeight}
        placeholder={placeholder}
        {...(props as any)}
      />
      {!hideError && (!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>)}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

interface TextFocus {
  focused?: boolean;
  error?: boolean;
}

const LeftText = styled.p<TextFocus>`
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.focused ? theme.PRIMARY500 : props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

const RightText = styled.p<TextFocus>`
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.focused ? theme.PRIMARY500 : props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

RightText.defaultProps = {
  focused: false,
  error: false,
};

LeftText.defaultProps = {
  focused: false,
  error: false,
};

const InputTitle = styled(AppText)`
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.ATTENTION700};
  margin: 0;
`;
