import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { NodeContainer } from "./NodeContainer";
import { Handle, Position } from "react-flow-renderer";
import { PhoenixIcon, PhoenixMultiSelect } from "../../Phoenix";
import { edit, log_out, more_vertical } from "../../../../images/NewDesign";
import { AppText } from "../../AppText";
import { FlexDiv } from "../../FlexDiv";
import { useClickOutside } from "../../../../utils/hooks";
import { ModalContext } from "../../../../context";
import { endActionOptions } from "../../../../utils/sequences";
interface EndSequenceProps {
  data: any;
}

export const EndSequence: React.FC<EndSequenceProps> = ({ data }) => {
  const [showSequenceActionMenu, setShowSequenceActionMenu] = useState<boolean>(false);
  const [nodeIsFocus, setNodeIsFocus] = useState<boolean>(false);
  const { setShowEditEndSequenceModal, setSequenceStepData } = useContext(ModalContext);

  useEffect(() => {
    const curNode = document.getElementById(data.id);
    const parentNode = curNode?.parentElement;
    if (!!parentNode) {
      parentNode.style.zIndex = nodeIsFocus ? "10" : "0";
    }
  }, [nodeIsFocus]);

  return (
    <NodeContainer
      padding="16px"
      id={data.id}
      style={{
        overflow: "unset",
        gap: "16px",
      }}
      className="nodrag"
    >
      <FlexDiv align="center">
        <IconCircle>
          <PhoenixIcon svg={log_out} size="small" variant="danger" hoverColor={theme.DANGER600} />
        </IconCircle>
        <AppText fontSize={12} fontWeight={500}>
          End Sequence
        </AppText>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <PhoenixIcon
            svg={more_vertical}
            size="small"
            pointer
            onClick={() => {
              setShowSequenceActionMenu(true);
              setNodeIsFocus(true);
            }}
          />
          {showSequenceActionMenu && (
            <SequenceActionPopup
              setShowSequenceActionMenu={setShowSequenceActionMenu}
              setNodeIsFocus={setNodeIsFocus}
              nodeData={data}
            />
          )}
        </div>
      </FlexDiv>

      <PhoenixMultiSelect
        name={`end-sequence-${data?.id}`}
        value={endActionOptions.find(
          (option: { label: string; value: string }) => option.value === data?.post_step_action,
        )}
        options={endActionOptions}
        isMulti={false}
        marginBottom={false}
        isClearable={false}
        onChange={(e: { label: string; value: string }) => {
          setShowEditEndSequenceModal(true);
          setSequenceStepData({ nodeData: data, originId: data?.origin_id, postActionStep: e?.value });
        }}
      />

      <Handle type="target" position={Position.Top} isConnectable={false} />
    </NodeContainer>
  );
};

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  margin-right: 8px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 50%;
`;

interface SequenceActionPopupProps {
  setShowSequenceActionMenu: Dispatch<SetStateAction<boolean>>;
  setNodeIsFocus: Dispatch<SetStateAction<boolean>>;
  nodeData: any;
}

const SequenceActionPopup: React.FC<SequenceActionPopupProps> = ({
  setShowSequenceActionMenu,
  setNodeIsFocus,
  nodeData,
}) => {
  const containerRef = useRef(null);
  const { setShowEditEndSequenceModal, setSequenceStepData } = useContext(ModalContext);

  useClickOutside(containerRef, () => {
    setShowSequenceActionMenu(false);
    setNodeIsFocus(false);
  });

  return (
    <PopupContainer ref={containerRef}>
      <MenuItem
        onClick={() => {
          setShowEditEndSequenceModal(true);
          setSequenceStepData({ nodeData, originId: nodeData?.origin_id, postActionStep: nodeData?.post_step_action });
        }}
      >
        <PhoenixIcon svg={edit} size="small" color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} pointer />
        Edit
      </MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 16px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;

  animation: ${theme.popup} 0.2s ease;
  overflow: hidden;
`;

interface MenuItemProps {
  active?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 152px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${(props) => (!props?.active ? theme.PRIMARY500 : "black")};
  }
`;
