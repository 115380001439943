import React from "react";
import { theme } from "src/utils/theme";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixIcon, PhoenixInput } from "src/Components/UI/Phoenix";
import { lock } from "src/images/NewDesign";

interface IRequiredField {
  value: string;
}

export const RequiredField: React.FC<IRequiredField> = ({ value }) => (
  <FlexDiv align="center" gap={8}>
    <PhoenixInput
      value={value}
      width={352}
      displayNoContextText
      readOnly
      style={{ borderColor: theme.NEUTRAL300, cursor: "default" }}
      onFocus={(e) => e.target.blur()}
    />

    <PhoenixIcon svg={lock} size={16} variant="neutral" hoverColor={theme.NEUTRAL300} />
  </FlexDiv>
);
