import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading, AppErrorText, NewAppSidebarCard, Region } from "../../UI";
import { theme } from "../../../utils/theme";
import { formatTime } from "../../../utils/format";
import { AiOutlinePlus } from "react-icons/ai";
import { AnnotationNotes } from ".";
import { loggedInUser } from "../../../apollo/cache";
import { ModalContext } from "../../../context";
import { UpdateCallNotesPopup } from "../../modal";
import { useContext } from "react";

interface CoachingNotesProps {
  coachingNotes: Region[];
  color?: string;
  playRegion?: (regionId: string) => void;
  addRegion: (text: string) => void;
  updateNotes: (regionId: string, note: string) => void;
  removeRegion: (regionId: string) => void;
  repLoading?: boolean;
  sendFeedbackFunction?: any;
}

const CoachingNotes: React.FC<CoachingNotesProps> = ({
  coachingNotes,
  color,
  playRegion,
  addRegion,
  updateNotes,
  removeRegion,
  repLoading,
  sendFeedbackFunction,
}) => {
  const { callNotesModal, setCallNotesModal, showCallFeedbackModal, setShowCallFeedbackModal } = useContext(
    ModalContext,
  );

  return (
    <NewAppSidebarCard title="My Coaching Notes" color={color} width={466} height={270}>
      {callNotesModal && (
        <UpdateCallNotesPopup
          blinds={callNotesModal}
          setBlinds={setCallNotesModal}
          saveNewNote={addRegion}
          addNote={true}
        />
      )}
      <CoachingNotesPhoneCallsContainer>
        {coachingNotes.length === 0 && (
          <ErrorMessageContainer>
            <AppText fontSize={15}>There are no coaching notes.</AppText>
          </ErrorMessageContainer>
        )}
        {coachingNotes?.map((note, index) => {
          return (
            <AnnotationNotes
              key={note.id}
              coachingNote={note}
              playRegion={playRegion}
              updateNotes={updateNotes}
              removeRegion={removeRegion}
              lastElement={index === coachingNotes.length - 1}
            />
          );
        })}
      </CoachingNotesPhoneCallsContainer>
      {(loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") && (
        <AddButton>
          <NewAppButton
            customcolor={theme.SUCCESS500}
            onClick={() => {
              setCallNotesModal(true);
            }}
          >
            Create New
          </NewAppButton>
          {repLoading ? (
            <Loading />
          ) : (
            <NewAppButton customcolor={theme.TERTIARY500} onClick={() => setShowCallFeedbackModal(true)}>
              Send to Rep
            </NewAppButton>
          )}
        </AddButton>
      )}
    </NewAppSidebarCard>
  );
};

const NewAppButton = styled(AppButton)`
  border-radius: 33px;
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  width: 103px;
  margin-right: 8px;
`;

const CoachingNotesPhoneCallsContainer = styled.div`
  max-height: 130px;
  min-height: 100px;
  margin: 5px 0px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const ErrorMessageContainer = styled.div`
  max-height: 130px;
  min-height: 130px;
  margin: auto auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 24px;
`;

const AddButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: auto;
  right: 0px;
  top: 0px;
  /* background: ${theme.SUCCESS500}; */
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 3;
`;

export { CoachingNotes };
