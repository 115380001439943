import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import { AppText } from "src/Components/UI";
import { theme } from "src/utils/theme";
import { InputField } from "../InputField";
import { FormSelectField } from "../SelectField";
import { FormMultiSelectField } from "../MultiSelectField";

export const TitleLabel = styled(AppText)`
  margin-bottom: 8px;
`;

export const FieldWrapper = styled.div`
  width: 100%;
`;

export const EditTextInput = styled(InputField)<{ disabled?: boolean }>`
  height: 36px;
  font-size: 13px;
  margin-top: 0px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.BLACK_COLOR)} !important;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL300)} !important;
  }
`;

export const FieldSelect = styled(FormSelectField)`
  /* height: 36px; */
  font-size: 13px;
  border-radius: 2.9px;
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

export const MultiFieldSelect = styled(FormMultiSelectField)`
  /* height: 36px; */
  font-size: 13px;
  border-radius: 2.9px;
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  margin-bottom: 15px;
`;

export const MultiFieldTextSelect = styled(CreatableSelect)`
  /* height: 36px; */
  font-size: 13px;
  border-radius: 2.9px;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  .css-yk16xz-control {
    border: none;
  }
`;

export const DateTimePickerWrapper = styled.div`
  width: 100%;
  .react-datetime-picker {
    * {
      color: ${theme.PRIMARY600} !important;
    }
    /* Set the width of the date picker */
    border: 1px solid ${theme.PRIMARY600};
    border-radius: 4px;
  }

  .react-datetime-picker__wrapper {
    /* Add padding to the wrapper element */
    padding: 10px;
  }

  .react-datetime-picker__inputGroup {
    /* Remove the default border and add a new border */
    border: none;
    border-bottom: 2px solid ${theme.PRIMARY800};
  }

  .react-datetime-picker__calendar {
    /* Add a border and background color to the calendar */
    border: 1px solid ${theme.PRIMARY600};
    background-color: ${theme.WHITE_COLOR};
  }

  .react-datetime-picker__calendar button {
    /* Style the buttons in the calendar */
    background-color: ${theme.WHITE_COLOR};
    border: none;
  }

  .react-datetime-picker__calendar button.react-datetime-picker__navigation {
    /* Style the navigation buttons in the calendar */
    background-color: ${theme.WHITE_COLOR};
  }

  // clock
  .react-clock__hand__body {
    background-color: ${theme.PRIMARY500} !important;
  }

  .react-clock__mark__body {
    background-color: ${theme.PRIMARY600} !important;
  }
`;
