import { AppErrorText } from ".";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";
import * as React from "react";
import { Formik, FormikProps, useField } from "formik";
import { theme } from "../../utils/theme";

export interface NewAppMultiSelectProps {
  name: string;
  isMulti?: boolean;
  options: any[];
  onChange?: any;
  value?: any;
  error?: any;
  maxMenuHeight?: number;
  maxHeight?: number;
  maxValueWidth?: number;
  isDisabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  minHeight?: number;
  isClearable?: boolean;
  marginBottom?: boolean;
  itemBackgroundColor?: string;
  // [x: string]: any;
}

type MyOptionType = {
  label: string;
  value: string;
};

export const NewAppMultiSelect: React.FC<NewAppMultiSelectProps> = ({
  value,
  name,
  options,
  isMulti,
  onChange,
  error,
  maxHeight,
  minHeight,
  maxValueWidth,
  maxMenuHeight,
  //isClearable added in as default true to prevent breaking changes
  isClearable = true,
  // the old version had this hard coded to 32px in order to not have breaking changes we are adding this as a prop
  marginBottom = true,
  itemBackgroundColor,
  ...props
}) => {
  const customControlStyles: React.CSSProperties = {
    maxHeight: !!maxHeight ? `${maxHeight}px` : "300px",
    overflow: "auto",
    borderRadius: "4px",
    fontFamily: theme.PRIMARY_FONT,
    fontSize: "12px",
    letterSpacing: "0.2px",
    backgroundColor: theme.WHITE_COLOR,
    minHeight: !!minHeight ? `${minHeight}px` : "32px",
  };

  const selectStyle: StylesConfig<MyOptionType, boolean> = {
    control: (provided, state) => {
      // provided has CSSObject type
      // state has ControlProps type

      // return type is CSSObject which means this line will throw error if uncommented
      // return false;

      return {
        ...provided,
        ...customControlStyles,
      };
    },
    // option dropdown
    option: (base, state) => ({
      ...base,
      fontFamily: theme.PRIMARY_FONT,
      fontSize: "12px",
      letterSpacing: "0.2px",
    }),

    // selected options
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: itemBackgroundColor ?? theme.PRIMARY500,
      color: theme.WHITE_COLOR,
      height: "24px",
      width: "91px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      color: theme.WHITE_COLOR,
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: theme.WHITE_COLOR,
      fontWeight: 600,
      fontSize: "10px",
    }),
    // holds the selected options
    valueContainer: (base, state) => ({
      ...base,
      maxWidth: !!maxValueWidth ? `${maxValueWidth}px` : "unset",
    }),
    // dropdown indicator
    dropdownIndicator: (base, state) => ({
      ...base,
      color: theme.PRIMARY500,
    }),
    container: (base, state) => ({
      ...base,
      // This might have been used to fix something, but it was also causing the select to render above the dropdown options of other multi selects
      // zIndex: 5,
    }),
  };

  return (
    <MultiSelectDiv marginbottom={marginBottom}>
      <Select
        isMulti={isMulti ?? true}
        style={{
          borderRadius: "2.9px",
        }}
        styles={selectStyle}
        // styles={props.customStyles}
        options={options}
        value={value}
        onChange={onChange}
        {...props}
        isClearable={isClearable}
        maxMenuHeight={maxMenuHeight ? maxMenuHeight : undefined}
      />
      {!!error && <AppErrorText>{error}</AppErrorText>}
    </MultiSelectDiv>
  );
};

const MultiSelectDiv = styled.div<{ marginbottom: boolean }>`
  margin-bottom: ${(props) => (props.marginbottom ? "32px" : "0px")};
`;
