export const terms = [
  {
    label: "Month-to-Month",
    value: "Month-to-Month",
  },
  {
    label: "Annual Prepayment",
    value: "Annual Prepayment",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
  },
];
