import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";
import { theme } from "../../utils/theme";
import { carotDown } from "../../images/";

interface OptionItem {
  label: string;
  value: number | string;
  disabled?: boolean;
  data?: any;
}

export interface AppSelectProps {
  /**
   * If true, allows the user to select the placeholder after selecting another item.
   */
  allowSelectPlaceholder?: boolean;
  /**
   * Indicates an error strong
   */
  error?: string | false;
  /**
   * A list of options that can be selected.
   */
  options: OptionItem[];
  /**
   * True if placeholder is currently selected. Determines opacity
   */
  isPlaceholder?: boolean;
  fontSize?: number;
  width?: number;
  /**
   * By default, the placeholder has a lower opacity. This parameter overrides that.
   */
  ignorePlaceholderOpacity?: boolean;
  /**
   * If true, do not render underline
   */
  noUnderline?: boolean;
  /**
   * color of the carrot. Orange by default
   */
  carrotColor?: string;
  /**
   * If true, it does not enable possiblity of an error message. Normally bottom padding is reserved for an error message
   */
  noErrorNeeded?: boolean;
  /**
   * Use to change bottom spacing of arrow caret
   */
  customArrowLocation?: number;
  /**
   * App Icon for App select
   */
  appSelectIcon?: any;
  /**
   * The alignment of text: center, left, right.
   * @default center
   */
  textAlign?: string;
  /**
   * Overwrite onChange function
   */
  onChangeFunc?: any;

  variant?: "primary" | "error" | "success" | "warning";
}

export const StyledAppSelect = styled.select<AppSelectProps & React.HTMLProps<HTMLInputElement>>`
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  height: 40px;
  padding-left: ${(props) => (!!props.appSelectIcon ? "40px" : "15px")};
  padding-right: 45px;
  background-color: ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL100)};
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 2px;
  border: solid 1px ${theme.NEUTRAL200};
  /* border-bottom-color: ${(props) => (props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")}; */
  /* border-bottom-width: ${(props) => (props.noUnderline ? 0 : 2)}px; */
  opacity: ${(props) => (props.isPlaceholder ? (props.ignorePlaceholderOpacity ? 1.0 : 0.4) : 1.0)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "13")}px;
  line-height: 1.39;
  font-family: ${theme.PRIMARY_FONT};
  box-sizing: border-box;
  color: ${(props) => (props.color ? props.color : theme.BLACK_COLOR)};
  caret-color: ${theme.PRIMARY500};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align-last: ${(props) => (!!props.textAlign ? props.textAlign : "center")};
  ${(props) => {
    if (!props.disabled) {
      switch (props.variant) {
        case "primary":
          return `
        border: 1px solid ${theme.NEUTRAL200};
      `;
        case "error":
          return `
        border: 1px double ${theme.ATTENTION700};
        border-width: 4px;
      `;
        case "success":
          return `
        border: 1px double ${theme.SUCCESS500};
        border-width: 4px;
      `;
        case "warning":
          return `
        border: 1px double ${theme.TERTIARY500};
        border-width: 4px;
      `;
        default:
          return `
        border: 1px solid ${theme.NEUTRAL200};
    `;
      }
    }
  }};
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
  }
  :hover {
    ${(props) => {
      if (!props.disabled) {
        switch (props.variant) {
          case "primary":
            return `
        border: 1px double ${theme.PRIMARY500};
        border-width: 4px;
      `;
          case "error":
            return `
        border: 1px double ${theme.ATTENTION700};
        border-width: 4px;
      `;
          case "success":
            return `
        border: 1px double ${theme.SUCCESS500};
        border-width: 4px;
      `;
          case "warning":
            return `
        border: 1px double ${theme.TERTIARY500};
        border-width: 4px;
      `;
          default:
            return `
        border: 1px double ${theme.PRIMARY500};
        border-width: 4px;
    `;
        }
      }
    }};
  }
  ::placeholder {
    /* opacity: 0.4; */
    text-align: ${(props) => (!!props.textAlign ? props.textAlign : "center")};
  }
  option {
    color: ${theme.BLACK_COLOR};
    text-align: center;
  }
`;

StyledAppSelect.defaultProps = {
  ignorePlaceholderOpacity: false,
  noUnderline: false,
  allowSelectPlaceholder: false,
};

export const AppSelect = ({
  title,
  error,
  options,
  noErrorNeeded = false,
  appSelectIcon,
  onChangeFunc,
  ...props
}: AppSelectProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  // eslint-disable-next-line
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const toggleFocus = () => setFocus(!isFocused);
  const onChangeOverride = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    //@ts-ignore
    setIsPlaceholder(!event.target.value);
    !!props.onChange && props.onChange(event);
  };
  return (
    <>
      {!!title && <InputTitle>{title}</InputTitle>}
      <Div width={props.width}>
        {appSelectIcon && <AppIconContainer>{appSelectIcon}</AppIconContainer>}
        <IconContainer customArrowLocation={props.customArrowLocation}>
          <IconImg src={carotDown} />
        </IconContainer>
        <StyledAppSelect
          {...(props as any)}
          appSelectIcon={appSelectIcon}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          title={title}
          error={error}
          isPlaceholder={props.value === 0}
          onChange={!!onChangeFunc ? onChangeFunc : onChangeOverride}
        >
          <>
            <option
              selected
              onClick={() => setIsPlaceholder(true)}
              value={-1}
              key="placeholder-sellfire"
              disabled={!props.allowSelectPlaceholder}
            >
              {props.placeholder}
            </option>
            {options?.map((o) => (
              <option value={o.value} key={o.value} onClick={() => setIsPlaceholder(true)} disabled={o.disabled}>
                {o.label}
              </option>
            ))}
          </>
        </StyledAppSelect>
        {!!noErrorNeeded ? (
          <></>
        ) : !!error ? (
          <InputErrorText>{error}</InputErrorText>
        ) : (
          <InputErrorText>&nbsp;</InputErrorText>
        )}
      </Div>
    </>
  );
};

const IconImg = styled.img`
  padding-left: 16px;
  padding-top: 18px;
`;

interface DivProps {
  width?: number;
}

const Div = styled.div<DivProps>`
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface CaretLocation {
  customArrowLocation?: number;
}

const IconContainer = styled.div<CaretLocation>`
  position: absolute;
  top: 1px;
  right: 0px;
  bottom: 1px;
  width: 42px;
  height: 38px;
  bottom: ${(props) => (props.customArrowLocation ? `${props.customArrowLocation}px` : "25px")};
  /* align-self: center; */
  border-left: 1px solid ${theme.NEUTRAL200};
  pointer-events: none;
`;

const AppIconContainer = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  left: 16px;
  top: 10px;
  align-self: center;
  pointer-events: none;
`;
const InputTitle = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
  /* text-align: center; */
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 700;
  font-size: 12px;
  color: ${theme.ATTENTION700};
  margin: 0;
  margin-top: 5px;
  text-align: center;
`;
