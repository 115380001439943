import React from "react";
import { useField } from "formik";
import { AppFormTitle, AppErrorText } from "../UI";

interface RadioButtonFieldProps {
  name: string;
  id: string;
  title?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const RadioButtonField = ({ style, title, id, ...props }: RadioButtonFieldProps) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <div style={style}>
      {!!title && <AppFormTitle style={{ margin: 0 }}>{title.toUpperCase()}</AppFormTitle>}
      <input
        {...field}
        {...props}
        name={field.name}
        type="radio"
        id={id}
        checked={field.value === id}
        onChange={(e) => helpers.setValue(id)}
      />
      {meta.error && meta.touched && <AppErrorText>{meta.error}</AppErrorText>}
    </div>
  );
};

export { RadioButtonField };
