import styled from "styled-components";
import { AppButton, AppText, AppInput, AppSelect, Loading, AppErrorText } from "../UI";
import { ModalContext } from "../../context";
import { Modal } from "./Modal";
import React, { useContext } from "react";
import { PhoenixAppButton } from "../UI/Phoenix";
import { useMutation, gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { appToast } from "../../utils/toast";
const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($template_id: String!) {
    deleteTemplate(template_id: $template_id) {
      id
      name
      subject
      type
    }
  }
`;
const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    fetchAutomatedCampaigns {
      id
      name
      body
      subject
      event
      when
      increment
      increment_multiplier
      method_email
      method_sms
      template_id
    }
  }
`;

const DeleteTemplateModal: React.FC = () => {
  const {
    showDeleteTemplateModal,
    deleteTemplateModalId,
    setShowDeleteTemplateModal,
    setDeleteTemplateModalId,
    deleteTemplateModalType,
  } = useContext(ModalContext);
  const [deleteTemplate, { loading: deleteTemplateLoading, error: deleteTemplateError }] = useMutation(
    DELETE_TEMPLATE,
    {
      onCompleted: (data) => {
        console.log("delete template", data);
        appToast("Template deleted successfully");
        setShowDeleteTemplateModal(false);
      },
      onError: (error) => {
        console.log("delete template error", error);
        appToast("An error while deleting template");
      },
      refetchQueries: ["fetchTemplates", "fetchTemplatesCount"],
    },
  );

  const { data: allCampaignsData, loading: allCampaignsLoading, error: allCampaignsError } = useQuery(
    GET_ALL_CAMPAIGNS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        console.log("all campaigns", data);
      },

      onError: (error) => {
        console.log("all campaigns error", error);
        appToast("An error occured");
      },
    },
  );

  const filteredCampaigns = allCampaignsData?.fetchAutomatedCampaigns?.filter(
    (campaign: any) => campaign?.template_id === deleteTemplateModalId,
  );
  const isUsedInCampaigns = filteredCampaigns?.length > 0;

  return (
    <Modal
      open={showDeleteTemplateModal}
      onClose={() => {
        setShowDeleteTemplateModal(false);
      }}
      closeButtonSize={16}
    >
      <ScrollingDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "48px",
          }}
        >
          <CenterDiv>
            <PopupTitle>
              Delete
              {deleteTemplateModalType === "email" ? " Email" : " SMS"}
            </PopupTitle>
          </CenterDiv>

          <CenterDiv
            style={{
              width: "396px",
              textAlign: "center",
            }}
          >
            <>
              {deleteTemplateLoading || allCampaignsLoading ? (
                <Loading />
              ) : deleteTemplateError || allCampaignsError ? (
                <AppErrorText>An error occured. Please try again later.</AppErrorText>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <AppText fontSize={14} fontWeight={400}>
                    Are you sure you want to delete this
                    {deleteTemplateModalType === "email" ? " email " : " SMS "}
                    template? You cannot undo this action.
                  </AppText>

                  <>
                    {isUsedInCampaigns && (
                      <AppErrorText>
                        {`This template is being used in the following automated ${
                          filteredCampaigns.length > 1 ? "messages" : "message"
                        } (${filteredCampaigns
                          ?.map((campaign: any) => campaign?.name)
                          ?.join(
                            ", ",
                          )}) If you delete this template, that automated message will be deleted. Are you sure you want to delete?`}
                      </AppErrorText>
                    )}
                  </>
                </div>
              )}
            </>
          </CenterDiv>
        </div>
        <BottomDiv>
          <PhoenixAppButton
            onClick={() => {
              setShowDeleteTemplateModal(false);
            }}
            variant="danger-outline"
            buttonType="secondary"
          >
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            onClick={() => {
              deleteTemplate({
                variables: {
                  template_id: deleteTemplateModalId,
                },
              });
            }}
            variant="brand"
            buttonType="secondary"
          >
            Delete
          </PhoenixAppButton>
        </BottomDiv>
      </ScrollingDiv>
    </Modal>
  );
};

const BottomDiv = styled.div`
  height: 72px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

const ScrollingDiv = styled.div`
  padding: 40px 40px 16px 40px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
  min-width: 476px;
  min-height: 313px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { DeleteTemplateModal };
