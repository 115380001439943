import moment from "moment";
import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";

interface ScheduleContextState {
  showDatePicker: boolean;
  setShowDatePicker: Dispatch<SetStateAction<boolean>>;

  myScheduleDate: string;
  setMyScheduleDate: Dispatch<SetStateAction<string>>;

  selectedMyScheduleEventId: string;
  setSelectedMyScheduleEventId: Dispatch<SetStateAction<string>>;

  expandSchedule: boolean;
  setExpandSchedule: Dispatch<SetStateAction<boolean>>;

  monthlyRank: boolean;
  setMonthlyRank: Dispatch<SetStateAction<boolean>>;

  stackRankTeamView: boolean;
  setStackRankTeamView: Dispatch<SetStateAction<boolean>>;

  stackMetric: string;
  setStackMetric: Dispatch<SetStateAction<string>>;
}

export const ScheduleContext = createContext<ScheduleContextState>({} as ScheduleContextState);

export const ScheduleProvider: FunctionComponent = ({ children }) => {
  // Calendar date picker for my schedule
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [myScheduleDate, setMyScheduleDate] = useState(moment().format("YYYY-MM-DD"));

  // Toggle expand/collapse
  const [expandSchedule, setExpandSchedule] = useState(true);

  const [selectedMyScheduleEventId, setSelectedMyScheduleEventId] = useState("");

  // Right sidebar states
  const [monthlyRank, setMonthlyRank] = useState(localStorage.getItem("monthly_rank_sidebar") === "true");
  const [stackMetric, setStackMetric] = useState(localStorage.getItem("static_metric_sidebar") || "WinPerRepPerDay");
  const [stackRankTeamView, setStackRankTeamView] = useState(
    localStorage.getItem("static_rank_team_view_sidebar") === "true",
  );

  useEffect(() => {
    localStorage.setItem("monthly_rank_sidebar", `${monthlyRank}`);
  }, [monthlyRank]);

  useEffect(() => {
    localStorage.setItem("static_metric_sidebar", `${stackMetric}`);
  }, [stackMetric]);

  useEffect(() => {
    localStorage.setItem("static_rank_team_view_sidebar", `${stackRankTeamView}`);
  }, [stackRankTeamView]);

  const memoizedValue = useMemo(
    () => ({
      showDatePicker,
      setShowDatePicker,
      myScheduleDate,
      setMyScheduleDate,
      expandSchedule,
      setExpandSchedule,

      selectedMyScheduleEventId,
      setSelectedMyScheduleEventId,
      monthlyRank,
      setMonthlyRank,
      stackMetric,
      setStackMetric,
      stackRankTeamView,
      setStackRankTeamView,
    }),
    [
      showDatePicker,
      setShowDatePicker,
      myScheduleDate,
      setMyScheduleDate,
      expandSchedule,
      setExpandSchedule,

      selectedMyScheduleEventId,
      setSelectedMyScheduleEventId,
      monthlyRank,
      setMonthlyRank,
      stackMetric,
      setStackMetric,
      stackRankTeamView,
      setStackRankTeamView,
    ],
  );

  return <ScheduleContext.Provider value={memoizedValue}>{children}</ScheduleContext.Provider>;
};
