import styled from "styled-components";
import React from "react";
import { WidgetButton } from "./Elements/WidgetButton";
import { WidgetText } from "./Elements/WidgetText";
import { theme } from "../../../../utils/theme";

interface customizationOptions {
  header_background_color?: string;
  header_text_color?: string;
  font?: string;
  brand_name?: string;
  button_color?: string;
  button_text_color?: string;
  is_demo?: boolean;
}

const Widget = ({ customizationOptions, isMini }: { customizationOptions: customizationOptions; isMini: boolean }) => {
  const widgetStyles = {
    widgetHeader: {
      backgroundColor: customizationOptions.header_background_color,
      color: customizationOptions.header_text_color,
      font: customizationOptions.font,
      height: isMini ? "34px" : "64px",
    },
    widgetHeaderText: {
      brand_name: customizationOptions.brand_name,
      fontWeight: isMini ? 700 : 700,
      fontSize: isMini ? "8.54px" : "16px",
      lineHeight: isMini ? "11.74px" : "22px",
    },
    padding: isMini ? "33px" : "66px",
    welcomeStepInnerSubDiv: {
      width: "100%",
      height: isMini ? 22 : 40,
      marginBottom: isMini ? 17.8 : 40,
    },
    WelcomeStepInnerDiv: {
      minHeight: isMini ? "75px" : "150px",
      gap: isMini ? "8px" : "16px",
    },
    widgetText: {
      fontSize: isMini ? 7.47 : 14,
      fontWeight: 400,
      align: "center",
      fontFamily: customizationOptions.font,
      lineHeight: isMini ? 10.67 : 20,
      text: "Thanks for reaching out! How would you like to connect with us?",
    },
    widgetButton: {
      fontSize: isMini ? 6 : 12,
      height: isMini ? 25 : 48,
      width: isMini ? 100 : 188,
      variant: "primary",
      backgroundColor: customizationOptions.button_color,
      disabled: true,
      textColor: customizationOptions.button_text_color,
      fontFamily: customizationOptions.font,
    },
    OvalOpaqueOverlay: {
      height: isMini ? "45px" : "90px",
      bottom: isMini ? "-30px" : "-60px",
    },
    WidgetFooter: {
      height: isMini ? "25px" : "64px",
      fontWidth: isMini ? 600 : 300,
      fontSize: isMini ? "5px" : "10px",
      lineHeight: isMini ? "8px" : "16px",
      letterSpacing: isMini ? "0.5px" : "1px",
    },

    CloseModalButton: {
      top: isMini ? "4px" : "8px",
      right: isMini ? "4px" : "8px",
      fontSize: isMini ? "10px" : "20px",
      fontWeight: isMini ? 350 : 700,
    },
  };

  return (
    <WidgetDiv>
      <div>
        <WidgetHeader
          height={widgetStyles.widgetHeader.height as string}
          backgroundColor={widgetStyles.widgetHeader.backgroundColor as string}
          color={widgetStyles.widgetHeader.color as string}
          font={widgetStyles.widgetHeader.font as string}
        >
          <WidgetHeaderText
            fontWeight={widgetStyles.widgetHeaderText.fontWeight}
            fontSize={widgetStyles.widgetHeaderText.fontSize}
            lineHeight={widgetStyles.widgetHeaderText.lineHeight}
          >
            {widgetStyles.widgetHeaderText.brand_name}
          </WidgetHeaderText>
          <OvalOpaqueOverlay
            height={widgetStyles.OvalOpaqueOverlay.height}
            bottom={widgetStyles.OvalOpaqueOverlay.bottom}
          />
        </WidgetHeader>

        <div
          style={{
            padding: widgetStyles.padding,
          }}
        >
          <WelcomeStepInnerDiv
            minHeight={widgetStyles.WelcomeStepInnerDiv.minHeight}
            gap={widgetStyles.WelcomeStepInnerDiv.gap}
          >
            <div
              style={{
                width: widgetStyles.welcomeStepInnerSubDiv.width,
                height: widgetStyles.welcomeStepInnerSubDiv.height,
                marginBottom: widgetStyles.welcomeStepInnerSubDiv.marginBottom,
              }}
            >
              <WidgetText
                fontSize={widgetStyles.widgetText.fontSize}
                fontWeight={widgetStyles.widgetText.fontWeight}
                align={widgetStyles.widgetText.align}
                fontFamily={widgetStyles.widgetText.fontFamily}
                lineHeight={widgetStyles.widgetText.lineHeight}
              >
                {widgetStyles.widgetText.text}
              </WidgetText>
            </div>
            <WidgetButton
              fontSize={widgetStyles.widgetButton.fontSize}
              height={widgetStyles.widgetButton.height}
              width={widgetStyles.widgetButton.width}
              variant={widgetStyles.widgetButton.variant}
              backgroundColor={widgetStyles.widgetButton.backgroundColor}
              disabled={widgetStyles.widgetButton.disabled}
              textColor={widgetStyles.widgetButton.textColor}
              fontFamily={widgetStyles.widgetButton.fontFamily}
            >
              CALL ME NOW
            </WidgetButton>
            <WidgetButton
              fontSize={widgetStyles.widgetButton.fontSize}
              height={widgetStyles.widgetButton.height}
              width={widgetStyles.widgetButton.width}
              variant={widgetStyles.widgetButton.variant}
              backgroundColor={widgetStyles.widgetButton.backgroundColor}
              disabled={widgetStyles.widgetButton.disabled}
              textColor={widgetStyles.widgetButton.textColor}
              fontFamily={widgetStyles.widgetButton.fontFamily}
            >
              SCHEDULE A MEETING
            </WidgetButton>
          </WelcomeStepInnerDiv>
        </div>
        <CloseModalButton
          top={widgetStyles.CloseModalButton.top}
          right={widgetStyles.CloseModalButton.right}
          fontSize={widgetStyles.CloseModalButton.fontSize}
          fontWeight={widgetStyles.CloseModalButton.fontWeight}
          disabled={true}
        >
          {" "}
          X{" "}
        </CloseModalButton>
      </div>
      <WidgetFooter
        height={widgetStyles.WidgetFooter.height}
        fontWidth={widgetStyles.WidgetFooter.fontWidth}
        fontSize={widgetStyles.WidgetFooter.fontSize}
        lineHeight={widgetStyles.WidgetFooter.lineHeight}
        letterSpacing={widgetStyles.WidgetFooter.letterSpacing}
      >
        <span>
          {" "}
          <span>powered by </span>
          <span style={{ color: "#3E65FF" }}>Sellfire</span>
        </span>
      </WidgetFooter>
    </WidgetDiv>
  );
};

const OvalOpaqueOverlay = styled.div<{ height: string; bottom: string }>`
  position: absolute;
  width: 110%;
  height: ${(props) => props.height};
  bottom: ${(props) => props.bottom};
  border-radius: 50%;
  background: linear-gradient(116.54deg, rgb(250, 250, 250, 0) 11.56%, #ffffff 93.04%);
  opacity: 0.2;
  // tilt the oval
  transform: rotate(-1.44deg);
`;

const WidgetFooter = styled.div<{
  height: string;
  fontWidth: number;
  fontSize: string;
  letterSpacing: string;
  lineHeight: string;
}>`
  width: 100%;
  height: ${(props) => props.height};
  justify-content: center;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid #dcdde5;

  font-family: "Inter";
  font-style: normal;
  font-weight: ${(props) => props.fontWidth};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.fontSize};
  letter-spacing: ${(props) => props.letterSpacing};
  text-transform: uppercase;
  color: #949ab1;
`;

interface WidgetHeaderProps {
  backgroundColor: string;
  color: string;
  font: string;
  height: string;
}

const WidgetHeader = styled.div.attrs((props: WidgetHeaderProps) => ({
  style: {
    background: props.backgroundColor ?? theme.PRIMARY500,
    color: props.color ?? theme.WHITE_COLOR,
    fontFamily: props.font,
  },
}))<WidgetHeaderProps>`
  width: 100%;
  height: ${(props) => props.height};
  justify-content: center;
  display: flex;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: -1;
  overflow: hidden;
  position: relative;
`;

const WidgetHeaderText = styled.h1<{
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
}>`
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
`;

const WidgetDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  position: relative;
`;

const CloseModalButton = styled.button<{
  disabled: boolean;
  top: string;
  right: string;
  fontSize: string;
  fontWeight: number;
}>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  border: none;
  background-color: transparent;
  color: white;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: 0;
  margin: 0;
`;
const WelcomeStepInnerDiv = styled.div<{ gap: string; minHeight: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${(props) => props.minHeight};
  height: 100%;
  gap: ${(props) => props.gap};
`;

export default Widget;
