import { CustomField, FormField } from "src/utils/misc";

const useAvailableFields = ({
  systemFields,
  customFields,
  values,
}: {
  systemFields: string[];
  customFields: CustomField[];
  values: any & {
    hidden_fields: FormField[];
    form_fields: FormField[];
    referrer_fields: FormField[];
  };
}) => {
  const allFields: FormField[] = [...values.form_fields, ...values.hidden_fields, ...values.referrer_fields].filter(
    Boolean,
  );

  const availableCustomFields = customFields.filter((field) => !allFields.some((f) => f.custom_field_id === field.id));
  const availableSystemFields = systemFields
    .filter((field) => !allFields.some((f) => f.system_field === field))
    .filter((f) => f !== "LEAD_SOURCE");

  return { availableCustomFields, availableSystemFields };
};

export default useAvailableFields;
