import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTabFocus } from "src/utils/hooks";
import { appToast } from "src/utils/toast";
import styled from "styled-components";
import { ModalContext } from "../../context";
import google_meet_dial_in from "../../images/screenshots/google_meet_dial_in.png";
import google_meet_recording_reminder from "../../images/screenshots/google_meet_recording_reminder.png";
import google_meet_no_extension from "../../images/screenshots/google_meet_no_extension.png";
import { theme } from "../../utils/theme";
import { AppErrorText, AppText, FlexDiv } from "../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { google_meet_activities_logo, info } from "src/images/NewDesign";

const JoinZoomModal: React.FC = () => {
  const { showJoinZoomModal, setShowJoinZoomModal, joinZoomModalData } = useContext(ModalContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pin, setPin] = useState("");
  const [extensionExplainerStep, setExtensionExplainerStep] = useState<boolean>(false);
  const [recordingExplainerStep, setRecordingExplainerStep] = useState<boolean>(false);

  const parseClipboardData = (data: string) => {
    const phoneRegex = /ZOOM-PHONE:(.*?)PIN:/;
    const pinRegex = /PIN:(.*)/;
    const phoneMatch = data.match(phoneRegex);
    const pinMatch = data.match(pinRegex);
    const phone = phoneMatch?.[1]?.trim()?.replace(/\s/g, "")?.replace(/-/g, "");
    const pin = pinMatch?.[1]?.trim()?.replace("#", "")?.replace(/\s/g, "");
    return { phone, pin };
  };

  useTabFocus(async () => {
    setTimeout(async () => {
      if (document.hasFocus()) {
        const userHasFilledOutFields = phoneNumber?.length && pin?.length;
        if (userHasFilledOutFields) {
          return;
        }
        try {
          const clipboardData = await navigator.clipboard.readText();

          const clipboardDataIsFromExtension =
            clipboardData?.includes("ZOOM-PHONE:") && clipboardData?.includes("PIN:");

          if (clipboardDataIsFromExtension) {
            const { phone, pin } = parseClipboardData(clipboardData);
            phone && setPhoneNumber(phone);
            pin && setPin(pin);
            appToast("Autofilled phone number and pin");
            // clear clipboard
            navigator.clipboard.writeText("");
          }
        } catch (err) {
          console.error("Failed to read clipboard contents: ", err);
        }
      }
    }, 1000);
  });

  // besides checking on focus we want to check every 5 seconds if the clipboard has new data this will help prevent issues where the user browser blocked the clipboard read on the first try
  useEffect(() => {
    const interval = setInterval(() => {
      if (document.hasFocus()) {
        const userHasFilledOutFields = phoneNumber?.length && pin?.length;
        if (userHasFilledOutFields) {
          return;
        }

        try {
          navigator.clipboard.readText().then((text) => {
            if (text.includes("ZOOM-PHONE:") && text.includes("PIN:")) {
              const { phone, pin } = parseClipboardData(text);

              phone && setPhoneNumber(phone);
              pin && setPin(pin);
              appToast("Autofilled phone number and pin");
              // clear clipboard
              navigator.clipboard.writeText("");
            }
          });
        } catch (err) {
          console.error("Failed to read clipboard contents: ", err);
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Modal open={showJoinZoomModal} onClose={() => setShowJoinZoomModal(false)}>
      <EditCardDiv>
        {extensionExplainerStep ? (
          <NoExtensionStep
            setExtensionExplainerStep={setExtensionExplainerStep}
            joinZoomModalData={joinZoomModalData}
          />
        ) : recordingExplainerStep ? (
          <HowToRecordMeetingStep setRecordingExplainerStep={setRecordingExplainerStep} />
        ) : (
          <MainStep
            phoneNumber={phoneNumber}
            pin={pin}
            setPhoneNumber={setPhoneNumber}
            setPin={setPin}
            setRecordingExplainerStep={setRecordingExplainerStep}
            setExtensionExplainerStep={setExtensionExplainerStep}
            setShowJoinZoomModal={setShowJoinZoomModal}
            joinZoomModalData={joinZoomModalData}
          />
        )}
      </EditCardDiv>
    </Modal>
  );
};

const MainStep = ({
  phoneNumber,
  pin,
  setPhoneNumber,
  setPin,
  setExtensionExplainerStep,
  setRecordingExplainerStep,
  setShowJoinZoomModal,
  joinZoomModalData,
}: {
  phoneNumber: string;
  pin: string;
  setPhoneNumber: (phoneNumber: string) => void;
  setPin: (pin: string) => void;
  setExtensionExplainerStep: (extensionExplainerStep: boolean) => void;
  setRecordingExplainerStep: (recordingExplainerStep: boolean) => void;
  setShowJoinZoomModal: (showJoinZoomModal: boolean) => void;
  joinZoomModalData: {
    dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
    meetingLink: string;
  };
}) => {
  console.log("joinZoomModalData", joinZoomModalData);

  const SELLFIRE_EXTENSION_LINK = "https://chromewebstore.zoom.com/detail/sellfire/ljfjngncicimgjjcjigboilfocmlcajg";

  return (
    <>
      <TitleDiv>
        <TitleText>Join a Zoom Meet Meeting</TitleText>
      </TitleDiv>
      <Main>
        <>
          <img src={google_meet_dial_in} alt="Zoom Meet Dial In" height="100%" width="100%" />
          <FlexDiv direction="column" gap={8}>
            <AppText fontSize={14} fontWeight={500}>
              Fill in the generated phone number and PIN.
            </AppText>
            <InstructionsList>
              <ListItem>
                Make sure you've installed the{" "}
                <Link onClick={() => window.open(SELLFIRE_EXTENSION_LINK, "_blank")}>
                  Sellfire Extension for Chrome.
                </Link>
              </ListItem>
              <ListItem>
                Go to <Link onClick={() => window.open(joinZoomModalData?.meetingLink, "_blank")}>Zoom Meet.</Link>
              </ListItem>
              <ListItem>
                Select <Bold>"Join and use a phone for audio."</Bold>
              </ListItem>
              <ListItem>Come back to this tab to auto-fill the number and PIN.</ListItem>
            </InstructionsList>
          </FlexDiv>
          <PhoenixInput
            value={phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
            title="Phone Number"
            titleText="Phone Number"
            displayNoContextText
            requiredStar
            titleTextSpacing={8}
          />
          <PhoenixInput
            value={pin}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPin(e.target.value)}
            requiredStar
            titleText="PIN"
            displayNoContextText
            titleTextSpacing={8}
          />
          <FlexDiv direction="column" gap={0}>
            <PhoenixAppButton
              variant="brand-outline"
              buttonType="ghost-small"
              onClick={() => setExtensionExplainerStep(true)}
            >
              Haven't installed the Sellfire Extension yet?
            </PhoenixAppButton>
            <PhoenixAppButton
              variant="brand-outline"
              buttonType="ghost-small"
              onClick={() => setRecordingExplainerStep(true)}
            >
              How to record a Zoom Meet meeting?
            </PhoenixAppButton>
          </FlexDiv>
        </>
      </Main>
      <SubmitDiv>
        <PhoenixAppButton
          disabled={!phoneNumber || !pin}
          buttonType="secondary"
          variant="brand"
          onClick={() => {
            joinZoomModalData?.dialInToMeeting({ newPhoneNumber: phoneNumber, newPin: pin });
            setShowJoinZoomModal(false);
          }}
        >
          Join Meeting
        </PhoenixAppButton>
        <FlexDiv gap={8} align="center">
          <PhoenixIcon svg={info} variant="danger" size={16} />
          <AppText fontSize={12} fontWeight={400} color={theme.text.danger.primary}>
            Record this meeting for future reference.
          </AppText>
        </FlexDiv>
      </SubmitDiv>
    </>
  );
};

const NoExtensionStep = ({
  setExtensionExplainerStep,
  joinZoomModalData,
}: {
  setExtensionExplainerStep: (extensionExplainerStep: boolean) => void;
  joinZoomModalData: {
    meetingLink: string;
  };
}) => {
  const SELLFIRE_EXTENSION_LINK = "https://chromewebstore.google.com/detail/sellfire/ljfjngncicimgjjcjigboilfocmlcajg";

  return (
    <>
      <TitleDiv>
        <TitleText>Haven't installed the Sellfire Extension yet?</TitleText>
      </TitleDiv>
      <Main>
        <img src={google_meet_no_extension} alt="Zoom Meet No Extension" height="100%" width="100%" />
        <FlexDiv direction="column" gap={8}>
          <AppText fontSize={12} fontWeight={400}>
            No worries! You can install it{" "}
            <Link onClick={() => window.open(SELLFIRE_EXTENSION_LINK, "_blank")}>here</Link> or enter the info manually.
          </AppText>
          <AppText fontSize={12} fontWeight={400}>
            To find your dial in number and PIN:
          </AppText>
          <InstructionsList>
            <ListItem>
              Go to <Link onClick={() => window.open(joinZoomModalData?.meetingLink, "_blank")}>Zoom.</Link>
            </ListItem>
            <ListItem>
              Select <Bold>"Join and use a phone for audio."</Bold>
            </ListItem>
            <ListItem>
              Open the <Bold>"Dial in using phone audio"</Bold> tab to find your phone number and PIN.
            </ListItem>
          </InstructionsList>
          <AppText fontSize={10} fontWeight={400} color={theme.text.neutral.tertiary}>
            Each meeting generates unique numbers. Do not copy the above.
          </AppText>
        </FlexDiv>
      </Main>
      <SubmitDiv>
        <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => setExtensionExplainerStep(false)}>
          Got it
        </PhoenixAppButton>
      </SubmitDiv>
    </>
  );
};

const HowToRecordMeetingStep = ({
  setRecordingExplainerStep,
}: {
  setRecordingExplainerStep: (recordingExplainerStep: boolean) => void;
}) => {
  return (
    <>
      <TitleDiv>
        <TitleText>How to record a Zoom Meet meeting?</TitleText>
      </TitleDiv>
      <Main>
        <>
          <img src={google_meet_recording_reminder} alt="Zoom Meet Recording Reminder" height="100%" width="100%" />
          <InstructionsList>
            <ListItem>
              At the bottom right, click <Bold>"Activities"</Bold>{" "}
              <img src={google_meet_activities_logo} alt="Zoom Meet Activities" height="12px" width="12px" />
            </ListItem>
            <ListItem>
              Click <Bold>"Recording"</Bold>, then click <Bold>"Start Recording."</Bold>
            </ListItem>
            <ListItem>You will hear an indication when the recording starts.</ListItem>
          </InstructionsList>
        </>
      </Main>
      <SubmitDiv>
        <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => setRecordingExplainerStep(false)}>
          Got it
        </PhoenixAppButton>
      </SubmitDiv>
    </>
  );
};

const InstructionsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-weight: 400;
  font-size: 12px;
  padding-left: 2px;
`;

const Link = styled.span`
  color: ${theme.text.brand.primary};
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  width: 100%;
  padding: 0px 40px 0px 40px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 460px;
  max-width: 70vw;
  gap: 24px;
  max-height: 80vh;

  overflow: auto;
`;

const SubmitDiv = styled.div`
  display: flex;
  border-top: 1px solid ${theme.border.neutral.secondary};
  align-items: center;
  padding: 16px 40px;
  flex-direction: column;
  gap: 12px;
  height: max-content;
`;

export { JoinZoomModal };
