import { RowClassParams } from "ag-grid-community";
import { AgGridReact, ChangeDetectionStrategyType } from "ag-grid-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { lock, reorder } from "../../../../images/NewDesign";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { SkeletonBlock } from "../../../UI/SkeletonBlock";
import "./LeadRankingTable.css";
import { gql, useQuery, useMutation } from "@apollo/client";
import { appToast } from "../../../../utils/toast";
import { useDebounce, useFirstRender } from "../../../../utils/hooks";
import { cloneDeep } from "lodash";
import { theme } from "../../../../utils/theme";

interface RankingTableRule {
  value: string;
  label: string;
  movable: boolean;
  // server uses index order for priority we need to map that order to priority to use with ag-grid
  priority: number;
}

interface ServerRule {
  value: string;
  label: string;
  movable: boolean;
}

interface FetchRulesExpectedResponse {
  fetchActiveQueueSortOptions: ServerRule[];
}

// GraphQL queries and mutations

const FETCH_RULES = gql`
  query FetchRules {
    fetchActiveQueueSortOptions
  }
`;

const UPDATE_RULE_ORDER = gql`
  mutation UpdateActiveQueueSortOrder($activeQueueSortOrder: [QueueSortOptions!]!) {
    updateActiveQueueSortOrder(active_queue_sort_order: $activeQueueSortOrder) {
      id
    }
  }
`;

// Helper functions

/*
 * this function is used to reorder the rows to have non-draggable rows at the top
 *
 * We had to do this due to edge cases with ag-grid where it would not honor the state value order in certain cases
 *
 * @param rows - the rows to reorder
 * @returns the reordered rows
 */
const reorderTableRows = (rows: RankingTableRule[]) => {
  const noDragRows = rows.filter((row) => !row.movable);
  const dragRows = rows.filter((row) => row.movable);
  const combinedRows = [...noDragRows, ...dragRows];
  return combinedRows?.map((row, index) => {
    row.priority = index + 1;
    return row;
  });
};

const handleRowDragEnd = async ({
  event,
  rowData,
  setRowData,
}: {
  event: any;
  rowData: RankingTableRule[];
  setRowData: (rowData: RankingTableRule[]) => void;
}) => {
  // edge case handling:
  const invalidAGGridState = !event || !event?.api || !event?.node || !event?.overIndex;
  // this can happen when the user drags a row out of the grid and then releases the mouse
  // this would actualy cause the ag-grid to move the row to the nearest index udpate the row locally but not update the state value
  // resulting in the row being in an invalid position and not in sync with the state value
  if (invalidAGGridState) {
    setRowData(reorderTableRows(rowData));

    if (event?.api && event?.api?.setRowData) {
      event.api.setRowData(reorderTableRows(rowData));
    }

    return;
  }

  // main logic:

  const currentRowData = cloneDeep(rowData);

  const draggedAGRowData = event?.node?.data;

  const rowDataNotFound = !draggedAGRowData || !draggedAGRowData.value;

  if (rowDataNotFound) return;

  const draggedRowIndex = currentRowData.findIndex((row) => row?.value === draggedAGRowData?.value);

  const newRows = cloneDeep(currentRowData);

  newRows.splice(draggedRowIndex, 1);

  newRows.splice(event.overIndex, 0, draggedAGRowData);

  // update the priority that AG-Grid uses to render and order the rows
  newRows.forEach((row, index) => {
    row.priority = index + 1;
  });

  // update the state and ensure the ag-grid is updated to having the non-draggable rows at the top
  setRowData(reorderTableRows(newRows));
};

/*
 * LeadRankingTable
 *
 * This component is used to display the lead ranking table for a given queue
 *
 * Currently this is just for the active queue but could change in the future
 *
 * @param queue - the queue to display the lead ranking table for
 */

/*
 * This function is used to style the rows based on the movable property
 *
 * @param params - the ag-grid row params
 * @returns the style to apply to the row
 */
const getRowStyle = (params: RowClassParams) => {
  if (!params?.data?.movable) {
    return { pointerEvents: "none" };
  }
  return undefined;
};

const LeadRankingTable = ({ queue = "Active" }) => {
  // state
  const [rowData, setRowData] = useState<RankingTableRule[]>([]);

  // queries and mutations
  const { data: rulesData, loading: loadingRules, error: errorRules } = useQuery<FetchRulesExpectedResponse>(
    FETCH_RULES,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ fetchActiveQueueSortOptions }) => {
        const rules = fetchActiveQueueSortOptions;

        const orderedRules: RankingTableRule[] = reorderTableRows(
          rules?.map((rule, index) => ({ ...rule, priority: index + 1 })),
        );

        setRowData(orderedRules);
      },
    },
  );

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER, {
    onCompleted: (data) => {
      console.log("Successfully updated rule order");
      appToast("Successfully updated rule order");
    },
    onError: (error) => {
      appToast("Error updating rule order");
      console.log("Error updating rule order: ", error);
    },
  });

  const memoizedRowData = useMemo(() => rowData, [rowData]);

  const isFirstRender = useFirstRender();

  useDebounce(
    () => {
      // if the memoizedRowData changes, update the server

      // first check if initial load has completed
      if (isFirstRender) {
        console.log("Initial render, skipping update");
        return;
      }

      // check for loaded data

      const serverData = rulesData?.fetchActiveQueueSortOptions
        ?.filter((rule) => rule.movable)
        ?.map((rule) => rule.value);

      const localData = memoizedRowData.filter((rule) => rule.movable)?.map((rule) => rule.value);

      const notInitialized = !serverData || !localData;

      if (notInitialized) {
        console.log("Data has not been initialized");
        return;
      }

      const dataChanged = JSON.stringify(serverData) !== JSON.stringify(localData);

      if (!dataChanged) {
        console.log("Data has not changed");
        return;
      }

      // if the data has changed, update the server
      updateRuleOrder({
        variables: {
          activeQueueSortOrder: memoizedRowData?.map((rule) => rule.value),
        },
      });
    },
    [memoizedRowData],
    1000,
  );

  // AG-Grid  Logic

  const AgGridRef = useRef<AgGridReact | null>(null);

  useEffect(() => {
    const AGGridInitalize = AgGridRef?.current?.api;

    if (!AGGridInitalize) {
      console.log("AG Grid is not initialized yet");
      return;
    }

    if (!AgGridRef?.current?.api) {
      console.log("AG Grid column API is not available");
      return;
    }

    if (!memoizedRowData || memoizedRowData.length === 0) {
      console.log("Row data is empty or not available");
      return;
    }

    AgGridRef?.current.api?.setRowData(memoizedRowData);
    AgGridRef?.current.api.refreshClientSideRowModel("group");
  }, [memoizedRowData]);

  const PriorityCellRenderer = (props: any) => {
    return (
      <GridCell>
        {!props.data.movable ? (
          <PhoenixIcon
            svg={lock}
            size={16}
            color={theme.icon.neutral.default}
            hoverColor={theme.icon.neutral.default}
          />
        ) : (
          <PhoenixIcon svg={reorder} size={16} />
        )}
        <div style={{ marginLeft: "8px" }}>{props.value}</div>
      </GridCell>
    );
  };

  const columnDefs = [
    {
      headerName: "Order",
      field: "priority",
      width: 66,
      headerClass: "ag-lead-ranking-table-header",
      rowDrag: true,
      cellRendererFramework: PriorityCellRenderer,
    },
    {
      headerName: "Logic",
      field: "label",
      headerClass: "ag-lead-ranking-table-header",
      width: 380,
    },
  ];

  const loading = loadingRules;

  const error = errorRules;

  if (error) {
    return <div>Error! Something went wrong.</div>;
  }

  if (loading) {
    return <SkeletonBlock height={320} width={446} borderRadius={4} />;
  }

  return (
    <>
      <div className={"ag-theme-lead-ranking-table"} style={{ height: "max-content", width: "446px" }}>
        <AgGridReact
          ref={AgGridRef}
          rowDataChangeDetectionStrategy={"DeepValueCheck" as ChangeDetectionStrategyType}
          rowData={memoizedRowData}
          domLayout="autoHeight"
          rowDragEntireRow={true}
          columnDefs={columnDefs}
          suppressMovableColumns={true}
          suppressDragLeaveHidesColumns={true}
          tooltipMouseTrack={true}
          rowDragManaged
          animateRows={true}
          getRowStyle={(params) => getRowStyle(params)}
          onRowDragMove={(event) => {
            // failsafe for css block on non movable rows being removed

            const rowIsNotMovable = !event?.node?.data?.movable;
            if (rowIsNotMovable) {
              // If it's an unmovable row, prevent the default behavior of dragging
              event.event.preventDefault();
            }
          }}
          // onRowDragLeave doesn't mean the user has reorganized the rows, it just means they've left the table while dragging the row and then let go of the mouse

          // this leads to edge cases where the row order is not updated or updated incorrectly in the ag-grid state but not updated in the state value
          onRowDragLeave={(event) => {
            if (!event || !event.api) {
              return;
            }

            // reset the row order to the original order
            event.api.setRowData(reorderTableRows(rowData));
          }}
          // normal drag end logic
          onRowDragEnd={(event) => {
            if (!event || !event.api) {
              return;
            }
            handleRowDragEnd({
              event,
              rowData,
              setRowData,
            });
          }}
          enableCellTextSelection={true}
        />
      </div>
    </>
  );
};

const GridCell = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  height: 100%;
`;

export default LeadRankingTable;
