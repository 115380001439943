import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { appToast } from "../../utils/toast";
import { FormAreaInputField } from "../Field";
import { AppButton, AppText, Loading } from "../UI";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  /**
   * organization id
   */
  id?: string;
  sms_opt_in_dialog?: string;
}

const followupSchema = Yup.object().shape({
  sms_opt_in_dialog: Yup.string().required(""),
});

interface MyFormikProps {
  sms_opt_in_dialog: string;
}

const EDIT_SMS_DIALOG = gql`
  mutation updateSMSDialog($sms_opt_in_dialog: String!) {
    updateSMSDialog(sms_opt_in_dialog: $sms_opt_in_dialog) {
      id
      sms_opt_in_dialog
    }
  }
`;

const UpdateSMSDialog: React.FC<DisappearingDivProps> = ({ visible, close, id, ...props }) => {
  const [updateSMSDialog, { loading, error }] = useMutation(EDIT_SMS_DIALOG, {
    onCompleted({ updateSMSDialog }) {
      console.log("updateSMSDialog: ", updateSMSDialog);
      //window.location.reload(false);
      if (!updateSMSDialog) {
        return;
      }
      appToast("SMS Diaglog updated");
      close();
    },
    onError({ message }) {
      console.log("Error in createAutomatedCampaign: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateSMSDialog GraphQL Error: ${message}`,
      });
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Follow Up page of Admin Onboarding"}>
      <Formik
        initialValues={{
          sms_opt_in_dialog: props.sms_opt_in_dialog || "",
        }}
        validationSchema={followupSchema}
        onSubmit={async ({ sms_opt_in_dialog }) => {
          await updateSMSDialog({
            variables: {
              sms_opt_in_dialog,
            },
          });
          return true;
        }}
      >
        {({ submitForm, isSubmitting }: FormikProps<MyFormikProps>) => {
          return (
            <Modal open={visible} onClose={close} closeButtonSize={16}>
              <ScrollingDiv>
                <CenterDiv>
                  <PopupTitle>SMS Input Dialog</PopupTitle>
                </CenterDiv>
                <FormAreaInputField name="sms_opt_in_dialog" placeholder="SMS Input Dialog" />
                <CenterDiv>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <AddEmailButton type="submit" onClick={submitForm}>
                      Save
                    </AddEmailButton>
                  )}
                </CenterDiv>
                <CenterDiv>
                  <AddEmailCancelButton cancel onClick={close}>
                    Cancel
                  </AddEmailCancelButton>
                </CenterDiv>
                <WhitespaceDiv />
              </ScrollingDiv>
            </Modal>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const WhitespaceDiv = styled.div`
  height: 50px;
`;

const AddEmailCancelButton = styled(AppButton)`
  font-size: 10px;
  font-weight: 600;
  width: 136px;
  height: 32px;
  border-radius: 2px;
  text-transform: uppercase;
  margin: auto;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

const ScrollingDiv = styled.div`
  padding: 40px 66px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

export { UpdateSMSDialog };
