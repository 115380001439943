import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Call, Device, TwilioError } from "@twilio/voice-sdk";
import moment from "moment";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { CreateInstantMeetingResponse } from "src/Components/Segments/CallSegments";
import { useTabFocus } from "src/utils/hooks";
import { v4 as uuidv4 } from "uuid";
import { restAPI } from "../apollo";
import { currentCallState, inputDevices, outputDevices, twilioMuted } from "../apollo/cache";
import { LOGGED_IN_USER } from "../apollo/query";
import { MixpanelActions } from "../services/mixpanel";
import { axios } from "../services/rest";
import { CurrentLeadType, ILeadIntentItem, IScheduleItem, OTFMeetingType, ParentType } from "../types";
import { extractNumber } from "../utils/format";
import { addDashBack, setEnvDocTitle, tabSync } from "../utils/misc";
import { appToast, connectionToast, errorToast } from "../utils/toast";
import { BACKEND_URL, USER_STATUS } from "../utils/variables";
import { UserStatusContext } from "./UserStatusContext";
import { ModalContext } from "./ModalContext";

const UPDATE_CALL_HOLD_STATUS = gql`
  mutation updateLeadCallHoldStatus($lead_id: String!, $hold: Boolean!) {
    updateLeadCallHoldStatus(lead_id: $lead_id, hold: $hold) {
      current_hold_status
    }
  }
`;
const UPDATE_CALL_NOTES = gql`
  mutation updateCallNotes($call_notes: String!, $lead_id: String, $call_sid: String) {
    updateCallNotes(call_notes: $call_notes, lead_id: $lead_id, call_sid: $call_sid) {
      id
      notes
      updated_at
    }
  }
`;

const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($status: STATUS!, $call_sid: String) {
    updateUserStatus(status: $status, call_sid: $call_sid) {
      id
      email
      status
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser {
    fetchUser {
      id
      default_audio_input
      default_audio_output
    }
  }
`;

const FETCH_CALL_NOTES_TEMPLATE = gql`
  query fetchCallNotesTemplate {
    fetchCallNotesTemplate {
      body
      included_number_dialed_from
      require_notes
    }
  }
`;

const UPDATE_USER_TAB = gql`
  mutation updateRepTab($tab_id: String!) {
    updateRepTab(tab_id: $tab_id)
  }
`;

interface ISuggestedActionData {
  intentData?: ILeadIntentItem;
  leadData?: Partial<CurrentLeadType>;
  expectedAction?: string;
  suggestedAndNextActionAreTheSameLead?: boolean;
}

type TransferParams = {
  from: string;
  to: string;
  conference_name: string;
};

interface IOptionalOverrideIntentData {
  id?: string;
  lead_id?: string;
  type?: string;
  is_scheduled_item?: boolean;
  schedule_item?: IScheduleItem;
  associate_parent?: Partial<CurrentLeadType>;
  associate_parent_id?: string | null;
}

export interface OTFVideoMeeting {
  meeting_id: string;
  sellfire_meeting_id: string;
  meeting_type: OTFMeetingType;
  meeting_url: string;
  passcode?: string;
  created_at: string;
}

// required For NextDialOverride (lead_id, primary_phone_number)
interface IRequiredLeadData extends Partial<CurrentLeadType> {
  id: string;
  primary_phone_number: string;
}
// required fields for NextDialOverride is the lead id and the phone number
// the other fields are optional and only used for passing in a double booked event intent into the NextDialOverride
interface INextDialOverrideData extends IOptionalOverrideIntentData {
  lead: IRequiredLeadData;
}

interface CallContextState {
  callState: boolean;
  incomingCallState: CallStateState;
  joinMeItem: {
    viewerLink: string;
    presenterLink: string;
  };
  setJoinMeItem: Dispatch<SetStateAction<{ viewerLink: string; presenterLink: string }>>;
  joinMeLeadID: string;
  setJoinMeLeadID: Dispatch<SetStateAction<string>>;
  recording: boolean;
  startedRecording: boolean;
  recordingButtonDisabled: boolean;
  callNotes?: string;
  callLeadId?: string;
  getCallLeadId: () => string | undefined;
  isNonCallActivity?: boolean;
  callStartTime?: Date;
  phoneNumber?: string;
  intentId?: string;
  intentReplacementId?: string | null | undefined;
  goToCallState: ({
    callLeadId,
    phoneNumber,
    intentId,
    CallSid,
  }: {
    callLeadId?: string;
    phoneNumber?: string;
    intentId?: string;
    CallSid?: string;
  }) => void;

  revertCallState: () => void;
  conferenceState: boolean;
  setConferenceState: (state: boolean) => void;
  updateCallNotes: (call_notes: string, lead_id: string) => void;
  persistCallNotes: () => void;
  toggleRecording: (state: boolean) => void;
  toggleDidStartRecording: (state: boolean) => void;
  setRecordingButtonDisabled: (disabled: boolean) => void;
  handleRevertClosingScript: (disabled: boolean, state: boolean) => void;
  callNotesLastUpdated: string;
  nextActionOverride: {
    intent_object: INextDialOverrideData;
    parent: ParentType;
  };
  setNextActionOverride: Dispatch<
    SetStateAction<{ intent_object: INextDialOverrideData; parent: ParentType | undefined }>
  >;
  resetNextDial: () => void;
  stopScheduledEventNotification: boolean;
  setStopScheduledEventNotification: Dispatch<SetStateAction<boolean>>;
  callEntity?: Call;
  device?: Device;
  setCallEntity: (callEntity: any) => void;
  toggleMute: () => void;
  mute: () => void;
  unMute: () => void;
  sendDigit: (digit: string) => any;
  callNumber: (callNumberParams: {
    phone: string;
    lead_id: string;
    suggested_dial?: boolean;
    intent_id?: string;
  }) => any;
  setupDevice: (authToken: string) => void;
  refreshToken: () => Promise<void>;
  showOptions: () => void;
  updateInputDevices: () => void;
  updateOutputDevices: () => void;
  setupInputDevices: () => void;
  setupOutputDevices: () => void;
  unsetOutputDevices: () => void;
  unsetInputDevices: () => void;
  transfer: (transferParams: TransferParams) => Promise<void>;
  hangup: () => void;
  updateCallHoldStatus: (hold: boolean) => void;
  getStatus: () => void;
  changeInputDevice: (deviceId: string) => Promise<void>;
  changeOutputDevice: (deviceId: string) => Promise<void>;
  getOutputDevice: () => any;
  showIncoming: boolean;
  isZoomCall: boolean;
  isGoogleMeetCall: boolean;
  setShowIncoming: (showIncoming: boolean) => void;
  callSid?: string;
  leadId: string;
  setLeadId: (leadId: string) => void;
  authToken?: string;
  setAuthToken?: (authToken: string) => void;
  incomingError: string;
  setIncomingError: (incomingError: string) => void;
  isAutoDialActive: boolean;
  setIsAutoDialActive: (isAutoDialActive: boolean) => void;
  defaultInput: string;
  setDefaultInput: Dispatch<SetStateAction<string>>;
  defaultOutput: string;
  setDefaultOutput: Dispatch<SetStateAction<string>>;
  personSpokeTo: string;
  setPersonSpokeTo: Dispatch<SetStateAction<string>>;
  associatedAction: string | undefined;
  setAssociatedAction: Dispatch<SetStateAction<string | undefined>>;
  goToCall: ({
    lead_id,
    phoneNumber,
    intentId,
    intentReplacementId,
    suggested_dial,
    dialAsNumber,
    presetDigitsToSend,
    persistPreviousCallDispositionFlow,
    google_space_sellfire_id,
    zoom_meeting_sellfire_id,
    action_source,
  }: {
    lead_id?: string;
    phoneNumber: string;
    intentId?: string;
    intentReplacementId?: string | null | undefined;
    suggested_dial?: boolean;
    dialAsNumber?: string;
    presetDigitsToSend?: string[];
    persistPreviousCallDispositionFlow?: boolean;
    google_space_sellfire_id?: string;
    zoom_meeting_sellfire_id?: string;
    action_source?: string;
  }) => Promise<void>;
  callOptionStack: string[];
  setCallOptionStack: Dispatch<SetStateAction<string[]>>;
  callOptionStackPush: (item: string) => void;
  callOptionStackPeek: () => string;
  callOptionStackPop: // optional number input
  (num?: number) => void;
  resetCallOptionStack: () => void;
  transferError: string;
  setTransferError: Dispatch<SetStateAction<string>>;
  transferState: TransferState;
  setTransferState: Dispatch<SetStateAction<TransferState>>;
  resetIncomingCall: () => void;
  incomingCallEntity?: Call;
  setIncomingCallEntity: (callEntity: any) => void;
  suggestedActionData: ISuggestedActionData | null;
  setSuggestedActionData: Dispatch<SetStateAction<ISuggestedActionData | null>>;
  callCameFromTransfer: boolean;
  cancelTransferGlobal: (callSid?: string) => void;
  transfer_attempt_id: string;
  setTransferAttemptId: Dispatch<SetStateAction<string>>;
  dialAsNumber: string | undefined;
  setDialAsNumber: Dispatch<SetStateAction<string | undefined>>;
  secondaryCallIncoming: boolean;
  setSecondaryCallIncoming: Dispatch<SetStateAction<boolean>>;
  timeOnCall: number;
  setTimeOnCall: Dispatch<SetStateAction<number>>;
  logNonCallActivity: (lead_id: string) => void;
  repLeadSearch: string;
  setRepLeadSearch: Dispatch<SetStateAction<string>>;
  includeNumberDialedFrom: boolean;
  setIncludeNumberDialedFrom: Dispatch<SetStateAction<boolean>>;
  requireNotes: boolean;
  setRequireNotes: Dispatch<SetStateAction<boolean>>;
  performedNoteAction: boolean;
  setPerformedNoteAction: Dispatch<SetStateAction<boolean>>;
  deviceResetCheck: boolean;
  setDeviceResetCheck: Dispatch<SetStateAction<boolean>>;
  externalRepCallSid: string;
  setExternalRepCallSid: Dispatch<SetStateAction<string>>;
  kickExternalTransferNumber: () => void;
  isConnectingToTwilio: boolean;
  setIsConnectingToTwilio: Dispatch<SetStateAction<boolean>>;
  isEnteringRequiredFields: boolean;
  setIsEnteringRequiredFields: Dispatch<SetStateAction<boolean>>;
  existingOTFVideoMeetings: Record<string, OTFVideoMeeting>;
  addOTFVideoMeeting: ({ leadId, OTFVideoMeeting }: { leadId: string; OTFVideoMeeting: OTFVideoMeeting }) => void;
  removeOTFVideoMeeting: (leadId: string) => void;
  handleLeadCardRefresh: Function | undefined;
  setHandleLeadCardRefresh: Dispatch<SetStateAction<Function | undefined>>;
  currentTwilioWarning: string;
  setCurrentTwilioWarning: Dispatch<SetStateAction<string>>;
}

type TransferState = "InProgress" | "Success" | "Fail" | "Rejected" | "Cancelled" | "Idle";
/**
 * The Call State is an object that is used when the user is on the call screen. Either through a normal call flow or when creating a non call activity.
 * @interface CallStateState
 * @property {boolean} callState - Indicates whether a call is currently active (this is passed down to the app as a boolean to be used in various places)
 * @property {string} [callLeadId] - The ID of the lead associated with the call.
 * @property {Date} [callStartTime] - The start time of the call, if available.
 * @property {string} [phoneNumber] - The lead phone number associated with the call.
 * @property {string} [intentId] - The ID of the intent associated with the call, if any.
 * @property {string} [callNotes] - Any notes associated with the call.
 * @property {boolean} recording - Indicates whether the call is currently being recorded.
 * @property {boolean} startedRecording - Indicates whether recording has been initiated for this call.
 * @property {boolean} recordingButtonDisabled - Indicates whether the recording button is disabled.
 * @property {string} callNotesLastUpdated - The timestamp of when call notes were last updated.
 * @property {string} [callSid] - The Twilio Call SID, if available.
 * @property {boolean} isNonCallActivity - Indicates whether this logging of a call result came from a call or the log non call activity button.
 */
interface CallStateState {
  callState: boolean;
  callLeadId?: string;
  callStartTime?: Date;
  phoneNumber?: string;
  intentId?: string;
  callNotes?: string;
  recording: boolean;
  startedRecording: boolean;
  recordingButtonDisabled: boolean;
  callNotesLastUpdated: string;
  callSid?: string;
  isNonCallActivity: boolean;
  google_space_sellfire_id?: string;
  zoom_meeting_sellfire_id?: string;
}

export const CallContext = createContext<CallContextState>({} as CallContextState);

let device: Device | undefined = undefined;

export const CallProvider: FunctionComponent = ({ children }) => {
  const { updateUserToOffline, updateUserToIdle, setUserUIStatus } = useContext(UserStatusContext);
  const { closeDialer } = useContext(ModalContext);

  //conference call state

  const [conferenceState, setConferenceState] = useState(false);
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [incomingError, setIncomingError] = useState("");
  const [showIncoming, setShowIncoming] = useState(false);
  const [leadId, setLeadId] = useState("");
  const [transfer_attempt_id, setTransferAttemptId] = useState("");
  const [callCameFromTransfer, setCallCameFromTransfer] = useState(false);
  const [callEntity, setCallEntity] = useState<Call | undefined>(undefined);

  const [defaultInput, setDefaultInput] = useState("");
  const [defaultOutput, setDefaultOutput] = useState("");

  const [personSpokeTo, setPersonSpokeTo] = useState("");

  const [associatedAction, setAssociatedAction] = useState<string | undefined>(undefined);

  const DEFAULT_CALL_OPTION_STACK = ["spoke-with"];

  const [callOptionStack, setCallOptionStack] = useState<Array<string>>(DEFAULT_CALL_OPTION_STACK);

  const [dialAsNumber, setDialAsNumber] = useState<string | undefined>("");

  const [timeOnCall, setTimeOnCall] = useState<number>(0);

  // true while device is reinitializing
  const [deviceResetCheck, setDeviceResetCheck] = useState(false);

  const [secondaryCallIncoming, setSecondaryCallIncoming] = useState<boolean>(false);

  const [externalRepCallSid, setExternalRepCallSid] = useState("");

  // For intermediate state between call button click and Twilio call getting established
  const [isConnectingToTwilio, setIsConnectingToTwilio] = useState(false);

  // To prevent call state from being reset when entering required demo fields
  const [isEnteringRequiredFields, setIsEnteringRequiredFields] = useState(false);

  const [createInstantMeetingDataOverride, setCreateInstantMeetingDataOverride] = useState<
    CreateInstantMeetingResponse | null | undefined
  >(undefined);

  // Function to refetch lead card data while on call
  const [handleLeadCardRefresh, setHandleLeadCardRefresh] = useState<Function | undefined>(undefined);

  /*  Call State Flow

  The call state is controlled by an array of strings. the last string determines the current step to render in CallResult component.

  There are two main flows:

  1. transfer call flow
  2. schedule demo flow

  both share  the same initial state of ["spoke-with"] to choose DM or NDM, ...

  The other main difference that affects the components in the flow is whether auto or manual transfer is enabled.

  you can find those settings in the transfer tab. 

  Here is an example flow where an SDR is transfering a call to an AE who schedules a demo:

  SDR


 ['spoke-with', 'call-completed', 'transfer demo', <transfer call to AE here>  'log-other']


  AE after receiving the call


  ['spoke-with', 'call-completed', 'schedule-event', 'log-result', 'Schedule Follow Up Demo']


  */

  const { data: loggedInUser, loading: loggedInUserLoading } = useQuery(LOGGED_IN_USER);

  useEffect(() => {
    console.log("callOptionStack", callOptionStack);
  }, [callOptionStack]);

  const callOptionStackPush = (item: string) => setCallOptionStack([...callOptionStack, item]);
  const callOptionStackPeek = () => callOptionStack[callOptionStack.length - 1];
  const callOptionStackPop = (num?: number) => setCallOptionStack(callOptionStack.slice(0, (num ?? 1) * -1));
  const resetCallOptionStack = () => setCallOptionStack(DEFAULT_CALL_OPTION_STACK);

  // accross the app we have places (lead card and call flow) where we allow the user to create an OTF video meeting

  // these are ephemeral and are not tied to BE entities

  // this state needs to be shared between all components and tabs so the user does not lose their OTF video meeting when switching tabs or making different calls

  // we decided to use context with localstorage initialization to keep all states in sync

  // the individual buttons on lead cards etc... will check the state for the their lead's meeting

  // example: { "1234567890": { meeting_id: "1234567890", meeting_type: "Zoom", meeting_url: "https://zoom.us/j/1234567890", passcode: "123456", created_at: "2024-10-01T00:00:00Z",  }
  const [existingOTFVideoMeetings, setExistingOTFVideoMeetings] = useState<Record<string, OTFVideoMeeting>>(
    // initialize from localstorage if it exists
    JSON.parse(localStorage.getItem("OTFVideoMeetings") || "{}"),
  );

  const addOTFVideoMeeting = ({ leadId, OTFVideoMeeting }: { leadId: string; OTFVideoMeeting: OTFVideoMeeting }) => {
    setExistingOTFVideoMeetings({ ...existingOTFVideoMeetings, [leadId]: OTFVideoMeeting });
  };

  const removeOTFVideoMeeting = (leadId: string) => {
    const { [leadId]: _, ...rest } = existingOTFVideoMeetings;
    setExistingOTFVideoMeetings(rest);
  };

  // on all changes update localstorage
  useEffect(() => {
    localStorage.setItem("OTFVideoMeetings", JSON.stringify(existingOTFVideoMeetings));
  }, [existingOTFVideoMeetings]);

  // reset context on logout
  const resetExstingOTFVideoMeetings = () => {
    localStorage.removeItem("OTFVideoMeetings");
    setExistingOTFVideoMeetings({});
  };

  // onfocus recheck local storage for OTFVideoMeetings (like when switching tabs)
  useTabFocus(
    () => {
      setExistingOTFVideoMeetings(JSON.parse(localStorage.getItem("OTFVideoMeetings") || "{}"));
    },
    { buffer: 1000 },
  );

  // transfer component stuff
  const [transferError, setTransferError] = useState("");
  const [transferState, setTransferState] = useState<TransferState>("Idle");
  const history = useHistory();

  const [includeNumberDialedFrom, setIncludeNumberDialedFrom] = useState(false);
  const [requireNotes, setRequireNotes] = useState(false);
  const [performedNoteAction, setPerformedNoteAction] = useState(false);

  useQuery(FETCH_CALL_NOTES_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    onCompleted({ fetchCallNotesTemplate }) {
      setIncludeNumberDialedFrom(fetchCallNotesTemplate[0]?.included_number_dialed_from || false);
      setRequireNotes(fetchCallNotesTemplate[0]?.require_notes || false);
    },
  });

  const [updateLeadCallHoldStatus] = useMutation(UPDATE_CALL_HOLD_STATUS, {
    onCompleted({ updateLeadCallHoldStatus }) {
      if (!updateUserStatus) {
        return;
      }
    },
    onError({ message }) {
      console.log(message);
      errorToast(`error in updateLeadCallHoldStatus ${message}`);
    },
  });

  const [fetchUser] = useLazyQuery(FETCH_USER, {
    onCompleted: ({ fetchUser }) => {
      setDefaultInput(fetchUser.default_audio_input);
      setDefaultOutput(fetchUser.default_audio_output);
    },
    onError: ({ message }) => {
      console.log("Error in fetchUser: ", message);
    },
  });

  useEffect(() => {
    if (!loggedInUser?.loggedInUser?.id || loggedInUserLoading) {
      return;
    }
    fetchUser();
  }, [loggedInUser?.loggedInUser?.id]);

  useEffect(() => {
    if (showIncoming || secondaryCallIncoming) {
      const flashingInterval = setInterval(() => {
        if (!document.title.includes("🔴")) {
          document.title = `${document.title} 🔴`;
        } else {
          setEnvDocTitle();
        }
      }, 500);

      return () => clearInterval(flashingInterval);
    } else {
      setEnvDocTitle();
    }
  }, [showIncoming, secondaryCallIncoming]);

  useEffect(() => {
    if (showIncoming) {
      localStorage.setItem("showIncoming", JSON.stringify(showIncoming));
      window.dispatchEvent(new Event("storage"));
    }
  }, [showIncoming]);

  const [updateCallNotesBackend, { loading, error }] = useMutation(UPDATE_CALL_NOTES, {
    onError: (e) => {
      appToast(`Could not update call notes: ", ${e.message}`);
      Sentry.captureEvent({
        message: `updateCallNotesBackend GraphQL Error: ${e.message}`,
      });
    },
    onCompleted: ({ updateCallNotes }) => {
      setCallState({ ...callState, callNotesLastUpdated: updateCallNotes.updated_at });
    },
  });

  const [updateUserStatus, { loading: loadStatus, error: errorStatus }] = useMutation(UPDATE_USER_STATUS, {
    onCompleted({ updateUserStatus }) {
      if (!updateUserStatus) {
        return;
      }
    },
    onError({ message }) {
      console.log(message);
    },
  });

  const [joinMeItem, setJoinMeItem] = useState({
    viewerLink: "",
    presenterLink: "",
  });
  const [joinMeLeadID, setJoinMeLeadID] = useState("");

  const [repLeadSearch, setRepLeadSearch] = useState("");

  // Twilio warnings
  const [currentTwilioWarning, setCurrentTwilioWarning] = useState("");

  const DEFAULT_CALL_STATE = {
    callState: false,
    callLeadId: "",
    callStartTime: undefined,
    phoneNumber: "",
    intentId: "",
    callNotes: "",
    callNotesLastUpdated: "",
    recording: false,
    startedRecording: false,
    recordingButtonDisabled: true,
    callSid: "",
    isNonCallActivity: false,
    google_space_sellfire_id: "",
    zoom_meeting_sellfire_id: "",
  };

  //Changes whether or not the dashboard goes into the dialer
  const [callState, setCallState] = useState<CallStateState>(DEFAULT_CALL_STATE);

  const getLastCallEndedAt = () => {
    const item = window.sessionStorage.getItem("lastCallEndedAt");
    const date = item ? new Date(item) : null;
    return date;
  };

  const setLastCallEndedAt = (date: string | null = new Date().toJSON()) => {
    window.sessionStorage.setItem("lastCallEndedAt", date || "");
  };

  // FE failsafe for incoming concierge requests during a call
  // if the user is on a call and recieves an incoming concierge call, this value will be checked in localstorage to see if the user should see the incoming concierge modal

  useEffect(() => {
    if (callState.callState) {
      sessionStorage.setItem("userIsOnCall", JSON.stringify(callState.callState));
    }
  }, [callState.callState]);

  const [incomingCallState, setIncomingCallState] = useState<CallStateState>(DEFAULT_CALL_STATE);

  const [incomingCallEntity, setIncomingCallEntity] = useState<Call | undefined>(undefined);

  const [intentReplacementId, setIntentReplacementId] = useState<string | null | undefined>(undefined);

  const resetIncomingCall = () => {
    setIncomingCallState(DEFAULT_CALL_STATE);
    setIncomingCallEntity(undefined);
  };
  // Device functions

  /**
   * Browsers are cracking down on unwarranted background audio. As a result, browsers
   * are progressively moving toward requiring a user gesture before allowing audio to
   * play on a page. Twilio recommends calling Device.setup() in response to a user gesture,
   * such as a click. One popular and intuitive way to implement this is to add a "ready"
   * button that will initiate the Device and show the dialer once clicked.
   */
  const setupDevice = async (authTokenParam: string) => {
    console.log("setupDevice: ", device);
    console.log("authTokenParam: ", authTokenParam);
    device = new Device(authTokenParam, { allowIncomingWhileBusy: true, tokenRefreshMs: 240000 });
    device.updateOptions({
      logLevel: "DEBUG",
      allowIncomingWhileBusy: true,
      edge: loggedInUser?.loggedInUser?.organization?.edge_servers?.length
        ? loggedInUser?.loggedInUser?.organization?.edge_servers
        : undefined,
    });
    registerDeviceListeners(device);

    await device.register();
    console.log("new setDevice: ", device);
    MixpanelActions.track("device token register", { token: device.token });
  };

  const sendDigitsWithDelay = (
    call: Call,
    digitStrings: string[],
    initialDelay: number,
    delayBetweenDigits: number,
  ) => {
    setTimeout(() => {
      digitStrings.forEach((digitString, index) => {
        setTimeout(() => {
          call.sendDigits(digitString);
        }, index * delayBetweenDigits);
      });
    }, initialDelay);
  };

  const isValidPhoneNumber = (phoneNumber: string): boolean => {
    const invalidNumber = phoneNumber.match(/[a-z]/i);
    return !!phoneNumber && !invalidNumber;
  };

  const goToCall = async ({
    lead_id,
    phoneNumber,
    intentId,
    intentReplacementId,
    suggested_dial = false,
    dialAsNumber,
    presetDigitsToSend,
    persistPreviousCallDispositionFlow = false,
    google_space_sellfire_id,
    zoom_meeting_sellfire_id,
    action_source,
  }: {
    lead_id?: string;
    phoneNumber: string;
    intentId?: string;
    intentReplacementId?: string | null | undefined;
    suggested_dial?: boolean;
    dialAsNumber?: string;
    presetDigitsToSend?: string[];
    persistPreviousCallDispositionFlow?: boolean;
    google_space_sellfire_id?: string;
    zoom_meeting_sellfire_id?: string;
    action_source?: string;
  }) => {
    console.log("goToCall Dashboard phoneNumber: ", phoneNumber);

    // failsafe
    setCallCameFromTransfer(false);
    setIsConnectingToTwilio(true);
    // validations
    if (!isValidPhoneNumber(phoneNumber)) {
      errorToast(`Invalid phone number: "${phoneNumber}"`);
      return;
    }

    if (isEnteringRequiredFields) {
      setIsEnteringRequiredFields(false);
    }

    // start the call
    if (lead_id) {
      setTimeOnCall(0);

      if (currentCallState()?.onCall) {
        hangup();
      }

      // only used for OTF video meetings. UI needs to not reset the call disposition flow for the new call to google meet or zoom
      // could be on currentCallState but feels very rarely used
      if (persistPreviousCallDispositionFlow) {
        localStorage.setItem("persistPreviousCallDispositionFlow", JSON.stringify(true));
      } else {
        // default removes the item and all check
        localStorage.removeItem("persistPreviousCallDispositionFlow");
      }

      MixpanelActions.track("Dial Event", {
        contact_type: "lead",
        contact_id: lead_id,
        action_source: action_source,
      });

      // Reset Twilio warnings
      setCurrentTwilioWarning("");

      await callNumber({
        phone: phoneNumber,
        lead_id,
        suggested_dial,
        intent_id: intentId,
        dialAsNumber,
        google_space_sellfire_id,
        persistPreviousCallDispositionFlow,
        zoom_meeting_sellfire_id,
      }).then((call: Call | undefined) => {
        console.log("inside then", JSON.stringify(call?.parameters));

        // when callEntity is updated we begin the device reinitialization process
        setDeviceResetCheck(true);
        setCallEntity(call);

        setStopScheduledEventNotification(false);
        intentReplacementId && setIntentReplacementId(intentReplacementId);

        if (presetDigitsToSend) {
          handleSendDigits(
            call,
            presetDigitsToSend,
            google_space_sellfire_id ? "GoogleMeet" : zoom_meeting_sellfire_id ? "Zoom" : "Default",
          );
        }
      });
    }
  };

  const handleSendDigits = (
    call: Call | undefined,
    presetDigitsToSend: string[] | undefined,
    callType: "GoogleMeet" | "Zoom" | "Default",
  ) => {
    if (call && presetDigitsToSend) {
      // no feedback is possible from Google Meet or Zoom calls so we have to estimate the delay
      let initialDelay = callType === "GoogleMeet" ? 10000 : callType === "Zoom" ? 5000 : 5000;

      const interval = 5000;

      sendDigitsWithDelay(call, presetDigitsToSend, initialDelay, interval);
    }
  };

  const logNonCallActivity = (lead_id: string) => {
    setCallState({
      ...DEFAULT_CALL_STATE,
      isNonCallActivity: true,
      callLeadId: lead_id,
    });
  };

  const registerDeviceListeners = (this_device: Device) => {
    this_device.on(Device.EventName.Registered, readyListener);
    this_device.on(Device.EventName.Destroyed, disconnectListener);
    this_device.on(Device.EventName.Unregistered, offlineListener);
    this_device.on(Device.EventName.Incoming, incomingListener);
    this_device.on("tokenWillExpire", refreshToken);
    this_device.audio?.addListener("deviceChange", deviceChangeListenerReady);
    this_device.on(Device.EventName.Error, deviceErrorListener);
  };

  const unregisterListeners = () => {
    if (!device) {
      return;
    }
    device.off(Device.EventName.Registered, readyListener);
    device.off(Device.EventName.Destroyed, disconnectListener);
    device.off(Device.EventName.Unregistered, offlineListener);
    device.off(Device.EventName.Incoming, incomingListener);
    device.off("tokenWillExpire", refreshToken);
    device?.audio?.removeListener("deviceChange", deviceChangeListenerReady);
    device.off(Device.EventName.Error, deviceErrorListener);
  };

  /**
   * This should only fire on logout
   */
  useEffect(() => {
    if (!loggedInUser?.loggedInUser?.id) {
      if (!device) {
        return;
      }
      unregisterListeners();
      device.unregister();
      device.destroy();
      device = undefined;
    } else {
      // This happens upon login or upon page refresh
      registerDevice();
    }
    return () => {
      hangup();
      tabSync.handleUnregister();
      resetExstingOTFVideoMeetings();
    };
  }, [loggedInUser?.loggedInUser?.id]);

  useEffect(() => {
    function unregisterEvent() {
      tabSync.handleUnregister();
      const sessionCall = JSON.parse(sessionStorage.getItem("userIsOnCall") || "false");
      localStorage.setItem("userIsOnCallLocal", JSON.stringify(!!callState.callState));
      if (!!callState.callState || sessionCall) {
        localStorage.setItem("userStatusLocal", JSON.stringify("IDLE"));
      }
      return null;
    }

    window.addEventListener("beforeunload", unregisterEvent);

    return () => {
      window.removeEventListener("beforeunload", unregisterEvent);
    };
  }, []);

  /**
   * This should only fire on logout
   */
  useEffect(() => {
    if (device) {
      device.updateOptions({
        edge: loggedInUser?.loggedInUser?.organization?.edge_servers?.length
          ? loggedInUser?.loggedInUser?.organization?.edge_servers
          : undefined,
      });
    }
  }, [loggedInUser?.loggedInUser?.organization?.edge_servers]);

  const registerDevice = async () => {
    if (loggedInUserLoading || !loggedInUser?.loggedInUser?.id) {
      return;
    }

    const tab_id = uuidv4();
    const is_active = tabSync.initializeNewDevice(tab_id);

    const response = await restAPI.get(
      `/twilio/getAccessToken?id=${loggedInUser?.loggedInUser?.id}&tab_id=${tab_id}&is_active=${is_active}`,
    );
    console.log("response from twilio getAccessToken: ", response);
    if (!response?.data) {
      return;
    }

    setupDevice(response.data);
    setAuthToken(response.data);
    updateRepTab({ variables: { tab_id } });
  };

  let expirePageInterval: number | null = null;
  const connectListener = async (conn: Call) => {
    setDeviceResetCheck(false);
    setLastCallEndedAt(null);
    if (expirePageInterval !== null) window.clearInterval(expirePageInterval);
    console.log("connect listener fire: ", conn);
    if (!conn) {
      return;
    }
    console.log("parameters: ", conn.parameters);
    if (conn.parameters.CallSid) {
      console.log(
        `connectListener Twilio Reference URL: https://console.twilio.com/us1/monitor/logs/calls?frameUrl=%2Fconsole%2Fvoice%2Fcalls%2Flogs%2F${conn.parameters.CallSid}%3F__override_layout__%3Dembed%26bifrost%3Dtrue%26x-target-region%3Dus1`,
      );
    }
    MixpanelActions.track("Dial Event", {
      dial_as_number: conn.customParameters.get("dial_as_number"),
      lead_number:
        conn.direction === "INCOMING"
          ? conn.customParameters.get("phone_number")
          : conn.customParameters.get("phoneNumber"),
      lead_id: conn.customParameters.get("lead_id"),
      suggested_dial: conn.customParameters.get("suggested_dial"),
      CallSid: conn.parameters.CallSid ? conn.parameters.CallSid : "",
      Twilio_Reference_URL: conn.parameters.CallSid
        ? `https://console.twilio.com/us1/monitor/logs/calls?frameUrl=%2Fconsole%2Fvoice%2Fcalls%2Flogs%2F${conn.parameters.CallSid}%3F__override_layout__%3Dembed%26bifrost%3Dtrue%26x-target-region%3Dus1`
        : "",
    });
    // setupInputDevices();
    // setupOutputDevices();
    console.log("call direction", conn.direction);
    console.log("phone_number: ", conn.customParameters.get("phone_number"));
    if (!(conn.parameters && conn.parameters.From === conn.parameters.To && !!conn.parameters.To)) {
      conn.direction === "INCOMING"
        ? goToCallState({
            callLeadId: leadId,
            CallSid: callEntity?.parameters.CallSid,
            phoneNumber: conn.customParameters.get("phone_number"),
            persistPreviousCallDispositionFlow: false,
          })
        : goToCallState({
            ...callState,
            callLeadId: conn.customParameters.get("lead_id"),
            CallSid: callEntity?.parameters.CallSid,
            intentId: conn.customParameters.get("intent_id"),
            phoneNumber: conn.customParameters.get("phoneNumber"),
            persistPreviousCallDispositionFlow:
              callEntity?.customParameters.get("persistPreviousCallDispositionFlow") === "true",
            google_space_sellfire_id: callEntity?.customParameters.get("google_space_sellfire_id"),
            zoom_meeting_sellfire_id: callEntity?.customParameters.get("zoom_meeting_sellfire_id"),
          });
    }
    console.log("connectListener CallState", callState);
    updateUserStatus({ variables: { status: "CALL", call_sid: callEntity?.parameters.CallSid } });
    currentCallState({
      onCall: true,
      dispositionLogged: false,
      alternate_contact: false,
    });
    setTransferState("Idle");
    console.log("conn.parameters: ", conn.parameters);

    // Twilio call is established, set loading state to false
    setIsConnectingToTwilio(false);

    if (conn.parameters && conn.parameters.From === conn.parameters.To && !!conn.parameters.To) {
      console.log("accept call coaching");
      conn.mute();
      twilioMuted(true);
      setConferenceState(true);
    }
    if (transfer_attempt_id) {
      await acceptIncomingTransfer();
      setTransferAttemptId("");
    }
  };

  const disconnectListener = (conn: Call) => {
    console.log("device disconnect listener fire: ", conn);

    hangup();
    unsetInputDevices();
    setShowIncoming(false);
    localStorage.setItem("showIncoming", JSON.stringify(false));
    window.dispatchEvent(new Event("storage"));
    setSecondaryCallIncoming(false);

    updateUserToOffline({ do_not_update_if_on_call: false, checkTabs: true });

    // Set user's status to offline when exiting
    setConferenceState(false);
    currentCallState({
      dispositionLogged: currentCallState().dispositionLogged,
      onCall: false,
      alternate_contact: currentCallState().alternate_contact,
    });
    console.log("disconnect fire update OnCall status: ", currentCallState().onCall);
    const isEnteringRequiredFieldsLocal = JSON.parse(localStorage.getItem("isEnteringRequiredFields") || "false");
    if (
      currentCallState().dispositionLogged &&
      !currentCallState().alternate_contact &&
      !isEnteringRequiredFieldsLocal
    ) {
      revertCallState();
      setLeadId("");
    }
  };

  const offlineListener = (d: any) => {
    console.log("offline: ", d);
    console.log("updateUserStatus from offline listener");

    updateUserToOffline({ do_not_update_if_on_call: false, checkTabs: true });
  };

  const [updateRepTab] = useMutation(UPDATE_USER_TAB);

  const refreshToken = async () => {
    console.log("refresh token fire");
    try {
      const response = await restAPI.get(`/twilio/getAccessToken?id=${loggedInUser?.loggedInUser?.id}`);
      if (device && response?.data) {
        device.updateToken(response.data);

        MixpanelActions.track("device token update", { token: device.token });
      }
      const newTabId = uuidv4();
      tabSync.refreshToken(newTabId);
      updateRepTab({ variables: { tab_id: newTabId } });
    } catch (e: any) {
      Sentry.captureEvent({ message: `Could not refresh auth token: ${e?.message}` });
      console.log(`Could not fetch Twilio auth token: ${e}`);
    }
  };

  const showOptions = () => {
    if (!device) {
      return;
    }
    const input = device.audio?.availableInputDevices;
    input?.forEach((v, k) => console.log(v, k));
  };

  //possibly needed. placing this unused function as placeholder
  const unsetOutputDevices = () => {};

  const updateInputDevices = () => {
    if (!device) {
      return;
    }
    const options = Array.from(device.audio?.availableInputDevices as any, ([_, value]) => ({
      label: value.label,
      value: value.deviceId,
    }));
    inputDevices(options);
  };

  const updateOutputDevices = () => {
    if (!device) {
      return;
    }
    const options = Array.from(device.audio?.availableOutputDevices as any, ([_, value]) => ({
      label: value.label,
      value: value.deviceId,
    }));
    outputDevices(options);
  };

  const setupInputDevices = () => {
    console.log("setupInputDevices");
    if (!device) {
      return;
    }
    const options = Array.from(device.audio?.availableInputDevices as any, ([_, value]) => ({
      label: value.label,
      value: value.deviceId,
    }));
    console.log("setupInputDevices options: ", options);
    inputDevices(options);
    if (!options || !options.length) {
      return;
    }

    if (!!defaultInput && inputDevices().findIndex((device) => device.value === defaultInput) !== -1) {
      device.audio?.setInputDevice(defaultInput);
    }
  };

  const setupOutputDevices = () => {
    console.log("setupOutputDevices");
    if (!device) {
      return;
    }
    const options = Array.from(device.audio?.availableOutputDevices as any, ([_, value]) => ({
      label: value.label,
      value: value.deviceId,
    }));
    console.log("setupOutputDevices options: ", options);
    outputDevices(options);
    if (!options || !options.length) {
      return;
    }

    if (!!defaultOutput && options.findIndex((device) => device.value === defaultOutput) !== -1) {
      changeOutputDevice(defaultOutput);
    }
  };

  const unsetInputDevices = () => {
    if (!device) {
      return;
    }
    device.audio?.unsetInputDevice();
  };

  const transfer = async ({ from, to, conference_name }: { from: string; to: string; conference_name: string }) => {
    await axios.post(`/transferCall`, {
      from,
      to,
      conference_name,
    });
  };

  const hangup = () => {
    // status boolean that controls UI indicator RepStatusMenuIcon
    setUserUIStatus(USER_STATUS.IDLE);
    console.log("RepStatusMenuIcon updated to", USER_STATUS.IDLE);

    // Reset Twilio warnings
    setCurrentTwilioWarning("");

    if (!device) {
      return;
    }
    device.disconnectAll();

    closeDialer();
  };

  const updateCallHoldStatus = async (hold: boolean) => {
    await updateLeadCallHoldStatus({ variables: { lead_id: callState.callLeadId, hold: hold } });
  };

  /**
   * returns the status of the device. Will be one of the following states: ready, offline, busy
   */
  const getStatus = () => {
    if (!device) {
      return;
    }
    return device.state;
  };

  const changeInputDevice = async (deviceId: string) => {
    if (!device) {
      return;
    }
    console.log("deviceId: ", deviceId);
    console.log("device: ", device);
    // localStorage.setItem("Most_Recent_Input", deviceId as string);
    await device.audio?.setInputDevice(deviceId);
    MixpanelActions.people.set({ $Most_Recent_Microphone: deviceId });
  };

  const changeOutputDevice = async (deviceId: string) => {
    if (!device) {
      return;
    }
    console.log("device.audio.speakerDevices", device.audio?.speakerDevices);
    await device.audio?.speakerDevices.set(deviceId);
    MixpanelActions.people.set({ $Most_Recent_Output: deviceId });
  };

  const getOutputDevice = () => {
    if (!device) {
      return "";
    }
    if (!device.audio || !device.audio.speakerDevices) {
      return "";
    }
    const device_ret: any = Array.from(device.audio?.speakerDevices.get() as any, (value) => value)[0];
    if (!device_ret) {
      return "";
    }
    return device_ret.deviceId;
  };

  const goToCallState = ({
    callLeadId,
    phoneNumber,
    intentId,
    CallSid,

    persistPreviousCallDispositionFlow,
    google_space_sellfire_id,
    zoom_meeting_sellfire_id,
  }: {
    callLeadId?: string;
    phoneNumber?: string;
    intentId?: string;
    CallSid?: string;
    google_space_sellfire_id?: string;
    zoom_meeting_sellfire_id?: string;
    persistPreviousCallDispositionFlow?: boolean;
  }) => {
    console.log("goToCallState intentId: ", intentId);
    console.log("goToCallState phoneNumber: ", phoneNumber);
    console.log("goToCallState callLeadId: ", callLeadId);
    console.log("goToCallState callSid: ", CallSid);

    setCallState({
      callLeadId: callLeadId,
      phoneNumber: phoneNumber,
      intentId: intentId,
      callNotesLastUpdated: "",
      callStartTime: new Date(),
      callState: true,
      recording: true,
      startedRecording: true,
      recordingButtonDisabled: true,
      callSid: CallSid,
      isNonCallActivity: false,
      google_space_sellfire_id: google_space_sellfire_id,
      zoom_meeting_sellfire_id: zoom_meeting_sellfire_id,
    });

    console.log("current path: ", history);

    if (!persistPreviousCallDispositionFlow) {
      history.push(`/dash`);
    }
  };

  const persistCallNotes = () => {
    const call_notes = callState.callNotes;
    if (!!call_notes) {
      updateCallNotesBackend({
        variables: { call_notes: call_notes, lead_id: callState.callLeadId, call_sid: callState.callSid },
      });
    }
  };

  const revertCallState = () => {
    const call_notes = callState.callNotes;
    console.log("revert call state: ", call_notes);
    // Update call notes if applicable
    if (!!call_notes) {
      updateCallNotesBackend({
        variables: { call_notes: call_notes, lead_id: callState.callLeadId, call_sid: callState.callSid },
      });
    }

    // remove any OTF video meetings from the context
    if (callState.callLeadId) {
      removeOTFVideoMeeting(callState.callLeadId);
    }

    setCallState({
      callState: false,
      callLeadId: "",
      callStartTime: undefined,
      phoneNumber: "",
      intentId: "",
      callNotes: "",
      callNotesLastUpdated: "",
      recording: false,
      startedRecording: false,
      recordingButtonDisabled: true,
      callSid: "",
      isNonCallActivity: false,
      google_space_sellfire_id: "",
      zoom_meeting_sellfire_id: "",
    });

    // This tracks if they updated a note during the call.
    // We set this back to false every time we revert the call state.
    setPerformedNoteAction(false);

    setIntentReplacementId(undefined);

    setJoinMeItem({
      viewerLink: "",
      presenterLink: "",
    });
    setJoinMeLeadID("");
    setTransferAttemptId("");
    setCallCameFromTransfer(false);
    setAssociatedAction(undefined);
    setCallCameFromTransfer(false);

    localStorage.setItem("userIsOnCallLocal", JSON.stringify(false));
    sessionStorage.setItem("userIsOnCall", JSON.stringify(false));
    window.dispatchEvent(new Event("storage"));
    setLastCallEndedAt(null);
    if (expirePageInterval !== null) window.clearInterval(expirePageInterval);
    setRepLeadSearch("");
    setDeviceResetCheck(false);
  };
  const updateCallNotes = (callNotes: string) => {
    setCallState({ ...callState, callNotes });
    !performedNoteAction && setPerformedNoteAction(true);
  };

  const toggleRecording = (state: boolean) => {
    setCallState({ ...callState, recording: state });
  };

  const toggleDidStartRecording = (state: boolean) => {
    setCallState({ ...callState, startedRecording: state, recording: state });
  };

  const setRecordingButtonDisabled = (disabled: boolean) =>
    setCallState({ ...callState, recordingButtonDisabled: disabled });

  const handleRevertClosingScript = (state: boolean, disabled: boolean) => {
    setCallState({ ...callState, recordingButtonDisabled: disabled, recording: state });
  };

  const kickExternalTransferNumber = async () => {
    const response = await restAPI.post(`${BACKEND_URL}/twilio/kickExternalTransferParticipant`, {
      call_sid: callState.callSid,
      external_sid: externalRepCallSid,
    });

    if (response.data.error) {
      setTransferError(response.data.error);
      return appToast(response.data.error);
    }
    setExternalRepCallSid("");
  };

  const callNumber = async ({
    phone,
    lead_id,
    suggested_dial,
    intent_id,
    dialAsNumber,
    persistPreviousCallDispositionFlow,
    google_space_sellfire_id,
    zoom_meeting_sellfire_id,
  }: {
    phone: string;
    lead_id: string;
    suggested_dial?: boolean;
    intent_id?: string;
    dialAsNumber?: string;
    persistPreviousCallDispositionFlow?: boolean;
    google_space_sellfire_id?: string;
    zoom_meeting_sellfire_id?: string;
  }) => {
    console.log("callNumber device: ", device);
    console.log("callNumber device status: ", device?.state);
    console.log("callNumber token", device?.token);
    console.log("callNumber token state", authToken);
    console.log("callNumber token state", dialAsNumber);
    if (!device) {
      return;
    }

    // await refreshToken();
    console.log(`calling ${phone}`);

    console.log("callConnectParams", {
      number: extractNumber(phone),
      phoneNumber: phone,
      lead_id: lead_id,
      suggested_dial: `${suggested_dial}`,
      intent_id: intent_id ? intent_id : ``,
      dial_as_number: dialAsNumber || "",
      persistPreviousCallDispositionFlow: persistPreviousCallDispositionFlow ? "true" : "false",
      google_space_sellfire_id: google_space_sellfire_id || "",
      zoom_meeting_sellfire_id: zoom_meeting_sellfire_id || "",
    });
    const connection = await device.connect({
      params: {
        // prettier-ignore
        "number": extractNumber(phone),
        // prettier-ignore
        "phoneNumber": phone,
        // prettier-ignore
        "lead_id": lead_id,
        // prettier-ignore
        "suggested_dial": `${suggested_dial}`,
        // prettier-ignore
        "intent_id": intent_id ? intent_id : ``,
        dial_as_number: dialAsNumber || "",
        persistPreviousCallDispositionFlow: persistPreviousCallDispositionFlow ? "true" : "false",
        // BE needs the ID created by them to confirm the recording id when trying to find the saved recordings.
        // this is not the generated id for the meeting itself but OPSIQ generated ID for the meeting event.
        google_space_sellfire_id: google_space_sellfire_id || "",
        zoom_meeting_sellfire_id: zoom_meeting_sellfire_id || "",
      },
    });
    console.log("connection", connection);
    console.log("connection params", connection.parameters);
    // setCallEntity(connection);
    return connection;
  };

  const toggleMute = () => {
    if (!callEntity) {
      return;
    }
    const current_status = callEntity.isMuted();
    callEntity?.mute(!current_status);
  };

  const unMute = () => {
    if (!callEntity) {
      return;
    }
    callEntity.mute(false);
  };

  const mute = () => {
    if (!callEntity) {
      return;
    }
    callEntity.mute(true);
  };

  const sendDigit = (number: string) => {
    if (!callEntity) {
      return;
    }
    const response = callEntity.sendDigits(number);
    return response;
  };

  const deviceChangeListenerReady = () => {
    updateInputDevices();
    updateOutputDevices();
  };

  const incomingListener = async (conn: Call) => {
    console.log(`incoming listener: `, conn);
    console.log(`incoming listener device: `, device);
    if (!device) {
      return;
    }
    // Now we can set the input device that we read in updateMicOptions.
    // `Device` will store this internally. This will avoid getUserMedia calls.
    if (device.calls.length > 2) {
      console.log("more than 2 connections, rejecting...");
      conn.reject();
    } else {
      // console.log("conn parameters from: ", conn.parameters.From);
      if (conn.parameters && conn.parameters.CallSid) {
        setIncomingCallState({ ...incomingCallState, callSid: conn.parameters.CallSid });
      }
      // Coaching call
      if (conn.parameters && conn.parameters.From === conn.parameters.To) {
        console.log("conference call check", conn);
        setCallEntity(conn);
        //below temporary code.
        conn.accept();
        setConferenceState(true);
        twilioMuted(true);
        // conn.on("mute", (isMuted: boolean, conn: any) => {
        //   twilioMuted(isMuted);
        // });
        // conn.on("disconnect", (conn: any) => {
        //   twilioMuted(true);
        // });
        // conn.on("accept", (conn: any) => {
        //   conn.mute();
        //   console.log("accept on call component");
        //   setConferenceState(true);
        // });
      }
      if (conn.parameters && conn.parameters.From !== conn.parameters.To) {
        // incoming call
        // console.log("prop incoming check", conn);
        let lead_id = "";
        if (conn.customParameters.get("lead_id")) {
          // console.log("custom lead_id");
          lead_id = conn.customParameters.get("lead_id") || "";
          console.log("lead_id from custom param: ", lead_id);
          setLeadId(lead_id);
        } else {
          // console.log("lead_id From");
          lead_id = conn.parameters.From.includes(":") ? conn.parameters.From.split(":")[1] : conn.parameters.From;
          // console.log("lead_id From: ", lead_id);
          setLeadId(addDashBack(lead_id));
        }
        if (conn.customParameters.get("transfer_attempt_id")) {
          const transfer_attempt_id = conn.customParameters.get("transfer_attempt_id") || "";
          setTransferAttemptId(transfer_attempt_id);

          // needed for transfer tab V2 to hide transfer button when a call has already been transferred

          // the transfer attempt id is being reset as part of the current flow. we don't have a way to get it back currently;
          setCallCameFromTransfer(true);
        } else {
          setCallCameFromTransfer(false);
        }
        // console.log("set incoming");
        // show UI

        // * When the rep is already on a call and a new call comes in
        const isOnCall = localStorage.getItem("userIsOnCallLocal");
        if (isOnCall === "true") {
          console.log("call incoming while on call: ", isOnCall, device.calls.length);
          setSecondaryCallIncoming(true);
        } else {
          setShowIncoming(true);
        }

        setIncomingCallEntity(conn);
      }

      // if the call is cancelled by the lead
      conn.on("cancel", (conn: any) => {
        console.log("lead cancelled call");
        setShowIncoming(false);
        localStorage.setItem("showIncoming", JSON.stringify(false));
        window.dispatchEvent(new Event("storage"));
        setSecondaryCallIncoming(false);
        resetIncomingCall();
        unsetInputDevices();
      });
    }
  };

  const readyListener = async (d: any) => {
    console.log("ready listener: ", d);
    console.log("ready listener device: ", device);
    if (!device) {
      return;
    }

    console.log("ready listener device state: ", device.state);
    showOptions();
    console.log("updateUserStatus from ready listener");
    updateUserToIdle({ do_not_update_if_on_call: true });

    if (!device.audio) {
      return;
    }
  };

  const callEntityDisconnectListener = () => {
    // for debugging of call disonnect on enablement user side when joining a rep's call
    const info = {
      currentCallState: currentCallState(),
      callEntity: callEntity,
      device: device,
      incomingCallEntity: incomingCallEntity,
      incomingCallState: incomingCallState,
      conferenceState: conferenceState,
    };

    console.log("call disconnect fire", info);
    console.log("isEnteringRequiredFields: ", isEnteringRequiredFields);

    setDeviceResetCheck(false);

    hangup();
    unsetInputDevices();
    updateUserToIdle({ do_not_update_if_on_call: false });

    // Set user's status to offline when exiting
    setConferenceState(false);
    currentCallState({
      dispositionLogged: currentCallState().dispositionLogged,
      onCall: false,
      alternate_contact: currentCallState().alternate_contact,
    });
    const isEnteringRequiredFieldsLocal = JSON.parse(localStorage.getItem("isEnteringRequiredFields") || "false");
    if (
      currentCallState().dispositionLogged &&
      !currentCallState().alternate_contact &&
      !isEnteringRequiredFieldsLocal
    ) {
      revertCallState();
    }
    twilioMuted(false);
    setLastCallEndedAt();
    const intervalId = window.setInterval(() => {
      const lastCallEndedAt = getLastCallEndedAt();
      const persistPreviousCallDispositionFlow = localStorage.getItem("persistPreviousCallDispositionFlow") === "true";
      const difference = lastCallEndedAt && moment().diff(moment(lastCallEndedAt), "hours");
      if (
        difference &&
        difference >= 3 &&
        !currentCallState().onCall &&
        !currentCallState().dispositionLogged &&
        !persistPreviousCallDispositionFlow
      ) {
        setIsEnteringRequiredFields(false);
        console.log("Expire page after timeout.");
        revertCallState();
        window.clearInterval(intervalId);
      }
    }, 5 * 60 * 1000); // check every minute
    expirePageInterval = intervalId;
  };

  const deviceErrorListener = (error: TwilioError.TwilioError) => {
    console.log("Twilio Device Error: ", error.code, error.description);
    Sentry.captureEvent({
      message: `Twilio Device Error: ${error.message}`,
      extra: {
        code: error.code,
        info: error.description,
        explanation: error.explanation,
      },
    });
  };

  const rejectIncomingTransfer = async () => {
    const response = await restAPI.post(`/twilio/rejectTransfer`, {
      transfer_attempt_id,
    });
    return response;
  };

  const acceptIncomingTransfer = async () => {
    const response = await restAPI.post(`/twilio/acceptTransfer`, {
      transfer_attempt_id,
    });
    return response;
  };

  const callEntityRejectListener = async () => {
    console.log("reject listener fire");
    setDeviceResetCheck(false);
    updateUserStatus({ variables: { status: "IDLE" } });
    setIsConnectingToTwilio(false);
    if (transfer_attempt_id) {
      await rejectIncomingTransfer();
      setTransferAttemptId("");
      setCallCameFromTransfer(false);
    }
  };

  const callEntityCancelListener = () => {
    hangup();
    unsetInputDevices();
    // refresh token when call is disconnected
    setShowIncoming(false);
    setDeviceResetCheck(false);
    localStorage.setItem("showIncoming", JSON.stringify(false));
    window.dispatchEvent(new Event("storage"));
    setSecondaryCallIncoming(false);
    console.log("updateUserStatus from callEntity.parameters useEffect cancel listener");
    updateUserToIdle({ do_not_update_if_on_call: false });
    console.log("reject listener fire");

    setIsConnectingToTwilio(false);
  };

  const callEntityErrorListener = (error: any) => {
    if (error) {
      setDeviceResetCheck(false);
      console.log("Error making call: ", error);
      setIsConnectingToTwilio(false);
    }
  };

  const networkQualityWarningRaised = [
    "high-rtt",
    "low-mos",
    "high-jitter",
    "high-packet-loss",
    "high-packets-lost-fraction",
    "low-bytes-received",
    "low-bytes-sent",
    "ice-connectivity-lost",
  ];

  const callWarningListener = (warningName: string, warningData: any) => {
    if (networkQualityWarningRaised.includes(warningName)) {
      setCurrentTwilioWarning(warningName);
      // console.log("twilio warning: ", warningData);
    }
  };

  const callEntityMuteListener = (isMuted: boolean, conn: Call) => {
    console.log("twilio muted: ", isMuted);
    twilioMuted(isMuted);
  };

  // Setup listeners when call occurs
  useEffect(() => {
    if (!callEntity) {
      return;
    }
    console.log("connect useEffect fire: ", callEntity);
    console.log("parameters: ", callEntity.parameters);
    if (callEntity.direction === "INCOMING") {
      callEntity?.accept();
    }
    setupInputDevices();
    setupOutputDevices();
    currentCallState({
      onCall: true,
      dispositionLogged: false,
      alternate_contact: false,
    });
    callEntity.on("accept", connectListener);
    callEntity.on("disconnect", callEntityDisconnectListener);
    callEntity.on("reject", callEntityRejectListener);
    callEntity.on("cancel", callEntityCancelListener);
    callEntity.on("error", callEntityErrorListener);
    callEntity.on("mute", callEntityMuteListener);
    callEntity.on("warning", callWarningListener);
    return () => {
      callEntity.off("accept", connectListener);
      callEntity.off("disconnect", callEntityDisconnectListener);
      callEntity.off("reject", callEntityRejectListener);
      callEntity.off("cancel", callEntityCancelListener);
      callEntity.off("error", callEntityErrorListener);
      callEntity.off("mute", callEntityMuteListener);
      callEntity.off("warning", callWarningListener);
    };
  }, [callEntity?.parameters]);

  // Next Dial context. This stores a lead intent/custom queue object
  const [nextActionOverride, setNextActionOverride] = useState(() => {
    const storedData = localStorage.getItem("next_dial_override");
    const parsedData = !!storedData
      ? JSON.parse(storedData)
      : { intent_object: { id: "", lead: { id: "", primary_phone_number: "" } }, parent: undefined };
    if (!!storedData && !!parsedData?.intent_object?.lead?.id) {
      return parsedData;
    } else return { intent_object: { id: "", lead: { id: "", primary_phone_number: "" } }, parent: undefined };
  });
  const resetNextDial = () =>
    setNextActionOverride({
      intent_object: { id: "", lead: { id: "", primary_phone_number: "" } },
      parent: undefined,
    });
  useEffect(() => {
    localStorage.setItem(
      "next_dial_override",
      JSON.stringify(nextActionOverride) ??
        "{ intent_object:{id: '', lead: { id: '', primary_phone_number: '' }}, parent: undefined}",
    );
  }, [nextActionOverride]);

  // Stops notification of current events until a call is made. Used for when a call action is decided upon and we want the next dial to not prompt an action.
  const [stopScheduledEventNotification, setStopScheduledEventNotification] = useState(false);

  const [isAutoDialActive, setIsAutoDialActive] = useState(false);

  const [suggestedActionData, setSuggestedActionData] = useState<ISuggestedActionData | null>(null);

  const cancelTransferGlobal = async (callSid?: string) => {
    updateCallHoldStatus(false);
    setTransferState("Idle");
    const response = await restAPI.post(`${BACKEND_URL}/twilio/cancelTransfer`, {
      transfer_attempt_id,
      call_sid: callSid,
    });

    if (response.data.error) {
      setTransferError(response.data.error);
      return appToast(response.data.error);
    } else {
      callOptionStackPop();
      // setExternalRepCallSid("")
    }
  };

  useEffect(() => {
    sessionStorage.setItem("callLeadId", callState.callLeadId || "");
  }, [callState]);

  useEffect(() => {
    localStorage.setItem("isEnteringRequiredFields", JSON.stringify(isEnteringRequiredFields));
  }, [isEnteringRequiredFields]);

  const getCallLeadId = () => {
    return sessionStorage.getItem("callLeadId") || undefined;
  };

  const memoizedValue = useMemo(
    () => ({
      callStartTime: callState.callStartTime,
      callState: callState.callState,
      callLeadId: callState.callLeadId,
      getCallLeadId,
      phoneNumber: callState.phoneNumber,
      intentId: callState.intentId,
      callNotes: callState.callNotes,
      callNotesLastUpdated: callState.callNotesLastUpdated,
      recording: callState.recording,
      startedRecording: callState.startedRecording,
      recordingButtonDisabled: callState.recordingButtonDisabled,
      callSid: callState.callSid,
      isNonCallActivity: callState.isNonCallActivity,
      isZoomCall: !!callState.zoom_meeting_sellfire_id,
      isGoogleMeetCall: !!callState.google_space_sellfire_id,
      createInstantMeetingDataOverride,
      setCreateInstantMeetingDataOverride,
      intentReplacementId,
      incomingCallState,
      joinMeItem,
      setJoinMeItem,
      joinMeLeadID,
      repLeadSearch,
      setRepLeadSearch,
      setJoinMeLeadID,
      setRecordingButtonDisabled,
      handleRevertClosingScript,
      toggleRecording,
      toggleDidStartRecording,
      goToCallState,
      revertCallState,
      conferenceState,
      setConferenceState,
      updateCallNotes,
      persistCallNotes,
      nextActionOverride,
      setNextActionOverride,
      resetNextDial,
      stopScheduledEventNotification,
      setStopScheduledEventNotification,
      callEntity,
      setCallEntity,
      toggleMute,
      mute,
      unMute,
      sendDigit,
      callNumber,
      setupDevice,
      refreshToken,
      showOptions,
      updateInputDevices,
      updateOutputDevices,
      setupInputDevices,
      setupOutputDevices,
      unsetOutputDevices,
      unsetInputDevices,
      transfer,
      hangup,
      updateCallHoldStatus,
      getStatus,
      changeInputDevice,
      changeOutputDevice,
      getOutputDevice,
      device,
      showIncoming,
      setShowIncoming,
      leadId,
      setLeadId,
      authToken,
      setAuthToken,
      incomingError,
      setIncomingError,
      isAutoDialActive,
      setIsAutoDialActive,
      defaultInput,
      setDefaultInput,
      defaultOutput,
      setDefaultOutput,
      personSpokeTo,
      setPersonSpokeTo,
      associatedAction,
      setAssociatedAction,
      goToCall,
      setCallState,
      callOptionStack,
      setCallOptionStack,
      callOptionStackPush,
      callOptionStackPeek,
      callOptionStackPop,
      resetCallOptionStack,
      transferError,
      setTransferError,
      transferState,
      setTransferState,
      resetIncomingCall,
      incomingCallEntity,
      setIncomingCallEntity,
      suggestedActionData,
      setSuggestedActionData,
      callCameFromTransfer,
      cancelTransferGlobal,
      transfer_attempt_id,
      setTransferAttemptId,
      dialAsNumber,
      setDialAsNumber,
      secondaryCallIncoming,
      setSecondaryCallIncoming,
      timeOnCall,
      setTimeOnCall,
      logNonCallActivity,
      includeNumberDialedFrom,
      setIncludeNumberDialedFrom,
      requireNotes,
      setRequireNotes,
      performedNoteAction,
      setPerformedNoteAction,
      deviceResetCheck,
      setDeviceResetCheck,
      externalRepCallSid,
      setExternalRepCallSid,
      kickExternalTransferNumber,
      isConnectingToTwilio,
      setIsConnectingToTwilio,
      isEnteringRequiredFields,
      setIsEnteringRequiredFields,
      existingOTFVideoMeetings,
      addOTFVideoMeeting,
      removeOTFVideoMeeting,
      handleLeadCardRefresh,
      setHandleLeadCardRefresh,
      currentTwilioWarning,
      setCurrentTwilioWarning,
    }),
    [
      callCameFromTransfer,
      callState,
      intentReplacementId,
      incomingCallState,
      goToCallState,
      setCallState,
      logNonCallActivity,
      repLeadSearch,
      setRepLeadSearch,
      revertCallState,
      conferenceState,
      setConferenceState,
      updateCallNotes,
      persistCallNotes,
      nextActionOverride,
      stopScheduledEventNotification,
      callEntity,
      setCallEntity,
      toggleMute,
      mute,
      unMute,
      sendDigit,
      callNumber,
      setupDevice,
      refreshToken,
      showOptions,
      updateInputDevices,
      updateOutputDevices,
      setupInputDevices,
      setupOutputDevices,
      unsetOutputDevices,
      unsetInputDevices,
      transfer,
      hangup,
      updateCallHoldStatus,
      getStatus,
      changeInputDevice,
      changeOutputDevice,
      getOutputDevice,
      device,
      showIncoming,
      setShowIncoming,
      leadId,
      setLeadId,
      authToken,
      setAuthToken,
      incomingError,
      setIncomingError,
      isAutoDialActive,
      setIsAutoDialActive,
      defaultInput,
      setDefaultInput,
      defaultOutput,
      setDefaultOutput,
      personSpokeTo,
      setPersonSpokeTo,
      associatedAction,
      setAssociatedAction,
      goToCall,
      callOptionStack,
      createInstantMeetingDataOverride,
      setCreateInstantMeetingDataOverride,
      setCallOptionStack,
      callOptionStackPush,
      callOptionStackPeek,
      callOptionStackPop,
      resetCallOptionStack,
      transferError,
      setTransferError,
      transferState,
      setTransferState,
      resetNextDial,
      resetIncomingCall,
      incomingCallEntity,
      setIncomingCallEntity,
      suggestedActionData,
      setSuggestedActionData,
      cancelTransferGlobal,
      transfer_attempt_id,
      setTransferAttemptId,
      dialAsNumber,
      setDialAsNumber,
      secondaryCallIncoming,
      setSecondaryCallIncoming,
      timeOnCall,
      setTimeOnCall,
      includeNumberDialedFrom,
      setIncludeNumberDialedFrom,
      requireNotes,
      setRequireNotes,
      performedNoteAction,
      setPerformedNoteAction,
      deviceResetCheck,
      setDeviceResetCheck,
      externalRepCallSid,
      setExternalRepCallSid,
      kickExternalTransferNumber,
      isConnectingToTwilio,
      setIsConnectingToTwilio,
      isEnteringRequiredFields,
      setIsEnteringRequiredFields,

      existingOTFVideoMeetings,
      addOTFVideoMeeting,
      removeOTFVideoMeeting,
      handleLeadCardRefresh,
      setHandleLeadCardRefresh,
      currentTwilioWarning,
      setCurrentTwilioWarning,
    ],
  );

  return <CallContext.Provider value={memoizedValue}>{children}</CallContext.Provider>;
};
