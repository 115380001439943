import React from "react";
import { FieldArray } from "formik";
import { CustomField } from "src/Components/Field/CustomField";

const CustomFieldSaleArray = ({
  values,
  setFieldValue,
}: {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}) => {
  return (
    <FieldArray name="customFields">
      {() => (
        <>
          {values.customFields?.map((customField: any, index: number) => {
            return (
              <CustomField
                customField={customField}
                setFieldValue={setFieldValue}
                values={values}
                fieldName={`customFields`}
                index={index}
              />
            );
          })}
        </>
      )}
    </FieldArray>
  );
};

export default CustomFieldSaleArray;
