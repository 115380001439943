import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading, AppErrorText, AppSidebarCard, Region } from "../../UI";
import { theme } from "../../../utils/theme";
import { formatTime } from "../../../utils/format";
import { useState } from "react";
import { GoPencil } from "react-icons/go";
import iconCheck from "../../../images/icons-ic-notifications-accept.svg";
import { BsTrashFill } from "react-icons/bs";
import { UpdateCallNotesPopup } from "../../modal/";
import { newEditIcon } from "../../../images";
import { loggedInUser } from "../../../apollo/cache";

interface AnnotationNotesProps {
  coachingNote: Region;
  playRegion?: (regionId: string) => void;
  updateNotes: (regionId: string, note: string) => void;
  removeRegion: (regionId: string) => void;
  lastElement: boolean;
}

const AnnotationNotes: React.FC<AnnotationNotesProps> = ({
  coachingNote,
  playRegion,
  updateNotes,
  removeRegion,
  lastElement,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <AnnotationNotesContainer>
      <CoachNoteContainer key={coachingNote.id}>
        <TimeBox onClick={() => playRegion && playRegion(coachingNote.id)}>
          <TimeBoxFont>{`${formatTime(coachingNote.start)}-${formatTime(coachingNote.end)}`}</TimeBoxFont>
        </TimeBox>
        <NoteDiv>
          <NoteFont>{coachingNote.data.note}</NoteFont>
        </NoteDiv>
        <NoteIconContainer>
          {isEdit ? (
            <UpdateCallNotesPopup
              blinds={isEdit}
              setBlinds={setIsEdit}
              timeText={`${formatTime(coachingNote.start)}-${formatTime(coachingNote.end)}`}
              addNote={false}
              onDelete={() => removeRegion(coachingNote.id)}
              noteText={coachingNote.data.note}
              updateNoteFunction={(note: string) => {
                updateNotes(coachingNote.id, note);
              }}
            />
          ) : (
            (loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") && (
              <EditIcon onClick={() => setIsEdit(true)}>
                <img src={newEditIcon} alt="Edit" />
              </EditIcon>
              // <StyledPencil color={theme.NEUTRAL500} size={17}  />
            )
          )}
        </NoteIconContainer>
      </CoachNoteContainer>
      <StyledHr lastElement={lastElement} />
    </AnnotationNotesContainer>
  );
};

export { AnnotationNotes };

interface LastProp {
  lastElement: boolean;
}

const StyledHr = styled.hr<LastProp>`
  display: ${(props) => (props.lastElement ? "none" : "block")};
  margin: 0px 15px;
  border: none;
  border-top: 0.5px solid #d8d8d8;
  /* width: 100%; */
`;

const EditIcon = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AnnotationNotesContainer = styled.div`
  width: 100%;
  height: 100%;
  word-break: keep-all;
  overflow-wrap: break-word;
`;

const CoachNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 15px;
`;

const NoteDiv = styled.div`
  width: 382px;
  /* min-height: 33px; */
  margin-left: 20px;
  display: flex;
`;

const NoteDivTextArea = styled.textarea`
  max-width: 300px;
  min-width: 300px;
  /* min-height: 33px; */
  display: flex;
  font-size: 11px;
  font-family: ${theme.PRIMARY_FONT};
`;

const NoteFont = styled(AppText)`
  font-size: 11px;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  max-width: 320px;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 24px;
  border-radius: 3px;
  background-color: #d8d8d8;
  padding: 5px;
  color: ${theme.BLACK_COLOR};
  :hover {
    cursor: pointer;
  }
`;

const TimeBoxFont = styled(AppText)`
  width: 52px;
  height: 14px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const NoteIconContainer = styled.div`
  width: 45px;
  /* height: 33px; */
  padding-left: 12px;
  justify-content: space-between;
  align-items: center;
`;

const Spread = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const StyledPencil = styled(GoPencil)`
  float: right;
  :hover {
    cursor: pointer;
  }
`;

const StyledTrash = styled(BsTrashFill)`
  :hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  :hover {
    cursor: pointer;
  }
`;
