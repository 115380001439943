import React from "react";

const ThreeDotsIcon = ({ color = "#C4C4C4" }: { color?: string }) => (
  <svg width="25" height="7" viewBox="0 0 25 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="3.36486" transform="matrix(-1 0 0 1 21.3109 3.36486)" fill={color} />
    <circle r="3.36486" transform="matrix(-1 0 0 1 12.3378 3.36486)" fill={color} />
    <circle r="3.36486" transform="matrix(-1 0 0 1 3.36512 3.36486)" fill={color} />
  </svg>
);

export { ThreeDotsIcon };
