import styled from "styled-components";

export const ToolTipContainer = styled.div`
  min-width: 180px;
  padding: 18px;
  opacity: 0.9;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
`;
