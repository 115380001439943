import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "./AppText";
import * as React from "react";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { propTypes } from "react-spinkit";

interface NewAppSidebarCardProps {
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number | string;
  /**
   * height of button. Default value is 50.
   */
  height?: number;
  secondary?: boolean;
  cancel?: boolean;
  textcolor?: string;
  unbordered?: boolean;
  HeaderComponents?: any;
}

export const NewAppSidebarCard = ({
  title,
  width,
  color = theme.PRIMARY500,
  unbordered = false,
  textcolor,
  children,
  ...props
}: NewAppSidebarCardProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <SidebarCard width={width} unbordered={unbordered}>
      <TitleDiv color={color} unbordered={unbordered}>
        <TitleText textcolor={textcolor}>{title}</TitleText>
      </TitleDiv>
      <ContentSection>{children}</ContentSection>
    </SidebarCard>
  );
};

const ContentSection = styled.div`
  padding-top: 42px;
`;

interface TitleDivProps {
  color: string;
  unbordered: boolean;
}

const TitleDiv = styled.div<TitleDivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 49px;
  border-top-left-radius: ${(props) => (props.unbordered ? "7px" : "3px")};
  border-top-right-radius: ${(props) => (props.unbordered ? "7px" : "3px")};
  background-color: ${(props) => props.color || theme.PRIMARY500};
`;

interface TitleTextProps {
  textcolor?: string;
}

const TitleText = styled(AppText)<TitleTextProps>`
  font-size: 14px;
  font-weight: 700;
  /* height: 100%; */
  line-height: 1;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 49px; */
  letter-spacing: normal;
  text-align: left;
  vertical-align: center;
  color: ${(props) => props.textcolor || theme.WHITE_COLOR};
  margin: 16px;
`;

const SidebarCard = styled.div<NewAppSidebarCardProps>`
  box-sizing: border-box;
  width: ${(props) => {
    if (!props.width) {
      return "466px";
    }
    if (typeof props.width === "string") {
      return props.width;
    }
    return `${props.width}px`;
  }};
  position: relative;
  height: ${(props) => props.height};
  border-radius: 3px;
  /* border: ${(props) => (props.unbordered ? "none" : `4px solid ${theme.NEUTRAL100}`)}; */
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
  background-color: ${theme.WHITE_COLOR};
  line-height: 1;
  padding: 0;
  background-color: ${theme.WHITE_COLOR};
  margin: 10px 0px;
`;

NewAppSidebarCard.defaultProps = {
  height: 50,
  secondary: false,
  unbordered: false,
};
