import React, { useRef, Dispatch, SetStateAction, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useClickOutside } from "../../../utils/hooks";
import { sanitizeURL } from "../../../utils/misc";
import { ModalContext } from "../../../context";

interface EditSequenceMenuProps {
  sequenceId: string;
  sequenceInitialName: string;
  priority: number;
  setShowEditSequenceMenu: Dispatch<SetStateAction<boolean>>;
  onEditSequence?: () => void;
}

export const EditSequenceMenu: React.FC<EditSequenceMenuProps> = ({
  setShowEditSequenceMenu,
  sequenceId,
  sequenceInitialName,
  priority,
  onEditSequence,
}) => {
  const containerRef = useRef(null);
  const history = useHistory();

  const { setShowRenameSequenceModal, setRenameSequenceData } = useContext(ModalContext);

  useClickOutside(containerRef, () => setShowEditSequenceMenu(false));

  return (
    <PopupContainer ref={containerRef}>
      <MenuItem
        onClick={() => {
          const santizedURL = sanitizeURL(`/sequences/${sequenceId}`);
          onEditSequence?.();
          history.push(santizedURL);
        }}
      >
        Edit Sequence
      </MenuItem>
      <MenuItem
        onClick={() => {
          setShowRenameSequenceModal(true);
          setRenameSequenceData({ sequence_id: sequenceId, name: sequenceInitialName, priority });
          setShowEditSequenceMenu(false);
        }}
      >
        Rename Sequence
      </MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;

  width: 152px;
  height: 40px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;
