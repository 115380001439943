import moment from "moment";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { AppText } from "..";
import { ModalContext } from "../../../context";
import { edit, more_vertical } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { FlexDiv } from "../FlexDiv";
import { ManageFormMenu } from "../OPSIQForms/ManageFormMenu";
import { PhoenixIcon } from "../Phoenix";

interface FormRowProps {
  formId: string;
  formName: string;
  createdAt: string;
  createdBy: string;
  formSessionToken?: string;
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  deleteOpsiqForm: () => void;
  cloneOpsiqForm: () => void;
}

export const FormRow: React.FC<FormRowProps> = ({
  formId,
  formName,
  formSessionToken,
  createdAt,
  createdBy,
  lastUpdatedAt,
  lastUpdatedBy,
  deleteOpsiqForm,
  cloneOpsiqForm,
}: FormRowProps) => {
  const [showManageFormMenu, setShowManageFormMenu] = useState<boolean>(false);
  const { setShowUpdateFormModal, setUpdateFormModalData, setShowDeleteFormModal, setDeleteFormModalData } = useContext(
    ModalContext,
  );

  return (
    <FlexDiv direction="column" width={"100%"}>
      <FormRowContainer isMounted>
        <FormRowDiv>
          <FormNameContainer>
            <FormTitleText>{formName}</FormTitleText>
          </FormNameContainer>

          <AppText fontSize={12} style={{ color: theme.NEUTRAL300, lineHeight: "18px" }}>
            {`${moment(createdAt).format("MM/DD/YY")} by ${createdBy}`}
          </AppText>

          <AppText fontSize={12} style={{ color: theme.NEUTRAL300, lineHeight: "18px" }}>
            {`${moment(lastUpdatedAt).format("MM/DD/YY")} by ${lastUpdatedBy}`}
          </AppText>

          <ActionsContainer>
            <div style={{ position: "relative" }}>
              {showManageFormMenu && (
                <ManageFormMenu
                  formId={formId}
                  formName={formName}
                  formSessionToken={formSessionToken}
                  setShowManageFormMenu={setShowManageFormMenu}
                  handleEditForm={() => {
                    setUpdateFormModalData({
                      id: formId,
                      name: formName,
                    });
                    setShowUpdateFormModal(true);
                  }}
                  handleDeleteForm={() => {
                    setDeleteFormModalData({
                      id: formId,
                      deleteOpsiqForm: deleteOpsiqForm,
                    });
                    setShowDeleteFormModal(true);
                  }}
                  handleDuplicateForm={() => {
                    cloneOpsiqForm();
                  }}
                />
              )}
              <Link to={`/system-config/sellfire-forms/${formId}`}>
                <PhoenixIcon svg={edit} size={16} pointer />
              </Link>
            </div>
            <PhoenixIcon
              svg={more_vertical}
              size={16}
              pointer
              onClick={() => setShowManageFormMenu(!showManageFormMenu)}
            />
          </ActionsContainer>
        </FormRowDiv>
      </FormRowContainer>
    </FlexDiv>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface FormItemContainerProps {
  isMounted: boolean;
}

const FormTitleText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
`;

const FormRowContainer = styled.div<FormItemContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  min-width: 620px;
  margin-right: 8px;

  min-height: 72px;
  max-height: 72px;

  background-color: #fff;
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;

  animation: ${(props) =>
    !props.isMounted
      ? css`
          ${fadeIn} 0.25s ease forwards
        `
      : "unset"};
  animation-fill-mode: initial;
  opacity: 1;
`;

export const FormRowDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 100px;
  align-items: center;
  padding: 16px;
  width: 100%;
`;

const FormNameContainer = styled.div`
  min-width: 0;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-self: end;
  padding-left: 24px;
`;
