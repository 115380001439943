import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useContext } from "react";
import { AppText, Loading, NewAppButton, AppIconButton } from "../../UI";
import { theme } from "../../../utils/theme";
import { CallContext } from "../../../context/";
import { gql, useQuery } from "@apollo/client";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { iconArrowDown } from "../../../images";
import { formatCellData } from "../../../utils/format";
import moment from "moment";
// const GET_USER_STATUS_LIST = gql`
//   query fetchChooseUserRepList {
//     fetchChooseUserRepList {
//       id
//       first_name
//       last_name
//       role
//       updated_at
//       last_updated_hours
//     }
//   }
// `;

const GET_USER_STATUS_LIST = gql`
  query fetchManualTransferRepList($lead_id: String!, $sort_option: TransferSortOption!, $ascending: Boolean!) {
    fetchManualTransferRepList(lead_id: $lead_id, sort_option: $sort_option, ascending: $ascending) {
      user_id
      first_name
      last_name
      team_name
      site_name
      close_rate
      organization_id
      status
      updated_at
    }
  }
`;

interface ChooseUserProps {
  chooseRep: (rep_id: string) => void;
  lead_id: string;
}

const ChooseUserComponent: React.FC<ChooseUserProps> = ({ chooseRep }) => {
  const { callLeadId } = useContext(CallContext);
  const [selectedRep, setSelectedRep] = useState("");
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [sortOption, setSortOption] = useState("CloseRate");
  const [expandSortButton, setExpandSortButton] = useState(false);
  // const { data, loading } = useQuery(GET_USER_STATUS_LIST, {
  //   fetchPolicy: "network-only",
  //   onError({ message, name }) {
  //     Sentry.captureEvent({
  //       message: `${name} GraphQL Error: ${message}`,
  //     });
  //     console.log(`Error in ${name}: `, message);
  //   },
  //   // pollInterval: FETCH_USER_TRANSFER_LIST_INTERVAL,
  // });

  const { data: liveUserData, loading } = useQuery(GET_USER_STATUS_LIST, {
    fetchPolicy: "network-only",
    // pollInterval: FETCH_USER_TRANSFER_LIST_INTERVAL,
    variables: {
      ascending: ascendingOrder,
      sort_option: sortOption,
      lead_id: callLeadId,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const formatSortOptionName = (name: string) => {
    switch (name) {
      case "CloseRate":
        return "Close Rate";
      case "SiteName":
        return "Site Name";
      default:
        return "";
    }
  };
  // If there is no user_id, show a list
  if (loading) {
    return (
      <ChooseUserDiv>
        <Loading />
      </ChooseUserDiv>
    );
  } else {
    return (
      // <ChooseUserDiv>
      //   <Vertical>
      //     <UserStatusTitleDiv>
      //       <UserTitle>Rep</UserTitle>
      //       <UserTitle>Status</UserTitle>
      //     </UserStatusTitleDiv>
      //     {data.fetchChooseUserRepList
      //       .slice()
      //       .sort((a: any, b: any) =>
      //         `${a?.first_name} ${a?.last_name} (${a?.role})`
      //           .toLocaleLowerCase()
      //           .localeCompare(`${b?.first_name} ${b?.last_name} (${b?.role})`.toLocaleLowerCase()),
      //       )
      //       ?.map(({ id, first_name, last_name, role, last_updated_hours }: any) => (
      //         <UserStatusDiv onClick={() => chooseRep(id)}>
      //           <Vertical>
      //             <UserText>{`${first_name} ${last_name} (${role})`}</UserText>
      //             <UserDescriptionText>{`Last updated ${last_updated_hours} hour(s) ago.`}</UserDescriptionText>
      //           </Vertical>
      //         </UserStatusDiv>
      //       ))}
      //   </Vertical>
      // </ChooseUserDiv>
      <TransferDiv>
        <AppText style={{ fontSize: "16px", margin: "0px auto 0px auto" }}>Choose Rep</AppText>
        <UserStatusTitleDiv>
          <UserTitle>Reps</UserTitle>
          <UserTitle>
            <AppIconButton onClick={() => setAscendingOrder(!ascendingOrder)}>
              <img
                style={{
                  transform: !!ascendingOrder ? "rotate(180deg" : "rotate(0deg)",
                }}
                src={iconArrowDown}
              />
            </AppIconButton>
            Sort by {formatSortOptionName(sortOption)}
            <AppIconButton onClick={() => setExpandSortButton(!expandSortButton)}>
              <BiSortDown />
            </AppIconButton>
          </UserTitle>
          {!!expandSortButton && (
            <OptionsDiv>
              <Option
                onClick={() => {
                  setExpandSortButton(!expandSortButton);
                  setSortOption("CloseRate");
                }}
              >
                Close Rate
              </Option>
              <Option
                onClick={() => {
                  setExpandSortButton(!expandSortButton);
                  setSortOption("SiteName");
                }}
              >
                Site Name
              </Option>
            </OptionsDiv>
          )}
        </UserStatusTitleDiv>
        <ButtonsDiv>
          {liveUserData?.fetchManualTransferRepList?.map((item: any) => (
            // <UserStatusDiv onClick={() => transferCall(user_id)}>
            //   <Vertical>
            //     <UserText>{`${user.first_name} ${user.last_name} (${user.role})`}</UserText>
            //     <UserDescriptionText>{`Last updated ${last_updated_hours} hour(s) ago.`}</UserDescriptionText>
            //   </Vertical>
            //   <AppText>{status}</AppText>
            // </UserStatusDiv>
            <NewAppButton
              variant={"secondary"}
              height={88}
              selected={item?.user_id === selectedRep}
              onClick={() => setSelectedRep(item?.user_id)}
            >
              <div style={{ display: "flex", flexDirection: "row", height: 88 }}>
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    flexGrow: 2,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div>
                    <AppText>{`${item?.first_name} ${item?.last_name} (${item?.role})`}</AppText>
                  </div>
                  <div>
                    <AppText style={{ color: `${theme.BLACK_COLOR}`, textAlign: "left" }}>{`Last updated ${moment(
                      item?.updated_at,
                    ).format("HH:mm:ss")} hour(s) ago.`}</AppText>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "row",
                      gap: 8,
                    }}
                  >
                    <AppText>{`${item?.team_name}`}</AppText>

                    <AppText>{`${formatCellData(item?.close_rate, "percentage")}% CR`}</AppText>

                    <AppText>{`${item?.site_name}`}</AppText>
                  </div>
                </div>

                <div style={{ alignSelf: "center", display: "inline-flex", justifyContent: "flex-end", flexGrow: 1 }}>
                  <AppText>{item?.status}</AppText>
                </div>
              </div>
            </NewAppButton>
          ))}
        </ButtonsDiv>
        <NewAppButton
          style={{ marginTop: 20 }}
          size={"lg"}
          variant={"primary"}
          onClick={() => chooseRep(selectedRep)}
          disabled={!selectedRep}
        >
          Choose Rep
        </NewAppButton>
      </TransferDiv>
    );
  }
};

const ChooseUserDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
`;

const ChooseUserText = styled(AppText)`
  font-size: 15px;
`;

const UserTitle = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.2px;
  :hover {
    cursor: pointer;
  }
`;

const UserText = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.2px;
  :hover {
    cursor: pointer;
  }
`;

const UserDescriptionText = styled(AppText)`
  font-size: 9px;
  font-weight: 400;
  opacity: 0.5;
  :hover {
    cursor: pointer;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserStatusDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL200};
  margin-bottom: 10px;
  padding: 15px 5px 15px 5px;
  cursor: pointer;
  :hover {
    cursor: pointer;
  }
`;

const UserStatusTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
`;

const TransferDiv = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
`;
const TransferText = styled(AppText)`
  font-size: 15px;
  text-align: center;
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 112px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 40px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  flex-grow: 2;
`;

export { ChooseUserComponent };
