import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { PhoenixIcon } from "./Phoenix";
import { emoji } from "../../images/NewDesign";
import { theme } from "../../utils/theme";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

interface EmojiPickerProps {
  onEmojiSelect: (e: any) => void;

  // override icon, expected to be PhoenixIcon or react component
  icon?: React.ReactNode;
  // only applies to builtin icon. Number converts to px
  size?: number | "small" | "xsmall" | "medium";

  onClick?: () => void;

  // positionals, Number converts to px
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  zIndex?: number;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ ...props }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  return (
    <div style={{ position: "relative", width: "fit-content", zIndex: props.zIndex ? props.zIndex : 900 }}>
      {showEmojiPicker && (
        <PickerWrapper {...props}>
          <Picker
            data={data}
            onClickOutside={() => setShowEmojiPicker(false)}
            onEmojiSelect={(e: any) => props.onEmojiSelect(e)}
            searchPosition="none"
          />
        </PickerWrapper>
      )}
      <div
        onClick={() => {
          setShowEmojiPicker(!showEmojiPicker);
          if (props.onClick !== undefined) props.onClick();
        }}
      >
        {props.icon || <PhoenixIcon svg={emoji} size={props.size || "small"} pointer />}
      </div>
    </div>
  );
};

const PickerWrapper = styled.div<EmojiPickerProps>`
  position: absolute;
  top: ${(props) => (!!props.top ? `${props.top}px` : "unset")};
  right: ${(props) => (!!props.right ? `${props.right}px` : "unset")};
  bottom: ${(props) => (!!props.bottom ? `${props.bottom}px` : "unset")};
  left: ${(props) => (!!props.left ? `${props.left}px` : "unset")};

  animation: ${theme.popup} 0.2s ease;
`;
