import React, { useContext, useEffect, useState } from "react";
import { AppText, NewAppButton } from "../../UI";
import styled from "styled-components";
import { gql, useMutation } from "@apollo/client";
import { theme } from "../../../utils/theme";
import * as Sentry from "@sentry/react";
import { iconImportLead } from "../../../images";
import { calculatePercentage } from "../../../utils/format";
import { appToast } from "../../../utils/toast";

const CANCEL_IMPORT = gql`
  mutation CancelCurrentLeadImport($lead_import_id: String!) {
    cancelCurrentLeadImport(lead_import_id: $lead_import_id)
  }
`;

interface HeroImportProps {
  currentImportData: any;
}

const HeroImport: React.FC<HeroImportProps> = ({ currentImportData }) => {
  const [cancelImport, { loading: loadingCancel, error: errorCancel }] = useMutation(CANCEL_IMPORT, {
    async onCompleted({ cancelCurrentLeadImport }) {
      console.log("cancelCurrentLeadImport: ", cancelCurrentLeadImport);
      if (!cancelCurrentLeadImport) {
        appToast("Error resolving lead. Something went wrong.");
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `cancelCurrentLeadImport GraphQL Error: ${message}`,
      });
      console.log("Error in cancelCurrentLeadImport: ", message);
    },
    refetchQueries: ["fetchInProgressLeadImport", "fetchInProgressLeadActivityImport"],
  });

  return (
    <Container>
      <ImportInfoDiv>
        <img style={{ height: 32 }} src={iconImportLead} />
        <ImportTextContainer>
          <AppText fontSize={22} style={{ fontWeight: 600 }}>
            {currentImportData?.filename}
          </AppText>
          <AppText>{currentImportData?.message}</AppText>
          <AppText variant="primary">
            {`${currentImportData?.numerator}/${currentImportData?.denominator}`} leads processed
          </AppText>
        </ImportTextContainer>

        <div style={{ textAlign: "right", flexGrow: 2 }}>
          <NewAppButton
            size="sm"
            style={{ width: 100 }}
            variant={currentImportData?.numerator >= currentImportData?.denominator ? "confirm" : "attention"}
            disabled={currentImportData?.numerator >= currentImportData?.denominator}
            onClick={async () =>
              await cancelImport({
                variables: {
                  lead_import_id: currentImportData?.id,
                },
              })
            }
          >
            {currentImportData?.numerator >= currentImportData?.denominator ? "Import Complete" : "Stop Import"}
          </NewAppButton>
        </div>
      </ImportInfoDiv>
      <PercentIndicatorBar
        percentage={calculatePercentage(currentImportData?.numerator, currentImportData?.denominator)}
      />
    </Container>
  );
};

const Container = styled.div`
  min-height: inherit;
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-bottom: 0px;
  padding: 43px 32px 24px 32px;
  border-radius: 8px;
  min-width: 1200px;
`;

const ImportInfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 32px;
`;

const ImportTextContainer = styled.div`
  display: "flex";
  flex-direction: "column";
  justify-content: "flex-start";
  flex-grow: 3;
`;

interface PercentageProp {
  percentage: number;
}

const PercentIndicatorBar = styled.div<PercentageProp>`
  position: relative;
  height: 6px;
  width: 100%;
  border-radius: 9px;
  background: ${theme.NEUTRAL200};
  ::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    border-radius: 9px;
    background: ${theme.PRIMARY500};
    width: ${(props) => (!!props.percentage ? `${props.percentage * 100}%` : "0%")};
  }
`;

export { HeroImport };
