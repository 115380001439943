import styled from "styled-components";
import * as React from "react";
import { AppText, Loading, NewAppButton, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import * as Sentry from "@sentry/react";
import { gql, useQuery, useMutation } from "@apollo/client";

import { appToast } from "../../../utils/toast";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect, PhoenixRadio } from "../../UI/Phoenix";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { iconCheckThin, iconNewCaretDown, iconTrashNew } from "../../../images";
import Switch from "react-switch";

enum StorageTimeUnit {
  // Weeks = "Weeks",
  Months = "Months",
  Years = "Years",
}

interface DisappearingDivProps {
  step: number;
}

const FETCH_DISPOSITION_TYPES = gql`
  query fetchDispositionsTypes($showLabel: Boolean) {
    fetchDispositionsTypes(show_label: $showLabel)
  }
`;

const stepCallStorageRules = Yup.object().shape({
  recording_storage_duration: Yup.number()
    .when("recording_storage_unit", {
      is: (val: string) => val === "Months",
      then: Yup.number().min(12, "Minimum recommended storage duration is 12 months."),
      otherwise: Yup.number().min(1, "Minimum recommended storage duration is 1 year."),
    })
    .required("Recording storage duration is required."),
  recording_storage_unit: Yup.string().required(),
  delete_recordings_enabled: Yup.boolean().required(),
  delete_recordings_marked_as: Yup.array().of(Yup.string()),
});

const SET_CALL_RECORDING_STORAGE_TIME = gql`
  mutation setCallStorageTime(
    $recording_storage_duration: Int!
    $recording_storage_unit: StorageTimeUnit!
    $delete_recordings_enabled: Boolean!
    $delete_recordings_marked_as: [CALLMARKEDAS]
  ) {
    setCallStorageTime(
      recording_storage_duration: $recording_storage_duration
      recording_storage_unit: $recording_storage_unit
      delete_recordings_enabled: $delete_recordings_enabled
      delete_recordings_marked_as: $delete_recordings_marked_as
    ) {
      recording_storage_duration
      recording_storage_unit
      delete_recordings_enabled
      delete_recordings_marked_as
    }
  }
`;

const FETCH_CALL_RECORDING_STORAGE_TIME = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      recording_storage_duration
      recording_storage_unit
      delete_recordings_enabled
      delete_recordings_marked_as
    }
  }
`;

interface MyFormikProps {
  recording_storage_duration: number;
  recording_storage_unit: string;
  delete_recordings_enabled: boolean;
  delete_recordings_marked_as: string[];
}

interface OptionItem {
  value: string;
  label: string;
}

interface OptionItemColored {
  value: string;
  label: string;
  text_color?: string;
  bg_color?: string;
}

const unitOptions: OptionItem[] = [
  // { value: "Weeks", label: "Weeks" },
  { value: "Months", label: "Months" },
  { value: "Years", label: "Years" },
];

const CallRecordingStorageTab: React.FC<DisappearingDivProps> = ({ step }) => {
  const { data, loading, error, refetch } = useQuery(FETCH_CALL_RECORDING_STORAGE_TIME, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dispositionTypes, loading: loadingDispositionTypes } = useQuery(FETCH_DISPOSITION_TYPES, {
    fetchPolicy: "cache-and-network",
    variables: { showLabel: true },
  });

  const [setCallStorageTime, { data: dataCallStorage, loading: loadingUpdate }] = useMutation(
    SET_CALL_RECORDING_STORAGE_TIME,
    {
      fetchPolicy: "no-cache",
      onCompleted() {
        refetch();
        appToast("Call recording storage time updated successfully");
      },
      onError({ message }) {
        appToast(`${message}`);
      },
      refetchQueries: ["fetchOrganization"],
    },
  );
  if (loading) return <Loading />;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        recording_storage_duration: data?.fetchOrganization?.recording_storage_duration || 1,
        recording_storage_unit: data?.fetchOrganization?.recording_storage_unit || "Years",
        delete_recordings_enabled: data?.fetchOrganization?.delete_recordings_enabled || false,
        delete_recordings_marked_as: data?.fetchOrganization?.delete_recordings_marked_as || [],
      }}
      validationSchema={stepCallStorageRules}
      onSubmit={async (values) => {
        // alert(JSON.stringify(values, null, 2));
        await setCallStorageTime({
          variables: {
            recording_storage_duration: values.recording_storage_duration,
            recording_storage_unit: values.recording_storage_unit,
            delete_recordings_enabled: values.delete_recordings_enabled,
            delete_recordings_marked_as: values.delete_recordings_marked_as,
          },
        });
      }}
    >
      {({ submitForm, values, setFieldValue, errors }: FormikProps<MyFormikProps>) => {
        return (
          <Sentry.ErrorBoundary fallback={"An error has occurred at the CallRecordingStorageTab segment"}>
            <DisappearingDiv>
              <SaveAndContinueButton disabled={loadingUpdate} type="submit" onClick={submitForm} variant={"primary"}>
                SAVE CHANGES
              </SaveAndContinueButton>
              {/* </CenterDiv> */}
              <AppText fontSize={12} style={{ margin: "40px 0px 16px 0px" }}>
                Determine how long call recordings should remain in Sellfire before being deleted.
              </AppText>
              {/* </CenterDiv> */}
              <FlexDiv gap={8} align="center" style={{ marginBottom: 40 }}>
                <Switch
                  name="delete_recordings_enabled"
                  onChange={(checked) => {
                    setFieldValue("delete_recordings_enabled", checked);
                  }}
                  onColor={theme.PRIMARY500}
                  checked={values?.delete_recordings_enabled}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
                <AppText fontSize={12} fontWeight={500}>
                  Customize call recording storage
                </AppText>
              </FlexDiv>

              {values?.delete_recordings_enabled && (
                <>
                  {/* <BorderDiv> */}
                  <AppText fontSize={12} fontWeight={500} style={{ marginBottom: 16 }}>
                    Delete calls after:
                  </AppText>
                  <InputWrapper>
                    <PhoenixInput
                      name="recording_storage_duration"
                      inputValueType="number"
                      // width={140}
                      value={values.recording_storage_duration}
                      showNumberArrows
                      displayNoContextText
                      // label="Number"
                      onChange={(e: any) => setFieldValue("recording_storage_duration", parseInt(e.target.value) || 0)}
                      handleNumberArrowUpClick={() =>
                        setFieldValue("recording_storage_duration", values.recording_storage_duration + 1)
                      }
                      handleNumberArrowDownClick={() =>
                        setFieldValue("recording_storage_duration", Math.max(1, values.recording_storage_duration - 1))
                      }
                    />
                    <PhoenixMultiSelect
                      isMulti={false}
                      isClearable={false}
                      name="recording_storage_unit"
                      options={unitOptions}
                      value={[
                        {
                          label: values.recording_storage_unit,
                          value: values.recording_storage_unit,
                        },
                      ]}
                      onChange={(e: OptionItem) => setFieldValue("recording_storage_unit", e?.value)}
                    />
                  </InputWrapper>
                  {errors.recording_storage_duration && (
                    <AppText
                      fontSize={12}
                      fontWeight={500}
                      color={theme.DANGER500}
                      style={{ margin: "0px 0px 16px 0px" }}
                    >
                      {errors.recording_storage_duration}
                    </AppText>
                  )}
                  <AppText fontSize={12} color={theme.PILL_GRAY} style={{ margin: "0px" }}>
                    Calls can be recovered for 30 days after deletion.
                  </AppText>
                  {/* </BorderDiv> */}
                  <SectionTitle>CONFIGURE CALL DISPOSITION STORAGE</SectionTitle>
                  <AppText fontSize={12} fontWeight={400} style={{ marginBottom: "24px", maxWidth: "486px" }}>
                    By default, all recordings will be saved. Move call dispositions to “Delete These Calls” to delete
                    them after the designated time frame.
                  </AppText>
                  <FlexDiv style={{ marginBottom: "40px" }}>
                    {loadingDispositionTypes ? (
                      <Loading />
                    ) : (
                      <>
                        <BorderDiv style={{ padding: "0px" }}>
                          {/* <PhoenixAppButton variant="brand">Save</PhoenixAppButton> */}
                          <div
                            style={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: "16px" }}
                          >
                            <ClickableDiv
                              color={theme.PRIMARY500}
                              onClick={() => {
                                setFieldValue("delete_recordings_marked_as", []);
                              }}
                            >
                              <PhoenixIcon
                                svg={iconCheckThin}
                                size={12}
                                variant="brand"
                                style={{ marginRight: "4px" }}
                              />
                              Retain All
                            </ClickableDiv>
                            <ClickableDiv
                              color={theme.DANGER500}
                              onClick={() => {
                                const allValues = dispositionTypes?.fetchDispositionsTypes
                                  ?.map((item: any) => item?.type)
                                  ?.filter(
                                    (value: string, index: number, array: string[]) => array.indexOf(value) === index,
                                  )
                                  ?.filter((item: any) => item !== "DNC" && item !== "Sale");
                                setFieldValue("delete_recordings_marked_as", [...allValues, "NoCallResult"]);
                              }}
                            >
                              <PhoenixIcon
                                svg={iconTrashNew}
                                size={12}
                                variant="danger"
                                style={{ marginRight: "4px" }}
                              />
                              Delete All
                            </ClickableDiv>
                          </div>
                          <GridTable>
                            <GridTHead>
                              <GridTR>
                                <th>Disposition</th>
                                <th>Retain</th>
                                <th>Delete</th>
                              </GridTR>
                            </GridTHead>
                            <tbody>
                              <GridTR key={"sale-recording-del"}>
                                <td>All calls on leads with Sale</td>
                                <td>
                                  <CenterButtonDiv>
                                    <PhoenixRadio
                                      selected={true}
                                      selectedDotColor={theme.LIGHT_BLUE}
                                      color={theme.LIGHT_BLUE}
                                      selectedBorderColor={theme.LIGHT_BLUE}
                                    />
                                  </CenterButtonDiv>
                                </td>
                                <td></td>
                              </GridTR>
                              <GridTR key={"dnc-recording-del"}>
                                <td>All calls on leads marked DNC</td>
                                <td>
                                  <CenterButtonDiv>
                                    <PhoenixRadio
                                      selected={true}
                                      selectedDotColor={theme.LIGHT_BLUE}
                                      color={theme.LIGHT_BLUE}
                                      selectedBorderColor={theme.LIGHT_BLUE}
                                    />
                                  </CenterButtonDiv>
                                </td>
                                <td></td>
                              </GridTR>
                              {/* NOTE: This option is for calls with no disposition  */}
                              <GridTR key={"no-result-recording-del"}>
                                <td>No Call Result</td>
                                <td>
                                  <CenterButtonDiv>
                                    <PhoenixRadio
                                      selected={!values.delete_recordings_marked_as?.includes("NoCallResult")}
                                      onClick={async () => {
                                        const newValues = values.delete_recordings_marked_as
                                          ?.map((item) => item)
                                          ?.filter((val) => val !== "NoCallResult")
                                          ?.filter((value, index, array) => array.indexOf(value) === index);
                                        setFieldValue("delete_recordings_marked_as", newValues);
                                      }}
                                    />
                                  </CenterButtonDiv>
                                </td>
                                <td>
                                  <CenterButtonDiv>
                                    <PhoenixRadio
                                      selected={values.delete_recordings_marked_as?.includes("NoCallResult")}
                                      onClick={async () => {
                                        const newValues = [
                                          ...values.delete_recordings_marked_as,
                                          "NoCallResult",
                                        ].filter((value, index, array) => array.indexOf(value) === index);
                                        setFieldValue("delete_recordings_marked_as", newValues);
                                      }}
                                    />
                                  </CenterButtonDiv>
                                </td>
                              </GridTR>
                              {dispositionTypes?.fetchDispositionsTypes
                                ?.map((item: any) => item)
                                ?.filter((item: any) => item?.type !== "DNC" && item?.type !== "Sale")
                                ?.sort((a: any, b: any) => a?.type.localeCompare(b?.type))
                                ?.map((item: any) => (
                                  <GridTR key={item.type}>
                                    <td>{item.label}</td>
                                    <td>
                                      <CenterButtonDiv>
                                        <PhoenixRadio
                                          selected={!values.delete_recordings_marked_as?.includes(item?.type)}
                                          onClick={async () => {
                                            const newValues = values.delete_recordings_marked_as
                                              ?.map((item) => item)
                                              ?.filter((val) => item?.type !== val)
                                              ?.filter((value, index, array) => array.indexOf(value) === index);
                                            setFieldValue("delete_recordings_marked_as", newValues);
                                          }}
                                        />
                                      </CenterButtonDiv>
                                    </td>
                                    <td>
                                      <CenterButtonDiv>
                                        <PhoenixRadio
                                          selected={values.delete_recordings_marked_as?.includes(item?.type)}
                                          onClick={async () => {
                                            const newValues = [
                                              ...values.delete_recordings_marked_as,
                                              item?.type,
                                            ].filter((value, index, array) => array.indexOf(value) === index);
                                            setFieldValue("delete_recordings_marked_as", newValues);
                                          }}
                                        />
                                      </CenterButtonDiv>
                                    </td>
                                  </GridTR>
                                ))}
                            </tbody>
                          </GridTable>
                        </BorderDiv>
                      </>
                    )}
                  </FlexDiv>
                </>
              )}
            </DisappearingDiv>
          </Sentry.ErrorBoundary>
        );
      }}
    </Formik>
  );
};

const SectionTitle = styled(AppText)`
  color: ${theme.PILL_GRAY};
  margin-bottom: 8px;
  margin-top: 40px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const CenterButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface ColorProps {
  color: string;
}

const ClickableDiv = styled.div<ColorProps>`
  color: ${(props) => props.color || theme.PRIMARY500};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 140% */
  height: 36px;
  margin-right: 8px;
  :last-child {
    margin-right: 0px;
  }
`;

const GridTHead = styled.thead`
  height: 32px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  background-color: ${theme.PRIMARY100};
  border-radius: 8px 8px 0px 0px;

  th:nth-child(1) {
    text-align: left;
    width: 280px;
  }
`;
const GridTR = styled.tr`
  padding: 16px 0px;
  height: 32px;
  :nth-child(2n) {
    background-color: ${theme.PRIMARY50};
  }

  td:nth-child(1) {
    text-align: left;
  }
  td:nth-child(2) {
    align-items: center;
  }
  td:nth-child(3) {
    align-items: center;
  }
`;

const GridTable = styled.table`
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  padding: 5px;
  width: 486px;
  margin-bottom: 8px;
  th {
    padding: 0px 16px;
  }
  td {
    padding: 0px 16px;
  }
  /* td:last-child {
    tr {
      border-radius: 0px 0px 16px 16px;
    }
  } */
`;

interface PillProps {
  bg_color?: string;
  text_color?: string;
}

const PillItem = styled(AppText)<PillProps>`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${(props) => props.bg_color || theme.NEUTRAL100};
  color: ${(props) => props.text_color || theme.PILL_GRAY};
  font-size: 8px;
  line-height: 16px;
  font-weight: 600;
  height: 24px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const BorderDiv = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL200};
  width: fit-content;
  margin-bottom: 40px;
`;

const DroppableBorderDiv = styled(BorderDiv)`
  margin-bottom: 0px;
  min-width: 200px;
  min-height: 300px;
`;

interface CaretProps {
  disabled: boolean;
  bottom?: boolean;
}

const AbsDivTop = styled.div<CaretProps>`
  display: flex;
  background: url(${iconNewCaretDown}) no-repeat;
  background-position: 5px 13px;
  position: absolute;
  right: 10px;
  top: ${(props) => (props.bottom ? "20px" : "0px")};
  transform: ${(props) => (props.bottom ? "rotate(180deg)" : "rotate(0deg)")};
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const NumberInput = styled.input`
  height: 40px;
  padding-left: 12px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 2px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;

const AbsDivTime = styled.div`
  display: flex;
  position: absolute;
  right: 36px;
  top: 0px;
  width: fit-content;
  height: 20px;
`;

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

const LeadDiv = styled.div`
  display: flex;
  flex-direction: row;
  /* min-width: 150px; */
  align-items: center;
`;

const SpinDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-right: 4px;
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 200px;
  grid-gap: 16px;
  flex-direction: row;
  align-items: flex-start;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const GridDiv = styled.div`
  margin-bottom: 16px;
  min-height: 600px;
  max-height: 640px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const SaveAndContinueButton = styled(NewAppButton)`
  font-size: 10px;
  font-weight: 600;
  width: 140px;
  letter-spacing: 1px;
  height: 40px;
`;

const ListItemText = styled(AppText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
`;

export { CallRecordingStorageTab };
