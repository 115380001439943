import React, { useState, useContext, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import { theme } from "../../../utils/theme";
import { ModalContext } from "../../../context";
import { PhoenixAppButton, PhoenixIcon, PhoenixRadio } from "../../UI/Phoenix";
import { PhoenixMultiSelectField } from "../../Field/Phoenix/PhoenixMultiSelectField";
import { AppText, DarkDiv, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { info, plus, trash, xIcon } from "../../../images/NewDesign";
import { Formik, FormikProps } from "formik";
import { errorToast } from "../../../utils/toast";
import { cloneDeep } from "lodash";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { blockedDispositions } from "../../../utils/sequences";

const FETCH_DISPOSITION_TYPES = gql`
  query fetchDispositionsTypes($showLabel: Boolean) {
    fetchDispositionsTypes(show_label: $showLabel)
  }
`;

const CREATE_OR_UPDATE_SEQUENCE_STEP = gql`
  mutation createOrUpdateSequenceStep($sequenceStepInput: SequenceStepInput!) {
    createOrUpdateSequenceStep(SequenceStepInput: $sequenceStepInput) {
      id
    }
  }
`;

interface SequenceBranchModalProps {
  sequenceId: string;
  branchCount: number;
  refetchSequence?: any;
  sequenceData: any;
}

interface BranchFormProps {
  branches: any[];
}

export const SequenceBranchModal: React.FC<SequenceBranchModalProps> = ({
  sequenceId,
  refetchSequence,
  branchCount,
  sequenceData,
}) => {
  const { setShowSequenceBranchModal, sequenceStepData, setSequenceStepData } = useContext(ModalContext);

  const [selectedDispositions, setSelectedDispositions]: any[] = useState([]);
  const [downstreamBranch, setDownstreamBranch]: any = useState();

  const connectedBranches = sequenceData?.active_steps?.filter(
    (step: any) => step?.origin_id === sequenceStepData?.originId && !step?.is_no_branch,
  );

  const { data: dispositionTypes, loading: loadingDispositionTypes } = useQuery(FETCH_DISPOSITION_TYPES, {
    fetchPolicy: "cache-and-network",
    variables: { showLabel: true },
  });

  useEffect(() => {
    // in edit state
    if (connectedBranches && !!connectedBranches.length) {
      const connectedBranchesConditions = connectedBranches?.map((branch: any) => branch?.conditions)?.flat();
      const formattedBranchesConditions = connectedBranchesConditions?.map((condition: any) => ({
        label:
          dispositionTypes?.fetchDispositionTypes?.find(
            (ele: { label: string; type: string }) => ele.type === condition?.dispositionType,
          ) || condition?.dispositionType,
        value: condition?.dispositionType,
      }));

      setSelectedDispositions(formattedBranchesConditions);
    }
  }, [sequenceData, dispositionTypes]);

  const [createOrUpdateSequenceStep, { loading: loadingCreateOrUpdateSequenceStep }] = useMutation(
    CREATE_OR_UPDATE_SEQUENCE_STEP,
    {
      async onCompleted({ createOrUpdateSequenceStep }) {
        console.log("createOrUpdateSequenceStep:", createOrUpdateSequenceStep);
        setSequenceStepData({});
      },
      onError({ message }) {
        errorToast(`${message}`);
        Sentry.captureEvent({
          message: `createOrUpdateSequenceStep GraphQL Error: ${message}`,
        });
        console.log(`createOrUpdateSequenceStep GraphQL Error: ${message}`);
        setSequenceStepData({});
      },
    },
  );

  const handleBranchChange = (curBranches: any[], index: number, newVal: any[]) => {
    const newBranches = cloneDeep(curBranches);
    newBranches[index].dispositions = newVal;
    setSelectedDispositions(newBranches?.map((ele: any) => ele.dispositions || []).flat());
    return newBranches;
  };

  const handleSubmit = async (e: BranchFormProps) => {
    let downstreamHost;
    const nonSelectedDispositions = dispositionTypes?.fetchDispositionsTypes
      ?.map((ele: { label: string; type: string }) => ele.type)
      ?.filter(
        (type: string) =>
          !selectedDispositions?.map((ele: any) => ele.value)?.includes(type) && !blockedDispositions?.includes(type),
      );
    let shouldUpdateToNoBranch = !nonSelectedDispositions.length;

    // new branch state
    if (!sequenceStepData?.nodeData?.conditions?.length) {
      // create the 'No' branch (all dispositions not selected)
      let noStep: any;
      if (!!nonSelectedDispositions.length) {
        noStep = await createOrUpdateSequenceStep({
          variables: {
            sequenceStepInput: {
              conditions: nonSelectedDispositions,
              is_branch: true,
              origin_id: sequenceStepData?.originId,
              sequence_id: sequenceId,
              tasks: [],
              is_no_branch: true,
            },
          },
        });
      }

      // create a step for every branch

      for (let i = 0; i < e.branches.length; i++) {
        const branch = e.branches[i];
        const dispositions = branch?.dispositions?.map((disposition: any) => disposition.value);
        const newStep = await createOrUpdateSequenceStep({
          variables: {
            sequenceStepInput: {
              conditions: dispositions,
              is_branch: true,
              origin_id: sequenceStepData?.originId,
              sequence_id: sequenceId,
              tasks: [],
              is_no_branch: shouldUpdateToNoBranch,
            },
          },
        });
        if (i === downstreamBranch) {
          downstreamHost = newStep;
        }
        if (!!shouldUpdateToNoBranch) {
          shouldUpdateToNoBranch = false;
        }
      }

      // set downstream steps to the downstreamHost
      if (!!sequenceStepData.nextNode) {
        const nextNode = sequenceStepData.nextNode;
        // update nextStep's origin_id
        await createOrUpdateSequenceStep({
          variables: {
            sequenceStepInput: {
              step_id: nextNode.id,
              sequence_id: sequenceId,
              // if no downstreamHost is selected, set downstream to 'No' branch
              origin_id: !!downstreamHost
                ? downstreamHost.data?.createOrUpdateSequenceStep?.id
                : noStep?.data?.createOrUpdateSequenceStep?.id,
              is_origin_update: true,
            },
          },
        });
      }
    } else {
      // edit state

      for (let i = 0; i < e.branches.length; i++) {
        const branch = e.branches[i];
        const dispositions = branch?.dispositions?.map((disposition: any) => disposition.value);
        await createOrUpdateSequenceStep({
          variables: {
            sequenceStepInput: {
              step_id: branch.id,
              conditions: dispositions,
              is_branch: true,
              origin_id: sequenceStepData?.originId,
              sequence_id: sequenceId,
              tasks: [],
              is_no_branch: shouldUpdateToNoBranch ? undefined : false,
            },
          },
        });
      }
    }

    refetchSequence();
    setShowSequenceBranchModal(false);
  };

  return (
    <>
      <DarkDiv noFade />
      <PhoenixStyledTooltip id="downstream-steps-info" lineHeight={16} maxWidth={200} />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            color={theme.PRIMARY500}
            size={24}
            pointer
            onClick={() => {
              setShowSequenceBranchModal(false);
            }}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            {!sequenceStepData?.nodeData?.conditions?.length ? "Add" : "Edit"} Branches
          </AppText>
        </ModalHeader>

        <Formik
          initialValues={{
            branches: !!sequenceStepData?.nodeData?.conditions?.length
              ? // initial edit state
                connectedBranches?.map((cb: any) => ({
                  dispositions: cb?.conditions?.map((condition: any) => ({
                    label:
                      condition?.dispositionType === "TransferredAnotherSDRsSet"
                        ? "Transferred Another SDRs Set"
                        : condition?.dispositionType.replace(/([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, "$1$2 "),
                    value: condition?.dispositionType,
                  })),
                  id: cb?.id,
                }))
              : // initial create state
                [{ dispositions: [] }],
          }}
          onSubmit={handleSubmit}
        >
          {({ submitForm, values, setFieldValue }: FormikProps<BranchFormProps>) => {
            return (
              <>
                <ModalBody direction="column" gap={32}>
                  {values.branches?.map((branch: any, i: number) => (
                    <BranchContainer key={`branchContainer-${i}`}>
                      <FlexDiv justify="space-between">
                        <BranchText>Branch {i + 1}</BranchText>
                        {i !== 0 && !sequenceStepData?.nodeData?.conditions?.length && (
                          <PhoenixIcon
                            svg={trash}
                            size={16}
                            pointer
                            variant="danger"
                            onClick={() => {
                              const splicedBranches = [...values.branches];
                              const removedBranch = splicedBranches.splice(i, 1)[0];
                              const removedDispositions = removedBranch?.dispositions?.map(
                                (disposition: { label: string; value: string }) => disposition.value,
                              );
                              const filteredSelectedDispositions = cloneDeep(selectedDispositions).filter(
                                (ele: string) => !removedDispositions?.includes(ele),
                              );
                              setSelectedDispositions(filteredSelectedDispositions);
                              setFieldValue("branches", splicedBranches);
                              downstreamBranch === i && setDownstreamBranch(undefined);
                            }}
                          />
                        )}
                      </FlexDiv>
                      <div>
                        <AppText fontSize={12} fontWeight={500} style={{ marginBottom: "7px" }}>
                          Call Dispositions
                        </AppText>
                        <PhoenixMultiSelectField
                          name={`branch-${i}`}
                          value={values?.branches?.[i]?.dispositions}
                          isMulti={true}
                          onChange={(e: any) => setFieldValue("branches", handleBranchChange(values.branches, i, e))}
                          options={
                            !loadingDispositionTypes
                              ? dispositionTypes?.fetchDispositionsTypes
                                  ?.filter(
                                    (ele: { label: string; type: string }) => !blockedDispositions?.includes(ele.type),
                                  )
                                  ?.filter(
                                    (ele: { label: string; type: string }) =>
                                      !selectedDispositions.find((sd: any) => sd.value === ele.type),
                                  )
                                  ?.sort((a: { label: string; type: string }, b: { label: string; type: string }) =>
                                    a.label.localeCompare(b.label),
                                  )
                                  ?.map((ele: { label: string; type: string }) => ({
                                    label: ele.label,
                                    value: ele.type,
                                  }))
                              : []
                          }
                          marginBottom={false}
                          isClearable={false}
                        />
                      </div>
                      <AppText fontSize={12} color={theme.NEUTRAL300} style={{ marginTop: "24px" }}>
                        Any call disposition not selected will default to the “No” branch.
                      </AppText>
                    </BranchContainer>
                  ))}
                  {values.branches.length < 4 && values.branches.length < 20 - (branchCount + 1) && (
                    <FlexDiv
                      align="center"
                      gap={8}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFieldValue("branches", [...values.branches, { dispositions: [] }]);
                      }}
                    >
                      <PhoenixIcon svg={plus} hoverColor={theme.PRIMARY500} pointer size="small" />
                      <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
                        Add Branch
                      </AppText>
                    </FlexDiv>
                  )}

                  {!dispositionTypes?.fetchDispositionsTypes.filter(
                    (ele: { label: string; type: string }) =>
                      !selectedDispositions?.map((sd: any) => sd.value)?.includes(ele.type) &&
                      !blockedDispositions?.includes(ele.type),
                  )?.length &&
                    !!sequenceStepData?.nodeData?.conditions?.length && (
                      <AppText color={theme.DANGER600}>
                        Warning: You have all available call dispositions selected. If there is a 'No' branch on this
                        level, saving will delete the 'No' branch.
                      </AppText>
                    )}

                  {!!sequenceStepData.nextNode && (
                    <>
                      <div>
                        <FlexDiv justify="space-between" align="center">
                          <BranchText style={{ marginBottom: "0px" }}>Select a branch for downstream steps</BranchText>
                          <PhoenixIcon
                            data-tip="Looks like there are steps that come after this one. Select which branch those steps will be assigned to."
                            data-for="downstream-steps-info"
                            variant="neutral"
                            svg={info}
                            size={14}
                            style={{ width: "fit-content" }}
                          />
                        </FlexDiv>
                        <AppText fontSize={12} color={theme.NEUTRAL300} style={{ marginTop: "12px" }}>
                          No selection will move downstream steps to the "No" branch.
                        </AppText>
                      </div>
                      <FlexDiv direction="column" gap={8}>
                        {values.branches?.map((branch: any, i: number) => (
                          <FlexDiv key={`downstreamSelect-${i}`} align="center" gap={8}>
                            <PhoenixRadio selected={downstreamBranch === i} onClick={() => setDownstreamBranch(i)} />
                            <AppText fontSize={14}>Branch {i + 1}</AppText>
                          </FlexDiv>
                        ))}
                      </FlexDiv>
                    </>
                  )}
                </ModalBody>

                <ModalFooter justify="space-between">
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    buttonTextFontSize={10}
                    uppercase
                    onClick={() => {
                      setShowSequenceBranchModal(false);
                    }}
                  >
                    Cancel
                  </PhoenixAppButton>

                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand"
                    buttonTextFontSize={10}
                    uppercase
                    disabled={
                      values?.branches?.some((ele: any) => !ele.dispositions?.length) ||
                      (values?.branches?.length === 1 &&
                        !dispositionTypes?.fetchDispositionsTypes
                          ?.filter((ele: { label: string; type: string }) => !blockedDispositions?.includes(ele.type))
                          ?.filter(
                            (ele: { label: string; type: string }) =>
                              !selectedDispositions.find((sd: any) => sd.value === ele.type),
                          )?.length) ||
                      loadingCreateOrUpdateSequenceStep
                    }
                    onClick={submitForm}
                  >
                    Save
                  </PhoenixAppButton>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 40px;

  max-height: 80vh;
  min-height: 70vh;
  margin-bottom: auto;

  overflow-y: overlay;
  overflow-x: hidden;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;

const BranchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px 0px;

  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const BranchText = styled(AppText)`
  font-size: 16px;
  margin-bottom: 16px;
`;
