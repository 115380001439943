import React, { useState, useContext, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import styled, { keyframes } from "styled-components";
import { theme } from "../../../utils/theme";
import { ModalContext } from "../../../context";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput, PhoenixMultiSelect, PhoenixRadio } from "../../UI/Phoenix";
import { AppText, DarkDiv, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { errorToast, successToast } from "../../../utils/toast";
import { endActionOptions, entryCriteriaOptions } from "../../../utils/sequences";
import { xIcon } from "../../../images/NewDesign";
import { isEqual } from "lodash";

const UPDATE_END_STEP_ACTION = gql`
  mutation add_end_sequence_step_action(
    $step_id: String!
    $post_step_action: postAction!
    $transfer_to_sequence_id: String
  ) {
    add_end_sequence_step_action(
      step_id: $step_id
      post_step_action: $post_step_action
      transfer_to_sequence_id: $transfer_to_sequence_id
    ) {
      id
      post_step_action
    }
  }
`;

const FETCH_SEQUENCES = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

interface EditEndSequenceModalProps {
  sequenceData: any;
}

export const EditEndSequenceModal: React.FC<EditEndSequenceModalProps> = ({ sequenceData }) => {
  const { setShowEditEndSequenceModal, sequenceStepData, setSequenceStepData } = useContext(ModalContext);
  const [endAction, setEndAction] = useState<{ label: string; value: string } | undefined>(
    endActionOptions?.find(
      (option: { label: string; value: string }) => option.value === sequenceStepData?.postActionStep,
    ),
  );
  const [search, setSearch] = useState<string>("");
  const [selectedSequence, setSelectedSequence] = useState<string | undefined>(
    sequenceData?.active_steps?.find((step: any) => step?.id === sequenceStepData?.originId)?.transfer_to_sequence_id,
  );

  const [updateEndStepAction, { loading: loadingUpdateEndStepAction }] = useMutation(UPDATE_END_STEP_ACTION, {
    async onCompleted({ add_end_sequence_step_action }) {
      console.log("add_end_sequence_step_action:", add_end_sequence_step_action);
      successToast("End step action successfully changed!");
      setShowEditEndSequenceModal(false);
      setSequenceStepData({});
    },
    onError({ message }) {
      errorToast(`${message}`);
      console.log(`add_end_sequence_step_action GraphQL Error: ${message}`);
    },
    refetchQueries: ["fetchSequence"],
  });

  const { data: dataSequences, loading: loadingSequences, error: errorSequences } = useQuery(FETCH_SEQUENCES, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: endAction?.value !== "TransferAnotherSequence",
    variables: {
      sequencesFilter: {
        entry_phases: sequenceData?.sequence_entry_criteria?.current_phase,
      },
    },
    onCompleted({ fetchSequences }) {
      console.log("fetchSequences w/ entry_phases filter:", fetchSequences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <>
      <DarkDiv noFade />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            size={24}
            pointer
            onClick={() => {
              setShowEditEndSequenceModal(false);
              setSequenceStepData({});
            }}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            Edit End Sequence
          </AppText>
        </ModalHeader>

        <>
          <ModalBody direction="column" gap={32}>
            <CriteriaContainer>
              <CriteriaTitle>End Action Type</CriteriaTitle>
              <div style={{ width: "100%" }}>
                <PhoenixMultiSelect
                  name="end-action-type"
                  value={endAction}
                  isMulti={false}
                  onChange={(e: { label: string; value: string }) => {
                    setEndAction(e);
                    setSelectedSequence(undefined);
                  }}
                  options={endActionOptions}
                  marginBottom={false}
                  isClearable={false}
                />
              </div>
            </CriteriaContainer>

            {endAction?.value === "TransferAnotherSequence" && (
              <CriteriaContainer>
                <FlexDiv direction="column" gap={8}>
                  <CriteriaTitle>Select a Sequence</CriteriaTitle>
                  <PhoenixInput
                    placeholder="Search Sequences"
                    variant="primary"
                    type="text"
                    name="search"
                    id="search-sequences"
                    searchInput
                    fontSize={10}
                    fontWeight={400}
                    displayNoContextText
                    width={359}
                    value={search}
                    onChange={(e: any) => {
                      setSearch(e.target.value);
                      setSelectedSequence(undefined);
                    }}
                  />
                </FlexDiv>

                <SequenceListContainer direction="column" gap={8}>
                  {loadingSequences ? (
                    <Loading />
                  ) : !dataSequences?.fetchSequences?.length ? (
                    // No sequences with the same entry criteria

                    <AppText color={theme.NEUTRAL400} style={{ marginLeft: "auto", marginRight: "auto" }}>
                      No sequences available to transfer.
                    </AppText>
                  ) : !!dataSequences?.fetchSequences?.length &&
                    // No sequences matching search

                    !dataSequences?.fetchSequences?.filter((ele: any) =>
                      ele?.name?.toLowerCase()?.includes(search.toLowerCase()),
                    )?.length ? (
                    <AppText
                      color={theme.NEUTRAL400}
                      style={{ marginLeft: "auto", marginRight: "auto", wordBreak: "break-all" }}
                    >
                      No sequences available matching {`'${search}'`}.
                    </AppText>
                  ) : (
                    dataSequences?.fetchSequences
                      ?.filter(
                        (ele: any) =>
                          ele?.name?.toLowerCase()?.includes(search.toLowerCase()) && ele?.id !== sequenceData?.id,
                      )
                      ?.map((ele: any) => (
                        <SequenceOption selected={selectedSequence === ele?.id} key={ele.id}>
                          <FlexDiv align="center" gap={8} style={{ width: "100%" }}>
                            <PhoenixRadio
                              selected={selectedSequence === ele?.id}
                              size={16}
                              onClick={() => setSelectedSequence(ele?.id)}
                            />
                            <AppText
                              fontSize={12}
                              fontWeight={500}
                              noWrap
                              style={{ textOverflow: "ellipsis", overflow: "hidden", maxWidth: "150px" }}
                            >
                              {ele.name}
                            </AppText>

                            <PhaseContainer>
                              {
                                entryCriteriaOptions?.find((option: { value: string | string[]; label: string }) =>
                                  ele?.sequence_entry_criteria?.current_phase[0] &&
                                  ele?.sequence_entry_criteria?.current_phase?.length === 1
                                    ? option.value === ele?.sequence_entry_criteria?.current_phase[0]
                                    : isEqual(option.value, ele?.sequence_entry_criteria?.current_phase),
                                )?.label
                              }
                            </PhaseContainer>
                          </FlexDiv>
                        </SequenceOption>
                      ))
                  )}
                </SequenceListContainer>
              </CriteriaContainer>
            )}
          </ModalBody>

          <ModalFooter justify="space-between">
            <PhoenixAppButton
              buttonType="secondary"
              variant="danger-outline"
              buttonTextFontSize={10}
              uppercase
              onClick={() => {
                setShowEditEndSequenceModal(false);
                setSequenceStepData({});
              }}
            >
              Cancel
            </PhoenixAppButton>

            <PhoenixAppButton
              buttonType="secondary"
              variant="brand"
              buttonTextFontSize={10}
              uppercase
              disabled={
                loadingUpdateEndStepAction || (endAction?.value === "TransferAnotherSequence" && !selectedSequence)
              }
              onClick={() => {
                updateEndStepAction({
                  variables: {
                    step_id: sequenceStepData?.originId,
                    post_step_action: endAction?.value,
                    transfer_to_sequence_id: endAction?.value === "TransferAnotherSequence" ? selectedSequence : null,
                  },
                });
              }}
            >
              Save Changes
            </PhoenixAppButton>
          </ModalFooter>
        </>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 40px;

  height: 100%;
  margin-bottom: auto;

  overflow-y: overlay;
  overflow-x: hidden;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;

const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const CriteriaTitle = styled(AppText)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
`;

const SequenceListContainer = styled(FlexDiv)`
  max-height: 55vh;
  width: 100%;
  margin-top: 8px;
  overflow-y: auto;
  padding-right: 8px;
`;

interface SequenceOption {
  selected: boolean;
}

const SequenceOption = styled.div<SequenceOption>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 14px;

  background-color: ${theme.PRIMARY100};
  border-radius: 8px;
  border: ${(props) => (props?.selected ? `2px solid ${theme.PRIMARY500}` : `2px solid ${theme.PRIMARY100}`)};
`;

const PhaseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  margin-left: auto;
  padding: 6px 8px;

  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;

  color: ${theme.PRIMARY500};
  background-color: ${theme.PRIMARY200};
  border-radius: 4px;
`;
