import styled from "styled-components";
import * as React from "react";
import { theme } from "../../utils/theme";
import { AppText, FlexDiv } from "../UI";
import { xIcon } from "../../images/NewDesign";
import { sanitizeURL } from "../../utils/misc";
import { PhoenixIcon } from "../UI/Phoenix";

interface IToastProps {
  //   title: string;
  message: string;
  subtext?: string;
  variant: string;
  lead_id?: string;
  redirect?: string;
  icon?: string;
  iconColor?: string;
}

interface IToastCloseProps {
  closeToast: any;
}

const StyledCloseToast: React.FC<IToastCloseProps> = ({ closeToast }) => {
  return (
    <StyledClose onClick={closeToast}>
      <PhoenixIcon svg={xIcon} size={16} variant="brand" onClick={closeToast} pointer />
    </StyledClose>
  );
};

const StyledToast: React.FC<IToastProps> = ({ message, subtext, variant, lead_id, redirect, icon, iconColor }) => {
  const clickable = !!lead_id || !!redirect;

  return (
    <RelDiv
      variant={variant}
      style={{ cursor: !!lead_id || !!redirect ? "pointer" : "default" }}
      onClick={() => {
        if (!!redirect) {
          const santiziedURL = sanitizeURL(redirect);
          window.open(santiziedURL, "_blank", "noreferrer");
          return;
        }
        if (!!lead_id) {
          const santiziedURL = sanitizeURL(`/lead-detail/${lead_id}`);
          // redirect to lead detail page
          window.open(santiziedURL, "_blank", "noreferrer");
        }
      }}
    >
      <ColorTip variant={variant} />
      {icon && (
        <PhoenixIcon
          svg={icon}
          size={24}
          variant="brand"
          color={iconColor ? iconColor : theme.icon.brand.default}
          hoverColor={iconColor ? iconColor : theme.icon.brand.default}
          style={{ padding: "20px 16px 16px 16px" }}
        />
      )}
      <TextContainer icon={!!icon}>
        <ToastText1>
          {message} {clickable && <ToastTextClickable>Click to View Here</ToastTextClickable>}
        </ToastText1>
        {subtext && <ToastText2>{subtext}</ToastText2>}
      </TextContainer>
    </RelDiv>
  );
};

const RelDiv = styled.div.attrs(({ variant }: { variant: string }) => ({
  variant: variant,
}))`
  display: flex;
  flex-direction: row;

  /* align-items: center; */
  border-radius: 8px;
  height: max-content;
  padding-right: 16px;

  ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
      background-color: ${theme.WHITE_COLOR};
      border: 1px solid ${theme.buttonborder.brand_outline.default};
      `;
      case "success":
        return `
      background-color: ${theme.fill.success.tertiary};
      border: 1px solid ${theme.buttonborder.success_outline.default};
    `;
      case "warning":
        return `
      background-color:  ${theme.fill.warning.tertiary};
      border: 1px solid ${theme.buttonborder.warning_outline.default};
    `;
      case "error":
        return `
      background-color:  ${theme.fill.danger.tertiary};
      border: 1px solid ${theme.buttonborder.danger_outline.default};
    `;
      case "hot":
        return `
          background-color: ${theme.fill.dataviz4.secondary};
          border: 1px solid ${theme.border.dataviz4.inverse};
          box-shadow: 0px 0px 10px 0px rgba(252, 122, 29, 0.50);
        `;
      default:
        return `
      background-color: ${theme.WHITE_COLOR};
      border: 1px solid ${theme.buttonborder.brand_outline.default};
    `;
    }
  }};
`;

interface VariantProps {
  variant: string;
}
const ColorTip = styled.div<VariantProps>`
  width: 16px;
  min-height: 100%;
  border-radius: ${(props) => {
    if (props.variant === "hot") {
      return "6px 0px 0px 6px";
    }
    return "8px 0px 0px 8px";
  }};
  ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
      background-color: ${theme.fill.brand.primary};
      `;
      case "success":
        return `
      background-color: ${theme.fill.success.primary};
    `;
      case "warning":
        return `
      background-color:  ${theme.fill.warning.primary};
    `;
      case "error":
        return `
      background-color:  ${theme.fill.danger.primary};
    `;
      case "hot":
        return `
          background-color: ${theme.fill.dataviz4.inverse};
        `;
      default:
        return `
      background-color:  ${theme.fill.brand.primary};
    `;
    }
  }};
`;

const TextContainer = styled.div<{ icon: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.icon ? "0px" : "8px")};
  margin-right: 16px;
  width: 100%;
  min-height: 64px;

  padding: ${(props) => (props.icon ? "8px 8px 8px 0px" : "8px")};
  overflow: hidden;
  justify-content: center;
`;

const ToastText1 = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // wrap text
  white-space: pre-wrap;
  width: 100%;
`;
const ToastText2 = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  // wrap text
  white-space: pre-wrap;
  width: 100%;
`;

const ToastTextClickable = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;

const StyledClose = styled.div`
  padding: 8px;
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
`;

export { StyledToast, StyledCloseToast };
