import React from "react";
import styled from "styled-components";

import { chat, mail, phone_primary } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { PhoenixIcon } from "../../../UI/Phoenix";

const LeadCardContactButton = ({
  disabled,
  onClick,
  type = "phone",
  callState = false,
  size,
  padding,
  id,
  isNextDial = false,
}: {
  disabled: boolean;
  onClick: () => void;
  type?: "phone" | "sms" | "email";
  callState?: boolean;
  size?: number;
  padding?: number;
  id?: string;
  isNextDial?: boolean;
}) => {
  const getData = () => {
    switch (type) {
      case "phone":
        return { icon: phone_primary, id: `leadCardContactButton-phone${isNextDial ? "-nextDial" : ""}` };
      case "sms":
        return { icon: chat, id: `leadCardContactButton-sms${isNextDial ? "-nextDial" : ""}` };
      case "email":
        return { icon: mail, id: `leadCardContactButton-email${isNextDial ? "-nextDial" : ""}` };
      default:
        return { icon: chat };
    }
  };

  return (
    <ActionButton
      id={id || getData()?.id}
      disabled={disabled}
      onClick={onClick}
      callState={callState}
      padding={padding}
    >
      <PhoenixIcon svg={getData().icon} size={size ?? 16} alt={type} pointer={!disabled} />
    </ActionButton>
  );
};

interface INumberDiv {
  callState: boolean;
  disabled: boolean;
  padding?: number;
}
const ActionButton = styled.button<INumberDiv>`
  display: ${(props) => (props.callState ? "none" : "auto")};
  padding: ${(props) => (props.padding ?? 8) + "px"};
  margin-right: 4px;
  margin-left: 4px;
  background: transparent;
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  :hover {
    background-color: ${(props) => (props.disabled ? "transparent" : theme.PRIMARY200)};
  }
`;

export { LeadCardContactButton };
