import styled from "styled-components";

import React, { useState, useEffect } from "react";

import { AppInput, AppText, FlexDiv, Loading } from "../../../UI";
import { NewAppButton } from "../../../UI/NewAppButton";

import { theme } from "../../../../utils/theme";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput, PhoenixRadio } from "../../../UI/Phoenix";
import { useQuery, gql } from "@apollo/client";

import { AppErrorText } from "../../../UI/";
import "../../Onboarding/transfer-tab/TransferRulesTable.css";
import { debounce, set } from "lodash";
import { useMutation } from "@apollo/client";
import { appToast, errorToast } from "../../../../utils/toast";
import { restAPI } from "../../../../apollo";
import { SkeletonBlock } from "../../../UI/SkeletonBlock";
import { useDebounce } from "../../../../utils/hooks";
import { SETTINGS_DEBOUNCE_INTERVAL } from "../../../../utils/variables";
import { AppCheckbox } from "../../../UI/AppCheckbox";
import { InputField } from "../../../Field";
import { OutsideEvent } from "../../../Dumb";
import { iconButtonArrow, searchIcon, tempIconUpDown } from "../../../../images";
import { info } from "../../../../images/NewDesign";
import ReactTooltip from "react-tooltip";
import { loggedInUser } from "../../../../apollo/cache";
import Switch from "react-switch";
import { useFlags } from "launchdarkly-react-client-sdk";

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      allow_external_transfer
      Reps {
        id
        full_name
        transfer_type
        booking_transfer_type
        id
        first_name
        last_name
        revenue_per_day
        email
        role
        allow_external_transfer
      }
    }
  }
`;

const FETCH_ORG_LIVE_TRANSFER_SETTINGS = gql`
  query FetchOrgLiveTransferSetting {
    fetchOrgLiveTransferSetting {
      ae_transfer_to_ae
      ae_transfer_to_sdr
      created_at
      dial_duration
      hold_music_options
      hold_music_url
      id
      max_cycle
      organization_id
      sdr_transfer_to_sdr
      transfer_type
      updated_at
      organization {
        Reps {
          id
          close_rate
        }
      }
    }
  }
`;

const FETCH_ORG_FUTURE_BOOKING_SETTINGS = gql`
  query FetchOrgFutureBookingSetting {
    fetchOrgFutureBookingSetting {
      created_at
      id
      organization {
        Reps {
          id
        }
      }
      organization_id
      sdr_demo_to_sdr
      transfer_type
      updated_at
    }
  }
`;

const UPDATE_ORG_LIVE_TRANSFER_SETTING = gql`
  mutation UpdateOrgLiveTransferSetting($data: LiveTransferSettingInput!) {
    updateOrgLiveTransferSetting(data: $data) {
      id
    }
  }
`;

const UPDATE_ORG_FUTURE_BOOKING_SETTING = gql`
  mutation UpdateOrgFutureBookingSetting($data: FutureBookingSettingInput!) {
    updateOrgFutureBookingSetting(data: $data) {
      id
    }
  }
`;

const UPDATE_USER_TRANSFER_TYPE = gql`
  mutation updateUserTransferType($manual: [String!]!, $automatic: [String!]!) {
    updateUserTransferType(manual: $manual, automatic: $automatic)
  }
`;

const UPDATE_GLOBAL_EXTERNAL_TRANSFER = gql`
  mutation updateGlobalExternalTransferSetting($allow_global_external_transfer: Boolean!) {
    updateGlobalExternalTransferSetting(allow_global_external_transfer: $allow_global_external_transfer)
  }
`;
const UPDATE_REP_EXTERNAL_TRANSFER = gql`
  mutation updateRepExternalTransfer($allowed: [String!]!, $disabled: [String!]!) {
    updateRepExternalTransfer(allowed: $allowed, disabled: $disabled)
  }
`;

const UPDATE_BOOKING_TRANSFER_TYPE = gql`
  mutation updateBookingTransferType($manual: [String!]!, $automatic: [String!]!) {
    updateBookingTransferType(manual: $manual, automatic: $automatic)
  }
`;

const TransferRulesSettings = () => {
  const [dataOrgLiveTransferSettings, setDataOrgLiveTransferSettings] = useState<any>({});
  const [dataOrgFutureBookingSettings, setDataOrgFutureBookingSettings] = useState<any>({});

  // Manual vs. Automatic rep selection for live transfers
  const [searchTermManual, setSearchTermManual] = useState("");
  const [searchTermAutomatic, setSearchTermAutomatic] = useState("");
  const [selectedReps, setSelectedReps] = useState({
    manual: [] as string[],
    automatic: [] as string[],
  });
  const [selectedRepsExternalTransfer, setSelectedRepsExternalTransfer] = useState({
    allowed: [] as string[],
    disabled: [] as string[],
  });
  const [repExternalTransferRules, setRepExternalTransferRules] = useState({
    allowed: [] as string[],
    disabled: [] as string[],
  });
  const [globalExternalTransfer, setGlobalExternalTransfer] = useState(false);

  const [searchTermExternalDisable, setSearchTermExternalDisable] = useState("");
  const [searchTermExternalAllow, setSearchTermExternalAllow] = useState("");
  const [repTransferMethods, setRepTransferMethods] = useState({
    manual: [] as string[],
    automatic: [] as string[],
  });

  // Manual vs. Automatic rep selection for future bookings
  const [searchTermBookingManual, setSearchTermBookingManual] = useState("");
  const [searchTermBookingAutomatic, setSearchTermBookingAutomatic] = useState("");
  const [selectedBookingReps, setSelectedBookingReps] = useState({
    manual: [] as string[],
    automatic: [] as string[],
  });
  const [bookingTransferMethods, setBookingTransferMethods] = useState({
    manual: [] as string[],
    automatic: [] as string[],
  });

  const { allowExternalTransfers } = useFlags();
  const {
    data: rawdataOrgLiveTransferSettings,
    error: errorOrgLiveTransferSettings,
    loading: loadingOrgLiveTransferSettings,
    refetch: refetchOrgLiveTransferSettings,
  } = useQuery(FETCH_ORG_LIVE_TRANSFER_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching live transfer settings");
    },
    onCompleted() {
      setDataOrgLiveTransferSettings({
        ...rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting,
        max_cycle: rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.max_cycle,
        dial_duration: rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.dial_duration,
      });

      setStateMaxCycle(rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.max_cycle);
      setStateDialDuration(rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.dial_duration);
    },
  });

  const {
    data: rawDataOrgFutureBookingSettings,
    error: errorOrgFutureBookingSettings,
    loading: loadingOrgFutureBookingSettings,
    refetch: refetchOrgFutureBookingSettings,
  } = useQuery(FETCH_ORG_FUTURE_BOOKING_SETTINGS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching future booking settings");
    },

    onCompleted() {
      setDataOrgFutureBookingSettings(rawDataOrgFutureBookingSettings?.fetchOrgFutureBookingSetting);
    },
  });

  //--------------------------------------------------------------------------------
  // debounced listener for live transfer settings
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const initalLoad = Object.keys(dataOrgLiveTransferSettings).length === 0;

    const stateIsSameAsRawData = () => {
      return (
        JSON.stringify(dataOrgLiveTransferSettings) ===
        JSON.stringify({
          ...rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting,
          max_cycle: rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.max_cycle,
          dial_duration: rawdataOrgLiveTransferSettings?.fetchOrgLiveTransferSetting?.dial_duration,
        })
      );
    };

    if (initalLoad) return;

    if (stateIsSameAsRawData()) return;

    console.log("dataOrgLiveTransferSettings after check", dataOrgLiveTransferSettings);

    const debounced = debounce(() => {
      updateOrgLiveTransferSetting({
        variables: {
          data: {
            ae_transfer_to_ae: !!dataOrgLiveTransferSettings?.ae_transfer_to_ae,
            ae_transfer_to_sdr: !!dataOrgLiveTransferSettings?.ae_transfer_to_sdr,
            dial_duration: dataOrgLiveTransferSettings?.dial_duration,
            hold_music_options: dataOrgLiveTransferSettings?.hold_music_options,
            max_cycle: dataOrgLiveTransferSettings?.max_cycle,
            sdr_transfer_to_sdr: !!dataOrgLiveTransferSettings?.sdr_transfer_to_sdr,
            transfer_type: dataOrgLiveTransferSettings?.transfer_type,
          },
        },
      });
    }, SETTINGS_DEBOUNCE_INTERVAL);
    debounced();
    return debounced.cancel;
  }, [dataOrgLiveTransferSettings]);

  //--------------------------------------------------------------------------------
  // debounced listener for future booking settings
  //--------------------------------------------------------------------------------

  useEffect(() => {
    const initalLoad = Object.keys(dataOrgFutureBookingSettings).length === 0;

    const stateIsSameAsRawData = () => {
      return (
        JSON.stringify(dataOrgFutureBookingSettings) ===
        JSON.stringify(rawDataOrgFutureBookingSettings?.fetchOrgFutureBookingSetting)
      );
    };

    if (initalLoad) return;

    if (stateIsSameAsRawData()) return;

    const debounced = debounce(() => {
      updateOrgFutureBookingSetting({
        variables: {
          data: {
            sdr_demo_to_sdr: !!dataOrgFutureBookingSettings?.sdr_demo_to_sdr,
            transfer_type: dataOrgFutureBookingSettings?.transfer_type,
          },
        },
      });
    }, SETTINGS_DEBOUNCE_INTERVAL);
    debounced();
    return debounced.cancel;
  }, [dataOrgFutureBookingSettings]);

  /*
  {
  // expected response
  "data": {
    "ae_transfer_to_ae": null,
    "ae_transfer_to_sdr": null,
    "dial_duration": null,
    "hold_music_options": null,
    "hold_music_url": null,
    "max_cycle": null,
    "sdr_transfer_to_sdr": null,
    "transfer_type": null
  }
}
  */
  const [updateOrgLiveTransferSetting] = useMutation(UPDATE_ORG_LIVE_TRANSFER_SETTING, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error updating live transfer settings");
    },
    onCompleted() {
      appToast("Settings updated successfully");
      refetchOrgLiveTransferSettings();
    },
  });

  /*
  {
  // expected response
  "data": {
    "sdr_demo_to_sdr": null,
    "transfer_type": null
  }
}
  */

  const [updateOrgFutureBookingSetting] = useMutation(UPDATE_ORG_FUTURE_BOOKING_SETTING, {
    onError({ message, name }) {
      appToast("Error updating future booking settings");
      console.log(`Error in ${name}: `, message);
    },
    onCompleted() {
      appToast("Settings updated successfully");
      refetchOrgFutureBookingSettings();
    },
  });

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchReps } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onCompleted(dataOrg) {
      setRepTransferMethods({
        manual: dataOrg?.fetchOrganization?.Reps?.length
          ? dataOrg?.fetchOrganization?.Reps?.slice()
              .filter((item: any) => item?.transfer_type === "Manual")
              ?.map((rep: any) => rep.id)
          : [],
        automatic: dataOrg?.fetchOrganization?.Reps?.slice()
          .filter((item: any) => item?.transfer_type === "Auto")
          ?.map((rep: any) => rep.id),
      });
      setBookingTransferMethods({
        manual: dataOrg?.fetchOrganization?.Reps?.length
          ? dataOrg?.fetchOrganization?.Reps?.slice()
              .filter((item: any) => item?.booking_transfer_type === "Manual")
              ?.map((rep: any) => rep.id)
          : [],
        automatic: dataOrg?.fetchOrganization?.Reps?.slice()
          .filter((item: any) => item?.booking_transfer_type === "Auto")
          ?.map((rep: any) => rep.id),
      });
      setRepExternalTransferRules({
        allowed: dataOrg?.fetchOrganization?.Reps?.length
          ? dataOrg?.fetchOrganization?.Reps?.slice()
              .filter((item: any) => item?.allow_external_transfer)
              .map((rep: any) => rep.id)
          : [],
        disabled: dataOrg?.fetchOrganization?.Reps?.slice()
          .filter((item: any) => !item?.allow_external_transfer)
          .map((rep: any) => rep.id),
      });
      setGlobalExternalTransfer(dataOrg?.fetchOrganization?.allow_external_transfer);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateUserTransferType, { data: userTransfer, loading: loadingUserTransfer }] = useMutation(
    UPDATE_USER_TRANSFER_TYPE,
    {
      fetchPolicy: "no-cache",
      onCompleted() {
        // refetch();
        appToast("Successfully updated user transfer rules");
        refetchReps();
      },
      onError({ message }) {
        appToast(`${message}`);
      },
      refetchQueries: ["fetchOrganization"],
    },
  );

  const [updateRepExternalTransfer, { data: userExternalTransfer, loading: loadingUserExternalTransfer }] = useMutation(
    UPDATE_REP_EXTERNAL_TRANSFER,
    {
      fetchPolicy: "no-cache",
      onCompleted() {
        // refetch();
        appToast("Successfully updated rep external transfer rules");
        refetchReps();
      },
      onError({ message }) {
        appToast(`${message}`);
      },
      refetchQueries: ["fetchOrganization"],
    },
  );

  const [
    updateGlobalExternalTransfer,
    { data: userGlobalExternalTransfer, loading: loadingGlobalExternalTransfer },
  ] = useMutation(UPDATE_GLOBAL_EXTERNAL_TRANSFER, {
    fetchPolicy: "no-cache",
    onCompleted() {
      // refetch();
      appToast("Successfully updated external transfer setting for organization");
      refetchReps();
    },
    onError({ message }) {
      appToast(`${message}`);
    },
    refetchQueries: ["fetchOrganization"],
  });

  const [updateBookingTransferType, { data: bookingTransfer, loading: loadingBookingTransfer }] = useMutation(
    UPDATE_BOOKING_TRANSFER_TYPE,
    {
      fetchPolicy: "no-cache",
      onCompleted() {
        // refetch();
        appToast("Successfully updated future booking transfer rules");
        refetchReps();
      },
      onError({ message }) {
        appToast(`${message}`);
      },
      refetchQueries: ["fetchOrganization"],
    },
  );

  const handleUploadMusic = async (e: any) => {
    const file = e.target.files[0];

    let formData = new FormData();

    formData.append("file", file);

    try {
      const response = await restAPI.post(`/upload/holdMusic`, formData);

      console.log("response from upload", response);

      refetchOrgLiveTransferSettings();
      appToast("File uploaded successfully");
    } catch (error) {
      console.log(error);
      appToast("Error uploading file");
    }
  };

  // debounced dial duration
  const [stateDialDuration, setStateDialDuration] = useState<number>(dataOrgLiveTransferSettings?.dial_duration);

  useDebounce(
    () => {
      if (!stateDialDuration) return;

      if (stateDialDuration < 15) {
        errorToast("Dial Duration must be greater than 15 seconds. ");
        setStateDialDuration(dataOrgLiveTransferSettings?.dial_duration || 15);
        return;
      }

      setDataOrgLiveTransferSettings({
        ...dataOrgLiveTransferSettings,
        dial_duration: typeof stateDialDuration === "string" ? parseInt(stateDialDuration) : stateDialDuration,
      });
    },
    [stateDialDuration], // Only call effect if debounced search term changes
    SETTINGS_DEBOUNCE_INTERVAL,
  );

  // debounce max cycle

  const [stateMaxCycle, setStateMaxCycle] = useState<number>(dataOrgLiveTransferSettings?.max_cycle);

  useDebounce(
    () => {
      if (!stateMaxCycle) return;

      if (stateMaxCycle < 1) {
        errorToast("Max Cycle must be greater than 0. ");
        setStateMaxCycle(dataOrgLiveTransferSettings?.max_cycle || 1);
        return;
      }

      if (stateMaxCycle > 5) {
        errorToast("Max Cycle must be less than 5. ");
        setStateMaxCycle(dataOrgLiveTransferSettings?.max_cycle || 1);
        return;
      }

      setDataOrgLiveTransferSettings({
        ...dataOrgLiveTransferSettings,
        max_cycle: typeof stateMaxCycle === "string" ? parseInt(stateMaxCycle) : stateMaxCycle,
      });
    },
    [stateMaxCycle], // Only call effect if debounced search term changes
    SETTINGS_DEBOUNCE_INTERVAL,
  );

  const initalLoad =
    Object.keys(dataOrgLiveTransferSettings).length === 0 || Object.keys(dataOrgFutureBookingSettings).length === 0;
  const loading = initalLoad && (loadingOrgLiveTransferSettings || loadingOrgFutureBookingSettings);
  const error = errorOrgLiveTransferSettings || errorOrgFutureBookingSettings;

  if (error)
    return (
      <MinHeightDiv>
        <AppErrorText>
          Something went wrong. Please refresh the page or contact support if the problem persists.
        </AppErrorText>
      </MinHeightDiv>
    );

  if (loading)
    return (
      <>
        <SkeletonBlock height={281} width={"100%"} borderRadius={4} />
        <SkeletonBlock height={724} width={"100%"} borderRadius={4} />
        <SkeletonBlock height={240} width={"100%"} borderRadius={4} />
      </>
    );

  return (
    <>
      <Container>
        <ReactTooltip
          multiline
          effect="solid"
          css={{
            maxWidth: 300,
            lineHeight: 1.4,
            textAlign: "center",
            fontFamily: theme.PRIMARY_FONT,
          }}
          backgroundColor={theme.PRIMARY800}
          getContent={(dataTip) => (
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              {dataTip}
            </span>
          )}
          id="live-transfer-type-reps-tooltip"
        />
        <ContainerHeader>
          <ContainerHeaderLeft>
            <ContainerTitle>General</ContainerTitle>
          </ContainerHeaderLeft>
          <ContainerHeaderRight />
        </ContainerHeader>
        <ContainerBody>
          <ContainerSubTitle>Hold Music</ContainerSubTitle>
          <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
            To use custom music, upload an MP3 or WAV file that is below 10mb.
          </AppText>
          <SpacerDiv />
          <ContainerBoldText>
            Would you like background music to play for Leads during call transfers?
          </ContainerBoldText>
          <RadioSection>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgLiveTransferSettings?.hold_music_options === "Default"}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    hold_music_options: "Default",
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                Sellfire default music
              </AppText>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgLiveTransferSettings?.hold_music_options === "None"}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    hold_music_options: "None",
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                None
              </AppText>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgLiveTransferSettings?.hold_music_options === "Custom"}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    hold_music_options: "Custom",
                  })
                }
                style={{
                  minWidth: "16px",
                }}
              />
              <AppText
                fontSize={12}
                fontWeight={500}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Custom music
              </AppText>

              <SpacerDiv />
            </RadioDiv>
            <RadioDiv>
              {dataOrgLiveTransferSettings?.hold_music_options === "Custom" && (
                <AppText
                  fontSize={12}
                  fontWeight={500}
                  color={theme.NEUTRAL300}
                  style={{
                    maxWidth: "100%",
                    // ellipsis
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span style={{ color: theme.BLACK_COLOR }}>Current file:</span>{" "}
                  {dataOrgLiveTransferSettings?.hold_music_url}
                </AppText>
              )}
            </RadioDiv>
            <RadioDiv>
              {dataOrgLiveTransferSettings?.hold_music_options === "Custom" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <ContainerSubTitle>Upload A New MP3 File</ContainerSubTitle>
                  <InputWrapper>
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={handleUploadMusic}
                      accept="audio/*"
                      title=" f"
                    />
                  </InputWrapper>
                </div>
              )}
            </RadioDiv>
          </RadioSection>
        </ContainerBody>
      </Container>
      <Container>
        <ContainerHeader>
          <ContainerHeaderLeft>
            <ContainerTitle>Live Transfers</ContainerTitle>
          </ContainerHeaderLeft>
          <ContainerHeaderRight />
        </ContainerHeader>
        <ContainerBody>
          <ContainerBoldText>
            When a call is being transferred, how long should Sellfire dial an AE before moving onto the next AE?
          </ContainerBoldText>
          <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
            Time must be 15 seconds minimum
          </AppText>
          <SpacerDiv />
          <AlignedRow>
            <PhoenixInput
              width={47}
              height={40}
              value={stateDialDuration}
              onChange={(e: any) => {
                setStateDialDuration(e.target.value);
              }}
              variant={dataOrgLiveTransferSettings?.dial_duration < 15 ? "error" : "primary"}
            />
            <AppText fontSize={12} fontWeight={500}>
              Seconds
            </AppText>
          </AlignedRow>
          <SpacerDiv />
          <ContainerBoldText>
            When a call is being transferred, how many times should Sellfire cycle through all eligible AEs before
            alerting the SDR that no one is available?
          </ContainerBoldText>
          <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
            Maximum number of cycles is 5
          </AppText>
          <SpacerDiv />
          <AlignedRow>
            <PhoenixInput
              value={stateMaxCycle}
              width={47}
              height={40}
              onChange={(e: any) => {
                setStateMaxCycle(e.target.value);
              }}
              variant={
                dataOrgFutureBookingSettings?.max_cycle > 5 || dataOrgFutureBookingSettings?.max_cycle < 1
                  ? "error"
                  : "primary"
              }
            />
            <AppText fontSize={12} fontWeight={500}>
              Cycles
            </AppText>
          </AlignedRow>
          <SpacerDiv />
          <ContainerBoldText>Allow AEs to transfer demos to other AEs?</ContainerBoldText>
          <RadioSection>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgLiveTransferSettings?.ae_transfer_to_ae}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    ae_transfer_to_ae: true,
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                Yes
              </AppText>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={!dataOrgLiveTransferSettings?.ae_transfer_to_ae}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    ae_transfer_to_ae: false,
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                No
              </AppText>
            </RadioDiv>
          </RadioSection>
          <SpacerDiv />
          <ContainerBoldText>Allow AEs to transfer demos to SDRs?</ContainerBoldText>
          <RadioSection>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgLiveTransferSettings?.ae_transfer_to_sdr}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    ae_transfer_to_sdr: true,
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                Yes
              </AppText>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={!dataOrgLiveTransferSettings?.ae_transfer_to_sdr}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    ae_transfer_to_sdr: false,
                  })
                }
              />

              <AppText fontSize={12} fontWeight={500}>
                No
              </AppText>
            </RadioDiv>
          </RadioSection>
          <SpacerDiv />

          <ContainerBoldText>Allow SDRs to transfer demos to SDRs?</ContainerBoldText>
          <RadioSection>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgLiveTransferSettings?.sdr_transfer_to_sdr}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    sdr_transfer_to_sdr: true,
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                Yes
              </AppText>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={!dataOrgLiveTransferSettings?.sdr_transfer_to_sdr}
                onClick={() =>
                  setDataOrgLiveTransferSettings({
                    ...dataOrgLiveTransferSettings,
                    sdr_transfer_to_sdr: false,
                  })
                }
              />

              <AppText fontSize={12} fontWeight={500}>
                No
              </AppText>
            </RadioDiv>
          </RadioSection>
          <SpacerDiv />
          {loggedInUser()?.organization?.rep_level_call_transfer ? (
            <>
              <FlexDiv>
                <ContainerBoldText>
                  What would you like the rep experience to be when live transferring an event?
                </ContainerBoldText>
                <PhoenixIcon
                  svg={info}
                  variant="neutral"
                  size={16}
                  data-for="live-transfer-type-reps-tooltip"
                  data-tip={`The lists below are mutually exclusive, collectively exhaustive.`}
                  style={{
                    marginLeft: "8px",
                    marginTop: "0px",
                  }}
                />
              </FlexDiv>

              <RepListsGrid>
                <SelectRepsContainer>
                  <AppText fontWeight={600} fontSize={14} style={{ marginBottom: "8px" }}>
                    Manual
                  </AppText>
                  <AppText fontWeight={500}>
                    Reps who manually select a transferee based on your transfer rules.
                  </AppText>
                  <RepsListContainer>
                    <SpaceBetweenDiv>
                      <SearchRepsInput
                        value={searchTermManual}
                        onChange={(e: any) => setSearchTermManual(e.target.value)}
                        placeholder="Search Reps"
                      />
                    </SpaceBetweenDiv>
                    <RepsScrollDiv>
                      {!!loadingOrg ? (
                        <Loading />
                      ) : errorOrg ? (
                        <AppErrorText>Error loading reps</AppErrorText>
                      ) : (
                        !!dataOrg?.fetchOrganization?.Reps &&
                        dataOrg?.fetchOrganization?.Reps.slice()
                          .filter((item: any) => repTransferMethods?.manual.includes(item?.id))
                          .filter(
                            (item: any) =>
                              !searchTermManual ||
                              `${item?.first_name} ${item?.last_name} (${item?.role})`
                                .toLowerCase()
                                .includes(searchTermManual.toLowerCase()),
                          )
                          .filter(
                            (item: any) =>
                              dataOrgLiveTransferSettings?.ae_transfer_to_ae ||
                              dataOrgLiveTransferSettings?.ae_transfer_to_sdr ||
                              item?.role === "SDR",
                          )
                          .sort((a: any, b: any) =>
                            `${a?.first_name} ${a?.last_name} (${a?.role})`.localeCompare(
                              `${b?.first_name} ${b?.last_name} (${b?.role})`,
                            ),
                          )
                          ?.map((item: any) => (
                            <AppCheckbox
                              title={`${item?.first_name} ${item?.last_name} (${item?.role})`}
                              checked={selectedReps?.manual.includes(item?.id)}
                              onClick={() => {
                                selectedReps?.manual.includes(item?.id)
                                  ? setSelectedReps({
                                      automatic: selectedReps?.automatic,
                                      manual: selectedReps?.manual.slice().filter((li: string) => li !== item?.id),
                                    })
                                  : setSelectedReps({
                                      automatic: selectedReps?.automatic,
                                      manual: [...selectedReps?.manual, item?.id],
                                    });
                              }}
                            />
                          ))
                      )}
                    </RepsScrollDiv>
                  </RepsListContainer>
                </SelectRepsContainer>

                <FlexColDiv>
                  <IconButton
                    style={{ marginBottom: "16px", border: `1px solid ${theme.PILL_LIGHT_GRAY}` }}
                    disabled={loadingUserTransfer}
                    type="button"
                    onClick={async () => {
                      // alert("clicked");
                      const newAutomatic = [...repTransferMethods?.automatic, ...selectedReps.manual];
                      const newManual = repTransferMethods?.manual
                        ?.map((item) => item)
                        .filter((item) => !selectedReps?.manual.includes(item));

                      await updateUserTransferType({
                        variables: {
                          manual: newManual,
                          automatic: newAutomatic,
                        },
                      });

                      setRepTransferMethods({
                        automatic: newAutomatic,
                        manual: newManual,
                      });
                      setSelectedReps({
                        manual: [],
                        automatic: selectedReps?.automatic,
                      });
                    }}
                  >
                    <PhoenixIcon pointer svg={iconButtonArrow} alt="Set Transfer Rule to Automatic" />
                  </IconButton>
                  <IconButton
                    style={{ border: `1px solid ${theme.PILL_LIGHT_GRAY}` }}
                    disabled={loadingUserTransfer}
                    type="button"
                    onClick={async () => {
                      const newAutomatic = repTransferMethods?.automatic
                        ?.map((item) => item)
                        .filter((item) => !selectedReps.automatic.includes(item));

                      const newManual = [...repTransferMethods?.manual, ...selectedReps.automatic];

                      await updateUserTransferType({
                        variables: {
                          manual: newManual,
                          automatic: newAutomatic,
                        },
                      });
                      setRepTransferMethods({
                        automatic: newAutomatic,
                        manual: newManual,
                      });
                      setSelectedReps({
                        manual: selectedReps.manual,
                        automatic: [],
                      });
                    }}
                  >
                    <PhoenixIcon
                      style={{ transform: "rotate(180deg)" }}
                      pointer
                      svg={iconButtonArrow}
                      alt="Set Transfer Rule to Manual"
                    />
                  </IconButton>
                </FlexColDiv>

                <SelectRepsContainer>
                  {/* <SpaceBetweenDiv> */}
                  <AppText fontWeight={600} fontSize={14} style={{ marginBottom: "8px" }}>
                    Automated
                  </AppText>
                  <AppText fontWeight={500}>
                    Reps for which Sellfire will automatically transfer based on your transfer rules.
                  </AppText>
                  {/* {values?.rep_ids?.length > 1 && (
                  <SpaceBetweenDiv
                    onClick={() => {
                      fetchRepOrder({
                        variables: {
                          user_ids: values.rep_ids,
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <AppText style={{ color: theme.PRIMARY500, fontWeight: 600 }}>Sellfire Suggested Rank</AppText>
                    <img style={{ marginLeft: "7px" }} src={iconRefreshSmall} alt="Auto rank" />
                  </SpaceBetweenDiv>
                )} */}
                  {/* </SpaceBetweenDiv> */}
                  <RepsListContainer>
                    <SearchRepsInput
                      value={searchTermAutomatic}
                      onChange={(e: any) => setSearchTermAutomatic(e.target.value)}
                      placeholder="Search Reps"
                    />
                    <RepsScrollDiv>
                      {!!loadingOrg ? (
                        <Loading />
                      ) : errorOrg ? (
                        <AppErrorText>Error loading reps</AppErrorText>
                      ) : (
                        !!dataOrg?.fetchOrganization?.Reps &&
                        dataOrg?.fetchOrganization?.Reps.slice()
                          .filter((item: any) => repTransferMethods?.automatic.includes(item?.id))

                          // ?.map((item) => returnRepData(item))
                          .sort((a: any, b: any) =>
                            `${a?.first_name} ${a?.last_name} (${a?.role})`.localeCompare(
                              `${b?.first_name} ${b?.last_name} (${b?.role})`,
                            ),
                          )
                          .filter(
                            (item: any) =>
                              dataOrgLiveTransferSettings?.ae_transfer_to_ae ||
                              dataOrgLiveTransferSettings?.ae_transfer_to_sdr ||
                              item?.role === "SDR",
                          )
                          .filter(
                            (item: any) =>
                              !searchTermAutomatic ||
                              `${item?.first_name} ${item?.last_name} (${item?.role})`
                                .toLowerCase()
                                .includes(searchTermAutomatic?.toLowerCase()),
                          )
                          ?.map((item: any, index: number) => (
                            <AppCheckbox
                              title={`${item?.first_name} ${item?.last_name} (${item?.role})`}
                              checked={selectedReps.automatic.includes(item?.id)}
                              onClick={() => {
                                selectedReps.automatic.includes(item?.id)
                                  ? setSelectedReps({
                                      manual: selectedReps.manual,
                                      automatic: selectedReps?.automatic
                                        .slice()
                                        .filter((li: string) => li !== item?.id),
                                    })
                                  : setSelectedReps({
                                      manual: selectedReps.manual,
                                      automatic: [...selectedReps.automatic, item?.id],
                                    });
                              }}
                            />
                          ))
                      )}
                    </RepsScrollDiv>
                  </RepsListContainer>
                </SelectRepsContainer>
                {/* <PhoenixAppButton
              buttonType="primary"
              onClick={async () => {
                await updateUserTransferType({
                  variables: {
                    manual: repTransferMethods?.manual,
                    automatic: repTransferMethods?.automatic,
                  },
                });
              }}
              disabled={loadingUserTransfer}
            >
              Save User Transfer Roles
            </PhoenixAppButton> */}
              </RepListsGrid>
              <SpacerDiv />
            </>
          ) : (
            <>
              <ContainerBoldText>
                What would you like the rep experience to be when live transferring an event?
              </ContainerBoldText>
              <RadioSection>
                <RadioDiv>
                  <PhoenixRadio
                    selected={dataOrgLiveTransferSettings?.transfer_type === "Manual"}
                    onClick={() =>
                      setDataOrgLiveTransferSettings({
                        ...dataOrgLiveTransferSettings,
                        transfer_type: "Manual",
                      })
                    }
                  />

                  <AppText fontSize={12} fontWeight={500}>
                    Allow rep to select from a list of Eligible Reps
                  </AppText>
                </RadioDiv>
                <RadioDiv>
                  <PhoenixRadio
                    selected={dataOrgLiveTransferSettings?.transfer_type === "Auto"}
                    onClick={() =>
                      setDataOrgLiveTransferSettings({
                        ...dataOrgLiveTransferSettings,
                        transfer_type: "Auto",
                      })
                    }
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Have Sellfire automatically cycle through Eligible Reps
                  </AppText>
                </RadioDiv>
              </RadioSection>
              <SpacerDiv />
            </>
          )}
        </ContainerBody>
        {/* {JSON.stringify(repTransferMethods)}
        {JSON.stringify(selectedReps)} */}
        {/* {
          dataOrg?.fetchOrganization?.Reps?.map((rep: any) => rep?.transfer_type).filter(
            (item: any) => item !== "Manual",
          ).length
        } */}
        <ContainerHeader>
          <ContainerHeaderLeft>
            <ContainerTitle>Future Bookings</ContainerTitle>
          </ContainerHeaderLeft>
          <ContainerHeaderRight />
        </ContainerHeader>
        <ContainerBody>
          <FlexDiv>
            <ContainerBoldText>What would you like the SDR experience to be when booking a demo?</ContainerBoldText>
            <PhoenixIcon
              svg={info}
              variant="neutral"
              size={16}
              data-for="live-transfer-type-reps-tooltip"
              data-tip={`The lists below are mutually exclusive, collectively exhaustive.`}
              style={{
                marginLeft: "8px",
                marginTop: "0px",
              }}
            />
          </FlexDiv>
          <SpacerDiv />
          {loggedInUser()?.organization?.rep_level_call_transfer ? (
            <>
              <RepListsGrid>
                <SelectRepsContainer>
                  <AppText fontWeight={600} fontSize={14} style={{ marginBottom: "8px" }}>
                    Manual
                  </AppText>
                  <AppText fontWeight={500}>
                    Reps who manually select a transferee based on your transfer rules.
                  </AppText>
                  <RepsListContainer>
                    <SpaceBetweenDiv>
                      <SearchRepsInput
                        value={searchTermBookingManual}
                        onChange={(e: any) => setSearchTermBookingManual(e.target.value)}
                        placeholder="Search Reps"
                      />
                    </SpaceBetweenDiv>
                    <RepsScrollDiv>
                      {!!loadingOrg ? (
                        <Loading />
                      ) : errorOrg ? (
                        <AppErrorText>Error loading reps</AppErrorText>
                      ) : (
                        !!dataOrg?.fetchOrganization?.Reps &&
                        dataOrg?.fetchOrganization?.Reps.slice()
                          .filter((item: any) => item?.role === "SDR") // only SDRs
                          .filter((item: any) => bookingTransferMethods?.manual.includes(item?.id))
                          .filter(
                            (item: any) =>
                              !searchTermBookingManual ||
                              `${item?.first_name} ${item?.last_name} (${item?.role})`
                                .toLowerCase()
                                .includes(searchTermBookingManual.toLowerCase()),
                          )

                          .sort((a: any, b: any) =>
                            `${a?.first_name} ${a?.last_name} (${a?.role})`.localeCompare(
                              `${b?.first_name} ${b?.last_name} (${b?.role})`,
                            ),
                          )
                          ?.map((item: any) => (
                            <AppCheckbox
                              title={`${item?.first_name} ${item?.last_name} (${item?.role})`}
                              checked={selectedBookingReps?.manual.includes(item?.id)}
                              onClick={() => {
                                selectedBookingReps?.manual.includes(item.id)
                                  ? setSelectedBookingReps({
                                      automatic: selectedBookingReps?.automatic,
                                      manual: selectedBookingReps?.manual
                                        .slice()
                                        .filter((li: string) => li !== item?.id),
                                    })
                                  : setSelectedBookingReps({
                                      automatic: selectedBookingReps?.automatic,
                                      manual: [...selectedBookingReps?.manual, item?.id],
                                    });
                              }}
                            />
                          ))
                      )}
                    </RepsScrollDiv>
                  </RepsListContainer>
                </SelectRepsContainer>

                <FlexColDiv>
                  <IconButton
                    style={{ marginBottom: "16px", border: `1px solid ${theme.PILL_LIGHT_GRAY}` }}
                    disabled={loadingUserTransfer}
                    type="button"
                    onClick={async () => {
                      // alert("clicked");
                      const newAutomatic = [...bookingTransferMethods?.automatic, ...selectedBookingReps.manual];
                      const newManual = bookingTransferMethods?.manual
                        ?.map((item) => item)
                        .filter((item) => !selectedBookingReps?.manual.includes(item));

                      await updateBookingTransferType({
                        variables: {
                          manual: newManual,
                          automatic: newAutomatic,
                        },
                      });

                      setBookingTransferMethods({
                        automatic: newAutomatic,
                        manual: newManual,
                      });
                      setSelectedBookingReps({
                        manual: [],
                        automatic: selectedBookingReps?.automatic,
                      });
                    }}
                  >
                    <PhoenixIcon svg={iconButtonArrow} pointer alt="Set Transfer Rule to Automatic" />
                  </IconButton>
                  <IconButton
                    style={{ border: `1px solid ${theme.PILL_LIGHT_GRAY}` }}
                    disabled={loadingBookingTransfer}
                    type="button"
                    onClick={async () => {
                      const newAutomatic = bookingTransferMethods?.automatic
                        ?.map((item) => item)
                        .filter((item) => !selectedBookingReps.automatic.includes(item));

                      const newManual = [...bookingTransferMethods?.manual, ...selectedBookingReps.automatic];

                      await updateBookingTransferType({
                        variables: {
                          manual: newManual,
                          automatic: newAutomatic,
                        },
                      });
                      setBookingTransferMethods({
                        automatic: newAutomatic,
                        manual: newManual,
                      });
                      setSelectedBookingReps({
                        manual: selectedBookingReps.manual,
                        automatic: [],
                      });
                    }}
                  >
                    <PhoenixIcon
                      style={{ transform: "rotate(180deg)" }}
                      svg={iconButtonArrow}
                      pointer
                      alt="Set Transfer Rule to Manual"
                    />
                  </IconButton>
                </FlexColDiv>

                <SelectRepsContainer>
                  {/* <SpaceBetweenDiv> */}
                  <AppText fontWeight={600} fontSize={14} style={{ marginBottom: "8px" }}>
                    Automated
                  </AppText>
                  <AppText fontWeight={500}>
                    Reps for which Sellfire will automatically transfer based on your transfer rules.
                  </AppText>
                  {/* {values?.rep_ids?.length > 1 && (
                  <SpaceBetweenDiv
                    onClick={() => {
                      fetchRepOrder({
                        variables: {
                          user_ids: values.rep_ids,
                        },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <AppText style={{ color: theme.PRIMARY500, fontWeight: 600 }}>Sellfire Suggested Rank</AppText>
                    <img style={{ marginLeft: "7px" }} src={iconRefreshSmall} alt="Auto rank" />
                  </SpaceBetweenDiv>
                )} */}
                  {/* </SpaceBetweenDiv> */}
                  <RepsListContainer>
                    <SearchRepsInput
                      value={searchTermBookingAutomatic}
                      onChange={(e: any) => setSearchTermBookingAutomatic(e.target.value)}
                      placeholder="Search Reps"
                    />
                    <RepsScrollDiv>
                      {!!loadingOrg ? (
                        <Loading />
                      ) : errorOrg ? (
                        <AppErrorText>Error loading reps</AppErrorText>
                      ) : (
                        !!dataOrg?.fetchOrganization?.Reps &&
                        dataOrg?.fetchOrganization?.Reps.slice()
                          .filter((item: any) => item?.role === "SDR") // only SDRs
                          .filter((item: any) => bookingTransferMethods?.automatic.includes(item?.id))

                          // ?.map((item) => returnRepData(item))
                          .sort((a: any, b: any) =>
                            `${a?.first_name} ${a?.last_name} (${a?.role})`.localeCompare(
                              `${b?.first_name} ${b?.last_name} (${b?.role})`,
                            ),
                          )
                          .filter(
                            (item: any) =>
                              !searchTermBookingAutomatic ||
                              `${item?.first_name} ${item?.last_name} (${item?.role})`
                                .toLowerCase()
                                .includes(searchTermBookingAutomatic?.toLowerCase()),
                          )
                          ?.map((item: any, index: number) => (
                            <AppCheckbox
                              title={`${item?.first_name} ${item?.last_name} (${item?.role})`}
                              checked={selectedBookingReps.automatic.includes(item?.id)}
                              onClick={() => {
                                selectedBookingReps.automatic.includes(item?.id)
                                  ? setSelectedBookingReps({
                                      manual: selectedBookingReps.manual,
                                      automatic: selectedBookingReps?.automatic
                                        .slice()
                                        .filter((li: string) => li !== item?.id),
                                    })
                                  : setSelectedBookingReps({
                                      manual: selectedBookingReps.manual,
                                      automatic: [...selectedBookingReps.automatic, item?.id],
                                    });
                              }}
                            />
                          ))
                      )}
                    </RepsScrollDiv>
                  </RepsListContainer>
                </SelectRepsContainer>
                {/* <PhoenixAppButton
              buttonType="primary"
              onClick={async () => {
                await updateUserTransferType({
                  variables: {
                    manual: repTransferMethods?.manual,
                    automatic: repTransferMethods?.automatic,
                  },
                });
              }}
              disabled={loadingUserTransfer}
            >
              Save User Transfer Roles
            </PhoenixAppButton> */}
              </RepListsGrid>
              <SpacerDiv />
            </>
          ) : (
            <>
              <RadioSection>
                <RadioDiv>
                  <PhoenixRadio
                    selected={dataOrgFutureBookingSettings?.transfer_type === "Manual"}
                    onClick={() =>
                      setDataOrgFutureBookingSettings({
                        ...dataOrgFutureBookingSettings,
                        transfer_type: "Manual",
                      })
                    }
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Allow SDR to select from a list of Eligible Reps
                  </AppText>
                </RadioDiv>
                <RadioDiv>
                  <PhoenixRadio
                    selected={dataOrgFutureBookingSettings?.transfer_type === "Auto"}
                    onClick={() =>
                      setDataOrgFutureBookingSettings({
                        ...dataOrgFutureBookingSettings,
                        transfer_type: "Auto",
                      })
                    }
                  />
                  <AppText fontSize={12} fontWeight={500}>
                    Have Sellfire automatically cycle through Eligible Reps
                  </AppText>
                </RadioDiv>
              </RadioSection>
              <SpacerDiv />
            </>
          )}

          <ContainerBoldText>Allow SDRs to book demos with SDRs?</ContainerBoldText>
          <RadioSection>
            <RadioDiv>
              <PhoenixRadio
                selected={dataOrgFutureBookingSettings?.sdr_demo_to_sdr}
                onClick={() =>
                  setDataOrgFutureBookingSettings({
                    ...dataOrgFutureBookingSettings,
                    sdr_demo_to_sdr: true,
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                Yes
              </AppText>
            </RadioDiv>
            <RadioDiv>
              <PhoenixRadio
                selected={!dataOrgFutureBookingSettings?.sdr_demo_to_sdr}
                onClick={() =>
                  setDataOrgFutureBookingSettings({
                    ...dataOrgFutureBookingSettings,
                    sdr_demo_to_sdr: false,
                  })
                }
              />

              <AppText fontSize={12} fontWeight={500}>
                No
              </AppText>
            </RadioDiv>
          </RadioSection>
          <SpacerDiv />
        </ContainerBody>
      </Container>
      {allowExternalTransfers && (
        <Container>
          <ContainerHeader>
            <ContainerHeaderLeft>
              <ContainerTitle>External Transfers</ContainerTitle>
            </ContainerHeaderLeft>
            <ContainerHeaderRight />
          </ContainerHeader>
          <ContainerBody>
            <ContainerBoldText>Allow reps to transfer calls to external numbers?</ContainerBoldText>
            {/* <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
            Time must be 15 seconds minimum
          </AppText> */}
            <SpacerDiv />
            <FlexDiv gap={8} align="center" style={{ marginBottom: 40 }}>
              <Switch
                name="allow_external_transfer"
                onChange={async (checked) => {
                  setGlobalExternalTransfer(checked);
                  await updateGlobalExternalTransfer({
                    variables: {
                      allow_global_external_transfer: checked,
                    },
                  });
                }}
                onColor={theme.PRIMARY500}
                checked={globalExternalTransfer}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <AppText fontSize={12} fontWeight={500}>
                Allow External Transfers
              </AppText>
            </FlexDiv>
            {globalExternalTransfer && (
              <>
                <RepListsGrid>
                  <SelectRepsContainer>
                    <AppText fontWeight={600} fontSize={14} style={{ marginBottom: "8px" }}>
                      Reps not allowed to make external transfers
                    </AppText>
                    {/* <AppText fontWeight={500}>Reps who cannot transfer external</AppText> */}
                    <RepsListContainer>
                      <SpaceBetweenDiv>
                        <SearchRepsInput
                          value={searchTermExternalDisable}
                          onChange={(e: any) => setSearchTermExternalDisable(e.target.value)}
                          placeholder="Search Reps"
                        />
                      </SpaceBetweenDiv>
                      <RepsScrollDiv>
                        {!!loadingOrg ? (
                          <Loading />
                        ) : errorOrg ? (
                          <AppErrorText>Error loading reps</AppErrorText>
                        ) : (
                          !!dataOrg?.fetchOrganization?.Reps &&
                          dataOrg?.fetchOrganization?.Reps.slice()
                            .filter((item: any) => repExternalTransferRules?.disabled.includes(item?.id))
                            .filter(
                              (item: any) =>
                                !searchTermExternalDisable ||
                                `${item?.first_name} ${item?.last_name} (${item?.role})`
                                  .toLowerCase()
                                  .includes(searchTermExternalDisable.toLowerCase()),
                            )

                            .sort((a: any, b: any) =>
                              `${a?.first_name} ${a?.last_name} (${a?.role})`.localeCompare(
                                `${b?.first_name} ${b?.last_name} (${b?.role})`,
                              ),
                            )
                            .map((item: any) => (
                              <AppCheckbox
                                title={`${item?.first_name} ${item?.last_name} (${item?.role})`}
                                checked={selectedRepsExternalTransfer?.disabled.includes(item?.id)}
                                onClick={() => {
                                  selectedRepsExternalTransfer?.disabled.includes(item.id)
                                    ? setSelectedRepsExternalTransfer({
                                        allowed: selectedRepsExternalTransfer?.allowed,
                                        disabled: selectedRepsExternalTransfer?.disabled
                                          .slice()
                                          .filter((li: string) => li !== item?.id),
                                      })
                                    : setSelectedRepsExternalTransfer({
                                        allowed: selectedRepsExternalTransfer?.allowed,
                                        disabled: [...selectedRepsExternalTransfer?.disabled, item?.id],
                                      });
                                }}
                              />
                            ))
                        )}
                      </RepsScrollDiv>
                    </RepsListContainer>
                  </SelectRepsContainer>

                  <FlexColDiv>
                    <IconButton
                      style={{ marginBottom: "16px", border: `1px solid ${theme.PILL_LIGHT_GRAY}` }}
                      disabled={loadingUserTransfer}
                      type="button"
                      onClick={async () => {
                        // alert("clicked");
                        const newAutomatic = [
                          ...repExternalTransferRules?.allowed,
                          ...selectedRepsExternalTransfer.disabled,
                        ];
                        const newManual = repExternalTransferRules?.disabled
                          .map((item) => item)
                          .filter((item) => !selectedRepsExternalTransfer?.disabled.includes(item));

                        await updateRepExternalTransfer({
                          variables: {
                            disabled: newManual,
                            allowed: newAutomatic,
                          },
                        });

                        setRepExternalTransferRules({
                          allowed: newAutomatic,
                          disabled: newManual,
                        });
                        setSelectedRepsExternalTransfer({
                          disabled: [],
                          allowed: selectedRepsExternalTransfer?.allowed,
                        });
                      }}
                    >
                      <PhoenixIcon svg={iconButtonArrow} pointer alt="Set to allow External Transfer" />
                    </IconButton>
                    <IconButton
                      style={{ border: `1px solid ${theme.PILL_LIGHT_GRAY}` }}
                      disabled={loadingBookingTransfer}
                      type="button"
                      onClick={async () => {
                        const newAllowed = repExternalTransferRules?.allowed
                          .map((item) => item)
                          .filter((item) => !selectedRepsExternalTransfer.allowed.includes(item));

                        const newDisabled = [
                          ...repExternalTransferRules?.disabled,
                          ...selectedRepsExternalTransfer.allowed,
                        ];

                        await updateRepExternalTransfer({
                          variables: {
                            disabled: newDisabled,
                            allowed: newAllowed,
                          },
                        });
                        setRepExternalTransferRules({
                          allowed: newAllowed,
                          disabled: newDisabled,
                        });
                        setSelectedRepsExternalTransfer({
                          disabled: selectedRepsExternalTransfer.disabled,
                          allowed: [],
                        });
                      }}
                    >
                      <PhoenixIcon
                        style={{ transform: "rotate(180deg)" }}
                        svg={iconButtonArrow}
                        pointer
                        alt="Set to disable ExternalTransfer"
                      />
                    </IconButton>
                  </FlexColDiv>

                  <SelectRepsContainer>
                    {/* <SpaceBetweenDiv> */}
                    <AppText fontWeight={600} fontSize={14} style={{ marginBottom: "8px" }}>
                      Reps allowed to make external transfers
                    </AppText>
                    {/* <AppText fontWeight={500}>Reps allowed to make external transfers</AppText> */}

                    <RepsListContainer>
                      <SearchRepsInput
                        value={searchTermExternalAllow}
                        onChange={(e: any) => setSearchTermExternalAllow(e.target.value)}
                        placeholder="Search Reps"
                      />
                      <RepsScrollDiv>
                        {!!loadingOrg ? (
                          <Loading />
                        ) : errorOrg ? (
                          <AppErrorText>Error loading reps</AppErrorText>
                        ) : (
                          !!dataOrg?.fetchOrganization?.Reps &&
                          dataOrg?.fetchOrganization?.Reps.slice()
                            .filter((item: any) => repExternalTransferRules?.allowed.includes(item?.id))

                            // .map((item) => returnRepData(item))
                            .sort((a: any, b: any) =>
                              `${a?.first_name} ${a?.last_name} (${a?.role})`.localeCompare(
                                `${b?.first_name} ${b?.last_name} (${b?.role})`,
                              ),
                            )
                            .filter(
                              (item: any) =>
                                !searchTermExternalAllow ||
                                `${item?.first_name} ${item?.last_name} (${item?.role})`
                                  .toLowerCase()
                                  .includes(searchTermExternalAllow?.toLowerCase()),
                            )
                            .map((item: any, index: number) => (
                              <AppCheckbox
                                title={`${item?.first_name} ${item?.last_name} (${item?.role})`}
                                checked={selectedRepsExternalTransfer.allowed.includes(item?.id)}
                                onClick={() => {
                                  selectedRepsExternalTransfer.allowed.includes(item?.id)
                                    ? setSelectedRepsExternalTransfer({
                                        disabled: selectedRepsExternalTransfer.disabled,
                                        allowed: selectedRepsExternalTransfer?.allowed
                                          .slice()
                                          .filter((li: string) => li !== item?.id),
                                      })
                                    : setSelectedRepsExternalTransfer({
                                        disabled: selectedRepsExternalTransfer.disabled,
                                        allowed: [...selectedRepsExternalTransfer.allowed, item?.id],
                                      });
                                }}
                              />
                            ))
                        )}
                      </RepsScrollDiv>
                    </RepsListContainer>
                  </SelectRepsContainer>
                  {/* <PhoenixAppButton
                  buttonType="primary"
                  onClick={async () => {
                    await updateUserTransferType({
                      variables: {
                        manual: repTransferMethods?.manual,
                        automatic: repTransferMethods?.automatic,
                      },
                    });
                  }}
                  disabled={loadingUserTransfer}
                >
                  Save User Transfer Roles
                </PhoenixAppButton> */}
                </RepListsGrid>
                <SpacerDiv />
              </>
            )}
            <AlignedRow></AlignedRow>
          </ContainerBody>
        </Container>
      )}
    </>
  );
};

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 330px 40px 330px;
  grid-gap: 16px;
  margin-bottom: 32px;
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 48px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 99;
`;

interface SelectedProp {
  selected: boolean;
}

const Option = styled.div<SelectedProp>`
  display: flex;
  font-weight: ${(props) => (props.selected ? 600 : "normal")};
  align-items: center;
  height: 40px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const IconButton = styled(NewAppButton)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerDivSort = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RepsScrollDiv = styled.div`
  max-height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchRepsInput = styled(AppInput)`
  background-color: ${theme.NEUTRAL100};
  margin: 0px;
  /* margin-top: 16px; */
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

const RepsListContainer = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  height: 240px;
  overflow: hidden;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 12px;
  border-radius: 2px;
`;

const SelectRepsContainer = styled.div`
  width: 330px;
`;

const ContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ContainerSubTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const AlignedRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

const ContainerBoldText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.WHITE_COLOR};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  margin-bottom: 16px;
`;

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
`;

const ContainerHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ContainerHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;
const ContainerBody = styled.div``;

const MinHeightDiv = styled.div`
  min-width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div``;

export default TransferRulesSettings;
