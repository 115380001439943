import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { reorder, more_vertical, trash } from "src/images/NewDesign";
import { ModalContext } from "src/context";
import { DraggableFieldType } from "src/utils/variables";
import { SaleConfigSection } from "src/utils/misc";
import { useFirstRender } from "src/utils/hooks";
import { ShowMoreMenu } from "./ShowMoreMenu";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";

export interface IDraggableFieldBase {
  type: DraggableFieldType;
  index: number;
  hideMenu?: boolean;
  id: string;
  systemFields?: SaleConfigSection[];
  setSelectedFieldID: Dispatch<SetStateAction<string>>;
  hideDelete?: boolean;
}

interface IDraggableFieldHeader {
  fieldType: DraggableFieldType;
}

export const DraggableFieldBase: React.FC<IDraggableFieldBase> = ({
  children,
  type,
  index,
  hideMenu,
  id,
  systemFields,
  setSelectedFieldID,
  hideDelete,
}) => {
  const { setShowDeleteFieldModal } = useContext(ModalContext);

  const [showMoreMenu, setShowMoreMenu] = useState<boolean>(false);

  const required = useMemo(() => type === DraggableFieldType.REQUIRED_FIELD, [type]);
  const note = useMemo(() => type === DraggableFieldType.NOTE, [type]);
  const header = useMemo(() => type === DraggableFieldType.HEADER, [type]);
  const pandaDoc = useMemo(() => type === DraggableFieldType.PANDADOC, [type]);

  const isFirstRender = useFirstRender();

  return (
    <Draggable draggableId={`field-${id}`} index={index}>
      {(provided) => (
        <DraggableFieldContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={isFirstRender ? "animate-on-mount" : ""}
        >
          {hideDelete && <PhoenixStyledTooltip id={`field-tooltip-${id}`} />}

          <DraggableFieldHeader
            fieldType={type}
            style={{ borderBottom: pandaDoc ? "none" : `1px solid ${theme.NEUTRAL200}` }}
          >
            <FlexDiv gap={8} align="center">
              <div ref={provided.innerRef} {...provided.dragHandleProps}>
                {!pandaDoc && <PhoenixIcon svg={reorder} size={16} variant="neutral" style={{ cursor: "grab" }} />}
              </div>
              <AppText
                fontSize={required ? 10 : header ? 12 : 14}
                fontWeight={required || note || header || pandaDoc ? 500 : 600}
                lineHeight={required ? 14 : 20}
                color={
                  required ? theme.PILL_DARK_RED : note || header || pandaDoc ? theme.BLACK_COLOR : theme.PRIMARY500
                }
              >
                {required
                  ? "Required Field"
                  : note
                  ? "Note"
                  : header
                  ? "Section Header"
                  : pandaDoc
                  ? "PandaDoc Integration Page"
                  : (systemFields?.findIndex((f) => f.id === id) || 0) + 1}
              </AppText>
            </FlexDiv>

            <FlexDiv gap={8} align="center">
              {!required && !pandaDoc && (
                <PhoenixIcon
                  svg={trash}
                  size={16}
                  variant={!hideDelete ? "danger" : "neutral"}
                  pointer={!hideDelete}
                  onClick={() => {
                    if (hideDelete) return;
                    setShowDeleteFieldModal(true);
                    setSelectedFieldID(id);
                  }}
                  data-tip={
                    hideDelete && "A minimum of 1 field is required. Please add another field before deleting this one."
                  }
                  data-for={`field-tooltip-${id}`}
                />
              )}
              {!hideMenu && !pandaDoc && (
                <PhoenixIcon
                  svg={more_vertical}
                  size={16}
                  pointer
                  onClick={() => {
                    setShowMoreMenu(!showMoreMenu);
                    setSelectedFieldID(id);
                  }}
                />
              )}
            </FlexDiv>
            {showMoreMenu && <ShowMoreMenu setShowMoreMenu={setShowMoreMenu} />}
          </DraggableFieldHeader>

          {!pandaDoc && <DraggableFieldBody>{children}</DraggableFieldBody>}
        </DraggableFieldContainer>
      )}
    </Draggable>
  );
};

const DraggableFieldContainer = styled.div`
  width: 424px;

  margin-bottom: 24px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  overflow: hidden;

  &.animate-on-mount {
    animation: ${theme.textFadeIn} 0.3s ease-in-out;
  }
`;

const DraggableFieldHeader = styled.div<IDraggableFieldHeader>`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 32px;
  padding: 8px;

  background-color: ${({ fieldType }) =>
    fieldType === DraggableFieldType.REQUIRED_FIELD
      ? theme.DANGER50
      : fieldType === DraggableFieldType.NOTE
      ? theme.NEUTRAL100
      : theme.PRIMARY50};
`;

const DraggableFieldBody = styled.div`
  padding: 24px;
  background-color: ${theme.WHITE_COLOR};
`;
