import React, { useContext, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { CallContext } from "../../../../context";
import { clock, resting, user_remove, user_white, xIcon } from "../../../../images/NewDesign";
import { CurrentLeadType } from "../../../../types";
import { formatSequenceAction } from "../../../../utils/format";
import { useClickOutside } from "../../../../utils/hooks";
import { theme } from "../../../../utils/theme";
import { AppText, FlexDiv } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";

// same functionality as skip lead

const SnoozeMenu = ({
  setShowSnoozeMenu,
  scheduledEventBehavior,
  leadData,
  intentData,
  customTaskAck,
  leadIsNextDialOverride,
  resetNextDial,
}: {
  setShowSnoozeMenu: (showSnoozeMenu: boolean) => void;
  scheduledEventBehavior: string;
  leadData: CurrentLeadType;
  intentData: {
    id: string;
  };
  customTaskAck: (args: any) => void;
  leadIsNextDialOverride: boolean;
  resetNextDial: () => void;
}) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShowSnoozeMenu(false));

  const showScheduleEventOption = useMemo(
    () =>
      scheduledEventBehavior === "Neutral" &&
      leadData?.next_lead_intent?.schedule_item &&
      !leadData?.next_lead_intent?.schedule_item?.deleted_at,
    [scheduledEventBehavior, leadData],
  );

  return (
    <OptionsDiv ref={containerRef} style={{ top: "72px", right: "16px", width: "200px", zIndex: 10 }}>
      <Option
        width={showScheduleEventOption ? "224px" : undefined}
        onClick={(e) => {
          e?.stopPropagation();
          customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: intentData?.id,
                nextstep: false,
                remove: false,
                snooze: true,
                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                delay_days: 0,
                delay_hours: 0,
                delay_minutes: 30,
              },
            },
          });
          setShowSnoozeMenu(false);

          if (leadIsNextDialOverride) {
            resetNextDial && resetNextDial();
          }
        }}
      >
        <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
        <AppText fontSize={12} style={{ textTransform: "none" }}>
          30 Minutes
        </AppText>
      </Option>
      <Option
        width={showScheduleEventOption ? "224px" : undefined}
        onClick={(e) => {
          e?.stopPropagation();
          customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: intentData?.id,
                nextstep: false,
                remove: false,
                snooze: true,
                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                delay_days: 0,
                delay_hours: 4,
                delay_minutes: 0,
              },
            },
          });
          setShowSnoozeMenu(false);

          if (leadIsNextDialOverride) {
            resetNextDial && resetNextDial();
          }
        }}
      >
        <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
        <AppText fontSize={12} style={{ textTransform: "none" }}>
          4 Hours
        </AppText>
      </Option>
      <Option
        width={showScheduleEventOption ? "224px" : undefined}
        onClick={(e) => {
          e?.stopPropagation();
          customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: intentData?.id,
                nextstep: false,
                remove: false,
                snooze: true,
                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                delay_days: 1,
                delay_hours: 0,
                delay_minutes: 0,
              },
            },
          });
          setShowSnoozeMenu(false);

          if (leadIsNextDialOverride) {
            resetNextDial && resetNextDial();
          }
        }}
      >
        <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
        <AppText fontSize={12} style={{ textTransform: "none" }}>
          1 Day
        </AppText>
      </Option>
      {showScheduleEventOption && (
        <Option
          width="224px"
          onClick={(e) => {
            e?.stopPropagation();
            customTaskAck({
              variables: {
                customTaskAckInput: {
                  action_completed: false,
                  lead_intent_id: intentData?.id,
                  nextstep: false,
                  remove: false,
                  snooze: true,
                  step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                  delay_until_scheduled_event: true,
                },
              },
            });
            setShowSnoozeMenu(false);

            if (leadIsNextDialOverride) {
              resetNextDial && resetNextDial();
            }
          }}
        >
          <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
          <AppText fontSize={12} style={{ textTransform: "none" }}>
            Until After Scheduled Event
          </AppText>
        </Option>
      )}
    </OptionsDiv>
  );
};

const RemoveMenu = ({
  setShowRemoveMenu,
  customTaskAck,
  leadData,
  intentData,
  leadIsNextDialOverride,
  resetNextDial,
}: {
  setShowRemoveMenu: (showRemoveMenu: boolean) => void;
  customTaskAck: any;
  leadData: CurrentLeadType;
  intentData: any;
  leadIsNextDialOverride: boolean;
  resetNextDial: () => void;
}) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShowRemoveMenu(false));

  return (
    <OptionsDiv
      ref={containerRef}
      style={{ top: "72px", right: "16px", width: "200px", zIndex: 10 }}
      onClick={(e) => {
        e.stopPropagation();
        setShowRemoveMenu(false);
      }}
    >
      <Option
        style={{ width: "100%" }}
        onClick={() => {
          customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: intentData?.id,
                nextstep: false,
                remove: true,
                snooze: false,
                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                reason: "Task is Outdated",
              },
            },
          });

          if (leadIsNextDialOverride) {
            resetNextDial && resetNextDial();
          }
        }}
      >
        <AppText fontSize={12}>Task is Outdated</AppText>
      </Option>
      <Option
        style={{ width: "100%" }}
        onClick={() => {
          customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: intentData?.id,
                nextstep: false,
                remove: true,
                snooze: false,
                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                reason: "Lead No Longer Interested",
              },
            },
          });
          if (leadIsNextDialOverride) {
            resetNextDial && resetNextDial();
          }
        }}
      >
        <AppText fontSize={12}>Lead No Longer Interested</AppText>
      </Option>
      <Option
        style={{ width: "100%" }}
        onClick={() => {
          customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: intentData?.id,
                nextstep: false,
                remove: true,
                snooze: false,
                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                reason: "Other",
              },
            },
          });

          if (leadIsNextDialOverride) {
            resetNextDial && resetNextDial();
          }
        }}
      >
        <AppText fontSize={12}>Other</AppText>
      </Option>
    </OptionsDiv>
  );
};
const SnoozeLeadButton = ({
  handleSnoozeLead,
  showSnoozeMenu,
  customTaskAck,
  leadData,
  intentData,
  scheduledEventBehavior,
  loadingCompleteTask,
  setShowSnoozeMenu,
  resetNextDial,
  leadIsNextDialOverride,
}: {
  handleSnoozeLead: () => void;
  showSnoozeMenu: boolean;
  customTaskAck: (args: any) => void;
  leadData: CurrentLeadType;
  intentData: any;
  scheduledEventBehavior: any;
  loadingCompleteTask: boolean;
  setShowSnoozeMenu: (showSnoozeMenu: boolean) => void;
  resetNextDial: () => void;
  leadIsNextDialOverride: boolean;
}) => {
  return (
    <>
      <PhoenixAppButton
        variant="brand"
        buttonType="ghost-small"
        onClick={handleSnoozeLead}
        disabled={!!loadingCompleteTask}
      >
        <PhoenixIcon svg={resting} color={theme.PRIMARY500} size={12} pointer />
        Snooze
      </PhoenixAppButton>
      {showSnoozeMenu && (
        <SnoozeMenu
          setShowSnoozeMenu={setShowSnoozeMenu}
          customTaskAck={customTaskAck}
          leadData={leadData}
          intentData={intentData}
          scheduledEventBehavior={scheduledEventBehavior}
          leadIsNextDialOverride={leadIsNextDialOverride}
          resetNextDial={resetNextDial}
        />
      )}
    </>
  );
};

const ReleaseLeadButton = ({
  handleReleaseLead,
  leadIsNextDialOverride,
  resetNextDial,
}: {
  handleReleaseLead: () => void;
  leadIsNextDialOverride: boolean;
  resetNextDial: () => void;
}) => {
  return (
    <PhoenixAppButton
      variant="brand"
      buttonType="ghost-small"
      onClick={() => {
        handleReleaseLead();
        // is the lead set as override through the make next action button? if so, we need to remove the override
        if (leadIsNextDialOverride) {
          resetNextDial();
        }
      }}
    >
      <PhoenixIcon svg={user_remove} color={theme.PRIMARY500} size={12} pointer />
      Release Lead
    </PhoenixAppButton>
  );
};

// conditional remove from custom queue based on parentType
const RemoveFromCustomQueueButton = ({
  handleRemoveFromCustomQueue,
  resetNextDial,
  leadIsNextDialOverride,
}: {
  handleRemoveFromCustomQueue: () => void;
  resetNextDial: () => void;
  leadIsNextDialOverride: boolean;
}) => {
  return (
    <PhoenixAppButton
      variant="brand"
      buttonType="ghost-small"
      onClick={() => {
        handleRemoveFromCustomQueue();
        // is the lead set as override through the make next action button? if so, we need to remove the override
        if (leadIsNextDialOverride) {
          resetNextDial();
        }
      }}
    >
      <PhoenixIcon svg={user_white} color={theme.PRIMARY500} size={12} pointer />
      Remove from Custom Queue
    </PhoenixAppButton>
  );
};

const RemoveFromCustomSequenceButton = ({
  handleRemoveFromCustomSequence,
  showRemoveMenu,
  setShowRemoveMenu,
  customTaskAck,
  leadData,
  intentData,
  resetNextDial,
  leadIsNextDialOverride,
}: {
  handleRemoveFromCustomSequence: () => void;
  showRemoveMenu: boolean;
  setShowRemoveMenu: (showRemoveMenu: boolean) => void;
  customTaskAck: any;
  leadData: any;
  intentData: any;
  resetNextDial: () => void;
  leadIsNextDialOverride: boolean;
}) => {
  return (
    <>
      <PhoenixAppButton variant="danger-outline" buttonType="ghost-small" onClick={handleRemoveFromCustomSequence}>
        <PhoenixIcon
          svg={xIcon}
          color={theme.icon.danger.default}
          hoverColor={theme.icon.danger.default}
          size={12}
          pointer
        />
        Remove Task
      </PhoenixAppButton>
      {showRemoveMenu && (
        <RemoveMenu
          setShowRemoveMenu={setShowRemoveMenu}
          customTaskAck={customTaskAck}
          leadData={leadData}
          intentData={intentData}
          resetNextDial={resetNextDial}
          leadIsNextDialOverride={leadIsNextDialOverride}
        />
      )}
    </>
  );
};

const CustomSequenceActionHeader = ({
  showDefaultReleaseButton,
  showDefaultRemoveFromCustomQueueButton,
  showCustomRemoveStepActionButton,
  showCustomSnoozeStepActionButton,

  handleReleaseLead,
  handleRemoveFromCustomQueue,

  // for use in snooze menu
  customTaskAck,
  leadData,
  intentData,
  scheduledEventBehavior,

  // styling
  inQueue,
}: {
  showDefaultReleaseButton: boolean;
  showDefaultRemoveFromCustomQueueButton: boolean;
  showDefaultRemoveFromNextDialButton: boolean;
  showCustomRemoveStepActionButton: boolean;
  showCustomSnoozeStepActionButton: boolean;

  handleReleaseLead: () => void;
  handleRemoveFromCustomQueue: () => void;
  handleRemoveFromNextDial?: () => void;

  // for use in snooze menu
  customTaskAck: any;
  leadData: any;
  intentData: any;
  scheduledEventBehavior: any;

  // styling
  inQueue: boolean;
}) => {
  const expectedAction = leadData?.sequence_step?.actions?.[0]?.task || "manualCall";

  const { nextActionOverride, resetNextDial } = useContext(CallContext);

  const leadIsNextDialOverride = useMemo(() => {
    return nextActionOverride?.intent_object?.lead?.id === leadData?.id;
  }, [nextActionOverride, leadData]);

  const [showSnoozeMenu, setShowSnoozeMenu] = useState<boolean>(false);

  const [showRemoveMenu, setShowRemoveMenu] = useState<boolean>(false);

  const handleSnoozeLead = () => {
    setShowSnoozeMenu(true);
  };

  const handleRemoveFromCustomSequence = () => {
    setShowRemoveMenu(true);
  };

  return (
    <Main>
      <FlexDiv align="center" gap={8}>
        <PhoenixIcon svg={formatSequenceAction(expectedAction)?.icon} hoverColor={theme.PRIMARY500} size="small" />
        <AppText fontSize={expectedAction === "requestManagerFeedback" && !inQueue ? 9 : 12} fontWeight={600}>
          {formatSequenceAction(expectedAction)?.text}
        </AppText>
      </FlexDiv>
      <FlexDiv align="center" gap={16}>
        {showDefaultReleaseButton && (
          <ReleaseLeadButton
            handleReleaseLead={handleReleaseLead}
            leadIsNextDialOverride={leadIsNextDialOverride}
            resetNextDial={resetNextDial}
          />
        )}
        {showCustomSnoozeStepActionButton && (
          <SnoozeLeadButton
            handleSnoozeLead={handleSnoozeLead}
            showSnoozeMenu={showSnoozeMenu}
            customTaskAck={customTaskAck}
            leadData={leadData}
            intentData={intentData}
            scheduledEventBehavior={scheduledEventBehavior}
            loadingCompleteTask={false}
            setShowSnoozeMenu={setShowSnoozeMenu}
            resetNextDial={resetNextDial}
            leadIsNextDialOverride={leadIsNextDialOverride}
          />
        )}
        {showDefaultRemoveFromCustomQueueButton && (
          <RemoveFromCustomQueueButton
            handleRemoveFromCustomQueue={handleRemoveFromCustomQueue}
            resetNextDial={resetNextDial}
            leadIsNextDialOverride={leadIsNextDialOverride}
          />
        )}

        {showCustomRemoveStepActionButton && (
          <RemoveFromCustomSequenceButton
            handleRemoveFromCustomSequence={handleRemoveFromCustomSequence}
            showRemoveMenu={showRemoveMenu}
            setShowRemoveMenu={setShowRemoveMenu}
            customTaskAck={customTaskAck}
            leadData={leadData}
            intentData={intentData}
            resetNextDial={resetNextDial}
            leadIsNextDialOverride={leadIsNextDialOverride}
          />
        )}
      </FlexDiv>
    </Main>
  );
};

const Main = styled.div`
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.fill.brand.secondary};
  border-bottom: 1px solid ${theme.border.brand.tertiary};
`;

interface RepAndResultIcon {
  background?: string;
  color?: string;
}

const RepAndResultIcon = styled.div<RepAndResultIcon>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};

  white-space: nowrap;
`;

const OptionsDiv = styled.div`
  position: absolute;
  top: 32px;
  right: 48px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
  z-index: 3;
`;

interface OptionProps {
  width?: string;
}

const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: ${(props) => (props.width ? props.width : "168px")};
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;

export { CustomSequenceActionHeader };
