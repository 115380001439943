import styled from "styled-components";
import { theme } from "../../../utils/theme";

interface PhoenixButtonProps {
  // overrides to default styling based on type and variant
  width?: number;
  height?: number;
  borderRadius?: number;
  padding?: string;
  backgroundColor?: string;
  borderColor?: string;
  buttonTextFontSize?: number;
  buttonTextFontWeight?: number;
  buttonTextFontFamily?: string;
  uppercase?: boolean;
  centered?: boolean;
  noTransition?: boolean;

  //button states

  disabled?: boolean;
  selected?: boolean;

  // old from new app button ( not in line with design)
  // variant?: "brand" | "secondary" | "attention" | "confirm";
  // hoverVariant?: "primary" | "secondary" | "attention" | "confirm";

  // ------------------ new design ------------------

  // design uses 3 types of buttons - primary, secondary, and tertiary for sizing
  // for styling purposes, they use the below props
  // there is a 1 to 1 relationship from the old variant to the new design varaint

  variant?: "brand" | "brand-outline" | "danger-outline" | "success-outline";
  buttonType: "primary" | "secondary" | "tertiary" | "ghost-large" | "ghost-small";

  // not necessary but can be used to override the hover styling to a different variant
  hoverVariant?: "brand" | "brand-outline" | "danger-outline" | "success-outline";
}

export const PhoenixAppButton = styled.button<PhoenixButtonProps>`
  transition: ${(props) => (props.noTransition ? "none" : "all 0.15s ease-in-out")};

  //----------------------------------//
  // text styles
  //----------------------------------//
  ${(props) => {
    if (props.buttonTextFontFamily) {
      return `font-family: ${props.buttonTextFontFamily};`;
    } else {
      return `font-family: ${theme.PRIMARY_FONT};`;
    }
  }}
  ${(props) => {
    if (props.buttonTextFontSize) {
      return `font-size: ${props.buttonTextFontSize}px`;
    } else {
      return "font-size: 10px";
    }
  }};
  ${(props) => {
    if (props.buttonTextFontWeight) {
      return `font-weight: ${props.buttonTextFontWeight};`;
    } else {
      return "font-weight: 600";
    }
  }};

  ${(props) => {
    // all buttons except ghost buttons small should be uppercase by default
    // ghost buttons small should be lowercase unless specified
    if (props.uppercase || !["ghost-small"].includes(props.buttonType)) {
      return `
      text-transform: uppercase;
      letter-spacing: 1px;
    `;
    }
  }}

  // text size 
   // ghost buttons
  ${(props) => {
    if (props.buttonType === "ghost-large" || props.buttonType === "ghost-small") {
      return `
      font-size: 10px;
      `;
    }
  }}

    // text line height

    // ghost buttons
    ${(props) => {
    switch (props.buttonType) {
      case "ghost-large":
        return `
          line-height: 16px;
          `;
      case "ghost-small":
        return `
          line-height: 14px;
          `;
      default:
        return `
          line-height: 'initial';
          `;
    }
  }}



  //----------------------------------//
  // height
  //----------------------------------//

  ${(props) => {
    if (props.height) {
      return `height: ${props.height}px;`;
    } else {
      switch (props.buttonType) {
        case "primary":
          return `height: 48px;`;
        case "secondary":
          return `height: 40px;`;
        case "tertiary":
          return `height: 32px;`;
        case "ghost-large":
          return `height: 26px;`;
        case "ghost-small":
          return `height: 24px;`;
        default:
          return `height: 48px;`;
      }
    }
  }}

  //----------------------------------//
  // width
  //----------------------------------//

    ${(props) => {
    if (props.width) {
      return `
      width:${props.width}px;
      `;
    } else {
      switch (props.buttonType) {
        case "primary":
          return `
          width: 100%;
          `;
        case "secondary":
          return `
          width: max-content;
          `;
        case "ghost-large":
          return `
          width: max-content;
          `;
        case "ghost-small":
          return `
          width: max-content;
          `;
        case "tertiary":
          return `
          width: max-content;
          `;
        default:
          return `
          width: 100%;
          `;
      }
    }
  }}

//----------------------------------//
  // border-radius
  //----------------------------------//

  ${(props) => {
    if (props.borderRadius) {
      return `border-radius: ${props.borderRadius}px;`;
    } else {
      switch (props.buttonType) {
        case "primary":
          return `border-radius: 8px;`;
        case "secondary":
          return `border-radius: 8px;`;
        case "tertiary":
          return `border-radius: 4px;`;
        case "ghost-large":
          return `border-radius: 4px;`;
        case "ghost-small":
          return `border-radius: 4px;`;
      }
    }
  }}

  //----------------------------------//
  // padding 
  //----------------------------------//

  ${(props) => {
    if (props.padding) {
      return `padding: ${props.padding};`;
    } else {
      switch (props.buttonType) {
        case "primary":
          return `padding: 0px 16px 0px 16px;`;
        case "secondary":
          return `padding: 0px 24px 0px 24px;`;
        case "tertiary":
          return `padding: 0px 8px 0px 8px;`;
        case "ghost-large":
          return `padding:4px;`;
        case "ghost-small":
          return `padding: 4px;`;
        default:
          return `padding: 0px 16px 0px 16px;`;
      }
    }
  }}

//----------------------------------//
  // default state background color and text color
  //----------------------------------//

  ${(props) => {
    if (props.backgroundColor) {
      return `background-color: ${props.backgroundColor};`;
    } else {
      // variant state is differnt for ghost buttons type for the rest of the types it is the same
      if (props.buttonType === "ghost-large" || props.buttonType === "ghost-small") {
        switch (props.variant) {
          case "brand":
            return `
        background-color: ${props.selected ? theme.buttonfill.brand_outline.selected : "transparent"};
        color: ${props.selected ? theme.buttontext.brand_outline.selected : theme.buttontext.brand_outline.default};
        `;
          case "brand-outline":
            return `
        background-color: ${props.selected ? theme.buttonfill.brand_outline.selected : "transparent"};
        color: ${props.selected ? theme.buttontext.brand_outline.selected : theme.buttontext.brand_outline.default};
        `;
          case "danger-outline":
            return `
        background-color: ${props.selected ? theme.buttonfill.danger_outline.selected : "transparent"};
        color: ${props.selected ? theme.buttontext.danger_outline.selected : theme.buttontext.danger_outline.default};
        `;
          case "success-outline":
            return `
        background-color: ${props.selected ? theme.buttonfill.success_outline.selected : "transparent"};
        color: ${props.selected ? theme.buttontext.success_outline.selected : theme.buttontext.success_outline.default};
        `;
          default:
            return `
        background-color: ${props.selected ? theme.buttonfill.brand_outline.selected : "transparent"};
        color: ${props.selected ? theme.buttontext.brand_outline.selected : theme.buttontext.brand_outline.default};
        `;
        }
      }

      // all other button types
      switch (props.variant) {
        case "brand":
          return `
        background-color: ${props.selected ? theme.PRIMARY800 : theme.PRIMARY500};
        color: ${props.selected ? theme.WHITE_COLOR : theme.WHITE_COLOR};
        `;
        case "brand-outline":
          return `
        background-color: ${props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR};
        color: ${props.selected ? theme.WHITE_COLOR : theme.PRIMARY500};
        `;
        case "danger-outline":
          return `
        background-color: ${props.selected ? theme.DANGER600 : theme.WHITE_COLOR};
        color: ${props.selected ? theme.WHITE_COLOR : theme.DANGER600};
        `;
        case "success-outline":
          return `
        background-color: ${props.selected ? theme.SUCCESS500 : theme.WHITE_COLOR};
        color: ${props.selected ? theme.WHITE_COLOR : theme.SUCCESS500};
        `;
        default:
          return `
        background-color: ${props.selected ? theme.PRIMARY800 : theme.PRIMARY500};
        color: ${props.selected ? theme.WHITE_COLOR : theme.WHITE_COLOR};
        `;
      }
    }
  }}

       


  //----------------------------------//
  // default state border
  //----------------------------------//
  

  ${(props) => {
    if (props.buttonType === "ghost-large" || props.buttonType === "ghost-small") {
      return `border: 1px solid transparent;`;
    }

    // moved overide to under switch because of type error
    switch (props.variant) {
      case "brand":
        return `border:  ${props.selected ? `1px solid ${theme.PRIMARY700}` : `1px solid ${theme.PRIMARY500}`};
      `;

      case "brand-outline":
        return `
    border:  1px solid ${theme.PRIMARY500};
  `;

      case "danger-outline":
        return `
      border:  ${props.selected ? `1px solid ${theme.ATTENTION700}` : `1px solid ${theme.ATTENTION500}`};
    `;
      case "success-outline":
        return `
        border: 1px solid ${theme.SUCCESS500};
    `;
      default:
        return `
        border:  ${props.selected ? `1px solid ${theme.PRIMARY700}` : `1px solid ${theme.PRIMARY500}`};
    `;
    }
  }}

    ${(props) => {
    if (props.borderColor) {
      return `border: 1px solid ${props.borderColor};`;
    }
  }}

  

  //----------------------------------//
  // hover state changes
  //----------------------------------//

  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    ${(props) => {
      if (!props.disabled && !props.selected) {
        // ghost button hover state
        if (props.buttonType === "ghost-large" || props.buttonType === "ghost-small") {
          switch (!!props.hoverVariant ? props.hoverVariant : props.variant) {
            case "brand":
              return `background-color: ${theme.buttonfill.brand_outline.hover};`;
            case "brand-outline":
              return `background-color: ${theme.buttonfill.brand_outline.hover};`;
            case "danger-outline":
              return `background-color: ${theme.buttonfill.danger_outline.hover};`;
            case "success-outline":
              return `background-color: ${theme.buttonfill.success_outline.hover};`;
            default:
              return `background-color: ${theme.buttonfill.brand_outline.hover};`;
          }
        }

        switch (!!props.hoverVariant ? props.hoverVariant : props.variant) {
          case "brand":
            return `
        background-color: ${theme.PRIMARY600};
        color: ${theme.WHITE_COLOR};
        border-color : ${theme.PRIMARY500};
      `;
          case "brand-outline":
            return `
        background-color: ${theme.PRIMARY100};
        color: ${theme.PRIMARY500};
        border-color :  ${theme.PRIMARY500};
    `;
          case "danger-outline":
            return `
        background-color: ${theme.ATTENTION100};
        color: ${theme.ATTENTION700};
        border-color : ${theme.ATTENTION700};
    `;
          case "success-outline":
            return `
        background-color: ${theme.SUCCESS100};
        color: ${theme.SUCCESS700};
        border-color : ${theme.SUCCESS500};
    `;
          default:
            return `
            background-color: ${theme.PRIMARY600};
        color: ${theme.WHITE_COLOR};
        border-color : ${theme.PRIMARY500};
    `;
        }
      }
    }};
  }

  //----------------------------------//
  // focus state changes
  //----------------------------------//

  ${(props) => {
    if (props.buttonType === "ghost-large" || props.buttonType === "ghost-small") {
      // variant state is differnt for ghost buttons type for the rest of the types it is the same
      switch (props.variant) {
        case "brand":
          return `
        :focus {
          outline: 1px solid ${theme.buttonborder.brand_outline.default};
          outline-offset: 1px;
        }
      `;
        case "brand-outline":
          return `
        :focus {
          outline: 1px solid ${theme.buttonborder.brand_outline.default};
          outline-offset: 1px;
        }
      `;
        case "danger-outline":
          return `
        :focus {
          // outline: 1px solid ${theme.buttonborder.danger_outline.default};
          // design has danger use same as brand
          outline: 1px solid ${theme.buttonborder.brand_outline.default};
          outline-offset: 1px;
        }
      `;
        case "success-outline":
          return `
        :focus {
          outline: 1px solid ${theme.buttonborder.success_outline.default};
          outline-offset: 1px;
        }
      `;
        default:
          return `
        :focus {
          outline: 1px solid ${theme.buttonborder.brand_outline.default};
          outline-offset: 1px;
        }
      `;
      }
    } else {
      // all other button focus states
      return `
      :focus {

outline: 1px solid ${theme.PRIMARY500};
outline-offset: 1px;
}
    `;
    }
  }}

  //----------------------------------//
  // disabled state changes
  //----------------------------------//

  // the disabled state is an opaque version of the default state
  ${(props) => {
    if (props.disabled) {
      return `
      opacity: 0.2 !important;
      cursor: default !important;
        `;
    }
  }}

  //----------------------------------//
  // centered content
  //----------------------------------//

  ${(props) => {
    if (props.centered || props.buttonType === "ghost-large" || props.buttonType === "ghost-small") {
      return `
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      `;
    }
  }}
`;
