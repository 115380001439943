import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { reorder, trash } from "src/images/NewDesign";
import { ModalContext } from "src/context";
import { DraggableFieldType } from "src/utils/variables";
import { FormField } from "src/utils/misc";
import { useFirstRender } from "src/utils/hooks";

export interface IDraggableFieldBase {
  hideMenu?: boolean;
  id: string;
  index: number;
  setSelectedFieldID: (id: string) => void;
  systemFields?: FormField[];
  type: DraggableFieldType;
}

interface IDraggableFieldHeader {
  fieldType: DraggableFieldType;
}

export const DraggableFieldBase: React.FC<IDraggableFieldBase> = ({
  children,
  id,
  index,
  setSelectedFieldID,
  type,
}) => {
  const { setShowDeleteFieldModal } = useContext(ModalContext);
  const isRequired = type === DraggableFieldType.REQUIRED_FIELD;

  const isFirstRender = useFirstRender();

  return (
    <Draggable draggableId={`field-${id}`} index={index}>
      {(provided) => (
        <DraggableFieldContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={isFirstRender ? "animate-on-mount" : ""}
        >
          <DraggableFieldHeader fieldType={type} style={{ borderBottom: `1px solid ${theme.NEUTRAL200}` }}>
            <FlexDiv gap={8} align="center">
              <div ref={provided.innerRef} {...provided.dragHandleProps}>
                <PhoenixIcon svg={reorder} size={16} variant="neutral" style={{ cursor: "grab" }} />
              </div>
              <AppText
                fontSize={isRequired ? 12 : 14}
                fontWeight={500}
                lineHeight={14}
                color={isRequired ? theme.DANGER600 : theme.BLACK_COLOR}
              >
                {isRequired ? "Required Field" : ""}
              </AppText>
            </FlexDiv>

            {!isRequired && (
              <FlexDiv gap={8} align="center">
                <PhoenixIcon
                  svg={trash}
                  size={16}
                  variant="danger"
                  pointer
                  onClick={() => {
                    setShowDeleteFieldModal(true);
                    setSelectedFieldID(id);
                  }}
                />
              </FlexDiv>
            )}
          </DraggableFieldHeader>
          <DraggableFieldBody>{children}</DraggableFieldBody>
        </DraggableFieldContainer>
      )}
    </Draggable>
  );
};

const DraggableFieldContainer = styled.div`
  width: 424px;

  margin-bottom: 24px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  overflow: hidden;

  &.animate-on-mount {
    animation: ${theme.textFadeIn} 0.3s ease-in-out;
  }
`;

const DraggableFieldHeader = styled.div<IDraggableFieldHeader>`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 32px;
  padding: 8px;

  background-color: ${({ fieldType }) =>
    fieldType === DraggableFieldType.REQUIRED_FIELD
      ? theme.DANGER50
      : fieldType === DraggableFieldType.NOTE
      ? theme.NEUTRAL100
      : theme.PRIMARY50};
`;

const DraggableFieldBody = styled.div`
  padding: 24px;
  background-color: ${theme.WHITE_COLOR};
`;
