import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { useQuery, gql } from "@apollo/client";
import { ModalContext } from "../../context";
import { AppText, Loading, NewAppButton } from "../UI";
import { Modal } from "./Modal";
import { newCloseModal } from "../../images";
import { theme } from "../../utils/theme";
import { formatPhoneNumber } from "../../utils/format";
import { FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL } from "../../utils/variables";

const FETCH_CONFERENCE_PARTICIPANTS = gql`
  query fetchConferenceParticipants {
    participants: fetchConferenceParticipants
  }
`;

const CallParticipantsModal: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(FETCH_CONFERENCE_PARTICIPANTS, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { showCallParticipantsModal, setShowCallParticipantsModal } = useContext(ModalContext);

  useEffect(() => {
    if (!(loading || error) && showCallParticipantsModal) refetch();
  }, [showCallParticipantsModal]);

  return (
    <Modal open={showCallParticipantsModal} onClose={() => setShowCallParticipantsModal(false)} closeButtonSize={16}>
      {loading || error ? (
        <Loading />
      ) : (
        <>
          <TitleDiv>
            <TitleText>Call Participants ({data.participants.length})</TitleText>
          </TitleDiv>
          <ParticipantsDiv>
            {!!data.participants &&
              data.participants?.map((p: any, index: number) => (
                <InfoDiv key={`${index}-${p}`}>{p.match(/(Rep)+/g) ? p : formatPhoneNumber(p)}</InfoDiv>
              ))}
          </ParticipantsDiv>
        </>
      )}
    </Modal>
  );
};

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};

  button {
    display: inline;
    position: absolute;
    right: 0;
  }
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const ParticipantsDiv = styled.div``;

const InfoDiv = styled.div`
  font-size: 14px;
  padding: 1rem;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

export { CallParticipantsModal };
