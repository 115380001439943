import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

interface SequenceNavProps {
  expanded: boolean;
}

export const SequenceNav: React.FC<SequenceNavProps> = ({ expanded }) => {
  const { toggleSequenceReporting } = useFlags();

  const history = useHistory();
  const location = useLocation();

  const isCurPath = (path: string) =>
    !!matchPath(location.pathname, {
      path: path,
      exact: true,
      strict: false,
    });

  return (
    <Container expanded={expanded}>
      <NavigationItem
        active={isCurPath("/sequences")}
        onClick={() => !isCurPath("/sequences") && history.push("/sequences/")}
      >
        Sequences Home
      </NavigationItem>
      {!!toggleSequenceReporting && toggleSequenceReporting !== undefined && (
        <NavigationItem
          active={isCurPath("/sequences/reporting")}
          onClick={() => !isCurPath("/sequences/reporting") && history.push("/sequences/reporting/")}
        >
          Performance
        </NavigationItem>
      )}
      <NavigationItem
        active={isCurPath("/system-config/email-templates")}
        onClick={() => !isCurPath("/system-config/email-templates") && history.push("/system-config/email-templates/")}
      >
        Email Templates
      </NavigationItem>
      <NavigationItem
        active={isCurPath("/system-config/sms-templates")}
        onClick={() => !isCurPath("/system-config/sms-templates") && history.push("/system-config/sms-templates/")}
      >
        SMS Templates
      </NavigationItem>
      <NavigationItem
        active={isCurPath("/sequences/settings")}
        onClick={() => !isCurPath("/sequences/settings") && history.push("/sequences/settings/")}
      >
        Settings
      </NavigationItem>
      <Fill />
    </Container>
  );
};

interface ContainerProps {
  expanded: boolean;
}

const Container = styled.div<ContainerProps>`
  transition: all 0.2s ease-in-out;
  position: fixed;
  top: 0;
  left: ${(props) => (props.expanded ? "72px" : "-152px")};
  z-index: 90;

  width: fit-content;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};
`;

interface NavigationItemProps {
  active: boolean;
}

const NavigationItem = styled.div<NavigationItemProps>`
  display: flex;
  align-items: center;

  width: 224px;
  height: 64px;
  padding-left: 24px;

  border-right: ${(props) => (props.active ? `4px solid ${theme.PRIMARY500}` : `1px solid ${theme.NEUTRAL250}`)};
  border-bottom: 1px solid ${theme.NEUTRAL200};
  cursor: pointer;

  font-size: 12px;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  color: ${(props) => (props.active ? theme.PRIMARY500 : theme.BLACK_COLOR)};

  :hover {
    color: ${theme.PRIMARY500};
  }
`;

const Fill = styled.div`
  width: 100%;
  height: 100%;
  border-right: 1px solid ${theme.NEUTRAL250};
`;
