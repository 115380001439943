import React from "react";
import styled from "styled-components";
import { generateCommunicationToggleTooltip } from "../../../utils/format";
import { gql, useMutation } from "@apollo/client";
import { loggedInUser } from "../../../apollo/cache";
import { CommunicationStates } from "../../../utils/format";
import { CommunicationToggle } from "./CommunicationToggle";
import { appToast, errorToast } from "../../../utils/toast";
import * as Sentry from "@sentry/react";
import { AppErrorText } from "../../UI";

const UPDATE_EMAIL_SUBSCRIPTION_STATUS = gql`
  mutation UpdateEmailSubscriptionStatus($leadId: String!, $emailSubStatus: OptInStatus!) {
    updateEmailSubscriptionStatus(lead_id: $leadId, email_sub_status: $emailSubStatus) {
      email_sub_status
    }
  }
`;

const EmailCommunicationToggle = ({
  lead,
  loadingLead,
  refetchFunction,
}: {
  lead: any;
  loadingLead: boolean;
  // please use the refetch queries string on the onCompleted prop of the mutation
  // this is for anything that we can't use those for
  refetchFunction?: () => void;
}) => {
  const emailStatus = lead?.email_sub_status;

  const leadSubscribedToEmail = emailStatus === CommunicationStates.Subscribed;

  const unsubScribeWasLeadActionEmail = emailStatus === CommunicationStates.LeadUnsubscribed;

  const [
    updateEmailSubscriptionStatus,
    { loading: loadingUpdateEmailSubscriptionStatus, error: errorUpdateEmailSubscriptionStatus },
  ] = useMutation(UPDATE_EMAIL_SUBSCRIPTION_STATUS, {
    onCompleted({ updateEmailSubscriptionStatus }) {
      if (!updateEmailSubscriptionStatus) {
        return;
      }
      appToast("Email Subscription Status Updated!");

      refetchFunction && refetchFunction();
    },
    onError({ message, name }) {
      errorToast("Unable to update Email Subscription Status.  Please contact support.");
      Sentry.captureEvent({
        message: `updateEmailSubscriptionStatus GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
    },
    refetchQueries: ["fetchLeadEmails"],
  });

  const error = errorUpdateEmailSubscriptionStatus || !emailStatus;

  if (error) {
    return <AppErrorText style={{ marginBottom: "16px" }}>Error loading communication toggles.</AppErrorText>;
  }

  return (
    <CommunicationsRow>
      <CommunicationToggle
        toggled={leadSubscribedToEmail}
        type="email"
        repIsAdmin={loggedInUser()?.role === "ADMIN"}
        toggle={() => {
          updateEmailSubscriptionStatus({
            variables: {
              leadId: lead?.id,
              emailSubStatus: leadSubscribedToEmail
                ? CommunicationStates.OPSIQUnsubscribed
                : CommunicationStates.Subscribed,
            },
          });
        }}
        repOwnsLead={lead?.rep_id === loggedInUser()?.id}
        leadOptedOut={leadSubscribedToEmail ? undefined : unsubScribeWasLeadActionEmail}
        tooltipText={generateCommunicationToggleTooltip({
          type: "email",
          isAdmin: false,
          leadLeadAction: leadSubscribedToEmail ? undefined : unsubScribeWasLeadActionEmail,
          toggled: leadSubscribedToEmail,
        })}
        loading={loadingLead || loadingUpdateEmailSubscriptionStatus}
      />
    </CommunicationsRow>
  );
};

const CommunicationsRow = styled.div`
  display: flex;
  flex-direction: "column";
  gap: "4px";
  margin-bottom: 0px;
  margin-top: 4px;
`;

export { EmailCommunicationToggle };
