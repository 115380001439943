import React, { Dispatch, SetStateAction, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { copy, edit, link, trash } from "../../../images/NewDesign";
import { generateOPSIQFormsEmbeddableHTML } from "../../../utils/format";
import { useClickOutside } from "../../../utils/hooks";
import { copyToClipboard } from "../../../utils/misc";
import { theme } from "../../../utils/theme";
import { PhoenixIcon } from "../Phoenix";

interface ManageFormMenuProps {
  formId: string;
  formName: string;
  formSessionToken?: string;
  setShowManageFormMenu: Dispatch<SetStateAction<boolean>>;
  handleEditForm: () => void;
  handleDeleteForm: () => void;
  handleDuplicateForm: () => void;
}

export const ManageFormMenu: React.FC<ManageFormMenuProps> = ({
  formId,
  formName,
  formSessionToken,
  setShowManageFormMenu,
  handleEditForm,
  handleDeleteForm,
  handleDuplicateForm,
}) => {
  const containerRef = useRef(null);
  const history = useHistory();

  const session_token = formSessionToken || "N/A";

  const html = generateOPSIQFormsEmbeddableHTML(session_token);

  useClickOutside(containerRef, () => setShowManageFormMenu(false));

  return (
    <PopupContainer ref={containerRef}>
      <MenuItem
        onClick={() => {
          handleEditForm();
          setShowManageFormMenu(false);
        }}
      >
        <PhoenixIcon size={16} svg={edit} variant="brand" pointer />
        Rename
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDuplicateForm();
          setShowManageFormMenu(false);
        }}
      >
        <PhoenixIcon size={16} svg={copy} variant="brand" pointer />
        Duplicate
      </MenuItem>
      <MenuItem
        onClick={() => {
          copyToClipboard(html);
          setShowManageFormMenu(false);
        }}
      >
        <PhoenixIcon size={16} svg={link} variant="brand" pointer />
        Copy HTML
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteForm();
          setShowManageFormMenu(false);
        }}
      >
        <PhoenixIcon size={16} svg={trash} variant="danger" pointer />
        Delete
      </MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;

  width: 152px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};
  gap: 8px;

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;
