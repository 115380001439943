import { gql } from "@apollo/client";

export const CORE_LEAD_CARD_DATA = gql`
  fragment CoreLeadCardData on Lead {
    id
    favorited
    primary_phone_number
    primary_email
    current_lead_type
    city
    state
    country
    channel
    rep_id
    current_close_date
    computed_mrr
    lead_value {
      id
      value
    }
    timezone_by_state
    lead_source
    industry
    sub_industry
    business_name
    full_name
    first_name
    last_name
    lead_ownership_status
    lead_intent {
      id
      type
    }
    next_lead_intent {
      id
      event_type_label
      dial_num_in_sales_cycle
      anytime_day
      current_sequence_step
      schedule_item {
        id
        start_time
        end_time
        deleted_at
      }
      replacement_lead_intent_id
    }
    next_intent_scheduled_or_unscheduled {
      id
      event_type_label
      dial_num_in_sales_cycle
      anytime_before #general time
      anytime_after
      anytime_day
      anytime_day_upperbound
      anytime_tz
      general_time_start_date
      general_time_end_date
      current_sequence_step
      schedule_item {
        #specific time
        id
        start_time
        end_time
        deleted_at
      }
      replacement_lead_intent_id
    }
    next_scheduled_event {
      id
      type
    }
    next_scheduled_event_time
    pipeline_state_label
  }
`;
