import React, { useEffect } from "react";
import styled from "styled-components";
import { useContext } from "react";
import { ModalContext } from "../../../context";
import { useQuery } from "@apollo/client";
import { ProfileImageWithText } from "../../Segments/SettingSegments/ProfileImageWithText";
import IndividualSaleTable from "./IndividualSaleTable";

import { PanelModal, UpdateProductModal, UpdateTermsModal, DeleteProductModal } from "../../modal";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { gql } from "@apollo/client";
import { appToast } from "../../../utils/toast";
import { AppText, Loading } from "../../UI";
import { theme } from "../../../utils/theme";
import RenderAssociateTitle from "../LeadDetail/RenderAssociateTitle";
import moment from "moment";
import { SkeletonBlock } from "../../UI/SkeletonBlock";

const FETCH_ALL_SALES = gql`
  query fetchAllSales($lead_id: String!, $associate_lead_ids: [String!]!) {
    fetchAllSales(lead_id: $lead_id, associate_lead_ids: $associate_lead_ids) {
      id
      products
      total_price
      created_at
      lead {
        full_name
        id
      }
      lead_activity {
        user {
          profile_image
          id
          first_name
          last_name
          full_name
        }
      }
    }
  }
`;

const Step7Container = ({
  primaryLeadId,
  associatedLeadIds,
  filterByAssociateContact,
}: {
  primaryLeadId: string;
  associatedLeadIds: string[];
  filterByAssociateContact: string;
}) => {
  const {
    selectedSale,
    setSelectedSale,
    showDeleteProductModal,
    setShowDeleteProductModal,
    setShowUpdateProductModal,
    showUpdateProductModal,
    setShowUpdateTermsModal,
    showUpdateTermsModal,
  } = useContext(ModalContext);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const { data: allSales, loading: loadingAllSales, error: errorAllSales, refetch: refetchAllSale } = useQuery(
    FETCH_ALL_SALES,
    {
      fetchPolicy: "cache-and-network",
      skip: !primaryLeadId,
      variables: {
        lead_id: primaryLeadId,
        associate_lead_ids: associatedLeadIds?.map((lead: string) => lead),
      },

      onCompleted({ fetchAllSales }) {
        console.log("fetchAllSales", fetchAllSales);
      },

      onError({ message, name }) {
        appToast("Something went wrong");
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  return (
    <div>
      {showUpdateProductModal && (
        <PanelModal open={showUpdateProductModal} onClose={() => setShowUpdateProductModal(false)}>
          <UpdateProductModal />
        </PanelModal>
      )}

      {showUpdateTermsModal && (
        <PanelModal open={showUpdateTermsModal} onClose={() => setShowUpdateTermsModal(false)}>
          <UpdateTermsModal />
        </PanelModal>
      )}
      {showDeleteProductModal && (
        <DarkDiv2>
          <DeleteProductModal />
        </DarkDiv2>
      )}
      {!selectedSale?.id ? (
        <MinHeightDiv>
          {!!loadingAllSales ? (
            <SaleCardsContainer>
              <SkeletonBlock height={124} width={411} borderRadius={0} />
              <SkeletonBlock height={124} width={411} borderRadius={0} />
              <SkeletonBlock height={124} width={411} borderRadius={0} />
              <SkeletonBlock height={124} width={411} borderRadius={0} />
              <SkeletonBlock height={124} width={411} borderRadius={0} />
              <SkeletonBlock height={124} width={411} borderRadius={0} />
            </SaleCardsContainer>
          ) : !!errorAllSales ? (
            <AppText fontSize={12} color={theme.WARNING500}>
              Error loading sales
            </AppText>
          ) : (
            <SaleCardsContainer>
              <PhoenixStyledTooltip id="saleCardTooltip" effect="solid" place="top" />
              {allSales?.fetchAllSales?.filter((sale: any) => {
                if (filterByAssociateContact === "All") {
                  return true;
                }
                if (!!filterByAssociateContact) {
                  return sale?.lead?.id === filterByAssociateContact;
                }
                return true;
              })?.length > 0 ? (
                allSales?.fetchAllSales
                  ?.filter((sale: any) => {
                    if (filterByAssociateContact === "All") {
                      return true;
                    }
                    if (!!filterByAssociateContact) {
                      return sale?.lead?.id === filterByAssociateContact;
                    }
                    return true;
                  })
                  ?.map((sale: any) => (
                    <SaleCard id={sale?.id}>
                      <Left>
                        <div style={{ display: "flex", alignItems: "center", gap: "16px", marginLeft: "4px" }}>
                          <ProfileImageWithText
                            height={26}
                            width={26}
                            textSize={12}
                            first_name={sale?.lead_activity?.user?.first_name}
                            last_name={sale?.lead_activity?.user?.last_name}
                            profile_image={sale?.lead_activity?.user?.profile_image}
                          />
                          <AppText fontSize={12} fontWeight={600}>
                            {sale?.lead_activity?.user?.full_name}
                          </AppText>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <RenderAssociateTitle
                            primary_lead_id={primaryLeadId}
                            associate_lead_ids={associatedLeadIds}
                            contact_id={sale?.lead?.id}
                            isAssociatedLead={associatedLeadIds.includes(sale?.lead?.id)}
                          />

                          <AppText
                            fontSize={12}
                            fontWeight={400}
                            style={{
                              // ellipsis
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {sale?.lead?.full_name}
                          </AppText>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <AppText
                            fontSize={12}
                            fontWeight={500}
                            style={{
                              // ellipsis
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              //no wrap
                              whiteSpace: "nowrap",
                              //width
                              width: "100%",
                              cursor: "pointer",
                            }}
                            data-tip={sale?.products ?? "No Products Found"}
                            data-for="saleCardTooltip"
                          >
                            {sale?.products ?? "No Products Found"}
                          </AppText>
                          <AppText fontSize={12} fontWeight={500}>
                            {formatter.format(sale?.total_price)}
                          </AppText>
                        </div>
                      </Left>
                      <Right>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                          <AppText fontSize={12} fontWeight={500}>
                            {moment(sale?.created_at).format("MM/DD/YYYY")}
                          </AppText>
                          <AppText fontSize={12} fontWeight={500}>
                            {moment(sale?.created_at).format("hh:mm A")}
                          </AppText>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}
                          onClick={() => {
                            const newSelectedSale = {
                              ...selectedSale,
                              // id of sale
                              id: sale?.id,
                              // id of lead
                              lead_id: sale?.lead?.id,
                            };
                            setSelectedSale(newSelectedSale);
                          }}
                        >
                          <AppText
                            fontSize={12}
                            fontWeight={500}
                            color={theme.PRIMARY600}
                            style={{
                              cursor: "pointer",
                              border: "none",
                              color: theme.PRIMARY600,
                            }}
                          >
                            View Details
                          </AppText>
                        </div>
                      </Right>
                    </SaleCard>
                  ))
              ) : (
                <AppText
                  style={{
                    margin: "8px 16px",
                  }}
                >
                  No sales has been recorded.
                </AppText>
              )}
            </SaleCardsContainer>
          )}
        </MinHeightDiv>
      ) : (
        <IndividualSaleTable
          associatedLeadIds={associatedLeadIds}
          primaryLeadId={primaryLeadId}
          refetchAllSales={refetchAllSale}
        />
      )}
    </div>
  );
};

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SaleCardsContainer = styled.div`
  padding: 16px;
  // we want to have a grid layout 2 cards per row
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;

  // expand to fill the width of the container
  width: max-content;
  overflow-y: auto;
`;
const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -72px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const MinHeightDiv = styled.div`
  min-height: 517px;
  max-height: 517px;
  overflow-y: scroll;
  padding-bottom: 24px;
  animation: ${theme.fadeIn} 0.2s ease;
`;

const SaleCard = styled.div`
  width: 411px;
  height: 124px;
  background: ${theme.LIGHT_BLUE};
  padding: 16px;
  display: grid;
  grid-template-columns: 284px 1fr;
`;

export default Step7Container;
