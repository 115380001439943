import React from "react";
import styled from "styled-components";
import { AppText } from "../../../UI";

const CustomFieldLinkCheck = (value: string, key: string) => {
  if (
    new RegExp(
      "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
    ).test(value)
  ) {
    if (new RegExp("([a-zA-Z0-9]+://)").test(value)) {
      return (
        <CustomFieldText data-tip={value} data-for={key}>
          <a href={value} target="_blank">
            {value}
          </a>
        </CustomFieldText>
      );
    }
    return (
      <CustomFieldText data-tip={value} data-for={key}>
        <a href={`http://${value}`} target="_blank">
          {value}
        </a>
      </CustomFieldText>
    );
  }
  return (
    <CustomFieldText data-tip={value} data-for={key}>
      {value}
    </CustomFieldText>
  );
};

export { CustomFieldLinkCheck };

interface CustomFieldTextProps {
  bold?: boolean;
}

const CustomFieldText = styled(AppText)<CustomFieldTextProps>`
  font-weight: ${(props) => (props.bold ? 600 : "normal")};
  margin: 0px;
  margin-bottom: 4px;
  max-width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
