import styled from "styled-components";
import * as React from "react";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import { formatImportDate, flipNameFormat } from "../../utils/format";
import editIcon from "../../images/icons-ic-edit.png";

interface EventItemProps {
  time: string;
  salesperson: string;
  contact: string;
  event: string;
  event_label: string;
  role: string;
  id: string;
  lead_id: string;
  handleEditClick: (id: string, time: string, lead_id: string) => void;
}

const eventText = (string: string, label?: string) => {
  if (string === "Demo") return "Demo";
  if (label === "Decision Call") return "Decision Call"
  if (string === "CallBack") return "Call Back";
  if (string === "ScheduledCallBack") return "Schedule Call Back";
  return "Other";
};

const EventItem: React.FC<EventItemProps> = (props) => {
  return (
    <ListItemDiv>
      <ListItemText>{formatImportDate(props.time)}</ListItemText>
      <ListItemText>
        {props.salesperson} {props.role ? `(${props.role})` : ""}
      </ListItemText>
      <ListItemText>{flipNameFormat(props.contact)}</ListItemText>
      <ListItemText>{eventText(props.event, props.event_label)}</ListItemText>
      <ListItemIcon>
        <img
          src={editIcon}
          style={{ height: "24px" }}
          onClick={() => props.handleEditClick(props?.id, props.time, props.lead_id)}
        />
      </ListItemIcon>
    </ListItemDiv>
  );
};

const ListItemDiv = styled.div`
  display: grid;
  grid-template-columns: 232px 277px 361px 1fr 24px 24px;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListItemText = styled(AppText)`
  font-size: 12px;
`;

const ListItemIcon = styled.div`
  justify-self: end;
  cursor: pointer;
`;

export { EventItem };
