import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { ReactSVG } from "react-svg";

interface PhoenixIconProps {
  variant?: "brand" | "neutral" | "danger" | "alert" | "success" | "white" | "dark-disabled" | "brown";
  disabled?: boolean;
  alt?: string;

  // path to icon - must be an SVG. If you're using a .png or .jpeg you'll have to use an <img> tag
  svg: any;

  /* some SVG color changes require the fill property. 
     If you're using an SVG that needs to be filled, set this to true. 
     Most PhoenixIcons do not require this. */
  fillIcon?: boolean;

  // overrides
  color?: string;
  hoverColor?: string;
  size?: number | "xsmall" | "small" | "medium";
  style?: React.CSSProperties;
  pointer?: boolean;

  onClick?: (e?: any) => void;
}

export const PhoenixIcon: React.FC<PhoenixIconProps> = ({ ...props }) => {
  return <Icon src={props.svg} {...props} style={props.style} />;
};

const Icon = styled(ReactSVG)<PhoenixIconProps>`
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};

  & div {
    display: flex;
    align-items: center;
    width: ${(props) => {
      if (typeof props.size === "number") {
        return `${props.size}px`;
      } else {
        switch (props.size) {
          case "xsmall":
            return "10px";
          case "small":
            return "16px";
          case "medium":
            return "unset";
          default:
            return "unset";
        }
      }
    }};
    height: ${(props) => {
      if (typeof props.size === "number") {
        return `${props.size}px`;
      } else {
        switch (props.size) {
          case "xsmall":
            return "10px";
          case "small":
            return "16px";
          case "medium":
            return "unset";
          default:
            return "unset";
        }
      }
    }};
  }

  & svg path,
  & svg line,
  & svg rect {
    stroke: ${(props) => {
      if (props.color) {
        return props.color;
      } else {
        switch (props.variant) {
          case "brand":
            return !props.disabled ? theme.PRIMARY500 : theme.PRIMARY200;
          case "neutral":
            return !props.disabled ? theme.NEUTRAL300 : theme.NEUTRAL200;
          case "danger":
            return !props.disabled ? theme.DANGER600 : theme.DANGER100;
          case "alert":
            return !props.disabled ? theme.WARNING500 : theme.WARNING200;
          case "success":
            return !props.disabled ? theme.SUCCESS500 : theme.SUCCESS200;
          case "white":
            return theme.WHITE_COLOR;
          case "brown":
            return theme.WARNING700;
          case "dark-disabled":
            return theme.PRIMARY700;
          default:
            return !props.disabled ? theme.PRIMARY500 : theme.PRIMARY200;
        }
      }
    }};
    fill: ${(props) => {
      if (props.fillIcon) {
        switch (props.variant) {
          case "brand":
            return !props.disabled ? theme.PRIMARY500 : theme.PRIMARY200;
          case "neutral":
            return !props.disabled ? theme.NEUTRAL300 : theme.NEUTRAL200;
          case "danger":
            return !props.disabled ? theme.DANGER600 : theme.DANGER100;
          case "alert":
            return !props.disabled ? theme.WARNING500 : theme.WARNING200;
          case "success":
            return !props.disabled ? theme.SUCCESS500 : theme.SUCCESS200;
          case "white":
            return theme.WHITE_COLOR;
          case "brown":
            return theme.WARNING700;
          case "dark-disabled":
            return theme.PRIMARY700;
          default:
            return !props.disabled ? theme.PRIMARY500 : theme.PRIMARY200;
        }
      }
    }};
  }

  & svg {
    width: ${(props) => {
      if (typeof props.size === "number") {
        return `${props.size}px`;
      } else {
        switch (props.size) {
          case "xsmall":
            return "10px";
          case "small":
            return "16px";
          case "medium":
            return "unset";
          default:
            return "unset";
        }
      }
    }};
    height: ${(props) => {
      if (typeof props.size === "number") {
        return `${props.size}px`;
      } else {
        switch (props.size) {
          case "xsmall":
            return "10px";
          case "small":
            return "16px";
          case "medium":
            return "unset";
          default:
            return "unset";
        }
      }
    }};
  }

  & svg:hover path,
  & svg:hover line,
  & svg:hover rect {
    stroke: ${(props) => {
      if (props.hoverColor) {
        return props.hoverColor;
      } else if (!props.disabled) {
        switch (props.variant) {
          case "brand":
            return theme.PRIMARY600;
          case "neutral":
            return theme.NEUTRAL350;
          case "danger":
            return theme.DANGER700;
          case "alert":
            return theme.WARNING600;
          case "success":
            return theme.SUCCESS700;
          case "white":
            return theme.WHITE_COLOR;
          case "brown":
            return theme.WARNING700;
          case "dark-disabled":
            return theme.PRIMARY700;
          default:
            return theme.PRIMARY600;
        }
      }
    }};
    fill: ${(props) => {
      if (props.fillIcon && props.hoverColor) {
        return props.hoverColor;
      } else if (props.fillIcon && !props.disabled) {
        switch (props.variant) {
          case "brand":
            return theme.PRIMARY600;
          case "neutral":
            return theme.NEUTRAL350;
          case "danger":
            return theme.DANGER700;
          case "alert":
            return theme.WARNING600;
          case "success":
            return theme.SUCCESS700;
          case "white":
            return theme.WHITE_COLOR;
          case "brown":
            return theme.WARNING700;
          case "dark-disabled":
            return theme.PRIMARY700;
          default:
            return theme.PRIMARY600;
        }
      }
    }};
  }
`;
