import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";

interface CheckboxFieldProps {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
  onClick?: () => void;
}

const CheckboxField = ({ label, style, title, ...props }: CheckboxFieldProps) => {
  const [field] = useField(props.name);
  return (
    <CheckboxContainer style={style} checked={field.value}>
      {!!title && <TitleText>{title}</TitleText>}
      <input
        {...field}
        {...props}
        name={field.name}
        type="checkbox"
        checked={field.value}
        onChange={field.onChange}
        onClick={props.onClick}
      />
      <span className="checkmark" />
    </CheckboxContainer>
  );
};

interface CheckedProp {
  checked: boolean;
}

const CheckboxContainer = styled.label<CheckedProp>`
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid ${(props) => (props.checked ? theme.NEUTRAL400 : theme.NEUTRAL200)};
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "✓";
    font-family: ${theme.PRIMARY_FONT};
    position: absolute;
    display: none;
    font-size: 17px;
    line-height: 24px;
    left: 3px;
    bottom: -1px;
    color: ${theme.NEUTRAL400};
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
`;

const TitleText = styled(AppText)`
  margin-left: 36px;
  line-height: 24px;
`;

export { CheckboxField };
