import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading, AppErrorText, AppSidebarCard, NewAppSidebarCard } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery } from "@apollo/client";
import { daysFromCreatedDate } from "../../../utils/format";
import { FETCH_CURRENT_LEAD_POLL_INTERVAL } from "../../../utils/variables";
import moment from "moment";

interface MyPriortCallNotesProps {
  callNotes: { created_at: string; notes: string; id: string }[];
  color?: string;
  textcolor?: string;
  isSearchDashboard?: boolean;
  isInCallReport?: boolean;
}

const MyPriorCallNotes: React.FC<MyPriortCallNotesProps> = ({
  isSearchDashboard,
  callNotes,
  color,
  textcolor,
  isInCallReport = false,
}) => {
  if (callNotes.length === 0)
    return isInCallReport ? (
      <NewAppSidebarCard
        width={isSearchDashboard ? "100%" : "465px"}
        title="My Prior Call Notes"
        color={color}
        textcolor={textcolor}
      >
        <EmptyContainer isInCallReport={isInCallReport}>
          <EmptyFont>There are no prior call notes.</EmptyFont>
        </EmptyContainer>
      </NewAppSidebarCard>
    ) : (
      <AppSidebarCard width={isSearchDashboard ? "100%" : undefined} title="MY PRIOR CALL NOTES" color={color}>
        <EmptyContainer isInCallReport={isInCallReport}>
          <EmptyFont>There are no prior call notes</EmptyFont>
        </EmptyContainer>
      </AppSidebarCard>
    );
  return isInCallReport ? (
    <NewAppSidebarCard
      width={isSearchDashboard ? "100%" : "465px"}
      title="My Prior Call Notes"
      color={color}
      textcolor={textcolor}
    >
      <MyPriorPhoneCallsContainer isInCallReport={isInCallReport}>
        {callNotes?.map((note: { created_at: string; notes: string; id: string }) => {
          return (
            <>
              <CallContainer key={note.id}>
                <TimeFont>{!!note.created_at ? moment(note.created_at).format("M/D/YYYY h:mm A") : ""}</TimeFont>
                <NoteFont>{note.notes}</NoteFont>
              </CallContainer>
              <StyledHr />
            </>
          );
        })}
      </MyPriorPhoneCallsContainer>
    </NewAppSidebarCard>
  ) : (
    <AppSidebarCard width={isSearchDashboard ? "100%" : undefined} title="MY PRIOR CALL NOTES" color={color}>
      <MyPriorPhoneCallsContainer isInCallReport={isInCallReport}>
        {callNotes?.map((note: { created_at: string; notes: string; id: string }) => {
          return (
            <CallContainer key={note.id}>
              <TimeFont>{!!note.created_at ? moment(note.created_at).format("M/D/YYYY h:mm A") : ""}</TimeFont>
              <NoteFont>{note.notes}</NoteFont>
            </CallContainer>
          );
        })}
      </MyPriorPhoneCallsContainer>
    </AppSidebarCard>
  );
};

const StyledHr = styled.hr`
  /* margin: 0px 15px; */
  border: none;
  border-top: 0.5px solid #d8d8d8;
  width: 100%;
  :last-child {
    display: none;
  }
`;

interface IsInCallReportProp {
  isInCallReport?: boolean;
}

const MyPriorPhoneCallsContainer = styled.div<IsInCallReportProp>`
  max-height: 130px;
  min-height: ${(props) => (props.isInCallReport ? "130px" : "100px")};
  margin: 5px 0px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 24px;
`;

const EmptyContainer = styled.div<IsInCallReportProp>`
  max-height: 130px;
  min-height: ${(props) => (props.isInCallReport ? "130px" : "100px")};
  margin: ${(props) => (props.isInCallReport ? "auto auto" : "5px 0px")};
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 24px;
`;

const ErrorMessageContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyDiv = styled.div`
  display: none;
`;

const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 10px;
`;

const TimeFont = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.NEUTRAL500};
`;

const NoteFont = styled(AppText)`
  font-size: 11px;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
`;

const EmptyFont = styled(AppText)`
  font-size: 15px;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
`;

export { MyPriorCallNotes };
