/**
 * converts blob into mp3 file
 * @param blob raw blob
 * @returns raw binary in mp3 format
 */
export const convertBlobToMediaFile = async (blobUrl: string) => {
  let new_blob = await fetch(blobUrl).then((r) => r.blob());
  console.log("[REC BLOB URL]", new_blob);
  let fileBlob = new File([new_blob], new Date().toISOString() + "_recording.mp3", { type: "audio/mpeg" });
  return fileBlob;
};
