import moment from "moment";
import { CustomFieldType, CustomObjectRecord } from "../__generated__/graphql";
import { convertDate } from "./format";

export const handleCustomObjectRecordDataType = (record: CustomObjectRecord, type: CustomFieldType) => {
  switch (type) {
    case CustomFieldType.MultiText:
    case CustomFieldType.MultiDropdown:
      if (record.list_value.length) {
        return record.list_value.join(", ");
      }
      return "N/A";
    case CustomFieldType.Boolean:
      if (typeof record.boo_value === "boolean") {
        return record.boo_value ? "true" : "false";
      }
      return "N/A";
    case CustomFieldType.Number:
      if (typeof record.num_value === "number") {
        return record.num_value.toString();
      }
      return "N/A";
    case CustomFieldType.Date:
      if (record.string_value) {
        return moment(convertDate(record.string_value)).format("MM/DD/YYYY");
      }
      return "N/A";
    case CustomFieldType.DateTime:
      if (record.date_value) {
        return moment(convertDate(record.date_value)).format("M/D/YYYY h:mm A");
      }
      return "N/A";
    case CustomFieldType.Text:
    case CustomFieldType.Dropdown:
    case CustomFieldType.Reference:
    default:
      if (record.string_value) {
        return record.string_value;
      }
      return "N/A";
  }
};
