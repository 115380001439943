import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, Loading, NewAppButton } from "../../UI";
import { theme } from "../../../utils/theme";
import { Link, useHistory } from "react-router-dom";
import { CreateNewLead, LeadCSVUpload, ResolveDuplicateLead } from "../../modal/";
import * as Sentry from "@sentry/react";
import { useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { formatImportDate } from "../../../utils/format";

import { restAPI } from "../../../apollo";
import { LEAD_CSV_EXAMPLE_URL, LEAD_IMPORT_HISTORY_POLL_INTERVAL } from "../../../utils/variables";
import { ModalContext } from "../../../context";
import { useContext } from "react";
import { appToast } from "../../../utils/toast";
import Lottie from "react-lottie";
import pending from "../../../images/lotties/pending.json";
import ReactTooltip from "react-tooltip";
import { AppTable, AppTableItems, TableRow, TableTD } from "../../UI/AppTable";
import { downloadResourceAWS, sanitizeURL } from "../../../utils/misc";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { LeadActivityCSVUpload } from "../../modal/LeadActivityCSVUpload";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const FETCH_LEAD_ACTIVITY_IMPORT_HISTORY = gql`
  query fetchLeadActivityImportHistory($skip: Int, $take: Int) {
    fetchLeadActivityImportHistory(skip: $skip, take: $take) {
      id
      name
      type
      created_at
      leads_added
      leads_updated
      leads_errored
      leads_queued
      leads_on_warning
      error_report_url
      duplicate_report_url
      is_resolved
      user {
        id
        first_name
        last_name
      }
    }
  }
`;

const DOWNLOAD_CSV_LA_TEMPLATE = gql`
  mutation OrgLATemplateCsv {
    OrgLATemplateCsv {
      url
    }
  }
`;

const IMPORT_COLUMN_HEADERS = [
  { label: "Upload Date" },
  { label: "Upload Type", style: { minWidth: 100 } },
  { label: "Uploader" },
  { label: "File Name / Lead Name" },
  { label: "Added" },
  { label: "Updated" },
  { label: "Queued" },
  { label: "Warnings" },
  { label: "Errored" },
  { label: "Error Report" },
];

const defaultCSVModal = {
  num_imported: undefined,
  num_updated: undefined,
  num_staged: undefined,
  upload_id: undefined,
  num_error: undefined,
  error_report_url: undefined,
  visible: false,
};

const ImportLeadActivity: React.FC<DisappearingDivProps> = ({ step, isOnboarding }) => {
  const [leadUploadLoading, setLeadUploadLoading] = useState(false);

  const { csvModal, setCsvModal } = useContext(ModalContext);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const lottieOptions = (loop: boolean, animationData: any) => {
    return {
      loop: loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  };

  const { data, loading, error, refetch } = useQuery(FETCH_LEAD_ACTIVITY_IMPORT_HISTORY, {
    fetchPolicy: "network-only",
    variables: 20,
    pollInterval: LEAD_IMPORT_HISTORY_POLL_INTERVAL,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [OrgLATemplateCsv, { data: orgTemplateCsvData, loading: loadingTemplate }] = useMutation(
    DOWNLOAD_CSV_LA_TEMPLATE,
    {
      fetchPolicy: "no-cache",
      onCompleted({ OrgLATemplateCsv }) {
        if (OrgLATemplateCsv.url) {
          // window.open(OrgTemplateCsv.url);
          downloadResourceAWS(OrgLATemplateCsv.url);
        }
      },
      onError({ message }) {
        appToast(`${message}`);
      },
    },
  );

  const onFileChange = async (event: any) => {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append("file", event.target.files[0]);
    //Request made to the backend api
    //Send formData object;
    try {
      setLeadUploadLoading(true);
      const response = await restAPI.post(`/upload/LeadActivityCSV`, formData, {
        headers: {
          ...restAPI.defaults.headers,
          "content-type": "multipart/form-data",
        },
      });
      if (response.data) {
        const newState = {
          num_imported: response.data.num_imported,
          num_updated: response.data.num_updated,
          num_error: response.data.num_errors,
          num_queued: response.data.num_queued,
          error_report_url: response.data.error_report,
          upload_id: response.data.upload_id,
          num_staged: response.data.num_stagged,
          visible: true,
        };
        setCsvModal(newState);
        refetch();
        event.value = "";
        return null;
      }

      appToast(response?.data?.message);
      // window.localStorage.setItem("customPhasesData", JSON.stringify(response));
      setLeadUploadLoading(false);
      // history.push("/onboarding/map-sale-phases");
      event.value = "";
    } catch (e: any) {
      setLeadUploadLoading(false);
      console.error(e?.response?.data ?? "Error uploading CSV.");
      event.value = "";
      appToast(`${e?.response?.data.error}` ?? "Error uploading CSV.");
    } finally {
      setLeadUploadLoading(false);
    }
  };

  const history = useHistory();
  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred at the Import Lead Activity CSV segment"}>
      <DisappearingDiv>
        {csvModal.visible && <LeadActivityCSVUpload close={() => setCsvModal(defaultCSVModal)} />}

        <CenterDiv>
          <PhoenixAppButton
            height={48}
            width={188}
            variant="brand"
            buttonType="secondary"
            disabled={leadUploadLoading || loadingTemplate}
            onClick={() => OrgLATemplateCsv()}
          >
            Download CSV Template
          </PhoenixAppButton>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 16,
              flexGrow: 2,
            }}
          >
            <>
              <PhoenixAppButton
                height={48}
                width={188}
                buttonType={"primary"}
                variant="brand"
                disabled={leadUploadLoading}
                onClick={() => {
                  hiddenFileInput.current !== null && hiddenFileInput?.current.click();
                }}
              >
                Import CSV
              </PhoenixAppButton>
              <input
                style={{ display: "none" }}
                type="file"
                ref={hiddenFileInput}
                onChange={onFileChange}
                onClick={(event: any) => (event.target.value = null)}
              />
            </>
          </div>
        </CenterDiv>
        <LocalWrapper>
          <GridDiv>
            <>
              <AppTable columns={IMPORT_COLUMN_HEADERS} noTooltip>
                {loading || leadUploadLoading ? (
                  <Loading />
                ) : (
                  !!data?.fetchLeadActivityImportHistory?.length &&
                  data?.fetchLeadActivityImportHistory?.map((activity: any, index: number) => (
                    <TableRow key={activity?.id}>
                      <TableTD>
                        <ListItemText style={{ minWidth: 80 }}>
                          {formatImportDate(activity.created_at || Date.now())}
                        </ListItemText>
                      </TableTD>

                      <TableTD>
                        <ListItemText style={{ width: 100 }}>{activity.type}</ListItemText>
                      </TableTD>
                      <TableTD>{`${activity.user?.first_name} ${activity.user?.last_name}`}</TableTD>
                      <TableTD>
                        <LeadDiv style={{ width: 150 }}>
                          <ReactTooltip
                            id={activity.id}
                            multiline
                            place="top"
                            effect="solid"
                            className="sib-tooltip"
                            css={{
                              maxWidth: 600,
                              lineHeight: 1.4,
                              textAlign: "center",
                              fontFamily: theme.PRIMARY_FONT,
                            }}
                            backgroundColor={theme.PRIMARY800}
                            getContent={(dataTip) => (
                              <span
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "10px",
                                  lineHeight: "14px",
                                }}
                              >
                                {dataTip}
                              </span>
                            )}
                          />
                          <SpinDiv>
                            {!activity.is_resolved && (
                              <Lottie options={lottieOptions(true, pending)} height={11} width={11} />
                            )}
                          </SpinDiv>

                          <ListItemText data-tip={activity.name} data-for={activity.id}>
                            {activity.name}
                          </ListItemText>
                        </LeadDiv>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{activity.leads_added}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{activity.leads_updated}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{activity.leads_queued}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{activity.leads_on_warning}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <ListItemText>{activity.leads_errored}</ListItemText>
                      </TableTD>
                      <TableTD>
                        <NewAppButton
                          variant="attention"
                          style={{ border: "none", backgroundColor: "transparent", textAlign: "left", padding: 0 }}
                          onClick={() => {
                            const santizedURL = sanitizeURL(activity.error_report_url);

                            if (santizedURL) {
                              window.open(santizedURL, "_blank", "noopener,noreferrer");
                            }
                          }}
                          disabled={!activity.error_report_url}
                        >
                          Download
                        </NewAppButton>
                      </TableTD>
                    </TableRow>
                  ))
                )}
              </AppTable>
            </>
          </GridDiv>
          {!!isOnboarding && (
            <CenterDiv>
              <SaveAndContinueButton
                onClick={() => {
                  if (isOnboarding) {
                    history.push(`/onboarding?step=${step + 1}`);
                  } else {
                    appToast("Updated!");
                  }
                }}
              >
                {!!isOnboarding ? "Continue" : "Save Changes"}
              </SaveAndContinueButton>
            </CenterDiv>
          )}
        </LocalWrapper>
      </DisappearingDiv>
    </Sentry.ErrorBoundary>
  );
};

const LeadDiv = styled.div`
  display: flex;
  flex-direction: row;
  /* min-width: 150px; */
  align-items: center;
`;

const SpinDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-right: 4px;
`;

const LocalWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const GridDiv = styled.div`
  margin-bottom: 16px;
  min-height: 600px;
  max-height: 640px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const ListItemText = styled(AppText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
`;

export { ImportLeadActivity };
