import React, { useContext, useEffect } from "react";
import { FlexDiv } from "../../UI/FlexDiv";
import styled from "styled-components";
import { AppText } from "src/Components/UI";
import { theme } from "src/utils/theme";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { check, download } from "src/images/NewDesign";
import { formatImportDate } from "src/utils/format";
import { CallReportListLead } from "../CallReportListLead";
import { iconSave } from "src/images";
import { downloadResourceCors } from "src/utils/misc";
import { SkeletonBlock } from "src/Components/UI";
import { ModalContext } from "src/context";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";

interface CallHistoryTabProps {
  lead: Record<any, any>;
  req: any;
  called: boolean;
  data?: any;
  loading?: boolean;
}

const columns = [
  "Set",
  "Held",
  "Closed",
  "Business Name",
  "Time of Event",
  "Sales Rep",
  "Contact Name",
  "Time of Call",
  "Call Result",
  "",
];

const widthMap = ["44px", "44px", "44px", "166px", "140px", "120px", "120px", "164px", "146px", "64px"];

export const CallHistoryTab: React.FC<CallHistoryTabProps> = ({ lead, req, called, data, loading }) => {
  const { setSaveCallToLibraryModal, setCurrentConferenceID } = useContext(ModalContext);

  useEffect(() => {
    !called &&
      req({
        variables: {
          call_report_lead_args: {
            lead_id: lead?.id,
            order_by: "CallTime",
            desc: true,
          },
        },
      });
  }, [called]);

  if (loading) {
    return (
      <FlexDiv height="100%" width="100%" style={{ position: "relative", overflow: "auto" }}>
        <FlexDiv height="100%" width="100%" style={{ position: "absolute" }}>
          <SkeletonBlock height="70vh" width="100%" borderRadius={8} />
        </FlexDiv>
      </FlexDiv>
    );
  }

  return (
    <FlexDiv height="100%" width="100%" style={{ position: "relative", overflow: "auto" }}>
      <FlexDiv direction="column" align="flex-start" height="100%" width="100%" style={{ position: "absolute" }}>
        <PhoenixStyledTooltip id="overflow-tip" />
        <Row>
          {columns.map((col, i) => (
            <Cell key={`col-${col}`} width={widthMap[i]}>
              <AppText fontWeight={600}>{col}</AppText>
            </Cell>
          ))}
        </Row>

        {data?.map((d: any) => (
          <Row key={d?.id}>
            <Cell width={widthMap[0]}>
              {d?.lead?.set ? <PhoenixIcon svg={check} size={16} variant="success" /> : <AppText>-</AppText>}
            </Cell>
            <Cell width={widthMap[1]}>
              {d?.lead?.held ? <PhoenixIcon svg={check} size={16} variant="success" /> : <AppText>-</AppText>}
            </Cell>
            <Cell width={widthMap[2]}>
              {d?.lead?.closed ? <PhoenixIcon svg={check} size={16} variant="success" /> : <AppText>-</AppText>}
            </Cell>
            <Cell width={widthMap[3]}>
              <a target="_blank" rel="noopener noreferrer" href={`/lead-detail/${d?.lead?.id ?? ""}`}>
                <Text
                  noWrap
                  data-tip={d?.lead?.business_name}
                  data-for={d?.lead?.business_name?.length >= 18 ? "overflow-tip" : undefined}
                >
                  {d?.lead?.business_name}
                </Text>
              </a>
            </Cell>
            <Cell width={widthMap[4]}>
              <AppText>
                {formatImportDate(!!d?.schedule_item?.start_time ? d?.schedule_item?.start_time : d?.created_at)}
              </AppText>
            </Cell>
            <Cell width={widthMap[5]}>
              <Text
                noWrap
                data-tip={`${d?.user?.first_name ?? ""} ${d?.user?.last_name ?? ""}`}
                data-for={
                  `${d?.user?.first_name ?? ""} ${d?.user?.last_name ?? ""}`.length >= 12 ? "overflow-tip" : undefined
                }
              >{`${d?.user?.first_name ?? ""} ${d?.user?.last_name ?? ""}`}</Text>
            </Cell>
            <Cell width={widthMap[6]}>
              <Text
                noWrap
                data-tip={`${d?.lead?.first_name ?? ""} ${d?.lead?.last_name ?? ""}`}
                data-for={
                  `${d?.lead?.first_name ?? ""} ${d?.lead?.last_name ?? ""}`.length >= 12 ? "overflow-tip" : undefined
                }
              >{`${d?.lead?.first_name ?? ""} ${d?.lead?.last_name ?? ""}`}</Text>
            </Cell>
            <Cell width={widthMap[7]}>
              <AppText>
                {formatImportDate(
                  d?.communication_type === "NonCall" ? d?.created_at : d?.conference?.start_time ?? d?.created_at,
                )}
              </AppText>
            </Cell>
            <Cell width={widthMap[8]}>
              <Text
                noWrap
                data-tip={d?.related_disposition?.label}
                data-for={d?.related_disposition?.label?.length >= 18 ? "overflow-tip" : undefined}
              >
                {d?.related_disposition?.label ?? "No Result"}
              </Text>
            </Cell>
            <Cell width={widthMap[9]}>
              {!!d?.conference?.duration && (
                <FlexDiv align="center" gap={8}>
                  <PhoenixIcon
                    style={{ paddingTop: "2px" }}
                    data-tip="Save"
                    pointer
                    size={24}
                    variant="neutral"
                    onClick={() => {
                      setCurrentConferenceID(d?.conference?.id);
                      setSaveCallToLibraryModal(true);
                    }}
                    svg={iconSave}
                    alt="Save to Call Library"
                  />
                  {!!d?.conference?.recording_url_computed && (
                    <PhoenixIcon
                      svg={download}
                      pointer
                      size={16}
                      alt="Download"
                      onClick={() => downloadResourceCors(d?.conference?.recording_url_computed)}
                    />
                  )}
                </FlexDiv>
              )}
            </Cell>
          </Row>
        ))}
      </FlexDiv>
    </FlexDiv>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;

  :nth-child(even) {
    background-color: ${theme.fill.brand.secondary};
  }
`;

const Cell = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ width }) => width || "fit-content"};
  height: 40px;
  padding: 0 16px;
`;

const Text = styled(AppText)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
