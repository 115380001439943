import * as React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { useState } from "react";

interface AppFileUploadProps {
  currency?: boolean;
  error?: string;
  field?: any;
  form?: any;
  width?: number;
  fontSize?: number;
  textAlign?: string;
  /**
   * When there is no title, leave out a top margin
   */
  noTopMargin?: boolean;
}

const StyledAppInput = styled.input<AppFileUploadProps>`
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: 50px;
  padding-left: ${(props) => (props.currency ? 25 : 5)}px;
  border-width: 0;
  border-color: transparent;
  margin-top: ${(props) => (!props.title ? (props.noTopMargin ? 0 : 19) : 0)}px;
  border-bottom-color: ${(props) => (props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  border-bottom-width: 2px;
  font-size: ${(props) => (!!props.fontSize ? props.fontSize : 36)}px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  box-sizing: border-box;
  color: ${theme.BLACK_COLOR};
  background-color: transparent;
  caret-color: ${theme.PRIMARY500};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  :focus {
    border-bottom-color: ${theme.PRIMARY500};
    outline: none;
  }
  ::placeholder {
    opacity: 0.4;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  }
`;

StyledAppInput.defaultProps = {
  noTopMargin: false,
};

const StyledFileInput = styled.input<AppFileUploadProps>`
  display: none;
`;
const FileLabel = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 14px 12px;
  border-radius: 4px;
  background: ${theme.BLACK_COLOR};
  color: #ffffff;
  cursor: pointer;
`;

export const AppFileUpload = ({
  currency = false,
  title,
  error,
  field,
  form,
  // form: { errors, touched },
  ...props
}: AppFileUploadProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  return (
    <Div width={props.width}>
      {!!title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      {currency && (
        <LeftText error={!!error} focused={isFocused}>
          $
        </LeftText>
      )}
      {currency && (
        <RightText error={!!error} focused={isFocused}>
          M
        </RightText>
      )}
      <div>
        <FileLabel htmlFor="">Upload New</FileLabel>
        <StyledFileInput
          {...(props as any)}
          onFocus={toggleFocus}
          onBlur={toggleFocus}
          currency={currency}
          title={title}
        />
      </div>
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

interface DivProps {
  width?: number;
}

const Div = styled.div<DivProps>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  display: flex;
  flex-direction: column;
`;

interface TextFocus {
  focused?: boolean;
  error?: boolean;
}

const LeftText = styled.p<TextFocus>`
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.focused ? theme.PRIMARY500 : props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

const RightText = styled.p<TextFocus>`
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${theme.PRIMARY_FONT};
  color: ${(props) => (props.focused ? theme.PRIMARY500 : props.error ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

RightText.defaultProps = {
  focused: false,
  error: false,
};

LeftText.defaultProps = {
  focused: false,
  error: false,
};

const InputTitle = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.ATTENTION700};
  margin: 0;
`;
