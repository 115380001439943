import React, { useCallback } from "react";
import styled from "styled-components";
import { AllTokens, TokenForFE } from "../../types/StateTypes";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { theme } from "./../../utils/theme";
import { AppText, SkeletonBlock } from "./../UI";
import { PhoenixAppButton } from "./../UI/Phoenix";

const Tokens = ({
  loading,
  tokens,
  ignoreOverflow,
  compressed,
}: {
  loading: boolean;
  tokens: AllTokens | undefined;
  ignoreOverflow?: boolean;
  compressed?: boolean;
}) => {
  const handleTooltipAfterShow = useCallback((e) => {
    e.stopPropagation();
    // get the token value
    const clickedButton = e?.currentTarget;

    const token = clickedButton?.textContent;

    if (token) navigator?.clipboard?.writeText(token);
  }, []);

  if (loading) {
    const loadingPattern = [
      [100, 1],
      [90, 1.5],
      [120, 2],
      [72, 2.5],
      [90, 3],
      [120, 1.5],
      [72, 2],
      [100, 2],
      [90, 2.5],
      [80, 3],
      [72, 1.5],
      [100, 2],
      [120, 2.5],
      [80, 3],
      [100, 3.5],
    ];
    return (
      <Main>
        <SkeletonBlock width={60} height={20} borderRadius="4px" delayNumber={0.5} />
        <TokensContainer ignoreOverflow={ignoreOverflow}>
          {loadingPattern?.map((p, index) => (
            <SkeletonBlock key={index} width={p[0]} height={38} borderRadius="8px" delayNumber={p[1]} />
          ))}
        </TokensContainer>

        <SkeletonBlock width={60} height={20} borderRadius="4px" delayNumber={1.5} />
        <TokensContainer ignoreOverflow={ignoreOverflow}>
          {loadingPattern?.map((p, index) => (
            <SkeletonBlock key={index} width={p[0]} height={38} borderRadius="8px" delayNumber={p[1] + 1.5} />
          ))}
        </TokensContainer>
      </Main>
    );
  }

  const TokensSection = ({
    sectionTokens,
    sectionLabel,
    ignoreOverflow,
    compressed,
  }: {
    sectionTokens: TokenForFE[];
    sectionLabel: string;
    ignoreOverflow?: boolean;
    compressed?: boolean;
  }) => {
    return (
      <>
        <PhoenixStyledTooltip
          id="tokens-tooltip"
          event="click"
          eventOff="mouseleave"
          delayHide={800}
          backgroundColor={theme.SUCCESS200}
          textColor={theme.SUCCESS500}
          place="bottom"
          afterShow={handleTooltipAfterShow}
        />
        <PhoenixStyledTooltip id="fallback-tooltip" width={250} />
        <AppText fontSize={12} fontWeight={500} style={{ color: theme.BLACK_COLOR }}>
          {sectionLabel} Tokens
        </AppText>
        <TokensContainer ignoreOverflow={ignoreOverflow}>
          {sectionTokens?.map((token: TokenForFE) => (
            <FallbackHoverDiv
              key={token?.token}
              id="fallback-tooltip"
              data-tip={
                sectionLabel === "Custom Field"
                  ? "If a custom field token is used and has more than one value, all values will be added and comma separated"
                  : token?.fallback?.length
                  ? `If there is no value in this field, we will use "${token?.fallback}"`
                  : `If there is no value in this field, the token will be blank`
              }
              data-for="fallback-tooltip"
            >
              <PhoenixAppButton
                data-tip="Token copied to clipboard"
                data-for="tokens-tooltip"
                buttonType={compressed ? "tertiary" : "secondary"}
                variant="brand"
                buttonTextFontSize={10}
                padding="8px"
              >
                {token?.token_formatted}
              </PhoenixAppButton>
            </FallbackHoverDiv>
          ))}
        </TokensContainer>
      </>
    );
  };

  return (
    <Main>
      <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
        Tokens
      </AppText>
      <AppText fontSize={12} fontWeight={500} style={{ color: theme.NEUTRAL300 }}>
        Select a token below and paste it into your message to automatically include the corresponding information.
      </AppText>
      {tokens &&
        Object.entries(tokens)?.map(([sectionLabel, sectionTokens]) => (
          <TokensSection
            key={sectionLabel}
            sectionTokens={sectionTokens}
            sectionLabel={sectionLabel}
            ignoreOverflow={ignoreOverflow}
            compressed={compressed}
          />
        ))}
    </Main>
  );
};

const FallbackHoverDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const TokensContainer = styled.div<{ ignoreOverflow?: boolean }>`
  width: 100%;
  /* min-height: 100px; */
  height: fit-content;
  max-height: ${({ ignoreOverflow }) => (ignoreOverflow ? "none" : "400px")};
  overflow-y: ${({ ignoreOverflow }) => (ignoreOverflow ? "none" : "auto")};
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export { Tokens };
