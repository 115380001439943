import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../utils/theme";
import { AppText, DarkDiv, FlexDiv } from "../UI";
import { ModalContext } from "../../context";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect, PhoenixTextArea, PhoenixAppButton } from "../UI/Phoenix";
import { xIcon } from "../../images/NewDesign";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import ReactTooltip from "react-tooltip";

interface CoachingNoteModalProps {
  conference_id: string;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  duration: string;
  addCoachingNoteLoading: boolean;
}

export const CoachingNoteModal: React.FC<CoachingNoteModalProps> = ({
  conference_id,
  addCoachingNote,
  duration,
  addCoachingNoteLoading,
}) => {
  const { setShowCoachingNotesModal } = useContext(ModalContext);

  const [text, setText] = useState<string>("");
  const [timestamp, setTimestamp] = useState<string>("");

  const isOverOneHour = useMemo(() => parseInt(duration) > 3600, [duration]);

  const timestampValid = useMemo(() => {
    const split = timestamp?.split(":");
    const valid =
      !timestamp ||
      (split?.length === 2 && !!split[0]?.length && !!split[1]?.length) ||
      (split?.length === 3 && !!split[0]?.length && !!split[1]?.length && !!split[2]?.length);
    return valid;
  }, [timestamp]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [timestampValid, isOverOneHour]);

  const disabled = useMemo(() => !timestampValid || !text, [timestampValid, text]);

  const convertTimestampToSeconds = (timestamp: string, isOverOneHour: boolean): number => {
    const parts = timestamp.split(":")?.map(Number);
    if (isOverOneHour) {
      // HH:MM:SS format
      const [hours, minutes, seconds] = parts;
      return hours * 3600 + minutes * 60 + (seconds || 0);
    } else {
      // MM:SS format
      const [minutes, seconds] = parts;
      return minutes * 60 + (seconds || 0);
    }
  };

  const formattedTimestamp = useMemo(() => {
    if (!timestampValid || !timestamp) return 0;
    return convertTimestampToSeconds(timestamp, isOverOneHour);
  }, [timestampValid, timestamp, isOverOneHour]);

  const handleTimestampChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = e.target.value;
      let parts = newValue.split(":");
      let isValid = true;

      if (newValue === "") return;

      // Check and adjust parts based on hour format
      if (isOverOneHour) {
        // HH:MM:SS format
        if (parts[0]) {
          const hours = Number(parts[0]);
          if (isNaN(hours) || hours > 23) isValid = false;
        }
        if (parts[1] && (parts[1].length > 2 || !/^\d*$/.test(parts[1]) || Number(parts[1]) > 59)) isValid = false;
        if (parts[2] && (parts[2].length > 2 || !/^\d*$/.test(parts[2]) || Number(parts[2]) > 59)) isValid = false;
        if (newValue.length > 8) isValid = false;
      } else {
        // MM:SS format
        if (parts[0]) {
          const minutes = Number(parts[0]);
          if (isNaN(minutes) || minutes > 720) {
            parts[0] = "720";
            newValue = parts.join(":");
          }
        }
        if (parts[1] && (parts[1].length > 2 || !/^\d*$/.test(parts[1]) || Number(parts[1]) > 59)) isValid = false;
        if (newValue.length > 5) isValid = false;
      }

      if (isValid) {
        setTimestamp(newValue);
      }
    },
    [timestamp, isOverOneHour],
  );

  return (
    <>
      <DarkDiv />
      <PhoenixStyledTooltip id="call-library-tooltip" />
      <ModalContainer>
        <HeaderDiv>
          <AppText fontSize={16} fontWeight={600} lineHeight={22}>
            Add Coaching Note
          </AppText>
          <PhoenixIcon svg={xIcon} size={24} pointer onClick={() => setShowCoachingNotesModal(false)} />
        </HeaderDiv>

        <ModalBody>
          <PhoenixInput
            titleText="Time Stamp"
            titleTextSpacing={8}
            width={104}
            height={40}
            displayNoContextText
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTimestampChange(e)}
            value={timestamp}
            variant={timestampValid ? "primary" : "error"}
            style={{ boxShadow: timestampValid ? "none" : `0px 0px 3px 1px ${theme.DANGER600}` }}
            data-tip={isOverOneHour ? "Duration must be formatted 'HH:MM:SS'" : "Duration must be formatted 'MM:SS'"}
            data-for={!timestampValid ? "call-library-tooltip" : ""}
          />

          {/* <PhoenixMultiSelect
            name="note_type"
            width={376}
            options={[]}
            titleText="Note Type"
            titleTextSpacing={8}
            isMulti={false}
          /> */}

          <FlexDiv direction="column" gap={8} padding="140px 0px 0px 0px">
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Write a Note
            </AppText>
            <PhoenixTextArea
              name="note"
              width={376}
              height={92}
              placeholder="Write a note"
              value={text}
              onChange={(e) => text.length < 160 && setText(e.target.value)}
              fixedSize
            />
          </FlexDiv>
        </ModalBody>

        <ModalFooter>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            onClick={() => setShowCoachingNotesModal(false)}
          >
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            onClick={() => {
              addCoachingNote({
                variables: {
                  conference_id,
                  text,
                  start: formattedTimestamp,
                  end: formattedTimestamp,
                },
              });
            }}
            disabled={disabled || addCoachingNoteLoading}
          >
            Save
          </PhoenixAppButton>
        </ModalFooter>
      </ModalContainer>
    </>
  );
};

const slideInAnimation = keyframes`
  from {
    right: -456px;
  }

  to {
    right: 0;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 899;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 456px;
  height: 100vh;

  background-color: ${theme.fill.neutral.primary};

  animation: ${slideInAnimation} 0.4s ease forwards;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 100%;
  padding: 0px 40px;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 24px 40px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 40px 24px 40px;
`;

export const formatTime = (value: string): string => {
  const [hours, minutes, seconds] = value.split(":")?.map(Number);

  if (isNaN(minutes) || isNaN(seconds)) {
    return "0:00";
  }

  let totalSeconds = (hours || 0) * 3600 + minutes * 60 + seconds;

  const formattedHours = Math.floor(totalSeconds / 3600);
  const formattedMinutes = Math.floor((totalSeconds % 3600) / 60);
  const formattedSeconds = totalSeconds % 60;

  if (formattedHours > 0) {
    return `${formattedHours}:${formattedMinutes.toString().padStart(2, "0")}:${formattedSeconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds.toString().padStart(2, "0")}`;
  }
};

export const incrementTime = (time: string): string => {
  const [hours, minutes, seconds] = time.split(":")?.map(Number);
  let totalSeconds = (hours || 0) * 3600 + minutes * 60 + seconds + 1;
  return formatTime(totalSeconds.toString());
};

export const decrementTime = (time: string): string => {
  const [hours, minutes, seconds] = time.split(":")?.map(Number);
  let totalSeconds = Math.max((hours || 0) * 3600 + minutes * 60 + seconds - 1, 0);
  return formatTime(totalSeconds.toString());
};
