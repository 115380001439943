import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, Loading, AppErrorText } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../../Field";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { sortByMonthAndYear } from "../../../utils/sorts";
import { useHistory } from "react-router-dom";
import { appToast, successToast } from "../../../utils/toast";
import { iconNewCaretDown } from "../../../images";
import { loggedInUser } from "../../../apollo/cache";
import { values } from "lodash";
import ReactTooltip from "react-tooltip";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

interface MyFormikProps {
  win_rep_day_goal: number;
  set_goal: number;
  hold_goal: number;
}

const stepRestingRules = Yup.object().shape({
  win_rep_day_goal: Yup.number().notRequired(),
  set_goal: Yup.number().notRequired(),
  hold_goal: Yup.number().notRequired(),
});

const ORGANIZATION_DATA = gql`
  {
    fetchOrganization {
      id
      win_rep_day_goal
      set_goal
      hold_goal
    }
  }
`;

const UPDATE_ORGANIZATION_REST_RULE = gql`
  mutation updateOrganizationSettings($win_rep_day_goal: Float!, $set_goal: Float!, $hold_goal: Float!) {
    updateOrganizationSettings(win_rep_day_goal: $win_rep_day_goal, set_goal: $set_goal, hold_goal: $hold_goal) {
      id
      win_rep_day_goal
      set_goal
      hold_goal
    }
  }
`;

const StepRepGoals: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const history = useHistory();

  const short_resting_min = 0;
  const short_resting_max = 2 ** 32 - 1;
  const resting_min = 0;
  const resting_max = 2 ** 32 - 1;
  const skipped_cold_call_rest_min = 0;
  const skipped_cold_call_rest_max = 2 ** 32 - 1;

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    variables: {
      org_id: loggedInUser().organization_id,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateOrganizationSettings, { loading: loadingRules, error: errorRules }] = useMutation(
    UPDATE_ORGANIZATION_REST_RULE,
    {
      async onCompleted({ updateOrganizationSettings }) {
        if (!updateOrganizationSettings) {
          appToast("Error updating Rep Goals. Something went wrong.");
          return;
        }
        successToast("Updated Rep Goals");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateOrganizationSettings GraphQL Error: ${message}`,
        });
        console.log("Error in updateOrganizationSettings: ", message);
      },
    },
  );

  if (loadingOrg) return <Loading />;
  if (errorOrg) return <AppErrorText>Error loading data</AppErrorText>;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        win_rep_day_goal: dataOrg.fetchOrganization.win_rep_day_goal,
        set_goal: dataOrg.fetchOrganization.set_goal,
        hold_goal: dataOrg.fetchOrganization.hold_goal,
      }}
      validationSchema={stepRestingRules}
      onSubmit={async (values) => {
        await updateOrganizationSettings({
          variables: {
            win_rep_day_goal: Number(values.win_rep_day_goal),
            set_goal: Number(values.set_goal),
            hold_goal: Number(values.hold_goal),
          },
        });
        if (isOnboarding === true) {
          history.push(`/onboarding?step=${step + 1}`);
        }
      }}
    >
      {({ dirty, submitForm, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        return (
          <DisappearingDiv>
            <LocalWrapper isOnboarding={isOnboarding}>
              <ReactTooltip
                multiline
                place="top"
                effect="solid"
                css={{
                  maxWidth: 600,
                  lineHeight: 1.4,
                  textAlign: "center",
                  fontFamily: theme.PRIMARY_FONT,
                }}
                id="resting"
                backgroundColor={theme.PRIMARY800}
                getContent={(dataTip) => (
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    {dataTip}
                  </span>
                )}
              />
              {/* <TopAlignDiv> */}
              <TopContentDiv>
                {/* <SegmentTitle>Resting Rules</SegmentTitle> */}

                <LabelText>Win/Rep/Day Goal</LabelText>
                <RelDiv>
                  <NumberInput
                    name="win_rep_day_goal"
                    type="number"
                    max={skipped_cold_call_rest_max}
                    min={skipped_cold_call_rest_min}
                    value={values.win_rep_day_goal}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= skipped_cold_call_rest_max && value >= skipped_cold_call_rest_min) {
                        setFieldValue("win_rep_day_goal", value);
                      }
                    }}
                  />

                  <AbsDivTop
                    disabled={values.win_rep_day_goal >= skipped_cold_call_rest_max}
                    onClick={() =>
                      values.win_rep_day_goal < skipped_cold_call_rest_max &&
                      setFieldValue("win_rep_day_goal", Number(values.win_rep_day_goal) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.win_rep_day_goal <= skipped_cold_call_rest_min}
                    onClick={() =>
                      values.win_rep_day_goal > skipped_cold_call_rest_min &&
                      setFieldValue("win_rep_day_goal", Number(values.win_rep_day_goal) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Set Goal
                  {/* <span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "} */}
                  {/* <TooltipText
                    data-tip="If a rep releases a lead, this is the minimum amount of time that they will not be reassigned the same lead."
                    data-for="resting"
                  >
                    ?
                  </TooltipText> */}
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="set_goal"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.set_goal}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("set_goal", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.set_goal >= resting_max}
                    onClick={() =>
                      values.set_goal < resting_max && setFieldValue("set_goal", Number(values.set_goal) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.set_goal <= resting_min}
                    onClick={() =>
                      values.set_goal > resting_min && setFieldValue("set_goal", Number(values.set_goal) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Hold Goal
                  {/* <span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "} */}
                  {/* <TooltipText
                    data-tip="The amount of time leads are removed from circulation if they enter the resting phase prior to a demo."
                    data-for="resting"
                  >
                    ?
                  </TooltipText> */}
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="hold_goal"
                    type="number"
                    max={short_resting_max}
                    min={short_resting_min}
                    value={values.hold_goal}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= short_resting_max && value >= short_resting_min) {
                        setFieldValue("hold_goal", value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.hold_goal >= short_resting_max}
                    onClick={() =>
                      values.hold_goal < short_resting_max && setFieldValue("hold_goal", Number(values.hold_goal) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.hold_goal <= short_resting_min}
                    onClick={() =>
                      values.hold_goal > short_resting_min && setFieldValue("hold_goal", Number(values.hold_goal) - 1)
                    }
                  />
                </RelDiv>
              </TopContentDiv>
              {/* {JSON.stringify(values)} */}
              {isOnboarding === true ? (
                <CenterContentDiv>
                  <OldSaveAndContinueButton type="submit" disabled={!dirty} onClick={submitForm}>
                    Save & Continue
                  </OldSaveAndContinueButton>
                </CenterContentDiv>
              ) : (
                <CenterDiv>
                  <SaveAndContinueButton
                    type="submit"
                    disabled={!dirty || loadingRules}
                    onClick={submitForm}
                    variant={"primary"}
                  >
                    Save Changes
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </LocalWrapper>
          </DisappearingDiv>
        );
      }}
    </Formik>
  );
};

const SegmentTitle = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
`;

const OldSaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const CenterContentDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

interface CaretProps {
  disabled: boolean;
  bottom?: boolean;
}

const AbsDivTop = styled.div<CaretProps>`
  display: flex;
  background: url(${iconNewCaretDown}) no-repeat;
  background-position: 5px 13px;
  position: absolute;
  right: 10px;
  top: ${(props) => (props.bottom ? "20px" : "0px")};
  transform: ${(props) => (props.bottom ? "rotate(180deg)" : "rotate(0deg)")};
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const LabelText = styled(AppText)`
  margin-bottom: 8px;
`;

const TooltipText = styled.span`
  background-color: ${theme.NEUTRAL200};
  padding: 0px 5px;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  margin-left: 8px;
  opacity: 0.7;
  font-size: 12px;
  cursor: help;
`;

const NumberInput = styled.input`
  height: 40px;
  padding-left: 12px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 2px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;
interface IsOnboardingProp {
  isOnboarding?: boolean;
}

const RevenueText = styled(AppText)`
  width: 150px;
  font-size: 14px;
`;

const LocalWrapper = styled.div<IsOnboardingProp>`
  width: 420px;
  margin: ${(props) => (props.isOnboarding ? "20px auto" : "0px")};
`;

const TopContentDiv = styled.div`
  width: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SaveAndContinueButton = styled(NewAppButton)`
  width: 155px;
  height: 48px;
`;

export { StepRepGoals };
