import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, AppSelect, Loading, AppErrorText } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql, useLazyQuery } from "@apollo/client";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../Field";
import { toast } from "react-toastify";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { appToast } from "../../utils/toast";
import { NewAppButton } from "../UI/NewAppButton";
import { newCloseModal } from "../../images";
import { useEffect } from "react";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  /**
   * Only pass in the id if it is an edit operation
   */
  id?: string;
  onDeleteFunction: any;
  fieldType: string;
  fieldName?: string;
  customFields?: boolean;
  loading: boolean;
  userDelete?: boolean;
  deleteUserID?: string;
  keepOpenOnLoading?: boolean;
}

const GET_LEADS_IN_PROGRESS = gql`
  query fetchInprogressLead($user_id: String!) {
    fetchInprogressLead(user_id: $user_id)
  }
`;

const DeleteField: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  id,
  onDeleteFunction,
  fieldType = "",
  fieldName,
  customFields = false,
  loading = false,
  userDelete = false,
  deleteUserID,
  keepOpenOnLoading,
  ...propsField
}) => {
  const [leadsInProgress, { data: dataIP, loading: loadingIP, error: errorIP, refetch: refetchIP }] = useLazyQuery(
    GET_LEADS_IN_PROGRESS,
    {
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (userDelete && !!deleteUserID) {
      leadsInProgress({
        variables: {
          user_id: deleteUserID,
        },
      });
    }
  }, [userDelete]);

  return (
    <Modal open={blinds} onClose={() => close()} closeButtonSize={16}>
      <ScrollingDiv>
        {customFields && (
          <DeleteText>
            <span style={{ fontWeight: 600 }}>
              Delete “{fieldName}” {fieldType}?
            </span>
            <br />
            Removing a {fieldType.toLowerCase()} will permanently remove this value from any lead that currently uses
            it. This cannot be undone.
          </DeleteText>
        )}
        {userDelete && (
          <>
            <DeleteText style={{ fontWeight: 600, margin: "0px auto" }}>
              Rep's Current Leads In Progress:{" "}
              {loadingIP ? "..." : errorIP ? "?" : !!dataIP && `${dataIP.fetchInprogressLead}`}
            </DeleteText>
            <br />
            <DeleteText>
              Please contact <a href="mailto:support@sellfire.com">support@sellfire.com</a> for assistance bulk
              re-assigning the leads above to one or multiple other Reps on your team. If the Rep isn’t showing any in
              progress leads or if you don’t want to re-assign the leads to other Reps, then please proceed with
              deleting this Rep.
            </DeleteText>
          </>
        )}
      </ScrollingDiv>
      <SubmitDiv>
        <AddEmailCancelButton onClick={close}>No, Go Back</AddEmailCancelButton>
        <AddEmailButton
          type="submit"
          onClick={() => {
            onDeleteFunction(fieldType === "Custom Field" ? id : fieldName);
            if (keepOpenOnLoading) {
              return;
            }
            close();
          }}
          disabled={loading}
        >
          {loading ? <Loading /> : `Yes, ${userDelete ? "Delete" : "Remove"} ${fieldType}`}
        </AddEmailButton>
      </SubmitDiv>
    </Modal>
  );
};

const DeleteText = styled(AppText)`
  font-size: 14px;
  text-align: center;
`;

const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;
  height: 88px;
  width: 100%;
  gap: 12px;
  padding: 24px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 186px 186px;
  grid-template-rows: 1fr;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 6px;
  cursor: pointer;
  z-index: 5;
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  /* margin-bottom: 32px; */
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionTaskContainer = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DropdownOptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const DropdownOption = styled(InputField)`
  margin: 0;
  text-align: left;
  border-radius: 2px;
  /* height: 45px; */
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const FieldSelect = styled(FormSelectField)`
  /* height: 45px; */
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

interface ContainerDivProps {
  blinds: boolean;
}

const PopupContainerDiv = styled.div<ContainerDivProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
`;

const PopupInputLabel = styled(AppText)`
  /* font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px; */
  margin-bottom: 8px;
`;

const FieldInput = styled(InputField)`
  margin: 0;
  text-align: left;
  border-radius: 2.9px;
  height: 45px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const AddEmailCancelButton = styled(NewAppButton)`
  /* font-size: 10px;
  font-weight: 600;
  width: 136px;
  height: 32px;
  border-radius: 2px;
  text-transform: uppercase;
  margin: auto; */
  width: 96px;
  border-radius: 2px;
  text-transform: capitalize;
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-style: normal;
  color: ${theme.PRIMARY500};
  width: auto;
`;

const AddEmailButton = styled(NewAppButton)`
  width: auto;
  height: 40px;
  border-radius: 2px;
  color: ${theme.ATTENTION700};
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-style: normal;
  /* font-size: 14px;
  font-weight: 600; */
  /* margin: 25px auto; */
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 27px; */
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
}

const ScrollingDiv = styled.div`
  padding: 48px;
  overflow: auto;
  width: 100%;
  width: calc(24px + 24px + 384px);
  height: auto;
  margin-bottom: 88px;
  overflow: auto;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { DeleteField };
