import styled from "styled-components";
import * as React from "react";
import { AppText, AppErrorText, Loading, Wrapper } from "../UI";
import { theme } from "../../utils/theme";
import * as Sentry from "@sentry/react";
import { useQuery, gql } from "@apollo/client";

export const FETCH_PHONE_NUMBERS = gql`
  query orgPhoneNumbers {
    orgPhoneNumbers
  }
`;

const PhoneNumbers: React.FC = () => {
  const { data, loading, error } = useQuery(FETCH_PHONE_NUMBERS, {
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  if (loading) return <Loading />;
  if (error) return <AppErrorText>Error loading folders</AppErrorText>;

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Phone Numbers page"}>
      <ThisWrapper>
        <MainGrid>
          <TableItem>
            <TitleText>Phone Number</TitleText>
          </TableItem>
          <TableItem>
            <TitleText>Type</TitleText>
          </TableItem>
          <TableItem>
            <TitleText>Area Code(s)</TitleText>
          </TableItem>
          <TableItem>
            <TitleText>Email</TitleText>
          </TableItem>
        </MainGrid>
        {data.orgPhoneNumbers?.map((phone: any) => (
          <MainGrid key={phone.id}>
            <TableItem>
              <ItemText>{phone.number}</ItemText>
            </TableItem>
            <TableItem>
              <ItemText>{phone.type}</ItemText>
            </TableItem>
            <TableItem>
              <ItemText>{phone.area_codes}</ItemText>
            </TableItem>
            <TableItem>
              <ItemText>{phone.email}</ItemText>
            </TableItem>
          </MainGrid>
        ))}
      </ThisWrapper>
    </Sentry.ErrorBoundary>
  );
};

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px 300px 1fr;
`;

const TableItem = styled.div`
  height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: 0.5px solid #000;
`;

const ThisWrapper = styled(Wrapper)`
  margin-left: 300px;
  margin-top: 100px;
  margin-bottom: 50px;
`;

const TitleText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
`;

const ItemText = styled(AppText)`
  font-size: 14px;
  font-weight: 400;
`;

export { PhoneNumbers };
