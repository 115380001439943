import React, { useState, useRef } from "react";
import styled from "styled-components";

import { AppText, Loading, AppErrorText, GradientButton, GhostField, FlexDiv } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { mobileSize } from "src/utils/breakpoints";
import { ReactComponent as SellfireLogo } from "src/images/NewDesign/sellfire_logo_horizontal.svg";

interface MyFormikProps {
  email: string;
}
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Please enter an email address!").email("Please enter a valid email address!"),
});

interface ForgotPasswordResponse {
  resetPassword: boolean;
}

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

const ForgotPassword: React.FC = () => {
  const userEmail = useRef<string>("");
  const history = useHistory();

  const [PasswordResetState, setPasswordResetState] = useState<"completed" | "error" | undefined>(undefined);

  const [resetPassword, { loading: mutationLoading, error: mutationError }] = useMutation<ForgotPasswordResponse>(
    FORGOT_PASSWORD,
    {
      onCompleted({ resetPassword }) {
        console.log("Password reset: ", resetPassword);
        if (!resetPassword) {
          setPasswordResetState("error");
          return;
        }
        setPasswordResetState("completed");
      },
      onError({ message }) {
        console.log("Error sending email for password reset in: ", message);
        Sentry.captureEvent({
          message: `resetPassword GraphQL Error: ${message}`,
        });
      },
    },
  );

  const resendEmail = () => {
    resetPassword({ variables: { email: userEmail.current.toLowerCase() } });
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred on the Forgot Password page"}>
      <Main>
        <LogoWrapper onClick={() => history.push("/login")}>
          <SellfireLogo />
        </LogoWrapper>

        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordSchema}
          onSubmit={({ email }) => {
            userEmail.current = email;
            resetPassword({ variables: { email: email.toLowerCase() } });
          }}
        >
          {({ submitForm, values }: FormikProps<MyFormikProps>) => {
            const submitted = PasswordResetState === "completed";

            if (mutationLoading) {
              return (
                <FormContainer>
                  <Loading />
                </FormContainer>
              );
            }

            if (mutationError || PasswordResetState === "error") {
              return (
                <FormContainer>
                  <AppErrorText style={{ textAlign: "center" }}>
                    Sorry! Something went wrong. Please try again.
                  </AppErrorText>
                </FormContainer>
              );
            }

            if (!submitted) {
              return (
                <FormContainer>
                  <FlexDiv direction="column" align="center" gap={20}>
                    <Title>Reset your password</Title>
                    <Subtitle>We’ll email you a temporary link to reset your password.</Subtitle>
                  </FlexDiv>

                  <FlexDiv align="center" direction="column" gap={40} width="100%">
                    <GhostField label="Email" placeholder="Enter your email address" name="email" />

                    <GradientButton onClick={submitForm} disabled={!values.email}>
                      Send Reset Link
                    </GradientButton>
                  </FlexDiv>
                </FormContainer>
              );
            }

            if (submitted) {
              return (
                <FormContainer>
                  <FlexDiv direction="column" align="center" gap={20}>
                    <Title>Check your email</Title>
                    <Subtitle>
                      Please check the email address <span>{userEmail.current}</span> for instruction to reset your
                      password.
                    </Subtitle>
                  </FlexDiv>

                  <FlexDiv direction="column" align="center" gap={20}>
                    <AppText textAlign="center" fontSize={16} fontWeight={500} color={theme.text.neutral.inverse}>
                      Didn’t receive an email?
                    </AppText>

                    <FlexDiv direction="column" align="center" gap={20}>
                      <GradientButton onClick={resendEmail}>Resend Email</GradientButton>

                      <LoginText to="/login">Back to Login</LoginText>
                    </FlexDiv>
                  </FlexDiv>
                </FormContainer>
              );
            }

            return (
              <FormContainer>
                <AppErrorText>There was an application error. Please contact support.</AppErrorText>
              </FormContainer>
            );
          }}
        </Formik>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const Main = styled.div`
  align-items: center;
  background-image: url(${require("src/images/login-bg.jpeg")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow-y: auto;
  padding: 24px;
  width: 100%;

  @media ${mobileSize} {
    background-image: url(${require("src/images/login-bg-mobile.jpeg")});
  }
`;

const FormContainer = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  max-width: 560px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
  left: 80px;
  position: absolute;
  top: 40px;

  svg {
    height: 40px;
    width: 190px;
  }

  @media ${mobileSize} {
    left: 24px;
    top: 16px;

    svg {
      height: 30px;
      width: 150px;
    }
  }
`;

const Title = styled(AppText)`
  color: ${theme.text.neutral.inverse};
  font-size: 48px;
  font-weight: 500;
  text-align: center;

  @media ${mobileSize} {
    font-size: 30px;
  }
`;

const Subtitle = styled(AppText)`
  color: ${theme.text.neutral.inverse};
  font-size: 20px;
  text-align: center;

  span {
    color: #8fc9ff;
  }

  @media ${mobileSize} {
    font-size: 16px;
  }
`;

const LoginText = styled(Link)`
  color: ${theme.text.neutral.inverse};
  font-size: 16px;
  font-weight: 600;
  margin: 16px;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

export { ForgotPassword };
