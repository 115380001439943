import React, { useState } from "react";
import styled from "styled-components";
import { useField } from "formik";
import { FlexDiv } from "src/Components/UI";
import { eye, eye_off } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { PhoenixIcon } from "./Phoenix";

const formatErrorMessage = (error?: string | Record<string, string>) => {
  if (!error) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  if (error.required) {
    return error.required;
  }

  if (error.min) {
    return error.min;
  }

  if (error.uppercase) {
    return error.uppercase;
  }

  if (error.lowercase) {
    return error.lowercase;
  }

  if (error.number) {
    return error.number;
  }

  if (error.special) {
    return error.special;
  }

  if (error.spaces) {
    return error.spaces;
  }

  if (error.weakPassword) {
    return error.weakPassword;
  }

  if (error.match) {
    return error.match;
  }
};

export const GhostField = ({
  name,
  label,
  placeholder,
  type,
}: {
  name: string;
  label: string;
  placeholder: string;
  type?: string;
}) => {
  const [field, meta] = useField(name);
  const [isPasswordField] = useState(type === "password");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const error = formatErrorMessage(meta.touched ? meta.error : undefined);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <FlexDiv direction="column" gap={8} width="100%">
      <Label>{label}</Label>
      <InputWrapper>
        <Input
          title={label}
          placeholder={placeholder}
          {...field}
          error={!!error}
          type={isPasswordField ? (isPasswordVisible ? "text" : "password") : type}
        />

        {isPasswordField ? (
          <PhoenixIcon
            svg={isPasswordVisible ? eye : eye_off}
            color={theme.WHITE_COLOR}
            onClick={togglePasswordVisibility}
            size={16}
            style={{ position: "absolute", right: 16, top: 16 }}
          />
        ) : null}
      </InputWrapper>
      {error ? <InputErrorText>{error}</InputErrorText> : null}
    </FlexDiv>
  );
};

const Label = styled.label`
  color: ${theme.WHITE_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{ error?: string | boolean }>`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border: 1px solid ${({ error }) => (error ? "#E4626F" : "rgba(255, 255, 255, 0.06)")};
  color: ${theme.WHITE_COLOR};
  height: 48px;
  padding: 12px 8px 12px 16px;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const InputErrorText = styled.p`
  color: #e4626f;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: 400;
`;
