import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { SHOW_AUTO_DIALER, IS_LOGGED_IN } from "../apollo/query";
import { useMutation, useQuery } from "@apollo/client";
import { Loading, SkeletonBlock } from "../Components/UI";
import { CallComponent } from "../twilio/CallComponent";
import { loggedInUser } from "../apollo/cache";
import { Onboarding } from "../Components/Pages";
import { DevicePermission } from "../twilio/DevicePermission";
import { InfoHeader, TabNav } from "../Components/Segments";
import styled, { css } from "styled-components";
import { AutoDialTimerComponent, CallMonitorComponent } from "../Components/Smart/";
import {
  CallContext,
  AutoDialContextProvider,
  NotificationsProvider,
  LiveUserStatusProvider,
  ModalContext,
} from "../context";
import { AutoDialContext } from "../context/AutoDialContext";
import { MakeSomeHeightComponent } from "../Components/Dumb";
import { theme } from "../utils/theme";
import gql from "graphql-tag";
import { getLocalStorage } from "../utils/misc";
import { SystemConfigNav } from "../Components/Segments/SystemConfigNav";
import { useFlags } from "launchdarkly-react-client-sdk";

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PrivateRouteProps;

export const PrivateRouteComponent = ({
  component: Component,
  path,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  const UPDATE_USER_STATUS = gql`
    mutation updateUserStatus($status: STATUS!) {
      updateUserStatus(status: $status) {
        id
        email
        status
      }
    }
  `;

  const { data, loading } = useQuery(IS_LOGGED_IN);

  const { data: cacheOrgData } = useQuery(SHOW_AUTO_DIALER);

  const { makeSaleConfig } = useFlags();

  const showAutoDialer = cacheOrgData?.showAutoDialer;

  const [updateUserStatus, { loading: loadStatus, error: errorStatus }] = useMutation(UPDATE_USER_STATUS, {
    onCompleted({ updateUserStatus }) {
      if (!updateUserStatus) {
        return;
      }
    },
    onError({ message }) {
      console.log(message);
    },
  });

  const eventListenerRef = (e: BeforeUnloadEvent) => {
    const savedDevices = getLocalStorage("saved_devices", []);
    !savedDevices.length && updateUserStatus({ variables: { status: "OFFLINE" } });

    const isCallLiveInTab = JSON.parse(sessionStorage.getItem("userIsOnCall") || "false");
    if (!!isCallLiveInTab) {
      localStorage.setItem("userIsOnCallLocal", JSON.stringify(false));
      sessionStorage.setItem("userIsOnCall", JSON.stringify(false));
      window.dispatchEvent(new Event("storage"));
    }
  };

  React.useEffect(() => {
    window.removeEventListener("unload", eventListenerRef);
    window.addEventListener("unload", eventListenerRef);
  }, []);

  if (loading) {
    return (
      <FullViewportDiv>
        <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />
      </FullViewportDiv>
    );
  }
  const is_authenticated = data.isLoggedIn;
  return (
    <div id="root">
      <NotificationsProvider>
        <CallComponent />
        <DevicePermission />

        <AutoDialContextProvider>
          <MakeSomeHeightComponent />
          {(loggedInUser().role === "SDR" || loggedInUser().role === "AE") && showAutoDialer && (
            <AutoDialTimerComponent />
          )}
        </AutoDialContextProvider>
        <BackgroundFlexDiv>
          {path !== "/onboarding/" && <InfoHeader />}
          {path.includes("system-config") ? <SystemConfigNav /> : <TabNav />}
          <MainContent path={path}>
            <Route
              {...rest}
              component={(props: any) => {
                if (is_authenticated) {
                  const is_onboarded = loggedInUser()?.organization?.onboardComplete;
                  console.log("in_onboarded: ", is_onboarded);
                  if (!is_onboarded) {
                    return <Onboarding {...props} />;
                  }
                  return <Component {...props} />;
                } else {
                  return <Redirect to="/login/" />;
                }
              }}
            />
            <LiveUserStatusProvider>
              {(loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") && <CallMonitorComponent />}
            </LiveUserStatusProvider>
          </MainContent>
        </BackgroundFlexDiv>
      </NotificationsProvider>
    </div>
  );
};

const FullViewportDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 16px;
`;

const MainContent: React.FC<{ path: string }> = ({ children, path }) => {
  const { isSystemConfigNavExpanded } = useContext(ModalContext);
  return (
    <MainContentDiv
      expanded={!!isSystemConfigNavExpanded && !!path.includes("system-config")}
      isSystemConfig={!!path.includes("system-config")}
    >
      {children}
    </MainContentDiv>
  );
};

const MainContentDiv = styled.div<{ expanded: boolean; isSystemConfig: boolean }>`
  width: 100%;
  overflow-y: auto;

  margin-left: ${(props) => (props.expanded ? "250px" : "0px")};

  ${(props) =>
    props.isSystemConfig &&
    css`
      transition: margin-left 0.25s ease-in-out;
    `};
`;

const BackgroundFlexDiv = styled.div`
  display: flex;
  background-color: ${theme.surface.neutral.tertiary};
  height: 100vh;
`;

const PrivateRoute = PrivateRouteComponent;

export default PrivateRoute;
