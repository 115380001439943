import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppText } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import { useMutation, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { appToast } from "../../utils/toast";
import { PhoenixInputField } from "../Field/Phoenix";
import { PhoenixAppButton } from "../UI/Phoenix";
import { MixpanelActions } from "src/services/mixpanel";

interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  saveNewNote?: any;
  timeText?: string;
  onDelete?: any;
  nameText?: string;
  updateNoteFunction?: (nameText: string) => void;
  folderID?: string;
}

const CREATE_NEW_FOLDER = gql`
  mutation createNewFolder($name: String!) {
    createNewFolder(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_FOLDER_NAME = gql`
  mutation updateFolderName($folder_id: String!, $name: String!) {
    updateFolderName(folder_id: $folder_id, name: $name) {
      id
      name
    }
  }
`;

const folderNameSchema = Yup.object().shape({
  folder_name: Yup.string().notRequired(),
});

interface MyFormikProps {
  folder_name: string;
}

const UpdateFolderNamePopup: React.FC<DisappearingDivProps> = ({
  blinds,
  setBlinds,
  saveNewNote,
  timeText,
  onDelete,
  nameText,
  folderID = "",
  updateNoteFunction,
}) => {
  const [updateFolderName, { loading: updateFolderLoading, error: updateFolderError }] = useMutation(
    UPDATE_FOLDER_NAME,
    {
      async onCompleted({ updateFolderName }) {
        console.log("updateFolderName: ", updateFolderName);
        if (!updateFolderName) {
          appToast("Error requesting edit. Something went wrong.");
          return;
        }
        setBlinds(false);
        appToast("Updated folder name!");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateFolderName GraphQL Error: ${message}`,
        });
        console.log("Error in updateFolderName: ", message);
      },
      refetchQueries: ["fetchViewableFolders", "fetchFolder", "userFolders"],
    },
  );

  const [createNewFolder, { loading: addFolderLoading, error: addFolderError }] = useMutation(CREATE_NEW_FOLDER, {
    async onCompleted({ createNewFolder }) {
      console.log("createNewFolder: ", createNewFolder);
      if (!createNewFolder) {
        appToast("Error creating new folder");
        return;
      }
      MixpanelActions.track("Folder Created");
      setBlinds(false);
      appToast("Added new folder!");
    },
    onError({ message }) {
      setBlinds(false);
      appToast(message);
      Sentry.captureEvent({
        message: `createNewFolder GraphQL Error: ${message}`,
      });
      console.log("Error in createNewFolder: ", message);
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder", "userFolders"],
  });

  return (
    <Modal open={blinds} onClose={() => setBlinds(false)} popupAnimation>
      <Formik
        enableReinitialize
        initialValues={{
          folder_name: nameText ?? "New folder",
        }}
        validationSchema={folderNameSchema}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }: FormikProps<MyFormikProps>) => {
          return (
            <>
              <ContentDiv>
                <PopupText1 style={{ marginBottom: !!timeText ? "0px" : "34px" }}>
                  {!!folderID ? "Rename Folder" : "New Folder"}
                </PopupText1>
                {!!timeText && <PopupText2>{timeText}</PopupText2>}
                <PhoenixInputField
                  name="folder_name"
                  placeholder="Folder name"
                  value={values.folder_name}
                  onChange={(e: any) => setFieldValue("folder_name", e.target.value)}
                  displayNoContextText
                  width={380}
                  titleText="Folder Name"
                  titleTextSpacing={8}
                />
              </ContentDiv>
              <ButtonDiv>
                <PhoenixAppButton
                  buttonType="secondary"
                  variant="danger-outline"
                  onClick={() => setBlinds(false)}
                  uppercase
                  buttonTextFontSize={10}
                  style={{ letterSpacing: "1px" }}
                >
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton
                  buttonType="secondary"
                  uppercase
                  buttonTextFontSize={10}
                  style={{ letterSpacing: "1px" }}
                  onClick={async () => {
                    if (!!folderID) {
                      await updateFolderName({
                        variables: {
                          folder_id: folderID,
                          name: values.folder_name,
                        },
                      });
                    } else {
                      await createNewFolder({
                        variables: {
                          name: values.folder_name,
                        },
                      });
                    }
                  }}
                  disabled={updateFolderLoading || addFolderLoading}
                >
                  Continue
                </PhoenixAppButton>
              </ButtonDiv>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const PopupText2 = styled(AppText)`
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  margin-top: 9px;
  margin-bottom: 34px;
`;

const PopupText1 = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 476px;
  height: 240px;
  padding: 40px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

export { UpdateFolderNamePopup };
