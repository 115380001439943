import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { theme } from "../../utils/theme";
import { BsArrowRight } from "react-icons/bs";
import { FormMultiSelectField } from "../Field";
import { Formik } from "formik";
import { NewAppButton, AppErrorText, Loading } from "../UI";
import { useQuery, gql } from "@apollo/client";
import { toast } from "react-toastify";
import { ModalContext } from "../../context";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { restAPI } from "../../apollo";
import { useState } from "react";

const GET_LEAD_PHASE = gql`
  query getLeadPhase {
    getLeadPhase
  }
`;

interface MapSalesFieldProps {
  index: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  opsiqPhaseOptions: any[];
  customPhaseOptions: any[];
}

const MapSalesField: React.FC<MapSalesFieldProps> = ({
  index,
  setFieldValue,
  opsiqPhaseOptions,
  customPhaseOptions,
}) => {
  return (
    <FieldDiv>
      <FormMultiSelectField
        name="import_field"
        isMulti={false}
        options={customPhaseOptions ?? []}
        style={{ height: "32px" }}
        onChange={(e: any) => {
          setFieldValue(`fields[${index}].import`, e?.value ?? "");
        }}
      />
      <ArrowDiv>
        <BsArrowRight size={18} />
      </ArrowDiv>
      <FormMultiSelectField
        isMulti={false}
        name="opsiq_field"
        options={opsiqPhaseOptions}
        style={{ height: "32px" }}
        onChange={(e: any) => {
          setFieldValue(`fields[${index}].opsiq`, e?.value ?? "");
        }}
      />
    </FieldDiv>
  );
};

const MapSalesPhases: React.FC = () => {
  const { data: mapSalesData, loading: mapSalesLoading, error } = useQuery(GET_LEAD_PHASE, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { csvModal, setCsvModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  if (error) return <AppErrorText>Error loading phases.</AppErrorText>;
  if (mapSalesLoading || loading)
    return (
      <CenterDiv>
        <Loading />
      </CenterDiv>
    );

  const opsiqPhaseOptions = mapSalesData.getLeadPhase?.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });

  // Create custom options from API response stored in local storage.
  // When should we clear this?
  // Try/Catch block helps avoid parsing error if no data available in local storage.
  const createCustomPhases = () => {
    try {
      const data = JSON.parse(window?.localStorage?.getItem("customPhasesData") ?? "")?.data;
      const options = data?.map((item: string) => {
        return {
          label: item,
          value: item,
        };
      });
      return options;
    } catch (error: any) {
      console.error(error);
      toast("Error uploading custom fields.");
    }
  };

  const customPhaseOptions = createCustomPhases();

  // Cleaner way of creating a 20 length initial value array.
  const initialValuesArray = (amount: number) => {
    let i = 0;
    const initialArray = [];
    while (i < amount) {
      initialArray.push({
        opsiq: undefined,
        import: undefined,
      });
      i++;
    }
    return initialArray;
  };

  return (
    <Main>
      <HeaderDiv>
        <Header>Map Sales Phases</Header>
        <SubHeader>
          Below you are able to map your custom phases to Sellfire phases. Your custom phases have been automatically
          pulled from your CSV upload. These phases will be reflected when you complete your lead import. Leaving a
          custom phase blank will cause that lead to be put under the ColdCallCold phase.
        </SubHeader>
      </HeaderDiv>

      <Formik
        initialValues={{
          fields: initialValuesArray(20),
        }}
        onSubmit={async (values: any) => {
          try {
            // set loading to true
            setLoading(true);
            // Reduce data into object with correct syntax.
            const data = values.fields.reduce((acc: any, cur: any) => ({ ...acc, [cur.import]: cur.opsiq }), {});
            // Filter out undefined values caused by unused fields.
            Object.keys(data).forEach((key: any) => (data[key] === undefined ? delete data[key] : {}));
            const sentData = {
              mapped_phases: data,
            };
            const response = await restAPI.post(`/upload/mapLeadCSV`, sentData, {
              headers: {
                ...restAPI.defaults.headers,
                "content-type": "application/json",
              },
            });

            const newState = {
              num_imported: response.data.num_imported,
              num_updated: response.data.num_updated,
              num_error: response.data.num_errors,
              error_report_url: response.data.error_report,
              num_queued: response.data.num_queued,
              upload_id: response.data.upload_id,
              num_staged: response.data.num_stagged,
              visible: true,
            };
            setCsvModal(newState);
            setLoading(false);
            history.goBack();
          } catch (error: any) {
            setLoading(false);
            console.error(error);
            toast("Error submitting phases.");
          }
        }}
      >
        {({ submitForm, setFieldValue, isValid, values }) => {
          return (
            <>
              <FormContainer>
                <FieldDiv>
                  <FieldHeaderText>Imported Sales Phase</FieldHeaderText>
                  <p></p>
                  <FieldHeaderText>Sellfire Phases</FieldHeaderText>
                </FieldDiv>
                {values.fields?.map((item: any, index: number) => (
                  <MapSalesField
                    index={index}
                    key={index}
                    setFieldValue={setFieldValue}
                    opsiqPhaseOptions={opsiqPhaseOptions}
                    customPhaseOptions={customPhaseOptions}
                  />
                ))}
              </FormContainer>
              <NewAppButton
                style={{ marginTop: "24px", marginBottom: "34px", fontSize: "18px" }}
                onClick={submitForm}
                disabled={loading}
                width={264}
                height={50}
                variant="primary"
                hoverVariant="primary"
              >
                {loading ? <Loading color={theme.WHITE_COLOR} /> : "Complete Import"}
              </NewAppButton>
              <CancelText
                onClick={() => {
                  history.goBack();
                  window.localStorage.removeItem("customPhasesData");
                }}
              >
                Cancel
              </CancelText>
            </>
          );
        }}
      </Formik>
    </Main>
  );
};

const Main = styled.div`
  background: ${theme.NEUTRAL100};
  min-width: 1100px;
  min-height: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 409px;
  padding-right: 409px;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  min-width: 1100px;
  min-height: inherit;
`;

const HeaderDiv = styled.div`
  padding-top: 50px;
  align-self: flex-start;
  margin-bottom: 40px;
`;

const Header = styled.p`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
`;

const SubHeader = styled.p`
  font-weight: 400;
  font-size: 14px;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding: 0px 32px 54px 32px;
  max-height: 65vh;
  min-height: 65vh;
  overflow: auto;
  min-width: 547px;
`;

const FieldDiv = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 0.1fr 1fr;
`;

const ArrowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
`;

const CancelText = styled.p`
  opacity: 40%;
  text-decoration: none;
  font-size: 14px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    opacity: 70%;
  }
`;

const FieldHeaderText = styled.p`
  font-weight: 700;
  font-size: 14px;
`;

export { MapSalesPhases };
