import styled, { keyframes } from "styled-components";
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { theme } from "../../../utils/theme";

import { GridInfoTableV2 } from "./GridInfoTableV2";
import { GridFilterContext } from "../../../context/";
import { useFlags } from "launchdarkly-react-client-sdk";
interface ParamTypes {
  view_id: string;
  org_id: string;
}
const ShareViewContainer: React.FC<ParamTypes> = () => {
  const { view_id, org_id } = useParams<ParamTypes>();
  const { setSharedViewId, setSharedViewOrgId } = useContext(GridFilterContext);

  useEffect(() => {
    setSharedViewOrgId(org_id);
    setSharedViewId(view_id);
    return () => {
      setSharedViewOrgId("");
      setSharedViewId("");
    };
  }, [view_id]);

  return (
    <DashboardContainer>
      <GridInfoTableV2 />
    </DashboardContainer>
  );
};
const DashboardContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  width: calc(100vw);
  max-height: 100vh;
`;

export { ShareViewContainer };
