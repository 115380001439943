import styled, { keyframes } from "styled-components";
import { AppText, DarkDiv } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { theme } from "../../../utils/theme";
import { xIcon } from "../../../images/NewDesign";
import React, { useContext, useMemo, useState } from "react";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon } from "../../UI/Phoenix";
import { ModalContext } from "../../../context";
import { gql, useQuery } from "@apollo/client";
import { Segment } from "../../../types/SequenceTypes";

const FETCH_SEQUENCES = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

interface SelectASequenceModalProps {
  segments: Segment[];
  setSegments: React.Dispatch<React.SetStateAction<Segment[]>>;
  sequenceData?: any;
}

export const SelectASequenceModal: React.FC<SelectASequenceModalProps> = ({ segments, setSegments, sequenceData }) => {
  const { setShowSelectASequenceModal } = useContext(ModalContext);

  const { data: dataSequences, loading: loadingSequences, error: errorSequences } = useQuery(FETCH_SEQUENCES, {
    fetchPolicy: "cache-and-network",
    // sequenceData can be passed into modal. If no sequenceData is passed we will fetch it
    skip: !!sequenceData,
    variables: {
      sequencesFilter: {},
    },
    onCompleted({ fetchSequences }) {
      console.log("fetchSequences:", fetchSequences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const sequences = useMemo(() => {
    const data = sequenceData || dataSequences;
    if (data?.fetchSequences) {
      return data.fetchSequences
        .filter(
          (sequence: any) =>
            !!sequence?.sequence_entry_criteria && !segments.find((ele: any) => ele.sequence_id === sequence?.id),
        )
        ?.map((sequence: any) => ({
          id: sequence?.id,
          name: sequence?.name,
          current_phase:
            // length > 1 is cold call aggregate
            sequence?.sequence_entry_criteria.current_phase?.length > 1
              ? "ColdCall"
              : sequence?.sequence_entry_criteria.current_phase?.[0],
        }));
    }
    return [];
  }, [dataSequences, sequenceData]);

  const [selectedSequences, setSelectedSequences] = useState<string[]>([]);

  const handleCheck = (id: string) => {
    if (selectedSequences.includes(id)) {
      setSelectedSequences(selectedSequences.filter((sequenceId: string) => sequenceId !== id));
    } else {
      setSelectedSequences([...selectedSequences, id]);
    }
  };

  const SequenceOption = ({ sequence }: any) => (
    <FlexDiv align="center" key={`${sequence?.id}`} style={{ paddingTop: "16px" }}>
      <PhoenixCheckbox checked={selectedSequences.includes(sequence?.id)} onChange={() => handleCheck(sequence?.id)} />
      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
        {sequence?.name}
      </AppText>
    </FlexDiv>
  );

  return (
    <>
      <DarkDiv noFade />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            color={theme.PRIMARY500}
            size={24}
            pointer
            onClick={() => {
              setShowSelectASequenceModal(false);
            }}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            Select a Sequence
          </AppText>
        </ModalHeader>

        <ModalBody direction="column" gap={24}>
          <FlexDiv direction="column">
            <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
              Cold Call
            </AppText>
            {sequences?.filter((sequence: any) => sequence.current_phase === "ColdCall")?.length ? (
              sequences
                ?.filter((sequence: any) => sequence.current_phase === "ColdCall")
                ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} />)
            ) : (
              <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                No sequences available.
              </AppText>
            )}
          </FlexDiv>
          <FlexDiv direction="column">
            <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
              Demo set for future date
            </AppText>
            {sequences?.filter((sequence: any) => sequence.current_phase === "HeldPhase")?.length ? (
              sequences
                ?.filter((sequence: any) => sequence.current_phase === "HeldPhase")
                ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} />)
            ) : (
              <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                No sequences available.
              </AppText>
            )}
          </FlexDiv>
          <FlexDiv direction="column">
            <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
              Demo not held at set time
            </AppText>
            {sequences?.filter((sequence: any) => sequence.current_phase === "PostInitialHelPhaseDemoNotHeld")
              ?.length ? (
              sequences
                ?.filter((sequence: any) => sequence.current_phase === "PostInitialHelPhaseDemoNotHeld")
                ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} />)
            ) : (
              <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                No sequences available.
              </AppText>
            )}
          </FlexDiv>
          <FlexDiv direction="column">
            <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
              Demo held
            </AppText>
            {sequences?.filter((sequence: any) => sequence.current_phase === "PostHoldPhase")?.length ? (
              sequences
                ?.filter((sequence: any) => sequence.current_phase === "PostHoldPhase")
                ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} />)
            ) : (
              <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                No sequences available.
              </AppText>
            )}
          </FlexDiv>
        </ModalBody>

        <ModalFooter justify="space-between">
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            buttonTextFontSize={10}
            uppercase
            onClick={() => {
              setShowSelectASequenceModal(false);
            }}
          >
            Cancel
          </PhoenixAppButton>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            buttonTextFontSize={10}
            uppercase
            disabled={!selectedSequences.length}
            onClick={() => {
              const sequences = dataSequences ?? sequenceData;
              const newSegments: Segment[] = [
                ...(selectedSequences?.map((id: string) => ({
                  type: "Sequence",
                  title: sequences?.fetchSequences?.find((ele: any) => ele.id === id)?.name || "",
                  sequence_id: id || "",
                })) as Segment[]),
                ...segments,
              ];
              setSegments(newSegments);
              setShowSelectASequenceModal(false);
            }}
          >
            Continue
          </PhoenixAppButton>
        </ModalFooter>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${theme.fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 40px;

  max-height: 80vh;
  min-height: 70vh;
  margin-bottom: auto;

  overflow-y: overlay;
  overflow-x: hidden;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;
