import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { theme } from "../../utils/theme";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  onDeleteFolder?: any;
  onDeleteCall?: any;
  deleteFolder?: boolean;
}

const DeleteCallFolderPopup: React.FC<DisappearingDivProps> = ({
  blinds,
  setBlinds,
  onDeleteFolder,
  onDeleteCall,
  deleteFolder = false,
}) => {
  return (
    <Modal open={blinds} onClose={() => setBlinds(false)}>
      <ContentDiv>
        <PopupText1>{deleteFolder ? "Delete Folder" : "Delete Recording"}</PopupText1>
        <AppText fontSize={16} fontWeight={400} lineHeight={22} style={{ textAlign: "center" }}>
          Are you sure you want to delete this {deleteFolder ? "folder" : "recording"}? This action cannot be undone.
        </AppText>
      </ContentDiv>
      <ButtonsDiv>
        <PhoenixAppButton
          buttonType="secondary"
          variant="danger-outline"
          onClick={() => setBlinds(false)}
          uppercase
          buttonTextFontSize={10}
          style={{ letterSpacing: "1px" }}
        >
          No, Cancel
        </PhoenixAppButton>
        <PhoenixAppButton
          buttonType="secondary"
          onClick={() => {
            setBlinds(false);
            if (deleteFolder) {
              onDeleteFolder();
            } else {
              onDeleteCall();
            }
          }}
          uppercase
          buttonTextFontSize={10}
          style={{ letterSpacing: "1px" }}
        >
          Yes, Continue
        </PhoenixAppButton>
      </ButtonsDiv>
    </Modal>
  );
};

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

const PopupText1 = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 40px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 476px;
  height: 240px;
  padding: 40px;
`;

export { DeleteCallFolderPopup };
