import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import styled from "styled-components";
import { sanitizeURL } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppText, NewAppButton } from "../UI";
import { Modal } from "./Modal";
import { PhoenixAppButton } from "../UI/Phoenix";
interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  refetch: (key?: any) => Promise<any>;
  lead: any;
}

const IGNORE_LEAD_UPLOAD_WARNING = gql`
  mutation IgnoreLeadUploadWarning($upload_id: String!, $force_upload: Boolean) {
    ignoreLeadUploadWarning(upload_id: $upload_id, force_upload: $force_upload)
  }
`;

const RESOLVE_WITHOUT_FIXING_DUPLICATES = gql`
  mutation resolveWithoutFixingDuplicates($upload_id: String!) {
    resolveWithoutFixingDuplicates(upload_id: $upload_id)
  }
`;

const ResolveDuplicateLead: React.FC<DisappearingDivProps> = ({ visible, close, lead, refetch }) => {
  const [resolveLead, { loading: loadingResolve, error: errorResolve }] = useMutation(IGNORE_LEAD_UPLOAD_WARNING, {
    async onCompleted({ ignoreLeadUploadWarning }) {
      console.log("ignoreLeadUploadWarning: ", ignoreLeadUploadWarning);
      if (!ignoreLeadUploadWarning) {
        appToast("Error resolving lead. Something went wrong.");
        return;
      }
      close();
      refetch();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `ignoreLeadUploadWarning GraphQL Error: ${message}`,
      });
      console.log("Error in ignoreLeadUploadWarning: ", message);
    },
  });

  const [resolveWithoutFixingDuplicates] = useMutation(RESOLVE_WITHOUT_FIXING_DUPLICATES, {
    onCompleted() {
      refetch();
      close();
    },
    onError({ message }) {
      appToast(`${message}`);
    },
  });

  return (
    <Modal open={visible} onClose={close} closeButtonSize={16}>
      <ModalContainer>
        <AppText fontSize={16} style={{ fontWeight: 600 }}>
          Resolve Duplicate Leads
        </AppText>
        <ColumnDiv>
          <ModalText style={{ fontWeight: 600 }}>
            {lead?.leads_on_warning} Lead(s) in this import currently exist in Sellfire
          </ModalText>
          <ModalText variant="error" style={{ fontWeight: 600 }}>
            Would you like to overwrite all {lead?.leads_on_warning} Lead(s)?
          </ModalText>
        </ColumnDiv>
        <ModalText style={{ padding: "0px 56px 0px 56px" }}>
          Updating these leads will be permanent and overwritten data will be lost. If you're updating which Rep owns an
          existing lead please note that currently owned Leads will be reassigned from the old Rep to the new Rep you're
          assigning in this import. Finally note that all historical sales data and reporting on that existing lead to
          date will not be affected (i.e. Managers and the original owning Rep[s] will still get credit for the previous
          dials, connects, demos, etc.).
        </ModalText>
        <NewAppButton
          size="sm"
          variant="attention"
          onClick={() => {
            const santizedURL = sanitizeURL(lead.duplicate_report_url);

            window.open(santizedURL, "_blank", "noopener,noreferrer");
          }}
        >
          Download Duplicate Leads
        </NewAppButton>
        <Divider />
        <ColumnDiv>
          <ModalText style={{ fontWeight: 600 }}>
            Would you like to overwrite all {lead?.leads_on_warning} Leads?
          </ModalText>
          <ButtonDiv>
            <PhoenixAppButton
              variant="danger-outline"
              buttonType="secondary"
              disabled={!!lead?.is_resolved}
              onClick={async () =>
                await resolveLead({
                  variables: {
                    upload_id: lead?.id,
                    force_upload: true,
                  },
                })
              }
            >
              Yes, Overwrite {lead?.leads_on_warning} Leads
            </PhoenixAppButton>
            <PhoenixAppButton
              variant="brand"
              buttonType="secondary"
              onClick={() => {
                resolveWithoutFixingDuplicates({
                  variables: {
                    upload_id: lead?.id,
                  },
                });
              }}
            >
              No, Do Not Import {lead?.leads_on_warning} Leads
            </PhoenixAppButton>
          </ButtonDiv>
        </ColumnDiv>
      </ModalContainer>
    </Modal>
  );
};

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 10;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 640px;
  height: 600px;
`;

const ModalText = styled(AppText)`
  font-size: 14px;
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  width: 100%;
  padding: 16px;
`;
export { ResolveDuplicateLead };
