import { gql, useMutation, useQuery } from "@apollo/client";
import DateTimePicker from "react-datetime-picker";
import { FieldArray, Formik, FormikProps } from "formik";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { FiArrowLeft } from "react-icons/fi";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { InputField, FormMultiSelectField, CheckboxField, FormSelectField } from "../../Field";
import { AppButton, AppErrorText, AppText, Loading, NewAppButton } from "../../UI";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { CallContext, CartContext } from "../../../context";
import { restAPI } from "../../../apollo";
import { BACKEND_URL } from "../../../utils/variables";
import { appToast, errorToast } from "../../../utils/toast";
import { useState } from "react";
import CustomFieldSaleArray from "../CallSegments/MakeSaleComponents/CustomFieldSaleArray";
import {
  findCountryWithCode,
  sortedCountryCodesData,
  listBrazil,
  listMexico,
  statesUSA,
  findStateCode,
} from "../../../static/countries";
import { PHONE_REGEX } from "../../../utils/regex";
import { convertDate, extractNumberWithCountry } from "../../../utils/format";
import moment from "moment";
import { copyFile } from "fs";
import HelpfulLinks from "./MakeSaleComponents/HelpfulLinks";

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      first_name
      last_name
      business_name
      primary_phone_number
      primary_email
      address
      city
      state
      country
      local_primary_phone_number
      primary_phone_number_country_code
      timezone_by_state
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        required_required_for_sale
      }
    }
  }
`;

const FETCH_CUSTOM_FIELD_DROPDOWN_OPTIONS = gql`
  query getCustomFieldOptions($key: String!) {
    getCustomFieldOptions(key: $key)
  }
`;

const FETCH_HELPFUL_LINKS = gql`
  query fetchHelpfulLinks {
    fetchOrganization {
      id
      helpful_links
    }
  }
`;

export const VALIDATE_PHONE_NUMBER = gql`
  mutation validatePhoneNumber($phone_number: String!, $lead_id: String!) {
    validatePhoneNumber(phone_number: $phone_number, lead_id: $lead_id)
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: String!
    $first_name: String
    $last_name: String
    $business_name: String
    $primary_phone_number: String
    $primary_email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $address: String
    $zip: String
    $country_code: String
    $custom_fields: [CustomFieldInput!]
  ) {
    updateLead(
      id: $id
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      address: $address
      zip: $zip
      country_code: $country_code
      custom_fields: $custom_fields
    ) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        required_required_for_sale
      }
    }
  }
`;
interface CustomField {
  id: string;
  value: string | number | boolean;
  num_value?: number;
  list_value?: string[];
  boo_value?: boolean;
  date_value?: string;
  type: string;
  key: string;
  options: string[];
  required_required_for_sale?: boolean;
}

interface MyFormikProps {
  customFields: CustomField[];
}

interface ConfirmLeadDetailsCustomProps {
  setCallOptions: (state: string) => void;
  leadID: string;
}

const ConfirmLeadDetailsCustom: React.FC<ConfirmLeadDetailsCustomProps> = (props) => {
  console.log("confirm details custom render");

  const { data, loading, error, refetch } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: props.leadID },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataLinks, loading: loadingLinks, error: errorLinks } = useQuery(FETCH_HELPFUL_LINKS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateLead, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_LEAD, {
    async onCompleted({ updateLead }) {
      if (!updateLead) {
        appToast("Error confirming lead. Something went wrong.");
        return;
      }
      props.setCallOptions("closing-script");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateLead GraphQL Error: ${message}`,
      });
    },
  });

  const [validatePhoneNumber, { loading: phoneLoading, data: phoneData, error: phoneError }] = useMutation(
    VALIDATE_PHONE_NUMBER,
  );

  const { handleRevertClosingScript, recording } = React.useContext(CallContext);
  const { clearCartContext } = React.useContext(CartContext);

  const [oldPhoneValue, setOldPhoneValue] = useState("");

  const numberYupValidation = () => ({
    is: "Rate" || "Percentage" || "Number",
    then: Yup.number().typeError("Must be a number").nullable(),
  });

  const textYupValidation = () => ({
    is: "Text",
    then: Yup.string(),
  });

  const booleanYupValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const requiredValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const confirmDetailsSchema = () =>
    Yup.object().shape({
      customFields: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          type: Yup.string().notRequired(),
          value: Yup.string().when("type", textYupValidation()).nullable(),
          boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
          num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
          // date_value: Yup.string().typeError("Must be a string").when("type", textYupValidation()).nullable(),
        }),
      ),
    });

  const resumeRecording = async () => {
    const api_call = await restAPI.request({ method: "POST", baseURL: `${BACKEND_URL}/twilio/resumeRecording` });
    return api_call;
  };

  const handleRestartRecording = () => {
    if (!recording) resumeRecording();
  };

  if (loading) return <Loading />;
  if (error) return <AppErrorText>Error fetching lead details.</AppErrorText>;

  const {
    id,
    first_name,
    last_name,
    business_name,
    primary_phone_number,
    primary_email,
    address,
    city,
    state,
    zip,
    country,
    primary_phone_number_country_code,
    local_primary_phone_number,
    sale_flow_custom_fields,
  } = data.fetchLead;

  return (
    <FormContainer>
      <HeaderContainer>
        <AppText fontSize={16}>Confirm Additional Account Details</AppText>
        <HelpfulLinks />
      </HeaderContainer>
      <Formik
        initialValues={{
          customFields: (sale_flow_custom_fields || [])?.map((customField: CustomField) => {
            let valueToUse = {
              id: customField.id,
              value: "",
              list_value: [],
              type: customField.type,
              key: customField.key,
              options: [],
              required_required_for_sale: customField.required_required_for_sale,
            } as any;
            let optionsToUse = [] as string[];
            switch (customField.type) {
              case "Dropdown":
                valueToUse.value = customField.value;
                valueToUse.options = customField.options;
                break;
              case "MultiDropdown":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                valueToUse.options = customField.options;
              case "MultiText":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                break;
              case "DateTime":
                valueToUse.date_value = customField.date_value ? convertDate(customField.date_value) : undefined;
                break;
              case "Date":
                valueToUse.value = customField.value;
                break;
              case "Number":
                valueToUse.num_value = customField.num_value;
                break;
              case "Text":
                valueToUse.value = customField.value;
                break;
              case "Boolean":
                valueToUse.boo_value = customField.boo_value;
                break;
              case "Rate":
                valueToUse.num_value = customField.num_value;
                break;
              case "Percentage":
                valueToUse.num_value = customField.num_value;
                break;
              default:
                valueToUse.value = "";
            }
            return valueToUse;
          }),
        }}
        validationSchema={confirmDetailsSchema()}
        validateOnMount={true}
        onSubmit={async (values) => {
          // check validation
          for (const k of values.customFields.filter((cf) => cf.required_required_for_sale)) {
            switch (k.type) {
              case "Text":
              case "Dropdown":
                if (!k.value) {
                  return errorToast(`${k.key} is a required field!`);
                }
                break;
              case "MultiDropdown":
              case "MultiText":
                if (!k.list_value?.length) {
                  return errorToast(`${k.key} is a required field!`);
                }
                break;
              case "DateTime":
                if (!k.date_value) {
                  return errorToast(`${k.key} is a required field!`);
                }
                break;
              case "Date":
                if (!k.value) {
                  return errorToast(`${k.key} is a required field!`);
                }
                break;
              case "Number":
              case "Rate":
              case "Percentage":
                if (!k.num_value) {
                  return errorToast(`${k.key} is a required field!`);
                }
                break;
              // case "Boolean":
            }
          }
          const final_custom_fields = values.customFields?.map((customField) => {
            switch (customField.type) {
              case "Dropdown":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "MultiDropdown":
              case "MultiText":
                return {
                  id: customField.id,
                  // @ts-ignore
                  list_value: customField.list_value?.map((k) => k.value ?? k),
                };
              case "DateTime":
                return {
                  id: customField.id,
                  date_value: customField.date_value,
                };
              case "Date":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Number":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Text":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Boolean":
                return {
                  id: customField.id,
                  boo_value: customField.boo_value,
                };
              case "Rate":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Percentage":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              default:
                return {
                  id: customField.id,
                  value: customField.value,
                };
            }
          });
          console.log("final_custom_fields: ", final_custom_fields);
          await updateLead({
            variables: {
              id,
              custom_fields: final_custom_fields ?? undefined,
            },
          });
        }}
      >
        {({ submitForm, values, setFieldValue, isSubmitting, isValid, errors }: FormikProps<MyFormikProps>) => {
          console.log("values: ", values);
          console.log("errors: ", errors);
          console.log("isValid: ", isValid);
          return (
            <>
              <FormContainerDiv>
                <CustomFieldSaleArray values={values} setFieldValue={setFieldValue} />
              </FormContainerDiv>
              {isSubmitting ? (
                <Loading />
              ) : (
                <NewAppButton type="submit" size={"lg"} variant={"primary"} onClick={submitForm} disabled={!isValid}>
                  Confirm Details
                </NewAppButton>
              )}
            </>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

const TitleLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const EditTextInput = styled(InputField)`
  height: 36px;
  font-size: 13px;
  margin-top: 0px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const CheckBoxColumn = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin: 0px;
  min-height: 50px;
`;

const ExtensionText = styled(AppText)`
  margin: 15px;
  margin-top: 5px;
  height: 25px;
  font-size: 13px;
`;

const NumberExtensionDiv = styled.div`
  display: flex;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 39px;
`;

const LeadInput = styled(InputField)`
  margin: 0;
  height: 30px;
  text-align: center;
  font-size: 13px;
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const FormContainerDiv = styled.div`
  width: 100%;
  overflow-y: scroll;
  justify-content: center;
  padding-bottom: 200px;
  flex-grow: 2;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const SpacerDiv = styled.div`
  width: 25px;
  height: 25px;
`;

const SubmitButton = styled(AppButton)`
  width: 262px;
  height: 47px;
  border-radius: 1.8px;
  font-size: 11px;
  font-weight: 600;
  margin: 15px auto;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const FieldSelect = styled(FormSelectField)`
  /* height: 36px; */
  font-size: 13px;
  border-radius: 2.9px;
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const MultiFieldSelect = styled(FormMultiSelectField)`
  /* height: 36px; */
  font-size: 13px;
  border-radius: 2.9px;
  text-align: center;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

export { ConfirmLeadDetailsCustom };
