import { useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { loggedInUser } from "src/apollo/cache";
import {
  CHANNEL_OPTIONS,
  STATUS_OPTIONS,
  NEXT_SCHEDULED_EVENT_OPTIONS,
  LEAD_CREATION_SOURCE_OPTIONS,
} from "src/Components/Segments/LeadsFilterV2/shared";
import { OptionItem } from "src/types";

const FETCH_REPS_FROM_ORG = gql`
  query fetchRepsFromOrg {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
        profile_image
      }
    }
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions($include_others: Boolean) {
    fetchIndustryOptions(include_others: $include_others) {
      id
      label
      sub_industries
    }
  }
`;

const GET_LEAD_SOURCE_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const GET_STATE_OPTIONS = gql`
  query getStateOptions {
    getStateOptions
  }
`;

const GET_TIMEZONE_OPTIONS = gql`
  query getTZOptions {
    getTZOptions
  }
`;

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      name
    }
  }
`;

const useLeadsFilterOptions = () => {
  const { data: repsData } = useQuery(FETCH_REPS_FROM_ORG, {
    variables: { org_id: loggedInUser().organization_id },
    fetchPolicy: "network-only",
  });

  const { data: industriesData } = useQuery(GET_POSSIBLE_INDUSTRIES_DATA, {
    fetchPolicy: "network-only",
    variables: { include_others: true },
  });

  const { data: leadSourceData } = useQuery(GET_LEAD_SOURCE_OPTIONS, { fetchPolicy: "network-only" });

  const { data: stateData } = useQuery(GET_STATE_OPTIONS, {
    fetchPolicy: "network-only",
  });

  const { data: tzData } = useQuery(GET_TIMEZONE_OPTIONS, {
    fetchPolicy: "network-only",
  });

  const { data: teamsData } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "network-only",
  });

  const repOptions = (repsData?.fetchOrganization?.Reps ?? [])
    ?.map((item: any) => ({
      avatar: item?.profile_image,
      first_name: item.first_name,
      label: `${item.first_name} ${item.last_name}`,
      last_name: item.last_name,
      value: item?.id,
    }))
    ?.sort((a: any, b: any) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1));

  const industryOptions = (industriesData?.fetchIndustryOptions || [])
    ?.map((item: any) => ({
      label: item.label,
      value: item.label,
    }))
    .sort((a: any, b: any) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1));

  const leadSourcesOptions = leadSourceData?.getUniqueLeadSources?.map((item: any) => ({
    label: item.label,
    value: item.label,
  }));

  const stateOptions =
    stateData?.getStateOptions
      ?.map((item: any) => ({ label: item, value: item }))
      .sort((a: OptionItem, b: OptionItem) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1)) ?? [];

  const timezoneOptions =
    tzData?.getTZOptions
      ?.map((item: any) => ({ label: item, value: item }))
      .sort((a: OptionItem, b: OptionItem) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1)) ?? [];

  const teamsOptions =
    teamsData?.fetchTeams
      ?.map((item: any) => ({ label: item.name, value: item.id }))
      .sort((a: OptionItem, b: OptionItem) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1)) ?? [];

  const mapOptionsToKey = useCallback(
    (key: string) => {
      switch (key) {
        case "reps":
        case "sourced_by_user":
          return repOptions;
        case "lead_sources":
          return leadSourcesOptions;
        case "lead_creation_source":
          return LEAD_CREATION_SOURCE_OPTIONS;
        case "status":
          return STATUS_OPTIONS;
        case "industries":
          return industryOptions;
        case "sub_industry":
          return [];
        case "states":
          return stateOptions;
        case "timezones":
          return timezoneOptions;
        case "PDV":
          return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
        case "next_scheduled_event":
          return NEXT_SCHEDULED_EVENT_OPTIONS;
        case "channels":
          return CHANNEL_OPTIONS;
        case "team":
          return teamsOptions;
        default:
          break;
      }
    },
    [repOptions, leadSourcesOptions, industryOptions, stateOptions, timezoneOptions, teamsOptions],
  );

  const getSubIndustryOptions = useCallback(
    (industryIds: string[]) => {
      return (
        industriesData?.fetchIndustryOptions
          ?.filter((item: any) => industryIds.includes(item.label))
          ?.flatMap((item: any) => item.sub_industries)
          ?.map((subIndustry: string) => ({
            label: subIndustry,
            value: subIndustry,
          }))
          ?.sort((a: OptionItem, b: OptionItem) =>
            `${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1,
          ) ?? []
      );
    },
    [industriesData],
  );

  return {
    channelOptions: CHANNEL_OPTIONS,
    getSubIndustryOptions,
    industryOptions,
    mapOptionsToKey,
    nextScheduledEventOptions: NEXT_SCHEDULED_EVENT_OPTIONS,
    repOptions,
    statusOptions: STATUS_OPTIONS,
  };
};

export default useLeadsFilterOptions;
