import React from "react";
import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { AppText, SkeletonBlock } from "../../../UI";
import { theme } from "../../../../utils/theme";
import { appToast } from "../../../../utils/toast";
import styled from "styled-components";

const SWAP_PRIMARY_NUMBER = gql`
  mutation swapPrimaryNumberOnLead($contact_id: String!) {
    swapPrimaryNumberOnLead(contact_id: $contact_id) {
      id
    }
  }
`;

/**
 * @param {string} contact_id - contact_id of the contact that the BE will use to swap the primary number
 *
 **/
const SwapPrimaryNumberDiv = ({ contact_id }: { contact_id: string }) => {
  const [changePrimaryNumber, { loading: loadingChangePrimaryNumber, error: errorChangePrimaryNumber }] = useMutation(
    SWAP_PRIMARY_NUMBER,
    {
      onCompleted({ swapPrimaryNumberOnLead }) {
        if (!swapPrimaryNumberOnLead) {
          appToast("Error: Something went wrong while editing number");
          return;
        }
        appToast("Changed primary number");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `swapPrimaryNumberOnLead GraphQL Error: ${message}`,
        });
        console.log("Error in swapPrimaryNumberOnLead: ", message);
      },
      refetchQueries: ["fetchLead", "FetchAssociateContact"],
    },
  );

  const loading = loadingChangePrimaryNumber;

  if (loading) {
    return <SkeletonBlock height={20} width={100} borderRadius={6} />;
  }

  return (
    <SwapPrimaryText onClick={async () => await changePrimaryNumber({ variables: { contact_id: contact_id } })}>
      Make Primary Phone
    </SwapPrimaryText>
  );
};

const SwapPrimaryText = styled(AppText)`
  border-bottom: none;
  cursor: pointer;
  color: ${theme.PRIMARY500};
  font-size: 10px;
  font-weight: 400;
`;
export { SwapPrimaryNumberDiv };
