import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";

interface CheckboxFieldProps {
  name?: string;
  title: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
  checked: boolean;
  value?: any;
  id?: string;
  onClick?: any;
  small?: boolean;
}

const NewAppCheckBox = ({
  small = false,
  label,
  style,
  title,
  checked,
  value,
  onClick,
  disabled = false,
  ...props
}: CheckboxFieldProps) => {
  return (
    <div style={{ borderRadius: "10px" }}>
      <CheckboxContainer style={style} checked={checked} small={small}>
        {!!title && (
          <label htmlFor={value}>
            <TitleText disabled={disabled}>{title}</TitleText>
          </label>
        )}
        <input type="checkbox" name={value} checked={checked} onClick={onClick} disabled={disabled} />
        <span className="checkmark" />
      </CheckboxContainer>
    </div>
  );
};

interface CheckedProp {
  checked: boolean;
  small: boolean;
}

const CheckboxContainer = styled.label<CheckedProp>`
  border-radius: 10px;
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  /* width: 30px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* z-index: 3; */
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create a custom checkbox */
  .checkmark {
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0px;
    width: ${(props) => (props.small ? "16px" : "24px")};
    height: ${(props) => (props.small ? "16px" : "24px")};
    border-radius: 2px;
    border: 1px solid ${(props) => (props.checked ? theme.NEUTRAL400 : theme.NEUTRAL200)};
    background-color: ${(props) => (props.checked ? theme.PRIMARY500 : "transparent")};
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "✓";
    font-family: ${theme.PRIMARY_FONT};
    position: absolute;
    display: none;
    font-size: ${(props) => (props.small ? "13px" : "17px")};
    line-height: ${(props) => (props.small ? "16px" : "24px")};
    left: ${(props) => (props.small ? "1px" : "3px")};
    bottom: -1px;
    color: ${(props) => (props.checked ? theme.WHITE_COLOR : "transparent")};
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
`;

interface DisabledProp {
  disabled?: boolean;
}

const TitleText = styled(AppText)<DisabledProp>`
  margin-left: 36px;
  line-height: 24px;
  color: ${(props) => (props.disabled ? theme.NEUTRAL400 : theme.PRIMARY800)};
`;

export { NewAppCheckBox };
