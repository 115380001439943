import { gql, useLazyQuery } from "@apollo/client";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { formatSpokeWith } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppErrorText, AppText } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { SkeletonBlock } from "../UI/SkeletonBlock";

/**
 * SelectDispo Component:
 * This is a reusable component that allows the user to select the disposition of a call. It is used in Edit event, delete event, and log non-call activiy modals.
 *
 * 
 * @param {string} selectedEventType - The type of event selected. (this changes the person spoke to and disposition options) 

 *
 * @param {string} lead_id - The lead ID associated with the selection.
 * 
 * // setters and getters
 * @param {string | undefined} selectedPersonSpokeTo - The currently selected person spoke to.
 * @param {string} selectedDispositionId - The currently selected disposition id.
 * @param {function} setSelectedPersonSpokeTo - A function to set the selected person spoke to.
 * @param {function} setSelectedDispositionId - A function to set the selected disposition id.
 * 
 * // optional for modal use
 *  @param {function | undefined} setStepForward - A function to move to the next step, if applicable.
 */

// fetch person spoke to options (DM, NDM, etc)

interface IFetchPersonSpokeToOptions {
  fetchPersonSpokeToOptions?: string[];
}
const FETCH_PERSON_SPOKE_TO = gql`
  query fetchPersonSpokeToOptions($lead_id: String!, $associated_action: String) {
    fetchPersonSpokeToOptions(lead_id: $lead_id, associated_action: $associated_action)
  }
`;

// fetch disposition options based on person spoke to

interface IDisposition {
  id?: string;
  phase?: string;
  person_spoke_to?: string;
  type?: string;
  next_phase?: string;
  created_at?: string;
  updated_at?: string;
  next_implied_action?: string;
  organization_id?: string;
  belongs_to_action?: string;
  associated_action?: string;
  order?: number;
  label?: string;
}
interface IFetchDispositionOptions {
  fetchDispositionOptions?: IDisposition[];
}
const FETCH_DISPOSITION_OPTIONS = gql`
  query FetchDispositionOptions($leadId: String!, $associatedAction: String, $person_spoke_to: CALLRESULT) {
    fetchDispositionOptions(lead_id: $leadId, associated_action: $associatedAction, person_spoke_to: $person_spoke_to) {
      id
      label
    }
  }
`;

const SelectDispo = ({
  selectedPersonSpokeTo,
  selectedDispositionId,
  selectedEventType,
  setSelectedPersonSpokeTo,
  setSelectedDispositionId,
  setStepForward,
  lead_id,
  vertical,
}: {
  selectedPersonSpokeTo?: string;
  selectedDispositionId: string;
  selectedEventType: string;
  setSelectedPersonSpokeTo: (person: string) => void;
  setSelectedDispositionId: (dispositionId: string) => void;
  setStepForward?: () => void;
  lead_id: string;
  vertical?: boolean;
}) => {
  const handleFetchError = ({ message, name }: { message: string; name: string }) => {
    console.log(`Error in ${name}: `, message);
    appToast("Error loading options. ");
  };

  // DM, NDM, etc
  const [
    fetchPersonSpokeToOptions,
    { data: dataPersonSpokeTo, loading: loadingPersonSpokeTo, error: errorPersonSpokeTo },
  ] = useLazyQuery<IFetchPersonSpokeToOptions>(FETCH_PERSON_SPOKE_TO, {
    fetchPolicy: "cache-and-network",
    onError: handleFetchError,
    variables: {
      lead_id,
      associated_action: selectedEventType,
    },
  });

  // disposition options based on person spoke to
  const [
    fetchDispositionOptions,
    { data: dataDispositionOptions, loading: loadingDispositionOptions, error: errorDispositionOptions },
  ] = useLazyQuery<IFetchDispositionOptions>(FETCH_DISPOSITION_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError: handleFetchError,
  });

  // fetch person spoke to options on eventType change
  useEffect(() => {
    if (lead_id) fetchPersonSpokeToOptions();
  }, [selectedEventType, lead_id]);

  // fetch disposition options on person spoke to change
  useEffect(() => {
    if (selectedPersonSpokeTo)
      fetchDispositionOptions({
        variables: { leadId: lead_id, associatedAction: selectedEventType, person_spoke_to: selectedPersonSpokeTo },
      });
  }, [selectedPersonSpokeTo]);

  const SkeletonLoader = () => (
    <SkeletonLoaderDiv vertical={vertical}>
      {new Array(2).fill(0)?.map((_, i) => (
        <SkeletonBlock key={i} height={40} width={120} borderRadius={4} />
      ))}
    </SkeletonLoaderDiv>
  );

  const error = errorPersonSpokeTo || errorDispositionOptions;

  if (error) {
    return (
      <SelectDispoDiv>
        <AppErrorText>{error.message}</AppErrorText>
      </SelectDispoDiv>
    );
  }

  const noPersonSpokeToOptions = dataPersonSpokeTo?.fetchPersonSpokeToOptions?.length === 0;
  const noDispositionOptions = dataDispositionOptions?.fetchDispositionOptions?.length === 0;
  return (
    <SelectDispoDiv>
      <AppText fontSize={16} color={theme.NEUTRAL400}>
        {selectedPersonSpokeTo ? "What was the result of the call?" : "Who did you speak with?"}
      </AppText>

      <Main vertical={vertical}>
        <CallButtonsDiv vertical={vertical}>
          {/* if person spoke to is not selected, show person spoke to options */}
          {!selectedPersonSpokeTo ? (
            loadingPersonSpokeTo ? (
              <SkeletonLoader />
            ) : noPersonSpokeToOptions ? (
              <AppText fontSize={16} color={theme.NEUTRAL400}>
                No available options found for {selectedEventType.toLowerCase()}.
              </AppText>
            ) : (
              dataPersonSpokeTo?.fetchPersonSpokeToOptions?.map((person: string) => (
                <PhoenixAppButton
                  key={person}
                  width={240}
                  variant={"brand-outline"}
                  buttonType="secondary"
                  selected={selectedPersonSpokeTo === person}
                  onClick={() => {
                    setSelectedPersonSpokeTo(person);
                  }}
                >
                  {formatSpokeWith(person, true)}
                </PhoenixAppButton>
              ))
            )
          ) : loadingDispositionOptions ? (
            <SkeletonLoader />
          ) : noDispositionOptions ? (
            <AppText fontSize={16} color={theme.NEUTRAL400}>
              No available options found for {selectedPersonSpokeTo}.
            </AppText>
          ) : (
            dataDispositionOptions?.fetchDispositionOptions?.map((item: IDisposition) => (
              <PhoenixAppButton
                key={item?.id}
                width={240}
                variant={"brand-outline"}
                buttonType="secondary"
                selected={selectedDispositionId === item?.id}
                onClick={() => {
                  if (!item?.id) {
                    appToast("No disposition data found for this call result. Please contact support for assistance.");
                    return;
                  }
                  setSelectedDispositionId(item?.id);
                  setStepForward && setStepForward();
                }}
              >
                {item?.label}
              </PhoenixAppButton>
            ))
          )}
        </CallButtonsDiv>
      </Main>
    </SelectDispoDiv>
  );
};

const Main = styled.div<{ vertical?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  justify-content: center;
`;

const SelectDispoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
`;

const SkeletonLoaderDiv = styled.div<{ vertical?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 14px;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  ${({ vertical }) => vertical && "justify-content: center;"};
  ${({ vertical }) => vertical && "align-items: center;"};
  margin-bottom: ${({ vertical }) => (vertical ? "20px" : "0")};
`;

const CallButtonsDiv = styled.div<{ vertical?: boolean }>`
  display: flex;
  grid-gap: 14px;
  width: ${({ vertical }) => (vertical ? "100%" : "max-content")};
  max-width: 100%;
  flex-wrap: wrap;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  ${({ vertical }) => vertical && "justify-content: center;"};
  ${({ vertical }) => vertical && "align-items: center;"};
  margin-bottom: ${({ vertical }) => (vertical ? "20px" : "0")};
`;

export { SelectDispo };
