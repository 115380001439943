import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";

interface TimeButtonProps {
  showX?: boolean;
  selected?: boolean;
  onClick?: any;
  children?: any;
  onCloseClick?: any;
  disabled?: boolean;
}

const TimeButton = ({
  disabled = false,
  showX = false,
  selected = false,
  onClick = () => {},
  ...props
}: TimeButtonProps) => {
  return (
    <TimeButtonDiv {...props} onClick={onClick} selected={selected} showX={showX} disabled={disabled}>
      {props.children}
      <AbsoluteIcon
        onClick={(e) => {
          e.stopPropagation();
          props.onCloseClick && props.onCloseClick();
        }}
      >
        {showX && (
          <IoCloseOutline
            size={16}
            color={selected ? theme.WHITE_COLOR : theme.PRIMARY500}
            style={{ marginLeft: "4px" }}
          />
        )}
      </AbsoluteIcon>
    </TimeButtonDiv>
  );
};

const TimeButtonDiv = styled.div<TimeButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR)};
  color: ${(props) => (props.disabled ? theme.NEUTRAL200 : props.selected ? theme.WHITE_COLOR : theme.PRIMARY500)};

  font-weight: 400;
  font-size: 12px;
  line-height: 18;
  cursor: pointer;

  border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};

  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL500)};
  }
  // not really sure why this is needed but here it is
  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 2px;
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;

const AbsoluteIcon = styled.div`
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { TimeButton };
