import React, { useMemo } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { CallReportSegment } from "../CallReportSegments";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { AIProcessingCall } from "../CallReportV2Segments/AIProcessingCall";

const lineChartHeight = 28;

interface AITimelineProps {
  aiProcessing?: boolean;
  aiData: any;
  conferenceData: any;
}

export const AITimeline: React.FC<AITimelineProps> = ({ aiProcessing, aiData, conferenceData }) => {
  if (aiProcessing) return <AIProcessingCall />;

  return (
    <>
      <SentimentChartContainer>
        <FlexDiv align="center" gap={8} style={{ paddingLeft: "62px" }}>
          <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase>
            Rep Sentiment:
          </AppText>
          <StatusTag score={aiData?.avgRepSentiment || 0} />
        </FlexDiv>

        {/* width of 99.9% is a workaround to get responsive width
                    see: https://stackoverflow.com/q/50891591 */}
        <ResponsiveContainer width="99.9%" height={lineChartHeight}>
          <LineChart
            data={aiData?.repSentimentDataPoints || []}
            height={lineChartHeight}
            margin={{ left: 24, right: 24 }}
          >
            {/* linearGradient coords and stops taken from figma */}
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2={lineChartHeight} gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#00A56F" />
                <stop offset="0.25" stop-color="#73BF44" />
                <stop offset="0.50" stop-color="#ECC80F" />
                <stop offset="0.70" stop-color="#FA8700" />
                <stop offset="1" stop-color="#E91E16" />
              </linearGradient>
            </defs>

            <ReferenceLine y={0} stroke={theme.border.neutral.primary} strokeWidth={0.5} />
            <Line dataKey="score" stroke="url(#colorUv)" strokeWidth={2} dot={false} />
            <XAxis type="number" dataKey="x" domain={[0, conferenceData?.duration || 100]} hide={true} />
            <YAxis type="number" domain={[-1, 1]} hide={true} />
          </LineChart>
        </ResponsiveContainer>
      </SentimentChartContainer>
      <SentimentChartContainer>
        <FlexDiv align="center" gap={8} style={{ paddingLeft: "62px" }}>
          <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase>
            Lead Sentiment:
          </AppText>
          <StatusTag score={aiData?.avgRepSentiment || 0} />
        </FlexDiv>

        <ResponsiveContainer width="99.9%" height={lineChartHeight}>
          <LineChart
            data={aiData?.leadSentimentDataPoints || []}
            height={lineChartHeight}
            margin={{ left: 24, right: 24 }}
          >
            <ReferenceLine y={0} stroke={theme.border.neutral.primary} strokeWidth={0.5} />
            <Line dataKey="score" stroke="url(#colorUv)" strokeWidth={2} dot={false} />
            <XAxis type="number" dataKey="x" domain={[0, conferenceData?.duration || 100]} hide={true} />
            <YAxis type="number" domain={[-1, 1]} hide={true} />
          </LineChart>
        </ResponsiveContainer>
      </SentimentChartContainer>

      <FlexDiv direction="column" gap={24} style={{ padding: "24px 0px" }}>
        {/* TODO: update away from salesperson */}
        <SpeakerTimeline timelineData={aiData?.repSpeakingTimeline} speaker={conferenceData?.salesperson} />
        <SpeakerTimeline
          timelineData={aiData?.leadSpeakingTimeline}
          speaker={conferenceData?.lead?.first_name + conferenceData?.lead?.last_name}
        />
      </FlexDiv>
    </>
  );
};

const SpeakerTimeline: React.FC<{ timelineData: any; speaker: string }> = ({ timelineData, speaker }) => {
  return (
    <FlexDiv direction="column" gap={8} style={{ padding: "0px 24px" }}>
      <AppText fontSize={16} fontWeight={400} lineHeight={22} style={{ paddingLeft: "12px" }}>
        {speaker}
      </AppText>

      <TimelineContainer>
        {!!timelineData?.length ? (
          timelineData?.map((o: any) => (
            <div
              style={{
                height: "100%",
                width: `${o.width}%`,
                backgroundColor: o.talking ? theme.fill.brand.primary : theme.fill.neutral.secondary,
              }}
            />
          ))
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.fill.neutral.secondary,
            }}
          />
        )}
      </TimelineContainer>
    </FlexDiv>
  );
};

const SentimentChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  height: 104px;

  padding: 16px 0px 24px 0px;
  background-color: ${theme.fill.neutral.secondary};
`;

const TimelineContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

interface StatusTagProps {
  score: number;
}

const StatusTag: React.FC<StatusTagProps> = ({ score }) => {
  const label = useMemo(() => {
    if (score >= 0.3) {
      return "Positive";
    } else if (score <= -0.3) {
      return "Negative";
    } else {
      return "Neutral";
    }
  }, [score]);
  const color = useMemo(() => {
    switch (label) {
      case "Positive":
        return theme.text.success.primary;
      case "Negative":
        return theme.text.danger.primary;
      default:
        return theme.text.warning.primary;
    }
  }, [label]);

  return (
    <StatusTagContainer color={color}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color}>
        {label}
      </AppText>
    </StatusTagContainer>
  );
};

const StatusTagContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 24px;
  padding: 4px 8px;

  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
`;
