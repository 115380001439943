import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context";
import { chevron_left } from "../../images/NewDesign";
import { IIntegrations } from "../../types";
import { theme } from "../../utils/theme";
import { appToast, errorToast } from "../../utils/toast";
import { FormSelectField, InputField } from "../Field";
import { PhoenixMultiSelectField } from "../Field/Phoenix/PhoenixMultiSelectField";
import { AppErrorText, AppText, Loading, SkeletonBlock } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { PhoenixIcon } from "../UI/Phoenix";
import { PanelModal } from "./PanelModal";
import { MixpanelActions } from "src/services/mixpanel";

interface FieldsData {
  id: string;
  external_object: string;
  external_field: string;
  external_field_label: string;
  opsiq_object: string;
  opsiq_field: string;
  opsiq_field_label: string;
  sync_rule: string;
  trigger_events: {
    label: string;
    value: string;
  }[];
  mapping_type: string;
  opsiq_field_type?: string;
}

const ADD_OR_UPDATE_INTEGRATION_MAPPING = gql`
  mutation addorUpdateIntegrationMapping(
    $mapping_id: String
    $integration_type: INTEGRATION_TYPE!
    $mapping: IntegrationMappingInput!
  ) {
    addorUpdateIntegrationMapping(mapping_id: $mapping_id, integration_type: $integration_type, mapping: $mapping) {
      id
      opsiq_object
      opsiq_field
      opsiq_field_label
      opsiq_field_type
      external_object
      external_field
      external_field_label
      sync_rule
      trigger_events
      mapping_type
      integration_type
      organization_id
      created_at
      updated_at
    }
  }
`;

const REMOVE_INTEGRATION_MAPPING = gql`
  mutation removeIntegrationMapping($mapping_id: String!) {
    removeIntegrationMapping(mapping_id: $mapping_id)
  }
`;

const FETCH_INTEGRATION_MAPPING_OPTIONS = gql`
  query fetchIntegrationMappingOptions(
    $integration: INTEGRATION_TYPE!
    $template_id: String
    $event_types: [SYSTEM_EVENT!]!
  ) {
    fetchIntegrationMappingOptions(integration: $integration, template_id: $template_id, event_types: $event_types)
  }
`;
const FETCH_INTEGRATION_MAPPING_DATA = gql`
  query fetchIntegrationMappingData($integration: INTEGRATION_TYPE!, $external_object: String!) {
    fetchIntegrationMappingData(integration: $integration, external_object: $external_object) {
      field_label
      field_id
      type
      custom
    }
  }
`;

const IntegrationMapRulesV2: React.FC = ({}) => {
  const formikRef = useRef<FormikProps<FieldsData>>(null);
  const {
    showIntegrationMapRulesModal,
    setShowIntegrationMapRulesModal,
    mapStateData,
    selectedIntegration,
    selectedIntegrationLabel,
    editIntegrationType,
    templateId,
    resetMapStateData,
  } = useContext(ModalContext);

  const computeModalInitialStep = (selectedIntegration: IIntegrations) => {
    switch (selectedIntegration) {
      case IIntegrations.Salesforce:
        return mapStateData.trigger_events.findIndex((event) => event === "NewLead") !== -1 ||
          mapStateData.trigger_events.findIndex((event) => event === "HoldActivity") !== -1
          ? 1
          : 0;
      case IIntegrations.HubSpot:
        return 0;
      case IIntegrations.PandaDoc:
        return 1;
      default:
        return 0;
    }
  };

  const [step, setStep] = useState(computeModalInitialStep(selectedIntegration ?? IIntegrations.Salesforce));

  const [showDeleteStep, setShowDeleteStep] = useState(false);

  const [externalField, setExternalField] = useState("");
  const [eventTypes, seteventTypes] = useState(mapStateData.trigger_events);

  const [addorUpdateIntegrationMapping, { loading }] = useMutation(ADD_OR_UPDATE_INTEGRATION_MAPPING, {
    onCompleted({ addorUpdateIntegrationMapping }) {
      if (!addorUpdateIntegrationMapping) {
        appToast("Error: Something went wrong!");
        return;
      }
      const values = formikRef.current?.values;
      MixpanelActions.track("Field Mapping Saved", {
        is_custom: editIntegrationType?.toLowerCase() === "custom",
        integration: selectedIntegration,
        events: values?.trigger_events?.map((event) => event.value),
        sellfire_object: values?.opsiq_object,
        sellfire_field: values?.opsiq_field,
        integration_object: values?.external_object,
        integration_field: values?.external_field,
        sync_rule:
          selectedIntegration === "PandaDoc"
            ? "OpsiqToExternal"
            : values?.sync_rule
            ? values.sync_rule
            : "OpsiqToExternal",
      });
      setShowIntegrationMapRulesModal(false);
      appToast("Updated mappings");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addorUpdateIntegrationMapping GraphQL Error: ${message}`,
      });
      console.log("Error in addorUpdateIntegrationMapping: ", message);
    },
    refetchQueries: ["fetchIntegrationMappings"],
  });

  const [removeIntegrationMapping, { loading: loadingDelete }] = useMutation(REMOVE_INTEGRATION_MAPPING, {
    onCompleted({ removeIntegrationMapping }) {
      if (!removeIntegrationMapping) {
        appToast("Error: Something went wrong!");
        return;
      }

      const values = formikRef.current?.values;
      MixpanelActions.track("Field Mapping Deleted", {
        is_custom: editIntegrationType?.toLowerCase() === "custom",
        integration: selectedIntegration,
        sellfire_object: values?.opsiq_object,
        sellfire_field: values?.opsiq_field,
        integration_object: values?.external_object,
        integration_field: values?.external_field,
        sync_rule:
          selectedIntegration === "PandaDoc"
            ? "OpsiqToExternal"
            : values?.sync_rule
            ? values.sync_rule
            : "OpsiqToExternal",
      });

      setShowDeleteStep(false);
      setShowIntegrationMapRulesModal(false);
      appToast("Mapping deleted successfully");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `removeIntegrationMapping GraphQL Error: ${message}`,
      });
      console.log("Error in removeIntegrationMapping: ", message);
    },
    refetchQueries: ["fetchIntegrationMappings"],
  });

  const [fetchOptions, { data: dataOptions, loading: loadingOptions, error: errorOptions }] = useLazyQuery(
    FETCH_INTEGRATION_MAPPING_OPTIONS,
    {
      variables: {
        integration: selectedIntegration,
        template_id: templateId,
        event_types: eventTypes,
      },
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [
    fetchDataFieldOptions,
    { data: dataFieldOptions, loading: loadingFieldOptions, error: errorFieldOptions },
  ] = useLazyQuery(FETCH_INTEGRATION_MAPPING_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }) {},
  });

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    mapDataField(externalField || mapStateData.external_object);
  }, [externalField]);

  const mapSchema = Yup.object().shape({
    id: Yup.string(),
    external_object: Yup.string(),
    external_field: Yup.string(),
    external_field_label: Yup.string(),
    opsiq_object: Yup.string(),
    opsiq_field: Yup.string(),
    opsiq_field_label: Yup.string(),
    opsiq_field_type: Yup.string(),
    sync_rule: Yup.string(),
    trigger_events: Yup.array().of(Yup.string()),
    mapping_type: Yup.string(),
  });

  const findMappingTypeFromObjectField = (
    integration_type: string,
    external_object: string,
    external_field: string,
  ) => {
    try {
      if (integration_type === "SalesForce") {
        return dataFieldOptions?.fetchIntegrationMappingData
          ?.slice()
          .filter((item: any) => item.field_id === external_field)[0]?.type;
      }
      const type = dataOptions?.fetchIntegrationMappingOptions?.external[external_object]
        ?.slice()
        .filter((item: any) => item.field_id === external_field)[0]?.type;
      return type;
    } catch (error) {
      return "";
    }
  };

  const mapDataField = (external_object: string) => {
    try {
      fetchDataFieldOptions({ variables: { external_object, integration: selectedIntegration } });
    } catch (error) {}
  };

  const getDataField = (values: any) => {
    if (dataFieldOptions?.fetchIntegrationMappingData?.length) {
      const data_field_options = dataFieldOptions?.fetchIntegrationMappingData
        ?.map((field: any) => ({ label: field.field_label, value: field.field_id }))
        .sort((a: any, b: any) => (a.label < b.label ? -1 : 1));

      if (!data_field_options.length) {
        return [{ label: "No Options Found", value: "" }];
      }
      return data_field_options;
    }
    return !!values.external_object
      ? !!dataOptions?.fetchIntegrationMappingOptions?.external[values.external_object]?.length
        ? dataOptions?.fetchIntegrationMappingOptions?.external[values.external_object]
            ?.map((field: any) => ({ label: field.field_label, value: field.field_id }))
            ?.sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
        : [{ label: "No Options Found", value: "" }]
      : [{ label: `Select ${selectedIntegration} Object first`, value: "" }];
  };

  const findMappingType = (editIntegrationType: string) => {
    switch (editIntegrationType) {
      case "System":
        return "SystemField";
      case "Custom":
        return "CustomField";
      case "Static":
        return "Static";
      default:
        return "";
    }
  };

  const SYNC_OPTIONS = [
    {
      enum: "ExternalToOpsiq",
      rule: `Always use ${selectedIntegrationLabel} unless blank`,
      description: `Sellfire will always be updated with the most recent value from ${selectedIntegrationLabel}. Not accepting blank values.`,
    },
    {
      enum: "ExternalToOpsiqAllowBlank",
      rule: `Always use ${selectedIntegrationLabel}`,
      description: `Sellfire will always be updated with the most recent value from ${selectedIntegrationLabel}. Accepting blank values.`,
    },
    {
      enum: "OpsiqToExternal",
      rule: `Always use Sellfire unless blank`,
      description: `${selectedIntegrationLabel} will always be updated with the most recent value from Sellfire. Not accepting blank values.`,
    },
    {
      enum: "OpsiqToExternalAllowBlank",
      rule: `Always use Sellfire`,
      description: `${selectedIntegrationLabel} will always be updated with the most recent value from Sellfire. Accepting blank values.`,
    },
    {
      enum: "Both",
      rule: "Two-way unless blank",
      description: `Both Sellfire and ${selectedIntegrationLabel} will always use the most recent value available. Values deleted in one system will not be deleted in the other.`,
    },
    {
      enum: "BothAllowBlank",
      rule: "Two-way",
      description: `Both Sellfire and ${selectedIntegrationLabel} will always use the most recent value available. The most recent value will always overwrite any existing values. If the value is deleted in one system, it will also be deleted in the other.`,
    },
    {
      enum: "None",
      rule: "Don’t Sync",
      description: `Changes to this property in either Sellfire or ${selectedIntegrationLabel} will not be detected or updated in the corresponding system.`,
    },
  ] as const;

  const findFieldLabelFromIDExternal = (id: string, object: string) => {
    try {
      if (dataFieldOptions?.fetchIntegrationMappingData.length) {
        const field_data = [...dataFieldOptions?.fetchIntegrationMappingData];
        const field_type = field_data.filter((item: any) => item.field_id === id);
        return field_type[0].field_label;
      }
      const type = dataOptions?.fetchIntegrationMappingOptions?.external[object]
        ?.slice()
        .filter((item: any) => item.field_id === id)[0]?.field_label;
      return type;
    } catch (error) {
      return "";
    }
  };

  const findFieldLabelFromIDOpsiq = (id: string, object: string) => {
    try {
      const type = dataOptions?.fetchIntegrationMappingOptions?.opsiq[object]
        ?.slice()
        .filter((item: any) => item.field_id === id)[0]?.field_label;
      return type || "";
    } catch (error) {
      return "";
    }
  };

  const findFieldTypeFromOpsiqFields = (id: string, object: string) => {
    try {
      const type = dataOptions?.fetchIntegrationMappingOptions?.opsiq[object]
        ?.slice()
        .filter((item: any) => item.field_id === id)[0]?.type;
      return type || "";
    } catch (error) {
      return "";
    }
  };

  const returnIfExtraStep = (trigger_events: { label: string; value: string }[]) => {
    return (
      trigger_events.findIndex((event) => event.value === "NewLead") !== -1 ||
      trigger_events.findIndex((event) => event.value === "InboundHold") !== -1
    );
  };

  const renderNumberOfSteps = (trigger_events: { label: string; value: string }[]) => {
    switch (selectedIntegration) {
      case "SalesForce":
        return returnIfExtraStep(trigger_events) ? "3" : "2";
      case "HubSpot":
        return "3";
      case "PandaDoc":
        return "1";
      default:
        return "UNRECOGNIZED INTEGRATION";
    }
  };

  const atBaseStepForIntegration = (selectedIntegration: IIntegrations, step: number) => {
    switch (selectedIntegration) {
      case IIntegrations.Salesforce:
        return step === 0;
      case IIntegrations.HubSpot:
        return step === 0;
      case IIntegrations.PandaDoc:
        return step === 1;
      default:
        return false;
    }
  };

  const getTriggerEventLabel = (event: string) => {
    switch (event) {
      case "Sale":
        return "New Sale";
      case "NewLead":
        return "Lead Create/Update";
      case "InboundHold":
        return "Inbound Hold";
      default:
        return event;
    }
  };

  const handleSyncRule = ({
    values,
    item,
    setFieldValue,
  }: {
    values: FieldsData;
    item: { enum: string; rule: string; description: string };
    setFieldValue: Function;
  }) => {
    if (values.opsiq_field === "lead_ownership_status" || values.opsiq_field === "last_call_result") {
      if (item.enum === "OpsiqToExternal") {
        return setFieldValue("sync_rule", "OpsiqToExternal");
      }
      errorToast("Selected Sellfire field is only supported for outbound sync (Always use Sellfire)");
    } else {
      return setFieldValue("sync_rule", item.enum);
    }
  };

  const fetchOPSIQObjectOptions = (values: FieldsData) => {
    return dataOptions?.fetchIntegrationMappingOptions?.opsiq
      ? Object.keys(dataOptions?.fetchIntegrationMappingOptions?.opsiq)
          .filter((name) => {
            if (values.external_object === "Opportunity") {
              return true;
            }
            return name !== "Reference";
          })
          ?.map((name: string) => ({ label: name, value: name }))
          .sort((a, b) => (a.label < b.label ? -1 : 1))
      : [];
  };

  const ErrorComponent = () => {
    return (
      <ErrorDiv>
        <AppErrorText>ERROR</AppErrorText>
        <AppText style={{ margin: "1rem 0" }}>
          We were unable to fetch field mappings from {selectedIntegrationLabel} at this time. Please wait a moment to
          try again.
        </AppText>
        <ErrorButton variant={"primary"} onClick={() => fetchOptions()}>
          {loadingOptions ? <Loading /> : "Try Again"}
        </ErrorButton>
      </ErrorDiv>
    );
  };

  const closeModal = () => {
    setShowIntegrationMapRulesModal(false);
    resetMapStateData();
  };

  const BackButton = () => {
    return (
      <BackButtonDiv
        onClick={() => {
          if (atBaseStepForIntegration(selectedIntegration ?? IIntegrations.Salesforce, step)) {
            closeModal();
            return;
          }
          setStep(step - 1);
        }}
      >
        <PhoenixIcon svg={chevron_left} size={16} variant="brand" />
        <AppText
          fontSize={10}
          fontWeight={600}
          uppercase
          style={{
            color: theme.PRIMARY500,
          }}
        >
          Back
        </AppText>
      </BackButtonDiv>
    );
  };

  const DottedConnector = () => {
    return <Dots />;
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          id: mapStateData?.id,
          external_object: mapStateData.external_object,
          external_field: mapStateData.external_field,
          external_field_label: mapStateData.external_field_label,
          opsiq_object: mapStateData.opsiq_object,
          opsiq_field: mapStateData.opsiq_field,
          opsiq_field_label: mapStateData.opsiq_field_label,
          sync_rule: mapStateData.sync_rule,
          trigger_events: mapStateData.trigger_events.length
            ? mapStateData.trigger_events?.map((event) => ({
                label: getTriggerEventLabel(event),
                value: event,
              }))
            : [],
          mapping_type: mapStateData?.mapping_type,
          opsiq_field_type: mapStateData.opsiq_field_type,
        }}
        onSubmit={async (values) => {
          await addorUpdateIntegrationMapping({
            variables: {
              mapping_id: !!values?.id ? values.id : undefined,
              integration_type: selectedIntegration,
              mapping: {
                external_object: values.external_object,
                external_field: values.external_field,
                external_field_label: findFieldLabelFromIDExternal(values.external_field, values.external_object),
                opsiq_object: values.opsiq_object,
                opsiq_field: editIntegrationType === "Static" ? values.opsiq_field_label : values.opsiq_field,
                opsiq_field_label:
                  editIntegrationType === "Static"
                    ? values.opsiq_field_label
                    : findFieldLabelFromIDOpsiq(values.opsiq_field, values.opsiq_object),
                opsiq_field_type: findFieldTypeFromOpsiqFields(values.opsiq_field, values.opsiq_object),
                sync_rule:
                  // pandadoc is alwasy OpsiqToExternal and the default for when we don't set a value is opsIQToExternal
                  selectedIntegration === "PandaDoc"
                    ? "OpsiqToExternal"
                    : values.sync_rule
                    ? values.sync_rule
                    : "OpsiqToExternal",
                events: ["SalesForce", "HubSpot"].includes(selectedIntegration ?? "")
                  ? values.trigger_events?.map((event) => event.value)
                  : [],
                mapping_type: !!values.id ? values?.mapping_type : findMappingType(editIntegrationType),
                external_field_type: findMappingTypeFromObjectField(
                  selectedIntegration ?? "",
                  values.external_object,
                  values.external_field,
                ),
                external_template_id: templateId,
              },
            },
          });
        }}
        validationSchema={mapSchema}
      >
        {({ submitForm, setFieldValue, isValid, isSubmitting, values }: FormikProps<FieldsData>) => {
          const requiredFieldsAreNotFilled =
            !values.opsiq_object ||
            !values.opsiq_field ||
            !values.external_object ||
            !values.external_field ||
            [values.opsiq_object, values.opsiq_field, values.external_object, values.external_field].includes("0");
          return (
            <PopupContainerDiv>
              {showDeleteStep ? (
                <PanelModal open={showIntegrationMapRulesModal} onClose={closeModal}>
                  <PaddingDeleteDiv>
                    <DeleteText>Are you sure you want to delete?</DeleteText>
                    <DeleteText style={{ fontWeight: "normal", marginTop: "4px" }}>
                      This will delete the mapping of {values.opsiq_field_label} and {values.external_field_label}.
                    </DeleteText>
                  </PaddingDeleteDiv>
                  <SubmitDiv>
                    <DeleteMapButton
                      style={{ color: theme.PRIMARY500, marginRight: "12px" }}
                      onClick={() => setShowDeleteStep(false)}
                    >
                      No, Go Back
                    </DeleteMapButton>
                    <DeleteMapButton
                      disabled={loadingDelete}
                      onClick={async () =>
                        await removeIntegrationMapping({
                          variables: {
                            mapping_id: values.id,
                          },
                        })
                      }
                    >
                      Yes, Delete Mapping
                    </DeleteMapButton>
                  </SubmitDiv>
                </PanelModal>
              ) : (
                <PanelModal open={showIntegrationMapRulesModal} onClose={closeModal}>
                  <Modal>
                    <TitleDiv>
                      <TitleText>
                        {!!values.id
                          ? `Edit ${editIntegrationType} Field Mapping`
                          : `Map a ${editIntegrationType} Field`}
                      </TitleText>
                    </TitleDiv>
                    <BackButton />
                    <Main>
                      <AppText fontSize={10} fontWeight={600} color={theme.NEUTRAL300} uppercase>
                        step{" "}
                        {selectedIntegration === "SalesForce" || selectedIntegration === "HubSpot"
                          ? step + 1
                          : selectedIntegration === "PandaDoc"
                          ? step
                          : step - 1}{" "}
                        of {renderNumberOfSteps(values.trigger_events)}
                      </AppText>
                      {step === 0 && <SectionTitle>Select an Action for Integration Mapping</SectionTitle>}
                      {step === 1 && (
                        <SectionTitle>{`Map Sellfire field to ${selectedIntegrationLabel} field`}</SectionTitle>
                      )}
                      {step === 0 && (
                        <DropdownsContainer>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              minHeight: "400px",
                            }}
                          >
                            <DropdownLabel>
                              Action
                              <span style={{ color: theme.ATTENTION700 }}>*</span>
                            </DropdownLabel>

                            {loadingOptions ? (
                              <SkeletonBlock height={"40px"} width={"100%"} borderRadius={6} />
                            ) : errorOptions ? (
                              <ErrorComponent />
                            ) : (
                              <PhoenixMultiSelectField
                                name="trigger_events"
                                options={
                                  dataOptions?.fetchIntegrationMappingOptions?.events
                                    ? dataOptions?.fetchIntegrationMappingOptions?.events
                                        ?.map((event: { label: string; value: string }) => ({
                                          label: event.label,
                                          value: event.value,
                                        }))
                                        .sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
                                    : []
                                }
                                onChange={(e: any) => {
                                  if (e?.length && e?.map((el: any) => el.value).includes("InboundHold")) {
                                    setFieldValue(`opsiq_object`, "HoldActivity");
                                  }
                                  setFieldValue(`trigger_events`, e);
                                  seteventTypes(e?.map((el: any) => el.value));
                                }}
                              />
                            )}
                          </div>
                        </DropdownsContainer>
                      )}
                      {step === 1 &&
                        (loadingOptions ? (
                          <DropdownsContainer>
                            <SkeletonBlock height={600} width={"100%"} borderRadius={6} />
                          </DropdownsContainer>
                        ) : errorOptions ? (
                          <ErrorComponent />
                        ) : (
                          <DropdownsContainer>
                            <Mapping>
                              <DottedConnector />
                              <MappingPair>
                                <MappingObject>
                                  <DropdownLabel>Sellfire Object</DropdownLabel>
                                  <FormSelectField name="opsiq_object" options={fetchOPSIQObjectOptions(values)} />
                                </MappingObject>
                                <MappingObject>
                                  <DropdownLabel>Sellfire Field</DropdownLabel>
                                  {editIntegrationType === "Static" ? (
                                    <MapInputField name="opsiq_field_label" />
                                  ) : (
                                    <FormSelectField
                                      name="opsiq_field"
                                      allowSelectPlaceholder
                                      placeholder="Select field"
                                      disabled={!values.opsiq_object}
                                      options={
                                        !!values.opsiq_object &&
                                        !!dataOptions?.fetchIntegrationMappingOptions?.opsiq[values.opsiq_object]
                                          ?.length
                                          ? dataOptions?.fetchIntegrationMappingOptions?.opsiq[values.opsiq_object]
                                              .slice()
                                              .filter((item: any) =>
                                                editIntegrationType === "Custom" ? item.custom : !item.custom,
                                              )
                                              // .sort((a: any, b: any) => (a.field_label > b.field_label ? 1 : -1))
                                              ?.map((field: any) => ({
                                                label: field.field_label,
                                                value: field.field_id,
                                              }))
                                              .sort((a: any, b: any) => (a.label < b.label ? -1 : 1))
                                              ?.map((item: any) => JSON.stringify(item))
                                              .filter((v: string, i: number, a: string[]) => a.indexOf(v) === i)
                                              ?.map((item: any) => JSON.parse(item))
                                          : [{ label: "Select Sellfire Object first", value: "" }]
                                      }
                                    />
                                  )}
                                </MappingObject>
                              </MappingPair>
                            </Mapping>
                            <Mapping>
                              <DottedConnector />
                              <MappingPair>
                                <MappingObject>
                                  <DropdownLabel>{selectedIntegrationLabel} Object </DropdownLabel>
                                  <FormSelectField
                                    allowSelectPlaceholder
                                    placeholder="Select field category"
                                    name="external_object"
                                    onChange={(e: any) => {
                                      setExternalField(e.target.value);
                                      setFieldValue("external_object", e.target.value);
                                    }}
                                    options={
                                      dataOptions?.fetchIntegrationMappingOptions?.external
                                        ? Object.keys(dataOptions?.fetchIntegrationMappingOptions?.external)
                                            ?.map((name: string) => ({ label: name, value: name }))
                                            .sort((a, b) => (a.label < b.label ? -1 : 1))
                                        : []
                                    }
                                  />
                                </MappingObject>

                                <MappingObject>
                                  <DropdownLabel>{selectedIntegration} Field</DropdownLabel>
                                  {loadingFieldOptions ? (
                                    <SkeletonBlock height={"100%"} width={"100%"} borderRadius={6} />
                                  ) : (
                                    <FormSelectField
                                      name="external_field"
                                      disabled={
                                        !(
                                          values.external_object &&
                                          (dataFieldOptions?.fetchIntegrationMappingData?.length ||
                                            dataOptions?.fetchIntegrationMappingOptions?.external[
                                              values.external_object
                                            ]?.length)
                                        )
                                      }
                                      allowSelectPlaceholder
                                      placeholder={`Select ${selectedIntegration} Object first`}
                                      options={getDataField(values)}
                                    />
                                  )}
                                </MappingObject>
                              </MappingPair>
                            </Mapping>
                          </DropdownsContainer>
                        ))}
                      {step === 2 && (
                        <OptionsContainer>
                          {SYNC_OPTIONS?.map((item) => (
                            <SyncObject
                              selected={item.enum === values.sync_rule}
                              disabled={
                                values.opsiq_field === "lead_ownership_status" ||
                                values.opsiq_field === "last_call_result"
                              }
                              is_outbound={item.enum === "OpsiqToExternal"}
                              onClick={() => {
                                handleSyncRule({ item, setFieldValue, values });
                              }}
                            >
                              <CenterDiv>
                                <RadioBorder selected={item.enum === values.sync_rule}>
                                  <RadioButton selected={item.enum === values.sync_rule} />
                                </RadioBorder>
                              </CenterDiv>
                              <div>
                                <OptionTitleText>{item.rule}</OptionTitleText>
                                <OptionDescriptionText>{item.description}</OptionDescriptionText>
                              </div>
                            </SyncObject>
                          ))}
                        </OptionsContainer>
                      )}
                    </Main>
                    {/* submit div for panda doc is unique */}
                    {selectedIntegration === "PandaDoc" ? (
                      <SubmitDiv>
                        {values.id ? (
                          <DeleteButton onClick={() => setShowDeleteStep(true)}>Delete</DeleteButton>
                        ) : (
                          <CancelButton
                            onClick={() => {
                              setShowIntegrationMapRulesModal(false);
                              resetMapStateData();
                            }}
                          >
                            Cancel
                          </CancelButton>
                        )}
                        <ConfirmButton
                          type="submit"
                          disabled={loading || requiredFieldsAreNotFilled}
                          variant={"primary"}
                          onClick={() => {
                            submitForm();
                            setShowIntegrationMapRulesModal(false);
                            resetMapStateData();
                          }}
                        >
                          Save
                        </ConfirmButton>
                      </SubmitDiv>
                    ) : step === 0 ||
                      (selectedIntegration === "HubSpot"
                        ? step === 1 // Saleforce integration has an extra step if trigger_events includes "NewLead"
                        : step === 1 && returnIfExtraStep(values.trigger_events)) ? (
                      <SubmitDiv>
                        {values.id ? (
                          <DeleteButton onClick={() => setShowDeleteStep(true)}>Delete</DeleteButton>
                        ) : (
                          <CancelButton
                            onClick={() => {
                              setShowIntegrationMapRulesModal(false);
                              resetMapStateData();
                            }}
                          >
                            Cancel
                          </CancelButton>
                        )}
                        <ConfirmButton
                          type="submit"
                          variant={"primary"}
                          onClick={() => setStep(step + 1)}
                          disabled={
                            step === 1 &&
                            (selectedIntegration === "HubSpot" ||
                              (selectedIntegration === "SalesForce" && !!values.id)) &&
                            requiredFieldsAreNotFilled
                          }
                        >
                          Next
                        </ConfirmButton>
                      </SubmitDiv>
                    ) : (
                      <SubmitDiv>
                        {values.id ? (
                          <DeleteButton onClick={() => setShowDeleteStep(true)}>Delete</DeleteButton>
                        ) : (
                          <CancelButton
                            onClick={() => {
                              setShowIntegrationMapRulesModal(false);
                              resetMapStateData();
                            }}
                          >
                            Cancel
                          </CancelButton>
                        )}
                        <ConfirmButton
                          type="submit"
                          variant={"primary"}
                          disabled={loading || requiredFieldsAreNotFilled}
                          onClick={() => submitForm()}
                        >
                          Save
                        </ConfirmButton>
                      </SubmitDiv>
                    )}
                  </Modal>
                </PanelModal>
              )}
            </PopupContainerDiv>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const Modal = styled.div`
  height: 100%;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
`;
const MapInputField = styled(InputField)`
  margin: 0px;
`;

const DeleteText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  width: 100%;
`;

const DeleteMapButton = styled(NewAppButton)`
  height: 40px;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${theme.ATTENTION700};
`;

const PaddingDeleteDiv = styled.div`
  padding: 48px 58px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
`;

const DropdownsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

interface SelectedProp {
  selected: boolean;
}

const RadioBorder = styled.div<SelectedProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 50%;
`;

const RadioButton = styled.div<SelectedProp>`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : "transparent")};
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
`;

const OptionTitleText = styled(AppText)`
  font-weight: 600;
  font-size: 12px;
`;

const OptionDescriptionText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
`;

interface OptionContainerProp {
  selected: boolean;
  disabled: boolean;
  is_outbound: boolean;
}

const OptionContainer = styled.div<OptionContainerProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 4px;
  cursor: ${(props) => {
    if (props.is_outbound) {
      return "pointer";
    } else if (!props.disabled) {
      return "pointer";
    }
    return "cursor";
  }};
  display: grid;
  grid-template-columns: 88px 1fr;
`;

const SyncObject = styled.div<OptionContainerProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL300)};
  border-radius: 4px;
  cursor: ${(props) => {
    if (props.is_outbound) {
      return "pointer";
    } else if (!props.disabled) {
      return "pointer";
    }
    return "cursor";
  }};
  display: flex;
  padding: 16px;
  gap: 16px;

  border-radius: 8px;

  width: 329px;
`;

const DropdownLabel = styled(AppText)`
  font-weight: 500;
  font-size: 12px;
`;

const Mapping = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 40px;
`;

const SectionTitle = styled(AppText)`
  font-weight: 500;
  font-size: 12px;
  width: 100%;
`;

const SubmitDiv = styled.div`
  padding: 24px;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const Main = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 400px;
`;

const ConfirmButton = styled(NewAppButton)`
  height: 40px;
  width: 120px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const DeleteButton = styled(NewAppButton)`
  height: 40px;
  width: 120px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${theme.ATTENTION700};
  margin-right: 12px;
`;

const CancelButton = styled(NewAppButton)`
  height: 40px;
  width: 120px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: ${theme.ATTENTION700};
  margin-right: 12px;
`;

const PopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
`;

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
  border: 1px solid ${theme.NEUTRAL100};
  padding: 2rem;
`;

const ErrorButton = styled(NewAppButton)`
  :active {
    background-color: ${theme.PRIMARY800};
  }
`;

const MappingPair = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const MappingObject = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 16px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  width: 329px;
`;

const BackButtonDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
  margin-left: 36px;
`;

const Dots = styled.div`
  top: 24%;
  position: absolute;
  height: 145px;
  width: 16px;
  left: -16px;
  border-top: 1px dashed #206cd0;
  border-bottom: 1px dashed #206cd0;
  border-left: 1px dashed #206cd0;
`;

export { IntegrationMapRulesV2 };
