import React, { Component } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { mobileSize } from "../../utils/breakpoints";

export interface ModalProps {
  showModal: boolean;
  // label: string;
  toggleModal: () => void;
  children: React.ReactNode;
  zIndex?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  marginTop?: string;
  marginBottom?: string;
}

export interface ModalState {}

class Modal extends Component<ModalProps, ModalState> {
  public render(): JSX.Element {
    const {
      showModal,
      // label,
      toggleModal,
      children,
      zIndex = "10",
      marginBottom = "30px",
      marginTop = "30px",
      backgroundColor = "rgb(26, 26, 26, 0.4)",
    } = this.props;
    return (
      <StyledModal
        shouldCloseOnOverlayClick={false}
        isOpen={showModal}
        // contentLabel={label}
        ariaHideApp={false}
        zIndex={zIndex}
        marginBottom={marginBottom}
        marginTop={marginTop}
        backgroundColor={backgroundColor}
        {...this.props}
        onRequestClose={toggleModal}
      >
        {children}
      </StyledModal>
    );
  }
}

function ReactModalAdapter({ className, children, ...props }: any) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal portalClassName={className} className={contentClassName} overlayClassName={overlayClassName} {...props}>
      {children}
    </ReactModal>
  );
}

interface AdditionalModalProps {
  width?: "940px";
  zIndex?: "10";
  backgroundColor?: "rgb(26, 26, 26, 0.4)";
  marginTop?: "30px";
  marginBottom?: "30px";
}

const StyledModal = styled(ReactModalAdapter)<AdditionalModalProps>`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${(props) => props.backgroundColor};
    z-index: ${(props) => props.zIndex};
    overflow-y: auto;
    max-height: 100%;
    outline: none;
  }
  &__content {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: auto;
    background: #fff;
    overflow-y: auto;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    -webkit-overflow-scrolling: touch;
    outline: none;

    @media ${mobileSize} {
      /* max-height: 600px; */
      overflow-y: auto;
    }
  }
`;

export { Modal };
