import React, { Dispatch, SetStateAction, memo, useMemo } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { isEqual } from "lodash";
import { DraggableFieldType } from "src/utils/variables";
import { CustomField, FieldItem as FieldItemType, SaleConfigSection } from "src/utils/misc";
import { OptionItem } from "src/types";
import { DraggableFieldBase } from "./DraggableFieldBase";
import { RequiredField } from "./RequiredField";
import { FieldListHeader } from "./FieldListHeader";
import { NoteField } from "./NoteField";
import { Field } from "./Field";

type FieldItem = FieldItemType<SaleConfigSection>;

const SaleConfigFieldList: React.FC<{
  droppableId: string;
  handleFieldDragEnd: (result: DropResult) => void;
  onChangeHandlers: {
    onInputChange: (id: string, newContent: string) => void;
    onNoteStyleChange: (id: string, newStyle: "default" | "warning" | "link") => void;
    onFieldChange: (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => void;
    onFieldRequiredChange: (id: string, newRequired: boolean) => void;
    onFieldRadioChange: (id: string, newType: "system" | "custom") => void;
  };
  fields: FieldItem[] | undefined;
  allFields: FieldItem[] | undefined;
  customFieldData: CustomField[];
  customFieldOptions: OptionItem[];
  setSelectedFieldID: Dispatch<SetStateAction<string>>;
}> = memo(
  ({
    handleFieldDragEnd,
    droppableId,
    fields,
    allFields,
    customFieldData,
    customFieldOptions,
    onChangeHandlers: { onInputChange, onNoteStyleChange, onFieldChange, onFieldRequiredChange, onFieldRadioChange },
    setSelectedFieldID,
  }) => {
    const systemFields = useMemo(() => fields?.filter((f: FieldItem) => f.baseType === DraggableFieldType.FIELD), [
      fields,
    ]);

    const hideDelete = useMemo(() => !!allFields && allFields.length <= 1, [allFields]);

    return (
      <DragDropContext onDragEnd={handleFieldDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields?.map((f: FieldItem, i: number) => (
                <DraggableFieldBase
                  key={`drag-field-${f.id}`}
                  type={f.baseType}
                  index={i}
                  id={f.id}
                  systemFields={systemFields}
                  setSelectedFieldID={setSelectedFieldID}
                  hideDelete={hideDelete}
                >
                  {f.baseType === DraggableFieldType.FIELD && (
                    <Field
                      data={f}
                      allFields={allFields}
                      customFieldData={customFieldData}
                      customFieldOptions={customFieldOptions}
                      onFieldChange={onFieldChange}
                      onFieldRequiredChange={onFieldRequiredChange}
                      onFieldRadioChange={onFieldRadioChange}
                    />
                  )}
                  {f.baseType === DraggableFieldType.REQUIRED_FIELD && <RequiredField value={f?.content || ""} />}
                  {f.baseType === DraggableFieldType.HEADER && (
                    <FieldListHeader id={f.id} content={f?.content || ""} onInputChange={onInputChange} />
                  )}
                  {f.baseType === DraggableFieldType.NOTE && (
                    <NoteField
                      id={f.id}
                      content={f?.content || ""}
                      type={f.type === "LINK" ? "link" : f.type === "WARNING" ? "warning" : "default"}
                      onInputChange={onInputChange}
                      onNoteStyleChange={onNoteStyleChange}
                    />
                  )}
                </DraggableFieldBase>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  },
  (oldProps, newProps) =>
    isEqual(oldProps?.fields, newProps?.fields) && isEqual(oldProps?.allFields, newProps?.allFields),
);

export default SaleConfigFieldList;
