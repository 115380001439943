import React, { useContext } from "react";
import styled from "styled-components";
import { ModalContext } from "../../../../context";
import { EdgeProps, getEdgeCenter, getSmoothStepPath } from "react-flow-renderer";
import { PhoenixIcon } from "../../Phoenix";
import { plus } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";

const foreignObjectSize = 24;

export const ButtonEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  source,
}) => {
  const { setShowSelectAnActionModal, setSequenceStepData } = useContext(ModalContext);

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <PlusButton
          onClick={() => {
            setShowSelectAnActionModal(true);
            setSequenceStepData({ originId: source, id: id.split("=")?.[1] });
          }}
        >
          <PhoenixIcon
            svg={plus}
            size="small"
            variant="white"
            hoverColor="white"
            pointer
            style={{ marginRight: "1px" }}
          />
        </PlusButton>
      </foreignObject>
    </>
  );
};

const PlusButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border: none;
  border-radius: 50%;

  background-color: ${theme.PRIMARY500};
  cursor: pointer;
  transition: background-color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY600};
  }

  // disable animation propagation that comes from .react-flow__edge.animated
  & path {
    stroke-dasharray: 0 !important;
    animation: none !important;
  }
`;
