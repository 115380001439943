import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { PhoenixIcon } from "../Phoenix";
import {
  arrow_down,
  check,
  dollar_sign,
  edit,
  eye_off,
  pause,
  reorder,
  repeat,
  trash,
} from "../../../images/NewDesign";
import { Draggable } from "react-beautiful-dnd";
import { AllSequenceFetchShape, Segment } from "../../../types/SequenceTypes";
import { FlexDiv } from "../FlexDiv";
import { AppText } from "../AppText";
import { SequenceGraph, SequenceGraphProps } from "./SequenceGraph";
import { FETCH_SEQUENCE_MODULE_METRICS, SEQUENCE_REPORTING_CHARTS } from "../../../utils/sequence-reporting";
import { ModalContext } from "../../../context";
import { DocumentNode } from "graphql";
import { useQuery } from "@apollo/client";
import { SkeletonBlock } from "../SkeletonBlock";

interface ReportingSegmentProps {
  index: number;
  segmentData: Segment;
  sequenceData: AllSequenceFetchShape[];
  segments: Segment[];
  setSegments: React.Dispatch<React.SetStateAction<Segment[]>>;
  timeframe: { lowerbound_date: Date | string | undefined; upperbound_date: Date | string | undefined };
  filterBySequences: string[];
  filterByPhases: string[];
  filterBySalesTeamsAndReps: { reps: string[]; roles: string[]; teams: string[]; sites: string[] };
  setSelectedSegment: React.Dispatch<React.SetStateAction<Segment | null>>;
  finishedLoadingCharts: DocumentNode[];
  setFinishedLoadingCharts: React.Dispatch<React.SetStateAction<DocumentNode[]>>;
}

export const ReportingSegment: React.FC<ReportingSegmentProps> = ({
  index,
  segmentData,
  sequenceData,
  segments,
  setSegments,
  timeframe,
  filterBySequences,
  filterByPhases,
  filterBySalesTeamsAndReps,
  setSelectedSegment,
  finishedLoadingCharts,
  setFinishedLoadingCharts,
}) => {
  const { setShowHideReportingSectionModal, setShowEditSectionModal } = useContext(ModalContext);

  const { lowerbound_date, upperbound_date } = useMemo(() => timeframe, [timeframe]);

  return (
    <Draggable draggableId={`reporting-segment-${index}`} index={index}>
      {(provided, snapshot) => (
        <SegmentContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{ ...provided.draggableProps.style }}
        >
          <DragHandle {...provided.dragHandleProps}>
            <PhoenixIcon
              svg={reorder}
              size={16}
              color={theme.NEUTRAL300}
              hoverColor={theme.NEUTRAL300}
              style={{ cursor: snapshot.isDragging ? "grabbing" : "grab" }}
            />
          </DragHandle>

          <SegmentContent direction="column">
            <SegmentHeader justify="space-between">
              <AppText fontSize={18} fontWeight={500} lineHeight={24}>
                {segmentData.type === "Sequence" ? segmentData.title : segmentData.type}
              </AppText>

              {segmentData.type === "Sequence" ? (
                <PhoenixIcon
                  svg={trash}
                  size={16}
                  color={theme.PRIMARY500}
                  pointer
                  onClick={() =>
                    setSegments(segments.filter((segment) => segment.sequence_id !== segmentData.sequence_id))
                  }
                />
              ) : (
                <FlexDiv align="center" gap={16}>
                  <PhoenixIcon
                    svg={eye_off}
                    size={16}
                    variant="neutral"
                    pointer
                    onClick={() => {
                      setShowHideReportingSectionModal(true);
                      setSelectedSegment(segmentData);
                    }}
                  />
                  <PhoenixIcon
                    svg={edit}
                    size={16}
                    variant="brand"
                    pointer
                    onClick={() => {
                      setShowEditSectionModal(true);
                      setSelectedSegment(segmentData);
                    }}
                  />
                </FlexDiv>
              )}
            </SegmentHeader>
            <ReportingSegmentBody gap={16}>
              {segmentData.type === "Sequence" ? (
                <SequenceSegmentContent
                  sequenceId={segmentData.sequence_id || ""}
                  filterSequenceIds={sequenceData
                    ?.filter((sequence: any) =>
                      !!filterBySequences.length ? filterBySequences?.includes(sequence?.id) : true,
                    )
                    ?.filter((sequence: any) =>
                      !!filterByPhases.length
                        ? filterByPhases.includes(sequence?.sequence_entry_criteria?.current_phase?.[0])
                        : true,
                    )
                    ?.map((sequence: any) => sequence?.id)}
                />
              ) : (
                segmentData?.charts
                  ?.filter((chart: string) => segmentData?.selected_charts?.includes(chart))
                  ?.map((chartId: string, i: number) => {
                    const chart =
                      segmentData.type !== "Sequence" &&
                      SEQUENCE_REPORTING_CHARTS[segmentData.type].find(
                        (chart: SequenceGraphProps) => chart.id === chartId,
                      );
                    if (chart) {
                      return (
                        <SequenceGraph
                          fetchArgs={{
                            lowerbound_date,
                            upperbound_date,
                            sequence_ids: chart.refetchOnGlobalFilterChange
                              ? sequenceData
                                  ?.filter((sequence: any) =>
                                    !!filterBySequences.length ? filterBySequences?.includes(sequence?.id) : true,
                                  )
                                  ?.filter((sequence: any) =>
                                    !!filterByPhases.length
                                      ? filterByPhases.includes(sequence?.sequence_entry_criteria?.current_phase?.[0])
                                      : true,
                                  )
                                  ?.map((sequence: any) => sequence?.id)
                              : sequenceData?.map((sequence: any) => sequence?.id),
                            team_ids: filterBySalesTeamsAndReps?.teams,
                            user_ids: filterBySalesTeamsAndReps?.reps,
                            site_ids: filterBySalesTeamsAndReps?.sites,
                          }}
                          {...chart}
                          filterBySequences={filterBySequences}
                          filterByPhases={filterByPhases}
                          allSequenceData={sequenceData}
                          finishedLoadingCharts={finishedLoadingCharts}
                          setFinishedLoadingCharts={setFinishedLoadingCharts}
                          key={`${segmentData.type}-${i}`}
                        />
                      );
                    }
                  })
              )}
            </ReportingSegmentBody>
          </SegmentContent>
        </SegmentContainer>
      )}
    </Draggable>
  );
};

const SegmentContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  min-height: 75px;

  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;

  overflow: hidden;

  margin-bottom: 16px;
`;

const DragHandle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  float: left;

  min-width: 24px;
  height: auto;
  background-color: ${theme.PRIMARY50};
`;

const SegmentContent = styled(FlexDiv)`
  max-width: 100%;
  min-width: 100%;
  padding-top: 16px;
`;

const ReportingSegmentBody = styled(FlexDiv)`
  overflow-x: auto;
  padding: 16px 40px 16px 16px;
`;

const SegmentHeader = styled(FlexDiv)`
  width: calc(100% - 24px);
  padding: 0px 16px;
`;

interface IMetrics {
  converted_rate: number | string;
  influenced_revenue: number | string;
  not_interested_rate: number | string;
  scheduled_hold_rate: number | string;
  close_rate: number | string;
  sale_count: number | string;
  revenue: number | string;
}

interface SequenceSegmentContentProps {
  sequenceId: string;
  filterSequenceIds: string[];
}

const SequenceSegmentContent: React.FC<SequenceSegmentContentProps> = ({ sequenceId, filterSequenceIds }) => {
  const { data: dataMetrics, loading: loadingMetrics } = useQuery(FETCH_SEQUENCE_MODULE_METRICS, {
    fetchPolicy: "network-only",
    variables: {
      sequencesFilter: { id: sequenceId },
      sequenceDashboardInput: {
        sequence_ids: filterSequenceIds,
      },
    },
    onCompleted({ fetchSequences }) {
      console.log("fetchSequences: MODULE METRICS:", fetchSequences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const metrics: IMetrics = useMemo(() => {
    const moduleMetrics = dataMetrics?.fetchSequences?.[0]?.moduleMetrics;
    return {
      converted_rate: moduleMetrics?.converted_rate,
      influenced_revenue: moduleMetrics?.influenced_revenue,
      not_interested_rate: moduleMetrics?.not_interested_rate,
      scheduled_hold_rate: moduleMetrics?.scheduled_hold_rate,
      close_rate: moduleMetrics?.close_rate,
      sale_count: moduleMetrics?.sale_count,
      revenue: moduleMetrics?.revenue,
    };
  }, [dataMetrics]);

  return (
    <>
      <SequenceSegmentCard>
        <SequenceSegmentCardIcon icon={dollar_sign} iconColor={theme.WHITE_COLOR} backgroundColor={theme.SUCCESS500} />
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
            Total Sales
          </AppText>
          {!loadingMetrics ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={28}>
              {metrics?.sale_count || 0}
              <SaleSubtext>{` / $${metrics?.revenue || 0}`}</SaleSubtext>
            </FadeInText>
          ) : (
            <SkeletonBlock width={64} height={24} borderRadius={4} />
          )}
        </FlexDiv>
      </SequenceSegmentCard>
      <SequenceSegmentCard>
        <SequenceSegmentCardIcon icon={dollar_sign} iconColor={theme.WHITE_COLOR} backgroundColor={theme.SUCCESS500} />
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
            Revenue Influenced
          </AppText>
          {!loadingMetrics ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={28}>
              ${metrics?.influenced_revenue}
            </FadeInText>
          ) : (
            <SkeletonBlock width={64} height={24} borderRadius={4} />
          )}
        </FlexDiv>
      </SequenceSegmentCard>
      <SequenceSegmentCard>
        <SequenceSegmentCardIcon icon={check} iconColor={theme.PRIMARY500} backgroundColor={theme.PRIMARY100} />
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
            Close Rate
          </AppText>
          {!loadingMetrics ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={28}>
              {typeof metrics?.close_rate === "number" ? metrics.close_rate.toFixed(2) : metrics?.close_rate}%
            </FadeInText>
          ) : (
            <SkeletonBlock width={64} height={24} borderRadius={4} />
          )}
        </FlexDiv>
      </SequenceSegmentCard>
      <SequenceSegmentCard>
        <SequenceSegmentCardIcon icon={repeat} iconColor={theme.SUCCESS500} backgroundColor={theme.SUCCESS200} />
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
            Conversion Rate
          </AppText>
          {!loadingMetrics ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={28}>
              {typeof metrics?.converted_rate === "number"
                ? metrics.converted_rate.toFixed(2)
                : metrics?.converted_rate}
              %
            </FadeInText>
          ) : (
            <SkeletonBlock width={64} height={24} borderRadius={4} />
          )}
        </FlexDiv>
      </SequenceSegmentCard>
      <SequenceSegmentCard>
        <SequenceSegmentCardIcon icon={pause} iconColor={theme.WARNING500} backgroundColor={theme.WARNING300} />
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
            Scheduled Hold Rate
          </AppText>
          {!loadingMetrics ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={28}>
              {typeof metrics?.scheduled_hold_rate === "number"
                ? metrics.scheduled_hold_rate.toFixed(2)
                : metrics?.scheduled_hold_rate}
              %
            </FadeInText>
          ) : (
            <SkeletonBlock width={64} height={24} borderRadius={4} />
          )}
        </FlexDiv>
      </SequenceSegmentCard>
      <SequenceSegmentCard>
        <SequenceSegmentCardIcon icon={arrow_down} iconColor={theme.DANGER600} backgroundColor={theme.DANGER100} />
        <FlexDiv direction="column" gap={4}>
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
            Not Interested Rate
          </AppText>
          {!loadingMetrics ? (
            <FadeInText fontSize={22} fontWeight={500} lineHeight={28}>
              {typeof metrics?.not_interested_rate === "number"
                ? metrics.not_interested_rate.toFixed(2)
                : metrics?.not_interested_rate}
              %
            </FadeInText>
          ) : (
            <SkeletonBlock width={64} height={24} borderRadius={4} />
          )}
        </FlexDiv>
      </SequenceSegmentCard>
    </>
  );
};

const SequenceSegmentCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  min-width: 240px;
  height: fit-content;
  padding: 16px;

  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 8px;
`;

interface SequenceSegmentCardIconProps {
  backgroundColor: string;
  icon: string;
  iconColor: string;
}
const SequenceSegmentCardIcon: React.FC<SequenceSegmentCardIconProps> = ({ backgroundColor, icon, iconColor }) => {
  return (
    <SequenceSegmentCardIconContainer backgroundColor={backgroundColor}>
      <PhoenixIcon svg={icon} size={24} color={iconColor} hoverColor={iconColor} />
    </SequenceSegmentCardIconContainer>
  );
};

interface SequenceSegmentCardIconContainerProps {
  backgroundColor: string;
}
const SequenceSegmentCardIconContainer = styled.div<SequenceSegmentCardIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 6px;
`;

const SaleSubtext = styled.span`
  color: ${theme.NEUTRAL300};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 4px;
`;

const FadeInText = styled(AppText)`
  animation: ${theme.textFadeIn} 0.2s ease-in-out forwards;
`;
