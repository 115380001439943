import * as React from "react";
import { useMutation, gql } from "@apollo/client";
import styled from "styled-components";
import * as Yup from "yup";
import { MixpanelActions } from "src/services/mixpanel";
import useModalContext from "src/hooks/useModalContext";
import { AppText, FlexDiv } from "../UI";
import { theme } from "../../utils/theme";

import { Formik, FormikProps } from "formik";

import { InputField } from "../Field";
import { appToast, errorToast } from "../../utils/toast";
import { Modal } from "./Modal";
import { PhoenixAppButton, PhoenixCheckbox } from "../UI/Phoenix";
import { LeadFilterContext } from "src/context";

const CREATE_UPDATE_SAVED_VIEW = gql`
  mutation createOrUpdateAllLeadsSavedViewFilter($allLeadsSavedViewFilterInput: AllLeadsSavedViewFilterInput!) {
    createOrUpdateAllLeadsSavedViewFilter(allLeadsSavedViewFilterInput: $allLeadsSavedViewFilterInput) {
      id
      name
      description
    }
  }
`;

const formSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  is_default: Yup.boolean(),
});

type FormikType = {
  name: string;
  description: string;
  is_default: boolean;
};

const AddOrEditLeadFilterSavedViewModal: React.FC = () => {
  const { leadsFilterVariables } = React.useContext(LeadFilterContext);

  const {
    leadFilterSavedViewModalData,
    setLeadFilterSavedViewModalData,
    setShowAddOrEditLeadFilterSavedViewModal,
    showAddOrEditLeadFilterSavedViewModal,
  } = useModalContext();

  const closeModal = () => {
    setShowAddOrEditLeadFilterSavedViewModal(false);
    setLeadFilterSavedViewModalData(null);
  };

  const [createOrUpdateAllLeadsSavedViewFilter, { loading }] = useMutation(CREATE_UPDATE_SAVED_VIEW, {
    onCompleted() {
      appToast(leadFilterSavedViewModalData?.id ? "Lead filter saved view updated" : "Lead filter saved view added");
      MixpanelActions.track(
        leadFilterSavedViewModalData?.id ? "Lead filter saved view updated" : "Lead filter saved view added",
      );
      closeModal();
    },
    onError({ message }) {
      errorToast(message);
      console.log("Error in createOrUpdateAllLeadsSavedViewFilter: ", message);
    },
    refetchQueries: ["fetchAllLeadsSavedViewFilters"],
  });

  return (
    <Modal open={showAddOrEditLeadFilterSavedViewModal} onClose={closeModal}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: leadFilterSavedViewModalData?.name || "",
          description: leadFilterSavedViewModalData?.description || "",
          is_default: leadFilterSavedViewModalData?.is_default || false,
        }}
        validationSchema={formSchema}
        onSubmit={async (values) => {
          await createOrUpdateAllLeadsSavedViewFilter({
            variables: {
              allLeadsSavedViewFilterInput: {
                id: leadFilterSavedViewModalData?.id,
                name: values.name,
                description: values.description,
                is_default: values.is_default,
                ...leadsFilterVariables.current,
              },
            },
          });
        }}
      >
        {({ submitForm, isSubmitting, isValid, setFieldValue, values }: FormikProps<FormikType>) => {
          return (
            <ModalContent>
              <ModalHeader>
                <HeaderTitle>{leadFilterSavedViewModalData?.id ? "Edit" : "Save"} View</HeaderTitle>
                {!leadFilterSavedViewModalData?.id ? <HeaderSubtitle>Save view to easily reuse.</HeaderSubtitle> : null}
              </ModalHeader>
              <ModalBody>
                <FlexDiv direction="column">
                  <PopupInputLabel>Name</PopupInputLabel>
                  <FieldInput name="name" />
                </FlexDiv>

                <FlexDiv direction="column">
                  <PopupInputLabel>Description</PopupInputLabel>
                  <FieldInput name="description" />
                </FlexDiv>

                <FlexDiv align="center">
                  <PhoenixCheckbox
                    checked={values?.is_default}
                    onChange={() => setFieldValue("is_default", !values?.is_default)}
                  />
                  <AppText>Set as default</AppText>
                </FlexDiv>
              </ModalBody>

              <ModalFooter>
                <PhoenixAppButton type="button" onClick={closeModal} buttonType="secondary" variant="danger-outline">
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton
                  type="submit"
                  onClick={submitForm}
                  buttonType="secondary"
                  variant="brand"
                  disabled={loading || !isValid || isSubmitting}
                >
                  Save
                </PhoenixAppButton>
              </ModalFooter>
            </ModalContent>
          );
        }}
      </Formik>
    </Modal>
  );
};

const ModalContent = styled.div`
  height: auto;
  max-height: 70vh;
  width: 456px;
`;

const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  gap: 4px;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 12px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 40px 40px 40px;
  position: relative;
  width: 100%;
  z-index: 1;
`;

const ModalFooter = styled.div`
  align-items: center;
  border-top: solid 1px ${theme.NEUTRAL200};
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding: 16px 40px;
  width: 100%;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const FieldInput = styled(InputField)`
  margin: 0;
  text-align: left;

  ::placeholder {
    text-align: left;
  }
`;

const HeaderTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 27px; */
`;

const HeaderSubtitle = styled(AppText)`
  color: ${theme.NEUTRAL300};
  font-size: 12px;
  font-weight: 400;
`;

export { AddOrEditLeadFilterSavedViewModal };
