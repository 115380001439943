import * as Sentry from "@sentry/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import WaveSurfer, { WaveSurferParams } from "wavesurfer.js";
import { FetchResult, MutationFunctionOptions, gql, useMutation } from "@apollo/client";
import { IoMdPause, IoMdPlay } from "react-icons/io";
import Switch from "react-switch";
import styled from "styled-components";
//@ts-ignore
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import { CoachingNotes } from ".";
import { loggedInUser } from "../../../apollo/cache";
import { ModalContext } from "../../../context";
import { iconDownload, iconSave } from "../../../images";
import { secondsToTime } from "../../../utils/format";
import { downloadResourceCors } from "../../../utils/misc";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { AppText, Loading } from "../../UI/";
import { MyPriorCallNotes } from "../DashboardSideBarSegments";

const SEND_REP_FEEDBACK = gql`
  mutation sendReviewCallReportToRep($conference_id: String!) {
    sendReviewCallReportToRep(conference_id: $conference_id)
  }
`;

interface Lead {
  id: string;
  business_name: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry_label: string;
  lead_source_label: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

export interface Region {
  id: string;
  start: number;
  end: number;
  data: {
    note: string;
  };
}

interface WaveFormProps {
  url: string;
  conference_id: string;
  regions: Region[];
  addRegion?: (regions: Region[]) => void;
  lead_data: Lead;
  refetch_call_report: () => void;
  deleteCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
}

const formWaveSurferOptions = (
  ref: null | any,
  scrollWaveform: boolean,
  widthWaveform: number,
  playbackSpeed: number,
) =>
  ({
    container: ref,
    waveColor: "#292929",
    progressColor: "black",
    cursorColor: "black",
    backgroundColor: "white",
    autoCenter: false,
    barWidth: 3,
    barRadius: 3,
    responsive: true,
    height: 140,
    scrollParent: scrollWaveform,
    minPxPerSec: widthWaveform,
    audioRate: playbackSpeed,
    fillParent: true,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    plugins: [RegionsPlugin.create({})],
  } as WaveSurferParams);

const Waveform: React.FunctionComponent<WaveFormProps> = ({
  url,
  regions,
  addRegion: addPropsRegion,
  lead_data,
  refetch_call_report,
  addCoachingNote,
  deleteCoachingNote,
  editCoachingNote,
  conference_id,
}) => {
  const waveformRef = useRef(null as any);
  const wavesurfer = useRef(null as any);
  const audio = document.createElement("audio");
  const [playerTime, setPlayerTime] = useState("00:00:00");
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  const [scrollWaveform, setScrollWaveform] = useState(false);
  const [widthWaveform, setWidthWaveform] = useState(50);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [audioURL, setAudioURL] = useState("");

  const [sendReviewCallReportToRep, { loading: repLoading, error: repError }] = useMutation(SEND_REP_FEEDBACK, {
    onCompleted({ sendReviewCallReportToRep }) {
      console.log("sendReviewCallReportToRep: ", sendReviewCallReportToRep);
      //window.location.reload(false);
      appToast("Feedback sent!");
    },
    onError({ message }) {
      console.log(message);
      appToast("Feedback sending feedback!");
      Sentry.captureEvent({
        message: `sendReviewCallReportToRep GraphQL Error: ${message}`,
      });
    },
  });

  const { setSaveCallToLibraryModal, currentConferenceID, setCurrentConferenceID } = useContext(ModalContext);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    const options = formWaveSurferOptions(waveformRef.current, scrollWaveform, widthWaveform, playbackSpeed);
    wavesurfer.current = WaveSurfer.create(options);
    audio.src = url;
    wavesurfer.current.load(url);

    setAudioURL(url);

    wavesurfer.current.on("ready", () => {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);

      // make sure object still available when file loaded
      setIsLoadingAudio(false);
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });
    wavesurfer.current.on("ready", () => {
      regions &&
        regions.forEach((region) => {
          wavesurfer.current.addRegion({
            ...region,
            color: "rgba(255,179,56,0.6)",
          });
        });

      if (loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") {
        wavesurfer.current.enableDragSelection({
          color: "rgba(255,179,56,0.6)",
          attributes: {
            wavesurferShouldCreate: true,
          },
          data: {
            note: "",
          },
        });
      }
      var timeline = Object.create(wavesurfer.current.Timeline);
      timeline.init({
        wavesurfer: waveformRef.current,
        container: "#wave-timeline",
      });
    });
    console.log("wavesurfer", wavesurfer.current);

    const shouldAddNewRegion = (regionId: string, regionList: string[]) => {
      return !regions.some((region) => region.id === regionId);
    };

    // wavesurfer.current.on("scroll", (e: any) => {
    //   console.log("scroll", e);
    // });

    wavesurfer.current.on("dblclick", () => {
      console.log("double clicked");
    });

    wavesurfer.current.on("play", () => {
      setPlay(true);
    });
    wavesurfer.current.on("pause", () => {
      setPlay(false);
    });

    //annotation logic ** in progress **
    wavesurfer.current.on("seek", (float: any) => {
      //auto plays on seek, can revert this pending client
      wavesurfer.current.play();
      // setPlay(true);
    });
    wavesurfer.current.on("region-click", (region: any, e: any) => {
      e.stopPropagation();
      // Play on click, loop on shift click
      //set region here to whatever state
      console.log("region click", region);
      //can set region selected here
      console.log("event", e);
      e.shiftKey ? region.playLoop() : region.play();
    });

    wavesurfer.current.on("region-updated", (region: any) => {
      // console.log("region update", region);
      saveRegions();
    });

    wavesurfer.current.on("region-update-end", async (region: any) => {
      if (region.attributes.wavesurferShouldCreate) {
        await addCoachingNote({
          variables: {
            note_id: region.id,
            conference_id: conference_id,
            text: "",
            start: Math.floor(region.start),
            end: Math.floor(region.end),
          },
        });
        refetch_call_report();
        saveRegions();
        region.update({
          attributes: {
            wavesurferShouldCreate: false,
          },
        });
      } else {
        await editCoachingNote({
          variables: {
            noteItemId: region.id,
            text: region.data.note,
            start: Math.floor(region.start),
            end: Math.floor(region.end),
          },
        });
        refetch_call_report();
        saveRegions();
      }
    });

    wavesurfer.current.on("region-removed", (region: any) => {
      console.log("region removed", region);
      saveRegions();
    });

    wavesurfer.current.on("audioprocess", () => {
      if (wavesurfer.current.isPlaying()) {
        const currentTime = wavesurfer.current.getCurrentTime();
        setPlayerTime(secondsToTime(currentTime));
      }
    });

    wavesurfer.current.on("region-in", () => {
      console.log("region in");
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const saveRegions = () => {
    //save region/annotation state locally to waveform state
    const newRegions = Object.keys(wavesurfer.current.regions.list)?.map((id) => {
      const region = wavesurfer.current.regions.list[id];
      return {
        id: region.id,
        start: region.start,
        end: region.end,
        color: region.color,
        attributes: region.attributes,
        data: region.data,
      };
    });

    console.log("New Regions updated", newRegions);
  };

  const playRegion = (regionId: string) => {
    const region = wavesurfer.current.regions.list[regionId];
    console.log("play", region);
    region.play();
  };

  const addRegion = async (text?: string) => {
    const results = await addCoachingNote({
      variables: {
        conference_id: conference_id,
        text: !!text ? text : "",
        start: Math.max(Math.floor(wavesurfer.current.getCurrentTime()), 0),
        end: Math.floor(wavesurfer.current.getCurrentTime() + 1),
      },
    });
    console.log("results", results);
    wavesurfer.current.addRegion({
      id: results.data.addCoachingNote.id,
      start: Math.max(Math.floor(wavesurfer.current.getCurrentTime()), 0),
      end: Math.floor(wavesurfer.current.getCurrentTime() + 1),
      color: "rgba(255,179,56,0.6)",
      attributes: {},
      data: {
        note: results.data.addCoachingNote.text ?? "",
      },
    });
    refetch_call_report();
    appToast("New note added!");
  };

  const removeRegion = async (regionId: string) => {
    await deleteCoachingNote({
      variables: {
        noteItemId: regionId,
      },
    });
    const region = wavesurfer.current.regions.list[regionId];
    region.remove();
    refetch_call_report();
    appToast("Note deleted!");
  };

  const updateNotes = async (regionId: string, note: string) => {
    await editCoachingNote({
      variables: {
        noteItemId: regionId,
        text: note,
      },
    });
    refetch_call_report();

    const region = wavesurfer.current.regions.list[regionId];

    region.update({
      data: {
        note: note,
      },
    });
    appToast("Note updated!");
  };

  const onVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <>
      <WaveFormContainer>
        <FlexBetweenDiv>
          {/* Scroll width slider: */}
          <FlexDiv>
            <LabelText>Zoomed in view</LabelText>
            <Switch
              onChange={(checked) => {
                setScrollWaveform(checked);
                wavesurfer.current.toggleScroll();
              }}
              onColor={theme.PRIMARY500}
              checked={scrollWaveform}
              height={18}
              width={44}
              checkedIcon={<SwitchText>ON</SwitchText>}
              uncheckedIcon={<SwitchText>OFF</SwitchText>}
            />

            {scrollWaveform && (
              <HorizontalDiv style={{ marginLeft: "20px" }}>
                <LabelText>Zoom level</LabelText>
                <SwitchLabel>Min</SwitchLabel>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={widthWaveform}
                  onChange={(e: any) => {
                    setWidthWaveform(e.target.value);
                    wavesurfer.current.zoom(e.target.value);
                  }}
                />
                <SwitchLabel>Max</SwitchLabel>
              </HorizontalDiv>
            )}
          </FlexDiv>

          <FlexDiv>
            <SpeedDiv>
              <SpeedText
                selected={playbackSpeed === 1}
                onClick={() => {
                  setPlaybackSpeed(1);
                  wavesurfer.current.setPlaybackRate(1);
                }}
              >
                1x
              </SpeedText>
              <SpeedText
                selected={playbackSpeed === 1.25}
                onClick={() => {
                  setPlaybackSpeed(1.25);
                  wavesurfer.current.setPlaybackRate(1.25);
                }}
              >
                1.25x
              </SpeedText>
              <SpeedText
                selected={playbackSpeed === 1.5}
                onClick={() => {
                  setPlaybackSpeed(1.5);
                  wavesurfer.current.setPlaybackRate(1.5);
                }}
              >
                1.5x
              </SpeedText>
              <SpeedText
                selected={playbackSpeed === 1.75}
                onClick={() => {
                  setPlaybackSpeed(1.75);
                  wavesurfer.current.setPlaybackRate(1.75);
                }}
              >
                1.75x
              </SpeedText>
              <SpeedText
                selected={playbackSpeed === 2}
                onClick={() => {
                  setPlaybackSpeed(2);
                  wavesurfer.current.setPlaybackRate(2);
                }}
              >
                2x
              </SpeedText>
            </SpeedDiv>
            {!!audioURL && (
              <div
                style={{ marginRight: "4px", cursor: "pointer" }}
                onClick={() => {
                  setCurrentConferenceID(conference_id);
                  setSaveCallToLibraryModal(true);
                }}
              >
                <img src={iconSave} alt="Save" data-tip="Save" />
              </div>
            )}
            {!!audioURL && (
              <div style={{ marginRight: "0px" }}>
                {/* <a href={audioURL} download target="_blank"> */}
                <img
                  style={{ cursor: "pointer" }}
                  src={iconDownload}
                  alt="Download"
                  data-tip="Download"
                  onClick={() => downloadResourceCors(audioURL)}
                />
                {/* </a> */}
              </div>
            )}
          </FlexDiv>
        </FlexBetweenDiv>
        <PlayerContainer>
          <WaveFormPlayerButton onClick={handlePlayPause}>
            <WaveFormPlayerButtonIconContainer>
              {!playing ? (
                <IoMdPlay size={80} color={theme.WHITE_COLOR} />
              ) : (
                <IoMdPause size={80} color={theme.WHITE_COLOR} />
              )}
              <WaveFormDurationTextContainer>
                <WaveFormText>{playerTime}</WaveFormText>
              </WaveFormDurationTextContainer>
            </WaveFormPlayerButtonIconContainer>
          </WaveFormPlayerButton>
          <WaveFormContainerPlayer>
            {isLoadingAudio && <Loading />}
            <WaveFormPlayer ref={waveformRef} />
          </WaveFormContainerPlayer>
        </PlayerContainer>

        <NotesContainer>
          <MyPriorCallNotes
            isInCallReport
            callNotes={lead_data.call_notes}
            color={"#D8D8D8"}
            textcolor={theme.BLACK_COLOR}
          />
          <CoachingNotes
            coachingNotes={regions ? regions : []}
            playRegion={playRegion}
            addRegion={addRegion}
            updateNotes={updateNotes}
            removeRegion={removeRegion}
            repLoading={repLoading}
            sendFeedbackFunction={() => sendReviewCallReportToRep({ variables: { conference_id } })}
          />
        </NotesContainer>
      </WaveFormContainer>
    </>
  );
};

export { Waveform };

interface SelectedProps {
  selected: boolean;
}

const SpeedText = styled(AppText)<SelectedProps>`
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : "transparent")};
  color: ${(props) => (props.selected ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  border-radius: 4px;
  border-bottom: none;
  width: 35px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: ${(props) => (props.selected ? 600 : "normal")};
`;

const SpeedDiv = styled.div`
  display: flex;
  margin-right: 12px;
  padding: 4px;
  border-radius: 4px;
  background-color: ${theme.WHITE_COLOR};
`;

const FlexDiv = styled.div`
  display: flex;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
`;

const SwitchText = styled(AppText)`
  font-weight: normal;
  font-size: 10px;
  height: 18px;
  line-height: 10px;
  vertical-align: bottom;
  /* padding-top: 3px; */
  /* vertical-align: bottom; */
  line-height: 18px;
  margin: auto auto;
  color: ${theme.WHITE_COLOR};
`;

const HorizontalDiv = styled.div`
  max-height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const FlexBetweenDiv = styled.div`
  max-height: 45px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface SwitchLabelProps {
  selected?: boolean;
  option?: boolean;
}

const LabelText = styled(AppText)`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-right: 8px;
`;

const SwitchLabel = styled(AppText)<SwitchLabelProps>`
  font-size: 10.5px;
  font-weight: ${(props) => (props.selected && props.option ? 600 : !props.selected && !props.option ? 600 : "normal")};
  margin: 5px;
`;

const WaveFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WaveFormContainerPlayer = styled.div`
  height: 140px;
  width: 850px;
  /* max-width: 850px;
  min-width: 850px; */
`;

const WaveFormPlayer = styled.div`
  height: 100%;
  width: 100%;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const WaveFormPlayerButton = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  background-color: ${theme.BLACK_COLOR};
`;

const WaveFormPlayerButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WaveFormDurationTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15px;
`;

const WaveFormText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  color: ${theme.WHITE_COLOR};
`;

const NotesContainer = styled.div`
  margin-top: 13px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
