import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { trash } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { AppText, Loading, NewAppButton } from "../../UI";
import { PhoenixIcon } from "../../UI/Phoenix";
import { CreateCampaign, UpdateSMSDialog } from "../../modal/";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const defaultModalCampaign = {
  id: "",
  name: "",
  event: "",
  when: "",
  increment_multiplier: 0,
  increment: 1,
  method_email: false,
  method_sms: false,
  visible: false,
};

const defaultSMSDialog = {
  sms_opt_in_dialog: "",
  visible: false,
};

const GET_ALL_CAMPAIGNS = gql`
  query fetchAutomatedCampaigns {
    fetchAutomatedCampaigns {
      id
      name
      body
      subject
      event
      when
      increment
      increment_multiplier
      method_email
      method_sms
      template_id
    }
    fetchOrganization {
      id
      sms_opt_in_dialog
    }
  }
`;

const DELETE_ONE_CAMPAIGN = gql`
  mutation DeleteOneAutomatedCampaign($automatedCampaignId: String!) {
    deleteOneAutomatedCampaign(automated_campaign_id: $automatedCampaignId) {
      id
      name
      body
    }
  }
`;

const StepFollowup: React.FC<DisappearingDivProps> = ({ step, isOnboarding }) => {
  const [modalCampaign, setModalCampaign] = useState(defaultModalCampaign);
  const [modalSMS, setModalSMS] = useState(defaultSMSDialog);

  const [deleteOneAutomatedCampaign] = useMutation(DELETE_ONE_CAMPAIGN, {
    onCompleted({ deleteOneAutomatedCampaign }) {
      console.log("deleteOneAutomatedCampaign: ", deleteOneAutomatedCampaign);
      if (!deleteOneAutomatedCampaign) {
        return;
      }
      appToast("Automated Campaign deleted");
    },
    onError({ message }) {
      console.log("Error in deleteOneAutomatedCampaign: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneAutomatedCampaign GraphQL Error: ${message}`,
      });
    },
  });

  const { data, loading, error, refetch } = useQuery(GET_ALL_CAMPAIGNS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const history = useHistory();

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Follow Up page of Admin Onboarding"}>
      <DisappearingDiv>
        {modalCampaign.visible && (
          <CreateCampaign
            close={() => {
              setModalCampaign({ ...defaultModalCampaign, visible: false });
              refetch();
            }}
            {...modalCampaign}
          />
        )}
        {modalSMS.visible && (
          <UpdateSMSDialog close={() => setModalSMS({ ...defaultSMSDialog, visible: false })} {...modalSMS} />
        )}
        <CenterDiv>
          <NewAppButton
            width={220}
            height={35}
            variant="primary"
            hoverVariant="primary"
            onClick={() => setModalCampaign({ ...defaultModalCampaign, visible: true })}
          >
            Add New Automated Campaign
          </NewAppButton>
        </CenterDiv>
        {loading ? (
          <Loading />
        ) : data.fetchAutomatedCampaigns && data.fetchAutomatedCampaigns.length === 0 ? (
          <TopAlignDiv></TopAlignDiv>
        ) : (
          <div>
            <TopAlignDiv>
              <CampaignDiv>
                <AppText> </AppText>
                <CampaignTable style={{ borderBottom: `solid 1px ${theme.NEUTRAL500}`, alignItems: "flex-end" }}>
                  <TableHeadingText>Automated Messaging</TableHeadingText>
                  <TableHeadingText>Trigger Event</TableHeadingText>
                  <TableHeadingText>When</TableHeadingText>
                  <TableHeadingText>Increment</TableHeadingText>
                  <TableHeadingText>Email</TableHeadingText>
                  <TableHeadingText>SMS</TableHeadingText>
                </CampaignTable>
                <AppText> </AppText>
              </CampaignDiv>
              {data.fetchAutomatedCampaigns?.map((item: any, index: number) => (
                <CampaignDiv key={item.id}>
                  <TableHeadingText style={{ marginBottom: 0 }}>{index + 1}</TableHeadingText>
                  <CampaignTable>
                    <TableSubheadingText>{item.name}</TableSubheadingText>
                    <TableText>{item.event}</TableText>
                    <TableText>{item.when}</TableText>
                    <TableText>
                      {item.increment}{" "}
                      {item.increment_multiplier === 1 ? "minute" : item.increment_multiplier === 60 ? "hour" : "day"}
                      {item.increment > 1 && "s"}
                    </TableText>
                    <TickText>{item.method_email ? "✓" : ""}</TickText>
                    <TickText>{item.method_sms ? "✓" : ""}</TickText>
                  </CampaignTable>
                  <ButtonsRow>
                    <NewAppButton
                      style={{
                        border: `solid 2px ${theme.NEUTRAL100}`,
                        marginLeft: "10.5px",
                        marginRight: "18px",
                      }}
                      height={28}
                      width={124}
                      variant="secondary"
                      hoverVariant="secondary"
                      onClick={() => setModalCampaign({ ...item, visible: true })}
                    >
                      Edit
                    </NewAppButton>

                    <PhoenixIcon
                      style={{ cursor: "pointer", lineHeight: 1 }}
                      size={24}
                      color={theme.NEUTRAL500}
                      hoverColor={theme.NEUTRAL500}
                      onClick={async () => {
                        await deleteOneAutomatedCampaign({
                          variables: {
                            automatedCampaignId: item.id,
                          },
                        });
                        refetch();
                      }}
                      svg={trash}
                    />
                  </ButtonsRow>
                </CampaignDiv>
              ))}
            </TopAlignDiv>
          </div>
        )}

        {loading ? (
          ""
        ) : data.fetchAutomatedCampaigns && data.fetchAutomatedCampaigns.length === 0 ? (
          <CenterDiv>
            <NewAppButton
              style={{
                fontSize: "15px",
              }}
              width={221}
              height={47}
              variant="secondary"
              hoverVariant="secondary"
              onClick={() => {
                if (isOnboarding) {
                  history.push(`/onboarding?step=${step + 1}`);
                } else {
                  appToast("Updated!");
                }
              }}
            >
              Skip This Step
            </NewAppButton>
          </CenterDiv>
        ) : (
          <>
            {/* {data.fetchOrganization && (
              <CenterDiv>
                <NewAppButton
                  style={{
                    margin: "21px",
                  }}
                  width={200}
                  height={40}
                  variant="secondary"
                  hoverVariant="secondary"
                  onClick={() => setModalSMS({ ...data.fetchOrganization, visible: true })}
                >
                  Edit SMS Opt-In Dialogue
                </NewAppButton>
              </CenterDiv>
            )} */}
            <CenterDiv>
              <NewAppButton
                style={{
                  fontSize: "15px",
                }}
                width={221}
                height={47}
                variant="confirm"
                hoverVariant="confirm"
                onClick={() => {
                  if (isOnboarding) {
                    history.push(`/onboarding?step=${step + 1}`);
                  } else {
                    appToast("Updated!");
                  }
                }}
              >
                {isOnboarding === true ? "Continue" : "Save Changes"}
              </NewAppButton>
            </CenterDiv>
          </>
        )}
      </DisappearingDiv>
    </Sentry.ErrorBoundary>
  );
};

const TickText = styled(AppText)`
  font-size: 20px;
  color: ${theme.SUCCESS500};
`;

const TableText = styled(AppText)`
  font-size: 13px;
`;

const TableSubheadingText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonsRow = styled.div`
  display: flex;
`;

const TableHeadingText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8.5px;
`;

const CampaignTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 267px 109px 138px 57px 57px;
  align-items: center;
`;

const CampaignDiv = styled.div`
  display: grid;
  height: 50px;
  grid-template-columns: 30px 1fr 130px;
  align-items: center;
`;

const DisappearingDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopAlignDiv = styled.div`
  min-height: 450px;
  max-height: 475px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export { StepFollowup };
