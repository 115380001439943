import { gql, useQuery } from "@apollo/client";

const FETCH_LEADS_SAVED_VIEWS = gql`
  query fetchAllLeadsSavedViewFilters {
    fetchAllLeadsSavedViewFilters {
      id
      name
      search_text
      lead_filter
      skip
      take
      order_by
      ascending
      my_leads
      lead_system_operators
      description
      is_favorite
      is_default
    }
  }
`;

const useFetchLeadsSavedViews = (params?: { onCompleted?: (data: any) => void }) => {
  const { data, loading } = useQuery(FETCH_LEADS_SAVED_VIEWS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      params?.onCompleted?.(data?.fetchAllLeadsSavedViewFilters ?? []);
    },
  });

  return { data: data?.fetchAllLeadsSavedViewFilters ?? [], loading };
};

export default useFetchLeadsSavedViews;
