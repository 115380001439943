import { gql, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { ModalContext } from "../../context";
import { MixpanelActions } from "../../services/mixpanel";
import { SystemConfigNavShape } from "../../types";
import { theme } from "../../utils/theme";
import { STEP_SYSVIEW_PERSIST_KEY } from "../../utils/variables";
import {
  BulkReassignComponent,
  LeadFunnelComponent,
  LeadPoolsComponent,
  LeadSearchPage,
} from "../Segments/SystemView/";
import { AppText } from "../UI";
import { SaveToFolderModal } from "../modal";
import { LeadSearchFiltersModal } from "../Segments/SystemView/LeadSearchFIltersModal";

const ORGANIZATION_DATA = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_system_view
      show_rep_my_leads
      show_rep_all_leads
      reps_can_bulk_unassign
    }
  }
`;
interface ParamTypes {
  tab_name?: string;
  tab_type_id?: string;
}

const SystemView: React.FC = () => {
  const [step, setStep] = useState(1);

  const {
    setShowTabNav,
    showTabNav,
    tabNavData,
    setTabNavData,
    saveCallToLibraryModal,
    setSaveCallToLibraryModal,
    currentConferenceID,
    setCurrentConferenceID,
    setCurrentTab,
    currentTab,
    showFiltersModal,
  } = useContext(ModalContext);
  const { tab_name } = useParams<ParamTypes>();

  const { showLeadPool, showNewLeadSearch } = useFlags();

  const history = useHistory();

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    setShowTabNav(true);

    return () => {
      setShowTabNav(false);

      if (currentTab) {
        setCurrentTab(undefined);
      }
    };
  }, []);

  useEffect(() => {
    setCurrentTab(tab_name);
  }, [tab_name]);

  useEffect(() => {
    if (!dataOrg) {
      return;
    }
    if (loggedInUser().role === "ADMIN") {
      if (!!showLeadPool) {
        setTabNavData([
          {
            tab_name: "lead-pool",
            title: "Lead Pool",
            // system view doesn't currnetly use description in a tooltip like system config
            description: "N/A",
            drawer_name: "Lead Pool",
            items: [],
            path: "system-view/lead-pool",
          },
          {
            tab_name: "lead-funnel",
            title: "Lead Funnel",
            description: "N/A",
            drawer_name: "Lead Funnel",
            items: [],
            path: "system-view/lead-funnel",
          },
          {
            tab_name: "all-leads",
            title: "All Leads",
            description: "N/A",
            drawer_name: "All Leads",
            items: [],
            path: "system-view/all-leads",
          },
        ]);
      } else {
        setTabNavData([
          {
            tab_name: "lead-funnel",
            title: "Lead Funnel",
            description: "N/A",
            path: "system-view/lead-funnel",
            drawer_name: "Lead Funnel",
            items: [],
          },
          {
            tab_name: "all-leads",
            title: "All Leads",
            description: "N/A",
            path: "system-view/all-leads",
            drawer_name: "All Leads",
            items: [],
          },
        ]);
      }
      if (!tab_name) {
        history.replace(!!showLeadPool ? "lead-pool" : "lead-funnel");
      }
    } else {
      const newTabNavData = [] as SystemConfigNavShape[];
      if (dataOrg?.fetchOrganization?.show_rep_all_leads || loggedInUser().role === "SM") {
        newTabNavData.push({
          tab_name: "all-leads",
          title: "All Leads",
          description: "N/A",
          path: "system-view/all-leads",
          drawer_name: "All Leads",
          items: [],
        });
      }
      if (dataOrg?.fetchOrganization?.show_rep_my_leads && !loggedInUser().visible_all_leads_sm) {
        newTabNavData.push({
          tab_name: "my-leads",
          title: "My Leads",
          description: "N/A",
          path: "system-view/my-leads",
          drawer_name: "My Leads",
          items: [],
        });
      }
      setTabNavData(newTabNavData);
      if (!tab_name) {
        history.replace(`${newTabNavData[0]?.tab_name}`);
      }
    }

    if (loggedInUser().role === "AE" || loggedInUser().role === "SDR") {
      if (dataOrg?.fetchOrganization?.show_rep_all_leads) {
        MixpanelActions.track("System View", { type: "tab impression", value: "All Leads" });
        setStep(parseInt(window?.localStorage?.getItem(STEP_SYSVIEW_PERSIST_KEY) ?? "3"));
      } else if (dataOrg?.fetchOrganization?.show_rep_my_leads) {
        MixpanelActions.track("System View", { type: "tab impression", value: "My Leads" });
        setStep(parseInt(window?.localStorage?.getItem(STEP_SYSVIEW_PERSIST_KEY) ?? "4"));
      }
    } else {
      MixpanelActions.track("System View", { type: "tab impression", value: "Lead Pool" });
      setStep(parseInt(window?.localStorage?.getItem(STEP_SYSVIEW_PERSIST_KEY) ?? "1"));
    }
  }, [dataOrg?.fetchOrganization]);

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
      {showNewLeadSearch && showFiltersModal && <LeadSearchFiltersModal />}
      <OnboardingColorWrapper>
        <ScrollWrapper>
          {saveCallToLibraryModal && (
            <SaveToFolderModal
              blinds={saveCallToLibraryModal}
              setBlinds={setSaveCallToLibraryModal}
              conferenceID={currentConferenceID}
            />
          )}
          <Main>
            <SimpleFlexDiv>
              <StepLocationText>
                {!!tabNavData?.length &&
                  tabNavData[tabNavData.findIndex((step: any) => step.tab_name == tab_name)]?.title}
              </StepLocationText>
              {/* <QuestionMarkMoreInfo data-tip={stepDescription[step - 1].description}>?</QuestionMarkMoreInfo> */}
              <div />
            </SimpleFlexDiv>
            <ContentContainer>
              {tab_name === "lead-pool" && !!showLeadPool && <LeadPoolsComponent step={step} />}
              {tab_name === "lead-pool" && !showLeadPool && <>Under maintenance</>}
              {tab_name === "lead-funnel" && <LeadFunnelComponent step={step} />}
              {((loggedInUser().role === "AE" && dataOrg?.fetchOrganization?.show_rep_all_leads) ||
                (loggedInUser().role === "SDR" && dataOrg?.fetchOrganization?.show_rep_all_leads) ||
                (loggedInUser().visible_all_leads_sm && loggedInUser().role === "SM") ||
                loggedInUser().role === "ADMIN") &&
                tab_name === "all-leads" &&
                (showNewLeadSearch ? (
                  <LeadSearchPage
                    my_leads={false}
                    display_bulk_unassign={
                      loggedInUser().role === "ADMIN" ||
                      loggedInUser().role === "SM" ||
                      !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                    }
                  />
                ) : (
                  <BulkReassignComponent
                    my_leads={false}
                    display_bulk_unassign={
                      loggedInUser().role === "ADMIN" ||
                      loggedInUser().role === "SM" ||
                      !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                    }
                  />
                ))}
              {((loggedInUser().role === "AE" && dataOrg?.fetchOrganization?.show_rep_my_leads) ||
                (loggedInUser().role === "SDR" && dataOrg?.fetchOrganization?.show_rep_my_leads) ||
                loggedInUser().role === "ADMIN") &&
                tab_name === "my-leads" &&
                (showNewLeadSearch ? (
                  <LeadSearchPage
                    my_leads={true}
                    display_bulk_unassign={
                      loggedInUser().role === "ADMIN" ||
                      loggedInUser().role === "SM" ||
                      !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                    }
                  />
                ) : (
                  <BulkReassignComponent
                    my_leads={true}
                    display_bulk_unassign={
                      loggedInUser().role === "ADMIN" ||
                      loggedInUser().role === "SM" ||
                      !!dataOrg?.fetchOrganization?.reps_can_bulk_unassign
                    }
                  />
                ))}
            </ContentContainer>
          </Main>
        </ScrollWrapper>
      </OnboardingColorWrapper>
    </Sentry.ErrorBoundary>
  );
};

const OnboardingColorWrapper = styled.div`
  background-color: ${theme.surface.neutral.tertiary};
  position: relative;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const ScrollWrapper = styled.div`
  min-height: 100vh;
  padding: 24px;
`;

const StepLocationText = styled(AppText)`
  font-weight: 600;
  font-size: 22px;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

interface IFontSize {
  fontSize?: number;
  fontWeight?: number;
  marginBottom?: number;
  marginTop?: number;
}

interface ProgressProps {
  step: number;
  circle: number;
  width?: number;
}

const ContentContainer = styled.div`
  width: auto;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: inherit;
`;

const Main = styled.div<ModalOnProps>`
  height: 100%;
  background-color: ${theme.WHITE_COLOR};
  padding: 24px 40px;
  border-radius: 8px;
`;

export { SystemView };
