import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppBackButton, AppText, Loading, NewAppButton } from "../../../UI";
import { LeadCustomEmptyPage } from "./LeadCustomFieldEmptyTable";
import { UpdateCustomObject } from "../../../modal/UpdateCustomObject";

import { PhoenixAppButton, PhoenixEyeToggle, PhoenixIcon } from "../../../UI/Phoenix";
import { edit, trash } from "../../../../images/NewDesign";
import Switch from "react-switch";
import { AgGridReact } from "ag-grid-react";
import { appToast } from "../../../../utils/toast";
import { DeleteFieldV2, UpdateCustomFieldV2 } from "../../../modal";
import "./ObjectManager.css";
import { getUpIconHTML, getDownIconHTML } from "../../../../utils/misc";
import { RowDragEndEvent } from "ag-grid-community";
import { MixpanelActions } from "src/services/mixpanel";

interface ParamProps {
  tab_type_id: string;
}
interface DisappearingDivProps {
  togglePageTitle?: () => void;
}

const FETCH_ONE_CUSTOM_OBJECT = gql`
  query fetchOneCustomObject($id: String!, $field_is_primary_key: Boolean!) {
    fetchOneCustomObject(custom_object_id: $id, field_is_primary_key: $field_is_primary_key) {
      id
      name
      mappable_lead_keys

      CustomObjectField {
        id
        name
        custom_object_id
        type
        list_option
        visible
        show_on_lead_preview
        allow_reps_to_edit
        is_name_field
        CustomObjectFieldForeignKey {
          id
          lead_system_field_key
        }
      }
    }
  }
`;

const DELETE_ONE_CUSTOM_OBJECT = gql`
  mutation deleteOneCustomObject($id: String!) {
    deleteOneCustomObject(custom_object_id: $id) {
      id
      name
    }
  }
`;

const DELETE_ONE_CUSTOM_OBJECT_FIELD = gql`
  mutation deleteOneCustomObjectField($id: String!) {
    deleteOneCustomObjectField(custom_object_field_id: $id) {
      id
      name
    }
  }
`;

const UPDATE_ONE_CUSTOM_OBJECT_FIELD = gql`
  mutation updateOneCustomObjectField($id: String!, $data: CustomObjectDataInput!) {
    updateOneCustomObjectField(custom_object_field_id: $id, data: $data) {
      id
      custom_object_id
      name
      type
      list_option
    }
  }
`;

const UPDATE_CUSTOM_OBJECT_FIELDS_ORDER = gql`
  mutation updateCustomObjectFieldsOrder($id: String!, $items: [String!]!) {
    updateCustomObjectFieldsOrder(custom_object_id: $id, custom_object_field_ids: $items)
  }
`;

interface CustomObjectField {
  id: string;
  name: string;
  custom_object_id: string;
  type: string;
  list_option: string[];
  visible: boolean;
  show_on_lead_preview: boolean;
  allow_reps_to_edit: boolean;
  is_name_field: boolean;
  CustomObjectFieldForeignKey?: {
    id: string;
    lead_system_field_key: string;
  };
}

interface FetchOneCustomObject {
  id: string;
  name: string;
  mappable_lead_keys: string[];
  CustomObjectField: CustomObjectField[];
}

const CustomObjectTable: React.FC<DisappearingDivProps> = ({ togglePageTitle }) => {
  const gridRef = useRef<AgGridReact>(null);

  const defaultField = {
    id: "",
    name: "",
    type: "",
    value: "",
    list_option: [],
    visible: true,
    show_on_lead_preview: false,
    allow_reps_to_edit: false,
  };
  const [blinds, setBlinds] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.NONE);

  const [rowData, setRowData] = useState<CustomObjectField[]>([]);
  const [customObject, setCustomObject] = useState<{
    id: string;
    name: string;
  }>({ id: "", name: "" });
  const [modalField, setModalField] = useState(defaultField);
  const [mappableLeadKeys, setMappableLeadKeys] = useState([]);
  const { tab_type_id: custom_object_id } = useParams<ParamProps>();
  const history = useHistory();

  const { data: data, loading: loading, error: error } = useQuery(FETCH_ONE_CUSTOM_OBJECT, {
    variables: {
      id: custom_object_id,
      field_is_primary_key: false,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted: ({ fetchOneCustomObject }) => {
      setMappableLeadKeys(fetchOneCustomObject?.mappable_lead_keys);
    },
  });

  useEffect(() => {
    if (data?.fetchOneCustomObject?.CustomObjectField) {
      const rowData: CustomObjectField[] = data.fetchOneCustomObject.CustomObjectField || [];
      setRowData(rowData);
    }
    if (data?.fetchOneCustomObject) {
      const customObject: FetchOneCustomObject = data.fetchOneCustomObject;
      setCustomObject({ id: customObject.id, name: customObject.name });
    }
  }, [data]);

  const [deleteOneCustomObject, { loading: loadingDelete, error: errorDelete }] = useMutation(
    DELETE_ONE_CUSTOM_OBJECT,
    {
      onCompleted() {
        appToast("Custom Object Deleted");
        MixpanelActions.track("Custom Object Deleted", {
          object_name: `${customObject.name}`,
        });
        history.push("/system-config/object-manager?object=CustomObject");
      },
      onError({ message }) {
        appToast(message);
        console.log("Error in deleteOneCustomObject: ", message);
      },
      refetchQueries: ["fetchOneCustomObject"],
    },
  );

  const [deleteOneCustomObjectField, { loading: loadingDeleteField, error: errorDeleteField }] = useMutation(
    DELETE_ONE_CUSTOM_OBJECT_FIELD,
    {
      onCompleted() {
        appToast("Custom Field Deleted");
        MixpanelActions.track("Custom Field Deleted", {
          parent_object_name: customObject.name,
          field_name: modalField.name,
        });
      },
      onError({ message }) {
        appToast(message);
        console.log("Error in deleteOneCustomObjectField: ", message);
      },
      refetchQueries: ["fetchOneCustomObject"],
    },
  );

  const [updateCustomObjectField] = useMutation(UPDATE_ONE_CUSTOM_OBJECT_FIELD, {
    onCompleted() {
      appToast("Custom Field Updated");
    },
    refetchQueries: ["fetchOneCustomObject"],
  });

  const [updateCustomObjectFieldsOrder] = useMutation(UPDATE_CUSTOM_OBJECT_FIELDS_ORDER, {
    onCompleted() {
      appToast("Custom Fields Order Updated");
    },
    refetchQueries: ["fetchOneCustomObject"],
  });

  const ManageCellRenderer = useCallback((params: any) => {
    const is_name_field = params?.data?.is_name_field;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "40px",
          gap: "8px",
        }}
      >
        <PhoenixIcon
          svg={edit}
          size={16}
          onClick={() => {
            handleEdit(params);
          }}
        />
        {!is_name_field && (
          <PhoenixIcon
            svg={trash}
            variant="danger"
            size={16}
            onClick={() => {
              handleDelete(params);
            }}
          />
        )}
      </div>
    );
  }, []);

  const SwitchRendererEdit = useCallback(
    (props: any) => {
      const {
        name,
        custom_object_id,
        type,
        list_option,
        visible,
        show_on_lead_preview,
        allow_reps_to_edit,
        CustomObjectFieldForeignKey,
      } = props.data;

      console.log("CustomObjectFieldForeignKey", CustomObjectFieldForeignKey);
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "40px",
            gap: "8px",
          }}
        >
          <Switch
            checked={allow_reps_to_edit}
            onChange={(check) => {
              updateCustomObjectField({
                variables: {
                  id: props.data.id,
                  data: {
                    name,
                    custom_object_id,
                    type,
                    list_option,
                    visible,
                    allow_reps_to_edit: check,
                    show_on_lead_preview,
                    lead_system_field_key: CustomObjectFieldForeignKey?.lead_system_field_key ?? "",
                  },
                },
              });
            }}
            onColor={theme.PRIMARY500}
            height={16}
            width={32}
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={12}
          />
        </div>
      );
    },
    [updateCustomObjectField],
  );

  const SwitchRendererPreview = useCallback(
    (props: any) => {
      const {
        name,
        custom_object_id,
        type,
        list_option,
        visible,
        show_on_lead_preview,
        allow_reps_to_edit,
        is_name_field,
        CustomObjectFieldForeignKey,
      } = props.data;

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "40px",
            gap: "8px",
          }}
        >
          <Switch
            disabled={!visible || is_name_field}
            checked={show_on_lead_preview}
            onChange={(check) => {
              updateCustomObjectField({
                variables: {
                  id: props.data.id,
                  data: {
                    name,
                    custom_object_id,
                    type,
                    list_option,
                    visible,
                    allow_reps_to_edit,
                    show_on_lead_preview: check,
                    lead_system_field_key: CustomObjectFieldForeignKey?.lead_system_field_key ?? "",
                  },
                },
              });
            }}
            onColor={theme.PRIMARY500}
            height={16}
            width={32}
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={12}
          />
        </div>
      );
    },
    [updateCustomObjectField],
  );

  const EyeRenderer = useCallback(
    (props: any) => {
      const {
        name,
        custom_object_id,
        type,
        list_option,
        allow_reps_to_edit,
        visible,
        show_on_lead_preview,
        is_name_field,
        CustomObjectFieldForeignKey,
      } = props.data;

      return (
        <PhoenixEyeToggle
          visible={visible}
          height={40}
          onChange={() => {
            updateCustomObjectField({
              variables: {
                id: props.data.id,
                data: {
                  name,
                  custom_object_id,
                  type,
                  list_option,
                  allow_reps_to_edit,
                  visible: !visible,
                  // if visible is toggled off show on lead preview must be off too
                  show_on_lead_preview: visible === true ? false : show_on_lead_preview,
                  lead_system_field_key: CustomObjectFieldForeignKey?.lead_system_field_key ?? "",
                },
              },
            });
          }}
        />
      );
    },
    [updateCustomObjectField],
  );

  const handleEdit = (params: any) => {
    const data = params.data;
    setModalField({
      ...data,
      keyName: data.name,
      options: data.list_option,
      lead_system_field_key: data.CustomObjectFieldForeignKey?.lead_system_field_key || "",
    });
    setModalType(ModalType.FIELD_UPDATE);
  };

  const handleDelete = (params: any) => {
    const data = params.data;
    setModalField({ ...data, keyName: data.key, options: data.list_option });
    setModalType(ModalType.FIELD_DELETE);
  };

  const colDefs = useMemo(
    () => [
      {
        headerName: "Custom Field Name",
        field: "name",
        flex: 2,
        headerClass: "ag-object-manager-header",
        rowDrag: true,
        sortable: true,
      },
      {
        headerName: "Custom Field Type",
        field: "type",
        flex: 2,
        headerClass: "ag-object-manager-header",
        sortable: true,
      },
      {
        headerName: "Allow Reps to Edit",
        field: "allow_reps_to_edit",
        flex: 2,
        headerClass: "ag-object-manager-header",
        sortable: true,
        cellRenderer: SwitchRendererEdit,
      },
      {
        headerName: "Show on Record Preview",
        field: "show_on_lead_preview",
        flex: 1,
        minWidth: 200,
        headerClass: "ag-object-manager-header",
        sortable: true,
        cellRenderer: SwitchRendererPreview,
      },
      {
        headerName: "Visibility",
        field: "visible",
        flex: 1,
        headerClass: "ag-object-manager-header",
        sortable: true,
        cellRenderer: EyeRenderer,
      },
      {
        headerName: "Manage Field",
        field: "manage",
        cellRenderer: ManageCellRenderer,
        flex: 2,
        headerClass: "ag-object-manager-header",
      },
    ],
    [EyeRenderer, ManageCellRenderer, SwitchRendererEdit, SwitchRendererPreview],
  );

  const onRowDragEnd = useCallback(
    (event: RowDragEndEvent<any>) => {
      const movingNode = event.node;
      const movingData = movingNode.data;

      const newItems = [...rowData];
      const fromIndex = newItems.findIndex((item) => item.id === movingData.id);
      const toIndex = event.overIndex;

      if (fromIndex === toIndex) {
        return;
      }

      const startIndex = Math.min(fromIndex, toIndex);
      const endIndex = Math.max(fromIndex, toIndex);

      for (let i = startIndex; i <= endIndex; i++) {
        if (newItems[i].is_name_field) {
          appToast("Record name field can't be reordered");
          gridRef.current?.api.setRowData(rowData);
          return;
        }
      }

      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movingData);

      setRowData(newItems);

      updateCustomObjectFieldsOrder({
        variables: {
          id: customObject.id,
          items: newItems?.map((x) => x.id),
        },
      });
    },
    [rowData, customObject.id],
  );

  useEffect(() => {
    if (togglePageTitle) {
      togglePageTitle();
    }
  }, []);

  const renderModal = () => {
    if (modalType === ModalType.OBJECT_UPDATE) {
      return (
        <UpdateCustomObject
          blinds={true}
          close={() => setModalType(ModalType.NONE)}
          id={customObject.id}
          name={customObject.name}
        />
      );
    }
    if (modalType === ModalType.OBJECT_DELETE) {
      return (
        <DeleteFieldV2
          customFields
          fieldType={`Custom Object: ${customObject.name}`}
          loading={loadingDelete}
          blinds={true}
          close={() => setModalType(ModalType.NONE)}
          fieldName={customObject.name}
          id={customObject.id}
          onDeleteFunction={async () => {
            await deleteOneCustomObject({
              variables: {
                id: customObject.id,
              },
            });
          }}
        />
      );
    }
    if (modalType === ModalType.FIELD_ADD) {
      return (
        <UpdateCustomFieldV2
          blinds={true}
          is_custom_object={true}
          custom_object_id={customObject.id}
          custom_object_name={customObject.name}
          close={() => {
            setModalType(ModalType.NONE);
          }}
          mappable_lead_keys={mappableLeadKeys}
        />
      );
    }

    if (modalType === ModalType.FIELD_UPDATE) {
      return (
        <UpdateCustomFieldV2
          blinds={true}
          is_custom_object={true}
          custom_object_id={customObject.id}
          custom_object_name={customObject.name}
          close={() => {
            setModalType(ModalType.NONE);
          }}
          {...modalField}
          mappable_lead_keys={mappableLeadKeys}
        />
      );
    }

    if (modalType === ModalType.FIELD_DELETE) {
      return (
        <DeleteFieldV2
          customFields
          fieldType={`Field: ${modalField.name}`}
          loading={loadingDelete}
          blinds={true}
          close={() => setModalType(ModalType.NONE)}
          id={modalField.id}
          onDeleteFunction={async () => {
            await deleteOneCustomObjectField({
              variables: {
                id: modalField.id,
              },
            });
          }}
        />
      );
    }

    return null;
  };

  const renderTable = useMemo(() => {
    if (loading || loadingDelete || loadingDeleteField) {
      return <Loading />;
    }
    if (!rowData.length) {
      return (
        <LeadCustomEmptyPage
          blinds={blinds}
          defaultField={"Field"}
          setBlinds={setBlinds}
          type={"Field"}
          setModalField={() => setModalType(ModalType.FIELD_ADD)}
        />
      );
    }

    return (
      <div className="ag-theme-object-manager" style={{ minHeight: 500, width: "100%" }}>
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          animateRows
          enableCellChangeFlash
          onRowDragEnd={onRowDragEnd}
          rowDragEntireRow
          rowDragManaged
          suppressDragLeaveHidesColumns
          suppressMovableColumns
          tooltipMouseTrack
          icons={{
            sortAscending: getUpIconHTML(),
            sortDescending: getDownIconHTML(),
          }}
        />
      </div>
    );
  }, [blinds, colDefs, loading, loadingDelete, loadingDeleteField, onRowDragEnd, rowData]);

  if (error || errorDelete || errorDeleteField) {
    return <AppText>Something went wrong</AppText>;
  }

  return (
    <Main>
      <FlexColumn>
        <AppBackButton />
        <SimpleFlexDiv>
          <FlexRow>
            <StepLocationText>{customObject.name}</StepLocationText>
            <PhoenixIcon
              svg={edit}
              size={16}
              onClick={() => {
                setModalType(ModalType.OBJECT_UPDATE);
              }}
            />
          </FlexRow>
          <div>
            <DeleteButton
              onClick={() => {
                setModalType(ModalType.OBJECT_DELETE);
              }}
            >
              DELETE OBJECT
            </DeleteButton>

            <PhoenixAppButton
              buttonType="secondary"
              onClick={() => {
                setModalField(defaultField);
                setModalType(ModalType.FIELD_ADD);
              }}
            >
              ADD FIELD
            </PhoenixAppButton>
          </div>
        </SimpleFlexDiv>
      </FlexColumn>
      <BodyContainer>
        {renderModal()}
        {renderTable}
      </BodyContainer>
    </Main>
  );
};

const Main = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  position: relative;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
  margin-bottom: 40px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`;
const SimpleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  gap: 6;
`;

const StepLocationText = styled(AppText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-right: 8px;
`;

const DeleteButton = styled(NewAppButton)`
  text-transform: capitalize;
  color: #e91e16;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100px;
  padding: 0px;
  margin: 8px;
`;

enum ModalType {
  OBJECT_DELETE = "OBJECT_DELETE",
  OBJECT_UPDATE = "OBJECT_UPDATE",
  FIELD_DELETE = "FIELD_DELETE",
  FIELD_UPDATE = "FIELD_UPDATE",
  FIELD_ADD = "FIELD_ADD",
  NONE = "NONE",
}

export { CustomObjectTable };
