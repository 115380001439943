import styled from "styled-components";
import * as React from "react";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import { ModalContext } from "../../context";

const TabNav: React.FC = () => {
  const history = useHistory();
  const { showTabNav, tabNavData, currentTab, setShowTabNav, setTabNavData, setCurrentTab } = useContext(ModalContext);
  const [step, handleStep] = useState("");

  const replaceHistory = (tabName: string) => {
    const currentPath = history.location.pathname;
    let subdirectories = currentPath.split("/");
    history.push(`/${subdirectories[1]}/${tabName}`);
  };

  console.log("currentTab", currentTab, tabNavData);
  return (
    <>
      {showTabNav && (
        <TabContainer>
          {tabNavData?.map((tab: any) => (
            <TabDiv thisTab={currentTab === tab.tab_name} onClick={() => replaceHistory(tab.tab_name)}>
              <TabTitle thisTab={currentTab === tab.tab_name}>{tab.title}</TabTitle>
            </TabDiv>
          ))}
        </TabContainer>
      )}
    </>
  );
};

interface ActiveTabProps {
  thisTab: boolean;
}

const TabTitle = styled(AppText)<ActiveTabProps>`
  font-weight: ${(props) => (props.thisTab ? 600 : "normal")};
  color: ${(props) => (props.thisTab ? theme.text.brand.primary : theme.text.neutral.primary)};
  padding: 0px 24px;
  max-width: 192px;
  :hover {
    cursor: pointer;
  }
  transition: color 0.2s ease-in-out;
`;

const TabDiv = styled.div<ActiveTabProps>`
  border-right: ${(props) => (props.thisTab ? ` solid 4px ${theme.PRIMARY500}` : "normal")};
  border-bottom: 1px solid ${theme.NEUTRAL200};
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: start;

  :hover {
    & ${TabTitle} {
      color: ${theme.text.brand.primary};
    }
  }
  transition: border-right 0.1s ease-in-out;
`;

const TabContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 192px;
  border-right: solid 1px ${theme.NEUTRAL200};
  background-color: ${theme.WHITE_COLOR};
  z-index: 0;

  overflow-y: auto;
  ::-webkit-scrollbar {
    // allow scroll but hide scrollbar
    display: none;
  }

  ${TabDiv}:last-child {
    margin-bottom: 90px;
  }
`;

export { TabNav };
