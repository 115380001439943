import styled, { AnyStyledComponent } from "styled-components";
import * as React from "react";
import { useEffect } from "react";
import { AppText, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { useContext, useMemo, useState } from "react";
import { newCloseModal } from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import { ModalContext } from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useMutation, useQuery } from "@apollo/client";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { formatTypeName } from "../../utils/format";

const UPDATE_SALES_LIST_COLUMNS = gql`
  mutation editSalesTeamColumnOptions($column: [String!]!) {
    editSalesTeamColumnOptions(column: $column) {
      label
      value
    }
  }
`;
interface ManageSalesTeamsColumnsModalProps {
  columns: any;
  tempSelected: any;
  setTempSelected: any;
}

const ManageSalesTeamsColumnsModal: React.FC<ManageSalesTeamsColumnsModalProps> = ({
  columns,
  tempSelected,
  setTempSelected,
}) => {
  // Context for filters
  const { showSalesTeamsManageColumnsModal, setShowSalesTeamsManageColumnsModal } = useContext(ModalContext);

  const handleCheckboxClick = (column: any) => {
    if (!!tempSelected?.find((ele: any) => ele.label === column.label)) {
      const newSelected = tempSelected.filter((ele: any) => ele.label !== column.label);
      setTempSelected(newSelected);
    } else {
      setTempSelected([...tempSelected, column]);
    }
  };

  const [updateColumns, { loading: updateColumnsLoading, error: updateColumnsError }] = useMutation(
    UPDATE_SALES_LIST_COLUMNS,
    {
      onCompleted({ editSalesTeamColumnOptions }) {
        if (!editSalesTeamColumnOptions) {
          appToast("Error: Something went wrong!");
          return;
        }
        appToast("Columns updated");
        setShowSalesTeamsManageColumnsModal(false);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateColumns GraphQL Error: ${message}`,
        });
        console.log("Error in updateColumns: ", message);
      },
      refetchQueries: ["fetchSalesTeamColumnOptions"],
    },
  );

  const columnOptions = [
    { label: "User Id", value: "Id" },
    { label: "Sales Person", value: "SalesPerson" },
    { label: "Email", value: "Email" },
    { label: "Role", value: "Role" },
    { label: "Phone Number", value: "PhoneNumber" },
    { label: "Team", value: "Team" },
    { label: "Timezone", value: "Timezone" },
    { label: "Primary Industry", value: "PrimaryIndustry" },
    { label: "Sub Industry", value: "SubIndustry" },
    { label: "Lead Source", value: "LeadSource" },
    { label: "State", value: "State" },
    { label: "Channel", value: "Channel" },
    { label: "Team Start Date", value: "TeamStartDate" },
  ];

  return (
    <EditCardDiv>
      <TitleDiv>
        <TitleText>Manage Columns Settings</TitleText>
        <CloseButton>
          <NewAppButton
            // small
            // borderless
            onClick={() => {
              setShowSalesTeamsManageColumnsModal(!showSalesTeamsManageColumnsModal);
            }}
          >
            <img src={newCloseModal} alt="Close" />
          </NewAppButton>
        </CloseButton>
      </TitleDiv>
      <ScrollDiv>
        <PaddingAndScrollDiv>
          {columnOptions?.map((column: any) => (
            <AppCheckbox
              title={column.label}
              checked={!!tempSelected?.find((ele: any) => ele.label === column.label)}
              onClick={() => handleCheckboxClick(column)}
            />
          ))}
        </PaddingAndScrollDiv>
      </ScrollDiv>

      <SubmitDiv>
        <NewAppButton
          onClick={() => {
            setShowSalesTeamsManageColumnsModal(!showSalesTeamsManageColumnsModal);
          }}
        >
          Cancel
        </NewAppButton>
        <NewAppButton
          variant={"primary"}
          onClick={async () => {
            await updateColumns({
              variables: {
                column: tempSelected?.map((obj: any) => obj.value),
              },
            });
          }}
          disabled={!tempSelected.length || updateColumnsLoading}
        >
          Save
        </NewAppButton>
      </SubmitDiv>
    </EditCardDiv>
  );
};

const ScrollDiv = styled.div`
  max-height: calc(100vh - 156px);
  height: calc(100vh - 156px);
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 20px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 24px 32px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

export { ManageSalesTeamsColumnsModal };
