import styled from "styled-components";
import GreenCheck from "../../../images/icons-ic-notifications-accept.svg";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../../UI";
import { useState, useContext, useEffect, useMemo } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { GridFilterContext } from "../../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { iconGreenCheck } from "../../../images/";
import { formatDateTime, formatImportDate, formatUSD } from "../../../utils/format";
import { appToast } from "../../../utils/toast";
import { AppTable, TableRow, TableTD } from "../../UI/AppTable";
import { contractOptions } from "../../../static";
import { isUserRep, loggedInUser } from "./../../../apollo/cache";
import { formatCellData, formatOrdinalSuffix } from "../../../utils/format";

interface StackRankProps {
  userID?: string;
  teamID?: string;
  leadID?: string;
  orgID?: string;
  metric?: string;
  gridFilter: boolean;
  computedID?: string;
  position?: string;
  lowerbound_date?: Date;
  upperbound_date?: Date;
}

const STACK_RANK_LIST = gql`
  query stackRankListV2($dashboard_detail_args: DashboardDetailArgument!, $only_team: Boolean!) {
    stackRankListV2(dashboard_detail_args: $dashboard_detail_args, only_team: $only_team) {
      id
      metric_label
      row_label
      list_rank
      list_value
      selected
      metric_type
      best_all_time_value
    }
  }
`;

const STACK_RANK_COLUMNS = ["Rank", "Rep Name", "Value"];

const StackRankListTable: React.FC<StackRankProps> = ({
  lowerbound_date,
  upperbound_date,
  metric,
  position,
  computedID,
}) => {
  const [sortAscending, setSortAscending] = useState(false);
  const {
    dateStart,
    dateEnd,
    products,
    channel,
    gridFilter,
    dateLabel,
    repFilter,
    funnelSegment,
    selectedCell,
    pipelineIgnoreCloseDatesComputed,
    columnOptions,
    measureBy,
    groupOption,
    tableView,
    tablePipelineMetric,
    repFilterArgs,
    leadFilterArgs,
    dateFilterArgs,
    leadActivityChannel,
  } = useContext(GridFilterContext);
  const [reportsList, setReportsList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const takeNumber = 7;

  const handleShowMore = () => {
    setSkip(skip + takeNumber);
  };

  const { data: dataStack, loading: loadingStack, error: errorStack, refetch: refetchStack } = useQuery(
    STACK_RANK_LIST,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      skip: !selectedCell?.id,
      variables: {
        only_team: false,
        dashboard_detail_args: {
          lead_activity_channel: leadActivityChannel,
          lead_filter: leadFilterArgs,
          rep_filter: repFilterArgs,
          date_filter: { ...dateFilterArgs, lowerbound_date, upperbound_date },
          measure_by: measureBy,
          metric: !!metric ? metric : undefined,
          position: !!position ? position : undefined,
          computed_id: !!computedID ? computedID : undefined,
          // take: takeNumber,
          // skip: skip,
          // desc: !sortAscending,
          // order_by: orderBy,
        },
      },
      onCompleted: ({ stackRankListV2 }) => {
        const newList = [...reportsList, ...stackRankListV2].reduce(
          (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
          [],
        );
        setReportsList(newList);
        // setReportsList(reportsList.concat(listData.fetchCallReportList));
        if (stackRankListV2?.length < takeNumber) setHasMore(false);
        else setHasMore(true);
      },
      onError: () => {},
    },
  );
  return (
    <ListContainer id="stack-rank-list">
      <InfiniteScroll
        dataLength={reportsList.length}
        next={handleShowMore}
        hasMore={false} // No pagination needed for this view
        loader={<Loading />}
        scrollableTarget="stack-rank-list"
      >
        <AppTable
          sticky
          columns={!loadingStack ? STACK_RANK_COLUMNS : []}
          headerStyle={{ fontSize: 10 }}
          // height="299px"
        >
          {dataStack &&
            dataStack.stackRankListV2 &&
            dataStack.stackRankListV2
              ?.map((item: any) => item)
              // .sort((a: any, b: any) => (sortTableAscending ? a.list_rank - b.list_rank : b.list_rank - a.list_rank))
              ?.map((item: any) => (
                <TableRow key={item.id}>
                  <TableTD>{formatOrdinalSuffix(parseInt(item.list_rank))}</TableTD>
                  <TableTD>{item.row_label}</TableTD>
                  <TableTD>{formatCellData(item?.list_value, item?.metric_type)}</TableTD>
                </TableRow>
              ))}
        </AppTable>
      </InfiniteScroll>
    </ListContainer>
  );
};

interface SelectedProp {
  selected?: boolean;
}

const ListContainer = styled.div`
  width: 100%;
  height: 299px;
  /* padding: 16px; */
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  /* box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5); */
  background-color: ${theme.WHITE_COLOR};
  overflow: auto;
  /* padding: 0px 25px 0px 25px; */
`;

export { StackRankListTable };
