import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../utils/theme";

const NotFoundPage = () => (
  <StyledContainer>
    <StyledHeader>404</StyledHeader>
    <StyledSubHeader>Page not found</StyledSubHeader>
    <StyledLink to="/">Go back</StyledLink>
  </StyledContainer>
);

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: ${theme.PRIMARY600};
  color: ${theme.WHITE_COLOR};
`;

const StyledHeader = styled.h1`
  font-size: 100px;
  font-weight: 700;
  margin: 0;
`;

const StyledSubHeader = styled.h2`
  font-size: 30px;
  font-weight: 400;
  margin: 0;
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  font-weight: 400;
  margin: 0;

  color: ${theme.SUCCESS500};
`;

export default NotFoundPage;
