import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import moment from "moment";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SingleDatePicker } from "react-dates";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import styled from "styled-components";
import * as Yup from "yup";
import { iconNewMoveRowPrimary, iconRefreshSmall, searchIcon } from "../../../images";
import { info } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { OutsideEvent } from "../../Dumb";
import { FormSelectField, InputField, RadioButtonField } from "../../Field";
import {
  AppButton,
  AppErrorText,
  AppSidebarCard,
  AppText,
  AppTitle2,
  Loading,
  NewAppCheckBox,
  NewAppSelect,
} from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { ReactDatesWrapper } from "../../UI/ReactDatesWrapper";
import { DeleteRoutingRuleModal } from "../../modal";

import { useFlags } from "launchdarkly-react-client-sdk";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { TbArrowsUpDown } from "react-icons/tb";
import { trash } from "../../../images/NewDesign";
import { formatMultipleTypeNames, handleRateAndPercentage } from "../../../utils/format";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect } from "../../UI/Phoenix";

import DateTimePicker from "react-datetime-picker";
import {
  LEAD_CREATION_STATUSES,
  LEAD_STATUSES,
  arraysAreEqual,
  returnIndividualDataFromArrayBasedOnID,
  testForArrayFormatedAsAString,
  testForArrayWithMultipleElements,
  testIfFieldsAreUnique,
} from "../../../utils/misc";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";

interface DisappearingDivProps {
  step?: number;
  isOnboarding?: boolean;
  togglePageTitle?: () => void;
  rule_id?: string;
  rule: "widget" | "lead";
  rule_subtitle: string;
  rule_title?: string;
}

interface FetchRulesExpectedResponse {
  fetchRules?: {
    id?: string;
    priority?: number;
    name?: string;
    updated_at?: string;
    active?: boolean;
    applyToInboundConciergeSchedule?: boolean;
    applyToInboundConciergeCallMeNow?: boolean;
    applyToInboundConcierge?: boolean;
    distribution_method?: string;
    rep_selection_method?: string;
    deferral_date_time?: string;
    conditions?: {
      id?: string;
      field?: string;
      operator?: string;
      value?: string;
      type?: string;
    }[];
    organization?: {
      id?: string;
      send_lead_routing_email?: boolean;
      lead_routing_email_list?: string[];
    };
    routings?: {
      rep_id?: string;
      rule_id?: string;
    }[];
  }[];
}
const FETCH_ORG_RULES = gql`
  query fetchRules($rule_type: RuleType!) {
    fetchRules(rule_type: $rule_type) {
      id
      priority
      name
      updated_at
      active
      applyToInboundConciergeSchedule
      applyToInboundConciergeCallMeNow
      applyToInboundConcierge
      distribution_method
      rep_selection_method
      deferral_date_time
      conditions {
        id
        field
        operator
        value
        type
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
        rule_id
      }
    }
  }
`;

interface FetchOneLeadRoutingRuleExpectedResponse {
  fetchOneLeadRoutingRule?: {
    id: string;
    updated_at: string;
    created_at: string;
    name?: string;
    priority?: number;
    active?: boolean;
    applyToInboundConcierge?: boolean;
    applyToInboundConciergeSchedule?: boolean;
    applyToInboundConciergeCallMeNow?: boolean;
    rule_type?: string;
    rank_sort_type?: string;
    date_range?: string;
    entity?: string;
    metric?: string;
    deferral_date_time?: string;
    rep_priority_rule?: string;
    lead_creation_status?: string;
    distribution_method?: string;
    rep_selection_method?: string;
    los_rules?: string[];
    entity_type?: string;
    conditions?: {
      id?: string;
      field?: string;
      operator?: string;
      value: string;
      type?: string;
      cadence?: string;
      object: string;
      field_label: string;
    }[];
    organization?: {
      id?: string;
      send_lead_routing_email?: boolean;
      lead_routing_email_list?: string[];
    };
    routings?: {
      rep_id?: string;
    }[];
  };
}
const FETCH_ONE_ROUTING_RULE = gql`
  query FetchOneLeadRoutingRule($rule_id: String!) {
    fetchOneLeadRoutingRule(rule_id: $rule_id) {
      id
      updated_at
      created_at
      name
      priority
      active
      applyToInboundConcierge
      rule_type
      rank_sort_type
      date_range
      entity
      metric
      deferral_date_time
      rep_priority_rule
      lead_creation_status
      applyToInboundConcierge
      applyToInboundConciergeSchedule
      applyToInboundConciergeCallMeNow
      distribution_method
      rep_selection_method
      los_rules
      entity_type
      conditions {
        id
        object
        field
        field_label
        operator
        value
        type
        cadence
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
      }
    }
  }
`;

const REMOVE_ROUTING_RULE = gql`
  mutation removeRoutingRule($rule_id: String!) {
    removeRoutingRule(rule_id: $rule_id)
  }
`;

const UPDATE_RULE_ORDER = gql`
  mutation updateRuleOrder($rule_ids: [String!]!) {
    updateRuleOrder(rule_ids: $rule_ids)
  }
`;
interface Field {
  key: string;
  label: string;
  type: ConditionTypes;
  options: string[];
}

type ConditionTypes = "Text" | "Dropdown" | "MultiDropdown" | "Boolean" | "Date" | "Number" | "MultiText";

interface Operations {
  [key: string]: string[];
  Boolean: ("Equal" | "NotEqual")[];
  Dropdown: ("Equal" | "NotEqual" | "IsNull" | "NotNull" | "In" | "NotIn")[];
  MultiDropdown: ("Equal" | "NotEqual" | "IsNull" | "NotNull" | "In" | "NotIn")[];
  List: ("In" | "NotIn")[];
  Text: ("Contain" | "NotContain" | "Equal" | "NotEqual" | "IsNull" | "NotNull")[];
  Number: ("LessThan" | "LessThanOrEqualTo" | "GreaterThan" | "GreaterThanOrEqualTo" | "Equal" | "NotEqual")[];
  Date: (
    | "LessThan"
    | "LessThanOrEqualTo"
    | "GreaterThan"
    | "GreaterThanOrEqualTo"
    | "Equal"
    | "NotEqual"
    | "InTheNext"
    | "SinceTheLast"
  )[];
  MultiText: ("In" | "NotIn")[];
}

interface DateCadence {
  label: string;
  value: string;
}

interface FetchAvailableConditionOptionExpectedResponse {
  fetchAvaliableConditionOption: {
    objects: { [object: string]: Field[] };
    operations: Operations;
    date_cadence: DateCadence[];
  };
}

const FETCH_AVAILABLE_CONDITION_OPTIONS = gql`
  query fetchAvaliableConditionOption {
    fetchAvaliableConditionOption
  }
`;

interface RepOrderExpectedResponse {
  fetchRepOrder?: {
    id?: string;
    full_name?: string;
  }[];
}

const FETCH_REP_ORDER = gql`
  query fetchRepOrder($user_ids: [String!]!) {
    fetchRepOrder(user_ids: $user_ids) {
      id
      full_name
    }
  }
`;

const UPDATE_ROUTING_RULE_STATUS = gql`
  mutation updateRoutingRuleStatus($rule_id: String!, $active: Boolean!) {
    updateRoutingRuleStatus(rule_id: $rule_id, active: $active) {
      active
      name
      priority
    }
  }
`;

const UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_CALL_ME_NOW = gql`
  mutation updateRoutingRuleApplyToInboundConcierge($rule_id: String!, $applyToInboundConciergeCallMeNow: Boolean!) {
    updateRoutingRuleApplyToInboundConcierge(
      rule_id: $rule_id
      applyToInboundConciergeCallMeNow: $applyToInboundConciergeCallMeNow
    ) {
      active
      name
      priority
    }
  }
`;

const UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_SCHEDULE = gql`
  mutation updateRoutingRuleApplyToInboundConcierge($rule_id: String!, $applyToInboundConciergeSchedule: Boolean!) {
    updateRoutingRuleApplyToInboundConcierge(
      rule_id: $rule_id
      applyToInboundConciergeSchedule: $applyToInboundConciergeSchedule
    ) {
      active
      name
      priority
    }
  }
`;

const CREATE_OR_UPDATE_ROUTING_RULE = gql`
  mutation createOrUpdateLeadRoutingRule(
    $rule_id: String
    $name: String!
    $conditions: [RoutingCondition!]!
    $rep_ids: [String!]!
    $distribution_method: DISTRIBUTION_METHOD!
    $rep_selection_method: REP_SELECTION_METHOD!
    $deferral_date_time: DateTime
    $los_rules: [LOS!]!
    $lead_creation_status: LeadCreationStatus
    $rule_type: RuleType!
  ) {
    createOrUpdateLeadRoutingRule(
      rule_id: $rule_id
      name: $name
      conditions: $conditions
      rep_ids: $rep_ids
      distribution_method: $distribution_method
      rep_selection_method: $rep_selection_method
      deferral_date_time: $deferral_date_time
      los_rules: $los_rules
      lead_creation_status: $lead_creation_status
      rule_type: $rule_type
    ) {
      id
      priority
      name
      updated_at
      active
      applyToInboundConcierge
      distribution_method
      rep_selection_method
      deferral_date_time
      los_rules
      lead_creation_status
      conditions {
        object
        field
        operator
        value
        type
      }
      organization {
        id
        send_lead_routing_email
        lead_routing_email_list
      }
      routings {
        rep_id
      }
    }
  }
`;

interface FetchOrganizationExpectedResponse {
  fetchOrganization?: {
    id?: string;
    Reps?: {
      id?: string;
      full_name?: string;
      first_name?: string;
      last_name?: string;
      revenue_per_day?: number;
      email?: string;
    }[];
  };
}
const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Reps {
        id
        full_name
        id
        first_name
        last_name
        revenue_per_day
        email
      }
    }
  }
`;

const SET_LEAD_ROUTING_EMAIL_TOGGLE = gql`
  mutation setLeadRoutingEmailToggle($send_lead_routing_email: Boolean!) {
    setLeadRoutingEmailToggle(send_lead_routing_email: $send_lead_routing_email) {
      send_lead_routing_email
      id
    }
  }
`;

const SET_LEAD_ROUTING_EMAIL_LIST = gql`
  mutation setLeadRoutingEmailList($lead_routing_email_list: [String!]!) {
    setLeadRoutingEmailList(lead_routing_email_list: $lead_routing_email_list) {
      lead_routing_email_list
      id
    }
  }
`;

interface ICondition {
  field?: string;
  operator?: string;
  value: string;
  type?: string;
  cadence?: string;
  object: string;
  field_label: string;
}

interface MyFormikProps {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  priority: number;
  active: boolean;
  distribution_method: string;
  rep_selection_method: string;
  conditions: ICondition[];
  rep_ids: string[];
  emails_list: string[];
  email_updates: boolean;
  deferral_date_time: string | null;
  lead_creation_status: string;
  los_rules: string[];
  available_fields_search: string;
}

const yupRoutingRules = Yup.object().shape({
  id: Yup.string().notRequired(),
  created_at: Yup.string().notRequired(),
  updated_at: Yup.string().notRequired(),
  name: Yup.string().notRequired(),
  priority: Yup.number().notRequired(),
  active: Yup.boolean().notRequired(),
  distribution_method: Yup.string().notRequired(),
  rep_selection_method: Yup.string().notRequired(),
  conditions: Yup.array()
    .test("unique fields", "Each field can only be used once", (value) => testIfFieldsAreUnique(value ?? []))
    .of(
      Yup.object().shape({
        object: Yup.string()
          .required("Object is required")
          .test("Object is required", "Object is required", (value) => value !== "" && value !== undefined),
        field: Yup.string()
          .required("Field is required")
          .test("Field is required", "Field is required", (value) => value !== "" && value !== undefined),
        operator: Yup.string()
          .required("Operator is required")
          .test("Operator is required", "Operator is required", (value) => value !== "" && value !== undefined),
        value: Yup.string().when("operator", {
          is: (value) => value === "In" || value === "NotIn",
          then: Yup.string().test("value", "Requires multiple values", (value) =>
            testForArrayWithMultipleElements(value ?? ""),
          ),
          otherwise: Yup.string().when("operator", {
            is: (value) => value === "IsNull" || value === "NotNull",
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required("Value is required"),
          }),
        }),

        type: Yup.string().required("type is required"),
        field_label: Yup.string(),
        cadence: Yup.string().when("operator", {
          is: (value) => value === "InTheNext" || value === "SinceTheLast",
          then: Yup.string().required("Cadence is required"),
        }),
      }),
    ),
  rep_ids: Yup.array().of(Yup.string()),
  emails_list: Yup.array().of(Yup.string().email("Email addresses must be valid")),
  email_updates: Yup.boolean(),
});

const RulesTable: React.FC<DisappearingDivProps> = ({
  step,
  isOnboarding,
  togglePageTitle,
  rule_id,
  rule,
  rule_title,
  rule_subtitle,
}) => {
  const history = useHistory();

  const { leadRoutingOnUpdate } = useFlags();

  const formikRef = useRef() as any;

  const [isClicked, setIsClicked] = useState(false);

  const [sortAlphabetically, setSortAlphabetically] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);
  const [focused, setFocused] = useState(null as any);
  const [dataRules, setDataRules] = useState([] as any[]);

  // ----------------------- //

  // customization based on rule type

  // ----------------------- //

  // switch statement to get the url param
  const findRoutingURL = (rule: string) => {
    switch (rule) {
      case "lead":
        return "routing-rules";
      case "widget":
        return "inbound-concierge";
      default:
        return "routing-rules";
    }
  };

  // what options to display when editing or creating a rule
  const findRuleOptions = (rule: string) => {
    switch (rule) {
      case "lead":
        return [
          "name",
          "priority",
          "conditions",
          "lead_creation_status",
          "lead_status",
          "rep_distribution",
          "date",
          "email_updates",
        ];

      case "widget":
        return ["name", "conditions"];

      default:
        return [
          "name",
          "priority",
          "conditions",
          "lead_creation_status",
          "lead_status",
          "rep_distribution",
          "date",
          "email_updates",
        ];
    }
  };

  // the edit page is shared between rules and is customized based on the above

  // the actual table is unique to each rule type

  const URL = findRoutingURL(rule);
  const RULE_OPTIONS_WHEN_EDITING = findRuleOptions(rule);

  const {
    data: dataRulesRaw,
    loading: loadingRules,
    error: errorRules,
    refetch: refetchRules,
  } = useQuery<FetchRulesExpectedResponse>(FETCH_ORG_RULES, {
    variables: {
      rule_type: rule === "lead" ? "Routing" : rule === "widget" ? "Widget" : "Routing",
    },
    fetchPolicy: "network-only",
    onError({ message, name }: any) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
    onCompleted: ({ fetchRules }: any) => {
      setDataRules(fetchRules.slice().sort((a: any, b: any) => a.priority - b.priority));
    },
  });

  const {
    data: dataSelectedRule,
    loading: loadingSelectedRule,
    error: errorSelectedRule,
    refetch: refetchSelectedRule,
  } = useQuery<FetchOneLeadRoutingRuleExpectedResponse>(FETCH_ONE_ROUTING_RULE, {
    fetchPolicy: "network-only",
    skip: !rule_id || (!!rule_id && rule_id === "new"),
    variables: { rule_id: rule_id },
    onCompleted({ fetchOneLeadRoutingRule }) {
      if (!fetchOneLeadRoutingRule) {
        return;
      }
      setSelectedRule({
        id: fetchOneLeadRoutingRule?.id,
        created_at: fetchOneLeadRoutingRule?.created_at,
        updated_at: fetchOneLeadRoutingRule?.updated_at,
        name: fetchOneLeadRoutingRule?.name,
        priority: fetchOneLeadRoutingRule?.priority,
        active: fetchOneLeadRoutingRule?.active,
        distribution_method: fetchOneLeadRoutingRule?.distribution_method,
        rep_selection_method: fetchOneLeadRoutingRule?.rep_selection_method,
        conditions: fetchOneLeadRoutingRule?.conditions?.map((el: ICondition) => ({
          object: el?.object,
          field: el?.field,
          field_label: el?.field_label,
          operator: el?.operator,
          value: !!el.value ? el.value.toString() : "",
          type: el?.type,
          cadence: el?.cadence,
        })),
        rep_ids: fetchOneLeadRoutingRule?.routings?.map((el: any) => el.rep_id) || [],
        deferral_date_time: fetchOneLeadRoutingRule?.deferral_date_time,
        email_updates: fetchOneLeadRoutingRule?.organization?.send_lead_routing_email,
        emails_list: fetchOneLeadRoutingRule?.organization?.lead_routing_email_list,
        lead_creation_status: fetchOneLeadRoutingRule?.lead_creation_status,
        los_rules: fetchOneLeadRoutingRule?.los_rules,
      });
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  interface IRule {
    id: string;
    created_at: string;
    updated_at: string;
    name?: string;
    priority?: number;
    active?: boolean;
    distribution_method?: string;
    rep_selection_method?: string;
    conditions?: ICondition[];
    rep_ids?: string[];
    emails_list?: string[];
    email_updates?: boolean;
    deferral_date_time?: string | null;
    lead_creation_status?: string;
    los_rules?: string[];
  }
  const defaultRuleTemplate = {
    id: "",
    created_at: "",
    updated_at: "",
    name: "",
    priority: !!dataRules ? dataRules.length : 0,
    active: false,
    distribution_method: "RoundRobin",
    rep_selection_method: "FirstRep",
    conditions: [{ object: "", field: "", operator: "", value: "", type: "", field_label: "" }],
    rep_ids: [],
    email_updates: false,
    emails_list: [],
    deferral_date_time: null,
    lead_creation_status: "Create",
    los_rules: [],
  };

  const [selectedRule, setSelectedRule] = useState<IRule>(defaultRuleTemplate);

  interface IRep {
    included_list: string[];
    ranked_list: string[];
  }
  const [selectedReps, setSelectedReps] = useState<IRep>({
    included_list: [] as string[],
    ranked_list: [] as string[],
  });

  const [createOrUpdateLeadRoutingRule, { data: updateRule, loading: loadingUpdate }] = useMutation(
    CREATE_OR_UPDATE_ROUTING_RULE,
    {
      onCompleted({ createOrUpdateLeadRoutingRule }) {
        if (!createOrUpdateLeadRoutingRule) {
          return;
        }
        if (rule_id !== createOrUpdateLeadRoutingRule?.id) {
          history.push(`/${URL}/${createOrUpdateLeadRoutingRule?.id}`);
        }
        setSelectedRule({
          id: createOrUpdateLeadRoutingRule?.id,
          created_at: createOrUpdateLeadRoutingRule?.created_at,
          updated_at: createOrUpdateLeadRoutingRule?.updated_at,
          name: createOrUpdateLeadRoutingRule?.name,
          priority: createOrUpdateLeadRoutingRule?.priority,
          active: createOrUpdateLeadRoutingRule?.active,
          distribution_method: createOrUpdateLeadRoutingRule?.distribution_method,
          rep_selection_method: createOrUpdateLeadRoutingRule?.rep_selection_method,
          conditions: createOrUpdateLeadRoutingRule?.conditions?.map((el: ICondition) => ({
            object: el?.object,
            field: el?.field,
            operator: el?.operator,
            value: el?.value,
            type: el?.type,
            cadence: el?.cadence,
          })),
          rep_ids: createOrUpdateLeadRoutingRule?.routings?.map((el: any) => el.rep_id) || [],
          deferral_date_time: createOrUpdateLeadRoutingRule?.deferral_date_time,
          email_updates: createOrUpdateLeadRoutingRule?.organization?.send_lead_routing_email,
          emails_list: createOrUpdateLeadRoutingRule?.organization?.lead_routing_email_list,
          los_rules: createOrUpdateLeadRoutingRule?.los_rules,
          lead_creation_status: createOrUpdateLeadRoutingRule?.lead_creation_status,
        });
        appToast("Success! Updated rules");
      },
      onError({ message }) {
        console.log("Error in createOrUpdateLeadRoutingRule: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `createOrUpdateLeadRoutingRule GraphQL Error: ${message}`,
        });
      },
      refetchQueries: ["fetchRules"],
    },
  );

  const [removeRoutingRule, { loading: loadingRemoveRule }] = useMutation(REMOVE_ROUTING_RULE, {
    onCompleted({ removeRoutingRule }) {
      if (!removeRoutingRule) {
        return;
      }
      history.push(`/system-config/${URL}`);
      appToast("Deleted routing route");
      // setSelectedRule(defaultRuleTemplate);
    },
    onError({ message }) {
      console.log("Error in removeRoutingRule: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `removeRoutingRule GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER, {
    onCompleted({ updateRuleOrder }) {
      if (!updateRuleOrder) {
        return;
      }
      appToast("Updated order");
    },
    onError({ message }) {
      console.log("Error in updateRuleOrder: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRuleOrder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [updateRoutingRuleApplyToInboundConciergeCallMeNow] = useMutation(
    UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_CALL_ME_NOW,
    {
      onCompleted({ updateRoutingRuleApplyToConcierge }) {
        appToast("Updated inbound concierge call me rule status");
      },
      onError({ message }) {
        console.log("Error in updateRoutingRuleApplyToConcierge: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `updateRoutingRuleApplyToConcierge GraphQL Error: ${message}`,
        });
      },

      refetchQueries: ["fetchRules"],
    },
  );

  const [updateRoutingRuleApplyToInboundConciergeSchedule] = useMutation(
    UPDATE_ROUTING_RULE_APPLY_TO_INBOUND_CONCIERGE_SCHEDULE,
    {
      onCompleted({ updateRoutingRuleApplyToConcierge }) {
        appToast("Updated inbound concierge schedule meeting application status");
      },
      onError({ message }) {
        console.log("Error in updateRoutingRuleApplyToConcierge: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `updateRoutingRuleApplyToConcierge GraphQL Error: ${message}`,
        });
      },

      refetchQueries: ["fetchRules"],
    },
  );

  const [updateRoutingRuleStatus] = useMutation(UPDATE_ROUTING_RULE_STATUS, {
    onCompleted({ updateRoutingRuleStatus }) {
      if (!updateRoutingRuleStatus) {
        return;
      }
      if (rule === "widget") {
        appToast("Updated widget rule status");
      }
      if (rule === "lead") {
        appToast("Updated integration and csv rule status");
      }
    },
    onError({ message }) {
      console.log("Error in updateRoutingRuleStatus: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRoutingRuleStatus GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchRules"],
  });

  const [setLeadRoutingEmailToggle] = useMutation(SET_LEAD_ROUTING_EMAIL_TOGGLE, {
    onCompleted({ setLeadRoutingEmailToggle }) {
      setLeadRoutingEmailToggle.send_lead_routing_email
        ? appToast("Email updates will now be sent")
        : appToast("Email updates will NOT be sent");
    },
    onError({ message }) {
      console.log("Error in setLeadRoutingEmailToggle", message);
      appToast(`Setting email updates error: ${message}`);
    },
  });

  const [setLeadRoutingEmailList] = useMutation(SET_LEAD_ROUTING_EMAIL_LIST, {
    onCompleted({ setLeadRoutingEmailList }) {
      appToast("Email list updated");
    },
    onError({ message }) {
      console.log("Error in setLeadRoutingEmailList", message);
      appToast(`Update email list error: ${message}`);
    },
  });

  const {
    data: dataOrg,
    loading: loadingOrg,
    error: errorOrg,
  } = useQuery<FetchOrganizationExpectedResponse>(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [fetchRepOrder, { data: dataOrder, loading: loadingOrder, error: errorOrder, called: calledOrder }] =
    useLazyQuery<RepOrderExpectedResponse>(FETCH_REP_ORDER, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (
      !!dataOrder &&
      !!dataOrder.fetchRepOrder &&
      !!dataOrder.fetchRepOrder.length &&
      !!formikRef.current &&
      !!formikRef.current.setFieldValue &&
      !!formikRef.current.values
    ) {
      const sortedIDs = dataOrder.fetchRepOrder?.map((item: any) => item.id);
      if (arraysAreEqual(sortedIDs, formikRef.current.values.rep_ids)) {
        formikRef.current.setFieldValue("rep_ids", sortedIDs);
      }
    }
  }, [dataOrder]);

  const {
    data: dataOptions,
    loading: loadingOptions,
    error: errorOptions,
  } = useQuery<FetchAvailableConditionOptionExpectedResponse>(FETCH_AVAILABLE_CONDITION_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const findTypeFromFieldKey = (object: string, value: string | undefined): ConditionTypes | string => {
    const DEFAULT_TYPE = "Text";

    const fields = dataOptions?.fetchAvaliableConditionOption?.objects[object];
    if (!value || !fields) {
      return DEFAULT_TYPE;
    } else {
      const field = fields.find((item) => item.key === value);
      if (!field) return DEFAULT_TYPE;

      return handleRateAndPercentage(field.type);
    }
  };

  const findOperatorsFromFieldType = (value: string) => {
    const val = value === "Rate" || value === "Percentage" ? "Number" : value;

    return !!value &&
      !!dataOptions?.fetchAvaliableConditionOption?.operations &&
      !!dataOptions.fetchAvaliableConditionOption.operations[val]
      ? value === "Text"
        ? [
            ...dataOptions.fetchAvaliableConditionOption.operations[val]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
            ...dataOptions.fetchAvaliableConditionOption.operations["MultiText"]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
          ]
        : dataOptions.fetchAvaliableConditionOption.operations[val]?.map((item: string) => ({
            label: formatMultipleTypeNames(item),
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  const findDropdownOptionsFromField = (object: string, value: string | undefined) => {
    if (!value) {
      return [{ label: "Select field first", value: "" }];
    }

    const fields = dataOptions?.fetchAvaliableConditionOption.objects[object];

    return !!value && !!fields && !!fields.slice().filter((item) => item.key === value).length
      ? fields
          .slice()
          .filter((item) => item.key === value)[0]
          .options?.map((item: string) => ({
            label: item,
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  if (loadingRules) return <Loading />;

  if (errorRules) return <AppErrorText>Error loading Rules</AppErrorText>;

  const reorderRules = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // update local state
    setDataRules(result?.map((r, i) => ({ ...r, priority: i })));
    updateRuleOrder({
      variables: {
        rule_ids: result?.map((item: any) => item.id),
      },
    });
  };

  const onDragEndRules = (result: any) => {
    console.log("result: ", result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderRules(
      dataRules.slice().sort((a: any, b: any) => a.priority - b.priority),
      result.source.index,
      result.destination.index,
    );
  };

  const isRepValid = (repId: string) => {
    const repIsValid =
      !!dataOrg?.fetchOrganization?.Reps &&
      dataOrg?.fetchOrganization?.Reps.slice().filter((item) => repId === item.id);
    return repIsValid;
  };

  const RuleEdit = ({
    values,
    setFieldValue,
    errors,
    isValid,
    submitForm,
    renderValueSelect,
    renderCadenceSelect,
    handleChangeEmails,
    onDragEndReps,
    RULE_OPTIONS_WHEN_EDITING,
  }: {
    values: any;
    setFieldValue: any;
    errors: any;
    isValid: any;
    submitForm: any;
    renderValueSelect: any;
    renderCadenceSelect: any;
    handleChangeEmails: any;
    onDragEndReps: any;
    RULE_OPTIONS_WHEN_EDITING: string[];
  }) => {
    const resetCondition = ({
      type,
      index,
      prev_val,
      current_val,
    }: {
      type: ResetObjectType;
      index: number;
      prev_val: string;
      current_val: string;
    }) => {
      if (prev_val === current_val) {
        return;
      }

      if (type === ResetObjectType.Object) {
        setFieldValue(`conditions[${index}].field`, "");
        setFieldValue(`conditions[${index}].operator`, "");
        setFieldValue(`conditions[${index}].value`, "");
        setFieldValue(`conditions[${index}].type`, "");
      }
      if (type === ResetObjectType.Field) {
        setFieldValue(`conditions[${index}].operator`, "");
        setFieldValue(`conditions[${index}].value`, "");
      }
      if (type === ResetObjectType.Operator) {
        setFieldValue(`conditions[${index}].value`, "");
      }
    };

    const renderConditions = (remove: <T>(index: number) => T | undefined) => {
      return values.conditions?.map((item: ICondition, index: number) => {
        const { object, field, operator, type } = item;
        const field_options = object
          ? dataOptions?.fetchAvaliableConditionOption?.objects[object]?.map((item: any) => ({
              label: item.label,
              value: item.key,
              type: item.type,
            }))
          : [];

        const object_options = Object.keys(dataOptions?.fetchAvaliableConditionOption?.objects || [])?.map(
          (item: any) => ({
            label: item,
            value: item,
          }),
        );

        return (
          <>
            {index > 0 && <OperationBox>AND</OperationBox>}
            <ConditionTitle>Condition {index + 1}</ConditionTitle>
            <ConditionDiv key={index}>
              <CriteriaGrid>
                <div>
                  <InputLabel>Object</InputLabel>
                  <NewAppSelect
                    textAlign="left"
                    name={`conditions[${index}].object`}
                    placeholder="Select one"
                    allowSelectPlaceholder
                    options={object_options}
                    onChange={(value: any) => {
                      const prev_val = values.conditions[index].object;
                      setFieldValue(`conditions[${index}].object`, value.value);
                      const current_val = value.value;
                      resetCondition({ type: ResetObjectType.Object, index: index, prev_val, current_val });
                    }}
                    value={object}
                  />
                  <>
                    {errors && errors?.conditions?.[index]?.object && values?.conditions[index]?.object && (
                      <AppErrorText>{errors?.conditions?.[index]?.object}</AppErrorText>
                    )}
                  </>
                </div>
                <div>
                  <InputLabel>Fields</InputLabel>
                  <NewAppSelect
                    textAlign="left"
                    name={`conditions[${index}].field`}
                    placeholder="Select one"
                    allowSelectPlaceholder
                    options={field_options || []}
                    onChange={(value: any) => {
                      const prev_val = values.conditions[index].field;
                      setFieldValue(`conditions[${index}].field`, value.value);
                      setFieldValue(`conditions[${index}].type`, value.type);
                      const current_val = value.value;
                      console.log("field", value, value.value, value.type);
                      resetCondition({ type: ResetObjectType.Field, index: index, prev_val, current_val });
                    }}
                    value={field}
                  />
                  <>
                    {errors && errors?.conditions?.[index]?.field && values?.conditions[index]?.field && (
                      <AppErrorText>{errors?.conditions?.[index]?.field}</AppErrorText>
                    )}
                  </>
                </div>

                <div>
                  <InputLabel>Operator</InputLabel>
                  <NewAppSelect
                    textAlign="left"
                    placeholder="Select one"
                    allowSelectPlaceholder
                    // name={`conditions[${index}].operator`}
                    options={findOperatorsFromFieldType(
                      findTypeFromFieldKey(values.conditions[index].object, values.conditions[index].field),
                    )}
                    onChange={(value: any) => {
                      const prev_val = values.conditions[index].operator;
                      setFieldValue(`conditions[${index}].operator`, value.value);
                      const current_val = value.value;
                      resetCondition({ type: ResetObjectType.Operator, index: index, prev_val, current_val });
                    }}
                    value={operator}
                  />

                  <>
                    {errors && errors?.conditions?.[index]?.operator && values?.conditions?.[index]?.operator && (
                      <AppErrorText>{errors?.conditions?.[index]?.operator}</AppErrorText>
                    )}
                  </>
                </div>
                {/*start of values  */}

                <ValueDiv>
                  <div>
                    <InputLabel>Value</InputLabel>
                    {renderValueSelect({
                      currentCondition: values.conditions[index],
                      index,
                    })}
                  </div>
                  <div>
                    {renderCadenceSelect({
                      currentCondition: values.conditions[index],
                      index,
                      date_cadence: dataOptions?.fetchAvaliableConditionOption?.date_cadence || "",
                    })}
                  </div>
                  <>
                    {errors && errors?.conditions?.[index]?.value && values?.conditions[index]?.value && (
                      <AppErrorText>{errors?.conditions?.[index]?.value}</AppErrorText>
                    )}
                  </>
                </ValueDiv>
                {(index > 0 || values.conditions.length > 1) && (
                  <CloseDiv onClick={() => remove(index)}>
                    <PhoenixIcon svg={trash} variant="brand" size={24} />
                  </CloseDiv>
                )}
              </CriteriaGrid>
            </ConditionDiv>
          </>
        );
      });
    };

    return (
      <>
        <PhoenixStyledTooltip
          multiline
          place="right"
          effect="solid"
          css={{
            fontFamily: theme.PRIMARY_FONT,
          }}
          backgroundColor={theme.PRIMARY800}
          id="step-routing-rules-tooltip"
          getContent={(dataTip) => (
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              {dataTip}
            </span>
          )}
        />
        {RULE_OPTIONS_WHEN_EDITING.includes("name") && (
          <>
            <SectionTitle>Name</SectionTitle>

            <InputLabel>Routing Rule Name</InputLabel>
            <RuleInput name="name" placeholder="Rule Name" />
          </>
        )}
        {RULE_OPTIONS_WHEN_EDITING.includes("priority") && (
          <>
            <InputLabel>Priority</InputLabel>
            <RuleInput gray disabled name="priority" />
            <AppText style={{ fontSize: "10px", lineHeight: 1.5, opacity: 0.3, marginBottom: "40px" }}>
              Routing rule can be prioritized in the dashboard after being created
            </AppText>
          </>
        )}
        {RULE_OPTIONS_WHEN_EDITING.includes("conditions") && (
          <>
            <SectionTitle>
              {rule === "widget" ? "If Lead Meets These Criteria, Don't Show Widget" : "If Lead Meets These Criteria"}
            </SectionTitle>
            <FieldArray name="conditions">
              {({ remove, push }) => (
                <>
                  {loadingOptions ? (
                    <Loading />
                  ) : errorOptions ? (
                    <AppErrorText>Error loading options</AppErrorText>
                  ) : (
                    renderConditions(remove)
                  )}
                  {`${JSON.stringify(errors.conditions)}`.includes("Each field can only be used once") && (
                    <AppErrorText style={{ marginBottom: "12px" }}>Each field can only be used once</AppErrorText>
                  )}

                  <AddConditionButton
                    variant="primary"
                    onClick={() => push({ field: "", operator: "", value: "", type: "" })}
                  >
                    Add Condition
                  </AddConditionButton>
                </>
              )}
            </FieldArray>
          </>
        )}

        {leadRoutingOnUpdate && (
          <>
            {RULE_OPTIONS_WHEN_EDITING.includes("lead_creation_status") && (
              <>
                <InputLabel>Lead Creation Status</InputLabel>
                <RadioGroup>
                  {LEAD_CREATION_STATUSES?.map((status) => (
                    <RadioLabel>
                      <RadioButtonField name="lead_creation_status" id={status.id} />
                      <AppText>{status.text}</AppText>
                      {status.tip && (
                        <PhoenixIcon
                          svg={info}
                          variant="brand"
                          size={14}
                          data-for="step-routing-rules-tooltip"
                          data-tip={status.tip}
                        />
                      )}
                    </RadioLabel>
                  ))}
                </RadioGroup>
              </>
            )}

            {RULE_OPTIONS_WHEN_EDITING.includes("lead_status") && (
              <>
                <InputLabel>Lead Status</InputLabel>
                <FlexColDiv>
                  {LEAD_STATUSES?.map((item: any) => {
                    const checked = values.los_rules.includes(item.value);
                    return (
                      <NewAppCheckBox
                        title={item.title}
                        checked={checked}
                        onClick={() => {
                          !!checked
                            ? setFieldValue(
                                "los_rules",
                                values.los_rules.filter((rule: string) => rule !== item.value),
                              )
                            : setFieldValue("los_rules", [...values.los_rules, item.value]);
                        }}
                      />
                    );
                  })}
                </FlexColDiv>
              </>
            )}
          </>
        )}

        {RULE_OPTIONS_WHEN_EDITING.includes("rep_distribution") && (
          <>
            <SectionTitle>Then Distribute to Reps According To These Rules</SectionTitle>
            <RepListsGrid>
              <SelectRepsContainer>
                <AppText fontWeight={500}>Select Reps to Include in This Rule</AppText>
                <RepsListContainer>
                  <SpaceBetweenDiv>
                    <SearchRepsInput name="available_fields_search" placeholder="Search Reps" />
                    <OutsideEvent onOutsideClick={() => setIsClicked(false)}>
                      <ContainerDivSort>
                        <div
                          onClick={() => {
                            setIsClicked(!isClicked);
                          }}
                          style={{
                            border: `1px solid ${theme.NEUTRAL200}`,
                            borderRadius: "4px",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <TbArrowsUpDown
                            color={theme.PRIMARY500}
                            size={28}
                            style={{
                              marginTop: "4px",
                              marginBottom: "4px",
                              marginLeft: "8px",
                              marginRight: "8px",
                            }}
                          ></TbArrowsUpDown>
                        </div>
                        {isClicked && (
                          <OptionsDiv>
                            <Option
                              selected={sortAlphabetically}
                              onClick={() => {
                                setSortAlphabetically(true);
                              }}
                            >
                              Alphabetically
                            </Option>
                            <Option
                              selected={!sortAlphabetically}
                              onClick={() => {
                                setSortAlphabetically(false);
                              }}
                            >
                              Rev / Rep / Day
                            </Option>
                          </OptionsDiv>
                        )}
                      </ContainerDivSort>
                    </OutsideEvent>
                  </SpaceBetweenDiv>
                  <RepsScrollDiv>
                    {!!loadingOrg ? (
                      <Loading />
                    ) : errorOrg ? (
                      <AppErrorText>Error loading reps</AppErrorText>
                    ) : (
                      !!dataOrg?.fetchOrganization?.Reps &&
                      dataOrg?.fetchOrganization?.Reps.slice()
                        .filter(
                          (item) =>
                            !values.available_fields_search ||
                            `${item.first_name} ${item.last_name}`
                              .toLowerCase()
                              .includes(values.available_fields_search.toLowerCase()),
                        )
                        .sort((a: any, b: any) =>
                          sortAlphabetically
                            ? `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)
                            : b.revenue_per_day - a.revenue_per_day,
                        )
                        ?.map((item: any) => (
                          <NewAppCheckBox
                            title={`${item.first_name} ${item.last_name} ${
                              !sortAlphabetically ? `(${item.revenue_per_day.toFixed(2)})` : ""
                            }`}
                            checked={selectedReps.included_list.includes(item.id)}
                            onClick={() => {
                              selectedReps.included_list.includes(item.id)
                                ? setSelectedReps({
                                    ranked_list: selectedReps.ranked_list,
                                    included_list: selectedReps.included_list
                                      .slice()
                                      .filter((li: string) => li !== item.id),
                                  })
                                : setSelectedReps({
                                    ranked_list: selectedReps.ranked_list,
                                    included_list: [...selectedReps.included_list, item.id],
                                  });
                            }}
                          />
                        ))
                    )}
                  </RepsScrollDiv>
                </RepsListContainer>
              </SelectRepsContainer>

              <FlexColDiv>
                <div
                  style={{
                    border: `1px solid ${theme.NEUTRAL200}`,
                    borderRadius: "4px",
                    marginBottom: "10px",
                  }}
                  onClick={() => {
                    setFieldValue("rep_ids", [...new Set([...values.rep_ids, ...selectedReps.included_list])]);
                    setSelectedReps({
                      included_list: [],
                      ranked_list: selectedReps.ranked_list,
                    });
                  }}
                >
                  <HiArrowRight
                    size={24}
                    color={theme.PRIMARY500}
                    style={{
                      marginLeft: "8px",
                      marginTop: "4px",
                    }}
                  ></HiArrowRight>
                </div>
                <div
                  style={{
                    border: `1px solid ${theme.NEUTRAL200}`,
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    setFieldValue(
                      "rep_ids",
                      values.rep_ids.filter((item: string) => !selectedReps.ranked_list.includes(item)),
                    );
                    setSelectedReps({
                      included_list: selectedReps.included_list,
                      ranked_list: [],
                    });
                  }}
                >
                  <HiArrowLeft
                    size={24}
                    color={theme.PRIMARY500}
                    style={{
                      marginLeft: "8px",
                      marginTop: "4px",
                    }}
                  ></HiArrowLeft>
                </div>
              </FlexColDiv>

              <SelectRepsContainer>
                <SpaceBetweenDiv>
                  <AppText fontWeight={500}>Rank Selected Reps</AppText>
                  {values?.rep_ids?.length > 1 && (
                    <SpaceBetweenDiv
                      onClick={() => {
                        fetchRepOrder({
                          variables: {
                            user_ids: values.rep_ids,
                          },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <AppText style={{ color: theme.PRIMARY500, fontWeight: 600 }}>Sellfire Suggested Rank</AppText>
                      <img style={{ marginLeft: "7px" }} src={iconRefreshSmall} alt="Auto rank" />
                    </SpaceBetweenDiv>
                  )}
                </SpaceBetweenDiv>
                <RepsListContainer>
                  <DragDropContext onDragEnd={onDragEndReps}>
                    <Droppable droppableId="droppablereps">
                      {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <DragElements>
                            {!!loadingOrg ? (
                              <Loading />
                            ) : errorOrg ? (
                              <AppErrorText>Error loading reps</AppErrorText>
                            ) : (
                              values.rep_ids
                                ?.map((item: any) =>
                                  returnIndividualDataFromArrayBasedOnID({
                                    id: item,
                                    array: dataOrg?.fetchOrganization?.Reps || ([] as any),
                                  }),
                                )
                                ?.map((item: any, index: number) => (
                                  <Draggable key={`rep_${item.id}`} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        key={`$rep_checkbox_${item.id}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <RepDraggableDiv>
                                          <img
                                            style={{ marginRight: "8px", marginTop: "6px" }}
                                            src={iconNewMoveRowPrimary}
                                            height="12px"
                                            alt="Drag me"
                                          />
                                          <NewAppCheckBox
                                            title={`${item.first_name} ${item.last_name}`}
                                            checked={selectedReps.ranked_list.includes(item.id)}
                                            onClick={() => {
                                              selectedReps.ranked_list.includes(item.id)
                                                ? setSelectedReps({
                                                    included_list: selectedReps.included_list,
                                                    ranked_list: selectedReps.ranked_list
                                                      .slice()
                                                      .filter((li: string) => li !== item.id),
                                                  })
                                                : setSelectedReps({
                                                    included_list: selectedReps.included_list,
                                                    ranked_list: [...selectedReps.ranked_list, item.id],
                                                  });
                                            }}
                                          />
                                        </RepDraggableDiv>
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                            )}
                          </DragElements>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </RepsListContainer>
              </SelectRepsContainer>
            </RepListsGrid>

            <InputLabel>How should we determine if a Rep is available to take a Priority Lead?</InputLabel>
            <RadioGroup>
              <RadioLabel>
                <RadioButtonField name="rep_selection_method" id="FirstRep" />
                <AppText>Rep is not on any call (Emphasize Speed to Dial)</AppText>
              </RadioLabel>
              <RadioLabel>
                <RadioButtonField name="rep_selection_method" id="BestRep" />
                <AppText>
                  Rep may be on a call, but is not in a Scheduled Call Back, Demo, or Decision Call (Emphasize Getting
                  Leads to Best Reps)
                </AppText>
              </RadioLabel>
            </RadioGroup>

            <InputLabel>Do you want to prioritize equal lead distribution or company performance?</InputLabel>
            <RadioGroup>
              <RadioLabel>
                <RadioButtonField name="distribution_method" id="RoundRobin" />
                <AppText>Round Robin (Even Distribution)</AppText>
              </RadioLabel>
              <RadioLabel>
                <RadioButtonField name="distribution_method" id="Ranking" />
                <AppText>By Rank (Always Prioritize Leads to Your Top Ranked Reps)</AppText>
              </RadioLabel>
            </RadioGroup>
          </>
        )}
        {RULE_OPTIONS_WHEN_EDITING.includes("date") && (
          <>
            <Horizontal>
              <CenteredTextAndIcon>
                <InputLabel>Start Date</InputLabel>
                <PhoenixIcon
                  svg={info}
                  size={14}
                  style={{
                    marginLeft: "8px",
                  }}
                  data-for="step-routing-rules-tooltip"
                  data-tip="This rep will not be prompted to call this lead until this date and time passes. This is an optional value. If not specified, the lead will be available to call immediately."
                />
              </CenteredTextAndIcon>
            </Horizontal>

            <ReactDatesWrapper>
              <SingleDatePicker
                id="step-routing--date-picker"
                required
                date={moment(values?.deferral_date_time || undefined)}
                onDateChange={(date) => setFieldValue("deferral_date_time", date)}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                numberOfMonths={1}
                showDefaultInputIcon
                inputIconPosition={"after"}
              />
            </ReactDatesWrapper>
          </>
        )}
        {RULE_OPTIONS_WHEN_EDITING.includes("email_updates") && (
          <>
            <EmailsSwitchDiv>
              <Switch
                onChange={(checked) => {
                  setFieldValue("email_updates", checked);
                }}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                checked={values.email_updates}
                height={24}
                width={40}
                handleDiameter={18}
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <AppText>Send email updates when this rule has been applied</AppText>
            </EmailsSwitchDiv>
            {values.email_updates &&
              (loadingOrg ? (
                <Loading />
              ) : errorOrg ? (
                <AppErrorText>Error loading reps</AppErrorText>
              ) : (
                !!dataOrg &&
                !!dataOrg?.fetchOrganization &&
                !!dataOrg.fetchOrganization?.Reps && (
                  <div style={{ maxWidth: "732px" }}>
                    <CreatableSelect
                      isClearable
                      isMulti
                      onChange={handleChangeEmails}
                      placeholder="Enter email addresses..."
                      options={dataOrg?.fetchOrganization?.Reps?.map((item: any) => ({
                        label: `${item.email} (${item.first_name} ${item.last_name})`,
                        value: item.email,
                      }))}
                      defaultValue={dataOrg?.fetchOrganization?.Reps.filter((item: any) =>
                        values?.emails_list.includes(item.email),
                      )?.map((item: any) => ({
                        label: `${item.email} (${item.first_name} ${item.last_name})`,
                        value: item.email,
                      }))}
                    />
                    {!!`${JSON.stringify(errors.emails_list)}`.includes("Email addresses must be valid") && (
                      <AppErrorText style={{ marginBottom: "12px" }}>
                        Please make sure the email addresses are valid
                      </AppErrorText>
                    )}
                  </div>
                )
              ))}
          </>
        )}

        <ButtonsDiv2>
          {!!values.id && <DeleteButton onClick={() => setDeleteModal(true)}>Delete Rule</DeleteButton>}
          <SaveButton disabled={!isValid} variant="primary" type="submit" onClick={submitForm}>
            Save Rule
          </SaveButton>
        </ButtonsDiv2>
      </>
    );
  };

  const WidgetTable = () => {
    return (
      <DragDropContext onDragEnd={onDragEndRules}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {dataRules
                .slice()
                .sort((a, b) => a.priority - b.priority)
                ?.map((item: any, index: number) => (
                  <Draggable key={`table_${item.id}`} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <WidgetCampaignTable>
                        <TableText>{item.name}</TableText>
                        <TableText>{!!item.updated_at && moment(item.updated_at).format("MM/DD/YYYY")}</TableText>
                        <TableDiv>
                          <Switch
                            onChange={(checked) => {
                              updateRoutingRuleStatus({
                                variables: {
                                  rule_id: item.id,
                                  active: checked,
                                },
                              });
                              setDataRules(
                                dataRules?.map((item2: any) => {
                                  if (item2.id === item.id) {
                                    return {
                                      ...item2,
                                      active: checked,
                                    };
                                  }
                                  return item2;
                                }),
                              );
                            }}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            checked={item.active}
                            height={24}
                            width={40}
                            handleDiameter={18}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                        </TableDiv>

                        <TableText>
                          <EditSpan
                            onClick={() => {
                              !!togglePageTitle && togglePageTitle();

                              history.push(`/system-config/${URL}/${item.id}`);
                            }}
                          >
                            Edit
                          </EditSpan>
                        </TableText>
                      </WidgetCampaignTable>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const LeadTable = () => {
    return (
      <DragDropContext onDragEnd={onDragEndRules}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {dataRules
                .slice()
                .sort((a, b) => a.priority - b.priority)
                ?.map((item: any, index: number) => (
                  <Draggable key={`table_${item.id}`} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <LeadCampaignTable
                        inboundConcierge={true}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TableText>
                          <img
                            style={{
                              marginRight: "16px",
                              cursor: "pointer",
                              // fill the svg with the color red
                            }}
                            src={iconNewMoveRowPrimary}
                            height="10px"
                            alt="Drag me"
                          />

                          {item.priority + 1}
                        </TableText>
                        <TableText>{item.name}</TableText>
                        <TableText>
                          {!!item.updated_at && moment(item.updated_at).format("MM/DD/YYYY hh:mmA")}
                        </TableText>
                        <TableDiv>
                          <Switch
                            onChange={(checked) => {
                              updateRoutingRuleStatus({
                                variables: {
                                  rule_id: item.id,
                                  active: checked,
                                },
                              });
                              setDataRules(
                                dataRules?.map((item2: any) => {
                                  if (item2.id === item.id) {
                                    return {
                                      ...item2,
                                      active: checked,
                                    };
                                  }
                                  return item2;
                                }),
                              );
                            }}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            checked={item.active}
                            height={24}
                            width={40}
                            handleDiameter={18}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                        </TableDiv>
                        <TableDiv>
                          <Switch
                            onChange={(checked) => {
                              updateRoutingRuleApplyToInboundConciergeCallMeNow({
                                variables: {
                                  rule_id: item.id,
                                  applyToInboundConciergeCallMeNow: checked,
                                },
                              });

                              setDataRules(
                                dataRules?.map((item2: any) => {
                                  if (item2.id === item.id) {
                                    return {
                                      ...item2,
                                      applyToInboundConciergeCallMeNow: checked,
                                    };
                                  }
                                  return item2;
                                }),
                              );
                            }}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            checked={item.applyToInboundConciergeCallMeNow}
                            height={24}
                            width={40}
                            handleDiameter={18}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                        </TableDiv>
                        <TableDiv>
                          <Switch
                            onChange={(checked) => {
                              updateRoutingRuleApplyToInboundConciergeSchedule({
                                variables: {
                                  rule_id: item.id,
                                  applyToInboundConciergeSchedule: checked,
                                },
                              });
                              setDataRules(
                                dataRules?.map((item2: any) => {
                                  if (item2.id === item.id) {
                                    return {
                                      ...item2,
                                      applyToInboundConciergeSchedule: checked,
                                    };
                                  }
                                  return item2;
                                }),
                              );
                            }}
                            onColor={theme.PRIMARY500}
                            offColor={theme.NEUTRAL200}
                            checked={item.applyToInboundConciergeSchedule}
                            height={24}
                            width={40}
                            handleDiameter={18}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                        </TableDiv>
                        <TableText>
                          <span
                            style={{
                              color: theme.PRIMARY500,
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              !!togglePageTitle && togglePageTitle();

                              history.push(`${URL}/${item.id}`);
                            }}
                          >
                            Edit
                          </span>
                        </TableText>
                      </LeadCampaignTable>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <DisappearingDiv>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          id: selectedRule?.id,
          created_at: selectedRule?.created_at,
          updated_at: selectedRule?.updated_at ?? "",
          name: selectedRule?.name ?? "",
          priority: selectedRule?.priority ? selectedRule?.priority + 1 : defaultRuleTemplate.priority + 1,
          active: selectedRule?.active ?? false,
          distribution_method: selectedRule?.distribution_method ?? "",
          rep_selection_method: selectedRule?.rep_selection_method ?? "",
          conditions: selectedRule?.conditions ?? [],
          rep_ids: selectedRule?.rep_ids ? selectedRule?.rep_ids.filter((id) => isRepValid(id)) : [],
          email_updates: selectedRule?.email_updates ?? false,
          emails_list: selectedRule?.emails_list ?? defaultRuleTemplate.emails_list,
          deferral_date_time: selectedRule?.deferral_date_time ?? "",
          lead_creation_status: selectedRule?.lead_creation_status ?? "",
          los_rules: selectedRule?.los_rules ?? [],
          available_fields_search: "",
        }}
        validationSchema={yupRoutingRules}
        onSubmit={async (values) => {
          if (values.email_updates !== selectedRule?.email_updates) {
            await setLeadRoutingEmailToggle({
              variables: {
                send_lead_routing_email: values.email_updates,
              },
            });
          }

          if ([...values?.emails_list].sort().toString() !== [...(selectedRule?.emails_list || [])].sort().toString()) {
            await setLeadRoutingEmailList({
              variables: {
                lead_routing_email_list: values.emails_list,
              },
            });
          }

          await createOrUpdateLeadRoutingRule({
            variables: {
              rule_id: rule_id === "new" ? undefined : rule_id,
              name: values.name || "Untitled Rule",
              conditions: values.conditions,
              rep_ids: values.rep_ids,
              distribution_method: values.distribution_method,
              rep_selection_method: values.rep_selection_method,
              deferral_date_time: values.deferral_date_time || undefined,
              lead_creation_status: values.lead_creation_status,
              los_rules: values.los_rules,
              rule_type: rule === "lead" ? "Routing" : rule === "widget" ? "Widget" : "Routing",
            },
          });

          history.push(`/system-config/${URL}`);
        }}
      >
        {({ submitForm, values, setFieldValue, errors, isValid }: FormikProps<MyFormikProps>) => {
          const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            setFieldValue("rep_ids", result);
          };

          const onDragEndReps = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            reorderReps(values.rep_ids, result.source.index, result.destination.index);
          };

          const handleChangeEmails = (newValue: any, actionMeta: any) => {
            const emailsList = newValue?.map((item: any) => item.value);
            setFieldValue("emails_list", emailsList);
            console.group("Value Changed");
            console.log(newValue);
            console.log(`action: ${actionMeta.action}`);
            console.groupEnd();
          };

          const renderCadenceSelect = ({
            currentCondition,
            index,
            date_cadence,
          }: {
            currentCondition: ICondition;
            index: number;
            date_cadence: { label: string; value: string }[];
          }) => {
            const fieldType = findTypeFromFieldKey(currentCondition.object, currentCondition?.field);
            const dateTypeCondition = fieldType === "Date" || fieldType === "DateTime";

            const operatorRequiresCadence = ["InTheNext", "SinceTheLast"]?.includes(currentCondition?.operator || "");

            if (dateTypeCondition && operatorRequiresCadence) {
              return (
                <div>
                  <InputLabel>Cadence</InputLabel>
                  <PhoenixMultiSelect
                    name={`conditions[${index}]?.cadence`}
                    isMulti={false}
                    isClearable={false}
                    options={date_cadence}
                    value={{
                      value: values?.conditions[index]?.cadence,
                      label: date_cadence.find((item: any) => item.value === values?.conditions[index]?.cadence)?.label,
                    }}
                    onChange={(value: any) => {
                      setFieldValue(`conditions[${index}].cadence`, value?.value);
                    }}
                  />
                </div>
              );
            } else {
              return null;
            }
          };
          const renderValueSelect = ({ currentCondition, index }: { currentCondition: ICondition; index: number }) => {
            // for Booleons there can be only 1 type of input regardless of the field

            // date checks

            const dateCondition = findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "Date";

            const operatorRequiresCadence = ["InTheNext", "SinceTheLast"].includes(currentCondition?.operator || "");

            const handleDateValueArrowClick = (direction: 1 | -1) => {
              // check if value would be negative
              if (direction === -1 && parseInt(currentCondition.value || "1") <= 1) {
                appToast("Value must be a positive number");
                return;
              }

              setFieldValue("conditions", [
                ...values.conditions?.map((item: ICondition, i: number) =>
                  i !== index
                    ? item
                    : {
                        ...currentCondition,
                        value: ((parseInt(currentCondition.value) || 0) + direction).toString(),
                      },
                ),
              ]);
            };

            const handleDateValueChange = (e: any) => {
              const parsedValue = parseInt(e?.target?.value);

              if (parsedValue <= 0 || isNaN(parsedValue)) {
                setFieldValue("conditions", [
                  ...values.conditions?.map((item: ICondition, i: number) =>
                    i !== index
                      ? item
                      : {
                          ...currentCondition,
                          value: "",
                        },
                  ),
                ]);
                return;
              }

              setFieldValue("conditions", [
                ...values.conditions?.map((item: ICondition, i: number) =>
                  i !== index
                    ? item
                    : {
                        ...currentCondition,
                        value: parsedValue.toString(),
                      },
                ),
              ]);
            };

            // for Null and Not Null there can never be any input
            if (
              currentCondition.operator === "IsNull" ||
              currentCondition.operator === "NotNull" ||
              !currentCondition.operator
            ) {
              return (
                <div
                  style={{
                    width: "100%",
                  }}
                ></div>
              );
            }

            if (dateCondition && !operatorRequiresCadence) {
              return (
                <DateTimePickerWrapper>
                  <DateTimePicker
                    format="MM/dd/yyyy"
                    // no clock
                    disableClock={true}
                    onChange={(date) => {
                      setFieldValue(`conditions[${index}].value`, date);
                    }}
                    value={currentCondition.value ? new Date(currentCondition.value) : undefined}
                  />
                </DateTimePickerWrapper>
              );
            }

            if (dateCondition && !operatorRequiresCadence) {
              return (
                <DateTimePickerWrapper>
                  <DateTimePicker
                    format="MM/dd/yyyy"
                    // no clock
                    disableClock={true}
                    onChange={(date) => {
                      setFieldValue(`conditions[${index}].value`, date);
                    }}
                    value={currentCondition.value ? new Date(currentCondition.value) : undefined}
                  />
                </DateTimePickerWrapper>
              );
            }

            if (dateCondition && operatorRequiresCadence) {
              return (
                <PhoenixInput
                  inputValueType="number"
                  value={
                    typeof currentCondition.value === "string"
                      ? parseInt(currentCondition.value)
                      : currentCondition.value
                  }
                  showNumberArrows={true}
                  handleNumberArrowDownClick={() => handleDateValueArrowClick(-1)}
                  handleNumberArrowUpClick={() => handleDateValueArrowClick(1)}
                  onChange={handleDateValueChange}
                />
              );
            }

            if (
              !!currentCondition.field &&
              findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "Boolean"
            ) {
              return (
                <RulesSelect
                  textAlign="left"
                  placeholder="Select one"
                  allowSelectPlaceholder
                  name={`conditions[${index}].value`}
                  options={[
                    { label: "True", value: "true" },
                    { label: "False", value: "false" },
                  ]}
                />
              );
            }

            // start of the logic based on the field type

            // for dropdows or multidropdowns there can be 2 types of inputs depending on the operator

            if (
              (!!currentCondition.field &&
                findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "Dropdown") || // if the field is a multi
              findTypeFromFieldKey(currentCondition.object, currentCondition.field) === "MultiDropdown"
            ) {
              switch (currentCondition.operator) {
                // this is different from the text field which is a normal input not a select
                case "Equal":
                case "NotEqual":
                  return (
                    <RulesSelect
                      textAlign="left"
                      placeholder="Select one"
                      allowSelectPlaceholder
                      name={`conditions[${index}].value`}
                      options={findDropdownOptionsFromField(currentCondition.object, currentCondition.field)}
                    />
                  );

                default:
                  return (
                    <CreatableSelect
                      isMulti={true}
                      textAlign="left"
                      placeholder="Select two or more"
                      allowSelectPlaceholder
                      options={findDropdownOptionsFromField(currentCondition.object, currentCondition.field)}
                      onChange={(newValue: any, actionMeta: any) => {
                        const emailsList = newValue?.map((item: any) => item.value);
                        setFieldValue(`conditions[${index}].value`, JSON.stringify(emailsList));
                      }}
                      value={findDropdownOptionsFromField(currentCondition.object, currentCondition.field).filter(
                        (option: any) => currentCondition.value && currentCondition.value.includes(option.value),
                      )}
                    />
                  );
              }
            }
            // the default for the rest of the field types is the the same currently but this should be changed in the future by adding more cases above
            // this is applied currently applied to Text, List, Number, and Date field types

            switch (currentCondition.operator) {
              case "In":
              case "NotIn":
                return (
                  <>
                    <CreatableSelect
                      isMulti={true}
                      onChange={(newValue: any, actionMeta: any) => {
                        const emailsList = newValue?.map((item: any) => item.value);
                        setFieldValue("conditions", [
                          ...values.conditions.slice()?.map((item, i) =>
                            i !== index
                              ? item
                              : {
                                  ...item,
                                  value: JSON.stringify(emailsList),
                                },
                          ),
                        ]);
                      }}
                      placeholder="Enter multiple options..."
                      defaultValue={
                        testForArrayFormatedAsAString(currentCondition.value || "")
                          ? JSON.parse(currentCondition?.value || "")?.map((item: string) => ({
                              label: item,
                              value: item,
                            }))
                          : []
                      }
                    />
                  </>
                );
              default:
                // unlike the dropdown and multi dropdown the Equal and NotEqual operators for text fields are standard inputs not selects
                return (
                  <PhoenixInput
                    value={currentCondition.value}
                    onChange={(e: any) => {
                      setFieldValue("conditions", [
                        ...values.conditions.slice()?.map((item, i) =>
                          i !== index
                            ? item
                            : {
                                field: currentCondition.field,
                                operator: currentCondition.operator,
                                value: e.target.value,
                                type: currentCondition.type,
                                field_label: currentCondition.field_label,
                                object: currentCondition.object,
                              },
                        ),
                      ]);
                    }}
                  />
                );
            }
          };

          return (
            <>
              {deleteModal && (
                <DeleteRoutingRuleModal
                  blinds={deleteModal}
                  setBlinds={setDeleteModal}
                  loadingDelete={loadingRemoveRule}
                  deleteFunction={async () => {
                    await removeRoutingRule({
                      variables: {
                        rule_id: values.id,
                      },
                    });
                  }}
                />
              )}
              <TopAlignDiv>
                {!!rule_id ? (
                  <RuleEdit
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    isValid={isValid}
                    submitForm={submitForm}
                    renderValueSelect={renderValueSelect}
                    renderCadenceSelect={renderCadenceSelect}
                    handleChangeEmails={handleChangeEmails}
                    onDragEndReps={onDragEndReps}
                    RULE_OPTIONS_WHEN_EDITING={RULE_OPTIONS_WHEN_EDITING}
                  />
                ) : (
                  <>
                    {!!rule_title && <AppTitleWithSpacing>{rule_title}</AppTitleWithSpacing>}
                    <AppSidebarCard title={rule_subtitle}>
                      <>
                        {rule === "widget" && (
                          <WidgetCampaignHeader>
                            <TableHeadingText>Rule Name</TableHeadingText>
                            <TableHeadingText>Modified</TableHeadingText>
                            <TableHeadingText>Active</TableHeadingText>
                            <TableHeadingText>Manage</TableHeadingText>
                          </WidgetCampaignHeader>
                        )}
                        {rule === "lead" && (
                          <LeadCampaignHeader inboundConcierge={true}>
                            <TableHeadingText>Priority Order </TableHeadingText>
                            <TableHeadingText>Rule Name</TableHeadingText>
                            <TableHeadingText>Last Modified </TableHeadingText>
                            <TableHeadingText title={"Sellfire"}>Integration or CSV</TableHeadingText>
                            <TableHeadingText title={"Inbound Concierge"}>Call Me </TableHeadingText>
                            <TableHeadingText title={"Inbound Concierge"}>Schedule meeting</TableHeadingText>
                            <TableHeadingText>Actions</TableHeadingText>
                          </LeadCampaignHeader>
                        )}
                      </>
                      {loadingRules ? (
                        <Loading />
                      ) : errorRules ? (
                        <AppErrorText>Error loading rules</AppErrorText>
                      ) : !!dataRules ? (
                        <>
                          {rule === "widget" && <WidgetTable />}
                          {rule === "lead" && <LeadTable />}
                        </>
                      ) : (
                        <AppText style={{ padding: "32px" }}>
                          No rules have been added. Adding some will display them here.
                        </AppText>
                      )}

                      <BottomAddDiv>
                        <AddButton
                          variant={"primary"}
                          onClick={() => {
                            history.push(`${URL}/new`);
                          }}
                        >
                          {rule === "widget" ? "Add Criteria" : "Add Routing Rule"}
                        </AddButton>
                      </BottomAddDiv>
                    </AppSidebarCard>
                  </>
                )}
              </TopAlignDiv>
              {isOnboarding === true && (
                <CenterDiv>
                  <SaveAndContinueButton
                    type="submit"
                    onClick={() => {
                      !!step && history.push(`/onboarding?step=${step + 1}`);
                    }}
                  >
                    Continue
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </>
          );
        }}
      </Formik>
    </DisappearingDiv>
  );
};

const ValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
`;

const EmailsSwitchDiv = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  margin-top: 28px;
  margin-bottom: 20px;
  align-items: center;
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 48px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 99;
`;

interface SelectedProp {
  selected: boolean;
}

const Option = styled.div<SelectedProp>`
  display: flex;
  font-weight: ${(props) => (props.selected ? 600 : "normal")};
  align-items: center;
  height: 40px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const AppTitleWithSpacing = styled(AppTitle2)`
  margin-bottom: 16px;
`;

const ContainerDivSort = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RepDraggableDiv = styled.div`
  display: flex;
`;

const DragElements = styled.div`
  max-height: 208px;
  min-height: 208px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 330px 40px 330px;
  grid-gap: 16px;
  margin-bottom: 40px;
`;

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RepsScrollDiv = styled.div`
  max-height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchRepsInput = styled(InputField)`
  background-color: ${theme.NEUTRAL100};
  margin: 0px;
  /* margin-top: 16px; */
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

const RepsListContainer = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  height: 240px;
  overflow: hidden;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 12px;
  border-radius: 2px;
`;

const SelectRepsContainer = styled.div`
  width: 330px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 40px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    accent-color: ${theme.PRIMARY500};
  }
`;

interface SelectedProp {
  selected: boolean;
}

const SaveButton = styled(NewAppButton)`
  height: 48px;
  width: 120px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
`;

const DeleteButton = styled(NewAppButton)`
  height: 48px;
  width: 120px;
  margin-right: 12px;
  font-weight: 500;
  border: 1px solid ${theme.NEUTRAL200};
  color: ${theme.ATTENTION700};

  :hover {
    color: ${theme.ATTENTION700};
    border: 1px solid ${theme.ATTENTION500};
    background-color: ${theme.ATTENTION100};
  }
`;

const ButtonsDiv2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 52px;
`;
const TableText = styled(AppText)<IOpacity>`
  font-size: 12px;
  opacity: ${(props) => (props.translucent ? 0.5 : 1)};
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
`;
const OperationBox = styled(AppText)`
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: ${theme.NEUTRAL400};
  padding: 12px 18px;
  border: 1px solid ${theme.NEUTRAL400};
  border-radius: 99px;
  margin-top: 16px;
  /* margin-bottom: 16px; */
  margin-left: 521px;
  background-color: ${theme.WHITE_COLOR};
`;

const CenteredTextAndIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const AddConditionButton = styled(NewAppButton)`
  text-transform: uppercase;
  margin-bottom: 32px;
  letter-spacing: 1px;
  width: max-content;
  height: 48px;
  font-size: 10px;
  /* margin-top: 35px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  height: 40px;
  width: 140px;
  margin-bottom: 32px; */
`;

const RulesSelect = styled(FormSelectField)`
  background-color: ${theme.WHITE_COLOR};
`;

const EditSpan = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
`;

const CloseDiv = styled.div`
  position: relative;
  top: 50%;
  translate: 0 -37%;
  cursor: pointer;
  height: 40px;
  border: 1px solid ${theme.NEUTRAL200};
  padding: 8px;
  border-radius: 4px;
`;

const CriteriaGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 42px 200px;
  grid-gap: 8px;
  /* align-items: center; */
`;

const ConditionDiv = styled.div`
  display: flex;
  padding: 20px;
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 2px;
  margin: 10px 0px;
  width: fit-content;
  position: relative;
`;
const ConditionTitle = styled(AppText)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.NEUTRAL500};
`;
interface GrayProp {
  gray?: boolean;
}

const RuleInput = styled(InputField)<GrayProp>`
  margin: 0px;
  width: 330px;
  height: 40px;
  background-color: ${(props) => (props.gray ? "transparent" : theme.WHITE_COLOR)};
`;

const InputLabel = styled(AppText)`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
`;

const SectionTitle = styled(AppText)`
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */

  display: flex;
  align-items: center;
`;

const BackButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 340px;
  margin-bottom: 24px;
`;

const AddButton = styled(NewAppButton)`
  height: 40px;
  width: 150px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
`;

const BottomAddDiv = styled.div`
  padding: 24px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

interface IOpacity {
  translucent?: boolean;
}

const TableDiv = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 8px;
  width: 100%;
`;
const TableHeadingMainText = styled(AppText)`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  padding-left: 11px;
  padding-top: 11px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-color: ${theme.NEUTRAL200};
  border: 1px solid ${theme.NEUTRAL200};
  :last-child {
    border-right: none;
  }
`;

const DateTimePickerWrapper = styled.div`
  .react-datetime-picker {
    height: 40px;
    * {
      /* color: ${theme.PRIMARY600} !important; */
    }
    /* Set the width of the date picker */
    /* border: 1px solid ${theme.PRIMARY600}; */
    border: none;
  }
`;

const TableHeadingTitleText = styled(AppText)`
  position: absolute;
  top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #949ab1;
`;

interface TableHeadingProps {
  title?: string;
  children?: any;
}

const TableHeadingText = (props: TableHeadingProps) => {
  return (
    <TableHeadingMainText>
      {props.children}
      <TableHeadingTitleText>{props.title}</TableHeadingTitleText>
    </TableHeadingMainText>
  );
};

const WidgetCampaignTable = styled.div`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  grid-template-columns: 1fr 200px 147px 147px;
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const WidgetCampaignHeader = styled.div`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  grid-template-columns: 1fr 200px 147px 147px;
  grid-template-rows: 50px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const LeadCampaignTable = styled.div.attrs(({ inboundConcierge }: { inboundConcierge: boolean }) => ({
  inboundConcierge: inboundConcierge,
}))`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  /* grid-template-columns: 118px 1fr 162px 150px 125px 137px 88px; */
  grid-template-columns: ${(props) =>
    props.inboundConcierge ? "118px 1fr 162px 150px 125px 137px 88px" : "118px 1fr 162px 150px 88px"};
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;

const LeadCampaignHeader = styled.div.attrs(({ inboundConcierge }: { inboundConcierge: boolean }) => ({
  inboundConcierge: inboundConcierge,
}))`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;
  /* grid-template-columns: 118px 1fr 162px 150px 125px 137px 88px; */
  grid-template-columns: ${(props) =>
    props.inboundConcierge ? "118px 1fr 162px 150px 125px 137px 88px" : "118px 1fr 162px 150px 88px"};

  grid-template-rows: 55px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  /* margin-left: 1px; */
  background-color: ${theme.WHITE_COLOR};
`;
const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  width: 100%;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

enum ResetObjectType {
  Object = "object",
  Field = "field",
  Operator = "operator",
  Value = "value",
  Type = "type",
}
export { RulesTable };
