import React from "react";
import { theme } from "../../../utils/theme";
import { FlexDiv } from "../FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "../Phoenix";
import { AppText } from "../AppText";

interface StepActionButton {
  icon: any;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const StepActionButton: React.FC<StepActionButton> = ({ icon, text, onClick, disabled, ...props }) => {
  return (
    <PhoenixAppButton
      variant="brand-outline"
      buttonType="primary"
      borderColor={theme.NEUTRAL200}
      borderRadius={4}
      padding="8px"
      width={208}
      height={40}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <FlexDiv align="center" gap={8}>
        <PhoenixIcon svg={icon} hoverColor={theme.PRIMARY500} size="small" pointer />
        <AppText fontSize={12}>{text}</AppText>
      </FlexDiv>
    </PhoenixAppButton>
  );
};
