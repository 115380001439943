import styled from "styled-components";
import * as React from "react";
import { useState, useEffect } from "react";
import { AppButton, AppText, AppModalPriorityPopup, Loading, AppErrorText, AppSidebarCard } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../Field";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";

interface Lead {
  id: string;
  business_name: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry_label: string;
  lead_source_label: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

interface ConfirmJoinConferenceProps {
  showConfirmJoinConference: boolean;
  closeConfirmJoinConference: () => void;
  joinCall: () => void;
  salesperson: string;
  lead_data: Lead;
}

const ConfirmJoinConferenceModal: React.FC<ConfirmJoinConferenceProps> = ({
  showConfirmJoinConference,
  closeConfirmJoinConference,
  joinCall,
  salesperson,
  lead_data,
}) => {
  return (
    <ModalContainer>
      <ModalTitle>Join Conference Call</ModalTitle>
      <ModalSubTitle>You are about to join this call (not whisper) with:</ModalSubTitle>
      <InfoDiv>
        <InfoSegment>
          <InfoTitle>Sales Rep</InfoTitle>
          <InfoText>{salesperson}</InfoText>
        </InfoSegment>
        <InfoSegment>
          <InfoTitle>Contact Name</InfoTitle>
          <InfoText>{`${lead_data.first_name} ${lead_data.last_name}`}</InfoText>
        </InfoSegment>
      </InfoDiv>
      <JoinCallButton
        onClick={() => {
          joinCall();
          closeConfirmJoinConference();
        }}
      >
        Join Call
      </JoinCallButton>
      <CancelButton onClick={closeConfirmJoinConference}>Cancel</CancelButton>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 25%;
  left: 35%;
  width: 600px;
  height: 450px;
  border-radius: 7px;
  background: ${theme.WHITE_COLOR};
  border: solid 4px #f4f4f4;
  overflow: hidden;
  z-index: 4;
  padding: 35px 65px;
`;

const ModalTitle = styled(AppText)`
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 22px;
`;

const ModalSubTitle = styled(AppText)`
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 26px;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 460px;
  height: 155px;
  margin: 0 0 26px;
  padding: 20px 161px 24px;
  border: solid 1px #ddddde;
  margin-bottom: 26px;
`;

const InfoSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoTitle = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 5px;
`;

const InfoText = styled(AppText)`
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const JoinCallButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  padding: 14px 103px;
  border-radius: 2px;
  background-color: ${theme.PRIMARY500};
  font-size: 14px;
  margin-bottom: 18px;
`;

const CancelButton = styled(AppButton)`
  width: 206px;
  height: 45px;
  padding: 14px 79px;
  border-radius: 2px;
  font-size: 14px;
  background-color: ${theme.BLACK_COLOR};
`;

export { ConfirmJoinConferenceModal };
