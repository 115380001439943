import styled from "styled-components";
import { theme } from "../../utils/theme";

interface AppButtonProps {
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number;
  height?: number;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "attention" | "confirm" | "newDesignSecondary";
  hoverVariant?: "primary" | "secondary" | "attention" | "confirm";
  size?: "sm" | "md" | "lg";
  selected?: boolean;
}

export const NewAppButton = styled.button<AppButtonProps>`
  padding: 0px 16px 0px 16px;
  font-family: ${theme.PRIMARY_FONT};
  border-radius: 8px;
  height: ${(props) => props.height}px;
  font-size: 12px;
  transition: all 0.15s ease-in-out;
  ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
      background-color: ${props.disabled ? theme.PRIMARY200 : props.selected ? theme.PRIMARY700 : theme.PRIMARY500};
        color: ${theme.WHITE_COLOR};
        border:  ${
          props.disabled
            ? `1px solid ${theme.PRIMARY200}`
            : props.selected
            ? `1px solid ${theme.PRIMARY700}`
            : `1px solid ${theme.PRIMARY500}`
        };
      `;
      case "secondary":
        return `
      background-color: ${props.disabled ? theme.WHITE_COLOR : props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR};
      color:  ${props.disabled ? theme.NEUTRAL200 : props.selected ? theme.WHITE_COLOR : theme.PRIMARY500};
      border:  ${
        props.disabled
          ? `1px solid ${theme.NEUTRAL200}`
          : props.selected
          ? `1px solid ${theme.PRIMARY500}`
          : `1px solid ${theme.NEUTRAL200}`
      };
    `;

      case "newDesignSecondary":
        return `
    background-color: ${props.disabled ? theme.NEUTRAL100 : props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR};
    color:  ${props.disabled ? theme.NEUTRAL200 : props.selected ? theme.WHITE_COLOR : theme.PRIMARY500};
    border:  ${props.disabled ? `1px solid ${theme.NEUTRAL200}` : `1px solid ${theme.PRIMARY500}`}  ;
  `;

      case "attention":
        return `
      background-color: ${props.selected ? theme.ATTENTION700 : theme.WHITE_COLOR};
      color:  ${props.disabled ? theme.ATTENTION100 : props.selected ? theme.WHITE_COLOR : theme.ATTENTION700};
      border:  ${props.selected ? `1px solid ${theme.ATTENTION700}` : `1px solid ${theme.NEUTRAL200}`};
    `;
      case "confirm":
        return `
        background-color: ${props.disabled ? theme.SUCCESS100 : theme.SUCCESS500};
        color: ${props.disabled ? theme.NEUTRAL300 : theme.WHITE_COLOR};
        border: ${props.disabled ? `1px solid ${theme.SUCCESS100}` : `1px solid ${theme.SUCCESS500}`};
    `;
      default:
        return `
      background-color: transparent;
      color: ${theme.NEUTRAL300};
      border:  transparent;
    `;
    }
  }};

  ${(props) => {
    if (props.width) {
      return `
      width:${props.width}px;
      `;
    } else {
      switch (props.size) {
        case "sm":
          return `
      width:33%;
      `;
        case "md":
          return `
      width:50%;
    `;
        case "lg":
          return `
      width:66%;
    `;
        default:
          return `
      width:100%;
    `;
      }
    }
  }}
  font-weight: 600;

  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    ${(props) => {
      if (!props.disabled && !props.selected) {
        switch (!!props.hoverVariant ? props.hoverVariant : props.variant) {
          case "primary":
            return `
        background-color: ${theme.PRIMARY600};
        color: ${theme.WHITE_COLOR};
        border-color : ${theme.PRIMARY600};
      `;
          case "secondary":
            return `
        background-color: ${theme.PRIMARY100};
        color: ${theme.PRIMARY500};
        border-color :  ${theme.PRIMARY300};
    `;

          case "newDesignSecondary":
            return `
        background-color: ${theme.PRIMARY100};
        color: ${theme.PRIMARY500};
        border-color :  ${theme.PRIMARY300};
    `;
          case "attention":
            return `
        background-color: ${theme.ATTENTION100};
        color: ${theme.ATTENTION700};
        border-color : ${theme.ATTENTION700};
    `;
          case "confirm":
            return `
        background-color: ${theme.SUCCESS700};
        color: ${theme.WHITE_COLOR};
        border-color : ${theme.SUCCESS700};
    `;
          default:
            return `
        background-color: transparent;
        color: ${theme.PRIMARY500};
        border:  transparent;
    `;
        }
      }
    }};
  }
`;

NewAppButton.defaultProps = {
  height: 40,
  disabled: false,
};
