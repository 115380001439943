import { gql, useMutation } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context/ModalContext";
import { SYSTEM_FIELD_CHAR_LIMIT } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast, successToast } from "../../utils/toast";
import { PhoenixInputField } from "../Field/Phoenix";
import { AppText } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
}

const ADD_DISPOSITION_REASON_FIELD = gql`
  mutation createDispositionReason($disposition_type: DispositionGroup!, $reason: String!) {
    createDispositionReason(disposition_type: $disposition_type, reason: $reason) {
      id
    }
  }
`;

const CreateDispositionReasonModal: React.FC<DisappearingDivProps> = ({ blinds, close }) => {
  interface MyFormikProps {
    disposition_type: "Not Interested" | "Not Qualified";
    reason: string;
  }

  const { dispositionReasonModalData } = useContext(ModalContext);

  const DispositionReasonSchema = Yup.object().shape({
    reason: Yup.string()
      .required("Field required!")
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    disposition_type: Yup.string().required("Field required!"),
  });

  const [addDispositionReason, { loading: addLoading, error: addError }] = useMutation(ADD_DISPOSITION_REASON_FIELD, {
    onCompleted({ addDispositionReason }) {
      console.log("Add disposition reason: ", addDispositionReason);
      successToast(`Reason added`);
      close();
    },
    onError({ message }) {
      appToast(message);
      console.log("Error in addDispositionReason: ", message);
    },
    fetchPolicy: "no-cache",
    refetchQueries: ["fetchDispositionReasons"],
  });

  const mapDispositionToExpectedBEType = (dispositionType: string) => {
    switch (dispositionType) {
      case "Not Interested":
        return "NOT_INTERESTED";
      case "Not Qualified":
        return "NOT_QUALIFIED";
      default:
        return dispositionType;
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        disposition_type: dispositionReasonModalData.disposition_type,
        reason: "",
      }}
      validationSchema={DispositionReasonSchema}
      onSubmit={async (values) => {
        await addDispositionReason({
          variables: {
            disposition_type: mapDispositionToExpectedBEType(values.disposition_type),
            reason: values.reason,
          },
        });
      }}
    >
      {({ submitForm, isSubmitting, isValid, dirty, errors, values }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close}>
            <ModalContent>
              <HeaderDiv>
                <AppText fontSize={16} fontWeight={600}>
                  Add {dispositionReasonModalData.disposition_type} Reason
                </AppText>
              </HeaderDiv>
              <MainDiv>
                <PopupInputLabel>
                  {dispositionReasonModalData.disposition_type} Reason<span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <PhoenixInputField name="reason" />
              </MainDiv>
              <SubmitDiv>
                <PhoenixAppButton type="button" onClick={close} buttonType="secondary" variant="danger-outline">
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton
                  type="submit"
                  onClick={submitForm}
                  buttonType="secondary"
                  variant="brand"
                  disabled={addLoading || !isValid || isSubmitting}
                >
                  Save
                </PhoenixAppButton>
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const ModalContent = styled.div`
  width: 456px;
  height: 326px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const HeaderDiv = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;
const SubmitDiv = styled.div`
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${theme.border.neutral.secondary};
  background-color: ${theme.surface.neutral.primary};
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const MainDiv = styled.div`
  padding: 0px 40px 40px 40px;
  overflow: auto;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { CreateDispositionReasonModal };
