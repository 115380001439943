import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "./AppText";
import * as React from "react";
import { FiPhoneCall, FiMail } from "react-icons/fi";

interface AppModalPriorityPopupProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  priorityPopup: boolean;
  setPriorityPopup: (blinds: boolean) => void;
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number;
  /**
   * height of button. Default value is 50.
   */
  height?: number;
  secondary?: boolean;
  cancel?: boolean;
  textcolor?: string;
  unbordered?: boolean;
  stickBottom?: boolean;
}

export const AppModalPriorityPopup = ({
  blinds,
  setBlinds,
  priorityPopup,
  setPriorityPopup,
  title,
  width,
  height,
  color,
  unbordered,
  textcolor,
  children,
  stickBottom,
  ...props
}: AppModalPriorityPopupProps & React.HTMLProps<HTMLDivElement>) => {
  return (
    <>
      {blinds && priorityPopup && (
        <Main
          onClick={() => {
            setPriorityPopup(!priorityPopup);
          }}
        >
          <ModalContainer>
            <PopupModal
              width={width}
              height={height}
              stickBottom={stickBottom}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              {children}
            </PopupModal>
          </ModalContainer>
        </Main>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: relative;
  display: block;
  min-width: 100%;
  min-height: 100%;
`;

const Main = styled.div`
  display: block;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(22, 21, 26, 0.5);
  z-index: 2;
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
  height?: number;
}

const PopupModal = styled.div<ModalProps>`
  position: absolute;
  bottom: ${(props) => (props.stickBottom ? 0 : "none")};
  left: ${(props) => (!!props.width ? `calc((100vw - ${props.width}px) / 2)` : "calc((100vw - 453px) / 2)")};
  top: ${(props) => (!!props.height ? `calc((100vh - ${props.height}px) / 2)` : "104px")};
  width: ${(props) => (!!props.width ? `${props.width}px` : "453px")};
  border-radius: 7px;
  height: auto;
  background: ${theme.WHITE_COLOR};
  border: 4px solid ${theme.PRIMARY500};
  z-index: 5;
`;

AppModalPriorityPopup.defaultProps = {
  secondary: false,
  unbordered: false,
  stickBottom: false,
};
