import React from "react";
import styled from "styled-components";
import { notification_dot } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";

interface PhoenixMenuButtonProps {
  children?: any;
  onClick?: () => void;
  id?: string;
  // overrides to default styling
  width?: number;
  height?: number;

  backgroundColor?: string;
  borderRadius?: number;

  //button states
  disabled?: boolean;
  selected?: boolean;
  notification?: boolean;
}

export const PhoenixMenuButton: React.FC<PhoenixMenuButtonProps> = ({
  width,
  height,
  backgroundColor,
  borderRadius,
  disabled,
  selected,
  notification,
  children,
  id,
  onClick,
}) => {
  return (
    <Button
      id={id}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      disabled={disabled}
      selected={selected}
      notification={notification}
      onClick={onClick}
    >
      {notification && <NotificationDot src={notification_dot} />}
      {children}
    </Button>
  );
};

const NotificationDot = styled.img`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const Button = styled.button<PhoenixMenuButtonProps>`
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: ${(props) => (props.width ? `${props.width}px` : "45px")};
  height: ${(props) => (props.height ? `${props.height}px` : "45px")};
  background: ${(props) => (props.selected ? theme.GRADIENT : theme.PRIMARY900)};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "8px")};
  border: none;

  :hover {
    cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
    background-color: ${(props) => !props.disabled && theme.PRIMARY800};
  }

  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 1px;
  }
`;
