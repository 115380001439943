import styled from "styled-components";
import { theme } from "../../utils/theme";

interface AppButtonProps {
  selected?: boolean;
  height?: number;
  disabled?: boolean;
  variant?: "primary" | "secondary";
}
/* height: ${(props) => props.height}px; */

/* background-color: ${(props) => (props.selected ? theme.PRIMARY700 : "transparent")};
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background-color: ${theme.PRIMARY700};
  } */

//TODO:    color of side nav needs to be changed TO MATCH
export const AppIconButton = styled.button<AppButtonProps>`
  padding: 14px;
  font-family: ${theme.PRIMARY_FONT};
  border-radius: 8px;
  /* font-size: 12px; */
  ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
        background-color: ${theme.PRIMARY800};
        color: ${theme.WHITE_COLOR};
        border: 1px solid ${theme.PRIMARY800};
        background: ${props.selected && theme.GRADIENT}
      `;
      case "secondary":
        return `
        background-color: ${theme.WHITE_COLOR};
        color: ${props.selected ? theme.WHITE_COLOR : theme.BLACK_COLOR};
        border: 1px solid ${theme.NEUTRAL200};
        background: ${props.selected && theme.PRIMARY500}
      `;
      default:
        return `
        background-color: transparent;
        color: ${theme.PRIMARY500};
        border:  transparent;
    `;
    }
  }};

  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    ${(props) => {
      if (!(props.disabled || props.selected)) {
        switch (props.variant) {
          case "primary":
            return `
        background-color:   ${theme.PRIMARY900};
        color: ${theme.WHITE_COLOR};
        border: 1px solid ${theme.PRIMARY900}}
      `;
          case "secondary":
            return `
        background-color:   ${theme.PRIMARY500};
        color: ${theme.WHITE_COLOR};
        border: 1px solid ${theme.PRIMARY500}}
      `;
          default:
            return `
        background-color: transparent;
        color: ${theme.PRIMARY500};
        border:  transparent;
    `;
        }
      }
    }};
`;

AppIconButton.defaultProps = {
  selected: false,
};
