import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { useFormikContext } from "formik";
import { gql, useQuery } from "@apollo/client";
import { AppText, AppErrorText, Loading, FlexDiv } from "src/Components/UI";
import { CallContext } from "src/context";
import { FormSelectField } from "src/Components/Field";
import { NewAppButton } from "src/Components/UI/NewAppButton";
import { SkeletonBlock } from "src/Components/UI/SkeletonBlock";
import { theme } from "src/utils/theme";
import { timezone_list_items } from "src/static";
import { PhoenixDatePicker, PhoenixIcon, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { chevron_left, chevron_right } from "src/images/NewDesign";
import RadioSelect from "../LeadsFilterV2/RadioSelect";
import "src/utils/react-big-calendar.css";

interface AvailableTimeItem {
  user_id: string;
  user_name: string;
  start_time: string;
  event_count: number;
  rep_ids: string[];
  rep_id: string;
}

interface DisappearingDivProps {
  user_id?: string;
  lead_id?: string;
  values: any;
  setFieldValue: any;
  errors?: any;
  selectedDataTime: any;
  dateValue: any;
  setDateValue: any;
  yearMonth: any;
  setYearMonth: any;
  specificOptionStep: any;
  setSpecificOptionStep: any;
  toggleCalendar: any;
  closeCalendar: any;
  isInCallRoute?: boolean;
  callOptions?: string;
  disableSelectTime?: boolean;
  disableGeneralTime?: boolean;
  events?: any;
  setAvailableRepsForBooking?: (reps: any) => void;
  setOpsiqSuggestedRep: (rep: any) => void;
}

const AVAILABLE_SPECIFIC_TIMES_V2 = gql`
  query fetchAvailableTimes($day: DateTime, $timezone: String, $action: String, $leadId: String) {
    fetchAvailableTimesV3(day: $day, timezone: $timezone, action: $action, lead_id: $leadId)
  }
`;

const MONTHLY_SCHEDULE = gql`
  query fetchScheduleItemsMonth($yearmonth: String) {
    fetchScheduleItemsMonth(yearmonth: $yearmonth)
  }
`;

const generalTimesDropdownOptions = new Array(24)
  .fill(moment("7:00 AM", "h:mm A"))
  ?.map((t, i) => moment(t).add(i * 30, "minutes"))
  ?.map((item) => ({ label: item.format("h:mm A"), value: `${item.toDate()}` }));

interface GeneralTimesComponentProps {
  isInCallRoute?: boolean;
  setFieldValue?: any;
  errors?: any;
}

const TimeZoneSelect: React.FC<GeneralTimesComponentProps> = ({ isInCallRoute }) => {
  const { values, setFieldValue } = useFormikContext<any>();

  if (isInCallRoute) {
    return (
      <PhoenixMultiSelect
        titleText="Select Timezone"
        required
        marginBottom={false}
        name="timezone"
        options={timezone_list_items}
        isMulti={false}
        isClearable={false}
        onChange={(option: any) => {
          setFieldValue("timezone", option.value);
        }}
        value={timezone_list_items.find((option: any) => option.value === values.timezone)}
      />
    );
  }
  return <></>;
};

const GeneralTimeDropdowns: React.FC<GeneralTimesComponentProps> = ({ isInCallRoute, setFieldValue }) => {
  const { values, setFieldTouched, errors } = useFormikContext<any>();
  const [preselectedRange, setPreselectedRange] = useState<string>("");

  const startTimeOptions = [...generalTimesDropdownOptions];
  const endTimeOptions = [
    ...generalTimesDropdownOptions?.map((i) => ({
      label: moment(i.label, "h:mm A").add(30, "minutes").format("h:mm A"),
      value: `${moment(i.value).add(30, "minutes")}`,
    })),
  ];

  const onMorningClick = () => {
    const morningStart = startTimeOptions.find(
      (option) => moment(option.label, "h:mm A").hour() === 9 && moment(option.label, "h:mm A").minute() === 0,
    );
    const morningEnd = endTimeOptions.find(
      (option) => moment(option.label, "h:mm A").hour() === 12 && moment(option.label, "h:mm A").minute() === 0,
    );

    setPreselectedRange("morning");

    if (morningStart && morningEnd) {
      setFieldValue("anytime_after", morningStart.value);

      setTimeout(() => {
        setFieldValue("anytime_before", morningEnd.value);
        setFieldTouched("anytime_before", true, true);
      }, 0);
    }
  };

  const onAfternoonClick = () => {
    const afternoonStart = startTimeOptions.find(
      (option) => moment(option.label, "h:mm A").hour() === 12 && moment(option.label, "h:mm A").minute() === 0,
    );
    const afternoonEnd = endTimeOptions.find(
      (option) => moment(option.label, "h:mm A").hour() === 17 && moment(option.label, "h:mm A").minute() === 0,
    );

    setPreselectedRange("afternoon");

    if (afternoonStart && afternoonEnd) {
      setFieldValue("anytime_after", afternoonStart.value);

      setTimeout(() => {
        setFieldValue("anytime_before", afternoonEnd.value);
        setFieldTouched("anytime_before", true, true);
      }, 0);
    }
  };

  return (
    <FlexDiv gap={32} direction="column">
      <TimeZoneSelect isInCallRoute={isInCallRoute} />

      <FlexDiv gap={8} direction="column">
        <TitleLabel>Time Range</TitleLabel>

        <ScrollSegment>
          <NewAppButton variant={"secondary"} selected={preselectedRange === "morning"} onClick={onMorningClick}>
            Morning
          </NewAppButton>

          <NewAppButton variant={"secondary"} selected={preselectedRange === "afternoon"} onClick={onAfternoonClick}>
            Afternoon
          </NewAppButton>
        </ScrollSegment>

        <FlexDiv gap={8} direction="row">
          <FlexDiv width="100%" direction="column">
            <PhoenixMultiSelect
              isMulti={false}
              isClearable={false}
              name="anytime_after"
              marginBottom={false}
              options={startTimeOptions}
              placeholder="Start Time"
              value={startTimeOptions.find((option) => option.value === values.anytime_after)}
              onChange={(option: any) => {
                setFieldValue("anytime_after", option.value);
                setPreselectedRange("");
              }}
            />
          </FlexDiv>

          <FlexDiv width="100%" direction="column">
            <PhoenixMultiSelect
              isMulti={false}
              isClearable={false}
              name="anytime_before"
              marginBottom={false}
              options={endTimeOptions}
              placeholder="End Time"
              value={endTimeOptions.find((option: any) => option.value === values.anytime_before)}
              onChange={(option: any) => {
                setFieldValue("anytime_before", option.value);
                setPreselectedRange("");
              }}
            />
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  );
};

const optionsTime = [
  {
    label: "Specific Time",
    value: "Specific Time",
  },
  {
    label: "General Time",
    value: "General Time",
  },
];

const optionsGeneralTime = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

// GENERAL TIMES: Helper function that generates the months in a given year. Input being number of years from today.
function createMonthsInYearText(num: number) {
  const currentYearMonths = new Array(12)
    .fill(null)
    ?.map((_, i) => moment().startOf("year").add(num, "years").add(i, "months"))
    .filter((item) => item.isAfter(moment().subtract(1, "months")))
    ?.map((item) => `${item.format("MMM")} ${moment().year() + num}`);

  return currentYearMonths;
}

// Function that returns the weekdays in a given month, with an input of number of months from today
function createMonthsWithDays(num: number) {
  const currentMonthDates = new Array(moment().add(num, "months").daysInMonth())
    .fill(null)
    ?.map((_, i) => moment().startOf("month").add(num, "months").add(i, "days"))
    .filter((item) => item.isAfter(moment().subtract(1, "days")) && item.isoWeekday() !== 6 && item.isoWeekday() !== 7);
  return currentMonthDates;
}

// Creates a range of weeks for each month, where the input is the number of months starting at the current month
function createMonthWithWeek(num: number) {
  const currentMonthWeeks = new Array(5)
    .fill(null)
    ?.map((_, i) => moment().add(num, "months").startOf("month").weekday(1).add(i, "weeks"))
    .filter((item) => item.isAfter(moment().subtract(1, "weeks")))
    .filter((item) =>
      item.isBefore(
        moment()
          .startOf("day")
          .add(num + 1, "months")
          .startOf("month"),
      ),
    );

  const dummyWeeks = new Array(currentMonthWeeks.length).fill(null)?.map((_, index) => ({
    label: `${currentMonthWeeks[index].calendar(null, {
      sameDay: "MMM DD",
      nextDay: "MMM DD",
      nextWeek: "MMM DD",
      lastDay: "MMM DD",
      lastWeek: "MMM DD",
      sameElse: "MMM DD",
    })} - ${currentMonthWeeks[index].add(6, "days").calendar(null, {
      sameDay: "MMM DD",
      nextDay: "MMM DD",
      nextWeek: "MMM DD",
      lastDay: "MMM DD",
      lastWeek: "MMM DD",
      sameElse: "MMM DD",
    })}`,
    value: moment(currentMonthWeeks[index]).format(),
  }));

  return dummyWeeks;
}

// This function creates an array of the months in a year (also checks that it is not in the past)
function createMonthsOfYear(num: number) {
  return new Array(12)
    .fill(null)
    ?.map((_, i) => moment().startOf("year").add(num, "years").add(i, "months"))
    .filter((item) => item.isAfter(moment().subtract(1, "months")))
    ?.map((item) => ({ label: item.format("MMMM"), value: item.format() }));
}

const DateNavigator = ({
  generalOptionStep,
  setGeneralOptionStep,
  label,
  maxSteps = 1,
}: {
  generalOptionStep: number;
  setGeneralOptionStep: (step: number) => void;
  label?: string;
  maxSteps?: number;
}) => {
  const canGoBack = generalOptionStep > 0;
  const canGoForward = generalOptionStep < maxSteps - 1;

  return (
    <FlexDiv align="center" gap={8} justify="space-between">
      <PhoenixIcon
        svg={chevron_left}
        alt="Previous"
        size={16}
        disabled={!canGoBack}
        onClick={() => canGoBack && setGeneralOptionStep(generalOptionStep - 1)}
      />
      <TimeSeekingText>{label}</TimeSeekingText>

      <PhoenixIcon
        svg={chevron_right}
        alt="Next"
        size={16}
        disabled={!canGoForward}
        onClick={() => canGoForward && setGeneralOptionStep(generalOptionStep + 1)}
      />
    </FlexDiv>
  );
};

const DateTimeSelector = ({
  generalOption,
  generalOptionStep,
  setGeneralOptionStep,
  setGeneralOption,

  setFieldValue,
  values,
  isInCallRoute,
  errors,
  dummyMonthsWithDays,
  dummyMonthsWithWeeks,
  dummyYears,
}: any) => {
  const [hasSelected, setHasSelected] = useState(false);

  const getNavigatorProps = () => {
    switch (generalOption) {
      case "day":
        return {
          label: dummyMonthsWithDays?.[generalOptionStep]?.month,
          maxSteps: dummyMonthsWithDays.length,
        };
      case "week":
        return {
          label: dummyMonthsWithWeeks?.[generalOptionStep]?.month,
          maxSteps: dummyMonthsWithWeeks.length,
        };
      case "month":
        return {
          label: dummyYears?.[generalOptionStep]?.year,
          maxSteps: dummyYears.length,
        };
    }
  };

  const renderOptions = () => {
    if (!generalOption || !["day", "week", "month"].includes(generalOption)) {
      return null;
    }

    const optionsMap = {
      day: {
        data: dummyMonthsWithDays?.[generalOptionStep]?.days,
        onClick: (item: any) => {
          setFieldValue("day", values.day === moment(item).format() ? "" : moment(item).format());
          setHasSelected(true);
        },
      },
      week: {
        data: dummyMonthsWithWeeks?.[generalOptionStep]?.weeks,
        onClick: (item: any) => {
          setFieldValue(
            "week_start",
            values.week_start === moment(item.value).subtract(6, "days").format()
              ? ""
              : moment(item.value).subtract(6, "days").format(),
          );
          setFieldValue("week_end", values.week_end === item.value ? "" : item.value);
          setHasSelected(true);
        },
      },
      month: {
        data: dummyYears?.[generalOptionStep]?.months,
        onClick: (item: any) => {
          setFieldValue("month", item.value);
          setHasSelected(true);
        },
      },
    };

    const { data, onClick } = optionsMap[generalOption as keyof typeof optionsMap];

    const isSelected = (item: any) => {
      switch (generalOption) {
        case "day":
          return values.day === moment(item).format();

        case "week":
          return (
            values.week_start === moment(item.value).subtract(6, "days").format() && values.week_end === item.value
          );

        case "month":
          return values.month === item.value;

        default:
          return false;
      }
    };

    return (
      <FlexDiv direction="column" gap={32}>
        <ScrollSegment>
          {data?.map((item: any, index: number) => (
            <NewAppButton variant={"secondary"} key={index} selected={isSelected(item)} onClick={() => onClick(item)}>
              {item.label ||
                item.calendar(null, {
                  sameDay: "([Today])",
                  nextDay: "([Tomorrow])",
                  nextWeek: "MM/DD/YYYY",
                  sameElse: "MM/DD/YYYY",
                })}
            </NewAppButton>
          ))}
        </ScrollSegment>

        {hasSelected ? (
          <GeneralTimeDropdowns isInCallRoute={isInCallRoute} setFieldValue={setFieldValue} errors={errors} />
        ) : null}
      </FlexDiv>
    );
  };

  return (
    <FlexDiv gap={32} direction="column">
      <FlexDiv gap={8} direction="column">
        <AppText fontSize={12}>Select a General Time</AppText>

        <RadioSelect
          direction="row"
          options={optionsGeneralTime}
          value={generalOption}
          onChange={(option) => setGeneralOption(option.value as string)}
        />
      </FlexDiv>

      {generalOption && (
        <FlexDiv gap={16} direction="column">
          <DateNavigator
            generalOptionStep={generalOptionStep}
            setGeneralOptionStep={setGeneralOptionStep}
            {...getNavigatorProps()}
          />
          {renderOptions()}
        </FlexDiv>
      )}
    </FlexDiv>
  );
};

const ScheduleTimeSelectComponentV4: React.FC<DisappearingDivProps> = ({
  user_id,
  lead_id,
  values,
  errors,
  setFieldValue,
  selectedDataTime,
  dateValue,
  setDateValue,
  yearMonth,
  setYearMonth,
  specificOptionStep,
  setSpecificOptionStep,
  toggleCalendar,
  isInCallRoute,
  callOptions,
  disableGeneralTime = true,
  closeCalendar,
  events,
  setAvailableRepsForBooking,
  setOpsiqSuggestedRep,
  ...props
}) => {
  const { callLeadId } = useContext(CallContext);
  const [focused, setFocused] = useState(false);
  const [selectedHour, setSelectedHour] = useState(0);
  const [generalOption, setGeneralOption] = useState("");
  const [selectedMinute, setSelectedMinute] = useState(0);
  const [generalOptionStep, setGeneralOptionStep] = useState(0);

  const { data, loading: loadingTimes, error: errorTimes } = useQuery(AVAILABLE_SPECIFIC_TIMES_V2, {
    variables: {
      day: dateValue,
      timezone: values.timezone,
      action: callOptions,
      callback: ["Schedule Callback", "Scheduled Callback", "Schedule Call Back"].includes(callOptions || ""),
      leadId: callLeadId || lead_id,
    },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataCal, loading: loadingCal, error: errorCal, refetch: refetchCal } = useQuery(MONTHLY_SCHEDULE, {
    variables: { yearmonth: yearMonth },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const monthsInNext3Years = [...createMonthsInYearText(0), ...createMonthsInYearText(1), ...createMonthsInYearText(2)];

  const dummyMonthsWithDays = monthsInNext3Years?.map((item, index) => ({
    month: moment().add(index, "months").format("MMM YYYY"),
    days: createMonthsWithDays(index),
  }));

  const dummyMonthsWithWeeks = monthsInNext3Years?.map((item, index) => ({
    month: item,
    weeks: createMonthWithWeek(index),
  }));

  const dummyYears = [
    { year: `${moment().year()}`, months: createMonthsOfYear(0) },
    { year: `${moment().year() + 1}`, months: createMonthsOfYear(1) },
    { year: `${moment().year() + 2}`, months: createMonthsOfYear(2) },
  ];

  useEffect(() => {
    if (!!values.specific_time) {
      setFieldValue(
        "specific_time",
        moment(dateValue)
          .tz(values.timezone, true)
          .set({
            hour: selectedHour,
            minute: selectedMinute,
          })
          .format(),
      );
    }
  }, [dateValue, values.timezone]);

  useEffect(() => {
    if (values.time === "General Time" && generalOption === "day") {
      const pos = dummyMonthsWithDays
        .slice()
        ?.map((item: any) => item.month)
        .indexOf(`${moment(dateValue, "YYYY-MM-DD").format("MMM YYYY")}`);
      setGeneralOptionStep(pos >= 0 ? pos : 0);
    }
    if (values.time === "General Time" && generalOption === "week") {
      const pos = dummyMonthsWithWeeks
        .slice()
        ?.map((item: any) => item.month)
        .indexOf(`${moment(dateValue, "YYYY-MM-DD").format("MMM YYYY")}`);
      setGeneralOptionStep(pos >= 0 ? pos : 0);
    }
    if (values.time === "General Time" && generalOption === "month") {
      const pos = dummyYears
        .slice()
        ?.map((item: any) => item.year)
        .indexOf(`${moment(dateValue, "YYYY-MM-DD").format("YYYY")}`);
      setGeneralOptionStep(pos >= 0 ? pos : 0);
    }
  }, [dateValue, generalOption]);

  return (
    <FieldsWrapper>
      <HeaderContainer>
        <AppText fontSize={16}>{callOptions}</AppText>
      </HeaderContainer>
      <FlexDiv direction="column" gap={32}>
        {!disableGeneralTime && (
          <>
            <PhoenixMultiSelect
              titleText={
                isInCallRoute && callOptions === "schedule demo"
                  ? "Schedule Demo"
                  : isInCallRoute && callOptions === "schedule call back"
                  ? "Schedule Call Back"
                  : "Select Time"
              }
              required
              marginBottom={false}
              name="time"
              options={optionsTime}
              isMulti={false}
              isClearable={false}
              isDisabled={props.disableSelectTime}
              onChange={(option: any) => {
                setFieldValue("time", option.value);
              }}
              value={optionsTime.find((option: any) => option.value === values.time)}
            />
          </>
        )}
        {dataCal && dataCal.fetchScheduleItemsMonth && values.time === "Specific Time" ? (
          <FlexDiv direction="column" gap={32} id="time-select-container">
            {loadingCal ? (
              <Loading />
            ) : (
              <FlexDiv gap={3} direction="column">
                <TitleLabel>
                  Select a Date<span style={{ color: theme.ATTENTION700 }}>*</span>
                </TitleLabel>

                <PhoenixDatePicker
                  id="make-sale-date-picker"
                  required
                  date={moment(dateValue)}
                  onDateChange={(date) => {
                    setDateValue(date);
                  }}
                  focused={focused}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  numberOfMonths={1}
                  isDayHighlighted={(date) =>
                    events.filter((item: any) => {
                      return moment(date).day() === moment(item.start).day();
                    })
                  }
                  showDefaultInputIcon
                  inputIconPosition={"after"}
                />
              </FlexDiv>
            )}

            <TimeZoneSelect isInCallRoute={isInCallRoute} />

            <FlexDiv gap={12} direction="column">
              <TitleLabel>
                Select Time<span style={{ color: theme.ATTENTION700 }}>*</span>
              </TitleLabel>

              {loadingTimes ? (
                <SkeletonBlock width="100%" height={250} borderRadius={4} />
              ) : errorTimes ? (
                <AppErrorText>{errorTimes.message}</AppErrorText>
              ) : data?.fetchAvailableTimesV3?.length === 0 ||
                // the last item is before the current time
                moment(data?.fetchAvailableTimesV3[data?.fetchAvailableTimesV3?.length - 1]?.start_time).isBefore(
                  moment(),
                ) ? (
                <ScrollSegment>
                  <AppText fontSize={12} style={{ textAlign: "center" }}>
                    No available times
                  </AppText>
                </ScrollSegment>
              ) : (
                <ScrollSegment>
                  {data?.fetchAvailableTimesV3
                    ?.slice()
                    ?.filter((item: any) => {
                      if (moment(item.start_time).isBefore(moment())) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    ?.map(
                      (item: AvailableTimeItem) =>
                        (
                          <NewAppButton
                            variant={"secondary"}
                            key={`${item.user_id}${item.start_time}`}
                            selected={!!values.specific_time && values.specific_time === item.start_time}
                            onClick={async () => {
                              await setFieldValue(
                                "specific_time",
                                values.specific_time === item.start_time ? "" : item.start_time,
                              );
                              console.log("Selected time is:", moment(item.start_time).toDate());
                              if (item.rep_ids) setAvailableRepsForBooking && setAvailableRepsForBooking(item.rep_ids);
                              if (item.user_id) setOpsiqSuggestedRep && setOpsiqSuggestedRep(item.user_id);
                            }}
                          >
                            {values.timezone
                              ? moment(item.start_time).tz(values.timezone).format("h:mm A")
                              : moment(item.start_time).format("h:mm A")}
                            {!!item.event_count && <span style={{ opacity: 0.6 }}>{` (${item.event_count})`}</span>}
                          </NewAppButton>
                        ) || (
                          <AppText fontSize={12} style={{ textAlign: "center" }}>
                            Something went wrong fetching available times
                          </AppText>
                        ),
                    )}
                </ScrollSegment>
              )}
            </FlexDiv>
          </FlexDiv>
        ) : (
          values.time === "Specific Time" && <Loading />
        )}
        {values.time === "General Time" && (
          <DateTimeSelector
            generalOption={generalOption}
            generalOptionStep={generalOptionStep}
            setGeneralOptionStep={setGeneralOptionStep}
            setFieldValue={setFieldValue}
            setGeneralOption={setGeneralOption}
            values={values}
            isInCallRoute={isInCallRoute}
            errors={errors}
            dummyMonthsWithDays={dummyMonthsWithDays}
            dummyMonthsWithWeeks={dummyMonthsWithWeeks}
            dummyYears={dummyYears}
          />
        )}
      </FlexDiv>
    </FieldsWrapper>
  );
};

const ScrollSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
  /* max-height: 250px; */
  overflow-x: visible;
  /* overflow-y: auto; */
`;

const TimeSeekingText = styled(AppText)`
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  margin: 0px 10px;
  min-width: 100px;
  max-height: 21px;
  text-align: left;
  text-overflow: ellipsis;
`;

const FieldsWrapper = styled.div`
  padding: 14px 0px;
`;

const TitleLabel = styled(AppText)`
  // margin-bottom: 11px;
  // margin-top: 11px;
`;

const FieldSelect = styled(FormSelectField)``;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
`;

export { ScheduleTimeSelectComponentV4 };
