import styled from "styled-components";
import { AppInput, AppButton, AppText, AppSidebarCard, Loading } from "../UI";
import { theme } from "../../utils/theme";
import DateTimePicker from "react-datetime-picker";
import Switch from "react-switch";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";

import { ModalContext } from "../../context";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import React, { useContext } from "react";

import { PhoenixAppButton } from "../UI/Phoenix";
import { useQuery, gql, useMutation } from "@apollo/client";
import { appToast } from "../../utils/toast";
import moment from "moment";
interface MyFormikProps {
  event_id: string;
  date: Date;
  title: string;
}

const EventDateSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  date: Yup.date().required("Required"),
});

const FETCH_NO_EVENT_DATE = gql`
  query FetchNoEventDate($fetchNoEventDateId: String!) {
    fetchNoEventDate(id: $fetchNoEventDateId) {
      created_at
      date
      id
      organization_id
      title
      updated_at
    }
  }
`;

const CREATE_NO_EVENT_DATE = gql`
  mutation CreateNoEventDate($data: OrgNoEventDateInput!) {
    createNoEventDate(data: $data) {
      id
    }
  }
`;

const UPDATE_NO_EVENT_DATE = gql`
  mutation UpdateNoEventDate($updateNoEventDateId: String!, $data: OrgNoEventDateInput!) {
    updateNoEventDate(id: $updateNoEventDateId, data: $data) {
      id
    }
  }
`;

const NoEventDateModal = () => {
  const { noEventModalData, setShowNoEventModal } = useContext(ModalContext);

  const { data, loading, error } = useQuery(FETCH_NO_EVENT_DATE, {
    variables: {
      fetchNoEventDateId: noEventModalData?.event_id,
    },
    skip: !noEventModalData?.event_id,
  });

  const [createNoEventDate] = useMutation(CREATE_NO_EVENT_DATE, {
    onCompleted: (data) => {
      appToast("No Event Date Created");
      setShowNoEventModal(false);
    },
    onError: (error) => {
      appToast("Error creating No Event Date");
      console.log(error);
      console.log(error?.message);
    },
    refetchQueries: ["FetchNoEventDates", "fetchNoEventDates"],
  });
  const [updateNoEventDate] = useMutation(UPDATE_NO_EVENT_DATE, {
    onCompleted: (data) => {
      appToast("No Event Date Updated");
      setShowNoEventModal(false);
    },
    onError: (error) => {
      appToast("Error Updating No Event Date");
      appToast(error.message);
    },
    refetchQueries: ["FetchNoEventDates", "fetchNoEventDates"],
  });

  if (loading)
    return (
      <Main>
        <TitleDiv>
          <TitleText>{noEventModalData?.event_id ? "Edit" : "Add"} No Event Date</TitleText>
        </TitleDiv>

        <ScrollingDiv>
          <Loading />
        </ScrollingDiv>
      </Main>
    );
  if (error)
    return (
      <Main>
        <TitleDiv>
          <TitleText>{noEventModalData?.event_id ? "Edit" : "Add"} No Event Date</TitleText>
        </TitleDiv>

        <ScrollingDiv>
          <AppText>Something went wrong</AppText>
        </ScrollingDiv>
      </Main>
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        event_id: noEventModalData?.event_id || "",
        date:
          // we need to convert any dates to this format MM/dd/yyyy
          data?.fetchNoEventDate?.date ? moment(data?.fetchNoEventDate?.date).toDate() : new Date(),

        title: data?.fetchNoEventDate?.title || "",
      }}
      validationSchema={EventDateSchema}
      onSubmit={async (values) => {
        console.log("onSubmit", values);
        const isEditing = !!noEventModalData?.event_id;

        if (isEditing) {
          await updateNoEventDate({
            variables: {
              updateNoEventDateId: noEventModalData?.event_id,
              data: {
                date: values.date,
                title: values.title,
                is_federal_holiday: false,
              },
            },
          });
        } else {
          await createNoEventDate({
            variables: {
              data: {
                date: values.date,
                title: values.title,
                is_federal_holiday: false,
              },
            },
          });
        }
      }}
    >
      {({ submitForm, isSubmitting, values, isValid, errors, dirty, setFieldValue }: FormikProps<MyFormikProps>) => {
        console.log("values", values);
        console.log("errors", errors);
        return (
          <Main>
            <ScrollingDiv>
              <TitleDiv>
                <TitleText>{noEventModalData?.event_id ? "Edit" : "Add"} No Event Date</TitleText>
              </TitleDiv>
              <AppText fontSize={12} fontWeight={500} color={theme.NEUTRAL300}>
                Calls, demos, and events will not take place on this day.
              </AppText>
              <SpacerDiv height={23} />

              <PhoenixInputField
                name={"title"}
                placeholder={"Event Name"}
                style={{ marginTop: 16 }}
                titleText="Title"
                requiredStar
                titleTextSpacing={8}
                titleTextFontSize={12}
                titleTextFontWeight={500}
              />

              <div>
                <AppText
                  fontSize={12}
                  fontWeight={500}
                  style={{
                    marginBottom: 8,
                  }}
                >
                  Date
                  <span style={{ color: "red" }}>*</span>
                </AppText>
                <DateTimePickerWrapper>
                  <DateTimePicker
                    format="MM/dd/yyyy"
                    // no clock
                    disableClock={true}
                    onChange={(date) => {
                      setFieldValue("date", date);
                    }}
                    value={values?.date}
                  />
                </DateTimePickerWrapper>
              </div>
              <SpacerDiv height={23} />
            </ScrollingDiv>
            <SubmitDiv>
              <PhoenixAppButton
                onClick={() => {
                  setShowNoEventModal(false);
                }}
                variant="danger-outline"
                buttonType="secondary"
              >
                Cancel
              </PhoenixAppButton>
              <PhoenixAppButton
                onClick={submitForm}
                variant="brand"
                buttonType="secondary"
                disabled={!isValid || !dirty || isSubmitting}
              >
                {values?.event_id ? "Update" : "Add"} No Event Date
              </PhoenixAppButton>
            </SubmitDiv>
          </Main>
        );
      }}
    </Formik>
  );
};

const Main = styled.div`
  width: 476px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScrollingDiv = styled.div`
  padding: 0px 40px 16px 40px;
  overflow: auto;
  width: 100%;
  height: fit-content;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
const SubmitDiv = styled.div`
  height: 80px;
  bottom: 0px;
  width: 100%;

  padding: 0px 40px;

  /* padding: 0px 24px; */
  /* margin-top: auto; */

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid ${theme.NEUTRAL200};
`;

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 8px;
`;
const DateTimePickerWrapper = styled.div`
  .react-datetime-picker {
    * {
      color: ${theme.PRIMARY600} !important;
    }
    /* Set the width of the date picker */
    /* border: 1px solid ${theme.PRIMARY600}; */
    border: none;
  }

  .react-datetime-picker__wrapper {
    /* Add padding to the wrapper element */
    padding: 10px;
  }

  .react-datetime-picker__inputGroup {
    /* Remove the default border and add a new border */
    border: none;
    border-bottom: 2px solid ${theme.PRIMARY800};
  }

  .react-datetime-picker__calendar {
    /* Add a border and background color to the calendar */
    border: 1px solid ${theme.PRIMARY600};
    background-color: ${theme.WHITE_COLOR};
  }

  .react-datetime-picker__calendar button {
    /* Style the buttons in the calendar */
    background-color: ${theme.WHITE_COLOR};
    border: none;
  }

  .react-datetime-picker__calendar button.react-datetime-picker__navigation {
    /* Style the navigation buttons in the calendar */
    background-color: ${theme.WHITE_COLOR};
  }
  .react-datetime-picker__wrapper {
    border: none !important;
  }

  .react-datetime-picker__calendar-button {
  }
  // clock
  .react-clock__hand__body {
    background-color: ${theme.PRIMARY500} !important;
  }

  .react-clock__mark__body {
    background-color: ${theme.PRIMARY600} !important;
  }
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

export { NoEventDateModal };
