import styled from "styled-components";
import * as React from "react";

/* this is a wrapper div that can be used to style all of the children of a component 
to alter the opacity and brightness of the children when the component is disabled or hovered over */

const OpacityDiv = ({
  children,
  disabled = false,
  disabledOpacity = 0.2,
  disabledBrightness = 1,
  hoverOpacity = 1,
  hoverBrightness = 0.8,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  disabledOpacity?: number;
  disabledBrightness?: number;
  hoverOpacity?: number;
  hoverBrightness?: number;
}) => {
  return (
    <OpacityWrapper
      disabled={disabled}
      disabledOpacity={disabledOpacity}
      hoverBrightness={hoverBrightness}
      disabledBrightness={disabledBrightness}
      hoverOpacity={hoverOpacity}
    >
      {children}
    </OpacityWrapper>
  );
};

const OpacityWrapper = styled.div<{
  disabled: boolean;
  disabledOpacity: number;
  disabledBrightness: number;
  hoverBrightness: number;
  hoverOpacity: number;
}>`
  filter: opacity(${(props) => (props.disabled ? props.disabledOpacity : 1)});
  filter: brightness(${(props) => (props.disabled ? props.disabledBrightness : 1)});

  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  &:hover {
    filter: opacity(${(props) => props.hoverOpacity});
    filter: brightness(${(props) => props.hoverBrightness});
  }
`;

export { OpacityDiv };
