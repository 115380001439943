import React, { useState, useContext } from "react";
import { theme } from "../../../../utils/theme";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { edit, trash } from "../../../../images/NewDesign";
import { AgGridReact } from "ag-grid-react";

import moment from "moment";
import { ModalContext } from "../../../../context";

import { useQuery, gql } from "@apollo/client";
import { appToast } from "../../../../utils/toast";
import { SkeletonBlock } from "../../../UI/SkeletonBlock";
import { AppErrorText } from "../../../UI";
import styled from "styled-components";

// for custom dates

const FETCH_CUSTOM_NO_EVENT_DATES = gql`
  query FetchNoEventDates {
    fetchNoEventDates {
      created_at
      date
      id
      organization_id
      title
      updated_at
    }
  }
`;

const CustomDateTable = ({ selectedYear }: { selectedYear: number | string }) => {
  const [initialLoad, setInitialLoad] = useState(true);

  const { noEventModalData, setNoEventModalData, setShowDeleteNoEventModal, setShowNoEventModal } = useContext(
    ModalContext,
  );

  const [rowData, setRowData] = useState([] as any[]);

  //-------------------------------------------------------//
  //-----------------custom no event dates-----------------//
  //-------------------------------------------------------//

  const { data: noEventData, loading: noEventLoading, error: noEventError, refetch: refetchNoEventData } = useQuery(
    FETCH_CUSTOM_NO_EVENT_DATES,
    {
      fetchPolicy: "cache-and-network",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
        appToast(message);
      },

      onCompleted({ fetchNoEventDates }) {
        if (initialLoad) setInitialLoad(false);

        setRowData(
          fetchNoEventDates?.map((event: any) => {
            return {
              title: event?.title ?? "NA",
              date: moment(event?.date)?.format("dddd, MMMM Do YYYY") ?? "NA",
              manage: event?.id,
              // set this as a number for the year
              year: moment(event?.date)?.year(),
            };
          }),
        );
      },
    },
  );

  const ManageCustomNoEventCellRenderer = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "40px", gap: "8px" }}>
        <PhoenixIcon
          svg={edit}
          size={16}
          onClick={() => {
            setNoEventModalData({
              ...noEventModalData,
              event_id: props?.data?.manage,
            });
            setShowNoEventModal(true);
          }}
        />
        <PhoenixIcon
          svg={trash}
          variant="danger"
          size={16}
          onClick={() => {
            setNoEventModalData({
              ...noEventModalData,
              event_id: props?.data?.manage,
            });
            setShowDeleteNoEventModal(true);
          }}
        />
      </div>
    );
  };

  const CUSTOM_COLUMN_DEFS = [
    {
      headerName: "No Event Date",
      field: "title",
      sortable: false,
      filter: false,
      resizable: true,
      flex: 1,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-no-event-table-header",
    },
    {
      headerName: "Date",
      field: "date",
      sortable: false,
      filter: false,
      resizable: true,
      flex: 1,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-no-event-table-header",
    },
    {
      headerName: "Action",
      field: "manage",
      sortable: false,
      filter: false,
      resizable: true,
      width: 104,
      cellStyle: { textAlign: "left" },
      cellRendererFramework: ManageCustomNoEventCellRenderer,
      headerClass: "ag-no-event-table-header",
    },
  ];

  const loading = noEventLoading;
  const error = noEventError;

  if (initialLoad && loading) {
    return <SkeletonBlock height={500} width={"100%"} borderRadius={4} />;
  }

  if (error) {
    return <AppErrorText>Something went wrong. Please try again later.</AppErrorText>;
  }

  return (
    <Wrapper>
      <div
        className={"ag-theme-no-event-table"}
        style={{
          width: "100%",
          border: `1px solid ${theme.NEUTRAL300}`,
          height: "fit-content",
          borderRadius: "8px",
          maxHeight: "550px",
          overflow: "auto",
          padding: "4px",
        }}
      >
        <AgGridReact
          rowData={rowData.filter((row) => {
            if (selectedYear === "all") return true;

            console.log("selectedYear: ", selectedYear);

            // should never happen
            // if (typeof selectedYear === "string") return row.year === parseInt(selectedYear);

            return row.year === selectedYear;
          })}
          rowDragEntireRow={true}
          columnDefs={CUSTOM_COLUMN_DEFS}
          suppressMovableColumns={true}
          suppressDragLeaveHidesColumns={true}
          tooltipMouseTrack={true}
          animateRows={true}
          enableCellTextSelection={true}
          rowHeight={40}
          domLayout="autoHeight"
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ag-row-drag {
    visibility: hidden;
    display: none;
  }

  .ag-row {
    border-bottom: none !important;
  }
  .ag-theme-no-event-table {
    justify-content: left;
  }

  .ag-theme-no-event-table .ag-row-even {
    background-color: #f7f9ff;
  }

  .ag-theme-no-event-table .ag-row-odd {
    background-color: #ffffff;
  }

  .ag-theme-no-event-table {
    --ag-odd-row-background-color: #f7f9ff;
    --ag-header-background-color: #f7f9ff;
    --ag-header-height: 40px;
    --ag-row-height: 40px;
    --ag-font-size: 12px;
    --ag-font-family: "Inter", sans-serif;
    --ag-borders: none;
    --ag-range-selection-border-color: #00000000;
  }

  .ag-row-drag {
    visibility: hidden;
    display: none;
  }

  .ag-no-event-table-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    font-family: "Inter";
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    /* Neutral/Neutral-Main */
    background-color: white;
    color: #000000;
  }

  .ag-no-event-table-header div {
    justify-content: left;
  }

  .ag-theme-no-event-table-list .ag-row-even {
    background-color: #f7f9ff;
  }

  .ag-theme-no-event-table-list .ag-row-odd {
    background-color: #ffffff;
  }
`;

export default CustomDateTable;
