import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext, useState } from "react";
import { AppText, Loading, AppErrorText, AppMultiSelect } from "../UI";
import { theme } from "../../utils/theme";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { ModalContext } from "../../context";
import { appToast, notificationToast } from "../../utils/toast";
import { NewAppButton } from "../UI/NewAppButton";
import { loggedInUser } from "../../apollo/cache";
import { IS_USER_REP } from "../../apollo/query";
import { MixpanelActions } from "../../services/mixpanel";
import { Modal } from "./Modal";
import { FontWeight } from "@cloudinary/url-gen/qualifiers";
import { CallContext } from "../../context/CallContext";

const DisassociateLeadModal: React.FC = () => {
  const { showDisassociateLeadModal, setShowDisassociateLeadModal, swapPrimaryLeadModalData } = useContext(
    ModalContext,
  );

  const { goToCall, callState, nextActionOverride, setNextActionOverride, resetNextDial } = useContext(CallContext);

  const { data: isUserRep } = useQuery(IS_USER_REP);

  const DISSOCIATE_LEAD = gql`
    mutation DisassociateLead($primary_lead_id: String!, $associate_lead_id: String!, $rep_id: String) {
      disassociateLead(primary_lead_id: $primary_lead_id, associate_lead_id: $associate_lead_id, rep_id: $rep_id) {
        message
        status
        status_boolean
      }
    }
  `;

  const [dissociateLead, { loading: dissociateLoading, error: dissociateError }] = useMutation(DISSOCIATE_LEAD, {
    onCompleted({ dissociateLead }) {
      console.log("dissociateLead: ", dissociateLead);

      appToast("Lead dissociated successfully");

      setShowDisassociateLeadModal(false);

      MixpanelActions.track("Contacts Disassociated");
    },
    onError({ message }) {
      notificationToast({
        id: message,
        message: message,
        variant: "primary",
      });
      Sentry.captureEvent({
        message: `Error dissociating lead GraphQL Error: ${message}`,
      });
      console.log("Error dissociating lead: ", message);
    },
    refetchQueries: [
      "fetchLead",
      "FetchAssociateContact",
      "fetchNextDial",
      "fetchLeadHistory",
      "fetchFavoritedLeads",
      "fetchCustomQueueLeads",
      "fetchFilteredLeadsInQueueV2",
      "fetchRecommendedDial",
      "fetchCurrentAssociateStats",
      "fetchAssociateContactHistory",
      "fetchLeadCallHistory",
      "fetchScheduledItems",
    ],
  });

  return (
    <Modal open={showDisassociateLeadModal} onClose={() => setShowDisassociateLeadModal(false)} closeButtonSize={16}>
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Disassociate Contacts </TitleText>
        </TitleDiv>

        <FlexDiv>
          <FlexInnerDiv>
            <AppText fontSize={14} style={{ textAlign: "center", marginBottom: "16px" }} fontWeight={400}>
              Are you sure you want to disassociate these contacts?
            </AppText>
          </FlexInnerDiv>
        </FlexDiv>
        <SubmitDiv>
          <div
            style={{
              width: "118px",
            }}
          >
            <NewAppButton
              variant="attention"
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
              onClick={() => {
                setShowDisassociateLeadModal(false);
              }}
            >
              no, cancel
            </NewAppButton>
          </div>
          <div
            style={{
              width: "138px",
            }}
          >
            <NewAppButton
              onClick={async () => {
                await dissociateLead({
                  variables: {
                    primary_lead_id: swapPrimaryLeadModalData?.primary_lead_id,
                    associate_lead_id: swapPrimaryLeadModalData?.associate_lead_id,
                    rep_id: swapPrimaryLeadModalData?.rep_id,
                  },
                });
              }}
              variant="primary"
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
            >
              yes, continue
            </NewAppButton>
          </div>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 16px 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
`;

const FlexInnerDiv = styled.div`
  height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

export { DisassociateLeadModal };
