import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";

interface PhoenixMultiRangeSliderProps {
  min: number;
  max: number;
  onChange?: (min: number, max: number) => void;

  minThumbLabel?: string;
  maxThumbLabel?: string;

  // style props
  width?: string | number;
  thumbWidth?: string | number;
}

export const PhoenixMultiRangeSlider: React.FC<PhoenixMultiRangeSliderProps> = ({
  min = 0,
  max = 1000,
  onChange,
  width,
  thumbWidth,
}) => {
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);
  const [prevSelected, setPrevSelected] = useState<"min" | "max">("min");

  const range = useRef<HTMLDivElement>(null);
  const minLabelRef = useRef<HTMLSpanElement>(null);
  const maxLabelRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (range.current) {
      const rangeWidthPercent = ((maxValue - minValue) / max) * 100;
      const leftPercent = (minValue / max) * 100;
      range.current.style.width = `${rangeWidthPercent}%`;
      range.current.style.left = `${leftPercent}%`;
    }
    if (minLabelRef.current) {
      minLabelRef.current.style.left = `${(minValue / max) * 100}%`;
    }
    if (maxLabelRef.current) {
      maxLabelRef.current.style.left = `${(maxValue / max) * 100}%`;
    }
  }, [minValue, maxValue, max]);

  return (
    <Container width={width}>
      <Input
        type="range"
        min={min}
        max={max}
        value={minValue}
        onChange={(e) => {
          setMinValue(Math.min(Number(e.target.value), maxValue));
          setPrevSelected("min");
          onChange?.(minValue, maxValue);
        }}
        onClick={() => setPrevSelected("min")}
        prevSelected={prevSelected === "min"}
        thumbWidth={thumbWidth}
        width={width}
      />
      <Input
        type="range"
        min={min}
        max={max}
        value={maxValue}
        onChange={(e) => {
          setMaxValue(Math.max(Number(e.target.value), minValue));
          setPrevSelected("max");
          onChange?.(minValue, maxValue);
        }}
        onClick={() => setPrevSelected("max")}
        prevSelected={prevSelected === "max"}
        thumbWidth={thumbWidth}
        width={width}
      />
      <RangeFill ref={range} />
    </Container>
  );
};

const Container = styled.div<{ width?: string | number }>`
  position: relative;
  width: ${(props) => (!!props.width ? (typeof props.width === "number" ? `${props.width}px` : props.width) : "376px")};
  height: 8px;

  border-radius: 8px;
  background-color: ${theme.fill.brand.secondary};
`;

interface InputProps {
  prevSelected: boolean;
  thumbWidth?: string | number;
  width?: string | number;
}

const Input = styled.input<InputProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: ${(props) => (!!props.width ? (typeof props.width === "number" ? `${props.width}px` : props.width) : "376px")};

  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  pointer-events: none;

  &::-webkit-slider-thumb {
    position: relative;
    top: -4px;
    z-index: ${(props) => (props.prevSelected ? 5 : 4)};

    -webkit-appearance: none;
    cursor: pointer;
    pointer-events: all;

    width: ${(props) =>
      !!props.thumbWidth
        ? typeof props.thumbWidth === "number"
          ? `${props.thumbWidth}px`
          : props.thumbWidth
        : "38px"};
    height: 16px;

    border-radius: 80px;
    background-color: ${theme.fill.neutral.primary};
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);

    overflow: visible;
  }
`;

const RangeFill = styled.div`
  position: absolute;
  top: 0;
  z-index: 3;

  height: 100%;

  background-color: ${theme.fill.brand.primary};

  border-radius: 80px;
`;

const ThumbText = styled.span<{ prevSelected: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.prevSelected ? 5 : 4)};

  transform: translateX(50%);

  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;
