import * as React from "react";
import styled from "styled-components";
import { SingleDatePicker, SingleDatePickerShape } from "react-dates";
import { theme } from "src/utils/theme";
import { FlexDiv } from "../FlexDiv";
import { PhoenixIcon } from "./PhoenixIcon";
import { calendar } from "src/images/NewDesign";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

type PhoenixDatePickerProps = SingleDatePickerShape;

export const PhoenixDatePicker: React.FC<PhoenixDatePickerProps> = ({ ...props }) => {
  return (
    <FlexDiv direction="row" gap={8} align="center">
      <PickerWrapper>
        <SingleDatePicker {...props} customInputIcon={() => null} />
      </PickerWrapper>

      <PhoenixIcon size={16} svg={calendar} />
    </FlexDiv>
  );
};

const PickerWrapper = styled.div`
  height: 40px;
  width: 100%;

  .SingleDatePicker {
    height: 40px;
    width: 100%;
  }

  .SingleDatePickerInput {
    height: 40px;
    width: 100%;
  }

  .SingleDatePickerInput__withBorder {
    border-radius: 4px;
    border: 1px solid ${theme.NEUTRAL300};
  }

  .DateInput {
    border-radius: 4px;
    height: 100%;
    width: 100%;
  }

  .DateInput_input {
    border-radius: 4px;
    font-family: ${theme.PRIMARY_FONT};
    font-size: 12px;
    font-weight: 400;
    height: 100%;
    padding: 4px 8px;
    width: 100%;
  }
`;
