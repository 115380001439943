import styled from "styled-components";
import * as React from "react";
import { useState, useEffect } from "react";
import { AppButton, AppText, AppModalPriorityPopup, Loading, AppErrorText, AppSidebarCard } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../Field";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { Modal } from "./Modal";
import { PhoenixIcon } from "../UI/Phoenix";
import { trash } from "../../images/NewDesign";

interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  onDelete: any;
  actionText: string;
  detailsText?: string;
  loading?: boolean;
}

const ConfirmDeletePopup: React.FC<DisappearingDivProps> = ({
  blinds,
  setBlinds,
  onDelete,
  actionText,
  detailsText,
  loading,
}) => {
  return (
    <Modal open={blinds} onClose={() => setBlinds(false)} closeButtonSize={16}>
      <div>
        <TitleDiv>
          <TitleText>CONFIRM DELETION</TitleText>
        </TitleDiv>
        <ContentDiv>
          <PopupText1>You are attempting to delete</PopupText1>
          <PopupText2>{actionText}</PopupText2>
          {!!detailsText && <PopupText3>{detailsText}</PopupText3>}
          {loading ? (
            <Loading />
          ) : (
            <PopupButton white uppercase customtextcolor={theme.ATTENTION700} fontsize={12} onClick={onDelete}>
              <PhoenixIcon svg={trash} style={{ verticalAlign: "baseline" }} color={theme.ATTENTION700} size={12} />
              &nbsp; CONFIRM DELETION
            </PopupButton>
          )}
          <PopupButton
            cancel
            style={{ fontSize: "10px", fontWeight: 500, width: "186px" }}
            onClick={() => setBlinds(false)}
          >
            Cancel Action
          </PopupButton>
        </ContentDiv>
        <CloseButton
          onClick={() => {
            setBlinds(false);
          }}
        >
          <IoMdClose size={22} color={theme.PRIMARY500} />
        </CloseButton>
      </div>
    </Modal>
  );
};

const PopupText3 = styled(AppText)`
  font-size: 11px;
  margin-bottom: 34px;
  max-width: 311px;
  text-align: center;
`;

const PopupText2 = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  margin-top: 21px;
  margin-bottom: 34px;
  text-transform: uppercase;
`;

const PopupText1 = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  margin-top: 31px;
`;

const PopupButton = styled(AppButton)`
  width: 248px;
  height: 43px;
  margin-bottom: 20px;
  border-radius: 0px;
  font-size: 15px;
  font-weight: 600;
  :focus {
    outline: none;
  }
`;

const ContentDiv = styled.div`
  margin-top: 22px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const TitleText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: ${theme.WHITE_COLOR};
  line-height: 22px;
`;

const TitleDiv = styled.div`
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: ${theme.ATTENTION700};
`;

const CloseButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  right: -14px;
  top: -14px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 5;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const LeadSelect = styled(FormSelectField)`
  /* height: 45px; */
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const WhitespaceDiv = styled.div`
  height: 50px;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 13px;
  margin-left: 5px;
`;

const LeadInput = styled(InputField)`
  margin: 0;
  text-align: left;
  font-size: 13px;
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const AddEmailCancelButton = styled(AppButton)`
  font-size: 10px;
  font-weight: 600;
  width: 136px;
  height: 32px;
  border-radius: 2px;
  text-transform: uppercase;
  margin: auto;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

const PopupTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 27px;
`;

interface ModalProps {
  width?: number;
  stickBottom?: boolean;
}

const PopupModal = styled.div<ModalProps>`
  position: absolute;
  top: 104px;
  bottom: ${(props) => (props.stickBottom ? 0 : "none")};
  left: ${(props) => `calc((100vw - ${props.width}px) / 2)` || "calc(50vw - (453px / 2))"};
  width: ${(props) => `${props.width}px` || "100%"};
  width: 453px;
  left: calc(50vw - 226.5px);
  border-radius: 7px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: auto;
  background: ${theme.WHITE_COLOR};
  border: 4px solid ${theme.NEUTRAL100};
  z-index: 3;
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 40px 66px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
`;

const LeadsButton = styled(AppButton)`
  width: 144px;
  height: 32px;
  border-radius: 2.9px;
  border: solid 1px ${theme.NEUTRAL200};
  margin: 11px;
  font-size: 11px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  max-height: 450px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

export { ConfirmDeletePopup };
