import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";
import { theme } from "../../utils/theme";
import Select, { components, MenuListComponentProps, OptionsType, OptionTypeBase } from "react-select";
import { Props as SelectProps } from "react-select";
import { carrotDropdown } from "../../images/";
import { none } from "@cloudinary/url-gen/qualifiers/progressive";
import { StringLocale } from "yup";
import { carrot } from "../../images/NewDesign";
import { AppText } from "./AppText";
interface OptionItem {
  label: string;
  value: any;
  disabled?: boolean;
}

export interface NewAppSelectProps extends SelectProps {
  title?: string;
  /**
   * Indicates an error strong
   */
  error?: string;
  options: OptionItem[];
  /**
   * If true, it does not enable possiblity of an error message. Normally bottom padding is reserved for an error message
   */
  noErrorNeeded?: boolean;
  value?: any;
  variant?: "primary" | "error" | "success" | "warning";
  transparent?: boolean;
  //for async dropdown (these are default react select props):
  onFocus?: () => void;
  autoload?: boolean;
  isLoading?: boolean;
  multi?: boolean | undefined;
  pinnedOption?: any;
  height?: number;
  padding?: number;
  menuPortal?: boolean;
  hideBlueIndicator?: boolean;
  disabledText?: boolean;
  TitleIcon?: React.ReactNode;
  isRequired?: boolean;
  titleInInput?: boolean;
}

export const NewAppSelect = ({
  title,
  options,
  error,
  value,
  noErrorNeeded = false,
  hideBlueIndicator = false,
  disabledText = false,
  onFocus,
  autoload,
  isLoading,
  multi,
  transparent,
  pinnedOption,
  height = 42,
  padding = 4,
  menuPortal,
  TitleIcon,
  isRequired = false,
  titleInInput = false,
  ...props
}: NewAppSelectProps) => {
  const customStyles = {
    //Select Containter
    // input: (provided: any, state: any) => ({ ...provided, width: "100%" }),

    control: (provided: any, state: any) => ({
      ...provided,
      padding: "auto",
      borderWidth: state.isFocused ? 4 : 1,
      border: props.borderless
        ? "none"
        : state.isFocused
        ? `1px solid ${theme.NEUTRAL300} !important`
        : `1px solid ${theme.NEUTRAL200}`,
      backgroundColor: transparent ? "transparent" : theme.WHITE_COLOR,
      boxShadow: "none",
      borderRadius: 4,
      height: height,
      minHeight: height,
      fontSize: 12,
      alignItems: "baseline",
      overflow: "wrap",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      marginTop: 8,
      zIndex: 9999,
      fontSize: 12,
    }),
    option: (provided: any, state: any) => ({ ...provided, fontSize: 10 }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: height,
      padding: "0 6px",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: height,
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: 9999 }),
  };

  //unsure how to get this to work with multiselect
  // const MenuList = (props: MenuListComponentProps<OptionsType<OptionTypeBase>, typeof isMulti>) => {
  const MenuList = (props: MenuListComponentProps<OptionsType<OptionTypeBase>, false>) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <>{!!pinnedOption && pinnedOption}</>
      </components.MenuList>
    );
  };
  return (
    <>
      {!!title && !titleInInput && (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <NormalTitle disabledText={disabledText}>
            {title}
            {isRequired && <span style={{ color: theme.ATTENTION700 }}>{" *"}</span>}
          </NormalTitle>

          {TitleIcon && <div style={{ marginLeft: 8, display: "flex", alignItems: "center" }}>{TitleIcon}</div>}
        </div>
      )}

      <StyledSelect
        {...(props as any)}
        options={options}
        value={options ? options.find((option) => option.value === value) : ""}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (
            <>
              {!hideBlueIndicator && (
                <img
                  src={carrot}
                  style={{
                    marginRight: 16,
                  }}
                />
              )}
            </>
          ),
          MenuList,
          LoadingIndicator: null,
          // custom value container component
          ValueContainer: ({ children, ...props }: any) => {
            if (titleInInput) {
              return (
                <StyledValueContainer {...props}>
                  {title && (
                    <InputTitle
                      disabledText={disabledText}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {title}
                      {isRequired && <span style={{ color: theme.ATTENTION700 }}>{" *"}</span>}
                    </InputTitle>
                  )}

                  {
                    // there are two children, one is the value element, the other is the input element to type in and search
                    children &&
                      React.Children?.map(children, (child: React.ReactElement) => {
                        // value element
                        if (
                          React.isValidElement(child) &&
                          child.props &&
                          typeof child?.type === "function" && // Check if child.type is a function
                          child.type.name === "SingleValue"
                        ) {
                          return (
                            <AppText>{options ? options.find((option) => option.value === value)?.label : ""}</AppText>
                          );
                        }

                        // input element

                        if (
                          React.isValidElement(child) &&
                          child.props &&
                          typeof child?.type === "function" && // Check if child.type is a function
                          child.type.name === "Input"
                        ) {
                          return (
                            <div
                              style={{
                                // move it to left
                                left: "6px",
                                position: "absolute",
                                top: "40%",
                              }}
                            >
                              {child}
                            </div>
                          );
                        }

                        // ...

                        return child;
                      })
                  }
                </StyledValueContainer>
              );
            } else {
              // default value container
              return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
            }
          },
        }}
        onFocus={onFocus}
        autoload={autoload}
        isLoading={isLoading}
        isMulti={!!multi}
        menuPortalTarget={menuPortal && document.querySelector("body")}
      />
      {!!noErrorNeeded ? undefined : !!error ? (
        <InputErrorText>{error}</InputErrorText>
      ) : (
        <InputErrorText>&nbsp;</InputErrorText>
      )}
    </>
  );
};

const InputTitle = styled.p<{ disabledText: boolean }>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 8px;
  font-weight: 600;
  color: ${({ disabledText }) => (disabledText ? theme.NEUTRAL200 : theme.NEUTRAL300)};
  margin: 0;
  margin-bottom: 6px;
  /* text-align: center; */
`;

const NormalTitle = styled.p<{ disabledText: boolean }>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${({ disabledText }) => (disabledText ? theme.NEUTRAL300 : theme.BLACK_COLOR)};
  margin: 0;
  margin-bottom: 4px;
  /* text-align: center; */
`;

const StyledSelect = styled(Select)``;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 700;
  font-size: 12px;
  color: ${theme.ATTENTION700};
  margin: 0;
  margin-top: 4px;
  text-align: center;
`;

const StyledValueContainer = styled(components.ValueContainer)`
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding-top: 6px !important;
  flex-direction: column;
`;

const StyledSingleValue = styled(components.SingleValue)`
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding-top: 4px !important;
`;

const DropdownArrow = styled.img`
  padding: 0px 16px 0px 16px;
`;
