import React from "react";
import ReactDom from "react-dom";
import styled, { css, keyframes } from "styled-components";
import { AppIconButton } from "../UI/AppIconButton";
import { theme } from "../../utils/theme";
import { AiOutlineClose } from "react-icons/ai";
import { PhoenixIcon } from "../UI/Phoenix";
import { xIcon } from "../../images/NewDesign";

interface ModalProps {
  //Modal wrapper, height/width should be determined within the modal content styling itself
  children?: any;
  open?: boolean;
  onClose?: any;
  noDarkDiv?: boolean;
  style?: React.CSSProperties;
  popupAnimation?: boolean;
  width?: number;
  minWidth?: number;
  closeButtonSize?: number;
}

export const Modal = ({
  open,
  children,
  onClose,
  noDarkDiv,
  style,
  popupAnimation,
  width,
  minWidth = 400,
  closeButtonSize = 22,
}: ModalProps & React.HTMLProps<HTMLDivElement>) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      {!noDarkDiv && <ModalOverlay onClick={onClose} />}
      <ModalContainer style={style} popupAnimation={popupAnimation} width={width} minWidth={minWidth}>
        <CloseButton onClick={onClose}>
          <PhoenixIcon svg={xIcon} size={closeButtonSize} pointer />
        </CloseButton>
        {children}
      </ModalContainer>
    </>,
    document.getElementById("modal-root")!,
  );
};

const CloseButton = styled(AppIconButton)`
  position: absolute;
  z-index: 20;
  right: 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 21, 26, 0.5);
  z-index: 1000;
`;

const popupAnimation = keyframes`
  0% {
    margin-top: 12px;
    opacity: .5;
    scale: .99;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
    scale: 1;
  }
`;

const ModalContainer = styled.div<ModalProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.WHITE_COLOR};
  z-index: 1000;
  border-radius: 8px;

  // default min-width can be overwritten by passing a width prop
  min-width: ${(props) => props.minWidth};

  // for specific widths, pass a width prop otherwise default to min width expanding behavior
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  transform-origin: top left;

  animation: ${(props) =>
    props.popupAnimation &&
    css`
      ${popupAnimation} .2s ease forwards
    `};
`;
