/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { AnyStyledComponent } from "styled-components";
import * as Sentry from "@sentry/react";
import React, { useState, useMemo } from "react";
import { AppButton, AppText, Loading, NewAppButton, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import Switch from "react-switch";
import { Link, useLocation, useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { UpdateUserModalV2, UserCSVUpload } from "../../modal";
import { toast } from "react-toastify";
import { USER_CSV_EXAMPLE_URL } from "../../../utils/variables";
import { restAPI } from "../../../apollo";
import { truncate } from "fs/promises";
import { appToast } from "../../../utils/toast";
import { EditableInputField } from "../../Field/EditableInputField";
import { carrotDropdown, searchIcon, iconNewEdit, iconRefresh, iconGrid, iconList } from "../../../images";

interface SalesTeamsTilesProps {
  step: number;
  isOnboarding?: boolean;
  sortBy: boolean;
  searchText: string;
  tileState: {
    teams: any[];
    setTeams: React.Dispatch<React.SetStateAction<any[]>>;
    unassignedUsers: any[];
    setUnassignedUsers: React.Dispatch<React.SetStateAction<any[]>>;
  };
}

const defaultModalUser = {
  id: "",
  role: "",
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  team_name: "",
  site: {
    id: "",
    name: "",
  },
  manager_id: "",
  close_rate: 0,
  team_id: "",
  // taking_demos: true,
  default_availability: true,
  holds_per_prospect_vs_held_demo_per_dial: 0,
  user_custom_availability: undefined,
  channel: "",
  visible: false,
  timezone: "",
  industry_filter: false,
  industry_white_list_array: [],
  sub_industry_white_list_array: [],
  lead_source_filter: false,
  lead_source_white_list_array: [],
  regions_white_list_assign_type: "OnlyAssign",
  industry_white_list_assign_type: "OnlyAssign",
  sub_industry_white_list_assign_type: "OnlyAssign",
  lead_source_white_list_assign_type: "OnlyAssign",
  auto_assign_no_phone_leads: null,
};

const defaultCSVModal = {
  num_imported: undefined,
  num_updated: undefined,
  num_error: undefined,
  error_report_url: undefined,
  upload_id: undefined,
  num_stagged: undefined,
  visible: false,
};

const FETCH_UNASSIGNED_USERS = gql`
  query fetchUnassignedUsers {
    fetchUnassignedUsers {
      id
      email
      first_name
      last_name
      full_name
      last_name_first_name
      role
      phone_number
      team_id
      team_name
      manager_id
      close_rate
      metric
      taking_demos
      default_availability
      holds_per_prospect_vs_held_demo_per_dial
      timezone
      industry_filter
      industry_white_list_array
      regions_white_list_array
      sub_industry_white_list_array
      lead_source_filter
      lead_source_white_list_array
      regions_white_list_assign_type
      industry_white_list_assign_type
      sub_industry_white_list_assign_type
      lead_source_white_list_assign_type
      auto_assign_no_phone_leads
      user_custom_availability {
        id
        day
        start_hour
        start_minute
        end_hour
        end_minute
        start_time
        end_time
      }
      channel
      current_team_start_date
    }
  }
`;

const UPDATE_TEAM_NAME = gql`
  mutation updateTeam($team_id: String!, $team_name: String!) {
    updateTeam(team_id: $team_id, team_name: $team_name) {
      id
      name
    }
  }
`;

const ASSIGN_USER_TO_TEAM = gql`
  mutation assignUserToTeam($user_id: String!, $team_id: String) {
    assignUserToTeam(user_id: $user_id, team_id: $team_id) {
      id
      role
      email
      first_name
      last_name
      full_name
      last_name_first_name
      phone_number
      team_id
      team_name
      manager_id
      close_rate
      metric
      taking_demos
      default_availability
      holds_per_prospect_vs_held_demo_per_dial
      timezone
      industry_filter
      industry_white_list_array
      sub_industry_white_list_array
      lead_source_filter
      lead_source_white_list_array
      regions_white_list_assign_type
      industry_white_list_assign_type
      sub_industry_white_list_assign_type
      lead_source_white_list_assign_type
      auto_assign_no_phone_leads
      user_custom_availability {
        id
        day
        start_minute
        start_hour
        end_minute
        end_hour
      }
      team {
        id
        name
        name
        average_close_rate
        average_holds_per_prospect_vs_held_demo_per_dial
        label
      }
      channel
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteOneUser($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
    }
  }
`;

const UPDATE_ORG = gql`
  mutation updateRepNoteView($reps_can_view_all_notes: Boolean!) {
    updateRepNoteView(reps_can_view_all_notes: $reps_can_view_all_notes) {
      id
      reps_can_view_all_notes
    }
  }
`;

const FETCH_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      reps_can_view_all_notes
    }
  }
`;

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      created_at
      name
      average_close_rate
      average_holds_per_prospect_vs_held_demo_per_dial
      label
      site {
        id
        name
      }
      team_leader {
        id
        email
        first_name
        last_name
        full_name
        last_name_first_name
        role
        team_id
        team {
          site_id
        }
        phone_number
        close_rate
        metric
        holds_per_prospect_vs_held_demo_per_dial
        taking_demos
        team_name
        default_availability
        timezone
        user_custom_availability {
          id
          day
          start_hour
          start_minute
          end_hour
          end_minute
          start_time
          end_time
        }
        visible_all_leads_sm
      }
      active_members {
        id
        email
        first_name
        last_name
        full_name
        last_name_first_name
        role
        team_id
        team {
          site_id
        }
        phone_number
        close_rate
        metric
        holds_per_prospect_vs_held_demo_per_dial
        taking_demos
        team_name
        default_availability
        timezone
        industry_filter
        industry_white_list_array
        regions_white_list_array
        sub_industry_white_list_array
        lead_source_filter
        lead_source_white_list_array
        regions_white_list_assign_type
        industry_white_list_assign_type
        sub_industry_white_list_assign_type
        lead_source_white_list_assign_type
        auto_assign_no_phone_leads
        custom_field_white_list_array {
          custom_field {
            id
          }
          assign
          selected
        }
        channel
        leads_in_queue
        user_custom_availability {
          id
          day
          start_hour
          start_minute
          end_hour
          end_minute
          start_time
          end_time
        }
        current_team_start_date
        manager_id
      }
    }
  }
`;

const REFILL_LEAD_QUEUE = gql`
  mutation refillLeadQueue($user_id: String!) {
    refillLeadQueue(user_id: $user_id)
  }
`;

const SalesTeamsTiles: React.FC<SalesTeamsTilesProps> = ({
  step,
  isOnboarding,
  sortBy,
  searchText,
  tileState: { teams, setTeams, unassignedUsers, setUnassignedUsers },
}) => {
  const [userModal, setUserModal] = useState(defaultModalUser);
  const [csvModal, setCSVModal] = useState(defaultCSVModal);
  const [csvLoading, setCsvLoading] = useState(false);
  const [listView, setListView] = useState(false);

  const history = useHistory();

  const [updateTeam, { loading: teamUpdateLoading, error: teamUpdateError }] = useMutation(UPDATE_TEAM_NAME, {
    onCompleted({ updateTeam }) {
      if (!updateTeam) {
        appToast("Error updating team name");
      } else {
        appToast("Team name updated.");
      }
    },
    onError({ message }) {
      console.error(message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateTeam GraphQL Error: ${message}`,
      });
    },
  });

  const [assignUserToTeam, { loading: assignLoading, error: assignError }] = useMutation(ASSIGN_USER_TO_TEAM, {
    onCompleted({ assignUserToTeam }) {
      console.log("Add admin: ", assignUserToTeam);
      if (!assignUserToTeam) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("User team assignment changed");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `assignUserToTeam GraphQL Error: ${message}`,
      });

      console.log("Error in addUser: ", message);
    },
    update(cache, { data, data: { assignUserToTeam } }) {
      console.log("data in update", data);
      if (!assignUserToTeam.team_id) {
        cache.modify({
          fields: {
            fetchTeams(existingTeams, { readField }) {
              existingTeams.forEach((team_ref: any) => {
                cache.modify({
                  id: cache.identify(team_ref),
                  fields: {
                    active_members(existingMembers, { readField }) {
                      return existingMembers.filter((m: any) => assignUserToTeam.id !== readField("id", m));
                    },
                  },
                });
              });
              return existingTeams;
            },
            fetchUnassignedUsers(existingUsers, { readField }) {
              const newUserRef = cache.writeFragment({
                data: assignUserToTeam,
                fragment: gql`
                  fragment NewUser on User {
                    id
                    role
                    email
                    first_name
                    last_name
                    full_name
                    last_name_first_name
                    phone_number
                    team_name
                    Site {
                      id
                      name
                    }
                    manager_id
                    close_rate
                    metric
                    taking_demos
                    default_availability
                    holds_per_prospect_vs_held_demo_per_dial
                    timezone
                    industry_filter
                    industry_white_list_array
                    sub_industry_white_list_array
                    lead_source_filter
                    lead_source_white_list_array
                    regions_white_list_assign_type
                    industry_white_list_assign_type
                    sub_industry_white_list_assign_type
                    lead_source_white_list_assign_type
                    channel
                    team {
                      id
                      name
                      name
                      average_close_rate
                      average_holds_per_prospect_vs_held_demo_per_dial
                      label
                    }
                    user_custom_availability {
                      id
                      day
                      start_minute
                      start_hour
                      end_minute
                      end_hour
                    }
                  }
                `,
              });
              return [...existingUsers, newUserRef];
            },
          },
        });
      } else {
        // Assign user to team
        cache.modify({
          fields: {
            fetchUnassignedUsers(existingUsers, { readField }) {
              return existingUsers.filter((ref: any) => assignUserToTeam.id !== readField("id", ref));
            },
          },
        });
        cache.modify({
          id: cache.identify(assignUserToTeam.team),
          fields: {
            active_members(existingMembers, { readField }) {
              return [...existingMembers, assignUserToTeam];
            },
          },
        });
      }
    },
  });

  const {
    data: usersData,
    loading: unassignedUsersLoading,
    error: unassignedUsersError,
    refetch: userRefetch,
  } = useQuery(FETCH_UNASSIGNED_USERS, {
    fetchPolicy: "network-only",
    onCompleted({ fetchUnassignedUsers }) {
      setUnassignedUsers(
        fetchUnassignedUsers
          .filter((user: any) => {
            const name = user.full_name.toLowerCase();
            return name.includes(searchText.toLowerCase());
          })
          .sort((a: any, b: any) => {
            if (!sortBy) {
              const aname: string = a.full_name ? a.full_name.toLowerCase() : "";
              const bname = b.full_name ? b.full_name.toLowerCase() : "";
              return aname.localeCompare(bname);
            }
            return b.metric - a.metric;
          }),
      );
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: teamsData, loading: teamsLoading, error: teamsError, refetch: teamRefetch } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "network-only",
    onCompleted({ fetchTeams }) {
      setTeams(
        fetchTeams
          .filter((team: any) => !!team.team_leader)
          ?.map((team: any) => ({
            ...team,
            active_members: team.active_members
              .filter((user: any) => user.role !== "SM" && user.role !== "ADMIN")
              .filter((user: any) => {
                const name = user.full_name.toLowerCase();
                return name.includes(searchText.toLowerCase());
              })
              .sort((a: any, b: any) => {
                if (!sortBy) {
                  const aname: string = a.last_name_first_name ? a.last_name_first_name.toLowerCase() : "";
                  const bname = b.last_name_first_name ? b.last_name_first_name.toLowerCase() : "";
                  return aname.localeCompare(bname);
                }
                return b.metric - a.metric;
              }),
          })),
      );
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: orgData, loading: orgLoading, error: orgError, refetch: orgRefetch } = useQuery(FETCH_ORG, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [repSelect, setRepSelect] = useState(orgLoading ? "" : `${orgData.fetchOrganization.reps_can_view_all_notes}`);

  const loadingState = useMemo(() => {
    return unassignedUsersLoading || !usersData || teamsLoading || !teamsData || orgLoading || !orgData;
  }, [unassignedUsersLoading, usersData, teamsLoading, teamsData, orgLoading, orgData]);

  if (loadingState) {
    return <Loading />;
  }

  if (unassignedUsersError) {
    return <p>Error fetching users</p>;
  }

  if (teamsError) {
    return <p>Error fetching teams</p>;
  }

  if (orgError) {
    return <p>Error org settings</p>;
  }

  console.log("fetchUnassignedUsers: ", usersData);
  console.log("fetchTeams: ", teamsData);

  const all_users = [
    ...usersData.fetchUnassignedUsers,
    ...teamsData.fetchTeams.reduce((acc: any, cv: any) => [...acc, ...cv.active_members], []),
  ];

  const onDragEnd = async (result: any) => {
    console.log("result: ", result);
    const { source, destination, draggableId: user_id } = result;
    console.log("source: ", source);
    console.log("destination: ", destination);
    console.log("destination: ", destination);
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    // If source and destination droppable ids are same, don't change anything
    if (sInd === dInd) {
      return;
    }
    const team_id = dInd === "unassigned" ? undefined : dInd;
    // Can get optimistic response here as well
    console.log("all_users: ", all_users);
    const user_list = all_users.filter((u: any) => u.id === user_id);
    console.log("user_list: ", user_list);
    const optimistic_user = user_list.length ? { ...user_list[0], team_id } : { id: user_id, team_id };

    assignUserToTeam({
      variables: {
        user_id,
        team_id,
      },
      optimisticResponse: { assignUserToTeam: optimistic_user },
    });
    userRefetch();
    teamRefetch();
    // if destination is unassigned, called unassignUser mutation
    // If destination is truthy id, call the assignTeam mutation
  };

  return (
    <>
      {userModal.visible && (
        <UpdateUserModalV2
          close={() => setUserModal(defaultModalUser)}
          {...userModal}
          tileState={{
            teams,
            unassignedUsers,
            setTeams,
            setUnassignedUsers,
          }}
        />
      )}
      <TeamContainerDiv>
        {teams
          .sort((a: any, b: any) => {
            if (a.created_at > b.created_at) {
              return 1;
            } else return -1;
          })
          ?.map((team: any) => (
            <Droppable key={team.id} droppableId={team.id}>
              {(provided, snapshot) => (
                <TeamColumn ref={provided.innerRef} {...provided.droppableProps}>
                  <EditableInputField
                    text={team.name}
                    onSubmit={updateTeam}
                    id={team.id}
                    loading={teamUpdateLoading}
                    error={teamUpdateError}
                    pencil={false}
                  />
                  <ScrollWrap>
                    <TeamManagerWrap>
                      <TeamManagerCap>Sales Manager</TeamManagerCap>
                      <TeamManagerBox>
                        <FlexDiv style={{ padding: "8px" }}>
                          <AppText fontWeight={600}>{team.team_leader.last_name_first_name}</AppText>
                        </FlexDiv>
                        <FlexDiv
                          justify="space-between"
                          align="center"
                          style={{ borderTop: `1px solid ${theme.PILL_LIGHT_GRAY}`, padding: "8px" }}
                        >
                          <FlexDiv align="center" gap={8}>
                            <AppText fontSize={10}>Avg:</AppText>
                            <ColorCard variant="primary">{team.average_close_rate}</ColorCard>
                            <ColorCard variant="secondary">
                              {team.average_holds_per_prospect_vs_held_demo_per_dial}
                            </ColorCard>
                          </FlexDiv>
                          <EditPencil
                            src={iconNewEdit}
                            alt="edit"
                            onClick={() => setUserModal({ ...team.team_leader, visible: true })}
                          />
                        </FlexDiv>
                      </TeamManagerBox>
                    </TeamManagerWrap>
                    {team.active_members
                      .slice()
                      .filter((user: any) => {
                        if (!searchText.length) return true;
                        return user.full_name.toLowerCase().includes(searchText.toLowerCase());
                      })
                      ?.map((user: any, index: any) => (
                        <RepTile user={user} index={index} setUserModal={setUserModal} />
                      ))}
                    {provided.placeholder}
                  </ScrollWrap>
                </TeamColumn>
              )}
            </Droppable>
          ))}
      </TeamContainerDiv>
      <UnassignedRepsTitle>Unassigned Reps</UnassignedRepsTitle>
      <Droppable key={`unassigned`} droppableId={`unassigned`} direction="horizontal">
        {(provided, snapshot) => (
          <UnassignedMembersList ref={provided.innerRef} {...provided.droppableProps}>
            {unassignedUsers
              .slice()
              .filter((user: any) => {
                if (!searchText.length) return true;
                return user.full_name.toLowerCase().includes(searchText.toLowerCase());
              })
              ?.map((user: any, index: number) => (
                <RepTile user={user} index={index} setUserModal={setUserModal} />
              ))}
            {provided.placeholder}
          </UnassignedMembersList>
        )}
      </Droppable>
      {isOnboarding === true && (
        <CenterDiv>
          <SaveAndContinueButton
            onClick={() => {
              if (isOnboarding) {
                history.push(`/onboarding?step=${step + 1}`);
              } else {
                appToast("Updated!");
              }
            }}
          >
            Continue
          </SaveAndContinueButton>
        </CenterDiv>
      )}
    </>
  );
};

interface RepTileProps {
  user: any;
  index: number;
  setUserModal: any;
}

const RepTile: React.FC<RepTileProps> = ({ user, index, setUserModal }) => {
  const [refillDisabled, setRefillDisabled] = useState(false);

  const [refillLeadQueue, { loading: loadingRefill, error: errorRefill }] = useMutation(REFILL_LEAD_QUEUE, {
    onCompleted({ refillLeadQueue }) {
      if (!refillLeadQueue) {
        appToast("Error refilling queue.");
        return;
      }
      appToast("User's queue has been refilled!");
      setRefillDisabled(false);
    },
    onError({ message }) {
      appToast(message);
      setRefillDisabled(false);
      Sentry.captureEvent({
        message: `refillLeadQueue GraphQL Error: ${message}`,
      });
      console.log("Error in refillLeadQueue: ", message);
    },
  });

  return (
    <Draggable key={user.id} draggableId={user.id} index={index}>
      {(provided, snapshot) => (
        <TeamMemberBox ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <FlexDiv justify="space-between" style={{ padding: "8px" }}>
            <FlexDiv align="center" gap={8}>
              <ColorCard variant={user.role === "AE" ? "primary" : "secondary"}>{user.role}</ColorCard>
              <AppText fontWeight={600}>{`${user.last_name}, ${user.first_name}`}</AppText>
            </FlexDiv>
            <MetricText>{user.metric}</MetricText>
          </FlexDiv>
          <FlexDiv
            justify="space-between"
            align="center"
            style={{ borderTop: `1px solid ${theme.PILL_LIGHT_GRAY}`, padding: "8px" }}
          >
            <RefillWrap>
              <RefillIcon src={iconRefresh} alt="refill" style={{ opacity: refillDisabled ? 0.5 : 1 }} />
              <AppText
                variant="primary"
                fontSize={10}
                fontWeight={500}
                style={{ borderBottom: "none", opacity: refillDisabled ? 0.5 : 1 }}
                onClick={async () => {
                  if (!refillDisabled) {
                    setRefillDisabled(true);
                    appToast("Attempting to refill user's lead queue...");
                    await refillLeadQueue({
                      variables: {
                        user_id: user.id,
                      },
                    });
                  }
                }}
              >
                Refill Queue
              </AppText>
            </RefillWrap>
            <EditPencil src={iconNewEdit} alt="edit" onClick={() => setUserModal({ ...user, visible: true })} />
          </FlexDiv>
        </TeamMemberBox>
      )}
    </Draggable>
  );
};

const UnassignedRepsTitle = styled(AppText)`
  font-size: 12px;
  color: black;
  margin: 24px 0px 8px 0px;
`;

const UnassignedMembersList = styled.div`
  display: flex;
  overflow: auto;
  gap: 16px;
  min-height: 80px;
`;

interface ColorCardProps {
  variant?: "primary" | "secondary";
}

const ColorCard = styled.div<ColorCardProps>`
  min-width: 27px;
  text-align: center;
  color: ${(props) => (props.variant === "primary" ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  background-color: ${(props) => (props.variant === "primary" ? theme.TERTIARY500 : theme.PRIMARY500)};
  border-radius: 4px;

  font-weight: 600;
  font-size: 8px;
  padding: 6px 8px 6px 8px;
`;

const TeamMemberBox = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
  margin-bottom: 16px;
  border-radius: 8px;
  border: solid 1px ${theme.PILL_LIGHT_GRAY};
  min-width: 240px;
  max-width: 240px;
  height: fit-content;
  background: ${theme.WHITE_COLOR};
  position: relative;
  cursor: grab;
`;

const MetricText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;

  min-width: 24px;

  font-size: 12px;
  border-left: 1px solid ${theme.PILL_LIGHT_GRAY};
`;

const EditPencil = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const RefillWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;
const RefillIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const TeamManagerWrap = styled.div`
  width: fit-content;
`;

const TeamManagerCap = styled.div`
  width: 100%;
  height: 23px;
  padding: 5.5px 7px 5.5px 7px;

  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.PRIMARY900};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const TeamManagerBox = styled(TeamMemberBox)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-bottom: 16px;
  cursor: unset;
`;

const TeamColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 288px;
  max-width: 288px;
  max-height: 610px;

  :nth-child(odd) {
    background-color: ${theme.PRIMARY100};
  }
  :nth-child(even) {
    background-color: ${theme.WHITE_COLOR};
  }
`;

const ScrollWrap = styled.div`
  padding: 0px 24px 16px 24px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  :hover {
    overflow-y: overlay;
  }
`;

const TeamContainerDiv = styled.div`
  display: flex;
  min-height: 400px;
  max-height: 610px;
  max-width: inherit;
  overflow: auto;
  border-top: 1px solid ${theme.PILL_LIGHT_GRAY};
  border-bottom: 1px solid ${theme.PILL_LIGHT_GRAY};
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

export { SalesTeamsTiles };
