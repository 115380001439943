import * as React from "react";
import { useMutation } from "@apollo/client";
import { FieldArray, Formik, FormikProps } from "formik";
import gql from "graphql-tag";
import styled from "styled-components";
import * as Yup from "yup";
import { CustomFieldType, CustomObjectField, CustomObjectRecord, CustomObjectRow } from "../../__generated__/graphql";

import { theme } from "../../utils/theme";
import { AppText, Loading } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";

import { Modal } from "./Modal";
import { appToast } from "../../utils/toast";
import { CustomField } from "../Field/CustomField";

interface EditCustomObjectFieldsProps {
  showEditCustomObjectFields: boolean;
  closeCustomObjectFieldsModal: () => void;
  lead_id: string;
  customHeight?: boolean;
  custom_object_id: string;
  custom_object_fields: CustomObjectField[];
  selectedRow: CustomObjectRow | null;
  name: string;
}

interface CustomObjectFieldData {
  id: string;
  value: string | number | boolean;
  num_value?: number;
  boo_value?: boolean;
  date_value?: string;
  list_value?: string[];
  type: string;
  key: string;
  options: string[];
  allow_reps_to_edit?: boolean;
}

interface MyFormikProps {
  customObjectFields: CustomObjectFieldData[];
}
const UPSERT_CUSTOM_OBJECT_ROW = gql`
  mutation upsertCustomObjectRow($custom_object_id: String!, $lead_id: String!, $row_id: String, $data: Json) {
    upsertCustomObjectRow(custom_object_id: $custom_object_id, lead_id: $lead_id, row_id: $row_id, data: $data) {
      message
      status
    }
  }
`;

const EditCustomObjectFieldsComponent: React.FC<EditCustomObjectFieldsProps> = ({
  showEditCustomObjectFields,
  closeCustomObjectFieldsModal,
  customHeight = false,
  custom_object_id,
  custom_object_fields,
  lead_id,
  selectedRow,
  ...props
}) => {
  const numberYupValidation = () => ({
    is: "Rate" || "Percentage" || "Number",
    then: Yup.number().typeError("Must be a number").nullable(),
  });

  const textYupValidation = () => ({
    is: "Text",
    then: Yup.string(),
  });

  const booleanYupValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const EditCustomObjectFieldsSchema = Yup.object().shape({
    customObjectFields: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        type: Yup.string().notRequired(),
        value: Yup.string().when("type", textYupValidation()).nullable(),
        list_value: Yup.array().of(Yup.string()),
        boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
        num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
        date_value: Yup.string().typeError("Must be a string").when("type", textYupValidation()).nullable(),
      }),
    ),
  });

  const [upsertCustomObjectRow] = useMutation(UPSERT_CUSTOM_OBJECT_ROW, {
    onCompleted: ({ upsertCustomObjectRow }) => {
      appToast(upsertCustomObjectRow?.message || "");
      closeCustomObjectFieldsModal();
    },
    onError: (error) => {
      appToast(error.message);
    },
    refetchQueries: [
      "fetchAssociatedCustomObjects",
      "fetchCustomObjectRowsFromLead",
      "fetchAllCustomObjectFields",
      "fetchLeadCustomObjectRecords",
    ],
  });

  const existing_data = selectedRow?.record.reduce((acc, record_data) => {
    acc[record_data.object_field_id] = record_data;
    return acc;
  }, {} as { [custom_field_id: string]: CustomObjectRecord });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        customObjectFields: custom_object_fields?.map((customField: CustomObjectField) => {
          let valueToUse = {
            id: customField.id,
            value: "",
            list_value: [],
            type: customField.type,
            key: customField.name,
            options: [],
            allow_reps_to_edit: customField?.allow_reps_to_edit || false,
            is_name_field: customField?.is_name_field || false,
          } as any;
          let optionsToUse = [] as string[];
          switch (customField.type) {
            case CustomFieldType.MultiDropdown:
            case CustomFieldType.Dropdown:
              valueToUse.options = customField?.list_option;
              valueToUse.options = customField?.list_option;
              break;
            default:
              break;
          }

          if (existing_data) {
            switch (customField.type) {
              case CustomFieldType.MultiDropdown:
              case CustomFieldType.MultiText:
                valueToUse.list_value = existing_data[customField.id]?.list_value;
                break;
              case CustomFieldType.Date:
                valueToUse.value = existing_data[customField.id]?.string_value;
                break;
              case CustomFieldType.DateTime:
                valueToUse.date_value = existing_data[customField.id]?.date_value;
                break;
              case CustomFieldType.Boolean:
                valueToUse.boo_value = existing_data[customField.id]?.boo_value;
                break;
              case CustomFieldType.Number:
              case CustomFieldType.Rate:
              case CustomFieldType.Percentage:
                valueToUse.num_value = existing_data[customField.id]?.num_value;
                break;
              default:
                valueToUse.value = existing_data[customField.id]?.string_value;
                break;
            }
          }

          console.log("valueToUse", valueToUse);
          return valueToUse;
        }),
      }}
      validationSchema={EditCustomObjectFieldsSchema}
      onSubmit={async (values) => {
        const data = values?.customObjectFields?.reduce((acc, customField) => {
          switch (customField.type) {
            case CustomFieldType.MultiDropdown:
            case CustomFieldType.MultiText:
              acc[customField.key] = customField?.list_value;
              break;
            case CustomFieldType.Date:
              acc[customField.key] = customField.value;
              break;
            case CustomFieldType.DateTime:
              acc[customField.key] = customField.date_value;
              break;
            case CustomFieldType.Boolean:
              acc[customField.key] = customField.boo_value;
              break;
            case CustomFieldType.Number:
            case CustomFieldType.Rate:
            case CustomFieldType.Percentage:
              acc[customField.key] = customField.num_value;
              break;
            default:
              acc[customField.key] = customField.value;
              break;
          }
          return acc;
        }, {} as { [key: string]: any });

        await upsertCustomObjectRow({
          variables: {
            data: data,
            lead_id: lead_id,
            row_id: selectedRow?.id || undefined,
            custom_object_id: custom_object_id,
          },
        });
      }}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={showEditCustomObjectFields} onClose={closeCustomObjectFieldsModal} closeButtonSize={16}>
            <TitleDiv>
              <TitleText>
                {selectedRow ? "Edit" : "Add"} {props.name} Fields
              </TitleText>
              <CloseButton></CloseButton>
            </TitleDiv>
            <ScrollingDiv customHeight={customHeight}>
              <FieldArray name="customObjectFields">
                {() => (
                  <>
                    {values.customObjectFields?.map((customField, index) => {
                      return (
                        <CustomField
                          customField={customField}
                          setFieldValue={setFieldValue}
                          values={values}
                          fieldName={`customObjectFields`}
                          index={index}
                        />
                      );
                    })}
                  </>
                )}
              </FieldArray>
            </ScrollingDiv>
            <SubmitDiv customHeight={customHeight}>
              <NewAppButton
                onClick={() => {
                  closeCustomObjectFieldsModal();
                }}
              >
                Cancel
              </NewAppButton>
              <NewAppButton variant={"primary"} onClick={submitForm} disabled={false}>
                {isSubmitting ? <Loading color={theme.WHITE_COLOR} /> : "Save"}
              </NewAppButton>
            </SubmitDiv>
          </Modal>
        );
      }}
    </Formik>
  );
};

interface CustomHeightProps {
  customHeight: boolean;
}

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.NEUTRAL100};
  border-bottom: solid 1px ${theme.NEUTRAL200};
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 320px)" : "calc(100vh - 80px - 56px - 47px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 320px)" : "calc(100vh - 80px - 56px - 47px)")};
  overflow: auto;
  width: 100%;
  padding: 32px 24px;
  margin-bottom: ${(props) => (props.customHeight ? "60px" : "0px")};
`;

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;
  height: 80px;
  /* bottom: ${(props) => (props.customHeight ? "0px" : "20px")}; */
  bottom: 0px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 6px;
  cursor: pointer;
  z-index: 5;
`;

export { EditCustomObjectFieldsComponent };
