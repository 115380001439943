import React from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
import { AppIconButton } from "../UI/AppIconButton";
import { theme } from "../../utils/theme";
import { AiOutlineClose } from "react-icons/ai";

interface ModalProps {
  //Modal wrapper, height/width should be determined within the modal content styling itself
  children?: any;
  open?: boolean;
  onClose?: any;
  closeButtonAlignment?: "left" | "right";
  panelPosition?: "left" | "right";

  //overrides
  width?: string | number;
  borderRadius?: string | number;
}

export const PanelModal = ({
  open,
  children,
  onClose,
  closeButtonAlignment = "left",
  panelPosition = "right",
  width,
  borderRadius,
}: ModalProps & React.HTMLProps<HTMLDivElement>) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <>
        <ModalContainer panelPosition={panelPosition} width={width} borderRadius={borderRadius}>
          <div
            style={{
              position: "relative",
            }}
          >
            <CloseButton closeButtonAlignment={closeButtonAlignment} onClick={onClose}>
              <AiOutlineClose size={22} />
            </CloseButton>
          </div>
          {children}
        </ModalContainer>
      </>
      <ModalOverlay onClick={onClose} />
    </>,
    document.getElementById("modal-root")!,
  );
};

interface CloseButtonProps {
  closeButtonAlignment: "left" | "right";
}

interface ModalContainerProps {
  panelPosition: "left" | "right";
  width?: string | number;
  borderRadius?: string | number;
}

const CloseButton = styled(AppIconButton)<CloseButtonProps>`
  position: absolute;
  z-index: 20;
  ${({ closeButtonAlignment }) => (closeButtonAlignment === "right" ? "right: 0px;" : "left: 0px;")}
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 21, 26, 0.5);
  z-index: 999;
`;

const ModalContainer = styled.div<ModalContainerProps>`
  /* transform: translate(-50%, -50%); */
  position: fixed;
  top: 0;
  ${({ panelPosition }) => (panelPosition === "right" ? "right: 0" : "left: 0")};
  background-color: ${theme.WHITE_COLOR};
  z-index: 1000;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? (typeof borderRadius === "number" ? `${borderRadius}px` : borderRadius) : "0px"};
  /* padding: 10px; */
  min-width: ${({ width }) => (width ? (typeof width === "number" ? `${width}px` : width) : "400px")};
  min-height: 100vh;
  max-height: 100vh;
  width: ${({ width }) => (width ? (typeof width === "number" ? `${width}px` : width) : "initial")};
`;
