import { isLoggedInVar, loggedInUser } from "../apollo/cache";
import { appToast } from "./../utils/toast";

export const logout = () => {
  isLoggedInVar(false);
  loggedInUser(JSON.parse("{}"));
  setTimeout(() => {
    // old bug fix for failed queires while logging out. Seems to work well.
    // TODO: find a better solution to requests post logout
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("revenue_forecast");
  }, 0);
};

interface apolloErrorCheck {
  message: string;
  locations: any;
  path: any;
}

export const checkForExpiredSession = ({ message, locations, path }: apolloErrorCheck) => {
  // session expired check
  const expiredSessionError = message?.toLowerCase()?.includes("token expired");

  const expiredSession = isLoggedInVar() && loggedInUser() && expiredSessionError;

  if (expiredSession) {
    console.log(`GLOBAL: [error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    appToast("Your session has expired. Please login again.");
    logout();
    return;
  }
};

export const checkForLoggedOutFromAnotherTab = ({ message, locations, path }: apolloErrorCheck) => {
  const user_id = localStorage.getItem("user_id");
  const appStateIsLoggedIn = isLoggedInVar() && loggedInUser();

  // added for extra safety
  const notOnLoginPage = window.location.pathname !== "/login" && window.location.pathname !== "/";

  const loggedOutFromAnotherTab = message === "Not Authorised!" && !user_id && appStateIsLoggedIn && notOnLoginPage;

  if (loggedOutFromAnotherTab) {
    console.log(`GLOBAL: [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    logout();
    appToast("You have logged out from a different tab. Please log back in.");
    return;
  }
};
