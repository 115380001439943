import React from "react";
import styled from "styled-components";
import { FaCaretDown } from "react-icons/fa";
import { theme } from "../../../utils/theme";
import { PopupOptionsProps } from "./PhoenixPopupOptions";
import PhoenixPopupOptions from "./PhoenixPopupOptions";
interface PhoenixMenuSelectProps extends PopupOptionsProps {
  title?: string;
  onClick: () => void;
  height?: number | string;
  width?: number | string;

  // rest are passed into PhoenixPopupOptions component
  // please refer to PhoenixPopupOptions.tsx for more details on what parameters are accepted
}

const PhoenixMenuSelect = ({ title, onClick, height = 40, width = "100%", ...rest }: PhoenixMenuSelectProps) => {
  return (
    <>
      <PhoenixPopupOptions {...rest} height={height} />
      <MenuSelectContainer height={height} width={width} onClick={onClick}>
        <SelectTitle>{title}</SelectTitle>
        <CaretDownIcon />
      </MenuSelectContainer>
    </>
  );
};

export const MenuSelectContainer = styled.div<{ height?: number | string; width?: number | string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  padding: 6px 12px;
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  height: ${(props) => (typeof props.height === "number" ? `${props.height}px` : props.height)};
  width: ${(props) => (typeof props.width === "number" ? `${props.width}px` : props.width)};
`;

export const SelectTitle = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  margin-right: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CaretDownIcon = styled(FaCaretDown)`
  font-size: 16px;
  color: ${theme.PRIMARY600};
`;

export { PhoenixMenuSelect };
