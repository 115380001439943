import React from "react";
import styled from "styled-components";
import { menu_collapse, menu_expand } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { PhoenixIcon } from "../PhoenixIcon";

interface PhoenixExpandButtonProps {
  // state variable that defines whether or not the component is expanded
  expandedState: boolean;
  // can be any function, should be used to setExpandedState
  onClick?: (e: any) => void;

  /* direction to move
    Note: if moveDirection is 'left', the positional property 'right' must be used.
    if moveDirection is 'right', the positional property 'left' must be used.
  */
  moveDirection: "left" | "right";

  // distance to travel in pixels
  moveDistance: number;

  // position of button on screen
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export const PhoenixExpandButton: React.FC<PhoenixExpandButtonProps> = ({
  top,
  right,
  bottom,
  left,
  moveDirection,
  moveDistance,
  expandedState,
  onClick,
}) => {
  return (
    <Button
      expanded={expandedState}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      moveDirection={moveDirection}
      moveDistance={moveDistance}
      onClick={onClick}
    >
      {expandedState && moveDirection === "left" ? (
        <PhoenixIcon svg={menu_expand} variant="white" pointer={true} />
      ) : expandedState && moveDirection === "right" ? (
        <PhoenixIcon svg={menu_collapse} variant="white" pointer={true} />
      ) : !expandedState && moveDirection === "left" ? (
        <PhoenixIcon svg={menu_collapse} variant="white" pointer={true} />
      ) : (
        <PhoenixIcon svg={menu_expand} variant="white" pointer={true} />
      )}
    </Button>
  );
};

interface ButtonProps {
  expanded: boolean;
  moveDirection: "left" | "right";
  moveDistance: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

const Button = styled.button<ButtonProps>`
  transition: all 0.2s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 2;

  width: 40px;
  height: 40px;
  cursor: pointer;

  top: ${(props) => (!!props.top ? `${props.top}px` : "unset")};
  bottom: ${(props) => (!!props.bottom ? `${props.bottom}px` : "unset")};

  right: ${(props) =>
    !!props.right && !props.expanded
      ? `${props.right}px`
      : !!props.right && props.expanded && props.moveDirection === "left"
      ? `${props.right + props.moveDistance}px`
      : "unset"};
  left: ${(props) =>
    !!props.left && !props.expanded
      ? `${props.left}px`
      : !!props.left && props.expanded && props.moveDirection === "right"
      ? `${props.left + props.moveDistance}px`
      : "unset"};

  border: solid ${theme.NEUTRAL300};
  border-width: ${(props) => {
    if (props.moveDirection) {
      switch (props.moveDirection) {
        case "right":
          return "1px 1px 1px 0px";
        case "left":
          return "1px 0px 1px 1px";
      }
    }
    // default to no left border
    return "1px 1px 1px 0px";
  }};
  border-radius: ${(props) => {
    if (props.moveDirection) {
      switch (props.moveDirection) {
        case "right":
          return "0px 8px 8px 0px";
        case "left":
          return "8px 0px 0px 8px";
      }
    }
    // default to right side radius
    return "0px 8px 8px 0px";
  }};

  background: linear-gradient(135deg, #3e65ff 0%, #7091ff 100%);
  background-size: 100px;
  :hover {
    background-size: 48px;
  }
`;
