import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext } from "react";
import { currentCallState } from "../../../apollo/cache";
import { CallContext, LeadCardContext } from "../../../context";
import { MixpanelActions } from "../../../services/mixpanel";
import { appToast } from "../../../utils/toast";
import { Loading, NewAppButton } from "../../UI";
import { LOG_DISPOSITION } from "./CallDispositionsV2";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SHOW_NEXT_ACTION } from "../../../apollo/query";

const FETCH_DISPOSITION_OPTIONS = gql`
  query fetchDispositionOptions($person_spoke_to: CALLRESULT, $lead_id: String!, $associated_action: String) {
    fetchDispositionOptions(
      person_spoke_to: $person_spoke_to
      lead_id: $lead_id
      associated_action: $associated_action
    ) {
      id
      label
      person_spoke_to
      phase
      next_phase
      next_phase_label
      resting_day
      description
      type
    }
  }
`;

interface NoContactNomessageProps {
  setTransferDemoSubOptions: (transferDemoSubOptions: string) => void;
  isLoadingDisposition: boolean;
  setIsLoadingDisposition: (isLoadingDisposition: boolean) => void;
}

const NoContactNoMessageComponent: React.FC<NoContactNomessageProps> = ({
  setTransferDemoSubOptions,
  isLoadingDisposition,
  setIsLoadingDisposition,
}) => {
  const { selectedTabDialQueue } = useContext(LeadCardContext);
  const { intentId, setPersonSpokeTo, callOptionStackPush, associatedAction, callLeadId, callSid } = useContext(
    CallContext,
  );
  const { data: orgData } = useQuery(SHOW_NEXT_ACTION);

  const showNextAction = orgData?.showNextAction;

  const { data: dataDispositions, loading: loadingDispositions } = useQuery(FETCH_DISPOSITION_OPTIONS, {
    variables: {
      person_spoke_to: "NoContact",
      lead_id: callLeadId,
      associated_action: associatedAction,
    },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      console.log("dataDispositions: ", dataDispositions);
    },
  });

  const [logDisposition, { loading: loadingLogDisposition, error: errorDisposition }] = useMutation(LOG_DISPOSITION, {
    async onCompleted({ logDisposition }) {
      console.log("logDisposition: ", logDisposition);
      // alert(JSON.stringify(logDisposition));
      if (!logDisposition) {
        appToast("Error logging disposition. Please try again.");
        return;
      }
      MixpanelActions.track("Disposition Event", {
        phase: logDisposition?.related_disposition?.phase,
        next_phase: logDisposition?.related_disposition?.next_phase,
        type: logDisposition?.related_disposition?.type,
        person_spoke_to: logDisposition?.related_disposition?.person_spoke_to,
      });
      // This mutation implicitly logs a disposition
      // Make sure to update dispositionLogged if call is still going on
      // If the call has ended, revert back to dashboard

      if (!currentCallState().onCall && !currentCallState().alternate_contact) {
        // revertCallState();
      } else {
        currentCallState({
          ...currentCallState(),
          dispositionLogged: callLeadId !== logDisposition.lead?.id ? currentCallState().dispositionLogged : true,
        });
      }
      appToast("Metrics are being updated.", {}, "");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `logDisposition GraphQL Error: ${message}`,
      });
      console.log("Error in logDisposition: ", message);
      setIsLoadingDisposition(false);
    },
    refetchQueries: ["fetchNextDial", "fetchCustomQueueLeads", "fetchFilteredLeadsInQueueV2"],
  });

  return (
    <>
      <NewAppButton
        style={{ marginTop: 24 }}
        size={"lg"}
        variant={"primary"}
        disabled={
          isLoadingDisposition ||
          loadingDispositions ||
          loadingLogDisposition ||
          dataDispositions?.fetchDispositionOptions?.length === 0
        }
        onClick={async () => {
          console.log("dataDispositions: ", dataDispositions);
          setIsLoadingDisposition(true);
          setPersonSpokeTo("NoContact");

          const noMessageDisposition = dataDispositions?.fetchDispositionOptions
            ?.map((item: any) => item)
            ?.filter((item: any) => item?.person_spoke_to === "NoContact" && item?.type === "NoMessageLeft");

          setTransferDemoSubOptions(noMessageDisposition[0]?.id);
          console.log("noMessageDisposition: ", noMessageDisposition);

          if (!currentCallState()?.dispositionLogged && noMessageDisposition[0]?.id) {
            await logDisposition({
              variables: {
                disposition_id: noMessageDisposition[0]?.id,
                lead_id: callLeadId,
                intent_id: intentId,
                call: true,
                from_custom_call_queue: selectedTabDialQueue === "custom",
                call_sid: callSid,
                release_lead: currentCallState().release_lead,
                scheduled_event_ack: false,
                try_alternate_contact: true, // or !!currentCallState().alternate_contact,
              },
            }).catch((e) => {
              console.error(e);
            });
          } else {
            appToast("No message left disposition ID was not found");
          }
          callOptionStackPush("alternate-contact");
        }}
      >
        {isLoadingDisposition || loadingDispositions || loadingLogDisposition ? <Loading /> : "No Contact, No Message"}
      </NewAppButton>
    </>
  );
};

export { NoContactNoMessageComponent };
