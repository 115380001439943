import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import { cloneDeep, debounce, isEqual } from "lodash";
import moment from "moment";
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SingleDatePicker } from "react-dates";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import * as Yup from "yup";
import { currentCallState, loggedInUser } from "../../../apollo/cache";
import { MRR_LABEL } from "../../../apollo/query";
import { CallContext, CartContext, LeadCardContext } from "../../../context";
import { lock, plus, trash } from "../../../images/NewDesign";
import { getExtraCountryCodes, sortedCountryCodesData } from "../../../static/countries";
import { OptionItem } from "../../../types";
import { extractNumberWithCountry } from "../../../utils/format";
import {
  CustomField,
  SaleFlowPage,
  SaleFlowSection,
  copyToClipboard,
  getLocalStorage,
  getSystemFieldInputType,
  recursiveObjectTrim,
} from "../../../utils/misc";
import { PHONE_REGEX } from "../../../utils/regex";
import { theme } from "../../../utils/theme";
import { appToast, errorToast } from "../../../utils/toast";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { PhoenixInputField } from "../../Field/Phoenix";
import { AppText, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import {
  PhoenixAppButton,
  PhoenixCheckbox,
  PhoenixIcon,
  PhoenixInput,
  PhoenixMultiSelect,
  PhoenixTextArea,
} from "../../UI/Phoenix";
import { StateDropdown } from "../LeadCard/StateDropdown";

const SALE_CONFIG_PAGES = gql`
  query SaleConfigPages {
    fetchOrganization {
      SaleConfig {
        saleConfigPagesVisibleOnly {
          id
          order
          title
          sections {
            id
            required
            type
            content
            system_field
            custom_field {
              id
              value
              num_value
              list_value
              boo_value
              date_value
              type
              key
              options
            }
          }
        }
      }
      OrganizationPaymentSetting {
        id
        contract_duration_options
        contract_term_options
        default_term_duration
        default_contract_duration
        enable_contract_duration
        enable_payment_duration
        require_product_selection
      }
      mrr_required_on_sale
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String) {
    fetchLead(id: $id) {
      id
      associate_parent_id
      first_name
      computed_mrr
      current_close_date
      last_name
      business_name
      primary_phone_number
      primary_email
      address
      city
      state
      country
      local_primary_phone_number
      primary_phone_number_country_code
      timezone_by_state
      zip
      sale_flow_custom_fields {
        id
        computed_value
        key
        value
        list_value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
      }
      custom_fields {
        id
        key
        value
        list_value
        num_value
        boo_value
        date_value
      }
      industry
      sub_industry
      lead_source
    }
  }
`;

const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: String!
    $first_name: String
    $last_name: String
    $business_name: String
    $primary_phone_number: String
    $primary_email: String
    $industry: String
    $sub_industry: String
    $lead_source: String
    $channel: CHANNEL
    $status: String
    $title: String
    $content: String
    $city: String
    $state: String
    $country: String
    $address: String
    $zip: String
    $country_code: String
    $custom_fields: [CustomFieldInput!]
  ) {
    updateLead(
      id: $id
      first_name: $first_name
      last_name: $last_name
      business_name: $business_name
      primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      industry: $industry
      sub_industry: $sub_industry
      lead_source: $lead_source
      channel: $channel
      status: $status
      title: $title
      content: $content
      city: $city
      state: $state
      country: $country
      address: $address
      zip: $zip
      country_code: $country_code
      custom_fields: $custom_fields
    ) {
      id
      first_name
      last_name
      full_name
      business_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      industry
      sub_industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
    }
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const GET_POSSIBLE_LEAD_SOURCES_DATA = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query fetchProducts {
    fetchProducts {
      id
      active
      product_title
      pricing
      available_concessions {
        id
        label
        amount
      }
      show_override_price_option
      pricing_floor
      prepayment_options
    }
  }
`;

const END_CLOSING_SCRIPT = gql`
  mutation endClosingScript {
    endClosingScript {
      id
    }
  }
`;

const MAKE_SALE = gql`
  mutation makeSale(
    $lead_id: String!
    $person_spoke_to: CALLRESULT!
    $products: [ProductInputType!]!
    $intent_id: String
    $call: Boolean
    $notes: String
    $contract_duration: String
    $payment_terms: String
    $from_custom_call_queue: Boolean
    $prepayment: Int
    $call_sid: String
    $pandadoc_sale_id: String
    $mrr: Float
  ) {
    makeSale(
      lead_id: $lead_id
      person_spoke_to: $person_spoke_to
      products: $products
      intent_id: $intent_id
      call: $call
      notes: $notes
      contract_duration: $contract_duration
      payment_terms: $payment_terms
      from_custom_call_queue: $from_custom_call_queue
      prepayment: $prepayment
      call_sid: $call_sid
      pandadoc_sale_id: $pandadoc_sale_id
      mrr: $mrr
    ) {
      id
      lead_id
      related_disposition {
        id
        completed_text
        completed_image
        completed_tip
      }
    }
  }
`;

const FETCH_PANDADOC_TEMPLATE_REP = gql`
  query fetchAllPandaDocTemplateForRep {
    fetchAllPandaDocTemplateForRep {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

const PREVIEW_PANDADOC_DATA = gql`
  query previewPandaDocData(
    $products: [ProductInputType!]!
    $lead_id: String!
    $sale_id: String
    $template_id: String!
    $product_data: ProductDataType!
  ) {
    previewPandaDocData(
      products: $products
      lead_id: $lead_id
      sale_id: $sale_id
      template_id: $template_id
      product_data: $product_data
    )
  }
`;

const CREATE_PANDADOC_DOCUMENT = gql`
  mutation createDocument($previewPandaDocData: PandaDocMappingInput!, $template_id: String!, $lead_id: String) {
    createDocument(previewPandaDocData: $previewPandaDocData, template_id: $template_id, lead_id: $lead_id)
  }
`;

const CHECK_LEAD_PHONE_NUMBER = gql`
  query checkLeadPhoneNumber($phone_number: String!, $country_code: String) {
    checkLeadPhoneNumber(phone_number: $phone_number, country_code: $country_code)
  }
`;

interface CustomMakeSaleProps {
  leadID: string;
  isPandaDocEnabled: boolean;
}

interface MyFormikProps {
  business_name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  address?: string;
  city?: string;
  state?: string;
  country?: { label: string; value: { iso2: string; code: string } };
  country_code?: { label: string; value: { iso2: string; code: string } };
  zip?: string;
  industry?: string;
  sub_industry?: string;
  lead_source?: string;
  customFields: CustomField[];
}

type Product = {
  product_id?: string;
  concession_id?: string;
  price?: number;
  quantity: number;
  prepayment_term?: number;
};

const startingProductValue = [
  { product_id: undefined, concession_id: undefined, price: undefined, quantity: 1, prepayment_term: 1 },
];

export const CustomMakeSale: React.FC<CustomMakeSaleProps> = ({ leadID, isPandaDocEnabled }) => {
  const {
    callOptionStackPush,
    callOptionStackPop,
    getCallLeadId,
    revertCallState,
    callLeadId,
    callSid,
    personSpokeTo,
    intentId,
    isNonCallActivity,
    requireNotes,
    performedNoteAction,
    persistCallNotes,
    setAssociatedAction,
    callCameFromTransfer,
    handleLeadCardRefresh,
  } = useContext(CallContext);

  const organization_id = loggedInUser().organization_id;

  const { setCartSaleID, pandadoc_sale_id, setPandaDocId } = useContext(CartContext);
  const { selectedTabDialQueue } = useContext(LeadCardContext);

  const [pages, setPages] = useState([]);
  const [pageNum, setPageNum] = useState(1);

  const [selectedTemplateID, setSelectedTemplateID] = useState<string | undefined>(undefined);
  const [pandaDocSaleData, setPandaDocSaleData] = useState<{ name: string; value: string }[]>([]);

  const [mrr, setMrr] = useState<string | undefined>(undefined);

  const [products, setProducts] = useState<Product[]>(startingProductValue);

  const [contractDuration, setContractDuration] = useState<OptionItem | undefined>(undefined);
  const [paymentTerms, setPaymentTerms] = useState<OptionItem | undefined>(undefined);

  const [productRequired, setProductRequired] = useState<boolean>(true);

  const [saleNotes, setSaleNotes] = useState<string | undefined>(undefined);

  const [dateFocused, setDateFocused] = useState<"None" | "Date" | "DateTime">("None");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>(undefined);

  const [doFadeAnimation, setDoFadeAnimation] = useState<boolean>(true);
  const [alertFields, setAlertFields] = useState<string[]>([]);

  const debouncedChange = useMemo(
    () =>
      debounce(async (value) => {
        setPhoneNumber(extractNumberWithCountry(value));
        await checkLeadPhoneNumber({ variables: { phone_number: value } });
      }, 500),
    [],
  );

  const curPage: SaleFlowPage = useMemo(() => pages[pageNum - 1], [pages, pageNum]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (doFadeAnimation) {
      timeout = setTimeout(() => {
        setDoFadeAnimation(false);
      }, 1000);
    }
    return () => {
      if (!!timeout) clearTimeout(timeout);
    };
  }, [doFadeAnimation]);

  const emptyProductValue = useMemo(
    () => products.some((p) => !p.product_id || !p.prepayment_term || (!p.price && p.price !== 0)),
    [products],
  );

  const isPandaDocPage = useMemo(() => !!curPage?.sections?.find((s) => s.type === "PANDADOC"), [curPage]);

  const { data: dataPages, loading: loadingPages, error: errorPages } = useQuery(SALE_CONFIG_PAGES, {
    fetchPolicy: "network-only",
    onCompleted: ({ fetchOrganization }) => {
      console.log("SaleConfigPages:", fetchOrganization);

      const {
        SaleConfig: { saleConfigPagesVisibleOnly },
        OrganizationPaymentSetting: {
          default_term_duration,
          default_contract_duration,
          enable_contract_duration,
          enable_payment_duration,
          require_product_selection,
        },
      } = fetchOrganization;

      setPages(saleConfigPagesVisibleOnly.slice().sort((a: SaleFlowPage, b: SaleFlowPage) => a.order - b.order));
      enable_contract_duration &&
        setContractDuration({ label: default_contract_duration, value: default_contract_duration });
      enable_payment_duration && setPaymentTerms({ label: default_term_duration, value: default_term_duration });

      setProductRequired(require_product_selection);
    },
    onError: ({ message, name }) => {
      console.log(`Error in ${name}: `, message);
    },
  });

  const contractDurationOptions = useMemo(
    () =>
      dataPages?.fetchOrganization?.OrganizationPaymentSetting?.contract_duration_options?.map((o: string) => ({
        label: o,
        value: o,
      })) || [],
    [dataPages],
  );
  const paymentTermsOptions = useMemo(
    () =>
      dataPages?.fetchOrganization?.OrganizationPaymentSetting?.contract_term_options?.map((o: string) => ({
        label: o,
        value: o,
      })) || [],
    [dataPages],
  );

  const { data: dataLead, loading: loadingLead } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: leadID },
    onCompleted({ fetchLead }) {
      setPhoneNumber(fetchLead?.local_primary_phone_number || "");
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: industryOptions } = useQuery(GET_POSSIBLE_INDUSTRIES_DATA, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: leadSourceOptions } = useQuery(GET_POSSIBLE_LEAD_SOURCES_DATA, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataProducts, loading: loadingProducts } = useQuery(FETCH_PRODUCTS, {
    // variables: {
    //   orderBy: [{ amount: "asc" }],
    // },
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const finalProducts = useMemo(() => {
    if (!productRequired && isEqual(products, startingProductValue)) {
      return [];
    }

    return products?.map((p: any) => {
      const hasPriceOverride =
        dataProducts?.fetchProducts?.find((fp: any) => fp.id === p.product_id)?.pricing !== p.price;
      return {
        product_id: p.product_id,
        quantity: parseInt(p.quantity),
        concession_id: p.concession_id,
        price_override: hasPriceOverride ? p.price : undefined,
        prepayment: p.prepayment_term,
      };
    });
  }, [products, dataProducts, productRequired]);

  const { data: mrrLabel } = useQuery(MRR_LABEL);

  const [updateLead, { loading: loadingUpdate }] = useMutation(UPDATE_LEAD, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      errorToast("Error confirming lead. Something went wrong.");
    },
  });

  const { data: dataVisibleTemplates } = useQuery(FETCH_PANDADOC_TEMPLATE_REP, {
    fetchPolicy: "network-only",
  });
  const [
    fetchPandaDocPreview,
    { data: dataPandaDocPreview, loading: loadingDocPreview, error: errorDocPreview },
  ] = useLazyQuery(PREVIEW_PANDADOC_DATA, {
    fetchPolicy: "network-only",
    onCompleted: ({ previewPandaDocData }) => {
      setPandaDocSaleData(previewPandaDocData?.tokens || []);
    },
  });

  const [checkLeadPhoneNumber] = useLazyQuery(CHECK_LEAD_PHONE_NUMBER, {
    fetchPolicy: "network-only",
    variables: {
      phone_number: extractNumberWithCountry(phoneNumber),
    },
    onCompleted({ checkLeadPhoneNumber }) {
      // turn off phone number validations for all orgs
      setPhoneNumberError(undefined);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [endClosingScript] = useMutation(END_CLOSING_SCRIPT);

  const [makeSale, { loading: loadingSale }] = useMutation(MAKE_SALE, {
    async onCompleted({ makeSale }) {
      console.log("makeSale: ", makeSale);
      endClosingScript();
      if (!makeSale) {
        errorToast("Error making sale!");
        return;
      }

      setCartSaleID(makeSale?.id);

      const callLeadId = getCallLeadId();
      const isSameCallLead =
        callLeadId === makeSale.lead_id || dataLead?.fetchLead?.associate_parent_id === makeSale.lead_id;

      // This mutation implicitly logs a disposition
      // Make sure to update dispositionLogged if call is still going on
      // If the call has ended, revert back to dashboard
      if (!currentCallState().onCall && isSameCallLead) {
        revertCallState();
      } else {
        currentCallState({
          ...currentCallState(),
          dispositionLogged: isSameCallLead ? true : currentCallState().dispositionLogged,
        });
        handleLeadCardRefresh && handleLeadCardRefresh();
      }

      appToast(`${makeSale.related_disposition.completed_text}`, {}, "");
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `makeSale GraphQL Error: ${message}`,
      });
      console.log("Error in makeSale: ", message);
    },
    refetchQueries: ["fetchMySchedule", "fetchCustomQueueLeads", "fetchNextDial", "fetchFilteredLeadsInQueueV2"],
  });

  const [createDocument, { loading: loadingCreateDocument }] = useMutation(CREATE_PANDADOC_DOCUMENT, {
    async onCompleted({ createDocument }) {
      console.log("createDocument: ", createDocument);
      if (!createDocument) {
        appToast("Error creating PandaDoc");
        return;
      }
      if (createDocument?.id) {
        setPandaDocId(createDocument?.id);
      }
      appToast("Document is being sent");
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `createDocument GraphQL Error: ${message}`,
      });
      console.log("Error in createDocument: ", message);
    },
  });

  const saleConfigCustomFields = useMemo(() => {
    const sections =
      dataPages?.fetchOrganization?.SaleConfig?.saleConfigPagesVisibleOnly?.reduce(
        (acc: any, page: any) => acc.concat(page?.sections || []),
        [],
      ) || [];
    return sections.filter((section: SaleFlowSection) => section.type === "CUSTOM_FIELD");
  }, [dataPages]);

  /** Generates a dynamic Yup schema based on the organizations SaleConfig preferences */
  const createValidationSchema = useCallback(() => {
    const sections = pages.reduce((acc, page: any) => acc.concat(page?.sections || []), []);

    // list of fields that should not be generated in the schema
    const excludeList: string[] = [
      "product_selection",
      "contract_duration",
      "payment_terms",
      "lead_id_label",
      "sale_note",
    ];

    const filteredSections = sections.filter((section: SaleFlowSection) => {
      const dataKey: keyof MyFormikProps =
        section?.system_field === "PRIMARY_PHONE_NUMBER"
          ? "phone_number"
          : section?.system_field === "ZIP_CODE"
          ? "zip"
          : (section?.system_field?.toLowerCase() as keyof MyFormikProps);
      return !excludeList.includes(dataKey) && !!section.system_field;
    });
    const validationSchema = Yup.object().shape({
      ...filteredSections.reduce((acc: any, section: any) => {
        const dataKey: keyof MyFormikProps =
          section?.system_field === "ZIP_CODE"
            ? "zip"
            : section?.system_field === "COUNTRY"
            ? "country_code"
            : section?.system_field === "PRIMARY_PHONE_NUMBER"
            ? "phone_number"
            : section?.system_field?.toLowerCase();

        if (dataKey === "phone_number") {
          return {
            ...acc,
            [dataKey]: Yup.string()
              .required("Phone number is a required field")
              .matches(PHONE_REGEX, "Must be a valid phone number")
              .max(15, "Must be valid phone number")
              .min(7, "Must be valid phone number")
              .when("country_code", {
                is: (country_code) => country_code?.value?.iso2 === "US",
                then: Yup.string().length(10, "US phone numbers must be 10 digits"),
              }),
          };
        }

        return {
          ...acc,
          [dataKey]: section.required
            ? Yup.string().required(`${section?.content} is a required field`).trim()
            : Yup.string().trim(),
        };
      }, {}),

      customFields: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          type: Yup.string().notRequired(),
          value: Yup.string().when("type", textYupValidation()).nullable(),
          boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
          num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
        }),
      ),
    });

    return validationSchema;
  }, [pages, phoneNumber]);

  const getCustomFieldValues = useCallback(
    (e: SaleFlowSection) => {
      const cf = dataLead?.fetchLead?.custom_fields?.find((cf: any) => cf?.key === e?.custom_field?.key);
      return {
        value: cf?.value,
        boo_value: cf?.boo_value,
        num_value: cf?.num_value,
        date_value: cf?.date_value,
        list_value: cf?.list_value,
      };
    },
    [dataLead],
  );

  if (loadingPages || loadingLead || loadingProducts) {
    return <Loading />;
  }

  if (!!errorPages) {
    return (
      <div>
        <AppText fontSize={14} color={theme.DANGER600}>
          Error loading custom sale configuration.{" "}
          <span
            onClick={() => callOptionStackPush("confirm-details")}
            style={{ color: theme.PRIMARY500, cursor: "pointer" }}
          >
            Click here
          </span>{" "}
          to view legacy sale configuration.
        </AppText>
      </div>
    );
  }

  const orgCountryCodeOptions = getLocalStorage("country_code_options", []);

  const defaultPlusExtraCountryOptions = [
    ...sortedCountryCodesData,
    ...getExtraCountryCodes(orgCountryCodeOptions),
  ].sort((a, b) => {
    if (a?.value?.iso2 === "US") return -1;
    if (b?.value?.iso2 === "US") return 1;
    return a?.label?.localeCompare(b?.label);
  });

  return (
    <CustomMakeSaleWrap>
      <Formik
        initialValues={{
          business_name: dataLead?.fetchLead?.business_name || "",
          first_name: dataLead?.fetchLead?.first_name || "",
          last_name: dataLead?.fetchLead?.last_name || "",
          phone_number: dataLead?.fetchLead?.local_primary_phone_number || "",
          email: dataLead?.fetchLead?.primary_email || "",
          address: dataLead?.fetchLead?.address || "",
          city: dataLead?.fetchLead?.city || "",
          state: dataLead?.fetchLead?.state || "",
          country_code:
            defaultPlusExtraCountryOptions.find(
              (countryCode) => countryCode.value.iso2 === dataLead?.fetchLead?.country,
            ) ?? defaultPlusExtraCountryOptions[0],
          zip: dataLead?.fetchLead?.zip || "",
          industry: dataLead?.fetchLead?.industry || "",
          sub_industry: dataLead?.fetchLead?.sub_industry || "",
          lead_source: dataLead?.fetchLead?.lead_source || "",
          customFields: saleConfigCustomFields?.map((e: SaleFlowSection) => ({
            ...e.custom_field,
            ...getCustomFieldValues(e),
          })),
        }}
        onSubmit={async (values) => {
          // custom field validation was taken from legacy make sale flow
          console.log("Formik values: ", values);

          // check validation
          for (const k of saleConfigCustomFields.filter((scf: SaleFlowSection) => !!scf.required)) {
            const cf = values.customFields.find((cf: CustomField) => cf?.id === k.custom_field?.id);
            switch (cf?.type) {
              case "Text":
              case "Dropdown":
                if (!cf?.value) {
                  return errorToast(`${cf?.key} is a required field!`);
                }
                break;
              case "MultiDropdown":
              case "MultiText":
                if (!cf?.list_value?.length) {
                  return errorToast(`${cf?.key} is a required field!`);
                }
                break;
              case "DateTime":
                if (!cf?.date_value) {
                  return errorToast(`${cf?.key} is a required field!`);
                }
                break;
              case "Date":
                if (!cf?.value) {
                  return errorToast(`${cf?.key} is a required field!`);
                }
                break;
              case "Number":
              case "Rate":
              case "Percentage":
                if (!cf?.num_value && !cf?.value) {
                  return errorToast(`${cf?.key} is a required field!`);
                }
                break;
            }
          }

          const val: any = cloneDeep({ ...values });
          recursiveObjectTrim(val);

          const final_custom_fields = val.customFields?.map((customField: CustomField) => {
            switch (customField.type) {
              case "Dropdown":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "MultiDropdown":
                return {
                  id: customField.id,
                  // @ts-ignore
                  list_value: customField.list_value?.map((k) => k.value ?? k),
                };
              case "DateTime":
                return {
                  id: customField.id,
                  date_value: customField.date_value,
                };
              case "Date":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Number":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.value as any),
                };
              case "Text":
                return {
                  id: customField.id,
                  value: customField.value,
                };
              case "Boolean":
                return {
                  id: customField.id,
                  boo_value: customField.boo_value || false,
                };
              case "Rate":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              case "Percentage":
                return {
                  id: customField.id,
                  num_value: parseFloat(customField.num_value as any),
                };
              default:
                return {
                  id: customField.id,
                  value: customField.value,
                };
            }
          });

          // replace key with snake_case
          val.custom_fields = final_custom_fields;
          delete val.customFields;

          await updateLead({
            variables: {
              id: leadID,
              ...val,
              primary_email: val?.email,
              primary_phone_number: `+${val?.country_code?.value?.code || "1"}${val.phone_number}`,
              country: val?.country_code?.value?.iso2,
              country_code: val?.country_code?.value?.code,
            },
          });

          await makeSale({
            variables: {
              lead_id: callLeadId,
              person_spoke_to: personSpokeTo,
              products: finalProducts,
              notes: saleNotes,
              contract_duration: contractDuration?.value,
              payment_terms: paymentTerms?.value,
              intent_id: intentId,
              call: !isNonCallActivity,
              from_custom_call_queue: selectedTabDialQueue === "custom",
              call_sid: callSid,
              mrr: mrr ? parseFloat(Number.isInteger(mrr) ? mrr + ".0" : mrr.toString()) : undefined,
              pandadoc_sale_id,
            },
          });
        }}
        validationSchema={createValidationSchema()}
        validateOnMount={true}
      >
        {({ submitForm, values, setFieldValue, isSubmitting, isValid }: FormikProps<MyFormikProps>) => {
          const loadingSubmit = loadingUpdate || isSubmitting || loadingSale;
          const isFinalPage = pageNum === pages.length;
          const saleNoteRequired = curPage?.sections?.find((s) => s.system_field === "SALE_NOTE")?.required;

          const missingRequiredFields =
            curPage?.sections?.filter((val) => {
              if (val.custom_field) return false;

              const dataKey: keyof MyFormikProps =
                val?.system_field === "PRIMARY_PHONE_NUMBER"
                  ? "phone_number"
                  : val?.system_field === "ZIP_CODE"
                  ? "zip"
                  : val?.system_field === "COUNTRY"
                  ? "country_code"
                  : (val?.system_field?.toLowerCase() as keyof MyFormikProps);

              if (val.system_field === "CONTRACT_DURATION") return val.required && !contractDuration;
              if (val.system_field === "PAYMENT_TERMS") return val.required && !paymentTerms;

              return (
                val.required &&
                !values[dataKey] &&
                (val.system_field !== "PRODUCT_SELECTION" || emptyProductValue) &&
                (val.system_field !== "SALE_NOTE" || !saleNotes)
              );
            }) || [];

          const missingProductFields = Array.from(
            new Set(
              products
                ?.map((p) => {
                  if (!missingRequiredFields.find((mrf) => mrf.system_field === "PRODUCT_SELECTION")) return [];

                  const missingFields = [];
                  if (!p.product_id) missingFields.push("PRODUCT_ID");
                  if (!p.price) missingFields.push("PRICE");
                  if (!p.prepayment_term) missingFields.push("PREPAYMENT_TERM");
                  return missingFields;
                })
                .flat(),
            ),
          );

          const missingFields = [
            ...missingRequiredFields,
            ...missingProductFields?.map((mpf: string) => {
              const content = mpf === "PRODUCT_ID" ? "Product" : mpf === "PRICE" ? "Price" : "Prepayment Term";
              return { system_field: mpf, content };
            }),
          ];

          const loadingState = loadingLead || loadingSubmit || loadingCreateDocument;

          const nextDisabled =
            loadingState ||
            !!missingFields?.length ||
            (isFinalPage && !isValid) ||
            (isPandaDocPage && !selectedTemplateID) ||
            (isFinalPage && !!currentCallState().dispositionLogged) ||
            (isFinalPage && emptyProductValue && productRequired) ||
            (saleNoteRequired && !saleNotes) ||
            (isFinalPage && !!phoneNumberError) ||
            // cannot finish the sale if admin configured requireNotes & user did not perform note action
            (isFinalPage && requireNotes && !performedNoteAction);

          ReactTooltip.rebuild();

          return (
            <>
              {(!!missingFields?.length || (requireNotes && !performedNoteAction)) && (
                <PhoenixStyledTooltip id="missing-required-field" place="top" />
              )}

              <Header>
                <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                  {curPage?.title}
                </AppText>
              </Header>

              <Body doFadeAnimation={doFadeAnimation} id="make-sale-flow-body">
                {curPage?.sections?.map((section: SaleFlowSection) => {
                  if (section.system_field === "PRODUCT_SELECTION") {
                    return (
                      <ProductSelection
                        products={products}
                        setProducts={setProducts}
                        dataProducts={dataProducts}
                        key={section.id}
                        alertFields={alertFields}
                        setAlertFields={setAlertFields}
                      />
                    );
                  }

                  if (section.system_field === "CONTRACT_DURATION") {
                    return (
                      <FlexDiv direction="column" gap={4} key={section.id}>
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          Contract Duration
                          <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                        </AppText>
                        <PhoenixMultiSelect
                          name={"contract_duration"}
                          isMulti={false}
                          value={contractDuration}
                          onChange={(e: OptionItem) => {
                            setContractDuration(e);
                          }}
                          options={contractDurationOptions}
                          width={342}
                          // marginBottom={false}
                          isClearable={false}
                        />
                      </FlexDiv>
                    );
                  }

                  if (section.system_field === "PAYMENT_TERMS") {
                    return (
                      <FlexDiv direction="column" gap={4} key={section.id}>
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          Payment Terms
                          <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                        </AppText>
                        <PhoenixMultiSelect
                          name={"payment_terms"}
                          isMulti={false}
                          value={paymentTerms}
                          onChange={(e: OptionItem) => {
                            setPaymentTerms(e);
                          }}
                          options={paymentTermsOptions}
                          width={342}
                          // marginBottom={false}
                          isClearable={false}
                        />
                      </FlexDiv>
                    );
                  }

                  if (section.system_field === "LEAD_ID_LABEL") {
                    return (
                      <AppText
                        fontSize={12}
                        fontWeight={500}
                        lineHeight={18}
                        style={{ marginBottom: "8px", borderBottom: "none", cursor: "pointer" }}
                        onClick={() => copyToClipboard(leadID)}
                      >
                        Lead ID: {leadID}
                      </AppText>
                    );
                  }

                  if (section.system_field === "SALE_NOTE") {
                    return (
                      <FlexDiv direction="column" gap={8} style={{ paddingTop: "18px" }}>
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          Sale Notes:
                          {section.required && <span style={{ color: theme.PILL_DARK_RED }}>*</span>}
                        </AppText>
                        <PhoenixTextArea
                          placeholder="Type your sale notes here..."
                          value={saleNotes}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setSaleNotes(e.target.value)}
                          fixedSize
                          width={342}
                          height={200}
                        />
                      </FlexDiv>
                    );
                  }

                  if (section.type === "SYSTEM_FIELD") {
                    return renderSystemField(
                      section,
                      values,
                      setFieldValue,
                      {
                        industryData: industryOptions.fetchIndustryOptions,
                        leadSourceData: leadSourceOptions.fetchLeadSourceOptions,
                      },
                      setMrr,
                      alertFields,
                      setAlertFields,
                      mrrLabel?.getMrrLabel,
                      debouncedChange,
                      phoneNumberError,
                    );
                  }

                  if (section.type === "CUSTOM_FIELD") {
                    return renderCustomField(section, values, setFieldValue, dateFocused, setDateFocused);
                  }

                  if (["HEADER", "NOTE", "WARNING", "LINK"].includes(section.type)) {
                    return (
                      <div key={section.id} style={{ padding: "0px 42px" }}>
                        {renderSection(section)}
                      </div>
                    );
                  }

                  if (section.type === "PANDADOC") {
                    return (
                      <div>
                        {dataVisibleTemplates?.fetchAllPandaDocTemplateForRep && (
                          <PhoenixMultiSelect
                            name="template_id"
                            isMulti={false}
                            isClearable={false}
                            options={[
                              { label: "Select template...", value: "" },
                              ...dataVisibleTemplates?.fetchAllPandaDocTemplateForRep
                                .slice()
                                .sort((a: any, b: any) =>
                                  `${a?.template_name} ${a?.template_id}`
                                    .toLocaleLowerCase()
                                    .localeCompare(`${b?.template_name} ${b?.template_id}`.toLocaleLowerCase()),
                                )
                                ?.map((item: any) => ({
                                  label: item.template_name,
                                  value: item.template_id,
                                })),
                            ]}
                            onChange={async (e: OptionItem) => {
                              if (!e.value) return;

                              setSelectedTemplateID(e.value as string);

                              await fetchPandaDocPreview({
                                variables: {
                                  products: finalProducts,
                                  lead_id: leadID,
                                  template_id: e.value,
                                  product_data: {
                                    contract_duration: contractDuration?.value,
                                    payment_terms: paymentTerms?.value,
                                  },
                                },
                              });
                            }}
                            width={342}
                          />
                        )}

                        <ScrollDiv>
                          {loadingDocPreview && <Loading />}
                          {errorDocPreview && <AppText variant="error">{errorDocPreview.message}</AppText>}
                          {dataPandaDocPreview?.previewPandaDocData?.tokens?.map((value: any, index: number) => {
                            return (
                              <div key={`${value.name} ${index}`}>
                                <TemplateFieldLabel>{value.name}</TemplateFieldLabel>
                                <PhoenixInput
                                  value={pandaDocSaleData[index]?.value || ""}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const newPandaDocSaleData = [...pandaDocSaleData];
                                    newPandaDocSaleData[index] = {
                                      name: value?.name || "",
                                      value: e?.target?.value || "",
                                    };
                                    setPandaDocSaleData(newPandaDocSaleData);
                                  }}
                                  width={342}
                                  displayNoContextText
                                />
                              </div>
                            );
                          })}
                        </ScrollDiv>
                      </div>
                    );
                  }

                  return null;
                })}
              </Body>
              {!isNonCallActivity &&
                isFinalPage &&
                currentCallState().onCall &&
                loggedInUser().allow_external_transfer &&
                loggedInUser().organization?.allow_external_transfer && (
                  <ButtonsDiv>
                    <PhoenixAppButton
                      variant={"brand-outline"}
                      buttonType={"secondary"}
                      buttonTextFontSize={10}
                      uppercase
                      onClick={() => {
                        persistCallNotes();
                        callOptionStackPush("external-transfer");
                        setAssociatedAction(undefined);
                      }}
                      disabled={
                        !callLeadId ||
                        personSpokeTo === "NoContact" ||
                        !currentCallState().onCall ||
                        !!callCameFromTransfer
                      }
                    >
                      Transfer To External Number
                    </PhoenixAppButton>
                  </ButtonsDiv>
                )}

              <ButtonContainer justify="space-between">
                <PhoenixAppButton
                  variant={"danger-outline"}
                  buttonType={"secondary"}
                  buttonTextFontSize={10}
                  uppercase
                  onClick={() => {
                    if (pageNum === 1) {
                      callOptionStackPop();
                    } else {
                      setPageNum(pageNum - 1);
                    }
                  }}
                >
                  Back
                </PhoenixAppButton>

                <FlexDiv gap={8} align="center">
                  {!!isPandaDocPage && (
                    <PhoenixAppButton
                      variant={"brand-outline"}
                      buttonType={"secondary"}
                      buttonTextFontSize={10}
                      uppercase
                      onClick={async () => {
                        if (isFinalPage) {
                          // on the last page of custom make sale flow config
                          await submitForm();
                        } else {
                          // increment page if not on last page
                          setPageNum(pageNum + 1);
                        }
                      }}
                      disabled={
                        loadingState ||
                        (isFinalPage && !!currentCallState().dispositionLogged) ||
                        (isFinalPage && emptyProductValue && productRequired) ||
                        (isFinalPage && !isValid) ||
                        (isFinalPage && !!phoneNumberError)
                      }
                    >
                      Skip
                    </PhoenixAppButton>
                  )}

                  {loadingSubmit && !isPandaDocPage && <Loading />}

                  <PhoenixAppButton
                    variant={"brand"}
                    buttonType={"secondary"}
                    buttonTextFontSize={10}
                    uppercase
                    style={{
                      minWidth: isFinalPage ? 142 : undefined,
                      opacity: nextDisabled ? 0.2 : 1,
                      cursor: nextDisabled ? "unset" : "pointer",
                      outline: nextDisabled ? "none" : undefined,
                    }}
                    onClick={async () => {
                      if (nextDisabled) {
                        setAlertFields(
                          missingFields?.map((val: any) => {
                            if (val?.system_field === "PRODUCT_SELECTION") {
                              return "PRODUCT_SELECTION";
                            }
                            if (["PRODUCT_ID", "PRICE", "PREPAYMENT_TERM"].includes(val?.system_field as string)) {
                              return val?.system_field;
                            }
                            return val?.id;
                          }),
                        );
                        return;
                      }

                      if (isPandaDocPage && isPandaDocEnabled) {
                        // create pandadoc if on pandadoc page
                        const newDoc = {
                          ...dataPandaDocPreview?.previewPandaDocData,
                          recipients: [
                            {
                              ...dataPandaDocPreview?.previewPandaDocData?.recipients?.[0],
                              first_name: values.first_name,
                              last_name: values.last_name,
                              email: values.email,
                            },
                          ],
                          tokens: pandaDocSaleData,
                        };

                        await createDocument({
                          variables: {
                            template_id: selectedTemplateID,
                            previewPandaDocData: newDoc,
                            lead_id: leadID,
                          },
                        });
                      }

                      if (isFinalPage) {
                        // on the last page of custom make sale flow config
                        await submitForm();
                      } else {
                        // increment page if not on last page
                        setPageNum(pageNum + 1);
                      }
                    }}
                    data-tip={
                      !!missingFields?.length
                        ? `Missing required ${missingFields.length > 1 ? "fields" : "field"}: ${missingFields
                            .filter((val) => val?.system_field !== "PRODUCT_SELECTION")
                            ?.map((val) => `'${val.content}'`)
                            .join(", ")}`
                        : requireNotes && !performedNoteAction
                        ? "Please add a note before proceeding."
                        : ""
                    }
                    data-for={
                      !!missingFields?.length || (requireNotes && !performedNoteAction) ? "missing-required-field" : ""
                    }
                  >
                    {isFinalPage ? "Confirm Sale" : isPandaDocPage ? "Send Document" : "Next"}
                  </PhoenixAppButton>
                </FlexDiv>
              </ButtonContainer>
            </>
          );
        }}
      </Formik>
    </CustomMakeSaleWrap>
  );
};

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  /* height: 100%; */
`;

const ScrollDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 600px;
`;

const ClosingScriptText = styled(AppText)`
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0px 24px;
`;

const TemplateFieldLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
`;

const CustomMakeSaleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;

  padding: 24px 0px;
`;

interface BodyProps {
  doFadeAnimation: boolean;
}

const Body = styled.div<BodyProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  padding-bottom: 24px;

  & > * {
    animation: ${(props) => props.doFadeAnimation && theme.fadeIn} 0.3s ease-in-out forwards;
  }
`;

const ButtonContainer = styled(FlexDiv)`
  padding: 16px 64px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;

const numberYupValidation = () => ({
  is: "Rate" || "Percentage" || "Number",
  then: Yup.number().typeError("Must be a number").nullable(),
});

const textYupValidation = () => ({
  is: "Text",
  then: Yup.string(),
});

const booleanYupValidation = () => ({
  is: "Boolean",
  then: Yup.boolean().notRequired(),
});

const orgCountryCodeOptions = getLocalStorage("country_code_options", []);

const defaultPlusExtraCountryOptions = [...sortedCountryCodesData, ...getExtraCountryCodes(orgCountryCodeOptions)];

const renderSystemField = (
  section: SaleFlowSection,
  values: MyFormikProps,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  fetchData: { industryData: any; leadSourceData: any },
  setMrr: Dispatch<SetStateAction<string | undefined>>,
  alertFields: string[],
  setAlertFields: Dispatch<SetStateAction<string[]>>,
  mrrLabel?: string,
  debouncedChange?: (value: string) => void,
  phoneNumberError?: string,
) => {
  const { required, content, system_field } = section;
  const inputType = getSystemFieldInputType(system_field);
  const dataKey: keyof MyFormikProps =
    system_field === "PRIMARY_PHONE_NUMBER"
      ? "phone_number"
      : system_field === "ZIP_CODE"
      ? "zip"
      : (system_field?.toLowerCase() as keyof MyFormikProps);

  const value = values[dataKey];

  const { industryData, leadSourceData } = fetchData;

  const industryOptions =
    industryData?.map((industry: { id: string; label: string; sub_industries: any[] }) => ({
      label: industry.label,
      value: industry.label,
      subIndustries: industry.sub_industries?.map((si) => ({ label: si, value: si })) || [],
    })) || [];
  const leadSourceOptions = leadSourceData?.map((ls: { id: string; label: string }) => ({
    label: ls.label,
    value: ls.label,
  }));
  const countryOptions: any[] = defaultPlusExtraCountryOptions.map((country) => ({
    label: country.label,
    value: country,
  }));

  if (dataKey === "state") {
    return (
      <FlexDiv direction="column" gap={4} style={{ width: "342px" }} key={section.id}>
        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
          {content}
          {required && <span style={{ color: theme.PILL_DARK_RED }}>*</span>}
        </AppText>

        <StateDropdown
          callback={setFieldValue}
          country_code={values?.country_code?.value?.iso2 || "US"}
          current_value={values?.state}
          // marginBottom={false}
        />
      </FlexDiv>
    );
  }

  return (
    <FlexDiv direction="column" gap={4} key={section.id}>
      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
        {system_field === "MRR" && !!mrrLabel ? mrrLabel : content}
        {required && <span style={{ color: theme.PILL_DARK_RED }}>*</span>}
        {!!phoneNumberError && system_field === "PRIMARY_PHONE_NUMBER" && (
          <span style={{ fontSize: "12px", color: theme.ATTENTION700, paddingLeft: "16px" }}>{phoneNumberError}</span>
        )}
      </AppText>

      {inputType === "Dropdown" && (
        <PhoenixMultiSelect
          name={dataKey}
          isMulti={false}
          value={
            dataKey === "country"
              ? countryOptions.find((co) => co?.value?.value?.iso2 === values?.country_code?.value?.iso2)
              : { label: value, value: value }
          }
          onChange={(e: any) => {
            if (dataKey === "industry" && !!values.sub_industry) {
              setFieldValue("sub_industry", undefined);
            }

            if (dataKey === "country") {
              const countryCode = e?.value?.value?.code;
              setFieldValue("country_code", countryCode ? e?.value : "");
              return;
            }

            setFieldValue(dataKey, e.value);

            if (alertFields.includes(section.id) && !!e.value) {
              setAlertFields(alertFields.filter((val) => val !== section.id));
            }
          }}
          options={
            dataKey === "industry"
              ? industryOptions
              : dataKey === "sub_industry"
              ? industryOptions.find((io: OptionItem) => io.value === values.industry)?.subIndustries || []
              : dataKey === "lead_source"
              ? leadSourceOptions
              : dataKey === "country"
              ? countryOptions
              : []
          }
          width={342}
          // marginBottom={false}
          isClearable={false}
          style={{ boxShadow: alertFields.includes(section.id) ? `0px 0px 3px 1px ${theme.DANGER600}` : undefined }}
        />
      )}

      {inputType === "Text" && (
        <FlexDiv gap={16}>
          {system_field === "PRIMARY_PHONE_NUMBER" && (
            <AppText
              fontSize={12}
              fontWeight={400}
              lineHeight={18}
              color={theme.NEUTRAL300}
              style={{ paddingTop: "12px" }}
            >
              +{values?.country_code?.value?.code || "1"}
            </AppText>
          )}
          <PhoenixInputField
            name={dataKey}
            value={value}
            onChange={(e) => {
              let val = e.target.value;
              if (system_field === "PRIMARY_PHONE_NUMBER") {
                val = val.replace(/\D/g, ""); // Remove non-numeric characters
                debouncedChange && debouncedChange(val);
              }
              if (system_field === "MRR") {
                val = val.replace(/\D/g, "");
                setMrr(val);
              }
              setFieldValue(dataKey, val);

              if (alertFields.includes(section.id) && !!val.length) {
                setAlertFields(alertFields.filter((val) => val !== section.id));
              }
            }}
            width={system_field === "PRIMARY_PHONE_NUMBER" ? 310 : 342}
            style={{ boxShadow: alertFields.includes(section.id) ? `0px 0px 3px 1px ${theme.DANGER600}` : undefined }}
            // displayNoContextText
          />
        </FlexDiv>
      )}
    </FlexDiv>
  );
};

const renderCustomField = (
  section: SaleFlowSection,
  values: MyFormikProps,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  dateFocused: "None" | "Date" | "DateTime",
  setDateFocused: Dispatch<SetStateAction<"None" | "Date" | "DateTime">>,
) => {
  const { required, content, custom_field } = section;

  const inputType = custom_field?.type || "";
  const customFieldValues = values.customFields;
  const customField = customFieldValues.find((cf) => cf?.id === custom_field?.id);

  return (
    <FlexDiv
      gap={8}
      direction={inputType === "Boolean" ? "row" : "column"}
      style={{ alignSelf: "flex-start", padding: "0px 0px 18px 62px" }}
      key={section.id}
    >
      {inputType === "Boolean" && (
        <PhoenixCheckbox
          checked={typeof customField?.boo_value === "boolean" ? customField.boo_value : false}
          onChange={() => {
            const newCustomFields = cloneDeep(customFieldValues);
            const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
            if (customField) {
              newCustomFields[idx].boo_value = !customField.boo_value;
            }
            setFieldValue("customFields", newCustomFields);
          }}
        />
      )}

      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
        {content}
        {required && <span style={{ color: theme.PILL_DARK_RED }}>*</span>}
      </AppText>

      {["Dropdown", "MultiDropdown", "MultiText"].includes(inputType) && (
        <PhoenixMultiSelect
          isMulti={inputType === "MultiDropdown"}
          isClearable={inputType === "MultiDropdown"}
          name={content || ""}
          value={
            inputType === "Dropdown"
              ? { label: customField?.value, value: customField?.value }
              : ["MultiDropdown", "MultiText"].includes(inputType)
              ? customField?.list_value?.map((item: string) => ({ label: item, value: item })) || []
              : undefined
          }
          options={
            customFieldValues
              .find((cf) => cf?.id === custom_field?.id)
              ?.options?.map((o: string) => ({ label: o, value: o })) || []
          }
          onChange={(e: any) => {
            const newCustomFields = cloneDeep(customFieldValues);
            const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);

            if (["MultiDropdown", "MultiText"].includes(inputType)) {
              newCustomFields[idx].list_value = e?.map((item: OptionItem) => item.value);
            } else {
              newCustomFields[idx].value = e.value;
            }
            setFieldValue("customFields", newCustomFields);
          }}
          width={342}
          marginBottom={false}
        />
      )}

      {["Text", "Rate", "Percentage", "Number"].includes(inputType) && (
        <FlexDiv align="center" gap={16}>
          <PhoenixInputField
            name={custom_field?.key || ""}
            value={customField?.value?.toString()}
            onChange={(e) => {
              const newVal = e.target.value;

              if (inputType === "Number" && isNaN(parseInt(newVal))) return;

              const newCustomFields = cloneDeep(customFieldValues);
              const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
              newCustomFields[idx].value = newVal;
              setFieldValue("customFields", newCustomFields);
            }}
            width={inputType === "Percentage" ? 310 : 342}
            displayNoContextText
            style={{ borderColor: theme.NEUTRAL300, cursor: "default" }}
            inputValueType={inputType === "Number" ? "number" : "string"}
            handleNumberArrowUpClick={() => {
              if (inputType === "Number") {
                const newCustomFields = cloneDeep(customFieldValues);
                const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
                const newVal =
                  typeof customField?.value === "string" && !isNaN(parseInt(customField.value))
                    ? parseInt(customField.value) + 1
                    : customField?.value;
                newCustomFields[idx].value = newVal?.toString() || "";
                setFieldValue("customFields", newCustomFields);
              }
            }}
            handleNumberArrowDownClick={() => {
              if (inputType === "Number") {
                const newCustomFields = cloneDeep(customFieldValues);
                const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
                const newVal =
                  typeof customField?.value === "string" && !isNaN(parseInt(customField?.value || "0"))
                    ? parseInt(customField.value) - 1
                    : customField?.value;
                newCustomFields[idx].value = newVal?.toString() || "";
                setFieldValue("customFields", newCustomFields);
              }
            }}
            showNumberArrows={inputType === "Number"}
          />
          {inputType === "Percentage" && (
            <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.NEUTRAL300}>
              %
            </AppText>
          )}
        </FlexDiv>
      )}

      {inputType === "BigText" && (
        <PhoenixTextArea
          name={custom_field?.key}
          value={customField?.value?.toString()}
          onChange={(e) => {
            const newVal = e.target.value;
            const newCustomFields = cloneDeep(customFieldValues);
            const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
            newCustomFields[idx].value = newVal;
            setFieldValue("customFields", newCustomFields);
          }}
          style={{ borderColor: theme.NEUTRAL300, cursor: "default" }}
          ableToResize={false}
          width={342}
          height={160}
          hideError
        />
      )}

      {inputType === "DateTime" && (
        <SingleDatePicker
          openDirection={"up"}
          id={customFieldValues.find((cf) => cf?.id === custom_field?.id)?.id || ""}
          date={moment(customFieldValues.find((cf) => cf?.id === custom_field?.id)?.date_value || undefined)}
          onDateChange={(date: any) => {
            const newCustomFields = cloneDeep(customFieldValues);
            const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
            newCustomFields[idx].date_value = date;
            setFieldValue("customFields", newCustomFields);
          }}
          focused={dateFocused === "DateTime"}
          onFocusChange={({ focused }) => setDateFocused(focused ? "DateTime" : "None")}
          numberOfMonths={1}
          showDefaultInputIcon
          inputIconPosition={"after"}
        />
      )}
      {inputType === "Date" && (
        <SingleDatePicker
          openDirection={"up"}
          id={customFieldValues.find((cf) => cf?.id === custom_field?.id)?.id || ""}
          date={moment(
            (customFieldValues.find((cf) => cf?.id === custom_field?.id)?.value as string | null | undefined) ||
              undefined,
          )}
          onDateChange={(date: any) => {
            const newCustomFields = cloneDeep(customFieldValues);
            const idx = newCustomFields.findIndex((cf) => cf?.id === custom_field?.id);
            newCustomFields[idx].value = date;
            setFieldValue("customFields", newCustomFields);
          }}
          focused={dateFocused === "Date"}
          onFocusChange={({ focused }) => setDateFocused(focused ? "Date" : "None")}
          numberOfMonths={1}
          showDefaultInputIcon
          inputIconPosition={"after"}
        />
      )}
    </FlexDiv>
  );
};

const renderSection = (section: SaleFlowSection) => {
  const { type, content } = section;

  switch (type) {
    case "HEADER":
      return (
        <AppText
          fontSize={10}
          fontWeight={600}
          uppercase
          letterSpacing={1}
          key={section.id}
          style={{ marginBottom: "18px" }}
        >
          {content}
        </AppText>
      );
    case "NOTE":
      return (
        <AppText fontSize={12} fontWeight={500} lineHeight={18} key={section.id} style={{ marginBottom: "18px" }}>
          {content}
        </AppText>
      );
    case "WARNING":
      return (
        <AppText
          fontSize={12}
          fontWeight={500}
          lineHeight={18}
          color={theme.PILL_DARK_RED}
          key={section.id}
          style={{ marginBottom: "18px" }}
        >
          {content}
        </AppText>
      );
    case "LINK":
      return (
        <AppText
          fontSize={12}
          fontWeight={500}
          lineHeight={18}
          color={theme.PRIMARY500}
          style={{ borderBottom: `1px solid ${theme.PRIMARY500}`, cursor: "pointer", marginBottom: "18px" }}
          onClick={() => window.open(content, "_blank", "noreferrer")}
          key={section.id}
        >
          {content}
        </AppText>
      );
    default:
      return null;
  }
};

interface ProductSelectionProps {
  products: Product[];
  setProducts: Dispatch<SetStateAction<Product[]>>;
  dataProducts: any;
  alertFields: string[];
  setAlertFields: Dispatch<SetStateAction<string[]>>;
}

const ProductSelection: React.FC<ProductSelectionProps> = ({
  products,
  setProducts,
  dataProducts,
  alertFields,
  setAlertFields,
}) => {
  const productOptions = useMemo(
    () =>
      !!dataProducts?.fetchProducts
        ? dataProducts.fetchProducts?.map((item: any) => ({
            label: `${item?.product_title} ($${item?.pricing})`,
            value: item?.id,
            prepayment_options:
              [...item?.prepayment_options]
                ?.sort((a: number, b: number) => a - b)
                ?.map((o: number) => ({ label: o, value: o })) || [],
            show_override_price_option: item?.show_override_price_option,
            pricing: item?.pricing || 0,
            pricing_floor: item?.pricing_floor || 0,
            available_concessions:
              [...item?.available_concessions]?.sort(
                (a: { id: string; amount: number; label: string }, b: { id: string; amount: number; label: string }) =>
                  a.amount - b.amount,
              ) || [],
          }))
        : [],
    [dataProducts],
  );

  const combinedTotalPrice = useMemo(() => {
    return products.reduce((acc, p) => {
      const selectedProduct = productOptions.find((po: any) => po?.value === p.product_id);
      const discountAmount = selectedProduct?.available_concessions?.find((c: any) => c?.id === p.concession_id)
        ?.amount;

      return acc + Math.max((p.price || 0) * (p.quantity || 1) - (discountAmount || 0), 0);
    }, 0);
  }, [products, productOptions]);

  const canClearAlert = useMemo(() => {
    const everyProductHasPrepaymentTerms = products.every((p) => !!p.prepayment_term);
    const everyProductHasID = products.every((p) => !!p.product_id);
    const everyProductHasPrice = products.every((p) => p.price !== undefined && !isNaN(p.price));

    return everyProductHasPrepaymentTerms && everyProductHasID && everyProductHasPrice;
  }, [products]);

  useEffect(() => {
    if (canClearAlert && alertFields.includes("PRODUCT_SELECTION")) {
      setAlertFields(
        alertFields.filter((val) => ["PRODUCT_ID", "PRICE", "PREPAYMENT_TERM", "PRODUCT_SELECTION"].includes(val)),
      );
    }
  }, [canClearAlert]);

  return (
    <>
      {products?.map((p, i) => {
        const selectedProduct = productOptions.find((po: any) => po?.value === p.product_id);
        const discountValue = {
          label: selectedProduct?.available_concessions?.find((c: any) => c?.id === products[i]?.concession_id)?.label,
          value: products[i]?.concession_id,
          amount: selectedProduct?.available_concessions?.find((c: any) => c?.id === products[i]?.concession_id)
            ?.amount,
        };

        const totalPrice = Math.max(
          (products[i]?.price || 0) * (products[i]?.quantity || 1) - (discountValue.amount || 0),
          0,
        );

        return (
          <ProductContainer direction="column" key={`product-${p.product_id}`} id={`product-${i + 1}`}>
            <ProductPreviewBody gap={24} direction="column" id={`product-${i + 1}-body`}>
              <FlexDiv justify="space-between" align="center">
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  uppercase
                  letterSpacing={1}
                  lineHeight={16}
                  color={theme.PRIMARY500}
                >
                  Product {i + 1}
                </AppText>

                {i !== 0 && (
                  <PhoenixIcon
                    svg={trash}
                    size={16}
                    color={theme.DANGER600}
                    hoverColor={theme.DANGER600}
                    pointer
                    onClick={() => {
                      const newProducts = cloneDeep(products);
                      newProducts.splice(i, 1);
                      setProducts(newProducts);
                    }}
                  />
                )}
              </FlexDiv>

              <FlexDiv gap={8} direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Product
                  <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                </AppText>
                <PhoenixMultiSelect
                  isMulti={false}
                  name={`product-selection-${p.product_id}`}
                  placeholder="Select a product"
                  value={selectedProduct || undefined}
                  onChange={(e: any) => {
                    const newProducts = cloneDeep(products);
                    newProducts[i] = {
                      product_id: e.value,
                      concession_id: undefined,
                      quantity: 1,
                      prepayment_term: undefined,
                      price: e.pricing,
                    };
                    setProducts(newProducts);
                  }}
                  options={productOptions}
                  width={306}
                  marginBottom={false}
                  isClearable={false}
                  style={{
                    boxShadow:
                      alertFields.includes("PRODUCT_SELECTION") && !selectedProduct
                        ? `0px 0px 3px 1px ${theme.DANGER600}`
                        : undefined,
                  }}
                />
              </FlexDiv>
              <FlexDiv gap={8} direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Quantity
                  <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                </AppText>

                <PhoenixInput
                  name={`product-quantity-${i}`}
                  inputValueType="number"
                  value={products[i].quantity || 1}
                  showNumberArrows
                  displayNoContextText
                  disabled={!selectedProduct}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newProducts = cloneDeep(products);
                    newProducts[i].quantity = Math.max(0, parseInt(e.target.value));
                    setProducts(newProducts);
                  }}
                  handleNumberArrowUpClick={() => {
                    const newProducts = cloneDeep(products);
                    newProducts[i].quantity = newProducts[i].quantity + 1;
                    setProducts(newProducts);
                  }}
                  handleNumberArrowDownClick={() => {
                    const newProducts = cloneDeep(products);
                    newProducts[i].quantity = Math.max(0, newProducts[i].quantity - 1);
                    setProducts(newProducts);
                  }}
                />
              </FlexDiv>
              <FlexDiv gap={8} direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Prepayment Terms
                  <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                </AppText>
                <PhoenixMultiSelect
                  isMulti={false}
                  name={`product-prepayment-${i}`}
                  value={{ label: products[i].prepayment_term, value: products[i].prepayment_term }}
                  options={selectedProduct?.prepayment_options || []}
                  onChange={(e: any) => {
                    const newProducts = cloneDeep(products);
                    newProducts[i].prepayment_term = e.value;
                    setProducts(newProducts);
                  }}
                  width={306}
                  marginBottom={false}
                  isClearable={false}
                  isDisabled={!selectedProduct}
                  menuPortal
                  menuShouldBlockScroll
                  menuPosition="fixed"
                  style={{
                    boxShadow:
                      alertFields.includes("PRODUCT_SELECTION") && !products[i].prepayment_term
                        ? `0px 0px 3px 1px ${theme.DANGER600}`
                        : undefined,
                  }}
                />
              </FlexDiv>

              <FlexDiv gap={24} align="center">
                <FlexDiv gap={8} direction="column">
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Price
                    <span style={{ color: theme.PILL_DARK_RED }}>*</span>
                  </AppText>
                  <FlexDiv align="center" gap={8}>
                    <PhoenixInput
                      name={`product-price-${i}`}
                      inputValueType="number"
                      placeholder="$000"
                      value={products[i].price}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newProducts = cloneDeep(products);
                        newProducts[i].price = Math.max(selectedProduct?.pricing_floor || 0, parseInt(e.target.value));

                        // if price is less than concession amount, remove concession amount
                        if (
                          typeof newProducts[i]?.price === "number" &&
                          typeof discountValue?.amount === "number" &&
                          newProducts[i].price! < discountValue?.amount
                        ) {
                          newProducts[i].concession_id = undefined;
                        }
                        setProducts(newProducts);
                      }}
                      displayNoContextText
                      readOnly={!selectedProduct?.show_override_price_option}
                      width={130}
                      style={{
                        borderColor: theme.NEUTRAL300,
                        cursor: !selectedProduct?.show_override_price_option ? "default" : "pointer",
                        boxShadow:
                          alertFields.includes("PRODUCT_SELECTION") && !products[i].price
                            ? `0px 0px 3px 1px ${theme.DANGER600}`
                            : undefined,
                      }}
                      onFocus={(e) => !selectedProduct?.show_override_price_option && e.target.blur()}
                    />
                    {!selectedProduct?.show_override_price_option && (
                      <PhoenixIcon svg={lock} size={16} variant="neutral" />
                    )}
                  </FlexDiv>
                </FlexDiv>

                <FlexDiv gap={8} direction="column">
                  <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                    Discount
                  </AppText>
                  <PhoenixMultiSelect
                    isMulti={false}
                    isClearable={false}
                    name={`product-discount-${i}`}
                    value={
                      typeof products[i]?.price === "number" &&
                      typeof discountValue?.amount === "number" &&
                      products[i].price! < discountValue?.amount
                        ? null
                        : discountValue
                    }
                    options={
                      selectedProduct?.available_concessions
                        ?.filter((c: { id: string; label: string; amount: number }) => c.amount <= products[i]?.price!)
                        ?.map((o: { id: string; label: string; amount: number }) => ({
                          label: o.label,
                          value: o.id,
                        })) || []
                    }
                    onChange={(e: any) => {
                      const newProducts = cloneDeep(products);
                      newProducts[i].concession_id = e.value;
                      setProducts(newProducts);
                    }}
                    marginBottom={false}
                    isDisabled={!selectedProduct || products[i].price === 0}
                    width={130}
                    menuPortal
                    menuShouldBlockScroll
                    menuPosition="fixed"
                  />
                </FlexDiv>
              </FlexDiv>
            </ProductPreviewBody>

            <ProductPreviewFooter>
              <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                Total Price: ${totalPrice}
              </AppText>
            </ProductPreviewFooter>
          </ProductContainer>
        );
      })}

      <FlexDiv
        align="center"
        gap={4}
        style={{ cursor: "pointer", alignSelf: "flex-start", paddingLeft: "58px", marginBottom: "16px" }}
        onClick={() => {
          setProducts([
            ...products,
            { product_id: undefined, concession_id: undefined, quantity: 1, prepayment_term: 1 },
          ]);
        }}
      >
        <PhoenixIcon svg={plus} size={16} color={theme.PRIMARY500} pointer />
        <AppText fontSize={10} fontWeight={600} lineHeight={18} color={theme.PRIMARY500} uppercase letterSpacing={1}>
          Add Product
        </AppText>
      </FlexDiv>

      {products.length > 1 && (
        <AppText
          fontSize={14}
          fontWeight={500}
          lineHeight={18}
          style={{ alignSelf: "flex-start", paddingLeft: "60px", marginBottom: "24px" }}
        >
          Total Price: ${combinedTotalPrice}
        </AppText>
      )}
    </>
  );
};

const ProductContainer = styled(FlexDiv)`
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  overflow: hidden;
  min-height: 468px;
`;

const ProductPreviewBody = styled(FlexDiv)`
  padding: 16px 16px 24px 16px;
`;

const ProductPreviewFooter = styled.div`
  padding: 16px 32px;
  background-color: ${theme.PRIMARY50};
`;
