import React, { Dispatch, SetStateAction, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText } from "../AppText";
import { PhoenixIcon, PhoenixMultiSelect } from "../Phoenix";
import { getLeaderboardRank, handleLeaderboardSort, leaderboardMetricOptions } from "../../../utils/sequences";
import { OptionItem } from "../../../types";
import { caret_down, star_filled } from "../../../images/NewDesign";
import { AllSequenceFetchShape, SequenceLeaderboardItem, SequenceLeaderboardSort } from "../../../types/SequenceTypes";
import { useQuery } from "@apollo/client";
import { FETCH_SEQUENCE_LEADERBOARD } from "../../../utils/sequence-reporting";
import { Loading } from "../Loading";

interface SequenceLeaderboardProps {
  sequenceData: AllSequenceFetchShape[];
  timeframe: { lowerbound_date: Date | string | undefined; upperbound_date: Date | string | undefined };
  filterBySequences: string[];
  filterByPhases: string[];
  filterBySalesTeamsAndReps: { reps: string[]; roles: string[]; teams: string[]; sites: string[] };
  leaderboardMetric: { label: string; value: string };
  setLeaderboardMetric: Dispatch<SetStateAction<{ label: string; value: string }>>;
  leaderboardOrder: SequenceLeaderboardSort;
  setLeaderboardOrder: Dispatch<SetStateAction<SequenceLeaderboardSort>>;
}

export const SequenceLeaderboard: React.FC<SequenceLeaderboardProps> = ({
  sequenceData,
  timeframe,
  filterBySequences,
  filterByPhases,
  filterBySalesTeamsAndReps,
  leaderboardMetric,
  setLeaderboardMetric,
  leaderboardOrder,
  setLeaderboardOrder,
}) => {
  const { lowerbound_date, upperbound_date } = useMemo(() => timeframe, [timeframe]);

  const { data: dataLeaderboard, loading: loadingLeaderboard } = useQuery(FETCH_SEQUENCE_LEADERBOARD, {
    fetchPolicy: "network-only",
    variables: {
      sequenceDashboardInput: {
        lowerbound_date,
        upperbound_date,
        sequence_ids: sequenceData
          ?.filter((sequence: any) => (!!filterBySequences.length ? filterBySequences?.includes(sequence?.id) : true))
          ?.filter((sequence: any) =>
            !!filterByPhases.length
              ? filterByPhases.includes(sequence?.sequence_entry_criteria?.current_phase?.[0])
              : true,
          )
          ?.map((sequence: any) => sequence?.id),
        team_ids: filterBySalesTeamsAndReps?.teams,
        user_ids: filterBySalesTeamsAndReps?.reps,
        site_ids: filterBySalesTeamsAndReps?.sites,
      },
      sortBy: leaderboardMetric?.value,
    },
    onCompleted({ fetchSequenceReps }) {
      console.log("fetchSequenceReps:", fetchSequenceReps);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const leaderboardData = useMemo(
    () =>
      dataLeaderboard?.fetchSequenceReps
        .slice()
        .sort((a: SequenceLeaderboardItem, b: SequenceLeaderboardItem) =>
          handleLeaderboardSort(leaderboardMetric.value, leaderboardOrder, a, b),
        ),
    [dataLeaderboard, leaderboardOrder],
  );

  return (
    <LeaderboardContainer>
      <LeaderboardHeader>Leaderboard</LeaderboardHeader>
      <LeaderboardBody>
        <MetricDropdownContainer>
          <AppText fontSize={12} fontWeight={500} lineHeight={18}>
            Metric
          </AppText>
          <PhoenixMultiSelect
            name="sequence-leaderboard-metric"
            options={leaderboardMetricOptions}
            value={leaderboardMetric}
            onChange={(item: { label: string; value: string }) => setLeaderboardMetric(item)}
            width={243}
            minHeight={40}
            maxHeight={40}
            marginBottom={false}
            isMulti={false}
            isClearable={false}
          />
        </MetricDropdownContainer>

        <LoadingOverlay style={{ opacity: loadingLeaderboard ? 1 : 0 }}>
          <div style={{ height: "fit-content" }}>
            <Loading />
          </div>
        </LoadingOverlay>

        <RowContainer>
          <Row>
            <Cell width={61} padding="8px 16px">
              <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                Rank
              </AppText>
            </Cell>
            <Cell
              width={155}
              padding="8px 16px"
              pointer
              onClick={() =>
                leaderboardOrder === "rep-desc" ? setLeaderboardOrder("rep-asc") : setLeaderboardOrder("rep-desc")
              }
            >
              <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                Team Member
              </AppText>
              <PhoenixIcon
                svg={caret_down}
                size={16}
                color={theme.PRIMARY500}
                fillIcon
                pointer
                style={{
                  marginLeft: "8px",
                  display: !leaderboardOrder.includes("rep") ? "none" : undefined,
                  transform: leaderboardOrder.includes("asc") ? "rotate(180deg)" : undefined,
                }}
              />
            </Cell>
            <Cell
              width={104}
              padding="8px 16px"
              pointer
              onClick={() =>
                leaderboardOrder === "rate-desc" ? setLeaderboardOrder("rate-asc") : setLeaderboardOrder("rate-desc")
              }
            >
              <AppText fontSize={12} fontWeight={600} lineHeight={18}>
                {leaderboardMetric?.value === "fastest_tasks_completed" ? "Minutes" : "Count"}
              </AppText>
              <PhoenixIcon
                svg={caret_down}
                size={16}
                color={theme.PRIMARY500}
                fillIcon
                pointer
                style={{
                  marginLeft: "8px",
                  display: !leaderboardOrder.includes("rate") ? "none" : undefined,
                  transform: leaderboardOrder.includes("asc") ? "rotate(180deg)" : undefined,
                }}
              />
            </Cell>
          </Row>

          {leaderboardData?.map((data: SequenceLeaderboardItem) => {
            const leaderboardRank = getLeaderboardRank(dataLeaderboard?.fetchSequenceReps, data);
            return (
              <Row key={`row-${data.id}`}>
                <Cell width={61} padding="12px" justify="center">
                  <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                    {leaderboardRank > 3 ? leaderboardRank : generateLeaderboardStar(leaderboardRank)}
                  </AppText>
                </Cell>
                <Cell width={155} padding="11px 16px">
                  <AppText
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={18}
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {data?.name}
                  </AppText>
                </Cell>
                <Cell width={104} padding="11px 16px">
                  <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                    {data?.rate}
                  </AppText>
                </Cell>
              </Row>
            );
          })}
        </RowContainer>
      </LeaderboardBody>
    </LeaderboardContainer>
  );
};

const LeaderboardContainer = styled.div`
  min-width: 320px;
  min-height: 100%;

  overflow: hidden;
`;

const LeaderboardHeader = styled.div`
  width: 100%;
  height: 32px;
  padding: 8px 16px;

  color: ${theme.WHITE_COLOR};
  background-color: ${theme.PRIMARY900};

  font-size: 12px;
  font-weight: 600;
  line-height: 18px;

  border-radius: 8px 8px 0px 0px;
`;

const Row = styled.div`
  display: flex;
  height: 40px;
`;

const LeaderboardBody = styled.div`
  position: relative;

  width: 100%;
  height: 95%;

  overflow: hidden;

  background: ${theme.WHITE_COLOR};

  border: 1px solid ${theme.NEUTRAL300};
  border-top: none;
  border-radius: 0px 0px 8px 8px;

  & ${Row}:nth-child(odd) {
    background: ${theme.PRIMARY50};
  }
`;

const MetricDropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 72px;
`;

const RowContainer = styled.div`
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  padding-bottom: 16px;
`;

interface CellProps {
  width: number;
  padding?: string;
  justify?: string;
  pointer?: boolean;
}

export const Cell = styled.div<CellProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify && props.justify};

  width: ${(props) => props.width}px;
  height: 100%;
  padding: ${(props) => props.padding && props.padding};

  cursor: ${(props) => props.pointer && "pointer"};
  user-select: ${(props) => props.pointer && "none"};
`;

/** Generates leaderboard stars. Gold, Silver, Bronze.
 * @param rank number : The rank of the leaderboard item.
 * @returns JSX.Element : The leaderboard star.
 */
export const generateLeaderboardStar = (rank: number) => {
  switch (rank) {
    case 1:
      return (
        <PhoenixIcon
          svg={star_filled}
          size={16}
          variant="alert"
          color={theme.WARNING500}
          fillIcon
          hoverColor={theme.WARNING500}
        />
      );
    case 2:
      return (
        <PhoenixIcon
          svg={star_filled}
          size={16}
          variant="neutral"
          color={theme.NEUTRAL300}
          fillIcon
          hoverColor={theme.NEUTRAL300}
        />
      );
    case 3:
      return <PhoenixIcon svg={star_filled} size={16} variant="brown" color={theme.WARNING700} fillIcon />;
    default:
      return rank;
  }
};

const LoadingOverlay = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  padding-top: 25%;

  display: flex;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.75);

  transition: opacity 0.25s ease-in-out;
  pointer-events: none;
`;
