import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { theme } from "../../utils/theme";
import { gql, useQuery, useMutation } from "@apollo/client";
import { AppErrorText, AppText, Loading, NewAppButton } from "../UI";
import { useState, useEffect } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import moment from "moment";
import { formatBusinessName, formatUSD } from "../../utils/format";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";
import { ConfirmDeletePopup } from "../modal";
import { toast } from "react-toastify";
import { appToast } from "../../utils/toast";
import InfiniteScroll from "react-infinite-scroll-component";

export const FETCH_LEAD_POOL_DETAILS = gql`
  query fetchLeadPoolDetails(
    $metric: LeadPoolMetrics
    $organization_id: String
    $team_id: String
    $user_id: String
    $lead_source: String
    $lead_creation_source: String
    $industry: String
    $take: Int
    $skip: Int
    $sort_option: LeadPoolSortOption
    $ascending: Boolean
  ) {
    fetchLeadPoolDetails(
      metric: $metric
      organization_id: $organization_id
      team_id: $team_id
      user_id: $user_id
      lead_source: $lead_source
      lead_creation_source: $lead_creation_source
      industry: $industry
      take: $take
      skip: $skip
      sort_option: $sort_option
      ascending: $ascending
    ) {
      id
      full_name
      industry
      phase
      business_name
      phase_label
      lead_source
      num_of_dials
      lead_value {
        value
      }
      most_recent_disposition {
        related_disposition {
          label
        }
        user {
          full_name
        }
      }
      next_lead_intent {
        user {
          full_name
        }
        schedule_item {
          start_time
        }
      }
      next_scheduled_agent {
        id
        full_name
      }
      rep {
        id
        full_name
      }
      last_skipped_reason
      last_skipped_date
    }
  }
`;

export const BULK_DELETE_LEADS = gql`
  mutation bulkDeleteLeads(
    # $metric: LeadPoolMetrics
    # $organization_id: String
    # $lead_count: Int!
    $team_id: String
    $user_id: String
    $lead_source: String
    $lead_creation_source: LEADCREATIONSOURCE
    $industry: String
  ) {
    bulkDeleteLeads(
      # metric: $metric
      # organization_id: $organization_id
      # lead_count: $lead_count
      team_id: $team_id
      user_id: $user_id
      lead_source: $lead_source
      lead_creation_source: $lead_creation_source
      industry: $industry
    )
  }
`;

export const EXPORT_LEADS = gql`
  mutation exportLeads(
    $metric: LeadPoolMetrics
    $organization_id: String
    $team_id: String
    $user_id: String
    $lead_source: String
    $lead_creation_source: String
    $industry: String
  ) {
    exportLeads(
      metric: $metric
      organization_id: $organization_id
      team_id: $team_id
      user_id: $user_id
      lead_source: $lead_source
      lead_creation_source: $lead_creation_source
      industry: $industry
    )
  }
`;

interface CallReportProps {
  metric?: string;
  organization_id?: string;
  team_id?: string;
  user_id?: string;
  lead_source?: string;
  take?: number;
  skip?: number;
  click_function: any;
  table_title: any;
  total_records: any;
  lead_creation_source: string;
  industry: string;
  refetchTable: any;
}

const LeadDetailsTable: React.FC<CallReportProps> = ({
  metric,
  organization_id,
  team_id,
  user_id,
  lead_source,
  take,
  skip,
  click_function,
  table_title,
  total_records,
  lead_creation_source,
  industry,
  refetchTable,
}) => {
  const [sortAscending, setSortAscending] = useState(false);
  const [page, setPage] = useState(0);
  const [deletePopupModal, setDeletePopupModal] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentStateID, setCurrentStateID] = useState(moment().format());
  const [poolData, setPoolData] = useState([] as any);

  const recordsPerPage = 10;

  const { data: listData, loading: listLoading, error: listError, refetch } = useQuery(FETCH_LEAD_POOL_DETAILS, {
    variables: {
      metric: metric,
      organization_id:
        !!lead_source && !!organization_id
          ? organization_id
          : !!user_id || !!team_id
          ? undefined
          : !!organization_id
          ? organization_id
          : undefined,
      team_id: !!lead_source ? undefined : !!organization_id || !!user_id ? undefined : team_id,
      user_id: !!lead_source ? undefined : !!user_id ? user_id : undefined,
      lead_source: !!lead_source ? lead_source : undefined,
      lead_creation_source: !!lead_creation_source ? lead_creation_source : undefined,
      industry: !!industry ? industry : undefined,
      //hardcoded LeadValue as this is the only sortable option design wise
      sort_option: "LeadValue",
      ascending: !!sortAscending,
      skip: page * recordsPerPage,
      take: recordsPerPage,
    },
    fetchPolicy: "no-cache",
    onCompleted() {
      const newList = [...poolData, ...listData.fetchLeadPoolDetails].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id)?.includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );
      if (listData.fetchLeadPoolDetails.length < recordsPerPage) setHasMore(false);
      setPoolData(newList);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [bulkDeleteLeads, { loading: deleteLoading, error: deleteError }] = useMutation(BULK_DELETE_LEADS, {
    async onCompleted({ bulkDeleteLeads }) {
      console.log("deleteContactRequest: ", bulkDeleteLeads);
      if (!bulkDeleteLeads) {
        appToast("Error deleting leads.");
        return;
      }
      appToast(bulkDeleteLeads);
      setDeletePopupModal(false);
      await refetchTable();
    },
    onError({ message }) {
      appToast(`Error deleting leads: ${message}`);
      Sentry.captureEvent({
        message: `bulkDeleteLeads GraphQL Error: ${message}`,
      });
      console.log("Error in bulkDeleteLeads: ", message);
    },
    refetchQueries: ["fetchLeadPoolDetails", "fetchLeadPools"],
  });

  const [exportLeads, { loading: loadingExport, error: errorExport }] = useMutation(EXPORT_LEADS, {
    async onCompleted({ exportLeads }) {
      console.log("deleteContactRequest: ", exportLeads);
      if (!exportLeads) {
        appToast("Error exporting leads.");
        return;
      }
      appToast(exportLeads);
    },
    onError({ message }) {
      appToast(`Error exporting leads: ${message}`);
      Sentry.captureEvent({
        message: `export Leads GraphQL Error: ${message}`,
      });
      console.log("Error in exportLeads: ", message);
    },
    refetchQueries: ["fetchLeadPoolDetails"],
  });

  useEffect(() => {
    setPage(0);
    setPoolData([]);
    setCurrentStateID(moment().format());
    setHasMore(true);
  }, [metric, lead_source, user_id, team_id, organization_id, lead_creation_source]);

  if (!!!listData)
    return (
      <ListContainer>
        <Loading />
      </ListContainer>
    );
  if (listError)
    return (
      <ListContainer>
        <AppErrorText>Error Loading Items</AppErrorText>
      </ListContainer>
    );

  // If no leads available.
  if (poolData.length === 0 && !listLoading)
    return (
      <ListContainer style={{ height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <AppText>No leads to display.</AppText>
      </ListContainer>
    );

  // const leadName = `${listData.fetchLeadCallHistory[0]?.lead?.first_name ?? ""} ${
  //   listData.fetchLeadCallHistory[0]?.lead?.last_name ?? ""
  // }`;

  const queryVars = JSON.stringify({
    metric: metric,
    organization_id:
      !!lead_source && !!organization_id
        ? organization_id
        : !!user_id || !!team_id
        ? undefined
        : !!organization_id
        ? organization_id
        : undefined,
    team_id: !!lead_source ? undefined : !!organization_id || !!user_id ? undefined : team_id,
    user_id: !!lead_source ? undefined : !!user_id ? user_id : undefined,
    lead_source: !!lead_source ? lead_source : undefined,
  });

  const leadCreationSrc = () => {
    switch (lead_creation_source) {
      case "Self-Sourced":
        return "self_sourced";
      case "Bulk Import":
        return "CSV";
      case "Added by Admin":
        return "as_admin";
      default:
        return undefined;
    }
  };

  const handleShowMore = () => {
    setPage(page + 1);
    refetch();
  };

  return (
    <div key={`${currentStateID}`}>
      <ConfirmDeletePopup
        blinds={deletePopupModal}
        setBlinds={setDeletePopupModal}
        onDelete={async () => {
          await bulkDeleteLeads({
            variables: {
              team_id: !!lead_source
                ? undefined
                : !!organization_id || !!user_id
                ? undefined
                : !!team_id
                ? team_id
                : undefined,
              user_id: !!lead_source ? undefined : !!user_id ? user_id : undefined,
              lead_source: !!lead_source ? lead_source : undefined,
              lead_creation_source: leadCreationSrc(),
              industry: !!industry ? industry : undefined,
            },
          });
        }}
        loading={deleteLoading}
        actionText={`${table_title.subheader} - ${table_title.header}`}
        detailsText="Once this action is taken, these leads will be removed from the system."
      />
      {/* length: {listData.fetchLeadPoolDetails.length} */}
      <JustifyDiv>
        <TableTitle>
          {/* <span style={{ fontWeight: 500, paddingRight: "6px" }}>
          {metric} team: {team_id} user: {user_id} org: {organization_id} lead_source: {lead_source}
          <br />
          {queryVars}
        </span> */}
          &nbsp;
          <div style={{ display: "flex" }}>
            <AppText fontSize={12}>
              <span style={{ fontWeight: 500 }}>{table_title.header}</span> &nbsp; {table_title.subheader}
            </AppText>

            {listLoading && (
              <div
                style={{
                  maxHeight: "14px",
                  maxWidth: "40px",
                  transform: "scale(0.5)",
                }}
              >
                <Loading />
              </div>
            )}
          </div>
        </TableTitle>
        <ButtonsDiv>
          {metric === "NeverDialed" && !organization_id && (
            <NewAppButton variant={"attention"} onClick={() => setDeletePopupModal(true)}>
              Delete Leads
            </NewAppButton>
          )}
          {loadingExport ? (
            <Loading />
          ) : (
            <NewAppButton
              variant={"confirm"}
              onClick={async () => {
                await exportLeads({
                  variables: {
                    metric: metric,
                    organization_id:
                      !!lead_source && !!organization_id
                        ? organization_id
                        : !!user_id || !!team_id
                        ? undefined
                        : !!organization_id
                        ? organization_id
                        : undefined,
                    team_id: !!lead_source
                      ? undefined
                      : !!organization_id || !!user_id
                      ? undefined
                      : !!team_id
                      ? team_id
                      : undefined,
                    user_id: !!lead_source ? undefined : !!user_id ? user_id : undefined,
                    lead_source: !!lead_source ? lead_source : undefined,
                    lead_creation_source: leadCreationSrc(),
                    industry: !!industry ? industry : undefined,
                  },
                });
              }}
            >
              Export Leads
            </NewAppButton>
          )}
        </ButtonsDiv>
      </JustifyDiv>
      <ListContainer>
        <HeaderDiv>
          {/* <HeaderText onClick={() => setSortAscending(!sortAscending)}>
             {sortAscending ? <FaCaretUp /> : <FaCaretDown />}
          </HeaderText> */}
          <HeaderText>Dials</HeaderText>
          <HeaderText>Lead</HeaderText>
          <HeaderText>Industry</HeaderText>
          <HeaderText>Business Name</HeaderText>
          <HeaderText>Current State</HeaderText>
          <HeaderText
            style={{ cursor: metric !== "Skipped" ? "pointer" : "default" }}
            onClick={() => {
              metric !== "Skipped" && setSortAscending(!sortAscending);
            }}
          >
            {metric !== "Skipped" &&
              (sortAscending ? (
                <AiFillCaretDown style={{ marginRight: "6px" }} size={9} color={theme.BLACK_COLOR} />
              ) : (
                <AiFillCaretUp style={{ marginRight: "6px" }} size={9} color={theme.BLACK_COLOR} />
              ))}
            Current LV
          </HeaderText>

          <HeaderText>Most Recent Disposition</HeaderText>

          <HeaderText> {metric === "Skipped" ? "Assigned Rep" : "MRD Agent"}</HeaderText>
          <HeaderText>{metric === "Skipped" ? "Skip Reason" : "Scheduled Next Dial Agent"}</HeaderText>
          <HeaderText
            style={{ cursor: metric === "Skipped" ? "pointer" : "default" }}
            onClick={() => metric === "Skipped" && setSortAscending(!sortAscending)}
          >
            {metric === "Skipped" &&
              (sortAscending ? (
                <AiFillCaretUp style={{ marginRight: "6px" }} size={9} color={theme.BLACK_COLOR} />
              ) : (
                <AiFillCaretDown style={{ marginRight: "6px" }} size={9} color={theme.BLACK_COLOR} />
              ))}
            {metric === "Skipped" ? "Skipped Date/Time" : "Scheduled Next Dial Date"}
          </HeaderText>
        </HeaderDiv>
        <ScrollDiv id="lead-pool-detail-scroll">
          <InfiniteScroll
            dataLength={poolData.length}
            next={handleShowMore}
            hasMore={hasMore}
            loader={<Loading />}
            scrollableTarget="lead-pool-detail-scroll"
          >
            {poolData?.map((item: any) => (
              <ListItemDiv>
                <DialsNumber
                  onClick={() => {
                    click_function(item);
                  }}
                >
                  {item?.num_of_dials || "0"}
                </DialsNumber>
                <ListItemText>{item?.full_name || "Not named"}</ListItemText>
                <ListItemText>{item?.industry || "Not specified"}</ListItemText>
                <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.id ?? ""}`}>
                  <ListItemText>{formatBusinessName(item?.business_name)}</ListItemText>
                </WrapAnchor>
                <ListItemText>{item?.phase_label || "No phase"}</ListItemText>
                <ListItemText>
                  {!!item?.lead_value && !!item?.lead_value?.value
                    ? formatUSD(item?.lead_value?.value)
                    : item?.lead_value?.value ?? "No value assigned"}
                </ListItemText>
                <ListItemText>
                  {item?.most_recent_disposition?.related_disposition?.label || "No disposition logged"}
                </ListItemText>

                <ListItemText>
                  {metric === "Skipped"
                    ? item?.rep?.full_name || "No info"
                    : item?.most_recent_disposition?.user?.full_name || "Never assigned"}
                </ListItemText>
                <ListItemText>
                  {metric === "Skipped"
                    ? item?.last_skipped_reason || "No reason recorded"
                    : item?.next_scheduled_agent?.full_name || "Not assigned"}
                </ListItemText>
                <ListItemText>
                  {metric === "Skipped"
                    ? moment(item.last_skipped_date).format("MM/DD/YYYY h:mm A") || "No info"
                    : !!item?.next_lead_intent?.schedule_item?.start_time
                    ? moment(item.next_lead_intent.schedule_item.start_time).format("MM/DD/YYYY h:mm A")
                    : "Not scheduled"}
                </ListItemText>
              </ListItemDiv>
            ))}
          </InfiniteScroll>
        </ScrollDiv>
      </ListContainer>
      {/* <NavigationDiv>
        <NavIcon disabled={page <= 0} onClick={() => setPage(page - 1)}>
          <FiArrowLeft size={22} color={theme.WHITE_COLOR} />
        </NavIcon>
        <NavPageNumber>
          Page {page + 1} of {Math.ceil(total_records / recordsPerPage)}
        </NavPageNumber>
        <NavIcon disabled={page * recordsPerPage + recordsPerPage >= total_records} onClick={() => setPage(page + 1)}>
          <FiArrowLeft size={22} color={theme.WHITE_COLOR} style={{ transform: "rotate(180deg)" }} />
        </NavIcon>
      </NavigationDiv> */}
    </div>
  );
};

const ScrollDiv = styled.div`
  overflow: auto;
  max-height: 299px;
  height: 299px;
`;

const JustifyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ButtonsDiv = styled.div`
  display: flex;
`;

interface DeleteProp {
  delete?: boolean;
}

const DialsNumber = styled.div`
  width: 28px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.1px;
  background-color: ${theme.PRIMARY500};
  color: ${theme.WHITE_COLOR};
  font-size: 8px;
  font-weight: 500;
  cursor: pointer;
`;

const NavPageNumber = styled(AppText)`
  min-width: 160px;
  text-align: center;
  font-size: 13px;
  margin: 0px 20px;
`;

const NavigationDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface DisabledProps {
  disabled: boolean;
}

const NavIcon = styled.div<DisabledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: ${(props) => (props.disabled ? theme.NEUTRAL100 : theme.PRIMARY500)};
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const ListItemDiv = styled.div`
  display: grid;
  grid-template-columns: 0.3fr repeat(9, 1fr);
  align-items: center;
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  max-height: 50px;
  font-size: 10px;
  padding-right: 15px;
`;

const WrapAnchor = styled.a`
  max-width: 100px;
  text-overflow: ellipsis;
`;

const HeaderDiv = styled.div`
  display: grid;
  grid-template-columns: 0.3fr repeat(9, 1fr);
  align-items: center;
  justify-items: start;
  /* margin-top: 14px; */
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0px;
  height: 30px;
  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.NEUTRAL100};
`;

const TableTitle = styled(AppText)`
  padding-left: 14px;
  font-size: 12px;
  margin-bottom: 15px;
`;

const HeaderText = styled(AppText)`
  font-size: 9px;
  font-weight: 500;
  border-bottom: none;
`;

const HeaderTextCenter = styled(AppText)`
  justify-self: center;
  font-size: 8px;
  font-weight: 500;
`;

const ListContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  margin-bottom: 20px;
  overflow: auto;
  padding-left: 14px;
  padding-right: 14px;
`;

export { LeadDetailsTable };
