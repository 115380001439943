import styled from "styled-components";

import { AppText, Loading, TemplateEditor } from "../UI";
import * as React from "react";

import { Modal } from "./Modal";

import { useContext } from "react";
import { ModalContext } from "../../context";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

interface TemplatePreviewModalProps {
  noDarkDiv?: boolean;
}

const TemplatePreviewModal: React.FC<TemplatePreviewModalProps> = ({ noDarkDiv }) => {
  const { showTemplatePreviewModal, setShowTemplatePreviewModal, templatePreviewModalData } = useContext(ModalContext);

  const { leadId, templateId, templateType } = templatePreviewModalData;

  const FETCH_EMAIL_TEMPLATE_PREVIEW = gql`
    query FetchEmailTemplatePreview($leadId: String, $templateId: String!) {
      fetchEmailTemplatePreview(lead_id: $leadId, template_id: $templateId) {
        body
        from
        subject
        to
      }
    }
  `;

  const FETCH_SMS_TEMPLATE_PREVIEW = gql`
    query FetchSMSTemplatePreview($leadId: String, $templateId: String!) {
      fetchSMSTemplatePreview(lead_id: $leadId, template_id: $templateId) {
        text
      }
    }
  `;

  const { data: emailTemplatePreviewData, loading: templatePreviewLoading, error: templatePreviewError } = useQuery(
    FETCH_EMAIL_TEMPLATE_PREVIEW,
    {
      skip: templateType === "sms",
      fetchPolicy: "network-only",
      variables: {
        templateId,
      },
      onCompleted: (data) => {
        console.log("template preview data email", data);
      },
    },
  );

  const { data: smsTemplatePreviewData, loading: smsTemplatePreviewLoading, error: smsTemplatePreviewError } = useQuery(
    FETCH_SMS_TEMPLATE_PREVIEW,
    {
      skip: templateType === "email",
      variables: {
        templateId,
      },
      onCompleted: (data) => {
        console.log("template preview data sms", smsTemplatePreviewData?.fetchSMSTemplatePreview["text"] || "NA");
      },
    },
  );

  return (
    <Modal open={showTemplatePreviewModal} onClose={() => setShowTemplatePreviewModal(false)} noDarkDiv={noDarkDiv}>
      <EditCardDiv templateType={templateType}>
        <TitleDiv>
          <AppText fontSize={16} fontWeight={600}>
            {templateType === "email" ? "Email" : "SMS"} Preview
          </AppText>
        </TitleDiv>
        {(!!templatePreviewLoading || !!smsTemplatePreviewLoading) && <Loading />}
        {templateType === "email" && !templatePreviewLoading && (
          <>
            <SenderGridInfo>
              <TitleText>To: </TitleText>
              <BodyText>{emailTemplatePreviewData?.fetchEmailTemplatePreview?.to || "NA"}</BodyText>
              <TitleText>From: </TitleText>
              <BodyText>{emailTemplatePreviewData?.fetchEmailTemplatePreview?.from || "NA"}</BodyText>
            </SenderGridInfo>
            <MessageDiv>
              <TitleText>Subject:</TitleText>
              <BodyText>{emailTemplatePreviewData?.fetchEmailTemplatePreview?.subject || "NA"}</BodyText>
            </MessageDiv>
            <MessageDiv>
              <TitleText>Message:</TitleText>

              <TemplateEditor
                ableToEdit={false}
                initialContent={emailTemplatePreviewData?.fetchEmailTemplatePreview?.body || ""}
              />
            </MessageDiv>
          </>
        )}
        {templateType === "sms" && !smsTemplatePreviewLoading && (
          <>
            <MessageDiv>
              <TitleText>Message:</TitleText>
              <BodyText>{smsTemplatePreviewData?.fetchSMSTemplatePreview["text"] || "NA"}</BodyText>
            </MessageDiv>
          </>
        )}
      </EditCardDiv>
    </Modal>
  );
};

interface EditCardDivProps {
  templateType?: string;
}

const EditCardDiv = styled.div<EditCardDivProps>`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
  min-height: ${(props) => (props?.templateType === "email" ? "600px" : "400px")};
  padding: 40px;
  max-height: 90vh;
  overflow-y: auto;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;
interface RepAndResultIcon {
  background?: string;
  color?: string;
}
const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const MessageDiv = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const BodyText = styled(AppText)`
  font-weight: 400;
  font-size: 12px;
`;

const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`;

const SenderGridInfo = styled.div`
  display: grid;
  grid-template-columns: 33px 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 24px;
`;

export { TemplatePreviewModal };
