import React, { useContext, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { theme } from "../../../../utils/theme";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { mail, edit, more_vertical } from "../../../../images/NewDesign";
import { AppText } from "../../../UI";
import { ModalContext } from "../../../../context";
import { eye, trash } from "../../../../images/NewDesign";
interface TemplateRowProps {
  name: string;
  subject: string;
  content: string;
  opened?: number;
  clicked?: number;
  replied?: number;
  bounced?: number;
  delivered?: number;
  templateId: string;
  templateType: "email" | "sms";
}

const MoreInfoModal = ({
  setShowMoreInfo,
  templateId,
  templateType,
}: {
  setShowMoreInfo: (showMoreInfo: boolean) => void;
  templateId: string;
  templateType: "email" | "sms";
}) => {
  const {
    setShowDeleteTemplateModal,
    setDeleteTemplateModalId,
    setShowTemplatePreviewModal,
    setTemplatePreviewModalData,
  } = useContext(ModalContext);

  const componentRef = useRef(null) as any;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        setShowMoreInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef]);

  return (
    <div ref={componentRef}>
      <Menu
        onClick={() => {
          setShowTemplatePreviewModal(true);
          setTemplatePreviewModalData({
            leadId: "1",
            templateId: templateId,
            templateType: templateType,
          });
        }}
      >
        <Option
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
            textDecoration: "none",
            padding: "8px 8px 4px 8px",
            width: "100%",
          }}
        >
          <PhoenixIcon svg={eye} size={16} color={theme.PRIMARY600} pointer />

          <AppText fontSize={12} fontWeight={400} noWrap>
            Preview Template
          </AppText>
        </Option>
        <Option
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
            textDecoration: "none",
            padding: "4px 8px 8px 8px",
            width: "100%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteTemplateModalId(templateId);
            setShowDeleteTemplateModal(true);
          }}
        >
          <PhoenixIcon svg={trash} size={16} color={theme.DANGER600} pointer hoverColor={theme.DANGER600} />
          <AppText fontSize={12} fontWeight={400} noWrap>
            Delete Template
          </AppText>
        </Option>
      </Menu>
    </div>
  );
};

const TemplateRow = (props: TemplateRowProps) => {
  const { name, subject, content, opened, clicked, replied, bounced, delivered, templateId, templateType } = props;
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const { setEmailTemplateModal, setEditEmailTemplateID, setSMSTemplateModal, setEditSMSTemplateID } = React.useContext(
    ModalContext,
  );

  return (
    <TemplateRowDiv templateType={templateType}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          height: "100%",
          alignItems: "flex-start",
          paddingTop: "3px",
          position: "relative",
        }}
      >
        <PhoenixIcon svg={mail} size={14} variant={"neutral"} />
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <TemplateTextInfo>
          <AppText
            fontSize={14}
            fontWeight={500}
            noWrap
            style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          >
            {name}
          </AppText>
          <AppText
            fontSize={14}
            fontWeight={400}
            noWrap
            color={theme.NEUTRAL300}
            style={{ maxWidth: "400px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          >
            {templateType === "sms" ? content : subject}
          </AppText>
        </TemplateTextInfo>
      </div>
      {templateType === "email" && (
        <>
          <TemplateStat>
            <AppText fontSize={18} fontWeight={400} noWrap>
              {opened ?? 0}%
            </AppText>

            <AppText fontSize={10} fontWeight={600} noWrap color={theme.NEUTRAL300}>
              OPENED
            </AppText>
          </TemplateStat>

          <TemplateStat>
            <AppText fontSize={18} fontWeight={400} noWrap>
              {clicked ?? 0}%
            </AppText>

            <AppText fontSize={10} fontWeight={600} noWrap color={theme.NEUTRAL300}>
              CLICKED
            </AppText>
          </TemplateStat>

          <TemplateStat>
            <AppText fontSize={18} fontWeight={400} noWrap>
              {replied ?? 0}%
            </AppText>

            <AppText fontSize={10} fontWeight={600} noWrap color={theme.NEUTRAL300}>
              REPLIED
            </AppText>
          </TemplateStat>
        </>
      )}

      {templateType === "sms" && (
        <TemplateStat>
          <AppText fontSize={18} fontWeight={400} noWrap>
            {delivered ?? 0}%
          </AppText>

          <AppText fontSize={10} fontWeight={600} noWrap color={theme.NEUTRAL300}>
            DELIVERED
          </AppText>
        </TemplateStat>
      )}

      <TemplateStat>
        <AppText fontSize={18} fontWeight={400} noWrap>
          {bounced ?? 0}%
        </AppText>

        <AppText fontSize={10} fontWeight={600} noWrap color={theme.NEUTRAL300}>
          BOUNCED
        </AppText>
      </TemplateStat>

      <EndingButtons>
        <PhoenixIcon
          svg={edit}
          size={16}
          variant={"brand"}
          pointer
          onClick={() => {
            if (templateType === "sms") {
              setSMSTemplateModal(true);
              setEditSMSTemplateID(templateId);
            } else {
              setEmailTemplateModal(true);
              setEditEmailTemplateID(templateId);
            }
          }}
        />
        <PhoenixIcon
          svg={more_vertical}
          size={16}
          variant={"brand"}
          pointer
          onClick={() => {
            setShowMoreInfo(!showMoreInfo);
          }}
        />
        {showMoreInfo && (
          <div style={{ position: "absolute", top: "20px", right: "0px" }}>
            <MoreInfoModal setShowMoreInfo={setShowMoreInfo} templateId={templateId} templateType={templateType} />
          </div>
        )}
      </EndingButtons>
    </TemplateRowDiv>
  );
};

const EndingButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  position: relative;
`;
const TemplateRowDiv = styled.div<{ templateType: "email" | "sms" }>`
  width: 100%;
  min-width: max-content;
  display: grid;
  grid-template-columns: ${(props) =>
    props.templateType === "email" ? "50px 1fr 80px 80px 80px 80px 100px" : "50px 1fr 80px 80px 80px"};
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
  padding: 16px 24px;
  align-items: center;
`;

const TemplateStat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TemplateTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  margin: 0px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 152px;

  background-color: ${theme.WHITE_COLOR};
  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL300};
  animation: ${theme.popup} 0.2s ease;

  box-shadow: 0px 0px 10px #00000020;
  overflow: hidden;
`;

const Option = styled.div`
  transition: background-color 0.15s ease;
  :hover {
    background-color: ${theme.NEUTRAL100};
  }
`;

export default TemplateRow;
