import { gql, useQuery } from "@apollo/client";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { CustomObjectField, CustomObjectRecord } from "../../../__generated__/graphql";
import { AppBackButton, AppText, Loading } from "../../UI";
import "./CustomObjectListView.css";
import { handleCustomObjectRecordDataType } from "../../../utils/other-functions";
import { theme } from "../../../utils/theme";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { EditCustomObjectFieldsComponent } from "../../modal/EditCustomObject";

interface ParamProps {
  lead_id: string;
  custom_object_id: string;
}

const FETCH_ALL_CUSTOM_OBJECT_FIELDS = gql`
  query fetchAllCustomObjectFields($custom_object_id: String!) {
    fetchAllCustomObjectFields(custom_object_id: $custom_object_id) {
      id
      name
      type
      list_option
      visible
      allow_reps_to_edit
      custom_object {
        id
        name
      }
    }
  }
`;

const FETCH_LEAD_CUSTOM_OBJECT_RECORDS = gql`
  query fetchLeadCustomObjectRecords($custom_object_id: String!, $lead_id: String!, $skip: Int, $take: Int) {
    fetchLeadCustomObjectRecords(custom_object_id: $custom_object_id, lead_id: $lead_id, skip: $skip, take: $take) {
      id
      object_id
      object_row_id
      string_value
      list_value
      num_value
      boo_value
      date_value
      object_field {
        name
        type
      }
    }
  }
`;

export const CustomObjectListView: React.FC = ({}) => {
  const gridRef = useRef<AgGridReact>(null);

  const [showModal, setShowModal] = useState(false);

  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [customObject, setCustomObject] = useState<{ id: string; name: string }>({ id: "", name: "" });

  const { custom_object_id, lead_id } = useParams<ParamProps>();
  const history = useHistory();

  const { data: data, loading: loading, error: error, fetchMore } = useQuery(FETCH_LEAD_CUSTOM_OBJECT_RECORDS, {
    variables: { custom_object_id: custom_object_id, lead_id, skip: 0, take: 30 },
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: columnFields, loading: loadingFields, error: errorFields } = useQuery(FETCH_ALL_CUSTOM_OBJECT_FIELDS, {
    variables: { custom_object_id: custom_object_id },
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const formatData = (data: CustomObjectRecord[]) => {
    const result = data.reduce(
      (acc: { [object_row_id: string]: { [column: string]: string | null | undefined | number } }, el) => {
        const object_row_id = el.object_row_id;
        // el.object_row.lead_id
        const field_name = el.object_field.name;

        const value = handleCustomObjectRecordDataType(el, el.object_field.type);
        if (acc[object_row_id]) {
          acc[object_row_id][field_name] = value;
        } else {
          acc[object_row_id] = { [field_name]: value, object_row_id };
        }
        return acc;
      },
      {},
    );

    return Object.values(result);
  };

  const handleCreateNewEvent = () => {
    setShowModal(!showModal);
  };

  const renderModal = () => {
    if (showModal) {
      return (
        <EditCustomObjectFieldsComponent
          custom_object_id={customObject.id}
          name={customObject.name}
          closeCustomObjectFieldsModal={handleCreateNewEvent}
          showEditCustomObjectFields={showModal}
          lead_id={lead_id}
          custom_object_fields={columnFields?.fetchAllCustomObjectFields}
          selectedRow={null}
          customHeight
        />
      );
    }
  };

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      // ag-Grid row indices are 0-based; so even rows will have even indexes
      return { backgroundColor: theme.PRIMARY50, height: "40px" }; // Change '#ECF1FF' to your desired color for even rows
    }
  };
  useEffect(() => {
    const cellStyle = {
      "text-overflow": "unset",
      "white-space": "nowrap",
      "overflow-x": "auto",
      "overflow-y": "hidden",
      padding: "8px 16px",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "24px",
      textAlign: "left",
    };

    if (columnFields?.fetchAllCustomObjectFields) {
      const colData: CustomObjectField[] = columnFields.fetchAllCustomObjectFields;
      setCustomObject({ id: colData[0].custom_object.id, name: colData[0].custom_object.name });
      setColDefs([
        {
          headerName: "Row Id",
          field: "object_row_id",
          headerClass: "ag-object-list-header",
          cellStyle: { ...cellStyle, cursor: "pointer" },
          lockPosition: true,
          flex: 1,
          minWidth: 200,
          onCellClicked: (params) => {
            const custom_record: CustomObjectRecord | null = params?.data;
            history.push({
              pathname: `/record-detail/${custom_record?.object_row_id}/custom-object/${custom_object_id}`,
              state: { object_name: customObject.name },
            });
          },
        },
        ...colData
          .filter((field) => field.visible === true)
          ?.map((field: CustomObjectField) => {
            return {
              headerName: field.name,
              headerClass: "ag-object-list-header",
              field: field.name,
              cellStyle,
              flex: 1,
              lockPosition: true,
              minWidth: 200,
            };
          }),
      ]);
    }
    if (data?.fetchLeadCustomObjectRecords) {
      const formattedData = formatData(data.fetchLeadCustomObjectRecords);
      setRowData(formattedData);
    }
  }, [data, columnFields]);

  return (
    <Container>
      {renderModal()}
      <TopOptions>
        <FlexCol>
          <AppBackButton />
          <AppText fontSize={22} fontWeight={500}>
            {customObject.name}
          </AppText>
        </FlexCol>
        <PhoenixAppButton
          buttonType="secondary"
          onClick={() => {
            setShowModal(true);
          }}
          style={{ alignSelf: "center" }}
        >
          CREATE NEW RECORD
        </PhoenixAppButton>
      </TopOptions>
      <BodyContainer>
        <TableContainer>
          {loading ? (
            <Loading />
          ) : (
            <AgObjectList className={"ag-object-list"}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={colDefs}
                suppressCopyRowsToClipboard={false}
                headerHeight={40}
                rowHeight={40}
                enableCellTextSelection={true}
                getRowStyle={getRowStyle}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </AgObjectList>
          )}
        </TableContainer>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 1200px;
  box-sizing: border-box;
  background: #fff;
  height: 100vh;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  padding: 24px 40px;
`;

const BodyContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #f7f9ff;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
`;

const TableContainer = styled.div`
  margin: auto;
  height: fit-content;
  width: 100%;
  overflow: auto;
  padding: 40px;
  height: fit-content;
`;

const AgObjectList = styled.div`
  height: 85vh;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-neutral-primary, #a6aabc);
  background: #fff;
`;
