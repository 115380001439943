import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import styled from "styled-components";
import { loggedInUser } from "../../../../apollo/cache";
import { MRR_LABEL } from "../../../../apollo/query";
import { CallContext, LeadCardContext, ModalContext, UserStatusContext } from "../../../../context";
import { googleLogo } from "../../../../images";
import {
  calendar,
  chevron_down,
  chevron_up,
  clock,
  edit,
  info,
  sequences,
  user_white,
} from "../../../../images/NewDesign";
import { MixpanelActions } from "../../../../services/mixpanel";
import { returnLocalTime } from "../../../../static";
import {
  CommunicationStates,
  EMAIL_UNSUBSCRIBED_REP_TOOLTIP,
  SMS_UNSUBSCRIBED_REP_TOOLTIP,
  formatBusinessName,
  formatDate,
  formatUSDRaw,
} from "../../../../utils/format";
import { useClickOutside } from "../../../../utils/hooks";
import { makeNextActionDisabledCheck } from "../../../../utils/misc";
import { theme } from "../../../../utils/theme";
import { appToast, errorToast } from "../../../../utils/toast";
import { PhoenixStyledTooltip } from "../../../Dumb/PhoenixStyledTooltip";
import { AppText, FlexDiv, SkeletonBlock, StatCard } from "../../../UI";
import { NewAppButton } from "../../../UI/NewAppButton";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { PhoenixAppButton } from "../../../UI/Phoenix/PhoenixAppButton";
import { CustomSequenceActionHeader } from "./CustomSequenceActionHeader";
import { DefaultSequenceActionHeader } from "./DefaultSequenceActionHeader";
import { CUSTOM_TASK_ACK } from "./LeadCardV2";

const FAVORITE_LEAD = gql`
  mutation favoriteLead($lead_id: String!) {
    favoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

const UNFAVORITE_LEAD = gql`
  mutation unfavoriteLead($lead_id: String!) {
    unfavoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

interface RepAndResultIcon {
  background?: string;
  color?: string;
}

export const PhoenixSuggestedActionCardV2 = ({
  intentData,
  expectedAction,
  scheduledEventBehavior,
  loadingState,
  suggestedAndNextActionAreTheSameLead,
}: any) => {
  const leadData = useMemo(() => intentData?.lead, [intentData]);

  const isSequenceAction = useMemo(() => intentData?.type === "SequenceAction", [intentData]);

  const leadUnsubscribedFromEmail =
    leadData?.email_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    leadData?.email_sub_status === CommunicationStates.LeadUnsubscribed;

  const leadUnsubscribedFromSMS =
    leadData?.sms_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    leadData?.sms_sub_status === CommunicationStates.LeadUnsubscribed;

  const [expandedState, setExpandedState] = useState<boolean>(true);
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false);

  const { nextActionOverride, setNextActionOverride, resetNextDial } = useContext(CallContext);
  const {
    setEditModalLeadId,
    setEditModal,
    showEmailEditorModal,
    openEmailEditorModal,
    openSMSChat,
    showSMSChat,
    setSkipOrReleaseLeadModalData,
    setSkipLeadModal,
    setReleaseLeadModal,
  } = useContext(ModalContext);

  const { updateUserToIdle } = useContext(UserStatusContext);

  const { ableToOverideNextDial, leadInNextDial, upcomingDials, setUpcomingDials } = useContext(LeadCardContext);

  const { data: mrrLabel, loading: mrrLoading, error: mrrError } = useQuery(MRR_LABEL);

  const [favoriteToggled, setFavoriteToggled] = useState(leadData?.favorited);

  const [currentTime, setCurrentTime] = useState<string>(returnLocalTime(leadData?.timezone_by_state));

  useEffect(() => {
    setFavoriteToggled(leadData?.favorited);
  }, [leadData]);

  useEffect(() => {
    if (!currentTime) {
      const time = returnLocalTime(leadData?.timezone_by_state);
      if (time) {
        setCurrentTime(time);
      }
      return;
    }
    const intervalId = setInterval(() => {
      setCurrentTime(returnLocalTime(leadData?.timezone_by_state));
    }, 10000);
    return () => clearInterval(intervalId);
  }, [currentTime, leadData]);

  const [favoriteLead, { loading: favoriteLoading, error: favoriteError }] = useMutation(FAVORITE_LEAD, {
    variables: {
      lead_id: leadData?.id,
    },
    async onCompleted({ favoriteLead }) {
      if (!favoriteLead) {
        appToast("Error favoriting lead. Something went wrong.");
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2"],
  });

  const [unfavoriteLead, { loading: unfavoriteLoading, error: unfavoriteError }] = useMutation(UNFAVORITE_LEAD, {
    variables: {
      lead_id: leadData?.id,
    },
    async onCompleted({ unfavoriteLead }) {
      if (!unfavoriteLead) {
        appToast("Error unfavoriting lead. Something went wrong.");
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2"],
  });

  const [customTaskAck, { loading: loadingCompleteTask, error: errorCompleteTask }] = useMutation(CUSTOM_TASK_ACK, {
    onCompleted({ customTaskAck }) {
      // remove from active queue

      const filteredActive = upcomingDials.slice().filter((item: any) => item?.id !== intentData?.id);

      setUpcomingDials(filteredActive);

      if (!nextActionOverride?.intent_object?.lead?.id) {
        resetNextDial();
      }
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `Error marking custom task. GraphQL Error: ${message}`,
      });
      console.log("Error marking custom task: ", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2"],
  });

  const SearchButton = () => (
    <>
      <div onClick={() => setIsSearchClicked(true)}>
        <img
          style={{ height: "14px", width: "auto", cursor: "pointer", marginTop: "4px" }}
          src={googleLogo}
          alt="search on google"
        />
      </div>
    </>
  );

  const FavoriteButton = () => (
    <>
      {favoriteToggled ? (
        <AiFillStar
          color={theme.icon.brand.default}
          size={18}
          cursor="pointer"
          onClick={() => {
            unfavoriteLead();
            MixpanelActions.track("Lead Card", {
              type: "favorite toggled",
              id: leadData?.id,
            });
            setFavoriteToggled(false);
          }}
        />
      ) : (
        <AiOutlineStar
          color={theme.icon.brand.default}
          size={18}
          cursor="pointer"
          onClick={() => {
            if (leadData?.rep_id === loggedInUser()?.id) {
              favoriteLead();
              MixpanelActions.track("Lead Card", {
                type: "favorite toggled",
                id: leadData?.id,
              });
              setFavoriteToggled(true);
            } else {
              appToast("Leads not owned or assigned to you cannot be favorited.");
            }
          }}
        />
      )}
    </>
  );

  const handleDefaultSequenceSnooze = () => {
    MixpanelActions.track("Skip Lead");
    setSkipOrReleaseLeadModalData({
      lead_id: leadData?.id ?? "",
      isNextDial: nextActionOverride?.intent_object?.lead?.id === leadData?.id,
      intent: intentData,
    });
    setSkipLeadModal(true);
  };

  const handleDefaultReleaseLead = () => {
    // might need to make skip in context
    // setSkip(0);

    MixpanelActions.track("Release Lead");
    setSkipOrReleaseLeadModalData({
      lead_id: leadData?.id ?? "",
      isNextDial: false,
      intent: intentData,
    });
    setReleaseLeadModal(true);
  };

  // if loading is finished and there is no lead returned by BE then hide the card
  if (!loadingState && !leadData) {
    return null;
  }

  return (
    <Container>
      <SuggestedHeader>
        <AppText fontSize={12} fontWeight={600} color="white">
          Sellfire Suggested Action
        </AppText>
        {!!leadData && (
          <PhoenixIcon
            svg={expandedState ? chevron_up : chevron_down}
            size={24}
            variant="white"
            hoverColor="white"
            pointer
            onClick={() => setExpandedState(!expandedState)}
          />
        )}
      </SuggestedHeader>

      <Body>
        {isSequenceAction ? (
          <CustomSequenceActionHeader
            inQueue={false}
            showDefaultRemoveFromCustomQueueButton={false}
            showDefaultReleaseButton={true}
            showDefaultRemoveFromNextDialButton={false}
            showCustomSnoozeStepActionButton={leadData?.organization?.allow_snooze_step_action}
            showCustomRemoveStepActionButton={leadData?.organization?.allow_remove_step_action}
            handleReleaseLead={handleDefaultReleaseLead}
            handleRemoveFromCustomQueue={() => {}}
            customTaskAck={customTaskAck}
            leadData={leadData}
            intentData={intentData}
            scheduledEventBehavior={scheduledEventBehavior}
          />
        ) : (
          <DefaultSequenceActionHeader
            // always from active Queue
            showDefaultRemoveFromCustomQueueButton={false}
            showDefaultReleaseButton={true}
            showDefaultRemoveFromNextDialButton={false}
            showDefaultSnoozeButton={true}
            handleReleaseLead={handleDefaultReleaseLead}
            handleSnoozeLead={handleDefaultSequenceSnooze}
          />
        )}
        {expandedState && !loadingState && !!leadData && (
          <>
            <LeadCardNonExpand isSequenceAction={intentData?.type === "SequenceAction"}>
              <CardContainerHeader>
                <CardHeaderLeft>{leadData?.rep_id === loggedInUser()?.id && <FavoriteButton />}</CardHeaderLeft>

                <CardHeaderMiddle>
                  <CallbackPersonCompany
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(`/lead-detail/${leadData?.id}`, "_blank", "noreferrer")}
                  >
                    {formatBusinessName(leadData?.business_name)}
                  </CallbackPersonCompany>
                </CardHeaderMiddle>

                <CardHeaderRight>
                  <ButtonRow>
                    <SearchButton />
                    <PhoenixIcon
                      svg={edit}
                      size={16}
                      pointer
                      onClick={() => {
                        MixpanelActions.track("Lead Card", {
                          type: "edit form clicked",
                          id: leadData?.id,
                        });
                        setEditModalLeadId(leadData?.id);
                        setEditModal(true);
                      }}
                    />
                    {isSequenceAction && (
                      <SequenceTag>
                        <PhoenixIcon svg={sequences} size={16} variant="alert" hoverColor={theme.WARNING500} />
                      </SequenceTag>
                    )}
                  </ButtonRow>
                  {isSearchClicked && <SearchMenu setIsSearchClicked={setIsSearchClicked} leadData={leadData} />}
                </CardHeaderRight>
              </CardContainerHeader>
              <CardContainerMain>
                <StatCard icon={user_white} variant="primary" text="PRIMARY" fadeIn />
                <CardContainerMainRight>
                  <CardGridMiddle>
                    <Horizontal>
                      <CallbackPersonName fontWeight={400} fontSize={12}>
                        {leadData?.first_name && leadData?.last_name
                          ? `${leadData?.last_name}, ${leadData?.first_name}`
                          : leadData?.full_name}
                      </CallbackPersonName>
                    </Horizontal>
                    <Horizontal>
                      <CallbackCityStateDiv>
                        {leadData?.state && (
                          <CallbackCityStateText fontWeight={400} fontSize={12}>
                            {leadData?.city}
                            {leadData?.city && leadData?.state ? `, ` : ``}
                            {leadData?.state}
                            {leadData?.state && leadData?.country ? `, ` : ``}
                            {leadData?.country}
                          </CallbackCityStateText>
                        )}
                        {!!leadData?.timezone_by_state && (
                          <CallbackCityStateText style={{ fontSize: "12px" }}>
                            {` (${currentTime}${
                              leadData?.timezone_by_state
                                ?.split(" ")
                                ?.pop()
                                ?.replace(/[{()}]/g, "") ?? ""
                            })`}
                          </CallbackCityStateText>
                        )}
                      </CallbackCityStateDiv>
                    </Horizontal>
                    <CallbackIndustryDiv>
                      {!!leadData?.industry && (
                        <AppText style={{}} fontWeight={400} fontSize={12}>
                          {leadData?.industry}
                          {leadData?.sub_industry && ` (${leadData?.sub_industry})`}
                        </AppText>
                      )}
                    </CallbackIndustryDiv>
                    {!!leadData?.lead_source && (
                      <div>
                        <AppText fontWeight={400} fontSize={12} style={{}}>
                          {leadData?.lead_source}
                        </AppText>
                      </div>
                    )}

                    {!!leadData?.computed_mrr && (
                      <div>
                        <AppText style={{}} fontWeight={400} fontSize={12}>
                          <span>{mrrLabel?.getMrrLabel}:&nbsp;</span>
                          {formatUSDRaw(leadData?.computed_mrr)}
                        </AppText>
                      </div>
                    )}
                    {!!leadData?.current_close_date && (
                      <div>
                        <AppText style={{}} fontWeight={400} fontSize={12}>
                          <span>Close Date:&nbsp;</span>
                          {formatDate(leadData?.current_close_date)}
                        </AppText>
                      </div>
                    )}
                  </CardGridMiddle>
                </CardContainerMainRight>
              </CardContainerMain>
              {isSequenceAction && !!leadData?.sequence_step?.actions?.[0]?.custom_task_note && (
                <CustomNoteContainer>
                  <AppText fontSize={10} fontWeight={500}>
                    Task Note:
                  </AppText>
                  <AppText fontSize={10} fontWeight={400}>
                    {leadData?.sequence_step?.actions?.[0]?.custom_task_note}
                  </AppText>
                </CustomNoteContainer>
              )}

              {scheduledEventBehavior === "Neutral" &&
                leadData?.next_lead_intent?.schedule_item &&
                !leadData?.next_lead_intent?.schedule_item?.deleted_at && (
                  <UpcomingEventWarning>
                    <PhoenixStyledTooltip
                      place="left"
                      id={"suggested-action-upcoming-event-warning"}
                      fontSize={10}
                      padding={8}
                    />
                    <PhoenixIcon
                      svg={calendar}
                      size={16}
                      color={theme.WARNING500}
                      hoverColor={theme.WARNING500}
                      style={{ marginRight: "8px" }}
                    />
                    This lead has an upcoming scheduled event.
                    <PhoenixIcon
                      svg={info}
                      variant="brand"
                      size={16}
                      style={{ marginLeft: "auto" }}
                      data-tip="Sequence step not delayed based on specific time event times"
                      data-for="suggested-action-upcoming-event-warning"
                    />
                  </UpcomingEventWarning>
                )}
            </LeadCardNonExpand>

            {loggedInUser().role !== "ADMIN" && (
              <ButtonContainer>
                <>
                  {expectedAction === "manualCall" && (
                    <>
                      {!!leadData?.id && nextActionOverride?.intent_object?.lead?.id !== leadData?.id && (
                        <CTATooltipWrapperDiv
                          data-tip={
                            makeNextActionDisabledCheck({
                              // we can assume that sequence step leads are owned by the rep and able to be called
                              inCallableStatus: true,
                              // we can assume that sequence step leads are owned by the rep
                              isOwnedByRep: true,
                              // only primary leads can join a sequence
                              isAssociatedLead: false,
                              nextDialOverrideBlocked: !ableToOverideNextDial,
                              expandedState: false,
                              showPromptToDial: false,
                              leadIsInNextDial: leadData?.id === leadInNextDial,
                            })?.tooltip
                          }
                          data-for={
                            makeNextActionDisabledCheck({
                              // we can assume that sequence step leads are owned by the rep and able to be called
                              inCallableStatus: true,
                              // we can assume that sequence step leads are owned by the rep
                              isOwnedByRep: true,
                              // only primary leads can join a sequence
                              isAssociatedLead: false,
                              nextDialOverrideBlocked: !ableToOverideNextDial,
                              expandedState: false,
                              showPromptToDial: false,
                              leadIsInNextDial: leadData?.id === leadInNextDial,
                            })?.disabled
                              ? "lead-card-tooltip"
                              : undefined
                          }
                        >
                          <PhoenixAppButton
                            variant="brand"
                            buttonType="primary"
                            height={40}
                            uppercase
                            disabled={
                              makeNextActionDisabledCheck({
                                // we can assume that sequence step leads are owned by the rep and able to be called
                                inCallableStatus: true,
                                // we can assume that sequence step leads are owned by the rep
                                isOwnedByRep: true,
                                // only primary leads can join a sequence
                                isAssociatedLead: false,
                                nextDialOverrideBlocked: !ableToOverideNextDial,
                                expandedState: false,
                                showPromptToDial: false,
                                leadIsInNextDial: leadData?.id === leadInNextDial,
                              })?.disabled
                            }
                            onClick={() => {
                              updateUserToIdle({ do_not_update_if_on_call: true });
                              MixpanelActions.track("Lead Card", {
                                type: "make next action",
                                id: leadData?.id,
                              });
                              setNextActionOverride({
                                intent_object: {
                                  id: intentData?.id ?? null,
                                  lead: {
                                    id: leadData?.id ?? "",
                                    primary_phone_number: leadData?.primary_phone_number,
                                    ...leadData,
                                  },
                                  ...intentData,
                                },
                                parent: "suggestedAction",
                              });
                            }}
                          >
                            MAKE NEXT ACTION
                          </PhoenixAppButton>
                        </CTATooltipWrapperDiv>
                      )}
                    </>
                  )}
                  {expectedAction === "manualSms" && (
                    <CTATooltipWrapperDiv
                      data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : ""}
                      data-for={"lead-card-tooltip"}
                    >
                      <PhoenixAppButton
                        variant="brand"
                        uppercase
                        buttonType="primary"
                        height={40}
                        buttonTextFontSize={10}
                        disabled={leadUnsubscribedFromSMS}
                        onClick={() => {
                          if (showSMSChat) {
                            appToast("There is currently a chat open. Please close the existing chat before opening.");
                            return;
                          }

                          openSMSChat({
                            lead_id: leadData?.id ?? "",
                            phone_number: leadData?.primary_phone_number,
                            intent: intentData,
                          });
                        }}
                      >
                        Compose SMS
                      </PhoenixAppButton>
                    </CTATooltipWrapperDiv>
                  )}
                  {expectedAction === "manualEmail" && (
                    <CTATooltipWrapperDiv
                      data-tip={leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : ""}
                      data-for={"lead-card-tooltip"}
                    >
                      <PhoenixAppButton
                        variant="brand"
                        uppercase
                        buttonType="primary"
                        height={40}
                        disabled={leadUnsubscribedFromEmail}
                        onClick={() => {
                          !showEmailEditorModal &&
                            openEmailEditorModal({
                              lead_id: leadData?.id ?? "",
                              full_name: leadData?.full_name,
                              email: leadData?.primary_email,
                              intent: intentData,
                              isManualEmailSequenceStep: leadData?.sequence_step?.actions?.[0]?.task === "manualEmail",
                              sequenceStepId: leadData?.sequence_step?.actions?.[0]?.id,
                            });
                        }}
                      >
                        Compose Email
                      </PhoenixAppButton>
                    </CTATooltipWrapperDiv>
                  )}
                  {(expectedAction === "customTask" || expectedAction === "requestManagerFeedback") && (
                    <CTATooltipWrapperDiv>
                      <PhoenixAppButton
                        variant="brand"
                        uppercase
                        buttonType="primary"
                        height={40}
                        disabled={!!loadingCompleteTask}
                        onClick={() => {
                          console.log("acknowledging suggested action was performed...");
                          customTaskAck({
                            variables: {
                              customTaskAckInput: {
                                action_completed: true,
                                lead_intent_id: intentData?.id,
                                nextstep: false,
                                remove: false,
                                snooze: false,
                                step_action_id: leadData?.sequence_step?.actions?.[0]?.id,
                              },
                            },
                          });
                        }}
                      >
                        {expectedAction === "customTask" ? "Mark Complete" : "Request"}
                      </PhoenixAppButton>
                    </CTATooltipWrapperDiv>
                  )}
                </>
              </ButtonContainer>
            )}
          </>
        )}

        {!!loadingState && expandedState && (
          <>
            <FlexDiv direction="column" gap={16} style={{ margin: "16px" }}>
              <FlexDiv justify="space-between">
                <SkeletonBlock width={148} height={24} borderRadius={4} delayNumber={2} />
                <SkeletonBlock width={72} height={24} borderRadius={4} delayNumber={3} />
              </FlexDiv>
              <FlexDiv gap={16}>
                <SkeletonBlock width={72} height={24} borderRadius={4} delayNumber={2} />
                <SkeletonBlock width={180} height={72} borderRadius={4} delayNumber={3} />
              </FlexDiv>
            </FlexDiv>
            <ButtonContainer>
              <FlexDiv gap={8}>
                <SkeletonBlock width={100} height={38} borderRadius={8} delayNumber={2} />
                <SkeletonBlock width={100} height={38} borderRadius={8} delayNumber={3} />
              </FlexDiv>
              <SkeletonBlock width={148} height={38} borderRadius={8} delayNumber={4} />
            </ButtonContainer>
          </>
        )}
      </Body>
    </Container>
  );
};

const SearchMenu = ({ ...props }) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => props?.setIsSearchClicked(false));

  return (
    <OptionsDiv ref={containerRef}>
      {!!props?.leadData?.business_name && (
        <Option
          onClick={() => {
            let businessNameSearch = encodeURIComponent(props?.leadData?.business_name);
            let citySearch = encodeURIComponent(`${props?.leadData?.city || ""} ${props?.leadData?.state || ""}`);
            window.open(
              `https://google.com/search?q=${businessNameSearch || ""} ${citySearch || ""}`,
              "_blank",
              "noreferrer",
            );
          }}
        >
          Business Name
        </Option>
      )}
      {!!props?.leadData?.full_name && props?.leadData?.full_name.length > 3 && (
        <Option
          onClick={() => {
            let nameSearch = encodeURIComponent(props?.leadData?.full_name);
            let citySearch = encodeURIComponent(`${props?.leadData?.city || ""} ${props?.leadData?.state || ""}`);
            window.open(`https://google.com/search?q=${nameSearch || ""} ${citySearch || ""}`, "_blank", "noreferrer");
          }}
        >
          Lead Name
        </Option>
      )}
    </OptionsDiv>
  );
};

const SnoozeMenu = ({ ...props }) => {
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => props?.setShowSnoozeMenu(false));

  const showScheduleEventOption = useMemo(
    () =>
      props?.scheduledEventBehavior === "Neutral" &&
      props?.leadData?.next_lead_intent?.schedule_item &&
      !props?.leadData?.next_lead_intent?.schedule_item?.deleted_at,
    [],
  );

  return (
    <OptionsDiv
      ref={containerRef}
      style={{
        top: showScheduleEventOption ? "-140px" : "-108px",
        right: showScheduleEventOption ? "-70px" : "-38px",
      }}
    >
      <Option
        width={showScheduleEventOption ? "224px" : undefined}
        onClick={(e) => {
          e?.stopPropagation();
          props?.customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: props?.intentData?.id,
                nextstep: false,
                remove: false,
                snooze: true,
                step_action_id: props?.leadData?.sequence_step?.actions?.[0]?.id,
                delay_days: 0,
                delay_hours: 0,
                delay_minutes: 30,
              },
            },
          });
          props?.setShowSnoozeMenu(false);
        }}
      >
        <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
        <AppText fontSize={12} style={{ textTransform: "none" }}>
          30 Minutes
        </AppText>
      </Option>
      <Option
        width={showScheduleEventOption ? "224px" : undefined}
        onClick={(e) => {
          e?.stopPropagation();
          props?.customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: props?.intentData?.id,
                nextstep: false,
                remove: false,
                snooze: true,
                step_action_id: props?.leadData?.sequence_step?.actions?.[0]?.id,
                delay_days: 0,
                delay_hours: 4,
                delay_minutes: 0,
              },
            },
          });
          props?.setShowSnoozeMenu(false);
        }}
      >
        <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
        <AppText fontSize={12} style={{ textTransform: "none" }}>
          4 Hours
        </AppText>
      </Option>
      <Option
        width={showScheduleEventOption ? "224px" : undefined}
        onClick={(e) => {
          e?.stopPropagation();
          props?.customTaskAck({
            variables: {
              customTaskAckInput: {
                action_completed: false,
                lead_intent_id: props?.intentData?.id,
                nextstep: false,
                remove: false,
                snooze: true,
                step_action_id: props?.leadData?.sequence_step?.actions?.[0]?.id,
                delay_days: 1,
                delay_hours: 0,
                delay_minutes: 0,
              },
            },
          });
          props?.setShowSnoozeMenu(false);
        }}
      >
        <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
        <AppText fontSize={12} style={{ textTransform: "none" }}>
          1 Day
        </AppText>
      </Option>
      {showScheduleEventOption && (
        <Option
          width="224px"
          onClick={(e) => {
            e?.stopPropagation();
            props?.customTaskAck({
              variables: {
                customTaskAckInput: {
                  action_completed: false,
                  lead_intent_id: props?.intentData?.id,
                  nextstep: false,
                  remove: false,
                  snooze: true,
                  step_action_id: props?.leadData?.sequence_step?.actions?.[0]?.id,
                  delay_until_scheduled_event: true,
                },
              },
            });
            props?.setShowSnoozeMenu(false);
          }}
        >
          <PhoenixIcon svg={clock} size="small" hoverColor={theme.PRIMARY500} pointer />
          <AppText fontSize={12} style={{ textTransform: "none" }}>
            Until After Scheduled Event
          </AppText>
        </Option>
      )}
    </OptionsDiv>
  );
};

const CustomNoteContainer = styled.div`
  padding: 8px;
  background-color: ${theme.fill.neutral.secondary};
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 12px 16px 0px 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 16px;
  height: 72px;

  border-top: 1px solid ${theme.NEUTRAL200};

  & button {
    animation: ${theme.fadeIn} 0.25s ease forwards;
  }
`;

const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const Container = styled.div`
  width: 432px;
  position: relative;
`;

const SuggestedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 34px;
  padding: 8px 16px;

  background: linear-gradient(135deg, #3e65ff 0%, #7091ff 100%);
  border-radius: 8px 8px 0px 0px;
`;

interface ActionGutterProps {
  collapsed?: boolean;
}

const ActionGutter = styled.div<ActionGutterProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;

  width: 100%;
  height: fit-content;
  background-color: ${theme.PRIMARY100};

  border-bottom-left-radius: ${(props) => props?.collapsed && "8px"};
  border-bottom-right-radius: ${(props) => props?.collapsed && "8px"};
`;

const Body = styled.div`
  width: 432px;
  /* height: 320px; */
  margin-bottom: 24px;
  /* overflow: hidden; */

  & ${AppText} {
    animation: ${theme.fadeIn} 0.25s ease forwards;
  }

  background-color: white;
  border-radius: 0px 0px 8px 8px;
  border: solid ${theme.NEUTRAL200};
  border-width: 0px 1px 1px 1px;
`;

const CallbackCityStateDiv = styled.div`
  /* padding: 0px 0px 0px 0px; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
`;

const CallbackCityStateText = styled(AppText)`
  align-items: center;
  max-width: 240px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const CallbackIndustryDiv = styled.div`
  display: flex;
`;

const CallbackPersonName = styled(AppText)`
  /* font-size: 12px; */
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const CallbackPersonCompany = styled(AppText)`
  border-bottom: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  padding: 0px 4px 0px 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
  :hover {
    border-bottom: 1px solid ${theme.PRIMARY500};
    margin-bottom: -1px;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CardContainerHeader = styled.div`
  display: grid;

  grid-template-columns: 26px 1fr 1fr;
  min-height: 24px;
  height: fit-content;
  justify-content: center;
  align-items: center;
`;

const CardGridMiddle = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
  gap: 4px;
`;

const CardHeaderRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

interface LeadCardNonExpandProps {
  isSequenceAction: boolean;
}

const LeadCardNonExpand = styled.div<LeadCardNonExpandProps>`
  margin: 16px ${(props) => (props?.isSequenceAction ? "0px" : "16px")} 16px 16px;
  max-height: 450px;
  overflow-y: auto;
`;

const CardHeaderMiddle = styled.div`
  display: flex;
`;

const CardHeaderLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CardContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const CardContainerMainRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const OptionsDiv = styled.div`
  position: absolute;
  top: 32px;
  right: 48px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
  z-index: 3;
`;

interface OptionProps {
  width?: string;
}

const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: ${(props) => (props.width ? props.width : "168px")};
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;

const CTATooltipWrapperDiv = styled.div`
  width: 100%;
`;

const TooltipWrapperDiv = styled.div``;
const UpcomingEventWarning = styled.div`
  display: flex;
  align-items: center;

  margin: 16px 16px 0px 0px;
  padding: 8px 16px 8px 8px;

  border-radius: 8px;
  background-color: ${theme.WARNING200};

  color: ${theme.WARNING600};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const SequenceTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 4px 0px 0px 4px;
  background-color: ${theme.WARNING200};
`;

const FadeInText = styled(AppText)`
  animation: ${theme.textFadeIn} 0.3s ease;
`;

const MakeNextDialButton = styled(NewAppButton)`
  width: 146px;
  font-size: 10px;
  font-weight: 600;
`;
