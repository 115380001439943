import * as React from "react";
import * as Sentry from "@sentry/react";
import ReactTooltip from "react-tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useContext, useEffect } from "react";
import { ModalContext } from "../../context";
import styled from "styled-components";

import { theme } from "../../utils/theme";
import { formatUSDRaw } from "../../utils/format";
import { renderLeadIntentEventTypeValues } from "../../utils/misc";
import { appToast } from "../../utils/toast";

import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CORE_LEAD_CARD_DATA } from "./../../apollo/fragments";

import { AppSidebarCard, AppText, Loading, DarkDiv, StatCard } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { FlexDiv } from "../UI/FlexDiv";
import { searchIcon } from "../../images";
import { primary_x, merge_white, user_white } from "../../images/NewDesign";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

import { MergeLeadsModal } from "./MergeLeadsModal";
import { loggedInUser } from "../../apollo/cache";
import { info } from "../../images/NewDesign";
import { PhoenixIcon } from "../UI/Phoenix";

export const FETCH_LEADS = gql`
  query fetchLeads($searchText: String, $skip: Int, $take: Int) {
    fetchLeads(searchText: $searchText, skip: $skip, take: $take) {
      id
      timezone
      timezone_by_state
      channel
      city
      state
      country
      rep_id
      industry
      full_name
      business_name
      lead_ownership_status
      lead_value {
        id
        value
      }
      computed_mrr
      next_intent_scheduled_or_unscheduled {
        id
        event_type_label
        dial_num_in_sales_cycle
        anytime_before #general time
        anytime_after
        anytime_day
        anytime_day_upperbound
        anytime_tz
        general_time_start_date
        general_time_end_date
        current_sequence_step
        schedule_item {
          #specific time
          id
          start_time
          end_time
        }
      }
      favorited
    }
  }
`;

const FAVORITE_LEAD = gql`
  mutation favoriteLead($lead_id: String!) {
    favoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

const UNFAVORITE_LEAD = gql`
  mutation unfavoriteLead($lead_id: String!) {
    unfavoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;
export const MergeContactsModal: React.FC = () => {
  const { showMergeLeadsModal, setShowMergeContactsModal, setMergeLeadsData, mergeLeadsData } = useContext(
    ModalContext,
  );

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [leadData, setLeadData] = useState([] as any);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [reset, setReset] = useState(false);

  const recordsPerPage = 24;

  useEffect(() => {
    return () => {
      setMergeLeadsData({});
    };
  }, []);

  const [fetchLeadData, { data: listData, loading: listLoading, error: listError }] = useLazyQuery(FETCH_LEADS, {
    fetchPolicy: "network-only",
    onCompleted() {
      handleListUpdate(reset);
      setSearchResult(search);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    fetchLeadData({
      variables: {
        skip: page * recordsPerPage,
        take: recordsPerPage,
        searchText: search,
      },
    });
  }, []);

  const handleListUpdate = (reset: boolean) => {
    if (reset) {
      const newList = [...listData.fetchLeads];
      listData?.fetchLeads?.length < recordsPerPage ? setHasMore(false) : setHasMore(true);
      setLeadData(newList);
      setReset(false);
      return;
    }
    const newList = [...leadData, ...listData.fetchLeads];
    listData?.fetchLeads?.length < recordsPerPage ? setHasMore(false) : setHasMore(true);
    setLeadData(newList);
  };

  const handleShowMore = () => {
    setPage(page + 1);
    fetchLeadData({
      variables: {
        skip: page * recordsPerPage,
        take: recordsPerPage,
        searchText: search,
      },
    });
  };

  return (
    <>
      {showMergeLeadsModal && <MergeLeadsModal />}
      {!showMergeLeadsModal && <DarkDiv />}
      <AppSidebarCard
        title="Merge Contact"
        stretchHeight
        style={{
          minWidth: "820px",
          height: "100%",
          position: "relative",
          zIndex: !showMergeLeadsModal ? 899 : "unset",
          border: "none",
        }}
      >
        <ModalContentContainer>
          <FlexDiv justify="space-between" align="center">
            <form onSubmit={(e) => e.preventDefault()}>
              <SearchBoxContainer>
                <SearchInput
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Leads"
                />
                <Icon url={searchIcon} color={theme.PRIMARY500} size="14" top="14" left="10" />
                <FlexDiv gap={16} align="center">
                  <ActionButton
                    variant={"newDesignSecondary"}
                    width={95}
                    type="submit"
                    onClick={() => {
                      setPage(0);
                      setReset(true);
                      fetchLeadData({
                        variables: {
                          skip: 0,
                          take: recordsPerPage,
                          searchText: search,
                        },
                      });
                    }}
                  >
                    SEARCH
                  </ActionButton>
                  {listLoading && reset && <Loading />}
                </FlexDiv>
                <FlexDiv
                  direction="column"
                  style={{
                    marginLeft: "40px",
                  }}
                >
                  <AppText
                    fontSize={12}
                    color={theme.BLACK_COLOR}
                    fontWeight={600}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Merge Selected Lead With:
                  </AppText>
                  <AppText fontSize={16} color={theme.BLACK_COLOR} fontWeight={400}>
                    {mergeLeadsData?.source?.full_name || mergeLeadsData?.source?.business_name || "N/A"}
                  </AppText>
                </FlexDiv>
              </SearchBoxContainer>
            </form>
            <CloseButton src={primary_x} alt="close merge contact" onClick={() => setShowMergeContactsModal(false)} />
          </FlexDiv>

          <ScrollDiv id="merge-contact-modal-scroll">
            <InfiniteScroll
              dataLength={leadData.length}
              next={handleShowMore}
              hasMore={hasMore}
              loader={<Loading />}
              scrollableTarget="merge-contact-modal-scroll"
              style={{
                ...InfiniteScrollStyle,
                justifyContent: leadData?.length < 3 && leadData?.length > 0 ? "flex-start" : "space-evenly",
              }}
            >
              {leadData?.length
                ? leadData?.map((ele: any, i: number) => <LeadDetailCard data={ele} key={`${i}-${ele.id}`} />)
                : !listLoading && (
                    <NoneFoundText fontSize={16}>No leads found that match '{searchResult}'</NoneFoundText>
                  )}
            </InfiniteScroll>
          </ScrollDiv>
        </ModalContentContainer>
      </AppSidebarCard>
    </>
  );
};
interface LeadDetailCardProps {
  data: any;
}

const LeadDetailCard: React.FC<LeadDetailCardProps> = ({ data }) => {
  const [favorite, setFavorite] = useState(data?.favorited);
  const { setShowMergeLeadsModal, mergeLeadsData, setMergeLeadsData } = useContext(ModalContext);

  const [favoriteLead, { loading: favoriteLoading, error: favoriteError }] = useMutation(FAVORITE_LEAD, {
    variables: {
      lead_id: data?.id,
    },
    async onCompleted({ favoriteLead }) {
      if (!favoriteLead) {
        appToast("Error favoriting lead. Something went wrong.");
        return;
      }
      setFavorite(true);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      favoriteLead: {
        id: data?.id,
        favorited: true,
        __typename: "Lead",
      },
    },
    refetchQueries: ["FetchAssociateContact"],
  });

  const [unfavoriteLead, { loading: unfavoriteLoading, error: unfavoriteError }] = useMutation(UNFAVORITE_LEAD, {
    variables: {
      lead_id: data?.id,
    },
    async onCompleted({ unfavoriteLead }) {
      if (!unfavoriteLead) {
        appToast("Error unfavoriting lead. Something went wrong.");
        return;
      }
      setFavorite(false);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      unfavoriteLead: {
        id: data?.id,
        favorited: false,
        __typename: "Lead",
      },
    },
    refetchQueries: ["FetchAssociateContact"],
  });

  const sameAsParentLead = mergeLeadsData?.source?.id === data?.id;

  const cannotBeMergedLead =
    data?.lead_ownership_status === "Retired" ||
    data?.lead_ownership_status === "Resting" ||
    data?.lead_ownership_status === "Customer";

  const userIsARep = loggedInUser().role === "SDR" || loggedInUser().role === "AE";

  const leadIsNotOwnedByUser =
    ["Owned", "Assigned"].includes(data.lead_ownership_status) && data?.rep_id !== loggedInUser().id;

  if (sameAsParentLead) return null;

  return (
    <LeadCard>
      {/* header */}
      <FlexDiv gap={8} align={"center"}>
        {favorite ? (
          <AiFillStar color={theme.TERTIARY500} onClick={() => unfavoriteLead()} style={{ cursor: "pointer" }} />
        ) : (
          <AiOutlineStar color={theme.PRIMARY500} onClick={() => favoriteLead()} style={{ cursor: "pointer" }} />
        )}
        <AppText
          fontSize={14}
          fontWeight={600}
          style={{ cursor: "pointer", border: "none" }}
          onClick={() => window.open(`/lead-detail/${data?.id}`, "_blank", "noreferrer")}
        >
          {data?.business_name ? data?.business_name : "NA"}
        </AppText>
      </FlexDiv>

      {/* body */}
      <FlexDiv direction="column" gap={16} justify="space-between" style={{ height: "100%" }}>
        <FlexDiv gap={16}>
          <StatCard variant="primary" icon={user_white} text="PRIMARY" />
          <FlexDiv direction="column">
            <AppText>{data?.full_name}</AppText>
            <AppText>
              {data?.city ? data?.city + ", " : ""}
              {data?.state ? data?.state + " " : ""}
              {data?.city || data?.state
                ? data?.timezone_by_state
                  ? data?.timezone_by_state?.split(" ").pop()
                  : ""
                : data?.timezone ?? ""}
            </AppText>
            <AppText>{data?.industry ?? ""}</AppText>
            <AppText>{`${data?.channel} Call`}</AppText>
            <AppText>{`MRR + One-Time Fees: ${formatUSDRaw(data?.computed_mrr)}`}</AppText>
          </FlexDiv>
        </FlexDiv>

        <FlexDiv direction="column" gap={16}>
          {renderEventTypeCard(data)}
          <MergeButton
            variant="primary"
            width={200}
            disabled={sameAsParentLead || cannotBeMergedLead || (userIsARep && leadIsNotOwnedByUser)}
            onClick={() => {
              setShowMergeLeadsModal(true);
              // apply lead data to modal context var
              const mergeLeadsDataCopy = { ...mergeLeadsData };
              mergeLeadsDataCopy.lead = {
                id: data?.id,
                full_name: data?.full_name,
                business_name: data?.business_name,
              };
              setMergeLeadsData(mergeLeadsDataCopy);
            }}
          >
            <img src={merge_white} alt="merge" />
            <AppText variant="white" fontWeight={600}>
              ASSOCIATE EXISTING
            </AppText>
          </MergeButton>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {cannotBeMergedLead ? (
              <PhoenixIcon
                data-tip={"Leads that are resting, retired, or customer status are unable to merged."}
                variant="neutral"
                svg={info}
                size={14}
              />
            ) : userIsARep && leadIsNotOwnedByUser ? (
              <PhoenixIcon data-tip={"You can only merge leads that you own."} variant="neutral" svg={info} size={14} />
            ) : null}
          </div>
        </FlexDiv>
      </FlexDiv>
    </LeadCard>
  );
};

interface EventTypeObject {
  [key: string]: any;
}

const renderEventTypeCard = (lead: EventTypeObject) => {
  const intentEventType = renderLeadIntentEventTypeValues(lead);

  return (
    <RenderEventTypeInfoDiv>
      {/* <EventIconDiv>{intentEventType.icon && <img src={intentEventType.icon} alt="event type icon" />}</EventIconDiv> */}

      <KeyEventDiv>
        <AppText fontSize={12} fontWeight={600} style={{ color: theme.PRIMARY500 }}>
          {intentEventType.leadValue}
        </AppText>
      </KeyEventDiv>

      <div>
        <EventTypeDiv>
          <AppText style={{ fontSize: 14 }}>{intentEventType.label}</AppText>
        </EventTypeDiv>
        <EventTypeMessage>
          <AppText style={{ fontSize: 12 }}>{!!intentEventType.message ? intentEventType.message : "---"}</AppText>
        </EventTypeMessage>
      </div>
      <EventTypeTooltip>
        <ReactTooltip
          place={"left"}
          multiline
          effect="solid"
          css={{
            maxWidth: 200,
            lineHeight: 1.4,
            textAlign: "center",
            fontFamily: theme.PRIMARY_FONT,
          }}
          backgroundColor={theme.PRIMARY800}
          getContent={(dataTip) => (
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              {dataTip}
            </span>
          )}
        />
        <PhoenixIcon data-tip={intentEventType.tooltip} variant="neutral" svg={info} size={14} />
      </EventTypeTooltip>
    </RenderEventTypeInfoDiv>
  );
};

const InfiniteScrollStyle = {
  display: "flex",
  flexWrap: "wrap" as "wrap",
  gap: "16px",
  // columns: "326px",
  // width: "100%",
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 0px 24px;
  height: 92%;
`;

const SearchBoxContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 320px;
  height: 40px;

  border-radius: 4.4px;
  border: solid 0.9px ${theme.PILL_GRAY};
  padding: 8px 8px 8px 30px;

  font-family: ${theme.PRIMARY_FONT};
  background-color: ${theme.WHITE_COLOR};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${theme.NEUTRAL300};
  }
`;

interface IconProps {
  url: string;
  color: string;
  size: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

const Icon = styled.div<IconProps>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  bottom: ${(props) => props.bottom}px;
  left: ${(props) => props.left}px;
  background-color: ${(props) => props.color};
  -webkit-mask: url(${(props) => props.url}) no-repeat center;
  mask: url(${(props) => props.url}) no-repeat center;
  mask-size: ${(props) => props.size}px;
  transition: background-color 0.2s ease;
`;

const ActionButton = styled(NewAppButton)`
  letter-spacing: 1px;
  font-size: 10px;
  min-width: 95px;
`;

const CloseButton = styled.img`
  cursor: pointer;
  width: 26px;
  height: 26px;
  max-width: 26px;
  max-height: 26px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  /* max-height: 750px;
  height: 750px; */
`;

const LeadCard = styled.div`
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 362px;
  padding: 16px;
  border: 1px solid ${theme.PILL_GRAY};
  border-radius: 8px;
  animation: fadeIn 0.3s forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const RenderEventTypeInfoDiv = styled.div`
  background-color: ${theme.NEUTRAL100};
  border-radius: 8px;
  padding: 16px;
  display: grid;
  grid-template-columns: 76px 1fr;
  height: fit-content;
  position: relative !important;
  grid-column-gap: 8px;
`;

const KeyEventDiv = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-right: 2px solid ${theme.NEUTRAL200};
`;

const EventTypeDiv = styled.div`
  /* grid-area: 1 / 2 / 2 / 4; */
  justify-self: start;
  white-space: nowrap;
`;

const EventTypeMessage = styled.div`
  grid-area: 2 / 1 / 3 / 5;
`;

const EventTypeTooltip = styled.div`
  /* grid-area: 1 / 4 / 2 / 5; */
  /* width: 100%; */
  text-align: right;
  position: absolute;
  right: 16px;
  top: 16px;
`;

const MergeButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  margin-left: auto;
`;

const NoneFoundText = styled(AppText)`
  color: ${theme.NEUTRAL300};
  margin-top: 32px;
  animation: fadeIn 0.3s forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
