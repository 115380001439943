import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

// this dumb wrapper detects if a click event happened outside of this wrapper and will run a passed in function

interface OutsideEventProps {
  onOutsideClick: () => void;
  disabled?: boolean;
}

const OutsideEvent: React.FC<OutsideEventProps> = ({ onOutsideClick, disabled, children }) => {
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: Event) => {
        console.log("event", disabled);
        if (ref.current && !ref.current.contains(event.target) && !disabled) {
          onOutsideClick();
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, disabled]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return <div ref={wrapperRef}>{children}</div>;
};

export { OutsideEvent };
