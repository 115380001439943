import React from "react";
import { Color, ColorResult, CustomPicker, InjectedColorProps } from "react-color";
import { Saturation, Hue, EditableInput } from "react-color/lib/components/common";
import { ExportedColorProps } from "react-color/lib/components/common/ColorWrap";
import { handleInputChange } from "react-select/src/utils";
import styled from "styled-components";
import { theme } from "../../../utils/theme";

/*
  This component is WIP. Use the minimal version until complete.
*/

interface ColorPickerProps extends InjectedColorProps {
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

const ColorPicker = (props: ColorPickerProps) => {
  const handleChange = (data: any, type?: string) => {
    switch (type) {
      case "r":
        props.setColor(`rgb(${data}, ${props.rgb?.g}, ${props.rgb?.b})`);
      case "g":
        props.setColor(`rgb(${props.rgb?.r}, ${data}, ${props.rgb?.b})`);
      case "b":
        props.setColor(`rgb(${props.rgb?.r}, ${props.rgb?.g}, ${data})`);
      default:
        props.setColor(data);
    }
    console.log("data:::", type, data, props.rgb, `rgb(${props.rgb?.r}, ${props.rgb?.g}, ${data})`);
  };

  return (
    <ColorWrapper>
      <SaturationWrapper>
        <Saturation
          {...props}
          color={props.hsl}
          pointer={() => (
            <Pointer
              style={{
                backgroundColor: props.hex,
              }}
            />
          )}
          onChange={(color: Color | ColorResult) => handleChange(color, "hsl")}
        />
      </SaturationWrapper>
      <HueWrapper>
        <Hue
          {...props}
          color={props.rgb}
          pointer={() => (
            <Pointer
              style={{
                transform: "translate(-50%, -25%)",
                background: `hsl(${props.hsl?.h} 100% 50%)`,
              }}
            />
          )}
          onChange={(color: Color | ColorResult) => handleChange(color, "hsl")}
        />
      </HueWrapper>

      <div
        style={{
          marginBottom: 10,
          position: "relative",
        }}
      >
        <EditableInput value={props.hex} onChange={(color: Color | ColorResult) => handleChange(color)} />
        <EditableInput value={props?.rgb?.r} onChange={(color: Color | ColorResult) => handleChange(color, "r")} />
        <EditableInput value={props?.rgb?.g} onChange={(color: Color | ColorResult) => handleChange(color, "g")} />
        <EditableInput value={props?.rgb?.b} onChange={(color: Color | ColorResult) => handleChange(color, "b")} />
      </div>
    </ColorWrapper>
  );
};

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 328px;
  height: fit-content;
  padding: 16px;

  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
`;

const SaturationWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;

  border-radius: 8px;

  overflow: hidden;

  > div {
    border-radius: 8px;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  }
`;

const HueWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 16px;

  border-radius: 24px;

  /* overflow: hidden; */

  .hue-horizontal {
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
  }
`;

const Pointer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);

  width: 32px;
  height: 32px;

  border: 8px solid ${theme.WHITE_COLOR};
  border-radius: 50px;
  box-shadow: 0px 0px 0px 1px ${theme.NEUTRAL200};
`;

interface PhoenixColorPickerProps extends ExportedColorProps {
  setColor: any;
}

const PhoenixColorPicker = CustomPicker<PhoenixColorPickerProps>(ColorPicker);
export { PhoenixColorPicker };
