import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../../utils/theme";
import moment from "moment";
import { AppText } from "../AppText";
import { PhoenixIcon } from "./PhoenixIcon";
import { arrow_right } from "../../../images/NewDesign";
import "react-dates/initialize";
import { DayPickerRangeController, FocusedInputShape } from "react-dates";
import { chevronUpBlue } from "../../../images";

interface PhoenixDateRangeProps {
  minWidth?: number; // default 196
  minHeight?: number; // default 40
  disabled?: boolean;

  lowerbound_date: moment.Moment | null;
  upperbound_date: moment.Moment | null;
  onDateChange?: (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void;
  onClose?: (dates: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void;

  keepOpenOnDateSelect?: boolean;
  style?: any;

  min_date?: moment.Moment | null;
  max_date?: moment.Moment | null;
}

export const PhoenixDateRange: React.FC<PhoenixDateRangeProps> = ({ ...props }) => {
  const { lowerbound_date, upperbound_date, max_date, min_date } = props;

  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const [dates, setDates] = useState<{ startDate: moment.Moment | null; endDate: moment.Moment | null }>({
    startDate: lowerbound_date,
    endDate: upperbound_date,
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>("startDate");

  const isDayBlocked = (date: moment.Moment) => {
    if (max_date) {
      return date.unix() > max_date.unix();
    }
    if (min_date) {
      return date.unix() < min_date.unix();
    }
    return false;
  };

  return (
    <Container
      minWidth={props.minWidth || 196}
      minHeight={props.minHeight || 40}
      disabled={props.disabled}
      onClick={() => !props.disabled && setShowCalendar(true)}
      style={props.style}
    >
      <AppText
        fontSize={12}
        fontWeight={400}
        lineHeight={18}
        color={props.disabled ? theme.NEUTRAL200 : theme.BLACK_COLOR}
      >
        {lowerbound_date?.format("MM/DD/YYYY")}
      </AppText>

      <PhoenixIcon
        svg={arrow_right}
        size={16}
        color={props.disabled ? theme.PRIMARY200 : theme.PRIMARY500}
        hoverColor={props.disabled ? theme.PRIMARY200 : theme.PRIMARY500}
        pointer={!props.disabled}
      />

      <AppText
        fontSize={12}
        fontWeight={400}
        lineHeight={18}
        color={props.disabled ? theme.NEUTRAL200 : theme.BLACK_COLOR}
      >
        {upperbound_date?.format("MM/DD/YYYY")}
      </AppText>

      {showCalendar && (
        <DatePickerContainer>
          <DayPickerRangeController
            startDate={dates.startDate}
            endDate={dates.endDate}
            focusedInput={focusedInput || "startDate"}
            onDatesChange={({ startDate, endDate }) => {
              const computedDates = { startDate, endDate: endDate ?? startDate };
              setDates(computedDates);
              props.onDateChange && props.onDateChange(computedDates);
              if (props.keepOpenOnDateSelect === false && startDate && endDate) {
                setShowCalendar(false);
              }
            }}
            onFocusChange={(newFocus) => setFocusedInput(newFocus)}
            initialVisibleMonth={() => moment()}
            numberOfMonths={2}
            onOutsideClick={() => {
              setShowCalendar(false);
              props.onClose && props.onClose(dates);
            }}
            minimumNights={0}
            isDayBlocked={isDayBlocked}
          />
        </DatePickerContainer>
      )}
    </Container>
  );
};

interface ContainerProps {
  minWidth?: number;
  minHeight?: number;
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: fit-content;
  height: fit-content;
  min-width: ${(props) => props.minWidth && `${props.minWidth}px`};
  min-height: ${(props) => props.minHeight && `${props.minHeight}px`};

  padding: 0px 16px;

  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL300)};
  border-radius: 4px;

  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  transition: border 0.2s ease-in-out;
  & ${AppText} {
    transition: color 0.2s ease-in-out;
  }
`;

const fadeInKeyframes = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    
    background-color: rgba(0, 0, 0, .3);
  }
`;

const DatePickerContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  cursor: default;

  animation: ${fadeInKeyframes} 0.2s ease-in-out forwards;

  // There is a prop for this but it doesnt seem to work with just DayPickerRangeController
  .DayPickerKeyboardShortcuts_show {
    display: none;
  }

  // Navigation button overrides
  .DayPickerNavigation_button__default {
    background: url(${chevronUpBlue}) no-repeat;
    border: none;
    padding: 12px;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    transform: rotate(90deg);
  }
  .DayPickerNavigation_button__horizontalDefault {
    top: 20px;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    transform: rotate(-90deg);
  }
  .DayPickerNavigation_button__default svg {
    display: none;
  }

  // Color overrides
  .CalendarDay__selected {
    background-color: ${theme.PRIMARY500};
    border-color: #3055eb;
  }
  .CalendarDay__selected_span {
    background-color: #587ffd;
    border-color: #527aff;
  }
  .CalendarDay__hovered_span {
    background-color: ${theme.PRIMARY450};
    border-color: #6589fe;
    color: #1c2852;
  }

  // Popup animation
  .DayPicker {
    animation: ${theme.popup} 0.25s ease-in-out forwards;
  }
  .DayPicker_transitionContainer__horizontal {
    transition: none;
  }
`;
