import React from "react";
import styled from "styled-components";
import { findStateCode, listBrazil, listMexico, provincesCanada, statesUSA } from "../../../static/countries";
import { theme } from "../../../utils/theme";
import { FormMultiSelectField, InputField, NewSelectField } from "../../Field";
import { PhoenixMultiSelectField } from "../../Field/Phoenix";

export const StateDropdown = ({
  country_code,
  callback,
  current_value,
  marginBottom,
}: {
  country_code: string;
  callback: Function;
  current_value?: string | null;
  marginBottom?: boolean;
}) => {
  switch (country_code) {
    case "MX":
      return (
        <PhoenixMultiSelectField
          isMulti={false}
          isClearable={false}
          marginBottom={marginBottom}
          name="state"
          value={!!current_value ? { label: current_value, value: current_value } : { label: "", value: "" }}
          options={listMexico?.map((item: any) => ({ label: item, value: item }))}
          onChange={(e: any) => {
            callback(`state`, !!e?.value ? e?.value : "");
          }}
        />
      );
    case "BR":
      return (
        <PhoenixMultiSelectField
          isMulti={false}
          isClearable={false}
          marginBottom={marginBottom}
          name="state"
          value={!!current_value ? { label: current_value, value: current_value } : { label: "", value: "" }}
          options={listBrazil?.map((item: any) => ({ label: item, value: item }))}
          onChange={(e: any) => {
            callback(`state`, !!e?.value ? e?.value : "");
          }}
        />
      );
    case "US":
      return (
        <PhoenixMultiSelectField
          isMulti={false}
          isClearable={false}
          marginBottom={marginBottom}
          name="state"
          options={statesUSA?.map((item) => ({
            label: `${item.name} (${item.state_code})`,
            value: item.state_code,
          }))}
          value={
            !!current_value
              ? { label: `${findStateCode(current_value)} (${current_value})`, value: current_value }
              : { label: "", value: "" }
          }
          onChange={(e: any) => {
            callback("state", !!e?.value ? e?.value : "");
          }}
        />
      );
    case "CA":
      return (
        <PhoenixMultiSelectField
          isMulti={false}
          isClearable={false}
          marginBottom={marginBottom}
          name="state"
          options={provincesCanada?.map((item) => ({
            label: `${item.name} (${item.state_code})`,
            value: item.state_code,
          }))}
          value={
            !!current_value
              ? { label: `${findStateCode(current_value, "CA")} (${current_value})`, value: current_value }
              : { label: "", value: "" }
          }
          onChange={(e: any) => {
            callback("state", !!e?.value ? e?.value : "");
          }}
        />
      );
    default:
      return <LeadInput disabled={!country_code} name="state" />;
  }
};

const LeadInput = styled(InputField)`
  margin: 0;
  text-align: left;
  font-size: 13px;
  border-radius: 2.9px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;
