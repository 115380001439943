import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { ModalContext } from "../../context";
import { Dispatch, SetStateAction } from "react";
import { Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { loggedInUser } from "../../apollo/cache";
import { UpdateFolderNamePopup } from ".";
import { appToast } from "../../utils/toast";
import { FETCH_VIEWABLE_FOLDERS } from "../Pages/CallLibrary";
import { PhoenixMultiSelectField } from "../Field/Phoenix";
import { OptionItem } from "../../types";

interface MyFormikProps {
  folder: string;
}

interface CallFeedbackProps {
  blinds: boolean;
  setBlinds: Dispatch<SetStateAction<boolean>>;
  conferenceID: string;
  moveCall?: boolean;
  moveSrcFolder?: string;
}

export const FETCH_ALL_VIEWABLE_FOLDERS = gql`
  query userFolders($id: String!) {
    user(where: { id: $id }) {
      id
      viewable_folders {
        id
        name
        size
        conferences {
          id
          duration
          salesperson
          created_at
        }
        owner {
          id
          full_name
        }
      }
    }
  }
`;

const ADD_CALL_TO_FOLDER = gql`
  mutation addCallToFolder($folder_id: String!, $conference_id: String!) {
    addCallToFolder(folder_id: $folder_id, conference_id: $conference_id) {
      id
      name
    }
  }
`;

const MOVE_CALL_FROM_FOLDER = gql`
  mutation moveCallFromFolder($src_folder_id: String!, $des_folder_id: String!, $conference_id: String!) {
    moveCallFromFolder(src_folder_id: $src_folder_id, des_folder_id: $des_folder_id, conference_id: $conference_id)
  }
`;

const SaveToFolderModal: React.FC<CallFeedbackProps> = ({
  blinds,
  setBlinds,
  conferenceID,
  moveCall = false,
  moveSrcFolder,
}) => {
  const { folderUpdateModal, setFolderUpdateModal } = useContext(ModalContext);
  const user_id = `${loggedInUser()?.id}` || "";

  const { data, loading, error } = useQuery(FETCH_VIEWABLE_FOLDERS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataAll, loading: loadingAll, error: errorAll } = useQuery(FETCH_ALL_VIEWABLE_FOLDERS, {
    variables: { id: user_id },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [addCallToFolder] = useMutation(ADD_CALL_TO_FOLDER, {
    onCompleted({ addCallToFolder }) {
      setBlinds(false);
      appToast("Call saved successfully!");
      if (!addCallToFolder) {
        return;
      }
    },
    onError({ message }) {
      toast(message);
      Sentry.captureEvent({
        message: `addCallToFolder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder", "userFolders"],
  });

  const [moveCallFromFolder] = useMutation(MOVE_CALL_FROM_FOLDER, {
    onCompleted({ moveCallFromFolder }) {
      setBlinds(false);
      appToast("Call moved successfully!");
      if (!moveCallFromFolder) {
        return;
      }
    },
    onError({ message }) {
      toast(message);
      Sentry.captureEvent({
        message: `moveCallFromFolder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder"],
  });

  if (loading || loadingAll)
    return (
      <Modal open={blinds} onClose={() => setBlinds(false)} closeButtonSize={16}>
        <FeedbackContainer>
          <Loading />
        </FeedbackContainer>
      </Modal>
    );
  if (error || errorAll)
    return (
      <Modal open={blinds} onClose={() => setBlinds(false)} closeButtonSize={16}>
        <FeedbackContainer>
          <AppErrorText>Error loading available folders</AppErrorText>
        </FeedbackContainer>
      </Modal>
    );

  const managerAdminOptions = data?.fetchViewableFolders
    ?.slice()
    ?.sort((a: { name: string }, b: { name: string }) => {
      if (a.name?.[0] > b.name?.[0]) return 1;
      if (a.name?.[0] < b.name?.[0]) return -1;
    })
    ?.map((item: any) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });

  const callFeedbackSchema = Yup.object().shape({
    folder: Yup.string().test(
      "not-in-folder",
      "This call is already saved to the selected folder",
      (folder?: string | null) => {
        return !!!dataAll?.user?.viewable_folders
          ?.slice()
          ?.filter((item: any) => item?.id === folder)
          ?.map((item: any) => item.conferences)[0]
          ?.filter((item: any) => item?.id === conferenceID).length;
      },
    ),
  });

  return (
    <Modal open={blinds} onClose={() => setBlinds(false)}>
      <FeedbackContainer>
        <Header>
          <AppText fontSize={16} fontWeight={600} lineHeight={22}>
            Move Recording
          </AppText>
        </Header>
        <Formik
          initialValues={{
            folder: "",
          }}
          onSubmit={(values) => {
            if (moveCall) {
              moveCallFromFolder({
                variables: {
                  src_folder_id: moveSrcFolder,
                  des_folder_id: values.folder,
                  conference_id: conferenceID,
                },
              });
            } else {
              addCallToFolder({
                variables: {
                  folder_id: values.folder,
                  conference_id: conferenceID,
                },
              });
            }
          }}
          validationSchema={callFeedbackSchema}
          isInitialValid={false}
        >
          {({ values, submitForm, setFieldValue, isValid }: FormikProps<MyFormikProps>) => {
            return (
              <>
                <UpdateFolderNamePopup blinds={folderUpdateModal} setBlinds={setFolderUpdateModal} />
                <PhoenixMultiSelectField
                  name="folder"
                  isMulti={false}
                  isClearable={false}
                  options={[...managerAdminOptions]}
                  width={396}
                  titleText="Select a Folder"
                  titleTextSpacing={8}
                  value={managerAdminOptions.find((item: OptionItem) => item.value === values.folder)}
                  onChange={(e: OptionItem) => setFieldValue("folder", e.value)}
                />
                <ButtonDiv>
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    onClick={() => setBlinds(false)}
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                  >
                    Cancel
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    buttonType="secondary"
                    uppercase
                    buttonTextFontSize={10}
                    style={{ letterSpacing: "1px" }}
                    onClick={submitForm}
                    disabled={!isValid}
                  >
                    Continue
                  </PhoenixAppButton>
                </ButtonDiv>
              </>
            );
          }}
        </Formik>
      </FeedbackContainer>
    </Modal>
  );
};

const FeedbackContainer = styled.div`
  display: flex;
  width: 476px;
  height: 312px;
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 16px 40px;
  margin-top: auto;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

export { SaveToFolderModal };
