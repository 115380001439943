import styled from "styled-components";
import * as React from "react";
import { theme } from "../../../utils/theme";
import { AiOutlineLeft } from "react-icons/ai";
import { AppInput, AppButton, AppText, AppSidebarCard, Waveform, Loading } from "../../UI";
import { Link } from "react-router-dom";

interface Lead {
  id: string;
  business_name: string;
  city: string;
  state: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry?: string;
  lead_source?: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

interface CallResult {
  id: string;
  label: string;
  related_disposition_reason?: string;
}

interface CallReportSideBarProps {
  data: Lead;
  salesperson: string;
  call_result: CallResult;
  from_system_view: boolean;
}

const CallReportSideBar: React.FC<CallReportSideBarProps> = ({ data, salesperson, call_result, from_system_view }) => {
  const { business_name, city, state, channel, first_name, last_name, current_lead_type, industry, lead_source } = data;
  return (
    <SidebarContainer>
      <SidebarWrapper>
        <Spread>
          <CallReportTitle>Call Report</CallReportTitle>
        </Spread>
        <CallDetailContainer>
          <TitleText>Call Details</TitleText>
          <CallReportDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Sales Rep</CallDetailTitleText>
              <CallDetailText>{salesperson}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Contact Name</CallDetailTitleText>
              <CallDetailText>{`${first_name} ${last_name}`}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Business Name</CallDetailTitleText>
              <CallDetailText>{business_name}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>City</CallDetailTitleText>
              <CallDetailText>{city ?? "N/A"}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>State</CallDetailTitleText>
              <CallDetailText>{state ?? "N/A"}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Channel</CallDetailTitleText>
              <CallDetailText>{channel}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Call Type</CallDetailTitleText>
              <CallDetailText>{current_lead_type}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Call Result</CallDetailTitleText>
              <CallDetailText>{call_result ? call_result.label : ""}</CallDetailText>
            </CallDetailDiv>
            {call_result?.related_disposition_reason && (
              <CallDetailDiv>
                <CallDetailTitleText>Reason</CallDetailTitleText>
                <CallDetailText>{call_result?.related_disposition_reason}</CallDetailText>
              </CallDetailDiv>
            )}
            <CallDetailDiv>
              <CallDetailTitleText>Lead Source</CallDetailTitleText>
              <CallDetailText>{lead_source}</CallDetailText>
            </CallDetailDiv>
            <CallDetailDiv>
              <CallDetailTitleText>Industry</CallDetailTitleText>
              <CallDetailText>{industry}</CallDetailText>
            </CallDetailDiv>
          </CallReportDetailDiv>
        </CallDetailContainer>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export { CallReportSideBar };

const CallReportTitle = styled(AppText)`
  width: 124px;
  height: 21px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 18px;
`;

const Spread = styled.div`
  position: absolute;
  top: -108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SidebarContainer = styled.div`
  /* padding-top: 17px; */
  max-width: calc(480px + 150px);
  /* padding-left: 150px; */
  /* min-height: 680px; */
  max-height: calc(100vh - 47px - 139px);
  height: auto;
`;

const SidebarWrapper = styled.div`
  position: relative;
  margin: 0;
  margin-top: 139px;
  height: 100%;
`;

const BackToDashboardContainer = styled.div`
  position: absolute;
  display: flex;
  width: 230px;
  height: 60px;
  border-radius: 4px;
  background-color: ${theme.TERTIARY500};
  z-index: 2;
  top: -30px;
  justify-content: center;
  align-items: center;
  transition: ease 300ms;
  :hover {
    cursor: pointer;
  }
`;

const CallDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 686px;
  width: 253px;
  background-color: ${theme.WHITE_COLOR};
  padding: 80px 0px 0px 25px;
`;

const BackToDashBoardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  :hover {
    cursor: pointer;
  }
`;

const TitleText = styled(AppText)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  font-weight: 500;
`;

const BackTitleText = styled(AppText)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  font-weight: 500;
  :hover {
    cursor: pointer;
  }
`;

const CallReportDetailDiv = styled.div`
  margin-top: 35px;
`;

const StyledBackArrow = styled(AiOutlineLeft)`
  margin-right: 12px;
`;

const CallDetailDiv = styled.div`
  margin-bottom: 12px;
`;

const CallDetailTitleText = styled(AppText)`
  font-size: 10px;
  color: ${theme.NEUTRAL500};
  margin-bottom: 5px;
`;
const CallDetailText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;
