import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI/AppText";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixInput } from "src/Components/UI/Phoenix";
import { useDebounce } from "src/utils/hooks";

interface FieldListHeaderProps {
  id: string;
  content: string;
  onInputChange: (id: string, newContent: string) => void;
}

export const FieldListHeader: React.FC<FieldListHeaderProps> = ({ id, content, onInputChange }) => {
  const [header, setHeader] = useState(content);

  useDebounce(
    () => {
      onInputChange(id, header);
    },
    [header],
    500,
  );

  return (
    <FlexDiv direction="column" gap={8}>
      <FieldText>Section Header</FieldText>

      <PhoenixInput
        value={header}
        width={376}
        displayNoContextText
        style={{ borderColor: theme.NEUTRAL300 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHeader(e.target.value)}
      />
    </FlexDiv>
  );
};

const FieldText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
