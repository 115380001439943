import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { NodeContainer } from "./NodeContainer";
import { Handle, Position } from "react-flow-renderer";
import { theme } from "../../../../utils/theme";
import { FlexDiv } from "../../FlexDiv";
import { AppText } from "../../AppText";
import { PhoenixIcon } from "../../Phoenix";
import { edit, more_vertical, phone_call, trash } from "../../../../images/NewDesign";
import { DispositionPill } from "../../DispositionPill";
import { useClickOutside } from "../../../../utils/hooks";
import { ModalContext } from "../../../../context";
interface DispositionNodeProps {
  data: any;
}

export const DispositionNode: React.FC<DispositionNodeProps> = ({ data }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showSequenceActionMenu, setShowSequenceActionMenu] = useState<boolean>(false);
  const [nodeIsFocus, setNodeIsFocus] = useState<boolean>(false);

  const { setSelectedSequenceStepId } = useContext(ModalContext);

  useEffect(() => {
    const curNode = document.getElementById(data.id);
    const parentNode = curNode?.parentElement;
    if (!!parentNode) {
      parentNode.style.zIndex = showTooltip ? "11" : nodeIsFocus ? "12" : "0";
    }
  }, [showTooltip, nodeIsFocus]);

  return (
    <NodeContainer
      padding="8px"
      borderColor={theme.PRIMARY500}
      id={data.id}
      style={{ position: "relative", overflow: "unset" }}
    >
      <Title>
        <PhoenixIcon svg={phone_call} variant="neutral" hoverColor={theme.NEUTRAL300} size={16} />
        <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
          Call Dispositions
        </AppText>
        {!data?.is_no_branch && (
          <div style={{ marginLeft: "auto", position: "relative" }}>
            <PhoenixIcon
              svg={more_vertical}
              size="small"
              pointer
              onClick={() => {
                setShowSequenceActionMenu(true);
                setNodeIsFocus(true);
                setSelectedSequenceStepId(data.id);
              }}
            />
            {showSequenceActionMenu && (
              <SequenceActionPopup
                setNodeIsFocus={setNodeIsFocus}
                setShowSequenceActionMenu={setShowSequenceActionMenu}
                nodeData={data}
              />
            )}
          </div>
        )}
      </Title>

      <FlexDiv gap={4} wrap="wrap" style={{ position: "relative" }}>
        {!!data?.conditions?.length &&
          data.conditions
            .slice(0, 9)
            ?.map((disposition: any, i: number) => (
              <DispositionPill key={`${data.id}-dispositionPill-${i}`} dispositionType={disposition?.dispositionType} />
            ))}
        {data?.conditions?.length > 9 && (
          <div style={{ position: "relative" }}>
            <EllipsisPill onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
              <Ellipsis svg={more_vertical} color={theme.NEUTRAL300} hoverColor={theme.NEUTRAL300} size={16} />
            </EllipsisPill>
            <TooltipContainer showTooltip={showTooltip}>
              {!!data?.conditions?.length && (
                <AppText key={`tooltip-${data?.id}`} color={theme.WHITE_COLOR}>
                  {data.conditions
                    .filter((ele: any) => !data.conditions.slice(0, 9).includes(ele))
                    ?.map((disposition: any, i: number) =>
                      disposition?.dispositionType === "TransferredAnotherSDRsSet"
                        ? "Transferred Another SDRs Set"
                        : disposition?.dispositionType.replace(/([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, "$1$2 "),
                    )
                    ?.join(", ")}
                </AppText>
              )}
            </TooltipContainer>
          </div>
        )}
      </FlexDiv>

      <Handle type="target" position={Position.Top} isConnectable={false} />
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </NodeContainer>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 2px 0px 6px 4px;
`;

interface TooltipProps {
  showTooltip: boolean;
}

const TooltipContainer = styled.div<TooltipProps>`
  // we have to use a custom tooltip. React flow dynamic diagram positioning breaks our tooltip library.

  display: ${(props) => (props.showTooltip ? "flex" : "none")};
  flex-direction: column;
  gap: 4px;

  position: fixed;
  top: 0;
  left: 100%;

  width: 260px;
  height: fit-content;

  padding: 10px;
  background-color: #0f2794df;
  border-radius: 3px;

  animation: ${theme.fadeIn} 0.2s ease;
`;

const EllipsisPill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 24px;

  background-color: ${theme.NEUTRAL200};
  border-radius: 4px;
`;

const Ellipsis = styled(PhoenixIcon)`
  transform: rotate(90deg);
  margin-top: 2px;
`;

interface SequenceActionPopupProps {
  setShowSequenceActionMenu: Dispatch<SetStateAction<boolean>>;
  setNodeIsFocus: Dispatch<SetStateAction<boolean>>;
  nodeData: any;
}

const SequenceActionPopup: React.FC<SequenceActionPopupProps> = ({
  setShowSequenceActionMenu,
  setNodeIsFocus,
  nodeData,
}) => {
  const containerRef = useRef(null);

  const { setSequenceStepData, setShowConfirmDeleteSequenceStep, setShowSequenceBranchModal } = useContext(
    ModalContext,
  );

  useClickOutside(containerRef, () => {
    setShowSequenceActionMenu(false);
    setNodeIsFocus(false);
  });

  return (
    <PopupContainer ref={containerRef}>
      <MenuItem
        onClick={() => {
          setShowSequenceBranchModal(true);
          setSequenceStepData({
            nodeData,
            originId: nodeData?.origin_id,
          });
        }}
      >
        <PhoenixIcon svg={edit} hoverColor={theme.PRIMARY500} size="small" pointer />
        Edit
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (!nodeData?.active) {
            setShowConfirmDeleteSequenceStep(true);
            setSequenceStepData({ nodeData, originId: nodeData?.origin_id });
          }
        }}
        active={nodeData?.active}
      >
        <PhoenixIcon
          svg={trash}
          variant={!nodeData?.active ? "danger" : "neutral"}
          hoverColor={!nodeData?.active ? theme.DANGER600 : theme.NEUTRAL300}
          size="small"
          pointer
        />
        Delete
      </MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 16px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;

  animation: ${theme.popup} 0.2s ease;
  overflow: hidden;
`;

interface MenuItemProps {
  active?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 152px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${(props) => (!props?.active ? theme.PRIMARY500 : "black")};
  }
`;
