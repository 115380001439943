import React from "react";
import { useField } from "formik";
import { AppInput, AppInputProps, AppFormTitle, AppText } from "../UI";
import styled from "styled-components";

interface StaticTextFieldProps extends AppInputProps {
  name: string;
  title?: string;
  style?: React.CSSProperties;
  fontSize?: number;
}

const StaticTextField = ({ style, name, title, fontSize }: StaticTextFieldProps) => {
  const [field] = useField(name);
  return (
    <Div style={style}>
      {title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      <ThisAppText fontSize={fontSize}>{field.value}</ThisAppText>
    </Div>
  );
};

const Div = styled.div`
  height: 50px;
  margin-bottom: 50px;
`;

interface TextProps {
  fontSize?: number;
}

const InputTitle = styled(AppFormTitle)`
  margin: 0;
  margin-bottom: 5px;
`;

const ThisAppText = styled(AppText)<TextProps>`
  font-size: ${(props) => props.fontSize}px;
`;

ThisAppText.defaultProps = {
  fontSize: 24,
};

export { StaticTextField };
