import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { AppText, Loading, AppErrorText, AppSidebarCard } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, gql, useQuery, MutationFunctionOptions, FetchResult, QueryLazyOptions } from "@apollo/client";
import { Dispatch, SetStateAction, useState } from "react";
import { CurrentLeadType, SelectedContactType } from "../../types";
import { LeadPoolSearchItem } from "./LeadPoolSearchItem";

const FETCH_SEARCHED_LEADS = gql`
  query fetchLeads($searchText: String, $skip: Int, $take: Int) {
    fetchLeads(searchText: $searchText, skip: $skip, take: $take) {
      id
      full_name
      business_name
      industry
      phase
      phase_label
      lead_source
      num_of_dials
      lead_value {
        id
        value
      }
      last_disposition_label
      rep {
        id
        full_name
      }
    }
  }
`;

interface LeadPoolSearchListProps {
  searchText: string;
  skip?: number;
  take?: number;
  drillDownFunction: (item: any) => void;
  setSearchState: Dispatch<SetStateAction<boolean>>;
  inputRef: any;
}

const LeadPoolSearchList: React.FC<LeadPoolSearchListProps> = ({
  searchText,
  skip,
  take,
  drillDownFunction,
  setSearchState,
  inputRef,
}) => {
  const { data, loading, error } = useQuery(FETCH_SEARCHED_LEADS, {
    fetchPolicy: "network-only",
    variables: {
      searchText,
      skip,
      take,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  if (loading) {
    return (
      <SearchListDiv>
        <Loading />;
      </SearchListDiv>
    );
  }

  if (error)
    return (
      <SearchListDiv>
        <CenterDiv>
          <AppErrorText>Error loading list</AppErrorText>
        </CenterDiv>
      </SearchListDiv>
    );

  return (
    <SearchListDiv>
      {data.fetchLeads.length === 0 ? (
        <CenterDiv>
          <AppText font-size={12}>No Results</AppText>
        </CenterDiv>
      ) : (
        data.fetchLeads?.map((item: any) => {
          return (
            <LeadPoolSearchItem
              key={item?.id}
              full_name={item?.full_name}
              business_name={item?.business_name ?? ""}
              drillDownFunction={drillDownFunction}
              setSearchState={setSearchState}
              dataItem={item}
              inputRef={inputRef}
            />
          );
        })
      )}
    </SearchListDiv>
  );
};

export { LeadPoolSearchList };

const SearchListDiv = styled.div`
  width: 250px;
  height: 250px;
  position: absolute;
  background-color: ${theme.WHITE_COLOR};
  overflow: auto;
  z-index: 5;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  ::-webkit-scrollbar {
    display: none;
  }
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
