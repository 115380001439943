import * as React from "react";
import styled, { keyframes } from "styled-components";
import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { AppText, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { theme } from "../../../utils/theme";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";
import { gql, useMutation, useQuery } from "@apollo/client";
import { errorToast } from "../../../utils/toast";
import { check, copy, xIcon } from "../../../images/NewDesign";
import { copyTextToClipboard } from "../../../utils/misc";
import { loggedInUser } from "../../../apollo/cache";

const FETCH_ORG_DOMAIN = gql`
  query fetchOrganizationDomain {
    fetchOrganizationDomain
  }
`;

const CREATE_OR_UPDATE_ORG_DOMAIN = gql`
  mutation createOrUpdateOrganizationDomain($domain: String!) {
    createOrUpdateOrganizationDomain(domain: $domain)
  }
`;

const VALIDATE_ORG_DOMAIN = gql`
  mutation validateOrganizationDomain {
    validateOrganizationDomain
  }
`;

interface RegisterDomainProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

type DNSType = {
  data: string;
  host: string;
  type: string;
  valid: boolean;
};

const StepRegisterDomain: React.FC<RegisterDomainProps> = () => {
  const [currentDomain, setCurrentDomain] = useState<string>("");

  const [domain, setDomain] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [verify, setVerify] = useState<boolean>(false);
  const [dnsList, setDNSList] = useState<DNSType[]>([]);
  const [dnsErrorList, setDNSErrorList] = useState<string[]>([]);

  const { data, loading, error } = useQuery(FETCH_ORG_DOMAIN, {
    fetchPolicy: "network-only",
    onCompleted({ fetchOrganizationDomain }) {
      console.log("fetchOrganizationDomain: ", fetchOrganizationDomain);
      if (!!fetchOrganizationDomain?.domain) {
        !!fetchOrganizationDomain?.valid && setCurrentDomain(fetchOrganizationDomain.domain);
        setDomain(fetchOrganizationDomain.domain);
        setDNSList(Object.values(fetchOrganizationDomain?.dns));
      }
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [
    createOrUpdateOrgDomain,
    { data: dataOrgDomain, loading: loadingOrgDomain, error: errorOrgDomain },
  ] = useMutation(CREATE_OR_UPDATE_ORG_DOMAIN, {
    async onCompleted({ createOrUpdateOrganizationDomain }) {
      console.log("createOrUpdateOrgDomain: ", createOrUpdateOrganizationDomain);
      setDNSList(Object.values(createOrUpdateOrganizationDomain?.dns));
    },
    onError({ message }) {
      errorToast(message);
    },
    refetchQueries: ["fetchOrganizationDomain"],
  });

  const [validateOrgDomain, { data: dataValidate, loading: loadingValidate, error: errorValidate }] = useMutation(
    VALIDATE_ORG_DOMAIN,
    {
      async onCompleted({ validateOrganizationDomain }) {
        console.log("validateOrganizationDomain: ", validateOrganizationDomain);
        if (validateOrganizationDomain?.valid) {
          setCurrentDomain(domain);
        } else {
          const results = Object.entries(validateOrganizationDomain?.validation_results);
          const invalidResults = results.filter(([key, value]: any) => !value?.valid);
          setDNSErrorList(invalidResults?.map((res: any[]) => res[1]?.reason));
        }
      },
      onError({ message }) {
        errorToast(message);
      },
    },
  );

  return (
    <Main>
      <Header>
        <AppText fontSize={22} fontWeight={500} noWrap>
          Register Domain
        </AppText>
      </Header>

      <FlexDiv direction="column" gap={24}>
        <div>
          <DescriptionText fontSize={12} style={{ marginBottom: loading ? "42px" : "24px" }}>
            Register your organization's domain so that emails you send will be from your domain and not sellfire.com.
          </DescriptionText>

          {!loading && !!currentDomain && (
            <CurrentDomainText fontSize={12}>Currently registered: '{currentDomain}'</CurrentDomainText>
          )}
        </div>

        <DomainContainer>
          {step !== 3 ? (
            <>
              <DomainHeader>
                <AppText
                  color={theme.PRIMARY500}
                  fontSize={10}
                  fontWeight={600}
                  style={{ letterSpacing: "1px", textTransform: "uppercase" }}
                >
                  Step {step}
                </AppText>

                <AppText fontSize={18} fontWeight={500} style={{ lineHeight: "24px" }}>
                  {step === 1 ? "Enter the domain you wish to authenticate." : "Add the following records to your DNS."}
                </AppText>
              </DomainHeader>

              <DomainBody direction="column" gap={step === 1 ? 40 : 16}>
                {step === 1 && (
                  <>
                    <PhoenixInput
                      value={domain}
                      onChange={(e: any) => setDomain(e.target.value)}
                      titleText="Domain You Send From"
                      placeholder={loading ? "Loading..." : "domain.com"}
                      width={376}
                      displayNoContextText
                    />

                    <FlexDiv direction="column" gap={8}>
                      <AppText color={theme.NEUTRAL350} fontSize={12}>
                        What recipients will see:
                      </AppText>
                      <PreviewPill>janedoe@{domain || "domain.com"}</PreviewPill>

                      <AppText color={theme.NEUTRAL350} fontSize={12} style={{ marginTop: "8px" }}>
                        Sellfire Default:
                      </AppText>
                      <PreviewPill>
                        {/* regex removes whitespace to reflect what is shown in email "Rapptr Test" -> "rapptrtest" */}
                        {loggedInUser()?.organization?.name?.toLowerCase().replace(/\s+/g, "") || "business"}
                        @sellfire.com
                      </PreviewPill>
                    </FlexDiv>
                  </>
                )}

                {step === 2 && (
                  <>
                    <TableContainer>
                      <Table>
                        <Row>
                          <Column>Type</Column>
                          <Column>Host</Column>
                          <Column>Points To</Column>
                        </Row>
                        {!loadingOrgDomain &&
                          (!!dataOrgDomain || !!data) &&
                          dnsList?.map((dns: DNSType) => (
                            <Row>
                              <Cell>CNAME</Cell>
                              <Cell>
                                {dns?.host}
                                <PhoenixIcon
                                  svg={copy}
                                  color={theme.PRIMARY500}
                                  hoverColor={theme.PRIMARY500}
                                  pointer
                                  style={{ float: "right" }}
                                  onClick={() => copyTextToClipboard(dns?.host || "")}
                                />
                              </Cell>
                              <Cell>
                                {dns?.data}
                                <PhoenixIcon
                                  svg={copy}
                                  color={theme.PRIMARY500}
                                  hoverColor={theme.PRIMARY500}
                                  pointer
                                  style={{ float: "right" }}
                                  onClick={() => copyTextToClipboard(dns?.data || "")}
                                />
                              </Cell>
                            </Row>
                          ))}
                      </Table>
                      {loadingOrgDomain && (
                        <FlexDiv justify="center" align="center" style={{ padding: "24px 0px" }}>
                          <Loading />
                        </FlexDiv>
                      )}
                    </TableContainer>

                    <FlexDiv align="center" gap={16} style={{ marginBottom: "24px" }}>
                      <AppText color={theme.PRIMARY500} fontSize={12} fontWeight={500}>
                        Verify:
                      </AppText>

                      <FlexDiv gap={8} align="center">
                        <PhoenixCheckbox checked={verify} onChange={() => setVerify(!verify)} />
                        <AppText fontSize={12} fontWeight={500}>
                          I've added these records
                        </AppText>
                      </FlexDiv>
                    </FlexDiv>
                  </>
                )}
              </DomainBody>

              <FlexDiv gap={8} style={{ marginTop: "20px", float: "right" }}>
                {step === 2 && (
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand-outline"
                    buttonTextFontSize={10}
                    uppercase
                    onClick={() => setStep(1)}
                  >
                    Back
                  </PhoenixAppButton>
                )}
                <PhoenixAppButton
                  buttonType="secondary"
                  buttonTextFontSize={10}
                  uppercase
                  onClick={() => {
                    if (step === 1) {
                      // create a new domain if the user changes the org value
                      domain.toLowerCase() !== data?.fetchOrganizationDomain?.domain?.toLowerCase() &&
                        createOrUpdateOrgDomain({
                          variables: {
                            domain: domain,
                          },
                        });
                      setStep(2);
                      verify && setVerify(false);
                    }
                    if (step === 2) {
                      validateOrgDomain();
                      setStep(3);
                    }
                  }}
                  disabled={loadingOrgDomain || (step === 1 && !domain) || (step === 2 && !verify)}
                >
                  {step === 1 ? "Next Step" : "Verify"}
                </PhoenixAppButton>
              </FlexDiv>
            </>
          ) : !loadingValidate ? (
            <ValidateContainer>
              <ResultBadge valid={dataValidate?.validateOrganizationDomain?.valid}>
                <PhoenixIcon
                  svg={dataValidate?.validateOrganizationDomain?.valid ? check : xIcon}
                  size={48}
                  color={dataValidate?.validateOrganizationDomain?.valid ? theme.PRIMARY500 : theme.DANGER600}
                  hoverColor={dataValidate?.validateOrganizationDomain?.valid ? theme.PRIMARY500 : theme.DANGER600}
                />
              </ResultBadge>
              <AppText fontSize={18} fontWeight={500}>
                {dataValidate?.validateOrganizationDomain?.valid ? "It worked!" : "That didn't work."}
              </AppText>
              <AppText fontSize={14} fontWeight={600}>
                Your authenticated domain for <span style={{ color: theme.PRIMARY500 }}>{domain}</span> was
                {!dataValidate?.validateOrganizationDomain?.valid && " not"} verified.
              </AppText>
              <div>
                {!dataValidate?.validateOrganizationDomain?.valid &&
                  dnsErrorList?.map((err: string) => (
                    <AppText color={theme.DANGER600} fontSize={10}>
                      {err}
                    </AppText>
                  ))}
              </div>

              <PhoenixAppButton
                buttonType="secondary"
                variant="brand"
                buttonTextFontSize={10}
                uppercase
                onClick={() => {
                  // reset
                  setStep(2);
                  setVerify(false);
                }}
                style={{ marginTop: "20px" }}
              >
                Return to Sender Authentication
              </PhoenixAppButton>
            </ValidateContainer>
          ) : (
            <MinHeightDiv>
              <Loading />
            </MinHeightDiv>
          )}
        </DomainContainer>
      </FlexDiv>
    </Main>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  padding: 24px;
  border-radius: 8px;
  height: 90%;
`;

const Header = styled.div`
  padding-bottom: 24px;
`;

const DescriptionText = styled(AppText)`
  min-width: 475px;
  max-width: 475px;
`;

const DomainContainer = styled.div`
  width: 720px;

  padding: 24px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
`;

const DomainHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

const DomainBody = styled(FlexDiv)``;

const PreviewPill = styled.div`
  padding: 8px 16px;

  width: fit-content;
  max-width: 672px;
  height: 32px;

  background-color: ${theme.PRIMARY100};
  border-radius: 4px;

  color: ${theme.PRIMARY500};
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 8px;
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:nth-child(odd) {
    background-color: ${theme.PRIMARY50};
  }
  tr:nth-child(even) {
    background-color: ${theme.WHITE_COLOR};
  }
`;

const Row = styled.tr`
  width: 100%;

  td:nth-child(1) {
    width: 10%;
    padding: 0px 26px 0px 42px;
  }
  td:nth-child(2) {
    width: 41%;
    padding: 0px 16px;
  }
  td:nth-child(3) {
    width: 100%;
    padding: 0px 16px;
  }
`;

const Column = styled.td`
  height: 32px;
  font-size: 10px;
  font-weight: 600;
`;

const Cell = styled.td`
  height: 32px;
  font-size: 10px;
  font-weight: 400;
`;

const MinHeightDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 304px;
`;

const ValidateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  padding: 44px;

  animation: ${theme.fadeIn} 0.25s ease-in-out;
`;

interface ResultBadgeProps {
  valid: boolean;
}

const ResultBadge = styled.div<ResultBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  border-radius: 64px;

  background-color: ${(props) => (props.valid ? theme.PRIMARY100 : theme.DANGER100)};
`;

const CurrentDomainText = styled(AppText)`
  animation: ${theme.fadeIn} 0.5s ease forwards;
`;

export { StepRegisterDomain };
