import React, { useMemo } from "react";
import { ellipse_large, ellipse_medium, ellipse_small } from "../../images/NewDesign";
import styled from "styled-components";

interface SpinnerProps {
  size?: number;
}

export const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  const innerSize = useMemo(() => (!!size ? size / 3 : 14), [size]);
  const middleSize = useMemo(() => (!!size ? (size / 3) * 2 : 28), [size]);

  return (
    <SpinnerContainer size={size}>
      <InnerSpinner size={innerSize}>
        <img src={ellipse_small} />
      </InnerSpinner>
      <MiddleSpinner size={middleSize}>
        <img src={ellipse_medium} />
      </MiddleSpinner>
      <OuterSpinner size={size}>
        <img src={ellipse_large} />
      </OuterSpinner>
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div<{ size?: number }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (!!props.size ? `${props.size}px` : "42px")};
  height: ${(props) => (!!props.size ? `${props.size}px` : "42px")};
`;

const InnerSpinner = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => (!!props.size ? `${props.size}px` : "14px")};
  height: ${(props) => (!!props.size ? `${props.size}px` : "14px")};

  & > img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: bottom;
    transform-origin: center;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    animation: spin 1.25s linear infinite;
  }
`;

const MiddleSpinner = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  width: ${(props) => (!!props.size ? `${props.size}px` : "28px")};
  height: ${(props) => (!!props.size ? `${props.size}px` : "28px")};

  & > img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: top;
    transform-origin: center;

    @keyframes spinR {
      100% {
        transform: rotate(-360deg);
      }
    }

    animation: spinR 1.25s linear infinite;
  }
`;
const OuterSpinner = styled.div<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  width: ${(props) => (!!props.size ? `${props.size}px` : "42px")};
  height: ${(props) => (!!props.size ? `${props.size}px` : "42px")};

  & > img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: bottom;
    transform-origin: center;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    animation: spin 1.25s linear infinite;
  }
`;
