import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppText, FlexDiv, Loading } from "../../../UI";
import { PhoenixAppButton, PhoenixDateRange, PhoenixMultiSelect } from "../../../UI/Phoenix";
import { Row } from "./Row";
import { Cell } from "./Cell";
import { theme } from "../../../../utils/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment, { Moment } from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorToast, successToast } from "../../../../utils/toast";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import { ROUTING_LOG_TABLE_DATE_OPTIONS } from "src/utils/misc";

const FETCH_ROUTING_HISTORY = gql`
  query fetchRoutingHistory($input: RoutingHistoryInput!) {
    fetchRoutingHistory(input: $input)
  }
`;

const REQUEST_HISTORY_REPORT = gql`
  mutation requestRoutingHistoryReport($ruleId: String!, $emails: [String!]!) {
    requestRoutingHistoryReport(rule_id: $ruleId, emails: $emails)
  }
`;

interface LogTableProps {
  rule_id?: string;
}

interface HistoryItem {
  lead_id: string;
  business_name: string;
  rep_name: string;
  date_routed: string;
  first_rep_action: string | null;
}

interface IDateDropdown {
  label: string;
  value: { lowerbound_date: Moment | null; upperbound_date: Moment | null };
}

const take = 100;

export const LogTable: React.FC<LogTableProps> = ({ rule_id }) => {
  const history = useHistory();

  const [historyList, setHistoryList] = useState<HistoryItem[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [showInitLoad, setShowInitLoad] = useState<boolean>(true);

  const [date, setDate] = useState<{ lowerbound_date: Moment | null; upperbound_date: Moment | null }>(
    ROUTING_LOG_TABLE_DATE_OPTIONS[1].value,
  );

  const [dropdownDate, setDropdownDate] = useState<IDateDropdown>(ROUTING_LOG_TABLE_DATE_OPTIONS[1]);

  const handleShowMore = () => setSkip((prev) => prev + take);

  useEffect(() => {
    // reset infinite scroll configuration
    setSkip(0);
    setHasMore(true);
    setShowInitLoad(true);
    setHistoryList([]);
  }, [date]);

  const { loading: loadingHistory } = useQuery(FETCH_ROUTING_HISTORY, {
    fetchPolicy: "network-only",
    variables: {
      input: {
        rule_id,
        skip,
        take,
        lowerbound_date: date.lowerbound_date?.startOf("day")?.toDate(),
        upperbound_date: date.upperbound_date?.endOf("day")?.toDate(),
      },
    },
    onCompleted({ fetchRoutingHistory }) {
      showInitLoad && setShowInitLoad(false);

      const incomingData = [...fetchRoutingHistory];
      const newList = [...historyList, ...incomingData];

      setHistoryList(newList);

      if (incomingData?.length < take) setHasMore(false);
      else setHasMore(true);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [requestHistoryReport, { loading: requestHistoryReportLoading }] = useMutation(REQUEST_HISTORY_REPORT, {
    onCompleted() {
      successToast("Requested Routing History Report. Email will be sent shortly.");
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      errorToast(message);
    },
  });

  return (
    <FlexDiv direction="column" gap={24}>
      <ControlContainer>
        <FlexDiv gap={16}>
          <FlexDiv direction="column" gap={8}>
            <AppText fontSize={12} fontWeight={500}>
              Date Range
            </AppText>
            <PhoenixDateRange
              lowerbound_date={date.lowerbound_date}
              upperbound_date={date.upperbound_date}
              onClose={(dates) => {
                if (isEqual(dates.startDate, date.lowerbound_date) && isEqual(dates.endDate, date.upperbound_date))
                  return;

                setDate({ lowerbound_date: dates.startDate, upperbound_date: dates.endDate });
                setDropdownDate({
                  label: "Custom Date Range",
                  value: { lowerbound_date: dates.startDate, upperbound_date: dates.endDate },
                });
              }}
            />
          </FlexDiv>

          <div style={{ paddingTop: "26px" }}>
            <PhoenixMultiSelect
              name="date-range"
              isMulti={false}
              isClearable={false}
              options={ROUTING_LOG_TABLE_DATE_OPTIONS}
              width={196}
              value={dropdownDate}
              onChange={(option: IDateDropdown) => {
                setDropdownDate(option);
                setDate(option.value);
              }}
              marginBottom={false}
              menuPortal
            />
          </div>
        </FlexDiv>

        <PhoenixAppButton
          variant="brand-outline"
          buttonType="secondary"
          disabled={requestHistoryReportLoading}
          onClick={() => {
            requestHistoryReport({ variables: { ruleId: rule_id, emails: [] } });
          }}
        >
          Export
        </PhoenixAppButton>
      </ControlContainer>

      <div>
        <div />
        <HeaderRow>
          <Cell bold>Name of Lead</Cell>
          <Cell bold>Time Routed</Cell>
          <Cell bold>Rep Assigned To</Cell>
          <Cell bold>Time Rep Next Dialed</Cell>
        </HeaderRow>
        <Body id="history-table-body">
          {loadingHistory && showInitLoad ? (
            <FlexDiv style={{ height: "250px" }}>
              <Loading />
            </FlexDiv>
          ) : (
            <InfiniteScroll
              dataLength={historyList.length}
              next={handleShowMore}
              hasMore={hasMore}
              loader={<Loading />}
              scrollableTarget="history-table-body"
            >
              {!historyList.length && <EmptyState>No data found</EmptyState>}
              {historyList?.map((rowData: HistoryItem) => {
                return (
                  <Row>
                    <Cell onClick={() => history.push(`/lead-detail/${rowData.lead_id}`)} style={{ cursor: "pointer" }}>
                      {rowData.business_name}
                    </Cell>
                    <Cell>{moment(rowData.date_routed).format("MM/DD/YYYY hh:mmA")}</Cell>
                    <Cell>{rowData.rep_name}</Cell>
                    <Cell>
                      {rowData.first_rep_action ? moment(rowData.first_rep_action).format("MM/DD/YYYY hh:mmA") : "--"}
                    </Cell>
                  </Row>
                );
              })}
            </InfiniteScroll>
          )}
        </Body>
      </div>
    </FlexDiv>
  );
};

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  background-color: ${theme.surface.brand.secondary};
`;

const Body = styled.div`
  max-height: 58vh;
  overflow-y: auto;

  @media (min-height: 1080px) {
    max-height: 78vh;
  }
`;

const EmptyState = styled.div`
  height: 300px;
  padding: 32px;
  font-size: 14px;

  background: linear-gradient(0deg, ${theme.WHITE_COLOR} 0%, ${theme.PRIMARY50} 100%);

  animation: ${theme.fadeIn} 0.5s ease-in-out;
`;
