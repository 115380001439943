import React, { useState } from "react";
import Switch from "react-switch";
import { theme } from "../../../../../utils/theme";
import { useQuery, useMutation } from "@apollo/client";
import { useDebounce } from "../../../../../utils/hooks";
import { appToast } from "../../../../../utils/toast";
import { gql } from "@apollo/client";
import { IIntegrations } from "src/types";
import { SkeletonBlock } from "src/Components/UI";
import { AppText } from "src/Components/UI";
import styled from "styled-components";

interface fetchSalesforceSyncRuleExpectedResponse {
  fetchSalesforceSyncRule: {
    organization_id: string;
    sfdc_inbound_creation_sync: boolean;
    sfdc_inbound_update_sync: boolean;
    sfdc_outbound_activity_sync: boolean;
    sfdc_outbound_creation_sync: boolean;
    sfdc_outbound_update_sync: boolean;
  };
}

const FETCH_SALESFORCE_SETTINGS = gql`
  query FetchSalesforceSyncRule {
    fetchSalesforceSyncRule {
      organization_id
      sfdc_inbound_creation_sync
      sfdc_inbound_update_sync
      sfdc_outbound_activity_sync
      sfdc_outbound_creation_sync
      sfdc_outbound_update_sync
    }
  }
`;

// we are going to have the settings in a state var and set with the fetch. a debounce listener will update the settings for all of the toggles

interface ISettings {
  leadCreationOutbound?: boolean;
  leadCreationInbound?: boolean;
  leadUpdateOutbound?: boolean;
  leadUpdateInbound?: boolean;
  leadActivityOutbound?: boolean;
}

const SalesforceSettings = ({}) => {
  const [initialSettings, setInitialSettings] = useState<ISettings | undefined>(undefined);
  const [currentSettings, setCurrentSettings] = useState<ISettings | undefined>(undefined);

  // fetch for SF
  const {
    data: salesforceData,
    loading: salesforceLoading,
    error: salesforceError,
  } = useQuery<fetchSalesforceSyncRuleExpectedResponse>(FETCH_SALESFORCE_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        const {
          fetchSalesforceSyncRule: {
            sfdc_inbound_creation_sync,
            sfdc_inbound_update_sync,
            sfdc_outbound_activity_sync,
            sfdc_outbound_creation_sync,
            sfdc_outbound_update_sync,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });

        setCurrentSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });
      }
    },
  });

  const UPDATE_SALESFORCE_SYNC_RULE = gql`
    mutation UpdateSFDCSyncRule($syncRuleInput: OrgSalesforceSyncInput!) {
      updateSFDCSyncRule(sync_rule_input: $syncRuleInput) {
        organization_id
        sfdc_inbound_creation_sync
        sfdc_inbound_update_sync
        sfdc_outbound_activity_sync
        sfdc_outbound_creation_sync
        sfdc_outbound_update_sync
      }
    }
  `;

  // mutation
  const [updateSalesforceSyncRule] = useMutation(UPDATE_SALESFORCE_SYNC_RULE, {
    onCompleted: (data) => {
      appToast("Updated Salesforce settings");

      if (data) {
        const {
          updateSFDCSyncRule: {
            sfdc_inbound_creation_sync,
            sfdc_inbound_update_sync,
            sfdc_outbound_activity_sync,
            sfdc_outbound_creation_sync,
            sfdc_outbound_update_sync,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });

        setCurrentSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });
      }
    },
  });

  useDebounce(
    () => {
      const notInitalized = !currentSettings || !initialSettings;

      if (notInitalized) {
        return;
      }

      const settingsHaveNotChanged =
        currentSettings.leadCreationOutbound === initialSettings.leadCreationOutbound &&
        currentSettings.leadCreationInbound === initialSettings.leadCreationInbound &&
        currentSettings.leadUpdateOutbound === initialSettings.leadUpdateOutbound &&
        currentSettings.leadUpdateInbound === initialSettings.leadUpdateInbound &&
        currentSettings.leadActivityOutbound === initialSettings.leadActivityOutbound;

      if (settingsHaveNotChanged) {
        return;
      }

      updateSalesforceSyncRule({
        variables: {
          syncRuleInput: {
            sfdc_inbound_creation_sync: currentSettings.leadCreationInbound,
            sfdc_inbound_update_sync: currentSettings.leadUpdateInbound,
            sfdc_outbound_activity_sync: currentSettings.leadActivityOutbound,
            sfdc_outbound_creation_sync: currentSettings.leadCreationOutbound,
            sfdc_outbound_update_sync: currentSettings.leadUpdateOutbound,
          },
        },
      });
    },
    [currentSettings],
    1000,
  );

  const error = salesforceError;

  const loading = salesforceLoading;

  const canToggle = !loading && !error;

  const Loader = () => {
    return (
      <Page>
        <SkeletonBlock height={"90vh"} width={"100%"} borderRadius={6} />
      </Page>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SettingsContainer>
        <SettingsContainerTitle>Lead Creation</SettingsContainerTitle>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadCreationOutbound: checked ? true : false,
                });
              }
            }}
            checked={!!currentSettings?.leadCreationOutbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Outbound Salesforce Lead Creation Sync`}</ToggleText>
        </ToggleRow>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadCreationInbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadCreationInbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Inbound Salesforce Lead Creation Sync`}</ToggleText>
        </ToggleRow>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsContainerTitle>Lead Update</SettingsContainerTitle>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadUpdateOutbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadUpdateOutbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Outbound Salesforce Lead Update Sync`}</ToggleText>
        </ToggleRow>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadUpdateInbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadUpdateInbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Inbound Salesforce Lead Update Sync`}</ToggleText>
        </ToggleRow>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsContainerTitle>Lead Activity</SettingsContainerTitle>
        <ToggleRow>
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setCurrentSettings({
                  ...currentSettings,
                  leadActivityOutbound: checked,
                });
              }
            }}
            checked={!!currentSettings?.leadActivityOutbound}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
          <ToggleText>{`Outbound Salesforce Lead Activity Sync`}</ToggleText>
        </ToggleRow>
      </SettingsContainer>
    </>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const ToggleText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
`;

const PageHeader = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const SettingsContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
`;
const IntegrationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IntegrationLogoImg = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
`;

const IntegrationName = styled(AppText)`
  font-size: 20px;
  font-weight: 600;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export default SalesforceSettings;
