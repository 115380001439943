import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppText, Loading, AppErrorText, AppSidebarCard, NewAppButton } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery } from "@apollo/client";
import { FETCH_SWITCH_LEAD_CARD_OPTION_POLL_INTERVAL } from "../../../utils/variables";
import { toast } from "react-toastify";
import { useContext, useEffect } from "react";
import { CallContext } from "../../../context";
import { appToast } from "../../../utils/toast";

const FETCH_SWITCH_LEAD_CARD_OPTION = gql`
  query fetchSwitchLeadCardOption {
    fetchSwitchLeadCardOption
  }
`;

const SWITCH_TO_SCHEDULED_EVENT = gql`
  mutation {
    switchToScheduledEvent
  }
`;

const SWITCH_TO_COLD_CALL = gql`
  mutation {
    switchToColdCall
  }
`;

interface SwitchBackCardProps {}

const SwitchBackCard: React.FC<SwitchBackCardProps> = ({}) => {
  const { callState } = useContext(CallContext);
  const { data, loading, error, startPolling, stopPolling } = useQuery(FETCH_SWITCH_LEAD_CARD_OPTION, {
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    console.log("callState on switch back: ", callState);
    if (!callState) {
      startPolling(FETCH_SWITCH_LEAD_CARD_OPTION_POLL_INTERVAL);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
      console.log("switch back stop polling");
    };
  }, [callState]);

  const [
    switchToScheduledEvent,
    { loading: switchToScheduledEventLoading, error: switchToScheduledEventError },
  ] = useMutation(SWITCH_TO_SCHEDULED_EVENT, {
    async onCompleted({ switchToScheduled }) {
      console.log("switchToScheduled: ", switchToScheduled);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `switchToScheduledEvent GraphQL Error: ${message}`,
      });
      console.log("Error in createNewLead: ", message);
    },
    refetchQueries: ["fetchSwitchLeadCardOption"],
  });

  const [switchToColdCall, { loading: switchToColdCallLoading, error: switchToColdCallError }] = useMutation(
    SWITCH_TO_COLD_CALL,
    {
      async onCompleted({ switchToColdCall }) {
        console.log("switchToColdCall: ", switchToColdCall);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `switchToColdCall GraphQL Error: ${message}`,
        });
        console.log("Error in createNewLead: ", message);
      },
      refetchQueries: ["fetchSwitchLeadCardOption"],
    },
  );

  const onButtonClick = () => {
    switch (data.fetchSwitchLeadCardOption) {
      case "ScheduledCallBack":
        switchToScheduledEvent();
        break;
      case "ColdCall":
        switchToColdCall();
        break;
      default:
        break;
    }
  };

  const renderText = () => {
    switch (data.fetchSwitchLeadCardOption) {
      case "ScheduledCallBack":
        return "Switch to Schedule Call Back";
      case "ColdCall":
        return "Switch to Cold Call";
      default:
        break;
    }
  };

  if (loading) {
    return null;
  }
  if (error) return null;

  if (!data.fetchSwitchLeadCardOption) {
    return null;
  }

  return (
    <SwitchBackCardContainer>
      <NewAppButton onClick={() => onButtonClick()}>{renderText()}</NewAppButton>
    </SwitchBackCardContainer>
  );
};

const SwitchBackCardContainer = styled.div`
  width: 378px;
  border: 4px solid ${theme.TERTIARY500};
  border-radius: 7px;
  margin: 10px 0px;
`;

export { SwitchBackCard };
