import React, { useState, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { DocumentNode, useMutation, useQuery } from "@apollo/client";
import { theme } from "../../utils/theme";
import {
  PhoenixAppButton,
  PhoenixDateRange,
  PhoenixExpandButton,
  PhoenixIcon,
  PhoenixMultiSelect,
} from "../UI/Phoenix";
import { loggedInUser } from "../../apollo/cache";
import { SequenceNav } from "../UI/Sequences/SequenceNav";
import { ModalContext } from "../../context";
import { AppText } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { chevron_right, eye_off, folder, pin, trash } from "../../images/NewDesign";
import Switch from "react-switch";
import { ReportingSegment } from "../UI/Sequences/ReportingSegment";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { Segment, SequenceLeaderboardSort, TimeframeOptionItem } from "../../types/SequenceTypes";
import { cloneDeep } from "lodash";
import { leaderboardMetricOptions, sequenceReportingTimeframeOptions } from "../../utils/sequences";
import {
  FETCH_GLOBAL_FILTER_SEQUENCES,
  FETCH_DASHBOARD_VIEWS,
  defaultSegments,
  getInitialCustomRange,
  defaultReportingView,
  CREATE_OR_UPDATE_SEQUENCE_DASHBOARD_VIEW,
} from "../../utils/sequence-reporting";
import { getLocalStorage } from "../../utils/misc";
import { SequenceLeaderboard } from "../UI/Sequences/SequenceLeaderboard";
import moment from "moment";
import { SelectASequenceModal } from "../modal/Sequences/SelectASequenceModal";
import { SequenceReportingFilterModal } from "../modal/Sequences/SequenceReportingFilterModal";
import { HideReportingSectionModal } from "../modal/Sequences/HideReportingSectionModal";
import { EditSectionModal } from "../modal/Sequences/EditSectionModal";
import { ReportingViewModal } from "../modal/Sequences/ReportingViewModal";
import { OptionProps, components } from "react-select";
import { appToast, errorToast } from "../../utils/toast";
import { MixpanelActions } from "../../services/mixpanel";
import { APP_REP_ROLES, APP_ROLES } from "../../utils/variables";

export const SequenceReporting: React.FC = () => {
  const {
    sequenceNavExpanded,
    setSequenceNavExpanded,
    showSelectASequenceModal,
    setShowSelectASequenceModal,
    showSequenceReportingFilterModal,
    showHideReportingSectionModal,
    showEditSectionModal,
    showReportingViewModal,
    setShowReportingViewModal,
  } = useContext(ModalContext);

  const reportingFilters = useMemo(() => getLocalStorage("sequence_reporting_filters"), []);

  // Global Filters
  const [sequencesFilter, setSequencesFilter] = useState<boolean>(reportingFilters?.sequencesFilter ?? false);
  const [selectedSequences, setSelectedSequences] = useState<string[]>(reportingFilters?.selectedSequences ?? []);
  const [phasesFilter, setPhasesFilter] = useState<boolean>(reportingFilters?.phasesFilter ?? false);
  const [selectedPhases, setSelectedPhases] = useState<string[]>(reportingFilters?.selectedPhases ?? []);
  const [salesTeamsAndRepsFilter, setSalesTeamsAndRepsFilter] = useState<boolean>(
    reportingFilters?.salesTeamsAndRepsFilter ?? false,
  );
  const [selectedSalesTeamsAndReps, setSelectedSalesTeamsAndReps] = useState<{
    roles: string[];
    teams: string[];
    reps: string[];
    sites: string[];
  }>(
    reportingFilters?.selectedSalesTeamsAndReps ?? {
      roles: [],
      teams: [],
      reps: [],
      sites: [],
    },
  );
  const [timeframe, setTimeframe] = useState<TimeframeOptionItem>(
    reportingFilters?.timeframe ?? sequenceReportingTimeframeOptions[0],
  );
  const [customRange, setCustomRange] = useState<{
    lowerbound_date: moment.Moment | null;
    upperbound_date: moment.Moment | null;
  }>(getInitialCustomRange());
  const customRangeFormatted = useMemo(
    () => ({
      lowerbound_date: customRange.lowerbound_date?.startOf("day").toDate(),
      upperbound_date: customRange.upperbound_date?.endOf("day").toDate(),
    }),
    [customRange],
  );
  const [leaderboardMetric, setLeaderboardMetric] = useState<{ label: string; value: string }>(
    reportingFilters?.leaderboardMetric || leaderboardMetricOptions[0],
  );
  const [leaderboardOrder, setLeaderboardOrder] = useState<SequenceLeaderboardSort>(
    reportingFilters?.leaderboardOrder || "rate-desc",
  );
  const [filterModalType, setFilterModalType] = useState<"Sequence" | "Phase" | "Sales Team and Rep">("Sequence");

  // View controllers
  const [view, setView] = useState<{ label: string; value: any; id?: string; disableDeleteOption?: boolean }>(
    getLocalStorage("sequence_reporting_view", defaultReportingView),
  );
  const [initialViewLoad, setInitialViewLoad] = useState<boolean>(true);
  const [viewModalData, setViewModalData] = useState<{ id?: string; delete?: boolean }>({});

  // Segment controllers
  const [segments, setSegments] = useState<Segment[]>(getLocalStorage("sequence_reporting_segments", defaultSegments));
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);
  const [finishedLoadingCharts, setFinishedLoadingCharts] = useState<DocumentNode[]>([]);

  /** Segments not in the current view. Mapped at bottom of page in 'Hidden Sections' */
  const hiddenSegments = useMemo(() => {
    if (!segments) return [];
    return defaultSegments.filter((segment: Segment) => !segments.find((ele) => ele.type === segment.type));
  }, [segments]);

  useEffect(() => {
    // Update local storage on segment change
    localStorage.setItem("sequence_reporting_segments", JSON.stringify(segments));
  }, [segments]);

  useEffect(() => {
    // Update local storage on global filter change
    const newLocalSequenceReportingFilters = {
      sequencesFilter,
      phasesFilter,
      salesTeamsAndRepsFilter,
      timeframe,
      customRange,
      selectedSequences,
      selectedPhases,
      selectedSalesTeamsAndReps,
      leaderboardMetric,
      leaderboardOrder,
    };
    localStorage.setItem("sequence_reporting_filters", JSON.stringify(newLocalSequenceReportingFilters));
  }, [
    sequencesFilter,
    phasesFilter,
    salesTeamsAndRepsFilter,
    timeframe,
    customRange,
    selectedSequences,
    selectedPhases,
    selectedSalesTeamsAndReps,
    leaderboardMetric,
    leaderboardOrder,
  ]);

  useEffect(() => {
    // Update page state and layout on view change
    if (!initialViewLoad) {
      localStorage.setItem("sequence_reporting_segments", view.value.sequence_reporting_segments);
      localStorage.setItem("sequence_reporting_filters", view.value.sequence_reporting_filters);
      const parsedFilters = JSON.parse(view.value.sequence_reporting_filters);
      setSegments(JSON.parse(view.value.sequence_reporting_segments));
      setSequencesFilter(parsedFilters.sequencesFilter || false);
      setSelectedSequences(parsedFilters.selectedSequences || []);
      setPhasesFilter(parsedFilters.phasesFilter || false);
      setSelectedPhases(parsedFilters.selectedPhases || []);
      setSalesTeamsAndRepsFilter(parsedFilters.salesTeamsAndRepsFilter || false);
      setSelectedSalesTeamsAndReps(
        parsedFilters.selectedSalesTeamsAndReps || { roles: [], teams: [], reps: [], sites: [] },
      );
      setTimeframe(parsedFilters.timeframe || sequenceReportingTimeframeOptions[0]);
      setCustomRange(
        {
          lowerbound_date: moment(parsedFilters.customRange.lowerbound_date),
          upperbound_date: moment(parsedFilters.customRange.upperbound_date),
        } || getInitialCustomRange(),
      );
      setLeaderboardMetric(parsedFilters.leaderboardMetric || leaderboardMetricOptions[0]);
      setLeaderboardOrder(parsedFilters.leaderboardOrder || "rate-desc");
    }

    initialViewLoad && setInitialViewLoad(false);
    view && localStorage.setItem("sequence_reporting_view", JSON.stringify(view));
  }, [view]);

  useEffect(() => {
    MixpanelActions.track("Sequence Performance Viewed");
  }, []);

  const { data: allSequences } = useQuery(FETCH_GLOBAL_FILTER_SEQUENCES, {
    fetchPolicy: "cache-and-network",
    variables: {
      sequencesFilter: {},
    },
  });
  const { data: viewData, loading: loadingViewData } = useQuery(FETCH_DASHBOARD_VIEWS, {
    fetchPolicy: "network-only",
    onCompleted({ fetchDashboardsViews }) {
      if (
        // If view is saved in local storage but is deleted in DB, set view to default view
        // This is only possible if there are multiple users using one account or multitab
        !fetchDashboardsViews.find(
          (ele: {
            id: string;
            sequence_reporting_filters: JSON;
            sequence_reporting_segments: JSON;
            view_name: string;
          }) => ele.view_name === view.label,
        ) &&
        !view.disableDeleteOption
      ) {
        setView(defaultReportingView);
      }
    },
  });

  const [createOrUpdateSequenceDashboardView, { loading: loadingUpdateView }] = useMutation(
    CREATE_OR_UPDATE_SEQUENCE_DASHBOARD_VIEW,
    {
      onCompleted({ createOrSequenceDashboardView }) {
        console.log("createOrSequenceDashboardView: ", createOrSequenceDashboardView);
        appToast("View updated successfully");
      },
      onError({ message }) {
        console.log("Error in createOrSequenceDashboardView: ", message);
        errorToast(message);
      },
      refetchQueries: ["fetchDashboardsViews"],
    },
  );

  if (loggedInUser()?.role !== APP_ROLES.ADMIN) return null;

  const onSegmentDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || !source) return;

    const segmentsCopy = cloneDeep(segments);
    const [splicedSegment] = segmentsCopy.splice(source.index, 1);
    segmentsCopy.splice(destination.index, 0, splicedSegment);

    setSegments(segmentsCopy);
  };

  const { Option } = components;
  const CustomViewOption = (props: OptionProps<any, false, any>) => (
    <Option {...props}>
      {props.data.label}
      {!props.data.disableDeleteOption && (
        <PhoenixIcon
          svg={trash}
          size={15}
          pointer
          style={{ float: "right", paddingTop: "1px" }}
          onClick={(e: React.MouseEvent) => {
            if (!loadingViewData) {
              e.stopPropagation();
              setViewModalData({ id: props.data.id, delete: true });
              setShowReportingViewModal(true);
            }
          }}
        />
      )}
    </Option>
  );

  return (
    <Main expanded={sequenceNavExpanded}>
      {showSelectASequenceModal && (
        <SelectASequenceModal segments={segments} setSegments={setSegments} sequenceData={allSequences} />
      )}
      {showHideReportingSectionModal && (
        <HideReportingSectionModal selectedSegment={selectedSegment} segments={segments} setSegments={setSegments} />
      )}
      {showEditSectionModal && (
        <EditSectionModal selectedSegment={selectedSegment} segments={segments} setSegments={setSegments} />
      )}
      {showReportingViewModal && (
        <ReportingViewModal setView={setView} viewModalData={viewModalData} setViewModalData={setViewModalData} />
      )}
      {showSequenceReportingFilterModal && (
        <SequenceReportingFilterModal
          sequenceData={allSequences}
          filterType={filterModalType}
          onClose={(newValue: string[] | { roles: string[]; teams: string[]; reps: string[]; sites: string[] }) => {
            filterModalType === "Sequence"
              ? setSelectedSequences(newValue as string[])
              : filterModalType === "Phase"
              ? setSelectedPhases(newValue as string[])
              : setSelectedSalesTeamsAndReps(
                  newValue as { roles: string[]; teams: string[]; reps: string[]; sites: string[] },
                );
            if (filterModalType === "Sales Team and Rep" && salesTeamsAndRepsFilter) {
              setFinishedLoadingCharts([]);
            }
          }}
        />
      )}

      <PhoenixExpandButton
        expandedState={sequenceNavExpanded}
        moveDirection="right"
        moveDistance={224}
        top={24}
        left={72}
        onClick={() => setSequenceNavExpanded(!sequenceNavExpanded)}
      />
      <SequenceNav expanded={sequenceNavExpanded} />

      <TopBanner>
        <AppText fontSize={22} fontWeight={500} style={{ lineHeight: "40px" }}>
          Performance
        </AppText>
        <FlexDiv align="center" gap={8}>
          <div style={{ width: "227px" }}>
            <PhoenixMultiSelect
              name="view-select"
              value={view}
              options={[
                defaultReportingView,
                ...(loadingViewData ? [{ label: "Loading...", value: "", disabled: true }] : []),
                ...(viewData?.fetchDashboardsViews?.map((ele: any) => ({
                  label: ele.view_name,
                  value: {
                    sequence_reporting_filters: ele.sequence_reporting_filters,
                    sequence_reporting_segments: ele.sequence_reporting_segments,
                  },
                  id: ele.id,
                })) ?? []),
              ]}
              onChange={(e: { label: string; value: any; id?: string; disabled?: boolean }) =>
                !e.disabled && setView(e)
              }
              isMulti={false}
              isClearable={false}
              marginBottom={false}
              components={{ Option: CustomViewOption }}
            />
          </div>
          <PhoenixAppButton
            buttonType="secondary"
            variant="brand-outline"
            disabled={view?.disableDeleteOption || loadingUpdateView} // disabled if default view
            onClick={() =>
              createOrUpdateSequenceDashboardView({
                variables: {
                  sequenceDashboardViewInput: {
                    sequence_reporting_filters: JSON.stringify(getLocalStorage("sequence_reporting_filters")),
                    sequence_reporting_segments: JSON.stringify(getLocalStorage("sequence_reporting_segments")),
                    view_name: view.label,
                    view_id: view.id,
                  },
                },
              })
            }
          >
            <AppText variant="primary" fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
              Update View
            </AppText>
          </PhoenixAppButton>
          <PhoenixAppButton buttonType="secondary" onClick={() => setShowReportingViewModal(true)}>
            <FlexDiv gap={16} align="center">
              <PhoenixIcon svg={folder} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} pointer />
              <AppText variant="white" fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
                Create View
              </AppText>
            </FlexDiv>
          </PhoenixAppButton>

          {/* Export data button not required for MVP.  */}

          {/* <PhoenixAppButton buttonType="secondary" variant="brand-outline">
            <FlexDiv gap={16} align="center">
              <PhoenixIcon svg={download} size={16} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} pointer />
              <AppText variant="primary" fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
                Export Data
              </AppText>
            </FlexDiv>
          </PhoenixAppButton> */}
        </FlexDiv>
      </TopBanner>

      <Body>
        <SequenceLeaderboard
          sequenceData={allSequences?.fetchSequences}
          timeframe={timeframe?.value?.lowerbound_date === "custom" ? customRangeFormatted : timeframe.value}
          filterBySequences={sequencesFilter ? selectedSequences : []}
          filterByPhases={phasesFilter ? selectedPhases : []}
          filterBySalesTeamsAndReps={
            salesTeamsAndRepsFilter ? selectedSalesTeamsAndReps : { roles: [], teams: [], reps: [], sites: [] }
          }
          leaderboardMetric={leaderboardMetric}
          setLeaderboardMetric={setLeaderboardMetric}
          leaderboardOrder={leaderboardOrder}
          setLeaderboardOrder={setLeaderboardOrder}
        />

        <SegmentContainer>
          <FlexDiv direction="column" gap={16}>
            <FlexDiv justify="space-between" align="center" style={{ width: "100%", paddingTop: "1px" }}>
              <AppText fontSize={18} fontWeight={500}>
                Global Filters
              </AppText>
              <FlexDiv gap={8}>
                <PhoenixMultiSelect
                  name="timeframe-filter-select"
                  value={timeframe}
                  options={sequenceReportingTimeframeOptions}
                  onChange={(item: TimeframeOptionItem) => {
                    setTimeframe(item);
                    setFinishedLoadingCharts([]);
                  }}
                  isMulti={false}
                  isClearable={false}
                  marginBottom={false}
                  width={227}
                />
                <PhoenixDateRange
                  lowerbound_date={customRange.lowerbound_date}
                  upperbound_date={customRange.upperbound_date}
                  disabled={timeframe.value?.lowerbound_date !== "custom"}
                  keepOpenOnDateSelect={true}
                  onClose={({ startDate, endDate }) =>
                    setCustomRange({ lowerbound_date: startDate, upperbound_date: endDate })
                  }
                />
              </FlexDiv>
            </FlexDiv>

            <GlobalFilterWrap>
              <GlobalFilter
                checked={sequencesFilter}
                onChange={(checked: boolean) => setSequencesFilter(checked)}
                onModalOpen={() => setFilterModalType("Sequence")}
                title="Sequences"
                description="By default all sequences are reflected. Turn on sequence filters to view selected sequences only."
              />
              <GlobalFilter
                checked={phasesFilter}
                onChange={(checked: boolean) => setPhasesFilter(checked)}
                onModalOpen={() => setFilterModalType("Phase")}
                title="Phases"
                description="By default all phases are reflected. Turn on phase filters to view selected phases only."
                style={{ borderLeft: `1px solid ${theme.NEUTRAL200}`, borderRight: `1px solid ${theme.NEUTRAL200}` }}
              />
              <GlobalFilter
                checked={salesTeamsAndRepsFilter}
                onChange={(checked: boolean) => {
                  setSalesTeamsAndRepsFilter(checked);
                }}
                onModalOpen={() => setFilterModalType("Sales Team and Rep")}
                title="Sales Teams and Reps"
                description="Apply filters to view specific sales teams or filter by rep."
              />
            </GlobalFilterWrap>

            <DragDropContext onDragEnd={onSegmentDragEnd}>
              <Droppable droppableId="dnd-segment-container" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {segments?.map((segment: Segment, i: number) => (
                      <ReportingSegment
                        key={`segment-${segment.type}-${i}`}
                        index={i}
                        segmentData={segment}
                        segments={segments}
                        setSegments={setSegments}
                        sequenceData={allSequences?.fetchSequences}
                        timeframe={
                          timeframe?.value?.lowerbound_date === "custom" ? customRangeFormatted : timeframe.value
                        }
                        filterBySequences={sequencesFilter ? selectedSequences : []}
                        filterByPhases={phasesFilter ? selectedPhases : []}
                        filterBySalesTeamsAndReps={
                          salesTeamsAndRepsFilter
                            ? selectedSalesTeamsAndReps
                            : { roles: [], teams: [], reps: [], sites: [] }
                        }
                        setSelectedSegment={setSelectedSegment}
                        finishedLoadingCharts={finishedLoadingCharts}
                        setFinishedLoadingCharts={setFinishedLoadingCharts}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </FlexDiv>

          <PinSequenceSegment>
            <FlexDiv gap={8} align="center">
              <PhoenixIcon svg={pin} size={16} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} pointer />
              <AppText fontSize={14} fontWeight={500} lineHeight={20} color={theme.PRIMARY500}>
                Pin a Sequence
              </AppText>
            </FlexDiv>

            <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => setShowSelectASequenceModal(true)}>
              <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.WHITE_COLOR}>
                Select Sequence
              </AppText>
            </PhoenixAppButton>
          </PinSequenceSegment>

          {!!hiddenSegments.length && (
            <HiddenSectionContainer direction="column" gap={16}>
              <AppText fontSize={12} lineHeight={18} fontWeight={400}>
                Hidden Sections
              </AppText>
              <FlexDiv gap={8} wrap="wrap">
                {hiddenSegments?.map((segment) => (
                  <HiddenSection direction="column" gap={4}>
                    <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
                      {segment?.type}
                    </AppText>
                    <FlexDiv
                      gap={8}
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => setSegments([segment, ...segments])}
                    >
                      <PhoenixIcon
                        svg={eye_off}
                        color={theme.PRIMARY500}
                        hoverColor={theme.PRIMARY500}
                        size={12}
                        pointer
                      />
                      <AppText fontSize={10} fontWeight={500} lineHeight={14} color={theme.PRIMARY500}>
                        Show This Section
                      </AppText>
                    </FlexDiv>
                  </HiddenSection>
                ))}
              </FlexDiv>
            </HiddenSectionContainer>
          )}
        </SegmentContainer>
      </Body>
    </Main>
  );
};

interface GlobalFilterProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  onModalOpen: () => void;
  title: string;
  description: string;
  style?: React.CSSProperties;
}

const GlobalFilter: React.FC<GlobalFilterProps> = ({ checked, onChange, onModalOpen, title, description, style }) => {
  const { setShowSequenceReportingFilterModal } = useContext(ModalContext);

  return (
    <GlobalFilterContainer style={style}>
      <FlexDiv gap={8} align="center">
        <Switch
          onChange={onChange}
          checked={checked}
          onColor={theme.PRIMARY500}
          offColor={theme.NEUTRAL300}
          height={16}
          width={32}
          handleDiameter={12}
          checkedIcon={false}
          uncheckedIcon={false}
          activeBoxShadow="0 0 3px #00000078"
        />
        <AppText fontSize={14} fontWeight={500}>
          {title}
        </AppText>

        <FlexDiv
          gap={4}
          align="center"
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={() => {
            setShowSequenceReportingFilterModal(true);
            onModalOpen();
          }}
        >
          <AppText uppercase letterSpacing={1} fontSize={10} fontWeight={600} color={theme.PRIMARY500} lineHeight={16}>
            View
          </AppText>
          <PhoenixIcon svg={chevron_right} size={16} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} pointer />
        </FlexDiv>
      </FlexDiv>

      <AppText
        fontSize={12}
        fontWeight={500}
        color={theme.NEUTRAL300}
        style={{ lineHeight: "18px", paddingTop: "6px" }}
      >
        {description}
      </AppText>
    </GlobalFilterContainer>
  );
};

interface MainProps {
  expanded: boolean;
}

const Main = styled.div<MainProps>`
  min-width: 1200px;
  margin-left: ${(props) => (props.expanded ? "224px" : "0px")};
  transition: margin-left 0.22s ease-in-out;
`;

const TopBanner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 24px 32px 64px;

  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const Body = styled.div`
  display: flex;
  gap: 24px;

  padding: 16px 0px 0px 16px;
`;

const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 0px 16px 48px 0px;

  overflow-y: auto;
  max-height: calc(100vh - 124px);
`;

const GlobalFilterWrap = styled.div`
  display: flex;
  width: 100%;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
`;

const GlobalFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px 8px 16px;

  width: 100%;
`;

const PinSequenceSegment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;
  min-height: 152px;
  margin-bottom: 24px;

  border: 1px dashed ${theme.PRIMARY500};
  border-radius: 8px;
`;

const HiddenSectionContainer = styled(FlexDiv)`
  padding-top: 16px;
  border-top: 1px solid ${theme.NEUTRAL300};
`;

const HiddenSection = styled(FlexDiv)`
  min-width: 150px;
  height: fit-content;
  padding: 8px 16px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 6px;
  background-color: ${theme.WHITE_COLOR};
`;
