import moment from "moment-timezone";
import React, { useContext, useMemo, useEffect } from "react";

import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppErrorText, AppText, FlexDiv, SkeletonBlock } from "../../UI";

import { loggedInUser } from "../../../apollo/cache";
import "../../../utils/react-big-calendar.css";

import "../../../utils/react-big-calendar.css";

import { InputField } from "../../Field/InputField";

import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { CallContext } from "src/context";
import { AlternateContactType, OptionItem } from "src/types";
import { google_meet_logo, phone_white, trash, zoom_logo } from "../../../images/NewDesign";
import { FormAreaInputField } from "../../Field";
import { appToast } from "src/utils/toast";

const FETCH_LEAD_EMAILS = gql`
  query fetchLead($id: String!, $always_return_primary: Boolean) {
    fetchLead(id: $id, always_return_primary: $always_return_primary) {
      id
      full_name
      primary_email
      alternate_contacts {
        id
        title
        channel
        type
        value
        mapping_order
        contact_label
        country_code
      }
    }
  }
`;

const FETCH_ASSOCIATED_LEADS_EMAILS = gql`
  query FetchAssociateContact($primary_lead_id: String!) {
    fetchAssociateContact(primary_lead_id: $primary_lead_id) {
      id
      full_name
      primary_email
      alternate_contacts {
        id
        title
        channel
        type
        value
        mapping_order
        contact_label
        country_code
      }
    }
  }
`;

const CalendarInviteStep = ({
  eventType,
  values,
  setFieldValue,
  validateForm,
  loadingSwitches,
  formatedEventDuration,
  timezone_list_items,
  zoomIntegration,
  googleMeetIntegration,
  zoomIsEnabledOnOrg,
  googleMeetIsEnabledOnOrg,
  loadingCheckUserZoomStatus,
  selectedEventRepHasZoomEnabled,
  selectedEventRepHasGoogleEnabled,
  fromNonCallActivity,
  joinMeMeeting,
  createJoinMe,
  removeJoinMeItem,
  loadingJoinMe,
  submitForm,
  requireNotes,
  performedNoteAction,
  loadingRemoveJoinMeItem,
  formatedEventTitle,
  isValid,
  loadingCreate,
  setDispositionStep,
}: {
  eventType: string;
  values: any;
  setFieldValue: any;
  validateForm: any;
  loadingSwitches: boolean;
  formatedEventDuration: number | undefined;
  timezone_list_items: any;
  zoomIntegration: boolean;
  googleMeetIntegration: boolean;
  zoomIsEnabledOnOrg: boolean;
  googleMeetIsEnabledOnOrg: boolean;
  loadingCheckUserZoomStatus: boolean;
  selectedEventRepHasZoomEnabled: boolean;
  selectedEventRepHasGoogleEnabled: boolean;
  fromNonCallActivity: boolean;
  joinMeMeeting: any;
  createJoinMe: any;
  removeJoinMeItem: any;
  loadingJoinMe: boolean;
  submitForm: () => void;
  requireNotes: boolean;
  performedNoteAction: boolean;
  loadingRemoveJoinMeItem: boolean;
  formatedEventTitle: string;
  isValid: boolean;
  loadingCreate: boolean;
  setDispositionStep: (step: number) => void;
}) => {
  const meetingTypeOptions = [
    {
      label: "Phone",
      value: "PHONE",
      customIcon: {
        type: "svg" as "svg" | "img",
        src: phone_white,
        inputIconColor: theme.PRIMARY500,
      },
    },
    ...(googleMeetIntegration && googleMeetIsEnabledOnOrg && selectedEventRepHasGoogleEnabled
      ? [
          {
            label: "Google Meet",
            value: "GOOGLE_MEET",
            customIcon: {
              type: "img" as "svg" | "img",
              src: google_meet_logo,
            },
          },
        ]
      : []),
    ...(zoomIntegration && zoomIsEnabledOnOrg && selectedEventRepHasZoomEnabled
      ? [
          {
            label: "Zoom",
            value: "ZOOM",
            customIcon: {
              type: "img" as "svg" | "img",
              src: zoom_logo,
            },
          },
        ]
      : []),
  ];

  const { getCallLeadId } = useContext(CallContext);

  // find all of the possible emails for the lead
  const { data: leadData } = useQuery(FETCH_LEAD_EMAILS, {
    fetchPolicy: "cache-and-network",
    variables: { id: getCallLeadId(), always_return_primary: true },
    skip: !getCallLeadId(),
  });

  const [fetchAssociatedLeadsEmails, { data: associatedLeadsData }] = useLazyQuery(FETCH_ASSOCIATED_LEADS_EMAILS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (leadData?.fetchLead?.id) {
      fetchAssociatedLeadsEmails({ variables: { primary_lead_id: leadData.fetchLead.id } });
    }
  }, [leadData?.fetchLead?.id, fetchAssociatedLeadsEmails]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const leadEmails = useMemo(() => {
    const primaryLeadEmails = leadData?.fetchLead?.primary_email;
    const alternateContacts = leadData?.fetchLead?.alternate_contacts || [];

    // associated leads
    const associatedLeads = associatedLeadsData?.fetchAssociateContact || [];

    const associatedLeadsPrimaryEmails = associatedLeads?.map((lead: any) => lead?.primary_email);
    const associatedLeadsAlternateContacts = associatedLeads?.map((lead: any) => lead?.alternate_contacts) || [];

    const filterAlternateContacts = (alternateContacts: AlternateContactType[]) => {
      return (
        alternateContacts
          ?.map((contact: AlternateContactType) => {
            if (contact?.channel === "Email") {
              return contact.value;
            }
            return null;
          })
          ?.filter(Boolean) || []
      );
    };

    const filteredAlternateContacts = filterAlternateContacts(alternateContacts);
    const filteredAssociatedLeadsAlternateContacts = filterAlternateContacts(associatedLeadsAlternateContacts);

    let emails = [
      primaryLeadEmails ?? null,
      ...filteredAlternateContacts,
      ...associatedLeadsPrimaryEmails,
      ...filteredAssociatedLeadsAlternateContacts,
    ].filter(Boolean);

    // if no emails saved on lead, return the primary email the user was forced to enter on the previous step
    if (emails.length === 0)
      return [
        {
          label: values?.primary_email,
          value: values?.primary_email,
        },
      ];

    return emails?.map((email: string) => ({ label: email, value: email }));
  }, [leadData, associatedLeadsData]);

  const findPersonFromEmail = (email: string) => {
    const primaryLead = leadData?.fetchLead;
    const associatedLeads = associatedLeadsData?.fetchAssociateContact;

    // primary email checks
    if (email === primaryLead?.primary_email) return primaryLead?.full_name || "Primary";

    // associated lead primary email checks
    const associatedLeadPrimaryEmail = associatedLeads?.find((lead: any) => lead?.primary_email === email);
    if (associatedLeadPrimaryEmail) return associatedLeadPrimaryEmail?.full_name || "Associated";

    // alternate contact email checks
    if (primaryLead?.alternate_contacts?.find((contact: AlternateContactType) => contact?.value === email))
      return primaryLead?.full_name || "Primary";

    // associated lead alternate contact email checks
    const associatedLeadAlternateEmail = associatedLeads?.find(
      (lead: any) =>
        lead?.alternate_contacts &&
        lead.alternate_contacts.find((contact: AlternateContactType) => contact?.value === email),
    );
    if (associatedLeadAlternateEmail) return associatedLeadAlternateEmail?.full_name || "Associated";

    return "Guest";
  };

  const showMeetingType =
    (googleMeetIntegration && googleMeetIsEnabledOnOrg && selectedEventRepHasGoogleEnabled) ||
    (zoomIntegration && zoomIsEnabledOnOrg && selectedEventRepHasZoomEnabled);

  return (
    <>
      <HeaderContainer>
        <AppText fontSize={16}>Confirm Event Detail</AppText>
      </HeaderContainer>
      <MainContainer>
        {loadingSwitches ? (
          <SkeletonBlock height={"70vh"} width={"100%"} borderRadius={4} />
        ) : (
          <>
            <PhoenixStyledTooltip id="calendar-invite-tooltip" place="top" fontSize={10} padding={8} />
            <FlexDiv gap={4} direction="column">
              <AppText fontSize={16} fontWeight={600}>
                {eventType}
              </AppText>
              <AppText fontSize={12} fontWeight={400}>
                {/* time EX: Wednesday, June 26th * 8:00 AM - 9:00 AM (EST) */}
                {moment(values?.specific_time).tz(values?.timezone)?.format("dddd, MMMM Do")} *{" "}
                {moment(values?.specific_time).tz(values?.timezone)?.format("h:mm A")} -{" "}
                {moment(values?.specific_time)
                  .tz(values?.timezone)
                  .add(formatedEventDuration, "minutes")
                  .format("h:mm A")}
                &nbsp;(
                {timezone_list_items?.slice()?.filter((item: any) => item?.value === values?.timezone)[0]?.label})
              </AppText>
            </FlexDiv>
            <FlexDiv gap={4} direction="column">
              <PopupInputLabel>Event Title</PopupInputLabel>
              <MeetingInput id="event-title" name="event_title" displayNoContext />
            </FlexDiv>
            {showMeetingType && (
              <FlexDiv gap={4} direction="column">
                <PopupInputLabel>Meeting Type</PopupInputLabel>
                <PhoenixMultiSelect
                  isClearable={false}
                  name="meeting_type"
                  isMulti={false}
                  options={meetingTypeOptions}
                  value={meetingTypeOptions?.find((option: any) => option?.value === values.meeting_type)}
                  onChange={(value: any) => {
                    setFieldValue("meeting_type", value?.value);
                  }}
                  marginBottom={false}
                />
              </FlexDiv>
            )}

            {values?.calendar_invite && (
              <>
                <FlexDiv gap={4} direction="column">
                  <PopupInputLabel>Guests</PopupInputLabel>
                  <PhoenixMultiSelect
                    name="guest_list"
                    value={values?.guest_list?.map((guest: string) => ({ label: guest, value: guest }))}
                    options={leadEmails}
                    creatableOptions
                    hideSelectedOptions
                    marginBottom={false}
                    placeholder="Add guests by their email"
                    onChange={(value: any) => {
                      // check newest value
                      if (
                        value?.map((guest: OptionItem) => guest.value)?.filter((email: string) => !isValidEmail(email))
                          .length > 0
                      ) {
                        appToast("Please enter a valid email");
                        return;
                      }
                      // add value to email list
                      setFieldValue(
                        "guest_list",
                        value?.map((guest: any) => guest.value),
                      );
                    }}
                  />
                  {values?.guest_list?.map((guest_email: string) => (
                    <FlexDiv key={guest_email} justify="space-between" padding="2px 8px">
                      <FlexDiv direction="column">
                        <AppText fontSize={12} fontWeight={600}>
                          {findPersonFromEmail(guest_email)}
                        </AppText>
                        <AppText fontSize={10} fontWeight={500} color={theme.text.neutral.secondary}>
                          {guest_email}
                        </AppText>
                      </FlexDiv>
                    </FlexDiv>
                  ))}
                </FlexDiv>

                <FlexDiv gap={4} direction="column">
                  <PopupInputLabel>Event {values?.calendar_invite ? "Description" : "Notes"}</PopupInputLabel>
                  <MeetingDescriptionInput id="event-description" name="event_description" />
                  {(values.meeting_type === "ZOOM" || values.meeting_type === "GOOGLE_MEET") && (
                    <AppText color={theme.NEUTRAL400}>
                      {values.meeting_type === "ZOOM" ? "Zoom" : "Google Meet"} invite will be added to the event{" "}
                      {values.calendar_invite ? "description" : "notes"}.
                    </AppText>
                  )}
                </FlexDiv>
              </>
            )}
            <FlexDiv
              direction="row"
              data-for={loggedInUser()?.timekit_type === "Local" ? "calendar-invite-tooltip" : undefined}
              data-tip="Calendar must be synced to send an invite."
              width={"max-content"}
            >
              <PhoenixCheckbox
                disabled={loggedInUser()?.timekit_type === "Local"}
                checked={!!values.calendar_invite}
                onChange={() => {
                  setFieldValue("calendar_invite", !values.calendar_invite);
                  validateForm();
                }}
              />
              <AppText>Send invitation email to guests</AppText>
            </FlexDiv>
          </>
        )}
      </MainContainer>

      <SubmitButton
        disabled={!isValid || loadingCreate || (requireNotes && !performedNoteAction)}
        buttonType="secondary"
        variant="brand"
        onClick={() => {
          submitForm();
          if (values.calendar_invite) {
            setDispositionStep(4);
          }
        }}
        fromNonCallActivity={fromNonCallActivity}
        data-for={requireNotes && !performedNoteAction ? "missing-required-notes" : ""}
        data-tip={"Please add a note before proceeding."}
      >
        Confirm Event
      </SubmitButton>
    </>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 40px 0px;
  padding: 0px 16px;
`;

interface ErrorProp {
  emailIsValid?: boolean;
}

const MeetingDescriptionInput = styled(FormAreaInputField)`
  max-height: 130px;
  min-height: 130px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const MeetingInput = styled(InputField)`
  margin: 0px;
  height: 40px;

  text-align: left;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const PopupInputLabel = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
`;

interface LocationProp {
  fromNonCallActivity?: boolean;
}

const SubmitButton = styled(PhoenixAppButton)<LocationProp>`
  margin-top: 48px;
  width: ${(props) => (props.fromNonCallActivity ? "100%" : "262px")};
  height: 47px;
`;

export default CalendarInviteStep;
