import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { NewAppButton } from "./NewAppButton";
import { AppText } from "./AppText";
import { theme } from "../../utils/theme";
import { chevron_left } from "../../images/NewDesign";
import { PhoenixIcon } from "./Phoenix";

interface BackButtonProps {
  to?: string;
}

export const AppBackButton: React.FC<BackButtonProps> = ({ to }) => {
  const history = useHistory();
  return (
    <BackButton
      // borderless
      height={22}
      onClick={() => {
        if (to) {
          history.push(to);
          return;
        }

        history.goBack();
      }}
    >
      <PhoenixIcon svg={chevron_left} size={16} hoverColor={theme.PRIMARY500} pointer />
      <AppText variant="primary" fontSize={10} fontWeight={600}>
        BACK
      </AppText>
    </BackButton>
  );
};

const BackButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: left;
  width: max-content;
  padding: 0;
  margin-bottom: 12px;

  p,
  div {
    transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  }

  :hover {
    p {
      color: ${theme.PRIMARY500};
    }
    div {
      border-color: ${theme.PRIMARY500};
    }
  }
`;

const Caret = styled.div`
  border-left: 1px solid ${theme.NEUTRAL300};
  border-top: 1px solid ${theme.NEUTRAL300};
  rotate: -45deg;
  width: 7px;
  height: 7px;
`;
