import styled from "styled-components";
import { theme } from "../../../../../utils/theme";

interface WidgetButtonProps {
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number;
  height?: number;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "attention" | "confirm" | string;
  hoverVariant?: "primary" | "secondary" | "attention" | "confirm";
  size?: "sm" | "md" | "lg";
  selected?: boolean;
  backgroundColor?: string;
  textColor?: string;
  fontFamily?: string;
  fontSize?: number;
}

export const WidgetButton = styled.button.attrs((props: WidgetButtonProps) => {
  const defaultStyles = {
    fontFamily: props.fontFamily,
    height: props.height ?? "40px",
    color: props.textColor || theme.BLACK_COLOR,
  };
  switch (props.variant) {
    case "primary":
      return {
        style: {
          ...defaultStyles,
          background: props.disabled
            ? props.backgroundColor
            : // props bacckground color is used for the selected state
            props.backgroundColor
            ? props.backgroundColor
            : theme.PRIMARY500,
          border: props.disabled
            ? `1px solid ${props.backgroundColor}`
            : `1px solid ${props.backgroundColor || theme.PRIMARY500}`,
        },
      };
    case "secondary":
      return {
        style: {
          ...defaultStyles,
          background: props.disabled ? theme.WHITE_COLOR : props.selected ? props.backgroundColor : theme.WHITE_COLOR,
          border: props.disabled
            ? `1px solid ${theme.NEUTRAL200}`
            : props.selected
            ? `1px solid ${props.backgroundColor}`
            : `1px solid ${theme.NEUTRAL200}`,
        },
      };
    case "attention":
      return {
        style: {
          ...defaultStyles,
          background: props.selected ? theme.ATTENTION700 : theme.WHITE_COLOR,
          border: props.selected ? `1px solid ${theme.ATTENTION700}` : `1px solid ${theme.NEUTRAL200}`,
        },
      };
    case "confirm":
      return {
        style: {
          ...defaultStyles,
          background: props.disabled ? theme.SUCCESS100 : theme.SUCCESS500,
          border: props.disabled ? `1px solid ${theme.SUCCESS100}` : `1px solid ${theme.SUCCESS500}`,
        },
      };
    default:
      return {
        style: {
          ...defaultStyles,
          background: "transparent",
          border: "transparent",
        },
      };
  }
})<WidgetButtonProps>`
  padding: 0px 4px 0px 4px;
  border-radius: 8px;
  font-size: // fontSize is set to fontsize if it is specified
    ${(props) => (props.fontSize ? props.fontSize + "px" : "14px")};
  font-weight: 600;
  transition: all 0.15s ease-in-out;

  ${(props) => {
    if (props.width) {
      return `
      width:${props.width}px;
      `;
    } else {
      switch (props.size) {
        case "sm":
          return `
      width:33%;
      `;
        case "md":
          return `
      width:50%;
    `;
        case "lg":
          return `
      width:66%;
    `;
        default:
          return `
      width:100%;
    `;
      }
    }
  }}

  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    ${(props) => {
      if (!props.disabled && !props.selected) {
        switch (!!props.hoverVariant ? props.hoverVariant : props.variant) {
          case "primary":
            return `
        background-color: ${props.backgroundColor};

        border-color : ${props.backgroundColor};
      `;
          case "secondary":
            return `
        background-color: ${theme.WHITE_COLOR};

        border-color :  ${props.backgroundColor};
    `;
          case "attention":
            return `
        background-color: ${theme.ATTENTION100};

        border-color : ${theme.ATTENTION700};
    `;
          case "confirm":
            return `
        background-color: ${theme.SUCCESS700};

        border-color : ${theme.SUCCESS700};
    `;
          default:
            return `
        background-color: transparent;
  
        border:  transparent;
    `;
        }
      }
    }};
  }
`;

WidgetButton.defaultProps = {
  // height: 40,
  disabled: false,
};
