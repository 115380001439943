import React, { useContext } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";

interface ToggleIBCModalProps {
  onToggle?: () => void;
}

export const ToggleIBCModal: React.FC<ToggleIBCModalProps> = ({ onToggle }) => {
  const { showToggleIBCModal, setShowToggleIBCModal } = useContext(ModalContext);

  return (
    <Modal
      open={showToggleIBCModal}
      onClose={() => {
        setShowToggleIBCModal(false);
      }}
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Toggle IBC
          </AppText>

          <FlexDiv direction="column" align="center">
            <AppText fontSize={14} fontWeight={400}>
              Are you sure you want to continue this?
            </AppText>
            <AppText fontSize={14} fontWeight={400}>
              Unsaved changes will be lost.
            </AppText>
          </FlexDiv>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowToggleIBCModal(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              onToggle?.();
              setShowToggleIBCModal(false);
            }}
          >
            Yes
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 98px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
