const _getIntValueFromDay = (day: string) => {
  switch (day) {
    case "Monday":
      return 0;
    case "Tuesday":
      return 1;
    case "Wednesday":
      return 2;
    case "Thursday":
      return 3;
    case "Friday":
      return 4;
    case "Saturday":
      return 5;
    case "Sunday":
      return 6;
    default:
      return 7;
  }
};

/**
 * returns int value for a month
 * @param day
 */
const _getIntValueFromMonth = (day: string) => {
  switch (day) {
    case "January":
      return 0;
    case "February":
      return 1;
    case "March":
      return 2;
    case "April":
      return 3;
    case "May":
      return 4;
    case "June":
      return 5;
    case "July":
      return 6;
    case "August":
      return 7;
    case "September":
      return 8;
    case "October":
      return 9;
    case "November":
      return 10;
    case "December":
      return 11;
    default:
      return 12;
  }
};

/**
 *
 * @param year returns year number * 100. Used for sort calculations
 */
const _getIntValueFromYear = (year: string) => {
  const int_year = parseInt(year);
  return int_year * 100;
};

export const sortByDayOfWeek = (a: string, b: string) => {
  const new_a = _getIntValueFromDay(a);
  const new_b = _getIntValueFromDay(b);
  return new_a - new_b;
};

interface MonthYearItem {
  year: string;
  month: string;
  [key: string]: any;
}

export const sortByMonthAndYear = (a: MonthYearItem, b: MonthYearItem) => {
  // Take year, multiple by 20
  // Take month, get value 1 to 12
  const ayear = _getIntValueFromYear(a.year);
  const byear = _getIntValueFromYear(b.year);
  const amonth = _getIntValueFromMonth(a.month);
  const bmonth = _getIntValueFromMonth(b.month);
  const avalue = ayear + amonth;
  const bvalue = byear + bmonth;
  return avalue - bvalue;
  // Add the 2 together
  // a - b
};
