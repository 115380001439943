import { NewAppButton } from "./NewAppButton";
import styled from "styled-components";
import React from "react";
import { AppText } from "./AppText";

const NewAppIconButton = ({
  img,
  icon,
  alt,
  text,
  onClick,
  variant,
  disabled = false,
  backgroundColor,
  fontSize,
  fontWeight,
  color,
  imgHeight = "12px",
  imgWidth = "12px",
  textVariant,
}: {
  img?: string;
  icon?: any;
  alt: string;
  text: string;
  onClick: () => void;
  variant: any;
  disabled?: boolean;
  backgroundColor?: string;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  imgHeight?: string;
  imgWidth?: string;
  textVariant?: "primary" | "error" | "success" | "warning" | "white";
}) => {
  return (
    <AppIconButton
      disabled={disabled}
      onClick={() => {
        onClick();
      }}
      variant={variant}
      backgroundColor={backgroundColor}
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color,
      }}
    >
      {icon && icon}
      {img && (
        <img
          src={img}
          alt={alt}
          style={{
            height: imgHeight,
            width: imgWidth,
          }}
        />
      )}

      {text}
    </AppIconButton>
  );
};

const AppIconButton = styled(NewAppButton)<{
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
    `}
`;

export { NewAppIconButton };
