import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment-timezone";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { user_white } from "../../images/NewDesign";
import { formatBusinessName, formatUSD } from "../../utils/format";
import { theme } from "../../utils/theme";
import { AppText, FlexDiv, Loading, StatCard } from "../UI";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import { loggedInUser } from "../../apollo/cache";

const FETCH_MISSED_ITEMS_LIST = gql`
  query fetchMissedItemsList($user_id: String!, $skip: Int!, $take: Int!) {
    fetchMissedItemsList(user_id: $user_id, skip: $skip, take: $take) {
      id
      event_type_label
      current_sequence_step
      lead {
        id
        full_name
        business_name
        lead_value {
          id
          value
        }
      }
      schedule_item {
        id
        start_time
        end_time
      }
    }
  }
`;

const MyMissedCal: React.FC = ({ ...props }) => {
  const [missedEventListItems, setMissedEventList] = useState<any[]>([]);
  const missedEventListTake = 4;
  const [missedEventSkip, setMissedEventSkip] = useState(0);
  const [hasMoreMissedEvent, setHasMoreMissedEvent] = useState(true);

  const [
    fetchMissedEventList,
    {
      data: missedEventList,
      loading: missedEventListLoading,
      error: missedEventListError,
      refetch: refetchMissedEventList,
    },
  ] = useLazyQuery(FETCH_MISSED_ITEMS_LIST, {
    fetchPolicy: "no-cache",
    variables: { user_id: loggedInUser().id, skip: missedEventSkip, take: missedEventListTake },
    onCompleted: (missedEventList) => {
      const newList = [...missedEventListItems, ...missedEventList.fetchMissedItemsList].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id).includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );
      if (missedEventList.fetchMissedItemsList.length < missedEventListTake) {
        setHasMoreMissedEvent(false);
      } else setHasMoreMissedEvent(true);
      setMissedEventList(newList);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    fetchMissedEventList({
      variables: {
        user_id: loggedInUser().id,
        skip: missedEventSkip,
        take: missedEventListTake,
      },
    });
  }, [missedEventSkip]);

  const handleShowMore = () => {
    setMissedEventSkip(missedEventSkip + missedEventListTake);
  };

  return (
    <MissedEventScrollDiv id="missed-events-list-div">
      <InfiniteScroll
        dataLength={missedEventListItems.length}
        next={handleShowMore}
        hasMore={hasMoreMissedEvent}
        loader={<Loading />}
        scrollableTarget="missed-events-list-div"
      >
        {missedEventListItems.length > 0 &&
          missedEventListItems?.map((item: any, index: number) => {
            const prevStartDate =
              !!missedEventListItems[index - 1] &&
              moment(missedEventListItems[index - 1]?.schedule_item?.start_time).format("MMMM Do");
            const currentStartDate = moment(item?.schedule_item?.start_time).format("MMMM Do");
            return (
              <MissedEventListDiv>
                {prevStartDate !== currentStartDate && (
                  <DayDivider>
                    <AppText>{currentStartDate}</AppText>
                  </DayDivider>
                )}

                <MissedEventListItem key={item.id}>
                  <FlexDiv justify="flex-end" style={{ marginRight: "16px" }}>
                    <AppText
                      fontSize={12}
                      variant="primary"
                      style={{ borderBottom: "none", cursor: "pointer" }}
                      onClick={() => window.open(`/manage-schedule/${item?.schedule_item?.id}`, "_blank", "noreferrer")}
                    >
                      View Details
                    </AppText>
                  </FlexDiv>
                  <MissedEventListContent>
                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={600}>
                        {moment(item?.schedule_item?.start_time).format("h:mm A")} -{" "}
                        {moment(item?.schedule_item?.end_time).format("h:mm A")}
                      </AppText>
                      <FlexDiv gap={4}>
                        <AppText fontSize={12}>{item?.event_type_label}</AppText>
                        <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
                          |
                        </AppText>
                        <AppText fontSize={12} variant="primary">
                          {formatUSD(_.round(item?.lead?.lead_value?.value, 2))}
                        </AppText>
                      </FlexDiv>
                    </FlexDiv>

                    <FlexDiv direction="column" gap={8}>
                      <AppText fontSize={12} fontWeight={600}>
                        {formatBusinessName(item?.lead?.business_name)}
                      </AppText>
                      <FlexDiv gap={8}>
                        <StatCard icon={user_white} />
                        <AppText fontSize={12}>{item?.lead?.full_name}</AppText>
                      </FlexDiv>
                    </FlexDiv>
                  </MissedEventListContent>
                </MissedEventListItem>
              </MissedEventListDiv>
            );
          })}
      </InfiniteScroll>
    </MissedEventScrollDiv>
  );
};

const MissedEventListDiv = styled.div``;

const DayDivider = styled.div`
  padding-top: 5px;
  padding-left: 12px;
  height: 27px;
  background-color: ${theme.NEUTRAL100};
`;

const MissedEventListItem = styled.div`
  padding-top: 16px;
  border: 1px solid ${theme.NEUTRAL100};
`;

const MissedEventListContent = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 34px;
  margin: 16px;
`;

const MissedEventScrollDiv = styled.div`
  overflow: auto;
  height: 275px;
`;

export { MyMissedCal };
