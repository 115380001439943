import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText } from "../AppText";

interface PhoenixBasicCardProps {
  style?: React.CSSProperties;
  onClick?: () => void;

  header?: string;
  body?: string;
  selectedVar?: boolean; // state variable that overrides the built in state var
  selectable?: boolean;
  disabled?: boolean;

  // overrides
  width?: number | string; // Number is converted to px. Default is 376px
  padding?: number | string; // Number is converted to px. Default is 16px

  backgroundColor?: string;
  hoverColor?: string;
  selectedColor?: string;

  headerColor?: string;
  headerSize?: number | string; // Number is converted to px. Default is 12px
  headerWeight?: number; // Default is 600

  bodyColor?: string;
  bodySize?: number | string; // Number is converted to px. Default is 12px
  bodyWeight?: number; // Default is 400

  borderColor?: string;
  borderRadius?: number | string; // Number is converted to px. Default is 8px

  allowHighlight?: boolean;
}

export const PhoenixBasicCard: React.FC<PhoenixBasicCardProps> = ({ ...props }) => {
  const [selected, setSelected] = useState(false);

  return (
    <Container
      {...props}
      selected={props.selectedVar !== undefined ? props.selectedVar : selected}
      onClick={() => {
        if (!props.disabled) {
          props.onClick && props.onClick();
          props.selectable && props.selectedVar === undefined && setSelected(!selected);
        }
      }}
    >
      <HeaderText
        {...props}
        selected={props.selectedVar !== undefined ? props.selectedVar : selected}
        onClick={() => {
          /* onClick placed here to override {...props}.onClick */
        }}
      >
        {props.header}
      </HeaderText>
      <BodyText
        {...props}
        selected={props.selectedVar !== undefined ? props.selectedVar : selected}
        onClick={() => {
          /* onClick placed here to override {...props}.onClick */
        }}
      >
        {props.body}
      </BodyText>
    </Container>
  );
};

interface ContainerProps extends PhoenixBasicCardProps {
  selected?: boolean;
}

const Container = styled.div<ContainerProps>`
  transition: background-color 0.2s ease;

  display: flex;
  flex-direction: column;
  gap: 4px;

  width: ${(props) => (props.width ? (typeof props.padding === "number" ? `${props.width}px` : props.width) : "376px")};
  height: fit-content;

  padding: ${(props) =>
    props.padding ? (typeof props.padding === "number" ? `${props.padding}px` : props.padding) : "16px"};

  background-color: ${(props) => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    } else {
      return props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR;
    }
  }};

  border: 1px solid ${(props) => (!props.disabled ? props.borderColor || theme.PRIMARY500 : theme.PRIMARY200)};
  border-radius: ${(props) =>
    props.borderRadius
      ? typeof props.borderRadius === "number"
        ? `${props.borderRadius}px`
        : props.borderRadius
      : "8px"};

  user-select: ${(props) => !props.allowHighlight && "none"};

  :hover {
    cursor: ${(props) => props.selectable && !props.disabled && "pointer"};
    background-color: ${(props) => props.selectable && !props.selected && !props.disabled && theme.PRIMARY100};
  }

  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 2px;
  }
`;

const HeaderText = styled(AppText)<ContainerProps>`
  border-bottom: none;

  color: ${(props) => {
    if (props.headerColor) {
      return props.headerColor;
    } else {
      return !props.disabled ? (props.selected ? theme.WHITE_COLOR : theme.PRIMARY500) : theme.PRIMARY200;
    }
  }};
  font-size: ${(props) =>
    props.headerSize ? (typeof props.headerSize === "number" ? `${props.headerSize}px` : props.headerSize) : "12px"};
  font-weight: ${(props) => props.headerWeight || 600};
`;

const BodyText = styled(AppText)<ContainerProps>`
  border-bottom: none;

  color: ${(props) => {
    if (props.bodyColor) {
      return props.bodyColor;
    } else {
      return !props.disabled ? (props.selected ? theme.WHITE_COLOR : "#000") : theme.NEUTRAL200;
    }
  }};
  font-size: ${(props) =>
    props.bodySize ? (typeof props.bodySize === "number" ? `${props.bodySize}px` : props.bodySize) : "12px"};
  font-weight: ${(props) => props.bodyWeight || 400};
`;
