import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";

import { AppText, StatCard } from "../../UI";
import { check, xIcon } from "../../../images/NewDesign";

import moment from "moment";

interface ICommunicationsActivityRowProps {
  type: "sms" | "email";
  subscribed: boolean;
  computed_title: string;
  height?: number;
  date?: Date;
}

const CommunicationsActivityLeadDetail = ({
  type,
  subscribed,
  height = 56,
  computed_title = "",
  date = new Date(),
}: ICommunicationsActivityRowProps) => {
  return (
    <Bar toggled={subscribed} type={type} height={height}>
      <Left>
        {subscribed ? (
          <StatCard icon={check} backgroundColor={theme.SUCCESS500} iconColor={theme.WHITE_COLOR} iconSize={16} />
        ) : (
          <StatCard icon={xIcon} backgroundColor={theme.DANGER600} iconColor={theme.WHITE_COLOR} iconSize={16} />
        )}
        <AppText
          fontSize={14}
          fontWeight={400}
          style={{
            marginLeft: 8,
          }}
        >
          {computed_title}
        </AppText>
      </Left>
      <Right>
        <DateDiv>
          <AppText fontSize={12} fontWeight={400}>
            {moment(date).format("MM/DD/YYYY")}
          </AppText>
          <AppText
            style={{
              color: theme.NEUTRAL350,
            }}
          >
            {moment(date).format("h:mm A")}
          </AppText>
        </DateDiv>
      </Right>
    </Bar>
  );
};

const Bar = styled.div<{ toggled: boolean; type: string; height: number }>`
  display: flex;
  height: ${(props) => props.height}px;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.toggled ? theme.SUCCESS200 : theme.DANGER100)};
  border-radius: 0px;
  border: 1px solid ${(props) => (props.toggled ? theme.SUCCESS500 : theme.DANGER600)};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Right = styled.div``;

const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export { CommunicationsActivityLeadDetail };
