import styled from "styled-components";
import { theme } from "../../../utils/theme";

interface PhoenixRadioProps {
  selected: boolean;
  size?: number;
  selectedBorderColor?: string;
  unselectedBorderColor?: string;
  selectedDotColor?: string;
  unselectedDotColor?: string;
}

export const PhoenixRadio = styled.div<PhoenixRadioProps>`
  width: ${(props) => (!!props.size ? `${props.size}px` : "16px")};
  min-width: ${(props) => (!!props.size ? `${props.size}px` : "16px")};
  height: ${(props) => (!!props.size ? `${props.size}px` : "16px")};
  min-height: ${(props) => (!!props.size ? `${props.size}px` : "16px")};

  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.selected ? props.selectedBorderColor || theme.PRIMARY500 : props.unselectedBorderColor || theme.NEUTRAL300};
  background-color: ${(props) =>
    props.selected ? props.selectedDotColor || theme.PRIMARY500 : props.unselectedDotColor || theme.WHITE_COLOR};
  box-shadow: ${(props) =>
    props.size
      ? `inset 0px 0px 0px ${props.size / 8}px ${theme.WHITE_COLOR}`
      : `inset 0px 0px 0px 2px ${theme.WHITE_COLOR}`};

  cursor: pointer;
`;
