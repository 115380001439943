import React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

interface DispositionPillProps {
  dispositionType: string;
}

export const DispositionPill: React.FC<DispositionPillProps> = ({ dispositionType }) => {
  const parsedData = parseDispositionPillData(dispositionType);

  const innerText =
    dispositionType === "TransferredAnotherSDRsSet"
      ? "Transferred Another SDRs Set"
      : dispositionType.replace(/([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, "$1$2 ");

  return (
    <Container bgColor={parsedData.bgColor} textColor={parsedData.textColor}>
      {innerText}
    </Container>
  );
};

interface ContainerProps {
  bgColor: string;
  textColor: string;
}

const Container = styled.div<ContainerProps>`
  width: fit-content;
  height: fit-content;

  padding: 6px 8px;

  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;

  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
`;

const parseDispositionPillData = (dispositionType: string) => {
  let bgColor;
  let textColor;

  const grayList = [
    "Disconnected",
    "DNC",
    "WrongNum",
    "DuplicateLead",
    "TrackingNumber",
    "NeverDialed",
    "PracticeManagementSystemNotIntegrated",
  ];
  const greenList = [
    "SetDemoWithNDM",
    "SetDemoWithDM",
    "HeldDemoStillInterested",
    "HeldDemoNotInterested",
    "ProspectInterested",
    "Sale",
    "NDMHeldDemoStillInterested",
    "NDMInterested",
    "DemoRescheduledWithDM",
    "DemoRescheduledWithNDM",
    "HeldDemoWithDMStillInterested",
    "DemoConfirmed",
    "ProspectStillInterested",
    "HeldDemoWithNDMStillInterested",
    "ScheduleFUDemoWithDM",
    "ScheduleFUDemoWithNDM",
    "ScheduleDemoWithDM",
    "ScheduleDemoWithNDM",
    "HeldDemoScheduledDecisionCall",
    "ScheduledDecisionCall",
    "NDMHeldDemoScheduledDecisionCall",
    "ScheduleAdditionalDemoWithDM",
    "ScheduleAdditionalDemoWithNDM",
    "UpcomingScheduledDemoConfirmed",
    "StillInterested",
  ];
  const yellowList = [
    "DemoCancelled",
    "DemoCancelledStillInterested",
    "MissedScheduledFollowUpCall",
    "MissedScheduledDemo",
  ];
  const lightYellowList = [
    "LeftMessageWithNDM",
    "LeftVoicemail",
    "NoMessageLeft",
    "PartialDemo",
    "ProspectDropped",
    "PropsectDropped",
    "ProspectReached",
    "CurrentCustomer",
    "TransferredMyOwnSet",
    "TransferredOTF",
    "TransferredAnotherSDRsSet",
  ];
  const redList = [
    "NotInterested",
    "NotQualified",
    "NDMHeldDemoNotInterested",
    "NDMNotInterested",
    "HeldDemoWithNDMNotInterested",
    "DemoCancelledNotInterested",
    "ProspectNotInterested",
  ];
  const lightRedList = ["DeadLine", "Retired"];

  switch (true) {
    case grayList.includes(dispositionType):
      bgColor = theme.NEUTRAL200;
      textColor = theme.NEUTRAL300;
      break;
    case greenList.includes(dispositionType):
      bgColor = theme.SUCCESS200;
      textColor = theme.SUCCESS500;
      break;
    case yellowList.includes(dispositionType):
      bgColor = theme.WARNING500;
      textColor = theme.WHITE_COLOR;
      break;
    case lightYellowList.includes(dispositionType):
      bgColor = theme.WARNING200;
      textColor = theme.WARNING600;
      break;
    case redList.includes(dispositionType):
      bgColor = theme.DANGER600;
      textColor = theme.WHITE_COLOR;
      break;
    case lightRedList.includes(dispositionType):
      bgColor = theme.DANGER100;
      textColor = theme.DANGER600;
      break;
    default:
      bgColor = "";
      textColor = "";
  }

  return { bgColor, textColor };
};
