import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import * as Yup from "yup";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { InputField } from "../Field";
import { AppText, Loading } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { PhoenixAppButton } from "../UI/Phoenix";
import { SYSTEM_FIELD_CHAR_LIMIT } from "./../../utils/format";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  /**
   * Only pass in the id if it is an edit operation
   */
  id?: string;
  keyName?: string;
  options?: string[];
  label?: string;
  allow_reps_to_edit?: boolean;
  visible?: boolean;
  setDeleteModalData?: Dispatch<SetStateAction<{ id: string; fieldType: string; fieldName: string }>>;
  sub_fields?: string[];
}

const ADD_INDUSTRY_FIELD = gql`
  mutation addSystemField($systemFieldType: SystemFieldType!, $label: String!, $subFields: [String!]) {
    addSystemField(systemFieldType: $systemFieldType, label: $label, subFields: $subFields)
  }
`;

const EDIT_INDUSTRY_FIELD = gql`
  mutation updateSubIndustryList($label: String!, $sub_industries: [String!]!) {
    updateSubIndustryList(label: $label, sub_industries: $sub_industries) {
      id
      label
      sub_industries
    }
  }
`;

const UpdateIndustry: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  id,
  keyName,
  setDeleteModalData,
  label,
  ...propsField
}) => {
  interface MyFormikProps {
    id: string;
    key: string;
    options: string[];
    allow_reps_to_edit: boolean;
    visible: boolean;
    sub_industries: string[];
    sub_industry: string;
  }

  const [step, setStep] = useState(1);

  const customFieldsSchema = Yup.object().shape({
    id: Yup.string(),
    key: Yup.string()
      .required("Field required!")
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    allow_reps_to_edit: Yup.boolean(),
    visible: Yup.boolean(),
    options: Yup.array(Yup.string()),
    sub_industries: Yup.array(
      Yup.string().max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    ),

    sub_industry: Yup.string().max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  });

  const [addIndustry, { loading: loadingIndustry, error: errorIndustry }] = useMutation(ADD_INDUSTRY_FIELD, {
    onCompleted({ addIndustry }) {
      console.log("Add industry: ", addIndustry);
      appToast("Added industry");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addIndustry GraphQL Error: ${message}`,
      });
      console.log("Error in addIndustry: ", message);
    },
    refetchQueries: ["industryOptions"],
  });

  const [editIndustry, { loading: loadingIndustryEdit, error: errorEditIndustry }] = useMutation(EDIT_INDUSTRY_FIELD, {
    onCompleted({ editIndustry }) {
      console.log("Edit industry: ", editIndustry);
      appToast("Edited sub-industries");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `editIndustry GraphQL Error: ${message}`,
      });
      console.log("Error in editIndustry: ", message);
    },
    refetchQueries: ["industryOptions"],
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: id || "",
        key: label || "",
        visible: propsField.visible ?? false,
        allow_reps_to_edit: propsField.allow_reps_to_edit ?? false,
        options: propsField.options || ["new", "option"],
        sub_industries: propsField.sub_fields || [],
        sub_industry: "",
      }}
      validationSchema={customFieldsSchema}
      onSubmit={async ({ id, visible, key, allow_reps_to_edit, options, sub_industries }) => {
        console.log("test");
      }}
    >
      {({
        submitForm,
        isSubmitting,
        values,
        isValid,
        dirty,
        setFieldValue,
        touched,
        errors,
      }: FormikProps<MyFormikProps>) => {
        console.log("errors: ", errors);
        return (
          <Modal open={blinds} onClose={close} closeButtonSize={16}>
            <ModalContent>
              <TitleDiv>
                <PopupTitle>{id ? `Manage ` : `Add `}Industry</PopupTitle>
              </TitleDiv>

              {!!id ? (
                <>
                  {step === 1 && (
                    <TopAlignDiv style={{ padding: "0px" }}>
                      <SummaryDiv>
                        <AppText style={{ marginBottom: "12px" }}>Primary Industry</AppText>
                        <FilterItemLabel>{values.key}</FilterItemLabel>
                      </SummaryDiv>
                      <SummaryDiv style={{ paddingTop: "0px" }}>
                        {JSON.stringify(values.sub_industries) !== JSON.stringify(propsField.sub_fields) && (
                          <UpdateAppliedText>Update applied! Don’t forget to save your changes.</UpdateAppliedText>
                        )}
                        <SpaceBetweenDiv>
                          <AppText>Sub-industry</AppText>
                          <ClickText onClick={() => setStep(3)}>Edit</ClickText>
                        </SpaceBetweenDiv>
                        <GridDivFilters>
                          {values.sub_industries.length ? (
                            values.sub_industries?.map((item: string) => <FilterItemLabel>{item}</FilterItemLabel>)
                          ) : (
                            <AppText>No sub-industried have been created. Click "Back" to add some.</AppText>
                          )}
                        </GridDivFilters>
                      </SummaryDiv>
                    </TopAlignDiv>
                  )}
                  {step === 3 && (
                    <TopAlignDiv>
                      <AppText style={{ marginBottom: "12px" }}>Primary Industry</AppText>
                      <FilterItemLabel>{values.key}</FilterItemLabel>
                      <AppText style={{ marginBottom: "8px", marginTop: "16px" }}>Add Sub-Industry</AppText>
                      <FlexDivSubIndustry>
                        <SubIndustryField name="sub_industry" />
                        <PhoenixAppButton
                          disabled={values.sub_industry.toString().trim() === "" || !isValid}
                          onClick={() => {
                            setFieldValue("sub_industries", [...values.sub_industries, values.sub_industry]);
                            setFieldValue("sub_industry", "");
                          }}
                          buttonType="secondary"
                          variant="brand-outline"
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          Add
                        </PhoenixAppButton>
                      </FlexDivSubIndustry>
                      <GridDivFilters>
                        {values.sub_industries?.map((industry: string, index: number) => (
                          <FilterSelectBox key={`${index}-${industry}`}>
                            <AppText fontSize={10}>{industry}</AppText>
                            <IoMdClose
                              size={14}
                              color={theme.NEUTRAL400}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const newVals = values.sub_industries.filter(
                                  (item: string, i: number) => !(item == industry && index === i),
                                );
                                setFieldValue("sub_industries", newVals);
                              }}
                            />
                          </FilterSelectBox>
                        ))}
                      </GridDivFilters>
                    </TopAlignDiv>
                  )}
                </>
              ) : (
                <>
                  {step === 1 && (
                    <ScrollingDiv>
                      <PopupInputLabel>
                        Add Industry<span style={{ color: "red" }}>*</span>
                      </PopupInputLabel>
                      <FieldInput name="key" />
                    </ScrollingDiv>
                  )}
                  {step === 2 && (
                    <TopAlignDiv>
                      <AppText style={{ marginBottom: "12px" }}>Primary Industry</AppText>
                      <FilterItemLabel>{values.key}</FilterItemLabel>
                      <AppText style={{ marginBottom: "8px", marginTop: "16px" }}>Add Sub-Industry</AppText>
                      <FlexDivSubIndustry>
                        <SubIndustryField name="sub_industry" />
                        <PhoenixAppButton
                          disabled={values.sub_industry.toString().trim() === "" || !isValid}
                          onClick={() => {
                            setFieldValue("sub_industries", [...values.sub_industries, values.sub_industry]);
                            setFieldValue("sub_industry", "");
                          }}
                          buttonType="primary"
                          variant="brand"
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          Add
                        </PhoenixAppButton>
                      </FlexDivSubIndustry>
                      <GridDivFilters>
                        {values.sub_industries?.map((industry: string, index: number) => (
                          <FilterSelectBox key={`${index}-${industry}`}>
                            <AppText fontSize={10}>{industry}</AppText>
                            <IoMdClose
                              size={14}
                              color={theme.NEUTRAL400}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const newVals = values.sub_industries.filter(
                                  (item: string, i: number) => !(item == industry && index === i),
                                );
                                setFieldValue("sub_industries", newVals);
                              }}
                            />
                          </FilterSelectBox>
                        ))}
                      </GridDivFilters>
                    </TopAlignDiv>
                  )}
                  {step === 3 && (
                    <TopAlignDiv>
                      <SectionLabelText style={{ width: "100%", textAlign: "center", marginBottom: "12px" }}>
                        Almost done! Click “create” to add this industry type!
                      </SectionLabelText>
                      <AppText style={{ marginBottom: "12px" }}>Primary Industry</AppText>
                      <GridDivFilters>
                        <FilterItemLabel>{values.key}</FilterItemLabel>
                      </GridDivFilters>
                      <AppText style={{ marginBottom: "8px", marginTop: "8px" }}>Sub-Industry</AppText>
                      <GridDivFilters>
                        {values.sub_industries.length ? (
                          values.sub_industries?.map((item: string) => <FilterItemLabel>{item}</FilterItemLabel>)
                        ) : (
                          <AppText>No sub-industried have been created. Click "Back" to add some.</AppText>
                        )}
                      </GridDivFilters>
                    </TopAlignDiv>
                  )}
                </>
              )}

              <SubmitDiv>
                {!!id ? (
                  <AddEmailCancelButton
                    onClick={() =>
                      !!setDeleteModalData &&
                      setDeleteModalData({ id: id, fieldType: "Custom Field", fieldName: keyName ?? "" })
                    }
                  >
                    Delete
                  </AddEmailCancelButton>
                ) : (
                  <AppText>Step {step} of 3</AppText>
                )}
                <div>
                  {!!id ? (
                    <>
                      {step === 1 &&
                        (isSubmitting ? (
                          <Loading />
                        ) : (
                          <AddEmailButton
                            type="submit"
                            onClick={async () =>
                              await editIndustry({
                                variables: {
                                  label: values.key,
                                  sub_industries: values.sub_industries,
                                },
                              })
                            }
                            disabled={loadingIndustryEdit || !isValid}
                          >
                            Save
                          </AddEmailButton>
                        ))}
                      {step !== 1 && <GenericButton onClick={() => setStep(1)}>Update</GenericButton>}
                    </>
                  ) : (
                    <>
                      {step > 1 && <GenericButton onClick={() => setStep(step - 1)}>Back</GenericButton>}
                      {step === 3 ? (
                        <AddEmailButton
                          style={{ marginLeft: "8px" }}
                          type="submit"
                          onClick={async () =>
                            await addIndustry({
                              variables: {
                                systemFieldType: "Industry",
                                label: values.key,
                                subFields: values.sub_industries,
                              },
                            })
                          }
                          disabled={loadingIndustry}
                        >
                          Create
                        </AddEmailButton>
                      ) : (
                        <PhoenixAppButton
                          buttonType="secondary"
                          variant="brand"
                          disabled={(step === 1 && values.key.toString().trim() === "") || !isValid}
                          onClick={() => setStep(step + 1)}
                        >
                          Next
                        </PhoenixAppButton>
                      )}
                    </>
                  )}
                </div>
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const ClickText = styled(AppText)`
  border-bottom: none;
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
`;

const ModalContent = styled.div`
  width: 720px;
  height: 680px;
`;
const SpaceBetweenDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const UpdateAppliedText = styled(AppText)`
  color: ${theme.SUCCESS500};
  border: 1px solid ${theme.SUCCESS500};
  border-radius: 2px;
  padding: 8px 12px;
  margin-bottom: 16px;
  margin-right: auto;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin-top: 24px;
`;

const SummaryDiv = styled.div`
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  padding-bottom: 16px;
  :last-child {
    border-bottom: none;
  }
`;

const FlexDivSubIndustry = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  gap: 16px;
`;

const SubIndustryField = styled(InputField)`
  width: 330px;
  max-width: 330px;
  margin: 0px;
`;

const FilterSelectBox = styled.div`
  height: 31px;
  min-width: 67px;
  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: black;
  font-size: 12px;
  font-weight: 400;
  padding: 7px 12px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

const TopAlignDiv = styled.div`
  padding: 24px 32px;
  min-height: 536px;
  height: 536px;
  max-height: 536px;
  overflow: auto;
  margin-bottom: 88px;
`;

const GridDivFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterItemLabel = styled(AppText)`
  height: 31px;
  font-size: 10px;
  line-height: 29px;
  border-radius: 99px;
  background-color: ${theme.NEUTRAL200};
  border: 1px solid ${theme.NEUTRAL300};
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0px 12px;
`;

const SectionLabelText = styled(AppText)`
  font-size: 14px;
  margin-bottom: 24px;
`;

const WidthContainer = styled.div`
  max-width: 384px;
`;

const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;
  height: 88px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  /* margin-bottom: 32px; */
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const FieldInput = styled(InputField)`
  margin: 0;
  text-align: left;
  /* border-radius: 2.9px; */
  /* height: 45px; */
  /* border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  } */
  ::placeholder {
    text-align: left;
  }
`;

const AddEmailCancelButton = styled(NewAppButton)`
  width: 96px;
  border-radius: 2px;
  text-transform: capitalize;
  height: 40px;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-style: normal;
  color: ${theme.ATTENTION700};
`;

const GenericButton = styled(AddEmailCancelButton)`
  color: ${theme.PRIMARY500};
  margin-left: 8px;
`;

const AddEmailButton = styled(NewAppButton)`
  width: 96px;
  height: 40px;
  border-radius: 2px;
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.PRIMARY500};
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0.2px;
  font-weight: 600;
  font-style: normal;
  /* font-size: 14px;
  font-weight: 600; */
  /* margin: 25px auto; */
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 0px 168px;
  overflow: auto;
  width: 100%;
  min-height: 536px;
  height: 536px;

  max-height: 536px;
  margin-bottom: 88px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { UpdateIndustry };
