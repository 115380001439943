import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { useDebounce } from "../../../utils/hooks";
import { AppText, StatCard } from "../../UI";
import { check, xIcon, info } from "../../../images/NewDesign";
import { PhoenixIcon } from "../../UI/Phoenix";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
interface ToggleCommunicationsBarProps {
  repIsAdmin: boolean;
  toggled: boolean;
  type: "email" | "sms";
  toggle: () => void;
  leadOptedOut?: boolean;
  repOwnsLead?: boolean;
  tooltipText: string;
  loading?: boolean;
}

const canToggleSubStatus = ({
  toggledOn,
  repOwnsLead,
  repIsAdmin,
  leadOptedOut,
  type,
}: {
  toggledOn: boolean;
  repOwnsLead: boolean;
  repIsAdmin: boolean;
  leadOptedOut: boolean;
  type: "email" | "sms";
}): boolean => {
  const leadOptedOutOfSMS = leadOptedOut && type === "sms";

  const canToggleOff = repOwnsLead || repIsAdmin;

  const canToggleOn = repIsAdmin && !leadOptedOutOfSMS;

  if (toggledOn) {
    return canToggleOff;
  }

  if (!toggledOn) {
    return canToggleOn;
  }

  console.error("Something went wrong with the toggle helper function");
  return false;
};

const CommunicationToggle = ({
  repIsAdmin = false,
  toggled = false,
  type = "email",
  toggle = () => {},
  repOwnsLead = false,
  leadOptedOut = false,
  tooltipText = "",
  loading = false,
}: ToggleCommunicationsBarProps) => {
  const canToggle = canToggleSubStatus({ toggledOn: toggled, repOwnsLead, repIsAdmin, leadOptedOut, type });
  const [localToggled, setLocalToggled] = useState<boolean | undefined>(undefined);

  const REP_TOGGLE_WARNING_TOOLTIP = repOwnsLead
    ? `Toggling off will stop all automated and manual ${type} communications.`
    : `Only an enablement user or the owner of this lead can toggle ${type} communications.`;

  useEffect(() => {
    setLocalToggled(toggled);
  }, [toggled]);

  useDebounce(
    () => {
      const initalLoad = localToggled === undefined;
      const toggledChanged = localToggled !== toggled;

      if (!initalLoad && toggledChanged) {
        toggle();
      }
    },
    [localToggled],
    700,
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [localToggled, repIsAdmin, repOwnsLead, loading]);

  return (
    <>
      <PhoenixStyledTooltip id="bar-tooltip" />
      <Bar toggled={!!localToggled} type={type} leadOptedOut={leadOptedOut}>
        <Left>
          {localToggled ? (
            <StatCard
              icon={check}
              backgroundColor={theme.SUCCESS500}
              iconColor={theme.WHITE_COLOR}
              iconSize={8}
              containerPadding="0 2px"
            />
          ) : (
            <StatCard
              icon={xIcon}
              backgroundColor={leadOptedOut ? theme.DANGER900 : theme.DANGER600}
              iconColor={theme.WHITE_COLOR}
              iconSize={8}
              containerPadding="0 2px"
            />
          )}
          <AppText fontSize={10} fontWeight={400} style={{ margin: 0, marginLeft: 8 }}>
            {type === "email" ? "Email" : "SMS"} Communications
          </AppText>
          <PhoenixIcon
            svg={info}
            size={10}
            variant="neutral"
            color={leadOptedOut ? theme.NEUTRAL200 : undefined}
            data-tip={tooltipText}
            data-for="bar-tooltip"
          />
        </Left>
        <Right
          data-for={localToggled && !repIsAdmin ? "bar-tooltip" : undefined}
          data-tip={localToggled && !repIsAdmin ? REP_TOGGLE_WARNING_TOOLTIP : undefined}
        >
          <Switch
            onChange={(checked: boolean) => {
              if (canToggle) {
                setLocalToggled(checked);
              }
            }}
            checked={!!localToggled}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={12}
            width={26}
            handleDiameter={6}
            checkedIcon={false}
            uncheckedIcon={false}
            disabled={!canToggle || loading}
          />
        </Right>
      </Bar>
    </>
  );
};

const Bar = styled.div<{ toggled: boolean; type: string; leadOptedOut: boolean }>`
  display: flex;
  height: 16px;
  width: 100%;
  padding: 2px 2px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    props.leadOptedOut ? theme.NEUTRAL300 : props.toggled ? theme.SUCCESS200 : theme.DANGER100};
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.leadOptedOut ? theme.NEUTRAL400 : props.toggled ? theme.SUCCESS500 : theme.DANGER600)};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;

export { CommunicationToggle };
