import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading, AppErrorText, AppSidebarCard } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery, MutationFunctionOptions, FetchResult } from "@apollo/client";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction, useState } from "react";
import { SearchList } from "../../Smart";
import { SelectedContactType } from "../../../types";
import { GrFormClose } from "react-icons/gr";
import { HiOutlineSearch } from "react-icons/hi";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

interface EventNotificationProps {
  lead_intent_id: string;
  switchToColdCall: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  prepareForScheduleCallback: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  setNonScheduledCallBack: Dispatch<SetStateAction<boolean>>;
}

const EventNotification: React.FC<EventNotificationProps> = ({
  lead_intent_id,
  switchToColdCall,
  prepareForScheduleCallback,
  setNonScheduledCallBack,
}) => {
  const prepareForScheduledCallBackOnClick = async () => {
    await prepareForScheduleCallback({
      variables: {
        lead_intent_id,
        prepare: false,
      },
      refetchQueries: [`fetchCurrentLead`],
    });
  };

  const switchToColdCAllOnClick = async () => {
    await switchToColdCall({
      refetchQueries: [`fetchCurrentLead`],
    });
  };
  return (
    <>
      <PrepareForCallDiv>
        {/* <PrepareForCallText>
          <i>The average cold call lasts longer than the time before your next call</i>
        </PrepareForCallText> */}
        <PrepareForCallText titletext>We recommend you:</PrepareForCallText>
        <PrepareForCallButton onClick={() => prepareForScheduledCallBackOnClick()}>
          PREPARE FOR YOUR SCHEDULED EVENT
        </PrepareForCallButton>
        <PrepareForCallText style={{ marginBottom: "21px" }}>or take the following action:</PrepareForCallText>
        <OtherCallOptionButton style={{ marginBottom: "16px" }} onClick={() => switchToColdCAllOnClick()}>
          MAKE ANOTHER COLD CALL
        </OtherCallOptionButton>
        {/* <OtherCallOptionButton onClick={() => setNonScheduledCallBack(true)}>
          MAKE A NON-SCHEDULED CALL BACK
        </OtherCallOptionButton> */}
      </PrepareForCallDiv>
    </>
  );
};

interface PrepareForCallTextProps {
  titletext?: boolean;
}
const PrepareForCallText = styled(AppText)<PrepareForCallTextProps>`
  width: 224px;
  font-size: ${(props) => (props.titletext ? "16px" : "12px")};
  margin: ${(props) => (props.titletext ? "11px" : "0px")};
  line-height: 1.25;
  text-align: center;
`;

const OtherCallOptionButton = styled(AppButton)`
  width: 225px;
  height: 36px;
  border-radius: 2.3px;
  border: solid 1px ${theme.TERTIARY500};
  background-color: ${theme.WHITE_COLOR};
  font-size: 10px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
`;

const PrepareForCallButton = styled(AppButton)`
  position: relative;
  width: 304px;
  height: 47px;
  border-radius: 3px;
  border: solid 2.8px ${theme.WHITE_COLOR};
  background-color: ${theme.PRIMARY500};
  font-size: 12px;
  font-weight: bold;
  color: ${theme.WHITE_COLOR};
  margin-bottom: 45px;
  :after {
    content: "";
    display: block;
    position: absolute;
    bottom: -18px;
    left: 128px;
    width: 48px;
    height: 1px;
    background: black;
  }
`;

const PrepareForCallDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: solid 4px #f6f6f6;
  padding: 17px 0px 23px 0px;
`;

export { EventNotification };
