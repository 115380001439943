import React, { createContext, FunctionComponent, useState, useMemo, useEffect } from "react";

interface CartContextState {
  lead_id: string;
  intent_id: string;
  person_spoke_to: string;
  products: any;
  sale_notes: string;
  contract_duration: string;
  payment_terms: string;
  prepayment: number;
  pandadoc_sale_id?: string;
  mrr: number;
  submitClosingScript: (values: any) => void;
  clearCartContext: () => void;
  cartSaleID: string;
  setCartSaleID: (cartSaleID: string) => void;
  setPandaDocId: (pandadoc_sale_id: string) => void;
}

interface CartStateState {
  lead_id: string;
  intent_id: string;
  person_spoke_to: string;
  products: any;
  sale_notes: string;
  contract_duration: string;
  payment_terms: string;
  prepayment: number;
  pandadoc_sale_id?: string;
  mrr: number;
}

export const CartContext = createContext<CartContextState>({} as CartContextState);

export const CartProvider: FunctionComponent = ({ children }) => {
  const [cartState, setCartState] = useState<CartStateState>({
    lead_id: "",
    intent_id: "",
    person_spoke_to: "",
    products: undefined,
    sale_notes: "",
    contract_duration: "",
    payment_terms: "",
    prepayment: 1,
    pandadoc_sale_id: "",
    mrr: 0,
  });

  const [cartSaleID, setCartSaleID] = useState("");

  const submitClosingScript = (values: any) => {
    console.log("submitClosingSCript cartContext change: ", values);
    setCartState({
      lead_id: values.lead_id,
      intent_id: values.intent_id,
      person_spoke_to: values.person_spoke_to,
      products: values.products,
      sale_notes: values.sale_notes,
      contract_duration: values.contract_duration,
      payment_terms: values.payment_terms,
      prepayment: values.prepayment,
      mrr: values.mrr,
    });
  };

  const setPandaDocId = (pandadoc_sale_id: string) => {
    setCartState({ ...cartState, pandadoc_sale_id });
  };

  const clearCartContext = () => {
    console.log("submitClosingSCript cartContext clear");
    setCartState({
      lead_id: "",
      intent_id: "",
      person_spoke_to: "",
      products: [],
      sale_notes: "",
      contract_duration: "",
      payment_terms: "",
      prepayment: 1,
      pandadoc_sale_id: "",
      mrr: 0,
    });
    setCartSaleID("");
  };

  useEffect(() => {
    console.log(`cartContext lead_id changed: ${cartState.lead_id}`);
  }, [cartState.lead_id]);

  const memoizedValue = useMemo(
    () => ({
      lead_id: cartState.lead_id,
      intent_id: cartState.intent_id,
      person_spoke_to: cartState.person_spoke_to,
      products: cartState.products,
      sale_notes: cartState.sale_notes,
      contract_duration: cartState.contract_duration,
      payment_terms: cartState.payment_terms,
      prepayment: cartState.prepayment,
      pandadoc_sale_id: cartState.pandadoc_sale_id,
      mrr: cartState.mrr,
      submitClosingScript,
      clearCartContext,
      cartSaleID,
      setCartSaleID,
      setPandaDocId,
    }),
    [
      cartState,
      submitClosingScript,
      clearCartContext,
      cartSaleID,
      cartState.lead_id,
      cartState.products,
      cartState.pandadoc_sale_id,
      setPandaDocId,
    ],
  );

  return <CartContext.Provider value={memoizedValue}>{children}</CartContext.Provider>;
};
