import styled from "styled-components";
import * as React from "react";

import { useContext } from "react";
import { AppErrorText, AppText, NewAppSelect, SkeletonBlock } from "../UI";
import { theme } from "../../utils/theme";
import gql from "graphql-tag";
import { ModalContext } from "../../context";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { useMutation, useQuery } from "@apollo/client";
import { appToast } from "../../utils/toast";
import { MixpanelActions } from "../../services/mixpanel";

const LEAD_BATCH_ASSOCIATE_REP = gql`
  mutation AssociateMultipleLeads($primaryLeadId: String!, $associateLeadIds: [String!]!, $repId: String) {
    associateMultipleLeads(primary_lead_id: $primaryLeadId, associate_lead_ids: $associateLeadIds, rep_id: $repId) {
      message
      status
      status_boolean
    }
  }
`;

/* NOTE: can't be passed in from filteredLeads fetch because the collumns are saved on BE
because of that, the BE will not send the names in the fetch if the user has not saved those collumns */

const FETCH_LEAD_NAMES = gql`
  query Query($leadIds: [String!]!) {
    fetchLeadFullNames(lead_ids: $leadIds) {
      id
      full_name
      business_name
    }
  }
`;

interface AssociateMultipleLeadsExpectedResponse {
  associateMultipleLeads?: {
    message?: string;
    status?: string;
    status_boolean?: boolean;
  };
}

/*
{
  "primaryLeadId": "string",
  "associateLeadIds": ["list of ids"],
  "repId": "Person who is performing action Not Rerquired"
}
*/

const BulkMergeLeadsModal = ({ resetSelectedLeads }: { resetSelectedLeads?: () => void }) => {
  const { showBulkMergeLeadsModal, setShowBulkMergeLeadsModal, bulkMergeLeadsModalData } = useContext(ModalContext);

  const generateLeadLabel = (lead: any) => {
    const { full_name, business_name } = lead;

    if (full_name && business_name) {
      return `${business_name} - (${full_name})`;
    } else if (full_name) {
      return `N/A - (${full_name})`;
    } else if (business_name) {
      return business_name;
    }

    return "N/A";
  };

  // mutation to bulk associate leads
  const [
    associateMultipleLeads,
    { loading: associateMultipleLeadsLoading, error: associateMultipleLeadsError },
  ] = useMutation<AssociateMultipleLeadsExpectedResponse>(LEAD_BATCH_ASSOCIATE_REP, {
    onCompleted: (data) => {
      if (data?.associateMultipleLeads?.status_boolean) {
        appToast("Leads merged successfully");
        resetSelectedLeads && resetSelectedLeads();
        setShowBulkMergeLeadsModal(false);
      } else {
        appToast("Failed to merge leads");
      }

      MixpanelActions.track("Contacts Associated");
    },
    onError: (error) => {
      appToast("Failed to merge leads" + error.message);
    },
    refetchQueries: ["fetchFilteredLeadCount", "filterLeads", "fetchFavoritedLeads", "fetchFilteredLeadsInQueueV2"],
  });

  // fetch lead names
  const { data: leadNamesData, loading: leadNamesLoading, error: leadNamesError } = useQuery(FETCH_LEAD_NAMES, {
    variables: {
      leadIds: bulkMergeLeadsModalData?.selected_lead_ids,
    },
  });

  const [selectedPrimaryLead, setSelectedPrimaryLead] = React.useState<string>("");

  const handleCancel = () => {
    setShowBulkMergeLeadsModal(false);
  };
  const handleContinue = () => {
    associateMultipleLeads({
      variables: {
        primaryLeadId: selectedPrimaryLead,
        associateLeadIds: bulkMergeLeadsModalData?.selected_lead_ids.filter((lead_id: string) => {
          return lead_id !== selectedPrimaryLead;
        }),
        // not currently supported by UI or asked for by client
        repId: undefined,
      },
    });
  };

  const loading = associateMultipleLeadsLoading || leadNamesLoading;
  const error = associateMultipleLeadsError || leadNamesError;

  if (loading)
    return (
      <Modal open={showBulkMergeLeadsModal} onClose={() => setShowBulkMergeLeadsModal(false)} closeButtonSize={16}>
        <SkeletonBlock height={425} width={"100%"} borderRadius={6} />
      </Modal>
    );

  if (error)
    return (
      <Modal open={showBulkMergeLeadsModal} onClose={() => setShowBulkMergeLeadsModal(false)} closeButtonSize={16}>
        <AppErrorText>Something went wrong</AppErrorText>
        <div>{error.message}</div>
      </Modal>
    );

  return (
    <Modal open={showBulkMergeLeadsModal} onClose={() => setShowBulkMergeLeadsModal(false)} closeButtonSize={16}>
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Associate Leads </TitleText>
        </TitleDiv>

        <FlexDiv>
          <FlexInnerDiv>
            <AppText fontSize={14} style={{ textAlign: "center", marginBottom: "16px" }} fontWeight={400}>
              Please select a primary lead out of the {bulkMergeLeadsModalData?.selected_lead_ids?.length} lead(s).
            </AppText>
            <div style={{ width: "100%", marginBottom: "16px" }}>
              <NewAppSelect
                placeholder="Select A Primary Lead"
                value={selectedPrimaryLead}
                onChange={(e: any) => {
                  setSelectedPrimaryLead(e?.value);
                }}
                style={{ width: 376, marginBottom: "16px" }}
                options={leadNamesData?.fetchLeadFullNames?.map((lead: any) => {
                  return {
                    label: generateLeadLabel(lead),
                    value: lead.id,
                  };
                })}
                menuPortal
              />
            </div>
          </FlexInnerDiv>
        </FlexDiv>
        <SubmitDiv>
          <div>
            <PhoenixAppButton
              variant="danger-outline"
              buttonType="secondary"
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
              onClick={handleCancel}
            >
              cancel
            </PhoenixAppButton>
          </div>
          <div>
            <PhoenixAppButton
              variant="brand"
              buttonType="secondary"
              onClick={handleContinue}
              disabled={!selectedPrimaryLead}
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
            >
              continue
            </PhoenixAppButton>
          </div>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 16px 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  height: 100%;
`;

const FlexInnerDiv = styled.div`
  overflow: scroll;
  height: 400px;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  overflow: auto;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

export { BulkMergeLeadsModal };
