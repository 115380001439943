import React from "react";
import { FlexDiv, AppText } from "../../UI";
import { theme } from "../../../utils/theme";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { flame } from "src/images/NewDesign";
import { NotificationType } from "./HeaderNotifications";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

interface IHotNotification {
  data: NotificationType;
}

export const HotNotification: React.FC<IHotNotification> = ({ data }) => {
  const history = useHistory();
  const multiAssign = data?.details?.includes("leads has been assigned to you");
  return (
    <FlexDiv
      justify="space-between"
      style={{
        padding: "16px",
        border: `solid ${theme.border.dataviz4.inverse}`,
        borderWidth: "1px 0px",
        background: theme.border.dataviz4.secondary,
      }}
    >
      <FlexDiv align="center" gap={12}>
        <FlameIconContainer>
          <PhoenixIcon svg={flame} variant="white" hoverColor={theme.WHITE_COLOR} size={16} />
        </FlameIconContainer>
        <FlexDiv direction="column">
          <AppText textAlign="left">{data?.text_computed}</AppText>
          {!multiAssign && (
            <AppText
              fontSize={10}
              fontWeight={600}
              color={theme.text.brand.primary}
              style={{ cursor: "pointer", borderBottom: "none" }}
              onClick={() => {
                const bracketTextMatch = data?.text_computed?.match(/\[(.*?)\]/);
                const bracketText = bracketTextMatch ? bracketTextMatch[1] : "";
                history.push(`/lead-detail/${data?.lead_id}`);
                localStorage.setItem(
                  "hotlead_redirect",
                  JSON.stringify({
                    id: data?.relevant_id,
                    type: bracketText,
                  }),
                );
              }}
            >
              View Lead
            </AppText>
          )}
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  );
};

const FlameIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;

  border-radius: 4px;
  background-color: ${theme.fill.dataviz4.inverse};
`;
