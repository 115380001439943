import styled from "styled-components";
import * as React from "react";
import { AppInput, AppButton, AppText, AppSidebarCard, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { callBackStatusGeneral } from "../../images/leadhistory/index";
import { IoMdClose } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import { InputField, FormSelectField, CheckboxField } from "../Field";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { DATE_FORMAT } from "../../utils/format";
import moment from "moment";

interface GeneralTimeItemProps {
  name: string;
  business: string;
  start: string;
  end: string;
}

const GeneralTimeItem: React.FC<GeneralTimeItemProps> = ({ name, business, start, end }) => {
  const dateRangeLabel = () => {
    // If multiple days, display range.
    if (moment(start).startOf("day").isBefore(moment(end).startOf("day")))
      return `${moment(start).format("MMMM D")} - ${moment(end).format("MMMM D")}`;
    // Else display just one day.
    else return moment(start).format("MMMM D");
  };
  return (
    <ItemContainer>
      <img src={callBackStatusGeneral} height={"38px"} />
      <NameEventDiv>
        <UserNameText>{name}</UserNameText>
        <BusinessName>{business}</BusinessName>
      </NameEventDiv>
      <ItemDateDiv>
        <p>{dateRangeLabel()}</p>
        <p>
          {moment(start).format("LT")} - {moment(end).format("LT")}
        </p>
      </ItemDateDiv>
    </ItemContainer>
  );
};

interface GeneralTimeCalendarPopupProps {
  onClickingClose: () => void;
  data: any;
  date: string;
}

const GeneralTimeCalendarPopup: React.FC<GeneralTimeCalendarPopupProps> = ({ onClickingClose, data, date }) => {
  return (
    <Container>
      <CloseButton onClick={() => onClickingClose()}>
        <IoMdClose size={22} color={theme.PRIMARY500} />
      </CloseButton>
      <Header>General Time Events</Header>
      <DateDiv>{moment(date).format(DATE_FORMAT)}</DateDiv>
      <EventDiv>
        {data
          .slice()
          .sort((a: any, b: any) => moment(a?.start).unix() - moment(b?.start).unix())
          ?.map((item: any) => {
            return (
              <GeneralTimeItem
                key={item.lead_id}
                name={item.lead_name}
                business={item.business_name}
                start={item.start}
                end={item.end}
              />
            );
          })}
      </EventDiv>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 421px;
  height: 330px;
  border: 1px solid ${theme.BLACK_COLOR};
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.WHITE_COLOR};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding-top: 21px;
  padding-left: 20px;
  padding-bottom: 26px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: solid 1px ${theme.NEUTRAL100};
`;

const DateDiv = styled.div`
  width: 95px;
  position: relative;
  top: -12px;
  height: 24px;
  background-color: ${theme.NEUTRAL100};
  border-radius: 12px;
  color: ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  align-self: center;
`;

const EventDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 18px;
  padding-right: 18px;
  overflow: auto;
`;

const ItemContainer = styled.div`
  width: 100%;
  height: 60px;
  padding-right: 6px;
  padding-left: 13px;
  background-color: ${theme.NEUTRAL100};
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr;
  align-items: center;
`;

const ItemDateDiv = styled.div`
  background-color: #e9e9e9;
  height: 42px;
  width: 129px;
  border-radius: 14px;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 9px;
  justify-self: end;
`;

const NameEventDiv = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`;

const BusinessName = styled(AppText)`
  color: ${theme.NEUTRAL500};
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
`;

const UserNameText = styled(AppText)`
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
`;

const CloseButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  right: -14px;
  top: -15px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 5;
`;

export { GeneralTimeCalendarPopup };
