import React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { AppInput } from ".";
import "react-day-picker/lib/style.css";
import { DayPickerInputProps } from "react-day-picker/types/Props";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/format";

interface AppDatePickerProps {
  date?: Date;
  title?: string;
  width?: number;
  error?: string | false;
}

const AppDatePicker = ({ title, width, error, ...props }: AppDatePickerProps & DayPickerInputProps) => {
  console.log("date picker: ", props);
  return (
    <Div>
      <DayPickerInput
        {...props}
        format={DATE_FORMAT}
        clickUnselectsDay={false}
        formatDate={(date: any, format: string) => (date ? moment(date).format(format) : "")}
        value={props.value ? moment(props.value).format(DATE_FORMAT) : undefined}
        component={(props: any) => (
          <AppInput title={!!title ? title.toUpperCase() : undefined} width={width} {...props} />
        )}
      />
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

const Div = styled.div`
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.ATTENTION700};
  margin: 0;
`;

export { AppDatePicker };
