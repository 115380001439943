import styled, { css, keyframes } from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "./AppText";
import * as React from "react";
import { useState } from "react";
// import plus from "../../images/NewDesign/plus.svg";
import { MixpanelActions } from "./../../services/mixpanel";
import { PhoenixIcon } from "./Phoenix";
import { chevron_down, chevron_up, plus } from "../../images/NewDesign";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";

interface AppSidebarCardProps {
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number | string;
  /**
   * height of button. Default value is 50.
   */
  height?: number;
  titleHeight?: number;
  secondary?: boolean;
  cancel?: boolean;
  textcolor?: string;
  unbordered?: boolean;
  specialColor?: boolean;
  color?: string;
  hideTitleCard?: boolean;
  modalStyle?: boolean;
  render?: any;
  showExpand?: boolean;
  trackMixpanel?: boolean; //sets Mixpanel Tracker based off title
  scrollable?: boolean;
  stretchHeight?: boolean;
  viewAll?: boolean;
  handleViewAllEvent?: () => void;
  createNew?: boolean;
  createNewText?: string;
  createNewTextColor?: string;
  handleCreateNewEvent?: () => void;
  /**
   * Set to true if you would like the footer to be removed
   * @default true
   */
  removeFooter?: boolean;
  TooltipComponent?: any;

  fontSize?: number;
  fontWeight?: number;
  marginBottom?: string;

  count?: number;

  popupAnimation?: boolean;
}

export const AppSidebarCard = ({
  title,
  width,
  height,
  titleHeight = 36,
  color,
  unbordered = false,
  textcolor,
  children,
  specialColor = false,
  hideTitleCard = false,
  modalStyle = false,
  render,
  showExpand = false,
  trackMixpanel = false,
  scrollable = true,
  stretchHeight = false,
  removeFooter = true,
  TooltipComponent,
  viewAll = false,
  createNew = false,
  createNewText,
  createNewTextColor,
  handleViewAllEvent,
  handleCreateNewEvent,
  fontSize,
  fontWeight,
  marginBottom,
  count = 0,
  popupAnimation = false,
  ...props
}: AppSidebarCardProps & React.HTMLProps<HTMLDivElement>) => {
  const [show, setShow] = useState(true);
  return (
    <SidebarCard
      width={width}
      unbordered={unbordered}
      stretchHeight={stretchHeight}
      style={{ ...props?.style }}
      marginBottom={marginBottom}
      popupAnimation={popupAnimation}
    >
      <TitleDiv
        color={color}
        unbordered={unbordered}
        modalStyle={modalStyle}
        specialColor={specialColor}
        expanded={show}
        titleHeight={titleHeight}
      >
        <TitleText modalStyle={modalStyle} textcolor={textcolor} textSize={fontSize} textWeight={fontWeight}>
          {title} {viewAll ? <ViewAllText onClick={handleViewAllEvent}>({count})</ViewAllText> : ""}
          {!!TooltipComponent && TooltipComponent}
        </TitleText>

        <Horizontal>
          <>
            {createNew && (
              <PhoenixIcon svg={plus} variant="brand" size="small" pointer onClick={handleCreateNewEvent} />
            )}
            {!!render && render}
            {showExpand && (
              <ExpandButton
                onClick={() => {
                  !!!title &&
                    !!trackMixpanel &&
                    MixpanelActions.track(`${title} Collapse/Expand`, { type: show ? "Collapse" : "Expand" });
                  setShow(!show);
                }}
              >
                {!show ? (
                  <PhoenixIcon svg={chevron_down} variant="white" />
                ) : (
                  <PhoenixIcon svg={chevron_up} variant="white" />
                )}
              </ExpandButton>
            )}
          </>
        </Horizontal>
      </TitleDiv>
      {!!show && children}
      {!removeFooter && <FooterDiv />}
    </SidebarCard>
  );
};

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

interface TitleDivProps {
  color?: string;
  unbordered: boolean;
  specialColor: boolean;
  modalStyle: boolean;
  titleHeight?: number;
  expanded?: boolean;
}

const TitleDiv = styled.div<TitleDivProps>`
  margin: 0;
  padding: 0 16px 0 16px;
  display: block;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background-color: ${(props) =>
    props.modalStyle
      ? theme.NEUTRAL100
      : !!props.color
      ? props.color
      : !!props.specialColor
      ? "transparent"
      : theme.PRIMARY900};
  background: ${(props) => (!!props.specialColor ? theme.GRADIENT : "auto")};
  border-bottom: 1px solid ${(props) => (props.modalStyle ? theme.NEUTRAL200 : "transparent")};
  height: ${(props) => {
    if (props.modalStyle) {
      return "56px";
    }
    if (props.titleHeight) {
      return `${props.titleHeight}px`;
    }
  }};
`;

const ExpandButton = styled.div`
  /* background-color: ${theme.PRIMARY800}; */
  color: ${theme.NEUTRAL200};
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;
interface TitleTextProps {
  textcolor?: string;
  textSize?: number;
  textWeight?: number;
  modalStyle?: boolean;
  render?: any;
}

const TitleText = styled(AppText)<TitleTextProps>`
  font-size: ${(props) => (props.textSize ? props.textSize : 12)}px;
  font-weight: ${(props) => (props.textWeight ? props.textWeight : 600)};

  line-height: 18px;

  font-style: normal;
  letter-spacing: 0.2px;
  text-align: ${(props) => (props.modalStyle ? "center" : "left")};
  color: ${(props) => (props.modalStyle ? theme.BLACK_COLOR : props.textcolor || theme.WHITE_COLOR)};
  margin: ${(props) => (props.modalStyle ? "17px auto" : "10px 0px 9px 0px")};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: max-content;
  margin-right: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SidebarCard = styled.div<AppSidebarCardProps>`
  height: ${(props) => (props.stretchHeight ? "100%" : "auto")};
  box-sizing: border-box;
  width: ${(props) => {
    if (!props.width) {
      return "100%";
    }
    if (typeof props.width === "string") {
      return props.width;
    }
    return `${props.width}px`;
  }};
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: ${(props) => (props.unbordered ? "none" : `1px solid ${theme.NEUTRAL200}`)};
  background-color: ${theme.WHITE_COLOR};
  padding: 0;
  margin: 0px 0px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "24px")};

  animation: ${(props) =>
    props.popupAnimation &&
    css`
      ${popupAnimation} .2s ease forwards
    `};
`;

const popupAnimation = keyframes`
  0% {
    opacity: .5;
    scale: .99;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;

const FooterDiv = styled.div`
  height: 16px;
`;

const PlusIconWrapper = styled.div`
  cursor: pointer;
`;

const ViewAllText = styled.p`
  color: var(--buttontext-brand-outline-default, #3e65ff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`;

AppSidebarCard.defaultProps = {
  height: 50,
  secondary: false,
  unbordered: false,
  scrollable: true,
};
