import { keyframes } from "styled-components";

const PRIMARY_FONT = "Inter, sans-serif";
const SECONDARY_FONT = "serif";
const BLACK_COLOR = "#000000";
const WHITE_COLOR = "#ffffff";

const PRIMARY900 = "#0F183E";
const PRIMARY800 = "#1A2A6B";
const PRIMARY700 = "#253D9C";
const PRIMARY600 = "#3252CF";
const PRIMARY500 = "#3E65FF"; //Primary Color
const PRIMARY475 = "#4a6fff";
const PRIMARY450 = "#7091FF";
const PRIMARY400 = "#96BAFF";
const PRIMARY300 = "#BAD1FF";
const PRIMARY200 = "#D8E5FF";
const PRIMARY100 = "#ECF1FF";
const PRIMARY50 = "#F7F9FF";
const PRIMARY25 = "#FAFCFF";

const NEUTRAL800 = "#0b0c11";
const NEUTRAL700 = "#15161f";
const NEUTRAL600 = "#2D2F3B";
const NEUTRAL500 = "#424557";
const NEUTRAL450 = "#424557";
const NEUTRAL400 = "#6F7593";
const NEUTRAL350 = "#8A8FA8";
const NEUTRAL300 = "#A6AABC";
const NEUTRAL250 = "#C3C5D1";
const NEUTRAL200 = "#E1E2E7";
const NEUTRAL100 = "#F5F5F5";

const DANGER1000 = "#3B0705";
const DANGER900 = "#630D09";
const DANGER800 = "#8E120D";
const DANGER700 = "#BA1812";
const DANGER600 = "#F11515";
const DANGER500 = "#EF6260"; // 500 450
const DANGER450 = "#EF6260";
const DANGER400 = "#F28F8E";
const DANGER200 = "#F6B6B5";
const DANGER100 = "#FADBDA";
const DANGER50 = "#FCEDED";

const WARNING800 = "#493208";
const WARNING700 = "#A36F11";
const WARNING600 = "#E29917";
const WARNING500 = "#FFB338";
const WARNING450 = "#ffbd61";
const WARNING400 = "#FFCF87";
const WARNING300 = "#FFE0AD";
const WARNING200 = "#FFEFD1";
const WARNING100 = "#FFF6E9";

const SUCCESS1000 = "#003826";
const SUCCESS900 = "#005137";
const SUCCESS800 = "#006C49";
const SUCCESS700 = "#00885C";
const SUCCESS500 = "#00A56F";
const SUCCESS400 = "#44BF8B";
const SUCCESS300 = "#94D3B4";
const SUCCESS200 = "#CCE8DA";
const SUCCESS100 = "#E6F3EC";

const GRADIENT = "linear-gradient(135deg, #3E65FF 0%, #7091FF 100%)";

const DATAVIZ_1_100 = "#f6eaff";
const DATAVIZ_1_200 = "#eed7ff";
const DATAVIZ_1_300 = "#c6a0e3";
const DATAVIZ_1_400 = "#996DBC";
const DATAVIZ_1_500 = "#8251a3";
const DATAVIZ_1_600 = "#55336f";
const DATAVIZ_1_700 = "#471e67";
const DATAVIZ_1_800 = "#34134e";

const DATAVIZ_2_100 = "#d0eeff";
const DATAVIZ_2_200 = "#b7e4ff";
const DATAVIZ_2_300 = "#6eb4db";
const DATAVIZ_2_400 = "#3c8ebc";
const DATAVIZ_2_500 = "#2577a6";
const DATAVIZ_2_600 = "#14608b";
const DATAVIZ_2_700 = "#084a6f";
const DATAVIZ_2_800 = "#063752";

const DATAVIZ_3_100 = "#d8f9fd";
const DATAVIZ_3_200 = "#aeeaf1";
const DATAVIZ_3_300 = "#79d4de";
const DATAVIZ_3_400 = "#40a8b4";
const DATAVIZ_3_500 = "#23848a";
const DATAVIZ_3_600 = "#12656a";
const DATAVIZ_3_700 = "#085358";
const DATAVIZ_3_800 = "#064043";

const DATAVIZ_4_100 = "#ffeede";
const DATAVIZ_4_200 = "#ffe1c6";
const DATAVIZ_4_300 = "#ffc189";
const DATAVIZ_4_400 = "#ffa555";
const DATAVIZ_4_500 = "#f28727";
const DATAVIZ_4_600 = "#dd6f0b";
const DATAVIZ_4_700 = "#c66005";
const DATAVIZ_4_800 = "#834005";

// deprecated
const TERTIARY500 = "#FFB338";
const TERTIARY100 = "#FFF8ED";

const ATTENTION700 = "#C30F00";
const ATTENTION500 = "#F6857C";
const ATTENTION100 = "#FFD1CD";

// pill colors used in lead card
const PILL_DARK_BLUE = "#2A3C7C";
const PILL_LIGHT_BLUE = "#BAC7FF";
const PILL_BLUE = "#3252CF";
const PILL_DARK_GRAY = "#585D74";
const PILL_LIGHT_GRAY = "#E1E2E7";
const PILL_GRAY = "#A6AABC";
const PILL_DARK_GREEN = "#00A56F";
const PILL_LIGHT_GREEN = "#CCE8DA";
const PILL_VERY_LIGHT_GREEN = "#E6F3EC";
const PILL_GREEN = "#44BF8B";
const PILL_DARK_ORANGE = "#FFB338";
const PILL_LIGHT_ORANGE = "#FFCF87";
const PILL_DARK_RED = "#E91E16";
const PILL_LIGHT_RED = "#F28F8E";

const LIGHT_BLUE = "#ecf1ff";
const LIGHT_GRAY = "#8A8FA8";

// keyframes
const lightPopup = keyframes`
  0% {
    opacity: 0;
    scale: .99;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;
const popup = keyframes`
  0% {
    opacity: 0;
    scale: .96;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;
const heavyPopup = keyframes`
  0% {
    opacity: 0;
    scale: .75;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;
const dramaticPopup = keyframes`
  0% {
    opacity: 0;
    scale: .75;
  }
  60% {
    opacity: .6;
    scale: 1.08;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`;

const textFadeIn = keyframes`
  0% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const lightTheme = true;

const colorVariables = {
  text: {
    neutral: {
      primary: lightTheme ? BLACK_COLOR : WHITE_COLOR,
      secondary: lightTheme ? NEUTRAL300 : NEUTRAL350,
      tertiary: lightTheme ? NEUTRAL300 : NEUTRAL450,
      quaternary: lightTheme ? NEUTRAL800 : NEUTRAL300,
      inverse: lightTheme ? WHITE_COLOR : BLACK_COLOR,
      disabled: lightTheme ? NEUTRAL200 : NEUTRAL600,
    },
    brand: {
      primary: lightTheme ? PRIMARY500 : PRIMARY475,
      secondary: lightTheme ? PRIMARY300 : PRIMARY450,
      tertiary: lightTheme ? PRIMARY450 : PRIMARY900,
      quaternary: lightTheme ? PRIMARY900 : PRIMARY400,
      hover: lightTheme ? PRIMARY600 : PRIMARY500,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    warning: {
      primary: lightTheme ? WARNING450 : WARNING450,
      secondary: lightTheme ? WARNING700 : WARNING300,
      hover: lightTheme ? WARNING600 : WARNING500,
      disabled: lightTheme ? WARNING200 : WARNING800,
    },
    danger: {
      primary: lightTheme ? DANGER600 : DANGER500,
      secondary: lightTheme ? DANGER800 : DANGER200,
      hover: lightTheme ? DANGER700 : DANGER600,
      disabled: lightTheme ? DANGER100 : DANGER900,
    },
    success: {
      primary: lightTheme ? SUCCESS500 : SUCCESS400,
      secondary: lightTheme ? SUCCESS900 : SUCCESS300,
      hover: lightTheme ? SUCCESS700 : SUCCESS500,
      disabled: lightTheme ? SUCCESS200 : SUCCESS1000,
    },
    dataviz1: {
      primary: lightTheme ? DATAVIZ_1_400 : DATAVIZ_1_400,
      secondary: lightTheme ? DATAVIZ_1_700 : DATAVIZ_1_200,
      disabled: lightTheme ? DATAVIZ_1_200 : DATAVIZ_1_700,
    },
    dataviz2: {
      primary: lightTheme ? DATAVIZ_2_400 : DATAVIZ_2_400,
      secondary: lightTheme ? DATAVIZ_2_700 : DATAVIZ_2_200,
      disabled: lightTheme ? DATAVIZ_2_200 : DATAVIZ_2_800,
    },
    dataviz3: {
      primary: lightTheme ? DATAVIZ_3_400 : DATAVIZ_3_400,
      secondary: lightTheme ? DATAVIZ_3_700 : DATAVIZ_3_300,
      disabled: lightTheme ? DATAVIZ_3_200 : DATAVIZ_3_800,
    },
    dataviz4: {
      primary: lightTheme ? DATAVIZ_4_400 : DATAVIZ_4_400,
      secondary: lightTheme ? DATAVIZ_4_500 : DATAVIZ_4_300,
      disabled: lightTheme ? DATAVIZ_4_200 : DATAVIZ_4_800,
    },
  },
  surface: {
    neutral: {
      primary: lightTheme ? WHITE_COLOR : NEUTRAL700,
      secondary: lightTheme ? NEUTRAL100 : NEUTRAL800,
      tertiary: lightTheme ? NEUTRAL200 : BLACK_COLOR,
      inverse: lightTheme ? BLACK_COLOR : WHITE_COLOR,
    },
    brand: {
      primary: lightTheme ? PRIMARY100 : PRIMARY800,
      secondary: lightTheme ? PRIMARY50 : PRIMARY900,
      tertiary: lightTheme ? PRIMARY800 : PRIMARY700,
      quaternary: lightTheme ? PRIMARY200 : PRIMARY600,
      hover: lightTheme ? PRIMARY600 : PRIMARY600,
      inverse: lightTheme ? PRIMARY900 : PRIMARY100,
    },
    warning: {
      primary: lightTheme ? WARNING200 : WARNING500,
      secondary: lightTheme ? WARNING300 : WARNING600,
      inverse: lightTheme ? WARNING500 : WARNING200,
    },
    danger: {
      primary: lightTheme ? DANGER100 : DANGER900,
      secondary: lightTheme ? DANGER200 : DANGER800,
      inverse: lightTheme ? DANGER600 : DANGER100,
    },
    success: {
      primary: lightTheme ? SUCCESS200 : SUCCESS1000,
      secondary: lightTheme ? SUCCESS300 : SUCCESS900,
      inverse: lightTheme ? SUCCESS500 : SUCCESS200,
    },
    dataviz1: {
      primary: lightTheme ? DATAVIZ_1_100 : DATAVIZ_1_100,
      secondary: lightTheme ? DATAVIZ_1_200 : DATAVIZ_1_200,
      inverse: lightTheme ? DATAVIZ_1_500 : DATAVIZ_1_500,
    },
    dataviz2: {
      primary: lightTheme ? DATAVIZ_2_100 : DATAVIZ_2_100,
      secondary: lightTheme ? DATAVIZ_2_200 : DATAVIZ_2_200,
      inverse: lightTheme ? DATAVIZ_2_500 : DATAVIZ_2_500,
    },
    dataviz3: {
      primary: lightTheme ? DATAVIZ_3_100 : DATAVIZ_3_100,
      secondary: lightTheme ? DATAVIZ_3_200 : DATAVIZ_3_200,
      inverse: lightTheme ? DATAVIZ_3_500 : DATAVIZ_3_500,
    },
    dataviz4: {
      primary: lightTheme ? DATAVIZ_4_100 : DATAVIZ_4_100,
      secondary: lightTheme ? DATAVIZ_4_200 : DATAVIZ_4_200,
      inverse: lightTheme ? DATAVIZ_4_400 : DATAVIZ_4_400,
    },
  },
  fill: {
    neutral: {
      primary: lightTheme ? WHITE_COLOR : NEUTRAL700,
      secondary: lightTheme ? NEUTRAL100 : NEUTRAL800,
      tertiary: lightTheme ? NEUTRAL200 : NEUTRAL600,
      quaternary: lightTheme ? NEUTRAL300 : NEUTRAL450,
      inverse: lightTheme ? NEUTRAL800 : NEUTRAL300,
    },
    brand: {
      primary: lightTheme ? PRIMARY500 : PRIMARY475,
      secondary: lightTheme ? PRIMARY100 : PRIMARY900,
      tertiary: lightTheme ? PRIMARY25 : PRIMARY900,
      quaternary: lightTheme ? PRIMARY700 : PRIMARY800,
      inverse: lightTheme ? PRIMARY900 : PRIMARY450,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    warning: {
      primary: lightTheme ? WARNING500 : WARNING450,
      secondary: lightTheme ? WARNING200 : WARNING800,
      tertiary: lightTheme ? WARNING100 : WARNING800,
      inverse: lightTheme ? WARNING600 : WARNING300,
      disabled: lightTheme ? WARNING100 : WARNING800,
    },
    danger: {
      primary: lightTheme ? DANGER600 : DANGER500,
      secondary: lightTheme ? DANGER100 : DANGER900,
      tertiary: lightTheme ? DANGER50 : DANGER1000,
      inverse: lightTheme ? DANGER800 : DANGER200,
      disabled: lightTheme ? DANGER50 : DANGER1000,
    },
    success: {
      primary: lightTheme ? SUCCESS500 : SUCCESS500,
      secondary: lightTheme ? SUCCESS200 : SUCCESS1000,
      tertiary: lightTheme ? SUCCESS100 : SUCCESS1000,
      inverse: lightTheme ? SUCCESS900 : SUCCESS300,
      disabled: lightTheme ? SUCCESS100 : SUCCESS1000,
    },
    dataviz1: {
      primary: lightTheme ? DATAVIZ_1_400 : DATAVIZ_1_400,
      secondary: lightTheme ? DATAVIZ_1_200 : DATAVIZ_1_800,
      inverse: lightTheme ? DATAVIZ_1_600 : DATAVIZ_1_200,
      disabled: lightTheme ? DATAVIZ_1_100 : DATAVIZ_1_800,
    },
    dataviz2: {
      primary: lightTheme ? DATAVIZ_2_400 : DATAVIZ_2_400,
      secondary: lightTheme ? DATAVIZ_2_200 : DATAVIZ_2_800,
      inverse: lightTheme ? DATAVIZ_2_600 : DATAVIZ_2_200,
      disabled: lightTheme ? DATAVIZ_2_100 : DATAVIZ_2_800,
    },
    dataviz3: {
      primary: lightTheme ? DATAVIZ_3_400 : DATAVIZ_3_400,
      secondary: lightTheme ? DATAVIZ_3_200 : DATAVIZ_3_800,
      inverse: lightTheme ? DATAVIZ_3_600 : DATAVIZ_3_200,
      disabled: lightTheme ? DATAVIZ_3_100 : DATAVIZ_3_800,
    },
    dataviz4: {
      primary: lightTheme ? DATAVIZ_4_400 : DATAVIZ_4_400,
      secondary: lightTheme ? DATAVIZ_4_200 : DATAVIZ_4_800,
      inverse: lightTheme ? DATAVIZ_4_500 : DATAVIZ_4_200,
      disabled: lightTheme ? DATAVIZ_4_100 : DATAVIZ_4_800,
    },
    gradient: {
      primary: lightTheme ? PRIMARY500 : PRIMARY475,
      secondary: lightTheme ? PRIMARY100 : PRIMARY900,
      tertiary: lightTheme ? PRIMARY50 : PRIMARY900,
      quaternary: lightTheme ? PRIMARY700 : PRIMARY800,
      inverse: lightTheme ? PRIMARY900 : PRIMARY450,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
  },
  buttonfill: {
    brand: {
      default: lightTheme ? PRIMARY500 : PRIMARY475,
      hover: lightTheme ? PRIMARY600 : PRIMARY500,
      selected: lightTheme ? PRIMARY700 : PRIMARY600,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    brand_outline: {
      hover: lightTheme ? PRIMARY200 : PRIMARY800,
      selected: lightTheme ? PRIMARY500 : PRIMARY500,
    },
    warning_outline: {
      hover: lightTheme ? WARNING200 : WARNING800,
      selected: lightTheme ? WARNING500 : WARNING450,
    },
    danger_outline: {
      hover: lightTheme ? DANGER100 : DANGER900,
      selected: lightTheme ? DANGER600 : DANGER500,
    },
    success_outline: {
      hover: lightTheme ? SUCCESS200 : SUCCESS900,
      selected: lightTheme ? SUCCESS500 : SUCCESS400,
    },
  },
  buttontext: {
    brand: {
      default: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      hover: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      selected: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      disabled: lightTheme ? WHITE_COLOR : PRIMARY700,
    },
    brand_outline: {
      default: lightTheme ? PRIMARY500 : PRIMARY475,
      hover: lightTheme ? PRIMARY500 : PRIMARY475,
      selected: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    brand_ghost: {
      default: lightTheme ? PRIMARY500 : PRIMARY450,
      hover: lightTheme ? PRIMARY600 : PRIMARY450,
      selected: lightTheme ? PRIMARY700 : WHITE_COLOR,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    warning_outline: {
      default: lightTheme ? WARNING500 : WARNING450,
      hover: lightTheme ? WARNING500 : WARNING450,
      selected: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      disabled: lightTheme ? WARNING200 : WARNING800,
    },
    danger_outline: {
      default: lightTheme ? DANGER600 : DANGER500,
      hover: lightTheme ? DANGER600 : DANGER500,
      selected: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      disabled: lightTheme ? DANGER100 : DANGER900,
    },
    success_outline: {
      default: lightTheme ? SUCCESS500 : SUCCESS400,
      hover: lightTheme ? SUCCESS500 : SUCCESS400,
      selected: lightTheme ? WHITE_COLOR : WHITE_COLOR,
      disabled: lightTheme ? SUCCESS200 : SUCCESS900,
    },
  },
  buttonborder: {
    brand_outline: {
      default: lightTheme ? PRIMARY500 : PRIMARY475,
      hover: lightTheme ? PRIMARY500 : PRIMARY475,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    warning_outline: {
      default: lightTheme ? WARNING500 : WARNING450,
      hover: lightTheme ? WARNING500 : WARNING450,
      disabled: lightTheme ? WARNING200 : WARNING800,
    },
    danger_outline: {
      default: lightTheme ? DANGER600 : DANGER500,
      hover: lightTheme ? DANGER600 : DANGER500,
      disabled: lightTheme ? DANGER100 : DANGER900,
    },
    success_outline: {
      default: lightTheme ? SUCCESS500 : SUCCESS400,
      hover: lightTheme ? SUCCESS500 : SUCCESS400,
      disabled: lightTheme ? SUCCESS200 : SUCCESS900,
    },
  },
  border: {
    neutral: {
      primary: lightTheme ? NEUTRAL300 : NEUTRAL450,
      secondary: lightTheme ? NEUTRAL200 : BLACK_COLOR,
      tertiary: lightTheme ? NEUTRAL450 : NEUTRAL700,
      quaternary: lightTheme ? NEUTRAL100 : NEUTRAL500,
      inverse: lightTheme ? NEUTRAL800 : NEUTRAL300,
      hover: lightTheme ? NEUTRAL350 : NEUTRAL400,
    },
    brand: {
      primary: lightTheme ? PRIMARY500 : PRIMARY475,
      secondary: lightTheme ? PRIMARY100 : PRIMARY900,
      tertiary: lightTheme ? PRIMARY200 : PRIMARY700,
      quaternary: lightTheme ? PRIMARY700 : PRIMARY600,
      inverse: lightTheme ? PRIMARY900 : PRIMARY400,
    },
    warning: {
      primary: lightTheme ? WARNING500 : WARNING450,
      secondary: lightTheme ? WARNING200 : WARNING800,
      tertiary: lightTheme ? WARNING300 : WARNING800,
      inverse: lightTheme ? WARNING700 : WARNING300,
    },
    danger: {
      primary: lightTheme ? DANGER600 : DANGER500,
      secondary: lightTheme ? DANGER100 : DANGER900,
      inverse: lightTheme ? DANGER800 : DANGER200,
    },
    success: {
      primary: lightTheme ? SUCCESS500 : SUCCESS400,
      secondary: lightTheme ? SUCCESS200 : SUCCESS1000,
      inverse: lightTheme ? SUCCESS800 : SUCCESS300,
    },
    dataviz1: {
      primary: lightTheme ? DATAVIZ_1_400 : DATAVIZ_1_400,
      secondary: lightTheme ? DATAVIZ_1_200 : DATAVIZ_1_800,
      inverse: lightTheme ? DATAVIZ_1_600 : DATAVIZ_1_200,
    },
    dataviz2: {
      primary: lightTheme ? DATAVIZ_2_400 : DATAVIZ_2_400,
      secondary: lightTheme ? DATAVIZ_2_200 : DATAVIZ_2_800,
      inverse: lightTheme ? DATAVIZ_2_600 : DATAVIZ_2_200,
    },
    dataviz3: {
      primary: lightTheme ? DATAVIZ_3_400 : DATAVIZ_3_400,
      secondary: lightTheme ? DATAVIZ_3_200 : DATAVIZ_3_800,
      inverse: lightTheme ? DATAVIZ_3_600 : DATAVIZ_3_200,
    },
    dataviz4: {
      primary: lightTheme ? DATAVIZ_4_400 : DATAVIZ_4_400,
      secondary: lightTheme ? DATAVIZ_4_200 : DATAVIZ_4_800,
      inverse: lightTheme ? DATAVIZ_4_500 : DATAVIZ_4_200,
    },
  },
  icon: {
    neutral: {
      default: lightTheme ? NEUTRAL300 : NEUTRAL450,
      secondary: lightTheme ? BLACK_COLOR : NEUTRAL300,
      hover: lightTheme ? NEUTRAL350 : NEUTRAL400,
      selected: lightTheme ? NEUTRAL450 : NEUTRAL350,
      disabled: lightTheme ? NEUTRAL200 : NEUTRAL600,
      inverse: lightTheme ? WHITE_COLOR : BLACK_COLOR,
    },
    brand: {
      default: lightTheme ? PRIMARY500 : PRIMARY475,
      secondary: lightTheme ? PRIMARY900 : PRIMARY400,
      hover: lightTheme ? PRIMARY600 : PRIMARY500,
      selected: lightTheme ? PRIMARY700 : PRIMARY600,
      disabled: lightTheme ? PRIMARY200 : PRIMARY800,
    },
    warning: {
      default: lightTheme ? WARNING500 : WARNING450,
      secondary: lightTheme ? WARNING800 : WARNING300,
      hover: lightTheme ? WARNING600 : WARNING500,
      selected: lightTheme ? WARNING700 : WARNING600,
      disabled: lightTheme ? WARNING200 : WARNING800,
    },
    danger: {
      default: lightTheme ? DANGER600 : DANGER500,
      secondary: lightTheme ? DANGER900 : DANGER200,
      hover: lightTheme ? DANGER700 : DANGER600,
      selected: lightTheme ? DANGER800 : DANGER700,
      disabled: lightTheme ? DANGER100 : DANGER900,
    },
    success: {
      default: lightTheme ? SUCCESS100 : SUCCESS400,
      secondary: lightTheme ? SUCCESS1000 : SUCCESS300,
      hover: lightTheme ? SUCCESS700 : SUCCESS500,
      selected: lightTheme ? SUCCESS800 : SUCCESS700,
      disabled: lightTheme ? SUCCESS200 : SUCCESS1000,
    },
    dataviz1: {
      default: lightTheme ? DATAVIZ_1_400 : DATAVIZ_1_400,
      secondary: lightTheme ? DATAVIZ_1_700 : DATAVIZ_1_200,
      hover: lightTheme ? DATAVIZ_1_500 : DATAVIZ_1_600,
      selected: lightTheme ? DATAVIZ_1_500 : DATAVIZ_1_500,
      disabled: lightTheme ? DATAVIZ_1_200 : DATAVIZ_1_800,
    },
    dataviz2: {
      default: lightTheme ? DATAVIZ_2_400 : DATAVIZ_2_400,
      secondary: lightTheme ? DATAVIZ_2_800 : DATAVIZ_2_200,
      hover: lightTheme ? DATAVIZ_2_500 : DATAVIZ_2_700,
      selected: lightTheme ? DATAVIZ_2_600 : DATAVIZ_2_600,
      disabled: lightTheme ? DATAVIZ_2_200 : DATAVIZ_2_800,
    },
    dataviz3: {
      default: lightTheme ? DATAVIZ_3_400 : DATAVIZ_3_400,
      secondary: lightTheme ? DATAVIZ_3_800 : DATAVIZ_3_200,
      hover: lightTheme ? DATAVIZ_3_500 : DATAVIZ_3_700,
      selected: lightTheme ? DATAVIZ_3_600 : DATAVIZ_3_600,
      disabled: lightTheme ? DATAVIZ_3_200 : DATAVIZ_3_800,
    },
    dataviz4: {
      default: lightTheme ? DATAVIZ_4_400 : DATAVIZ_4_400,
      secondary: lightTheme ? DATAVIZ_4_800 : DATAVIZ_4_200,
      hover: lightTheme ? DATAVIZ_4_500 : DATAVIZ_4_600,
      selected: lightTheme ? DATAVIZ_4_600 : DATAVIZ_4_500,
      disabled: lightTheme ? DATAVIZ_4_200 : DATAVIZ_4_800,
    },
  },
  link: {
    brand: {
      default: lightTheme ? PRIMARY500 : PRIMARY500,
      hover: lightTheme ? PRIMARY600 : PRIMARY600,
      visited: lightTheme ? PRIMARY700 : PRIMARY700,
      disabled: lightTheme ? PRIMARY200 : PRIMARY200,
    },
  },
};

export const theme = {
  PRIMARY_FONT,
  SECONDARY_FONT,
  BLACK_COLOR,
  WHITE_COLOR,
  PRIMARY25,
  PRIMARY50,
  PRIMARY100,
  PRIMARY200,
  PRIMARY300,
  PRIMARY400,
  PRIMARY450,
  PRIMARY475,
  PRIMARY500,
  PRIMARY600,
  PRIMARY700,
  PRIMARY800,
  PRIMARY900,
  ATTENTION100,
  ATTENTION500,
  ATTENTION700,
  NEUTRAL100,
  NEUTRAL200,
  NEUTRAL250,
  NEUTRAL300,
  NEUTRAL350,
  NEUTRAL400,
  NEUTRAL450,
  NEUTRAL500,
  NEUTRAL600,
  NEUTRAL700,
  NEUTRAL800,
  GRADIENT,
  TERTIARY100,
  TERTIARY500,
  WARNING100,
  WARNING200,
  WARNING300,
  WARNING400,
  WARNING450,
  WARNING500,
  WARNING600,
  WARNING700,
  WARNING800,
  SUCCESS100,
  SUCCESS200,
  SUCCESS300,
  SUCCESS400,
  SUCCESS500,
  SUCCESS700,
  SUCCESS800,
  SUCCESS900,
  SUCCESS1000,
  // pill colors used in lead card
  PILL_DARK_BLUE,
  PILL_LIGHT_BLUE,
  PILL_BLUE,
  PILL_DARK_GRAY,
  PILL_LIGHT_GRAY,
  PILL_GRAY,
  PILL_DARK_GREEN,
  PILL_LIGHT_GREEN,
  PILL_VERY_LIGHT_GREEN,
  PILL_GREEN,
  PILL_DARK_ORANGE,
  PILL_LIGHT_ORANGE,
  PILL_DARK_RED,
  PILL_LIGHT_RED,
  DANGER1000,
  DANGER900,
  DANGER800,
  DANGER700,
  DANGER600,
  DANGER500,
  DANGER450,
  DANGER400,
  DANGER200,
  DANGER100,
  DANGER50,
  DATAVIZ_1_100,
  DATAVIZ_1_200,
  DATAVIZ_1_300,
  DATAVIZ_1_400,
  DATAVIZ_1_500,
  DATAVIZ_1_600,
  DATAVIZ_1_700,
  DATAVIZ_1_800,
  DATAVIZ_2_100,
  DATAVIZ_2_200,
  DATAVIZ_2_300,
  DATAVIZ_2_400,
  DATAVIZ_2_500,
  DATAVIZ_2_600,
  DATAVIZ_2_700,
  DATAVIZ_2_800,
  DATAVIZ_3_100,
  DATAVIZ_3_200,
  DATAVIZ_3_300,
  DATAVIZ_3_400,
  DATAVIZ_3_500,
  DATAVIZ_3_600,
  DATAVIZ_3_700,
  DATAVIZ_3_800,
  DATAVIZ_4_100,
  DATAVIZ_4_200,
  DATAVIZ_4_300,
  DATAVIZ_4_400,
  DATAVIZ_4_500,
  DATAVIZ_4_600,
  DATAVIZ_4_700,
  DATAVIZ_4_800,
  LIGHT_BLUE,
  LIGHT_GRAY,
  lightPopup,
  popup,
  heavyPopup,
  dramaticPopup,
  textFadeIn,
  fadeIn,

  ...colorVariables,
};
