import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { FlexDiv } from "src/Components/UI";
import { ModalContext } from "src/context";
import {
  FieldItem,
  FormField,
  SYSTEM_FIELD_NAME_TO_LABEL,
  formatFormFields,
  generateRowsFromFields,
} from "src/utils/misc";
import useCustomFields from "src/hooks/useCustomFields";
import SystemFieldPreview from "src/Components/Field/SystemFieldPreview";
import { DEFAULT_SYSTEM_FIELDS_FOR_OPSIQ_FORMS } from "src/utils/variables";
import { Modal } from "../Modal";

export const FormPreviewModal = () => {
  const { data: customFieldsData } = useCustomFields();
  const { formPreviewModalData, setShowFormPreviewModal, showFormPreviewModal } = useContext(ModalContext);

  const form = formPreviewModalData.values;

  const fields = useMemo(() => {
    return formatFormFields(
      formPreviewModalData.values.form_fields ?? [],
      DEFAULT_SYSTEM_FIELDS_FOR_OPSIQ_FORMS,
      customFieldsData,
    );
  }, [customFieldsData, form.form_fields]);

  const rows = useMemo(() => {
    return generateRowsFromFields(fields);
  }, [fields]);

  const labelStyle = {
    color: form.form_field_label_color,
    fontFamily: form.form_field_label_font,
    fontSize: form.form_field_label_text_size,
  };

  const inputStyle = {
    color: form.form_field_text_color,
    fontFamily: form.form_field_text_font,
    fontSize: form.form_field_text_size,
  };

  return (
    <Modal
      open={showFormPreviewModal}
      onClose={() => {
        setShowFormPreviewModal(false);
      }}
      popupAnimation
      style={{ backgroundColor: "transparent" }}
    >
      <Main
        backgroundColor={form.form_background_color}
        borderColor={form.form_border_color}
        borderRadius={form.form_corner_radius}
      >
        <ModalBody>
          {rows?.map((row) => {
            return (
              <FlexDiv key={row.id} gap={24}>
                {row.fields?.map((f) => {
                  const field = (f as unknown) as FieldItem<FormField>;

                  const isCustomField = Boolean(field.custom_field?.id);

                  const title = isCustomField
                    ? field?.custom_field?.key
                    : SYSTEM_FIELD_NAME_TO_LABEL[field.system_field as keyof typeof SYSTEM_FIELD_NAME_TO_LABEL];
                  return (
                    <SystemFieldPreview
                      key={field.id}
                      content={title ?? ""}
                      required={Boolean(field.required)}
                      inputType={field.inputType ?? "Text"}
                      systemField={field.system_field}
                      labelStyle={labelStyle}
                      inputStyle={inputStyle}
                    />
                  );
                })}
              </FlexDiv>
            );
          })}

          <ButtonWrapper align={form.button_alignment?.toLowerCase()}>
            <Button
              showButtonBorder={form.show_button_border}
              borderColor={form.button_border_color}
              borderRadius={form.button_corner_radius}
              fontSize={form.button_text_size}
              fontFamily={form.button_font}
              color={form.button_text_color}
              backgroundColor={form.button_fill_color}
            >
              {form.button_content}
            </Button>
          </ButtonWrapper>
        </ModalBody>
      </Main>
    </Modal>
  );
};

const ALIGNMENT_MAP: Record<string, string> = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

const Main = styled.div<{ backgroundColor?: string; borderRadius?: string | number; borderColor?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  padding: 24px 24px 40px;
  height: 100%;
  width: 100%;
`;

const Button = styled.button<{
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string | number;
  showButtonBorder?: boolean;
  fontSize?: string | number;
  fontFamily?: string;
  color?: string;
}>`
  appearance: none;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: ${({ showButtonBorder, borderColor }) => (showButtonBorder ? `1px solid ${borderColor}` : "none")};
  color: ${({ color }) => color};
  cursor: pointer;
  display: inline-block;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 400;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  padding: 12px 16px;
  position: relative;
  text-transform: uppercase;
  width: auto;
`;

const ButtonWrapper = styled.div<{ align: string }>`
  display: flex;
  justify-content: ${({ align }) => ALIGNMENT_MAP[align]};
  width: 100%;
`;
