import styled from "styled-components";
import * as React from "react";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField } from "../Field";
import { appToast } from "../../utils/toast";
import { newCloseModal } from "../../images";
import { Modal } from "./Modal";
import { SYSTEM_FIELD_CHAR_LIMIT } from "../../utils/format";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
}

const ADD_LEAD_SOURCE_FIELD = gql`
  mutation addSystemField($systemFieldType: SystemFieldType!, $label: String!) {
    addSystemField(systemFieldType: $systemFieldType, label: $label)
  }
`;

const CreateLeadSourceModal: React.FC<DisappearingDivProps> = ({ blinds, close }) => {
  interface MyFormikProps {
    key: string;
  }

  const leadSourceSchema = Yup.object().shape({
    key: Yup.string()
      .required("Field required!")
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  });

  const [addLeadSource, { loading: addLoading, error: addError }] = useMutation(ADD_LEAD_SOURCE_FIELD, {
    onCompleted({ addLeadSource }) {
      console.log("Add lead source: ", addLeadSource);
      appToast("Added lead source");
      close();
    },
    onError({ message }) {
      appToast(message);
      console.log("Error in addLeadSource: ", message);
    },
    refetchQueries: ["leadSourceOptions"],
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        key: "",
      }}
      validationSchema={leadSourceSchema}
      onSubmit={async (values) => {
        addLeadSource({
          variables: {
            systemFieldType: "LeadSource",
            label: values.key,
          },
        });
      }}
    >
      {({ submitForm, isSubmitting, isValid, dirty, errors }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close}>
            <ModalContent>
              <TitleDiv>
                <PopupTitle>Add Lead Source</PopupTitle>
              </TitleDiv>
              <ScrollingDiv>
                <PopupInputLabel>
                  Add Lead Source<span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <FieldInput name="key" />
              </ScrollingDiv>
              <SubmitDiv>
                <PhoenixAppButton
                  type="submit"
                  onClick={submitForm}
                  buttonType="secondary"
                  variant="brand"
                  disabled={addLoading || !isValid || isSubmitting}
                >
                  Save
                </PhoenixAppButton>
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const ModalContent = styled.div`
  width: 720px;
  height: 680px;
`;

const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;

  height: 88px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const FieldInput = styled(InputField)`
  margin: 0;
  text-align: left;

  ::placeholder {
    text-align: left;
  }
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 27px; */
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 0px 168px;
  overflow: auto;
  width: 100%;
  min-height: 536px;
  height: 536px;

  max-height: 536px;
  margin-bottom: 88px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { CreateLeadSourceModal };
