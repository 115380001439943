import React from "react";
import styled from "styled-components";
import { generateCommunicationToggleTooltip } from "../../../utils/format";
import { gql, useMutation } from "@apollo/client";
import { loggedInUser } from "../../../apollo/cache";
import { CommunicationStates } from "../../../utils/format";
import { CommunicationToggle } from "./CommunicationToggle";
import { appToast, errorToast } from "../../../utils/toast";
import * as Sentry from "@sentry/react";
import { AppErrorText } from "../../UI";

const UPDATE_SMS_SUBSCRIPTION_STATUS = gql`
  mutation UpdateSMSSubscriptionStatus($leadId: String!, $smsSubStatus: OptInStatus!) {
    updateSMSSubscriptionStatus(lead_id: $leadId, sms_sub_status: $smsSubStatus) {
      sms_sub_status
    }
  }
`;

const SMSCommunicationToggle = ({
  lead,
  loadingLead,
  refetchFunction,
}: {
  lead: any;
  loadingLead: boolean;
  // please use the refetch queries string on the onCompleted prop of the mutation
  // this is for anything that we can't use those for
  refetchFunction?: () => void;
}) => {
  const smsStatus = lead?.sms_sub_status;

  const leadSubscribedToSMS = smsStatus === CommunicationStates.Subscribed;

  const unsubScribeWasLeadActionSMS = smsStatus === CommunicationStates.LeadUnsubscribed;

  const [
    updateSMSSubscriptionStatus,
    { loading: loadingUpdateSMSSubscriptionStatus, error: errorUpdateSMSSubscriptionStatus },
  ] = useMutation(UPDATE_SMS_SUBSCRIPTION_STATUS, {
    onCompleted({ updateSMSSubscriptionStatus }) {
      if (!updateSMSSubscriptionStatus) {
        return;
      }
      appToast("SMS Subscription Status Updated!");

      refetchFunction && refetchFunction();
    },
    onError({ message, name }) {
      errorToast("Unable to update SMS Subscription Status. Please contact support.");
      Sentry.captureEvent({
        message: `updateSMSSubscriptionStatus GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
    },
    refetchQueries: ["fetchSMSItems"],
  });

  const error = errorUpdateSMSSubscriptionStatus || !smsStatus;

  if (error) {
    return <AppErrorText style={{ marginBottom: "16px" }}>Error loading communication toggles.</AppErrorText>;
  }

  return (
    <CommunicationsRow>
      <CommunicationToggle
        toggled={leadSubscribedToSMS}
        type="sms"
        repIsAdmin={loggedInUser()?.role === "ADMIN"}
        repOwnsLead={lead?.rep_id === loggedInUser()?.id}
        toggle={() => {
          updateSMSSubscriptionStatus({
            variables: {
              leadId: lead?.id,
              smsSubStatus: leadSubscribedToSMS
                ? CommunicationStates.OPSIQUnsubscribed
                : CommunicationStates.Subscribed,
            },
          });
        }}
        leadOptedOut={leadSubscribedToSMS ? undefined : unsubScribeWasLeadActionSMS}
        loading={loadingLead || loadingUpdateSMSSubscriptionStatus}
        tooltipText={generateCommunicationToggleTooltip({
          type: "sms",
          isAdmin: loggedInUser()?.role === "ADMIN",
          leadLeadAction: leadSubscribedToSMS ? undefined : unsubScribeWasLeadActionSMS,
          toggled: leadSubscribedToSMS,
        })}
      />
    </CommunicationsRow>
  );
};

const CommunicationsRow = styled.div`
  display: flex;
  flex-direction: "column";
  gap: "4px";
  margin-bottom: 0px;
  margin-top: 4px;
`;

export { SMSCommunicationToggle };
