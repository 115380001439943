import React from "react";
import { gql } from "@apollo/client";
import { theme } from "./theme";
import { AppText } from "../Components/UI";
import { entryCriteriaOptions, leaderboardMetricOptions, sequenceReportingTimeframeOptions } from "./sequences";
import { ReportingChartsStateShape, Segment } from "../types/SequenceTypes";
import moment from "moment";
import { getLocalStorage } from "./misc";
import { cloneDeep } from "lodash";

// -------- Bar chart colors
export const primaryBarColors = [theme.PRIMARY500, theme.PRIMARY450, theme.PRIMARY400, theme.PRIMARY300];
export const successBarColors = [theme.SUCCESS500, theme.SUCCESS400, theme.SUCCESS300, theme.SUCCESS200];
export const warningBarColors = [theme.WARNING700, theme.WARNING500, theme.WARNING400, theme.WARNING200];
export const dangerBarColors = [theme.DANGER600, theme.DANGER500, theme.DANGER400, theme.DANGER200];
export const altPrimaryBarColors = [
  theme.PRIMARY700,
  theme.PRIMARY500,
  theme.PRIMARY400,
  theme.DANGER600,
  theme.SUCCESS300,
  theme.SUCCESS500,
];
export const altSuccessBarColors = [theme.SUCCESS900, theme.SUCCESS500, theme.SUCCESS300];
export const altWarningBarColors = [theme.WARNING700, theme.WARNING500, theme.WARNING300];
export const altDangerBarColors = [theme.DANGER800, theme.DANGER600, theme.DANGER200];

// -------- Global Filters
export const FETCH_GLOBAL_FILTER_SEQUENCES = gql`
  query fetchGlobalFilterSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

export const FETCH_DASHBOARD_VIEWS = gql`
  query fetchDashboardsViews {
    fetchDashboardsViews {
      id
      view_name
      sequence_reporting_filters
      sequence_reporting_segments
    }
  }
`;

export const FETCH_SEQUENCE_LEADERBOARD = gql`
  query fetchSequenceReps($sequenceDashboardInput: SequenceDashboardInput!, $sortBy: RepsSortOrderSequenceQuery!) {
    fetchSequenceReps(SequenceDashboardInput: $sequenceDashboardInput, sortBy: $sortBy)
  }
`;

export const CREATE_OR_UPDATE_SEQUENCE_DASHBOARD_VIEW = gql`
  mutation createOrSequenceDashboardView($sequenceDashboardViewInput: SequenceDashboardViewInput!) {
    createOrSequenceDashboardView(SequenceDashboardViewInput: $sequenceDashboardViewInput) {
      id
      view_name
      sequence_reporting_filters
      sequence_reporting_segments
    }
  }
`;

export const DELETE_SEQUENCE_DASHBOARD_VIEW = gql`
  mutation deleteSequenceDashboardView($viewId: String!) {
    deleteSequenceDashboardView(view_id: $viewId)
  }
`;

// -------- Queries for Sequence Reporting charts
export const FETCH_SEQUENCE_MODULE_METRICS = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!, $sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      moduleMetrics(SequenceDashboardInput: $sequenceDashboardInput)
    }
  }
`;

// Attributed Revenue Module
export const FETCH_DIRECT_REVENUE = gql`
  query fetchDirectRevenue($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchDirectRevenue(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_INFLUENCED_REVENUE = gql`
  query fetchInfluencedRevenue($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchInfluencedRevenue(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// Events Module
export const NUMBER_OF_SETS = gql`
  query fetchSetsPerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSetsPerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_SCHEDULED_HOLD_RATE = gql`
  query fetchHoldRatePerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchHoldRatePerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// Velocity and Conversion Module
export const FETCH_AVERAGE_TIME_IN_PHASE = gql`
  query fetchAverageTimeInPhase($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchAverageTimeInPhase(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_CONVERSION_RATE_PHASE_TO_PHASE = gql`
  query fetchConversionRateFromPhaseToPhase($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchConversionRateFromPhaseToPhase(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// Email Module
export const FETCH_ALL_EMAIL_METRICS = gql`
  query fetchSequenceEmailMetrics($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceEmailMetrics(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_EMAIL_OPEN_RATE_BY_SEQUENCE = gql`
  query fetchSequenceEmailOpenRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceEmailOpenRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_EMAIL_CLICK_THROUGH_RATE = gql`
  query fetchSequenceEmailClickRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceEmailClickRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_EMAIL_UNSUBSCRIBE_RATE = gql`
  query fetchSequenceEmailUnsubscribeRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceEmailUnsubscribeRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_EMAIL_BOUNCE_RATE = gql`
  query fetchSequenceEmailBounceRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceEmailBounceRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_EMAIL_REPLY_RATE = gql`
  query fetchSequenceEmailReplyRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceEmailReplyRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// SMS Module
export const FETCH_ALL_SMS_METRICS = gql`
  query fetchSequenceSmsMetrics($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceSmsMetrics(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_SMS_DELIVERY_RATE = gql`
  query fetchSequenceSmsDeliveredRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceSmsDeliveredRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_SMS_FAILED_RATE = gql`
  query fetchSequenceSmsFailedRate($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchSequenceSmsFailedRate(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// Dials Module
export const FETCH_ANSWER_RATE = gql`
  query fetchAnswerRatePerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchAnswerRatePerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_DM_CONNECT_RATE = gql`
  query fetchDMRatePerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchDMRatePerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_NUMBER_OF_DIALS = gql`
  query fetchDialsPerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchDialsPerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_VOICEMAIL_RATE = gql`
  query fetchVoicemailRatePerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchVoicemailRatePerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_BAD_NUMBER_COUNT = gql`
  query fetchBadNumberCountPerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchBadNumberCountPerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// Tasks Module
export const FETCH_TASK_COMPLETION_RATE = gql`
  query fetchTaskCompletionRateBySequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchTaskCompletionRateBySequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_AVG_TASK_SPEED = gql`
  query fetchTaskCompletionAvgSpeedBySequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchTaskCompletionAvgSpeedBySequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;
export const FETCH_SNOOZED_COUNT = gql`
  query fetchTasksSnoozedPerSequence($sequenceDashboardInput: SequenceDashboardInput!) {
    fetchTasksSnoozedPerSequence(SequenceDashboardInput: $sequenceDashboardInput)
  }
`;

// -------- Queries for step metrics
export const FETCH_STEP_METRICS = gql`
  query fetchStepMetrics($stepId: String!) {
    fetchStepMetrics(step_id: $stepId)
  }
`;

// -------- Chart Configuration
export const SEQUENCE_REPORTING_CHARTS: ReportingChartsStateShape = {
  "Attributed Revenue": [
    {
      id: "attributed-revenue-direct",
      title: "Direct Revenue",
      description: "Sale was made while lead was in the specific sequence",
      metric: "Revenue",
      yAxisLabel: "Revenue",
      xAxisLabel: "Sequence",
      dataFetch: FETCH_DIRECT_REVENUE,
      dataKey: "direct_revenue_total",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "attributed-revenue-influenced",
      title: "Influenced Revenue",
      description: "Sale was made at any point in the sales cycle after completing at least one task in a sequence",
      metric: "Revenue",
      yAxisLabel: "Revenue",
      xAxisLabel: "Sequence",
      dataFetch: FETCH_INFLUENCED_REVENUE,
      dataKey: "revenue_influenced_total",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
  ],
  Events: [
    {
      id: "events-number-of-sets",
      title: "Number of Sets",
      legend: ["Initial Demos", "Follow-Up Demos", "Decision Calls"],
      yAxisLabel: "Events Set",
      xAxisLabel: "Sequences",
      dataFetch: NUMBER_OF_SETS,
      dataKey: "demos_set",
      altDataKeys: ["followup_hold_count", "decision_calls"],
      chartTheme: altPrimaryBarColors,
      refetchOnGlobalFilterChange: false,
      groupedBarChart: true,
    },
    {
      id: "events-scheduled-hold-rate",
      title: "Scheduled Hold Rate",
      description:
        "% of time scheduled demos actually occur regardless of if it's the first time scheduled or subsequently rescheduled",
      metric: "Percent",
      yAxisLabel: "Hold Rate",
      xAxisLabel: "Sequence",
      dataFetch: FETCH_SCHEDULED_HOLD_RATE,
      dataKey: "scheduled_hold_rate",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
  ],
  "Velocity and Conversion": [
    {
      id: "velocity-and-conversion-avg-time-in-phase",
      title: "Average Time in Phase",
      description: "How long leads stay in phase before moving to subsequent phase",
      legend: ["Cold Call", "Demo set for future date", "Demo not held at set time", "Demo held"],
      legendPosition: "right",
      yAxisLabel: "Average Time (days)",
      xAxisLabel: "Phases",
      minChartWidth: 180,
      dataFetch: FETCH_AVERAGE_TIME_IN_PHASE,
      dataKey: "value",
      altDataKeys: [
        "cold_call_avg_time_day",
        "hold_demo_set_avg_time_day",
        "post_hold_demo_not_set_avg_time_day",
        "post_hold_demo_held_avg_time_day",
      ],
      dataKeyOverride: true,
      chartTheme: altPrimaryBarColors,
      refetchOnGlobalFilterChange: true,
    },
    {
      id: "velocity-and-conversion-conversion-phase-to-phase",
      title: "Conversion Rate From Phase to Phase",
      legend: ["Leads in Sequence", "Leads Not in Sequence"],
      yAxisLabel: "Conversion Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_CONVERSION_RATE_PHASE_TO_PHASE,
      dataKey: "conversion_rate",
      altDataKeys: ["not_in_sequence_conversion_rate"],
      metric: "Percent",
      groupedBarChart: true,
      chartTheme: altPrimaryBarColors,
      refetchOnGlobalFilterChange: false,
      tooltipLabelFormatter: (payload: any) => (
        <AppText fontSize={10} lineHeight={14} color={theme.NEUTRAL300}>
          {
            // find entry criteria label based on payload sequence_phase
            entryCriteriaOptions?.find((option: { label: string; value: any }) =>
              Array.isArray(option.value)
                ? option.value[0] === payload?.[0]?.payload?.sequence_phase?.[0]
                : option.value === payload?.[0]?.payload?.sequence_phase?.[0],
            )?.label
          }
        </AppText>
      ),
    },
  ],
  Email: [
    {
      id: "email-all-emails",
      title: "All Emails",
      legend: ["Open Rate", "Clickthrough Rate", "Bounce Rate", "Unsubscribe Rate", "Reply Rate"],
      legendPosition: "right",
      yAxisLabel: "Percent",
      minChartWidth: 180,
      dataFetch: FETCH_ALL_EMAIL_METRICS,
      dataKey: "value",
      altDataKeys: [
        "email_opened_perc",
        "email_clicked_perc",
        "email_bounced_perc",
        "email_unsubscribed_perc",
        "email_replied_perc",
      ],
      metric: "Percent",
      dataKeyOverride: true,
      chartTheme: altPrimaryBarColors,
      refetchOnGlobalFilterChange: true,
      dataFormatter: (dataVal: any) =>
        // sort the data to match altDataKeys order
        [
          "email_opened_perc",
          "email_clicked_perc",
          "email_bounced_perc",
          "email_unsubscribed_perc",
          "email_replied_perc",
        ]?.map((key: string) => dataVal.find((item: any) => Object.keys(item).find((k) => k === key))),
    },
    {
      id: "email-open-rate",
      title: "Open Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Open Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_EMAIL_OPEN_RATE_BY_SEQUENCE,
      dataKey: "email_opened_perc",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "email-clickthrough-rate",
      title: "Clickthrough Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Clickthrough Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_EMAIL_CLICK_THROUGH_RATE,
      dataKey: "email_clicked_perc",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "email-unsubscribe-rate",
      title: "Unsubscribe Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Unsubscribe Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_EMAIL_UNSUBSCRIBE_RATE,
      dataKey: "email_unsubscribed_perc",
      chartTheme: dangerBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "email-bounce-rate",
      title: "Bounce Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Bounce Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_EMAIL_BOUNCE_RATE,
      dataKey: "email_bounced_perc",
      chartTheme: dangerBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "email-reply-rate",
      title: "Reply Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Reply Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_EMAIL_REPLY_RATE,
      dataKey: "email_replied_perc",
      chartTheme: successBarColors,
      refetchOnGlobalFilterChange: false,
    },
  ],
  SMS: [
    {
      id: "sms-all-sms",
      title: "All SMS",
      legend: ["Delivery failure", "Delivered successfully"],
      legendPosition: "right",
      minChartWidth: 180,
      dataFetch: FETCH_ALL_SMS_METRICS,
      dataKey: "value",
      altDataKeys: ["sms_bounced_perc", "sms_delivered_perc"],
      metric: "Percent",
      dataKeyOverride: true,
      chartTheme: altPrimaryBarColors,
      refetchOnGlobalFilterChange: true,
    },
    {
      id: "sms-delivery-rate",
      title: "Delivery Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Delivery Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_SMS_DELIVERY_RATE,
      dataKey: "sms_delivered_perc",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "sms-failed-rate",
      title: "Failed Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Failed Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_SMS_FAILED_RATE,
      dataKey: "sms_bounced_perc",
      chartTheme: dangerBarColors,
      refetchOnGlobalFilterChange: false,
    },
  ],
  Dials: [
    {
      id: "dials-answer-rate",
      title: "Connect Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "Connect Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_ANSWER_RATE,
      dataKey: "answer_rate",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "dials-dm-connect-rate",
      title: "DM Connect Rate by Sequence",
      metric: "Percent",
      yAxisLabel: "DM Connect Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_DM_CONNECT_RATE,
      dataKey: "dm_connect_rate",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "dials-number-of-dials",
      title: "Number of Dials",
      yAxisLabel: "Number of Dials",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_NUMBER_OF_DIALS,
      dataKey: "dials",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "dials-voicemail-rate",
      title: "Voicemail Rate by Sequence",
      description: "Average % of time a call result ends in either 'Left Voicemail' or 'No Contact'",
      metric: "Percent",
      yAxisLabel: "Voicemail Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_VOICEMAIL_RATE,
      dataKey: "voicemail_or_no_contact_rate",
      chartTheme: warningBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "dials-bad-number",
      title: "Bad Number by Sequence",
      yAxisLabel: "Number of Bad Numbers",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_BAD_NUMBER_COUNT,
      dataKey: "bad_number_count",
      chartTheme: dangerBarColors,
      refetchOnGlobalFilterChange: false,
    },
  ],
  Tasks: [
    {
      id: "tasks-completion-rate",
      title: "Task Completion Rate by Sequence",
      yAxisLabel: "Completion Rate",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_TASK_COMPLETION_RATE,
      dataKey: "task_completion_rate",
      metric: "Percent",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
    },
    {
      id: "tasks-avg-speed",
      title: "Average Task Completion Speed by Sequence",
      yAxisLabel: "Time to Complete",
      xAxisLabel: "Sequences",
      dataFetch: FETCH_AVG_TASK_SPEED,
      dataKey: "avg_time_to_complete",
      chartTheme: primaryBarColors,
      refetchOnGlobalFilterChange: false,
      dataFormatter: (dataVal: any) => {
        // convert data from minutes -> hours -> days if value is large enough
        const data = cloneDeep(dataVal);

        const highestMinutes = data?.reduce((acc: any, cur: any) => {
          if (cur.avg_time_to_complete > acc.avg_time_to_complete) {
            return cur;
          }
          return acc;
        }).avg_time_to_complete;
        const hours = highestMinutes / 60;
        const days = hours / 24;

        data?.forEach((item: any) => {
          if (days >= 4) {
            item.avg_time_to_complete = item.avg_time_to_complete / 60 / 24;
            item.reduced = "Days";
          } else if (hours >= 16) {
            item.avg_time_to_complete = item.avg_time_to_complete / 60;
            item.reduced = "Hours";
          }
        });

        return data;
      },
      tooltipLabelFormatter: (payload: any) => (
        <AppText fontSize={10} lineHeight={14} color={theme.NEUTRAL300}>
          {payload?.[0]?.payload?.reduced || "Minutes"}
        </AppText>
      ),
    },
    {
      id: "tasks-snoozed-count",
      title: "Number of Tasks Snoozed by Sequence",
      yAxisLabel: "Tasks Snoozed",
      xAxisLabel: "Sequence",
      dataFetch: FETCH_SNOOZED_COUNT,
      dataKey: "snooze_count",
      chartTheme: warningBarColors,
      refetchOnGlobalFilterChange: false,
    },
  ],
};

/** Default local storage config for reporting page */
export const defaultSegments: Segment[] = [
  {
    type: "Attributed Revenue",
    charts: ["attributed-revenue-direct", "attributed-revenue-influenced"],
    selected_charts: ["attributed-revenue-direct", "attributed-revenue-influenced"],
  },
  {
    type: "Events",
    charts: ["events-number-of-sets", "events-scheduled-hold-rate"],
    selected_charts: ["events-number-of-sets", "events-scheduled-hold-rate"],
  },
  {
    type: "Velocity and Conversion",
    charts: ["velocity-and-conversion-avg-time-in-phase", "velocity-and-conversion-conversion-phase-to-phase"],
    selected_charts: ["velocity-and-conversion-avg-time-in-phase", "velocity-and-conversion-conversion-phase-to-phase"],
  },
  {
    type: "Email",
    charts: [
      "email-all-emails",
      "email-open-rate",
      "email-clickthrough-rate",
      "email-unsubscribe-rate",
      "email-bounce-rate",
      "email-reply-rate",
    ],
    selected_charts: [
      "email-all-emails",
      "email-open-rate",
      "email-clickthrough-rate",
      "email-unsubscribe-rate",
      "email-bounce-rate",
      "email-reply-rate",
    ],
  },
  {
    type: "SMS",
    charts: ["sms-all-sms", "sms-delivery-rate", "sms-failed-rate"],
    selected_charts: ["sms-all-sms", "sms-delivery-rate", "sms-failed-rate"],
  },
  {
    type: "Dials",
    charts: [
      "dials-answer-rate",
      "dials-dm-connect-rate",
      "dials-number-of-dials",
      "dials-voicemail-rate",
      "dials-bad-number",
    ],
    selected_charts: [
      "dials-answer-rate",
      "dials-dm-connect-rate",
      "dials-number-of-dials",
      "dials-voicemail-rate",
      "dials-bad-number",
    ],
  },
  {
    type: "Tasks",
    charts: ["tasks-completion-rate", "tasks-avg-speed", "tasks-snoozed-count"],
    selected_charts: ["tasks-completion-rate", "tasks-avg-speed", "tasks-snoozed-count"],
  },
];

export const getInitialCustomRange = () => {
  const initCustomRange = getLocalStorage("sequence_reporting_filters")?.customRange;
  return initCustomRange
    ? {
        upperbound_date: moment(initCustomRange.upperbound_date),
        lowerbound_date: moment(initCustomRange.lowerbound_date),
      }
    : {
        upperbound_date: moment(),
        lowerbound_date: moment(),
      };
};

export const defaultReportingView = {
  label: "Default View",
  value: {
    sequence_reporting_segments: JSON.stringify(defaultSegments),
    sequence_reporting_filters: JSON.stringify({
      sequencesFilter: false,
      selectedSequences: [],
      phasesFilter: false,
      selectedPhases: [],
      salesTeamsAndRepsFilter: false,
      selectedSalesTeamsAndReps: { roles: [], teams: [], reps: [], sites: [] },
      timeframe: sequenceReportingTimeframeOptions[0],
      customRange: getInitialCustomRange(),
      leaderboardMetric: leaderboardMetricOptions[0],
      leaderboardOrder: "rate-desc",
    }),
  },
  disableDeleteOption: true,
};
