import { AppErrorText } from "./";
import Select, { StylesConfig } from "react-select";
import styled from "styled-components";
import * as React from "react";
import { Formik, FormikProps, useField } from "formik";
import { theme } from "../../utils/theme";

export interface AppMultiSelectProps {
  name: string;
  isMulti?: boolean;
  options: any[];
  onChange?: any;
  value?: any;
  error?: any;
  maxMenuHeight?: number;
  maxHeight?: number;
  isDisabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  minHeight?: number;
  isClearable?: boolean;
  // [x: string]: any;
}

type MyOptionType = {
  label: string;
  value: string;
};

export const AppMultiSelect: React.FC<AppMultiSelectProps> = ({
  value,
  name,
  options,
  isMulti,
  onChange,
  error,
  maxHeight,
  minHeight,
  //isClearable added in as default true to prevent breaking changes
  isClearable = true,
  ...props
}) => {
  const customControlStyles: React.CSSProperties = {
    maxHeight: !!maxHeight ? `${maxHeight}px` : "300px",
    overflow: "auto",
    borderRadius: "2px",
    fontFamily: theme.PRIMARY_FONT,
    fontSize: "12px",
    letterSpacing: "0.2px",
    backgroundColor: theme.NEUTRAL100,
    minHeight: !!minHeight ? `${minHeight}px` : "32px",
  };

  const selectStyle: StylesConfig<MyOptionType, boolean> = {
    control: (provided, state) => {
      // provided has CSSObject type
      // state has ControlProps type

      // return type is CSSObject which means this line will throw error if uncommented
      // return false;

      return {
        ...provided,
        ...customControlStyles,
      };
    },
    option: (base, state) => ({
      ...base,
      fontFamily: theme.PRIMARY_FONT,
      fontSize: "12px",
      letterSpacing: "0.2px",
    }),
    container: (base, state) => ({
      ...base,
      // This might have been used to fix something, but it was also causing the select to render above the dropdown options of other multi selects
      // zIndex: 5,
    }),
  };

  return (
    <MultiSelectDiv>
      <Select
        isMulti={isMulti ?? true}
        style={{
          borderRadius: "2.9px",
        }}
        styles={selectStyle}
        // styles={props.customStyles}
        options={options}
        value={value}
        onChange={onChange}
        {...props}
        isClearable={isClearable}
      />
      {!!error && <AppErrorText>{error}</AppErrorText>}
    </MultiSelectDiv>
  );
};

const MultiSelectDiv = styled.div`
  margin-bottom: 32px;
`;
