import React, { createContext, FunctionComponent, useState, useMemo, useEffect, Dispatch, SetStateAction } from "react";

interface AutoDialContextState {
  // dateRange: IDateRange;
  dialTimer: number;
  setDialTimer: (timer: number) => void;
  dialTimerBaseValue: number;
  setDialTimerBaseValue: (baseTime: number) => void;
  pauseAutoDial: boolean;
  setPauseAutoDial: (pauseAutoDial: boolean) => void;
  pauseTimer: number;
  setPauseTimer: (pauseTimer: number) => void;
  resetTimers: () => void;
}

export const AutoDialContext = createContext<AutoDialContextState>({} as AutoDialContextState);

export const AutoDialContextProvider: FunctionComponent = ({ children }) => {
  // active countdown value
  const [dialTimer, setDialTimer] = useState(Number(localStorage.getItem("auto_dial_timer")) ?? 0);
  // original countdown value
  const [dialTimerBaseValue, setDialTimerBaseValue] = useState(Number(localStorage.getItem("auto_dial_base_value")) ?? 0);
  // Pause function for auto dial
  const [pauseAutoDial, setPauseAutoDial] = useState(localStorage.getItem("auto_dial_paused") === "true");
  const [pauseTimer, setPauseTimer] = useState(Number(localStorage.getItem("auto_dial_paused_timer")) ?? 0);

  useEffect(() => {
    localStorage.setItem("auto_dial_timer", `${dialTimer}` || "0");
  }, [dialTimer]);

  useEffect(() => {
    localStorage.setItem("auto_dial_base_value", `${dialTimerBaseValue}` || "0");
  }, [dialTimerBaseValue])

  useEffect(() => {
    localStorage.setItem("auto_dial_paused", `${pauseAutoDial}` || "false");
  }, [pauseAutoDial]);

  useEffect(() => {
    localStorage.setItem("auto_dial_paused_timer", `${pauseTimer}` || "0");
  }, [pauseTimer]);

  const resetTimers = () => {
    setDialTimer(0);
    setPauseAutoDial(false);
    setPauseTimer(0);
  };

  const memoizedValue = useMemo(
    () => ({
      dialTimer,
      setDialTimer,
      dialTimerBaseValue,
      setDialTimerBaseValue,
      pauseAutoDial,
      setPauseAutoDial,
      pauseTimer,
      setPauseTimer,
      resetTimers,
    }),
    [dialTimer, dialTimerBaseValue, pauseAutoDial, pauseTimer],
  );

  return <AutoDialContext.Provider value={memoizedValue}>{children}</AutoDialContext.Provider>;
};
