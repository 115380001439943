import styled from "styled-components";
import * as React from "react";
import { useContext, useRef, useState } from "react";
import { AppErrorText, AppMultiSelect, AppText } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import { PhoenixMultiSelectField } from "../Field/Phoenix/PhoenixMultiSelectField";
import Switch from "react-switch";
import { InputField, NewInputField, NewSelectField } from "../Field";
import { PhoenixAppButton, PhoenixInput } from "../UI/Phoenix";
import { ModalContext } from "../../context";
import { Loading } from "../UI";
import { NewAppSelect } from "../UI";
import { calculateProductPrice } from "../../utils/misc";

interface MyFormikProps {
  sale_id: string;
  sale_item_id: string;
  product_id: string;
  product_title: any;
  quantity: number | undefined;
  // null means no discount
  concession_id: string | null;
  contract_duration: string;
  payment_terms: number;
  prepayment: number;
  price_override: number | null | undefined;
  pricing: number;
  concession_amount: number;
}

const FETCH_INDIVIDUAL_SALE = gql`
  query FetchSaleDetail($sale_id: String!) {
    fetchSaleDetail(sale_id: $sale_id) {
      id
      contract_duration
      payment_terms
      prepayment
      sale_items {
        id
        price_override
        quantity
        prepayment
        product {
          id
          product_title
          pricing
        }
        concession {
          id
          amount
        }
      }
    }
  }
`;

const FETCH_PRODUCTS = gql`
  query fetchProducts {
    fetchProducts {
      id
      product_title
      pricing
      available_concessions {
        id
        label
        amount
      }
      quantity_lock
    }
  }
`;

const FETCH_ORG_OPTIONS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_payment_terms_sales
      show_contract_duration_sales
      prepayment
      default_prepayment_option
      default_payment_terms_option
      default_contract_duration
      prepayment_options
      payment_terms_options
      contract_duration_options
      require_sale_notes
      show_sale_flow_custom_fields
      mrr_required_on_sale
      mrr
    }
  }
`;

interface ProductInputType {
  concession_id: any;
  price_override: any;
  product_id: any;
  quantity: any;
}

const UPDATE_SALE_ITEM = gql`
  mutation EditProduct($saleId: String!, $saleItemId: String!, $product: ProductInputType!) {
    editProduct(sale_id: $saleId, sale_item_id: $saleItemId, product: $product) {
      message
      status
      status_boolean
    }
  }
`;

const ADD_SALE_ITEM = gql`
  mutation AddProduct($sale_id: String!, $product: ProductInputType!) {
    addProduct(sale_id: $sale_id, product: $product) {
      message
      status
      status_boolean
    }
  }
`;

const createDiscountOptions = (data: any, id: string) => {
  const product = data.filter((item: any) => item.id === id);
  if (!!!product[0]) {
    return [];
  }
  return [
    {
      label: "No Discount",
      value: null,
    },
  ].concat(
    product[0].available_concessions?.map((item: any) => {
      return {
        label: item.label,
        value: item.id,
      };
    }),
  );
};

const UpdateProductModal = () => {
  const { selectedSale, setSelectedSale, setShowUpdateProductModal } = useContext(ModalContext);

  const [showPriceOverride, setShowPriceOverride] = useState<boolean>(false);
  const { data: orgData, loading: orgLoading, error: orgError } = useQuery(FETCH_ORG_OPTIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("data", data);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data, loading, error } = useQuery(FETCH_INDIVIDUAL_SALE, {
    variables: { sale_id: selectedSale?.id },
    skip: !selectedSale?.id,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("all sales", data);

      const current_sale_item = data.fetchSaleDetail.sale_items.find(
        (item: any) => item.id === selectedSale?.sale_item_id,
      );

      if (!!current_sale_item?.price_override) {
        setShowPriceOverride(true);
      }
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { data: dataProducts, loading: loadingProducts, error: errorProducts } = useQuery(FETCH_PRODUCTS, {
    // variables: {
    //   orderBy: [{ amount: "asc" }],
    // },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted: (data) => {
      console.log("data", data);
    },
  });

  // add sale item
  const [addSaleItem, { loading: loadingAddSaleItem, error: errorAddSaleItem }] = useMutation(ADD_SALE_ITEM, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted: (data) => {
      console.log("data", data);
      setShowUpdateProductModal(false);
    },

    refetchQueries: ["fetchAllSales", "fetchSaleDetail", "FetchSaleDetail"],
  });

  // update sale item

  const [updateSaleItem, { loading: loadingUpdateSaleItem, error: errorUpdateSaleItem }] = useMutation(
    UPDATE_SALE_ITEM,
    {
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
      onCompleted: (data) => {
        console.log("data", data);

        setShowUpdateProductModal(false);
      },

      refetchQueries: ["fetchAllSales", "fetchSaleDetail", "FetchSaleDetail"],
    },
  );

  const UpdateProductSchema = Yup.object().shape({
    product_id: Yup.string().required("This field is required."),
    quantity: Yup.number()
      .required("This field is required.")
      .min(1, "Quantity must be at least 1")
      .max(999, "Quantity must be under 999")
      .typeError("Quantity must be a number")
      .integer("Quantity must be a whole number"),
    price_override: Yup.string().notRequired().nullable(),
    concession_id: Yup.string().notRequired().nullable(),
    concession_amount: Yup.string().notRequired(),
  });

  if (loading || loadingAddSaleItem || loadingProducts || loadingUpdateSaleItem) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Loading />
      </div>
    );
  }

  if (error) return <AppErrorText>Something Went Wrong</AppErrorText>;

  const currentProduct = data?.fetchSaleDetail?.sale_items?.find((item: any) => item.id === selectedSale?.sale_item_id);

  const addProduct = selectedSale?.product_update_modal_type === "add";

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={{
        // global
        sale_id: selectedSale?.id || "",
        // individual item
        sale_item_id: selectedSale?.sale_item_id || "",
        // selected product id for the item
        product_id: addProduct ? "" : currentProduct?.product?.id || "",
        product_title: addProduct ? "" : currentProduct?.product?.product_title || "",
        price_override: addProduct ? undefined : currentProduct?.price_override || undefined,
        quantity: addProduct ? undefined : currentProduct?.quantity_lock || currentProduct?.quantity || 0,
        concession_id: addProduct ? null : currentProduct?.concession[0]?.id || null,

        concession_amount: addProduct
          ? undefined
          : currentProduct?.concession?.reduce((acc: any, item: any) => acc + item.amount, 0) || 0,
        pricing: currentProduct?.product?.pricing || 0,

        // the following is not editable just for reference and will always be disabled
        contract_duration: data?.fetchSaleDetail?.contract_duration || "",
        payment_terms: data?.fetchSaleDetail?.payment_terms || "",
        prepayment: data?.fetchSaleDetail?.prepayment || "",
      }}
      validationSchema={UpdateProductSchema}
      onSubmit={async (values) => {
        if (selectedSale.product_update_modal_type === "add") {
          addSaleItem({
            variables: {
              sale_id: values.sale_id,
              product: {
                concession_id: values.concession_id || null,
                price_override: Number(values.price_override),
                product_id: values.product_id,
                quantity: Number(values.quantity),
              } as ProductInputType,
            },
          });
        } else {
          updateSaleItem({
            variables: {
              saleId: values.sale_id,
              saleItemId: values.sale_item_id,
              product: {
                concession_id: values.concession_id || null,
                price_override: Number(values.price_override),
                product_id: values.product_id,
                quantity: Number(values.quantity),
              } as ProductInputType,
            },
          });
        }
      }}
    >
      {({
        submitForm,
        values,
        setFieldValue,
        isValid,
        dirty,
        errors,
        initialValues,
        isSubmitting,
        resetForm,
        setFieldError,
      }: FormikProps<MyFormikProps>) => {
        console.log("values", values);
        console.log("errors", errors);

        console.log(
          "selected product",
          dataProducts?.fetchProducts?.find((product: any) => product.id === values.product_id),
        );

        console.log("selected concession", values.concession_id);

        return (
          <>
            <TitleDiv>
              <PopupTitle>
                {selectedSale?.product_update_modal_type === "add" ? "Add " : "Edit "}
                Product
              </PopupTitle>
            </TitleDiv>
            <ScrollingDiv>
              <Row>
                <NewAppSelect
                  isRequired
                  title="Product"
                  // name="product_id"
                  titleText="Product"
                  options={
                    dataProducts?.fetchProducts?.map((product: any) => {
                      return {
                        value: product.id,
                        label: `${product.product_title} ($${product.pricing})`,
                      };
                    }) || []
                  }
                  placeholder="Select Product"
                  onChange={(value: any) => {
                    const oldQuantity = values.quantity;
                    const oldOverride = values.price_override;

                    const selectedProduct = dataProducts?.fetchProducts?.find(
                      (product: any) => product.id === value.value,
                    );

                    resetForm({
                      values: {
                        ...initialValues,
                        product_id: value.value,
                        product_title: selectedProduct?.product_title,
                        concession_id: null,
                        price_override: oldOverride,
                        concession_amount: selectedProduct?.concession?.amount,
                        pricing: selectedProduct?.pricing,
                        quantity: selectedProduct?.quantity_lock ? selectedProduct?.quantity_lock : oldQuantity,
                      },
                    });
                  }}
                  value={values.product_id || ""}
                />
              </Row>{" "}
              <Row>
                <CustomPriceDiv>
                  <Switch
                    onChange={(checked: any) => {
                      setShowPriceOverride(!showPriceOverride);
                      if (showPriceOverride) {
                        setFieldValue("price_override", undefined);
                      }
                    }}
                    checked={showPriceOverride}
                    onColor={theme.PRIMARY500}
                    offColor={theme.NEUTRAL200}
                    height={16}
                    width={32}
                    handleDiameter={12}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                  <AppText fontWeight={500} fontSize={12}>
                    Override Product Price?
                  </AppText>
                </CustomPriceDiv>
              </Row>
              {showPriceOverride && (
                <Row>
                  <PhoenixInputField
                    name="price_override"
                    placeholder="Custom Price"
                    noTopMargin
                    width={150}
                    type="number"
                    inputValueType="currency"
                  />
                </Row>
              )}
              <Row>
                <div>
                  <PhoenixInputField
                    requiredStar
                    titleText="Quantity"
                    name="quantity"
                    placeholder="Qty."
                    textAlign={"left"}
                    inputValueType="number"
                    disabled={
                      dataProducts?.fetchProducts?.find((product: any) => product?.id === values.product_id)
                        ?.quantity_lock === null
                        ? false
                        : true
                    }
                    type="number"
                  />
                </div>
              </Row>
              <Row>
                <NewAppSelect
                  title="Discount"
                  // titleText="Discount"
                  options={createDiscountOptions(dataProducts?.fetchProducts, values.product_id)}
                  value={values.concession_id}
                  onChange={(value: any) => {
                    setFieldValue("concession_id", value.value);
                    setFieldValue(
                      "concession_amount",
                      dataProducts?.fetchProducts
                        ?.find((product: any) => product.id === values.product_id)
                        ?.available_concessions?.find((concession: any) => concession.id === value.value)?.amount,
                    );
                  }}
                />
              </Row>
              {orgData?.fetchOrganization?.show_contract_duration_sales && (
                <Row>
                  <NewAppSelect
                    title="Contract Duration"
                    isDisabled
                    hideBlueIndicator
                    disabledText
                    options={[
                      {
                        value: data?.fetchSaleDetail?.contract_duration,
                        label: data?.fetchSaleDetail?.contract_duration,
                      },
                    ]}
                    value={data?.fetchSaleDetail?.contract_duration}
                  />
                </Row>
              )}
              {orgData?.fetchOrganization?.show_payment_terms_sales && (
                <Row>
                  <NewAppSelect
                    title="Payment Terms"
                    isDisabled
                    disabledText
                    hideBlueIndicator
                    options={[
                      {
                        value: data?.fetchSaleDetail?.payment_terms,
                        label: data?.fetchSaleDetail?.payment_terms,
                      },
                    ]}
                    value={data?.fetchSaleDetail?.payment_terms}
                  />
                </Row>
              )}
              {orgData?.fetchOrganization?.prepayment && (
                <Row>
                  <NewAppSelect
                    hideBlueIndicator
                    disabledText
                    title="Prepayment"
                    isDisabled
                    options={[
                      {
                        value: !!currentProduct?.prepayment
                          ? currentProduct?.prepayment
                          : data?.fetchSaleDetail?.prepayment,
                        label: !!currentProduct?.prepayment
                          ? currentProduct?.prepayment
                          : data?.fetchSaleDetail?.prepayment,
                      },
                    ]}
                    value={
                      !!currentProduct?.prepayment ? currentProduct?.prepayment : data?.fetchSaleDetail?.prepayment
                    }
                  />
                </Row>
              )}
              <Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <AppText>Total Cost:</AppText>
                  <AppText>
                    {calculateProductPrice({
                      price_override: values.price_override,
                      pricing: values.pricing,
                      quantity: values.quantity,
                      concession_amount: values.concession_amount,
                    })?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }) || "0"}
                  </AppText>
                </div>
              </Row>
            </ScrollingDiv>
            <SubmitDiv>
              <PhoenixAppButton
                buttonType="secondary"
                variant="danger-outline"
                onClick={() => {
                  setShowUpdateProductModal(false);
                }}
              >
                Cancel
              </PhoenixAppButton>
              <PhoenixAppButton
                buttonType="secondary"
                variant="brand"
                onClick={() => {
                  submitForm();
                }}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !values.product_id ||
                  calculateProductPrice({
                    price_override: values.price_override,
                    pricing: values.pricing,
                    quantity: values.quantity,
                    concession_amount: values.concession_amount,
                  }) <= 0
                }
              >
                Save Changes
              </PhoenixAppButton>
            </SubmitDiv>
          </>
        );
      }}
    </Formik>
  );
};
const Row = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;
interface CustomHeightProps {
  customHeight?: boolean;
}

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;
  /* bottom: ${(props) => (props.customHeight ? "0px" : "20px")}; */
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  /* margin-bottom: 32px; */
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 90px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
`;
const CustomPriceDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export { UpdateProductModal };
