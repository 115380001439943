import styled from "styled-components";
import * as React from "react";
import { useState } from "react";
import { AppText, AppErrorText, FlexDiv, GhostField, GradientButton, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Sentry from "@sentry/react";
import { useLocation, useHistory } from "react-router-dom";
import { appToast } from "../../utils/toast";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { cloneDeep } from "lodash";
import { logout } from "../../utils/auth";
import { mobileSize } from "src/utils/breakpoints";
import { ReactComponent as SellfireLogo } from "src/images/NewDesign/sellfire_logo_horizontal.svg";

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword($password: String!, $token: String!) {
    newPassword(password: $password, token: $token)
  }
`;

interface MyFormikProps {
  isAdmin: boolean;
  newPassword: string;
  confirmNewPassword: string;
  showPassword: boolean;
}

interface ParsedToken {
  user: {
    id?: string;
    email?: string;
    role?: string;
    organization_id?: string;
  };
}

// needed to extend the built-in errors type so we can check all errors at once instead of a waterfall check
interface CustomFormikErrors {
  newPassword: {
    required?: string;
    min?: string;
    uppercase?: string;
    lowercase?: string;
    number?: string;
    special?: string;
    spaces?: string;
    weakPassword?: string;
  };
  confirmNewPassword: {
    required?: string;
    match?: string;
  };
}

const DEFAULT_ERROR_STATE = {
  newPassword: {
    required: undefined,
    min: undefined,
    uppercase: undefined,
    lowercase: undefined,
    number: undefined,
    special: undefined,
    spaces: undefined,
    weakPassword: undefined,
  },
  confirmNewPassword: {
    required: undefined,
    match: undefined,
  },
};

interface ExtendedFormikProps
  // extend the built-in ones with our custom error type
  extends Omit<FormikProps<MyFormikProps>, "errors"> {
  errors: CustomFormikErrors;
}

const UPPER_CASE_REGEX = /[A-Z]/;
const LOWER_CASE_REGEX = /[a-z]/;
const NUMBER_REGEX = /[0-9]/;
const SPECIAL_CHARACTER_REGEX = /[!@#$%^&*)(]/;
const WEAK_PASSWORDS = ["password", "admin", "sudo", "OPSIQ", "sellfire"];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function parseJwt(token: string) {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
}

const StatusIcon = ({ requirementMet = false }: { requirementMet: boolean }) => {
  return requirementMet ? (
    <AiOutlineCheck size={20} color={theme.PRIMARY600} />
  ) : (
    <AiOutlineClose size={20} color={theme.ATTENTION700} />
  );
};

const ResetPassword: React.FC = () => {
  const [passwordUpdateProgress, setPasswordUpdateProgress] = useState<"success" | "failure" | undefined>(undefined);
  const token: string | null = useQuery().get("token");
  const parsedToken: ParsedToken = parseJwt(token ?? "");
  if (parsedToken?.user?.email) {
    WEAK_PASSWORDS.push(parsedToken.user.email);
  }
  const userRole = parsedToken.user?.role;
  const userIsAdmin = userRole !== "SDR" && userRole !== "AE";

  const history = useHistory();

  const [updatePassword, { loading: loadingUpdatePassword, error: errorUpdatePassword }] = useMutation(
    SET_NEW_PASSWORD,
    {
      onCompleted({ newPassword }) {
        if (!newPassword) {
          appToast("Sorry! Something went wrong. Please try again.");
          setPasswordUpdateProgress("failure");
          return;
        }

        appToast("Success! You may now login with your new password.");
        setPasswordUpdateProgress("success");
        logout();
        history.push(`/login`);
      },
      onError({ message }) {
        console.log("Error changing password: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `newPassword GraphQL Error: ${message}`,
        });
        setPasswordUpdateProgress("failure");
      },
    },
  );

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Reset Password page"}>
      <Main>
        <LogoWrapper onClick={() => history.push("/login")}>
          <SellfireLogo />
        </LogoWrapper>

        <Formik
          validateOnMount
          initialValues={{
            isAdmin: userIsAdmin,
            newPassword: "",
            confirmNewPassword: "",
            showPassword: false,
          }}
          validateOnBlur
          validateOnChange
          validate={(values) => {
            /* unfortunately we can't use the normal formik validation schema.
          
          This is because we have to check all errors at once. NOT a waterfall check

         we show the user the checks or x's for all requirments in real time.

          if we use the formik schema, it will stop at the first error and not show the user the rest of the errors

          */

            // error state resets on every validation check to prevent stale errors or errors that have been fixed from showing
            const errors: CustomFormikErrors = cloneDeep(DEFAULT_ERROR_STATE);

            // required
            if (!values.newPassword) {
              errors.newPassword.required = "Please enter a password.";
            }

            // min length

            const minPasswordLength = values.isAdmin ? 15 : 12;
            if (values.newPassword.length < minPasswordLength) {
              errors.newPassword.min = `Password must be at least ${minPasswordLength} characters.`;
            }

            // uppercase
            if (!UPPER_CASE_REGEX.test(values.newPassword)) {
              errors.newPassword.uppercase = "Password must contain at least one uppercase letter.";
            }

            // lowercase

            if (!LOWER_CASE_REGEX.test(values.newPassword)) {
              console.log("lowercase error: ", values.newPassword);
              errors.newPassword.lowercase = "Password must contain at least one lowercase letter.";
            }

            // number

            if (!NUMBER_REGEX.test(values.newPassword)) {
              errors.newPassword.number = "Password must contain at least one number.";
            }

            // special

            if (!SPECIAL_CHARACTER_REGEX.test(values.newPassword)) {
              errors.newPassword.special = "Password must contain at least one special character.";
            }

            // no spaces
            if (values.newPassword.includes(" ")) {
              errors.newPassword.spaces = "Password must not contain spaces.";
            }

            // weak

            if (
              WEAK_PASSWORDS.some((weakPassword) =>
                values?.newPassword?.toLowerCase().includes(weakPassword.toLowerCase()),
              )
            ) {
              errors.newPassword.weakPassword =
                "This password contains easily guessed elements. Please update it to be harder to guess";
            }

            // confirmNewPassword

            // required
            if (!values.confirmNewPassword) {
              errors.confirmNewPassword.required = "Please confirm your password.";
            }

            // match

            if (values.newPassword && values.confirmNewPassword && values.newPassword !== values.confirmNewPassword) {
              errors.confirmNewPassword.match = "Passwords do not match!";
            }

            // if we have no errors, return an empty object so that `isValid` is true
            if (
              Object.values(errors.newPassword).every((error) => error === undefined) &&
              Object.values(errors.confirmNewPassword).every((error) => error === undefined)
            ) {
              return {};
            }

            return errors;
          }}
          onSubmit={({ newPassword, confirmNewPassword }) => {
            if (newPassword !== confirmNewPassword) {
              appToast("Passwords do not match!");
              return;
            }
            updatePassword({ variables: { password: newPassword, token: token } });
          }}
        >
          {({ submitForm, isValid, errors, values, setFieldValue }: ExtendedFormikProps) => {
            return (
              <FormContainer>
                {loadingUpdatePassword ? (
                  <Loading />
                ) : errorUpdatePassword ? (
                  <AppErrorText>{errorUpdatePassword?.message}</AppErrorText>
                ) : (
                  <>
                    {!passwordUpdateProgress ? (
                      <>
                        <FlexDiv direction="column" align="center" gap={20}>
                          <Title>Change your password</Title>
                          <Subtitle>Enter a new password below.</Subtitle>
                        </FlexDiv>

                        <FlexDiv direction="column" gap={40} width="100%" align="center">
                          <FlexDiv direction="column" gap={24} width="100%">
                            <GhostField label="New Password" placeholder="****" name="newPassword" type="password" />
                            <GhostField
                              label="Re-enter New Password"
                              placeholder="****"
                              name="confirmNewPassword"
                              type="password"
                            />
                          </FlexDiv>

                          <GradientButton
                            onClick={(e) => {
                              e.preventDefault();
                              console.log("values: ", values);
                              console.log("errors: ", errors);
                              submitForm();
                            }}
                            disabled={!isValid || loadingUpdatePassword}
                          >
                            Continue
                          </GradientButton>
                        </FlexDiv>
                      </>
                    ) : null}

                    {passwordUpdateProgress === "failure" && (
                      <AppErrorText>{"Sorry! Something went wrong. Please try again."}</AppErrorText>
                    )}

                    {passwordUpdateProgress === "success" && (
                      <>
                        <FlexDiv direction="column" align="center" gap={20}>
                          <Title>Password updated!</Title>
                          <Subtitle>Your password has been changed successfully.</Subtitle>
                        </FlexDiv>

                        <GradientButton onClick={() => history.push("/login")}>Back to Login</GradientButton>
                      </>
                    )}
                  </>
                )}
              </FormContainer>
            );
          }}
        </Formik>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const Main = styled.div`
  align-items: center;
  background-image: url(${require("src/images/login-bg.jpeg")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow-y: auto;
  padding: 24px;
  width: 100%;

  @media ${mobileSize} {
    background-image: url(${require("src/images/login-bg-mobile.jpeg")});
  }
`;

const FormContainer = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  max-width: 560px;
  width: 100%;
`;

const LogoWrapper = styled.div`
  cursor: pointer;
  left: 80px;
  position: absolute;
  top: 40px;

  svg {
    height: 40px;
    width: 190px;
  }

  @media ${mobileSize} {
    left: 24px;
    top: 16px;

    svg {
      height: 30px;
      width: 150px;
    }
  }
`;

const Title = styled(AppText)`
  color: ${theme.WHITE_COLOR};
  font-size: 48px;
  font-weight: 500;
  text-align: center;

  @media ${mobileSize} {
    font-size: 30px;
  }
`;

const Subtitle = styled(AppText)`
  color: ${theme.WHITE_COLOR};
  font-size: 20px;
  text-align: center;

  span {
    color: #8fc9ff;
  }

  @media ${mobileSize} {
    font-size: 16px;
  }
`;

export { ResetPassword };
