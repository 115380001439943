import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixRadio, PhoenixTextArea } from "src/Components/UI/Phoenix";
import { useDebounce } from "src/utils/hooks";
import { AppText } from "src/Components/UI";

interface NoteFieldProps {
  id: string;
  content: string;
  type: "warning" | "link" | "default";
  onInputChange: (id: string, newContent: string) => void;
  onNoteStyleChange: (id: string, newStyle: "default" | "warning" | "link") => void;
}

export const NoteField: React.FC<NoteFieldProps> = ({ id, content, type, onInputChange, onNoteStyleChange }) => {
  const [style, setStyle] = useState<"default" | "warning" | "link">(type);
  const [noteContent, setNoteContent] = useState(content);

  useEffect(() => {
    onNoteStyleChange(id, style);
  }, [style]);

  useDebounce(
    () => {
      onInputChange(id, noteContent);
    },
    [noteContent],
    500,
  );

  return (
    <NoteFieldContent>
      <FlexDiv direction="column" gap={16}>
        <FieldText>Note Style</FieldText>
        <FlexDiv align="center" gap={16}>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio selected={style === "default"} onClick={() => setStyle("default")} />
            <FieldText>Default</FieldText>
          </FlexDiv>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio selected={style === "warning"} onClick={() => setStyle("warning")} />
            <FieldText>Warning</FieldText>
          </FlexDiv>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio selected={style === "link"} onClick={() => setStyle("link")} />
            <FieldText>Link</FieldText>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>

      <FlexDiv direction="column" gap={8}>
        <FieldText>Note Content</FieldText>
        <PhoenixTextArea
          value={noteContent}
          width={376}
          height={92}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNoteContent(e.target.value)}
          style={{ color: style === "warning" ? theme.PILL_DARK_RED : theme.BLACK_COLOR }}
          fixedSize
          hideError
        />
      </FlexDiv>
    </NoteFieldContent>
  );
};

const NoteFieldContent = styled(FlexDiv)`
  flex-direction: column;
  gap: 24px;
`;

const FieldText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
