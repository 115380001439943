import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useEffect, useContext, useMemo } from "react";
import { AppInput, AppText, AppSidebarCard, Loading, AppErrorText, ReactDatesWrapper } from "../../UI";
import { theme } from "../../../utils/theme";
import { FETCH_SALES_IMPROVEMENTS, ProgressGraph } from "..";
import { useMutation, useQuery, gql } from "@apollo/client";
import { formatCellData, formatUSD, transformColumnLabel } from "../../../utils/format";
import { AiFillCaretDown, AiFillCaretUp, AiOutlineInfoCircle, AiFillInfoCircle, AiFillWarning } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { FETCH_30_DAY_GRAPH_DATA, FETCH_PERFORMANCE_CHART, STACK_RANK_LIST } from "./SimpleGrid";
import { GridFilterContext, CallContext, LiveUserStatusContext } from "../../../context/";
import moment from "moment";
import Switch from "react-switch";
import {
  iconArrow,
  iconPhone,
  iconVideo,
  iconColdCall,
  iconInboundCall,
  iconDemoCall,
  iconDecisionCall,
  iconPhoneCallback,
} from "../../../images/";
import { useHistory } from "react-router-dom";
import { loggedInUser } from "../../../apollo/cache";
import { CallReportListV1 } from "../CallReportListV1";
import {
  environment,
  FETCH_USER_INFO_DASHBOARD_POLL_INTERVAL,
  isProduction,
  MANAGER_GRID_PERSIST_KEY,
} from "../../../utils/variables";
import { AppSelect } from "../../UI/AppSelect";
import { EventsListTable } from "./EventsListTable";
import { PipelineListTable } from "./PipelineListTable";
import { ManageGridColumnsModal, ManageTableColumnsModal } from "../../modal";
import { MixpanelActions } from "./../../../services/mixpanel";
import { DateRangePicker } from "react-dates";
import { OutsideEvent } from "../../Dumb";
import { sanitizeURL } from "../../../utils/misc";

const positionDummyText = { number: "20%", name: "Initial Hold Rate", output: "78%" };

const dateRangeOptions = [
  { start: moment().startOf("day").format(), end: moment().endOf("day").format(), label: "Today", value: "Today" },
  {
    start: moment().subtract(24, "hours").startOf("day").format(),
    end: moment().subtract(24, "hours").endOf("day").format(),
    label: "Yesterday",
    value: "Yesterday",
  },
  {
    start: moment().startOf("week").format(),
    end: moment().endOf("week").format(),
    label: "This Week",
    value: "ThisWeek",
  },
  {
    start: moment().subtract(1, "week").startOf("week").format(),
    end: moment().subtract(1, "week").endOf("week").format(),
    label: "Last Week",
    value: "LastWeek",
  },
  {
    start: moment().startOf("month").format(),
    end: moment().endOf("day").format(),
    label: "Month to Date",
    value: "MonthToDate",
  },
  {
    start: moment().subtract(1, "months").startOf("month").format(),
    end: moment().subtract(1, "months").endOf("month").format(),
    label: "Last Month",
    value: "LastMonth",
  },
  {
    start: moment().startOf("year").format(),
    end: moment().endOf("day").format(),
    label: "Year to Date",
    value: "YearToDate",
  },
  {
    start: moment().subtract(1, "years").startOf("year").format(),
    end: moment().subtract(1, "years").endOf("year").format(),
    label: "Last Year",
    value: "LastYear",
  },
  {
    start: moment().subtract(1, "months").startOf("day").format(),
    end: moment().endOf("day").format(),
    label: "Custom Range",
    value: "CustomRange",
  },
];

const FETCH_USER_INFO = gql`
  query fetchUserInfo($user_id: String) {
    fetchUser(user_id: $user_id) {
      id
      first_name
      last_name
      lead_source_filter
      industry_filter
      lead_source_white_list_array
      industry_white_list_array
      leads_in_queue
    }
  }
`;

const FETCH_REP_PREVIEW = gql`
  query fetchRepPreview($user_id: String!) {
    fetchRepPreview(user_id: $user_id) {
      AssignedLead
      OwnedLead
      TotalLead
      CCDials
      FollowUpDials
      GeneralCallBackDials
      UpcomingDials
      ScheduledCallBacks
      Demos
      DecisionCalls
      AllScheduledEvents
    }
  }
`;

const UserIcons = ({ user_id }: { user_id?: string }) => {
  const { data, loading, error } = useQuery(FETCH_USER_INFO, {
    fetchPolicy: "network-only",
    variables: { user_id },
    pollInterval: FETCH_USER_INFO_DASHBOARD_POLL_INTERVAL,
    skip: !user_id,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataRep, loading: loadingRep, error: errorRep } = useQuery(FETCH_REP_PREVIEW, {
    fetchPolicy: "network-only",
    variables: { user_id },
    pollInterval: FETCH_USER_INFO_DASHBOARD_POLL_INTERVAL,
    skip: !user_id,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data && data.fetchUser, dataRep && dataRep.fetchRepPreview]);
  if (!user_id) {
    return null;
  }
  if (loading || loadingRep) {
    return (
      <div
        style={{
          marginTop: "5px",
          marginRight: "5px",
          maxHeight: "10px",
          maxWidth: "25px",
          transform: "scale(0.5)",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (error || !data || errorRep) {
    return null;
  }
  // console.log("new data: ", dataRep);
  // console.log("data: ", data);
  let industries = "";
  let lead_sources = "";
  if (data?.fetchUser.industry_filter) {
    industries = data?.fetchUser?.industry_white_list_array.join(", ");
  }
  if (data?.fetchUser.lead_source_filter) {
    lead_sources = data?.fetchUser?.lead_source_white_list_array.join(", ");
  }
  const filters = data?.fetchUser.industry_filter || data?.fetchUser.lead_source_filter;
  const leads_in_queue = data?.fetchUser?.leads_in_queue || 0;
  const name = `${data?.fetchUser?.first_name} ${data?.fetchUser?.last_name}`;
  let main_text = ``;
  if (leads_in_queue > 10) {
    // main_text = `${name} has ${leads_in_queue} leads left in their queue.`;
    main_text = ``;
  } else if (leads_in_queue > 0) {
    main_text = `Warning! ${name} only has ${leads_in_queue} leads left in their queue.`;
  } else {
    main_text = `Warning! ${name} is out of leads!`;
  }
  return (
    <UserIconsDiv>
      {leads_in_queue > 10 ? (
        <AiOutlineInfoCircle data-for={`${user_id}-tooltip`} data-tip="Test" size={14} color={theme.BLACK_COLOR} />
      ) : leads_in_queue > 0 ? (
        <AiFillWarning data-for={`${user_id}-tooltip`} data-tip="Test" size={14} color={theme.TERTIARY500} />
      ) : (
        <AiOutlineInfoCircle data-for={`${user_id}-tooltip`} data-tip="Test" size={14} color={theme.ATTENTION700} />
      )}
      <StyledTooltip
        id={`${user_id}-tooltip`}
        multiline
        place="top"
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      >
        <FilterTextContainer>
          {/* {!!main_text && (
            <ShortLineHeightP style={{ fontWeight: 600, fontSize: "14px" }}>{main_text}</ShortLineHeightP>
          )}
          {!!main_text && !!dataRep && !!dataRep.fetchRepPreview && <br />} */}
          {!!dataRep && !!dataRep.fetchRepPreview && (
            <>
              <ShortLineHeightP>
                <b>Leads</b>
              </ShortLineHeightP>
              <ShortLineHeightP>Assigned: {dataRep.fetchRepPreview.AssignedLead}</ShortLineHeightP>
              <ShortLineHeightP>Owned: {dataRep.fetchRepPreview.OwnedLead}</ShortLineHeightP>
              <ShortLineHeightP>All Leads: {dataRep.fetchRepPreview.TotalLead}</ShortLineHeightP>
              <ShortLineHeightP>
                <br />
              </ShortLineHeightP>
              <ShortLineHeightP>
                <b>Upcoming Dials</b>
              </ShortLineHeightP>
              <ShortLineHeightP>Cold Calls: {dataRep.fetchRepPreview.CCDials}</ShortLineHeightP>
              <ShortLineHeightP>Call Follow-ups: {dataRep.fetchRepPreview.FollowUpDials}</ShortLineHeightP>
              <ShortLineHeightP>General Call Backs: {dataRep.fetchRepPreview.GeneralCallBackDials}</ShortLineHeightP>
              <ShortLineHeightP>All Upcoming Dials: {dataRep.fetchRepPreview.UpcomingDials}</ShortLineHeightP>
              <ShortLineHeightP>
                <br />
              </ShortLineHeightP>
              <ShortLineHeightP>
                <b>Scheduled Events</b>
              </ShortLineHeightP>
              <ShortLineHeightP>Scheduled Call Backs: {dataRep.fetchRepPreview.ScheduledCallBacks}</ShortLineHeightP>
              <ShortLineHeightP>Demos: {dataRep.fetchRepPreview.Demos}</ShortLineHeightP>
              <ShortLineHeightP>Decision Calls: {dataRep.fetchRepPreview.DecisionCalls}</ShortLineHeightP>
              <ShortLineHeightP>All Scheduled Events: {dataRep.fetchRepPreview.AllScheduledEvents}</ShortLineHeightP>
            </>
          )}
          {filters && !!dataRep && !!dataRep.fetchRepPreview && (
            <ShortLineHeightP>
              <br />
            </ShortLineHeightP>
          )}
          {filters && (
            <ShortLineHeightP style={{ fontSize: "10px" }}>
              Note: This user has the following filters applied to their lead pool:
            </ShortLineHeightP>
          )}
          {data?.fetchUser?.industry_filter && (
            <ShortLineHeightP style={{ fontSize: "10px" }}>Industry: {industries}</ShortLineHeightP>
          )}
          {data?.fetchUser?.lead_source_filter && (
            <ShortLineHeightP style={{ fontSize: "10px" }}>Lead Source: {lead_sources}</ShortLineHeightP>
          )}
        </FilterTextContainer>
      </StyledTooltip>
    </UserIconsDiv>
  );
};

interface IPerformanceChartData {
  id: string;
  computed_id?: string;
  row_label: string;
  metric: string;
  metric_label: string;
  metric_type: string;
  metric_tooltip: string;
  value: number;
  position?: string;
  rank?: number;
  team_id?: string;
  user_id?: string;
  lowerbound_date?: string;
  upperbound_date?: string;
}

const gridDefaultState = {
  sortTableAscending: true,
  selectedCell: ["", ""],
  selectedStack: ["", ""],
  showTeams: loggedInUser().role === "SM" ? true : false,
  teamIdStackRank: "",
  userIdStackRank: "",
  teamId: loggedInUser().role === "SM" ? loggedInUser().team_id : "",
  filterTeam: loggedInUser().role === "SM" ? loggedInUser().team_id : "",
  filterUser: "",
  userID: "",
  teamID: "",
  orgID: "",
  coachingReportView: false,
  metric: "",
  indexOfGrid: -1,
  tableState: loggedInUser().role === "SM" ? "team-detail" : "org",
  currentItemData: {} as any,
};

const calculateTimeDifference = (currentTime: any, updated_at: any) => {
  if (!currentTime) {
    return ``;
  }
  const minSec = moment.utc(moment(currentTime).diff(moment(updated_at))).format("mm:ss");
  const hours = moment(currentTime).diff(updated_at, "hours");
  if (!!hours) {
    return `${hours}:${minSec}`;
  }
  return `${minSec}`;
};

const ManagerGrid: React.FC = () => {
  const [sortTableAscending, setSortTableAscending] = useState(gridDefaultState.sortTableAscending);
  const [selectedCell, setSelectedCell] = useState(gridDefaultState.selectedCell);
  const [selectedStack, setSelectedStack] = useState(gridDefaultState.selectedStack);

  const [showTeams, setShowTeams] = useState(gridDefaultState.showTeams);
  const [teamIdStackRank, setTeamIdStackRank] = useState(gridDefaultState.teamIdStackRank);
  const [userIdStackRank, setUserIdStackRank] = useState(gridDefaultState.userIdStackRank);
  const [teamId, setTeamId] = useState(gridDefaultState.teamId);

  const [filterTeam, setFilterTeam] = useState(gridDefaultState.filterTeam);
  const [filterUser, setFilterUser] = useState(gridDefaultState.filterUser);
  const [userID, setUserID] = useState(gridDefaultState.userID);
  const [teamID, setTeamID] = useState(gridDefaultState.teamID);
  const [orgID, setOrgID] = useState(gridDefaultState.orgID);
  const [coachingReportView, setCoachingReportView] = useState(gridDefaultState.coachingReportView);

  const [metric, setMetric] = useState(gridDefaultState.metric);
  const [indexOfGrid, setIndexOfGrid] = useState(gridDefaultState.indexOfGrid);
  const [tableState, setTableState] = useState(gridDefaultState.tableState);
  const [currentTime, setCurrentTime] = useState(moment().format());

  const [currentItemData, setCurrentItemData] = useState(gridDefaultState.currentItemData);
  const [initialRenderBool, setInitialRenderBool] = useState(true);
  const [useNewVersion, setUseNewVersion] = useState(false);

  // For react-dates
  const [focusedInput, setFocusedInput] = useState(null as any);

  // set persist state
  useEffect(() => {
    const persistedStateRaw = localStorage.getItem(MANAGER_GRID_PERSIST_KEY);
    if (!persistedStateRaw) {
      return;
    }
    const persistedState = JSON.parse(persistedStateRaw);
    if (!persistedState) {
      return;
    }
    setSortTableAscending(persistedState.sortTableAscending || gridDefaultState.sortTableAscending);
    setSelectedCell(persistedState.selectedCell ?? gridDefaultState.selectedCell);
    setSelectedStack(persistedState.selectedStack ?? gridDefaultState.selectedStack);
    setShowTeams(persistedState.showTeams ?? gridDefaultState.showTeams);
    setTeamIdStackRank(persistedState.teamIdStackRank ?? gridDefaultState.teamIdStackRank);
    setUserIdStackRank(persistedState.userIdStackRank ?? gridDefaultState.userIdStackRank);
    setTeamId(persistedState.teamId ?? gridDefaultState.teamId);
    setFilterTeam(persistedState.filterTeam ?? gridDefaultState.filterTeam);
    setFilterUser(persistedState.filterUser ?? gridDefaultState.filterUser);
    setUserID(persistedState.userID ?? gridDefaultState.userID);
    setTeamID(persistedState.teamID ?? gridDefaultState.teamID);
    setOrgID(persistedState.orgID ?? gridDefaultState.orgID);
    setCoachingReportView(persistedState.coachingReportView ?? gridDefaultState.coachingReportView);
    setMetric(persistedState.metric ?? gridDefaultState.metric);
    setIndexOfGrid(persistedState.indexOfGrid ?? gridDefaultState.indexOfGrid);
    setTableState(persistedState.tableState ?? gridDefaultState.tableState);
    setCurrentItemData(persistedState.currentItemData ?? gridDefaultState.currentItemData);
  }, []);

  // persist settings when a change occurs
  useEffect(() => {
    const table_properties = {
      sortTableAscending,
      selectedCell,
      selectedStack,
      showTeams,
      teamIdStackRank,
      userIdStackRank,
      teamId,
      filterTeam,
      filterUser,
      userID,
      teamID,
      orgID,
      coachingReportView,
      metric,
      indexOfGrid,
      tableState,
      currentItemData,
    };
    localStorage.setItem(MANAGER_GRID_PERSIST_KEY, JSON.stringify(table_properties));
  }, [
    sortTableAscending,
    selectedCell,
    selectedStack,
    showTeams,
    teamIdStackRank,
    userIdStackRank,
    teamId,
    filterTeam,
    filterUser,
    userID,
    teamID,
    orgID,
    coachingReportView,
    metric,
    indexOfGrid,
    tableState,
    currentItemData,
  ]);

  const history = useHistory();
  const { liveUserStatus: userStatusData } = useContext(LiveUserStatusContext);

  const {
    dateStart,
    dateEnd,
    channel,
    products,
    tableView,
    setTableView,
    dateLabel,
    setDateStart,
    setDateEnd,
    setDateLabel,
    filterSidebarExpanded,
    gridFilter,
    gridModal,
    setGridModal,
    tableModal,
    setTableModal,
  } = useContext(GridFilterContext);

  // useEffect(() => {
  //   subscribeToMore({
  //     document: LIVE_USER_STATUS_SUBSCRIPTION,
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData?.data?.userStatus?.length) return prev;
  //       return Object.assign({}, prev, {
  //         fetchLiveUserStatus: subscriptionData.data.userStatus,
  //       });
  //     },
  //   });
  // }, []);

  const { data: dataTable, loading: loadingTable, error: errorTable, refetch: refetchTable } = useQuery(
    FETCH_PERFORMANCE_CHART,
    {
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      variables: {
        // product_ids_filter: products,
        // channel_filter: channel,
        coaching_view: tableView === "Coaching",
        lowerbound_date:
          dateStart === "Invalid Date" ? undefined : !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
        upperbound_date: dateEnd === "Invalid Date" ? undefined : !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
        dashboard_type: tableView,
        show_teams: showTeams,
        team_id_stack_rank: teamIdStackRank,
        user_id_stack_rank: userIdStackRank,
        team_id: teamId,
        new_version: useNewVersion,
        dashboard_filter: {
          Channels: gridFilter.channels,
          LeadSources: gridFilter.lead_sources,
          LeadCreationSources: gridFilter.lead_creation_sources,
          primary_industries: gridFilter.industries,
          sub_industries: gridFilter.sub_industries,
        },
      },
    },
  );

  const {
    data: dataImprovement,
    loading: loadingImprovement,
    error: errorImprovement,
    refetch: refetchImprovement,
  } = useQuery(FETCH_SALES_IMPROVEMENTS, {
    variables: {
      level:
        tableState === "team-detail" || tableState === "team-rank"
          ? "Team"
          : tableState === "user-rank"
          ? "User"
          : "Organization",
      team_id: teamId,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    console.log("dataImprovement, ", dataImprovement);
  }, [dataImprovement]);

  useEffect(() => {
    console.log("onmount dash");
    return () => {
      console.log("unmount dash");
    };
  }, []);

  // useEffect(() => {
  //   console.log("teamID change: ", teamID);
  //   refetchImprovement({
  //     team_id: teamID,
  //     level:
  //       tableState === "team-detail" || tableState === "team-rank"
  //         ? "Team"
  //         : tableState === "user-rank"
  //         ? "User"
  //         : "Organization",
  //   });
  // }, [teamID]);

  // const changeSelectedTableCell = async (checked: boolean) => {
  // const newLocation = [indexOfGrid / tableHeaders.length, indexOfGrid % tableHeaders.length];
  // const changeForReporting = (newIndex: number) => {
  //   if (!(selectedCell[0] === "")) {
  //     setSelectedCell([
  //       dataTable.fetchPerformanceChart[newIndex].id,
  //       dataTable.fetchPerformanceChart[newIndex].metric_label,
  //       dataTable.fetchPerformanceChart[newIndex].row_label,
  //     ]);
  //   }
  //   if (!(selectedStack[0] === "")) {
  //     setSelectedStack([
  //       dataTable.fetchPerformanceChart[newIndex].id,
  //       dataTable.fetchPerformanceChart[newIndex].metric_label,
  //       dataTable.fetchPerformanceChart[newIndex].row_label,
  //     ]);
  //   }
  // };
  // setTableView(!checked);
  // await refetchTable().then(() => {
  //   const newIndex = newLocation[0] * tableHeaders.length + newLocation[1]
  //   changeForReporting(newIndex);
  // });
  // };

  const handleOnClickStateChange = (state: string, item: any) => {
    if (state === "org") {
      setTableState("org");
      setShowTeams(false);
      setTeamIdStackRank("");
      setUserIdStackRank("");
      setTeamId("");
      setFilterTeam("");
      setFilterUser("");
    }
    if (state === "org-team") {
      setTableState("org-team");
      setUserIdStackRank("");
      setTeamIdStackRank("");
      setShowTeams(true);
      setFilterTeam("");
      setTeamId("");
      setFilterUser("");
      setSelectedCell(["", ""]);
    }
    if (state === "team-rank") {
      setTableState("team-rank");
      setShowTeams(true);
      setTeamId("");
      setTeamIdStackRank(item.team_id);
      setFilterTeam(item.team_id);
      setFilterUser("no-users");
    }

    if (state === "team-detail") {
      setTableState("team-detail");
      setShowTeams(true);
      setTeamId(item.team_id);
      setTeamIdStackRank("");
      setUserIdStackRank("");
      setFilterTeam(item.team_id);
      setFilterUser("");
    }
    if (state === "user-rank") {
      setFilterTeam(teamId || teamIdStackRank);
      setShowTeams(true);
      setTableState("user-rank");
      setUserIdStackRank(item.user_id);
      setTeamIdStackRank("");
      setFilterUser(item.user_id);
    }
  };

  const { data: dataStack, loading: loadingStack, error: errorStack, refetch: refetchStack } = useQuery(
    STACK_RANK_LIST,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      onCompleted: () => {
        console.log("from manager grid");
      },
      onError: () => {
        console.log("from manager grid");
      },
      skip: !currentItemData.computed_id,
      variables: {
        computed_id: currentItemData.computed_id,
        metric: currentItemData.metric,
        position: currentItemData.position,
        lowerbound_date: !!currentItemData.lowerbound_date
          ? moment(currentItemData.lowerbound_date).format()
          : undefined,
        upperbound_date: !!currentItemData.upperbound_date
          ? moment(currentItemData.upperbound_date).format()
          : moment().format(),
        // product_ids_filter: products,
        // channel_filter: channel,
        dashboard_filter: {
          Channels: gridFilter.channels,
          LeadSources: gridFilter.lead_sources,
          LeadCreationSources: gridFilter.lead_creation_sources,
          primary_industries: gridFilter.industries,
          sub_industries: gridFilter.sub_industries,
        },
      },
    },
  );

  const { data: dataGraph, loading: loadingGraph, error: errorGraph, refetch: refetchGraph } = useQuery(
    FETCH_30_DAY_GRAPH_DATA,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      skip: !currentItemData.computed_id,
      variables: {
        computed_id: currentItemData.computed_id,
        metric: currentItemData.metric,
        position: currentItemData.position,
        // product_ids_filter: products,
        // channel_filter: channel,
        lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
        upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
        dashboard_filter: {
          Channels: gridFilter.channels,
          LeadSources: gridFilter.lead_sources,
          LeadCreationSources: gridFilter.lead_creation_sources,
          primary_industries: gridFilter.industries,
          sub_industries: gridFilter.sub_industries,
        },
      },
    },
  );

  const tableHeaders =
    !!dataTable && !!dataTable.fetchPerformanceChart
      ? dataTable.fetchPerformanceChart
          ?.map((item: IPerformanceChartData) => item.metric_label)
          ?.filter((v: string, i: number, a: any) => a.indexOf(v) === i)
      : [];

  const returnTooltipData = (item: string) => {
    const element = dataTable.fetchPerformanceChart.filter((row: IPerformanceChartData) => row.metric_label === item);
    const tooltip = element[0].metric_tooltip || element[0].metric_label;
    return !!tooltip ? tooltip : "No tooltip";
  };

  const transformCellID = (oldMetricLabel: string, rowLabel: string) => {
    const element = dataTable.fetchPerformanceChart.filter(
      (row: IPerformanceChartData) => row.metric_label === oldMetricLabel,
    );
    return !!element ? element.id : "";
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataTable]);

  useEffect(() => {
    if (!!dataTable && !!dataTable.fetchPerformanceChart && initialRenderBool === false) {
      !!selectedStack[0] && setSelectedStack([transformColumnLabel(selectedStack[0]), selectedStack[1]]);
      !!selectedCell[0] && setSelectedCell([transformColumnLabel(selectedCell[0]), selectedCell[1]]);

      const newMetric = transformColumnLabel(!!selectedCell[0] ? selectedCell[0] : selectedStack[0]);

      const element = dataTable.fetchPerformanceChart
        ?.map((item: any) => item)
        .filter(
          (row: IPerformanceChartData) => row.metric_label === newMetric && row.row_label === currentItemData.row_label,
        )[0];

      if (!!element && !!element.id && !element.rank) {
        if (tableView !== "Reporting" && element.position === "User") {
          setCoachingReportView(true);
          setTeamID("");
          setUserID(element.user_id);
          setMetric(element.metric);
        } else if (tableView !== "Reporting" && element.position === "Team") {
          setCoachingReportView(true);
          setUserID("");
          setTeamID(element.team_id);
          setMetric(element.metric);
        } else if (tableView !== "Reporting" && element.position === "Organization") {
          setCoachingReportView(true);
          setUserID("");
          setTeamID("");
          setOrgID(element.organization_id);
          setMetric(element.metric);
        } else {
          setCoachingReportView(false);
          if (!!element && !!element.computed_id && !!element.metric && !!element.position) {
            refetchGraph({
              computed_id: element.computed_id,
              metric: element.metric,
              position: element.position,
              // product_ids_filter: products,
              // channel_filter: channel,
              lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
              upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
              dashboard_filter: {
                Channels: gridFilter.channels,
                LeadSources: gridFilter.lead_sources,
                LeadCreationSources: gridFilter.lead_creation_sources,
                primary_industries: gridFilter.industries,
                sub_industries: gridFilter.sub_industries,
              },
            });
          }
          setTeamID("");
          setUserID("");
          setMetric("");
        }
      } else {
        setCoachingReportView(false);
        if (!!element && !!element.computed_id && !!element.metric && !!element.position) {
          refetchStack({
            computed_id: element.computed_id,
            metric: element.metric,
            position: element.position,
            // lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
            // upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
            lowerbound_date: !!element.lowerbound_date ? moment(element.lowerbound_date).format() : undefined,
            upperbound_date: !!element.upperbound_date ? moment(element.upperbound_date).format() : moment().format(),
            dashboard_filter: {
              Channels: gridFilter.channels,
              LeadSources: gridFilter.lead_sources,
              LeadCreationSources: gridFilter.lead_creation_sources,
              primary_industries: gridFilter.industries,
              sub_industries: gridFilter.sub_industries,
            },
            // product_ids_filter: products,
            // channel_filter: channel,
          });
        }
        setTeamID("");
        setUserID("");
        setMetric("");
      }
    }
    // if (coachingView === true) {
    //   const newLocation = Math.floor(indexOfGrid / tableHeaders.length) + (indexOfGrid % tableHeaders.length);

    //   const changeForReporting = (newLocation: number) => {
    //     if (!(selectedCell[0] === "")) {
    //       setSelectedCell([
    //         dataTable.fetchPerformanceChart[newLocation].id,
    //         dataTable.fetchPerformanceChart[newLocation].metric_label,
    //         dataTable.fetchPerformanceChart[newLocation].row_label,
    //       ]);
    //     }
    //     if (!(selectedStack[0] === "")) {
    //       setSelectedStack([
    //         dataTable.fetchPerformanceChart[newLocation].id,
    //         dataTable.fetchPerformanceChart[newLocation].metric_label,
    //         dataTable.fetchPerformanceChart[newLocation].row_label,
    //       ]);
    //     }
    //   };
    //   const newLoc = dataTable.fetchPerformanceChart.findIndex(
    //     (element: any, index: number) =>
    //      element.row_label === (!!selectedCell ? selectedCell[2] : !!selectedStack[2] ? selectedStack[2] : 0) &&
    //      index === indexOfGrid % tableHeaders.length,
    //   );
    //   changeForReporting(newLocation);
    //   console.log("true");
    // }
    // if (coachingView === false) {
    //   if (!(selectedCell[0] === "")) {
    //     setSelectedCell([
    //       dataTable.fetchPerformanceChart[indexOfGrid].id,
    //       dataTable.fetchPerformanceChart[indexOfGrid].metric_label,
    //       dataTable.fetchPerformanceChart[indexOfGrid].row_label,
    //     ]);
    //   }
    //   if (!(selectedStack[0] === "")) {
    //     setSelectedStack([
    //       dataTable.fetchPerformanceChart[indexOfGrid].id,
    //       dataTable.fetchPerformanceChart[indexOfGrid].metric_label,
    //       dataTable.fetchPerformanceChart[indexOfGrid].row_label,
    //     ]);
    //   }
    //   console.log("false");
    // }
  }, [!!tableView && dataTable && dataTable.fetchPerformanceChart]);

  if (!!!dataTable) return <Loading />;
  if (errorTable) return <AppErrorText>Error loading table data!</AppErrorText>;

  const isUserIdInData = () => {
    const users = dataTable.fetchPerformanceChart.filter((item: any) => !!item.user_id);

    if (!!users.length) return true;
    return false;
  };

  const findUserIdFromRow = (row: string) => {
    const userIds = dataTable.fetchPerformanceChart.filter((item: any) => item.row_label === row && !!item.user_id);
    return !!userIds.length ? userIds[0].user_id : null;
  };

  const calculateIfSelected = (label: string) => {
    const selected = dateRangeOptions
      .slice()
      .filter(
        (item: any) =>
          moment(dateStart).format("YYY-MM-DD") === moment(item.start).format("YYY-MM-DD") &&
          moment(dateEnd).format("YYY-MM-DD") === moment(item.end).format("YYY-MM-DD"),
      )[0];

    if (!selected) {
      return label === "Custom Range";
    }

    return selected?.label === label;
  };

  const returnWhichOptionIsSelected = (value: string) => {
    const selected = dateRangeOptions.slice().filter((item: any) => item.value === value)[0];
    return selected ?? dateRangeOptions[0];
  };

  const ChooseUserStatusIcon = (id: string) => {
    if (!userStatusData) {
      return <GridTD></GridTD>;
    }
    const { user_id, status, conference_id, updated_at } =
      userStatusData?.filter((item: any) => item.user_id === id)[0] || [];

    if (!!conference_id && status === "DEMO") {
      return (
        <GridTD
          style={{ lineHeight: 1 }}
          onClick={() => {
            const sanitizedURL = sanitizeURL(`/call-report/${conference_id}`);

            history.push(sanitizedURL);
          }}
        >
          <StatusImg src={iconDemoCall} alt="Demo" />
          <TimeText>{calculateTimeDifference(currentTime, updated_at)}</TimeText>
        </GridTD>
      );
    }
    if (!!conference_id && status === "CALLBACK") {
      return (
        <GridTD
          style={{ lineHeight: 1 }}
          onClick={() => {
            const sanitizedURL = sanitizeURL(`/call-report/${conference_id}`);

            history.push(sanitizedURL);
          }}
        >
          <StatusImg src={iconPhoneCallback} alt="Call link" />
          <TimeText>{calculateTimeDifference(currentTime, updated_at)}</TimeText>
        </GridTD>
      );
    }
    if (!!conference_id && status === "DECISION_CALL") {
      return (
        <GridTD
          style={{ lineHeight: 1 }}
          onClick={() => {
            const sanitizedURL = sanitizeURL(`/call-report/${conference_id}`);
            history.push(sanitizedURL);
          }}
        >
          <StatusImg src={iconDecisionCall} alt="Idle" />
          <TimeText>{calculateTimeDifference(currentTime, updated_at)}</TimeText>
        </GridTD>
      );
    }
    if (!!conference_id && status === "INBOUND_CALL") {
      return (
        <GridTD
          style={{ lineHeight: 1 }}
          onClick={() => {
            const sanitizedURL = sanitizeURL(`/call-report/${conference_id}`);
            history.push(sanitizedURL);
          }}
        >
          <StatusImg src={iconInboundCall} alt="Idle" />
          <TimeText>{calculateTimeDifference(currentTime, updated_at)}</TimeText>
        </GridTD>
      );
    }
    if (!!conference_id && (status === "COLD_CALL" || status === "CALL")) {
      return (
        <GridTD style={{ lineHeight: 1 }} onClick={() => history.push(`/call-report/${conference_id}`)}>
          <StatusImg src={iconColdCall} alt="Idle" />
          <TimeText>{calculateTimeDifference(currentTime, updated_at)}</TimeText>
        </GridTD>
      );
    }
    if (!!conference_id) {
      return (
        <GridTD
          onClick={() => {
            const sanitizedURL = sanitizeURL(`/call-report/${conference_id}`);

            history.push(sanitizedURL);
          }}
          style={{ backgroundColor: "transparent", cursor: "auto" }}
        >
          {" "}
        </GridTD>
      );
    }
    return <GridTD style={{ backgroundColor: "transparent", cursor: "auto" }}></GridTD>;
  };

  const CreateUserStatusComponent = (user: any) => {
    const id = user;
    if (isUserIdInData()) {
      if (!!id) {
        return ChooseUserStatusIcon(id);
      }
      return <GridTD style={{ backgroundColor: "transparent", cursor: "auto" }}></GridTD>;
    }
    return <></>;
  };

  const rowLabels = dataTable.fetchPerformanceChart
    .filter((item: any) => !!!filterTeam || !!!item.team_id || item.team_id === filterTeam)
    .filter((item: any) => !!!filterUser || !!!item.user_id || item.user_id === filterUser)
    ?.map((item: IPerformanceChartData) => ({ label: item.row_label, user_id: item.user_id }))
    .filter((v: any, i: number, a: any) => a?.map((k: any) => k.label).indexOf(v.label) === i);

  const uniqueRows = dataTable.fetchPerformanceChart?.map((item: IPerformanceChartData) => ({
    id: item.id,
    row_label: item.row_label,
    team_id: item.team_id,
    user_id: item.user_id,
  }));

  // console.log("UNIQUE ROWS", uniqueRows);

  const formatGraphData = (data: any) => {
    // console.log("GRAPH DATA: ", data);
    // console.log(
    //   "GRAPH DATA FORMATTED: ",
    //   data?.map((item: any) => ({ x: item.formatted_day, y: item.value })),
    // );
    return data
      ?.map((item: any) => item)
      .sort((a: any, b: any) => moment(a.day).unix() - moment(b.day).unix())
      ?.map((item: any) =>
        item.metric_type === "Percentage"
          ? { x: item.formatted_day, y: Number(item.value) * 100, label: formatCellData(item) }
          : { x: item.formatted_day, y: Number(item.value), label: formatCellData(item) },
      );
  };

  const handleTeamRowLabelClick = (row: any) => {
    if (
      tableState === "org-team" ||
      tableState === "team-rank" ||
      (tableState === "team-detail" && teamId !== row.team_id)
    ) {
      handleOnClickStateChange("team-detail", row);
    } else {
      handleOnClickStateChange("org-team", row);
    }
  };

  const handleUserRowLabelClick = (row: any) => {
    handleOnClickStateChange("team-detail", row);
  };

  const CreateRowLabelCell = ({ label, user_id }: { label: string; user_id: string }) => {
    const relevantValues = dataTable.fetchPerformanceChart
      .filter((item: IPerformanceChartData) => item.row_label === label)
      .pop();

    if (relevantValues.rank) {
      return (
        <GridTD style={{ cursor: "auto", backgroundColor: "transparent" }}>
          <RowTitle style={{ fontSize: "11.1px", fontWeight: "normal", textTransform: "none" }}>
            {relevantValues.row_label}
          </RowTitle>
        </GridTD>
      );
    }

    if (relevantValues.position === "Organization") {
      return (
        <GridTD
          style={{ cursor: "pointer", backgroundColor: theme.WHITE_COLOR }}
          onClick={() => {
            tableState === "team-rank" || tableState === "org"
              ? handleOnClickStateChange("org-team", relevantValues)
              : handleOnClickStateChange("org", relevantValues);
          }}
        >
          <FlexDiv>
            {loadingTable ? (
              <div
                style={{
                  marginTop: "5px",
                  marginLeft: "5px",
                  maxHeight: "10px",
                  maxWidth: "25px",
                  transform: "scale(0.5)",
                }}
              >
                <Loading />
              </div>
            ) : (
              <ArrowImage
                style={{ transform: tableState === "org" ? "rotate(0deg)" : "rotate(90deg)" }}
                src={iconArrow}
                alt="Expand/Collapse"
              />
            )}
            <RowTitle>{relevantValues.row_label.toUpperCase()}</RowTitle>
          </FlexDiv>
        </GridTD>
      );
    }

    if (relevantValues.position === "Team") {
      return (
        <GridTD
          style={{ cursor: "pointer", backgroundColor: theme.WHITE_COLOR }}
          onClick={() => handleTeamRowLabelClick(relevantValues)}
        >
          <FlexDiv>
            {loadingTable ? (
              <div
                style={{
                  marginTop: "5px",
                  marginLeft: "5px",
                  maxHeight: "10px",
                  maxWidth: "25px",
                  transform: "scale(0.5)",
                }}
              >
                <Loading />
              </div>
            ) : tableState === "team-detail" || tableState === "team-rank" || tableState === "org-team" ? (
              <ArrowImage
                src={iconArrow}
                alt="Expand/Collapse"
                style={{ transform: tableState === "team-detail" ? "rotate(90deg)" : "rotate(0deg)" }}
              />
            ) : (
              (tableState === "user-detail" || tableState === "user-rank") && (
                <ArrowImage
                  src={iconArrow}
                  alt="Expand/Collapse"
                  style={{ transform: tableState === "user-rank" ? "rotate(90deg)" : "rotate(0deg)" }}
                />
              )
            )}
            <RowTitle style={{ fontSize: "11.1px" }}>{relevantValues.row_label.toUpperCase()}</RowTitle>
          </FlexDiv>
        </GridTD>
      );
    }

    if (relevantValues.position === "User") {
      return (
        <GridTD
          style={{
            cursor: "pointer",
            backgroundColor: theme.WHITE_COLOR,
          }}
          onClick={() => handleUserRowLabelClick(relevantValues)}
        >
          <FlexDiv>
            {/* {filterUser === relevantValues.user_id && (tableState === "user-detail" || tableState === "user-rank") && (
              <ArrowImage
                src={iconArrow}
                alt="Expand/Collapse"
                style={{ transform: tableState === "user-rank" ? "rotate(0deg)" : "rotate(90deg)" }}
              />
            )} */}
            <RowTitle style={{ fontSize: "11.1px", fontWeight: "normal" }}>{relevantValues.row_label}</RowTitle>
            <UserIcons user_id={user_id} />
          </FlexDiv>
        </GridTD>
      );
    } else {
      return (
        <GridTD style={{ cursor: "auto", backgroundColor: "transparent" }}>
          <RowTitle style={{ fontSize: "11.1px", fontWeight: "normal", textTransform: "none" }}>
            {relevantValues.row_label}
          </RowTitle>
        </GridTD>
      );
    }
  };

  return (
    <LargeDiv>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        className="sib-tooltip"
        id="managergridheader"
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      {gridModal && (
        <DarkDiv>
          <SlideInDiv>
            <ManageGridColumnsModal />
          </SlideInDiv>
        </DarkDiv>
      )}
      {tableModal && (
        <ManageTableColumnsModal
          dataAllColumns={[
            { metric_label: "Time of Call", metric: "CallTime", fixed: false },
            { metric_label: "Contact Name", metric: "LeadName", fixed: false },
            { metric_label: "Business Name", metric: "BusinessName", fixed: false },
            { metric_label: "Sales Rep", metric: "RepName", fixed: false },
            { metric_label: "Call Result", metric: "CallResult", fixed: false },
            { metric_label: "Set", metric: "Set", fixed: true },
            { metric_label: "Held", metric: "Held", fixed: true },
            { metric_label: "Closed", metric: "Close", fixed: true },
            { metric_label: "Time of Event", metric: "EventTime", fixed: false },
            { metric_label: "Close User", metric: "ClosedByUser", fixed: false },
            { metric_label: "Set User", metric: "SetByUser", fixed: false },
          ]}
        />
      )}
      <PaddingDiv width={tableHeaders.length} expanded={filterSidebarExpanded}>
        <TimePickerDiv>
          {/* <img src={iconCalendarNormal} alt="" height="24px" /> */}
          <TimeSelectDiv>
            <TimeLabelText>Date Range</TimeLabelText>
            <DateAppSelect
              textAlign="left"
              options={dateRangeOptions?.map((item) => ({ label: item.label, value: item.value }))}
              value={dateLabel ?? "Today"}
              onChange={(e: any) => {
                const selected = returnWhichOptionIsSelected(e.target.value);
                setDateStart(selected.start);
                setDateEnd(selected.end);
                setDateLabel(selected.value);
              }}
            />
          </TimeSelectDiv>

          <TimeColumnDiv>
            <div style={{ display: "flex", width: "100%" }}>
              <TimeLabelText style={{ marginLeft: "15px" }}>Start Date</TimeLabelText>
              <TimeLabelText style={{ marginLeft: "95px" }}>End Date</TimeLabelText>
            </div>
            <OutsideEvent onOutsideClick={() => setFocusedInput(focusedInput)}>
              <ReactDatesWrapper>
                <DateRangePicker
                  minimumNights={0}
                  isOutsideRange={() => false}
                  startDate={moment(dateStart)}
                  startDateId="manager_grid_start_date"
                  endDate={moment(dateEnd)}
                  endDateId="manager_grid_end_date"
                  onDatesChange={({ startDate, endDate }) => {
                    if (startDate && moment(startDate).isValid()) {
                      setDateLabel("CustomRange");
                      setDateStart(moment(startDate).startOf("day").format());
                    }
                    if (endDate && moment(endDate).isValid()) {
                      setDateLabel("CustomRange");
                      setDateEnd(moment(endDate).endOf("day").format());
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                />
              </ReactDatesWrapper>
            </OutsideEvent>
          </TimeColumnDiv>

          {/* <TimeColumnDiv>
            <TimeLabelText>Start Date</TimeLabelText>
            <DateInput
              type="date"
              max={moment(dateEnd).format("YYYY-MM-DD")}
              value={moment(dateStart).format("YYYY-MM-DD")}
              onChange={(e: any) => {
                if (e?.target?.value && moment(e.target.value).isValid()) {
                  setDateLabel("CustomRange");
                  setDateStart(moment(e.target.value).format());
                }
              }}
            />
          </TimeColumnDiv> */}
          {/* <TiArrowSortedUp style={{ transform: "rotate(90deg" }} size={24} /> */}
          {/* <img src={iconCalendarNormal} alt="" height="24px" /> */}
          {/* <TimeColumnDiv>
            <TimeColumnDiv>
              <TimeLabelText>End Date</TimeLabelText>
              <DateInput
                type="date"
                min={moment(dateStart).format("YYYY-MM-DD")}
                value={moment(dateEnd).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  if (e?.target?.value && moment(e.target.value).isValid()) {
                    setDateLabel("CustomRange");
                    setDateEnd(moment(e.target.value).endOf("day").format());
                  }
                }}
              />
            </TimeColumnDiv>
          </TimeColumnDiv> */}
        </TimePickerDiv>

        <SpaceBetweenDiv width={tableHeaders.length}>
          <TimePickerDiv>
            <ToggleViewText
              selected={tableView === "Reporting"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Reporting");
                // await refetchTable({
                //   dashboard_type: tableView,
                // });
                // ReactTooltip.rebuild();
              }}
            >
              Reporting
            </ToggleViewText>
            <ToggleViewText
              selected={tableView === "Coaching"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Coaching");
                // await refetchTable({
                //   dashboard_type: tableView,
                // });
                // ReactTooltip.rebuild();
              }}
            >
              Coaching
            </ToggleViewText>
            {/* {!isProduction && (
              <ToggleViewText
                selected={tableView === "Activity"}
                onClick={async () => {
                  setInitialRenderBool(false);
                  setTableView("Activity");
                  // await refetchTable({
                  //   dashboard_type: tableView,
                  // });
                  // ReactTooltip.rebuild();
                }}
              >
                Activity
              </ToggleViewText>
            )} */}
            <ToggleViewText
              selected={tableView === "Event"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Event");
                // await refetchTable({
                //   dashboard_type: tableView,
                // });
                // ReactTooltip.rebuild();
              }}
            >
              Event
            </ToggleViewText>
            <ToggleViewText
              selected={tableView === "Pipeline"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Pipeline");
                // await refetchTable({
                //   dashboard_type: tableView,
                // });
                // ReactTooltip.rebuild();
              }}
            >
              Pipeline
            </ToggleViewText>
            {/* {environment === "Development" && (
            <Horizontal style={{ marginLeft: "24px" }}>
              <SwtichLabelText style={{ fontWeight: useNewVersion ? "normal" : "bold" }}>old dashboard</SwtichLabelText>
              <Switch
                onChange={async (checked) => {
                  setUseNewVersion(checked);
                }}
                name="use_new_version"
                onColor={theme.TERTIARY500}
                offColor={theme.BLACK_COLOR}
                checked={useNewVersion}
                height={19}
                width={36.3}
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <SwtichLabelText style={{ fontWeight: useNewVersion ? "bold" : "normal" }}>new dashboard</SwtichLabelText>
            </Horizontal>
          )} */}
          </TimePickerDiv>
          {/* {["Coaching", "Reporting"].includes(tableView) ? (
            <ClickableText onClick={() => setGridModal(!gridModal)}>Manage Columns</ClickableText>
          ) : (
            <div />
          )} */}
        </SpaceBetweenDiv>
        <TableContainer>
          <InfoDiv>
            <InfoDiv>
              {!!dataImprovement?.fetchSalesImprovements &&
                tableHeaders.indexOf(dataImprovement?.fetchSalesImprovements?.metric_label) > -1 &&
                // !!dataImprovement?.fetchSalesImprovements?.sale_improvement &&
                // !!dataImprovement?.fetchSalesImprovements?.value &&
                !!dataImprovement?.fetchSalesImprovements?.metric_tooltip && (
                  <>
                    <div>
                      <UnderlineText style={{ width: "230px" }}>FOCUS AREA</UnderlineText>
                    </div>
                    <GridSuggestionBox
                      width={tableHeaders.length}
                      position={
                        tableHeaders.indexOf(dataImprovement.fetchSalesImprovements.metric_label) / tableHeaders.length
                      }
                    >
                      <SuggestionBoxText>
                        You can increase your sales by{" "}
                        <SlightlyBoldSpan>
                          {formatCellData(dataImprovement?.fetchSalesImprovements?.sale_improvement, "Percentage")}
                          {/* {formatSIBData(
                            dataImprovement?.fetchSalesImprovements?.sale_improvement,
                            dataImprovement?.fetchSalesImprovements?.metric_type || "Percentage",
                          )} */}
                        </SlightlyBoldSpan>{" "}
                        if you improve your{" "}
                        <SlightlyBoldSpan>{`${dataImprovement?.fetchSalesImprovements?.metric_label}`}</SlightlyBoldSpan>{" "}
                        to{" "}
                        <SlightlyBoldSpan>
                          {formatCellData(
                            dataImprovement?.fetchSalesImprovements?.value,
                            dataImprovement?.fetchSalesImprovements?.metric_type,
                          )}
                        </SlightlyBoldSpan>
                      </SuggestionBoxText>
                      <SuggestionRightIcon>
                        <QuestionMarkMoreInfo
                          big
                          data-for="managergridheader"
                          data-tip={dataImprovement?.fetchSalesImprovements?.metric_tooltip}
                        >
                          ?
                        </QuestionMarkMoreInfo>
                      </SuggestionRightIcon>
                    </GridSuggestionBox>
                  </>
                )}
            </InfoDiv>
          </InfoDiv>
          <GridTable loading={loadingTable}>
            <thead>
              <GridTR thickLine={tableView === "Reporting"}>
                <GridTH style={{ padding: "0px" }}>
                  <div>
                    <UnderlineText>KEY METRICS</UnderlineText>
                  </div>
                  <Horizontal style={{ marginTop: "50px" }}></Horizontal>
                </GridTH>
                {isUserIdInData() && <GridTH key={"status"}>Status</GridTH>}
                {tableHeaders?.map((item: string) => (
                  <GridTH key={`TH${item}`}>
                    <THText>
                      <MarginSpan>{item}</MarginSpan>
                      {["Reporting", "Coaching"].includes(tableView) && (
                        <QuestionMarkMoreInfo
                          data-for="managergridheader"
                          data-tip={returnTooltipData(item)}
                          style={{ transform: "rotate(90deg)" }}
                          nomargin
                        >
                          ?
                        </QuestionMarkMoreInfo>
                      )}
                    </THText>
                  </GridTH>
                ))}
              </GridTR>
            </thead>
            <tbody>
              {rowLabels?.map((row: any) => (
                <GridTR key={row.label} thickLine={tableView === "Reporting"}>
                  {CreateRowLabelCell({ label: row.label, user_id: row.user_id })}
                  {CreateUserStatusComponent(findUserIdFromRow(row.label))}
                  {dataTable.fetchPerformanceChart
                    .filter((item: any) => !!!filterTeam || !!!item.team_id || item.team_id === filterTeam)
                    .filter((item: any) => !!!filterUser || !!!item.user_id || item.user_id === filterUser)
                    ?.map(
                      (item: any, index: number) =>
                        row.label === item.row_label && (
                          <>
                            <GridTD
                              key={`TR${item.id}`}
                              selected={
                                item.rank
                                  ? selectedStack[0] === item.metric_label && selectedStack[1] === item.row_label
                                  : selectedCell[0] === item.metric_label && selectedCell[1] === item.row_label
                              }
                              onClick={async () => {
                                console.log("clicked cell: ", item);
                                MixpanelActions.track("Performance Dashboard", {
                                  type: "cell clicked",
                                  View: `${tableView}`,
                                  item,
                                });
                                setInitialRenderBool(false);
                                setCurrentItemData(item);
                                if (!item.rank) {
                                  if (
                                    ["Coaching", "Event", "Pipeline"].includes(tableView) &&
                                    item.position === "User"
                                  ) {
                                    setCoachingReportView(true);
                                    setSelectedCell([item.metric_label, item.row_label]);
                                    setSelectedStack(["", ""]);
                                    setTeamID("");
                                    setUserID(item.user_id);
                                    setMetric(item.metric);
                                    // tableView === "Event" && setFilterUser(item.user_id) && setFilterTeam(item.team_id);
                                  } else if (
                                    ["Coaching", "Event", "Pipeline"].includes(tableView) &&
                                    item.position === "Team"
                                  ) {
                                    setCoachingReportView(true);
                                    setSelectedCell([item.metric_label, item.row_label]);
                                    setSelectedStack(["", ""]);
                                    setUserID("");
                                    setTeamID(item.team_id);
                                    setMetric(item.metric);
                                    // tableView === "Event" && setFilterTeam(item.team_id);
                                  } else if (
                                    ["Coaching", "Event", "Pipeline"].includes(tableView) &&
                                    item.position === "Organization"
                                  ) {
                                    setCoachingReportView(true);
                                    setSelectedCell([item.metric_label, item.row_label]);
                                    setSelectedStack(["", ""]);
                                    setUserID("");
                                    setTeamID("");
                                    setOrgID(item.organization_id);
                                    setMetric(item.metric);
                                  } else {
                                    setSelectedStack(["", ""]);
                                    setCoachingReportView(false);
                                    setSelectedCell([item.metric_label, item.row_label]);
                                    handleOnClickStateChange(
                                      item.position === "Organization"
                                        ? ""
                                        : !!item.user_id
                                        ? "user-rank"
                                        : "team-rank",
                                      item,
                                    );
                                    await refetchGraph({
                                      computed_id: item.computed_id,
                                      metric: item.metric,
                                      position: item.position,
                                      // product_ids_filter: products,
                                      // channel_filter: channel,
                                      lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
                                      upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
                                      dashboard_filter: {
                                        Channels: gridFilter.channels,
                                        LeadSources: gridFilter.lead_sources,
                                        LeadCreationSources: gridFilter.lead_creation_sources,
                                        primary_industries: gridFilter.industries,
                                        sub_industries: gridFilter.sub_industries,
                                      },
                                    });
                                    if (tableView === "Reporting") {
                                      setIndexOfGrid(index);
                                    }
                                    setTeamID("");
                                    setUserID("");
                                    setMetric("");
                                  }
                                } else {
                                  setCoachingReportView(false);
                                  setSelectedStack([item.metric_label, item.row_label]);
                                  setSelectedCell(["", ""]);
                                  await refetchStack({
                                    computed_id: item.computed_id,
                                    metric: item.metric,
                                    position: item.position,
                                    // lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
                                    // upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
                                    lowerbound_date: !!item.lowerbound_date
                                      ? moment(item.lowerbound_date).format()
                                      : undefined,
                                    upperbound_date: !!item.upperbound_date
                                      ? moment(item.upperbound_date).format()
                                      : moment().format(),
                                    // product_ids_filter: products,
                                    // channel_filter: channel,
                                    dashboard_filter: {
                                      Channels: gridFilter.channels,
                                      LeadSources: gridFilter.lead_sources,
                                      LeadCreationSources: gridFilter.lead_creation_sources,
                                      primary_industries: gridFilter.industries,
                                      sub_industries: gridFilter.sub_industries,
                                    },
                                  });
                                  if (tableView === "Reporting") {
                                    setIndexOfGrid(index);
                                  }
                                  setTeamID("");
                                  setUserID("");
                                  setMetric("");
                                }
                              }}
                            >
                              {formatCellData(item?.value, item?.metric)}
                            </GridTD>
                          </>
                        ),
                    )}
                </GridTR>
              ))}
            </tbody>
          </GridTable>
          {/* {indexOfGrid}
          {selectedCell[0]} {selectedCell[1]} {selectedCell[2]} */}
          <br />
          {/* {coachingReportView && ["Coaching", "Event", "Pipeline"].includes(tableView) && (
            <div style={{ width: "1040px", marginBottom: "8px" }}>
              <ClickableText style={{ marginLeft: "auto" }} onClick={() => setTableModal(!tableModal)}>
                Manage Columns
              </ClickableText>
            </div>
          )} */}
          {coachingReportView && tableView === "Coaching" ? (
            <CallReportListV1
              key={`CallReportsRep${metric}`}
              userID={userID}
              teamID={teamID}
              orgID={orgID}
              gridFilter={true}
              metric={metric}
            />
          ) : coachingReportView && tableView === "Event" ? (
            <EventsListTable
              key={`EventsListRep${metric}`}
              userID={userID}
              teamID={teamID}
              orgID={orgID}
              gridFilter={true}
              metric={metric}
            />
          ) : coachingReportView && tableView === "Pipeline" ? (
            <PipelineListTable
              key={`PipelineListRep${metric}`}
              userID={userID}
              teamID={teamID}
              orgID={orgID}
              gridFilter={true}
              metric={metric}
            />
          ) : (
            !!selectedCell[0] && (
              <ChartContainer>
                <div style={{ padding: "20px 0px 0px 20px" }}>
                  <TitleText bold>{selectedCell[0]}</TitleText>
                  <TitleText>{selectedCell[1]}</TitleText>
                </div>
                {!!!dataGraph || loadingGraph ? (
                  <Loading />
                ) : errorGraph ? (
                  <AppErrorText></AppErrorText>
                ) : (
                  dataGraph &&
                  dataGraph.fetch30DayGraphData && (
                    <ProgressGraph
                      data={formatGraphData(dataGraph.fetch30DayGraphData.stat_items)}
                      bestIndividual={dataGraph.fetch30DayGraphData.best_individual}
                      companyAverage={dataGraph.fetch30DayGraphData.company_average}
                      metricType={currentItemData.metric_type}
                    />
                  )
                )}
              </ChartContainer>
            )
          )}
          {!!selectedStack[0] && (
            <StackTableContainer>
              <StackTable>
                <thead>
                  <StackTR>
                    <StackTH
                      colSpan={2}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <TitleText bold>{selectedStack[0]}</TitleText>
                      <TitleText>{selectedStack[1]}</TitleText>
                    </StackTH>
                    <StackTH
                      colSpan={2}
                      style={{
                        textAlign: "right",
                        paddingRight: "16px",
                      }}
                    >
                      <TitleText bold>{""}</TitleText>
                    </StackTH>
                  </StackTR>
                  <StackTR>
                    <StackTH style={{ width: "53px" }}>Rank</StackTH>
                    <StackTH>{tableState === "user-rank" ? "Name" : "Team Name"}</StackTH>
                    {/* <StackTH style={{ width: "231px" }}>Modified Metric</StackTH> */}
                    <StackTH
                      style={{ width: "231px", cursor: "pointer" }}
                      onClick={() => setSortTableAscending(!sortTableAscending)}
                    >
                      <TableHeaderContent>
                        {sortTableAscending ? (
                          <AiFillCaretDown style={{ marginRight: "6px" }} size={14} color={theme.BLACK_COLOR} />
                        ) : (
                          <AiFillCaretUp style={{ marginRight: "6px" }} size={14} color={theme.BLACK_COLOR} />
                        )}
                        Amount
                        {/* Standard Metric
                        <QuestionMarkMoreInfo>?</QuestionMarkMoreInfo> */}
                      </TableHeaderContent>
                    </StackTH>
                  </StackTR>
                </thead>
                <tbody>
                  {!!!dataStack || loadingStack ? (
                    <Loading />
                  ) : errorStack ? (
                    <AppErrorText>Error loading stack rank data!</AppErrorText>
                  ) : (
                    dataStack &&
                    dataStack.stackRankList &&
                    dataStack.stackRankList
                      ?.map((item: any) => item)
                      .sort((a: any, b: any) =>
                        sortTableAscending ? a.list_rank - b.list_rank : b.list_rank - a.list_rank,
                      )
                      ?.map((item: any) => (
                        <StackTR key={item.id}>
                          <StackTD selected={item.selected}>{item.list_rank}</StackTD>
                          <StackTD selected={item.selected}>{item.row_label}</StackTD>
                          {/* <StackTD selected={item.row_label === rowLabels[0]}>N/A</StackTD> */}
                          <StackTD selected={item.selected}>
                            {formatCellData(item?.list_value, item?.metric_type)}
                          </StackTD>
                        </StackTR>
                      ))
                  )}
                </tbody>
              </StackTable>
            </StackTableContainer>
          )}
          {/* show_teams:
      {`${showTeams}`}
      <br />
      team_id_stack_rank
      {teamIdStackRank}:
      <br /> user_id_stack_rank:
      {userIdStackRank}
      <br /> team_id:
      {teamId}
      <br /> table state:
      {tableState}
      <br /> filter:
      {filterTeam}
      <br /> filter:
      {filterUser} */}
        </TableContainer>
      </PaddingDiv>
    </LargeDiv>
  );
};

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 480px; }
`;

const DarkDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SlideInDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 478px;
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  background-color: ${theme.WHITE_COLOR};
`;

// const SlideInDiv = styled.div`
//   position: fixed;
//   left: 72px;
//   width: 478px;
//   min-height: calc(100vh - 48px);
//   overflow: hidden;
//   animation-name: ${slideInAnimation};
//   animation-duration: 700ms;
//   z-index: 4;
//   background-color: ${theme.WHITE_COLOR};
//   ::after {
//     position: fixed;
//     z-index: 3;
//     content: "";
//     top: 0px;
//     bottom: 0px;
//     left: 550px;
//     right: 0px;
//     background-color: rgba(0, 0, 0, 0.5);
//   }
// `;

const ClickableText = styled(AppText)`
  width: fit-content;
  border-bottom: none;
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 8px;
`;

interface OnlyWidthProp {
  width: number;
}

const SpaceBetweenDiv = styled.div<OnlyWidthProp>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: ${(props) => `${props.width * 36 + 240}px`};
  min-width: 1250px;
  align-items: flex-end;
  margin: 16px 0px;
`;

interface SelectedViewProp {
  selected: boolean;
}

const ToggleViewText = styled(AppText)<SelectedViewProp>`
  width: 141px;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 8px;
  font-weight: ${(props) => (props.selected ? 600 : "normal")};
  border-bottom: ${(props) => (props.selected ? `2px solid ${theme.PRIMARY500}` : `1px solid ${theme.NEUTRAL200}`)};
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.2px;
`;

const DateAppSelect = styled(AppSelect)`
  font-size: 12px;
`;

const TimeSelectDiv = styled.div`
  width: 240px;
  margin-right: 32px;
`;

const TimeLabelText = styled(AppText)`
  /* margin-top: 8px;
  font-size: 8px;
  font-weight: 600; */
  margin: 0px;
  margin-bottom: 5px;
  line-height: 1.3;
`;

const TimeColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
  margin-bottom: 24px;
`;

const TimePickerDiv = styled.div`
  display: flex;
  margin-left: 240px;
`;

const ShortLineHeightP = styled.p`
  line-height: 1.4;
  max-width: 320px;
`;

interface WidthProp {
  width: number;
  expanded: boolean;
}

const PaddingDiv = styled.div<WidthProp>`
  padding: 30px;
  padding-top: 50px;
  /* padding-left: calc(
    (
        100vw - 200px - 78px - ${(props) => (!!props.width ? `${props.width * 36}` : `10 * 36`)}px + 10px -
          ${(props) => (props.expanded ? `318` : `20`)}px
      ) / 2
  ); */
  padding-left: 50px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: min-content;
  /* NOTE: toggle the line below to make the stack table and graph shrink if the screen is small or always be the width of the table */
  /* min-width: min-content; */
`;

const SlightlyBoldSpan = styled.span`
  font-weight: 600;
`;

const StackTableContainer = styled.div`
  /* margin: 20px; */
  margin-top: 0px;
  max-height: 425px;
  overflow-y: scroll;
  min-width: 100%;
  border-radius: 10px;
`;

const StatusImg = styled.img`
  display: inline;
  height: 24px;
  width: 24px;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const ArrowImage = styled.img`
  margin-left: 12px;
  transform: rotate(90deg);
`;

const SwtichLabelText = styled(AppText)`
  font-size: 9.2px;
  margin: 10px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 299px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  /* margin-left: 20px; */
  margin-bottom: 20px;
`;

interface CoachingViewProp {
  thickLine: boolean;
}

const GridTR = styled.tr<CoachingViewProp>`
  td:nth-last-child(5),
  th:nth-last-child(5) {
    border-right: ${(props) =>
      props.thickLine ? `solid 1.5px ${theme.BLACK_COLOR}` : `solid 0.5px ${theme.NEUTRAL200}`};
  }
`;

const TableHeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

interface IBold {
  bold?: boolean;
}

const TitleText = styled(AppText)<IBold>`
  display: inline-block;
  font-size: 13px;
  font-weight: ${(props) => (props.bold ? 500 : "normal")};
  margin-left: ${(props) => (props.bold ? "15px" : "7px")};
`;

interface SelectedProp {
  selected?: boolean;
}

const StackTR = styled.tr`
  border: none;
  :last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  :last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  :last-child td {
    border-bottom: none;
  }
  td:first-child {
    border-left: none;
  }
  td:nth-child(2),
  th:nth-child(2) {
    text-align: left;
    padding-left: 38px;
  }
  td:last-child {
    border-right: none;
  }
`;

const StackTD = styled.td<SelectedProp>`
  font-size: 12px;
  font-weight: ${(props) => (props.selected ? 500 : 300)};
  color: ${(props) => (props.selected ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  border: solid 0.5px ${theme.NEUTRAL200};
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR)};
  line-height: 23px;
  text-align: center;
`;

const StackTH = styled.th`
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  height: 33px;
  text-align: center;
  border-bottom: solid 0.5px ${theme.NEUTRAL200};
  :first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
  }
`;

const StackTable = styled.table`
  min-width: 800px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  border-collapse: collapse;
`;

const SuggestionRightIcon = styled.div`
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
`;

interface QuestionSize {
  big?: boolean;
  nomargin?: boolean;
}

const QuestionMarkMoreInfo = styled(AppText)<QuestionSize>`
  display: inline-block;
  line-height: ${(props) => (props.big ? "15px" : "10px")};
  font-size: ${(props) => (props.big ? "10px" : "7px")};
  padding-left: ${(props) => (props.big ? "5px" : "0px")};
  margin: ${(props) => (props.nomargin ? "1px 0" : props.big ? "auto" : "5px")};
  margin-left: ${(props) => (props.nomargin ? 0 : "5px")};
  height: ${(props) => (props.big ? "15px" : "10px")};
  width: ${(props) => (props.big ? "15px" : "10px")};
  border-radius: 50%;
  background-color: ${theme.NEUTRAL100};
  color: ${theme.PRIMARY500};
  cursor: pointer;
`;

const SuggestionBoxText = styled(AppText)`
  font-size: 12.9px;
  font-weight: 500;
  margin: auto auto;
  line-height: 36px;
`;

const UnderlineText = styled(AppText)`
  width: 100%;
  position: relative;
  line-height: 14px;
  font-size: 11.1px;
  text-transform: uppercase;
  text-align: left;
  :after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 0px;
    height: 1px;
    background: ${theme.TERTIARY500};
    width: 100%;
  }
`;

const InfoDiv = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
`;

interface CaretLocation {
  position: number;
  width: number;
}

const GridSuggestionBox = styled.div<CaretLocation>`
  position: relative;
  min-width: ${(props) => `${props.width * 36 + 10}px`};
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background-color: ${theme.TERTIARY500};
  margin-bottom: 10px;

  :before {
    content: "";
    position: absolute;
    top: 36px;
    left: calc(100% * ${(props) => props.position} + 5px);
    border-top: 15px solid ${theme.TERTIARY500};
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    transform: scale(0.75, 1);
  }

  :after {
    content: "";
    position: absolute;
    left: calc(100% * ${(props) => props.position} + 6px);
    top: 36px;
    border-top: 14px solid ${theme.TERTIARY500};
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    transform: scale(0.75, 1);
  }
`;

const RowTitle = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

const GridTD = styled.td<SelectedProp>`
  white-space: nowrap;
  border: 1px solid ${theme.NEUTRAL200};
  line-height: 30px;
  font-size: 8px;
  padding: 2px;
  cursor: pointer;
  box-shadow: ${(props) => (!!props.selected ? `inset 0px 0px 2px 2px ${theme.PRIMARY500}` : "none")};
  text-align: center;
  background: ${theme.WHITE_COLOR};
`;

const TimeText = styled(AppText)`
  font-size: 8px;
  line-height: 8px;
  padding-bottom: 2px;
  margin: auto;
`;

interface LoadingTableProp {
  loading: boolean;
}

const GridTable = styled.table<LoadingTableProp>`
  pointer-events: ${(props) => (props.loading ? "none" : "auto")};
  cursor: ${(props) => (props.loading ? "wait" : "auto")};
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  padding: 5px;
  /* min-width: 1040px; */
  overflow: hidden;
  width: fit-content;
  :first-child {
    min-width: 240px;
    max-width: 240px;
  }
`;

const GridTH = styled.th`
  position: relative;
  border: 1px solid ${theme.NEUTRAL200};
  padding: 5px;
  height: 160px;
  transform: translate(2px, 55px) rotate(270deg);
  min-width: 36px;
  max-width: 36px;
  font-size: 10px;
  font-weight: 500;
  :first-child {
    transform: translate(0px, 0px) rotate(0deg);
    min-width: 232px;
    border: 0px solid ${theme.NEUTRAL200};
  }
`;

const THText = styled.div`
  display: flex;
  position: absolute;
  /* This number was calculated from... */
  /* GridTH_translateY + GridTH_width/2 - 1  */
  top: 72px;
  /* 5px to account for padding */
  left: 5px;
  max-width: 150px;
`;

const MarginSpan = styled.span`
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

const LargeDiv = styled.div`
  background: ${theme.NEUTRAL100};
  min-height: calc(100vh);
  max-height: calc(100vh);
  overflow: auto;
`;

const UserIconsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const StyledTooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 600px;
`;

const FilterTextContainer = styled.div`
  white-space: pre-wrap;
  overflow: hidden;
  text-align: left;
`;

export { ManagerGrid };
