import styled, { keyframes } from "styled-components";
import { AppText, DarkDiv, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { theme } from "../../../utils/theme";
import { chevron_down, chevron_up, refresh, xIcon } from "../../../images/NewDesign";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";
import { ModalContext } from "../../../context";
import { gql, useQuery } from "@apollo/client";
import { getLocalStorage } from "../../../utils/misc";

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      name
    }
  }
`;
const FETCH_REPS = gql`
  query fetchReps {
    fetchReps {
      id
      full_name
      role
    }
  }
`;
const FETCH_SITES = gql`
  query fetchSites {
    fetchOrganization {
      Sites {
        id
        name
      }
    }
  }
`;

interface SequenceReportingFilterModalProps {
  sequenceData: any;
  filterType: "Sequence" | "Phase" | "Sales Team and Rep";
  onClose: (selectedSequences: string[]) => void;
}

export const SequenceReportingFilterModal: React.FC<SequenceReportingFilterModalProps> = ({
  sequenceData,
  filterType,
  onClose,
}) => {
  const { setShowSequenceReportingFilterModal } = useContext(ModalContext);

  const [showRoles, setShowRoles] = useState<boolean>(true);
  const [showTeams, setShowTeams] = useState<boolean>(true);
  const [showReps, setShowReps] = useState<boolean>(true);
  const [showSites, setShowSites] = useState<boolean>(true);

  const [teamSearch, setTeamSearch] = useState<string>("");
  const [repSearch, setRepSearch] = useState<string>("");

  const { data: teamData, loading: loadingTeams } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "cache-and-network",
    skip: filterType !== "Sales Team and Rep",
  });
  const { data: repData, loading: loadingReps } = useQuery(FETCH_REPS, {
    fetchPolicy: "cache-and-network",
    skip: filterType !== "Sales Team and Rep",
  });
  const { data: siteData, loading: loadingSites } = useQuery(FETCH_SITES, {
    fetchPolicy: "cache-and-network",
    skip: filterType !== "Sales Team and Rep",
  });

  const sequences = useMemo(() => {
    if (sequenceData?.fetchSequences) {
      return sequenceData.fetchSequences
        ?.filter((sequence: any) => !!sequence?.sequence_entry_criteria)
        ?.map((sequence: any) => ({
          id: sequence?.id,
          name: sequence?.name,
          current_phase:
            // length > 1 is cold call aggregate
            sequence?.sequence_entry_criteria.current_phase?.length > 1
              ? "ColdCall"
              : sequence?.sequence_entry_criteria.current_phase?.[0],
        }));
    }
    return [];
  }, [sequenceData]);

  const [checkedSequences, setCheckedSequences] = useState<string[]>(
    getLocalStorage("sequence_reporting_filters").selectedSequences ?? [],
  );
  const [checkedPhases, setCheckedPhases] = useState<string[]>(
    getLocalStorage("sequence_reporting_filters").selectedPhases ?? [],
  );
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    getLocalStorage("sequence_reporting_filters")?.selectedSalesTeamsAndReps?.roles ?? [],
  );
  const [selectedTeams, setSelectedTeams] = useState<string[]>(
    getLocalStorage("sequence_reporting_filters")?.selectedSalesTeamsAndReps?.teams ?? [],
  );
  const [selectedReps, setSelectedReps] = useState<string[]>(
    getLocalStorage("sequence_reporting_filters")?.selectedSalesTeamsAndReps?.reps ?? [],
  );
  const [selectedSites, setSelectedSites] = useState<string[]>(
    getLocalStorage("sequence_reporting_filters")?.selectedSalesTeamsAndReps?.sites ?? [],
  );

  useEffect(() => {
    // if no SDRs or AEs are selected, remove the role from selectedRoles

    const sdrCount = selectedReps.filter(
      (rep_id: string) =>
        repData?.fetchReps?.find((rep: { id: string; full_name: string; role: string }) => rep?.id === rep_id)?.role ===
        "SDR",
    ).length;
    const aeCount = selectedReps.filter(
      (rep_id: string) =>
        repData?.fetchReps?.find((rep: { id: string; full_name: string; role: string }) => rep?.id === rep_id)?.role ===
        "AE",
    ).length;

    if (!sdrCount && selectedRoles.includes("SDR")) {
      setSelectedRoles(selectedRoles.filter((role: string) => role !== "SDR"));
    }
    if (!aeCount && selectedRoles.includes("AE")) {
      setSelectedRoles(selectedRoles.filter((role: string) => role !== "AE"));
    }
  }, [selectedReps]);

  const handleCheck = (
    value: string | string[],
    oldValue?: string[],
    setter?: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    switch (filterType) {
      case "Sequence": {
        if (checkedSequences.includes(value as string)) {
          setCheckedSequences(checkedSequences.filter((sequenceId: string) => sequenceId !== value));
        } else {
          setCheckedSequences([...checkedSequences, value as string]);
        }
        break;
      }
      case "Phase": {
        let newPhases = [...checkedPhases];
        if (Array.isArray(value)) {
          // if isArray, we are looking at cold call aggregate
          value.forEach((ccp: string) => {
            if (checkedPhases.includes(ccp)) {
              newPhases = newPhases.filter((phase: string) => phase !== ccp);
            } else {
              newPhases.push(ccp);
            }
          });
        } else {
          if (checkedPhases.includes(value)) {
            newPhases = newPhases.filter((phase: string) => phase !== value);
          } else {
            newPhases.push(value);
          }
        }
        setCheckedPhases(newPhases);
        break;
      }
      case "Sales Team and Rep": {
        if (oldValue && setter) {
          if (oldValue.includes(value as string)) {
            setter(oldValue.filter((ele: string) => ele !== value));
          } else {
            const newSelectedReps = [...selectedReps];
            repData?.fetchReps?.forEach(
              (rep: { id: string; full_name: string; role: string }) =>
                rep?.role === value && !selectedReps?.includes(rep?.id) && newSelectedReps.push(rep?.id),
            );
            setSelectedReps(newSelectedReps);
            setter([...oldValue, value as string]);
          }
        }
        break;
      }
      default:
        break;
    }
  };

  const SequenceOption = ({ sequence, index }: any) => (
    <FlexDiv align="center" key={`${sequence?.id}-${index}`} style={{ paddingTop: "16px" }}>
      <PhoenixCheckbox checked={checkedSequences.includes(sequence?.id)} onChange={() => handleCheck(sequence?.id)} />
      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
        {sequence?.name}
      </AppText>
    </FlexDiv>
  );

  return (
    <>
      <DarkDiv noFade />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            color={theme.PRIMARY500}
            size={24}
            pointer
            onClick={() => {
              setShowSequenceReportingFilterModal(false);
            }}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            Add {filterType} Filters
          </AppText>
        </ModalHeader>

        <ModalBody
          direction="column"
          gap={filterType === "Phase" ? 16 : 24}
          style={{ padding: filterType === "Sales Team and Rep" ? 0 : "40px" }}
        >
          {filterType === "Sequence" && (
            <>
              <FlexDiv direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
                  Cold Call
                </AppText>
                {sequences?.filter((sequence: any) => sequence.current_phase === "ColdCall")?.length ? (
                  sequences
                    ?.filter((sequence: any) => sequence.current_phase === "ColdCall")
                    ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} index={index} />)
                ) : (
                  <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                    No sequences available.
                  </AppText>
                )}
              </FlexDiv>
              <FlexDiv direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
                  Demo set for future date
                </AppText>
                {sequences?.filter((sequence: any) => sequence.current_phase === "HeldPhase")?.length ? (
                  sequences
                    ?.filter((sequence: any) => sequence.current_phase === "HeldPhase")
                    ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} index={index} />)
                ) : (
                  <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                    No sequences available.
                  </AppText>
                )}
              </FlexDiv>
              <FlexDiv direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
                  Demo not held at set time
                </AppText>
                {sequences?.filter((sequence: any) => sequence.current_phase === "PostInitialHelPhaseDemoNotHeld")
                  ?.length ? (
                  sequences
                    ?.filter((sequence: any) => sequence.current_phase === "PostInitialHelPhaseDemoNotHeld")
                    ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} index={index} />)
                ) : (
                  <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                    No sequences available.
                  </AppText>
                )}
              </FlexDiv>
              <FlexDiv direction="column">
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.NEUTRAL300}>
                  Demo held
                </AppText>
                {sequences?.filter((sequence: any) => sequence.current_phase === "PostHoldPhase")?.length ? (
                  sequences
                    ?.filter((sequence: any) => sequence.current_phase === "PostHoldPhase")
                    ?.map((sequence: any, index: number) => <SequenceOption sequence={sequence} index={index} />)
                ) : (
                  <AppText fontSize={10} fontWeight={400} color={theme.NEUTRAL300} style={{ paddingTop: "4px" }}>
                    No sequences available.
                  </AppText>
                )}
              </FlexDiv>
            </>
          )}

          {filterType === "Phase" && (
            <>
              <FlexDiv>
                <PhoenixCheckbox
                  checked={["ColdCallCold", "ColdCallWorkingNum", "ColdCallNDM", "ColdCallDM"].every((ele: string) =>
                    checkedPhases.includes(ele),
                  )}
                  onChange={() => handleCheck(["ColdCallCold", "ColdCallWorkingNum", "ColdCallNDM", "ColdCallDM"])}
                />
                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Cold Call
                </AppText>
              </FlexDiv>
              <FlexDiv>
                <PhoenixCheckbox
                  checked={checkedPhases.includes("HeldPhase")}
                  onChange={() => handleCheck("HeldPhase")}
                />
                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Demo set for future date
                </AppText>
              </FlexDiv>
              <FlexDiv>
                <PhoenixCheckbox
                  checked={checkedPhases.includes("PostInitialHelPhaseDemoNotHeld")}
                  onChange={() => handleCheck("PostInitialHelPhaseDemoNotHeld")}
                />

                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Demo not held at set time
                </AppText>
              </FlexDiv>
              <FlexDiv>
                <PhoenixCheckbox
                  checked={checkedPhases.includes("PostHoldPhase")}
                  onChange={() => handleCheck("PostHoldPhase")}
                />
                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                  Demo held
                </AppText>
              </FlexDiv>
            </>
          )}

          {filterType === "Sales Team and Rep" && (
            <>
              {!![...selectedRoles, ...selectedReps, ...selectedTeams].length && (
                <FlexDiv
                  direction="column"
                  gap={16}
                  style={{ padding: "16px 24px", borderBottom: `1px solid ${theme.NEUTRAL200}` }}
                >
                  <FlexDiv wrap="wrap" gap={4} style={{ maxHeight: "250px", overflowY: "auto" }}>
                    {selectedRoles?.map((role: string) => (
                      <FilterPill type="roles" text={role}>
                        {role}
                        <PhoenixIcon
                          svg={xIcon}
                          size={12}
                          pointer
                          color={theme.WHITE_COLOR}
                          hoverColor={theme.DANGER700}
                          onClick={() => setSelectedRoles(selectedRoles.filter((ele: string) => ele !== role))}
                        />
                      </FilterPill>
                    ))}
                    {selectedTeams?.map((team_id: string) => (
                      <FilterPill type="team_ids">
                        {teamData?.fetchTeams?.find((team: any) => team?.id === team_id)?.name}
                        <PhoenixIcon
                          svg={xIcon}
                          size={12}
                          pointer
                          color={theme.PRIMARY500}
                          hoverColor={theme.DANGER700}
                          onClick={() => setSelectedTeams(selectedTeams.filter((ele: string) => ele !== team_id))}
                        />
                      </FilterPill>
                    ))}
                    {selectedReps?.map((rep_id: string) => (
                      <FilterPill type="rep_ids">
                        {repData?.fetchReps?.find((rep: any) => rep?.id === rep_id)?.full_name}
                        <PhoenixIcon
                          svg={xIcon}
                          size={12}
                          pointer
                          color={theme.PRIMARY500}
                          hoverColor={theme.DANGER700}
                          onClick={() => setSelectedReps(selectedReps.filter((ele: string) => ele !== rep_id))}
                        />
                      </FilterPill>
                    ))}
                  </FlexDiv>
                  <ResetFilters
                    onClick={() => {
                      setSelectedRoles([]);
                      setSelectedTeams([]);
                      setSelectedReps([]);
                    }}
                  />
                </FlexDiv>
              )}
              <FlexDiv direction="column" gap={16} style={{ padding: "16px 40px 0px 40px" }}>
                <CabinetContainer direction="column" gap={16}>
                  <FlexDiv justify="space-between">
                    <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                      Roles
                    </AppText>
                    <PhoenixIcon
                      svg={showRoles ? chevron_up : chevron_down}
                      size={24}
                      color={theme.PRIMARY500}
                      hoverColor={theme.PRIMARY500}
                      pointer
                      onClick={() => setShowRoles(!showRoles)}
                    />
                  </FlexDiv>

                  {showRoles && (
                    <FlexDiv gap={24}>
                      <FlexDiv>
                        <PhoenixCheckbox
                          checked={selectedRoles.includes("SDR")}
                          onChange={() => handleCheck("SDR", selectedRoles, setSelectedRoles)}
                        />
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          SDR
                        </AppText>
                      </FlexDiv>
                      <FlexDiv>
                        <PhoenixCheckbox
                          checked={selectedRoles.includes("AE")}
                          onChange={() => handleCheck("AE", selectedRoles, setSelectedRoles)}
                        />
                        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                          AE
                        </AppText>
                      </FlexDiv>
                    </FlexDiv>
                  )}
                </CabinetContainer>

                <CabinetContainer direction="column" gap={16}>
                  <FlexDiv justify="space-between" align="center">
                    <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                      Teams
                    </AppText>
                    <PhoenixIcon
                      svg={showTeams ? chevron_up : chevron_down}
                      size={24}
                      color={theme.PRIMARY500}
                      hoverColor={theme.PRIMARY500}
                      pointer
                      onClick={() => setShowTeams(!showTeams)}
                    />
                  </FlexDiv>

                  {showTeams && (
                    <>
                      <PhoenixInput
                        placeholder="Search Sales Teams"
                        variant="primary"
                        type="text"
                        searchInput
                        fontSize={10}
                        fontWeight={400}
                        displayNoContextText
                        value={teamSearch}
                        onChange={(e: any) => setTeamSearch(e.target.value)}
                        height={40}
                      />
                      <ResetFilters onClick={() => setSelectedTeams([])} disabled={!selectedTeams.length} />
                      <CabinetOverflowContainer direction="column" gap={16}>
                        {!loadingTeams ? (
                          teamData?.fetchTeams
                            ?.slice()
                            ?.filter((team: { id: string; name: string }) =>
                              team?.name?.toLowerCase().includes(teamSearch.toLowerCase()),
                            )
                            ?.sort((a: { id: string; name: string }, b: { id: string; name: string }) =>
                              a?.name?.localeCompare(b?.name),
                            )
                            ?.map((team: { id: string; name: string }) => (
                              <FlexDiv align="center" key={`team-item-${team?.id}`}>
                                <PhoenixCheckbox
                                  checked={selectedTeams.includes(team?.id)}
                                  onChange={() => handleCheck(team?.id, selectedTeams, setSelectedTeams)}
                                />
                                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                  {team?.name}
                                </AppText>
                              </FlexDiv>
                            ))
                        ) : (
                          <Loading />
                        )}
                      </CabinetOverflowContainer>
                    </>
                  )}
                </CabinetContainer>

                <CabinetContainer direction="column" gap={16}>
                  <FlexDiv justify="space-between" align="center">
                    <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                      Reps
                    </AppText>
                    <PhoenixIcon
                      svg={showReps ? chevron_up : chevron_down}
                      size={24}
                      color={theme.PRIMARY500}
                      hoverColor={theme.PRIMARY500}
                      pointer
                      onClick={() => setShowReps(!showReps)}
                    />
                  </FlexDiv>

                  {showReps && (
                    <>
                      <PhoenixInput
                        placeholder="Search Reps"
                        variant="primary"
                        type="text"
                        searchInput
                        fontSize={10}
                        fontWeight={400}
                        displayNoContextText
                        value={repSearch}
                        onChange={(e: any) => setRepSearch(e.target.value)}
                        height={40}
                      />
                      <ResetFilters onClick={() => setSelectedReps([])} disabled={!selectedReps.length} />
                      <CabinetOverflowContainer direction="column" gap={16}>
                        {!loadingReps ? (
                          repData?.fetchReps
                            ?.slice()
                            ?.filter((rep: { id: string; full_name: string }) =>
                              rep?.full_name?.toLowerCase().includes(repSearch.toLowerCase()),
                            )
                            ?.sort((a: { id: string; full_name: string }, b: { id: string; full_name: string }) =>
                              a?.full_name?.localeCompare(b?.full_name),
                            )
                            ?.map((rep: { id: string; full_name: string }) => (
                              <FlexDiv align="center" key={`rep-item-${rep?.id}`}>
                                <PhoenixCheckbox
                                  checked={selectedReps.includes(rep?.id)}
                                  onChange={() => handleCheck(rep?.id, selectedReps, setSelectedReps)}
                                />
                                <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                  {rep?.full_name}
                                </AppText>
                              </FlexDiv>
                            ))
                        ) : (
                          <Loading />
                        )}
                      </CabinetOverflowContainer>
                    </>
                  )}
                </CabinetContainer>

                <CabinetContainer direction="column" gap={16}>
                  <FlexDiv justify="space-between" align="center">
                    <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                      Sites
                    </AppText>
                    <PhoenixIcon
                      svg={showSites ? chevron_up : chevron_down}
                      size={24}
                      color={theme.PRIMARY500}
                      hoverColor={theme.PRIMARY500}
                      pointer
                      onClick={() => setShowSites(!showSites)}
                    />
                  </FlexDiv>

                  {showSites && (
                    <CabinetOverflowContainer direction="column" gap={16}>
                      {!loadingSites ? (
                        siteData?.fetchOrganization?.Sites?.slice()
                          ?.sort((a: { id: string; name: string }, b: { id: string; name: string }) =>
                            a?.name?.localeCompare(b?.name),
                          )
                          ?.map((site: { id: string; name: string }) => (
                            <FlexDiv align="center" key={`site-item-${site?.id}`}>
                              <PhoenixCheckbox
                                checked={selectedSites.includes(site?.id)}
                                onChange={() => handleCheck(site?.id, selectedSites, setSelectedSites)}
                              />
                              <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                                {site?.name}
                              </AppText>
                            </FlexDiv>
                          ))
                      ) : (
                        <Loading />
                      )}
                      {!loadingSites && !siteData?.fetchOrganization?.Sites?.length && (
                        <AppText fontSize={12} color={theme.NEUTRAL300}>
                          No site options available.
                        </AppText>
                      )}
                    </CabinetOverflowContainer>
                  )}
                </CabinetContainer>
              </FlexDiv>
            </>
          )}
        </ModalBody>

        <ModalFooter justify="space-between">
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            buttonTextFontSize={10}
            uppercase
            onClick={() => {
              setShowSequenceReportingFilterModal(false);
            }}
          >
            Cancel
          </PhoenixAppButton>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            buttonTextFontSize={10}
            uppercase
            onClick={() => {
              onClose(
                filterType === "Sequence"
                  ? checkedSequences
                  : filterType === "Phase"
                  ? checkedPhases
                  : ({ roles: selectedRoles, teams: selectedTeams, reps: selectedReps, sites: selectedSites } as any),
              );
              setShowSequenceReportingFilterModal(false);
            }}
          >
            Apply Filters
          </PhoenixAppButton>
        </ModalFooter>
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${theme.fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 40px;

  max-height: 80vh;
  min-height: 70vh;
  margin-bottom: auto;

  overflow-y: overlay;
  overflow-x: hidden;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;

const CabinetContainer = styled(FlexDiv)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const CabinetOverflowContainer = styled(FlexDiv)`
  max-height: 40vh;
  overflow-y: overlay;
`;

const ResetFilters = ({ onClick, disabled }: { onClick: () => void; disabled?: boolean }) => {
  return (
    <FlexDiv
      align="center"
      gap={8}
      style={{ cursor: !disabled ? "pointer" : "unset" }}
      onClick={() => !disabled && onClick()}
    >
      <PhoenixIcon
        svg={refresh}
        color={!disabled ? theme.PRIMARY500 : theme.PRIMARY200}
        hoverColor={!disabled ? theme.PRIMARY500 : theme.PRIMARY200}
        size={12}
        pointer={!disabled}
      />
      <AppText fontSize={10} fontWeight={500} lineHeight={14} color={!disabled ? theme.PRIMARY500 : theme.PRIMARY200}>
        Reset Filters
      </AppText>
    </FlexDiv>
  );
};

interface FilterPillProps {
  type: "rep_ids" | "team_ids" | "roles";
  text?: string;
}

const FilterPill = styled.div<FilterPillProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: fit-content;
  height: 24px;
  padding: 4px 8px;

  background-color: ${(props) => {
    switch (props.type) {
      case "roles":
        if (props.text === "SDR") {
          return theme.PRIMARY500;
        }
        return theme.WARNING500;
      default:
        return theme.PRIMARY200;
    }
  }};
  color: ${(props) => {
    switch (props.type) {
      case "roles":
        if (props.text === "SDR") {
          return theme.WHITE_COLOR;
        }
        return theme.BLACK_COLOR;
      default:
        return theme.PRIMARY500;
    }
  }};

  border-radius: 4px;

  text-transform: uppercase;
  line-height: 12px;
  font-size: 8px;
  font-weight: 600;
`;
