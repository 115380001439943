import styled from "styled-components";
import * as React from "react";
import {
  Loading,
  AppSidebarCard,
  AppErrorText,
  AppText,
  NewAppSelect,
  FlexDiv,
  LeadCardLeadHistoryItem,
} from "../../UI";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { LeadCardV2 } from "../DashboardSideBarSegments";
import { CORE_LEAD_CARD_DATA } from "../../../apollo/fragments";
import { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LeadActivity } from "src/__generated__/graphql";
import { CurrentLeadType } from "src/types";
import { RecentActivityItem } from "./LeadCardV2/RecentActivityItem";
import { LeadCardContext } from "src/context/LeadCardContext";
import { SaveToFolderModal } from "src/Components/modal/SaveToFolderModal";

const FETCH_RECENT_ACTIVITIES = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchRecentActivities($skip: Int, $take: Int, $communication_type: CommunicationType) {
    fetchRecentActivities(skip: $skip, take: $take, communication_type: $communication_type) {
      id
      user {
        id
        first_name
        last_name
        full_name
        role
        profile_image
        phone_number
      }
      local_number {
        number
      }
      customer_number
      channel
      lead {
        id
        first_name
        last_name
        rep_id
        full_name
        associate_parent_id
        lead_ownership_status
        business_name
        primary_phone_number
        next_intent_scheduled_or_unscheduled {
          id
          type
        }
        ...CoreLeadCardData
      }
      SMSItem {
        id
        sent_at
        text
        isFromRep
        sender_label
        lead_number_used
        lead_activity {
          id
        }
        sequence_name
      }
      email_item {
        id
        from_email
        to_email
        subject
        channel
        sender_label
        body
        html
        sent_at
        opened_at
        clicked_at
        email_thread_id
      }
      type
      computed_title
      created_at
      is_last_activity
      created_at
      communication_type
      title
      conference {
        id
        start_time
        duration
        recording_url_computed
      }
      related_disposition {
        id
        label
        computed_call_history_theme {
          completed_with_theme {
            label
            bg_color
            font_color
          }
          call_result_theme {
            label
            bg_color
            font_color
          }
        }
      }
      related_disposition_reason
      call_completed_with
      schedule_item {
        id
        start_time
      }
      stat_item_aggregation {
        id
        set
        disregarded_set
        hold
        disregarded_hold
        sale
        otf
        dm_connect
        ndm_connect
        initial_set
        initial_hold
        rescheduled_set
        rescheduled_hold
        closing_opp_scheduled
        closing_opp_held
        followup_hold
      }
      associate_child {
        id
        first_name
        last_name
        full_name
        associate_parent_id
      }
    }
  }
`;
interface CurrentLeadProps {
  closeOtherModals?: any;
}

const RecentActivitiesComponent: React.FC<CurrentLeadProps> = ({ closeOtherModals }) => {
  const { limitUserInfiniteScroll } = useFlags();
  const [recentActivities, setRecentActivities] = useState<any[]>([]);
  const [recentActivitiesSkip, setRecentActivitiesSkip] = useState(0);
  const [recentActivitiesHasMore, setRecentActivitiesHasMore] = useState(true);
  const [currentConferenceID, setCurrentConferenceID] = useState("");
  const [showSaveToFolderModal, setShowSaveToFolderModal] = useState(false);

  const { activitiesCommunicationType, setActivitiesCommunicationType } = useContext(LeadCardContext);

  //Queries
  const { data: dataRecents, loading: loadingRecents, error: errorRecents, fetchMore: fetchMoreRecents } = useQuery(
    FETCH_RECENT_ACTIVITIES,
    {
      fetchPolicy: "network-only",
      variables: {
        skip: recentActivitiesSkip,
        // If you change the take value, you must change the length check below to match as well as setSkip in handleShowMore.
        take: 4,
        communication_type: activitiesCommunicationType === "All" ? undefined : activitiesCommunicationType,
      },
      onCompleted() {
        const newList = [...recentActivities, ...dataRecents.fetchRecentActivities].reduce(
          (acc, cv) => (acc.map((a: any) => a.id).includes(cv.id) ? acc : [...acc, cv]),
          [],
        );
        if (
          dataRecents.fetchRecentActivities.length < 4 ||
          // temp fix for performance issue for a specific user
          // should be fixed using virtualized list
          (limitUserInfiniteScroll && recentActivities?.length >= 26)
        ) {
          setRecentActivitiesHasMore(false);
        }
        setRecentActivities(newList);
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  //Custom Functions
  const handleShowMore = () => {
    setRecentActivitiesSkip(recentActivitiesSkip + 4);
    fetchMoreRecents({
      variables: {
        skip: recentActivitiesSkip,
        communication_type: activitiesCommunicationType === "All" ? undefined : activitiesCommunicationType,
      },
    });
  };

  // if (loadingRecents && !!!recentActivities.length) return <Loading />;
  // if (errorRecents) return <AppErrorText>Error loading recent activities</AppErrorText>;
  // if (recentActivities?.length <= 0 || loadingRecents || recentActivitiesRefreshNow) return <></>;

  return (
    <AppSidebarCard showExpand trackMixpanel title={"Recent Activities"} width={430}>
      {showSaveToFolderModal && currentConferenceID && (
        <SaveToFolderModal
          blinds={showSaveToFolderModal}
          setBlinds={setShowSaveToFolderModal}
          conferenceID={currentConferenceID}
          moveCall={false}
        />
      )}
      <FilterDiv>
        <FlexDiv align="center" gap={8} style={{ width: "100%" }}>
          <AppText fontSize={12} fontWeight={500} style={{ whiteSpace: "nowrap" }}>
            Filter by Type
          </AppText>
          <div style={{ width: "100%" }}>
            <NewAppSelect
              options={[
                { label: "Show All", value: "All" },
                { label: "Phone", value: "Phone" },
                { label: "SMS", value: "SMS" },
                { label: "Email", value: "Email" },
              ]}
              value={activitiesCommunicationType}
              onChange={async (e: any) => {
                if (e?.value !== activitiesCommunicationType) {
                  setRecentActivitiesSkip(0);
                  e?.value && setActivitiesCommunicationType(e?.value);
                }
                setRecentActivities([]);
                await fetchMoreRecents({
                  variables: {
                    skip: 0,
                    communication_type: e?.value === "All" ? undefined : e?.value,
                  },
                });
              }}
              noErrorNeeded
            />
          </div>
        </FlexDiv>
      </FilterDiv>
      {/* {loadingRecents && !!!recentActivities.length && <Loading />} */}
      {errorRecents && <AppErrorText>Error loading recent activities</AppErrorText>}
      <ScrollDiv id="recent-activities-scroll-div">
        <InfiniteScroll
          dataLength={recentActivities?.length}
          next={handleShowMore}
          hasMore={recentActivitiesHasMore}
          loader={<Loading />}
          scrollableTarget="recent-activities-scroll-div"
        >
          {recentActivities?.map((item: any, index: number) => (
            <RecentActivityItem
              isAssociatedLead={!!item?.lead?.associate_parent_id}
              showExtendedInfoSections
              key={item.id}
              item={item}
              primary_lead_id={item?.lead?.id}
              leadData={item?.lead}
              associate_lead_ids={
                item?.lead?.associate_contact_ids?.map((lead: CurrentLeadType) => {
                  return lead?.id || "";
                }) ?? []
              }
              contact_id={item?.lead_id}
              setCurrentConferenceID={setCurrentConferenceID}
              setShowSaveToFolderModal={setShowSaveToFolderModal}
              backgroundColor={theme.WHITE_COLOR}
              showRepIcon={false}
            />
          ))}
          {/* {recentActivities.map((item: any, index: number) => (
            <AppText key={index}>{JSON.stringify(item)}</AppText>
          ))} */}
        </InfiniteScroll>
      </ScrollDiv>
    </AppSidebarCard>
  );
};

const FilterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

interface BorderProps {
  borderTop?: boolean;
}

const BorderDiv = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.border.neutral.secondary};
  margin: 8px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  height: 400px;
`;

export { RecentActivitiesComponent };
