import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IS_LOGGED_IN } from "../apollo/query";
import { useQuery } from "@apollo/client";
import { Loading } from "../Components/UI";

interface NoAuthRoute {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = NoAuthRoute;

export const NoAuthRouteComponent = ({
  component: Component,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  return (
    <div id="root">
      <Route {...rest} component={(props: any) => <Component {...props} />} />
    </div>
  );
};

const NoAuthRoute = NoAuthRouteComponent;

export default NoAuthRoute;
