import styled from "styled-components";
import * as React from "react";
import { NewAppButton, AppErrorText, AppSidebarCard } from "../UI";

interface EventItemProps {
  refetch: () => void;
  error: string;
}

const GenericError: React.FC<EventItemProps> = ({ refetch, error }) => {
  return (
    <PaddingDiv>
      <AppErrorText>{error}</AppErrorText>
      <NewAppButton variant={"primary"} onClick={refetch}>
        Reload
      </NewAppButton>
    </PaddingDiv>
  );
};

const PaddingDiv = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
export { GenericError };
