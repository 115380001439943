import styled from "styled-components";
import React, { useState, useContext } from "react";
import { AppText } from "../../UI";
import { theme } from "../../../utils/theme";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { info } from "../../../images/NewDesign";
import { useHistory } from "react-router-dom";
import { PhoenixIcon } from "../../UI/Phoenix";
import { PanelModal, TransferRuleModal } from "../../modal";
import { ModalContext } from "../../../context";
import "../Onboarding/transfer-tab/TransferRulesTable.css";
import TransferRuleTab from "./transfer-tab/TransferRuleTab";
import TransferRulesSettings from "./transfer-tab/TransferRulesSettings";
import { AppButton } from "../../UI";
interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
  togglePageTitle?: () => void;
  rule_id?: string;
  tooltipText?: string;
}

const StepTransferRulesV2: React.FC<DisappearingDivProps> = ({ step, isOnboarding, tooltipText }) => {
  const {
    showTransferRuleModal,
    setShowTransferRuleModal,
    setTransferRuleModalData,
    transferRuleModalData,
  } = useContext(ModalContext);

  const [selectedTab, setSelectedTab] = useState("Transfers");

  const history = useHistory();

  return (
    <>
      {showTransferRuleModal && (
        <PanelModal
          width={542}
          title="Transfer Rules"
          onClose={() => setShowTransferRuleModal(false)}
          open={showTransferRuleModal}
        >
          <TransferRuleModal />
        </PanelModal>
      )}
      <Page>
        <PhoenixStyledTooltip id="transfer-rules-tooltip" fontSize={10} />
        <TopAlignDiv>
          <OptionsDiv>
            <OptionsLeft>
              <RowDiv>
                <AppText fontSize={22} fontWeight={500} noWrap>
                  Transfer Rules
                </AppText>
                <PhoenixIcon
                  data-tip={tooltipText || ""}
                  data-for="transfer-rules-tooltip"
                  variant="brand"
                  svg={info}
                  size={16}
                />
              </RowDiv>
              <Tabs>
                <Tab
                  selected={selectedTab === "Transfers"}
                  onClick={() => {
                    setSelectedTab("Transfers");
                    setTransferRuleModalData({
                      ...transferRuleModalData,
                      rule_type: "Transfer",
                    });
                  }}
                >
                  Live Transfers
                </Tab>
                <Tab
                  selected={selectedTab === "Bookings"}
                  onClick={() => {
                    setSelectedTab("Bookings");
                    setTransferRuleModalData({
                      ...transferRuleModalData,
                      rule_type: "Booking",
                    });
                  }}
                >
                  Future Bookings
                </Tab>
                <Tab selected={selectedTab === "Settings"} onClick={() => setSelectedTab("Settings")}>
                  Settings
                </Tab>
              </Tabs>
            </OptionsLeft>
            <OptionsRight />
          </OptionsDiv>
        </TopAlignDiv>

        <Main>
          {selectedTab === "Transfers" || selectedTab === "Bookings" ? (
            <TransferRuleTab selectedTab={selectedTab} />
          ) : (
            <TransferRulesSettings />
          )}
          {isOnboarding === true && (
            <CenterDiv>
              <SaveAndContinueButton type="submit" onClick={() => history.push(`/onboarding?step=${step + 1}`)}>
                Continue
              </SaveAndContinueButton>
            </CenterDiv>
          )}
        </Main>
      </Page>
    </>
  );
};

interface SelectedProps {
  selected?: boolean;
}

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const Tab = styled(AppText)<SelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: solid 2px ${(props) => (props.selected ? theme.BLACK_COLOR : "transparent")};
  padding-bottom: 6px;
  margin-top: 2px;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  text-transform: uppercase;
`;
const RowDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-left: 24px;
`;

const Page = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
  padding-top: 36px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${theme.LIGHT_BLUE};
  width: 100%;
  height: fit-content;

  border-top: 1px solid ${theme.NEUTRAL200};
  gap: 16px;
  padding: 48px;
  margin: 0px;
`;

const TopAlignDiv = styled.div`
  height: 90px;
  display: flex;
  justify-items: center;
  margin: 0px 24px;
`;

const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const OptionsLeft = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const OptionsRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export { StepTransferRulesV2 };
