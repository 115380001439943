import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText, Loading } from "src/Components/UI";
import { NewAppButton } from "src/Components/UI/NewAppButton";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { Modal } from "../Modal";
import { SequenceCanvas } from "src/Components/UI/Sequences/SequenceCanvas";
import { ModalContext } from "src/context";
import { useContext, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { appToast } from "src/utils/toast";
import { loggedInUser } from "src/apollo/cache";
import { cloneDeep } from "lodash";

const FETCH_SEQUENCE = gql`
  query fetchSequence($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
      active_steps {
        id
        origin_id
        is_last_step
        is_host_step
        is_no_branch
        lead_count
        conditions {
          id
          dispositionType
        }
        actions {
          id
          template_id
          task
          delay_amount
          custom_task_note
          scheduled_event_behavior
        }
      }
    }
  }
`;

const SequenceRepViewModal: React.FC = () => {
  const { showRepViewSequenceModal, setShowRepViewSequenceModal } = useContext(ModalContext);

  const { sequence_id, visible } = showRepViewSequenceModal;

  const { data: dataSequence, loading: loadingSequence, error: errorSequence, refetch: refetchSequence } = useQuery(
    FETCH_SEQUENCE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      skip: !sequence_id,
      variables: {
        sequencesFilter: { id: sequence_id },
      },
      onCompleted({ fetchSequences }) {
        console.log("fetchSequence:", fetchSequences);

        if (!fetchSequences.length) {
          appToast("Sequence not found");
          setShowRepViewSequenceModal({ visible: false, sequence_id: "", sequence_step_id: "" });
          // appToast("Redirecting...");
          // setTimeout(() => {
          //   history.push(`/sequences/`);
          // }, 3000);
        }

        // if (!hasSequenceBeenLoaded.current) {
        //   initialSequence.current = fetchSequences[0];
        //   hasSequenceBeenLoaded.current = true;
        // }

        // currentSequence.current = fetchSequences[0];

        // skipLoadingCycle && setSkipLoadingCycle(false);
      },
      onError({ message, name }) {
        appToast("Could not load sequence");
        setShowRepViewSequenceModal({ visible: false, sequence_id: "", sequence_step_id: "" });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const sequence = useMemo(() => {
    const data = cloneDeep(dataSequence?.fetchSequences?.[0]);
    const step = data?.active_steps?.find((as: any) => as.origin_id === null);

    // hide first step delay for FieldPulse, FieldPulse sandbox, and our test org.
    // ^ product team request.
    if (
      step &&
      !!step.actions?.length &&
      [
        "91f1b9c2-009b-48f9-93e5-b044788c1aa5",
        "ece14be5-953f-4213-b102-9c409ccbba14",
        "35372a5d-8395-4f2e-974f-4ac1d0216a3e",
      ].includes(loggedInUser().organization_id as string)
    ) {
      step.actions[0].delay_amount = 0;
    }

    return data;
  }, [dataSequence]);

  return (
    <Modal
      open={visible}
      onClose={() => setShowRepViewSequenceModal({ visible: false, sequence_id: "", sequence_step_id: "" })}
      popupAnimation
    >
      <ModalContent>
        <HeaderDiv>{sequence?.name ? `Sequence: ${sequence?.name}` : "Current Lead Sequence"}</HeaderDiv>
        <BodyDiv>
          {loadingSequence ? (
            <Loading />
          ) : (
            <SequenceCanvas
              repView
              initialSteps={sequence?.active_steps}
              active={!sequence?.is_paused}
              sequenceData={sequence}
            />
          )}
        </BodyDiv>
      </ModalContent>
    </Modal>
  );
};

const SubmitFlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
`;

const ModalContent = styled.div`
  width: 700px;
  height: 900px;
`;
const DeleteText = styled(AppText)`
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const HeaderDiv = styled.div`
  position: relative;
  padding: 10px 40px;
  overflow: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border-neutral-secondary, #e1e2e7);
  background: var(--fill-neutral-primary, #fff);
  margin-top: 10px;
`;

const BodyDiv = styled.div`
  position: relative;
  padding: 40px;
  overflow: auto;
  width: 100%;
  height: 100%;
  margin-bottom: 88px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const WarningText = styled(AppText)`
  font-size: 10px;
  color: ${theme.text.danger.primary};
  text-align: center;
  margin-top: 16px;
`;

export { SequenceRepViewModal };
