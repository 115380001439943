import React, { useContext, useState, useMemo } from "react";
import { ModalContext } from "../../../context";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import "../../../Components/Segments/Onboarding/SalesTeamsList.css";
import { AgGridReact } from "ag-grid-react";
import { theme } from "../../../utils/theme";
import { Loading } from "../../UI";
import { AppText } from "../../UI/AppText";
import { PhoenixIcon } from "../../UI/Phoenix";
import { edit, trash } from "../../../images/NewDesign";
import styled from "styled-components";
import { chevron_left } from "../../../images/NewDesign";
import { ProfileImageWithText } from "../../Segments/SettingSegments/ProfileImageWithText";
import RenderAssociateTitle from "./RenderAssociateTitle";
import moment from "moment";
import { formatUSDRaw } from "../../../utils/format";
import { appToast } from "../../../utils/toast";
import "./individual-sale-table.css";
import { calculateProductPrice } from "../../../utils/misc";

const FETCH_SALE_DETAILS = gql`
  query FetchSaleDetail($sale_id: String!) {
    fetchSaleDetail(sale_id: $sale_id) {
      id
      contract_duration
      payment_terms
      prepayment
      total_price
      created_at
      sale_items {
        id
        price_override
        quantity
        prepayment
        product {
          id
          product_title
          pricing
        }
        concession {
          id
          amount
        }
      }
      lead {
        full_name
        id
      }
      lead_activity {
        user {
          profile_image
          id
          first_name
          last_name
          full_name
        }
      }
    }
  }
`;

const FETCH_ORG_OPTIONS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      show_payment_terms_sales
      show_contract_duration_sales
      require_sale_notes
      prepayment
    }
  }
`;

const IndividualSaleTable = ({
  primaryLeadId,
  associatedLeadIds,
  refetchAllSales,
}: {
  primaryLeadId: string;
  associatedLeadIds: string[];
  refetchAllSales: () => void;
}) => {
  const {
    selectedSale,
    setSelectedSale,
    resetSelectedSale,
    setShowUpdateProductModal,
    setShowDeleteProductModal,
    showUpdateProductModal: ShowUpdateProductModal,
    showUpdateTermsModal: ShowUpdateTermsModal,
  } = useContext(ModalContext);
  const [rowData, setRowData] = useState<any>([]);

  const { data: orgData, loading: orgLoading, error: orgError } = useQuery(FETCH_ORG_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("error fetching organization options");
    },
  });
  const BASE_COLUMNS = [
    {
      headerName: "Product",
      field: "product_title",
      minWidth: 200,
    },
    {
      headerName: "Price",
      field: "product_price",
      minWidth: 100,
    },
    {
      headerName: "Override Price",
      field: "price_override",
      minWidth: 125,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      minWidth: 100,
    },
    {
      headerName: "Discount",
      field: "discount",
      minWidth: 100,
    },
    {
      headerName: "Total Cost",
      field: "total_price",
      minWidth: 100,
    },
  ];
  const actionColumn = {
    headerName: "Action",
    field: "action",

    cellRendererFramework: (params: any) => {
      const selectedSaleItemId = params.value as string;
      return <ActionColumnCell selectedSaleItemId={selectedSaleItemId} />;
    },
  };

  const ActionColumnCell = ({ selectedSaleItemId }: { selectedSaleItemId: string }) => {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "20px",
          alignItems: "center",
        }}
      >
        <PhoenixIcon
          size={16}
          variant="brand"
          svg={edit}
          style={{ marginRight: "10px" }}
          onClick={() => {
            console.log("edit");
            setShowUpdateProductModal(true);

            setSelectedSale({
              ...selectedSale,
              sale_item_id: selectedSaleItemId,
              product_update_modal_type: "edit",
            });
          }}
        />
        <PhoenixIcon
          size={16}
          variant="danger"
          svg={trash}
          onClick={() => {
            setSelectedSale({
              ...selectedSale,
              sale_item_id: selectedSaleItemId,
            });

            setShowDeleteProductModal(true);
          }}
        />
      </div>
    );
  };

  // these are hiden by default and will only show if the org has them enabled
  const optionalColumns = [
    {
      headerName: "Contact Duration",
      field: "contract_duration",
      condition: orgData?.fetchOrganization?.show_contract_duration_sales,
      minWidth: 135,
    },
    {
      headerName: "Payment Terms",
      field: "payment_terms",
      condition: orgData?.fetchOrganization?.show_payment_terms_sales,
      minWidth: 125,
    },
    {
      headerName: "Prepayment",
      field: "prepayment",
      condition: orgData?.fetchOrganization?.prepayment,
      minWidth: 100,
    },
  ];
  const columnDefs = [
    ...BASE_COLUMNS,
    ...optionalColumns.filter((column) => column.condition),
    {
      ...actionColumn,
      flex: 1,
      minWidth: 100,
    },
  ];
  columnDefs.forEach((column) => {
    Object.assign(column, {
      resizable: true,
      flex: 1,
    });
  });

  const { data: saleData, loading: saleLoading, error: saleError } = useQuery(FETCH_SALE_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: { sale_id: selectedSale.id },
    skip: !selectedSale.id,
    onError: (error) => {
      console.log("error", error);
    },
    onCompleted: (data) => {
      setRowData(
        data?.fetchSaleDetail?.sale_items
          ?.map((item: any) => {
            const concession = item.concession?.reduce((acc: number, concession: any) => {
              return acc + concession?.amount ?? 0;
            }, 0);
            return {
              product_title: item?.product?.product_title ?? "NA",
              price_override: item?.price_override ?? "NA",
              product_price: item?.product?.pricing ?? "NA",
              quantity: item?.quantity ?? "NA",
              discount: concession ?? "NA",
              action: item?.id ?? "NA",
              total_price:
                calculateProductPrice({
                  price_override: item?.price_override,
                  pricing: item?.product?.pricing,
                  quantity: item?.quantity,
                  concession_amount: concession,
                }) ?? 0,
              // these are for the global sale and will be the same for all items
              contract_duration: data?.fetchSaleDetail?.contract_duration ?? "NA",
              payment_terms: data?.fetchSaleDetail?.payment_terms ?? "NA",
              prepayment: !!item.prepayment ? item.prepayment : data?.fetchSaleDetail?.prepayment ?? "NA",
            };
          })
          .sort((a: any, b: any) => {
            return a?.product_title.localeCompare(b?.product_title);
          }),
      );
    },
  });

  if (saleLoading || orgLoading)
    return (
      <MinHeightDiv>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "517px",
          }}
        >
          <Loading />
        </div>
      </MinHeightDiv>
    );
  if (saleError || orgError)
    return (
      <MinHeightDiv>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "517px",
          }}
        >
          <AppText style={{ color: theme.WARNING500 }}>Error loading sale.</AppText>
        </div>
      </MinHeightDiv>
    );

  return (
    <div>
      <div className={"ag-theme-individual-sale"}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          suppressMovableColumns={true} // enable/disable column re-ordering
          suppressDragLeaveHidesColumns={true} // stops columns from being removed when dragged out
          tooltipMouseTrack={true}
          tooltipShowDelay={800} // delay before showing tooltip while hovering
          tooltipHideDelay={30000} // delay before hiding tooltip while hovering
          enableCellTextSelection={true}
        />
      </div>
      <Footer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            resetSelectedSale();
            refetchAllSales();
          }}
        >
          <PhoenixIcon svg={chevron_left} size={24} variant="brand" style={{ marginRight: "10px" }} />
          <AppText fontSize={10} fontWeight={600} style={{ color: theme.PRIMARY500 }}>
            BACK
          </AppText>
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "12px", marginRight: "16px" }}>
            <ProfileImageWithText
              height={40}
              width={40}
              first_name={saleData?.fetchSaleDetail?.lead_activity?.user?.first_name}
              last_name={saleData?.fetchSaleDetail?.lead_activity?.user?.last_name}
              profile_image={saleData?.fetchSaleDetail?.lead_activity?.user?.profile_image}
            />
            <AppText fontSize={12} fontWeight={600}>
              {saleData?.fetchSaleDetail?.lead_activity?.user?.full_name}
            </AppText>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <RenderAssociateTitle
              primary_lead_id={primaryLeadId}
              associate_lead_ids={associatedLeadIds}
              contact_id={saleData?.fetchSaleDetail?.lead?.id}
              isAssociatedLead={associatedLeadIds.includes(saleData?.fetchSaleDetail?.lead?.id)}
            />

            <AppText fontSize={12} fontWeight={600}>
              {saleData?.fetchSaleDetail?.lead?.full_name}
            </AppText>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <AppText fontSize={12} fontWeight={600}>
              Total Price: {formatUSDRaw(saleData?.fetchSaleDetail?.total_price)}
            </AppText>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center" }}>
          <AppText
            fontSize={12}
            fontWeight={500}
            style={{
              wordBreak: "keep-all",
              width: "max-content",
            }}
          >
            {moment(saleData?.fetchSaleDetail?.created_at)?.format("MM/DD/YYYY")}
          </AppText>
          <AppText
            fontSize={12}
            fontWeight={500}
            style={{
              wordBreak: "keep-all",
              width: "max-content",
            }}
          >
            {moment(saleData?.fetchSaleDetail?.created_at)?.format("hh:mm A")}
          </AppText>
        </div>
      </Footer>
    </div>
  );
};

const Footer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  height: 72px;
  border-top: 1px solid ${theme.NEUTRAL200};
  padding-left: 12px;
  padding-right: 12px;
`;

const MinHeightDiv = styled.div`
  min-height: 517px;
  max-height: 517px;
  overflow-y: scroll;
  padding-bottom: 24px;
`;

export default IndividualSaleTable;
