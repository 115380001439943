import styled from "styled-components";
import * as React from "react";
import { AppText, Loading, AppErrorText, SkeletonBlock } from "../../../UI";
import { theme } from "../../../../utils/theme";
import { ProgressGraph } from "../..";
import { useQuery, gql } from "@apollo/client";
import { formatCellData } from "../../../../utils/format";
import moment from "moment";
import {
  dateFilterArgsInterface,
  leadFilterArgsInterface,
  repFilterArgsInterface,
  selectedCellInterface,
} from "../../../../context";

const FETCH_30_DAY_GRAPH_DATA = gql`
  query fetch30DayGraphDataV2($dashboard_detail_args: DashboardDetailArgument!) {
    fetch30DayGraphDataV2(dashboard_detail_args: $dashboard_detail_args) {
      company_average
      best_individual
      stat_items {
        id
        day
        formatted_day
        metric
        metric_type
        value
        rank
        row_label
        __typename
      }
      __typename
    }
  }
`;

interface StatItem {
  id?: string;
  day?: string;
  formatted_day?: string;
  metric?: string;
  metric_type?: string;
  value?: number;
  row_label?: string;
  rank?: number;
}

const Render30DayChart = ({
  selectedCell,
  leadFilterArgs,
  repFilterArgs,
  dateFilterArgs,
  leadActivityChannel,
}: {
  selectedCell: selectedCellInterface | undefined;
  dateFilterArgs: dateFilterArgsInterface;
  leadFilterArgs: leadFilterArgsInterface;
  repFilterArgs: repFilterArgsInterface;
  leadActivityChannel: string[];
}) => {
  const { data: dataGraph, loading: loadingGraph, error: errorGraph, refetch: refetchGraph } = useQuery(
    FETCH_30_DAY_GRAPH_DATA,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      skip: !selectedCell?.id,
      variables: {
        dashboard_detail_args: {
          lead_activity_channel: leadActivityChannel,
          lead_filter: leadFilterArgs,
          rep_filter: repFilterArgs,
          date_filter: dateFilterArgs,
          metric: selectedCell?.metric,
          position: selectedCell?.position,
          computed_id: selectedCell?.computed_id,
          take: 50,
          skip: 0,
          desc: true,
          order_by: "BusinessName",
        },
      },
    },
  );

  const formatGraphData = (data: StatItem[]): { x: string; y: number; label: string }[] => {
    return data
      ?.map((item: StatItem) => item)
      ?.sort((a: StatItem, b: StatItem) => moment(a?.day)?.unix() - moment(b?.day)?.unix() ?? 0)
      ?.map((item: StatItem) =>
        item?.metric_type === "Percentage"
          ? {
              x: item?.formatted_day ?? "NA",
              y: Number(item?.value ?? 1) * 100,
              label: formatCellData(item?.value, item?.metric_type),
            }
          : {
              x: item.formatted_day ?? "NA",
              y: Number(item.value) ?? 0,
              label: formatCellData(item?.value, item?.metric_type),
            },
      );
  };

  return (
    <ChartContainer>
      <div style={{ padding: "20px 0px 0px 20px" }}>
        <TitleText bold>{selectedCell?.metric_label}</TitleText>
        <TitleText>{selectedCell?.row_label}</TitleText>
      </div>
      {!dataGraph || loadingGraph ? (
        <SkeletonBlock height="250px" width={"100%"} borderRadius={"0px"} />
      ) : errorGraph ? (
        <AppErrorText>Unable to load data. Please try again later or contact support</AppErrorText>
      ) : (
        !!dataGraph?.fetch30DayGraphDataV2 && (
          <ProgressGraph
            data={formatGraphData(dataGraph.fetch30DayGraphDataV2.stat_items)}
            bestIndividual={dataGraph.fetch30DayGraphDataV2.best_individual}
            companyAverage={dataGraph.fetch30DayGraphDataV2.company_average}
            metricType={selectedCell?.metric_type ?? ""}
          />
        )
      )}
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${theme.WHITE_COLOR};
  margin-bottom: 20px;
`;

interface IBold {
  bold?: boolean;
}

const TitleText = styled(AppText)<IBold>`
  display: inline-block;
  font-size: 13px;
  font-weight: ${(props) => (props.bold ? 600 : "normal")};
  margin-left: ${(props) => (props.bold ? "15px" : "7px")};
`;

export default Render30DayChart;
