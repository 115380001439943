import React from "react";
import styled, { CSSProperties } from "styled-components";
import { theme } from "../../utils/theme";
import moment from "moment";
import { DATE_FORMAT, TIME_FORMAT } from "../../utils/format";
import TimePicker, { TimePickerProps } from "rc-time-picker";
import "rc-time-picker/assets/index.css";

interface AppTimePickerProps {
  date?: Date;
  title?: string;
  width?: number;
  style?: CSSProperties;
  error?: string | false;
}

const NewAppTimePicker = ({ title, width, error, style, ...props }: AppTimePickerProps & TimePickerProps) => {
  return (
    <Div>
      <TimePicker
        {...props}
        css={{
          borderColor: "red",
          backgroundColor: "blue",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="NewAppTimePicker"
        showSecond={false}
        format={TIME_FORMAT}
        use12Hours
        inputReadOnly
        defaultOpenValue={moment("00:00", "hh:mm")}
        addon={(panel: any) => {
          return <TimePickerButton onClick={() => panel.close()}>Confirm</TimePickerButton>;
        }}
      />
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

const TimePickerButton = styled.button`
  width: 100%;
  height: 30px;
  font-family: ${theme.PRIMARY_FONT};
  background-color: ${theme.PRIMARY500};
  border: none;
  color: ${theme.WHITE_COLOR};
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .NewAppTimePicker {
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 14px;
    color: ${theme.PRIMARY500};
    font-weight: 500;
    border: 1px solid ${theme.NEUTRAL300};
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rc-time-picker-input {
    border: none;
    outline: none;
    color: ${theme.PRIMARY500};
    width: 75px;
  }

  .rc-time-picker-clear {
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rc-time-picker-clear-icon::after {
    color: ${theme.PRIMARY500};
    font-size: 16px !important;
    margin-top: 3px;
    font-weight: 400;
  }
`;

const InputErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: 600;
  color: ${theme.ATTENTION700};
  margin: 0;
  margin-top: 2px;
  flex-wrap: no-wrap;
  white-space: nowrap;
`;

export { NewAppTimePicker };
