import React from "react";
import { AppText, FlexDiv } from "src/Components/UI";
import {
  PhoenixCheckbox,
  PhoenixDateRange,
  PhoenixInput,
  PhoenixMultiSelect,
  PhoenixTextArea,
} from "src/Components/UI/Phoenix";
import { theme } from "src/utils/theme";

interface SystemFieldPreviewProps {
  content: string;
  inputStyle?: any;
  inputType: string;
  labelStyle?: any;
  required?: boolean;
  systemField?: string;
}

const SystemFieldPreview: React.FC<SystemFieldPreviewProps> = ({
  content,
  inputStyle,
  inputType,
  labelStyle,
  required,
  systemField,
}) => {
  return (
    <FlexDiv
      gap={8}
      direction={inputType === "Boolean" ? "row" : "column"}
      style={
        inputType === "Boolean" || inputType === "Date"
          ? { alignSelf: "flex-start", paddingLeft: "4px", flex: 1, width: "100%" }
          : { flex: 1, width: "100%" }
      }
    >
      {inputType === "Boolean" && <PhoenixCheckbox checked={false} disabled />}

      <AppText fontSize={12} fontWeight={500} lineHeight={18} style={labelStyle}>
        {content}
        {required && <span style={{ color: theme.PILL_DARK_RED }}>*</span>}
      </AppText>

      {["Dropdown", "MultiDropdown", "MultiText"].includes(inputType) && (
        <PhoenixMultiSelect
          isMulti={inputType === "MultiDropdown"}
          name={content}
          value={undefined}
          options={[]}
          marginBottom={false}
          isDisabled
          style={inputStyle}
        />
      )}

      {["Text", "Rate", "Percentage", "Number"].includes(inputType) && (
        <FlexDiv align="center" gap={16}>
          {systemField === "PRIMARY_PHONE_NUMBER" && (
            <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.NEUTRAL300}>
              +1
            </AppText>
          )}
          <PhoenixInput
            name={content}
            value=""
            displayNoContextText
            readOnly
            style={{ borderColor: theme.NEUTRAL300, cursor: "default", ...inputStyle }}
            onFocus={(e) => e.target.blur()}
            showNumberArrows={inputType === "Number"}
          />
          {inputType === "Percentage" && (
            <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.NEUTRAL300}>
              %
            </AppText>
          )}
        </FlexDiv>
      )}

      {inputType === "BigText" && (
        <PhoenixTextArea value={""} ableToEdit={false} ableToResize={false} disabled fixedSize height={160} hideError />
      )}

      {inputType === "Date" && <PhoenixDateRange disabled lowerbound_date={null} upperbound_date={null} />}
    </FlexDiv>
  );
};

export default SystemFieldPreview;
