import styled from "styled-components";
import { theme } from "../../../../utils/theme";

interface NodeContainerProps {
  padding?: string;
  borderColor?: string;
}

export const NodeContainer = styled.div<NodeContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 296px;
  min-height: 64px;
  padding: ${(props) => props.padding || "unset"};

  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => props.borderColor || theme.NEUTRAL300};

  cursor: default;
  background-color: ${theme.WHITE_COLOR};
  overflow: hidden;
`;
