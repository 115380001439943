import styled from "styled-components";
import * as React from "react";
import { theme } from "../../utils/theme";
import ReactTooltip from "react-tooltip";
import { TooltipProps } from "react-tooltip";

interface ITooltip extends TooltipProps {
  id: string;
}
const StyledTooltip: React.FC<ITooltip> = ({ id, ...props }) => {
  return (
    <CustomTooltip
      id={id}
      css={{
        fontFamily: theme.PRIMARY_FONT,
      }}
      backgroundColor={theme.PRIMARY800}
      {...props}
    >
      {props.children}
    </CustomTooltip>
  );
};

const CustomTooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 600px;
`;

export { StyledTooltip };
