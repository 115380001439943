import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, AppSelect, Loading, AppErrorText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { PhoenixAppButton, PhoenixInput } from "../../UI/Phoenix";
import { useMutation, gql } from "@apollo/client";
import { theme } from "../../../utils/theme";
import { errorToast, successToast } from "../../../utils/toast";
import { StepActionButton } from "../../UI/Sequences/StepActionButton";
import { chat, info, mail, phone_call, settings } from "../../../images/NewDesign";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";

interface SelectAnActionModalProps {
  sequenceData: any;
  setStartStepAction: Dispatch<SetStateAction<string>>;
}

export const SelectAnActionModal: React.FC<SelectAnActionModalProps> = ({ setStartStepAction, sequenceData }) => {
  const {
    showSelectAnActionModal,
    setShowSelectAnActionModal,
    setShowSequenceStepModal,
    setShowSequenceBranchModal,
    sequenceStepData,
    setSequenceStepData,
  } = useContext(ModalContext);

  useEffect(() => {
    setSequenceStepData({
      ...sequenceStepData,
      nextNode: sequenceData?.active_steps?.find((ele: any) => ele.origin_id === sequenceStepData?.id),
    });
  }, []);

  const handleShowSequenceStepModal = (action: string) => {
    setStartStepAction(action);
    setShowSequenceStepModal(true);
    setShowSelectAnActionModal(false);
  };

  const prevNodeTask = sequenceData?.active_steps?.find((ele: any) => ele.id === sequenceStepData?.originId)
    ?.actions?.[0]?.task;
  const branchDisabled =
    prevNodeTask !== "manualCall" ||
    sequenceData?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length >= 20;

  return (
    <Modal
      open={showSelectAnActionModal}
      onClose={() => {
        setShowSelectAnActionModal(false);
        setSequenceStepData({});
      }}
      popupAnimation
    >
      <Main>
        <PhoenixStyledTooltip place="right" id={"sequence-rmf"} fontSize={10} padding={8} />
        <AppText fontSize={14} style={{ marginBottom: "8px", lineHeight: "20px" }}>
          Select an Action
        </AppText>
        <div>
          <AppText
            fontSize={10}
            fontWeight={600}
            color={theme.NEUTRAL300}
            style={{ marginBottom: "8px", letterSpacing: "1px", textTransform: "uppercase" }}
          >
            Tasks
          </AppText>
          <StepActionsContainer>
            <StepActionButton
              text="Automated Email"
              icon={mail}
              onClick={() => handleShowSequenceStepModal("automatedEmail")}
            />
            <StepActionButton
              text="Manual Email"
              icon={mail}
              onClick={() => handleShowSequenceStepModal("manualEmail")}
            />
            <StepActionButton
              text="Automated SMS"
              icon={chat}
              onClick={() => handleShowSequenceStepModal("automatedSms")}
            />
            <StepActionButton text="Manual SMS" icon={chat} onClick={() => handleShowSequenceStepModal("manualSms")} />
            <StepActionButton
              text="Phone Call"
              icon={phone_call}
              onClick={() => handleShowSequenceStepModal("manualCall")}
            />
            <div
              data-for={prevNodeTask !== "manualCall" && !!prevNodeTask && "sequence-rmf"}
              data-tip="Request Manager Feedback is only available after a phone call"
            >
              <StepActionButton
                text="Request Manager Feedback"
                icon={info}
                disabled={prevNodeTask !== "manualCall" && !!prevNodeTask}
                onClick={() => handleShowSequenceStepModal("requestManagerFeedback")}
              />
            </div>
            <StepActionButton
              text="Custom Task"
              icon={settings}
              onClick={() => handleShowSequenceStepModal("customTask")}
            />
          </StepActionsContainer>
        </div>
        <div>
          <AppText
            fontSize={10}
            fontWeight={600}
            color={theme.NEUTRAL300}
            style={{ marginBottom: "8px", letterSpacing: "1px", textTransform: "uppercase" }}
          >
            Branching
          </AppText>
          <StepActionButton
            text="Call Dispositions"
            icon={phone_call}
            onClick={() => {
              setShowSelectAnActionModal(false);
              setShowSequenceBranchModal(true);
            }}
            disabled={branchDisabled}
          />
        </div>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 456px;
  padding: 16px;
`;

const StepActionsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  column-gap: 8px;
  row-gap: 8px;
  margin-bottom: 16px;
`;
