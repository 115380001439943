import React, { memo, useEffect, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect, PhoenixTextArea, PhoenixAppButton } from "../../UI/Phoenix";
import { SaleConfigSection, copyToClipboard, formatMakeSaleConfigFields } from "../../../utils/misc";
import { DraggableFieldType } from "../../../utils/variables";
import { isEqual } from "lodash";
import { lock, trash } from "../../../images/NewDesign";
import ReactTooltip from "react-tooltip";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { CustomField } from "./MakeSaleConfiguration";
import SystemFieldPreview from "src/Components/Field/SystemFieldPreview";

interface PreviewSectionProps {
  sectionData: SaleConfigSection[] | undefined;
  pageTitle: string;
  loading: boolean;
  requiredFieldData: string[];
  customFieldData: CustomField[];
  isLastPage: boolean;
}

interface FieldShape extends SaleConfigSection {
  inputType: string;
  baseType: DraggableFieldType;
  required_field?: boolean;
}

export const PreviewSection: React.FC<PreviewSectionProps> = ({
  sectionData,
  pageTitle,
  loading,
  requiredFieldData,
  customFieldData,
  isLastPage,
}) => {
  const fields = useMemo(() => {
    return formatMakeSaleConfigFields(sectionData, requiredFieldData, customFieldData);
  }, [sectionData]);

  const isPandaDocPage = useMemo(() => sectionData?.some((section) => section.type === DraggableFieldType.PANDADOC), [
    sectionData,
  ]);

  return (
    <PreviewSectionWrap>
      <PreviewContent>
        <AppText
          fontSize={10}
          fontWeight={600}
          lineHeight={16}
          uppercase
          color={theme.NEUTRAL300}
          letterSpacing={1}
          style={{ alignSelf: "flex-start" }}
        >
          Preview
        </AppText>

        <PreviewBody>
          {!loading ? (
            <>
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                {pageTitle}
              </AppText>

              <MappedFields fields={fields} />
            </>
          ) : (
            <div>
              <Loading />
            </div>
          )}
        </PreviewBody>
      </PreviewContent>
      <ButtonContainer justify="space-between">
        <PhoenixAppButton
          variant={"danger-outline"}
          buttonType={"secondary"}
          buttonTextFontSize={10}
          uppercase
          style={{ pointerEvents: "none" }}
        >
          Back
        </PhoenixAppButton>

        <FlexDiv gap={isPandaDocPage && !isLastPage ? 4 : 8} align="center" style={{ height: "fit-content" }}>
          {isPandaDocPage && (
            <PhoenixAppButton
              variant={"brand-outline"}
              buttonType={"secondary"}
              buttonTextFontSize={10}
              uppercase
              style={{ pointerEvents: "none" }}
            >
              Skip
            </PhoenixAppButton>
          )}

          <PhoenixAppButton
            variant={"brand"}
            buttonType={"secondary"}
            buttonTextFontSize={10}
            uppercase
            style={{ pointerEvents: "none" }}
          >
            {isLastPage ? "Confirm Sale" : isPandaDocPage ? "Send Document" : "Next"}
          </PhoenixAppButton>
        </FlexDiv>
      </ButtonContainer>
    </PreviewSectionWrap>
  );
};

interface MappedFieldsProps {
  fields: FieldShape[] | undefined;
}

const MappedFields: React.FC<MappedFieldsProps> = memo(
  ({ fields }) => {
    if (!fields) return null;

    return (
      <MappedFieldsContainer>
        {fields?.map((f: FieldShape) => {
          if ([DraggableFieldType.FIELD, DraggableFieldType.REQUIRED_FIELD].includes(f.baseType)) {
            if (f.system_field === "PRODUCT_SELECTION") {
              return <ProductPreview key={f.id} />;
            }
            if (f.system_field === "SALE_NOTE") {
              return <SaleNotePreview key={f.id} required={f?.required || f?.required_field || false} />;
            }
            if (f.system_field === "LEAD_ID_LABEL") {
              return (
                <AppText
                  key={f.id}
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={18}
                  style={{ marginBottom: "8px", borderBottom: "none", cursor: "pointer" }}
                  onClick={() => copyToClipboard("00000000-0000-0000-0000-000000000000")}
                >
                  Lead ID: 00000000-0000-0000-0000-000000000000
                </AppText>
              );
            }

            return (
              <SystemFieldPreview
                key={f.id}
                content={f?.content || ""}
                required={f?.required || f?.required_field || false}
                inputType={f.inputType}
                systemField={f.system_field}
              />
            );
          }

          if (f.type === DraggableFieldType.NOTE) {
            return (
              <AppText key={f.id} fontSize={12} fontWeight={500} lineHeight={18}>
                {f.content}
              </AppText>
            );
          }

          if (f.type === DraggableFieldType.WARNING) {
            return (
              <AppText key={f.id} fontSize={12} fontWeight={500} lineHeight={18} color={theme.PILL_DARK_RED}>
                {f.content}
              </AppText>
            );
          }

          if (f.type === DraggableFieldType.LINK) {
            return (
              <AppText
                key={f.id}
                fontSize={12}
                fontWeight={500}
                lineHeight={18}
                color={theme.PRIMARY500}
                style={{ borderBottom: `1px solid ${theme.PRIMARY500}`, cursor: "pointer" }}
                onClick={() => window.open(f.content, "_blank", "noreferrer")}
              >
                {f.content}
              </AppText>
            );
          }

          if (f.type === DraggableFieldType.HEADER) {
            return (
              <AppText key={f.id} fontSize={10} fontWeight={600} uppercase letterSpacing={1}>
                {f.content}
              </AppText>
            );
          }

          if (f.type === "PANDADOC") {
            return (
              <div key={f.id}>
                <PhoenixMultiSelect
                  name="template_id"
                  value={{ label: "Select template...", value: "" }}
                  isMulti={false}
                  isClearable={false}
                  options={[]}
                  width={342}
                  isDisabled
                />

                <div>
                  <TemplateFieldLabel>Field 1</TemplateFieldLabel>
                  <PhoenixInput
                    value="Example..."
                    width={342}
                    displayNoContextText
                    readOnly
                    style={{ borderColor: theme.NEUTRAL300, cursor: "default", marginBottom: "16px" }}
                    onFocus={(e) => e.target.blur()}
                  />
                  <TemplateFieldLabel>Field 2</TemplateFieldLabel>
                  <PhoenixInput
                    value="Example..."
                    width={342}
                    displayNoContextText
                    readOnly
                    style={{ borderColor: theme.NEUTRAL300, cursor: "default", marginBottom: "16px" }}
                    onFocus={(e) => e.target.blur()}
                  />
                  <TemplateFieldLabel>Field 3</TemplateFieldLabel>
                  <PhoenixInput
                    value="Example..."
                    width={342}
                    displayNoContextText
                    readOnly
                    style={{ borderColor: theme.NEUTRAL300, cursor: "default", marginBottom: "16px" }}
                    onFocus={(e) => e.target.blur()}
                  />
                  <TemplateFieldLabel>Field 4</TemplateFieldLabel>
                  <PhoenixInput
                    value="Example..."
                    width={342}
                    displayNoContextText
                    readOnly
                    style={{ borderColor: theme.NEUTRAL300, cursor: "default", marginBottom: "16px" }}
                    onFocus={(e) => e.target.blur()}
                  />
                </div>
              </div>
            );
          }

          return null;
        })}
      </MappedFieldsContainer>
    );
  },
  (oldProps, newProps) => isEqual(oldProps.fields, newProps.fields),
);

const PreviewSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 448px;
  margin-left: auto;
`;

const PreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 448px;
  height: 100%;
  padding: 24px 40px;
  margin-left: auto;

  overflow-y: auto;
  background-color: ${theme.WHITE_COLOR};
  border-left: 1px solid ${theme.NEUTRAL300};
`;

const PreviewBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  padding: 24px 0px;

  width: 100%;
  height: 67vh;

  @media (min-height: 1200px) {
    height: 72vh;
  }
  @media (min-height: 1440px) {
    height: 78vh;
  }
`;

const MappedFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

const ButtonContainer = styled(FlexDiv)`
  padding: 16px 64px;
  min-height: 238px;
  background-color: ${theme.WHITE_COLOR};
  border-top: 1px solid ${theme.NEUTRAL200};
  border-left: 1px solid ${theme.NEUTRAL300};
`;

interface SaleNotePreviewProps {
  required: boolean;
}

const SaleNotePreview: React.FC<SaleNotePreviewProps> = ({ required }) => {
  return (
    <FlexDiv direction="column" gap={8}>
      <AppText fontSize={12} fontWeight={500} lineHeight={18}>
        Sale Notes:
        {required && <span style={{ color: theme.PILL_DARK_RED }}>*</span>}
      </AppText>
      <PhoenixTextArea
        placeholder="Type your sale notes here..."
        value={undefined}
        fixedSize
        width={342}
        height={200}
        disabled
      />
    </FlexDiv>
  );
};

const ProductPreview: React.FC = () => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <ProductPreviewContainer direction="column">
      <PhoenixStyledTooltip id="product-preview-tooltip" width={240} />
      <ProductPreviewBody gap={24} direction="column">
        <FlexDiv justify="space-between" align="center">
          <AppText fontSize={10} fontWeight={600} uppercase letterSpacing={1} lineHeight={16} color={theme.PRIMARY500}>
            Product 1
          </AppText>
          <PhoenixIcon svg={trash} size={16} color={theme.DANGER100} hoverColor={theme.DANGER100} />
        </FlexDiv>

        <FlexDiv gap={8} direction="column">
          <AppText fontSize={12} fontWeight={500} lineHeight={18}>
            Product
            <span style={{ color: theme.PILL_DARK_RED }}>*</span>
          </AppText>

          <PhoenixMultiSelect
            isMulti={false}
            name="product-selection"
            placeholder="Select a product"
            value={undefined}
            options={[]}
            width={306}
            marginBottom={false}
            isDisabled
          />
        </FlexDiv>
        <FlexDiv gap={8} direction="column">
          <AppText fontSize={12} fontWeight={500} lineHeight={18}>
            Quantity
            <span style={{ color: theme.PILL_DARK_RED }}>*</span>
          </AppText>

          <PhoenixInput
            name="product-quantity"
            inputValueType="number"
            value={1}
            showNumberArrows
            displayNoContextText
            readOnly
            style={{ borderColor: theme.NEUTRAL300, cursor: "default" }}
            onFocus={(e) => e.target.blur()}
          />
        </FlexDiv>
        <FlexDiv gap={8} direction="column">
          <AppText fontSize={12} fontWeight={500} lineHeight={18}>
            Prepayment Terms
            <span style={{ color: theme.PILL_DARK_RED }}>*</span>
          </AppText>
          <div
            data-tip="Please note, Prepayment Terms are configured on the product level. This dropdown selection will only show for your reps if prepayment terms are enabled for the selected products"
            data-for="product-preview-tooltip"
          >
            <PhoenixMultiSelect
              isMulti={false}
              name="product-prepayment"
              value={{ label: "1", value: "1" }}
              options={[]}
              width={306}
              marginBottom={false}
              isDisabled
            />
          </div>
        </FlexDiv>

        <FlexDiv gap={24} align="center">
          <FlexDiv gap={8} direction="column">
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Price
            </AppText>
            <FlexDiv align="center" gap={8}>
              <PhoenixInput
                name="product-price"
                inputValueType="number"
                placeholder="$000"
                displayNoContextText
                readOnly
                width={130}
                style={{ borderColor: theme.NEUTRAL300, cursor: "default" }}
                onFocus={(e) => e.target.blur()}
              />
              <PhoenixIcon svg={lock} size={16} variant="neutral" />
            </FlexDiv>
          </FlexDiv>

          <FlexDiv gap={8} direction="column">
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Discount
            </AppText>
            <PhoenixMultiSelect
              isMulti={false}
              name="product-discount"
              value={{ label: "$0", value: "$0" }}
              options={[]}
              marginBottom={false}
              isDisabled
              width={130}
            />
          </FlexDiv>
        </FlexDiv>
      </ProductPreviewBody>

      <ProductPreviewFooter>
        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
          Total Price: $0
        </AppText>
      </ProductPreviewFooter>
    </ProductPreviewContainer>
  );
};

const ProductPreviewContainer = styled(FlexDiv)`
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  overflow: hidden;
  min-height: 468px;
`;

const ProductPreviewBody = styled(FlexDiv)`
  padding: 16px 16px 24px 16px;
`;

const ProductPreviewFooter = styled.div`
  padding: 16px 32px;
  background-color: ${theme.PRIMARY50};
`;

const TemplateFieldLabel = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
`;
