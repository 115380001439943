import styled from "styled-components";

import { useState } from "react";
import { AppInput, AppButton, AppText, AppSidebarCard, Loading } from "../UI";
import { theme } from "../../utils/theme";

import { ModalContext } from "../../context";
import React, { useContext } from "react";

import { PhoenixAppButton } from "../UI/Phoenix";
import { appToast } from "../../utils/toast";
import { gql, useMutation } from "@apollo/client";

const DELETE_NO_EVENT_DATE = gql`
  mutation DeleteNoEventDate($deleteNoEventDateId: String!) {
    deleteNoEventDate(id: $deleteNoEventDateId) {
      id
    }
  }
`;

const DeleteNoEventDateModal = () => {
  const { noEventModalData, setShowNoEventModal, setShowDeleteNoEventModal } = useContext(ModalContext);

  const [deleteNoEventDate, { loading: deleteNoEventDateLoading }] = useMutation(DELETE_NO_EVENT_DATE, {
    onCompleted: () => {
      appToast("No Event Date Deleted");
      setShowDeleteNoEventModal(false);
    },
    onError: (error) => {
      appToast("Error Deleting No Event Date");
      console.log(error);
    },

    refetchQueries: ["FetchNoEventDates"],
  });

  return (
    <Main>
      <TitleDiv>
        <TitleText>Delete No Event Date</TitleText>
      </TitleDiv>

      <ScrollingDiv>
        <AppText fontSize={14} fontWeight={400}>
          Are you sure you want to delete this No Event Date? You cannot undo this action.
        </AppText>
        <SpacerDiv height={23} />
      </ScrollingDiv>
      <SubmitDiv>
        <PhoenixAppButton
          onClick={() => {
            setShowNoEventModal(false);
          }}
          variant="danger-outline"
          buttonType="secondary"
        >
          No, Cancel
        </PhoenixAppButton>
        <PhoenixAppButton
          onClick={() => {
            deleteNoEventDate({
              variables: {
                deleteNoEventDateId: noEventModalData.event_id,
              },
            });
          }}
          disabled={deleteNoEventDateLoading}
          variant="brand"
          buttonType="secondary"
        >
          Yes, Delete It
        </PhoenixAppButton>
      </SubmitDiv>
    </Main>
  );
};

const Main = styled.div`
  width: 476px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScrollingDiv = styled.div`
  padding: 0px 40px 16px 40px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
const SubmitDiv = styled.div`
  height: 80px;
  bottom: 0px;
  width: 100%;

  padding: 0px 40px;

  /* padding: 0px 24px; */
  /* margin-top: auto; */

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid ${theme.NEUTRAL200};
`;

const SpacerDiv = styled.div<{ height?: number | string }>`
  height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
  min-height: ${(props) =>
    props.height ? (typeof props.height === "number" ? `${props.height}px` : props.height) : "16px"};
`;

export { DeleteNoEventDateModal };
