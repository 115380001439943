import styled from "styled-components";
import * as React from "react";
import { useState, useContext, useEffect, useMemo } from "react";
import { AppButton, AppText, Wrapper, AppErrorText, Loading, NewAppButton } from "../UI";
import { theme } from "../../utils/theme";
import { isLoggedInVar, loggedInUser } from "../../apollo/cache";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import moment from "moment";
import { BsTrashFill } from "react-icons/bs";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { toast } from "react-toastify";
import { CreateActionButtons } from "../Segments/Header/";
import ReactTooltip from "react-tooltip";
import { DeleteCallFolderPopup, UpdateFolderNamePopup, ShareFolderModal, SaveToFolderModal } from "../modal";

import {
  ThreeDotsIcon,
  callFolderIcon,
  callReportRemove,
  iconEditBlack,
  iconPersonBlack,
  callReportMove,
  callReportSave,
} from "../../images/";
import { OutsideEvent } from "../Dumb";
import { formatCallDuration } from "../../utils/format";
import { FaPlayCircle } from "react-icons/fa";
import { ModalContext } from "../../context";
import { appToast } from "../../utils/toast";
import { CALL_LIBRARY_PERSIST_KEY } from "../../utils/variables";

export const FETCH_VIEWABLE_FOLDERS = gql`
  query fetchViewableFolders {
    fetchViewableFolders {
      id
      name
      size
      owner {
        id
        full_name
      }
    }
  }
`;

export const FETCH_FOLDER = gql`
  query fetchFolder($folder_id: String!) {
    fetchFolder(folder_id: $folder_id) {
      id
      conferences {
        id
        salesperson
        created_at
        duration
        latest_call_result {
          id
          label
        }
      }
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation removeFolder($folder_id: String!) {
    removeFolder(folder_id: $folder_id) {
      id
      name
    }
  }
`;

const DELETE_CALL = gql`
  mutation removeFolder($folder_id: String!, $conference_id: String!) {
    removeCallFromFolder(folder_id: $folder_id, conference_id: $conference_id) {
      id
      name
    }
  }
`;

const callLibraryDefaultState = {
  sortOrder: "descending",
  selectedFolder: { id: "", name: "", conferences: [], owner: {} as any },
  currentConferenceID: "",
  queryFolderID: "",
  currentFolderID: "",
  currentFolderName: "",
  folderDelete: true,
};

const CallLibraryPage: React.FC = () => {
  const [sortOrder, setSortOrder] = useState(callLibraryDefaultState.sortOrder);
  const [selectedFolder, setSelectedFolder] = useState(callLibraryDefaultState.selectedFolder);

  const [currentConferenceID, setCurrentConferenceID] = useState(callLibraryDefaultState.currentConferenceID);

  const [queryFolderID, setQueryFolderID] = useState(callLibraryDefaultState.queryFolderID);
  const [currentFolderID, setCurrentFolderID] = useState(callLibraryDefaultState.currentFolderID);
  const [currentFolderName, setCurrentFolderName] = useState(callLibraryDefaultState.currentFolderName);

  const [folderDelete, setFolderDelete] = useState(callLibraryDefaultState.folderDelete);

  const userData = loggedInUser();

  // set persist state
  useEffect(() => {
    const persistedStateRaw = localStorage.getItem(CALL_LIBRARY_PERSIST_KEY);
    if (!persistedStateRaw) {
      return;
    }
    const persistedState = JSON.parse(persistedStateRaw);
    if (!persistedState) {
      return;
    }
    setSortOrder(persistedState.sortOrder ?? callLibraryDefaultState.sortOrder);
    setSelectedFolder(persistedState.selectedCell ?? callLibraryDefaultState.selectedFolder);
    setCurrentConferenceID(persistedState.selectedStack ?? callLibraryDefaultState.currentConferenceID);
    setQueryFolderID(persistedState.userID ?? callLibraryDefaultState.queryFolderID);
    setCurrentFolderID(persistedState.teamID ?? callLibraryDefaultState.currentFolderID);
    setCurrentFolderName(persistedState.orgID ?? callLibraryDefaultState.currentFolderName);
    setFolderDelete(persistedState.coachingReportView ?? callLibraryDefaultState.folderDelete);
  }, []);

  // persist settings when a change occurs
  useEffect(() => {
    const table_properties = {
      sortOrder,
      selectedFolder,
      currentConferenceID,
      queryFolderID,
      currentFolderID,
      currentFolderName,
      folderDelete,
    };
    localStorage.setItem(CALL_LIBRARY_PERSIST_KEY, JSON.stringify(table_properties));
  }, [sortOrder, selectedFolder, currentConferenceID, queryFolderID, currentFolderID, currentFolderName, folderDelete]);

  const {
    deleteModal,
    setDeleteModal,
    folderUpdateModal,
    setFolderUpdateModal,
    shareFolderModal,
    setShareFolderModal,
    moveToAnotherFolderModal,
    setMoveToAnotherFolderModal,
  } = useContext(ModalContext);

  const { data: dataFolders, loading: loadingFolders, error: errorFolders, refetch: refetchFolders } = useQuery(
    FETCH_VIEWABLE_FOLDERS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataCalls, loading: loadingCalls, error: errorCalls, refetch: refetchCalls } = useQuery(FETCH_FOLDER, {
    variables: { folder_id: queryFolderID },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [deleteOneFolder] = useMutation(DELETE_FOLDER, {
    onCompleted({ deleteOneFolder }) {
      appToast("Folder deleted successfully!");
      if (!deleteOneFolder) {
        return;
      }
    },
    onError({ message }) {
      toast(message);
      Sentry.captureEvent({
        message: `deleteOneFolder GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder"],
  });

  const [deleteOneCall] = useMutation(DELETE_CALL, {
    onCompleted({ deleteOneCall }) {
      appToast("Call removed successfully!");
      if (!deleteOneCall) {
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `deleteOneCall GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchViewableFolders", "fetchFolder"],
  });

  if (loadingFolders) return <Loading />;
  if (errorFolders) return <AppErrorText>Error loading folders</AppErrorText>;

  const CreateOptionsComponent = (item: any) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    return (
      <OutsideEvent onOutsideClick={() => setIsClicked(false)}>
        <ContainerDiv onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <div
            onClick={() => {
              setIsClicked(!isClicked);
            }}
          >
            <ThreeDotsIcon color={isHovered || isClicked ? theme.PRIMARY500 : undefined} />
          </div>
        </ContainerDiv>
        {isClicked && (
          <OptionsDiv>
            <Option
              onClick={() => {
                setCurrentFolderName(item?.item?.name);
                setCurrentFolderID(item?.item?.id);
                setFolderUpdateModal(true);
              }}
            >
              <img src={iconEditBlack} alt="Rename folder" width="16px" style={{ margin: "0px 18px" }} />
              Rename Folder
            </Option>
            <Option
              onClick={() => {
                setCurrentFolderName(item?.item?.name);
                setCurrentFolderID(item?.item?.id);
                setShareFolderModal(true);
              }}
            >
              <img src={iconPersonBlack} alt="Share folder" width="16px" style={{ margin: "0px 18px" }} />
              Share Folder
            </Option>
            {(userData?.id === item?.item.owner.id || userData?.role === "ADMIN") && (
              <Option
                onClick={() => {
                  setCurrentFolderID(item?.item?.id);
                  setFolderDelete(true);
                  setDeleteModal(true);
                }}
              >
                <img src={callReportRemove} alt="Remove folder" width="16px" style={{ margin: "0px 18px" }} />
                Remove Folder
              </Option>
            )}
          </OptionsDiv>
        )}
      </OutsideEvent>
    );
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at the Call Library page"}>
      <OnboardingColorWrapper>
        <Main>
          <DeleteCallFolderPopup
            blinds={deleteModal}
            setBlinds={setDeleteModal}
            deleteFolder={folderDelete}
            onDeleteFolder={async () =>
              await deleteOneFolder({
                variables: {
                  folder_id: currentFolderID,
                },
              })
            }
            onDeleteCall={async () =>
              await deleteOneCall({
                variables: {
                  folder_id: currentFolderID,
                  conference_id: currentConferenceID,
                },
              })
            }
          />
          <UpdateFolderNamePopup
            blinds={folderUpdateModal}
            setBlinds={setFolderUpdateModal}
            folderID={currentFolderID}
            nameText={currentFolderName}
          />
          <ShareFolderModal blinds={shareFolderModal} setBlinds={setShareFolderModal} folderID={currentFolderID} />
          {moveToAnotherFolderModal && (
            <SaveToFolderModal
              blinds={moveToAnotherFolderModal}
              setBlinds={setMoveToAnotherFolderModal}
              conferenceID={currentConferenceID}
              moveCall={true}
              moveSrcFolder={selectedFolder.id}
            />
          )}
          {/* <OnboardingWrapper> */}
          <StepLocationText>Call Library</StepLocationText>
          <CallLibraryBox>
            <FoldersDiv>
              <NewAppButton
                variant={"confirm"}
                onClick={() => {
                  setCurrentFolderID("");
                  setCurrentFolderName("");
                  setFolderUpdateModal(true);
                }}
              >
                New Folder
              </NewAppButton>
              <YourFoldersLabel>Your Folders</YourFoldersLabel>
              <FolderNamesContainer>
                {dataFolders.fetchViewableFolders
                  .slice()
                  .sort((a: any, b: any) =>
                    sortOrder === "ascending"
                      ? b.name.toLowerCase().localeCompare(a.name.toLowerCase())
                      : a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                  )
                  ?.map((item: any) => (
                    <FolderLink
                      key={item.id}
                      onClick={async () => {
                        setSelectedFolder({
                          id: item.id,
                          name: item.name,
                          conferences: item.conferences,
                          owner: item.owner,
                        });
                        setQueryFolderID(item.id);
                      }}
                      selected={selectedFolder.id === item.id}
                    >
                      {item.name}
                    </FolderLink>
                  ))}
              </FolderNamesContainer>
            </FoldersDiv>
            <MainContainer>
              <HorizontalDiv>
                <HeaderText
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedFolder({ id: "", name: "", conferences: [], owner: {} });
                  }}
                >
                  Call Library
                </HeaderText>
                {!!selectedFolder.name && (
                  <>
                    <span style={{ padding: "0px 16px" }}>&gt;</span>
                    <HeaderText selected>{selectedFolder.name}</HeaderText>
                  </>
                )}
              </HorizontalDiv>
              <StyledHr />

              {!!selectedFolder.id ? (
                <CallLibraryTable>
                  <CallLibraryTR>
                    <CallLibraryTH
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
                      }}
                    >
                      Name
                      {sortOrder === "descending" ? (
                        <AiFillCaretDown
                          style={{ marginLeft: "4px", verticalAlign: "bottom" }}
                          size={14}
                          color={theme.BLACK_COLOR}
                        />
                      ) : (
                        <AiFillCaretUp
                          style={{ marginLeft: "4px", verticalAlign: "bottom" }}
                          size={14}
                          color={theme.BLACK_COLOR}
                        />
                      )}
                    </CallLibraryTH>
                    <CallLibraryTH>Sales Rep</CallLibraryTH>
                    <CallLibraryTH>Call Result</CallLibraryTH>
                    <CallLibraryTH>Recording</CallLibraryTH>
                  </CallLibraryTR>

                  <tbody>
                    {loadingCalls ? (
                      <Loading />
                    ) : !!dataCalls?.fetchFolder.conferences.length ? (
                      dataCalls?.fetchFolder.conferences
                        .slice()
                        .sort((a: any, b: any) =>
                          sortOrder === "ascending"
                            ? moment(a.created_at).unix() - moment(b.created_at).unix()
                            : moment(b.created_at).unix() - moment(a.created_at).unix(),
                        )
                        ?.map((item: any) => (
                          <CallLibraryTR key={item.id}>
                            <CallLibraryTD>{moment(item.created_at).format("MM/DD/YYYY h:mm A")}</CallLibraryTD>
                            <CallLibraryTD>{item.salesperson}</CallLibraryTD>
                            <CallLibraryTD>{item.latest_call_result?.label}</CallLibraryTD>
                            <CallLibraryTD style={{ float: "left" }}>
                              <StyledLink to={`/call-report/${item.id}`}>
                                <ListItemDuration>
                                  <div style={{ display: "flex", height: "100%" }}>
                                    <CenterDiv>
                                      <FaPlayCircle
                                        size={10}
                                        color={theme.PRIMARY500}
                                        style={{ marginRight: "6px", marginLeft: "4px" }}
                                      />
                                    </CenterDiv>
                                    <CenterDiv>{formatCallDuration(item.duration)}</CenterDiv>
                                  </div>
                                </ListItemDuration>
                              </StyledLink>
                            </CallLibraryTD>
                            <CallLibraryTD>
                              <div style={{ display: "flex" }}>
                                <IconDiv
                                  style={{ color: theme.SUCCESS500, marginLeft: "70px" }}
                                  onClick={() => {
                                    setCurrentConferenceID(item.id);
                                    setMoveToAnotherFolderModal(true);
                                  }}
                                >
                                  <img src={callReportMove} alt="Move to different folder" />
                                  MOVE
                                </IconDiv>
                                {(userData?.role === "ADMIN" || userData?.id === selectedFolder?.owner?.id) && (
                                  <IconDiv
                                    style={{ color: theme.ATTENTION700 }}
                                    onClick={() => {
                                      setCurrentConferenceID(item.id);
                                      setCurrentFolderID(selectedFolder.id);
                                      setFolderDelete(false);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    <img src={callReportRemove} alt="Remove from folder" />
                                    REMOVE
                                  </IconDiv>
                                )}
                              </div>
                            </CallLibraryTD>
                          </CallLibraryTR>
                        ))
                    ) : (
                      <CallLibraryTR>
                        <CallLibraryTD>
                          <ItalicsTableText>Nothing in this folder</ItalicsTableText>
                        </CallLibraryTD>
                        <CallLibraryTD></CallLibraryTD>
                      </CallLibraryTR>
                    )}
                  </tbody>
                </CallLibraryTable>
              ) : (
                <CallLibraryTable>
                  <CallLibraryTR>
                    <CallLibraryTH
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSortOrder(sortOrder === "ascending" ? "descending" : "ascending");
                      }}
                    >
                      Name
                      {sortOrder === "descending" ? (
                        <AiFillCaretDown
                          style={{ marginLeft: "4px", verticalAlign: "bottom" }}
                          size={14}
                          color={theme.BLACK_COLOR}
                        />
                      ) : (
                        <AiFillCaretUp
                          style={{ marginLeft: "4px", verticalAlign: "bottom" }}
                          size={14}
                          color={theme.BLACK_COLOR}
                        />
                      )}
                    </CallLibraryTH>
                    <CallLibraryTH>Owner</CallLibraryTH>
                    <CallLibraryTH>No. of Calls</CallLibraryTH>
                  </CallLibraryTR>
                  <tbody style={{ overflow: "hidden" }}>
                    {dataFolders.fetchViewableFolders
                      .slice()
                      .sort((a: any, b: any) =>
                        sortOrder === "ascending"
                          ? b.name.toLowerCase().localeCompare(a.name.toLowerCase())
                          : a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                      )
                      ?.map((item: any) => (
                        <CallLibraryTR>
                          <CallLibraryTD
                            onClick={async () => {
                              setSelectedFolder({
                                id: item.id,
                                name: item.name,
                                conferences: item.conferences,
                                owner: item.owner,
                              });
                              setQueryFolderID(item.id);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <img src={callFolderIcon} alt="Call folder" style={{ marginRight: "12px" }} />
                              {item.name}
                            </div>
                          </CallLibraryTD>
                          <CallLibraryTD>
                            {!!item.owner?.full_name ? item.owner?.full_name : "Unassigned"}
                          </CallLibraryTD>
                          <CallLibraryTD>{item.size}</CallLibraryTD>
                          <CallLibraryTD>
                            <CreateOptionsComponent item={item} />
                          </CallLibraryTD>
                        </CallLibraryTR>
                      ))}
                  </tbody>
                </CallLibraryTable>
              )}
            </MainContainer>
          </CallLibraryBox>
          {/* </OnboardingWrapper> */}
        </Main>
      </OnboardingColorWrapper>
    </Sentry.ErrorBoundary>
  );
};

const FolderNamesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const IconDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  line-height: 13px;
  margin-left: 20px;
  cursor: pointer;
`;

const ListItemDuration = styled.div`
  border: solid 0.7px ${theme.PRIMARY500};
  border-radius: 2.1px;
  width: 74px;
  height: 17px;
  font-size: 10px;
  float: left;
  margin: 10px 0px;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 70px;
  /* top: 30px; */
  width: 200px;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  justify-content: canter;
  align-items: center;
  height: 37px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: ${theme.WHITE_COLOR};
  cursor: pointer;
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: rgba(62, 101, 255, 0.1);
  }
`;

const ContainerDiv = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHr = styled.hr`
  border: none;
  border-bottom: 1px solid #d8d8d8;
`;

const MainContainer = styled.div`
  padding: 34px 24px;
`;

const HorizontalDiv = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

interface SelectedProp {
  selected?: boolean;
}

const FolderLink = styled(AppText)<SelectedProp>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-left: 14px;
  margin-top: 16px;
  color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.BLACK_COLOR)};
  border-bottom: none;
  hyphens: auto;
  /* word-break: break-all; */
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;

const HeaderText = styled(AppText)<SelectedProp>`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.BLACK_COLOR)};
  border-bottom: none;
`;

const YourFoldersLabel = styled(AppText)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #979797;
  margin-top: 30px;
`;

const FoldersDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0px;
  padding: 0px 32px;
  border-right: 1px solid #d8d8d8;
`;

interface CallLibraryUnreadProp {
  blueDot?: boolean;
}

const CallLibraryTR = styled.tr<CallLibraryUnreadProp>`
  border: none;
  border-bottom: solid 1px ${theme.NEUTRAL100};
  td:nth-child(1) {
    max-width: 275px;
  }
  td:nth-child(4) {
    /* width: 400px; */
    float: right;
    padding: 5px 0px;
  }
  td:last-child {
    text-align: right;
  }
  /* :first-child {
    border-bottom: none;
  } */
  :last-child {
    border-bottom: none;
  }
`;

const CallLibraryTD = styled.td<CallLibraryUnreadProp>`
  font-size: 14px;
  line-height: 17px;
  color: ${theme.BLACK_COLOR};
  border: none;
  height: 52px;
  text-align: left;
  hyphens: auto;
  word-break: break-all;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 7px;
  ::before {
    content: "";
    position: absolute;
    left: 15px;
    margin-top: 6px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) => (props.blueDot ? theme.PRIMARY500 : "transparent")};
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ItalicsTableText = styled(AppText)`
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  text-align: left;
  font-style: italic;
  opacity: 0.5;
`;

const CallLibraryTH = styled.th`
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  height: 42px;
  text-align: left;
`;

const CallLibraryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  /* height: 100%; */
`;

const CallLibraryBox = styled.div`
  width: 100%;
  /* min-height: 800px; */
  height: auto;
  display: grid;
  grid-template-columns: 190px 1fr;
  border-radius: 8px;
  /* background-color: ${theme.WHITE_COLOR}; */
`;

interface ModalOnProps {
  blinds?: boolean;
}

const StepLocationText = styled(AppText)`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 41px;
  padding-left: 32px;
  text-align: left;
  width: 100%;
`;

const OnboardingColorWrapper = styled.div`
  background: ${theme.NEUTRAL100};
  position: relative;
  /* height: 100%; */
`;

const Main = styled.div<ModalOnProps>`
  background: ${theme.WHITE_COLOR};
  padding: 24px;
  border-radius: 8px;
  margin: 24px;
`;

const OnboardingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export { CallLibraryPage };
