import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export const FETCH_CUSTOM_FIELD_DROPDOWN_OPTIONS = gql`
  query getCustomFieldOptions($key: String!) {
    getCustomFieldOptions(key: $key)
  }
`;

const useCustomFieldDropdownOptions = (key?: string) => {
  const { data, loading, error } = useQuery(FETCH_CUSTOM_FIELD_DROPDOWN_OPTIONS, {
    variables: {
      key,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
    skip: !key,
  });

  const options = loading ? [{ label: "Loading...", value: "" }] : data?.getCustomFieldOptions || [];

  return { data, loading, error, options };
};

export default useCustomFieldDropdownOptions;
