import * as React from "react";
import * as Sentry from "@sentry/react";
import { useState, useContext, useEffect } from "react";
import { ModalContext } from "../../context";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { gql, useQuery, useMutation } from "@apollo/client";

import { AppModalPopup, AppText, Loading, NewAppSelect } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { FlexDiv } from "../UI/FlexDiv";
import { primary_x } from "../../images/NewDesign";
import { loggedInUser } from "../../apollo/cache";
import { MixpanelActions } from "../../services/mixpanel";

const FETCH_SOURCE = gql`
  query fetchSource($lead_id: String) {
    fetchLead(id: $lead_id) {
      id
      business_name
      full_name
      rep {
        id
        full_name
      }
    }
  }
`;
const FETCH_LEAD = gql`
  query fetchLead($lead_id: String) {
    fetchLead(id: $lead_id) {
      id
      business_name
      full_name
      rep {
        id
        full_name
      }
    }
  }
`;

const MERGE_LEAD = gql`
  mutation MergeAssociateLead($primary_lead_id: String!, $associate_lead_id: String!, $rep_id: String) {
    mergeAssociateLead(primary_lead_id: $primary_lead_id, associate_lead_id: $associate_lead_id, rep_id: $rep_id) {
      message
      status
      status_boolean
    }
  }
`;

export const MergeLeadsModal: React.FC = () => {
  const {
    showMergeLeadsModal,
    setShowMergeLeadsModal,
    mergeLeadsData,
    setMergeLeadsData,
    setShowMergeContactsModal,
  } = useContext(ModalContext);
  const [step, setStep] = useState(1);
  const [submitData, setSubmitData] = useState<any>({});
  const [leadRadio, setLeadRadio] = useState(false);

  const { data: sourceData, loading: sourceLoading, error: sourceError } = useQuery(FETCH_SOURCE, {
    variables: {
      lead_id: mergeLeadsData?.source?.id,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: leadData, loading: leadLoading, error: leadError } = useQuery(FETCH_LEAD, {
    variables: {
      lead_id: mergeLeadsData?.lead?.id,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    /* 
      Update state value to be the same as BE. 
      This was implemented as a client request to deal w/ data being changed in a separate tab, 
      and the first tab not reflecting that change
    */
    if (!!sourceData && !!leadData) {
      const clone = { ...mergeLeadsData };

      clone.source.business_name = sourceData?.fetchLead?.business_name;
      clone.source.full_name = sourceData?.fetchLead?.full_name;
      clone.lead.business_name = leadData?.fetchLead?.business_name;
      clone.lead.full_name = leadData?.fetchLead?.full_name;

      setMergeLeadsData(clone);
    }
  }, [leadData, sourceData]);

  const [mergeLead, { loading: loadingMerge, error: errorMerge }] = useMutation(MERGE_LEAD, {
    async onCompleted({ mergeLead }) {
      appToast("Leads merged successfully!");
      setShowMergeLeadsModal(false);
      setShowMergeContactsModal(false);

      MixpanelActions.track("Contacts Associated");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `mergeLead GraphQL Error: ${message}`,
      });
      console.log("Error in mergeLeadErrorLog: ", message);
    },
    refetchQueries: [
      "FetchAssociateContact",
      "fetchAssociateParent",
      "fetchAssociateContactHistory",
      "fetchLead",
      "fetchScheduledItems",
    ],
  });

  const handleContinue = () => {
    if (step === 1 && (loggedInUser().role === "ADMIN" || loggedInUser().role === "SM")) {
      setStep(2);
      !leadRadio
        ? setSubmitData({ primary_lead_id: mergeLeadsData?.source?.id, associate_lead_id: mergeLeadsData?.lead?.id })
        : setSubmitData({ primary_lead_id: mergeLeadsData?.lead?.id, associate_lead_id: mergeLeadsData?.source?.id });
    } else if (step === 1 && loggedInUser().role !== "ADMIN" && loggedInUser().role !== "SM") {
      !leadRadio
        ? mergeLead({
            variables: {
              primary_lead_id: mergeLeadsData?.source?.id,
              associate_lead_id: mergeLeadsData?.lead?.id,
              rep_id: loggedInUser().id,
            },
          })
        : mergeLead({
            variables: {
              primary_lead_id: mergeLeadsData?.lead?.id,
              associate_lead_id: mergeLeadsData?.source?.id,
              rep_id: loggedInUser().id,
            },
          });
    } else {
      mergeLead({ variables: { ...submitData } });
    }
  };

  const handleRepOptions = () => {
    const options = [];
    if (sourceData?.fetchLead?.rep?.id)
      options.push({ label: sourceData?.fetchLead?.rep?.full_name, value: sourceData?.fetchLead?.rep?.id });
    if (leadData?.fetchLead?.rep?.id && leadData?.fetchLead?.rep?.id !== sourceData?.fetchLead?.rep?.id)
      options.push({ label: leadData?.fetchLead?.rep?.full_name, value: leadData?.fetchLead?.rep?.id });
    if (options.length < 2) options.push({ label: "Unassigned", value: null });
    return options;
  };

  return (
    <AppModalPopup blinds={showMergeLeadsModal} setBlinds={setShowMergeLeadsModal} unbordered width={476} height={313}>
      <EditCardDiv style={{ height: "auto" }}>
        <CloseButton
          onClick={() => {
            setShowMergeLeadsModal(false);
          }}
        >
          <img src={primary_x} alt="Close" />
        </CloseButton>

        <TitleDiv>
          <TitleText>Merge Leads</TitleText>
        </TitleDiv>

        <ModalBody>
          <AppText fontSize={14}>
            {step === 1
              ? `You have selected ${
                  leadRadio
                    ? `'${mergeLeadsData?.source?.business_name}' (${mergeLeadsData?.source?.full_name || "No Name"})`
                    : `'${mergeLeadsData?.lead?.business_name}' (${mergeLeadsData?.lead?.full_name || "No Name"})`
                } to be associated with ${
                  leadRadio
                    ? `'${mergeLeadsData?.lead?.business_name}' (${mergeLeadsData?.lead?.full_name || "No Name"})`
                    : `'${mergeLeadsData?.source?.business_name}' (${mergeLeadsData?.source?.full_name || "No Name"})`
                }:`
              : "Who will own this lead?"}
          </AppText>
          <FlexDiv gap={24}>
            {step === 1 ? (
              <>
                <RadioWrap>
                  <Radio
                    type="radio"
                    name="primary"
                    checked={!leadRadio}
                    onClick={() => setLeadRadio(false)}
                    readOnly
                  />
                  <div>
                    <div>{mergeLeadsData?.source?.business_name}</div>
                    <div>{mergeLeadsData?.source?.full_name ? mergeLeadsData?.source?.full_name : "No Name"}</div>
                  </div>
                </RadioWrap>
                <RadioWrap>
                  <Radio type="radio" name="primary" checked={leadRadio} onClick={() => setLeadRadio(true)} readOnly />
                  <div>
                    <div>{mergeLeadsData?.lead?.business_name}</div>
                    <div>{mergeLeadsData?.lead?.full_name ? mergeLeadsData?.lead?.full_name : "No Name"}</div>
                  </div>
                </RadioWrap>
              </>
            ) : (
              <div style={{ width: "376px" }}>
                <NewAppSelect
                  options={
                    sourceLoading || leadLoading
                      ? [
                          {
                            label: "Loading...",
                            value: "",
                          },
                        ]
                      : [...handleRepOptions()?.map((ele: any) => ele)]
                  }
                  onChange={(e: any) => {
                    // add dropdown value to submitData obj as 'rep_id'
                    const newSubmitData = { ...submitData };
                    newSubmitData.rep_id = e.value;
                    setSubmitData(newSubmitData);
                  }}
                  noErrorNeeded={true}
                ></NewAppSelect>
              </div>
            )}
          </FlexDiv>
        </ModalBody>
        {console.log(submitData)}
        <ButtonContainer>
          <NewAppButton
            variant="attention"
            width={95}
            style={{ fontSize: "10px", letterSpacing: "1px", textTransform: "uppercase" }}
            onClick={() => setShowMergeLeadsModal(false)}
          >
            Cancel
          </NewAppButton>
          <NewAppButton
            variant="primary"
            width={108}
            style={{ fontSize: "10px", letterSpacing: "1px", textTransform: "uppercase" }}
            onClick={() => handleContinue()}
            disabled={(step === 2 && submitData?.rep_id === undefined) || loadingMerge}
          >
            Continue
          </NewAppButton>
        </ButtonContainer>
      </EditCardDiv>
    </AppModalPopup>
  );
};

const EditCardDiv = styled.div`
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  z-index: 5;
`;

const TitleDiv = styled.div`
  position: relative;
  width: 100%;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 48px;
`;

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 58px;
  padding: 0px 60px 0px 60px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 193px;
  height: 80px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const RadioWrap = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
`;

const Radio = styled.input`
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
  cursor: pointer;
`;
