import styled from "styled-components";
import * as React from "react";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { theme } from "../../../utils/theme";
import { AppInput, AppButton, AppText, AppSidebarCard, AppErrorText, Loading } from "../../UI";
import {
  iconPhoneCallback,
  iconDemoDM,
  iconDemoNDM,
  notInterested,
  closedDemo,
  closedLead,
  leftVoicemail,
  noContactPhone,
  iconProspectInterested,
  iconDecisionCall,
} from "../../../images/";
import { appToast } from "../../../utils/toast";

interface IToastProps {
  closeToast?: any;
  toastProps?: any;
  type?: any;
  completed_text: string;
  completed_image: string;
  completed_tip: string;
}

const returnCorrespondingColor = (completed_image: string) => {
  switch (completed_image) {
    case "arrow":
      return "#351e89";

    case "ScheduledDM":
      return "#ccfd83";
    case "ScheduledNDM":
      return "#fff973";

    case "ClosedDemo":
      return "linear-gradient(135deg, #f8f8f8, #eeeeee)";

    case "LeftVoicemail":
      return "linear-gradient(135deg, #d627ff 2%, #f72c6d)";

    case "NotInterested":
      return "linear-gradient(319deg, #ec4c4c 99%, #230303 16%)";

    case "RetiredLead":
    case "OutOfBusiness":
      return "linear-gradient(319deg, #ffdba1 100%, #fdd79a 15%)";

    case "CallDisconnected":
      return "linear-gradient(319deg, #005892 99%, #2af6ff 16%)";
    case "DeadLine":
      return "linear-gradient(319deg, #ff6727 99%, #ff0d0d 16%)";

    case "ProspectInterested":
      return "linear-gradient(135deg, #2b3fb5, #5085cd)";

    case "ScheduledDecisionCall":
      return "linear-gradient(135deg, #173c76, #1c56b1)";

    default:
      return "#351e89";
  }
};

const returnCorrespondingImage = (completed_image: string) => {
  switch (completed_image) {
    case "arrow":
      return iconPhoneCallback;

    case "ScheduledDM":
      return iconDemoDM;

    case "ScheduledNDM":
      return iconDemoNDM;

    case "ClosedDemo":
      return closedDemo;

    case "LeftVoicemail":
      return leftVoicemail;

    case "NotInterested":
      return notInterested;

    case "RetiredLead":
    case "OutOfBusiness":
      return closedLead;

    case "CallDisconnected":
    case "DeadLine":
      return noContactPhone;

    case "ProspectInterested":
      return iconProspectInterested;

    case "ScheduledDecisionCall":
      return iconDecisionCall;

    default:
      return iconPhoneCallback;
  }
};

const CustomToastComponent: React.FC<IToastProps> = ({ type, completed_text, completed_image, completed_tip }) => {
  if (completed_image === "arrow") {
    appToast("Call Result Confirmed");
    return <></>;
  }

  return (
    <ToastGrid>
      <IconContainer color={returnCorrespondingColor(completed_image)}>
        <img src={returnCorrespondingImage(completed_image)} alt="" height="48px" />
      </IconContainer>
      <TextContainer>
        <ToastText1>{completed_text}</ToastText1>
        {completed_tip !== "You make an average of $X every time you take Y action (e.g., dial)" && (
          <>
            <ToastText2>COACHING TIP</ToastText2>
            <ToastText3>{completed_tip}</ToastText3>
          </>
        )}
      </TextContainer>
    </ToastGrid>
  );
};

const TextContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
`;

const StyledToastContainer = styled(ToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress",
})`
  .toast-container {
    width: 360px;
    padding: 0px;
    margin: 0px;
  }

  /* .toast is passed to toastClassName */
  .toast {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
  }

  button[aria-label="close"] {
    display: none;
  }

  /* .body is passed to bodyClassName */
  .body {
    padding: 0px;
    margin: 0px;
  }

  /* .progress is passed to progressClassName */
  .progress {
    padding: 0px;
    margin: 0px;
  }
`;

const CloseToast = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  left: -14px;
  top: -14px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 9999;
`;

const ToastText1 = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 11px;
  margin-left: 11px;
  max-width: 200px;
`;
const ToastText2 = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 9px;
  font-weight: 600;
  margin-top: 11px;
  margin-left: 11px;
  max-width: 200px;
`;
const ToastText3 = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 9px;
  font-style: italic;
  line-height: 1.33;
  margin-top: 4px;
  margin-left: 11px;
  max-width: 200px;
`;

interface BGColorProp {
  color: string;
}

const IconContainer = styled.div<BGColorProp>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  width: 85px;
  height: 85px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
`;

const ToastGrid = styled.div`
  width: 360px;
  height: 74px;
  padding-left: 85px;
  margin: 0px;
  display: flex;
  align-items: center;
`;

export { CustomToastComponent };
