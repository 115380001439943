import { createGlobalStyle } from "styled-components";

import InterBlack from "./Inter-Black.woff";
import InterBlackItalic from "./Inter-BlackItalic.woff";
import InterExtraBold from "./Inter-ExtraBold.woff";
import InterExtraBoldItalic from "./Inter-ExtraBoldItalic.woff";
import InterBold from "./Inter-Bold.woff";
import InterBoldItalic from "./Inter-BoldItalic.woff";
import InterSemiBold from "./Inter-SemiBold.woff";
import InterSemiBoldItalic from "./Inter-SemiBoldItalic.woff";
import InterMedium from "./Inter-Medium.woff";
import InterMediumItalic from "./Inter-MediumItalic.woff";
import InterRegular from "./Inter-Regular.woff";
import InterItalic from "./Inter-Italic.woff";
import InterLight from "./Inter-Light.woff";
import InterLightItalic from "./Inter-LightItalic.woff";
import InterExtraLight from "./Inter-ExtraLight.woff";
import InterExtraLightItalic from "./Inter-ExtraLightItalic.woff";
import InterThin from "./Inter-Thin.woff";
import InterThinItalic from "./Inter-ThinItalic.woff";

export default createGlobalStyle`
    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 100;
        font-display: swap;
        src: local("Inter"), url(${InterThin}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 100;
        font-display: swap;
        src: local("Inter"), url(${InterThinItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 200;
        font-display: swap;
        src: local("Inter"), url(${InterExtraLight}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 200;
        font-display: swap;
        src: local("Inter"), url(${InterExtraLightItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 300;
        font-display: swap;
        src: local("Inter"), url(${InterLight}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 300;
        font-display: swap;
        src: local("Inter"), url(${InterLightItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 400;
        font-display: swap;
        src: local("Inter"), url(${InterRegular}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 400;
        font-display: swap;
        src: local("Inter"), url(${InterItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 500;
        font-display: swap;
        src: local("Inter"), url(${InterMedium}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 500;
        font-display: swap;
        src: local("Inter"), url(${InterMediumItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 600;
        font-display: swap;
        src: local("Inter"), url(${InterSemiBold}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 600;
        font-display: swap;
        src: local("Inter"), url(${InterSemiBoldItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 700;
        font-display: swap;
        src: local("Inter"), url(${InterBold}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 700;
        font-display: swap;
        src: local("Inter"), url(${InterBoldItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 800;
        font-display: swap;
        src: local("Inter"), url(${InterExtraBold}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 800;
        font-display: swap;
        src: local("Inter"), url(${InterExtraBoldItalic}) format("woff");
    }

    @font-face {
        font-family: "Inter";
        font-style:  normal;
        font-weight: 900;
        font-display: swap;
        src: local("Inter"), url(${InterBlack}) format("woff");
    }
    @font-face {
        font-family: "Inter";
        font-style:  italic;
        font-weight: 900;
        font-display: swap;
        src: local("Inter"), url(${InterBlackItalic}) format("woff");
    }
`;
