import styled from "styled-components";
import * as Sentry from "@sentry/react";
import React, { useMemo } from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { AppButton, AppText, Loading, NewAppButton, AppIconButton, AppInput, SkeletonBlock } from "../../UI";
import { currentCallState } from "../../../apollo/cache";
import { theme } from "../../../utils/theme";
import { useMutation, gql, useQuery, useLazyQuery, useSubscription } from "@apollo/client";
import { CallContext } from "../../../context";
import { restAPI } from "../../../apollo";
import {
  ABLY_API_KEY,
  BACKEND_URL,
  DEFAULT_CLOUDINARY_IMAGE,
  FETCH_USER_TRANSFER_LIST_INTERVAL,
  TRANSFER_TIMEOUT_SECONDS,
} from "../../../utils/variables";
import moment from "moment";
import { appToast, errorToast } from "../../../utils/toast";

import { formatCellData } from "../../../utils/format";
import { iconArrowDown, transferInProgress } from "../../../images";

import { ProfileImageWithText } from "../SettingSegments/ProfileImageWithText";
import { PhoenixIcon } from "../../UI/Phoenix";
import { bar_chart_down } from "../../../images/NewDesign";
import { formatTimeDifferenceByDelineation } from "../../../utils/format";

const FETCH_GLOBAL_TRANSFER_RULE = gql`
  query fetchGlobalTransferRule {
    fetchGlobalTransferRule {
      id
      organization_id
      user_effective_transfer_type
    }
  }
`;

// fetchReps

const FETCH_REPS = gql`
  query FetchAvailableRep($repIds: [String!]!) {
    fetchAvailableRep(rep_ids: $repIds) {
      user_id
      first_name
      last_name
      team_name
      profile_image
      site_id
      site_name
      role
      updated_at
      status
      status_label
      close_rate
    }
  }
`;

interface TransferProps {
  availableRepsForBooking: string[];
  handleNext: () => void;
  handleSelectRep: (rep: string) => void;
  selectedRep?: string;
}

const formatSortOptionName = (name: string) => {
  switch (name) {
    case "CloseRate":
      return "Close Rate";
    case "SiteName":
      return "Site Name";
    default:
      return "";
  }
};

const SelectRepForBooking: React.FC<TransferProps> = ({
  handleSelectRep,
  handleNext,
  availableRepsForBooking,
  selectedRep,
}) => {
  const intervalId = useRef(null);
  const [timeSinceTransferRequest, setTimeSinceTransferRequest] = useState(0);
  const [autoTransferOn, setAutoTransferOn] = useState(true);
  const [transfer_attempt_id, setTransferAttemptId] = useState("");
  const [auto_transfer_selection_id, setAutoTransferSelectionId] = useState("");
  const [destinationUser, setDestinationUser] = useState({ user_id: "", user_name: "", user_profile_image: "" });

  const [sortOption, setSortOption] = useState("CloseRate");
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [expandSortButton, setExpandSortButton] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const lottieOptions = (loop: boolean, animationData: any) => {
    return {
      loop: loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  };

  const { data: dataGlobalTransferRule, loading: loadingGlobalTransferRule, error: errorGlobalTransferRule } = useQuery(
    FETCH_GLOBAL_TRANSFER_RULE,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: repData, loading: loadingRep, error: errorRep } = useQuery(FETCH_REPS, {
    skip: !availableRepsForBooking.length,
    variables: {
      repIds: availableRepsForBooking,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching reps");
    },
  });

  /* current time for rep last active calculation

updating current time on re-render leads to weird UX.

if you want the current time to update add the state variable that will change to the dependency array
*/

  const now = useMemo(() => moment(), []);

  const MemoizedTimeSinceLastActive = React.memo(
    ({ item, selected, theme, now }: { item: any; selected: boolean; theme: any; now: moment.Moment }) => {
      return (
        <div>
          <AppText
            key={item?.user_id}
            style={{
              color: `${selected ? theme.WHITE_COLOR : theme.BLACK_COLOR}`,
              textAlign: "left",
            }}
          >
            {`Last updated ${formatTimeDifferenceByDelineation({
              startingTimeMoment: moment(item?.updated_at),
              endingTimeMoment: now,
              showEndingText: true,
              roundNumber: true,
            })}`}
          </AppText>
        </div>
      );
    },
  );

  // If there is no user_id, show a list

  const loading = loadingRep || loadingGlobalTransferRule;

  const error = errorGlobalTransferRule || errorRep;

  if (loading) {
    return <SkeletonBlock width={"100%"} height={500} borderRadius={4} />;
  }

  if (error) {
    return <AppText>An Error Occured </AppText>;
  }

  return (
    <TransferDiv>
      <AppText style={{ fontSize: "16px", margin: "0px auto 0px auto" }}>Choose Rep For Booking</AppText>
      <UserStatusTitleDiv>
        <UserTitle>Reps</UserTitle>

        <div style={{ display: "flex", alignItems: "center" }}>
          <AppIconButton onClick={() => setAscendingOrder(!ascendingOrder)}>
            <img
              style={{
                transform: !!ascendingOrder ? "rotate(180deg" : "rotate(0deg)",
              }}
              src={iconArrowDown}
            />
          </AppIconButton>
          <UserTitle>Sort by {formatSortOptionName(sortOption)}</UserTitle>
          <AppIconButton onClick={() => setExpandSortButton(!expandSortButton)}>
            <PhoenixIcon svg={bar_chart_down} size={20} variant="brand" />
          </AppIconButton>
        </div>
        {!!expandSortButton && (
          <OptionsDiv>
            <Option
              onClick={() => {
                setExpandSortButton(!expandSortButton);
                setSortOption("CloseRate");
              }}
            >
              Close Rate
            </Option>
            <Option
              onClick={() => {
                setExpandSortButton(!expandSortButton);
                setSortOption("SiteName");
              }}
            >
              Site Name
            </Option>
          </OptionsDiv>
        )}
      </UserStatusTitleDiv>
      <AppInput
        placeholder="Search for Rep..."
        value={searchValue}
        onChange={(e: any) => setSearchValue(e.target.value)}
      />
      <ButtonsDiv>
        {repData?.fetchAvailableRep
          ?.filter((item: any) => {
            const lowerCaseSearch = searchValue.toLowerCase();
            const fullName = (item?.first_name + " " + item?.last_name).toLowerCase();
            return fullName.includes(lowerCaseSearch);
          })
          .sort((a: any, b: any) => {
            if (sortOption === "CloseRate") {
              return ascendingOrder ? a?.close_rate - b?.close_rate : b?.close_rate - a?.close_rate;
            } else if (sortOption === "SiteName") {
              return ascendingOrder
                ? // return it alphabetically
                  a?.site_name?.localeCompare(b?.site_name)
                : // return it alphabetically backwards
                  b?.site_name?.localeCompare(a?.site_name);
            } else {
              return 0;
            }
          })
          ?.map((item: any) => (
            <NewAppButton
              key={item.user_id}
              variant={"secondary"}
              height={88}
              selected={item?.user_id === selectedRep}
              onClick={() => {
                handleSelectRep(item?.user_id);
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", height: 88 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "16px",
                    justifyContent: "center",
                  }}
                >
                  <ProfileImageWithText
                    profile_image={item?.profile_image}
                    first_name={item?.first_name}
                    last_name={item?.last_name}
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    flexGrow: 2,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div>
                    <AppText>{`${item?.first_name} ${item?.last_name} (${item?.role})`}</AppText>
                  </div>
                  <MemoizedTimeSinceLastActive
                    item={item}
                    theme={theme}
                    now={now}
                    selected={item?.user_id === selectedRep}
                  />
                  <div
                    style={{
                      display: "inline-flex",
                      flexDirection: "row",
                      gap: 8,
                      color: `${item?.user_id === selectedRep ? theme.WHITE_COLOR : theme.BLACK_COLOR}`,
                    }}
                  >
                    <AppText>{`${item?.team_name}`}</AppText>
                    <AppText>{`${formatCellData(item?.close_rate, "percentage")}% CR`}</AppText>
                    <AppText>{`${item?.site_name}`}</AppText>
                  </div>
                </div>
                <div style={{ alignSelf: "center", display: "inline-flex", justifyContent: "flex-end", flexGrow: 1 }}>
                  <AppText>{item?.status_label?.toUpperCase()}</AppText>
                </div>
              </div>
            </NewAppButton>
          ))}
      </ButtonsDiv>
      <NewAppButton
        style={{ marginTop: 20 }}
        size={"lg"}
        variant={"primary"}
        onClick={() => {
          // set rep id
          handleNext();
        }}
      >
        Choose Rep for Event.
      </NewAppButton>
    </TransferDiv>
  );
};

const TransferDiv = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 40px;
  text-align: center;
`;

const TransferText = styled(AppText)`
  font-size: 16px;
  text-align: center;
`;

const TransferTextDescription = styled(AppText)`
  font-size: 14px;
  text-align: center;
  width: 275px;
`;

const TransferTextFocus = styled(AppText)`
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  line-height: 28px;
`;

const UserTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`;

const UserStatusTitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonsDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* overflow-y: scroll; */
  /* height: 450px; */
  flex-grow: 2;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 124px;
  border: 3px solid #3e65ff;
  margin-bottom: 8px;
`;

const TextDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const OptionsDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 112px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 40px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

export { SelectRepForBooking };
