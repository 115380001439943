import styled from "styled-components";

import { AppText, LeadCardLeadHistoryItem, TemplateEditor } from "./../UI";
import * as React from "react";

import { displaySanitizedHTML } from "./../../utils/misc";

import { Modal } from "./Modal";

import { useContext, useEffect, useState, useMemo } from "react";
import { ModalContext } from "./../../context";

const SMSAndEmailDetailsModal = () => {
  const { showSMSAndEmailDetailsModal, setShowSMSAndEmailDetailsModal, SMSAndEmailDetailsModalData } = useContext(
    ModalContext,
  );

  const item = SMSAndEmailDetailsModalData;

  return (
    <Modal open={showSMSAndEmailDetailsModal} onClose={() => setShowSMSAndEmailDetailsModal(false)}>
      <EditCardDiv>
        <LeadCardLeadHistoryItem
          showExtendedInfoSections={false}
          item={SMSAndEmailDetailsModalData}
          primary_lead_id={SMSAndEmailDetailsModalData?.modal_primary_lead_id}
          associate_lead_ids={SMSAndEmailDetailsModalData?.modal_associate_lead_ids}
          contact_id={SMSAndEmailDetailsModalData?.modal_contact_id}
          showRepIcon={false}
          isAssociatedLead={SMSAndEmailDetailsModalData?.modal_is_associated_lead}
        />

        {item.communication_type === "Email" && (
          <>
            <MessageDiv>
              <TitleText>Subject</TitleText>
              <BodyText>{item?.email_item?.subject ?? "NA"}</BodyText>
            </MessageDiv>
            <MessageDiv>
              <TitleText>Message</TitleText>
              <TemplateEditor ableToEdit={false} initialContent={item?.email_item?.html || item?.email_item?.body} />
            </MessageDiv>
          </>
        )}
        {item.communication_type === "SMS" && (
          <MessageDiv>
            <TitleText>Message</TitleText>
            <BodyText>{item?.SMSItem?.text ?? "NA"}</BodyText>
          </MessageDiv>
        )}
      </EditCardDiv>
    </Modal>
  );
};

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
  min-height: 400px;
  padding: 56px 40px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`;
interface RepAndResultIcon {
  background?: string;
  color?: string;
}
const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const MessageDiv = styled.div`
  margin-top: 24px;
`;
const BodyText = styled(AppText)`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
`;

export { SMSAndEmailDetailsModal };
