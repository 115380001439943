import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText, FlexDiv } from "../UI";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { RulesTableV2 } from "../Segments";
import { useHistory, useLocation } from "react-router-dom";
import { chevron_left } from "../../images/NewDesign";
import { LogTable } from "../Segments/Onboarding/routing-rules/LogTable";
import { MixpanelActions } from "src/services/mixpanel";

interface RoutingRulesV3Props {
  step: number;
  togglePageTitle: () => void;
  tab_type_id?: string;
  description?: string;
}

export const RoutingRulesV3: React.FC<RoutingRulesV3Props> = ({ step, togglePageTitle, tab_type_id, description }) => {
  const history = useHistory();
  const location = useLocation();

  const logPage = useMemo(() => {
    const split = location.pathname.split("/");
    return split[split.length - 1] === "log";
  }, [location]);

  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [submitFormFunction, setSubmitFormFunction] = useState<() => void>(() => {});

  const handleIsValidChange = (isValid: boolean) => {
    setFormIsValid(isValid);
  };
  const handleSubmitFormFunction = (submitForm: () => void) => {
    setSubmitFormFunction(() => submitForm);
  };

  const addRoutingRule = () => {
    MixpanelActions.track("Add Routing Rule Clicked");
    history.replace("/system-config/routing-rules/new");
  };

  return (
    <Page>
      <Container editState={!!tab_type_id && !logPage}>
        <Header>
          <FlexDiv direction="column" gap={4}>
            {!!tab_type_id && (
              <FlexDiv
                gap={4}
                align="center"
                style={{ cursor: "pointer", marginBottom: "8px" }}
                onClick={() => history.replace("/system-config/routing-rules/")}
              >
                <PhoenixIcon svg={chevron_left} size={16} pointer />
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  uppercase
                  letterSpacing={1}
                  color={theme.buttontext.brand_outline.default}
                >
                  Back
                </AppText>
              </FlexDiv>
            )}

            <AppText fontSize={22} fontWeight={500} lineHeight={28}>
              {logPage ? "Lifetime Leads Routed" : "Routing Rules"}
            </AppText>
            {!tab_type_id && (
              <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.text.neutral.secondary}>
                {description}.
              </AppText>
            )}
          </FlexDiv>

          {!tab_type_id ? (
            <PhoenixAppButton variant="brand" buttonType="secondary" onClick={addRoutingRule}>
              Add Routing Rule
            </PhoenixAppButton>
          ) : (
            !logPage && (
              <FlexDiv align="center" gap={8}>
                {tab_type_id !== "new" && (
                  <PhoenixAppButton
                    variant="danger-outline"
                    buttonType="secondary"
                    onClick={() => setShowDeleteRuleModal(true)}
                  >
                    Delete Rule
                  </PhoenixAppButton>
                )}
                <PhoenixAppButton
                  variant="brand"
                  buttonType="secondary"
                  onClick={submitFormFunction}
                  disabled={!formIsValid}
                >
                  Save Rule
                </PhoenixAppButton>
              </FlexDiv>
            )
          )}
        </Header>

        <Body>
          {!logPage ? (
            <RulesTableV2
              step={step}
              togglePageTitle={togglePageTitle}
              rule_id={tab_type_id}
              rule={"lead"}
              rule_subtitle={"Lead Routing Rules"}
              showDeleteRuleModal={showDeleteRuleModal}
              setShowDeleteRuleModal={setShowDeleteRuleModal}
              onIsValidChange={handleIsValidChange}
              onSubmitFormFunction={handleSubmitFormFunction}
            />
          ) : (
            <LogTable rule_id={tab_type_id} />
          )}
        </Body>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100vh;

  padding: 24px;
`;

const Container = styled.div<{ editState: boolean }>`
  width: 100%;
  height: 100%;

  min-width: 1200px;

  background-color: ${({ editState }) => (editState ? theme.surface.brand.secondary : theme.fill.neutral.primary)};
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 40px;

  background-color: ${theme.fill.neutral.primary};
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Body = styled.div`
  padding: 40px;
  max-height: 82vh;
  overflow-y: auto;
`;
