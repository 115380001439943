import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { environment } from "./utils/variables";
import ReactGA from "react-ga";

ReactGA.initialize("UA-128203228-7");

Sentry.init({
  dsn: "https://fc9fbbf59928495eaf81a3311afa4831@o241222.ingest.sentry.io/5434072",
  environment,
  ignoreErrors: [
    /^TypeError: Failed to execute 'connect' on 'AudioNode': Overload resolution failed.$/,
    /^TypeError: Cannot read properties of null (reading 'connect')$/,
    /^TransportError: TransportError (31009): No transport available to send or receive messages$/,
    /^UnknownError: UnknownError (31000): An unknown error has occurred. See error details for more information.$/,
  ],
  // Security PenTest: don't leak token to third party vendors
  beforeSend(event) {
    const currentUrl = window.location.href;

    if (currentUrl.includes("new-password")) {
      if (event?.request?.url) {
        const splitURL = event.request.url.split("?");
        const baseURL = splitURL[0] ?? "";
        event.request.url = splitURL.length > 1 ? baseURL : "";
      }
      if (event?.request?.headers?.Referer) {
        event.request.headers.Referer = "";
      }

      if (event?.breadcrumbs) {
        event.breadcrumbs = [];
      }
    }

    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
