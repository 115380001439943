import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useMemo, useEffect } from "react";
import { AppText, Loading, AppInput } from "../UI";
import { theme } from "../../utils/theme";
import { useContext, useState } from "react";
import { newCloseModal, showMoreArrow, iconFilterClose } from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import { GridFilterContext, ModalContext, LeadFilterContext } from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useQuery } from "@apollo/client";
import { countryCodes } from "../../static/countries";
import { groupBy } from "../../utils/misc";

// const GET_REP_FILTER_OPTIONS = gql`
//   query fetchOrganization {
//     fetchOrganization {
//       Reps {
//         id
//         full_name
//       }
//       Teams {
//         id
//         name
//       }
//       sites {
//         id
//         name
//       }
//     }
//   }
// `;

const GET_REP_FILTER_OPTIONS = gql`
  query {
    fetchOrganization {
      Teams {
        id
        name
        __typename
      }
      Reps {
        id
        full_name
        __typename
      }
      Sites {
        id
        name
        __typename
      }
    }
  }
`;

const RepFiltersComponent: React.FC = () => {
  // States for whether each filer is expanded
  const [expanded, setExpanded] = useState([] as string[]);

  const [filterInputs, setFilterInputs] = useState({} as any);
  // Context for filters
  const {
    leadSources,
    setLeadSources,
    groupOption,
    repFilter,
    setRepFilter,
    resetRepFilter,
    repFilterNum,
  } = useContext(GridFilterContext);
  const { showRepFiltersModal, setShowRepFiltersModal } = useContext(ModalContext);
  // const { leadFilter, setLeadFilter, setSelected, selected, resetLeadQueueFilters } = useContext(LeadFilterContext);

  const [tempRepFilter, setTempRepFilter] = useState(JSON.parse(JSON.stringify(repFilter)));

  const [showAllFilters, setShowAllFilters] = useState(false);

  // //Queries
  // const { data: leadSourceOptions, error: leadSourceError, loading: leadSourceLoading } = useQuery(
  //   GET_LEAD_SOURCE_OPTIONS,
  //   {
  //     fetchPolicy: "network-only",
  //     onError({ message, name }) {
  //       Sentry.captureEvent({
  //         message: `${name} GraphQL Error: ${message}`,
  //       });
  //       console.log(`Error in ${name}: `, message);
  //     },
  //   },
  // );
  // const { data: industryOptions, error: industryError, loading: industryLoading } = useQuery(GET_INDUSTRY_OPTIONS, {
  //   fetchPolicy: "network-only",
  //   onError({ message, name }) {
  //     Sentry.captureEvent({
  //       message: `${name} GraphQL Error: ${message}`,
  //     });
  //     console.log(`Error in ${name}: `, message);
  //   },
  // });
  // //const { data: cityOptions, error: cityErroy, loading: cityLoading } = useQuery(GET_CITY_OPTIONS);
  // const { data: stateOptions, error: stateError, loading: stateLoading } = useQuery(GET_STATE_OPTIONS, {
  //   fetchPolicy: "network-only",
  //   onError({ message, name }) {
  //     Sentry.captureEvent({
  //       message: `${name} GraphQL Error: ${message}`,
  //     });
  //     console.log(`Error in ${name}: `, message);
  //   },
  // });
  // const { data: tzOptions, error: tzError, loading: tzLoading } = useQuery(GET_TIMEZONE_OPTIONS, {
  //   fetchPolicy: "network-only",
  //   onError({ message, name }) {
  //     Sentry.captureEvent({
  //       message: `${name} GraphQL Error: ${message}`,
  //     });
  //     console.log(`Error in ${name}: `, message);
  //   },
  // });

  //TODO: Add city loading back in.
  // const loading = leadSourceLoading || industryLoading || stateLoading || tzLoading;
  // if (loading)
  //   return (
  //     <div>
  //       <Loading />
  //     </div>
  //   );

  // Custom Data

  const { data: repFilterData, error: repFilterError, loading: repFilterLoading } = useQuery(GET_REP_FILTER_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const teamList = useMemo(() => {
    const list = repFilterData?.fetchOrganization?.Teams?.map((item: any) => item.id) || [];
    list.push("NO_ID");
    return list;
  }, [repFilterData]);

  const userList = useMemo(
    () =>
      repFilterData?.fetchOrganization?.Reps?.slice()
        .sort((a: any, b: any) => (a?.full_name.toLowerCase() < b?.full_name.toLowerCase() ? -1 : 1))
        ?.map((item: any) => item.id) || [],
    [repFilterData],
  );

  const siteList = useMemo(() => {
    const list = repFilterData?.fetchOrganization?.Sites?.map((item: any) => item.id) || [];
    list.push("NO_ID");
    return list;
  }, [repFilterData]);

  // const userList = useMemo(() => {
  //   !!repFilterData?.users ? Object.keys(groupBy(repFilterData?.users, "id")) : [];
  // }, [repFilterData]);

  // const siteList = useMemo(() => {
  //   !!repFilterData?.sites ? Object.keys(groupBy(repFilterData?.sites, "id")) : [];
  // }, [repFilterData]);

  // const leadSourceOptionsArray = Object.keys(groupLevel)?.map((item: any) => item.label);
  // const groupOptionsArray = Object.keys(groupLevel);
  // Custom Functions
  const mapOptionsToKey = (key: string | undefined) => {
    switch (key) {
      case "roles":
        return ["AE", "SDR"];
      case "user_ids":
        return !!repFilterData ? userList : [];
      case "team_ids":
        return !!repFilterData ? teamList : [];
      case "site_ids":
        return !!repFilterData ? siteList : [];
      default:
        break;
    }
  };

  const mapLabelToKey = (key: string | undefined) => {
    switch (key) {
      case "roles":
        return "Role Type";
      case "user_ids":
        return "Reps";
      case "team_ids":
        return `Teams`;
      case "site_ids":
        return `Sites`;
      default:
        break;
    }
  };

  const formatFilterName = (key: string, name: string) => {
    if (key === "team_ids") {
      const result = repFilterData?.fetchOrganization?.Teams.find((item: any) => item.id === name);
      return result?.name ?? "No Team";
    }
    if (key === "user_ids") {
      const result = repFilterData?.fetchOrganization?.Reps.find((item: any) => item.id === name);
      return result?.full_name || "user";
    }
    if (key === "site_ids") {
      const result = repFilterData?.fetchOrganization?.Sites.find((item: any) => item.id === name);
      return result?.name ?? "No Site";
    }
    return name;
  };

  const handleCheckboxClick = (key: string, item: string | number) => {
    if (tempRepFilter[key]?.includes(item)) {
      const filterKeyArray = tempRepFilter[key]?.filter((selected: string) => {
        return selected !== item;
      });
      setTempRepFilter({
        ...tempRepFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempRepFilter({
        ...tempRepFilter,
        [key]: [...(tempRepFilter[key] ?? []), item],
      });
    }
  };

  const repFilterOptions = () => {
    switch (groupOption) {
      case "Site":
        return ["site_ids", "user_ids", "roles"];
      case "Team":
        return ["team_ids", , "user_ids", "roles"];
      default:
        return ["user_ids", "roles"];
    }
  };

  const handleFilterDelete = (key: string, item: string | number) => {
    // Temp component values
    const filterKeyArray = tempRepFilter[key]?.filter((selected: string) => {
      return selected !== item;
    });
    setTempRepFilter({
      ...tempRepFilter,
      [key]: filterKeyArray,
    });

    // Global values
    const filterKeyArrayMain = repFilter[key]?.filter((selected: string) => {
      return selected !== item;
    });
    setRepFilter({
      ...repFilter,
      [key]: filterKeyArrayMain,
    });
    // const newSelectedMain = selectedRepFilters
    //   .slice()
    //   .filter((filter: any) => !(filter.key === key && filter.item === item));
    // setSelectedRepFilters(newSelectedMain);
  };

  const resetTempRepFilters = () => {
    setTempRepFilter({
      team_ids: [],
      site_ids: [],
      roles: [],
      user_ids: [],
    });
  };
  // Custom Data
  const filterOptionsArray = repFilterOptions() || [];

  const tempRepFilterLength = Object.values(tempRepFilter).flat().length;

  return (
    <EditCardDiv>
      <TitleDiv>
        <TitleText>Rep Filters</TitleText>
        <CloseButton>
          <NewAppButton
            // small
            // borderless
            onClick={() => {
              setShowRepFiltersModal(!showRepFiltersModal);
            }}
          >
            <img src={newCloseModal} alt="Close" />
          </NewAppButton>
        </CloseButton>
      </TitleDiv>
      <ScrollDiv>
        {!!repFilterNum && (
          <PaddingDiv>
            <AdditionalFilters style={{ marginBottom: "15px" }}>
              <AppliedFiltersText>Applied Filters</AppliedFiltersText>
              <ClickableText
                onClick={() => {
                  resetRepFilter();
                  resetTempRepFilters();
                }}
              >
                Clear All
              </ClickableText>
            </AdditionalFilters>
            {Object.keys(repFilter)?.map((key: string, indexKeys: number) => {
              return repFilter[key]?.map(
                (item: any, indexItems: number) =>
                  (showAllFilters || indexKeys + indexItems <= 4) && (
                    <SelectedFilterDiv>
                      <AdditionalFilters>
                        <FilterLabelText>{formatFilterName(key, item)}</FilterLabelText>
                        <CloseImg
                          src={iconFilterClose}
                          alt="Remove filter"
                          onClick={() => {
                            handleFilterDelete(key, item);
                          }}
                        />
                      </AdditionalFilters>
                    </SelectedFilterDiv>
                  ),
              );
            })}
            {repFilterNum > 3 ? (
              <ClickableText
                style={{ width: "100%", textAlign: "center", margin: "16px 0px" }}
                onClick={() => setShowAllFilters(!showAllFilters)}
              >
                {showAllFilters ? "Show Less" : `Show More (+${repFilterNum - 3})`}
              </ClickableText>
            ) : (
              <div style={{ height: "24px" }} />
            )}
          </PaddingDiv>
        )}
        {filterOptionsArray?.map((item: string | undefined) => {
          const optionsArray = mapOptionsToKey(item);
          const key = item || "";
          return (
            <SectionContainerDiv key={key}>
              <SectionRow>
                <SectionTitle>
                  <AppText style={{ borderBottom: "none" }}>{mapLabelToKey(key)} &nbsp;</AppText>
                </SectionTitle>

                <div>
                  <NewAppButton
                    // small
                    // borderless
                    onClick={() =>
                      expanded?.includes(key)
                        ? setExpanded(expanded.filter((item) => item !== key))
                        : setExpanded([...expanded, key])
                    }
                  >
                    {expanded?.includes(key) ? (
                      <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                    ) : (
                      <img src={showMoreArrow} alt="Expand" />
                    )}
                  </NewAppButton>
                </div>
              </SectionRow>
              <PaddingAndScrollDiv>
                {expanded?.includes(key) && (
                  <>
                    <AppInput
                      onChange={(e: any) => {
                        setFilterInputs({ ...filterInputs, [key]: e.target.value });
                      }}
                      value={filterInputs[key]}
                    />
                    <InnerScrollDiv>
                      {optionsArray
                        ?.filter((item: string) => {
                          return !!filterInputs[key]
                            ? formatFilterName(key, item).toLowerCase().includes(filterInputs[key].toLowerCase())
                            : item;
                        })
                        ?.map((item: string) => {
                          return (
                            <FilterOptionsContainer>
                              <AppCheckbox
                                title={formatFilterName(key, item)}
                                checked={tempRepFilter[key]?.includes(item)}
                                onClick={() => {
                                  handleCheckboxClick(key, item);
                                }}
                              />
                            </FilterOptionsContainer>
                          );
                        })}
                    </InnerScrollDiv>
                  </>
                )}
              </PaddingAndScrollDiv>
            </SectionContainerDiv>
          );
        })}
      </ScrollDiv>

      <SubmitDiv>
        <NewAppButton
          onClick={() => {
            resetRepFilter();
            resetTempRepFilters();
            setShowRepFiltersModal(!showRepFiltersModal);
          }}
        >
          Reset Filters
        </NewAppButton>
        <NewAppButton
          variant={"primary"}
          onClick={() => {
            setRepFilter(tempRepFilter);
            setShowRepFiltersModal(!showRepFiltersModal);
          }}
          disabled={JSON.stringify(tempRepFilter) === JSON.stringify(repFilter)}
        >
          Apply {tempRepFilterLength > 0 && `(${tempRepFilterLength})`} Filters
        </NewAppButton>
      </SubmitDiv>
    </EditCardDiv>
  );
};

const ScrollDiv = styled.div`
  /* max-height: calc(100vh - 156px);
  height: calc(100vh - 156px); */
  overflow-y: auto;
  flex-grow: 2;
`;

const SubmitDiv = styled.div`
  /* position: absolute; */
  height: 80px;
  bottom: 20px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  /* overflow-x: hidden; */
  overflow-y: hidden;
  /* max-height: 240px; */
`;
interface SectionContainerDivProps {
  height: number;
}

const InnerScrollDiv = styled.div`
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 210px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  /* max-height: height; */
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 15;
  height: 100vh;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

const PaddingDiv = styled.div`
  padding: 24px;
  padding-bottom: 0px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ClickableText = styled(AppText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.PRIMARY500};
  cursor: pointer;
  border-bottom: none;
`;

const AdditionalFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FilterLabelText = styled(AppText)`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
  max-height: 20px;
  overflow: hidden;
  white-space: nowrap;
`;

const SelectedFilterDiv = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  box-sizing: border-box;
  border-radius: 32px;
  padding: 7px 12px;
  margin-bottom: 8px;
`;

const AppliedFiltersText = styled(AppText)`
  font-size: 14px;
  line-height: 21px;
`;

const CloseImg = styled.img`
  cursor: pointer;
`;

export { RepFiltersComponent };
