import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { CustomObjectField, CustomObjectRecord } from "../../../__generated__/graphql";
import { AppBackButton, AppText, Loading } from "../../UI";
import "./CustomObjectListView.css";
import { handleCustomObjectRecordDataType } from "../../../utils/other-functions";
import { theme } from "../../../utils/theme";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { EditCustomObjectFieldsComponent } from "../../modal/EditCustomObject";
import { appToast, errorToast } from "src/utils/toast";

interface ParamProps {
  type?: string;
  id?: string;
}

const FETCH_HUBSPOT_LEAD = gql`
  query fetchHubspotLead($company_id: String, $contact_id: String) {
    fetchHubspotLead(company_id: $company_id, contact_id: $contact_id) {
      id
      business_name
    }
  }
`;

export const HubSpotLeadRedirect: React.FC = () => {
  const { type, id } = useParams<ParamProps>();
  const history = useHistory();

  const [fetchHubspotLeadFromID, { data: data, loading: loading, error: error }] = useLazyQuery(FETCH_HUBSPOT_LEAD, {
    variables: type === "company" ? { company_id: id } : type === "contact" ? { contact_id: id } : null,
    fetchPolicy: "network-only",
    onError({ message }) {
      history.push("/dash");
      appToast(`Error: ${message}`);
    },
    onCompleted() {
      // alert(data);
      if (!!data?.fetchHubspotLead?.id) {
        history.push(`/lead-detail/${data?.fetchHubspotLead?.id}`);
        appToast(`Lead found. Redirected to ${data?.fetchHubspotLead?.business_name}.`);
      } else if (!data?.fetchHubspotLead?.id && !loading) {
        appToast(`No lead found with ${type} ID ${id}`);
        history.push("/dash");
        appToast(`No lead found with ${type} ID ${id}`);
        // alert("No lead ID found");
      }
    },
  });

  useEffect(() => {
    const redirect = async () => {
      if (type && id) {
        try {
          await fetchHubspotLeadFromID({
            variables: type === "company" ? { company_id: id } : type === "contact" ? { contact_id: id } : null,
          });
        } catch (error) {
          console.error("Error computing new ID:", error);
          errorToast(`No lead found with ${type} ID ${id}`);
        }
      }
    };

    redirect();
  }, [type, id]);

  return (
    <Container>
      <BodyContainer>
        <FlexCol>
          {/* <AppText>Redirecting...</AppText>
          <AppText>type: {type}</AppText>
          <AppText>id: {id}</AppText> */}
        </FlexCol>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 1200px;
  box-sizing: border-box;
  background: #fff;
  height: 100vh;
`;

const BodyContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #f7f9ff;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  padding: 40px;
`;
