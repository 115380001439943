import React, { useState, useEffect, useContext, useMemo } from "react";
import * as Sentry from "@sentry/react";
import styled from "styled-components";
import { CallContext, LeadFilterContext, AutoDialContext, LeadCardContext } from "../../context";
import { iconTimerPause, iconTimerPlay } from "../../images";
import { formatTime } from "../../utils/format";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { useQuery, gql } from "@apollo/client";
import { tzOverride } from "../../apollo/cache";
import { FETCH_CURRENT_LEAD_POLL_INTERVAL } from "../../utils/variables";
import { FETCH_NEXT_DIAL } from "../Segments";
import { getLocalStorage } from "../../utils/misc";
import { INextDialOverrideData } from "src/types/StateTypes";

const FETCH_ORGANIZATION_AUTO_DIAL = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      auto_dial
      auto_call_interval
    }
  }
`;

const FETCH_RECOMMENDED_LEAD = gql`
  query fetchRecommendedDial {
    fetchRecommendedDial {
      id
      lead_id
      type
      lead {
        id
        primary_phone_number
        city
        state
        country
        timezone_by_state
        lead_source
        industry
        sub_industry
        business_name
        full_name
        first_name
        last_name
      }
    }
  }
`;

const AutoDialTimerComponent = () => {
  const { callState, nextActionOverride, stopScheduledEventNotification, goToCall } = useContext(CallContext);
  const { leadIntentInNextDial } = (useContext(LeadCardContext) as unknown) as {
    leadIntentInNextDial: INextDialOverrideData;
  };

  const {
    dialTimer,
    setDialTimer,
    dialTimerBaseValue,
    setDialTimerBaseValue,
    pauseTimer,
    setPauseTimer,
    pauseAutoDial,
    setPauseAutoDial,
    resetTimers,
  } = useContext(AutoDialContext);

  const { leadFilter } = useContext(LeadFilterContext);

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchAutoDial } = useQuery(
    FETCH_ORGANIZATION_AUTO_DIAL,
    {
      fetchPolicy: "no-cache",

      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  // console.log("test", dataOrg);

  const { data: dataNext, loading: loadingNext, error: errorNext, refetch: refetchNext } = useQuery(FETCH_NEXT_DIAL, {
    // fetchPolicy: "no-cache",
    variables: {
      lead_filter: leadFilter,
      override_timezone_contraints: tzOverride(),
      pollInterval: FETCH_CURRENT_LEAD_POLL_INTERVAL, // 5 minutes
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: dataRecommended,
    loading: loadingRecommended,
    error: errorRecommended,
    refetch: refetchRecommended,
  } = useQuery(FETCH_RECOMMENDED_LEAD, {
    // fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const maxTimerNum = useMemo(() => {
    if (
      dataOrg?.fetchOrganization?.auto_dial &&
      !!dataOrg?.fetchOrganization?.auto_call_interval &&
      dataOrg?.fetchOrganization?.auto_call_interval > 0
    ) {
      return dataOrg?.fetchOrganization?.auto_call_interval;
    } else return 300;
  }, [dataOrg]);

  const shouldAutoDialEnable = useMemo(() => {
    return (
      !!dataNext &&
      !!dataNext.fetchNextDial &&
      dataNext.fetchNextDial.slice().filter((item: any) => item?.is_scheduled_item === true)
    );
  }, [dataNext]);

  const returnLeadToAutoDial = useMemo(() => {
    if (!stopScheduledEventNotification && shouldAutoDialEnable) {
      if (!!nextActionOverride?.intent_object?.lead?.id) {
        return nextActionOverride;
      } else if (!!dataNext && !!dataNext.fetchNextDial && !!dataNext.fetchNextDial.length) {
        return {
          intent_object: {
            ...nextActionOverride?.intent_object,
            id: dataNext.fetchNextDial[0].id,
            lead: {
              id: dataNext.fetchNextDial[0].lead?.id,
              primary_phone_number: dataNext.fetchNextDial[0].lead?.primary_phone_number,
            },
          },
          parent: "nextAction",
        };
      } else if (!!leadIntentInNextDial) {
        return {
          intent_object: {
            ...leadIntentInNextDial,
            id: leadIntentInNextDial.id,
            lead: {
              id: leadIntentInNextDial?.lead?.id,
              primary_phone_number: leadIntentInNextDial?.lead?.primary_phone_number,
            },
          },
          parent: "nextAction",
        };
      }
    } else return { intent_object: { id: "", lead: { id: "", primary_phone_number: "" } }, parent: undefined };
  }, [dataNext && dataNext.fetchNextDial, nextActionOverride, shouldAutoDialEnable, leadIntentInNextDial]);

  useEffect(() => {
    if (
      (!callState &&
        dataOrg?.fetchOrganization?.auto_dial &&
        !!dataOrg?.fetchOrganization?.auto_call_interval &&
        shouldAutoDialEnable &&
        !stopScheduledEventNotification &&
        dialTimerBaseValue !== dataOrg?.fetchOrganization?.auto_call_interval) || // if the org auto interval does not match client interval
      (!callState &&
        dataOrg?.fetchOrganization?.auto_dial &&
        !!dataOrg?.fetchOrganization?.auto_call_interval &&
        shouldAutoDialEnable &&
        !stopScheduledEventNotification &&
        dialTimer <= 0) // or if dial timer has expired
    ) {
      // set both to current org interval
      setDialTimerBaseValue(dataOrg?.fetchOrganization?.auto_call_interval);
      setDialTimer(dataOrg?.fetchOrganization?.auto_call_interval);
    }
  }, [callState, dataOrg, shouldAutoDialEnable]);

  useEffect(() => {
    if (callState === true) {
      resetTimers();
      refetchAutoDial();
    }
  }, [callState]);

  useEffect(() => {
    if (!dialTimer) return;
    const intervalId = setTimeout(() => {
      if (
        !pauseAutoDial &&
        dialTimer === 1 &&
        !!returnLeadToAutoDial &&
        !!returnLeadToAutoDial?.intent_object?.lead?.primary_phone_number &&
        !!returnLeadToAutoDial?.intent_object?.lead?.id
      ) {
        if (!getLocalStorage("userIsOnCallLocal", false)) {
          goToCall({
            lead_id: returnLeadToAutoDial?.intent_object?.lead?.id,
            phoneNumber: returnLeadToAutoDial?.intent_object?.lead?.primary_phone_number,
            intentId: !!returnLeadToAutoDial?.intent_object?.id ? returnLeadToAutoDial?.intent_object?.id : "",
            suggested_dial:
              !!dataRecommended.fetchRecommendedDial &&
              returnLeadToAutoDial?.intent_object?.lead?.id === dataRecommended.fetchRecommendedDial.lead_id,
          } as any);
        }
      }
      if (!pauseAutoDial) {
        dialTimer > 0 && setDialTimer(dialTimer - 1);
      } else {
        pauseTimer >= 0 && setPauseTimer(pauseTimer + 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [dialTimer, pauseAutoDial, pauseTimer, returnLeadToAutoDial, dataRecommended]);
  return dialTimer > 0 && !callState ? (
    <>
      <TimerCountdownDiv turnRed={false} paused={pauseAutoDial}>
        <TimerText paused={pauseAutoDial}>Your Next Dial Will Automatically Start In {formatTime(dialTimer)}</TimerText>
        <PauseDiv>
          <NewAppButton
            // small
            // borderless
            style={{ height: "22px", margin: "0px 14px" }}
            onClick={() => setPauseAutoDial(!pauseAutoDial)}
          >
            <img src={pauseAutoDial ? iconTimerPlay : iconTimerPause} />
          </NewAppButton>
          {pauseAutoDial && <PausedText paused={pauseAutoDial}>Paused for {formatTime(pauseTimer)}</PausedText>}
        </PauseDiv>
      </TimerCountdownDiv>
      <CallLoader style={{ width: `${((maxTimerNum - dialTimer) / maxTimerNum) * 100}%` }} />
    </>
  ) : (
    <></>
  );
};

interface TimerCountdownDivProps {
  turnRed?: boolean;
  paused: boolean;
}
interface TimerAllProps {
  turnRed?: boolean;
  paused: boolean;
  percentage: number;
}

const TimerCountdownDiv = styled.div<TimerCountdownDivProps>`
  position: fixed;
  /* left: calc(50% - 250px);
  right: calc(50% - 250px); */
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100vw;
  /* height: 32px;
  max-height: 32px;
  min-height: 32px; */
  height: 48px;
  max-height: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
  background: ${(props) => (props.paused ? theme.TERTIARY500 : props.turnRed ? theme.ATTENTION700 : theme.PRIMARY500)};
  transition: background 300ms ease-in-out;
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
`;

const CallLoader = styled.div`
  top: 46px;
  position: fixed;
  content: "";
  height: 2px;
  max-height: 2px;
  min-height: 2px;
  left: 0px;
  z-index: 9;
  background-color: ${theme.PRIMARY800};
  transition: all 1000ms linear;
`;

const TimerText = styled(AppText)<TimerCountdownDivProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  /* text-transform: uppercase; */
  text-align: center;
  /* margin: 6px auto; */
  color: ${(props) => (props.paused ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  transition: color 300ms ease-in-out;
`;

const PauseDiv = styled.div`
  position: relative;
  display: flex;
`;

const PausedText = styled(AppText)<TimerCountdownDivProps>`
  position: absolute;
  right: -170px;
  top: 3px;
  width: 170px;
  color: ${(props) => (props.paused ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  transition: color 300ms ease-in-out;
`;

export { AutoDialTimerComponent };
