import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";
import { useMutation, gql, ApolloError } from "@apollo/client";
import { loggedInUser } from "../apollo/cache";
import { getLocalStorage } from "../utils/misc";
import { USER_STATUS } from "../utils/variables";

interface UserStatusContextState {
  updateUserToIdle: ({ do_not_update_if_on_call }: { do_not_update_if_on_call?: boolean }) => void;
  updateUserToCall: () => void;
  updateUserToOffline: ({
    do_not_update_if_on_call,
    checkTabs,
  }: {
    do_not_update_if_on_call?: boolean;
    checkTabs?: boolean;
  }) => void;
  loadingStatusUpdate: boolean;
  errorStatusUpdate: ApolloError | undefined;
  userUIStatus: USER_STATUS;
  setUserUIStatus: Dispatch<SetStateAction<USER_STATUS>>;
}

const UPDATE_USER_STATUS_V2 = gql`
  mutation updateUserStatus($status: STATUS!, $do_not_update_if_on_call: Boolean) {
    updateUserStatus(status: $status, do_not_update_if_on_call: $do_not_update_if_on_call) {
      id
      email
      status
    }
  }
`;

export const UserStatusContext = createContext<UserStatusContextState>({} as UserStatusContextState);

export const UserStatusProvider: FunctionComponent = ({ children }) => {
  // can not be in LiveUserStatusContext as the ably listener in that was causing issues throughout the app.

  // we moved that context to around the live call component as a fix

  // user status updates

  // status boolean that controls UI indicator RepStatusMenuIcon
  const [userUIStatus, setUserUIStatus] = React.useState<USER_STATUS>(
    getLocalStorage("userStatusLocal", "IDLE") as USER_STATUS,
  );

  const updateAdminUserStatus = false;

  const userRole = loggedInUser()?.role;

  const isAEorSDR = userRole === "AE" || userRole === "SDR";

  const [updateUserStatus, { loading: loadStatus, error: errorStatus }] = useMutation(UPDATE_USER_STATUS_V2, {
    onCompleted({ updateUserStatus }) {
      if (!updateUserStatus) {
        return;
      }
    },
    onError({ message }) {
      console.log(message);
    },
  });

  // helper functions to be used troughout the app

  function updateUserToIdle({ do_not_update_if_on_call = true }: { do_not_update_if_on_call?: boolean }) {
    if (updateAdminUserStatus || isAEorSDR) {
      updateUserStatus({
        variables: {
          status: "IDLE",
          do_not_update_if_on_call,
        },
      });
    }
  }

  function updateUserToCall() {
    if (isAEorSDR) {
      updateUserStatus({
        variables: {
          status: "CALL",
        },
      });
    }
  }

  function updateUserToOffline({
    do_not_update_if_on_call = true,
    checkTabs = false,
  }: {
    do_not_update_if_on_call?: boolean;
    checkTabs?: boolean;
  }) {
    if (updateAdminUserStatus || isAEorSDR) {
      const savedDevices = getLocalStorage("saved_devices", []);

      if (!!checkTabs && !!savedDevices.length) {
        return;
      }

      updateUserStatus({
        variables: {
          status: "OFFLINE",
          do_not_update_if_on_call,
        },
      });
    }
  }

  const memoizedValue = useMemo(
    () => ({
      updateUserToIdle,
      updateUserToCall,
      updateUserToOffline,
      loadingStatusUpdate: loadStatus,
      errorStatusUpdate: errorStatus,
      userUIStatus,
      setUserUIStatus,
    }),
    [updateUserToIdle, updateUserToCall, updateUserToOffline, loadStatus, errorStatus, userUIStatus, setUserUIStatus],
  );

  return <UserStatusContext.Provider value={memoizedValue}>{children}</UserStatusContext.Provider>;
};
