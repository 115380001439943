import React from "react";
import styled from "styled-components";
import { useField } from "formik";
import { AppText } from ".";
import { theme } from "../../utils/theme";

interface CheckboxFieldProps {
  name?: string;
  title: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
  checked: boolean;
  value?: any;
  id?: string;
  onClick?: any;
  small?: boolean;
  left?: boolean;
  titleTextLocation?: "top" | "right";
  width?: string;
  titleFontWeight?: number;
  titleFontSize?: number;
  noMarginBottom?: boolean;
  textMarginLeft?: number;
}

const NewAppCircleCheckBox = ({
  small = false,
  label,
  style,
  title,
  checked,
  value,
  onClick,
  width,
  titleFontSize,
  titleFontWeight,
  noMarginBottom = false,
  titleTextLocation = "top",
  disabled = false,
  textMarginLeft,
  ...props
}: CheckboxFieldProps) => {
  return (
    <div style={{ borderRadius: "10px" }}>
      <CheckboxContainer style={style} checked={checked} small={small} width={width}>
        {!!title && titleTextLocation === "top" && (
          <label htmlFor={value}>
            <TitleText textMarginLeft={textMarginLeft} disabled={disabled}>
              {title}
            </TitleText>
          </label>
        )}
        <input type="radio" name={value} checked={checked} onClick={onClick} disabled={disabled} />
        <span className="checkmark" />
        {!!title && titleTextLocation === "right" && (
          <label htmlFor={value}>
            <TitleText
              textMarginLeft={textMarginLeft}
              disabled={disabled}
              fontWeight={titleFontWeight ? titleFontWeight : 500}
              fontSize={titleFontSize ? titleFontSize : 12}
            >
              {title}
            </TitleText>
          </label>
        )}
      </CheckboxContainer>
    </div>
  );
};

interface CheckedProp {
  checked: boolean;
  small: boolean;
  left?: boolean;
  width?: string;
  noMarginBottom?: boolean;
}

const CheckboxContainer = styled.label<CheckedProp>`
  display: flex;
  position: relative;
  margin-bottom: ${(props) => (props.noMarginBottom ? "0px" : "23px")};
  margin-left: 5px;
  padding-left: ${(props) => (props.left ? "30px" : "0px")};
  padding-right: ${(props) => (props.left ? "20px" : "0px")};
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 500; */
  line-height: 21px;
  width: ${(props) => (props.left ? "auto" : props?.width ? props.width : "128px")};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    /* position: absolute; */
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  // outside part
  .checkmark {
    /* position: absolute; */
    /* top: 0; */
    left: ${(props) => (props.left ? "0px" : "57px")};
    width: 16px;
    height: 16px;
    display: flex;
    border-radius: 50%;
    border: solid 1px ${theme.PRIMARY500};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "●";
    font-family: ${theme.PRIMARY_FONT};
    /* position: absolute; */
    /* display: block; */
    margin: 0px;
    padding: 0px;

    line-height: 23px;
    width: 10px;
    height: 10px;
    margin: auto auto;
    color: ${(props) => (props.checked ? theme.PRIMARY500 : "transparent")};

    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    color: ${theme.PRIMARY500};
  }
`;

interface TitleProp {
  disabled?: boolean | undefined;
  // fontSize?: string | undefined;
  // fontWeight?: string | undefined;
  // lineHeight?: string | undefined;
  textMarginLeft?: number;
}

const TitleText = styled(AppText)<TitleProp>`
  margin-left: ${(props) => (props.textMarginLeft ? `${props.textMarginLeft}px` : "16px")};
  /* line-height: 24px; */
`;

export { NewAppCircleCheckBox };
