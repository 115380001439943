import * as React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { useState } from "react";

export interface AppInputProps {
  currency?: boolean;
  percentage?: boolean;
  minutes?: boolean;
  context?: string | false;
  width?: number;
  fontSize?: number;
  textAlign?: string;
  /**
   * When there is no title, leave out a top margin
   */
  noTopMargin?: boolean;
  /**
   * If true, will not display an error message even if there is an error message
   */
  displayNoContext?: boolean;
  disabled?: boolean;
  primary?: boolean;
  variant?: "primary" | "error" | "success" | "warning";
}

export const StyledAppInput = styled.input<AppInputProps>`
  font-family: ${theme.PRIMARY_FONT};
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: 40px;
  padding-left: ${(props) => (props.currency ? 25 : 15)}px;
  padding-right: 15px;
  border-radius: 4px;

  border: 1px solid ${theme.NEUTRAL200};
  margin-top: ${(props) => (!props.title ? (props.noTopMargin ? 0 : 19) : 0)}px;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 1.5;
  /* font-family: ${theme.PRIMARY_FONT}; */
  box-sizing: border-box;
  color: ${theme.BLACK_COLOR};
  break-inside: avoid;

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    opacity: 0.4;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  }
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
    /* Not in original design */
    /* box-shadow: 4px 4px 4px #333333; */
    ${(props) => {
      if (!props.disabled) {
        switch (props.variant) {
          case "primary":
            return `
        border: 1px double ${theme.PRIMARY500};
        border-width: 4px;
      `;
          case "error":
            return `
        border: 1px double ${theme.ATTENTION700};
        border-width: 4px;
      `;
          case "success":
            return `
        border: 1px double ${theme.SUCCESS500};
        border-width: 4px;
      `;
          case "warning":
            return `
        border: 1px double ${theme.TERTIARY500};
        border-width: 4px;
      `;
          default:
            return `
        border: 1px double ${theme.PRIMARY500};
        border-width: 4px;
    `;
        }
      }
    }};
  }
  background-color: ${(props) => (props.disabled ? theme.NEUTRAL100 : theme.WHITE_COLOR)};
  :hover:not(:focus) {
    border: 1px solid ${theme.NEUTRAL300};
  }
`;

StyledAppInput.defaultProps = {
  noTopMargin: false,
  primary: false,
};

export const StyledFileInput = styled.input<AppInputProps>`
  display: none;
`;

export const FileLabel = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 14px 12px;
  border-radius: 4px;
  background: ${theme.BLACK_COLOR};
  color: #ffffff;
  cursor: pointer;
`;

const AppInputComponent = ({
  primary = false,
  currency = false,
  percentage = false,
  minutes = false,
  displayNoContext = false,
  title,
  context,
  variant,
  ...props
}: AppInputProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  return (
    <Div width={props.width}>
      {!!title && (
        <InputTitle primary={primary} textAlign={props.textAlign}>
          {title}
        </InputTitle>
      )}
      <Div style={{ flexDirection: "row", alignItems: "center" }}>
        {currency && (
          <LeftText context={!!context} focused={isFocused}>
            $
          </LeftText>
        )}

        <StyledAppInput
          {...(props as any)}
          onFocus={toggleFocus}
          onBlur={(e) => {
            toggleFocus();
            !!props.onBlur && props.onBlur(e);
          }}
          currency={currency}
          percentage={percentage}
          minutes={minutes}
          title={title}
          context={!displayNoContext && !!context}
          primary={primary}
          variant={variant}
        />
        {percentage && (
          <PercentText context={!!context} focused={isFocused} minutes={minutes}>
            %
          </PercentText>
        )}
        {minutes && (
          <RightText context={!!context} focused={isFocused} minutes={minutes}>
            Minutes
          </RightText>
        )}
      </Div>
      {displayNoContext ? undefined : !!context ? (
        <InputContextText variant={variant}>{context}</InputContextText>
      ) : (
        <InputContextText variant={variant}>&nbsp;</InputContextText>
      )}
    </Div>
  );
};

interface DivProps {
  width?: number;
}

interface VariantProps {
  variant?: string;
}

const Div = styled.div<DivProps>`
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  display: flex;
  flex-direction: column;
`;

interface TextFocus {
  focused?: boolean;
  context?: boolean;
  minutes?: boolean;
}

const LeftText = styled.span<TextFocus>`
  font-size: 13px;
  line-height: 1;
  font-family: ${theme.PRIMARY_FONT};
  /* color: ${(props) =>
    props.focused ? theme.PRIMARY500 : props.context ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)"}; */
  color: ${theme.BLACK_COLOR};
  margin: 0;
`;

const RightText = styled.span<TextFocus>`
  font-size: 13px;
  line-height: 1;
  font-family: ${theme.PRIMARY_FONT};
  /* color: ${(props) =>
    props.focused ? theme.PRIMARY500 : props.context ? theme.ATTENTION700 : "rgba(26, 26, 26, 0.2)"}; */
  color: ${(props) => (props.minutes ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  margin: 0;
`;

const PercentText = styled(RightText)`
  font-size: 12px;
`;

RightText.defaultProps = {
  focused: false,
  context: false,
  minutes: false,
};

LeftText.defaultProps = {
  focused: false,
  context: false,
};

const InputTitle = styled.p<AppInputProps>`
  font-family: ${theme.PRIMARY_FONT};
  /* font-size: ${(props) => (props.primary ? 17.5 : 12)}px; */
  font-size: 12px;
  color: ${(props) => (props.primary ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  /* color: ${theme.BLACK_COLOR}; */
  margin: 0;
  /* margin-bottom: ${(props) => (props.primary ? 15 : 3)}px; */
  margin-bottom: 3px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;

const InputContextText = styled.p<VariantProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;

  margin: 0;
  margin-top: 4px;
  text-align: left;
  /* font-weight: bold; */
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-shadow: 1px 1px 2px ${theme.WHITE_COLOR};

  ${(props) => {
    switch (props.variant) {
      case "primary":
        return `
        color: ${theme.NEUTRAL400}
      `;
      case "error":
        return `
        color: ${theme.ATTENTION700};
      `;
      case "success":
        return `
        color: ${theme.SUCCESS500};
      `;
      default:
        return `
        color: ${theme.NEUTRAL400}
    `;
    }
  }};
`;

//const AppInput = React.memo(AppInputComponent, (p, n) => p === n);
const AppInput = AppInputComponent;

export { AppInput };
