import { any } from "cypress/types/bluebird";
import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";

interface SelfSourceLeadContextState {
  /**
   * The ID of the lead that was self-sourced using the add lead form.
   * This is used to determine whether or not to show the self-sourced lead or the add form.
   *
   */
  selfSourceLeadId: string;
  setSelfSourceLeadId: Dispatch<SetStateAction<string>>;
  /**
   * The ID of the intent that was returned when the lead was self-sourced.
   *
   **/
  selfSourceIntentId: string;
  setSelfSourceIntentId: Dispatch<SetStateAction<any>>;
  /**
   * Resets the self-sourced lead ID and intent ID to blank.
   **/
  resetSelfSourcedLead: () => void;
}

export const SelfSourceLeadContext = createContext<SelfSourceLeadContextState>({} as SelfSourceLeadContextState);

export const SelfSourceLeadProvider: FunctionComponent = ({ children }) => {
  const [selfSourceLeadId, setSelfSourceLeadId] = useState("");
  const [selfSourceIntentId, setSelfSourceIntentId] = useState("");

  const resetSelfSourcedLead = () => {
    setSelfSourceLeadId("");
    setSelfSourceIntentId("");
  };

  const memoizedValue = useMemo(
    () => ({
      selfSourceLeadId,
      setSelfSourceLeadId,
      selfSourceIntentId,
      setSelfSourceIntentId,
      resetSelfSourcedLead,
    }),
    [selfSourceLeadId, setSelfSourceLeadId, selfSourceIntentId, setSelfSourceIntentId, resetSelfSourcedLead],
  );

  return <SelfSourceLeadContext.Provider value={memoizedValue}>{children}</SelfSourceLeadContext.Provider>;
};
