import styled, { keyframes } from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { AppInput, AppText, Loading, AppErrorText, AppSelect, ReactDatesWrapper } from "../../UI";
import { theme } from "../../../utils/theme";
import { ProgressGraph } from "..";
import { useQuery, gql } from "@apollo/client";
import { formatCellData, formatUSD, transformColumnLabel } from "../../../utils/format";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { GridFilterContext } from "../../../context/";
import Switch from "react-switch";
import { CallReportListV1 } from "../CallReportListV1";
import { isProduction, SIMPLE_GRID_PERSIST_KEY } from "../../../utils/variables";
import { EventsListTable } from "./EventsListTable";
import { PipelineListTable } from "./PipelineListTable";
import { ManageGridColumnsModal, ManageTableColumnsModal } from "../../modal";
import { MixpanelActions } from "./../../../services/mixpanel";
import { DateRangePicker } from "react-dates";
import { OutsideEvent } from "../../Dumb";
import { ExportCallListModal } from "../../modal/ExportCallListModal";

const dateRangeOptions = [
  { start: moment().startOf("day").format(), end: moment().endOf("day").format(), label: "Today", value: "Today" },
  {
    start: moment().subtract(24, "hours").startOf("day").format(),
    end: moment().subtract(24, "hours").endOf("day").format(),
    label: "Yesterday",
    value: "Yesterday",
  },
  {
    start: moment().startOf("week").format(),
    end: moment().endOf("week").format(),
    label: "This Week",
    value: "ThisWeek",
  },
  {
    start: moment().subtract(1, "week").startOf("week").format(),
    end: moment().subtract(1, "week").endOf("week").format(),
    label: "Last Week",
    value: "LastWeek",
  },
  {
    start: moment().startOf("month").format(),
    end: moment().endOf("day").format(),
    label: "Month to Date",
    value: "MonthToDate",
  },
  {
    start: moment().subtract(1, "months").startOf("month").format(),
    end: moment().subtract(1, "months").endOf("month").format(),
    label: "Last Month",
    value: "LastMonth",
  },
  {
    start: moment().startOf("year").format(),
    end: moment().endOf("day").format(),
    label: "Year to Date",
    value: "YearToDate",
  },
  {
    start: moment().subtract(1, "years").startOf("year").format(),
    end: moment().subtract(1, "years").endOf("year").format(),
    label: "Last Year",
    value: "LastYear",
  },
  {
    start: moment().subtract(1, "months").startOf("day").format(),
    end: moment().endOf("day").format(),
    label: "Custom Range",
    value: "CustomRange",
  },
];

export const FETCH_PERFORMANCE_CHART = gql`
  query fetchPerformanceChart(
    # $product_ids_filter: [String!]
    # $channel_filter: CHANNEL
    $lowerbound_date: Date
    $upperbound_date: Date
    $coaching_view: Boolean
    $show_teams: Boolean
    $team_id_stack_rank: String
    $user_id_stack_rank: String
    $team_id: String
    $new_version: Boolean
    $dashboard_filter: DashboardFilter
    $dashboard_type: DashboardType
  ) {
    fetchPerformanceChart(
      # product_ids_filter: $product_ids_filter
      # channel_filter: $channel_filter
      lowerbound_date: $lowerbound_date
      upperbound_date: $upperbound_date
      coaching_view: $coaching_view
      show_teams: $show_teams
      team_id_stack_rank: $team_id_stack_rank
      user_id_stack_rank: $user_id_stack_rank
      team_id: $team_id
      new_version: $new_version
      dashboard_filter: $dashboard_filter
      dashboard_type: $dashboard_type
    ) {
      id
      computed_id
      row_label
      metric
      metric_label
      metric_type
      metric_tooltip
      value
      position
      rank
      lowerbound_date
      upperbound_date
      team_id
      user_id
      organization_id
    }
  }
`;

export const STACK_RANK_LIST = gql`
  query stackRankList(
    $computed_id: String!
    $metric: METRIC!
    $position: POSITION!
    $lowerbound_date: DateTime
    $upperbound_date: DateTime
    $dashboard_filter: DashboardFilter # $product_ids_filter: [String!] # $channel_filter: CHANNEL
    $only_team: Boolean
  ) {
    stackRankList(
      computed_id: $computed_id
      metric: $metric
      position: $position
      lowerbound_date: $lowerbound_date
      upperbound_date: $upperbound_date
      only_team: $only_team
      dashboard_filter: $dashboard_filter # product_ids_filter: $product_ids_filter # channel_filter: $channel_filter
    ) {
      id
      metric_label
      row_label
      list_rank
      list_value
      selected
      metric_type
      best_all_time_value
    }
  }
`;

export const FETCH_30_DAY_GRAPH_DATA = gql`
  query fetch30DayGraphData(
    $computed_id: String!
    $metric: METRIC!
    $position: POSITION!
    # $product_ids_filter: [String!]
    # $channel_filter: CHANNEL
    $upperbound_date: DateTime
    $lowerbound_date: DateTime
    $dashboard_filter: DashboardFilter
  ) {
    fetch30DayGraphData(
      computed_id: $computed_id
      metric: $metric
      position: $position
      # product_ids_filter: $product_ids_filter
      # channel_filter: $channel_filter
      upperbound_date: $upperbound_date
      lowerbound_date: $lowerbound_date
      dashboard_filter: $dashboard_filter
    ) {
      company_average
      best_individual
      stat_items {
        id
        day
        formatted_day
        metric
        metric_type
        value
        row_label
      }
    }
  }
`;

export const FETCH_SALES_IMPROVEMENTS = gql`
  query fetchSalesImprovements($level: POSITION, $team_id: String) {
    fetchSalesImprovements(level: $level, team_id: $team_id) {
      metric
      metric_label
      metric_type
      metric_tooltip
      value
      sale_improvement
      wrd_result
      cr_result
      shr_result
      rs_result
      sr_result
    }
  }
`;

interface IPerformanceChartData {
  id: string;
  computed_id?: string;
  row_label: string;
  metric: string;
  metric_label: string;
  metric_type: string;
  metric_tooltip: string;
  value: number;
  position?: string;
  rank?: number;
  lowerbound_date?: string;
  upperbound_date?: string;
}

const gridDefaultState = {
  sortTableAscending: true,
  selectedCell: "",
  selectedStack: ["", ""],
  filterUser: "",
  userID: "",
  teamID: "",
  orgID: "",
  coachingReportView: false,
  metric: "",
  currentItemData: {} as any,
};

const SimpleGrid: React.FC = () => {
  const [sortTableAscending, setSortTableAscending] = useState(true);
  const [selectedCell, setSelectedCell] = useState(gridDefaultState.selectedCell);
  const [selectedStack, setSelectedStack] = useState(gridDefaultState.selectedStack);

  const [currentItemData, setCurrentItemData] = useState({} as any);
  const [userID, setUserID] = useState(gridDefaultState.userID);
  const [teamID, setTeamID] = useState(gridDefaultState.teamID);
  const [orgID, setOrgID] = useState(gridDefaultState.orgID);
  const [metric, setMetric] = useState(gridDefaultState.metric);
  const [coachingReportView, setCoachingReportView] = useState(gridDefaultState.coachingReportView);
  const [initialRenderBool, setInitialRenderBool] = useState(true);

  // For react-dates
  const [focusedInput, setFocusedInput] = useState(null as any);

  // set persist state
  useEffect(() => {
    const persistedStateRaw = localStorage.getItem(SIMPLE_GRID_PERSIST_KEY);
    if (!persistedStateRaw) {
      return;
    }
    const persistedState = JSON.parse(persistedStateRaw);
    if (!persistedState) {
      return;
    }
    setSortTableAscending(persistedState.sortTableAscending || gridDefaultState.sortTableAscending);
    setSelectedCell(persistedState.selectedCell ?? gridDefaultState.selectedCell);
    setSelectedStack(persistedState.selectedStack ?? gridDefaultState.selectedStack);
    setUserID(persistedState.userID ?? gridDefaultState.userID);
    setTeamID(persistedState.teamID ?? gridDefaultState.teamID);
    setOrgID(persistedState.orgID ?? gridDefaultState.orgID);
    setCoachingReportView(persistedState.coachingReportView ?? gridDefaultState.coachingReportView);
    setMetric(persistedState.metric ?? gridDefaultState.metric);
    setCurrentItemData(persistedState.currentItemData ?? gridDefaultState.currentItemData);
  }, []);

  // persist settings when a change occurs
  useEffect(() => {
    const table_properties = {
      sortTableAscending,
      selectedCell,
      selectedStack,
      userID,
      teamID,
      orgID,
      coachingReportView,
      metric,
      currentItemData,
    };
    localStorage.setItem(SIMPLE_GRID_PERSIST_KEY, JSON.stringify(table_properties));
  }, [
    sortTableAscending,
    selectedCell,
    selectedStack,
    userID,
    teamID,
    orgID,
    coachingReportView,
    metric,
    currentItemData,
  ]);

  const {
    dateStart,
    dateEnd,
    tableView,
    setTableView,
    dateLabel,
    setDateStart,
    setDateEnd,
    setDateLabel,
    filterSidebarExpanded,
    gridFilter,
    gridModal,
    setGridModal,
    tableModal,
    setTableModal,
    callsExportModal,
    setCallsExportModal,
  } = useContext(GridFilterContext);

  const { data: dataTable, loading: loadingTable, error: errorTable, refetch: refetchTable } = useQuery(
    FETCH_PERFORMANCE_CHART,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      variables: {
        coaching_view: tableView === "Coaching",
        lowerbound_date:
          dateStart === "Invalid Date" ? undefined : !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
        upperbound_date: dateEnd === "Invalid Date" ? undefined : !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
        dashboard_type: tableView,
        dashboard_filter: {
          Channels: gridFilter.channels,
          LeadSources: gridFilter.lead_sources,
          LeadCreationSources: gridFilter.lead_creation_sources,
          primary_industries: gridFilter.industries,
          sub_industries: gridFilter.sub_industries,
        },
      },
    },
  );

  const { data: dataStack, loading: loadingStack, error: errorStack, refetch: refetchStack } = useQuery(
    STACK_RANK_LIST,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      skip: !currentItemData.computed_id,
      onCompleted: () => {
        console.log("from simple grid");
      },
      onError: () => {
        console.log("from simple grid");
      },
      variables: {
        computed_id: currentItemData.computed_id,
        metric: currentItemData.metric,
        position: currentItemData.position,
        lowerbound_date: !!currentItemData.lowerbound_date
          ? moment(currentItemData.lowerbound_date).format()
          : undefined,
        upperbound_date: !!currentItemData.upperbound_date
          ? moment(currentItemData.upperbound_date).format()
          : moment().format(),
        dashboard_filter: {
          Channels: gridFilter.channels,
          LeadSources: gridFilter.lead_sources,
          LeadCreationSources: gridFilter.lead_creation_sources,
          primary_industries: gridFilter.industries,
          sub_industries: gridFilter.sub_industries,
        },
      },
    },
  );

  const { data: dataGraph, loading: loadingGraph, error: errorGraph, refetch: refetchGraph } = useQuery(
    FETCH_30_DAY_GRAPH_DATA,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      skip: !currentItemData.computed_id,
      variables: {
        computed_id: currentItemData.computed_id,
        metric: currentItemData.metric,
        position: currentItemData.position,
        lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
        upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
        dashboard_filter: {
          Channels: gridFilter.channels,
          LeadSources: gridFilter.lead_sources,
          LeadCreationSources: gridFilter.lead_creation_sources,
          primary_industries: gridFilter.industries,
          sub_industries: gridFilter.sub_industries,
        },
      },
    },
  );

  const {
    data: dataImprovement,
    loading: loadingImprovement,
    error: errorImprovement,
    refetch: refetchImprovement,
  } = useQuery(FETCH_SALES_IMPROVEMENTS, {
    fetchPolicy: "network-only",
    variables: {
      team_id: teamID,
      level: teamID ? "Team" : undefined,
    },
  });
  useEffect(() => {
    console.log("dataImprovement, ", dataImprovement);
  }, [dataImprovement]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataTable]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataTable]);

  useEffect(() => {
    if (!!dataTable && !!dataTable.fetchPerformanceChart && !initialRenderBool) {
      !!selectedStack[0] && setSelectedStack([transformColumnLabel(selectedStack[0]), selectedStack[1]]);
      !!selectedCell[0] && setSelectedCell(transformColumnLabel(selectedCell));

      const newMetric = transformColumnLabel(!!selectedCell ? selectedCell : selectedStack[0]);

      const element = dataTable.fetchPerformanceChart
        ?.map((item: any) => item)
        ?.filter(
          (row: IPerformanceChartData) => row.metric_label === newMetric && row.row_label === currentItemData.row_label,
        )[0];

      if (
        !!element &&
        !!element.id &&
        !element.rank &&
        !!element.row_label &&
        !!element.computed_id &&
        !!element.metric &&
        !!element.position
      ) {
        if (tableView === "Reporting") {
          setSelectedStack(["", ""]);
          setSelectedCell(element.metric_label);
          refetchGraph({
            computed_id: element.computed_id,
            metric: element.metric,
            position: element.position,
            lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
            upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
            dashboard_filter: {
              Channels: gridFilter.channels,
              LeadSources: gridFilter.lead_sources,
              LeadCreationSources: gridFilter.lead_creation_sources,
              primary_industries: gridFilter.industries,
              sub_industries: gridFilter.sub_industries,
            },
          });
          setCoachingReportView(false);
          setMetric("");
          setUserID("");
          setTeamID("");
          setOrgID("");
        } else if (tableView === "Coaching") {
          setSelectedStack(["", ""]);
          setSelectedCell(element.metric_label);
          setMetric(element.metric);
          setUserID(element.user_id);
          setTeamID(element.team_id);
          setOrgID(element.organization_id);
          setCoachingReportView(true);
        } else if (tableView === "Event") {
          setSelectedStack(["", ""]);
          setSelectedCell(element.metric_label);
          setMetric(element.metric);
          setUserID(element.user_id);
          setTeamID(element.team_id);
          setOrgID(element.organization_id);
          setCoachingReportView(true);
        } else {
          setCoachingReportView(false);
          setSelectedStack([element.row_label ?? "", element.metric_label ?? ""]);
          setSelectedCell("");
          setMetric("");
          setUserID("");
          setTeamID("");
          setOrgID("");
          console.log("Refetching stack table: ", element.computed_id, element.metric, element.position);
          refetchStack({
            computed_id: element.computed_id,
            metric: element.metric,
            position: element.position,
            lowerbound_date: !!element.lowerbound_date ? moment(element.lowerbound_date).format() : undefined,
            upperbound_date: !!element.upperbound_date ? moment(element.upperbound_date).format() : moment().format(),
            dashboard_filter: {
              Channels: gridFilter.channels,
              LeadSources: gridFilter.lead_sources,
              LeadCreationSources: gridFilter.lead_creation_sources,
              primary_industries: gridFilter.industries,
              sub_industries: gridFilter.sub_industries,
            },
          });
        }
      }
    }
  }, [tableView && dataTable && dataTable.fetchPerformanceChart]);

  if (!!!dataTable) return <Loading />;
  if (errorTable) return <AppErrorText>Error loading table data!</AppErrorText>;

  const tableHeaders = dataTable.fetchPerformanceChart
    ?.map((item: IPerformanceChartData) => item.metric_label)
    .filter((v: string, i: number, a: any) => a.indexOf(v) === i);

  const returnTooltipData = (item: string) => {
    const element = dataTable.fetchPerformanceChart.filter((row: IPerformanceChartData) => row.metric_label === item);
    const tooltip = element[0].metric_tooltip || element[0].metric_label;
    return !!tooltip ? tooltip : "No tooltip";
  };

  const rowLabels = dataTable.fetchPerformanceChart
    ?.map((item: IPerformanceChartData) => item.row_label)
    .filter((v: string, i: number, a: any) => a.indexOf(v) === i);

  const formatGraphData = (data: any) => {
    console.log("GRAPH DATA: ", data);
    return data
      ?.map((item: any) => item)
      ?.sort((a: any, b: any) => a.day - b.day)
      ?.map((item: any) =>
        item.metric_type === "Percentage"
          ? {
              x: item.formatted_day,
              y: Number(item.value) * 100,
              label: formatCellData(item?.value, item?.metric_type),
            }
          : {
              x: item.formatted_day,
              y: Number(item.value),
              label: formatCellData(item?.value, item?.metric_type),
            },
      );
  };

  const returnWhichOptionIsSelected = (value: string) => {
    const selected = dateRangeOptions.slice().filter((item: any) => item.value === value)[0];
    return selected ?? dateRangeOptions[0];
  };

  return (
    <LargeDiv>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        id="tablecolumns"
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      {gridModal && (
        <DarkDiv>
          <SlideInDiv>
            <ManageGridColumnsModal />
          </SlideInDiv>
        </DarkDiv>
      )}
      {tableModal && (
        <ManageTableColumnsModal
          dataAllColumns={[
            { metric_label: "Time of Call", metric: "CallTime", fixed: false },
            { metric_label: "Contact Name", metric: "LeadName", fixed: false },
            { metric_label: "Business Name", metric: "BusinessName", fixed: false },
            { metric_label: "Sales Rep", metric: "RepName", fixed: false },
            { metric_label: "Call Result", metric: "CallResult", fixed: false },
            { metric_label: "Set", metric: "Set", fixed: true },
            { metric_label: "Held", metric: "Held", fixed: true },
            { metric_label: "Closed", metric: "Close", fixed: true },
            { metric_label: "Time of Event", metric: "EventTime", fixed: false },
            { metric_label: "Close User", metric: "ClosedByUser", fixed: false },
            { metric_label: "Set User", metric: "SetByUser", fixed: false },
          ]}
        />
      )}
      {/* {callsExportModal && (
        <ExportCallListModal
          dashboardVars={{
            coaching_view: tableView === "Coaching",
            lowerbound_date:
              dateStart === "Invalid Date" ? undefined : !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
            upperbound_date:
              dateEnd === "Invalid Date" ? undefined : !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
            dashboard_type: tableView,
            dashboard_filter: {
              Channels: gridFilter.channels,
              LeadSources: gridFilter.lead_sources,
              LeadCreationSources: gridFilter.lead_creation_sources,
              primary_industries: gridFilter.industries,
              sub_industries: gridFilter.sub_industries,
            },
          }}
        />
      )} */}
      <PaddingDiv width={tableHeaders.length} expanded={filterSidebarExpanded}>
        <TimePickerDiv>
          {/* <img src={iconCalendarNormal} alt="" height="24px" /> */}
          <TimeSelectDiv>
            <TimeLabelText>Date Range</TimeLabelText>
            <DateAppSelect
              textAlign="left"
              options={dateRangeOptions?.map((item) => ({ label: item.label, value: item.value }))}
              value={dateLabel ?? "Today"}
              onChange={(e: any) => {
                const selected = returnWhichOptionIsSelected(e.target.value);
                setDateStart(selected.start);
                setDateEnd(selected.end);
                setDateLabel(selected.value);
              }}
            />
          </TimeSelectDiv>
          <TimeColumnDiv>
            <div style={{ display: "flex", width: "100%" }}>
              <TimeLabelText style={{ marginLeft: "15px" }}>Start Date</TimeLabelText>
              <TimeLabelText style={{ marginLeft: "95px" }}>End Date</TimeLabelText>
            </div>
            <OutsideEvent onOutsideClick={() => setFocusedInput(focusedInput)}>
              <ReactDatesWrapper>
                <DateRangePicker
                  minimumNights={0}
                  isOutsideRange={() => false}
                  startDate={moment(dateStart)}
                  startDateId="simple_grid_start_date"
                  endDate={moment(dateEnd)}
                  endDateId="simple_grid_end_date"
                  onDatesChange={({ startDate, endDate }) => {
                    if (startDate && moment(startDate).isValid()) {
                      setDateLabel("CustomRange");
                      setDateStart(moment(startDate).startOf("day").format());
                    }
                    if (endDate && moment(endDate).isValid()) {
                      setDateLabel("CustomRange");
                      setDateEnd(moment(endDate).endOf("day").format());
                    }
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                />
              </ReactDatesWrapper>
            </OutsideEvent>
          </TimeColumnDiv>
          {/* <TimeColumnDiv>
            <TimeLabelText>Start Date</TimeLabelText>
            <DateInput
              type="date"
              max={moment(dateEnd).format("YYYY-MM-DD")}
              value={moment(dateStart).format("YYYY-MM-DD")}
              onChange={(e: any) => {
                if (e?.target?.value && moment(e.target.value).isValid()) {
                  setDateLabel("Custom Range");
                  setDateStart(moment(e.target.value).format());
                }
              }}
            />
          </TimeColumnDiv> */}
          {/* <TiArrowSortedUp style={{ transform: "rotate(90deg" }} size={24} /> */}
          {/* <img src={iconCalendarNormal} alt="" height="24px" /> */}
          {/* <TimeColumnDiv>
            <TimeColumnDiv>
              <TimeLabelText>End Date</TimeLabelText>
              <DateInput
                type="date"
                min={moment(dateStart).format("YYYY-MM-DD")}
                value={moment(dateEnd).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  if (e?.target?.value && moment(e.target.value).isValid()) {
                    setDateLabel("Custom Range");
                    setDateEnd(moment(e.target.value).endOf("day").format());
                  }
                }}
              />
            </TimeColumnDiv>
          </TimeColumnDiv> */}
        </TimePickerDiv>
        <SpaceBetweenDiv width={tableHeaders.length}>
          <TimePickerDiv>
            <ToggleViewText
              selected={tableView === "Reporting"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Reporting");
                // await refetchTable();
                // ReactTooltip.rebuild();
              }}
            >
              Reporting
            </ToggleViewText>
            <ToggleViewText
              selected={tableView === "Coaching"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Coaching");
                // await refetchTable();
                // ReactTooltip.rebuild();
              }}
            >
              Coaching
            </ToggleViewText>
            {/* {!isProduction && (
              <ToggleViewText
                selected={tableView === "Activity"}
                onClick={async () => {
                  setInitialRenderBool(false);
                  setTableView("Activity");
                  await refetchTable();
                  ReactTooltip.rebuild();
                }}
              >
                Activity
              </ToggleViewText>
            )} */}
            <ToggleViewText
              selected={tableView === "Event"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Event");
                // await refetchTable();
                // ReactTooltip.rebuild();
              }}
            >
              Event
            </ToggleViewText>
            <ToggleViewText
              selected={tableView === "Pipeline"}
              onClick={async () => {
                setInitialRenderBool(false);
                setTableView("Pipeline");
                // await refetchTable();
                // ReactTooltip.rebuild();
              }}
            >
              Pipeline
            </ToggleViewText>
          </TimePickerDiv>
          {/* {["Coaching", "Reporting"].includes(tableView) ? (
            <ClickableText onClick={() => setGridModal(!gridModal)}>Manage Columns</ClickableText>
          ) : ( */}
          <div />
          {/* )} */}
        </SpaceBetweenDiv>
        <TableContainer>
          <GridTable>
            <thead>
              <GridTR makeDivider={tableView === "Reporting"}>
                <GridTH style={{ padding: "0px" }}>
                  <div>
                    <UnderlineText>KEY METRICS</UnderlineText>
                  </div>
                  <Horizontal style={{ marginTop: "50px" }}></Horizontal>
                  <div
                    style={{
                      maxHeight: "16px",
                      minHeight: "16px",
                      transform: "scale(0.9)",
                    }}
                  >
                    {loadingTable && <Loading />}
                  </div>
                </GridTH>
                {tableHeaders?.map((item: string) => (
                  <GridTH key={`TH${item}`}>
                    <THText>
                      <MarginSpan>{item}</MarginSpan>
                      <QuestionMarkMoreInfo
                        data-for="tablecolumns"
                        data-tip={returnTooltipData(item)}
                        style={{ transform: "rotate(90deg)" }}
                        nomargin
                      >
                        ?
                      </QuestionMarkMoreInfo>
                    </THText>
                  </GridTH>
                ))}
              </GridTR>
            </thead>
            <tbody>
              {rowLabels?.map((row: string) => (
                <GridTR key={row} makeDivider={tableView === "Reporting"}>
                  <GridTD style={{ cursor: "auto", background: "none" }}>
                    <RowTitle>{row}</RowTitle>
                  </GridTD>
                  {dataTable.fetchPerformanceChart?.map(
                    (item: any) =>
                      row === item.row_label && (
                        <GridTD
                          key={`TR${item.id}`}
                          selected={
                            item.rank
                              ? selectedStack[0] === item.row_label && selectedStack[1] === item.metric_label
                              : selectedCell === item.metric_label
                          }
                          onClick={async () => {
                            setInitialRenderBool(false);
                            setCurrentItemData(item);
                            console.log("clicked: ", item);
                            MixpanelActions.track("Performance Dashboard", {
                              type: "cell clicked",
                              View: `${tableView}`,
                              item,
                            });
                            if (!item.rank) {
                              if (tableView === "Reporting") {
                                setSelectedStack(["", ""]);
                                setSelectedCell(item.metric_label);
                                await refetchGraph({
                                  computed_id: item.computed_id,
                                  metric: item.metric,
                                  position: item.position,
                                  lowerbound_date: !!dateStart ? `${moment(dateStart).toDate()}` : undefined,
                                  upperbound_date: !!dateEnd ? `${moment(dateEnd).toDate()}` : undefined,
                                  dashboard_filter: {
                                    Channels: gridFilter.channels,
                                    LeadSources: gridFilter.lead_sources,
                                    LeadCreationSources: gridFilter.lead_creation_sources,
                                    primary_industries: gridFilter.industries,
                                    sub_industries: gridFilter.sub_industries,
                                  },
                                });
                                setCoachingReportView(false);
                              }
                              // ! Changed here
                              else {
                                setSelectedStack(["", ""]);
                                setSelectedCell(item.metric_label);
                                setMetric(item.metric);
                                setUserID(item.user_id);
                                setTeamID(item.team_id);
                                setOrgID(item.organization_id);
                                setCoachingReportView(true);
                              }
                            } else {
                              setCoachingReportView(false);
                              setSelectedStack([item.row_label, item.metric_label]);
                              setSelectedCell("");
                              console.log("Refetching stack table: ", item.computed_id, item.metric, item.position);
                              await refetchStack({
                                computed_id: item.computed_id,
                                metric: item.metric,
                                position: item.position,
                                lowerbound_date: !!item.lowerbound_date
                                  ? moment(item.lowerbound_date).format()
                                  : undefined,
                                upperbound_date: !!item.upperbound_date
                                  ? moment(item.upperbound_date).format()
                                  : moment().format(),
                                dashboard_filter: {
                                  Channels: gridFilter.channels,
                                  LeadSources: gridFilter.lead_sources,
                                  LeadCreationSources: gridFilter.lead_creation_sources,
                                  primary_industries: gridFilter.industries,
                                  sub_industries: gridFilter.sub_industries,
                                },
                              });
                            }
                          }}
                        >
                          {formatCellData(item?.value, item?.metric_type)}
                        </GridTD>
                      ),
                  )}
                </GridTR>
              ))}
            </tbody>
          </GridTable>
        </TableContainer>
        <br />
        {coachingReportView ? (
          <TableActionsDiv>
            {/* <ClickableText style={{ marginRight: "24px" }} onClick={() => setCallsExportModal(!callsExportModal)}>
                Export
              </ClickableText> */}
            <ClickableText onClick={() => setTableModal(!tableModal)}>Manage Columns</ClickableText>
          </TableActionsDiv>
        ) : (
          <TableActionsDiv />
        )}
        {coachingReportView && tableView === "Coaching" ? (
          <CallReportListV1
            key={`CallReportsRep${metric}`}
            userID={userID}
            teamID={teamID}
            orgID={orgID}
            gridFilter={true}
            metric={metric}
          />
        ) : coachingReportView && tableView === "Event" ? (
          <EventsListTable
            key={`EventsListRep${metric}`}
            userID={userID}
            teamID={teamID}
            orgID={orgID}
            gridFilter={true}
            metric={metric}
          />
        ) : coachingReportView && tableView === "Pipeline" ? (
          <PipelineListTable
            key={`PipelineListRep${metric}`}
            userID={userID}
            teamID={teamID}
            orgID={orgID}
            gridFilter={true}
            metric={metric}
          />
        ) : (
          !!selectedCell && (
            <ChartContainer>
              <div style={{ padding: "20px 0px 0px 20px" }}>
                <TitleText bold>{selectedCell}</TitleText>
                <TitleText>{rowLabels[0]}</TitleText>
              </div>
              {!!!dataGraph || loadingGraph ? (
                <Loading />
              ) : errorGraph ? (
                <AppErrorText></AppErrorText>
              ) : (
                dataGraph &&
                dataGraph.fetch30DayGraphData.stat_items && (
                  <ProgressGraph
                    data={formatGraphData(dataGraph.fetch30DayGraphData.stat_items)}
                    bestIndividual={dataGraph.fetch30DayGraphData.best_individual}
                    companyAverage={dataGraph.fetch30DayGraphData.company_average}
                    metricType={currentItemData.metric_type}
                  />
                )
              )}
            </ChartContainer>
          )
        )}
        {!!selectedStack[0] && (
          <StackTableContainer>
            <StackTable>
              <thead>
                <StackTR>
                  <StackTH
                    colSpan={2}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <TitleText bold>{selectedStack[1]}</TitleText>
                    <TitleText>{rowLabels[0]}</TitleText>
                  </StackTH>
                  <StackTH
                    colSpan={2}
                    style={{
                      textAlign: "right",
                      paddingRight: "16px",
                    }}
                  >
                    <TitleText bold>{selectedStack[0]}</TitleText>
                  </StackTH>
                </StackTR>
                <StackTR>
                  <StackTH style={{ width: "53px" }}>Rank</StackTH>
                  <StackTH>Team Name</StackTH>
                  {/* <StackTH style={{ width: "231px" }}>Modified Metric</StackTH> */}
                  <StackTH
                    style={{ width: "231px", cursor: "pointer" }}
                    onClick={() => setSortTableAscending(!sortTableAscending)}
                  >
                    <TableHeaderContent>
                      {sortTableAscending ? (
                        <AiFillCaretDown style={{ marginRight: "6px" }} size={14} color={theme.BLACK_COLOR} />
                      ) : (
                        <AiFillCaretUp style={{ marginRight: "6px" }} size={14} color={theme.BLACK_COLOR} />
                      )}
                      Amount
                      {/* Standard Metric */}
                      {/* <QuestionMarkMoreInfo>?</QuestionMarkMoreInfo> */}
                    </TableHeaderContent>
                  </StackTH>
                </StackTR>
              </thead>
              <tbody>
                {!!!dataStack || loadingStack ? (
                  <Loading />
                ) : errorStack ? (
                  <AppErrorText>Error loading stack rank data!</AppErrorText>
                ) : (
                  dataStack &&
                  dataStack.stackRankList &&
                  dataStack.stackRankList
                    ?.map((item: any) => item)
                    ?.sort((a: any, b: any) =>
                      sortTableAscending ? a.list_rank - b.list_rank : b.list_rank - a.list_rank,
                    )
                    ?.map((item: any) => (
                      <StackTR key={item.id}>
                        <StackTD selected={item.selected}>{item.list_rank}</StackTD>
                        <StackTD selected={item.selected}>{item.row_label}</StackTD>
                        {/* <StackTD selected={item.row_label === rowLabels[0]}>N/A</StackTD> */}
                        <StackTD selected={item.selected}>{formatCellData(item?.value, item?.metric_type)}</StackTD>
                      </StackTR>
                    ))
                )}
              </tbody>
            </StackTable>
          </StackTableContainer>
        )}
      </PaddingDiv>
    </LargeDiv>
  );
};

const TableActionsDiv = styled.div`
  /* width: 1040px;
  margin-bottom: 8px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end; */
  width: 100%;
  margin-bottom: 8px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  height: 16px;
`;

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 480px; }
`;

const DarkDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SlideInDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 478px;
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  background-color: ${theme.WHITE_COLOR};
`;

// const SlideInDiv = styled.div`
//   position: fixed;
//   left: 72px;
//   width: 478px;
//   min-height: calc(100vh - 48px);
//   overflow: hidden;
//   animation-name: ${slideInAnimation};
//   animation-duration: 700ms;
//   z-index: 4;
//   background-color: ${theme.WHITE_COLOR};
//   ::after {
//     position: fixed;
//     z-index: 3;
//     content: "";
//     top: 0px;
//     bottom: 0px;
//     left: 550px;
//     right: 0px;
//     background-color: rgba(0, 0, 0, 0.5);
//   }
// `;

const ClickableText = styled(AppText)`
  width: fit-content;
  border-bottom: none;
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 8px;
`;

interface OnlyWidthProp {
  width: number;
}

const SpaceBetweenDiv = styled.div<OnlyWidthProp>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: ${(props) => `${props.width * 36 + 240}px`};
  min-width: 1250px;
  align-items: flex-end;
  margin: 16px 0px;
`;

interface SelectedViewProp {
  selected: boolean;
}

const ToggleViewText = styled(AppText)<SelectedViewProp>`
  width: 141px;
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 8px;
  font-weight: ${(props) => (props.selected ? 600 : "normal")};
  border-bottom: ${(props) => (props.selected ? `2px solid ${theme.PRIMARY500}` : `1px solid ${theme.NEUTRAL200}`)};
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.2px;
`;

const DateAppSelect = styled(AppSelect)`
  font-size: 12px;
`;

const TimeSelectDiv = styled.div`
  width: 240px;
  margin-right: 32px;
`;

const TimeLabelText = styled(AppText)`
  /* margin-top: 8px;
  font-size: 8px;
  font-weight: 600; */
  margin: 0px;
  margin-bottom: 5px;
  line-height: 1.3;
`;

const TimeColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`;

const TimePickerDiv = styled.div`
  display: flex;
  margin-left: 240px;
`;

const ShortLineHeightP = styled.p`
  line-height: 1.4;
  max-width: 320px;
`;

interface WidthProp {
  width: number;
  expanded: boolean;
}

const PaddingDiv = styled.div<WidthProp>`
  /* padding: 50px;
  padding-right: calc(
    (
        100vw - 200px - 78px - ${(props) => (!!props.width ? `${props.width * 36}` : `10 * 36`)}px + 10px -
          ${(props) => (props.expanded ? `318` : `20`)}px
      ) / 2
  ); */
  padding: 72px 60px 0px 60px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: min-content;
  /* NOTE: toggle the line below to make the stack table and graph shrink if the screen is small or always be the width of the table */
  /* min-width: min-content; */
`;

const SlightlyBoldSpan = styled.span`
  font-weight: 600;
`;

const SwtichLabelText = styled(AppText)`
  font-size: 9.2px;
  margin: 10px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StackTableContainer = styled.div`
  /* margin: 20px; */
  margin-top: 0px;
  max-height: 425px;
  overflow-y: scroll;
  min-width: 100%;
  border-radius: 10px;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  /* margin-left: 20px; */
  margin-bottom: 20px;
`;

interface CoachingViewProp {
  makeDivider: boolean;
}

const GridTR = styled.tr<CoachingViewProp>`
  td:nth-last-child(5),
  th:nth-last-child(5) {
    border-right: ${(props) =>
      props.makeDivider ? `solid 1.5px ${theme.BLACK_COLOR}` : `solid 0.5px ${theme.NEUTRAL200}`};
  }
`;

const TableHeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

interface IBold {
  bold?: boolean;
}

const TitleText = styled(AppText)<IBold>`
  display: inline-block;
  font-size: 13px;
  font-weight: ${(props) => (props.bold ? 500 : "normal")};
  margin-left: ${(props) => (props.bold ? "15px" : "7px")};
`;

interface SelectedProp {
  selected?: boolean;
}

const StackTR = styled.tr`
  border: none;
  :last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  :last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  :last-child td {
    border-bottom: none;
  }
  td:first-child {
    border-left: none;
  }
  td:nth-child(2),
  th:nth-child(2) {
    text-align: left;
    padding-left: 38px;
  }
  td:last-child {
    border-right: none;
  }
`;

const StackTD = styled.td<SelectedProp>`
  font-size: 12px;
  font-weight: ${(props) => (props.selected ? 500 : 300)};
  color: ${(props) => (props.selected ? theme.WHITE_COLOR : theme.BLACK_COLOR)};
  border: solid 0.5px ${theme.NEUTRAL200};
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.WHITE_COLOR)};
  line-height: 23px;
  text-align: center;
`;

const StackTH = styled.th`
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  height: 33px;
  text-align: center;
  border-bottom: solid 0.5px ${theme.NEUTRAL200};
  :first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
  }
`;

const StackTable = styled.table`
  min-width: 800px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  border-collapse: collapse;
`;

const SuggestionRightIcon = styled.div`
  position: absolute;
  display: flex;
  right: 10px;
  top: 10px;
`;

interface QuestionSize {
  big?: boolean;
  nomargin?: boolean;
}

const QuestionMarkMoreInfo = styled(AppText)<QuestionSize>`
  display: inline-block;
  line-height: ${(props) => (props.big ? "15px" : "10px")};
  font-size: ${(props) => (props.big ? "10px" : "7px")};
  padding-left: ${(props) => (props.big ? "5px" : "0px")};
  margin: ${(props) => (props.nomargin ? "1px 0" : props.big ? "auto" : "5px")};
  margin-left: ${(props) => (props.nomargin ? 0 : "5px")};
  height: ${(props) => (props.big ? "15px" : "10px")};
  width: ${(props) => (props.big ? "15px" : "10px")};
  border-radius: 50%;
  background-color: ${theme.NEUTRAL100};
  color: ${theme.PRIMARY500};
  cursor: pointer;
`;

const SuggestionBoxText = styled(AppText)`
  font-size: 12.9px;
  font-weight: 500;
  margin: auto auto;
  line-height: 36px;
`;

const UnderlineText = styled(AppText)`
  width: 100%;
  position: relative;
  line-height: 14px;
  font-size: 11.1px;
  text-transform: uppercase;
  text-align: left;
  :after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 0px;
    height: 1px;
    background: ${theme.TERTIARY500};
    width: 100%;
  }
`;

const InfoDiv = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
`;

interface CaretLocation {
  position: number;
  width: number;
}

const GridSuggestionBox = styled.div<CaretLocation>`
  position: relative;
  min-width: ${(props) => `${props.width * 36 + 10}px`};
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background-color: ${theme.TERTIARY500};
  margin-bottom: 10px;

  :before {
    content: "";
    position: absolute;
    top: 36px;
    left: calc(100% * ${(props) => props.position} + 5px);
    border-top: 15px solid ${theme.TERTIARY500};
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    transform: scale(0.75, 1);
  }

  :after {
    content: "";
    position: absolute;
    left: calc(100% * ${(props) => props.position} + 6px);
    top: 36px;
    border-top: 14px solid ${theme.TERTIARY500};
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    transform: scale(0.75, 1);
  }
`;

const RowTitle = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

const GridTD = styled.td<SelectedProp>`
  :first-child {
    min-width: 240px;
    max-width: 240px;
  }
  white-space: nowrap;
  border: 1px solid ${theme.NEUTRAL200};
  line-height: 30px;
  font-size: 8px;
  padding: 2px;
  cursor: pointer;
  box-shadow: ${(props) => (!!props.selected ? `inset 0px 0px 2px 2px ${theme.PRIMARY500}` : "none")};
  text-align: center;
  background: ${theme.WHITE_COLOR};
`;

const GridTable = styled.table`
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  padding: 5px;
  overflow: hidden;
  width: fit-content;
`;

const GridTH = styled.th`
  position: relative;
  border: 1px solid ${theme.NEUTRAL200};
  padding: 5px;
  height: 160px;
  transform: translate(2px, 55px) rotate(270deg);
  min-width: 36px;
  max-width: 36px;
  font-size: 10px;
  font-weight: 500;
  :first-child {
    transform: translate(0px, 0px) rotate(0deg);
    min-width: 232px;
    border: 0px solid ${theme.NEUTRAL200};
  }
`;

const THText = styled.div`
  display: flex;
  position: absolute;
  /* This number was calculated from... */
  /* GridTH_translateY + GridTH_width/2 - 1  */
  top: 72px;
  /* 5px to account for padding */
  left: 5px;
  max-width: 150px;
`;

const MarginSpan = styled.span`
  display: inline-block;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

const LargeDiv = styled.div`
  background: ${theme.NEUTRAL100};
  min-height: calc(100vh);
  max-height: calc(100vh);
  overflow: auto;
`;

export { SimpleGrid };
