import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext, useState } from "react";
import { AppButton, AppText, AppSidebarCard, Loading, AppErrorText, AppMultiSelect } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import { IoMdClose } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import { InputField, FormSelectField, FormMultiSelectField } from "../Field";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { SelectedContactType } from "../../types";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { ModalContext } from "../../context";
import { appToast } from "../../utils/toast";
import { VALIDATE_PHONE_NUMBER } from "../Segments/CallSegments/ConfirmLeadDetails";
import { NewAppButton } from "../UI/NewAppButton";
import { newCloseModal } from "../../images";
import { PHONE_REGEX } from "../../utils/regex";
import { extractNumberWithCountry } from "../../utils/format";
import { MixpanelActions } from "./../../services/mixpanel";

const FETCH_AVAILABLE_OPTIONS = gql`
  query fetchAvaliableUserForLeadTransfer($lead_id: String!) {
    fetchAvaliableUserForLeadTransfer(lead_id: $lead_id) {
      id
      first_name
      last_name
    }
  }
`;

const REASSIGN_LEAD = gql`
  mutation reassignLead(
    $lead_id: String!
    $dest_user_id: String!
    $source_user_id: String
    $override: Boolean
    $set_own: Boolean
    $ignore_future_event_warning: Boolean
  ) {
    reassignLead(
      lead_id: $lead_id
      dest_user_id: $dest_user_id
      source_user_id: $source_user_id
      override: $override
      set_own: $set_own
      ignore_future_event_warning: $ignore_future_event_warning
    ) {
      id
    }
  }
`;

interface ReassignProps {
  repVersion?: boolean;
  trackMixpanel?: boolean;
}

const ReassignLeadModalComponent: React.FC<ReassignProps> = ({ repVersion = false, trackMixpanel = false }) => {
  const [warning, setWarning] = useState("");
  const [step, setStep] = useState(1);
  const { reassignLeadModal, setReassignLeadModal, selectedLeadToReassign, setSelectedLeadToReassign } = useContext(
    ModalContext,
  );

  const [newRep, setNewRep] = useState({
    label: "",
    value: "",
  });

  const { data: dataOptions, loading: loadingOptions, error: errorOptions } = useQuery(FETCH_AVAILABLE_OPTIONS, {
    variables: {
      lead_id: selectedLeadToReassign.lead_id,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [reassignLead, { loading: loadingReassign }] = useMutation(REASSIGN_LEAD, {
    async onCompleted({ reassignLead }) {
      console.log("reassignLead: ", reassignLead);
      if (!reassignLead) {
        appToast("Error reassigning lead. Something went wrong.");
        return;
      }
      setReassignLeadModal(false);
      setSelectedLeadToReassign({ lead_id: "", source_user_id: "", lead_id_list: [] });
      appToast("Reassigned lead!");
    },
    onError({ message }) {
      appToast(message);
      // if (message && message.includes("Warning")) {
      //   setWarning(message);
      // }
      if (!!message) {
        setWarning(message);
        message === "there are future events for this lead" && setStep(2);
      }
      console.log("Error in reassignLead: ", message);
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact"],
  });

  if (loadingOptions)
    return (
      <Modal open={reassignLeadModal} onClose={() => setReassignLeadModal(false)} closeButtonSize={16}>
        <EditCardDiv>
          <Loading />
        </EditCardDiv>
      </Modal>
    );
  if (errorOptions)
    return (
      <Modal open={reassignLeadModal} onClose={() => setReassignLeadModal(false)} closeButtonSize={16}>
        <EditCardDiv>
          <AppErrorText>Error loading options</AppErrorText>
        </EditCardDiv>
      </Modal>
    );

  const repOptions = dataOptions.fetchAvaliableUserForLeadTransfer?.map((item: any) => ({
    label: `${item.first_name} ${item.last_name}`,
    value: item?.id,
  }));

  return (
    <Modal open={reassignLeadModal} onClose={() => setReassignLeadModal(false)} closeButtonSize={16}>
      <EditCardDiv style={{ height: repVersion ? "500px" : "315px" }}>
        <TitleDiv>
          <TitleText>Reassign Lead</TitleText>
        </TitleDiv>
        {step === 1 && (
          <FlexDiv>
            {repVersion && (
              <>
                <ReassignText style={{ fontWeight: 600 }}>Who would you like to reassign this lead to?</ReassignText>
                <ReassignText>
                  Re-assigning this lead to another person will make them the new owner of the lead. It will also
                  automatically move any upcoming events scheduled with this lead from your calendar to their calendar.
                </ReassignText>
              </>
            )}
            <div>
              <AppText style={{ padding: "7px 0px" }}>
                Who would you like to reassign the lead to<span style={{ color: theme.ATTENTION700 }}>*</span>
              </AppText>
              <AppMultiSelect
                isMulti={false}
                name="rep"
                value={newRep}
                options={repOptions.sort((a: any, b: any) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
                onChange={(e: any) =>
                  !!e?.value ? setNewRep({ label: e?.label, value: e?.value }) : setNewRep({ label: "", value: "" })
                }
              />
            </div>
            {!!warning && (
              <AppText style={{ padding: "7px 0px", textAlign: "center", color: theme.ATTENTION700 }}>
                {warning}
              </AppText>
            )}
            <NewAppButton
              variant={"primary"}
              disabled={!!!newRep.value || loadingReassign}
              onClick={async () => {
                await reassignLead({
                  variables: {
                    lead_id: selectedLeadToReassign.lead_id,
                    dest_user_id: newRep.value,
                    source_user_id: selectedLeadToReassign.source_user_id,
                    override: !!warning,
                    set_own: true,
                  },
                });
                trackMixpanel && MixpanelActions.track("Reassign Confirmation");
              }}
            >
              {loadingReassign ? (
                <Loading color={theme.WHITE_COLOR} />
              ) : repVersion ? (
                !!warning ? (
                  "Yes, Reassign Lead"
                ) : (
                  "Reassign Lead"
                )
              ) : !!warning ? (
                "Yes, Save"
              ) : (
                "Save"
              )}
            </NewAppButton>
            {repVersion && (
              <NewAppButton style={{ marginTop: "16px" }} onClick={() => setReassignLeadModal(false)}>
                Cancel
              </NewAppButton>
            )}
          </FlexDiv>
        )}
        {step === 2 && (
          <FlexDiv>
            <AppText style={{ padding: "7px 0px" }}>
              There are future events for this lead are you sure you want to reassign?
            </AppText>
            <SubmitDiv>
              <NewAppButton
                variant="primary"
                disabled={!!!newRep.value || loadingReassign}
                onClick={async () => {
                  await reassignLead({
                    variables: {
                      lead_id: selectedLeadToReassign.lead_id,
                      dest_user_id: newRep.value,
                      source_user_id: selectedLeadToReassign.source_user_id,
                      override: !!warning,
                      set_own: true,
                      ignore_future_event_warning: true,
                    },
                  });
                  trackMixpanel && MixpanelActions.track("Reassign Confirmation");
                }}
              >
                {loadingReassign ? <Loading color={theme.WHITE_COLOR} /> : "Yes, Reassign Lead"}
              </NewAppButton>

              <NewAppButton variant="secondary" onClick={() => setReassignLeadModal(false)}>
                Cancel
              </NewAppButton>
            </SubmitDiv>
          </FlexDiv>
        )}
      </EditCardDiv>
    </Modal>
  );
};

const ReassignText = styled(AppText)`
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 12px;
`;

const SubmitDiv = styled.div`
  display: flex;
  gap: 16px;
`;

const BodyTextDiv = styled.div`
  font-size: 14px;
  max-height: 100%;
  overflow-y: auto;
  padding: 0px 24px;
  padding-bottom: 24px;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
  /* margin-bottom: 24px; */
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.NEUTRAL100};
  border-bottom: solid 1px ${theme.NEUTRAL200};
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 286px;
`;

export { ReassignLeadModalComponent };
