import React from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, StatCard } from "../../../UI";
import { check, xIcon } from "../../../../images/NewDesign";
import moment from "moment";

interface ICommunicationsActivityLeadCardRowProps {
  type: "sms" | "email";
  subscribed: boolean;
  height: number;
  date?: Date;
  text: string;
}

const CommunicationsActivityLeadCard = ({
  type,
  subscribed,
  height = 56,
  date = new Date(),
  text = "No text provided",
}: ICommunicationsActivityLeadCardRowProps) => {
  return (
    <LeadCardBar subscribed={subscribed} type={type} height={height}>
      <Left>
        {subscribed ? (
          <StatCard icon={check} backgroundColor={theme.SUCCESS500} iconColor={theme.WHITE_COLOR} iconSize={12} />
        ) : (
          <StatCard icon={xIcon} backgroundColor={theme.DANGER600} iconColor={theme.WHITE_COLOR} iconSize={12} />
        )}
        <AppText fontSize={12} fontWeight={400} style={{ marginLeft: 8 }}>
          {text}
        </AppText>
      </Left>
      <Right>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 0 }}>
          <AppText fontSize={12} fontWeight={400}>
            {moment(date).format("MM/DD/YYYY")}
          </AppText>
          <AppText style={{ color: theme.NEUTRAL350 }}>{moment(date).format("h:mm A")}</AppText>
        </div>
      </Right>
    </LeadCardBar>
  );
};

const LeadCardBar = styled.div<{ subscribed: boolean; type: string; height: number }>`
  display: flex;
  height: ${(props) => props.height}px;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.subscribed ? theme.SUCCESS200 : theme.DANGER100)};
  border-radius: 0px;
  border: 1px solid ${(props) => (props.subscribed ? theme.SUCCESS500 : theme.DANGER600)};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Right = styled.div``;

export { CommunicationsActivityLeadCard };
