import styled from "styled-components";
import * as React from "react";
import { Loading, AppSidebarCard, AppErrorText } from "../../UI";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { LeadCardV2 } from "../../Segments/DashboardSideBarSegments";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFlags } from "launchdarkly-react-client-sdk";

interface CurrentLeadProps {
  closeOtherModals?: any;
  data?: any;
}

const SuggestedDialsComponent: React.FC<CurrentLeadProps> = ({ closeOtherModals, data }) => {
  return (
    <AppSidebarCard title={"Sellfire Suggested Dial"} specialColor width={432}>
      <LeadCardV2
        showEventTypeCard
        parentType={"recommendedDials"}
        leadData={data?.lead}
        intent={data?.lead?.lead_intent?.length && data?.lead?.lead_intent[0]}
        closeOtherModals={closeOtherModals}
      />
    </AppSidebarCard>
  );
};

interface BorderProps {
  borderTop?: boolean;
}

const BorderDiv = styled.div<BorderProps>`
  border-top: 1px solid ${(props) => (props.borderTop ? theme.NEUTRAL200 : "transparent")};
  padding: 0px;
  margin: 0px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  height: 400px;
`;

export { SuggestedDialsComponent };
