import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AppText, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { GoPencil } from "react-icons/go";
import { ApolloError } from "@apollo/client";

interface EditableInputFieldProps {
  text: string;
  onSubmit: any;
  id: string;
  loading: boolean;
  error: ApolloError | undefined;
  pencil?: boolean;
}

const EditableInputField: React.FC<EditableInputFieldProps> = ({ text, onSubmit, id, loading, error, pencil }) => {
  // Local State
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(text);

  //On mount
  useEffect(() => {
    searchInput?.current?.focus();
  }, [editing]);

  // Refs
  const searchInput = createRef<HTMLInputElement>();

  // Functions
  const saveOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    if (key === "Enter") {
      handleSubmit();
    }
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSubmit = () => {
    if (text !== value) {
      onSubmit({
        variables: {
          team_id: id,
          team_name: value,
        },
      });
    }
    setEditing(false);
  };

  const handleBlur = () => {
    handleSubmit();
    setEditing(false);
  };

  // UI
  return editing ? (
    <InputDiv>
      <TextInput
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e) => saveOnEnter(e)}
        onBlur={() => handleBlur()}
        ref={searchInput}
      />
    </InputDiv>
  ) : (
    <IconTextDiv>
      <Text 
        // if 'pencil' prop is false, click on text to edit
        {...(pencil === false && { onClick: () => handleEditClick() })}
        style={{borderBottom: 'none', cursor: pencil === false ? 'pointer' : 'unset'}}
      >{value}</Text>

        {/* if 'pencil' prop is true, click on pencil to edit */}
      {(pencil || pencil === undefined) && <GoPencil onClick={() => handleEditClick()} style={{ cursor: "pointer" }} />}
    </IconTextDiv>
  );
};

const IconTextDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 17px 0px 17px 0px;
`;

const InputDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 17px 0px 12px 0px;
  min-height: 29px;
`;

const TextInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 2px;
  margin: 0px 24px 0px 24px;
`;

const Text = styled(AppText)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export { EditableInputField };
