import React, { useState, useEffect } from "react";
import { calculateTimeDifference } from "../../utils/format";
import moment from "moment";
import { AppText } from "../UI";

interface IntervalProps {
  time: any;
}
//component with setInterval useEffect that allows rerenders for only this and not the parent
const TimeInterval = ({ time, ...props }: IntervalProps) => {
  const [currentTime, setCurrentTime] = useState(moment().format());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <span style={{ textAlign: "center" }}>{calculateTimeDifference(currentTime, time)}</span>;
};

export { TimeInterval };
