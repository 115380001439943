import React, { useContext, Dispatch, SetStateAction, memo, useMemo } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import { theme } from "../../../utils/theme";
import { AppText, SkeletonBlock } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { eye, eye_off, reorder, trash } from "../../../images/NewDesign";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { ModalContext } from "../../../context";
import { SaleConfigPage } from "../../../utils/misc";
import { useFirstRender } from "../../../utils/hooks";

interface PageSectionProps {
  pageState: {
    pages: SaleConfigPage[];
    setPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
    selectedPage: SaleConfigPage | undefined;
    setSelectedPage: Dispatch<SetStateAction<SaleConfigPage | undefined>>;
    setPageTitle: Dispatch<SetStateAction<string>>;
    hiddenPages: SaleConfigPage[];
    setHiddenPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
  };
  handlePageDragEnd: (result: DropResult) => void;
  handleHidePage: (id: string) => void;
  handleShowPage: (id: string) => void;
  loading: boolean;
}

export const PageSection: React.FC<PageSectionProps> = memo(
  ({
    pageState: { pages, selectedPage, setSelectedPage, setPageTitle, hiddenPages },
    handlePageDragEnd,
    handleHidePage,
    handleShowPage,
    loading,
  }) => {
    const { setShowAddSalePageModal } = useContext(ModalContext);

    return (
      <PageSectionWrap>
        <AppText
          fontSize={10}
          fontWeight={600}
          lineHeight={16}
          uppercase
          color={theme.NEUTRAL300}
          letterSpacing={1}
          style={{ paddingLeft: "16px" }}
        >
          Pages
        </AppText>

        {!loading ? (
          <DragDropContext onDragEnd={handlePageDragEnd}>
            <Droppable droppableId="make-sale-config-pages">
              {(provided) => (
                <PageListContainer direction="column" {...provided.droppableProps} ref={provided.innerRef}>
                  {pages?.map((p, i) => (
                    <DraggablePage
                      data={p}
                      selected={selectedPage?.id === p.id}
                      index={i}
                      key={p.id}
                      required={p.required}
                      setSelectedPage={setSelectedPage}
                      setPageTitle={setPageTitle}
                      handleHidePage={handleHidePage}
                    />
                  ))}

                  {provided.placeholder}
                </PageListContainer>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <FlexDiv direction="column" gap={16} style={{ padding: "16px 0px 0px 20px" }}>
            {Array.from({ length: 5 })?.map((_, i) => (
              <SkeletonBlock
                width={320}
                height={42}
                borderRadius={8}
                key={i}
                delayNumber={i + 1}
                lightPulseStateColor={theme.PRIMARY100}
                opacity={Math.max(1 - i * 0.2, 0.2)}
              />
            ))}
          </FlexDiv>
        )}

        <PhoenixAppButton
          variant="brand"
          buttonType="secondary"
          uppercase
          style={{ fontSize: "10px", margin: "24px 0px 0px 20px" }}
          onClick={() => setShowAddSalePageModal(true)}
          disabled={loading}
        >
          Add Page
        </PhoenixAppButton>

        {!loading && !!hiddenPages.length && (
          <HiddenPageListContainer>
            <AppText
              fontSize={10}
              fontWeight={600}
              lineHeight={16}
              letterSpacing={1}
              uppercase
              color={theme.NEUTRAL300}
              style={{ paddingBottom: "16px" }}
            >
              Hidden
            </AppText>

            {hiddenPages?.map((p) => (
              <HiddenPage key={p.id} data={p} handleShowPage={handleShowPage} />
            ))}
          </HiddenPageListContainer>
        )}
      </PageSectionWrap>
    );
  },
  (oldProps, newProps) => {
    const oldPages = oldProps?.pageState?.pages;
    const newPages = newProps?.pageState?.pages;

    const oldPageOrder = oldPages?.map((page) => page.id);
    const newPageOrder = newPages?.map((page) => page.id);

    const oldSelectedPage = oldPages?.find((p) => p?.id === oldProps?.pageState?.selectedPage?.id);
    const newSelectedPage = newPages?.find((p) => p?.id === newProps?.pageState?.selectedPage?.id);

    const sectionsAreEqual = !newPages.some((np) => {
      const op = oldPages.find((op) => op.id === np.id);
      if (!op) return true; // if the page is new, consider it has new sections

      return !isEqual(op.sections, np.sections); // check if sections are different
    });

    // if the page order, section order, page title, and loading state are the same, dont re-render
    return (
      sectionsAreEqual &&
      isEqual(oldPageOrder, newPageOrder) &&
      isEqual(oldSelectedPage?.title, newSelectedPage?.title) &&
      isEqual(oldProps.pageState.hiddenPages, newProps.pageState.hiddenPages) &&
      oldProps.loading === newProps.loading
    );
  },
);

const PageSectionWrap = styled.div`
  padding-top: 24px;
`;

const PageListContainer = styled(FlexDiv)`
  margin-top: 16px;
`;

const HiddenPageListContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 360px;
  margin-top: 24px;
  padding: 24px 24px 0px 16px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;

interface HiddenPageProps {
  data: SaleConfigPage;
  handleShowPage: (id: string) => void;
}

const HiddenPage: React.FC<HiddenPageProps> = ({ data, handleShowPage }) => {
  const { setShowDeleteSalePageModal, setDeleteHiddenPage } = useContext(ModalContext);

  const isPandaDocPage = useMemo(() => !!data.sections.find((s) => s.type === "PANDADOC"), [data]);

  return (
    <HiddenPageContainer>
      <FlexDiv justify="space-between" align="center" style={{ width: "100%" }}>
        <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.NEUTRAL300}>
          {data.title}
        </AppText>

        <FlexDiv align="center" gap={8}>
          <PhoenixIcon svg={eye_off} size={16} variant="neutral" pointer onClick={() => handleShowPage(data.id)} />
          {!isPandaDocPage && (
            <PhoenixIcon
              svg={trash}
              size={16}
              variant="danger"
              pointer
              onClick={() => {
                setDeleteHiddenPage(data.id);
                setShowDeleteSalePageModal(true);
              }}
            />
          )}
        </FlexDiv>
      </FlexDiv>
    </HiddenPageContainer>
  );
};

const HiddenPageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 320px;
  min-height: 42px;
  padding: 12px;
  margin-bottom: 16px;

  border-radius: 8px;
  background-color: ${theme.PRIMARY100};
`;

interface IDraggablePage {
  data: SaleConfigPage;
  selected: boolean;
  index: number;
  required?: boolean;
  setSelectedPage: Dispatch<SetStateAction<SaleConfigPage | undefined>>;
  setPageTitle: Dispatch<SetStateAction<string>>;
  handleHidePage: (id: string) => void;
}

const DraggablePage: React.FC<IDraggablePage> = ({
  data,
  selected,
  index,
  required,
  setSelectedPage,
  setPageTitle,
  handleHidePage,
}) => {
  const { setShowDeleteSalePageModal } = useContext(ModalContext);

  const isFirstRender = useFirstRender();

  const isPandaDocPage = useMemo(() => !!data.sections.find((s) => s.type === "PANDADOC"), [data]);

  return (
    <Draggable draggableId={`page-${data.id}`} index={index}>
      {(provided) => (
        <PageWrap
          required={required}
          ref={provided.innerRef}
          className={isFirstRender ? "animate-on-mount" : ""}
          {...provided.draggableProps}
        >
          <div ref={provided.innerRef} {...provided.dragHandleProps}>
            {!required && <PhoenixIcon svg={reorder} size={16} variant="neutral" style={{ cursor: "grab" }} />}
          </div>

          <Page
            selected={selected}
            onClick={() => {
              if (selected) return;
              setSelectedPage(data);
              setPageTitle(data.title);
            }}
          >
            <AppText fontSize={12} fontWeight={400} lineHeight={18}>
              {data.title}
            </AppText>

            <FlexDiv gap={8}>
              {!required && (
                <>
                  <PhoenixIcon
                    svg={eye}
                    size={16}
                    variant="neutral"
                    pointer
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHidePage(data.id);
                    }}
                  />
                  {!isPandaDocPage && (
                    <PhoenixIcon
                      svg={trash}
                      size={16}
                      variant="danger"
                      pointer
                      onClick={() => setShowDeleteSalePageModal(true)}
                    />
                  )}
                </>
              )}
            </FlexDiv>
          </Page>
        </PageWrap>
      )}
    </Draggable>
  );
};

interface PageWrapProps {
  required?: boolean;
}

const PageWrap = styled.div<PageWrapProps>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.required ? "0px" : "4px")};

  padding-left: ${(props) => (props.required ? "20px" : "0px")};
  padding-bottom: 16px;

  &.animate-on-mount {
    animation: ${theme.textFadeIn} 0.3s ease-in-out;
  }
`;

interface PageProps {
  selected: boolean;
}

const Page = styled.div<PageProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 320px;
  min-height: 42px;
  padding: 12px;

  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 8px;

  background-color: ${(props) => (props.selected ? theme.PRIMARY100 : theme.WHITE_COLOR)};
`;
