import styled from "styled-components";
import { theme } from "../../utils/theme";

interface AppTextProps {
  fontSize?: number;
  fontWeight?: number;
  variant?: "primary" | "error" | "success" | "warning" | "white";
  noWrap?: boolean;
  color?: string;
  letterSpacing?: number;
  uppercase?: boolean;
  lineHeight?: number;
  textAlign?: "center" | "left" | "right";
  showBorderOnClick?: boolean;
  cursor?: string;
}

export const AppText = styled.p<AppTextProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${(props) => props.fontSize || 12}px;
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  letter-spacing: ${(props) => (props.letterSpacing ? props.letterSpacing : 0.2)}px;
  width: fit-content;
  margin: 0;
  font-stretch: normal;
  font-style: "normal";
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "normal")};
  line-height: ${(props) => (props.lineHeight ? `${props.lineHeight}px` : "1.5")};
  border-bottom: ${(props) => (!!props.onClick && props.showBorderOnClick ? 4 : 0)}px solid ${theme.PRIMARY500};

  ${({ cursor }) =>
    cursor &&
    `
    cursor: ${cursor};
  `}
  /* :hover {
    cursor: ${(props) => (!!props.onClick ? "pointer" : "text")};
  } */
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}

  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      switch (props.variant) {
        case "primary":
          return theme.PRIMARY500;
        case "error":
          return theme.ATTENTION700;
        case "success":
          return theme.SUCCESS500;
        case "warning":
          return theme.TERTIARY500;
        case "white":
          return theme.WHITE_COLOR;
        default:
          return theme.BLACK_COLOR;
      }
    }
  }};
`;

AppText.defaultProps = {
  showBorderOnClick: true,
};

export const AppErrorText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.ATTENTION700};
  margin: 0;
`;

export const AppTitle = styled.h1`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 600;
`;

export const AppTitle2 = styled.h2`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 16px;
  font-weight: 600;
`;

export const AppFormTitle = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${theme.BLACK_COLOR};
`;

export const AppPanelTitle = styled.h2`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
`;

export const AppH3 = styled.h3``;
export const AppH4 = styled.h4``;
