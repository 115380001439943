import React, { useState, useContext, useEffect, useMemo } from "react";
import { useQuery, useLazyQuery, gql, useMutation } from "@apollo/client";
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import { theme } from "../../../utils/theme";
import { ModalContext } from "../../../context";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";
import { PhoenixMultiSelectField } from "../../Field/Phoenix/PhoenixMultiSelectField";
import { AppText, DarkDiv, Loading } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { plus, xIcon } from "../../../images/NewDesign";
import { Formik, FormikProps } from "formik";
import { cloneDeep, isEqual } from "lodash";
import { errorToast } from "../../../utils/toast";
import {
  allRegions,
  blockedDispositions,
  channelOptions,
  entryCriteriaOptions,
  personSpokenToOptions,
} from "../../../utils/sequences";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { MixpanelActions } from "../../../services/mixpanel";

const FETCH_INDUSTRY_OPTIONS = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const FETCH_DISPOSITION_TYPES = gql`
  query fetchDispositionsTypes($showLabel: Boolean) {
    fetchDispositionsTypes(show_label: $showLabel)
  }
`;

const FETCH_FILTERED_CALL_RESULT_DISPOSITIONS = gql`
  query fetchFilteredCallResultDispositions($phase: String!, $coldCallAggregate: Boolean) {
    fetchFilteredCallResultDispositions(phase: $phase, cold_call_aggregate: $coldCallAggregate)
  }
`;

const FETCH_LEAD_SOURCE_OPTIONS = gql`
  query fetchLeadSourceOptions {
    fetchLeadSourceOptions {
      id
      label
    }
  }
`;

const CREATE_OR_UPDATE_ENTRY_CRITERIA = gql`
  mutation createOrUpdateSequenceEntryCriteria($SequenceCriteriaInput: SequenceCriteriaInput!) {
    createOrUpdateSequenceEntryCriteria(SequenceCriteriaInput: $SequenceCriteriaInput) {
      id
      sequence_criteria_condition {
        value
      }
      sequence {
        name
        id
      }
    }
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;
interface CriteriaFormProps {
  types: string[] | null;
  person_spoke_to: string[] | null;
  channels: string[] | null;
  entry_phases: string[];
  conditions: any[];
  custom_fields: any[] | null;
  sequence_id: string;
}

interface SequenceCriteriaModal {
  setForceSkipEntry: React.Dispatch<React.SetStateAction<boolean>>;
  sequenceId: string;
  sequenceData?: any;
  isPaused?: boolean;
}

export const SequenceCriteriaModal: React.FC<SequenceCriteriaModal> = ({
  setForceSkipEntry,
  sequenceId,
  sequenceData,
  isPaused,
}) => {
  const { setShowSequenceCriteriaModal } = useContext(ModalContext);

  const [showInclusionCriteria, setShowInclusionCriteria] = useState<boolean>(
    !!sequenceData?.sequence_entry_criteria?.person_spoke_to?.length ||
      !!sequenceData?.sequence_entry_criteria?.types?.length ||
      !!sequenceData?.sequence_entry_criteria?.channels?.length ||
      !!sequenceData?.sequence_entry_criteria?.sequence_criteria_condition?.length,
  );

  useEffect(() => {
    if (showInclusionCriteria) {
      // fetch on mount if edit state
      fetchFilteredCallResultDispositions({
        variables: {
          phase: sequenceData?.sequence_entry_criteria?.current_phase?.[0],
          coldCallAggregate: sequenceData?.sequence_entry_criteria?.current_phase?.length > 1, // list > 1 means cold call aggregate
        },
      });
      fetchIndustryOptions();
      fetchLeadSourceOptions();
    }
  }, []);

  const [
    fetchFilteredCallResultDispositions,
    { data: dataDispositionTypes, loading: loadingDispositionTypes, error: errorDispositionTypes },
  ] = useLazyQuery(FETCH_FILTERED_CALL_RESULT_DISPOSITIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message }) {
      console.log("Error fetchFilteredCallResultDispositions: ", message);
    },
  });
  const [
    fetchIndustryOptions,
    { data: dataIndustryOptions, loading: loadingIndustryOptions, error: errorIndustryOptions },
  ] = useLazyQuery(FETCH_INDUSTRY_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message }) {
      Sentry.captureEvent({
        message: `Error fetchIndustryOptions - GraphQL Error: ${message}`,
      });
      console.log("Error fetchIndustryOptions: ", message);
    },
  });
  const [
    fetchLeadSourceOptions,
    { data: dataLeadSourceOptions, loading: loadingLeadSourceOptions, error: errorLeadSourceOptions },
  ] = useLazyQuery(FETCH_LEAD_SOURCE_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message }) {
      Sentry.captureEvent({
        message: `Error fetchLeadSourceOptions - GraphQL Error: ${message}`,
      });
      console.log("Error fetchLeadSourceOptions: ", message);
    },
  });

  const {
    data: allCustomFieldOptions,
    loading: allCustomFieldOptionsLoading,
    error: allCustomFieldOptionsError,
  } = useQuery(FETCH_CUSTOM_FIELDS, { fetchPolicy: "cache-and-network" });

  const dispositionOptions = useMemo(
    () =>
      dataDispositionTypes
        ? dataDispositionTypes.fetchFilteredCallResultDispositions
            ?.filter((ele: { label: string; value: string }) => !blockedDispositions.includes(ele?.value))
            ?.sort((a: { label: string; value: string }, b: { label: string; value: string }) =>
              a.label.localeCompare(b.label),
            )
            ?.map((ele: { label: string; value: string }) => ({
              value: ele.value,
              label: ele.label,
            }))
        : [],
    [dataDispositionTypes],
  );

  const industryOptions = useMemo(
    () =>
      dataIndustryOptions
        ? dataIndustryOptions.fetchIndustryOptions?.map((ele: any) => ({
            value: ele?.label,
            label: ele?.label,
          }))
        : [],
    [dataIndustryOptions],
  );

  const leadSourceOptions = useMemo(
    () =>
      dataLeadSourceOptions
        ? dataLeadSourceOptions.fetchLeadSourceOptions?.map((ele: any) => ({
            value: ele?.label,
            label: ele?.label,
          }))
        : [],
    [dataLeadSourceOptions],
  );

  const customFieldOptions = useMemo(
    () =>
      allCustomFieldOptions?.fetchCustomFields?.length > 0
        ? allCustomFieldOptions?.fetchCustomFields
            // filter unsupported types
            .filter((item: any) => {
              return ["MultiDropdown", "Dropdown", "Boolean"].includes(item.type);
            })
            ?.map((item: any) => ({
              label: item.key,
              value: item.id,
              type: item.type,
            }))
        : [],
    [allCustomFieldOptions],
  );

  const [createSequenceEntryCriteria, { loading: loadingCreateSequenceEntryCriteria }] = useMutation(
    CREATE_OR_UPDATE_ENTRY_CRITERIA,
    {
      async onCompleted({ createOrUpdateSequenceEntryCriteria }) {
        console.log("createOrUpdateSequenceEntryCriteria:", createOrUpdateSequenceEntryCriteria);
        setShowSequenceCriteriaModal(false);

        MixpanelActions.track("Sequence Edits Saved", { type: "entry and inclusion criteria" });
      },
      onError({ message }) {
        errorToast(`${message}`);
        Sentry.captureEvent({
          message: `createOrUpdateSequenceEntryCriteria GraphQL Error: ${message}`,
        });
        console.log(`createOrUpdateSequenceEntryCriteria GraphQL Error: ${message}`);
      },
      refetchQueries: ["fetchSequence"],
    },
  );

  const handleCriteriaCheck = (initialValue: any, condition: string, isChecked: boolean, secondCondition?: string) => {
    let newVal = cloneDeep(initialValue);

    if (isChecked) {
      newVal = newVal.filter((ele: any) => ele.conditionType !== condition); // remove from conditions array

      if (typeof secondCondition !== "undefined") {
        newVal = newVal.filter((ele: any) => ele.conditionType !== secondCondition);
      }
    } else {
      newVal.push({ value: [], conditionType: condition, operator: "In" }); // add to conditions array

      if (typeof secondCondition !== "undefined") {
        newVal.push({ value: [], conditionType: secondCondition, operator: "In" });
      }
    }
    return newVal;
  };

  const addValueToCondition = (initialValue: any, condition: string, newValue: any) => {
    const newVal = cloneDeep(initialValue);
    newVal.find((ele: any) => ele?.conditionType === condition).value = newValue;

    if (condition === "industry") {
      // remove sub-industries when removing industry
      const newAvailableSubIndustryOptions = newValue
        ?.map(
          (val: any) =>
            dataIndustryOptions.fetchIndustryOptions?.find((io: any) => {
              if (val.label) {
                return io.label === val.label;
              } else {
                return io.label === val;
              }
            })?.sub_industries,
        )
        ?.flat()
        ?.filter((ele: any) => !!ele)
        ?.map((ele: string) => ({ label: ele, value: ele }));

      let subIndustryVal = newVal.find((ele: any) => ele?.conditionType === "subIndustry").value;

      newVal.find((ele: any) => ele?.conditionType === "subIndustry").value = subIndustryVal?.filter(
        (ele: { label: string; value: string } | string) =>
          !!newAvailableSubIndustryOptions?.find((option: { label: string; value: string }) => {
            if (typeof ele === "string") {
              return option.label === ele;
            } else {
              return option.label === ele.label;
            }
          }),
      );
    }
    return newVal;
  };

  const handleSubmit = (e: any) => {
    const submitValue = cloneDeep(e);

    // parse and format submitValue
    submitValue.conditions = e?.conditions
      ?.map((condition: any) => {
        condition.value = condition?.value?.map((val: any) => (val.value ? val.value : val));
        return condition;
      })
      ?.filter((condition: any) => condition?.conditionType !== "customfield");

    if (!!submitValue.person_spoke_to) {
      submitValue.person_spoke_to = e.person_spoke_to?.map((ele: any) => ele.value);
    }
    if (!!submitValue.types) {
      submitValue.types = e.types?.map((ele: any) => ele.value);
    }
    if (!!submitValue.channels) {
      submitValue.channels = e.channels?.map((ele: any) => ele.value);
    }
    if (!!submitValue.custom_fields) {
      submitValue.custom_fields = e.custom_fields?.map((ele: any) => {
        if (typeof ele?.value?.[0] !== "string") {
          const newEle = ele;
          newEle.value = newEle?.value?.map((val: { label: string; value: string }) => val.value);
          return newEle;
        }
        return ele;
      });
    }

    // handle Cold Call Aggregate array
    if (submitValue?.entry_phases && Array.isArray(submitValue?.entry_phases?.[0])) {
      submitValue.entry_phases = [...submitValue.entry_phases?.[0]];
    }

    createSequenceEntryCriteria({
      variables: {
        SequenceCriteriaInput: submitValue,
      },
    });
  };

  return (
    <>
      <DarkDiv />
      <ModalContainer direction="column" justify="space-between">
        <ModalHeader direction="column">
          <PhoenixIcon
            svg={xIcon}
            variant="brand"
            color={theme.PRIMARY500}
            size={24}
            pointer
            onClick={() => setShowSequenceCriteriaModal(false)}
          />
          <AppText fontSize={16} fontWeight={600} style={{ width: "100%", textAlign: "center", whiteSpace: "nowrap" }}>
            Entry and Inclusion Criteria
          </AppText>
        </ModalHeader>

        <Formik
          initialValues={{
            person_spoke_to: !!sequenceData?.sequence_entry_criteria?.person_spoke_to?.length
              ? sequenceData?.sequence_entry_criteria?.person_spoke_to?.map((ele: string) =>
                  personSpokenToOptions.find((e: any) => e.value === ele),
                )
              : null,
            types: !!sequenceData?.sequence_entry_criteria?.types?.length
              ? sequenceData?.sequence_entry_criteria?.types?.map((ele: string) => ({ label: ele, value: ele }))
              : null,
            channels: !!sequenceData?.sequence_entry_criteria?.channels?.length
              ? sequenceData?.sequence_entry_criteria?.channels?.map((ele: string) =>
                  channelOptions.find((e: any) => e.value === ele),
                )
              : null,
            entry_phases: !!sequenceData?.sequence_entry_criteria?.current_phase?.length
              ? sequenceData?.sequence_entry_criteria?.current_phase
              : [],
            conditions: !!sequenceData?.sequence_entry_criteria?.sequence_criteria_condition?.length
              ? sequenceData?.sequence_entry_criteria?.sequence_criteria_condition?.map((condition: any) => {
                  // if we're in the edit state, change 'condition_type' key -> 'conditionType'
                  const newObj: any = {};
                  newObj.conditionType = condition.condition_type;
                  newObj.operator = condition.operator;
                  newObj.value = condition.value;
                  return newObj;
                })
              : [],
            custom_fields: !!sequenceData?.sequence_entry_criteria?.sequence_criteria_condition?.filter(
              (ele: any) => ele?.condition_type === "customfield",
            )?.length
              ? sequenceData?.sequence_entry_criteria?.sequence_criteria_condition
                  ?.filter((ele: any) => ele?.condition_type === "customfield")
                  ?.map((condition: any) => {
                    // if we're in the edit state, change 'condition_type' key -> 'conditionType'
                    const newObj: any = {};
                    newObj.conditionType = condition.condition_type;
                    newObj.customFieldType = condition.custom_field_type;
                    newObj.field = condition.field;
                    newObj.operator = condition.operator;
                    newObj.value = condition.value;
                    return newObj;
                  })
              : null,
            sequence_id: sequenceId,
          }}
          onSubmit={handleSubmit}
        >
          {({ submitForm, values, setFieldValue }: FormikProps<CriteriaFormProps>) => {
            const isCallResultChecked = values?.types !== null;
            const isIndustryChecked = !!values?.conditions?.find((ele) => ele?.conditionType === "industry");
            const isPersonSpokeToChecked = values?.person_spoke_to !== null;
            const isChannelChecked = values?.channels !== null;
            const isLeadSourceChecked = !!values?.conditions?.find((ele) => ele?.conditionType === "leadSource");
            const isRegionChecked = !!values?.conditions?.find((ele) => ele?.conditionType === "region");
            const isCustomFieldChecked = values?.custom_fields !== null;

            const isDisabled = // disabled when we check an inclusion criteria without selecting a value
              isPaused ||
              loadingCreateSequenceEntryCriteria ||
              !values.entry_phases?.length ||
              (isIndustryChecked &&
                !values?.conditions?.find((ele) => ele?.conditionType === "industry")?.value?.length) ||
              (isCallResultChecked && !values?.types?.length) ||
              (isPersonSpokeToChecked && !values?.person_spoke_to?.length) ||
              (isChannelChecked && !values?.channels?.length) ||
              (isLeadSourceChecked &&
                !values?.conditions?.find((ele) => ele?.conditionType === "leadSource")?.value?.length) ||
              (isRegionChecked && !values?.conditions?.find((ele) => ele?.conditionType === "region")?.value?.length) ||
              (isCustomFieldChecked &&
                (!values?.custom_fields?.length ||
                  !values?.custom_fields?.every((ele) => !!ele?.value?.length && !!ele?.field)));

            const availableSubIndustryOptions = values?.conditions // search dataIndustryOptions to find all available subindustries relating to selected industries
              ?.find((condition: any) => condition?.conditionType === "industry")
              ?.value?.map(
                (val: any) =>
                  dataIndustryOptions?.fetchIndustryOptions?.find((io: any) => {
                    if (val.label) {
                      return io.label === val.label;
                    } else {
                      return io.label === val;
                    }
                  })?.sub_industries,
              )
              ?.flat()
              ?.filter((ele: any) => !!ele)
              ?.map((ele: string) => ({ label: ele, value: ele }));
            return (
              <>
                <ModalBody direction="column">
                  {/* Entry Criteria */}
                  <CriteriaContainer>
                    <FlexDiv direction="column">
                      <CriteriaTitle>Entry Criteria</CriteriaTitle>
                      <CriteriaSubText>Select the phase for this sequence</CriteriaSubText>
                    </FlexDiv>
                    <div style={{ width: "100%" }}>
                      <PhoenixMultiSelectField
                        name="phase"
                        value={
                          !!values.entry_phases.length
                            ? entryCriteriaOptions?.find((option: { value: string | string[]; label: string }) =>
                                values.entry_phases[0] && values.entry_phases?.length === 1
                                  ? option.value === values.entry_phases[0]
                                  : isEqual(option.value, values.entry_phases),
                              )
                            : ""
                        }
                        isMulti={false}
                        onChange={(e: any) => {
                          setFieldValue("entry_phases", [e?.value]);
                          fetchFilteredCallResultDispositions({
                            variables: {
                              phase: typeof e?.value !== "string" ? e?.value[0] : e?.value,
                              coldCallAggregate: typeof e?.value !== "string", // not string means cold call aggregate
                            },
                          });
                          values?.types && setFieldValue("types", []);
                        }}
                        options={entryCriteriaOptions}
                        marginBottom={false}
                        isClearable={false}
                      />
                    </div>
                  </CriteriaContainer>

                  {/* Inclusion Criteria */}
                  <CriteriaContainer style={{ borderTop: `1px solid ${theme.NEUTRAL200}` }}>
                    <FlexDiv direction="column">
                      <CriteriaTitle>Inclusion Criteria</CriteriaTitle>
                      <CriteriaSubText>Only include leads who meet this criteria</CriteriaSubText>
                    </FlexDiv>

                    {showInclusionCriteria ? (
                      <InclusionCriteriaList>
                        {/* Call result */}
                        <InclusionCriteria checked={isCallResultChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isCallResultChecked}
                              onChange={() =>
                                values.types !== null ? setFieldValue("types", null) : setFieldValue("types", [])
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              Call Result
                            </AppText>
                          </FlexDiv>

                          {isCallResultChecked && (
                            <PhoenixMultiSelectField
                              name="call_result"
                              value={values?.types}
                              isMulti={true}
                              onChange={(e: any) => setFieldValue("types", e)}
                              options={
                                loadingDispositionTypes
                                  ? [{ label: "Loading...", value: "", disabled: true }]
                                  : !values.entry_phases?.length
                                  ? [{ label: "Select an entry criteria...", value: "", disabled: true }]
                                  : dispositionOptions
                              }
                              isOptionDisabled={(option) => option.disabled}
                              marginBottom={false}
                              isClearable={false}
                              showCheckbox
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              multiValueUppercase
                              menuPortal
                              menuShouldBlockScroll
                            />
                          )}
                        </InclusionCriteria>

                        {/* Industry */}
                        <InclusionCriteria checked={isIndustryChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isIndustryChecked}
                              onChange={() =>
                                setFieldValue(
                                  "conditions",
                                  handleCriteriaCheck(values?.conditions, "industry", isIndustryChecked, "subIndustry"),
                                )
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              Industry
                            </AppText>
                          </FlexDiv>

                          {isIndustryChecked && (
                            <>
                              <PhoenixMultiSelectField
                                name="industry"
                                value={values?.conditions
                                  ?.find((ele) => ele?.conditionType === "industry")
                                  ?.value?.map((ele: any) =>
                                    !ele?.value ? industryOptions?.find((opt: any) => opt.value === ele) : ele,
                                  )}
                                isMulti={true}
                                onChange={(e: any) =>
                                  setFieldValue("conditions", addValueToCondition(values?.conditions, "industry", e))
                                }
                                options={
                                  loadingIndustryOptions
                                    ? [{ label: "Loading...", value: "", disabled: true }]
                                    : industryOptions
                                }
                                isOptionDisabled={(option: any) => option.disabled}
                                showCheckbox
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                marginBottom={false}
                                isClearable={false}
                                multiValueUppercase
                                menuPortal
                                menuShouldBlockScroll
                              />
                              <div>
                                <AppText>Sub Industry</AppText>
                                <PhoenixMultiSelectField
                                  name="sub-industry"
                                  value={values?.conditions
                                    ?.find((ele) => ele?.conditionType === "subIndustry")
                                    ?.value?.map((ele: any) => (!ele?.value ? { label: ele, value: ele } : ele))}
                                  isMulti={true}
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      "conditions",
                                      addValueToCondition(values?.conditions, "subIndustry", e),
                                    )
                                  }
                                  options={
                                    loadingIndustryOptions
                                      ? [{ label: "Loading...", value: "", disabled: true }]
                                      : availableSubIndustryOptions
                                  }
                                  isOptionDisabled={(option: any) => option.disabled}
                                  marginBottom={false}
                                  isClearable={false}
                                  showCheckbox
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  multiValueUppercase
                                  menuPortal
                                  menuShouldBlockScroll
                                />
                              </div>
                            </>
                          )}
                        </InclusionCriteria>

                        {/* Person Spoken to */}
                        <InclusionCriteria checked={isPersonSpokeToChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isPersonSpokeToChecked}
                              onChange={() =>
                                values.person_spoke_to !== null
                                  ? setFieldValue("person_spoke_to", null)
                                  : setFieldValue("person_spoke_to", [])
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              Person Spoken to
                            </AppText>
                          </FlexDiv>

                          {isPersonSpokeToChecked && (
                            <PhoenixMultiSelectField
                              name="person_spoke_to"
                              value={values?.person_spoke_to}
                              isMulti={true}
                              onChange={(e: any) => setFieldValue("person_spoke_to", e)}
                              options={personSpokenToOptions}
                              marginBottom={false}
                              isClearable={false}
                              showCheckbox
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              multiValueUppercase
                              menuPortal
                              menuShouldBlockScroll
                            />
                          )}
                        </InclusionCriteria>

                        {/* Channel */}
                        <InclusionCriteria checked={isChannelChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isChannelChecked}
                              onChange={() =>
                                values.channels !== null
                                  ? setFieldValue("channels", null)
                                  : setFieldValue("channels", [])
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              Channel
                            </AppText>
                          </FlexDiv>

                          {isChannelChecked && (
                            <PhoenixMultiSelectField
                              name="channel"
                              value={values?.channels}
                              isMulti={true}
                              onChange={(e: any) => setFieldValue("channels", e)}
                              options={channelOptions}
                              marginBottom={false}
                              isClearable={false}
                              showCheckbox
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              multiValueUppercase
                              menuPortal
                              menuShouldBlockScroll
                            />
                          )}
                        </InclusionCriteria>

                        {/* Lead Source */}
                        <InclusionCriteria checked={isLeadSourceChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isLeadSourceChecked}
                              onChange={() =>
                                setFieldValue(
                                  "conditions",
                                  handleCriteriaCheck(values?.conditions, "leadSource", isLeadSourceChecked),
                                )
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              Lead Source
                            </AppText>
                          </FlexDiv>

                          {isLeadSourceChecked && (
                            <PhoenixMultiSelectField
                              name="leadSource"
                              value={values?.conditions
                                ?.find((ele) => ele?.conditionType === "leadSource")
                                ?.value?.map((ele: any) =>
                                  !ele?.value ? leadSourceOptions?.find((opt: any) => opt.value === ele) : ele,
                                )}
                              isMulti={true}
                              onChange={(e: any) =>
                                setFieldValue("conditions", addValueToCondition(values?.conditions, "leadSource", e))
                              }
                              options={
                                loadingLeadSourceOptions
                                  ? [{ label: "Loading...", value: "", disabled: true }]
                                  : leadSourceOptions
                              }
                              isOptionDisabled={(option: any) => option.disabled}
                              marginBottom={false}
                              isClearable={false}
                              showCheckbox
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              multiValueUppercase
                              menuPortal
                              menuShouldBlockScroll
                            />
                          )}
                        </InclusionCriteria>

                        {/* Region */}
                        <InclusionCriteria checked={isRegionChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isRegionChecked}
                              onChange={() =>
                                setFieldValue(
                                  "conditions",
                                  handleCriteriaCheck(values?.conditions, "region", isRegionChecked),
                                )
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              State / Province
                            </AppText>
                          </FlexDiv>

                          {isRegionChecked && (
                            <PhoenixMultiSelectField
                              name="region"
                              value={values?.conditions
                                ?.find((ele) => ele?.conditionType === "region")
                                ?.value?.map((ele: any) =>
                                  !ele?.value ? allRegions?.find((opt: any) => opt.value === ele) : ele,
                                )}
                              isMulti={true}
                              onChange={(e: any) =>
                                setFieldValue("conditions", addValueToCondition(values?.conditions, "region", e))
                              }
                              options={allRegions}
                              marginBottom={false}
                              isClearable={false}
                              showCheckbox
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              multiValueUppercase
                              menuPortal
                              menuShouldBlockScroll
                              isOptionDisabled={(option: any) => option.disabled}
                            />
                          )}
                        </InclusionCriteria>

                        {/* Custom Filters */}
                        <InclusionCriteria checked={isCustomFieldChecked}>
                          <FlexDiv align="center">
                            <PhoenixCheckbox
                              checked={isCustomFieldChecked}
                              onChange={() =>
                                values.custom_fields !== null
                                  ? setFieldValue("custom_fields", null)
                                  : setFieldValue("custom_fields", [{}])
                              }
                            />
                            <AppText fontSize={12} fontWeight={500} style={{ lineHeight: "18px" }}>
                              Custom Filters
                            </AppText>
                          </FlexDiv>
                          {isCustomFieldChecked &&
                            !!customFieldOptions &&
                            values?.custom_fields?.map((customField: any, i: number) => (
                              <CustomFieldContainer>
                                <div style={{ width: "100%", marginBottom: "24px" }}>
                                  <FlexDiv align="center">
                                    <AppText fontSize={14} color={theme.NEUTRAL400}>
                                      Custom Field {i + 1}
                                    </AppText>
                                    {i !== 0 && (
                                      <XIcon
                                        svg={xIcon}
                                        size={18}
                                        pointer
                                        onClick={() => {
                                          const splicedFields = [...(values.custom_fields as [])];
                                          const removedField = splicedFields.splice(i, 1)[0];
                                          console.log("removed custom_field", removedField);
                                          setFieldValue("custom_fields", splicedFields);
                                        }}
                                      />
                                    )}
                                  </FlexDiv>
                                  <PhoenixMultiSelectField
                                    name="customfield"
                                    value={{
                                      label: customFieldOptions?.find(
                                        (option: any) => option?.label === values?.custom_fields?.[i]?.field,
                                      )?.label,
                                      value: customFieldOptions?.find(
                                        (option: any) => option?.label === values?.custom_fields?.[i]?.field,
                                      )?.value,
                                    }}
                                    isMulti={false}
                                    marginBottom={false}
                                    isClearable={false}
                                    onChange={(e: any) => {
                                      const newVal: any = cloneDeep(values?.custom_fields);
                                      const customFieldEle: any = {};
                                      customFieldEle.field = e.label;
                                      customFieldEle.customFieldType = e.type;
                                      customFieldEle.value = [];
                                      customFieldEle.operator = "In";
                                      newVal[i] = customFieldEle;
                                      setFieldValue("custom_fields", newVal);
                                    }}
                                    options={customFieldOptions?.filter(
                                      (option: any) =>
                                        !values?.custom_fields?.find((cf: any) => cf?.field === option?.value),
                                    )}
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <PhoenixMultiSelectField
                                    name={`customfield-options-${i}`}
                                    value={values?.custom_fields?.[i]?.value?.map((ele: any) => ({
                                      value: ele?.value || ele,
                                      label: ele?.label || ele,
                                    }))}
                                    isMulti={values?.custom_fields?.[i]?.customFieldType === "MultiDropdown"}
                                    multiValueUppercase={
                                      values?.custom_fields?.[i]?.customFieldType === "MultiDropdown"
                                    }
                                    marginBottom={false}
                                    isClearable={false}
                                    onChange={(e: any) => {
                                      const newVal = cloneDeep(values?.custom_fields);
                                      const isMulti = values?.custom_fields?.[i]?.customFieldType === "MultiDropdown";

                                      if (!!newVal) newVal[i].value = isMulti ? e : [e?.value];

                                      setFieldValue("custom_fields", newVal);
                                    }}
                                    options={
                                      values?.custom_fields?.[i]?.field
                                        ? values?.custom_fields?.[i]?.customFieldType === "Boolean"
                                          ? [
                                              {
                                                label: "true",
                                                value: "true",
                                              },
                                              {
                                                label: "false",
                                                value: "false",
                                              },
                                            ]
                                          : allCustomFieldOptions?.fetchCustomFields
                                              ?.find((ele: any) => ele.key === values?.custom_fields?.[i]?.field)
                                              ?.options?.map((value: string) => ({
                                                label: value,
                                                value: value,
                                              }))
                                        : []
                                    }
                                  />
                                </div>
                              </CustomFieldContainer>
                            ))}

                          {isCustomFieldChecked && (
                            <FlexDiv
                              gap={8}
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() => setFieldValue("custom_fields", [...(values.custom_fields as []), {}])}
                            >
                              <PhoenixIcon svg={plus} pointer size="small" />
                              <AppText fontSize={12} fontWeight={500} variant="primary">
                                Add Custom Filter
                              </AppText>
                            </FlexDiv>
                          )}
                        </InclusionCriteria>
                      </InclusionCriteriaList>
                    ) : (
                      <FlexDiv
                        gap={8}
                        align="center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowInclusionCriteria(true);
                          fetchFilteredCallResultDispositions({
                            variables: {
                              phase: Array.isArray(values?.entry_phases?.[0])
                                ? values?.entry_phases?.[0].flat()?.[0]
                                : values?.entry_phases?.[0],
                              coldCallAggregate:
                                !!(values?.entry_phases?.length > 1) || Array.isArray(values?.entry_phases?.[0]), // cold call aggregate if greater than 1
                            },
                          });
                          fetchIndustryOptions();
                          fetchLeadSourceOptions();
                        }}
                      >
                        <PhoenixIcon svg={plus} pointer size="small" />
                        <AppText fontSize={12} fontWeight={500} variant="primary">
                          Add Criteria
                        </AppText>
                      </FlexDiv>
                    )}
                  </CriteriaContainer>
                </ModalBody>

                <ModalFooter justify="space-between">
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    buttonTextFontSize={10}
                    uppercase
                    onClick={() => setShowSequenceCriteriaModal(false)}
                  >
                    Cancel
                  </PhoenixAppButton>

                  <FlexDiv gap={8}>
                    {!sequenceData?.sequence_entry_criteria_id && (
                      <PhoenixAppButton
                        buttonType="secondary"
                        variant="brand-outline"
                        buttonTextFontSize={10}
                        uppercase
                        onClick={() => {
                          setForceSkipEntry(true);
                          setShowSequenceCriteriaModal(false);
                        }}
                      >
                        Skip for now
                      </PhoenixAppButton>
                    )}
                    <PhoenixAppButton
                      buttonType="secondary"
                      variant="brand"
                      buttonTextFontSize={10}
                      uppercase
                      disabled={isDisabled}
                      onClick={submitForm}
                      data-tip="Sequence must be paused to edit entry and inclusion criteria."
                      data-for="finished-button-disabled"
                    >
                      Finish
                    </PhoenixAppButton>
                  </FlexDiv>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
        {isPaused && <PhoenixStyledTooltip place="left" id="finished-button-disabled" fontSize={10} padding={8} />}
      </ModalContainer>
    </>
  );
};

const slideIn = keyframes`
  0% {
    width: 0px;
  }
  100% {
    width: 456px;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled(FlexDiv)`
  position: fixed;
  z-index: 899;
  top: 0;
  right: 0;

  width: 456px;
  height: 100vh;

  background-color: ${theme.WHITE_COLOR};

  animation: ${slideIn} 0.4s ease forwards;
  & > * {
    animation: ${fadeIn} 0.75s ease forwards;
  }
`;

const ModalHeader = styled(FlexDiv)`
  padding: 8px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalBody = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;

  max-height: 80vh;
  min-height: 70vh;
  margin-bottom: auto;

  overflow-y: overlay;
`;

const ModalFooter = styled(FlexDiv)`
  width: 100%;
  padding: 16px 40px 40px 40px;
`;

const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  padding: 24px 0px 40px 0px;
`;

const CriteriaTitle = styled(AppText)`
  font-size: 14px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
`;
const CriteriaSubText = styled(AppText)`
  font-size: 10px;
  color: ${theme.NEUTRAL300};
  line-height: 14px;
`;

interface InclusionCriteriaProps {
  checked: boolean;
}

const InclusionCriteria = styled.div<InclusionCriteriaProps>`
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 100%;
  padding: 16px;

  border: 1px solid;
  border-radius: 8px;
  border-color: ${(props) => (props.checked ? theme.PRIMARY500 : theme.NEUTRAL300)};

  transition: border-color 0.15s ease;
  animation: ${fadeIn} 0.4s forwards;
  opacity: 0;
`;

const InclusionCriteriaList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ${InclusionCriteria}:nth-child(1) {
    animation-delay: 0s;
  }
  ${InclusionCriteria}:nth-child(2) {
    animation-delay: 0.05s;
  }
  ${InclusionCriteria}:nth-child(3) {
    animation-delay: 0.1s;
  }
  ${InclusionCriteria}:nth-child(4) {
    animation-delay: 0.15s;
  }
  ${InclusionCriteria}:nth-child(5) {
    animation-delay: 0.2s;
  }
  ${InclusionCriteria}:nth-child(6) {
    animation-delay: 0.25s;
  }
  ${InclusionCriteria}:nth-child(7) {
    animation-delay: 0.3s;
  }
`;

const CustomFieldContainer = styled.div``;

const XIcon = styled(PhoenixIcon)`
  margin-left: auto;

  & svg path,
  & svg rect {
    stroke: ${theme.NEUTRAL300};
    transition: stroke 0.2s ease;
  }
`;
