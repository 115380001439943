import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import gql from "graphql-tag";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { ModalContext } from "../../context";
import { CallContext } from "../../context/CallContext";
import { theme } from "../../utils/theme";
import { notificationToast } from "../../utils/toast";
import { AppText } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";

const SwapPrimaryLeadModal: React.FC = () => {
  const { showSwapPrimaryLeadModal, setShowSwapPrimaryLeadModal, swapPrimaryLeadModalData } = useContext(ModalContext);

  const { setNextActionOverride, resetNextDial, setDialAsNumber } = useContext(CallContext);

  const MAKE_LEAD_PRIMARY = gql`
    mutation MakePrimary($primary_lead_id: String!, $associate_lead_id: String!) {
      makePrimary(primary_lead_id: $primary_lead_id, associate_lead_id: $associate_lead_id) {
        message
        status
        status_boolean
      }
    }
  `;

  const [makeLeadPrimary, { loading: makeLeadPrimaryLoading, error: makeLeadPrimaryError }] = useMutation(
    MAKE_LEAD_PRIMARY,
    {
      onCompleted({ makeLeadPrimary }) {
        console.log("makeLeadPrimary: ", makeLeadPrimary);

        resetNextDial();

        setNextActionOverride({
          intent_object: {
            lead: {
              id: swapPrimaryLeadModalData?.associate_lead_id ?? "",
              primary_phone_number: swapPrimaryLeadModalData?.associate_primary_phone_number ?? "",
            },
          },
          parent: undefined,
        });
        setShowSwapPrimaryLeadModal(false);
        setDialAsNumber("");
      },
      onError({ message }) {
        notificationToast({
          id: message,
          message: message,
          variant: "primary",
        });
        Sentry.captureEvent({
          message: `Error making lead primary GraphQL Error: ${message}`,
        });

        console.log("Error making lead primary: ", message);
      },
      refetchQueries: [
        "fetchLead",
        "FetchAssociateContact",
        "fetchNextDial",
        "fetchLeadHistory",
        "fetchFavoritedLeads",
        "fetchCustomQueueLeads",
        "fetchFilteredLeadsInQueueV2",
        "fetchRecommendedDial",
        "fetchAssociateParent",
      ],
    },
  );

  return (
    <Modal open={showSwapPrimaryLeadModal} onClose={() => setShowSwapPrimaryLeadModal(false)}>
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Change Primary Contact</TitleText>
        </TitleDiv>

        <FlexDiv>
          <FlexInnerDiv>
            <AppText fontSize={14} style={{ fontWeight: 400, textAlign: "center" }} fontWeight={400}>
              Are you sure you want to change the primary contact person for this lead?
            </AppText>
          </FlexInnerDiv>
        </FlexDiv>
        <SubmitDiv>
          <div
            style={{
              width: "118px",
            }}
          >
            <PhoenixAppButton
              variant="danger-outline"
              buttonType="secondary"
              style={{ textTransform: "capitalize", fontWeight: 600 }}
              buttonTextFontSize={10}
              onClick={() => {
                setShowSwapPrimaryLeadModal(false);
              }}
            >
              no, cancel
            </PhoenixAppButton>
          </div>
          <div
            style={{
              width: "138px",
            }}
          >
            <PhoenixAppButton
              onClick={async () => {
                await makeLeadPrimary({
                  variables: {
                    primary_lead_id: swapPrimaryLeadModalData?.primary_lead_id,
                    associate_lead_id: swapPrimaryLeadModalData?.associate_lead_id,
                  },
                });
              }}
              variant="brand"
              buttonType="secondary"
              style={{ textTransform: "capitalize", fontWeight: 600 }}
              buttonTextFontSize={10}
            >
              yes, continue
            </PhoenixAppButton>
          </div>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 16px 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
`;

const FlexInnerDiv = styled.div`
  height: 211px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

export { SwapPrimaryLeadModal };
