import { Cloudinary } from "@cloudinary/url-gen";
import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET } from "../utils/variables";

export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: CLOUDINARY_CLOUD_NAME,
  },
});

export const openUploadWidget = async (callback: (error: any, res: any) => void) => {
  if (window.cloudinary) {
    const options = {
      // cloudinary cloud name where we want to upload the image (like a bucket)
      cloudName: CLOUDINARY_CLOUD_NAME,
      // upload preset that we want to use for this upload these are settings for how we want the upload to work set in cloudinary
      uploadPreset: CLOUDINARY_UPLOAD_PRESET,
      // allow user to crop image in the widget (upload preset must be crop custom and gravity set to custom)
      cropping: true,
      // show back button
      croppingShowBackButton: true,
      // you can't crop multiple images and we don't support multiple images for a profile picture
      multiple: false,
    };

    window.cloudinary.openUploadWidget(options, callback);
  }
};
