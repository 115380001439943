import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { NotificationsContext, CallContext } from "../../../context";
import { bell, flame } from "../../../images/NewDesign";
import { sanitizeURL } from "../../../utils/misc";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { NotificationDetailsLink } from "../../Pages/Notifications";
import { AppErrorText, AppSidebarCard, AppText, FlexDiv, Loading, SkeletonBlock } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { PhoenixIcon } from "../../UI/Phoenix/PhoenixIcon";
import { LeadCardContactButton } from "../DashboardSideBarSegments/LeadCardV2";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { HotNotification } from "./HotNotification";
import { useFlags } from "launchdarkly-react-client-sdk";

export interface NotificationType {
  id: string;
  lead_id: string;
  text_computed?: string;
  details?: string;
  read?: boolean;
  payload?: any;
  send_action_back_to_server?: boolean;
  type: string;
  updated_at?: any;
  created_at?: any;
  relevant_id?: string;
  call_to_action_read?: boolean;
  text_action?: string;
  date_string?: string;
  type_label?: string;
  salesperson?: string;
  voicemail_url?: string;
  voicemail_url_computed?: string;
  // can the user dial this number from the notification header
  can_dial?: boolean;
  // number used to call rep
  phone_number?: string;
}

export const PERFORM_NOTIFICATION_ACTION = gql`
  mutation performNotificationAction(
    $notification_id: String!
    $relevant_id: String!
    $type: NotificationType!
    $accept: Boolean
  ) {
    performNotificationAction(
      notification_id: $notification_id
      relevant_id: $relevant_id
      type: $type
      accept: $accept
    ) {
      id
      text
      details
      read
      payload
      date_string
      created_at
      call_to_action_read
      text_action
      relevant_id
      send_action_back_to_server
      type
    }
  }
`;

interface CreateButtonProps {
  item: NotificationType;
  refetch: any;
  goToCall: (params: { lead_id: string; phoneNumber: string }) => void;
  closeHeaderDropdown: () => void;
}

const CreateActionButtons: React.FC<CreateButtonProps> = ({ item, refetch, goToCall, closeHeaderDropdown }) => {
  const [performNotificationAction, { loading, error }] = useMutation(PERFORM_NOTIFICATION_ACTION, {
    async onCompleted({ performNotificationAction }) {
      console.log("performNotificationAction: ", performNotificationAction);
      await refetch();
      if (!performNotificationAction) {
        appToast("Error performing action. Something went wrong.");
        return;
      }
      appToast("Action performed created!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `performNotificationAction GraphQL Error: ${message}`,
      });
      console.log("Error in performNotificationAction: ", message);
    },
  });

  const history = useHistory();

  if (loading)
    return (
      <OneButtonDiv>
        <Loading />;
      </OneButtonDiv>
    );
  if (error)
    return (
      <OneButtonDiv>
        <AppErrorText></AppErrorText>
      </OneButtonDiv>
    );

  switch (item?.type) {
    case "TimeOffRequest":
      return (
        <TwoButtonsDiv>
          <NotificationButton
            variant={"confirm"}
            onClick={async () => {
              await performNotificationAction({
                variables: {
                  notification_id: item?.id,
                  relevant_id: item?.relevant_id,
                  type: item?.type,
                  accept: true,
                },
              });
            }}
          >
            Accept
          </NotificationButton>
          <NotificationButton
            variant={"attention"}
            onClick={async () => {
              await performNotificationAction({
                variables: {
                  notification_id: item?.id,
                  relevant_id: item?.relevant_id,
                  type: item?.type,
                  accept: false,
                },
              });
            }}
          >
            Decline
          </NotificationButton>
        </TwoButtonsDiv>
      );

    case "CallReportReviewed":
    case "CallReportReviewedFeedback":
      return (
        <OneButtonDiv>
          <NotificationButton
            variant={"attention"}
            // color={theme.ATTENTION700}
            onClick={() => {
              const santizedURL = sanitizeURL(`/call-report/${item?.relevant_id}`);
              history.push(santizedURL);
            }}
          >
            Go to Call Report
          </NotificationButton>
        </OneButtonDiv>
      );

    case "MissedCall":
      return !!item?.voicemail_url_computed || !!item?.can_dial ? (
        <TwoButtonDiv>
          {!!item?.voicemail_url_computed ? (
            <a target="_blank" href={item?.voicemail_url_computed}>
              <PhoenixAppButton variant="brand-outline" buttonType="secondary" height={32}>
                Listen
              </PhoenixAppButton>
            </a>
          ) : (
            <div />
          )}
          {!!item?.can_dial ? (
            <LeadCardContactButton
              type={"phone"}
              disabled={false}
              onClick={() => {
                const numberUsedToCallRep = item?.phone_number;
                const leadId = item?.relevant_id ?? item?.lead_id;

                if (!leadId || !numberUsedToCallRep) {
                  appToast("Something went wrong.");
                  return;
                }

                // close header dropdown/modal
                closeHeaderDropdown && closeHeaderDropdown();

                // go to call with notification number
                goToCall({
                  lead_id: leadId,
                  phoneNumber: numberUsedToCallRep,
                });
              }}
            />
          ) : (
            <div />
          )}
        </TwoButtonDiv>
      ) : (
        <></>
      );
    case "EmailRecieved":
      return (
        <OneButtonDiv>
          <NotificationButton
            variant={"attention"}
            onClick={() => {
              const santizedURL = sanitizeURL(`/lead-detail/${item?.lead_id}?tab=emails&${item?.relevant_id}`);
              history.push(santizedURL, { email_id: item?.relevant_id });
            }}
          >
            View Email
          </NotificationButton>
        </OneButtonDiv>
      );
    case "ExportComplete":
      return (
        <OneButtonDiv>
          <NotificationButton
            variant={"attention"}
            onClick={() => {
              history.push({
                pathname: `/lead-detail/${item?.lead_id}`,
                search: `?tab=emails&${item?.relevant_id}`,
                state: { email_id: item?.relevant_id },
              });
            }}
          >
            View Details
          </NotificationButton>
        </OneButtonDiv>
      );
    case "AddContactRequest":
    case "EditContactRequest":
    case "DeleteContactRequest":
      return (
        <OneButtonDiv>
          <NotificationButton
            // color={theme.ATTENTION700}
            variant={"attention"}
            onClick={async () => {
              await performNotificationAction({
                variables: {
                  notification_id: item?.id,
                  relevant_id: item?.relevant_id,
                  type: item?.type,
                },
              });
            }}
          >
            Undo
          </NotificationButton>
        </OneButtonDiv>
      );
    default:
      return <></>;
  }
};

const NotificationsLoaderComponent: React.FC = () => {
  return <SkeletonBlock width={"100%"} height={400} borderRadius={6} />;
};

interface HeaderNotificationsProps {
  dropdownOpen: string;
  setDropdownOpen: (dropdownOpen: string) => void;
}

const HeaderNotificationsComponent: React.FC<HeaderNotificationsProps> = ({ dropdownOpen, setDropdownOpen }) => {
  const {
    descendingOnlySortedNotifications,
    fetchNotificationsLoading,
    fetchNotificationsError,
    fetchNotifications,
    handleUpdateOneNotification,
    handleDeleteOneNotification,
  } = useContext(NotificationsContext);

  const { goToCall } = useContext(CallContext);
  const { hotNotification } = useFlags();

  return (
    <div
      style={{ width: "fit-content" }}
      onClick={() => setDropdownOpen(dropdownOpen === "notifications" ? "" : "notifications")}
    >
      <PhoenixIcon svg={bell} variant="white" pointer={true} />

      {dropdownOpen === "notifications" && (
        <NotificationsContent onClick={() => setDropdownOpen(dropdownOpen === "notifications" ? "" : "notifications")}>
          <AppSidebarCard width={350} title="My Notifications" popupAnimation>
            <ScrollingDiv onClick={(e) => e.stopPropagation()}>
              <div>
                {fetchNotificationsLoading ? (
                  <NotificationsLoaderComponent />
                ) : fetchNotificationsError ? (
                  <AppErrorText>Error Loading Notifications</AppErrorText>
                ) : (
                  <AllNotifications>
                    {descendingOnlySortedNotifications && descendingOnlySortedNotifications.length > 0
                      ? descendingOnlySortedNotifications?.slice()?.map((item: NotificationType, index: number) =>
                          item?.type === "HotLead" && !!hotNotification ? (
                            <HotNotification key={item?.id} data={item} />
                          ) : (
                            <div key={item?.id}>
                              <NotificationGrid key={item?.id}>
                                <FlexToSides>
                                  <NotificationTime>
                                    {moment(item?.created_at).format("MM/DD/YYYY hh:mm A")}
                                  </NotificationTime>
                                  <ClearNotificationText
                                    onClick={async () => {
                                      if (item?.read) {
                                        await handleDeleteOneNotification(item);
                                      } else {
                                        await handleUpdateOneNotification(item);
                                      }
                                    }}
                                  >
                                    {item?.read ? "Clear" : "Mark as Read"}
                                  </ClearNotificationText>
                                </FlexToSides>
                                <NotificationTitle
                                  dot={
                                    !!item?.relevant_id && !item?.call_to_action_read
                                      ? "blue"
                                      : !item?.read
                                      ? "orange"
                                      : ""
                                  }
                                >
                                  {item?.text_computed}
                                </NotificationTitle>
                                <NotificationSubTitle>
                                  <NotificationDetailsLink item={item} />
                                </NotificationSubTitle>

                                {!!item?.relevant_id &&
                                  (!!item?.text_action ? (
                                    <ActionText>{item?.text_action}</ActionText>
                                  ) : (
                                    <CreateActionButtons
                                      item={item}
                                      refetch={fetchNotifications}
                                      goToCall={goToCall}
                                      closeHeaderDropdown={() => {
                                        // not sure why FE needs to have modal show hide as a string
                                        // todo: refactor this system to use a boolean
                                        setDropdownOpen("");
                                      }}
                                    />
                                  ))}
                              </NotificationGrid>
                            </div>
                          ),
                        )
                      : "No notifications"}
                  </AllNotifications>
                )}
              </div>
            </ScrollingDiv>
            <SearchDiv>
              <Link to="/notifications/">
                <ViewAllButton variant={"primary"}>View All</ViewAllButton>
              </Link>
            </SearchDiv>
          </AppSidebarCard>
        </NotificationsContent>
      )}
    </div>
  );
};

const SearchDiv = styled.div`
  margin: 0px;
  padding: 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const FlexToSides = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  width: 300px;
`;

const TwoButtonsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
`;

const OneButtonDiv = styled.div`
  width: 100%;
`;

const NotificationButton = styled(NewAppButton)`
  height: 32px;
`;

const ClearNotificationText = styled(AppText)`
  border-bottom: none;
  float: right;

  :hover {
    text-decoration: underline;
    cursor: pointer;
    /* opacity: 1; */
  }
`;

const NotificationGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-row-gap: 3px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  padding: 16px 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const NotificationSubTitle = styled(AppText)`
  margin-bottom: 8px;
`;

interface UnreadProps {
  dot?: string;
}

const ActionText = styled(NotificationSubTitle)`
  font-style: italic;
  opacity: 0.5;
  user-select: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
`;

const NotificationTime = styled(AppText)`
  font-weight: 600;
  color: ${theme.NEUTRAL400};
`;

const NotificationTitle = styled(AppText)<UnreadProps>`
  position: relative;
  font-weight: 600;

  text-overflow: ellipsis;

  ::before {
    display: block;
    content: "";
    position: absolute;
    top: 7px;
    left: -10px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.dot === "blue" ? theme.PRIMARY500 : props.dot === "orange" ? theme.TERTIARY500 : "transparent"};
    z-index: 3;
  }
`;

const ScrollingDiv = styled.div`
  max-height: calc(346px);
  height: fit-content;
  height: 100%;
  overflow: auto;
`;

const ViewAllButton = styled(NewAppButton)``;

const AllNotifications = styled.div`
  padding-bottom: 15px;
  margin-top: 10px;
`;

const NotificationsContent = styled.div`
  position: fixed;
  bottom: calc(50% - 212px);
  left: 96px;

  background: transparent;
  border-radius: 4px;
  padding: 0px 0px;
  cursor: default;
  pointer-events: all;
  color: ${theme.BLACK_COLOR};
`;

const TwoButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export { HeaderNotificationsComponent };
