import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";

interface PhoenixProgressBar {
  style?: React.CSSProperties;

  // runs when progress bar is full
  onComplete?: () => void;

  // Default light
  variant?: "light" | "dark";

  // represents the current progress bar step
  step: number;
  // steps to reach 100% full
  maxStep: number;

  /* width/height of progress bar container. 
     Number is converted to pixels, string can be any unit. */
  width?: number | string; // Default 100%
  height?: number | string; // Default 8px

  // overrides
  borderRadius?: number;
  color?: string;
}

export const PhoenixProgressBar: React.FC<PhoenixProgressBar> = ({ ...props }) => {
  useEffect(() => {
    if (props.step === props.maxStep && props.onComplete !== undefined) props.onComplete();
  }, [props.step]);

  return (
    <BarContainer {...props}>
      <ProgressBar
        containerWidth={props.width}
        step={props.step}
        maxStep={props.maxStep}
        variant={props.variant}
        borderRadius={props.borderRadius}
        color={props.color}
      />
    </BarContainer>
  );
};

const BarContainer = styled.div<PhoenixProgressBar>`
  width: ${(props) => {
    if (!props.width) {
      return "100%";
    } else if (typeof props.width === "string") {
      return props.width;
    } else {
      return `${props.width}px`;
    }
  }};
  height: ${(props) => {
    if (!props.height) {
      return "8px";
    } else if (typeof props.height === "string") {
      return props.height;
    } else {
      return `${props.height}px`;
    }
  }};
  min-height: ${(props) => {
    if (!props.height) {
      return "8px";
    } else if (typeof props.height === "string") {
      return props.height;
    } else {
      return `${props.height}px`;
    }
  }};

  background-color: ${(props) => {
    if (!props.variant) {
      return theme.NEUTRAL200;
    } else {
      switch (props.variant) {
        case "light":
          return theme.NEUTRAL200;
        case "dark":
          return theme.PRIMARY450;
        default:
          return theme.NEUTRAL200;
      }
    }
  }};
  border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "25px")};
  overflow: hidden;
`;

interface ProgressBarProps {
  containerWidth: any;
  step: number;
  maxStep: number;
  borderRadius?: number;
  variant?: "light" | "dark";
  color?: string;
}

const ProgressBar = styled.div<ProgressBarProps>`
  transition: width 0.2s ease;

  width: ${(props) => `calc((100% / ${props.maxStep}) * ${props.step})`};
  height: 100%;

  background-color: ${(props) => {
    if (props.color) {
      return props.color;
    }

    if (!props.variant) {
      return theme.PRIMARY500;
    } else {
      switch (props.variant) {
        case "light":
          return theme.PRIMARY500;
        case "dark":
          return theme.WHITE_COLOR;
        default:
          return theme.PRIMARY500;
      }
    }
  }};
  border-radius: inherit;
`;
