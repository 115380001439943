import styled from "styled-components";
import React from "react";
import { Loading } from "../UI";
import { theme } from "../../utils/theme";
import { ApolloError, gql, QueryLazyOptions, useLazyQuery } from "@apollo/client";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CurrentLeadType, SelectedContactType } from "../../types";
import { SearchItem } from ".";
import { NewAppButton } from "../UI/NewAppButton";
import { CORE_LEAD_CARD_DATA } from "../../apollo/fragments";

interface SearchListProps {
  isSearchDashboard?: boolean;
  setNonScheduledCallBack: Dispatch<SetStateAction<boolean>>;

  setSelectedContact: (selectedContact: SelectedContactType) => void;
  onCancelClick?: (key?: any) => any;

  filteredLeads?: CurrentLeadType[];
  loading?: boolean;
  error?: ApolloError;
  disableOutsideEvent: boolean;
  searchSortValue?: boolean;
  onClickEvfetent?: (key?: any) => any;
  getLead?: (options?: QueryLazyOptions<Record<string, any>> | undefined) => void;
}

const SearchList: React.FC<SearchListProps> = ({
  setNonScheduledCallBack,

  setSelectedContact,
  loading,
  error,
  filteredLeads,
  disableOutsideEvent,
  searchSortValue,
  onCancelClick,
  getLead,
  isSearchDashboard,
}) => {
  if (loading) {
    return (
      <div style={{ paddingTop: "35px" }}>
        <Loading />
      </div>
    );
  }

  const results = filteredLeads ?? [];

  if (error) return <p>Error loading list</p>;
  return (
    <SearchListDiv dashboard={!!isSearchDashboard}>
      {isSearchDashboard && (
        <BackgroundDiv onClick={() => (onCancelClick ? onCancelClick() : setNonScheduledCallBack(false))} />
      )}
      <GridDiv>
        {results?.map((lead: CurrentLeadType) => {
          return (
            <SearchItem
              key={lead?.id}
              setSelectedContact={setSelectedContact}
              data={lead}
              disableOutsideEvent={disableOutsideEvent}
              getLead={getLead}
              isSearchDashboard={!!isSearchDashboard}
            />
          );
        })}
      </GridDiv>
      <CancelButton
        variant={"attention"}
        style={{ zIndex: isSearchDashboard ? 4 : 0 }}
        onClick={() => (onCancelClick ? onCancelClick() : setNonScheduledCallBack(false))}
      >
        Cancel
      </CancelButton>
    </SearchListDiv>
  );
};

export { SearchList };

const CancelButton = styled(NewAppButton)`
  width: 186px;
  margin-top: 18px;
`;

const GridDiv = styled.div`
  display: grid;
  grid-gap: 18px;
  padding-left: 32px;
`;

interface Dashboard {
  dashboard: boolean;
}

const SearchListDiv = styled.div<Dashboard>`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: ${(props) => (props.dashboard ? `` : `solid 4px ${theme.NEUTRAL100}`)};
  padding: 17px 0px 70px 0px;
  padding-left: 28px;
  padding-right: 20px;
  z-index: 5;
  position: absolute;
  width: 420px;
  max-width: 440px;
`;

const BackgroundDiv = styled.div`
  background-color: white;
  opacity: 1;
  position: fixed;
  width: 552px;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 4;
`;
