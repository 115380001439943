import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "./AppText";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
type Order = "ASC" | "DES";

type Column = {
  label: string;
  onClick?: any;
  isAscending?: boolean;
  showArrow?: boolean;
  dataTip?: string;
  dataFor?: string;
  style?: any;
};
interface AppTableProps {
  columns: (Column | string)[];
  sticky?: boolean;
  height?: string;
  style?: any;
  headerStyle?: any;
  headerContainerZIndex?: number;
  dataTip?: string;
  dataFor?: string;
  id?: string;
  noTooltip?: boolean;
}

export const AppTable: React.FC<AppTableProps> = ({
  columns,
  sticky,
  height,
  children,
  headerStyle,
  headerContainerZIndex,
  dataTip,
  dataFor,
  id,
  noTooltip,
  ...rest
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [columns, children]);
  return (
    <>
      {!noTooltip && (
        <ReactTooltip
          multiline
          place="left"
          effect="solid"
          id={dataFor}
          css={{
            maxWidth: 600,
            lineHeight: 1.4,
            textAlign: "center",
            fontFamily: theme.PRIMARY_FONT,
          }}
          backgroundColor={theme.PRIMARY800}
          getContent={(dataTip) => (
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              {dataTip}
            </span>
          )}
        />
      )}
      <Table id={id}>
        <Head>
          <TableRow
            rowZIndex={headerContainerZIndex}
            style={{ position: headerContainerZIndex ? "relative" : undefined }}
          >
            {columns?.map((col: Column | string) =>
              typeof col === "string" ? (
                <TableTH
                  dataTip={dataTip}
                  dataFor={dataFor}
                  key={col}
                  label={col}
                  sticky={!!sticky}
                  headerStyle={headerStyle}
                />
              ) : (
                <TableTH
                  dataTip={col.dataTip}
                  dataFor={dataFor}
                  key={col.label}
                  sticky={!!sticky}
                  headerStyle={{ ...headerStyle, ...col.style }}
                  {...col}
                />
              ),
            )}
          </TableRow>
        </Head>
        <Body>{children}</Body>
      </Table>
    </>
  );
};

interface TableContainerProps {
  height?: string;
}

export const TableContainer = styled.div<TableContainerProps>`
  height: ${(props) => (props.height ? props.height : "initial")};
`;

export const Table = styled.table`
  position: relative;
  border-collapse: collapse;
  text-align: left;
  width: 100%;
`;

export const Head = styled.thead``;

interface HeadColProps {
  label: string;
  sticky: boolean;
  onClick?: any;
  isAscending?: boolean;
  showArrow?: boolean;
  headerStyle?: any;
  dataTip?: string;
  dataFor?: string;
}

export const TableTH: React.FC<HeadColProps> = ({
  label,
  sticky,
  onClick,
  headerStyle,
  isAscending,
  showArrow = true,
  dataTip,
  dataFor,
}) => {
  return (
    <HeadCol data-tip={dataTip} data-for={dataFor} sticky={sticky} onClick={onClick} style={headerStyle}>
      {label}
      {onClick !== undefined && isAscending && (
        <FaCaretUp
          size={16}
          color={theme.NEUTRAL300}
          opacity={showArrow ? 1 : 0}
          style={{ position: "relative", top: 3 }}
        />
      )}
      {onClick !== undefined && !isAscending && (
        <FaCaretDown
          size={16}
          color={theme.NEUTRAL300}
          opacity={showArrow ? 1 : 0}
          style={{ position: "relative", top: 3 }}
        />
      )}
    </HeadCol>
  );
};

const HeadCol = styled.th<{ sticky?: boolean }>`
  position: ${(props) => (props.sticky ? "-webkit-sticky" : "relative")};
  position: ${(props) => (props.sticky ? "sticky" : "relative")};
  top: 0;
  padding: 16px 0 16px 24px;
  font-weight: 600;
  font-size: 12px;
  color: ${theme.NEUTRAL300};
  background-color: ${theme.WHITE_COLOR};
  cursor: ${(props) => (Boolean(props.onClick) ? "pointer" : "auto")};
  /* white-space: pre-line; */
`;

export const Body = styled.tbody``;

export const TableRow = styled.tr<{ rowZIndex?: number }>`
  z-index: ${(props) => props.rowZIndex && props.rowZIndex};

  &:nth-child(even) {
    background-color: ${theme.WHITE_COLOR};
  }
  &:nth-child(odd) {
    background-color: ${theme.NEUTRAL100};
  }
  td:last-child,
  th:last-child {
    padding-right: 12px;
  }
`;

interface AppTableItemsProps {
  data: (number | string)[][];
}

export const AppTableItems: React.FC<AppTableItemsProps> = ({ data }) => {
  return (
    <>
      {data?.map((row, i) => (
        <TableRow key={`AppTable-row-${i}`}>
          <AppTableCell data={row} />
        </TableRow>
      ))}
    </>
  );
};

interface AppTableItemProps {
  data: (number | string)[];
}

export const AppTableCell: React.FC<AppTableItemProps> = ({ data }) => {
  return (
    <>
      {data?.map((item, i) => (
        <TableTD key={`${i}-${item}`}>
          <AppText>{item}</AppText>
        </TableTD>
      ))}
    </>
  );
};

export const TableTD = styled.td<{ fontSize?: string; fixedWidth?: string | number }>`
  /* padding: 16px 0 16px 24px; */
  padding: 0;
  padding-left: 24px;
  height: 44px;
  font-size: ${(props) => props.fontSize ?? "12px"};
  min-width: ${(props) =>
    props.fixedWidth ? (typeof props.fixedWidth === "number" ? `${props.fixedWidth}px` : props.fixedWidth) : "unset"};
  max-width: ${(props) =>
    props.fixedWidth ? (typeof props.fixedWidth === "number" ? `${props.fixedWidth}px` : props.fixedWidth) : "unset"};
`;
