import React from "react";
import { useField } from "formik";
import useCustomFieldDropdownOptions from "src/hooks/useCustomFieldDropdownOptions";
import { NewAppSelectProps, NewAppSelect } from "../UI/NewAppSelect";

interface NewSelectFieldProps extends NewAppSelectProps {
  cf_key?: string;
  name: string;
  onChangeSideEffect?: (e: any) => void;
}

const NewSelectField: React.FC<NewSelectFieldProps> = ({
  name,
  cf_key,
  onChangeSideEffect,
  value,
  options,
  ...rest
}) => {
  const [{ onChange, ...field }, meta, { setValue }] = useField(name);

  const { options: customOptions } = useCustomFieldDropdownOptions(cf_key);

  return (
    <NewAppSelect
      {...field}
      {...rest}
      options={customOptions?.length ? customOptions : options}
      onChange={(e) => {
        onChangeSideEffect && onChangeSideEffect(e);
        setValue(e?.value);
      }}
      error={meta.touched && meta.error ? meta.error : ""}
    />
  );
};

export { NewSelectField };
