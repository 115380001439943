import React, { useCallback, useMemo, useState, useEffect, useRef, memo, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { theme } from "src/utils/theme";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { AppText, SkeletonBlock } from "src/Components/UI";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { OPSIQFormsFormikProps } from "./OPSIQFormDetail";
import { CustomField, formatFormFields, FormFieldRow, generateRowsFromFields } from "src/utils/misc";
import useAvailableFields from "src/hooks/useAvailableFields";
import { plus, reorder, trash } from "src/images/NewDesign";
import FormsFieldList from "src/Components/Segments/FormsFieldList";

interface FieldSectionProps {
  allCustomFields: CustomField[];
  allSystemFields: string[];
  loading: boolean;
  setFieldValue: (field: string, value: any) => void;
  values: OPSIQFormsFormikProps;
  setSelectedFieldID: (id: string) => void;
}

export type OPSIQFormFieldsRef = {
  regenerateRows: (fields: any[]) => void;
};

export const OPSIQFormFields = forwardRef<OPSIQFormFieldsRef, FieldSectionProps>(
  ({ values, setFieldValue, loading, allSystemFields, allCustomFields, setSelectedFieldID }, ref) => {
    const fieldData = values.form_fields;
    const [rows, setRows] = useState<FormFieldRow[]>([]);
    const hasSetRows = useRef(false);

    const { availableCustomFields, availableSystemFields } = useAvailableFields({
      systemFields: allSystemFields,
      customFields: allCustomFields,
      values,
    });

    const fields = useMemo(() => {
      return formatFormFields(fieldData, allSystemFields, allCustomFields);
    }, [fieldData, allSystemFields, allCustomFields]);

    useEffect(() => {
      if (hasSetRows.current) return;
      const generatedRows = generateRowsFromFields(fields);
      setRows(generatedRows);
      hasSetRows.current = true;
    }, [fields]);

    useImperativeHandle(ref, () => ({
      regenerateRows: (f) => {
        const newRows = generateRowsFromFields(f);
        setRows(newRows);
      },
    }));

    const updateFormFields = useCallback((newRows: FormFieldRow[]) => {
      let order = 0;
      const flattenedFields = newRows.flatMap((row, rowIndex) =>
        row.fields?.map((field) => ({
          ...field,
          row: rowIndex,
          order: order++,
        })),
      );
      setRows(newRows);
      setFieldValue("form_fields", flattenedFields);
    }, []);

    const handleDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;
        const { source, destination, type } = result;
        if (type === "ROW") {
          const newRows = reorderRows(rows, source.index, destination.index);
          updateFormFields(newRows);
        } else if (type === "FIELD") {
          const newRows = moveField(rows, source, destination);
          updateFormFields(newRows);
        }
      },
      [rows],
    );

    const addField = () => {
      const newField = {
        id: uuidv4(),
        type: "SYSTEM_FIELD",
        required: false,
        hidden_field: false,
        custom_id: true,
        row: rows.length,
        order: rows.reduce((count, row) => count + row.fields.length, 0),
      };

      const newRow = formatFormFields([newField as any], allSystemFields, allCustomFields);
      const newRows = [...rows, { id: uuidv4(), fields: newRow }];
      updateFormFields(newRows);
    };

    const addEmptyRow = () => {
      const newRows = [...rows, { id: uuidv4(), fields: [] }];
      updateFormFields(newRows);
    };

    const deleteRow = (rowIndex: number) => {
      const newRows = rows.filter((_, index) => index !== rowIndex);
      updateFormFields(newRows);
    };

    const onFieldChange = (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => {
      const newRows = rows?.map((row) => ({
        ...row,
        fields: row.fields?.map((field) =>
          field.id === id
            ? {
                ...field,
                custom_field_id: radio === "custom" ? newOption.value : undefined,
                custom_field: radio === "custom" ? allCustomFields.find((cf) => cf.id === newOption.value) : undefined,
                system_field: radio === "system" ? newOption.value : undefined,
              }
            : field,
        ),
      }));
      updateFormFields(newRows);
    };

    const onFieldRadioChange = (id: string, newType: "system" | "custom") => {
      const newRows = rows?.map((row) => ({
        ...row,
        fields: row.fields?.map((field) =>
          field.id === id
            ? {
                ...field,
                custom_field_id: null,
                custom_field: null,
                system_field: null,
                type: newType === "system" ? "SYSTEM_FIELD" : "CUSTOM_FIELD",
                value: "",
              }
            : field,
        ),
      }));
      updateFormFields(newRows);
    };

    const onFieldRequiredChange = (id: string, newRequired: boolean) => {
      const newRows = rows?.map((row) => ({
        ...row,
        fields: row.fields?.map((field) => (field.id === id ? { ...field, required: newRequired } : field)),
      }));
      updateFormFields(newRows);
    };

    return (
      <>
        <FieldSectionWrap>
          <PhoenixStyledTooltip id="field-tooltip" />

          {!loading ? (
            <FlexDiv direction="column" gap={24}>
              <FlexDiv direction="column" gap={24}>
                <ButtonContainer>
                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    uppercase
                    width={205}
                    buttonTextFontSize={10}
                    onClick={addField}
                    disabled={loading}
                  >
                    <FlexDiv justify="center" align="center" gap={16}>
                      <PhoenixIcon svg={plus} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} />
                      Field
                    </FlexDiv>
                  </PhoenixAppButton>

                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    uppercase
                    width={205}
                    buttonTextFontSize={10}
                    onClick={addEmptyRow}
                    disabled={loading}
                  >
                    <FlexDiv justify="center" align="center" gap={16}>
                      <PhoenixIcon svg={plus} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} />
                      Row
                    </FlexDiv>
                  </PhoenixAppButton>
                </ButtonContainer>

                <FlexDiv align="center" justify="center">
                  <AppText fontSize={12} color={theme.NEUTRAL300} textAlign="center">
                    Each row may have a maximum of two fields.
                  </AppText>
                </FlexDiv>
              </FlexDiv>

              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="rows" type="ROW">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {rows?.map((row, rowIndex) => (
                        <Draggable key={row.id} draggableId={row.id} index={rowIndex}>
                          {(provided) => (
                            <FlexDiv direction="column" ref={provided.innerRef} {...provided.draggableProps}>
                              <FlexDiv align="center" gap={16} style={{ marginBottom: 16 }}>
                                <FlexDiv align="center" gap={16} {...provided.dragHandleProps}>
                                  <PhoenixIcon svg={reorder} size={16} variant="neutral" style={{ cursor: "grab" }} />
                                  <AppText color={theme.PRIMARY500} fontSize={10} fontWeight={600}>
                                    ROW {rowIndex + 1}
                                  </AppText>
                                </FlexDiv>

                                <FlexDiv style={{ flex: 1, background: theme.NEUTRAL300, height: 1 }} />

                                {!row.fields.length ? (
                                  <PhoenixIcon
                                    svg={trash}
                                    size={16}
                                    variant="danger"
                                    pointer
                                    onClick={() => deleteRow(rowIndex)}
                                  />
                                ) : null}
                              </FlexDiv>

                              <FormsFieldList
                                droppableId={row.id}
                                onChangeHandlers={{
                                  onFieldChange,
                                  onFieldRequiredChange,
                                  onFieldRadioChange,
                                }}
                                fields={row.fields}
                                availableSystemFields={availableSystemFields}
                                availableCustomFields={availableCustomFields}
                                customFieldData={allCustomFields}
                                setSelectedFieldID={setSelectedFieldID}
                              />
                            </FlexDiv>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FlexDiv>
          ) : (
            <FlexDiv direction="column" gap={16} style={{ paddingBottom: "24px" }}>
              {Array.from({ length: 3 })?.map((_, i) => (
                <SkeletonBlock
                  width={422}
                  height={120}
                  borderRadius={8}
                  key={i}
                  delayNumber={i + 1}
                  lightPulseStateColor={theme.PRIMARY100}
                  opacity={Math.max(1 - i * 0.3, 0.3)}
                />
              ))}
            </FlexDiv>
          )}
        </FieldSectionWrap>
      </>
    );
  },
);

const reorderRows = (rows: FormFieldRow[], startIndex: number, endIndex: number): FormFieldRow[] => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const moveField = (rows: FormFieldRow[], source: any, destination: any): FormFieldRow[] => {
  const newRows = cloneDeep(rows);
  const sourceRow = newRows.find((row) => row.id === source.droppableId);
  const destRow = newRows.find((row) => row.id === destination.droppableId);

  if (sourceRow && destRow) {
    const [movedField] = sourceRow.fields.splice(source.index, 1);

    if (destRow.fields.length < 2) {
      destRow.fields.splice(destination.index, 0, movedField);
    } else {
      sourceRow.fields.splice(source.index, 0, movedField);
    }

    let order = 0;
    newRows.forEach((row, rowIndex) => {
      row.fields.forEach((field) => {
        field.row = rowIndex;
        field.order = order++;
      });
    });
  }

  return newRows;
};

const FieldSectionWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 85vh;
  max-width: 425px;
  padding-top: 40px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
