import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, Loading, AppErrorText } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../../Field";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { sortByMonthAndYear } from "../../../utils/sorts";
import { useHistory } from "react-router-dom";
import { appToast } from "../../../utils/toast";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

interface MyFormikProps {
  revenue_plan_items: any[];
  use_revenue_planning: boolean;
}

const stepRevenueSchema = Yup.object().shape({
  use_revenue_planning: Yup.boolean().notRequired(),
  revenue_plan_items: Yup.array().of(
    Yup.object().shape({
      revenue_goal: Yup.number().required("Field required").min(0, "Cannot be negative"),
    }),
  ),
});

export const FETCH_REVENUE_MONTHS = gql`
  query fetchRevenuePlan {
    fetchRevenuePlan {
      id
      working_days
      month
      year
      revenue_goal
      actual_revenue
    }
    fetchOrganization {
      id
      use_revenue_planning
      preferred_goal_setting
    }
  }
`;

export const UPDATE_REVENUE_PLANNING_SETTING = gql`
  mutation UpdateRevenuePlanningSetting($use_revenue_planning: Boolean!) {
    updateRevenuePlanningSetting(use_revenue_planning: $use_revenue_planning) {
      id
      use_revenue_planning
    }
  }
`;

export const UPDATE_REVENUE_PLAN = gql`
  mutation updateRevenuePlan($use_revenue_planning: Boolean, $revenue_plan_items: [RevenueItemInput!]) {
    updateRevenuePlan(revenue_plan_items: $revenue_plan_items, use_revenue_planning: $use_revenue_planning) {
      id
      month
      year
      revenue_goal
      working_days
    }
  }
`;

const StepRevenue: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const history = useHistory();
  const [updateRevenuePlan] = useMutation(UPDATE_REVENUE_PLAN, {
    onCompleted(completeData) {
      console.log("completeData: ", completeData);
      if (!completeData) {
        return;
      }
      if (isOnboarding) {
        history.push(`/onboarding?step=${step + 1}`);
        appToast("Revenue Plan setting saved successfully");
      } else {
        appToast("Updated Revenue Plan!");
      }
    },
    onError({ message }) {
      console.log("Error in updateRevenuePlan: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateRevenuePlan GraphQL Error: ${message}`,
      });
    },
  });

  const { data, loading, error } = useQuery(FETCH_REVENUE_MONTHS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  if (loading) return <Loading />;
  if (error) return <p>Error loading revenue plan</p>;

  return (
    <Formik
      initialValues={{
        revenue_plan_items: data.fetchRevenuePlan.slice().sort(sortByMonthAndYear),
        use_revenue_planning: data.fetchOrganization.use_revenue_planning,
      }}
      validationSchema={stepRevenueSchema}
      onSubmit={async ({ use_revenue_planning, revenue_plan_items }) => {
        if (use_revenue_planning) {
          await updateRevenuePlan({
            variables: {
              use_revenue_planning,
              revenue_plan_items: revenue_plan_items?.map(
                ({ __typename, working_days, actual_revenue, ...g }: any) => g,
              ),
            },
          });
        } else {
          await updateRevenuePlan({
            variables: {
              use_revenue_planning,
            },
          });
        }
      }}
    >
      {({ dirty, isValid, submitForm, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        return (
          <DisappearingDiv>
            <LocalWrapper isOnboarding={isOnboarding}>
              {/* <TopAlignDiv> */}
              <TopContentDiv>
                <Horizontal>
                  <RevenueText style={{ width: "auto", marginRight: 10 }}>Revenue Plan</RevenueText>
                  <Switch
                    onChange={(checked) => setFieldValue("use_revenue_planning", checked)}
                    name="use_revenue_planning"
                    onColor={theme.PRIMARY500}
                    checked={values.use_revenue_planning}
                    height={19}
                    width={36.3}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                  <SwtichLabelText style={{ marginLeft: 10 }}>
                    {values.use_revenue_planning ? "Active" : "Inactive"}
                  </SwtichLabelText>
                </Horizontal>
              </TopContentDiv>
              {loading ? (
                <Loading />
              ) : (
                values.use_revenue_planning && (
                  <RevenueContainer>
                    <RevenueTable>
                      <TableTitle isOnboarding={isOnboarding}>2022</TableTitle>
                      <RevenueRow isOnboarding={isOnboarding}>
                        <TableHeading style={{ width: "150px" }}>Month (Working Days)</TableHeading>
                        <TableHeading style={{ width: "150px", textAlign: "center" }}>Revenue Goal</TableHeading>
                        {!isOnboarding && (
                          <TableHeading style={{ width: "150px", textAlign: "center" }}>Actual Revenue</TableHeading>
                        )}
                      </RevenueRow>
                      {values.revenue_plan_items?.map(
                        (item: any, index: number) =>
                          item.year === "2022" && (
                            <RevenueRow key={item.id} isOnboarding={isOnboarding}>
                              <RevenueText>
                                {item.month} ({item.working_days})
                              </RevenueText>
                              <AmountInput
                                name={`revenue_plan_items[${index}].revenue_goal`}
                                width={156}
                                type="number"
                                placeholder="Custom"
                                currency
                              />
                              {!isOnboarding && (
                                <RevenueText style={{ textAlign: "center" }}>{item.actual_revenue}</RevenueText>
                              )}
                            </RevenueRow>
                          ),
                      )}
                      <RevenueRow isOnboarding={isOnboarding}>
                        <RevenueTotalText>2022 Revenue Goal: &nbsp;</RevenueTotalText>
                        <RevenueTotalText style={{ color: theme.PRIMARY500, width: "150px", textAlign: "center" }}>
                          ${" "}
                          {Math.round(
                            values.revenue_plan_items
                              .filter((r) => r.year === "2022")
                              .reduce((acc, cv) => acc + cv.revenue_goal, 0),
                          ).toLocaleString()}
                        </RevenueTotalText>
                        {!isOnboarding && (
                          <RevenueTotalText style={{ color: theme.PRIMARY500, width: "150px", textAlign: "center" }}>
                            ${" "}
                            {Math.round(
                              values.revenue_plan_items
                                .filter((r) => r.year === "2022")
                                .reduce((acc, cv) => acc + cv.actual_revenue, 0),
                            ).toLocaleString()}
                          </RevenueTotalText>
                        )}
                      </RevenueRow>
                    </RevenueTable>
                    <RevenueTable>
                      <TableTitle isOnboarding={isOnboarding}>2023</TableTitle>
                      <RevenueRow isOnboarding={isOnboarding}>
                        <TableHeading style={{ width: "150px" }}>Month (Working Days)</TableHeading>
                        <TableHeading style={{ width: "150px", textAlign: "center" }}>Revenue Goal</TableHeading>
                        {!isOnboarding && (
                          <TableHeading style={{ width: "150px", textAlign: "center" }}>Actual Revenue</TableHeading>
                        )}
                      </RevenueRow>
                      {values.revenue_plan_items?.map(
                        (item: any, index: number) =>
                          item.year === "2023" && (
                            <RevenueRow key={item.id} isOnboarding={isOnboarding}>
                              <RevenueText>
                                {item.month} ({item.working_days})
                              </RevenueText>
                              <AmountInput
                                name={`revenue_plan_items[${index}].revenue_goal`}
                                width={156}
                                type="number"
                                placeholder="Custom"
                                currency
                              />
                              {!isOnboarding && (
                                <RevenueText style={{ textAlign: "center" }}>{item.actual_revenue}</RevenueText>
                              )}
                            </RevenueRow>
                          ),
                      )}
                      <RevenueRow isOnboarding={isOnboarding}>
                        <RevenueTotalText>2023 Revenue Goal: &nbsp;</RevenueTotalText>
                        <RevenueTotalText style={{ color: theme.PRIMARY500, width: "150px", textAlign: "center" }}>
                          ${" "}
                          {Math.round(
                            values.revenue_plan_items
                              .filter((r) => r.year === "2023")
                              .reduce((acc, cv) => acc + cv.revenue_goal, 0),
                          ).toLocaleString()}
                        </RevenueTotalText>
                        {!isOnboarding && (
                          <RevenueTotalText style={{ color: theme.PRIMARY500, width: "150px", textAlign: "center" }}>
                            ${" "}
                            {Math.round(
                              values.revenue_plan_items
                                .filter((r) => r.year === "2023")
                                .reduce((acc, cv) => acc + cv.actual_revenue, 0),
                            ).toLocaleString()}
                          </RevenueTotalText>
                        )}
                      </RevenueRow>
                    </RevenueTable>
                  </RevenueContainer>
                )
              )}
              {/* </TopAlignDiv> */}
              <CenterDiv>
                <SaveAndContinueButton type="submit" onClick={submitForm} disabled={!(isValid && dirty)}>
                  {isOnboarding === true ? "Save & Continue" : "Save Changes"}
                </SaveAndContinueButton>
              </CenterDiv>
            </LocalWrapper>
          </DisappearingDiv>
        );
      }}
    </Formik>
  );
};

const temp = 5;
temp.toLocaleString();

interface IsOnboardingProp {
  isOnboarding?: boolean;
}

const RevenueText = styled(AppText)`
  width: 150px;
  font-size: 14px;
`;

const LocalWrapper = styled.div<IsOnboardingProp>`
  max-width: ${(props) => (props.isOnboarding ? "700px" : "1000px")};
  margin-right: auto;
  margin-left: auto;
`;

const RevenueTotalText = styled(AppText)`
  display: inline;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  margin-top: 12px;
  margin-bottom: 20px;
`;

const SelectLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
`;

const SwtichLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 0px;
`;

const TableTitle = styled(AppText)<IsOnboardingProp>`
  width: ${(props) => (props.isOnboarding ? "300px" : "450px")};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 21px;
`;

const TableHeading = styled(AppText)`
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 21px;
  margin-top: 10px;
`;

const RevenueContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountInput = styled(InputField)`
  height: 35px;
  border-radius: 2.9px;
  border: solid 1px ${theme.NEUTRAL200};
  font-size: 13px;
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    font-size: 13px;
  }
`;

const RevenueRow = styled.div<IsOnboardingProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isOnboarding ? "300px" : "450px")};
  margin-top: -10px;
  margin-bottom: -10px;
`;

const RevenueTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const RevenueSelect = styled(FormSelectField)`
  height: 40px;
  width: 179px;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
`;

const TopContentDiv = styled.div`
  min-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 22px 0px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { StepRevenue };
