import * as React from "react";
import { AppText, FlexDiv, Spinner } from "../../UI";

export const AIProcessingCall = ({ width }: { width?: number }) => {
  return (
    <FlexDiv
      justify="center"
      align="center"
      direction="column"
      gap={24}
      style={{ width: width ? `${width}px` : "100%", height: "100%" }}
    >
      <Spinner size={48} />
      <AppText fontSize={14} fontWeight={400} lineHeight={20} textAlign="center">
        AI is processing your call. This may take a few minutes.
      </AppText>
    </FlexDiv>
  );
};
