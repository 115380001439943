import React from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { useClickOutside } from "../../../../utils/hooks";

const SearchMenu = ({ ...props }) => {
  const containerRef = React.useRef(null);

  useClickOutside(containerRef, () => props?.setIsSearchClicked(false));

  return (
    <OptionsDiv ref={containerRef}>
      {!!props?.lead?.business_name && (
        <Option
          onClick={() => {
            let businessNameSearch = encodeURIComponent(props?.lead?.business_name);
            let citySearch = encodeURIComponent(`${props?.lead?.city || ""} ${props?.lead?.state || ""}`);
            window.open(
              `https://google.com/search?q=${businessNameSearch || ""} ${citySearch || ""}`,
              "_blank",
              "noreferrer",
            );
          }}
        >
          Business Name
        </Option>
      )}
      {!!props?.lead?.full_name && props?.lead?.full_name.length > 3 && (
        <Option
          onClick={() => {
            let nameSearch = encodeURIComponent(props?.lead?.full_name);
            let citySearch = encodeURIComponent(`${props?.lead?.city || ""} ${props?.lead?.state || ""}`);
            window.open(`https://google.com/search?q=${nameSearch || ""} ${citySearch || ""}`, "_blank", "noreferrer");
          }}
        >
          Lead Name
        </Option>
      )}
    </OptionsDiv>
  );
};

const OptionsDiv = styled.div`
  position: absolute;
  top: 16px;
  right: 104px;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  /* animation: ${theme.popup} 0.2s ease; */
  z-index: 3;
`;

const Option = styled.div`
  display: flex;
  align-items: center;

  width: 168px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;

export { SearchMenu };
