import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, Loading, AppErrorText } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../../Field";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { sortByMonthAndYear } from "../../../utils/sorts";
import { useHistory } from "react-router-dom";
import { appToast, successToast } from "../../../utils/toast";
import { iconNewCaretDown } from "../../../images";
import { loggedInUser } from "../../../apollo/cache";
import { values } from "lodash";
import ReactTooltip from "react-tooltip";
import { formatTime } from "../../../utils/format";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

interface MyFormikProps {
  show_system_view: boolean;
}

const stepRestingRules = Yup.object().shape({
  show_system_view: Yup.boolean().notRequired(),
});

const ORGANIZATION_DATA = gql`
  {
    fetchOrganization {
      id
      show_system_view
    }
  }
`;

const UPDATE_ORGANIZATION_REST_RULE = gql`
  mutation updateOrganizationSettings($show_system_view: Boolean!) {
    updateOrganizationSettings(show_system_view: $show_system_view) {
      id
      show_system_view
    }
  }
`;

const StepRepPermissions: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const history = useHistory();

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    variables: {
      org_id: loggedInUser().organization_id,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateOrganizationSettings, { loading: loadingRules, error: errorRules }] = useMutation(
    UPDATE_ORGANIZATION_REST_RULE,
    {
      async onCompleted({ updateOrganizationSettings }) {
        if (!updateOrganizationSettings) {
          appToast("Error updating Rep Permission. Something went wrong.");
          return;
        }
        successToast("Updated Rep Permission");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateOrganizationSettings GraphQL Error: ${message}`,
        });
        console.log("Error in updateOrganizationSettings: ", message);
      },
    },
  );

  if (loadingOrg) return <Loading />;
  if (errorOrg) return <AppErrorText>Error loading data</AppErrorText>;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        show_system_view: dataOrg.fetchOrganization.show_system_view,
      }}
      validationSchema={stepRestingRules}
      onSubmit={async (values) => {
        await updateOrganizationSettings({
          variables: {
            show_system_view: values.show_system_view,
          },
        });
        if (isOnboarding === true) {
          history.push(`/onboarding?step=${step + 1}`);
        }
      }}
    >
      {({ dirty, submitForm, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        return (
          <DisappearingDiv>
            <LocalWrapper isOnboarding={isOnboarding}>
              <ReactTooltip
                multiline
                place="top"
                effect="solid"
                css={{
                  maxWidth: 600,
                  lineHeight: 1.4,
                  textAlign: "center",
                  fontFamily: theme.PRIMARY_FONT,
                }}
                id="resting"
                backgroundColor={theme.PRIMARY800}
                getContent={(dataTip) => (
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    {dataTip}
                  </span>
                )}
              />
              {/* <TopAlignDiv> */}
              <TopContentDiv>
                {/* <SegmentTitle>Resting Rules</SegmentTitle> */}
                <SwitchDiv>
                  <Switch
                    onChange={(checked) => setFieldValue("show_system_view", checked)}
                    checked={values.show_system_view}
                    onColor={theme.PRIMARY500}
                    offColor={theme.NEUTRAL200}
                    height={32}
                    width={54}
                    handleDiameter={24}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                  <AppText style={{ fontSize: "14px", marginLeft: "16px" }}>Enable Reps to Access System View</AppText>
                </SwitchDiv>
              </TopContentDiv>
              {/* {JSON.stringify(values)} */}
              {isOnboarding === true ? (
                <CenterContentDiv>
                  <OldSaveAndContinueButton disabled={!dirty} type="submit" onClick={submitForm}>
                    Save & Continue
                  </OldSaveAndContinueButton>
                </CenterContentDiv>
              ) : (
                <CenterDiv>
                  <SaveAndContinueButton disabled={!dirty} type="submit" onClick={submitForm} variant={"primary"}>
                    Save Changes
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </LocalWrapper>
          </DisappearingDiv>
        );
      }}
    </Formik>
  );
};

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const SegmentTitle = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
`;

const OldSaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const CenterContentDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

interface CaretProps {
  disabled: boolean;
  bottom?: boolean;
}

const AbsDivTop = styled.div<CaretProps>`
  display: flex;
  background: url(${iconNewCaretDown}) no-repeat;
  background-position: 5px 13px;
  position: absolute;
  right: 10px;
  top: ${(props) => (props.bottom ? "20px" : "0px")};
  transform: ${(props) => (props.bottom ? "rotate(180deg)" : "rotate(0deg)")};
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
const AbsDivTime = styled.div`
  display: flex;
  position: absolute;
  right: 36px;
  top: 0px;
  width: fit-content;
  height: 20px;
`;

const LabelText = styled(AppText)`
  margin-bottom: 8px;
`;

const TooltipText = styled.span`
  /* margin-bottom: 8px; */
  background-color: ${theme.NEUTRAL200};
  padding: 0px 5px;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  margin-left: 8px;
  opacity: 0.7;
  font-size: 12px;
`;

const NumberInput = styled.input`
  height: 40px;
  padding-left: 12px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 2px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;
interface IsOnboardingProp {
  isOnboarding?: boolean;
}

const RevenueText = styled(AppText)`
  width: 150px;
  font-size: 14px;
`;

const LocalWrapper = styled.div<IsOnboardingProp>`
  width: 364px;
  margin: ${(props) => (props.isOnboarding ? "20px auto" : "0px")};
`;

const RevenueTotalText = styled(AppText)`
  display: inline;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  margin-top: 12px;
  margin-bottom: 20px;
`;

const SelectLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
`;

const SwtichLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 0px;
`;

const TableTitle = styled(AppText)<IsOnboardingProp>`
  width: ${(props) => (props.isOnboarding ? "300px" : "450px")};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 21px;
`;

const TableHeading = styled(AppText)`
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 21px;
  margin-top: 10px;
`;

const RevenueContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountInput = styled(InputField)`
  height: 35px;
  border-radius: 2.9px;
  border: solid 1px ${theme.NEUTRAL200};
  font-size: 13px;
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    font-size: 13px;
  }
`;

const RevenueRow = styled.div<IsOnboardingProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isOnboarding ? "300px" : "450px")};
  margin-top: -10px;
  margin-bottom: -10px;
`;

const RevenueTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const RevenueSelect = styled(FormSelectField)`
  height: 40px;
  width: 179px;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
`;

const TopContentDiv = styled.div`
  width: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  /* margin: 22px 0px; */
  margin-bottom: 16px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SaveAndContinueButton = styled(NewAppButton)`
  /* margin: 20px auto; */
  /* border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500; */
  width: 155px;
  height: 48px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { StepRepPermissions };
