import React from "react";
import styled from "styled-components";
import { PhoenixIcon } from "../../Phoenix";
import { arrow_left_right } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { Handle, Position } from "react-flow-renderer";

export const ConditionNode: React.FC = () => {
  return (
    <IconCircleContainer style={{ width: "296px" }}>
      <IconCircle>
        <PhoenixIcon svg={arrow_left_right} size="small" variant="white" hoverColor="white" />
      </IconCircle>
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </IconCircleContainer>
  );
};

const IconCircleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 296px;
  cursor: default;

  .react-flow__handle-top {
    top: 32px !important;
  }
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  margin-top: 32px;

  background-color: ${theme.WARNING500};
  border-radius: 50%;
`;
