import React from "react";
import { AppText } from "../../../UI";
import { gql, useQuery } from "@apollo/client";
import { theme } from "../../../../utils/theme";

const FETCH_HELPFUL_LINKS = gql`
  query fetchHelpfulLinks {
    fetchOrganization {
      id
      helpful_links
    }
  }
`;

const HelpfulLinks = () => {
  const { data: dataLinks, loading: loadingLinks, error: errorLinks } = useQuery(FETCH_HELPFUL_LINKS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <>
      {dataLinks?.fetchOrganization?.helpful_links?.map((link: string, i: number) => (
        <AppText
          variant="primary"
          onClick={() => window.open(`${link}`, "_blank", "noreferrer")}
          style={{ borderBottom: `1px solid ${theme.PRIMARY500}`, cursor: "pointer" }}
          key={`link-${i}-${link}`}
        >
          {link}
        </AppText>
      ))}
    </>
  );
};

export default HelpfulLinks;
