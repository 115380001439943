import styled from "styled-components";
import { theme } from "../../../../utils/theme";

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  :nth-child(odd) {
    background-color: ${theme.surface.neutral.primary};
  }
  :nth-child(even) {
    background-color: ${theme.surface.brand.secondary};
  }
`;
