import styled from "styled-components";
import * as React from "react";
import { useEffect } from "react";
import { AppText, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { useContext, useMemo, useState } from "react";
import { newCloseModal } from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import { GridFilterContext } from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useMutation, useQuery } from "@apollo/client";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { formatTypeName } from "../../utils/format";

const FETCH_DEFAULT_COLUMN_OPTION = gql`
  query fetchDefaultColumnOption {
    fetchDefaultColumnOption {
      saved_view_id
      visible_reporting_fields
      visible_coaching_fields
      visible_activity_fields
      visible_event_fields
      visible_pipeline_fields
      visible_transfer_fields
      visible_transfer_quality_fields
      all_reporting_fields
      all_coaching_fields
      all_activity_fields
      all_pipeline_fields
      all_event_fields
      all_transfer_fields
      all_transfer_quality_fields
      created_at
      updated_at
    }
  }
`;

const FETCH_DASHBOARD_COLUMN_OPTION = gql`
  query fetchDashboardColumnOption($saved_view_id: String!) {
    fetchDashboardColumnOption(saved_view_id: $saved_view_id) {
      saved_view_id
      visible_reporting_fields
      visible_coaching_fields
      visible_activity_fields
      visible_event_fields
      visible_pipeline_fields
      visible_transfer_fields
      visible_transfer_quality_fields
      all_reporting_fields
      all_coaching_fields
      all_activity_fields
      all_pipeline_fields
      all_event_fields
      all_transfer_fields
      all_transfer_quality_fields
      created_at
      updated_at
    }
  }
`;

const UPDATE_DEFAULT_COLUMN_OPTION = gql`
  mutation updateDefaultColumnOption($column_options: ColumnOptionArgument!) {
    updateDefaultColumnOption(column_options: $column_options) {
      saved_view_id
      visible_reporting_fields
      visible_coaching_fields
      visible_activity_fields
      visible_event_fields
      visible_pipeline_fields
      visible_transfer_fields
      visible_transfer_quality_fields
      all_reporting_fields
      all_coaching_fields
      all_activity_fields
      all_pipeline_fields
      all_event_fields
      all_transfer_fields
      all_transfer_quality_fields
      created_at
      updated_at
    }
  }
`;
const UPDATE_DASHBOARD_COLUMN_OPTION = gql`
  mutation updateDashboardColumnOption($column_options: ColumnOptionArgument!, $saved_view_id: String!) {
    updateDashboardColumnOption(column_options: $column_options, saved_view_id: $saved_view_id) {
      saved_view_id
      visible_reporting_fields
      visible_coaching_fields
      visible_activity_fields
      visible_event_fields
      visible_pipeline_fields
      visible_transfer_fields
      visible_transfer_quality_fields
      all_reporting_fields
      all_coaching_fields
      all_activity_fields
      all_pipeline_fields
      all_event_fields
      all_transfer_fields
      all_transfer_quality_fields
      created_at
      updated_at
    }
  }
`;

const ManageGridColumnsModal: React.FC = () => {
  // Context for filters
  const { tableView, gridModal, setGridModal, currentSavedView, setColumnOptions, columnOptions } = useContext(
    GridFilterContext,
  );

  const [tempSelected, setTempSelected] = useState([] as string[]);

  // Todo: Fetch this from grid context

  const { data: dataDefaultColumnOption, loading: loadingDefaultColumnOption } = useQuery(FETCH_DEFAULT_COLUMN_OPTION, {
    fetchPolicy: "network-only",
    skip: !!currentSavedView?.id,
    onError({ message }) {
      Sentry.captureEvent({
        message: `GraphQL Error in fetchDefaultColumnOption: ${message}`,
      });
      appToast(message);
    },
    onCompleted() {
      setTempSelected(returnSelectedOptionsForView);
    },
  });

  const {
    data: dataDashboardColumnOption,
    loading: loadingDashboardColumnOption,
    refetch: refetchDashboardColumnOption,
  } = useQuery(FETCH_DASHBOARD_COLUMN_OPTION, {
    fetchPolicy: "network-only",
    skip: !currentSavedView?.id,
    variables: {
      saved_view_id: currentSavedView?.id,
    },
    onError({ message }) {
      Sentry.captureEvent({
        message: `GraphQL Error in fetchDashboardColumnOption: ${message}`,
      });
      appToast(message);
    },
    onCompleted({ fetchDashboardColumnOption }) {
      setColumnOptions({
        visible_reporting_fields: fetchDashboardColumnOption?.visible_reporting_fields,
        visible_coaching_fields: fetchDashboardColumnOption?.visible_coaching_fields,
        visible_activity_fields: fetchDashboardColumnOption?.visible_activity_fields,
        visible_event_fields: fetchDashboardColumnOption?.visible_event_fields,
        visible_pipeline_fields: fetchDashboardColumnOption?.visible_pipeline_fields,
        visible_transfer_fields: fetchDashboardColumnOption?.visible_transfer_fields,
        visible_transfer_quality_fields: fetchDashboardColumnOption?.visible_transfer_quality_fields,
      });
      setTempSelected(returnSelectedOptionsForView);
    },
  });

  useEffect(() => {
    if (!!currentSavedView?.id) {
      refetchDashboardColumnOption();
    }
  }, [currentSavedView?.id]);

  const [updateDefaultColumnOption, { loading: loadingUpdateDefault, error: errorUpdateDefault }] = useMutation(
    UPDATE_DEFAULT_COLUMN_OPTION,
    {
      onCompleted({ updateDefaultColumnOption }) {
        setColumnOptions({
          visible_reporting_fields: updateDefaultColumnOption?.visible_reporting_fields,
          visible_coaching_fields: updateDefaultColumnOption?.visible_coaching_fields,
          visible_activity_fields: updateDefaultColumnOption?.visible_activity_fields,
          visible_event_fields: updateDefaultColumnOption?.visible_event_fields,
          visible_pipeline_fields: updateDefaultColumnOption?.visible_pipeline_fields,
          visible_transfer_fields: updateDefaultColumnOption?.visible_transfer_fields,
          visible_transfer_quality_fields: updateDefaultColumnOption?.visible_transfer_quality_fields,
        });
        setGridModal(!gridModal);
        appToast("Columns updated successfully");
        console.log("updateDefaultColumnOption: ", updateDefaultColumnOption);
      },
      onError({ message }) {
        console.log("Error in updateDefaultColumnOption: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `updateDefaultColumnOption GraphQL Error: ${message}`,
        });
      },
      refetchQueries: ["fetchDefaultColumnOption"],
    },
  );

  const [updateDashboardColumnOption, { loading: loadingUpdateDashboard, error: errorUpdateDashboard }] = useMutation(
    UPDATE_DASHBOARD_COLUMN_OPTION,
    {
      onCompleted({ updateDashboardColumnOption }) {
        setColumnOptions({
          visible_reporting_fields: updateDashboardColumnOption?.visible_reporting_fields,
          visible_coaching_fields: updateDashboardColumnOption?.visible_coaching_fields,
          visible_activity_fields: updateDashboardColumnOption?.visible_activity_fields,
          visible_event_fields: updateDashboardColumnOption?.visible_event_fields,
          visible_pipeline_fields: updateDashboardColumnOption?.visible_pipeline_fields,
          visible_transfer_fields: updateDashboardColumnOption?.visible_transfer_fields,
          visible_transfer_quality_fields: updateDashboardColumnOption?.visible_transfer_quality_fields,
        });
        setGridModal(!gridModal);
        appToast("Columns updated successfully");
        console.log("updateDashboardColumnOption: ", updateDashboardColumnOption);
      },
      onError({ message }) {
        console.log("Error in updateDashboardColumnOption: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `updateDashboardColumnOption GraphQL Error: ${message}`,
        });
      },
      refetchQueries: ["fetchDashboardColumnOption"],
    },
  );

  const handleCheckboxClick = (item: string) => {
    if (tempSelected?.includes(item)) {
      const newSelected = tempSelected.filter((filter: any) => filter !== item);
      setTempSelected(newSelected);
    } else {
      setTempSelected([...tempSelected, item]);
    }
  };

  const returnAllOptionsForView = useMemo(() => {
    if (!!currentSavedView?.id) {
      switch (tableView) {
        case "Coaching":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_coaching_fields ?? [];
        case "Reporting":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_reporting_fields ?? [];
        case "Event":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_event_fields ?? [];
        case "Activity":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_activity_fields ?? [];
        case "Pipeline":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_pipeline_fields ?? [];
        case "Transfer":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_transfer_fields ?? [];
        case "TransferQuality":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.all_transfer_quality_fields ?? [];
        default:
          return [];
      }
    } else {
      switch (tableView) {
        case "Coaching":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.all_coaching_fields ?? [];
        case "Reporting":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.all_reporting_fields ?? [];
        case "Event":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.all_event_fields ?? [];
        case "Activity":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.all_activity_fields ?? [];
        case "Pipeline":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.all_pipeline_fields ?? [];
        case "Transfer":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.all_transfer_fields ?? [];
        case "TransferQuality":
          return dataDefaultColumnOption?.fetchDashboardColumnOption?.all_transfer_quality_fields ?? [];
        default:
          return [];
      }
    }
  }, [tableView, dataDashboardColumnOption, dataDefaultColumnOption]);

  const returnSelectedOptionsForView = useMemo(() => {
    if (!!currentSavedView?.id) {
      switch (tableView) {
        case "Coaching":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_coaching_fields ?? [];
        case "Reporting":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_reporting_fields ?? [];
        case "Event":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_event_fields ?? [];
        case "Activity":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_activity_fields ?? [];
        case "Pipeline":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_pipeline_fields ?? [];
        case "Transfer":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_transfer_fields ?? [];
        case "TransferQuality":
          return dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_transfer_quality_fields ?? [];
        default:
          return [];
      }
    } else {
      switch (tableView) {
        case "Coaching":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_coaching_fields ?? [];
        case "Reporting":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_reporting_fields ?? [];
        case "Event":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_event_fields ?? [];
        case "Activity":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_activity_fields ?? [];
        case "Pipeline":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_pipeline_fields ?? [];
        case "Transfer":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_transfer_fields ?? [];
        case "TransferQuality":
          return dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_transfer_quality_fields ?? [];
        default:
          return [];
      }
    }
  }, [tableView, dataDashboardColumnOption, dataDefaultColumnOption]);

  const returnColumnOptions = () => {
    if (!!currentSavedView?.id) {
      return {
        visible_reporting_fields:
          tableView === "Reporting"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_reporting_fields,
        visible_coaching_fields:
          tableView === "Coaching"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_coaching_fields,
        visible_activity_fields:
          tableView === "Activity"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_activity_fields,
        visible_event_fields:
          tableView === "Event"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_event_fields,
        visible_pipeline_fields:
          tableView === "Pipeline"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_pipeline_fields,
        visible_transfer_fields:
          tableView === "Transfer"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_transfer_fields,
        visible_transfer_quality_fields:
          tableView === "TransferQuality"
            ? tempSelected
            : dataDashboardColumnOption?.fetchDashboardColumnOption?.visible_transfer_quality_fields,
      };
    } else {
      return {
        visible_reporting_fields:
          tableView === "Reporting"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_reporting_fields,
        visible_coaching_fields:
          tableView === "Coaching"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_coaching_fields,
        visible_activity_fields:
          tableView === "Activity"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_activity_fields,
        visible_event_fields:
          tableView === "Event"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_event_fields,
        visible_pipeline_fields:
          tableView === "Pipeline"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_pipeline_fields,
        visible_transfer_fields:
          tableView === "Transfer"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_transfer_fields,
        visible_transfer_quality_fields:
          tableView === "TransferQuality"
            ? tempSelected
            : dataDefaultColumnOption?.fetchDefaultColumnOption?.visible_transfer_quality_fields,
      };
    }
  };

  return (
    <EditCardDiv>
      <TitleDiv>
        <TitleText>{tableView} View Settings</TitleText>
        <CloseButton>
          <NewAppButton
            // small
            // borderless
            onClick={() => {
              setGridModal(!gridModal);
            }}
          >
            <img src={newCloseModal} alt="Close" />
          </NewAppButton>
        </CloseButton>
      </TitleDiv>
      <ScrollDiv>
        <PaddingAndScrollDiv>
          {loadingDefaultColumnOption || loadingDashboardColumnOption ? (
            <Loading />
          ) : (
            returnAllOptionsForView?.map((item: string) => {
              return (
                <FilterOptionsContainer>
                  <AppCheckbox
                    title={formatTypeName(item)}
                    checked={tempSelected?.includes(item)}
                    onClick={() => {
                      handleCheckboxClick(item);
                    }}
                  />
                </FilterOptionsContainer>
              );
            })
          )}
        </PaddingAndScrollDiv>
      </ScrollDiv>

      <SubmitDiv>
        <NewAppButton
          onClick={() => {
            setGridModal(!gridModal);
          }}
        >
          Cancel
        </NewAppButton>
        <NewAppButton
          variant={"primary"}
          onClick={async () => {
            if (!!currentSavedView?.id) {
              await updateDashboardColumnOption({
                variables: {
                  saved_view_id: currentSavedView?.id,
                  column_options: returnColumnOptions(),
                },
              });
            } else {
              await updateDefaultColumnOption({
                variables: {
                  column_options: returnColumnOptions(),
                },
              });
            }
          }}
          disabled={
            loadingUpdateDashboard ||
            loadingUpdateDefault ||
            JSON.stringify(tempSelected) === JSON.stringify(returnSelectedOptionsForView)
          }
        >
          Save
        </NewAppButton>
      </SubmitDiv>
    </EditCardDiv>
  );
};

const ScrollDiv = styled.div`
  max-height: calc(100vh - 156px);
  height: calc(100vh - 156px);
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 20px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 24px 32px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

export { ManageGridColumnsModal };
