import * as React from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, Loading } from "../UI";
import { Modal } from "./Modal";
import Switch from "react-switch";
import { theme } from "../../utils/theme";
import { useMutation, gql, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import { appToast } from "../../utils/toast";

interface IOrganizationHolidaysModalProps {
  isVisible: boolean;
  close: () => void;
}

const GET_ORG_HOLIDAYS = gql`
  {
    fetchOrganizationHolidays {
      id
      observe
      holiday {
        id
        year
        label
        date
        date_string
      }
    }
  }
`;

const UPDATE_ORG_HOLIDAYS = gql`
  mutation($organization_holidays: [OrganizationHolidaysInput!]) {
    configureHolidayTimeOff(organization_holidays: $organization_holidays) {
      id
      observe
      holiday {
        id
        year
        label
        date
        date_string
      }
    }
  }
`;

interface Holiday {
  id: string;
  year: string;
  date: string;
  label: string;
  date_string: string;
}

interface OrganizationHoliday {
  id: string;
  observe: boolean;
  holiday: Holiday;
}

interface MyFormikProps {
  organization_holidays: HolidayFormItem[];
}

interface HolidayFormItem {
  id: string;
  next_id?: string;
  label: string;
  date_string: string;
  next_date_string?: string;
  observe: boolean;
}

const mapHolidays = (holidays: OrganizationHoliday[]) => {
  console.log("holidays: ", holidays);
  const adjusted_holidays = holidays
    ?.map((item: any) => item)
    ?.sort((a: any, b: any) => moment(a.holiday.date).unix() - moment(b.holiday.date).unix())
    // .sort((a, b) => {
    //   try {
    //     const date1 = parseInt(a.holiday.year);
    //     const date2 = parseInt(b.holiday.year);
    //     return date1 - date2;
    //   } catch (e) {
    //     console.log(`error parsing int for holidays: ${e}`);
    //     return 0;
    //   }
    // })
    ?.reduce<HolidayFormItem[]>((acc, nextValue): HolidayFormItem[] => {
      if (!acc?.map((a) => a.label).includes(nextValue.holiday.label)) {
        return [
          ...acc,
          {
            id: nextValue.id,
            observe: nextValue.observe,
            date_string: nextValue.holiday.date_string,
            label: nextValue.holiday.label,
          },
        ];
      } else {
        return acc?.map((a) => {
          if (nextValue.holiday.label === a.label) {
            return {
              ...a,
              next_id: nextValue.id,
              next_date_string: nextValue.holiday.date_string,
            };
          }
          return a;
        });
      }
    }, []);
  console.log("adjusted_holidays: ", adjusted_holidays);
  return adjusted_holidays;
};

const OrganizationHolidaysModal: React.FunctionComponent<IOrganizationHolidaysModalProps> = ({ close, isVisible }) => {
  const [updateHolidays, { loading, error }] = useMutation(UPDATE_ORG_HOLIDAYS, {
    onCompleted({ configureGlobalSchedule }) {
      console.log("configureGlobalSchedule: ", configureGlobalSchedule);
      appToast("Holiday preferences saved");
      close();
    },
    onError({ message }) {
      console.log(message);
      appToast("Error saving holiday prefernces");
      Sentry.captureEvent({
        message: `updateHolidays GraphQL Error: ${message}`,
      });
    },
  });
  const { loading: queryLoading, error: queryError, data } = useQuery(GET_ORG_HOLIDAYS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  // TODO: Setup error state
  console.log("queryLoading: ", queryLoading);
  console.log("queryError: ", queryError);
  console.log("data: ", data);

  return (
    <Modal open={isVisible} onClose={close} closeButtonSize={16}>
      {queryLoading ? (
        <Loading />
      ) : queryError ? (
        <AppText>Error fetching holidays</AppText>
      ) : (
        <Formik
          initialValues={{
            organization_holidays: mapHolidays(data.fetchOrganizationHolidays),
          }}
          // validationSchema={}
          onSubmit={async ({ organization_holidays }) => {
            const new_organization_holidays = [
              ...organization_holidays?.map((h) => ({ id: h.id, observe: h.observe })),
              ...organization_holidays?.map((h) => ({ id: h.next_id, observe: h.observe })),
            ];
            await updateHolidays({
              variables: {
                organization_holidays: new_organization_holidays,
              },
            });
          }}
        >
          {({ submitForm, values, setFieldValue, isSubmitting, errors }: FormikProps<MyFormikProps>) => {
            console.log("values: ", values);
            return (
              <ScrollingDiv>
                <CenterDiv>
                  <AppText style={{ fontSize: "16px", fontWeight: 500, margin: "35px 0px 27px 0px" }}>
                    Holiday Time Off
                  </AppText>
                </CenterDiv>
                <HolidayTable>
                  <HolidayRow style={{ borderBottom: "2px solid black" }}>
                    <AppText style={{ fontSize: "10px", fontWeight: 500 }}>Observe</AppText>
                    <AppText style={{ fontSize: "10px", fontWeight: 500 }}>US Holiday</AppText>
                    <AppText style={{ margin: "0 auto", fontSize: "10px", fontWeight: 500 }}>2021</AppText>
                    <AppText style={{ margin: "0 auto", fontSize: "10px", fontWeight: 500 }}>2022</AppText>
                  </HolidayRow>
                  {values.organization_holidays?.map((holiday, index) => (
                    <HolidayRow
                      key={holiday.label}
                      style={{
                        borderBottom:
                          index === values.organization_holidays.length ? "none" : `1px solid ${theme.NEUTRAL200}`,
                      }}
                    >
                      <Switch
                        name=""
                        onChange={(checked) => {
                          console.log("checked: ", checked);
                          setFieldValue(`organization_holidays[${index}]`, { ...holiday, observe: checked });
                        }}
                        onColor={theme.PRIMARY500}
                        checked={holiday.observe}
                        height={19}
                        width={36.3}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                      <AppText style={{ fontSize: "12px", fontWeight: 500 }}>{holiday.label}</AppText>
                      <AppText style={{ margin: "0 auto", fontSize: "12px" }}>{holiday.date_string}</AppText>
                      <AppText style={{ margin: "0 auto", fontSize: "12px" }}>{holiday.next_date_string}</AppText>
                    </HolidayRow>
                  ))}
                </HolidayTable>
                <CenterDiv style={{ paddingBottom: "30px" }}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <HolidaysButton onClick={submitForm}>Save Changes</HolidaysButton>
                      <HolidaysButton cancel onClick={close}>
                        CANCEL
                      </HolidaysButton>
                    </>
                  )}
                </CenterDiv>
              </ScrollingDiv>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

const HolidaysButton = styled(AppButton)`
  margin: 0px 16.5px;
  border-radius: 0px;
  font-weight: 600;
  font-size: 13px;
  height: 37px;
  width: 185px;
`;

const HolidayTable = styled.div`
  padding: 0px 0px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  min-width: 578px;
`;

const SegmentRow = styled.div`
  display: grid;
  min-height: 40px;
  grid-template-rows: 50px;
  grid-template-columns: 101px 117fr 101fr;
  grid-gap: 10px;
  justify-items: start;
  justify-self: center;
  align-self: flex-start;
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
  break-inside: avoid;
  break-after: always;
  break-before: always;
`;

const HolidayRow = styled(SegmentRow)`
  width: 100%;
  padding: 15px 0px;
  grid-template-rows: 1fr;
  grid-template-columns: 45px 3fr 2fr 2fr;
  grid-gap: 14px;
`;

const ScrollingDiv = styled.div`
  padding: 40px 66px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 37px);
  overflow: auto;
`;

const PopupModal = styled.div`
  position: absolute;
  top: 37px;
  bottom: 0;
  left: calc(50vw - (742px / 2));
  width: 742px;
  border-radius: 7px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: auto;
  background: ${theme.WHITE_COLOR};
  border: 4px solid ${theme.NEUTRAL100};
  z-index: 3;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { OrganizationHolidaysModal };
