import styled from "styled-components";
import { FlexDiv } from "../../../UI/FlexDiv";

export const Cell = styled(FlexDiv)<{ center?: boolean; width?: number; bold?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  align-items: center;

  width: ${(props) => (!!props.width ? `${props.width}px` : "100%")};
  flex-grow: 1;

  padding: 0px 16px;

  font-size: 12px;
  font-weight: ${(props) => (props.bold ? "600" : "400")};
`;
