import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, AppSelect, Loading } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import * as Sentry from "@sentry/react";
import { useState, useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useReactMediaRecorder } from "react-media-recorder";
import WaveSurfer, { WaveSurferParams, WaveSurferPlugin } from "wavesurfer.js";
import { IoMdPause, IoMdPlay } from "react-icons/io";
import { BsMic, BsStopFill } from "react-icons/bs";
import { restAPI } from "../../apollo";
import { toast } from "react-toastify";
import { convertBlobToMediaFile } from "../../utils/media";
import { ApolloQueryResult } from "@apollo/client";
import { appToast } from "../../utils/toast";

interface DisappearingDivProps {
  onCancel: any;
  blinds: any;
  setBlinds: any;
  refetch: (
    variables?:
      | Partial<{
          id: string | undefined;
        }>
      | undefined,
  ) => Promise<ApolloQueryResult<any>>;
}

const RecordVoicemailModalComponent: React.FC<DisappearingDivProps> = ({ onCancel, blinds, setBlinds, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [recordingFile, setRecordingFile] = useState<any>(null);
  const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
    video: false,
    blobPropertyBag: { type: "audio/wav" },
  });

  useEffect(() => {
    console.log("mediaBlobUrl", mediaBlobUrl);
    const convert = async () => {
      const file = await convertBlobToMediaFile(mediaBlobUrl as string);
      setRecordingFile(file);
    };
    convert();
  }, [mediaBlobUrl]);

  const uploadRecording = async (event: any) => {
    if (!mediaBlobUrl || !recordingFile) {
      appToast(`No audio file found!`, { style: { backgroundColor: theme.ATTENTION700 } });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("document", recordingFile);
    try {
      const response = await restAPI.post(`/upload/voicemail`, formData);
      setLoading(false);
      appToast(`Voicemail recording saved!`);
      refetch();
      setBlinds(false);
    } catch (e: any) {
      setLoading(false);
      appToast(`Something went wrong: ${e}`, { style: { backgroundColor: theme.ATTENTION700 } });
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={"Error occurred in recording voicemail "}>
      <Modal
        open={blinds}
        onClose={() => {
          setBlinds(false);
          onCancel();
        }}
        closeButtonSize={16}
      >
        <PaddingDiv>
          <TitleText>Record New Custom Voicemail</TitleText>
          <CenterDiv>
            {status === "idle" || status === "stopped" ? (
              <IconContainerDiv mic>
                <BsMic size={40} color={theme.BLACK_COLOR} onClick={startRecording} />
              </IconContainerDiv>
            ) : status === "recording" ? (
              <IconContainerDiv>
                <BsStopFill size={40} color={theme.WHITE_COLOR} onClick={stopRecording} />
              </IconContainerDiv>
            ) : (
              <IconContainerDiv style={{ display: "hidden" }}></IconContainerDiv>
            )}

            {!!mediaBlobUrl && (
              <>
                <audio src={mediaBlobUrl} controls />
                {loading ? <Loading /> : <SaveButton onClick={uploadRecording}>Save</SaveButton>}
              </>
            )}
          </CenterDiv>
        </PaddingDiv>
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const SaveButton = styled(AppButton)`
  height: 40px;
  /* color: ${theme.PRIMARY500}; */
  font-size: 13px;
  font-weight: 500;
  margin-top: 30px;
  width: 200px;
`;

interface IconProps {
  mic?: boolean;
}

const IconContainerDiv = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  border-radius: 50%;
  background-color: ${(props) => (props.mic ? theme.WHITE_COLOR : theme.ATTENTION700)};
  box-shadow: 0px 2px 5px 0px ${theme.BLACK_COLOR};
  cursor: pointer;
  margin: 20px;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TitleText = styled(AppText)`
  padding-top: 30px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
`;

const CustomSelect = styled(AppSelect)`
  text-align: left;
  border-color: ${theme.NEUTRAL100};
  :focus {
    border-color: ${theme.BLACK_COLOR};
  }
`;

const PaddingDiv = styled.div`
  /* padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px; */
  min-height: 300px;
`;

const ConfirmText = styled(AppText)`
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

const LabelText = styled(AppText)`
  font-size: 11px;
  padding: 3px;
  text-align: left;
  opacity: 0.7;
`;

const ConfirmButton = styled(AppButton)`
  font-size: 14px;
  background-color: transparent;
  color: ${theme.ATTENTION700};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-weight: 600;
`;

const SkipButton = styled(AppButton)`
  font-size: 14px;
  background-color: ${theme.BLACK_COLOR};
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: 600;
`;

const CardTitle = styled.div`
  min-width: 100%;
  max-height: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: ${theme.NEUTRAL100};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 9;
`;

export { RecordVoicemailModalComponent };
