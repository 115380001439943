import React, { useContext } from "react";
import styled from "styled-components";
import { iconNewCaretDown } from "../../../../images";
import { loggedInUser } from "../../../../apollo/cache";
import { AppCheckbox } from "../../../UI/AppCheckbox";
import { LeadsTableContext } from "./LeadsTableContext";
import { AppText } from "../../../UI";
import { ACTIONS_LABEL } from "./shared";

export const LeadsTableCustomHeader = (props: any) => {
  const {
    isAllSelected,
    loadingTableAndHeaders,
    handleAllSelected,
    leadSort,
    leadSortAscending,
    setLeadSort,
    setLeadSortAscending,
    nonSortableColumns,
    getHeaderLabel,
    getTitle,
    mrrLabel,
  } = useContext(LeadsTableContext);

  const item = props.displayName;

  if (item === ACTIONS_LABEL) {
    return (
      <div>
        <AppCheckbox
          small
          title={""}
          checked={isAllSelected}
          disabled={loggedInUser()?.visible_all_leads_sm}
          onClick={() => {
            if (loadingTableAndHeaders) return;
            handleAllSelected();
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{ cursor: nonSortableColumns.includes(item) ? "default" : "pointer" }}
      onClick={() => {
        if (nonSortableColumns.includes(item)) return;

        if (leadSort === item) {
          setLeadSortAscending(!leadSortAscending);
        } else {
          setLeadSort(item);
        }
      }}
    >
      <HeaderText>
        {getHeaderLabel(getTitle(item, mrrLabel))}
        {!nonSortableColumns.includes(item) && (
          <CaretImg
            src={iconNewCaretDown}
            style={{
              transform:
                leadSort === item
                  ? leadSortAscending
                    ? "rotate(0deg)"
                    : "rotate(180deg)"
                  : leadSortAscending
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
            }}
          />
        )}
      </HeaderText>
    </div>
  );
};

const HeaderText = styled(AppText)`
  font-size: 10px;
  font-weight: 600;
  height: 100%;
  letter-spacing: 0.2px;
  line-height: 15px;
  margin: 0px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const CaretImg = styled.img`
  width: 8px;
  margin-left: 4px;
  margin-bottom: 2px;
  cursor: pointer;
`;
