import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik } from "formik";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context";
import { CallContext } from "../../context/CallContext";
import { LeadCardContext } from "../../context/LeadCardContext";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppErrorText, AppInput, AppSelect, AppText, NewAppSelect, SkeletonBlock } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { PhoenixCheckbox, PhoenixInput } from "../UI/Phoenix";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  onCancel: any;

  blinds: any;
  setBlinds: any;

  release?: boolean;
}

const SKIP_LEAD = gql`
  mutation skipLead($lead_intent_id: String!, $reason: String) {
    skipLead(lead_intent_id: $lead_intent_id, reason: $reason) {
      id
    }
  }
`;

const FETCH_UPCOMING_EVENTS = gql`
  query fetchScheduledItems($lead_id: String!, $show_all_lead: Boolean) {
    fetchScheduledItems(lead_id: $lead_id, show_all_lead: $show_all_lead) {
      id
    }
  }
`;

const RELEASE_LEAD = gql`
  mutation releaseLead($lead_intent_id: String, $lead_id: String, $reason: String, $scheduled_event_ack: Boolean) {
    releaseLead(
      lead_intent_id: $lead_intent_id
      lead_id: $lead_id
      reason: $reason
      scheduled_event_ack: $scheduled_event_ack
    ) {
      id
      __typename
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      primary_phone_number
      primary_email
      business_name
      favorited
      full_name
      next_intent_scheduled_or_unscheduled {
        id
      }
    }
  }
`;

const optionsReason = [
  { value: "", label: "Select" },
  { value: "Bad Vertical", label: "Bad Vertical" },
  { value: "Not a Good Time to Call", label: "Not a Good Time to Call" },
  { value: "Missing Key Data", label: "Missing Key Data" },
  { value: "Bad Lead History", label: "Bad Lead History" },
  { value: "Other", label: "Other" },
];

const SkipOrReleaseLeadModal: React.FC<DisappearingDivProps> = ({ onCancel, blinds, setBlinds, release = false }) => {
  const { upcomingDials, setUpcomingDials, customDials, setCustomDials, favoriteDials, setFavoriteDials } = useContext(
    LeadCardContext,
  );

  const { skipOrReleaseLeadModalData, setReleaseLeadModal, setSkipLeadModal } = useContext(ModalContext);
  const { resetNextDial, suggestedActionData } = useContext(CallContext);

  const { data: dataLead, loading: loadingLead, error: errorLead } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    variables: { id: skipOrReleaseLeadModalData.lead_id },
  });

  const { data: dataUpcomingEvents, loading: loadingUpcomingEvents, error: errorUpcomingEvents } = useQuery(
    FETCH_UPCOMING_EVENTS,
    {
      fetchPolicy: "network-only",
      skip: !skipOrReleaseLeadModalData?.lead_id,
      variables: { lead_id: skipOrReleaseLeadModalData.lead_id },
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  console.log("skipOrReleaseLeadModalData", skipOrReleaseLeadModalData);

  //only for leads with intents
  const [releaseLead, { loading: releaseLeadLoading, error: releaseLeadError }] = useMutation(RELEASE_LEAD, {
    onCompleted({ releaseLead }) {
      if (!releaseLead) {
        appToast("Error: Something went wrong while releasing lead!");
        return;
      }
      appToast("Lead was released!");
      //remove from custom/active queue state
      const filteredCustom = customDials.slice().filter((item: any) => item?.lead?.id !== dataLead?.fetchLead?.id);
      setCustomDials(filteredCustom);

      const filteredActive = upcomingDials.slice().filter((item: any) => item?.lead?.id !== dataLead?.fetchLead?.id);

      setUpcomingDials(filteredActive);

      if (dataLead?.fetchLead?.favorited) {
        const filteredFavorites = favoriteDials.slice().filter((item: any) => item?.id !== dataLead?.fetchLead?.id);
        setFavoriteDials(filteredFavorites);
      }
      if (skipOrReleaseLeadModalData?.isNextDial) {
        console.log("lead was in next dial resetNextDial");
        resetNextDial();
      }

      setReleaseLeadModal(false);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `releaseLead GraphQL Error: ${message}`,
      });
      console.log("Error in releaseLead: ", message);
    },
    refetchQueries: [
      "fetchFavoritedLeads",
      "fetchCustomQueueLeads",
      "fetchRecommendedDial",
      "fetchNextDial",
      "fetchFilteredLeadsInQueueV2",
    ],
  });

  //only for leads with intents
  const [skipLead, { loading: skipLeadLoading, error: skipLeadError }] = useMutation(SKIP_LEAD, {
    onCompleted({ skipLead }) {
      if (!skipLead) {
        appToast(`Error: Something went wrong while lead!`);

        return;
      }
      appToast(`Lead was snoozed!`);

      const filteredActive = upcomingDials.slice().filter((item: any) => item?.lead?.id !== dataLead?.fetchLead?.id);

      setUpcomingDials(filteredActive);

      if (skipOrReleaseLeadModalData?.isNextDial) {
        resetNextDial();
      }

      setSkipLeadModal(false);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `skipLead GraphQL Error: ${message}`,
      });
      console.log("Error in skipLead: ", message);
    },

    refetchQueries: [
      "fetchFavoritedLeads",
      "fetchCustomQueueLeads",
      "fetchRecommendedDial",
      "fetchFilteredLeadsInQueueV2",
      "fetchNextDial",
    ],
  });

  const loading = loadingLead || loadingUpcomingEvents || releaseLeadLoading || skipLeadLoading;
  const error = errorLead || errorUpcomingEvents || releaseLeadError || skipLeadError;

  if (loading) {
    return (
      <Modal open={blinds} onClose={() => setBlinds(false)}>
        <SkeletonBlock height={371} width={432} borderRadius={4} />
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal open={blinds} onClose={() => setBlinds(false)}>
        <AppErrorText>Something went wrong!</AppErrorText>
      </Modal>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Formik
        enableReinitialize
        isInitialValid={false}
        initialValues={{ reason: "", customReason: "", releaseLeadFutureEventConfirmation: false }}
        onSubmit={async (values) => {
          if (release) {
            await releaseLead({
              variables: {
                lead_intent_id: dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.id,
                lead_id: dataLead?.fetchLead?.id,
                reason: values.reason === "Other" ? values.customReason : values.reason,
                scheduled_event_ack: !!dataUpcomingEvents?.fetchScheduledItems?.length
                  ? values.releaseLeadFutureEventConfirmation
                  : true,
              },
            });
          } else {
            await skipLead({
              variables: {
                lead_intent_id:
                  skipOrReleaseLeadModalData?.intent?.id ||
                  dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.id,
                reason: values.reason === "Other" ? values.customReason : values.reason,
              },
            });
          }
        }}
        validationSchema={Yup.object().shape({
          reason: Yup.string().required("Required"),
          customReason: Yup.string().when("reason", {
            is: "Other",
            then: Yup.string().required("Required"),
          }),
          releaseLeadFutureEventConfirmation: Yup.boolean().test("is-true", "Must be checked", (value) => {
            if (release && !!dataUpcomingEvents?.fetchScheduledItems?.length) {
              return value === true;
            }
            return true;
          }),
        })}
      >
        {({ values, errors, setFieldValue, isValid, submitForm, isInitialValid, isSubmitting }) => {
          console.log("values", values);
          console.log("errors", errors);
          console.log("isValid", isValid);

          return (
            <Modal
              open={blinds}
              onClose={() => {
                setBlinds(false);
                onCancel();
              }}
            >
              <ModalContent>
                <TitleDiv>
                  <TitleText>{release ? "Release" : "Snooze"} Lead</TitleText>
                </TitleDiv>
                <PaddingDiv>
                  {release ? (
                    <ConfirmText fontSize={12} fontWeight={500}>
                      Are you sure you want to release{" "}
                      <span style={{ fontWeight: 500 }}>{`${
                        !!dataLead?.fetchLead?.full_name ? dataLead?.fetchLead?.full_name : ""
                      } ${
                        !!dataLead?.fetchLead?.business_name ? `(${dataLead?.fetchLead?.business_name})` : ""
                      }`}</span>
                      ? They will be removed from your queue and made available for re-assignment to other reps.
                    </ConfirmText>
                  ) : (
                    <ConfirmText>
                      Are you sure you want to snooze{" "}
                      <span style={{ fontWeight: 500 }}>
                        {!!dataLead?.fetchLead?.full_name ? dataLead?.fetchLead?.full_name : "This Lead"}
                        {" - "}
                        {!!dataLead?.fetchLead?.business_name ? `(${dataLead?.fetchLead?.business_name})` : ""}
                      </span>{" "}
                      for 24 hours?
                    </ConfirmText>
                  )}
                  <LabelText>
                    Why are you {release ? "releasing" : "snoozing"}
                    <span style={{ color: "red" }}>*</span>
                  </LabelText>
                  <NewAppSelect
                    value={values.reason}
                    onChange={(e: any) => {
                      setFieldValue("reason", e.value);
                    }}
                    options={optionsReason}
                  />
                  {values.reason === "Other" && (
                    <>
                      <LabelText>
                        Please elaborate here: <span style={{ color: "red" }}>*</span>
                      </LabelText>
                      <PhoenixInput
                        value={values.customReason}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setFieldValue("customReason", e?.target?.value);
                        }}
                        variant="primary"
                      />
                    </>
                  )}
                  {release && !!dataUpcomingEvents?.fetchScheduledItems?.length && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "16px",
                        gap: "8px",
                      }}
                    >
                      <PhoenixCheckbox
                        checked={!!values.releaseLeadFutureEventConfirmation}
                        onChange={() => {
                          setFieldValue(
                            "releaseLeadFutureEventConfirmation",
                            !values.releaseLeadFutureEventConfirmation,
                          );
                        }}
                      />
                      <AppText fontWeight={500} fontSize={12} variant="error">
                        I understand that this lead has future scheduled events and releasing them will delete those
                        events.
                      </AppText>
                    </div>
                  )}

                  <ConfirmButton
                    variant={"primary"}
                    disabled={!isValid || loading || isSubmitting}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Confirm & {release ? "Release" : "Snooze"} Lead
                  </ConfirmButton>
                  <SkipButton onClick={onCancel}>Cancel</SkipButton>
                </PaddingDiv>
              </ModalContent>
            </Modal>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalContent = styled.div`
  width: 432px;
  height: 371px;
  background-color: ${theme.WHITE_COLOR};
`;

const CustomSelect = styled(AppSelect)`
  text-align: left;
  border-color: ${theme.NEUTRAL100};
  :focus {
    border-color: ${theme.BLACK_COLOR};
  }
`;

const CustomInput = styled(AppInput)`
  margin-top: 0px;
  text-align: left;
  border-color: ${theme.NEUTRAL100};
  :focus {
    border-color: ${theme.BLACK_COLOR};
  }
`;

const PaddingDiv = styled.div`
  padding: 24px;
  background-color: ${theme.WHITE_COLOR};
`;

const ConfirmText = styled(AppText)`
  text-align: center;
  margin-bottom: 20px;
`;

const LabelText = styled(AppText)`
  padding: 3px;
  text-align: left;
`;

const ConfirmButton = styled(NewAppButton)`
  margin-bottom: 16px;
  margin-top: 8px;
`;

const SkipButton = styled(NewAppButton)``;

export { SkipOrReleaseLeadModal };
