import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { NodeContainer } from "./NodeContainer";
import { Handle, Position } from "react-flow-renderer";
import { PhoenixIcon, PhoenixMultiSelect } from "../../Phoenix";
import { edit, log_out, more_vertical } from "../../../../images/NewDesign";
import { AppText } from "../../AppText";
import { FlexDiv } from "../../FlexDiv";
import { useClickOutside } from "../../../../utils/hooks";
import { ModalContext } from "../../../../context";

interface EndSequenceProps {
  data: any;
}

export const RepViewEndSequence: React.FC<EndSequenceProps> = ({ data }) => {
  const [nodeIsFocus, setNodeIsFocus] = useState<boolean>(false);

  useEffect(() => {
    const curNode = document.getElementById(data.id);
    const parentNode = curNode?.parentElement;
    if (!!parentNode) {
      parentNode.style.zIndex = nodeIsFocus ? "10" : "0";
    }
  }, [nodeIsFocus]);

  return (
    <NodeContainer
      padding="16px"
      id={data.id}
      style={{
        overflow: "unset",
        gap: "16px",
      }}
      className="nodrag"
    >
      <FlexDiv align="center">
        <IconCircle>
          <PhoenixIcon svg={log_out} size="small" variant="danger" hoverColor={theme.DANGER600} />
        </IconCircle>
        <AppText fontSize={12} fontWeight={500}>
          End Sequence
        </AppText>
      </FlexDiv>

      <Handle type="target" position={Position.Top} isConnectable={false} />
    </NodeContainer>
  );
};

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;
  margin-right: 8px;

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 50%;
`;
