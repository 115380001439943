import React from "react";
import { useField } from "formik";
import { AppMultiSelectProps } from "../../UI";
import { PhoenixMultiSelect } from "../../UI/Phoenix/PhoenixMultiSelect";

const PhoenixMultiSelectField = ({
  style,
  ...props
}: {
  name: string;
  style?: React.CSSProperties;
  onChange?: (e: any, values?: any) => void;
  value?: any;
  options?: any[];
  defaultValue?: string;
  isMulti?: boolean;
  marginBottom?: boolean;
  isClearable?: boolean;
  menuPortal?: boolean;
  menuShouldBlockScroll?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  components?: any;
  required?: boolean;
  titleText?: string;
  titleTextSpacing?: string | number;
  multiValueUppercase?: boolean;
  showCheckbox?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  creatableOptions?: boolean;
  isValidNewOption?: (inputValue: string) => boolean;
  hideErrorMessage?: boolean;
  noOptionsText?: string;
  width?: number;
} & AppMultiSelectProps) => {
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <PhoenixMultiSelect {...field} {...props} error={props.error || meta.error} />
    </div>
  );
};

export { PhoenixMultiSelectField };
