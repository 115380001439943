import React from "react";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixRadio } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";

type RadioSelectProps = {
  direction?: "row" | "column";
  gap?: number;
  onChange?: (selected: OptionItem) => void;
  options?: OptionItem[];
  value?: string;
};

const RadioSelect = ({ direction = "column", gap = 24, onChange, options = [], value }: RadioSelectProps) => {
  return (
    <FlexDiv direction={direction} gap={gap}>
      {options.map((item) => (
        <FlexDiv align="center" gap={8} onClick={() => onChange?.(item)} style={{ cursor: "pointer" }}>
          <PhoenixRadio selected={item.value === value} />
          <AppText fontSize={12} fontWeight={500}>
            {item.label}
          </AppText>
        </FlexDiv>
      ))}
    </FlexDiv>
  );
};

export default RadioSelect;
