import styled from "styled-components";
import * as React from "react";
import { theme } from "../../utils/theme";
import iconCall from "../../images//icons-ic-phone-callback.svg";
import {
  demoStatus,
  followupDemoStatus,
  callBackStatus,
  decisionCallStatus,
  rescheduleDemoStatus,
} from "../../images/leadhistory";
import moment from "moment";
import { AppText } from "../UI";

const returnScheduleItemIcon = (action: string) => {
  switch (action) {
    case "Demo":
      return demoStatus;
    case "Follow Up Demo":
      return followupDemoStatus;
    case "Call Back":
    case "Scheduled Callback":
    case "Scheduled Call Back":
    case "Schedule Callback":
    case "Schedule Call Back":
      return callBackStatus;
    case "Decision Call":
      return decisionCallStatus;
    case "Rescheduled Demo":
      return rescheduleDemoStatus;
    default:
      return iconCall;
  }
};

const calculateDuration = (start: any, end: any) => {
  if (!!!start || !!!end) return;
  const a = moment(start);
  const b = moment(end);
  return `${Math.abs(a.diff(b, "minutes"))} min`;
};

interface ScheduleTaskItemProps {
  first_name: string;
  last_name: string;
  associated_action: string;
  start_time: Date;
  end_time: Date;
  onClick: () => void;
}

const ScheduleTaskItem: React.FC<ScheduleTaskItemProps> = ({
  associated_action,
  start_time,
  end_time,
  first_name,
  last_name,
  onClick,
}) => {
  return (
    <ItemContainer onClick={() => onClick()}>
      <Icon src={returnScheduleItemIcon(associated_action)} alt="test" onClick={() => onClick()} />
      <NameActionDiv>
        <LeadNameText onClick={() => onClick()}>
          {first_name} {last_name}
        </LeadNameText>
        <LeadNameText onClick={() => onClick()}>
          {associated_action} <Duration>{calculateDuration(start_time, end_time)}</Duration>
        </LeadNameText>
      </NameActionDiv>
      <MyScheduleTime onClick={() => onClick()}>{moment(start_time).format("h:mm a")}</MyScheduleTime>
    </ItemContainer>
  );
};

const Duration = styled.span`
  opacity: 0.5;
`;

const Icon = styled.img`
  height: 24px;
`;

const ItemContainer = styled.div`
  background-color: ${theme.NEUTRAL100};
  height: 60px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0px 13px;
  margin-top: 10px;
  cursor: pointer;
`;

const LeadNameText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  margin-left: 9px;

  border-bottom: none;
`;

const MyScheduleTime = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  background: ${theme.NEUTRAL200};
  text-transform: uppercase;
  width: auto;
  padding: 0px 10px;
  height: 28px;
  border-radius: 14px;
  border-bottom: none;
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  margin-left: auto;
  white-space: nowrap;
`;

const NameActionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export { ScheduleTaskItem };
