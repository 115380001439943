import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { caret_down } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { useClickOutside } from "src/utils/hooks";
import { AppText } from "src/Components/UI";
import { LeadFilterContext } from "src/context";
import useModalContext from "src/hooks/useModalContext";
import { gql, useMutation } from "@apollo/client";
import { appToast, errorToast } from "src/utils/toast";
import { MixpanelActions } from "src/services/mixpanel";

const UPDATE_SAVED_VIEW = gql`
  mutation createOrUpdateAllLeadsSavedViewFilter($allLeadsSavedViewFilterInput: AllLeadsSavedViewFilterInput!) {
    createOrUpdateAllLeadsSavedViewFilter(allLeadsSavedViewFilterInput: $allLeadsSavedViewFilterInput) {
      id
      is_default
      is_favorite
    }
  }
`;

const BASE_ACTION_ITEMS = [
  {
    label: "New View",
    key: "new",
  },
];

const UPDATE_ACTION_ITEMS = [
  {
    label: "Update View",
    key: "edit",
  },
  {
    label: "Clone View",
    key: "clone",
  },
];

const SavedViewAction = () => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { savedView, leadsFilterVariables } = useContext(LeadFilterContext);
  const {
    setShowAddOrEditLeadFilterSavedViewModal,
    setLeadFilterSavedViewModalData,
    setShowCloneLeadFilterSavedView,
  } = useModalContext();

  useClickOutside(wrapperRef, () => setIsOpen(false));

  const [updateAllLeadsSavedViewFilter, { loading }] = useMutation(UPDATE_SAVED_VIEW, {
    onCompleted() {
      appToast("Saved view updated");
      MixpanelActions.track("Lead filter saved view updated");
    },
    onError({ message }) {
      errorToast(message);
      console.log("Error in updateAllLeadsSavedViewFilter: ", message);
    },
    refetchQueries: ["fetchAllLeadsSavedViewFilters"],
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleUpdate = async () => {
    await updateAllLeadsSavedViewFilter({
      variables: {
        allLeadsSavedViewFilterInput: {
          id: savedView?.id,
          name: savedView.name,
          description: savedView.description,
          ...leadsFilterVariables.current,
        },
      },
    });
  };

  const handleSaveView = () => {
    setShowAddOrEditLeadFilterSavedViewModal(true);
    setLeadFilterSavedViewModalData({
      name: "",
      description: "",
    });
  };

  const handleClone = () => {
    setShowCloneLeadFilterSavedView(true);
    setLeadFilterSavedViewModalData(savedView);
  };

  const handleItemClick = (key: string) => {
    switch (key) {
      case "new":
        handleSaveView();
        break;

      case "edit":
        handleUpdate();
        break;

      case "clone":
        handleClone();
        break;

      default:
        break;
    }
    setIsOpen(false);
  };

  const items = [...BASE_ACTION_ITEMS, ...(savedView?.id ? UPDATE_ACTION_ITEMS : [])];

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownHeader onClick={toggleDropdown}>
        <HeaderText>Save View</HeaderText>
        <PhoenixIcon
          svg={caret_down}
          color={theme.text.brand.primary}
          hoverColor={theme.PRIMARY600}
          size={16}
          fillIcon
        />
      </DropdownHeader>
      {isOpen && (
        <DropdownMenu>
          <DropdownMenuContainer>
            {items.map((item) => (
              <DropdownOptionItem key={item.key} onClick={() => handleItemClick(item.key)}>
                {item.label}
              </DropdownOptionItem>
            ))}
          </DropdownMenuContainer>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default SavedViewAction;

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownHeader = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: space-between;
  padding: 4px 8px;
  white-space: nowrap;
`;

const HeaderText = styled(AppText)`
  color: ${theme.text.brand.primary};
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
`;

const DropdownMenu = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  width: 100%;
  z-index: 3;
`;

const DropdownMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
  padding: 4px 0;
`;

export const DropdownOptionList = styled.ul`
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
`;
export const DropdownOptionItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 8px;
  padding: 4px 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DropdownMoreBadge = styled.div`
  background-color: ${theme.text.brand.primary};
  border-radius: 4px;
  padding: 2px 4px;
`;
